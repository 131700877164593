import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { LoadingOutlined, ArrowDownOutlined } from '@ant-design/icons';
import {
  deleteAlbumMedia,
  getAlbumImages
} from '../../../../../../../actions/album';
import ErrorBoundary from '../../../../../../../components/ErrorBoundary';
import { currentOccasionId } from '../../../../../../../selectors/dynamicData';
import {
  getAllGalleryPhotos,
  getAllGalleryPhotosNextPresent,
  getGalleryUUID
} from '../../../../../../../selectors/gallery';
import {
  getAllAlbumPhotos,
  getAllAlbumPhotosNextPresent
} from '../../../../../../../selectors/album';
import AlbumImage from './AlbumImage';
import './styles.scss';

function AlbumImageView(props) {
  const [page, setPage] = useState(1);
  useEffect(() => {
    props.getAlbumImages(props.occasionId, 'Album', props.albumId, page);
  }, []);

  const getNextPage = (str) => {
    const decodedURI = decodeURI(str);
    const number = new URLSearchParams(decodedURI).get('page[number]');
    return number ? parseInt(number) : 1;
  };

  const pagination = () => {
    if (props.photosNextPresent) {
      const nextPage = getNextPage(props.photosNextPresent);
      setPage(nextPage);
      props.getAlbumImages(props.occasionId, 'Album', props.albumId, nextPage);
    }
  };

  const getFilteredList = (list, length) => {
    let arr = [];
    for (let i = 0; i < list.length; i = i + length) {
      let end = length + i < list.length ? length + i : list.length;
      arr = [...arr, list.slice(i, end)];
    }
    return arr;
  };

  const onDeleteClick = (id) => {
    if (id) {
      props.deleteAlbumMedia(
        props.occasionId,
        id,
        'Album',
        props.albumId,
        'photo'
      );
    }
  };

  return (
    <ErrorBoundary>
      <div className="gallery-view-container">
        {page === 1 &&
          !props.fetchProgress &&
          props.photos.length === 0 &&
          'No Images To Display!'}
        {page === 1 && props.fetchProgress && props.photos.length === 0 && (
          <LoadingOutlined style={{ fontSize: 40 }} />
        )}
        <div className="gallery-media-container">
          {props?.photos?.length > 0 &&
            getFilteredList(props.photos, 3).map((item, index) => {
              return (
                <div className="gallery-image-row" key={index}>
                  {item.map((e, index) => {
                    return (
                      <AlbumImage
                        image={e}
                        onDeleteClick={onDeleteClick}
                        key={e.id}
                      />
                    );
                  })}
                </div>
              );
            })}
        </div>
        {props.photosNextPresent && (
          <Button className="gallery-load-more" onClick={pagination}>
            Load More{' '}
            {props.fetchProgress && page !== 1 ? (
              <LoadingOutlined />
            ) : (
              <ArrowDownOutlined />
            )}
          </Button>
        )}
      </div>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  occasionId: currentOccasionId(store, props),
  photos: getAllAlbumPhotos(store, props),
  photosNextPresent: getAllAlbumPhotosNextPresent(store, props),
  fetchProgress: store.browserState.fetchProgress,
  galleryId: getGalleryUUID(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteAlbumMedia,
      getAlbumImages
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AlbumImageView);
