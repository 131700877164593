import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../styles.scss';
import { Form, AutoComplete, Button } from 'antd';
import { SectionContent } from '../../../../../../components/Section';
import { CloseOutlined } from '@ant-design/icons';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { hospitalityTaskProvideAnswer } from '../../../../../../actions/hospitalityTask';
import moment from 'moment';

const { Option } = AutoComplete;

const validateHospitalityTask = (values, dataType) => {
  const errors = {};

  if (dataType === 'datetime') {
    if (!values.answerDate) errors.answerDate = 'Date is required';
    if (!values.answerTime) errors.answerTime = 'Time is required';
  }

  if (dataType === 'document') {
    if (!values.answer) errors.answer = 'Document is required';
  }

  if (dataType === 'text') {
    if (!values.answer) errors.answer = 'Value is required';
  }

  if (dataType === 'number') {
    if (!values.answer) errors.answer = 'Number is required';
    if (isNaN(values.answer)) errors.answer = 'Not a number';
  }

  if (dataType === 'scq') {
    if (!values.answer) errors.answer = 'Select an option';
  }

  if (dataType === 'mcq') {
    if (!values.answer || values.answer.length === 0)
      errors.answer = 'Select an option';
  }

  return errors;
};

const AddProvidedAnswerForAll = (props) => {
  const [hospitalityTaskAnswer, setHospitalityTaskAnswer] = React.useState({
    answer:
      props.dataInputType === 'mcq'
        ? []
        : props.dataInputType === 'document' ||
          props.dataInputType === 'multiple_documents'
        ? null
        : '',
    answerDate: '',
    answerTime: ''
  });

  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleHospitalityTaskCreate = () => {
    try {
      const validateObj = validateHospitalityTask(
        hospitalityTaskAnswer,
        props.dataInputType
      );
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        const formData = new FormData();

        if (props.dataInputType === 'document') {
          formData.append(
            props.sendToAll
              ? 'document_attachment'
              : 'hospitality_task_answer[document_attachment]',
            hospitalityTaskAnswer.answer[0].originFileObj
          );
        }

        if (props.dataInputType === 'multiple_documents') {
          hospitalityTaskAnswer?.answer.forEach((file) => {
            if (file?.originFileObj)
              formData.append(
                props.sendToAll
                  ? 'document_attachment'
                  : 'hospitality_task_answer[multiple_document_attachments][]',
                file?.originFileObj
              );
          });
        }

        if (props.dataInputType === 'mcq') {
          let str = '';
          hospitalityTaskAnswer.answer &&
            hospitalityTaskAnswer.answer.forEach((el, index, all) => {
              if (index === all.length - 1) {
                str += el;
              } else {
                str += el + ',';
              }
            });
          formData.append(
            props.sendToAll ? 'answer' : 'hospitality_task_answer[answer]',
            str
          );
        }

        if (props.dataInputType === 'datetime') {
          const date = moment(hospitalityTaskAnswer.answerDate).format(
            'YYYY-MM-DD'
          );
          const time = moment(hospitalityTaskAnswer.answerTime).format('HH:mm');
          const dateTime = new Date(`${date} ${time}`);
          formData.append(
            props.sendToAll ? 'answer' : 'hospitality_task_answer[answer]',
            dateTime
          );
        }

        if (
          props.dataInputType === 'text' ||
          props.dataInputType === 'scq' ||
          props.dataInputType === 'number'
        ) {
          formData.append(
            props.sendToAll ? 'answer' : 'hospitality_task_answer[answer]',
            hospitalityTaskAnswer.answer
          );
        }

        if (!props.sendToAll) {
          if (props.type === 'provide') {
            if (props?.userData?.guestId) {
              formData.append(
                'hospitality_task_answer[hospitality_guest_id]',
                props?.userData?.guestId
              );
            }

            formData.append(
              'hospitality_task_answer[answer_to_id]',
              props?.userData?.userId
            );
          }
          if (props.type === 'request') {
            if (props?.userData?.guestId) {
              formData.append(
                'hospitality_task_answer[hospitality_guest_id]',
                props?.userData?.guestId
              );
            }
            formData.append(
              'hospitality_task_answer[answer_by_id]',
              props?.userData?.userId
            );
            formData.append(
              'hospitality_task_answer[answer_to_id]',
              props?.hospitalityTaskDetails?.createdBy?.id
            );
          }
        }

        props.hospitalityTaskProvideAnswer(
          props.occasionId,
          props.hospitalityTaskId,
          null,
          formData,
          false,
          props.sendToAll,
          props.type,
          callback
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const callback = () => {
    setHospitalityTaskAnswer({
      answer:
        props.dataInputType === 'mcq'
          ? []
          : props.dataInputType === 'document' ||
            props.dataInputType === 'multiple_documents'
          ? null
          : '',
      answerDate: '',
      answerTime: ''
    });
    props.handleCloseClick && props.handleCloseClick();
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (isInitiallyDisabled) {
          setInitiallyDisabled(false);
        }

        if (type === 'answer') {
          value = e.fileList;
          name = type;
        }
        if (type === 'answerDate') {
          value = moment(e, 'DD/MM/YYYY');
          name = type;
        }
        if (type === 'answerTime') {
          value = moment(e, 'HH:mm');
          name = type;
        }
        const hospitalityTaskAnswerValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        setHospitalityTaskAnswer({
          ...hospitalityTaskAnswer,
          [inputName]: hospitalityTaskAnswerValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const optionsData = () => {
    const arr =
      (props.hospitalityTaskDetails &&
        props.hospitalityTaskDetails.hospitalityTaskOptions &&
        props.hospitalityTaskDetails.hospitalityTaskOptions.map((el) => ({
          label: el.option,
          name: el.option
        }))) ||
      [];

    return arr;
  };

  return (
    <ErrorBoundary>
      <SectionContent className="hospitality-section">
        <div className="hospitality-create-form">
          <Form
            className="hospitality-form-container"
            onFinish={handleHospitalityTaskCreate}>
            {props.dataInputType === 'datetime' && (
              <>
                <InputGroup
                  fieldType="datePicker"
                  name="answerDate"
                  label="Provide Date"
                  value={hospitalityTaskAnswer?.answerDate}
                  onChange={handleChange}
                  errorMessage={errorMessages.answerDate}
                />
                <InputGroup
                  fieldType="timePicker"
                  name="answerTime"
                  label="Provide Time"
                  format="HH:mm"
                  value={hospitalityTaskAnswer?.answerTime}
                  onChange={handleChange}
                  errorMessage={errorMessages.answerTime}
                />
              </>
            )}
            {(props.dataInputType === 'number' ||
              props.dataInputType === 'text') && (
              <InputGroup
                fieldType={
                  props.dataInputType === 'number' ? 'text' : 'textArea'
                }
                name="answer"
                label="Your Answer"
                value={hospitalityTaskAnswer?.answer}
                onChange={handleChange}
                errorMessage={errorMessages.answer}
              />
            )}
            {props.dataInputType === 'document' && (
              <InputGroup
                fieldType="file"
                name="answer"
                label="Your Answer"
                accept="*/*"
                fileList={hospitalityTaskAnswer?.answer}
                onChange={handleChange}
                errorMessage={errorMessages.answer}
              />
            )}

            {props.dataInputType === 'multiple_documents' && (
              <InputGroup
                fieldType="file"
                name="answer"
                label="Your Answer"
                accept="*/*"
                fileList={hospitalityTaskAnswer?.answer}
                onChange={handleChange}
                errorMessage={errorMessages.answer}
                multiple={true}
                noMaxCount={30}
              />
            )}

            {(props.dataInputType === 'mcq' ||
              props.dataInputType === 'scq') && (
              <InputGroup
                fieldType="dropdown"
                className="dropdown-input"
                name="answer"
                label="Your Answer"
                mode={props.dataInputType === 'mcq' ? 'multiple' : 'single'}
                placeholder="Select Options"
                onChange={handleChange}
                value={hospitalityTaskAnswer?.answer}
                errorMessage={errorMessages.answer}>
                {optionsData()?.length > 0 &&
                  optionsData().map((item, index) => {
                    return (
                      <Option value={item?.label} key={index}>
                        <div style={{ display: 'flex' }}>
                          <span>{item.name}</span>
                        </div>
                      </Option>
                    );
                  })}
              </InputGroup>
            )}
            <SubmitButton
              buttonText="Add Answer"
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hospitalityTaskProvideAnswer
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProvidedAnswerForAll);
