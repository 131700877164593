import request from './request';

export function occasionGroupCreateApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/groups`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function occasionGroupViewApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/get_groups_with_pagination?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function updateOccasionGroupApi(ocId, groupId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/groups/${groupId}`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function getGroupMembersApi(ocId, groupId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/get_occasion_group_members_with_pagination?page=${page}&group_id=${groupId}`,
    {
      method: 'GET',
      token
    }
  );
}

export function createGroupMembersApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/occasion_group_members`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function parseCSVtoAddGroupMembersApi(ocId, formData, groupId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/upload_member_via_csv?group_id=${groupId}`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function getGroupOccasionUsersApi(ocId, groupId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/get_occasion_users?page=${page}&group_id=${groupId}`,
    {
      method: 'GET',
      token
    }
  );
}

export function searchGroupOccasionUsersApi(ocId, groupId, page, query, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/search_occasion_users?page=${page}&q=${query}&group_id=${groupId}`,
    {
      method: 'GET',
      token
    }
  );
}

export function deleteGroupMembersApi(ocId, memberId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_group_members/${memberId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function deleteOccasionGroupApi(ocId, groupId, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/groups/${groupId}`, {
    method: 'DELETE',
    token
  });
}

export function SearchGroupMemberApi(
  ocId,
  groupId,
  page,
  memberType,
  searchData,
  token
) {
  let query = `page=${page}`;
  if (!!groupId) {
    query += `&group_id=${groupId}`;
  }
  if (!!searchData) {
    query += `&name_or_email_or_mobile=${searchData}`;
  }
  if (!!memberType) {
    query += `&member_type=${memberType}`;
  }

  return request(
    `api/v2/sociana_admin/oc/${ocId}/search_occasion_group_members_with_pagination?${query}`,
    {
      method: 'GET',
      token
    }
  );
}
