import {
  GET_WHATSAPP_TEMPLETS_PAGINATION_SUCCESS,
  GET_WHATSAPP_TEMPLETS_PAGINATION_FAILED,
  GET_WATI_PAGINATION_FAILED,
  GET_WATI_PAGINATION_SUCCESS,
  DELETE_WHATSAPP_TEMPLETS_SUCCESS,
  DELETE_WHATSAPP_TEMPLETS_FAILED,
  GET_WHATSAPP_TEMPLATE_MODELABLE_PAGINATION_SUCCESS,
  GET_WHATSAPP_TEMPLATE_MODELABLE_PAGINATION_FAILED,
  CREATE_WHATSAPP_TEMPLATE_MODELABLE_SUCCESS,
  CREATE_WHATSAPP_TEMPLATE_MODELABLE_FAILED,
  DELETE_WHATSAPP_TEMPLATE_MODELABLE_SUCCESS,
  DELETE_WHATSAPP_TEMPLATE_MODELABLE_FAILED,
  GET_WHATSAPP_TEMPLATE_PARAMS_PAGINATION_SUCCESS,
  CREATE_WHATSAPP_TEMPLATE_PARAMS_SUCCESS,
  DELETE_WHATSAPP_TEMPLATE_PARAMS_SUCCESS,
  GET_WHATSAPP_TEMPLATE_PARAMS_PAGINATION_FAILED,
  CREATE_WHATSAPP_TEMPLATE_PARAMS_FAILED,
  DELETE_WHATSAPP_TEMPLATE_PARAMS_FAILED
} from '../constants/actionTypes';
import { union } from '../helpers/extraHelpers';

const initialState = {
  allIds: null,
  whatsappTemplatesData: null,
  error: null,
  whatsappTemplatesLinks: null,
  watiData: null,
  watiLink: null,
  watiAllData: null,
  whatsappTemplateModelableData: null,
  whatsappTemplateModelableLinks: null,
  whatsappTemplateParamsData: null,
  whatsappTemplateParamsLinks: null
};

const whatsappTemplates = (state = initialState, action) => {
  switch (action.type) {
    case GET_WHATSAPP_TEMPLETS_PAGINATION_SUCCESS: {
      const { data, links, page, meta } = action.payload;

      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];
      const allIds = page !== 1 ? union(state.allIds || [], newIds) : newIds;
      return {
        ...state,
        whatsappTemplatesData: newIds,
        allIds,
        whatsappTemplatesLinks: links
      };
    }

    case DELETE_WHATSAPP_TEMPLETS_SUCCESS: {
      const { data } = action.payload;
      const prvData = state.whatsappTemplatesData || [];
      const AllData = state.allIds || [];
      const filteredIds = prvData.filter((id) => id !== data.id);
      const filteredIdsForAllData = AllData.filter((id) => id !== data.id);
      return {
        ...state,
        whatsappTemplatesData: filteredIds,
        allIds: filteredIdsForAllData
      };
    }

    case GET_WATI_PAGINATION_SUCCESS: {
      const { data, link, page } = action.payload;

      const newData =
        data && data?.length > 0
          ? data.filter((el) => el.status === 'APPROVED')
          : [];
      const allData =
        page !== 1 ? union(state.watiAllData || [], newData) : newData;
      return {
        ...state,
        watiData: newData,
        watiAllData: allData,
        watiLink: link
      };
    }

    case GET_WHATSAPP_TEMPLATE_MODELABLE_PAGINATION_SUCCESS: {
      const { data, wtId, links } = action.payload;
      const wtGroupedData = state.whatsappTemplateModelableData || {};
      const currentNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        whatsappTemplateModelableData: {
          ...wtGroupedData,
          [wtId]: [...currentNewIds]
        },
        whatsappTemplateModelableLinks: links
      };
    }

    case CREATE_WHATSAPP_TEMPLATE_MODELABLE_SUCCESS: {
      const { data, wtId } = action.payload;
      const wtGroupedData = state.whatsappTemplateModelableData || {};
      const currentIds = wtGroupedData[wtId] || [];

      const newIds = [data.id, ...currentIds];
      return {
        ...state,
        whatsappTemplateModelableData: {
          ...wtGroupedData,
          [wtId]: newIds
        }
      };
    }

    case DELETE_WHATSAPP_TEMPLATE_MODELABLE_SUCCESS: {
      const { data, wtId } = action.payload;
      const wtGroupedData = state.whatsappTemplateModelableData || {};
      const currentIds = wtGroupedData[wtId] || [];
      const filteredIds = currentIds.filter((id) => id !== data.id);

      return {
        ...state,
        whatsappTemplateModelableData: {
          ...wtGroupedData,
          [wtId]: filteredIds
        }
      };
    }

    case GET_WHATSAPP_TEMPLATE_PARAMS_PAGINATION_SUCCESS: {
      const { data, wmId, links } = action.payload;
      const wmGroupedData = state.whatsappTemplateParamsData || {};
      const currentNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        whatsappTemplateParamsData: {
          ...wmGroupedData,
          [wmId]: [...currentNewIds]
        },
        whatsappTemplateParamsLinks: links
      };
    }

    case CREATE_WHATSAPP_TEMPLATE_PARAMS_SUCCESS: {
      const { data, wmId } = action.payload;
      const wmGroupedData = state.whatsappTemplateParamsData || {};
      const currentIds = wmGroupedData[wmId] || [];

      const newIds = [data.id, ...currentIds];
      return {
        ...state,
        whatsappTemplateParamsData: {
          ...wmGroupedData,
          [wmId]: newIds
        }
      };
    }

    case DELETE_WHATSAPP_TEMPLATE_PARAMS_SUCCESS: {
      const { data, wmId } = action.payload;
      const wmGroupedData = state.whatsappTemplateParamsData || {};
      const currentIds = wmGroupedData[wmId] || [];
      const filteredIds = currentIds.filter((id) => id !== data.id);

      return {
        ...state,
        whatsappTemplateParamsData: {
          ...wmGroupedData,
          [wmId]: filteredIds
        }
      };
    }

    case GET_WATI_PAGINATION_FAILED:
    case GET_WHATSAPP_TEMPLATE_MODELABLE_PAGINATION_FAILED:
    case CREATE_WHATSAPP_TEMPLATE_MODELABLE_FAILED:
    case DELETE_WHATSAPP_TEMPLATE_MODELABLE_FAILED:
    case GET_WHATSAPP_TEMPLATE_PARAMS_PAGINATION_FAILED:
    case CREATE_WHATSAPP_TEMPLATE_PARAMS_FAILED:
    case DELETE_WHATSAPP_TEMPLATE_PARAMS_FAILED:
    case DELETE_WHATSAPP_TEMPLETS_FAILED:
    case GET_WHATSAPP_TEMPLETS_PAGINATION_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};
export default whatsappTemplates;
