import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../styles.scss';
import { Form, AutoComplete, Button } from 'antd';
import { SectionContent } from '../../../../../../components/Section';
import { CloseOutlined } from '@ant-design/icons';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { hospitalityTaskProvideAnswer } from '../../../../../../actions/hospitalityTask';

const validateHospitalityTask = (values) => {
  const errors = {};

  if (!values.csv_file) errors.answer = 'Document is required';

  return errors;
};

const AddProvidedAnswerForAll = (props) => {
  const [hospitalityTaskAnswer, setHospitalityTaskAnswer] = React.useState({
    csv_file: null
  });

  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleHospitalityTaskCreate = () => {
    try {
      const validateObj = validateHospitalityTask(hospitalityTaskAnswer);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        const formData = new FormData();

        formData.append(
          'csv_file',
          hospitalityTaskAnswer.csv_file[0].originFileObj
        );

        props.hospitalityTaskProvideAnswer(
          props.occasionId,
          props.hospitalityTaskId,
          null,
          formData,
          true,
          false,
          callback
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const callback = () => {
    setHospitalityTaskAnswer({
      answer:
        props.hospitalityTaskDetails?.dataInputType === 'mcq'
          ? []
          : props.hospitalityTaskDetails?.dataInputType === 'document'
          ? null
          : '',
      answerDate: '',
      answerTime: ''
    });
    props.handleCloseClick && props.handleCloseClick();
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (isInitiallyDisabled) {
          setInitiallyDisabled(false);
        }

        if (type === 'csv_file') {
          value = e.fileList;
          name = type;
        }

        const hospitalityTaskAnswerValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }

        setHospitalityTaskAnswer({
          ...hospitalityTaskAnswer,
          [inputName]: hospitalityTaskAnswerValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="hospitality-section">
        <div className="hospitality-create-form">
          <Form
            className="hospitality-form-container"
            onFinish={handleHospitalityTaskCreate}>
            <InputGroup
              fieldType="file"
              accept=".csv"
              name="csv_file"
              label="CSV file"
              fileList={hospitalityTaskAnswer?.csv_file}
              errorMessage={errorMessages.csv_file}
              onChange={handleChange}
            />
            <SubmitButton
              buttonText="Add Answer"
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hospitalityTaskProvideAnswer
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProvidedAnswerForAll);
