import { union } from 'lodash';
import {
  HOSPITALITY_TASK_CREATE_SUCCESS,
  HOSPITALITY_TASK_VIEW_SUCCESS,
  HOSPITALITY_TASK_VIEW_FAILED,
  HOSPITALITY_TASK_RESPONDED_VIEW_SUCCESS,
  HOSPITALITY_TASK_NOTRESPONDED_VIEW_SUCCESS,
  HOSPITALITY_TASK_RESPONDED_VIEW_FAILED,
  HOSPITALITY_TASK_NOTRESPONDED_VIEW_FAILED,
  HOSPITALITY_TASK_PROVIDED_VIEW_SUCCESS,
  HOSPITALITY_TASK_NOTPROVIDED_VIEW_SUCCESS,
  HOSPITALITY_TASK_NOTPROVIDED_VIEW_FAILED,
  HOSPITALITY_TASK_PROVIDED_VIEW_FAILED,
  HOSPITALITY_TASK_DELETE_SUCCESS,
  HOSPITALITY_TASK_DELETE_FAILED,
  HOSPITALITY_PROVIDED_TASK_ANSWER_DELETE_SUCCESS,
  HOSPITALITY_REQUESTED_TASK_ANSWER_DELETE_SUCCESS,
  HOSPITALITY_TASK_PROVIDE_ANSWER_SUCCESS,
  GET_ALL_TASKS_SUCCESS,
  SEARCH_NOTPROVIDED_TASKS_SUCCESS,
  RESET_NOTPROVIDEDTASKS,
  SEARCH_PROVIDED_TASKS_SUCCESS,
  RESET_PROVIDEDTASKS,
  SEARCH_RESPONDED_TASKS_SUCCESS,
  SEARCH_NOTRESPONDED_TASKS_SUCCESS,
  RESET_RESPONDEDTASKS,
  RESET_NOTRESPONDEDTASKS
} from '../constants/actionTypes';

const initialState = {
  hospitalityTaskId: null,
  error: null,
  taskLinks: null,
  allTasksData: null,
  allTasksLinks: null,
  respondedTaskId: null,
  respondedTaskLinks: null,
  notRespondedTaskId: null,
  notRespondedTaskLinks: null,
  providedTaskId: null,
  providedTaskLinks: null,
  notProvidedTaskId: null,
  notProvidedTaskLinks: null,
  notProvidedSearchData: null,
  notProvidedSearchLinks: null,
  isNotProvidedSearch: false,

  providedSearchData: null,
  providedSearchLinks: null,
  isProvidedSearch: false,

  respondedSearchData: null,
  respondedSearchLinks: null,
  isRespondedSearch: false,

  notRespondedSearchData: null,
  notRespondedSearchLinks: null,
  isNotRespondedSearch: false
};
const hospitalityTask = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TASKS_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const allTaskData = state.allTasksData || {};
      const allData = allTaskData?.[ocId] || [];

      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        allTasksData: {
          ...allTaskData,
          [ocId]: union([...allData, ...currentOccasionNewIds])
        },
        allTasksLinks: links
      };
    }

    case HOSPITALITY_TASK_VIEW_SUCCESS: {
      const { data, hospitalityCategoryId, links } = action.payload;
      const occasionGroupedData = state.hospitalityTaskId || {};
      const currentHospitalityTaskIds =
        occasionGroupedData[hospitalityCategoryId] || [];
      const currentHospitalityTaskNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        hospitalityTaskId: {
          ...occasionGroupedData,
          [hospitalityCategoryId]: currentHospitalityTaskNewIds
        },
        taskLinks: links
      };
    }
    case HOSPITALITY_TASK_CREATE_SUCCESS: {
      const { data, hospitalityCategoryId } = action.payload;
      const occasionGroupedData = state.hospitalityTaskId || {};
      const currentHospitalityTaskIds =
        occasionGroupedData[hospitalityCategoryId] || [];

      const newIds = [data.id, ...currentHospitalityTaskIds];
      return {
        ...state,
        hospitalityTaskId: {
          ...occasionGroupedData,
          [hospitalityCategoryId]: newIds
        }
      };
    }

    case HOSPITALITY_TASK_DELETE_SUCCESS: {
      const { data, hospitalityCategoryId } = action.payload;
      const occasionGroupedData = state.hospitalityTaskId || {};
      const currentHospitalityTaskIds =
        occasionGroupedData[hospitalityCategoryId] || [];

      const newIds = currentHospitalityTaskIds.filter((id) => id !== data.id);
      return {
        ...state,
        hospitalityTaskId: {
          ...occasionGroupedData,
          [hospitalityCategoryId]: newIds
        }
      };
    }

    case HOSPITALITY_TASK_RESPONDED_VIEW_SUCCESS: {
      const { data, hospitalityTaskId, links } = action.payload;

      const occasionGroupedData = state.respondedTaskId || {};
      const currentRespondedTaskIds =
        occasionGroupedData[hospitalityTaskId] || [];
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        respondedTaskId: {
          ...occasionGroupedData,
          [hospitalityTaskId]: newIds
        },
        respondedtaskLinks: links,
        isRespondedSearch: false
      };
    }

    case SEARCH_RESPONDED_TASKS_SUCCESS: {
      const { data, hospitalityTaskId, links } = action.payload;
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        respondedSearchData: {
          [hospitalityTaskId]: newIds
        },
        respondedSearchLinks: links,
        isRespondedSearch: true
      };
    }

    case SEARCH_NOTRESPONDED_TASKS_SUCCESS: {
      const { data, hospitalityTaskId, links } = action.payload;
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        notRespondedSearchData: {
          [hospitalityTaskId]: newIds
        },
        notRespondedSearchLinks: links,
        isNotRespondedSearch: true
      };
    }

    case HOSPITALITY_TASK_NOTRESPONDED_VIEW_SUCCESS: {
      const { data, hospitalityTaskId, links } = action.payload;
      const occasionGroupedData = state.notRespondedTaskId || {};
      const currentNotRespondedTaskIds =
        occasionGroupedData[hospitalityTaskId] || [];
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        notRespondedTaskId: {
          ...occasionGroupedData,
          [hospitalityTaskId]: newIds
        },
        notRespondedtaskLinks: links,
        isNotRespondedSearch: false
      };
    }

    case SEARCH_PROVIDED_TASKS_SUCCESS: {
      const { data, hospitalityTaskId, links } = action.payload;
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        providedSearchData: {
          [hospitalityTaskId]: newIds
        },
        providedSearchLinks: links,
        isProvidedSearch: true
      };
    }

    case HOSPITALITY_TASK_PROVIDED_VIEW_SUCCESS: {
      const { data, hospitalityTaskId, links } = action.payload;
      const occasionGroupedData = state.providedTaskId || {};
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        providedTaskId: {
          ...occasionGroupedData,
          [hospitalityTaskId]: newIds
        },
        providedtaskLinks: links,
        isProvidedSearch: false
      };
    }

    case SEARCH_NOTPROVIDED_TASKS_SUCCESS: {
      const { data, hospitalityTaskId, links } = action.payload;
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        notProvidedSearchData: {
          [hospitalityTaskId]: newIds
        },
        notProvidedSearchLinks: links,
        isNotProvidedSearch: true
      };
    }

    case HOSPITALITY_TASK_NOTPROVIDED_VIEW_SUCCESS: {
      const { data, hospitalityTaskId, links } = action.payload;
      const occasionGroupedData = state.notProvidedTaskId || {};
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        notProvidedTaskId: {
          ...occasionGroupedData,
          [hospitalityTaskId]: newIds
        },
        notProvidedtaskLinks: links,
        isNotProvidedSearch: false
      };
    }

    case RESET_PROVIDEDTASKS: {
      return {
        ...state,
        isProvidedSearch: false
      };
    }

    case RESET_NOTPROVIDEDTASKS: {
      return {
        ...state,
        isNotProvidedSearch: false
      };
    }

    case RESET_RESPONDEDTASKS: {
      return {
        ...state,
        isRespondedSearch: false
      };
    }

    case RESET_NOTRESPONDEDTASKS: {
      return {
        ...state,
        isNotRespondedSearch: false
      };
    }

    case HOSPITALITY_REQUESTED_TASK_ANSWER_DELETE_SUCCESS: {
      const { hospitalityTaskId, data } = action.payload;
      const respondedIds = state.respondedTaskId || {};
      const currentRespondedTaskIds = respondedIds[hospitalityTaskId] || [];
      const newIds = currentRespondedTaskIds.filter((id) => id != data.id);
      return {
        ...state,
        respondedTaskId: {
          ...respondedIds,
          [hospitalityTaskId]: newIds
        }
      };
    }

    case HOSPITALITY_TASK_DELETE_FAILED:
    case HOSPITALITY_TASK_VIEW_FAILED:
    case HOSPITALITY_TASK_RESPONDED_VIEW_FAILED:
    case HOSPITALITY_TASK_NOTRESPONDED_VIEW_FAILED:
    case HOSPITALITY_TASK_NOTPROVIDED_VIEW_FAILED:
    case HOSPITALITY_TASK_PROVIDED_VIEW_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};
export default hospitalityTask;
