import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  getTaskPublicAnswerApi,
  updateTaskPublicAnswerApi,
  deleteTaskPublicAnswerApi,
  createTaskPublicAnswerApi
} from '../api/publicAnswer';
import {
  GET_HOSPITALITY_TASK_PUBLIC_ANSWER,
  UPDATE_HOSPITALITY_TASK_PUBLIC_ANSWER,
  CREATE_HOSPITALITY_TASK_PUBLIC_ANSWER,
  DELETE_HOSPITALITY_TASK_PUBLIC_ANSWER
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  getHospitalityTaskPublicAnswerSuccess,
  getHospitalityTaskPublicAnswerFailed,
  updateHospitalityTaskPublicAnswerSuccess,
  updateHospitalityTaskPublicAnswerFailed,
  deleteHospitalityTaskPublicAnswerSuccess,
  deleteHospitalityTaskPublicAnswerFailed,
  createHospitalityTaskPublicAnswerSuccess,
  createHospitalityTaskPublicAnswerFailed
} from '../actions/publicAnswer';

export function* getHospitalityTaskPublicAnswerSaga(action) {
  try {
    const result = yield call(
      getTaskPublicAnswerApi,
      action.payload.ocId,
      action.payload.taskId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getHospitalityTaskPublicAnswerSuccess(
          action.payload.ocId,
          action.payload.taskId,
          result.data,
          result.included,
          result.links
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getHospitalityTaskPublicAnswerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updateHospitalityTaskPublicAnswerSaga(action) {
  try {
    const result = yield call(
      updateTaskPublicAnswerApi,
      action.payload.ocId,
      action.payload.taskId,
      action.payload.answerId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateHospitalityTaskPublicAnswerSuccess(
          action.payload.ocId,
          action.payload.taskId,
          result.data,
          result.included,
          result.links
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(updateHospitalityTaskPublicAnswerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* deleteHospitalityTaskPublicAnswerSaga(action) {
  try {
    const result = yield call(
      deleteTaskPublicAnswerApi,
      action.payload.ocId,
      action.payload.taskId,
      action.payload.answerId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        deleteHospitalityTaskPublicAnswerSuccess(
          action.payload.ocId,
          action.payload.taskId,
          result.data
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteHospitalityTaskPublicAnswerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* createHospitalityTaskPublicAnswerSaga(action) {
  try {
    const result = yield call(
      createTaskPublicAnswerApi,
      action.payload.ocId,
      action.payload.taskId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        createHospitalityTaskPublicAnswerSuccess(
          action.payload.ocId,
          action.payload.taskId,
          result.data,
          result.included
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(createHospitalityTaskPublicAnswerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* publicAnswerActionWatcher() {
  yield takeLatest(
    GET_HOSPITALITY_TASK_PUBLIC_ANSWER,
    progressSaga,
    getHospitalityTaskPublicAnswerSaga
  );
  yield takeLatest(
    UPDATE_HOSPITALITY_TASK_PUBLIC_ANSWER,
    progressSaga,
    updateHospitalityTaskPublicAnswerSaga
  );
  yield takeLatest(
    DELETE_HOSPITALITY_TASK_PUBLIC_ANSWER,
    progressSaga,
    deleteHospitalityTaskPublicAnswerSaga
  );
  yield takeLatest(
    CREATE_HOSPITALITY_TASK_PUBLIC_ANSWER,
    progressSaga,
    createHospitalityTaskPublicAnswerSaga
  );
}
