import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch } from '../../../../../../components/InputGroup/FormComponents';
import { updateTimeTriggerIsPinned } from '../../../../../../actions/trigger';

const TimeTriggerDetailsView = ({
  timeTriggerDetails,
  occasionId,
  fetchProgress,
  updateTimeTriggerIsPinned
}) => {
  const date = moment(timeTriggerDetails?.triggerDateTime);

  const onSubmit = (e) => {
    const data = new FormData();
    data.append('time_trigger[is_pinned]', e ? true : false);

    updateTimeTriggerIsPinned(occasionId, timeTriggerDetails?.id, data);
  };

  return (
    <div className="trigger-details-container">
      <p className="trigger-details">
        <p className="fields">Title:</p>
        <p className="field-data">{timeTriggerDetails?.title}</p>
      </p>
      <p className="trigger-details">
        <p className="fields">Message:</p>
        <p className="field-data">{timeTriggerDetails?.message}</p>
      </p>
      <p className="trigger-details">
        <p className="fields">Trigger Date Time:</p>
        <p className="field-data">{date.format('DD/MM/YYYY hh:mm A')}</p>
      </p>
      <p className="trigger-details">
        <p className="fields">Is Pinnned:</p>
        <p className="field-data">{timeTriggerDetails?.isPinned}</p>
        <Switch
          loading={fetchProgress}
          value={timeTriggerDetails?.isPinned === 'True'}
          style={{ marginLeft: 10 }}
          onChange={(e) => onSubmit(e)}
        />
      </p>
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateTimeTriggerIsPinned }, dispatch);
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeTriggerDetailsView);
