import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import Tabs from '../../../../../../components/Tabs';

import {
  currentOccasionId,
  currentAlbumId
} from '../../../../../../selectors/dynamicData';
import { getCurrentOccasion } from '../../../../../../selectors/occasion';
import { TabsHeader } from '../../../../../../components/TabsHeader';
import { getCurrentAlbum } from '../../../../../../selectors/album';
import AlbumMediaCreate from './createMedia';
import AlbumImageView from './Images/index';
import AlbumVideoView from './Videos/index';
import AlbumDetailsTab from './albumDetails';

function AlbumDetails(props) {
  const getAlbumCreateComponent = () => {
    return (
      <AlbumMediaCreate
        occasionId={props.occasionId}
        albumId={props.currentAlbumId}
      />
    );
  };
  const getAlbumImageComponent = () => {
    return (
      <AlbumImageView
        occasionId={props.occasionId}
        albumId={props.currentAlbumId}
      />
    );
  };
  const getAlbumVideoComponent = () => {
    return (
      <AlbumVideoView
        occasionId={props.occasionId}
        albumId={props.currentAlbumId}
      />
    );
  };

  const tabPanes = [
    {
      tabName: 'Details',
      tabKey: '1',
      children: <AlbumDetailsTab albumDetails={props.currentAlbumData} />
    },
    {
      tabName: 'Create',
      tabKey: '2',
      children: getAlbumCreateComponent()
    },
    {
      tabName: 'Image',
      tabKey: '3',
      children: getAlbumImageComponent()
    },
    {
      tabName: 'Video',
      tabKey: '4',
      children: getAlbumVideoComponent()
    }
  ];
  const goBack = () => {
    props.history.goBack();
  };

  return (
    <ErrorBoundary>
      <SectionContent className="album-section">
        <TabsHeader
          name={props.currentOccasion.name}
          extraData={[
            props.currentOccasion.type,
            props.currentOccasion.guestType,
            'Album',
            props.currentAlbumData.title
          ]}
          wrapperClass="section-details"
        />
        <div className="album-section-container">
          <Tabs
            defaultActiveKey="1"
            tabPanes={tabPanes}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  occasionId: currentOccasionId(store, props),
  currentOccasion: getCurrentOccasion(store, props),
  currentAlbumId: currentAlbumId(store, props),
  currentAlbumData: getCurrentAlbum(store, props)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AlbumDetails);
