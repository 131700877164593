import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import { Button } from 'antd';
import CarouselCreate from './create';
import { occasionCarouselView } from '../../../../../../actions/carousel';
import { getAllCarouselData } from '../../../../../../selectors/carousel';

const CarouselView = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.occasionCarouselView(props.occasionId, page);
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);
  const paginationOptions = {
    showTotal: () => (
      <div className="button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={() => setCreateOpen(true)}>
          Create
        </Button>
      </div>
    )
  };
  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="carousel-view-section">
          <div className="carousel-view-container">
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              isRouted={false}
              onPageChange={onPageChange}
              data={props.carouselData}
              fetchProgress={props.fetchProgress}
              blacklistKeys={['priority', 'carouselSegment']}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <CarouselCreate
        occasionId={props.occasionId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  carouselData: getAllCarouselData(store, props)
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ occasionCarouselView }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(CarouselView);
