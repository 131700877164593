import { createSelector } from 'reselect';
import { currentScheduleId, currentItineraryId } from './dynamicData';
import { userEntities, itineraryEntities } from './entity';
import { itineraryIds } from './static';

export const currentOccasionItineraryIds = createSelector(
  currentScheduleId,
  itineraryIds,
  (scheduleId, ids) => scheduleId && ids && ids[scheduleId]
);
export const getAllOccasionItineraries = createSelector(
  itineraryEntities,
  currentOccasionItineraryIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
export const occasionItineraryWithUserData = createSelector(
  getAllOccasionItineraries,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      createdBy: { ...entities[item?.createdBy?.id] },
      updatedBy: { ...entities[item?.updatedBy?.id] }
    }))
);

export const getCurrentItinerary = createSelector(
  currentItineraryId,
  itineraryEntities,
  (id, entities) => id && entities && entities[id]
);
