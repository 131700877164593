import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import ErrorBoundary from '../../../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../../../components/Table';
import { SectionContent } from '../../../../../../../../components/Section';
import './styles.scss';
import MessagesCreate from './create';
import { getWhatsappMessageByModelable } from '../../../../../../../../actions/whatsappForProvidedTask';
import {
  getWhatsappMessagesWithUserAndTemplateMessageData,
  getUnansweredWhatsappMessagesWithUserAndTemplateMessageData
} from '../../../../../../../../selectors/whatsappForProvidedTask';
import { userAccessType } from '../../../../../../../../selectors/static';

const WhatsAppView = (props) => {
  const [createPage, setCreateOpen] = useState(false);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.getWhatsappMessageByModelable(
      props.occasionId,
      'HospitalityTask',
      props.hospitalityTaskId,
      props.operationType,
      page
    );
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const paginationOptions = {
    showTotal: () => (
      <div className="task-whatsapp-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <div className="message-list-count-container">
          <span>Count</span>
          {!!props.isAnswered ? (
            <span className="count-text">{props.count}</span>
          ) : (
            <span className="count-text">{props.unansweredCount}</span>
          )}
        </div>
        <Button className="create-button" onClick={() => setCreateOpen(true)}>
          Send Bulk Messages
        </Button>
      </div>
    )
  };

  const getData = () => {
    if (!!props.isAnswered) {
      if (props?.whatsappMessageData?.length > 0) {
        return props?.whatsappMessageData?.map((item) => {
          return {
            id: item?.id,
            user: item.user,
            whatsappTemplateMessage: item.whatsappTemplateMessage,
            timeStamp: item.timeStamp,
            messageDelivered: item.messageDelivered,
            messageRead: item.messageRead,
            messageReplied: item.messageReplied,
            messageSent: item.messageSent,
            sentMessageId: item.sentMessageId,
            localMessageId: item.localMessageId,
            userWhatsappReplies: item.userWhatsappReplies
          };
        });
      }
    } else if (!props.isAnswered) {
      if (props?.unansweredWhatsappMessageData?.length > 0) {
        return props?.unansweredWhatsappMessageData?.map((item) => {
          return {
            id: item?.id,
            user: item.user,
            whatsappTemplateMessage: item.whatsappTemplateMessage,
            timeStamp: item.timeStamp,
            messageDelivered: item.messageDelivered,
            messageRead: item.messageRead,
            messageReplied: item.messageReplied,
            messageSent: item.messageSent,
            sentMessageId: item.sentMessageId,
            localMessageId: item.localMessageId,
            userWhatsappReplies: item.userWhatsappReplies
          };
        });
      }
    } else {
      return [];
    }
  };

  const getBlackListData = () => {
    if (
      props.currentUser === 'super_admin' ||
      props.currentUser === 'sociana_internal_user'
    ) {
      return ['userWhatsappReplies'];
    } else {
      return ['userWhatsappReplies', 'localMessageId'];
    }
  };

  if (!createPage) {
    return (
      <ErrorBoundary>
        <SectionContent className="task-whatsapp-view-section">
          <div className="task-whatsapp-view-container">
            <TableView
              isPaginated={true}
              isRouted={false}
              paginationOptions={paginationOptions}
              fetchProgress={props.fetchProgress}
              onPageChange={onPageChange}
              data={getData()}
              paginationLinks={
                !!props.isAnswered
                  ? props.whatsappMessageLinks
                  : props.unansweredWhatsappMessageLinks
              }
              normalTime={true}
              blacklistKeys={getBlackListData()}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <MessagesCreate
      occasionId={props.occasionId}
      hospitalityTaskId={props.hospitalityTaskId}
      operationType={props.operationType}
      userType={props.userType}
      handleCloseClick={() => setCreateOpen(false)}
    />
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  whatsappMessageData: getWhatsappMessagesWithUserAndTemplateMessageData(
    store,
    props
  ),
  whatsappMessageLinks: store.whatsappForProvidedTask.whatsappMessagesLinks,
  count: store.whatsappForProvidedTask.providedCount,
  unansweredWhatsappMessageData: getUnansweredWhatsappMessagesWithUserAndTemplateMessageData(
    store,
    props
  ),
  unansweredWhatsappMessageLinks:
    store.whatsappForProvidedTask.unansweredWhatsappMessagesLinks,
  unansweredCount: store.whatsappForProvidedTask.unansweredCount,
  isAnswered: store.whatsappForProvidedTask.isAnswered,
  currentUser: userAccessType(store, props)
});

const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ getWhatsappMessageByModelable }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(WhatsAppView);
