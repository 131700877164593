import { Input } from 'antd';
import React, { Component } from 'react';
import './styles.scss';

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.clearSearchBox = this.clearSearchBox.bind(this);
    this.searchInput = React.createRef();
    this.state = {
      place: props.address
    };
  }

  componentDidMount({ map, mapApi } = this.props) {
    const options = {
      // restrict your search to a specific type of result
      // types: ['address']
      // restrict your search to a specific country, or an array of countries
      // componentRestrictions: { country: ['gb', 'us'] },
    };
    this.autoComplete = new mapApi.places.Autocomplete(
      this.searchInput.current.input,
      options
    );
    this.autoComplete.addListener('place_changed', this.onPlaceChanged);
    this.autoComplete.bindTo('bounds', map);
    this.searchInput.current.input.value = this.props.address;
  }

  componentDidUpdate(prevProps) {
    this.searchInput.current.input.value = this.props.address;
  }

  componentWillUnmount({ mapApi } = this.props) {
    mapApi.event.clearInstanceListeners(this.searchInput.current.input);
  }

  onPlaceChanged = ({ map, addplace } = this.props) => {
    const place = this.autoComplete.getPlace();

    if (!place.geometry) return;
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }
    this.searchInput.current.setValue(place.formatted_address);
    addplace(place);
  };

  clearSearchBox() {
    this.searchInput.current.input.value = '';
  }

  render() {
    return (
      <div className="autocomplete-container">
        <Input
          name="place"
          className="search-input"
          ref={this.searchInput}
          placeholder="Enter a location"
        />
      </div>
    );
  }
}

export default AutoComplete;
