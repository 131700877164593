import {
  OCCASION_CAROUSEL_CREATE,
  OCCASION_CAROUSEL_CREATE_FAILED,
  OCCASION_CAROUSEL_CREATE_SUCCESS,
  OCCASION_CAROUSEL_VIEW,
  OCCASION_CAROUSEL_VIEW_FAILED,
  OCCASION_CAROUSEL_VIEW_SUCCESS
} from '../constants/actionTypes';

export const occasionCarouselCreate = (ocId, formData, callback) => ({
  type: OCCASION_CAROUSEL_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionCarouselCreateSuccess = (data, ocId, included, links) => ({
  type: OCCASION_CAROUSEL_CREATE_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionCarouselCreateFailed = (error) => ({
  type: OCCASION_CAROUSEL_CREATE_FAILED,
  error
});

export const occasionCarouselView = (ocId, page) => ({
  type: OCCASION_CAROUSEL_VIEW,
  payload: { ocId, page }
});

export const occasionCarouselViewSuccess = (data, ocId, included, links) => ({
  type: OCCASION_CAROUSEL_VIEW_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionCarouselViewFailed = (error) => ({
  type: OCCASION_CAROUSEL_VIEW_FAILED,
  error
});
