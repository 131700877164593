import request from './request';

export function createTaskPublicAnswerApi(ocId, taskId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${taskId}/hospitality_task_public_answers`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function getTaskPublicAnswerApi(ocId, taskId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${taskId}/hospitality_task_public_answers?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function updateTaskPublicAnswerApi(
  ocId,
  taskId,
  answerId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${taskId}/hospitality_task_public_answers/${answerId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function deleteTaskPublicAnswerApi(ocId, taskId, answerId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${taskId}/hospitality_task_public_answers/${answerId}`,
    {
      method: 'DELETE',
      token
    }
  );
}
