import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  CheckCircleTwoTone,
  InfoCircleTwoTone,
  CloseCircleTwoTone,
  WarningTwoTone,
  CloseOutlined
} from '@ant-design/icons';
import Portal from '../Portal';
import { requestHideAlert } from '../../actions/alert';
import './styles.scss';
import { usePrevious } from '../../hooks/usePrevious';

function Alert(props) {
  const [isVisible, setVisibility] = React.useState(false);

  const prevProps = usePrevious(props);

  const showAlert = () => {
    setVisibility(true);
  };

  const hideAlert = () => {
    setVisibility(false);
  };

  React.useEffect(() => {
    // show alert if previously it was hidden and now it wants to be visible
    if (
      prevProps &&
      prevProps.alert &&
      !prevProps.alert.visible &&
      props.alert.visible
    ) {
      showAlert();
    }

    // hide alert if previously it was shown and now it wants to hide
    if (
      prevProps &&
      prevProps.alert &&
      prevProps.alert.visible &&
      !props.alert.visible
    ) {
      hideAlert();
    }
  });

  const getColor = () => {
    switch (props.alert.type) {
      case 'Success':
        return '#52b80c';

      case 'Error':
        return '#ff0000';

      case 'Warning':
        return '#fdc300';
      default:
        return '#006ff0';
    }
  };

  const getIcon = () => {
    switch (props.alert.type) {
      case 'Success':
        return (
          <CheckCircleTwoTone twoToneColor="#52b80c" className="icon-style" />
        );

      case 'Error':
        return (
          <CloseCircleTwoTone twoToneColor="#ff0000" className="icon-style" />
        );

      case 'Warning':
        return <WarningTwoTone twoToneColor="#fdc300" className="icon-style" />;
      default:
        return (
          <InfoCircleTwoTone twoToneColor="#006ff0" className="icon-style" />
        );
    }
  };

  return (
    <Portal id="alert">
      <div
        className="alert-container"
        testId="alert-container"
        style={{
          transition: '1s transform ease-in-out',
          transform:
            !props.alert.dismissible || isVisible
              ? 'translateX(0px)'
              : 'translateX(400px)'
        }}>
        <span
          className="alert-left-band"
          testId="alert-left-band"
          style={{
            backgroundColor: getColor()
          }}
        />
        <div className="icon-container-style" testId="alert-icon">
          {getIcon()}
        </div>
        <div
          className="alert-type-and-message-container"
          testId="alert-type-and-message-container">
          <p className="alert-type" testId="alert-type">
            {props.alert?.type}
          </p>
          <p className="alert-message" testId="alert-message">
            {typeof props.alert?.message === 'string'
              ? props.alert?.message
              : 'Something went wrong!'}
          </p>
        </div>
        <div className="close-icon-container">
          <CloseOutlined
            testId="close-button"
            onClick={hideAlert}
            className="close-icon"
          />
        </div>
      </div>
    </Portal>
  );
}

const mapStateToProps = (store, props) => ({
  alert: store.browserState.alert
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestHideAlert
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
