import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  createEventPostApi,
  deleteEventApi,
  deleteEventPostApi,
  editEventPostApi,
  eventAffairCreateApi,
  eventAffairDeleteApi,
  eventAffairUpdateApi,
  eventAffairViewApi,
  eventScheduleCreateApi,
  eventScheduleDeleteApi,
  eventScheduleUpdateApi,
  eventScheduleViewApi,
  eventSearchApi,
  getEventMembersApi,
  getEventPendingInvitationApi,
  getEventPendingRegistrationApi,
  multipleEventInvitationsApi,
  occasionEventCreateApi,
  occasionEventViewApi,
  parse_csvEventApi,
  updateOccasionEventApi,
  viewEventPostApi
} from '../api/event';
import {
  CREATE_EVENT_POST,
  DELETE_EVENT,
  DELETE_EVENT_POST,
  EDIT_EVENT_POST,
  EVENT_AFFAIR_CREATE,
  EVENT_AFFAIR_DELETE,
  EVENT_AFFAIR_UPDATE,
  EVENT_AFFAIR_VIEW,
  EVENT_COLLABORATOR_CREATE,
  EVENT_COLLABORATOR_DELETE,
  EVENT_COLLABORATOR_SEARCH,
  EVENT_COLLABORATOR_UPDATE,
  EVENT_COLLABORATOR_VIEW,
  EVENT_SCHEDULE_CREATE,
  EVENT_SCHEDULE_DELETE,
  EVENT_SCHEDULE_UPDATE,
  EVENT_SCHEDULE_VIEW,
  GET_EVENT_MEMBERS,
  GET_EVENT_PENDING_INVITATION,
  GET_EVENT_PENDING_REGISTRATION,
  GET_EVENT_POST_PAGINATION,
  GET_EVENT_SEARCH,
  MULTIPLE_EVENT_INVITATIONS,
  OCCASION_EVENT_CREATE,
  OCCASION_EVENT_UPDATE,
  OCCASION_EVENT_VIEW,
  PARSE_CSV_EVENT
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  occasionEventCreateSuccess,
  occasionEventCreateFailed,
  occasionEventViewSuccess,
  occasionEventViewFailed,
  occasionEventUpdateSuccess,
  occasionEventUpdateFailed,
  getEventSearchSuccess,
  getEventSearchFailed,
  deleteEventSuccess,
  deleteEventFailed,
  multipleEventInvitationsSuccess,
  multipleEventInvitationsFailed,
  parse_csvEventSuccess,
  parse_csvEventFailed,
  getEventMembersSuccess,
  getEventMembersFailed,
  getEventPendingInvitationSuccess,
  getEventPendingInvitationFailed,
  getEventPendingRegistrationSuccess,
  getEventPendingRegistrationFailed,
  eventScheduleCreateSuccess,
  eventScheduleCreateFailed,
  eventScheduleViewSuccess,
  eventScheduleViewFailed,
  eventScheduleUpdateSuccess,
  eventScheduleUpdateFailed,
  eventScheduleDeleteSuccess,
  eventScheduleDeleteFailed,
  eventAffairCreateSuccess,
  eventAffairCreateFailed,
  eventAffairViewSuccess,
  eventAffairViewFailed,
  eventAffairUpdateSuccess,
  eventAffairUpdateFailed,
  eventAffairDeleteSuccess,
  eventAffairDeleteFailed,
  createEventPostSuccess,
  createEventPostFailed,
  getEventPostWithPaginationSuccess,
  getEventPostWithPaginationFailed,
  editEventPostSuccess,
  editEventPostFailed,
  deleteEventPostSuccess,
  deleteEventPostFailed
} from '../actions/event';
import {
  eventCollaboratorCreateApi,
  eventCollaboratorDeleteApi,
  eventCollaboratorSearchApi,
  eventCollaboratorUpdateApi,
  eventCollaboratorViewApi
} from '../api/eventCollaborator';
import {
  eventCollaboratorCreateFailed,
  eventCollaboratorCreateSuccess,
  eventCollaboratorDeleteFailed,
  eventCollaboratorDeleteSuccess,
  eventCollaboratorSearchFailed,
  eventCollaboratorSearchSuccess,
  eventCollaboratorUpdateFailed,
  eventCollaboratorUpdateSuccess,
  eventCollaboratorViewFailed,
  eventCollaboratorViewSuccess
} from '../actions/eventCollaborator';

export function* occasionEventCreateSaga(action) {
  try {
    const result = yield call(
      occasionEventCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(occasionEventCreateSuccess(result.data, action.payload.ocId));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionEventCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionEventCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionEventViewSaga(action) {
  try {
    const result = yield call(
      occasionEventViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionEventViewSuccess(result.data, action.payload.ocId, result.links)
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionEventViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionEventUpdateSaga(action) {
  try {
    const result = yield call(
      updateOccasionEventApi,
      action.payload.ocId,
      action.payload.eventId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(occasionEventUpdateSuccess(result.data));
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionEventUpdateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Some error occured', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionEventUpdateFailed(error));
    yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
  }
}

export function* getEventSearchSaga(action) {
  try {
    const result = yield call(
      eventSearchApi,
      action.payload.ocId,
      action.payload.title,
      action.payload.startDate,
      action.payload.endDate,
      action.payload.guestType,
      action.payload.tag,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getEventSearchSuccess(result.data, action.payload.ocId, result.links)
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getEventSearchFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* eventDeleteSaga(action) {
  try {
    const result = yield call(
      deleteEventApi,
      action.payload.ocId,
      action.payload.eventId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(deleteEventSuccess(action.payload.ocId, result.data));
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteEventFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* multipelEventInvitationSaga(action) {
  try {
    const result = yield call(
      multipleEventInvitationsApi,
      action.payload.ocId,
      action.payload.eventId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        multipleEventInvitationsSuccess(action.payload.eventId, result.data)
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(multipleEventInvitationsFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(multipleEventInvitationsFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}
export function* parse_csvEventSaga(action) {
  try {
    const result = yield call(
      parse_csvEventApi,
      action.payload.ocId,
      action.payload.eventId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(parse_csvEventSuccess(action.payload.eventId, result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(parse_csvEventFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(parse_csvEventFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getEventMembersSaga(action) {
  try {
    const result = yield call(
      getEventMembersApi,
      action.payload.ocId,
      action.payload.eventId,
      action.payload.page,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getEventMembersSuccess(
          action.payload.eventId,
          result.data,
          result.links,
          result.included
        )
      );

      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getEventMembersFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}
export function* getEventPendingInvitationSaga(action) {
  try {
    const result = yield call(
      getEventPendingInvitationApi,
      action.payload.ocId,
      action.payload.eventId,
      action.payload.page,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getEventPendingInvitationSuccess(
          action.payload.eventId,
          result.data,
          result.links,
          result.included
        )
      );

      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getEventPendingInvitationFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getEventPendingRegistrationSaga(action) {
  try {
    const result = yield call(
      getEventPendingRegistrationApi,
      action.payload.ocId,
      action.payload.eventId,
      action.payload.page,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getEventPendingRegistrationSuccess(
          action.payload.eventId,
          result.data,
          result.links,
          result.included
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getEventPendingRegistrationFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventScheduleCreateSaga(action) {
  try {
    const result = yield call(
      eventScheduleCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventScheduleCreateSuccess(
          action.payload.ocId,
          action.payload.eventId,
          result.data,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(eventScheduleCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(eventScheduleCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventScheduleViewSaga(action) {
  try {
    const result = yield call(
      eventScheduleViewApi,
      action.payload.ocId,
      action.payload.scheduleType,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventScheduleViewSuccess(
          action.payload.ocId,
          action.payload.eventId,
          result.data,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(eventScheduleViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventScheduleUpdateSaga(action) {
  try {
    const result = yield call(
      eventScheduleUpdateApi,
      action.payload.ocId,
      action.payload.scheduleId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventScheduleUpdateSuccess(
          action.payload.ocId,
          action.payload.eventId,
          result.data,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(eventScheduleUpdateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(eventScheduleUpdateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventScheduleDeleteSaga(action) {
  try {
    const result = yield call(
      eventScheduleDeleteApi,
      action.payload.ocId,
      action.payload.scheduleId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventScheduleDeleteSuccess(
          action.payload.ocId,
          action.payload.eventId,
          result.data,
          result.included,
          result.links
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(eventScheduleDeleteFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventAffairCreateSaga(action) {
  try {
    const result = yield call(
      eventAffairCreateApi,
      action.payload.ocId,
      action.payload.formData,
      action.payload.eventId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventAffairCreateSuccess(
          action.payload.ocId,
          action.payload.eventId,
          result.data,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(eventAffairCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(eventAffairCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventAffairViewSaga(action) {
  try {
    const result = yield call(
      eventAffairViewApi,
      action.payload.ocId,
      action.payload.page,
      action.payload.eventId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventAffairViewSuccess(
          action.payload.ocId,
          action.payload.eventId,
          result.data,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(eventAffairViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventAffairUpdateSaga(action) {
  try {
    const result = yield call(
      eventAffairUpdateApi,
      action.payload.ocId,
      action.payload.eventAffairId,
      action.payload.eventId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventAffairUpdateSuccess(
          action.payload.ocId,
          action.payload.eventId,
          result.data,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(eventAffairUpdateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(eventAffairUpdateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventAffairDeleteSaga(action) {
  try {
    const result = yield call(
      eventAffairDeleteApi,
      action.payload.ocId,
      action.payload.eventAffairId,
      action.payload.eventId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventAffairDeleteSuccess(
          action.payload.ocId,
          action.payload.eventId,
          result.data,
          result.included,
          result.links
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(eventAffairDeleteFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventCollaboratorCreateSaga(action) {
  try {
    const result = yield call(
      eventCollaboratorCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventCollaboratorCreateSuccess(
          action.payload.ocId,
          action.payload.eventId,
          result.data,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(eventCollaboratorCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(eventCollaboratorCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventCollaboratorViewSaga(action) {
  try {
    const result = yield call(
      eventCollaboratorViewApi,
      action.payload.ocId,
      action.payload.page,
      action.payload.eventId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventCollaboratorViewSuccess(
          action.payload.ocId,
          action.payload.eventId,
          result.data,
          result.included,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(eventCollaboratorViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventCollaboratorSearchSaga(action) {
  try {
    const result = yield call(
      eventCollaboratorSearchApi,
      action.payload.ocId,
      action.payload.page,
      action.payload.eventId,
      action.payload.memberData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventCollaboratorSearchSuccess(
          action.payload.ocId,
          action.payload.eventId,
          result.data,
          result.included,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(eventCollaboratorSearchFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventCollaboratorUpdateSaga(action) {
  try {
    const result = yield call(
      eventCollaboratorUpdateApi,
      action.payload.ocId,
      action.payload.eventCollaboratorId,
      action.payload.eventId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventCollaboratorUpdateSuccess(
          action.payload.ocId,
          action.payload.eventId,
          result.data,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(eventCollaboratorUpdateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(eventCollaboratorUpdateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventCollaboratorDeleteSaga(action) {
  try {
    const result = yield call(
      eventCollaboratorDeleteApi,
      action.payload.ocId,
      action.payload.eventCollaboratorId,
      action.payload.eventId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventCollaboratorDeleteSuccess(
          action.payload.ocId,
          action.payload.eventId,
          result.data,
          result.included,
          result.links
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(eventCollaboratorDeleteFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* createEventPostSaga(action) {
  try {
    const result = yield call(
      createEventPostApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (typeof action.callback === 'function') {
      action.callback();
    }
    if (isValidResult(result)) {
      yield put(
        createEventPostSuccess(
          action.payload.ocId,
          action.payload.eventId,
          result.data,
          result.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
    } else if (result && result.meta) {
      yield put(createEventPostFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(createEventPostFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getEventPostsWithPaginationSaga(action) {
  try {
    const result = yield call(
      viewEventPostApi,
      action.payload.ocId,
      action.payload.eventId,
      action.payload.pageNumber,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getEventPostWithPaginationSuccess(
          action.payload.ocId,
          action.payload.eventId,
          result.data,
          result?.links,
          result?.included
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getEventPostWithPaginationFailed(error));

    yield put(requestAutoHideAlert(error, 'Error', 4000));
  }
}

export function* editEventPostSaga(action) {
  try {
    const result = yield call(
      editEventPostApi,
      action.payload.occasionId,
      action.payload.postableType,
      action.payload.postId,
      action.payload.formData,
      getUserToken()
    );
    if (typeof action.callback === 'function') {
      action.callback();
    }
    if (isValidResult(result)) {
      yield put(
        editEventPostSuccess(
          action.payload.occasionId,
          result.data,
          result.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
    }
  } catch (error) {
    yield put(editEventPostFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* deleteEventPostSaga(action) {
  try {
    const result = yield call(
      deleteEventPostApi,
      action.payload.ocId,

      action.payload.postableType,
      action.payload.postId,
      getUserToken()
    );
    if (typeof action.callback === 'function') {
      action.callback();
    }
    if (isValidResult(result)) {
      yield put(
        deleteEventPostSuccess(
          action.payload.ocId,
          action.payload.eventId,
          action.payload.postableType,
          result.data,
          result.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
    }
  } catch (error) {
    yield put(deleteEventPostFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* eventActionWatcher() {
  yield takeLatest(CREATE_EVENT_POST, progressSaga, createEventPostSaga);
  yield takeLatest(
    GET_EVENT_POST_PAGINATION,
    progressSaga,
    getEventPostsWithPaginationSaga
  );
  yield takeLatest(EDIT_EVENT_POST, progressSaga, editEventPostSaga);
  yield takeLatest(DELETE_EVENT_POST, progressSaga, deleteEventPostSaga);

  yield takeLatest(
    OCCASION_EVENT_CREATE,
    progressSaga,
    occasionEventCreateSaga
  );
  yield takeLatest(OCCASION_EVENT_VIEW, progressSaga, occasionEventViewSaga);
  yield takeLatest(
    OCCASION_EVENT_UPDATE,
    progressSaga,
    occasionEventUpdateSaga
  );
  yield takeLatest(GET_EVENT_SEARCH, progressSaga, getEventSearchSaga);
  yield takeLatest(DELETE_EVENT, progressSaga, eventDeleteSaga);
  yield takeLatest(
    MULTIPLE_EVENT_INVITATIONS,
    progressSaga,
    multipelEventInvitationSaga
  );
  yield takeLatest(PARSE_CSV_EVENT, progressSaga, parse_csvEventSaga);
  yield takeLatest(GET_EVENT_MEMBERS, progressSaga, getEventMembersSaga);
  yield takeLatest(
    GET_EVENT_PENDING_INVITATION,
    progressSaga,
    getEventPendingInvitationSaga
  );
  yield takeLatest(
    GET_EVENT_PENDING_REGISTRATION,
    progressSaga,
    getEventPendingRegistrationSaga
  );
  yield takeLatest(
    EVENT_SCHEDULE_CREATE,
    progressSaga,
    eventScheduleCreateSaga
  );
  yield takeLatest(EVENT_SCHEDULE_VIEW, progressSaga, eventScheduleViewSaga);
  yield takeLatest(
    EVENT_SCHEDULE_UPDATE,
    progressSaga,
    eventScheduleUpdateSaga
  );
  yield takeLatest(
    EVENT_SCHEDULE_DELETE,
    progressSaga,
    eventScheduleDeleteSaga
  );
  yield takeLatest(EVENT_AFFAIR_CREATE, progressSaga, eventAffairCreateSaga);
  yield takeLatest(EVENT_AFFAIR_VIEW, progressSaga, eventAffairViewSaga);
  yield takeLatest(EVENT_AFFAIR_UPDATE, progressSaga, eventAffairUpdateSaga);
  yield takeLatest(EVENT_AFFAIR_DELETE, progressSaga, eventAffairDeleteSaga);

  yield takeLatest(
    EVENT_COLLABORATOR_CREATE,
    progressSaga,
    eventCollaboratorCreateSaga
  );
  yield takeLatest(
    EVENT_COLLABORATOR_VIEW,
    progressSaga,
    eventCollaboratorViewSaga
  );
  yield takeLatest(
    EVENT_COLLABORATOR_UPDATE,
    progressSaga,
    eventCollaboratorUpdateSaga
  );
  yield takeLatest(
    EVENT_COLLABORATOR_DELETE,
    progressSaga,
    eventCollaboratorDeleteSaga
  );
  yield takeLatest(
    EVENT_COLLABORATOR_SEARCH,
    progressSaga,
    eventCollaboratorSearchSaga
  );
}
