import { createSelector } from 'reselect';
import { occasionWhiteListEntities } from './entity';
import { occasionWhiteListIds } from './static';
import { currentOccasionId, currentWhiteListId } from './dynamicData';

export const currentOccasionWhiteListIds = createSelector(
  currentOccasionId,
  occasionWhiteListIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getAllOccasionWhiteListData = createSelector(
  occasionWhiteListEntities,
  currentOccasionWhiteListIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const currentOccasionWhiteListData = createSelector(
  currentWhiteListId,
  occasionWhiteListEntities,
  (id, entity) => id && entity && entity[id]
);
