import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import Tabs from '../../../../../components/Tabs';
import { currentOccasionId } from '../../../../../selectors/dynamicData';
import ActivityTrackerDetailsView from './details';
import PresentUserView from './PresentUser/view';
import AbsentUserView from './AbsentUser/view';
import { currentActivityTrackerData } from '../../../../../selectors/activityTracker';
import {
  setCurrentTabIndex,
  resetTabIndex
} from '../../../../../actions/tabKeys';
import { getCurrentOccasion } from '../../../../../selectors/occasion';
import { TabsHeader } from '../../../../../components/TabsHeader';

function ActivityTrackerDetails(props) {
  const [tabKey, setTabKey] = useState(1);
  const onChange = (key) => {
    setTabKey(key);
    props.setCurrentTabIndex(key, 'activity_tracker');
  };

  const tabPanes = [
    {
      tabName: 'Details',
      tabKey: '1',
      children: (
        <ActivityTrackerDetailsView
          activityTrackerDetails={props.currentActivityTrackerData}
          occasionId={props.occasionId}
          occasionName={props.currentOccasion.name}
        />
      )
    },
    {
      tabName: 'Present Users',
      tabKey: '2',
      children: (
        <PresentUserView
          activityTrackerId={props.currentActivityTrackerData?.id}
          occasionId={props.occasionId}
        />
      )
    },
    {
      tabName: 'Absent Users',
      tabKey: '3',
      children: (
        <AbsentUserView
          activityTrackerId={props.currentActivityTrackerData?.id}
          occasionId={props.occasionId}
        />
      )
    }
  ];
  const goBack = () => {
    props.resetTabIndex('activity_tracker');
    props.history.goBack();
  };
  return (
    <ErrorBoundary>
      <SectionContent className="activity-tracker-tab-section">
        <TabsHeader
          name={props.currentOccasion.name}
          extraData={[
            props.currentOccasion.type,
            props.currentOccasion.guestType,
            props.currentActivityTrackerData.name
          ]}
          wrapperClass="section-details"
        />
        <div className="activity-tracker-tab-container">
          <Tabs
            onChange={onChange}
            defaultActiveKey={props.activeTabKey}
            tabPanes={tabPanes}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  activeTabKey: store.tabKeys.activity_tracker,
  fetchProgress: store.browserState.fetchProgress,
  occasionId: currentOccasionId(store, props),
  currentOccasion: getCurrentOccasion(store, props),
  currentActivityTrackerData: currentActivityTrackerData(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setCurrentTabIndex, resetTabIndex }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityTrackerDetails);
