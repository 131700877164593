import React, { useState } from 'react';
import {
  Card,
  Carousel,
  Image,
  Tag,
  Modal,
  Badge,
  Typography,
  Popconfirm,
  Dropdown,
  Menu,
  Tooltip
} from 'antd';
import {
  HeartFilled,
  CommentOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { Dot } from '../Dot';
import ReactPlayer from 'react-player';
import { getImageSource } from '../../helpers/imageHelper';
import './styles.scss';
import CardImage from '../Table/CardImage';
import { COLOR_SET } from '../../constants/OtherConstants';
import { getRandomValueFromArray } from '../../helpers/occasionHelper';
import Likes from '../../containers/PageRoutes/Occasion/Details/Reports/Likes';
import Comments from '../../containers/PageRoutes/Occasion/Details/Reports/comments';
import ReportedBy from './reportedBy';
import { Avatar } from '../Avatar';

const { Paragraph } = Typography;

const PostCard = ({
  id,
  pictureUrl = [],
  cdnPictureUrl = [],
  hashTag = '',
  message = '',
  postedByDetails = {},
  reports = [],
  likeCount = 0,
  totalComment = 0,
  videoUrls = [],
  cdnVideoUrls = [],
  occasionId,
  disableLike,
  disableComment,
  onDelete,
  onEdit,
  createdAt = '',
  events = {}
}) => {
  const [modalType, setModalType] = useState('');
  const [images, setImages] = useState(pictureUrl);
  const [videos, setVideos] = useState(videoUrls);

  const showDropdown =
    typeof onDelete === 'function' || typeof onEdit === 'function';

  const menu = (
    <Menu className="post-dropdown-menu">
      {typeof onDelete === 'function' && (
        <Popconfirm
          title="Are you sure to delete this?"
          onConfirm={() => onDelete(id)}
          okText="Yes"
          cancelText="No">
          <Menu.Item icon={<DeleteOutlined className="delete-icon" />}>
            Delete
          </Menu.Item>
        </Popconfirm>
      )}
      {typeof onEdit === 'function' && (
        <Menu.Item
          icon={<EditOutlined className="edit-icon" />}
          onClick={() => onEdit(id)}>
          Edit
        </Menu.Item>
      )}
    </Menu>
  );

  const CardTitle = () => {
    return (
      <div className="card-title">
        <div className="card-image-and-name">
          <Avatar
            avatarUrl={postedByDetails.avatarUrl}
            name={postedByDetails?.name}
            className="post-avatar"
          />
          <div className="user-name">
            <div className="user-name-text1">
              <Tooltip
                overlayClassName="post-tooltip"
                placement="bottom"
                title={postedByDetails.name}>
                <span className="post-tooltip-span">
                  {postedByDetails.name}
                </span>
              </Tooltip>
              <Dot size={5} color="#179e8a" />
              <span className="user-createdAt-span">{createdAt}</span>

              {events?.title && (
                <Tooltip placement="top" title={events?.title}>
                  <span className="event-info-span">
                    <ExclamationCircleOutlined />
                  </span>
                </Tooltip>
              )}
            </div>
            {!!postedByDetails.roleName && (
              <span className="card-details-user-rolename">
                {postedByDetails.roleName}
              </span>
            )}
          </div>
        </div>
        {showDropdown && (
          <Dropdown overlay={menu} trigger={['click']}>
            <MoreOutlined />
          </Dropdown>
        )}
      </div>
    );
  };

  const handleImageLoadError = () => {
    setImages(cdnPictureUrl);
  };

  const handleVideoLoadError = () => {
    setVideos(cdnVideoUrls);
  };

  const displayLike = () => {
    if (!disableLike) {
      setModalType('likes');
    }
  };

  const displayComment = () => {
    if (!disableComment) {
      setModalType('comments');
    }
  };

  return (
    <>
      <Card className="post-card" title={<CardTitle />}>
        {pictureUrl?.length > 0 && (
          <Carousel className="carousel-style">
            {images?.map((url) => (
              <Image
                src={getImageSource(url, null, false)}
                onError={handleImageLoadError}
              />
            ))}
          </Carousel>
        )}
        {videoUrls?.length > 0 && (
          <Carousel className="carousel-style">
            {videos?.map((url) => (
              <ReactPlayer
                url={`${process.env.REACT_APP_CLIENT_URL}${url}`}
                width="100%"
                onError={handleVideoLoadError}
                controls={true}
              />
            ))}
          </Carousel>
        )}
        {message && (
          <div className="paragraph-container">
            {message.split('\n').map(function (item) {
              return (
                <span>
                  {item}
                  <br />
                </span>
              );
            })}
          </div>
        )}
        <div className="icons-container">
          <div>
            {hashTag &&
              hashTag?.split(' ')?.map((el) => (
                <Tag
                  color={getRandomValueFromArray(COLOR_SET)}
                  className="hashtags">
                  {el}
                </Tag>
              ))}
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', marginRight: 10 }}>
              <HeartFilled
                className="icon"
                onClick={displayLike}
                style={{
                  color: '#00A189',
                  opacity: disableLike ? 0.5 : 1,
                  cursor: disableLike ? 'not-allowed' : 'pointer'
                }}
              />
              <span>{likeCount}</span>
            </div>
            <div style={{ display: 'flex' }}>
              <CommentOutlined
                className="icon"
                onClick={displayComment}
                style={{
                  color: '#00A189',
                  opacity: disableComment ? 0.5 : 1,
                  cursor: disableComment ? 'not-allowed' : 'pointer'
                }}
              />
              <span>{totalComment}</span>
            </div>
          </div>
        </div>
        {reports?.length > 0 && <ReportedBy reportedBy={reports} />}
      </Card>
      <Modal
        className="modal-container"
        footer={null}
        visible={modalType.length > 0}
        onCancel={() => setModalType('')}>
        {modalType === 'likes' ? (
          <Likes postId={id} occasionId={occasionId} />
        ) : (
          <Comments postId={id} occasionId={occasionId} />
        )}
      </Modal>
    </>
  );
};

export default PostCard;
