import CookieService from '../services/CookieService';

export const checkIfUserIsAuthenticated = () => {
  const userToken = CookieService.get('user_token');
  if (userToken) {
    return true;
  }
  return false;
};

export const removeAuthCookie = () => {
  CookieService.remove('user_token', { path: '/' });
};

export const rememberUser = (result, remember) => {
  const expiresAt = 60 * 24 * 30;
  const date = new Date();
  date.setTime(date.getTime() + expiresAt * 60 * 1000);
  // if user clicks rememberMe cookie expires in 30 days otherwise it is set to browser session
  const options = { path: '/', expires: remember ? date : null };
  CookieService.set('user_token', result.meta.token, options);
};

export const getUserToken = () => {
  try {
    return CookieService.get('user_token');
  } catch (error) {
    console.error('Error in Cookie Service', error);
    return null;
  }
};

export const getDataFromFile = (file) => {
  const data = {
    name: file.name,
    dateModified: file.lastModifiedDate,
    uid: file.uid
  };
  return data;
};
