import {
  ACTIVITY_TRACKER_ABSENT_USER_VIEW,
  ACTIVITY_TRACKER_ABSENT_USER_VIEW_FAILED,
  ACTIVITY_TRACKER_ABSENT_USER_VIEW_SUCCESS
} from '../constants/actionTypes';

export const activityTrackerAbsentUserView = (ocId, trackerId, page) => ({
  type: ACTIVITY_TRACKER_ABSENT_USER_VIEW,
  payload: { ocId, trackerId, page }
});

export const activityTrackerAbsentUserViewSuccess = (
  data,
  included,
  ocId,
  trackerId,
  links,
  meta
) => ({
  type: ACTIVITY_TRACKER_ABSENT_USER_VIEW_SUCCESS,
  payload: { data, included, ocId, trackerId, links, meta }
});

export const activityTrackerAbsentUserViewFailed = (error) => ({
  type: ACTIVITY_TRACKER_ABSENT_USER_VIEW_FAILED,
  error
});
