import request from './request';

export function codeCreateApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/occasion_codes`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function codeViewApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_codes?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function deletecodeApi(ocId, codeId, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/occasion_codes/${codeId}`, {
    method: 'DELETE',
    token
  });
}

export function updatecodeApi(ocId, codeId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/occasion_codes/${codeId}`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}
