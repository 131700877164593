import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { Form, Checkbox, Collapse } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';

import MultiSelect from './MultiSelect';
import {
  getOccasionPendingInvitation,
  getOccasionPendingRegistration,
  searchPendingInvitation,
  searchPendingRegistrations
} from '../../../../../../actions/Invitation';
import {
  allPendingInvitationsWithUserData,
  allPendingRegistrationWithUserData,
  allSearchedPendingInvitationsWithUserData,
  getAllSearchedOccasionsPendingRegistration
} from '../../../../../../selectors/invitation';
import { getUserByOccasion, searchUsers } from '../../../../../../actions/user';
import {
  getAllUser,
  getCompleteUserData,
  getSearchedUserData
} from '../../../../../../selectors/user';
import { sendEmailSaver } from '../../../../../../actions/notifications';

const SendEmail = (props) => {
  const [existingUsers, setExistingUsersDetails] = React.useState({
    name: '',
    selectAll: false
  });
  const [invitedUsers, setInvitedUsersDetails] = React.useState({
    name: '',
    selectAll: false
  });
  const [unregisteredUsers, setUnregisteredUsersDetails] = React.useState({
    name: '',
    selectAll: false
  });

  const [selectedExistingUsers, setSelectedExistingUsers] = useState([]);
  const [selectedInvitedUsers, setSelectedInvitedUsers] = useState([]);
  const [selectedUnregisteredUsers, setSelectedUnregisteredUsers] = useState(
    []
  );

  const [errorMessages, setErrorMessages] = React.useState({});

  const addGroupMembers = () => {
    try {
      const formData = new FormData();
      if (
        !existingUsers.selectAll &&
        !invitedUsers.selectAll &&
        !unregisteredUsers.selectAll &&
        selectedExistingUsers.length === 0 &&
        selectedInvitedUsers.length === 0 &&
        selectedUnregisteredUsers.length === 0
      )
        return;

      if (existingUsers.selectAll) {
        formData.append('recipients[users]', 'all');
      } else {
        selectedExistingUsers.forEach((element) => {
          formData.append('recipients[users][]', element.email);
        });
      }

      if (invitedUsers.selectAll) {
        formData.append('recipients[pending_invitations]', 'all');
      } else {
        selectedInvitedUsers.forEach((element) => {
          formData.append('recipients[pending_invitations][]', element.email);
        });
      }

      if (unregisteredUsers.selectAll) {
        formData.append('recipients[pending_registrations]', 'all');
      } else {
        selectedUnregisteredUsers.forEach((element) => {
          formData.append(
            'recipients[pending_registrations][]',
            element.emailOrMobile
          );
        });
      }

      props.sendEmailSaver(props.occasionId, props.emailId, formData, callback);
    } catch (error) {
      console.error(error);
    }
  };

  const callback = () => {
    setExistingUsersDetails({
      name: '',
      selectAll: false
    });
    setInvitedUsersDetails({
      name: '',
      selectAll: false
    });
    setUnregisteredUsersDetails({
      name: '',
      selectAll: false
    });
    setSelectedExistingUsers([]);
    setSelectedInvitedUsers([]);
    setSelectedUnregisteredUsers([]);
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    if (name === 'existing') {
      setExistingUsersDetails((prev) => ({
        ...prev,
        selectAll: value
      }));
    }
    if (name === 'invited') {
      setInvitedUsersDetails((prev) => ({
        ...prev,
        selectAll: value
      }));
    }
    if (name === 'unregistered') {
      setUnregisteredUsersDetails((prev) => ({
        ...prev,
        selectAll: value
      }));
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="send-email-section">
        <div className="send-email-create">
          <Form
            className="send-email-form-container"
            onFinish={addGroupMembers}>
            <Collapse>
              <Collapse.Panel header="Select Existing Users">
                <div className="send-email-checkbox-selector">
                  <Checkbox
                    name="existing"
                    onChange={handleInputChange}
                    checked={existingUsers.selectAll}
                    className="checkbox-input">
                    Send All existing users
                  </Checkbox>
                  <div
                    style={{
                      display: existingUsers.selectAll ? 'none' : 'initial'
                    }}>
                    <MultiSelect
                      users={existingUsers}
                      allUsers={props.users}
                      searchedUsers={props.searchedUsers}
                      errorMessages={errorMessages}
                      fetchPage={props.getUserByOccasion}
                      fetchSearchDetails={props.searchUsers}
                      params={[props.occasionId]}
                      setErrorMessages={setErrorMessages}
                      setUsers={setExistingUsersDetails}
                      links={props.usersLinks}
                      searchedUsersLinks={props.searchedUsersLinks}
                      selectedUsers={selectedExistingUsers}
                      setSelectedUsers={setSelectedExistingUsers}
                    />
                  </div>
                </div>
              </Collapse.Panel>

              <Collapse.Panel header="Select Invited Users">
                <div className="send-email-checkbox-selector">
                  <Checkbox
                    name="invited"
                    onChange={handleInputChange}
                    checked={invitedUsers.selectAll}
                    className="checkbox-input">
                    Send All invited users
                  </Checkbox>
                  <div
                    style={{
                      display: invitedUsers.selectAll ? 'none' : 'initial'
                    }}>
                    <MultiSelect
                      users={invitedUsers}
                      allUsers={props.pendingInvitations}
                      searchedUsers={props.searchAllPendingInvitations}
                      errorMessages={errorMessages}
                      fetchPage={props.getOccasionPendingInvitation}
                      fetchSearchDetails={props.searchPendingInvitation}
                      params={[props.occasionId]}
                      setErrorMessages={setErrorMessages}
                      setUsers={setInvitedUsersDetails}
                      links={props.pendingInvitationLink}
                      searchedUsersLinks={props.searchPendingInvitationLink}
                      selectedUsers={selectedInvitedUsers}
                      setSelectedUsers={setSelectedInvitedUsers}
                    />
                  </div>
                </div>
              </Collapse.Panel>

              <Collapse.Panel header="Select Unregistered Users">
                <div className="send-email-checkbox-selector">
                  <Checkbox
                    name="unregistered"
                    onChange={handleInputChange}
                    checked={unregisteredUsers.selectAll}
                    className="checkbox-input">
                    Send All unregistered users
                  </Checkbox>
                  <div
                    style={{
                      display: unregisteredUsers.selectAll ? 'none' : 'initial'
                    }}>
                    <MultiSelect
                      users={unregisteredUsers}
                      allUsers={props.pendingRegistrations}
                      searchedUsers={props.searchAllPendingRegistrations}
                      errorMessages={errorMessages}
                      fetchPage={props.getOccasionPendingRegistration}
                      fetchSearchDetails={props.searchPendingRegistrations}
                      params={[props.occasionId]}
                      setErrorMessages={setErrorMessages}
                      setUsers={setUnregisteredUsersDetails}
                      links={props.pendingRegistrationLink}
                      searchedUsersLinks={props.searchPendingRegistrationLink}
                      selectedUsers={selectedUnregisteredUsers}
                      setSelectedUsers={setSelectedUnregisteredUsers}
                    />
                  </div>
                </div>
              </Collapse.Panel>
            </Collapse>

            <SubmitButton
              buttonText="Add Members"
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  users: getCompleteUserData(store, props),
  usersLinks: store.user.links,
  searchedUsers: getSearchedUserData(store, props),
  searchedUsersLinks: store.user.searchedLinks,
  pendingInvitations: allPendingInvitationsWithUserData(store, props),
  pendingInvitationLink: store.invitation.pendingInvitationLink,
  searchAllPendingInvitations: allSearchedPendingInvitationsWithUserData(
    store,
    props
  ),
  searchPendingInvitationLink: store.invitation.searchPendingInvitationLink,
  pendingRegistrations: allPendingRegistrationWithUserData(store, props),
  pendingRegistrationLink: store.invitation.pendingRegistrationLink,
  searchAllPendingRegistrations: getAllSearchedOccasionsPendingRegistration(
    store,
    props
  ),
  searchPendingRegistrationLink: store.invitation.searchPendingRegistrationLink
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserByOccasion,
      searchUsers,
      getOccasionPendingInvitation,
      getOccasionPendingRegistration,
      searchPendingRegistrations,
      searchPendingInvitation,
      sendEmailSaver
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SendEmail);
