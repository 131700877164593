import request from './request';

export function occasionTabSettingCreateApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_tab_setting/occasion_tab_settings`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function occasionTabSettingViewApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_tab_setting/occasion_tab_settings`,
    {
      method: 'GET',
      token
    }
  );
}

export function updateOccasionTabSettingApi(
  ocId,
  occasionTabSettingId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_tab_setting/occasion_tab_settings/${occasionTabSettingId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}
