import request from './request';

export function createNewAdmin(formData, token) {
  return request('api/v2/sociana_admin/sociana_admins', {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function getActiveAdminPagination(page, token) {
  return request(
    `api/v2/sociana_admin/get_active_sociana_admins?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function getInactiveAdminPagination(page, token) {
  return request(
    `api/v2/sociana_admin/get_inactive_sociana_admins?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function getCurrentAdminOccasionApi(page, adminId, adminStatus, token) {
  return request(
    `api/v2/sociana_admin/sociana_admin_occasions?page=${page}&sociana_admin_id=${adminId}&sociana_admin_status=${adminStatus}`,
    {
      method: 'GET',
      token
    }
  );
}

export function createAdminOccasionApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/sociana_admin_occasions`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function updateAdmin(adminId, formData, token) {
  return request(`api/v2/sociana_admin/sociana_admins/${adminId}`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function getAdminBySearchApi(searchData, status, page, token) {
  return request(
    `api/v2/sociana_admin/sociana_admin_search?page=${page}&q=${searchData}&current_status=${
      status ? 'active' : 'inactive'
    }`,
    {
      method: 'GET',
      token
    }
  );
}

export function deleteCurrentAdminOccasionApi(ocId, adminOccasionId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/sociana_admin_occasions/${adminOccasionId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function updateAdminOccasionApi(ocId, adminOccasionId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/sociana_admin_occasions/${adminOccasionId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}
