import React from 'react';
import { Image } from 'antd';
import { ZoomInOutlined, EditOutlined } from '@ant-design/icons';
import { getImageSource } from '../../../../../../helpers/imageHelper';

const AlbumDetailsTab = ({ albumDetails }) => {
  return (
    <div className="album-details-container">
      <p className="album-details card-details">
        <p className="fields">Image:</p>
        <p className="field-data card-image-container">
          <Image
            src={getImageSource(albumDetails?.albumCardImageUrl)}
            preview={{
              maskClassName: 'preview-style',
              mask: <ZoomInOutlined />
            }}
            className="image-style"
          />
        </p>
      </p>
      <p className="album-details">
        <p className="fields">Title:</p>
        <p className="field-data">{albumDetails?.title}</p>
      </p>
    </div>
  );
};
export default AlbumDetailsTab;
