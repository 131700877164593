export const getEmailFormData = (data) => {
  const formData = new FormData();
  formData.append('email_saver[sender]', data?.sender);
  formData.append('email_saver[name]', data?.name);
  formData.append('email_saver[subject]', data?.subject);
  formData.append('email_saver[valediction]', data?.valediction);

  const imageLinks = data?.imageLinks?.split(',').filter((el) => el);

  if (imageLinks && imageLinks.length > 0) {
    imageLinks.forEach((link) => {
      formData.append('email_saver[image_attachment_links][]', link);
    });
  }

  const fileLinks = data?.fileLinks?.split(',').filter((el) => el);

  if (fileLinks && fileLinks.length > 0) {
    fileLinks.forEach((link) => {
      formData.append('email_saver[file_url_links][]', link);
    });
  }

  const body = data?.body?.split('\n').filter((item) => item);

  if (body && body.length > 0) {
    body.forEach((el) => {
      formData.append('email_saver[body][]', el);
    });
  }

  return formData;
};
