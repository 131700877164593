import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import { Button, Tooltip } from 'antd';
import {
  faceInfoMediaView,
  regenerateAllGallaryMediaFaceInfo,
  regenerateFaceInfo
} from '../../../../../../actions/faceInfo';
import { getAllFaceInfoMedais } from '../../../../../../selectors/faceInfo';

const ViewFaceInfoMedia = (props) => {
  const [page, setPage] = React.useState(1);

  const onPageChange = (pageNumber) => {
    setPage(pageNumber);
    props.faceInfoMediaView(props.occasionId, 'Media', pageNumber);
  };

  const handleRegenerate = () => {
    props.regenerateAllGallaryMediaFaceInfo(props.occasionId);
  };

  const location = useLocation();

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleAction = (id) => {
    props.regenerateFaceInfo(props.occasionId, 'Media', id);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="face-info-media-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={handleRegenerate}>
          Regenerate All Gallary Media
        </Button>
        <div className="count-box">
          <span>Total Media</span>
          <span className="count">{props.count}</span>
        </div>
      </div>
    )
  };

  const customAction = (id) => {
    return (
      <Tooltip placement="right" title={'Regenerate Face Info'}>
        <Button className="action-button" onClick={() => handleAction(id)}>
          <SmileOutlined />
        </Button>
      </Tooltip>
    );
  };

  const getData = () => {
    if (props.mediaData?.length > 0) {
      return props?.mediaData?.map((item) => {
        return {
          id: item?.id,
          fileUrl: item?.thumbUrl ? item?.thumbUrl : item?.fileUrl,
          name: item?.name,
          createdAt: item?.createdAt,
          kind: item?.kind,
          faceInfo: item?.faceInfo
        };
      });
    } else {
      return [];
    }
  };
  console.log(props.mediaData);
  return (
    <ErrorBoundary>
      <SectionContent className="face-info-media-view-section">
        <div className="face-info-media-view-container">
          <TableView
            isPaginated={true}
            pathname={`${location.pathname}/media/view`}
            paginationOptions={paginationOptions}
            isRouted={true}
            data={getData()}
            onPageChange={onPageChange}
            fetchProgress={props?.fetchProgress}
            paginationLinks={props?.mediaLinks}
            normalTime={true}
            customAction={customAction}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  mediaData: getAllFaceInfoMedais(store, props),
  mediaLinks: store.faceInfo.faceInfoMediaLinks,
  count: store.faceInfo.faceInfoMediaCount
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    {
      faceInfoMediaView,
      regenerateAllGallaryMediaFaceInfo,
      regenerateFaceInfo
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(ViewFaceInfoMedia);
