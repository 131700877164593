import React from 'react';
import './styles.scss';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  PhoneOutlined,
  LoadingOutlined
} from '@ant-design/icons';

export const WatiCard = ({ id, data, accessDataFromKey }) => {
  const Data = React.useMemo(() => {
    if (id && data) {
      return data.find((el) => el.id === id);
    }
    return null;
  }, [id, data]);

  // header

  const renderHeader = () => {
    if (!!accessDataFromKey) {
      if (
        Data.whatsappTemplateMessage.header &&
        Object.keys(Data.whatsappTemplateMessage.header).length > 0
      ) {
        if (Data.whatsappTemplateMessage.header.typeString === 'video') {
          return (
            <>
              <div className="wati-card-header-media-box">
                <video
                  src={Data.whatsappTemplateMessage.header.mediaFromPC}
                  controls
                />
              </div>
            </>
          );
        } else if (Data.whatsappTemplateMessage.header.typeString === 'image') {
          return (
            <>
              <div className="wati-card-header-media-box">
                <img
                  src={
                    'https://m.greaterkashmir.com/sortd-service/imaginary/v22-01/jpg/large/high?url=Z3JlYXRlcmthc2htaXItc29ydGQtcHJvLXByb2Qtc29ydGQvbWVkaWE3MDhkMjk5MC04YjA5LTExZWUtYjRjZi1kZjI5ODA0ZmNiOWIuanBn'
                  }
                />
              </div>
            </>
          );
        } else if (Data.whatsappTemplateMessage.header.typeString === 'text') {
          return (
            <>
              {Data.whatsappTemplateMessage.header.text ? (
                <p className="wati-card-header-text-contant">
                  {Data.whatsappTemplateMessage.header.text}
                </p>
              ) : null}
            </>
          );
        }
      }
      return null;
    } else if (!accessDataFromKey) {
      if (Data.header && Object.keys(Data.header).length > 0) {
        if (Data.header.typeString === 'video') {
          return (
            <>
              <div className="wati-card-header-media-box">
                <video src={Data.header.mediaFromPC} controls />
              </div>
            </>
          );
        } else if (Data.header.typeString === 'image') {
          return (
            <>
              <div className="wati-card-header-media-box">
                <img
                  src={
                    'https://m.greaterkashmir.com/sortd-service/imaginary/v22-01/jpg/large/high?url=Z3JlYXRlcmthc2htaXItc29ydGQtcHJvLXByb2Qtc29ydGQvbWVkaWE3MDhkMjk5MC04YjA5LTExZWUtYjRjZi1kZjI5ODA0ZmNiOWIuanBn'
                  }
                />
              </div>
            </>
          );
        } else if (Data.header.typeString === 'text') {
          return (
            <>
              {Data.header.text ? (
                <p className="wati-card-header-text-contant">
                  {Data.header.text}
                </p>
              ) : null}
            </>
          );
        }
      }
      return null;
    }
  };

  // body
  const renderBody = () => {
    if (!!accessDataFromKey) {
      if (Data.whatsappTemplateMessage.body) {
        let customParams = [];
        Data.whatsappTemplateMessage.customParams.map((item) => {
          const kstr1 = item.split(',');
          const kstr2 = kstr1[0].split('=>"');
          const kstr3 = kstr2[1].split('"');

          const vstr2 = kstr1[1].split('=>"');
          const vstr3 = vstr2[1].split('"');
          const obj = { paramName: kstr3[0], paramValue: vstr3[0] };
          customParams.push(obj);
        });

        const replacedTextone = Data.whatsappTemplateMessage.body.replace(
          /{{(.*?)}}/g,
          (match, paramName) => {
            const param = customParams.find((p) => p.paramName === paramName);
            return param ? param.paramValue : match;
          }
        );
        return (
          <pre className="wati-card-body-text-contant">{replacedTextone} </pre>
        );
      }
    } else if (!accessDataFromKey) {
      if (Data.bodyOriginal) {
        const replacedText = Data.bodyOriginal.replace(
          /{{(.*?)}}/g,
          (match, paramName) => {
            const param = Data.customParams.find(
              (p) => p.paramName === paramName
            );
            return param ? param.paramValue : match;
          }
        );
        return (
          <pre className="wati-card-body-text-contant">{replacedText} </pre>
        );
      }
    }
  };

  // button
  const renderButton = () => {
    if (!!accessDataFromKey) {
      if (Data.whatsappTemplateMessage.buttonType) {
        if (Data.whatsappTemplateMessage.buttonType === 'call_to_action') {
          return (
            <div className="wati-card-url-button-box">
              {Data.whatsappTemplateMessage?.buttons?.map((item) => {
                let str1 = item.split(',');
                let buttonType = str1[0].split('"=>"')[1].split('"')[0];
                let buttonName = str1[1].split('"=>"')[1].split('"')[0];
                if (buttonType === 'url') {
                  return (
                    <button className="wati-card-button-contant">
                      <SyncOutlined className="wati-card-button-icon" />
                      {buttonName}
                    </button>
                  );
                } else if (buttonType === 'call') {
                  return (
                    <button className="wati-card-button-contant">
                      <PhoneOutlined className="wati-card-button-icon" />
                      {buttonName}
                    </button>
                  );
                }
              })}
            </div>
          );
        } else if (Data.whatsappTemplateMessage.buttonType === 'quick_reply') {
          return (
            <div className="wati-card-quick_reply-button-box">
              {Data.whatsappTemplateMessage?.buttons?.map((item) => {
                let str1 = item.split(',');
                let buttonType = str1[0].split('"=>"')[1].split('"')[0];
                let buttonName = str1[1].split('"=>"')[1].split('"')[0];
                return (
                  <button
                    className={'wati-card-button-quick_reply-row-contant'}>
                    {buttonName}
                  </button>
                );
              })}
            </div>
          );
        }
      }
      return null;
    } else if (!accessDataFromKey) {
      if (Data.buttonsType) {
        if (Data.buttonsType === 'call_to_action') {
          return (
            <div className="wati-card-url-button-box">
              {Data?.buttons?.map((item) => {
                if (item.type === 'url') {
                  return (
                    <button className="wati-card-button-contant">
                      <SyncOutlined className="wati-card-button-icon" />
                      {item.parameter.text}
                    </button>
                  );
                } else if (item.type === 'call') {
                  return (
                    <button className="wati-card-button-contant">
                      <PhoneOutlined className="wati-card-button-icon" />
                      {item.parameter.text}
                    </button>
                  );
                }
              })}
            </div>
          );
        } else if (Data.buttonsType === 'quick_reply') {
          return (
            <div className="wati-card-quick_reply-button-box">
              {Data?.buttons?.map((item, index) => {
                return (
                  <button
                    className={
                      index === 2
                        ? 'wati-card-button-quick_reply-coloum-contant'
                        : 'wati-card-button-quick_reply-row-contant'
                    }>
                    {item.parameter.text}
                  </button>
                );
              })}
            </div>
          );
        }
      }
      return null;
    }
  };

  return (
    <>
      {Data && (
        <div className="wati-card-container">
          {renderHeader()}
          {renderBody()}
          {!!accessDataFromKey && Data.whatsappTemplateMessage.footer ? (
            <p className="wati-card-footer-text-contant">
              {Data.whatsappTemplateMessage.footer}
              <span>
                {new Date().toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false
                })}
              </span>
            </p>
          ) : null}
          {!accessDataFromKey && Data.footer ? (
            <p className="wati-card-footer-text-contant">
              {Data.footer}{' '}
              <span>
                {new Date().toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false
                })}
              </span>
            </p>
          ) : null}
          {!!accessDataFromKey &&
            Data.whatsappTemplateMessage.buttonType &&
            Data.whatsappTemplateMessage.buttonType !== 'none' && (
              <hr style={{ color: 'rgb(199, 195, 195)' }} />
            )}
          {!accessDataFromKey &&
            Data.buttonsType &&
            Data.buttonsType !== 'none' && (
              <hr style={{ color: 'rgb(199, 195, 195)' }} />
            )}
          {renderButton()}
        </div>
      )}
    </>
  );
};
