import {
  CREATE_OCCASION_WHITE_LIST,
  CREATE_OCCASION_WHITE_LIST_FAILED,
  CREATE_OCCASION_WHITE_LIST_SUCCESS,
  CREATE_OCCASION_WHITE_LIST_BY_CSV,
  CREATE_OCCASION_WHITE_LIST_BY_CSV_FAILED,
  CREATE_OCCASION_WHITE_LIST_BY_CSV_SUCCESS,
  DELETE_OCCASION_WHITE_LIST,
  DELETE_OCCASION_WHITE_LIST_FAILED,
  DELETE_OCCASION_WHITE_LIST_SUCCESS,
  SEARCH_OCCASION_WHITE_LIST,
  SEARCH_OCCASION_WHITE_LIST_FAILED,
  SEARCH_OCCASION_WHITE_LIST_SUCCESS,
  GET_OCCASION_WHITE_LIST,
  GET_OCCASION_WHITE_LIST_FAILED,
  GET_OCCASION_WHITE_LIST_SUCCESS,
  CREATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE,
  CREATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_FAILED,
  CREATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_SUCCESS,
  UPDATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE,
  UPDATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_FAILED,
  UPDATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_SUCCESS
} from '../constants/actionTypes';

export const createOccasionWhiteList = (ocId, formData, callback) => ({
  type: CREATE_OCCASION_WHITE_LIST,
  payload: { ocId, formData },
  callback
});

export const createOccasionWhiteListSuccess = (
  data,
  ocId,
  included,
  links
) => ({
  type: CREATE_OCCASION_WHITE_LIST_SUCCESS,
  payload: { data, ocId, included, links }
});

export const createOccasionWhiteListFailed = (error) => ({
  type: CREATE_OCCASION_WHITE_LIST_FAILED,
  error
});

export const updateOccasionWhiteListWithDealerCode = (
  ocId,
  whiteListId,
  formData,
  callback
) => ({
  type: UPDATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE,
  payload: { ocId, whiteListId, formData },
  callback
});

export const updateOccasionWhiteListWithDealerCodeSuccess = (
  data,
  ocId,
  included
) => ({
  type: UPDATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_SUCCESS,
  payload: { data, ocId, included }
});

export const updateOccasionWhiteListWithDealerCodeFailed = (error) => ({
  type: UPDATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_FAILED,
  error
});

export const createOccasionWhiteListWithDealerCode = (
  ocId,
  formData,
  callback
) => ({
  type: CREATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE,
  payload: { ocId, formData },
  callback
});

export const createOccasionWhiteListWithDealerCodeSuccess = (
  data,
  ocId,
  included,
  links
) => ({
  type: CREATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_SUCCESS,
  payload: { data, ocId, included, links }
});

export const createOccasionWhiteListWithDealerCodeFailed = (error) => ({
  type: CREATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_FAILED,
  error
});

export const createOccasionWhiteListBycsv = (ocId, formData, callback) => ({
  type: CREATE_OCCASION_WHITE_LIST_BY_CSV,
  payload: { ocId, formData },
  callback
});

export const createOccasionWhiteListBycsvSuccess = (
  data,
  ocId,
  included,
  links
) => ({
  type: CREATE_OCCASION_WHITE_LIST_BY_CSV_SUCCESS,
  payload: { data, ocId, included, links }
});

export const createOccasionWhiteListBycsvFailed = (error) => ({
  type: CREATE_OCCASION_WHITE_LIST_BY_CSV_FAILED,
  error
});

export const getOccasionWhiteList = (ocId, page) => ({
  type: GET_OCCASION_WHITE_LIST,
  payload: { ocId, page }
});

export const getOccasionWhiteListSuccess = (
  data,
  ocId,
  included,
  links,
  meta
) => ({
  type: GET_OCCASION_WHITE_LIST_SUCCESS,
  payload: { data, ocId, included, links, meta }
});

export const getOccasionWhiteListFailed = (error) => ({
  type: GET_OCCASION_WHITE_LIST_FAILED,
  error
});

export const deleteOccasionWhiteList = (ocId, listId, callback) => ({
  type: DELETE_OCCASION_WHITE_LIST,
  payload: { ocId, listId },
  callback
});

export const deleteOccasionWhiteListSuccess = (ocId, data) => ({
  type: DELETE_OCCASION_WHITE_LIST_SUCCESS,
  payload: { ocId, data }
});

export const deleteOccasionWhiteListFailed = (error) => ({
  type: DELETE_OCCASION_WHITE_LIST_FAILED,
  error
});

export const searchOccasionWhiteList = (ocId, page, text) => ({
  type: SEARCH_OCCASION_WHITE_LIST,
  payload: { ocId, page, text }
});

export const searchOccasionWhiteListSuccess = (
  data,
  ocId,
  included,
  links,
  meta
) => ({
  type: SEARCH_OCCASION_WHITE_LIST_SUCCESS,
  payload: { data, ocId, included, links, meta }
});

export const searchOccasionWhiteListFailed = (error) => ({
  type: SEARCH_OCCASION_WHITE_LIST_FAILED,
  error
});
