import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import Tabs from '../../../../../components/Tabs';
import { currentOccasionId } from '../../../../../selectors/dynamicData';
import GroupDetailsView from './GroupDetails';
import GroupMembersView from '../../../GroupMembers/View';
import GroupMembersCreate from '../../../GroupMembers/Create';
import { getCurrentGroup } from '../../../../../selectors/group';
import {
  setCurrentTabIndex,
  resetTabIndex
} from '../../../../../actions/tabKeys';
import { getCurrentOccasion } from '../../../../../selectors/occasion';
import { TabsHeader } from '../../../../../components/TabsHeader';

function GroupDetails(props) {
  const [tabKey, setTabKey] = useState(1);
  const onChange = (key) => {
    setTabKey(key);
    props.setCurrentTabIndex(key, 'groups');
  };

  const tabPanes = [
    {
      tabName: 'Details',
      tabKey: '1',
      children: (
        <GroupDetailsView
          groupDetails={props.currentGroup}
          occasionId={props.occasionId}
        />
      )
    },
    {
      tabName: 'View Members',
      tabKey: '2',
      children: (
        <GroupMembersView
          groupId={props.currentGroup.id}
          occasionId={props.occasionId}
        />
      )
    },
    {
      tabName: 'Add Members',
      tabKey: '3',
      children: (
        <GroupMembersCreate
          groupId={props.currentGroup.id}
          occasionId={props.occasionId}
          tabKey={tabKey}
        />
      )
    }
  ];
  const goBack = () => {
    props.resetTabIndex('groups');
    props.history.goBack();
  };
  return (
    <ErrorBoundary>
      <SectionContent className="occasion-group-tab-section">
        <TabsHeader
          name={props.currentOccasion.name}
          extraData={[
            props.currentOccasion.type,
            props.currentOccasion.guestType,
            props.currentGroup.name
          ]}
          wrapperClass="section-details"
        />
        <div className="occasion-group-tab-container">
          <Tabs
            onChange={onChange}
            defaultActiveKey={props.activeTabKey}
            tabPanes={tabPanes}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  activeTabKey: store.tabKeys.groups,
  fetchProgress: store.browserState.fetchProgress,
  occasionId: currentOccasionId(store, props),
  currentOccasion: getCurrentOccasion(store, props),
  currentGroup: getCurrentGroup(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setCurrentTabIndex, resetTabIndex }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetails);
