import request from './request';

export function occasionPollPostCreateApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/poll_post/poll_posts`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function occasionPollPostViewApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/poll_post/poll_posts?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function occasionPollPostViewByTypeApi(ocId, type, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/poll_post/get_poll_post_by_question_type?page=${page}&question_type=${type}`,
    {
      method: 'GET',
      token
    }
  );
}

export function deletePollPostApi(ocId, pollPostId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/poll_post/poll_posts/${pollPostId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function updatePollPostApi(ocId, pollPostId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/poll_post/poll_posts/${pollPostId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function downloadPollPostByCsvApi(ocId, pollPostId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/poll_post/csv_export_poll_post_answers.csv?poll_post_id=${pollPostId}`,
    {
      method: 'GET',
      token,
      isBlob: true
    }
  );
}
