import { union } from 'lodash';
import {
  EVENT_AFFAIR_FOOD_VIEW_SUCCESS,
  EVENT_AFFAIR_FOOD_CREATE_SUCCESS,
  EVENT_AFFAIR_FOOD_VIEW_FAILED,
  EVENT_AFFAIR_FOOD_CREATE_FAILED,
  EVENT_AFFAIR_FOOD_DELETE_SUCCESS,
  EVENT_AFFAIR_FOOD_DELETE_FAILED
} from '../constants/actionTypes';

const initialState = {
  eventAffairFoodData: null,
  eventAffairFoodLinks: null,
  error: null
};

const eventAffairFood = (state = initialState, action) => {
  switch (action.type) {
    case EVENT_AFFAIR_FOOD_VIEW_SUCCESS: {
      const { ocId, eventId, eventAffairId, data, links } = action.payload;
      const occasionGroupedData = state.eventAffairFoodData || {};
      const currentEventFoodNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        eventAffairFoodData: {
          ...occasionGroupedData,
          [eventAffairId]: [...currentEventFoodNewIds]
        },
        eventAffairFoodLinks: links
      };
    }

    case EVENT_AFFAIR_FOOD_CREATE_SUCCESS: {
      const { ocId, eventId, eventAffairId, data } = action.payload;
      const occasionGroupedData = state.eventAffairFoodData || {};
      const currentEventAffairFoodIds =
        occasionGroupedData[eventAffairId] || [];

      const newIds = [data.id, ...currentEventAffairFoodIds];
      return {
        ...state,
        eventAffairFoodData: {
          ...occasionGroupedData,
          [eventAffairId]: newIds
        }
      };
    }

    case EVENT_AFFAIR_FOOD_DELETE_SUCCESS: {
      const { ocId, eventId, eventAffairId, data } = action.payload;
      const occasionGroupedData = state.eventAffairFoodData || {};
      const currentEventAffairFoodIds =
        occasionGroupedData[eventAffairId] || [];

      const filterIds = currentEventAffairFoodIds.filter(
        (id) => id !== data.id
      );
      return {
        ...state,
        eventAffairFoodData: {
          ...occasionGroupedData,
          [eventAffairId]: filterIds
        }
      };
    }

    case EVENT_AFFAIR_FOOD_VIEW_FAILED:
    case EVENT_AFFAIR_FOOD_CREATE_FAILED:
    case EVENT_AFFAIR_FOOD_DELETE_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default eventAffairFood;
