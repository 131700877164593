import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  createOccasionWhiteListApi,
  createOccasionWhiteListBycsvApi,
  createOccasionWhiteListWithDealerCodeApi,
  deleteOccasionWhiteListApi,
  getOccasionWhiteListApi,
  searchOccasionWhiteListApi,
  updateOccasionWhiteListWithDealerCodeApi
} from '../api/occasionWhiteList';
import {
  CREATE_OCCASION_WHITE_LIST,
  CREATE_OCCASION_WHITE_LIST_BY_CSV,
  CREATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE,
  DELETE_OCCASION_WHITE_LIST,
  GET_OCCASION_WHITE_LIST,
  SEARCH_OCCASION_WHITE_LIST,
  UPDATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  createOccasionWhiteListBycsvFailed,
  createOccasionWhiteListBycsvSuccess,
  createOccasionWhiteListFailed,
  createOccasionWhiteListSuccess,
  createOccasionWhiteListWithDealerCodeFailed,
  createOccasionWhiteListWithDealerCodeSuccess,
  deleteOccasionWhiteListFailed,
  deleteOccasionWhiteListSuccess,
  getOccasionWhiteListFailed,
  getOccasionWhiteListSuccess,
  searchOccasionWhiteListFailed,
  searchOccasionWhiteListSuccess,
  updateOccasionWhiteListWithDealerCodeFailed,
  updateOccasionWhiteListWithDealerCodeSuccess
} from '../actions/occasionWhiteList';

export function* createOccasionWhiteListSaga(action) {
  try {
    const result = yield call(
      createOccasionWhiteListApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        createOccasionWhiteListSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(createOccasionWhiteListFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(createOccasionWhiteListFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* createOccasionWhiteListWithDealerCodeSaga(action) {
  try {
    const result = yield call(
      createOccasionWhiteListWithDealerCodeApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        createOccasionWhiteListWithDealerCodeSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(
        createOccasionWhiteListWithDealerCodeFailed(result.meta.message)
      );
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(createOccasionWhiteListWithDealerCodeFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* createOccasionWhiteListBycsvSaga(action) {
  try {
    const result = yield call(
      createOccasionWhiteListBycsvApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        createOccasionWhiteListBycsvSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(createOccasionWhiteListBycsvFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(createOccasionWhiteListBycsvFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getOccasionWhiteListSaga(action) {
  try {
    const result = yield call(
      getOccasionWhiteListApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getOccasionWhiteListSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getOccasionWhiteListFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* searchOccasionWhiteListSaga(action) {
  try {
    const result = yield call(
      searchOccasionWhiteListApi,
      action.payload.ocId,
      action.payload.page,
      action.payload.text,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        searchOccasionWhiteListSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(searchOccasionWhiteListFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* deleteOccasionWhiteListSaga(action) {
  try {
    const result = yield call(
      deleteOccasionWhiteListApi,
      action.payload.ocId,
      action.payload.listId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        deleteOccasionWhiteListSuccess(action.payload.ocId, result.data)
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteOccasionWhiteListFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updateOccasionWhiteListWithDealerCodeSaga(action) {
  try {
    const result = yield call(
      updateOccasionWhiteListWithDealerCodeApi,
      action.payload.ocId,
      action.payload.whiteListId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateOccasionWhiteListWithDealerCodeSuccess(
          action.payload.ocId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(
        updateOccasionWhiteListWithDealerCodeFailed(result.meta.message)
      );
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateOccasionWhiteListWithDealerCodeFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* occasionWhiteListActionWatcher() {
  yield takeLatest(
    CREATE_OCCASION_WHITE_LIST,
    progressSaga,
    createOccasionWhiteListSaga
  );
  yield takeLatest(
    CREATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE,
    progressSaga,
    createOccasionWhiteListWithDealerCodeSaga
  );
  yield takeLatest(
    UPDATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE,
    progressSaga,
    updateOccasionWhiteListWithDealerCodeSaga
  );
  yield takeLatest(
    CREATE_OCCASION_WHITE_LIST_BY_CSV,
    progressSaga,
    createOccasionWhiteListBycsvSaga
  );
  yield takeLatest(
    GET_OCCASION_WHITE_LIST,
    progressSaga,
    getOccasionWhiteListSaga
  );
  yield takeLatest(
    SEARCH_OCCASION_WHITE_LIST,
    progressSaga,
    searchOccasionWhiteListSaga
  );
  yield takeLatest(
    DELETE_OCCASION_WHITE_LIST,
    progressSaga,
    deleteOccasionWhiteListSaga
  );
}
