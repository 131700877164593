import { createSelector } from 'reselect';
import { occasionRoleNameEntities } from './entity';
import { occasionRoleNameIds } from './static';
import { currentOccasionId, currentRoleNameId } from './dynamicData';

export const currentRoleNameIds = createSelector(
  currentOccasionId,
  occasionRoleNameIds,
  (hcId, ids) => hcId && ids && ids[hcId]
);

export const getAllRoleNameData = createSelector(
  occasionRoleNameEntities,
  currentRoleNameIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const currentRoleNameData = createSelector(
  currentRoleNameId,
  occasionRoleNameEntities,
  (id, entity) => id && entity && entity[id]
);
