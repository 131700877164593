import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { checkIfUserIsAuthenticated } from '../../helpers/authHelper';

function ProtectedRouter({ component: Component, ...rest }) {
  const isAuthenticated = checkIfUserIsAuthenticated();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return <Component {...props} />;
        }
        return <Redirect to={{ pathname: '/signin' }} />;
      }}
    />
  );
}

export default ProtectedRouter;
