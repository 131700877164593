import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import AutoComplete from './AutoComplete';
import Marker from './Marker';

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapApiLoaded: false,
      mapInstance: null,
      mapApi: null,
      geoCoder: null,
      places: [],
      center: [],
      zoom: 9,
      address: '',
      draggable: true,
      lat: null,
      lng: null,
      locationAccess: false
    };
  }

  componentDidMount() {
    if (navigator.geolocation) {
      const success = () => {
        this.enableLocationAccess();
      };
      const error = (err) => {
        console.error(`ERROR(${err.code}): ${err.message}`);
      };
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
      navigator.geolocation.getCurrentPosition(success, error, options);
    } else {
      alert('Sorry Not available!');
    }
  }

  enableLocationAccess = () => {
    this.setState({
      locationAccess: true
    });
  };

  componentWillMount() {
    const lat = this.props.value?.lat;
    const lng = this.props.value?.lng;
    this.setCurrentLocation(lat, lng);
  }

  onMarkerInteraction = (childKey, childProps, mouse) => {
    this.setState({
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng
    });
  };
  onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    this.setState({ draggable: true });
    this._generateAddress();
  };

  _onChange = ({ center, zoom }) => {
    this.setState({
      center: center,
      zoom: zoom
    });
  };

  _onClick = (value) => {
    this.setState({
      lat: value.lat,
      lng: value.lng
    });
  };

  apiHasLoaded = ({ map, maps }) => {
    this.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps
    });

    this._generateAddress();
  };

  addPlace = (place) => {
    this.setState({
      places: [place],
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    });
    this._generateAddress();
  };

  _generateAddress() {
    const { mapApi } = this.state;
    const geocoder = new mapApi.Geocoder();
    geocoder.geocode(
      { location: { lat: this.state.lat, lng: this.state.lng } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 12;
            this.setState({ address: results[0].formatted_address }, () => {
              this.props.onChange(
                {
                  address: this.state.address,
                  lat: this.state.lat,
                  lng: this.state.lng
                },
                'venue'
              );
              if (typeof this.props?.setVenue === 'function') {
                this.props.setVenue(this.state.address);
              }
            });
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }

  // Get Current Location Coordinates
  setCurrentLocation(lat, lng) {
    if (lat && lng) {
      this.setState({
        center: [parseFloat(lat), parseFloat(lng)],
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      });
    } else if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({
          center: [
            this.state.lat || position.coords.latitude,
            this.state.lng || position.coords.longitude
          ],
          lat: this.state.lat || position.coords.latitude,
          lng: this.state.lng || position.coords.longitude
        });
      });
    }
  }

  render() {
    const {
      places,
      mapApiLoaded,
      mapInstance,
      mapApi,
      locationAccess
    } = this.state;

    return locationAccess ? (
      <div className={this.props.className}>
        {mapApiLoaded && (
          <AutoComplete
            map={mapInstance}
            mapApi={mapApi}
            addplace={this.addPlace}
            address={this.state.address}
          />
        )}
        <GoogleMapReact
          center={this.state.center}
          zoom={this.state.zoom}
          draggable={this.state.draggable}
          onChange={this._onChange}
          onChildMouseDown={this.onMarkerInteraction}
          onChildMouseUp={this.onMarkerInteractionMouseUp}
          onChildMouseMove={this.onMarkerInteraction}
          onClick={this._onClick}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            libraries: ['places', 'geometry']
          }}
          onGoogleApiLoaded={this.apiHasLoaded}
          yesIWantToUseGoogleMapApiInternals>
          <Marker
            text={this.state.address}
            position={{ lat: this.state.lat, lng: this.state.lng }}
            lat={this.state.lat}
            lng={this.state.lng}
          />
        </GoogleMapReact>
      </div>
    ) : (
      <div>Please enable location to access the map</div>
    );
  }
}
export default Map;
