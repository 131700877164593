import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import Form from 'antd/lib/form/Form';
import { getCurrentCollaboratorDetails } from '../../../../../selectors/socianaInternalUser';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup';
import { capitalize } from '../../../../../helpers/stringHelper';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import { editInternalUserAccessSpecifications } from '../../../../../actions/socianaInternalUser';
import { currentOccasionId } from '../../../../../selectors/dynamicData';
import { adminData } from '../../../../../selectors/auth';

const RowExpandable = (props) => {
  const [isEditOpen, setEditOpen] = React.useState(false);
  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);
  const collabortorType = props.currentCollaboratorDetails?.type;
  const collabortorRoleName = props.currentCollaboratorDetails?.roleName;
  const [assignForm, setAssignForm] = React.useState({
    accessType: collabortorType || '',
    rolename: collabortorRoleName || ''
  });

  const toggleEditClick = () => {
    setEditOpen(!isEditOpen);
    setAssignForm({
      accessType: collabortorType || '',
      rolename: collabortorRoleName || ''
    });
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (isInitiallyDisabled) {
          setInitiallyDisabled(false);
        }
        if (type === 'accessType') {
          value = e;
          name = type;
        }
        const assignFormValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        setAssignForm({
          ...assignForm,
          [inputName]: assignFormValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onFormSubmit = () => {
    try {
      const formData = new FormData();
      formData.append('internal_team[type]', capitalize(assignForm.accessType));
      formData.append('internal_team[role_name]', assignForm.rolename);
      const userId = props.currentCollaboratorDetails?.userId;
      const occasionId = props.currentCollaboratorDetails?.occasionId;
      const entityType =
        props.currentCollaboratorDetails?.type?.toLowerCase() + 's';
      props.editInternalUserAccessSpecifications(
        occasionId,
        userId,
        formData,
        false,
        props.currentCollaboratorDetails?.type,
        props.currentCollaboratorDetails?.id,
        entityType,
        () => {
          setEditOpen(false);
          setInitiallyDisabled(true);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ErrorBoundary>
      <Form
        className="internal-user-expandable-container"
        onSubmitCapture={onFormSubmit}>
        <p className="expandable-user-field">
          <p className="expandable-user-fieldname">Access Type: </p>
          {!isEditOpen ? (
            <p className="expandable-user-data">{collabortorType}</p>
          ) : (
            <InputGroup
              fieldType="dropdown"
              name="accessType"
              optionLabelProp="label"
              value={assignForm?.accessType}
              placeholder="Select Access Type"
              onSelect={(e) => handleInputChange(e, 'accessType')}
              options={props?.userAccessTypes
                ?.filter(
                  (item) =>
                    (item !== 'owner' && props.admin.accessType === 'admin') ||
                    props.admin.accessType === 'super_admin'
                )
                ?.map((item) => ({
                  label: capitalize(item),
                  value: item
                }))}
            />
          )}
        </p>
        <p className="expandable-user-field">
          <p className="expandable-user-fieldname">Role Name: </p>
          {!isEditOpen ? (
            <p className="expandable-user-data">
              {collabortorRoleName || 'Not Provided'}
            </p>
          ) : (
            <InputGroup
              onChange={handleInputChange}
              value={assignForm?.rolename}
              name="rolename"
              placeholder="Enter Rolename"
            />
          )}
        </p>
        <button
          type="button"
          className="expandable-user-field edit-icon"
          onClick={toggleEditClick}>
          {!isEditOpen ? (
            <>
              <EditOutlined className="edit-icon-outlined" />
              Edit
            </>
          ) : (
            <CloseOutlined />
          )}
        </button>
        {isEditOpen && (
          <SubmitButton
            fetchProgress={props.fetchProgress}
            disabled={isInitiallyDisabled || props.fetchProgress}
            className="expandable-user-field submit-button"
            buttonText="Save Changes"
          />
        )}
      </Form>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => ({
  occasionId: currentOccasionId(store, props),
  currentCollaboratorDetails: getCurrentCollaboratorDetails(store, props),
  userAccessTypes: store.occasion.accessTypes,
  admin: adminData(store, props),
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ editInternalUserAccessSpecifications }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RowExpandable);
