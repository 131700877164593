import {
  REGENERATE_FACE_INFO,
  REGENERATE_FACE_INFO_FAILED,
  REGENERATE_FACE_INFO_SUCCESS,
  FACE_INFO_MEDIA_VIEW,
  FACE_INFO_MEDIA_VIEW_FAILED,
  FACE_INFO_MEDIA_VIEW_SUCCESS,
  FACE_INFO_USER_VIEW,
  FACE_INFO_USER_VIEW_FAILED,
  FACE_INFO_USER_VIEW_SUCCESS,
  CREATE_SELFIE_USER_MEDIA,
  CREATE_SELFIE_USER_MEDIA_FAILED,
  CREATE_SELFIE_USER_MEDIA_SUCCESS,
  UPDATE_SELFIE_IMAGE,
  UPDATE_SELFIE_IMAGE_FAILED,
  UPDATE_SELFIE_IMAGE_SUCCESS,
  REGENERATE_ALL_GALLARY_MEDIA,
  REGENERATE_ALL_GALLARY_MEDIA_FAILED,
  REGENERATE_ALL_GALLARY_MEDIA_SUCCESS,
  GET_SELFIE_USER_MEDIA,
  GET_SELFIE_USER_MEDIA_FAILED,
  GET_SELFIE_USER_MEDIA_SUCCESS
} from '../constants/actionTypes';

export const getSelfieUserMedia = (ocId, userId, page) => ({
  type: GET_SELFIE_USER_MEDIA,
  payload: { ocId, userId, page }
});

export const getSelfieUserMediaSuccess = (
  data,
  included,
  userId,
  links,
  meta
) => ({
  type: GET_SELFIE_USER_MEDIA_SUCCESS,
  payload: { data, included, userId, links, meta }
});

export const getSelfieUserMediaFailed = (error) => ({
  type: GET_SELFIE_USER_MEDIA_FAILED,
  error
});

export const faceInfoMediaView = (ocId, type, page) => ({
  type: FACE_INFO_MEDIA_VIEW,
  payload: { ocId, type, page }
});

export const faceInfoMediaViewSuccess = (
  data,
  included,
  ocId,
  links,
  meta
) => ({
  type: FACE_INFO_MEDIA_VIEW_SUCCESS,
  payload: { data, included, ocId, links, meta }
});

export const faceInfoMediaViewFailed = (error) => ({
  type: FACE_INFO_MEDIA_VIEW_FAILED,
  error
});

export const faceInfoUserView = (ocId, type, page) => ({
  type: FACE_INFO_USER_VIEW,
  payload: { ocId, type, page }
});

export const faceInfoUserViewSuccess = (data, included, ocId, links, meta) => ({
  type: FACE_INFO_USER_VIEW_SUCCESS,
  payload: { data, included, ocId, links, meta }
});

export const faceInfoUserViewFailed = (error) => ({
  type: FACE_INFO_USER_VIEW_FAILED,
  error
});

export const regenerateFaceInfo = (ocId, type, typeId, callback) => ({
  type: REGENERATE_FACE_INFO,
  payload: { ocId, type, typeId },
  callback
});

export const regenerateFaceInfoSuccess = (ocId, data, included) => ({
  type: REGENERATE_FACE_INFO_SUCCESS,
  payload: { ocId, data, included }
});

export const regenerateFaceInfoFailed = (error) => ({
  type: REGENERATE_FACE_INFO_FAILED,
  error
});

export const createSelfieUserMedia = (ocId, userId, callback) => ({
  type: CREATE_SELFIE_USER_MEDIA,
  payload: { ocId, userId },
  callback
});

export const createSelfieUserMediaSuccess = (ocId, data, included) => ({
  type: CREATE_SELFIE_USER_MEDIA_SUCCESS,
  payload: { ocId, data, included }
});

export const createSelfieUserMediaFailed = (error) => ({
  type: CREATE_SELFIE_USER_MEDIA_FAILED,
  error
});

export const updateSelfieImage = (ocId, userId, formData, callback) => ({
  type: UPDATE_SELFIE_IMAGE,
  payload: { ocId, userId, formData },
  callback
});

export const updateSelfieImageSuccess = (ocId, data, included) => ({
  type: UPDATE_SELFIE_IMAGE_SUCCESS,
  payload: { ocId, data, included }
});

export const updateSelfieImageFailed = (error) => ({
  type: UPDATE_SELFIE_IMAGE_FAILED,
  error
});

export const regenerateAllGallaryMediaFaceInfo = (ocId, callback) => ({
  type: REGENERATE_ALL_GALLARY_MEDIA,
  payload: { ocId },
  callback
});

export const regenerateAllGallaryMediaFaceInfoSuccess = (ocId, data) => ({
  type: REGENERATE_ALL_GALLARY_MEDIA_SUCCESS,
  payload: { ocId, data }
});

export const regenerateAllGallaryMediaFaceInfoFailed = (error) => ({
  type: REGENERATE_ALL_GALLARY_MEDIA_FAILED,
  error
});
