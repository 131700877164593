import request from './request';

export function occasionItineraryCreateApi(
  ocId,
  scheduleId,
  schedulableType,
  schedulableId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/schedules/${scheduleId}/itineraries?schedulable_type=${schedulableType}&schedulable_id=${schedulableId}`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function occasionItineraryViewApi(
  ocId,
  scheduleId,
  schedulableType,
  schedulableId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/schedules/${scheduleId}/itineraries?schedulable_type=${schedulableType}&schedulable_id=${schedulableId}`,
    {
      method: 'GET',
      token
    }
  );
}

export function occasionItineraryUpdateApi(
  ocId,
  scheduleId,
  itineraryId,
  schedulableType,
  schedulableId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/schedules/${scheduleId}/itineraries/${itineraryId}?schedulable_type=${schedulableType}&schedulable_id=${schedulableId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function occasionItineraryDeleteApi(
  ocId,
  scheduleId,
  itineraryId,
  schedulableType,
  schedulableId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/schedules/${scheduleId}/itineraries/${itineraryId}?schedulable_type=${schedulableType}&schedulable_id=${schedulableId}`,
    {
      method: 'DELETE',
      token
    }
  );
}
