import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import {
  competitionCreate,
  updateCompetition
} from '../../../../../actions/competition';
import { currentCompetitionData } from '../../../../../selectors/competition';
import { getImageSource } from '../../../../../helpers/imageHelper';

const validateCompetition = (values) => {
  const error = {};
  if (!values.name) {
    error.name = 'Name is required';
  }

  return error;
};

function CreateCompetition(props) {
  const [competitionData, setCompetitionData] = React.useState({
    ...props.initialValues
  });
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleSubmit = () => {
    try {
      const validateObj = validateCompetition(competitionData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append(
          'competition[start_time]',
          new Date(competitionData.schedule[0])
        );
        formData.append(
          'competition[end_time]',
          new Date(competitionData.schedule[1])
        );
        if (
          competitionData.card_image &&
          competitionData.card_image[0]?.originFileObj
        ) {
          formData.append(
            'competition[card_image]',
            competitionData.card_image[0].originFileObj
          );
        }
        formData.append('competition[name]', competitionData.name);
        formData.append(
          'competition[competition_type]',
          competitionData.competition_type
        );
        formData.append('competition[priority]', competitionData.priority);
        formData.append(
          'competition[description]',
          competitionData.description
        );
        formData.append('competition[rules]', competitionData.rules);
        formData.append('competition[organizer]', competitionData.organizer);

        props.competitionId
          ? props.updateCompetition(
              props.occasionId,
              props.competitionId,
              formData
            )
          : props.competitionCreate(props.occasionId, formData, () => {
              setCompetitionData({ priority: 1 });
            });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;

      if (type === 'schedule') {
        value = e.map((item) => moment(item, 'DD/MM/YYYY HH:mm'));
        name = type;
      }
      if (type === 'card_image') {
        value = e.fileList;
        name = type;
      }
      const competitionValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setCompetitionData({
        ...competitionData,
        [inputName]: competitionValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="competition-create-section">
        <div className="competition-create-form">
          <Form className="competition-form" onFinish={handleSubmit}>
            <InputGroup
              label="Name"
              name="name"
              placeholder="Enter Competition Name"
              onChange={handleInputChange}
              value={competitionData?.name}
              errorMessage={errorMessages.name}
            />
            <InputGroup
              fieldType="rangePicker"
              onChange={handleInputChange}
              placeholder={['Start Date with Time', 'End Date with Time']}
              label="Schedule"
              name="schedule"
              value={competitionData?.schedule}
            />
            <InputGroup
              label="Competition Type"
              name="competition_type"
              placeholder="Enter Competition Type"
              onChange={handleInputChange}
              value={competitionData?.competition_type}
            />
            <InputGroup
              label="Priority"
              name="priority"
              placeholder="Enter Priority"
              onChange={handleInputChange}
              value={competitionData?.priority}
            />
            <InputGroup
              label="Organizer"
              name="organizer"
              placeholder="Enter Competition Organizer"
              onChange={handleInputChange}
              value={competitionData?.organizer}
            />
            <InputGroup
              label="Description"
              name="description"
              fieldType="textArea"
              placeholder="Enter Description"
              onChange={handleInputChange}
              value={competitionData?.description}
            />
            <InputGroup
              label="Rules"
              name="rules"
              fieldType="textArea"
              placeholder="Enter Rules"
              onChange={handleInputChange}
              value={competitionData?.rules}
            />
            <InputGroup
              name="card_image"
              fieldType="file"
              label="Competition Image"
              accept="image/*"
              fileList={competitionData?.card_image}
              onChange={handleInputChange}
            />
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.competitionId ? 'Save Changes' : 'Create'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => {
  const currentCompetition = currentCompetitionData(store, props);

  const StartAt = moment(
    moment(currentCompetition?.startTime).format('DD-MM-YYYY HH:mm'),
    'DD-MM-YYYY HH:mm'
  );
  const EndAt = moment(
    moment(currentCompetition?.endTime).format('DD-MM-YYYY HH:mm'),
    'DD-MM-YYYY HH:mm'
  );
  const initialValues = {
    name: currentCompetition?.name || '',
    description: currentCompetition?.description || '',
    rules: currentCompetition?.rules || '',
    priority: currentCompetition?.priority || 1,
    competition_type: currentCompetition?.competitionType || '',
    organizer: currentCompetition?.organizer || '',
    schedule:
      currentCompetition?.startTime && currentCompetition?.endTime
        ? [StartAt, EndAt]
        : [],
    card_image: currentCompetition?.cardImageUrl
      ? [{ url: getImageSource(currentCompetition?.cardImageUrl) }]
      : null
  };
  return {
    currentCompetition,
    initialValues,
    fetchProgress: store.browserState.fetchProgress
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ competitionCreate, updateCompetition }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateCompetition);
