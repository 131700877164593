import request from './request';

export function CreateTaskApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function ViewTaskApi(ocId, page, hospitalityCategoryId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks?page=${page}&hospitality_category_id=${hospitalityCategoryId}`,
    {
      method: 'GET',
      token
    }
  );
}

export function getAllTasksApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/get_all_hospitality_tasks?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function hospitalityTaskCreateUpdateApi(
  ocId,
  hospitalityTaskId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function hospitalityTaskDeleteApi(ocId, hospitalityTaskId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function ViewRespondedTaskApi(ocId, hospitalityTaskId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}/get_answered_users_for_the_requested_task?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function ViewNotRespondedTaskApi(ocId, hospitalityTaskId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}/get_unanswered_users_for_the_requested_task?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function ViewProvidedTaskApi(ocId, hospitalityTaskId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}/get_answered_users_for_the_provided_task?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function ViewNotProvidedTaskApi(ocId, hospitalityTaskId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}/get_unanswered_users_for_the_provided_task?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function downloadHospitalityAnswerForProvidedTask(
  ocId,
  hospitalityTaskId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}/csv_export_hospitality_answers_for_the_provided_task.csv`,
    {
      method: 'GET',
      token,
      isBlob: true
    }
  );
}

export function downloadHospitalityAnswerForRequestedTask(
  ocId,
  hospitalityTaskId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}/csv_export_hospitality_answers_for_the_requested_task.csv`,
    {
      method: 'GET',
      token,
      isBlob: true
    }
  );
}

export function deleteProvidedTaskApi(
  ocId,
  hospitalityTaskId,
  answerId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}/destroy_answers_for_the_provided_task?id=${answerId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function deleteRespondedTaskApi(
  ocId,
  hospitalityTaskId,
  answerId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}/destroy_answers_for_the_requested_task?id=${answerId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function hospitalityTaskProvideAnswerForAllApi(
  ocId,
  hospitalityTaskId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}/create_same_hospitality_answers_for_users`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function hospitalityTaskProvideAnswerApi(
  ocId,
  hospitalityTaskId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}/create_answer_for_the_provided_task`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function hospitalityTaskRequestAnswerApi(
  ocId,
  hospitalityTaskId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}/create_hospitality_task_answer`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function hospitalityTaskProvideAnswerForCSVApi(
  ocId,
  hospitalityTaskId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}/create_hospitality_answers_for_the_provided_task_by_csv`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function editProvidedTaskAnswerApi(
  ocId,
  hospitalityTaskId,
  answerId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}/update_answers_for_the_provided_task?id=${answerId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function searchNotProvidedTasksApi(
  ocId,
  hospitalityTaskId,
  taskData,
  page,
  token
) {
  let params = '';

  if (taskData.info) {
    params += `&name_or_email_or_mobile=${taskData.info}`;
  }

  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}/search_unanswered_users_for_the_provided_task?page=${page}${params}`,
    {
      method: 'GET',
      token
    }
  );
}

export function searchProvidedTasksApi(
  ocId,
  hospitalityTaskId,
  taskData,
  page,
  token
) {
  let params = '';

  if (taskData.info) {
    params += `&name_or_email_or_mobile=${taskData.info}`;
  }

  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}/search_answered_users_for_the_provided_task?page=${page}${params}`,
    {
      method: 'GET',
      token
    }
  );
}

export function searchRespondedTasksApi(
  ocId,
  hospitalityTaskId,
  taskData,
  page,
  token
) {
  let params = '';

  if (taskData.info) {
    params += `&name_or_email_or_mobile=${taskData.info}`;
  }

  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}/search_answered_users_for_the_requested_task?page=${page}${params}`,
    {
      method: 'GET',
      token
    }
  );
}

export function searchNotRespondedTasksApi(
  ocId,
  hospitalityTaskId,
  taskData,
  page,
  token
) {
  let params = '';

  if (taskData.info) {
    params += `&name_or_email_or_mobile=${taskData.info}`;
  }

  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${hospitalityTaskId}/search_unanswered_users_for_the_requested_task?page=${page}${params}`,
    {
      method: 'GET',
      token
    }
  );
}
