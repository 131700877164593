import {
  GET_REPORTS_WITH_PAGINATION,
  GET_REPORTS_WITH_PAGINATION_SUCCESS,
  GET_REPORTS_WITH_PAGINATION_FAILED,
  GET_REPORTED_POST_COMMENTS_WITH_PAGINATION,
  GET_REPORTED_POST_COMMENTS_WITH_PAGINATION_FAILED,
  GET_REPORTED_POST_COMMENTS_WITH_PAGINATION_SUCCESS,
  GET_REPORTED_POST_LIKES_WITH_PAGINATION,
  GET_REPORTED_POST_LIKES_WITH_PAGINATION_FAILED,
  GET_REPORTED_POST_LIKES_WITH_PAGINATION_SUCCESS
} from '../constants/actionTypes';

export const getReportsWithPagination = (ocId, page) => ({
  type: GET_REPORTS_WITH_PAGINATION,
  payload: { ocId, page }
});

export const getReportsWithPaginationSuccess = (
  data,
  included,
  links,
  ocId
) => ({
  type: GET_REPORTS_WITH_PAGINATION_SUCCESS,
  payload: { data, included, links, ocId }
});

export const getReportsWithPaginationFailed = (error) => ({
  type: GET_REPORTS_WITH_PAGINATION_FAILED,
  error
});

export const getReportedPostLikesWithPagination = (
  ocId,
  page,
  likeableType,
  likeableId
) => ({
  type: GET_REPORTED_POST_LIKES_WITH_PAGINATION,
  payload: { ocId, page, likeableType, likeableId }
});

export const getReportedPostLikesWithPaginationSuccess = (
  data,
  included,
  links
) => ({
  type: GET_REPORTED_POST_LIKES_WITH_PAGINATION_SUCCESS,
  payload: { data, included, links }
});

export const getReportedPostLikesWithPaginationFailed = (error) => ({
  type: GET_REPORTED_POST_LIKES_WITH_PAGINATION_FAILED,
  error
});

export const getReportedPostCommentsWithPagination = (
  ocId,
  page,
  commentableType,
  commentableId
) => ({
  type: GET_REPORTED_POST_COMMENTS_WITH_PAGINATION,
  payload: { ocId, page, commentableType, commentableId }
});

export const getReportedPostCommentsWithPaginationSuccess = (
  data,
  included,
  links
) => ({
  type: GET_REPORTED_POST_COMMENTS_WITH_PAGINATION_SUCCESS,
  payload: { data, included, links }
});

export const getReportedPostCommentsWithPaginationFailed = (error) => ({
  type: GET_REPORTED_POST_COMMENTS_WITH_PAGINATION_FAILED,
  error
});
