import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  getMembers,
  searchMembersApi,
  deleteMemberApi,
  updateMemberApi,
  downloadMemberDetailsByExlsApi,
  downloadQrCodeApi,
  memberDownloadExslExportApi,
  memberCreateByCsvApi
} from '../api/members';
import {
  GET_ALL_MEMBERS,
  SEARCH_MEMBERS,
  DELETE_MEMBERS,
  UPDATE_MEMBERS,
  DOWNLOAD_MEMBER_EXLS,
  DOWNLOAD_QR_CODE,
  MEMBER_DOWNLOAD_EXSL_EXPORT,
  MEMBER_CREATE_BY_CSV
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  getOccasionMembersSuccess,
  getOccasionMembersFailed,
  searchMembersSuccess,
  searchMembersFailed,
  deleteMemberSuccess,
  deleteMemberFailed,
  updateMemberSuccess,
  updateMemberFailed,
  downloadMemberDetailsByExlsSuccess,
  downloadMemberDetailsByExlsFailed,
  downloadQrCodeSuccess,
  downloadQrCodeFailed,
  memberDownloadExslExportSuccess,
  memberDownloadExslExportFailed,
  memberCreateByCsvSuccess,
  memberCreateByCsvFailed
} from '../actions/members';
import { entityDeleteRequestSuccess } from '../actions/entity';

export function* memberCreateByCsvSaga(action) {
  try {
    const result = yield call(
      memberCreateByCsvApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        memberCreateByCsvSuccess(
          result.data,
          result.included,
          action.payload.ocId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(memberCreateByCsvFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(memberCreateByCsvFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getAllMembersSaga(action) {
  try {
    const result = yield call(
      getMembers,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getOccasionMembersSuccess(
          action.payload.ocId,
          result.data,
          result.included,
          result.links,
          result?.meta
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getOccasionMembersFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* searchMembersSaga(action) {
  try {
    const result = yield call(
      searchMembersApi,
      action.payload.ocId,
      action.payload.memberData,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        searchMembersSuccess(
          action.payload.ocId,
          result.data,
          result.included,
          result.links,
          result?.meta
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(searchMembersFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* deleteMemberSaga(action) {
  try {
    const result = yield call(
      deleteMemberApi,
      action.payload.ocId,
      action.payload.memberId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(deleteMemberSuccess(action.payload.ocId, result.data));

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteMemberFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updateMemberSaga(action) {
  try {
    const result = yield call(
      updateMemberApi,
      action.payload.ocId,
      action.payload.memberId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(updateMemberSuccess(action.payload.ocId, result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateMemberFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateMemberFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* downloadMemberDetailsByExlsSaga(action) {
  try {
    const result = yield call(
      downloadMemberDetailsByExlsApi,
      action.payload.ocId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        downloadMemberDetailsByExlsSuccess(result.data, result.included)
      );
      if (typeof action.callback === 'function') {
        action.callback(result?.blobUrl);
      }
    }
  } catch (error) {
    yield put(downloadMemberDetailsByExlsFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* downloadQrCodeSaga(action) {
  try {
    const result = yield call(
      downloadQrCodeApi,
      action.payload.ocId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(downloadQrCodeSuccess(result.data, result.included));
      if (typeof action.callback === 'function') {
        action.callback(result?.blobUrl);
      }
    }
  } catch (error) {
    yield put(downloadQrCodeFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* memberDownloadExslExportSaga(action) {
  try {
    const result = yield call(
      memberDownloadExslExportApi,
      action.payload.ocId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(memberDownloadExslExportSuccess(result.data, result.included));
      if (typeof action.callback === 'function') {
        action.callback(result?.blobUrl);
      }
    }
  } catch (error) {
    yield put(memberDownloadExslExportFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* membersActionWatcher() {
  yield takeLatest(MEMBER_CREATE_BY_CSV, progressSaga, memberCreateByCsvSaga);
  yield takeLatest(GET_ALL_MEMBERS, progressSaga, getAllMembersSaga);
  yield takeLatest(SEARCH_MEMBERS, progressSaga, searchMembersSaga);
  yield takeLatest(DELETE_MEMBERS, progressSaga, deleteMemberSaga);
  yield takeLatest(UPDATE_MEMBERS, progressSaga, updateMemberSaga);
  yield takeLatest(
    DOWNLOAD_MEMBER_EXLS,
    progressSaga,
    downloadMemberDetailsByExlsSaga
  );
  yield takeLatest(DOWNLOAD_QR_CODE, progressSaga, downloadQrCodeSaga);
  yield takeLatest(
    MEMBER_DOWNLOAD_EXSL_EXPORT,
    progressSaga,
    memberDownloadExslExportSaga
  );
}
