import {
  GET_USER_PAGINATION_SUCCESS,
  GET_USER_PAGINATION_FAILED,
  GET_USER_BY_SEARCH_SUCCESS,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAILED,
  GET_USER_BY_OCCASION_SUCCESS
} from '../constants/actionTypes';
import { union } from '../helpers/extraHelpers';

const initialState = {
  allIds: null,
  data: null,
  searchData: null,
  searchedLinks: null,
  error: null,
  links: null,
  count: 0
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_BY_OCCASION_SUCCESS:
    case GET_USER_PAGINATION_SUCCESS:
    case GET_USER_BY_SEARCH_SUCCESS: {
      const { data, links, page, meta } = action.payload;

      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];
      const allIds = page !== 1 ? union(state.allIds || [], newIds) : newIds;
      return {
        ...state,
        data: newIds,
        allIds,
        links,
        count: meta?.totalCount || 0
      };
    }

    case SEARCH_USERS_SUCCESS: {
      const { data, links } = action.payload;

      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];
      return {
        ...state,
        searchData: newIds,
        searchedLinks: links
      };
    }

    case SEARCH_USERS_FAILED:
    case GET_USER_PAGINATION_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};
export default user;
