import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import Tabs from '../../../../../components/Tabs';
import { getCurrentTicket } from '../../../../../selectors/ticket';
import TicketDetailsView from './TicketDetails';
import {
  currentOccasionId,
  currentTicketId
} from '../../../../../selectors/dynamicData';
import {
  resetTabIndex,
  setCurrentTabIndex
} from '../../../../../actions/tabKeys';
import ViewTicketVariant from './TicketVariant/TicketVariantView';
import { TabsHeader } from '../../../../../components/TabsHeader/index';
import TicketVariantFormView from './TicketVariantForm/TicketVariantFormView';
import TicketPurchaseIndex from './TicketPurchases';
import { getCurrentOccasion } from '../../../../../selectors/occasion';
import ViewQuestion from './Question/QuestionView';

function TicketDetails(props) {
  const getTicketVariantComponent = () => {
    return (
      <ViewTicketVariant
        occasionId={props.occasionId}
        ticketId={props.currentTicketId}
        ticketDetails={props.ticketDetails}
      />
    );
  };

  const getTicketVariantFormComponent = () => {
    return (
      <TicketVariantFormView
        occasionId={props.occasionId}
        ticketId={props.currentTicketId}
      />
    );
  };
  const getQuestionComponent = () => {
    return (
      <ViewQuestion
        occasionId={props.occasionId}
        ticketId={props.currentTicketId}
      />
    );
  };

  const getTicketPurchases = () => {
    return (
      <TicketPurchaseIndex
        occasionId={props.occasionId}
        ticketId={props.currentTicketId}
      />
    );
  };

  const tabPanes = [
    {
      tabName: 'Details',
      tabKey: '1',
      children: (
        <TicketDetailsView
          occasionId={props.occasionId}
          ticketDetails={props.ticketDetails}
        />
      )
    },
    {
      tabName: 'Ticket Variant',
      tabKey: '2',
      children: getTicketVariantComponent()
    },
    {
      tabName: 'Ticket Variant Form',
      tabKey: '3',
      children: getTicketVariantFormComponent()
    },
    {
      tabName: 'Question',
      tabKey: '4',
      children: getQuestionComponent()
    },
    {
      tabName: 'Ticket Purchases',
      tabKey: '5',
      children: getTicketPurchases()
    }
  ];

  const goBack = () => {
    props.resetTabIndex('tickets');
    props.history.goBack();
  };

  const handleTabChange = (key) => {
    props.setCurrentTabIndex(key, 'tickets');
  };

  return (
    <ErrorBoundary>
      <SectionContent className="ticket-tab-section">
        <TabsHeader
          name={props.currentOccasion.name}
          extraData={[
            props.currentOccasion.type,
            props.currentOccasion.guestType,
            props.ticketDetails.name
          ]}
          wrapperClass="section-details"
        />
        <div className="ticket-tab-container">
          <Tabs
            defaultActiveKey={props.activeTabKey}
            tabPanes={tabPanes}
            onChange={handleTabChange}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  activeTabKey: store.tabKeys.tickets,
  fetchProgress: store.browserState.fetchProgress,
  ticketDetails: getCurrentTicket(store, props),
  occasionId: currentOccasionId(store, props),
  currentOccasion: getCurrentOccasion(store, props),
  currentTicketId: currentTicketId(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setCurrentTabIndex, resetTabIndex }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TicketDetails);
