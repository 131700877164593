import { Image, Popconfirm, Button } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import {
  ZoomInOutlined,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { getImageSource } from '../../../../helpers/imageHelper';
import CardImage from '../../../../components/Table/CardImage';
import UserCreate from '../Create/index';
import { getDateInMonthFormat } from '../../../../helpers/dateTimeHelper';
import { useLocation } from 'react-router-dom';
import { Switch } from '../../../../components/InputGroup/FormComponents';
import { editUser, qrCodeDelete } from '../../../../actions/user';
import QrCodeCreateModal from './QrCodeCreateModal';

const UserDetailsView = ({
  userDetails,
  editUser,
  fetchProgress,

  qrCodeDelete
}) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);
  const dob = userDetails?.dob;
  const location = useLocation();

  const onSwitchChange = (e) => {
    const data = new FormData();
    data.append('user[from_internal_team]', e);
    editUser(data, userDetails.id);
  };

  const handleDelete = () => {
    qrCodeDelete(userDetails.id);
  };

  if (qrCodeModalOpen) {
    return (
      <div className="edit-container">
        <QrCodeCreateModal
          userId={userDetails?.id}
          userDetails={userDetails}
          handleCloseClick={() => setQrCodeModalOpen(false)}
        />
      </div>
    );
  }

  if (!isEditOpen) {
    return (
      <div className="user-details-container">
        <p className="user-details avatar-details">
          <p className="fields">Avatar:</p>
          <p className="fields-data avatar-image-container">
            <Image
              src={getImageSource(userDetails?.avatarUrl)}
              preview={{
                maskClassName: 'preview-style',
                mask: <ZoomInOutlined />
              }}
              className="image-style"
            />
          </p>
        </p>

        <p className="user-details">
          <p className="fields">Name:</p>
          <p className="field-data">{userDetails?.name}</p>
        </p>
        <p className="user-details">
          <p className="fields">Email Address:</p>
          <p className="field-data">{userDetails?.email}</p>
        </p>
        <p className="user-details">
          <p className="fields">Date of Birth:</p>
          <p className="field-data">{dob ? dob : 'Not Provided'}</p>
        </p>
        <p className="user-details">
          <p className="fields">Active:</p>
          <p className="field-data">{!!userDetails?.active ? 'Yes' : 'No'}</p>
        </p>
        <p className="user-details">
          <p className="fields">Gender:</p>
          <p className="field-data gender">
            {userDetails?.gender === 'prefer_not_to_disclose'
              ? 'Prefer Not To Disclose'
              : userDetails?.gender}
          </p>
        </p>
        <p className="user-details">
          <p className="fields">Form Internal Team:</p>
          <p className="field-data">
            <span className="field-data-span">
              {!!userDetails?.fromInternalTeam ? 'Active' : 'Inactive'}
            </span>
            <Switch
              loading={fetchProgress}
              disabled={fetchProgress}
              value={userDetails?.fromInternalTeam}
              onClick={onSwitchChange}
            />
          </p>
        </p>

        {userDetails?.qrCodeUrl ? (
          <p className="user-details avatar-details">
            <p className="fields">QR Code:</p>
            <p className="fields-data avatar-image-container">
              <CardImage data={userDetails?.qrCodeUrl} />
            </p>

            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={handleDelete}
              okText="Yes"
              cancelText="No">
              <Button className="delete-icon">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </p>
        ) : (
          <p className="user-details">
            <p className="fields">QR Code:</p>
            <button
              className="qr-code-create-button"
              onClick={() => setQrCodeModalOpen(true)}>
              Generate QR Code
            </button>
          </p>
        )}
        <button
          className="edit-details-container"
          onClick={() => setIsEditOpen(false)}>
          <EditOutlined />
          <span>Edit Details</span>
        </button>
      </div>
    );
  }
  return (
    <div className="edit-container">
      <UserCreate
        location={location}
        userId={userDetails?.id}
        allowAccessToPage
        userDetails={userDetails}
        handleCloseClick={() => setIsEditOpen(false)}
      />
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ editUser, qrCodeDelete }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsView);
