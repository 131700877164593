import request from './request';

export function getOccasionAppDetailsApi(ocId, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/occasion_apps`, {
    method: 'GET',
    token
  });
}

export function occasionAppCreateApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/occasion_apps`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function updateOccasionAppApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/update_occasion_app`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}
