import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  createMessagesApi,
  getProvidedAnsweredUsersApi,
  getWhatsappMessageByModelableApi,
  getWhatsappTemplateModelablesApi
} from '../api/whatsappForProvidedTask';
import {
  CREATE_MESSAGES_FOR_PROVIDED_TASK,
  GET_PROVIDED_ANSWERED_USERS,
  GET_WHATSAPP_MESSAGE_MODELABLE,
  GET_WHATSAPP_TEMPLATE_MODELABLES
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  createMessagesFailed,
  createMessagesSuccess,
  getProvidedAnsweredUsersFailed,
  getProvidedAnsweredUsersSuccess,
  getWhatsappMessageByModelableFailed,
  getWhatsappMessageByModelableSuccess,
  getWhatsappTemplateModelablesFailed,
  getWhatsappTemplateModelablesSuccess
} from '../actions/whatsappForProvidedTask';

export function* createMessagesSaga(action) {
  try {
    const result = yield call(
      createMessagesApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        createMessagesSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          action.payload.taskId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(createMessagesFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(createMessagesFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getWhatsappMessageByModelableSaga(action) {
  try {
    const result = yield call(
      getWhatsappMessageByModelableApi,
      action.payload.ocId,
      action.payload.modelableType,
      action.payload.taskId,
      action.payload.operationType,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getWhatsappMessageByModelableSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          action.payload.taskId,
          action.payload.operationType,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getWhatsappMessageByModelableFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getWhatsappTemplateModelablesSaga(action) {
  try {
    const result = yield call(
      getWhatsappTemplateModelablesApi,
      action.payload.ocId,
      action.payload.modelableType,
      action.payload.taskId,
      action.payload.operationType,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getWhatsappTemplateModelablesSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          action.payload.taskId,
          action.payload.operationType,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getWhatsappTemplateModelablesFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getProvidedAnsweredUsersSaga(action) {
  try {
    const result = yield call(
      getProvidedAnsweredUsersApi,
      action.payload.ocId,
      action.payload.taskId,
      action.payload.userType,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getProvidedAnsweredUsersSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          action.payload.taskId,
          action.payload.userType,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getProvidedAnsweredUsersFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* whatsappForProvidedTaskActionWatcher() {
  yield takeLatest(
    CREATE_MESSAGES_FOR_PROVIDED_TASK,
    progressSaga,
    createMessagesSaga
  );
  yield takeLatest(
    GET_WHATSAPP_MESSAGE_MODELABLE,
    progressSaga,
    getWhatsappMessageByModelableSaga
  );
  yield takeLatest(
    GET_WHATSAPP_TEMPLATE_MODELABLES,
    progressSaga,
    getWhatsappTemplateModelablesSaga
  );
  yield takeLatest(
    GET_PROVIDED_ANSWERED_USERS,
    progressSaga,
    getProvidedAnsweredUsersSaga
  );
}
