import {
  ACTIVITY_TRACKER_CREATE,
  ACTIVITY_TRACKER_CREATE_FAILED,
  ACTIVITY_TRACKER_CREATE_SUCCESS,
  ACTIVITY_TRACKER_VIEW,
  ACTIVITY_TRACKER_VIEW_FAILED,
  ACTIVITY_TRACKER_VIEW_SUCCESS,
  UPDATE_ACTIVITY_TRACKER,
  UPDATE_ACTIVITY_TRACKER_FAILED,
  UPDATE_ACTIVITY_TRACKER_SUCCESS,
  DELETE_ACTIVITY_TRACKER,
  DELETE_ACTIVITY_TRACKER_FAILED,
  DELETE_ACTIVITY_TRACKER_SUCCESS,
  ACTIVITY_TRACKER_DOWNLOAD_EXSL_EXPORT,
  ACTIVITY_TRACKER_DOWNLOAD_EXSL_EXPORT_FAILED,
  ACTIVITY_TRACKER_DOWNLOAD_EXSL_EXPORT_SUCCESS,
  BLACK_LIST_USER_CREATE_BY_CSV,
  BLACK_LIST_USER_CREATE_BY_CSV_FAILED,
  BLACK_LIST_USER_CREATE_BY_CSV_SUCCESS,
  BLACK_LIST_USER_CREATE,
  BLACK_LIST_USER_CREATE_FAILED,
  BLACK_LIST_USER_CREATE_SUCCESS,
  BLACK_LIST_USER_VIEW,
  BLACK_LIST_USER_VIEW_FAILED,
  BLACK_LIST_USER_VIEW_SUCCESS,
  DELETE_BLACK_LIST_USER,
  DELETE_BLACK_LIST_USER_FAILED,
  DELETE_BLACK_LIST_USER_SUCCESS
} from '../constants/actionTypes';

export const activityTrackerDownloadExslExport = (
  ocId,
  trackerId,
  callback
) => {
  return {
    type: ACTIVITY_TRACKER_DOWNLOAD_EXSL_EXPORT,
    payload: { ocId, trackerId },
    callback
  };
};

export const activityTrackerDownloadExslExportSuccess = (data, included) => {
  return {
    type: ACTIVITY_TRACKER_DOWNLOAD_EXSL_EXPORT_SUCCESS,
    payload: { data, included }
  };
};

export const activityTrackerDownloadExslExportFailed = (error) => {
  return {
    type: ACTIVITY_TRACKER_DOWNLOAD_EXSL_EXPORT_FAILED,
    error
  };
};

export const activityTrackerCreate = (ocId, formData, callback) => ({
  type: ACTIVITY_TRACKER_CREATE,
  payload: { ocId, formData },
  callback
});

export const activityTrackerCreateSuccess = (data, included, ocId) => ({
  type: ACTIVITY_TRACKER_CREATE_SUCCESS,
  payload: { data, included, ocId }
});

export const activityTrackerCreateFailed = (error) => ({
  type: ACTIVITY_TRACKER_CREATE_FAILED,
  error
});

export const activityTrackerView = (ocId, page) => ({
  type: ACTIVITY_TRACKER_VIEW,
  payload: { ocId, page }
});

export const activityTrackerViewSuccess = (data, included, ocId, links) => ({
  type: ACTIVITY_TRACKER_VIEW_SUCCESS,
  payload: { data, included, ocId, links }
});

export const activityTrackerViewFailed = (error) => ({
  type: ACTIVITY_TRACKER_VIEW_FAILED,
  error
});

export const updateActivityTracker = (ocId, trackerId, formData, callback) => ({
  type: UPDATE_ACTIVITY_TRACKER,
  payload: { ocId, trackerId, formData },
  callback
});

export const updateActivityTrackerSuccess = (ocId, data, included) => ({
  type: UPDATE_ACTIVITY_TRACKER_SUCCESS,
  payload: { ocId, data, included }
});

export const updateActivityTrackerFailed = (error) => ({
  type: UPDATE_ACTIVITY_TRACKER_FAILED,
  error
});

export const deleteActivityTracker = (ocId, trackerId, callback) => ({
  type: DELETE_ACTIVITY_TRACKER,
  payload: { ocId, trackerId },
  callback
});

export const deleteActivityTrackerSuccess = (ocId, data) => ({
  type: DELETE_ACTIVITY_TRACKER_SUCCESS,
  payload: { ocId, data }
});

export const deleteActivityTrackerFailed = (error) => ({
  type: DELETE_ACTIVITY_TRACKER_FAILED,
  error
});

export const blackListUserCreateByCsv = (ocId, formData, callback) => ({
  type: BLACK_LIST_USER_CREATE_BY_CSV,
  payload: { ocId, formData },
  callback
});

export const blackListUserCreateByCsvSuccess = (data, included, ocId) => ({
  type: BLACK_LIST_USER_CREATE_BY_CSV_SUCCESS,
  payload: { data, included, ocId }
});

export const blackListUserCreateByCsvFailed = (error) => ({
  type: BLACK_LIST_USER_CREATE_BY_CSV_FAILED,
  error
});

export const blackListUserCreate = (ocId, formData, callback) => ({
  type: BLACK_LIST_USER_CREATE,
  payload: { ocId, formData },
  callback
});

export const blackListUserCreateSuccess = (data, included, ocId) => ({
  type: BLACK_LIST_USER_CREATE_SUCCESS,
  payload: { data, included, ocId }
});

export const blackListUserCreateFailed = (error) => ({
  type: BLACK_LIST_USER_CREATE_FAILED,
  error
});

export const blackListUserView = (ocId, page) => ({
  type: BLACK_LIST_USER_VIEW,
  payload: { ocId, page }
});

export const blackListUserViewSuccess = (
  data,
  included,
  ocId,
  links,
  meta
) => ({
  type: BLACK_LIST_USER_VIEW_SUCCESS,
  payload: { data, included, ocId, links, meta }
});

export const blackListUserViewFailed = (error) => ({
  type: BLACK_LIST_USER_VIEW_FAILED,
  error
});

export const deleteBlackListUser = (ocId, userId, callback) => ({
  type: DELETE_BLACK_LIST_USER,
  payload: { ocId, userId },
  callback
});

export const deleteBlackListUserSuccess = (ocId, data) => ({
  type: DELETE_BLACK_LIST_USER_SUCCESS,
  payload: { ocId, data }
});

export const deleteBlackListUserFailed = (error) => ({
  type: DELETE_BLACK_LIST_USER_FAILED,
  error
});
