import React, { useState } from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Avatar } from '../Avatar';
import './styles.scss';

export const ListItem = ({
  data,
  onSelect,
  disabled = false,
  selected = false
}) => {
  const { name, email, avatarUrl, emailOrMobile, mobile } = data;
  const [isSelected, setSelected] = useState(selected);

  const onClick = () => {
    setSelected((val) => !val);
    if (typeof onSelect === 'function') {
      onSelect(data);
    }
  };
  return (
    <div
      className={`list-item-container  ${
        disabled ? 'disabled' : 'not-disabled'
      }`}>
      {name && (
        <Avatar
          avatarUrl={avatarUrl}
          name={name}
          className="list-item-avatar"
        />
      )}
      <div className="list-content">
        <div className="list-content-name">{name}</div>
        {email && !mobile && <div className="list-content-email">{email}</div>}
        <div className="list-content-email">{emailOrMobile}</div>
        {mobile && !email && <div className="list-content-email">{mobile}</div>}
        {mobile && email && <div className="list-content-email">{email}</div>}
        {mobile && email && <div className="list-content-email">{mobile}</div>}
      </div>
      <Button
        className={`button ${isSelected ? 'selected' : 'de-selected'} ${
          disabled ? 'disabled' : 'not-disabled'
        }`}
        disabled={disabled}
        onClick={onClick}>
        {!isSelected ? <PlusOutlined /> : <MinusOutlined />}
      </Button>
    </div>
  );
};
