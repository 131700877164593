import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../../../components/Section';
import ErrorBoundary from '../../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../../components/InputGroup/FormComponents';
import { capitalize } from '../../../../../../../helpers/stringHelper';
import {
  eventAffairFoodCreate,
  eventAffairFoodUpdate
} from '../../../../../../../actions/eventAffairFood';
import { getCurrentEventAffairFood } from '../../../../../../../selectors/eventAffair';
import { getImageSource } from '../../../../../../../helpers/imageHelper';

const validateFoodDate = (values) => {
  const error = {};
  if (!values.name) {
    error.name = 'Name is Required';
  }
  if (!values.foodType) {
    error.foodType = 'Food Type is Required';
  }
  return error;
};

const EventAffairFoodCreate = (props) => {
  const [foodData, setFoodDate] = useState({
    name: props.currentFoodDate?.name || '',
    foodType: props.currentFoodDate?.foodType || '',
    category: props.currentFoodDate?.category || '',
    usp: props.currentFoodDate?.usp || '',
    description: props.currentFoodDate?.description || '',
    image: props.currentFoodDate?.foodImageUrl && [
      { url: getImageSource(props.currentFoodDate?.foodImageUrl) }
    ]
  });
  const [errorMessages, setErrorMessages] = React.useState({});
  const foodTypeOptions = Object.keys(props.foodTypeData);

  const handleSubmit = () => {
    try {
      const validateObj = validateFoodDate(foodData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        const formData = new FormData();
        formData.append('event_affair_food[name]', foodData.name);
        formData.append('event_affair_food[food_type]', foodData.foodType);
        formData.append('event_affair_food[category]', foodData.category);

        formData.append('event_affair_food[usp]', foodData.usp);

        formData.append('event_affair_food[description]', foodData.description);

        if (foodData.image && foodData.image[0]?.originFileObj) {
          formData.append(
            'event_affair_food[food_image]',
            foodData.image[0]?.originFileObj
          );
        }

        props.foodId
          ? props.eventAffairFoodUpdate(
              props.occasionId,
              props.eventAffairId,
              props.eventId,
              props.foodId,
              formData
            )
          : props.eventAffairFoodCreate(
              props.occasionId,
              formData,
              props.eventId,
              props.eventAffairId,
              () => {
                setFoodDate({});
              }
            );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let formValue;
      let inputName;
      if (type === 'image') {
        value = e.fileList;
        name = type;
      }
      formValue = value || e?.target?.value;
      inputName = name || e?.target?.name;
      setFoodDate({
        ...foodData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="event-affair-food-create-section">
        <div className="event-affair-food-create-form">
          <Form
            className="event-affair-food-form-container"
            onFinish={handleSubmit}>
            <InputGroup
              label="Name"
              name="name"
              placeholder="Enter Name"
              onChange={handleInputChange}
              value={foodData?.name}
              errorMessage={errorMessages.name}
            />

            <InputGroup
              fieldType="dropdown"
              className="food-type"
              options={foodTypeOptions?.map((item) => ({
                label: capitalize(item),
                value: item
              }))}
              name="foodType"
              label="Food Type"
              onChange={handleInputChange}
              value={foodData?.foodType}
              errorMessage={errorMessages.foodType}
            />
            <InputGroup
              label="Category"
              name="category"
              placeholder="Enter Category"
              onChange={handleInputChange}
              value={foodData?.category}
            />
            <InputGroup
              label="USP"
              name="usp"
              placeholder="Enter USP"
              onChange={handleInputChange}
              value={foodData?.usp}
            />
            <InputGroup
              label="Description"
              name="description"
              placeholder="Enter Description"
              onChange={handleInputChange}
              fieldType="textArea"
              value={foodData?.description}
            />
            <InputGroup
              fieldType="file"
              label="Food Image"
              accept="image/*"
              fileList={foodData?.image}
              onChange={handleInputChange}
              name="image"
            />

            <SubmitButton
              buttonText={props.foodId ? 'Save Changes' : 'Create'}
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentFoodDate: getCurrentEventAffairFood(store, props),
  foodTypeData: store.occasion.events.event_affair_food_types
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { eventAffairFoodCreate, eventAffairFoodUpdate },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventAffairFoodCreate);
