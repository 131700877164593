import React, { useState } from 'react';
import { capitalize } from '../../helpers/stringHelper';
import './styles.scss';
import { Avatar } from '../Avatar';

export const EventContent = ({ data }) => {
  if (data.length > 0) {
    return (
      <div className="event-content">
        {data?.map((item) => {
          return (
            <div className="event-content-box">
              <span className="event-content-name">{item?.title || ''}</span>
              <Avatar
                avatarUrl={item?.cardImageUrl}
                name={item?.title}
                className="event-content-image"
              />
            </div>
          );
        })}
      </div>
    );
  }
  return '';
};
