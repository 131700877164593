import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  competitionCreateApi,
  competitionViewApi,
  deleteCompetitionApi,
  updateCompetitionApi
} from '../api/competition';
import {
  COMPETITION_CREATE,
  COMPETITION_VIEW,
  DELETE_COMPETITION,
  UPDATE_COMPETITION
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  competitionCreateFailed,
  competitionCreateSuccess,
  competitionViewFailed,
  competitionViewSuccess,
  deleteCompetitionFailed,
  deleteCompetitionSuccess,
  updateCompetitionFailed,
  updateCompetitionSuccess
} from '../actions/competition';

export function* competitionCreateSaga(action) {
  try {
    const result = yield call(
      competitionCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        competitionCreateSuccess(
          result.data,
          result.included,
          action.payload.ocId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(competitionCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(competitionCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* competitionViewSaga(action) {
  try {
    const result = yield call(
      competitionViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        competitionViewSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(competitionViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* competitionUpdateSaga(action) {
  try {
    const result = yield call(
      updateCompetitionApi,
      action.payload.ocId,
      action.payload.competitionId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateCompetitionSuccess(
          action.payload.ocId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateCompetitionFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateCompetitionFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* competitionDeleteSaga(action) {
  try {
    const result = yield call(
      deleteCompetitionApi,
      action.payload.ocId,
      action.payload.competitionId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(deleteCompetitionSuccess(action.payload.ocId, result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteCompetitionFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* competitionActionWatcher() {
  yield takeLatest(COMPETITION_CREATE, progressSaga, competitionCreateSaga);
  yield takeLatest(COMPETITION_VIEW, progressSaga, competitionViewSaga);
  yield takeLatest(UPDATE_COMPETITION, progressSaga, competitionUpdateSaga);
  yield takeLatest(DELETE_COMPETITION, progressSaga, competitionDeleteSaga);
}
