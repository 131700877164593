import {
  GET_TICKET_GOOGLE_SHEET,
  GET_TICKET_GOOGLE_SHEET_FAILED,
  GET_TICKET_GOOGLE_SHEET_SUCCESS,
  TICKET_GOOGLE_SHEET_CREATE,
  TICKET_GOOGLE_SHEET_CREATE_FAILED,
  TICKET_GOOGLE_SHEET_CREATE_SUCCESS,
  DELETE_TICKET_GOOGLE_SHEET,
  DELETE_TICKET_GOOGLE_SHEET_SUCCESS,
  DELETE_TICKET_GOOGLE_SHEET_FAILED
} from '../constants/actionTypes';

export const getTicketGoogleSheet = (ocId, page) => ({
  type: GET_TICKET_GOOGLE_SHEET,
  payload: { ocId, page }
});

export const getTicketGoogleSheetSuccess = (data, included, links, ocId) => ({
  type: GET_TICKET_GOOGLE_SHEET_SUCCESS,
  payload: { data, included, links, ocId }
});

export const getTicketGoogleSheetFailed = (error) => ({
  type: GET_TICKET_GOOGLE_SHEET_FAILED,
  error
});

export const ticketGoogleSheetCreate = (ocId, callback) => ({
  type: TICKET_GOOGLE_SHEET_CREATE,
  payload: { ocId },
  callback
});

export const ticketGoogleSheetCreateSuccess = (data, ocId) => ({
  type: TICKET_GOOGLE_SHEET_CREATE_SUCCESS,
  payload: { data, ocId }
});

export const ticketGoogleSheetCreateFailed = (error) => ({
  type: TICKET_GOOGLE_SHEET_CREATE_FAILED,
  error
});

export const deleteTicketGoogleSheet = (
  ocId,
  ticketGoogleSheetId,
  callback
) => ({
  type: DELETE_TICKET_GOOGLE_SHEET,
  payload: { ocId, ticketGoogleSheetId },
  callback
});

export const deleteTicketGoogleSheetSuccess = (ocId, data) => ({
  type: DELETE_TICKET_GOOGLE_SHEET_SUCCESS,
  payload: { ocId, data }
});

export const deleteTicketGoogleSheetFailed = (error) => ({
  type: DELETE_TICKET_GOOGLE_SHEET_FAILED,
  error
});
