import {
  OCCASION_ALARM_CREATE,
  OCCASION_ALARM_CREATE_FAILED,
  OCCASION_ALARM_CREATE_SUCCESS,
  OCCASION_ALARM_VIEW,
  OCCASION_ALARM_VIEW_FAILED,
  OCCASION_ALARM_VIEW_SUCCESS,
  UPDATE_ALARM,
  UPDATE_ALARM_FAILED,
  UPDATE_ALARM_SUCCESS,
  DELETE_ALARM,
  DELETE_ALARM_FAILED,
  DELETE_ALARM_SUCCESS
} from '../constants/actionTypes';
export const occasionAlarmCreate = (ocId, formData, callback) => ({
  type: OCCASION_ALARM_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionAlarmCreateSuccess = (data, included, ocId) => ({
  type: OCCASION_ALARM_CREATE_SUCCESS,
  payload: { data, included, ocId }
});

export const occasionAlarmCreateFailed = (error) => ({
  type: OCCASION_ALARM_CREATE_FAILED,
  error
});

export const occasionAlarmView = (ocId, page) => ({
  type: OCCASION_ALARM_VIEW,
  payload: { ocId, page }
});

export const occasionAlarmViewSuccess = (data, included, ocId, links) => ({
  type: OCCASION_ALARM_VIEW_SUCCESS,
  payload: { data, included, ocId, links }
});

export const occasionAlarmViewFailed = (error) => ({
  type: OCCASION_ALARM_VIEW_FAILED,
  error
});

export const updateAlarm = (ocId, alarmId, formData, callback) => ({
  type: UPDATE_ALARM,
  payload: { ocId, alarmId, formData },
  callback
});

export const updateAlarmSuccess = (ocId, data, included) => ({
  type: UPDATE_ALARM_SUCCESS,
  payload: { ocId, data, included }
});

export const updateAlarmFailed = (error) => ({
  type: UPDATE_ALARM_FAILED,
  error
});

export const deleteAlarm = (ocId, alarmId, callback) => ({
  type: DELETE_ALARM,
  payload: { ocId, alarmId },
  callback
});

export const deleteAlarmSuccess = (ocId, data) => ({
  type: DELETE_ALARM_SUCCESS,
  payload: { ocId, data }
});

export const deleteAlarmFailed = (error) => ({
  type: DELETE_ALARM_FAILED,
  error
});
