import { union } from 'lodash';
import {
  CODE_CREATE_FAILED,
  CODE_CREATE_SUCCESS,
  CODE_VIEW_FAILED,
  CODE_VIEW_SUCCESS,
  DELETE_CODE_SUCCESS,
  DELETE_CODE_FAILED
} from '../constants/actionTypes';

const initialState = {
  occasionCodeData: null,
  error: null,
  occasionCodeLinks: null
};

const occasionCode = (state = initialState, action) => {
  switch (action.type) {
    case CODE_VIEW_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.occasionCodeData || {};
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionCodeData: {
          ...occasionGroupedData,
          [ocId]: [...newIds]
        },
        occasionCodeLinks: links
      };
    }

    case CODE_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionCodeData || {};
      const currentOccasionCodeIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionCodeIds];
      return {
        ...state,
        occasionCodeData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }

    case DELETE_CODE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionCodeData || {};
      const currentOccasionCodeIds = occasionGroupedData[ocId] || [];
      const filteredIds = currentOccasionCodeIds.filter((id) => id !== data.id);

      return {
        ...state,
        occasionCodeData: {
          ...occasionGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case CODE_VIEW_FAILED:
    case CODE_CREATE_FAILED:
    case DELETE_CODE_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default occasionCode;
