import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  occasionScheduleCreateApi,
  occasionScheduleDeleteApi,
  occasionScheduleUpdateApi,
  occasionScheduleViewApi
} from '../api/schedule';
import {
  OCCASION_SCHEDULE_CREATE,
  OCCASION_SCHEDULE_DELETE,
  OCCASION_SCHEDULE_UPDATE,
  OCCASION_SCHEDULE_VIEW
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  occasionScheduleCreateFailed,
  occasionScheduleCreateSuccess,
  occasionScheduleDeleteFailed,
  occasionScheduleDeleteSuccess,
  occasionScheduleUpdateFailed,
  occasionScheduleUpdateSuccess,
  occasionScheduleViewFailed,
  occasionScheduleViewSuccess
} from '../actions/schedule';

export function* occasionScheduleCreateSaga(action) {
  try {
    const result = yield call(
      occasionScheduleCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionScheduleCreateSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionScheduleCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionScheduleCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionScheduleViewSaga(action) {
  try {
    const result = yield call(
      occasionScheduleViewApi,
      action.payload.ocId,
      action.payload.scheduleType,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionScheduleViewSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionScheduleViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionScheduleUpdateSaga(action) {
  try {
    const result = yield call(
      occasionScheduleUpdateApi,
      action.payload.ocId,
      action.payload.scheduleId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionScheduleUpdateSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionScheduleUpdateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionScheduleUpdateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionScheduleDeleteSaga(action) {
  try {
    const result = yield call(
      occasionScheduleDeleteApi,
      action.payload.ocId,
      action.payload.scheduleId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionScheduleDeleteSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionScheduleDeleteFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* scheduleActionWatcher() {
  yield takeLatest(
    OCCASION_SCHEDULE_CREATE,
    progressSaga,
    occasionScheduleCreateSaga
  );
  yield takeLatest(
    OCCASION_SCHEDULE_VIEW,
    progressSaga,
    occasionScheduleViewSaga
  );
  yield takeLatest(
    OCCASION_SCHEDULE_UPDATE,
    progressSaga,
    occasionScheduleUpdateSaga
  );
  yield takeLatest(
    OCCASION_SCHEDULE_DELETE,
    progressSaga,
    occasionScheduleDeleteSaga
  );
}
