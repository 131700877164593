import request from './request';

export function occasionScheduleCreateApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/schedules`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function occasionScheduleViewApi(ocId, scheduleType, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/schedules?schedulable_type=${scheduleType}&page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function occasionScheduleUpdateApi(ocId, scheduleId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/schedules/${scheduleId}`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function occasionScheduleDeleteApi(ocId, scheduleId, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/schedules/${scheduleId}`, {
    method: 'DELETE',
    token
  });
}
