import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { Form, AutoComplete, Image } from 'antd';
import '../styles.scss';
import InputGroup from '../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import { JOIN_AS, STATUS } from './InvitationConstants';
import {
  getOccasionPendingInvitation,
  pendingInvitationSearch
} from '../../../../../actions/Invitation';
import { searchInternalTeamMember } from '../../../../../actions/socianaInternalUser';
import { getSearchedInternalUsers } from '../../../../../selectors/socianaInternalUser';
import { getImageSource } from '../../../../../helpers/imageHelper';

const { Option } = AutoComplete;

function PendingInvitationSearchTab(props) {
  const [getInvitationDetails, setGetInvitationDetails] = React.useState({});
  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);

  const handleGetInvitationDetails = (page) => {
    try {
      props.pendingInvitationSearch(
        props.ocId,
        getInvitationDetails?.joinas,
        getInvitationDetails?.status,
        getInvitationDetails?.invitedby?.id,
        getInvitationDetails?.user?.id,
        (page = 1)
      );
      setGetInvitationDetails({});
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (isInitiallyDisabled) {
          setInitiallyDisabled(false);
        }
        if (type === 'joinas') {
          value = e.target.value;
          name = type;
        }
        if (type === 'status') {
          value = e.target.value;
          name = type;
        }
        if (type === 'invitedby') {
          value = {
            value: e?.value,
            id: e?.key
          };
          name = type;
        }
        if (type === 'user') {
          value = {
            value: e?.value,
            id: e?.key
          };
          name = type;
        }
        const invitationDetailsValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        setGetInvitationDetails({
          ...getInvitationDetails,
          [inputName]: invitationDetailsValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUserSearch = (val) => {
    setGetInvitationDetails({
      ...getInvitationDetails,
      user: { value: val, id: null }
    });
    if (val.length > 0) {
      props.searchInternalTeamMember(val);
    }
  };

  const handleInvitedSearch = (val) => {
    setGetInvitationDetails({
      ...getInvitationDetails,
      invitedby: { value: val, id: null }
    });
    if (val.length > 0) {
      props.searchInternalTeamMember(val);
    }
  };

  const handleReset = (page) => {
    setGetInvitationDetails({});
    props.getOccasionPendingInvitation(props.ocId, (page = 1));
  };
  return (
    <Form
      className="invitation-input-search-container"
      onFinish={handleGetInvitationDetails}>
      <InputGroup
        label="Join As"
        className="invitation-inputbox"
        fieldType="dropdown"
        placeholder="Select"
        options={JOIN_AS}
        name="joinas"
        onChange={handleChange}
        value={getInvitationDetails?.joinas}
      />
      <InputGroup
        label="Status"
        className="invitation-inputbox"
        fieldType="dropdown"
        placeholder="Select"
        options={STATUS}
        name="status"
        onChange={handleChange}
        value={getInvitationDetails?.status}
      />
      <InputGroup
        className="invitation-id-box"
        fieldType="searchbox"
        label="Invited By"
        placeholder="Search"
        notFoundContent="No Data"
        name="invitedby"
        value={getInvitationDetails?.invitedby?.value}
        onSelect={(e, option) => handleChange(option, 'invitedby')}
        onSearch={handleInvitedSearch}>
        {props.searchedInternalUser?.length > 0 &&
          getInvitationDetails?.invitedby?.value?.length > 0 &&
          props.searchedInternalUser.map((item) => {
            return (
              <Option value={item?.name} key={item?.id}>
                <div className="options-container">
                  <p className="avatar-image-container">
                    <Image
                      src={getImageSource(item?.avatarUrl)}
                      preview={false}
                    />
                  </p>
                  <div className="internal-member-occasion-details-container">
                    <p className="internal-member-occasion-name">
                      {item?.name}
                    </p>
                    <p className="internal-member-occasion-type">
                      {item?.email}
                    </p>
                  </div>
                </div>
              </Option>
            );
          })}
      </InputGroup>
      <InputGroup
        className="invitation-id-box"
        fieldType="searchbox"
        label="User"
        placeholder="Search"
        name="user"
        notFoundContent="No Data"
        value={getInvitationDetails?.user?.value}
        onSelect={(e, option) => handleChange(option, 'user')}
        onSearch={handleUserSearch}>
        {props.searchedInternalUser?.length > 0 &&
          getInvitationDetails?.user?.value?.length > 0 &&
          props.searchedInternalUser.map((item) => {
            return (
              <Option value={item?.name} key={item?.id}>
                <div className="options-container">
                  <p className="avatar-image-container">
                    <Image
                      src={getImageSource(item?.avatarUrl)}
                      preview={false}
                    />
                  </p>
                  <div className="internal-member-occasion-details-container">
                    <p className="internal-member-occasion-name">
                      {item?.name}
                    </p>
                    <p className="internal-member-occasion-type">
                      {item?.email}
                    </p>
                  </div>
                </div>
              </Option>
            );
          })}
      </InputGroup>
      <div className="invitation-button-box">
        <SubmitButton
          className="invitation-submit-inputbox"
          buttonText="Search"
          fetchProgress={props.fetchProgress}
          disabled={props.fetchProgress}
        />
        <Button className="reset-button" onClick={handleReset}>
          Reset
        </Button>
      </div>
    </Form>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  searchedInternalUser: getSearchedInternalUsers(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOccasionPendingInvitation,
      pendingInvitationSearch,
      searchInternalTeamMember
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingInvitationSearchTab);
