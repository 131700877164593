import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import {
  getHospitalityGoogleSheet,
  hospitalityGoogleSheetCreate,
  hospitalityGoogleSheetRegenerate,
  hospitalityGoogleSheetSync,
  deleteHospitalityGoogleSheet
} from '../../../../../../actions/hospitalityGoogleSheet';
import { getHospitalityGoogleSheetData } from '../../../../../../selectors/hospitalityGoogleSheet';

const HospitalityGoogleSheetView = (props) => {
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.getHospitalityGoogleSheet(props.occasionId, page);
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleCreate = () => {
    props.hospitalityGoogleSheetCreate(props.occasionId);
  };

  const handleRegenerate = () => {
    props.hospitalityGoogleSheetRegenerate(props.occasionId);
  };

  const handleSync = () => {
    props.hospitalityGoogleSheetSync(props.occasionId);
  };

  const deleteData = (id) => {
    props.deleteHospitalityGoogleSheet(props.occasionId, id);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="google-sheet-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        {props.hospitalityGoogleSheetData?.length > 0 ? (
          <>
            <Button className="create-button" onClick={handleRegenerate}>
              Regenerate Google Sheet
            </Button>
            <Button className="create-button" onClick={handleSync}>
              Sync Google Sheet
            </Button>
          </>
        ) : (
          <Button className="create-button" onClick={handleCreate}>
            Create Google Sheet
          </Button>
        )}
      </div>
    )
  };

  return (
    <ErrorBoundary>
      <SectionContent className="hospitality-google-sheet-view-section">
        <div className="hospitality-google-sheet-view-container">
          <TableView
            isPaginated={true}
            paginationOptions={paginationOptions}
            isRouted={false}
            onPageChange={onPageChange}
            onDelete={deleteData}
            data={props.hospitalityGoogleSheetData}
            fetchProgress={props.fetchProgress}
            paginationLinks={props.hospitalityGoogleSheetLinks}
            blacklistKeys={['modelId', 'modelType']}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  hospitalityGoogleSheetData: getHospitalityGoogleSheetData(store, props),
  hospitalityGoogleSheetLinks:
    store.hospitalityGoogleSheet.hospitalityGoogleSheetLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    {
      getHospitalityGoogleSheet,
      hospitalityGoogleSheetCreate,
      hospitalityGoogleSheetRegenerate,
      hospitalityGoogleSheetSync,
      deleteHospitalityGoogleSheet
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(HospitalityGoogleSheetView);
