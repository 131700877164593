import { createSelector } from 'reselect';
import { codeEntities, userEntities } from './entity';
import { codeIds } from './static';
import { currentOccasionId, currentCodeId } from './dynamicData';

export const currentCodeIds = createSelector(
  currentOccasionId,
  codeIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getAllRoleNameData = createSelector(
  codeEntities,
  currentCodeIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const occasionCodeWithUserData = createSelector(
  getAllRoleNameData,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      createdBy: { ...entities[item?.createdBy?.id] }
    }))
);

export const currentOccasionCodeData = createSelector(
  currentCodeId,
  codeEntities,
  (id, entity) => id && entity && entity[id]
);
