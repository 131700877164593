import { REQUEST_CLEAR_STORE } from '../constants/actionTypes';

export const startLogin = (payload, callback) => ({
  type: 'START_LOGIN',
  payload,
  callback
});

export const loginSuccessful = (payload) => ({
  type: 'LOGIN_SUCCESS',
  payload
});

export const logOutSuccessfull = (payload) => ({
  type: 'LOGOUT_SUCCESS',
  payload
});

export const loginFailure = (payload) => ({
  type: 'LOGIN_FAILURE',
  payload
});

export const startLogout = (callback) => ({
  type: 'START_LOGOUT',
  callback
});

export const requestClearStore = () => ({
  type: REQUEST_CLEAR_STORE
});
