import {
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAILED,
  GET_ADMIN_PAGINATION_SUCCESS,
  GET_ADMIN_PAGINATION_FAILED,
  GET_ADMIN_OCCASION_MEMBER_SUCCESS,
  EDIT_ADMIN_FAILED,
  EDIT_ADMIN_SUCCESS,
  GET_ADMIN_BY_SEARCH_SUCCESS,
  CREATE_ADMIN_OCCASION_SUCCESS,
  DELETE_ADMIN_OCCASION_MEMBER_SUCCESS,
  DELETE_ADMIN_OCCASION_MEMBER_FAILED
} from '../constants/actionTypes';

const initialState = {
  activeIds: null,
  inactiveIds: null,
  error: null,
  links: null,
  adminOccasionsData: null,
  adminOccasionsLinks: null,
  count: 0,
  adminOccasionsMessage: null,
  adminOccasionsCount: null
};

const admin = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_PAGINATION_FAILED:
    case DELETE_ADMIN_OCCASION_MEMBER_FAILED:
    case EDIT_ADMIN_FAILED:
    case CREATE_ADMIN_FAILED: {
      return { ...state, error: action.error };
    }

    case CREATE_ADMIN_SUCCESS:
    case EDIT_ADMIN_SUCCESS: {
      return {
        ...state
      };
    }

    case GET_ADMIN_OCCASION_MEMBER_SUCCESS: {
      const { data, links, adminId, meta } = action.payload;
      const adminGroupData = state.adminOccasionsData || {};
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];
      return {
        ...state,
        adminOccasionsData: { ...adminGroupData, [adminId]: [...newIds] },
        adminOccasionsLinks: links,
        adminOccasionsMessage: meta?.message,
        adminOccasionsCount: meta?.totalCount
      };
    }

    case CREATE_ADMIN_OCCASION_SUCCESS: {
      const { data, adminId } = action.payload;
      const adminGroupData = state.adminOccasionsData || {};
      const currentAdminOccasionIds = adminGroupData[adminId] || [];

      const newIds = [data.id, ...currentAdminOccasionIds];
      return {
        ...state,
        adminOccasionsData: { ...adminGroupData, [adminId]: newIds }
      };
    }

    case DELETE_ADMIN_OCCASION_MEMBER_SUCCESS: {
      const { data, links, adminId, meta } = action.payload;
      const adminGroupData = state.adminOccasionsData || {};
      const currentAdminOccasionIds = adminGroupData[adminId] || [];
      const filterIds = currentAdminOccasionIds?.filter((id) => id !== data.id);
      return {
        ...state,
        adminOccasionsData: { ...adminGroupData, [adminId]: filterIds }
      };
    }

    case GET_ADMIN_PAGINATION_SUCCESS:
    case GET_ADMIN_BY_SEARCH_SUCCESS: {
      const { data, links, isActive, meta } = action.payload;
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];
      return {
        ...state,
        activeIds: isActive ? newIds : state.activeIds || [],
        inactiveIds: !isActive ? newIds : state.inactiveIds || [],
        links,
        count: meta?.totalCount || 0
      };
    }

    default:
      return state;
  }
};

export default admin;
