import {
  HOSPITALITY_TASK_CREATE,
  HOSPITALITY_TASK_CREATE_SUCCESS,
  HOSPITALITY_TASK_CREATE_FAILED,
  HOSPITALITY_TASK_VIEW,
  HOSPITALITY_TASK_VIEW_SUCCESS,
  HOSPITALITY_TASK_VIEW_FAILED,
  HOSPITALITY_TASK_CREATE_UPDATE,
  HOSPITALITY_TASK_CREATE_UPDATE_FAILED,
  HOSPITALITY_TASK_CREATE_UPDATE_SUCCESS,
  HOSPITALITY_TASK_RESPONDED_VIEW,
  HOSPITALITY_TASK_RESPONDED_VIEW_SUCCESS,
  HOSPITALITY_TASK_RESPONDED_VIEW_FAILED,
  HOSPITALITY_TASK_NOTRESPONDED_VIEW,
  HOSPITALITY_TASK_NOTRESPONDED_VIEW_SUCCESS,
  HOSPITALITY_TASK_NOTRESPONDED_VIEW_FAILED,
  HOSPITALITY_TASK_PROVIDED_VIEW,
  HOSPITALITY_TASK_PROVIDED_VIEW_FAILED,
  HOSPITALITY_TASK_PROVIDED_VIEW_SUCCESS,
  HOSPITALITY_TASK_NOTPROVIDED_VIEW,
  HOSPITALITY_TASK_NOTPROVIDED_VIEW_FAILED,
  HOSPITALITY_TASK_NOTPROVIDED_VIEW_SUCCESS,
  HOSPITALITY_PROVIDED_TASK_ANSWER_DOWNLOAD,
  HOSPITALITY_PROVIDED_TASK_ANSWER_DOWNLOAD_SUCCESS,
  HOSPITALITY_PROVIDED_TASK_ANSWER_DOWNLOAD_FAILED,
  HOSPITALITY_REQUESTED_TASK_ANSWER_DOWNLOAD,
  HOSPITALITY_REQUESTED_TASK_ANSWER_DOWNLOAD_SUCCESS,
  HOSPITALITY_REQUESTED_TASK_ANSWER_DOWNLOAD_FAILED,
  HOSPITALITY_TASK_DELETE,
  HOSPITALITY_TASK_DELETE_FAILED,
  HOSPITALITY_TASK_DELETE_SUCCESS,
  HOSPITALITY_PROVIDED_TASK_ANSWER_DELETE,
  HOSPITALITY_PROVIDED_TASK_ANSWER_DELETE_SUCCESS,
  HOSPITALITY_PROVIDED_TASK_ANSWER_DELETE_FAILED,
  HOSPITALITY_REQUESTED_TASK_ANSWER_DELETE,
  HOSPITALITY_REQUESTED_TASK_ANSWER_DELETE_SUCCESS,
  HOSPITALITY_REQUESTED_TASK_ANSWER_DELETE_FAILED,
  HOSPITALITY_TASK_PROVIDE_ANSWER,
  HOSPITALITY_TASK_PROVIDE_ANSWER_SUCCESS,
  HOSPITALITY_TASK_PROVIDE_ANSWER_FAILED,
  GET_ALL_TASKS,
  GET_ALL_TASKS_FAILED,
  GET_ALL_TASKS_SUCCESS,
  SEARCH_NOTPROVIDED_TASKS,
  SEARCH_NOTPROVIDED_TASKS_FAILED,
  SEARCH_NOTPROVIDED_TASKS_SUCCESS,
  SEARCH_PROVIDED_TASKS,
  SEARCH_PROVIDED_TASKS_FAILED,
  SEARCH_PROVIDED_TASKS_SUCCESS,
  SEARCH_RESPONDED_TASKS,
  SEARCH_RESPONDED_TASKS_FAILED,
  SEARCH_RESPONDED_TASKS_SUCCESS,
  SEARCH_NOTRESPONDED_TASKS,
  SEARCH_NOTRESPONDED_TASKS_FAILED,
  SEARCH_NOTRESPONDED_TASKS_SUCCESS
} from '../constants/actionTypes';

export const searchNotRespondedTasks = (
  ocId,
  hospitalityTaskId,
  taskData,
  page,
  callback
) => ({
  type: SEARCH_NOTRESPONDED_TASKS,
  payload: { ocId, hospitalityTaskId, taskData, page },
  callback
});

export const searchNotRespondedTasksSuccess = (
  hospitalityTaskId,
  data,
  included,
  links,
  meta
) => ({
  type: SEARCH_NOTRESPONDED_TASKS_SUCCESS,
  payload: { hospitalityTaskId, data, included, links, meta }
});

export const searchNotRespondedTasksFailed = (error) => ({
  type: SEARCH_NOTRESPONDED_TASKS_FAILED,
  error
});

export const searchRespondedTasks = (
  ocId,
  hospitalityTaskId,
  taskData,
  page,
  callback
) => ({
  type: SEARCH_RESPONDED_TASKS,
  payload: { ocId, hospitalityTaskId, taskData, page },
  callback
});

export const searchRespondedTasksSuccess = (
  hospitalityTaskId,
  data,
  included,
  links,
  meta
) => ({
  type: SEARCH_RESPONDED_TASKS_SUCCESS,
  payload: { hospitalityTaskId, data, included, links, meta }
});

export const searchRespondedTasksFailed = (error) => ({
  type: SEARCH_RESPONDED_TASKS_FAILED,
  error
});

export const searchProvidedTasks = (
  ocId,
  hospitalityTaskId,
  taskData,
  page,
  callback
) => ({
  type: SEARCH_PROVIDED_TASKS,
  payload: { ocId, hospitalityTaskId, taskData, page },
  callback
});

export const searchProvidedTasksSuccess = (
  hospitalityTaskId,
  data,
  included,
  links,
  meta
) => ({
  type: SEARCH_PROVIDED_TASKS_SUCCESS,
  payload: { hospitalityTaskId, data, included, links, meta }
});

export const searchProvidedTasksFailed = (error) => ({
  type: SEARCH_PROVIDED_TASKS_FAILED,
  error
});

export const searchNotProvidedTasks = (
  ocId,
  hospitalityTaskId,
  taskData,
  page,
  callback
) => ({
  type: SEARCH_NOTPROVIDED_TASKS,
  payload: { ocId, hospitalityTaskId, taskData, page },
  callback
});

export const searchNotProvidedTasksSuccess = (
  hospitalityTaskId,
  data,
  included,
  links,
  meta
) => ({
  type: SEARCH_NOTPROVIDED_TASKS_SUCCESS,
  payload: { hospitalityTaskId, data, included, links, meta }
});

export const searchNotProvidedTasksFailed = (error) => ({
  type: SEARCH_NOTPROVIDED_TASKS_FAILED,
  error
});

export const hospitalityTaskCreate = (
  ocId,
  formData,
  hospitalityCategoryId,
  callback
) => ({
  type: HOSPITALITY_TASK_CREATE,
  payload: { ocId, formData, hospitalityCategoryId },
  callback
});

export const hospitalityTaskCreateSuccess = (
  data,
  included,
  hospitalityCategoryId
) => ({
  type: HOSPITALITY_TASK_CREATE_SUCCESS,
  payload: { data, included, hospitalityCategoryId }
});

export const hospitalityTaskCreateFailed = (error) => ({
  type: HOSPITALITY_TASK_CREATE_FAILED,
  error
});

export const hospitalityTaskView = (ocId, page, hospitalityCategoryId) => ({
  type: HOSPITALITY_TASK_VIEW,
  payload: { ocId, page, hospitalityCategoryId }
});

export const hospitalityTaskViewSuccess = (
  data,
  included,
  links,
  hospitalityCategoryId
) => ({
  type: HOSPITALITY_TASK_VIEW_SUCCESS,
  payload: { data, included, links, hospitalityCategoryId }
});

export const hospitalityTaskViewFailed = (error) => ({
  type: HOSPITALITY_TASK_VIEW_FAILED,
  error
});

export const getAllTasks = (ocId, page) => ({
  type: GET_ALL_TASKS,
  payload: { ocId, page }
});

export const getAllTasksSuccess = (data, included, links, ocId) => ({
  type: GET_ALL_TASKS_SUCCESS,
  payload: { data, included, links, ocId }
});

export const getAllTasksFailed = (error) => ({
  type: GET_ALL_TASKS_FAILED,
  error
});

export const hospitalityTaskCreateUpdate = (
  ocId,
  hospitalityTaskId,
  formData,
  hospitalityCategoryId,
  callback
) => ({
  type: HOSPITALITY_TASK_CREATE_UPDATE,
  payload: { ocId, hospitalityTaskId, formData, hospitalityCategoryId },
  callback
});

export const hospitalityTaskCreateUpdateSuccess = (
  ocId,
  hospitalityCategoryId,
  data,
  included
) => ({
  type: HOSPITALITY_TASK_CREATE_UPDATE_SUCCESS,
  payload: { ocId, hospitalityCategoryId, data, included }
});

export const hospitalityTaskCreateUpdateFailed = (error) => ({
  type: HOSPITALITY_TASK_CREATE_UPDATE_FAILED,
  error
});

export const hospitalityTaskRespondedView = (
  ocId,
  hospitalityTaskId,
  page
) => ({
  type: HOSPITALITY_TASK_RESPONDED_VIEW,
  payload: { ocId, hospitalityTaskId, page }
});

export const hospitalityTaskRespondedViewSuccess = (
  hospitalityTaskId,
  data,
  included,
  links
) => ({
  type: HOSPITALITY_TASK_RESPONDED_VIEW_SUCCESS,
  payload: { hospitalityTaskId, data, included, links }
});

export const hospitalityTaskRespondedViewFailed = (error) => ({
  type: HOSPITALITY_TASK_RESPONDED_VIEW_FAILED,
  error
});

export const hospitalityTaskNotRespondedView = (
  ocId,
  hospitalityTaskId,
  page
) => ({
  type: HOSPITALITY_TASK_NOTRESPONDED_VIEW,
  payload: { ocId, hospitalityTaskId, page }
});

export const hospitalityTaskNotRespondedViewSuccess = (
  hospitalityTaskId,
  data,
  included,
  links
) => ({
  type: HOSPITALITY_TASK_NOTRESPONDED_VIEW_SUCCESS,
  payload: { hospitalityTaskId, data, included, links }
});

export const hospitalityTaskNotRespondedViewFailed = (error) => ({
  type: HOSPITALITY_TASK_NOTRESPONDED_VIEW_FAILED,
  error
});

export const hospitalityTaskProvidedView = (ocId, hospitalityTaskId, page) => ({
  type: HOSPITALITY_TASK_PROVIDED_VIEW,
  payload: { ocId, hospitalityTaskId, page }
});

export const hospitalityTaskProvidedViewSuccess = (
  hospitalityTaskId,
  data,
  included,
  links
) => ({
  type: HOSPITALITY_TASK_PROVIDED_VIEW_SUCCESS,
  payload: { hospitalityTaskId, data, included, links }
});

export const hospitalityTaskProvidedViewFailed = (error) => ({
  type: HOSPITALITY_TASK_PROVIDED_VIEW_FAILED,
  error
});

export const hospitalityTaskNotProvidedView = (
  ocId,
  hospitalityTaskId,
  page
) => ({
  type: HOSPITALITY_TASK_NOTPROVIDED_VIEW,
  payload: { ocId, hospitalityTaskId, page }
});

export const hospitalityTaskNotProvidedViewSuccess = (
  hospitalityTaskId,
  data,
  included,
  links
) => ({
  type: HOSPITALITY_TASK_NOTPROVIDED_VIEW_SUCCESS,
  payload: { hospitalityTaskId, data, included, links }
});

export const hospitalityTaskNotProvidedViewFailed = (error) => ({
  type: HOSPITALITY_TASK_NOTPROVIDED_VIEW_FAILED,
  error
});

export const hospitalityRequestedTaskDownload = (ocId, taskId, callback) => {
  return {
    type: HOSPITALITY_REQUESTED_TASK_ANSWER_DOWNLOAD,
    payload: { ocId, taskId },
    callback
  };
};

export const hospitalityRequestedTaskDownloadSuccess = (data, included) => {
  return {
    type: HOSPITALITY_REQUESTED_TASK_ANSWER_DOWNLOAD_SUCCESS,
    payload: { data, included }
  };
};

export const hospitalityRequestedTaskDownloadFailed = (error) => {
  return {
    type: HOSPITALITY_REQUESTED_TASK_ANSWER_DOWNLOAD_FAILED,
    error
  };
};

export const hospitalityProvidedTaskDownload = (ocId, taskId, callback) => {
  return {
    type: HOSPITALITY_PROVIDED_TASK_ANSWER_DOWNLOAD,
    payload: { ocId, taskId },
    callback
  };
};

export const hospitalityProvidedTaskDownloadSuccess = (data, included) => {
  return {
    type: HOSPITALITY_PROVIDED_TASK_ANSWER_DOWNLOAD_SUCCESS,
    payload: { data, included }
  };
};

export const hospitalityProvidedTaskDownloadFailed = (error) => {
  return {
    type: HOSPITALITY_PROVIDED_TASK_ANSWER_DOWNLOAD_FAILED,
    error
  };
};

export const hospitalityTaskDelete = (
  ocId,
  hospitalityTaskId,
  hospitalityCategoryId,
  callback
) => ({
  type: HOSPITALITY_TASK_DELETE,
  payload: { ocId, hospitalityTaskId, hospitalityCategoryId },
  callback
});

export const hospitalityTaskDeleteSuccess = (
  ocId,
  hospitalityCategoryId,
  data,
  included
) => ({
  type: HOSPITALITY_TASK_DELETE_SUCCESS,
  payload: { ocId, hospitalityCategoryId, data, included }
});

export const hospitalityTaskDeleteFailed = (error) => ({
  type: HOSPITALITY_TASK_DELETE_FAILED,
  error
});

export const hospitalityProvidedTaskDelete = (
  ocId,
  hospitalityTaskId,
  answerId,
  callback
) => ({
  type: HOSPITALITY_PROVIDED_TASK_ANSWER_DELETE,
  payload: { ocId, hospitalityTaskId, answerId },
  callback
});

export const hospitalityProvidedTaskDeleteSuccess = (
  hospitalityTaskId,
  data,
  included
) => ({
  type: HOSPITALITY_PROVIDED_TASK_ANSWER_DELETE_SUCCESS,
  payload: { hospitalityTaskId, data, included }
});

export const hospitalityProvidedTaskDeleteFailed = (error) => ({
  type: HOSPITALITY_PROVIDED_TASK_ANSWER_DELETE_FAILED,
  error
});

export const hospitalityRequestedTaskDelete = (
  ocId,
  hospitalityTaskId,
  answerId,
  callback
) => ({
  type: HOSPITALITY_REQUESTED_TASK_ANSWER_DELETE,
  payload: { ocId, hospitalityTaskId, answerId },
  callback
});

export const hospitalityRequestedTaskDeleteSuccess = (
  ocId,
  hospitalityTaskId,
  data,
  included
) => ({
  type: HOSPITALITY_REQUESTED_TASK_ANSWER_DELETE_SUCCESS,
  payload: { ocId, hospitalityTaskId, data, included }
});

export const hospitalityRequestedTaskDeleteFailed = (error) => ({
  type: HOSPITALITY_REQUESTED_TASK_ANSWER_DELETE_FAILED,
  error
});

export const hospitalityTaskProvideAnswer = (
  ocId,
  hospitalityTaskId,
  hospitalityCategoryId,
  formData,
  isCSV,
  sendToAll,
  type,
  callback
) => ({
  type: HOSPITALITY_TASK_PROVIDE_ANSWER,
  payload: {
    ocId,
    hospitalityTaskId,
    hospitalityCategoryId,
    isCSV,
    formData,
    sendToAll,
    type
  },
  callback
});

export const hospitalityTaskProvideAnswerSuccess = (
  ocId,
  hospitalityTaskId,
  data,
  included,
  isSingleAnswer
) => ({
  type: HOSPITALITY_TASK_PROVIDE_ANSWER_SUCCESS,
  payload: { ocId, hospitalityTaskId, data, included, isSingleAnswer }
});

export const hospitalityTaskProvideAnswerFailed = (error) => ({
  type: HOSPITALITY_TASK_PROVIDE_ANSWER_FAILED,
  error
});
