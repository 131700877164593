import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import { RESET_EVENT_MEMBERS } from '../../../../../../constants/actionTypes';
import TableView from '../../../../../../components/Table';
import MemberSearch from './search';
import {
  eventCollaboratorView,
  eventCollaboratorDelete,
  eventCollaboratorSearch
} from '../../../../../../actions/eventCollaborator';
import {
  eventCollaboratorsWithUserData,
  eventCollaboratorsWithUserDataBySearch
} from '../../../../../../selectors/event';
import { Button } from 'antd';
import CreateCollaborator from './Create';

const ViewCollaborator = (props) => {
  const [memberData, setMemberData] = React.useState({
    info: ''
  });
  const [createOpen, setCreateOpen] = React.useState(false);
  const [collaboratorId, setCollaboratorId] = React.useState(null);
  const [page, setPage] = React.useState(1);

  const dispatch = useDispatch();

  const searchMemberData = () => {
    try {
      if (memberData.info) {
        props.eventCollaboratorSearch(
          props.occasionId,
          1,
          props.eventId,
          memberData
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const resetSearch = () => {
    dispatch({ type: RESET_EVENT_MEMBERS });
    setMemberData({
      info: ''
    });
  };

  const onPageChange = (page) => {
    setPage(page);
    props.eventCollaboratorView(props.occasionId, page, props.eventId);
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleCreate = () => {
    setCollaboratorId('');
    setCreateOpen(true);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="collaborator-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <div className="count-box">
          <span>Total User</span>
          <span className="count">
            {props.isSearch ? props.searchCount : props.count}
          </span>
        </div>
        <Button className="create-button" onClick={handleCreate}>
          Create
        </Button>
      </div>
    )
  };

  const editData = (id) => {
    setCreateOpen(true);
    setCollaboratorId(id);
  };

  const deleteData = (id) => {
    props.eventCollaboratorDelete(props.occasionId, id, props.eventId);
  };

  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="collaborator-view-section">
          <div className="collaborator-view-container">
            <MemberSearch
              memberData={memberData}
              setMemberData={setMemberData}
              searchMemberData={searchMemberData}
              resetSearch={resetSearch}
            />
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              isRouted={false}
              onPageChange={onPageChange}
              onDelete={deleteData}
              isEditable={editData}
              data={
                props.isSearch
                  ? props.eventCollaboratorSearchData
                  : props.eventCollaboratorData
              }
              fetchProgress={props.fetchProgress}
              paginationLinks={
                props.isSearch
                  ? props.eventCollaboratorSearchLinks
                  : props.eventCollaboratorLinks
              }
              blacklistKeys={['event']}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <CreateCollaborator
        occasionId={props.occasionId}
        eventId={props.eventId}
        eventCollaboratorId={collaboratorId}
        handleCloseClick={() => setCreateOpen(false)}
        afterCreatePageRefresh={onPageChange}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  eventCollaboratorData: eventCollaboratorsWithUserData(store, props),
  eventCollaboratorLinks: store.eventCollaborator.eventCollaboratorLinks,
  eventCollaboratorSearchData: eventCollaboratorsWithUserDataBySearch(
    store,
    props
  ),
  eventCollaboratorSearchLinks:
    store.eventCollaborator.eventCollaboratorSearchLinks,
  isSearch: store.eventCollaborator.isSearch,
  count: store.eventCollaborator.eventCollaboratorCount,
  searchCount: store.eventCollaborator.eventCollaboratorSearchCount
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { eventCollaboratorView, eventCollaboratorDelete, eventCollaboratorSearch },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(ViewCollaborator);
