import { createSelector } from 'reselect';
import {
  currentEventId,
  currentOccasionId,
  currentEventCollaboratorId,
  currentPostId
} from './dynamicData';
import {
  occasionEventsEntities,
  userEntities,
  eventMembersEntities,
  eventPendingInvitationEntities,
  eventPendingRegistrationEntities,
  scheduleEntities,
  eventAffairEntities,
  eventCollaboratorEntities,
  postEntities
} from './entity';
import {
  occasionEventIds,
  occasionAllEventIds,
  eventMembersIds,
  eventPendingInvitationIds,
  eventPendingRegistrationIds,
  eventScheduleIds,
  eventAffairIds,
  eventCollaboratorIds,
  eventCollaboratorSearchIds,
  occasionEventPostIds
} from './static';

import { getCurrentOccasionMembers } from './socianaInternalUser';

export const getCurrentEventPostIds = createSelector(
  currentEventId,
  occasionEventPostIds,
  (ocId, Ids) => ocId && Ids && Ids[ocId]
);
export const getEventPosts = createSelector(
  getCurrentEventPostIds,
  postEntities,
  (ids, entities) =>
    ids && entities && ids?.length > 0 && ids.map((id) => entities[id])
);

export const getCurrentEventPost = createSelector(
  currentPostId,
  postEntities,
  userEntities,
  (id, entities, users) => {
    const user = entities && id && users && users[entities[id]?.user?.id];
    return id && entities && entities[id] && { ...entities[id], user };
  }
);

export const getEventPostsWithPostedByData = createSelector(
  getEventPosts,
  userEntities,
  getCurrentOccasionMembers,
  (data, entities, memDetails) => {
    return (
      data &&
      entities &&
      data?.length > 0 &&
      data.map((item) => {
        const postedByDetails = entities[item?.user?.id];
        const postedByCollaboratorData = memDetails?.filter(
          (item) => item?.userId == postedByDetails?.id
        );
        return {
          ...item,
          postedByDetails: {
            ...postedByDetails,
            roleName:
              (postedByCollaboratorData &&
                postedByCollaboratorData[0]?.roleName) ||
              ''
          }
        };
      })
    );
  }
);

export const currentOccasionEventIds = createSelector(
  currentOccasionId,
  occasionEventIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getAllOccasionEvents = createSelector(
  occasionEventsEntities,
  currentOccasionEventIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const currentAllOccasionEventIds = createSelector(
  currentOccasionId,
  occasionAllEventIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getOccasionEventsWithAllData = createSelector(
  occasionEventsEntities,
  currentAllOccasionEventIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getCurrentEvent = createSelector(
  currentEventId,
  occasionEventsEntities,
  (ocId, entities) => ocId && entities && entities[ocId]
);

export const currentEventMemberIds = createSelector(
  currentEventId,
  eventMembersIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getAllEventMembers = createSelector(
  eventMembersEntities,
  currentEventMemberIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const eventMembersWithUserData = createSelector(
  getAllEventMembers,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      user: { ...entities[item?.user?.id] }
    }))
);

export const currentEventPendingInvitationIds = createSelector(
  currentEventId,
  eventPendingInvitationIds,
  (ocId, id) => ocId && id && id[ocId]
);

export const getEventPendingInvitations = createSelector(
  eventPendingInvitationEntities,
  currentEventPendingInvitationIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getEventPendingInvitationsWithUserData = createSelector(
  getEventPendingInvitations,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      user: { ...entities[item?.user?.id] },
      invitedBy: { ...entities[item?.invitedBy?.id] }
    }))
);

export const currentEventPendingRegistrationIds = createSelector(
  currentEventId,
  eventPendingRegistrationIds,
  (ocId, id) => ocId && id && id[ocId]
);

export const getEventPendingRegistrations = createSelector(
  eventPendingRegistrationEntities,
  currentEventPendingRegistrationIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getEventPendingRegistrationsWithUserData = createSelector(
  getEventPendingRegistrations,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      invitedBy: { ...entities[item?.invitedBy?.id] }
    }))
);

export const currentEventScheduleIds = createSelector(
  currentEventId,
  eventScheduleIds,
  (eventId, ids) => eventId && ids && ids[eventId]
);
export const getAllEventSchedules = createSelector(
  scheduleEntities,
  currentEventScheduleIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
export const eventScheduleWithUserData = createSelector(
  getAllEventSchedules,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      createdBy: { ...entities[item?.createdBy?.id] },
      updatedBy: { ...entities[item?.updatedBy?.id] }
    }))
);

export const currentEventAffairIds = createSelector(
  currentEventId,
  eventAffairIds,
  (eventId, ids) => eventId && ids && ids[eventId]
);

export const getAllEventAffairs = createSelector(
  eventAffairEntities,
  currentEventAffairIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const currentEventCollaboratorIds = createSelector(
  currentEventId,
  eventCollaboratorIds,
  (eventId, ids) => eventId && ids && ids[eventId]
);

export const getAllEventCollaborators = createSelector(
  eventCollaboratorEntities,
  currentEventCollaboratorIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const eventCollaboratorsWithUserData = createSelector(
  getAllEventCollaborators,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      user: { ...entities[item?.user?.id] }
    }))
);

export const getCurrentEventCollaborator = createSelector(
  currentEventCollaboratorId,
  eventCollaboratorEntities,
  (id, entities) => id && entities && entities[id]
);

export const currentEventCollaboratorSearchIds = createSelector(
  currentEventId,
  eventCollaboratorSearchIds,
  (eventId, ids) => eventId && ids && ids[eventId]
);

export const getAllEventCollaboratorsSearch = createSelector(
  eventCollaboratorEntities,
  currentEventCollaboratorSearchIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const eventCollaboratorsWithUserDataBySearch = createSelector(
  getAllEventCollaboratorsSearch,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      user: { ...entities[item?.user?.id] }
    }))
);
