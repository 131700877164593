import { Empty, List, Button } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SyncOutlined } from '@ant-design/icons';
import {
  getEventPostWithPagination,
  deleteEventPost
} from '../../../../../../actions/event';
import PostCard from '../../../../../../components/PostCard';
import EventPostCreate from './create';
import './styles.scss';
import { currentOccasionId } from '../../../../../../selectors/dynamicData';
import { getEventPostsWithPostedByData } from '../../../../../../selectors/event';

const EventPostsView = (props) => {
  const [page, setPage] = useState(1);
  const [isEditOpen, setEditOpen] = useState(null);

  const toggleEdit = (id) => {
    setEditOpen(id);
  };

  useEffect(() => {
    props.getEventPostWithPagination(props.occasionId, props.eventId, page);
  }, [page]);

  const onPageRefresh = (page) => {
    props.getEventPostWithPagination(props.occasionId, props.eventId, page);
  };

  const onEdit = (id) => {
    toggleEdit(id);
  };

  const onDelete = (id) => {
    props.deleteEventPost(props.occasionId, props.eventId, 'Event', id);
  };

  const postRenderItem = (item) => (
    <PostCard
      id={item?.id}
      key={item?.id}
      totalComment={item?.totalComment}
      hashTag={item?.hashTag}
      pictureUrl={item?.pictureUrl}
      message={item?.message}
      postedByDetails={item?.postedByDetails}
      cdnPictureUrl={item?.cdnPictureUrl}
      videoUrls={item?.videoUrls}
      cdnVideoUrls={item?.cdnPictureUrls}
      likeCount={item?.likeCount}
      occasionId={props.occasionId}
      onDelete={onDelete}
      onEdit={onEdit}
      disableLike={true}
      disableComment={true}
      createdAt={item?.createdAt}
    />
  );

  const getNextPage = (str) => {
    const str1 = str.split('=');
    const res = str1[2].split('&');
    return parseInt(res[0], 10);
  };

  const onLoadMore = () => {
    if (!!props.postLinks?.next) {
      const pageNumber = getNextPage(props?.postLinks?.next);
      setPage(pageNumber);
    }
  };

  const loadMore = useMemo(() => {
    return !!props.postLinks?.next ? (
      <div className="load-more">
        <Button onClick={onLoadMore}>loading more</Button>
      </div>
    ) : null;
  }, [props?.postLinks?.next]);

  if (!isEditOpen)
    return (
      <div className="post-main-container">
        <div className="post-container">
          <div className="button-box">
            <Button
              className="refresh-button"
              onClick={() => onPageRefresh(page)}>
              <SyncOutlined />
            </Button>
          </div>
          {props.data?.length > 0 ? (
            <List
              dataSource={props?.data}
              renderItem={postRenderItem}
              loading={props.fetchProgress}
              loadMore={loadMore}
            />
          ) : (
            <Empty />
          )}
        </div>
      </div>
    );

  return (
    <div className="post-container">
      <EventPostCreate
        postId={isEditOpen}
        occasionId={props.occasionId}
        toggleEdit={toggleEdit}
        postableType="Occasion"
      />
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  occasionId: currentOccasionId(store, props),
  data: getEventPostsWithPostedByData(store, props),
  fetchProgress: store.browserState.fetchProgress,
  postLinks: store.event.eventPostLinks
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getEventPostWithPagination, deleteEventPost },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EventPostsView);
