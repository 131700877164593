import { createSelector } from 'reselect';
import {
  currentEventAffairId,
  currentEventAffairEssenceId,
  currentEventAffairEntertainmentId,
  currentEventAffairFoodId
} from './dynamicData';
import {
  eventAffairEntities,
  eventAffairEssenceEntities,
  eventAffairEntertainmentEntities,
  eventAffairFoodEntities
} from './entity';
import {
  eventAffairEssenceIds,
  eventAffairEntertainmentIds,
  eventAffairFoodIds
} from './static';

export const getCurrentEventAffair = createSelector(
  currentEventAffairId,
  eventAffairEntities,
  (id, entities) => id && entities && entities[id]
);

export const currentEventAffairEssenceIds = createSelector(
  currentEventAffairId,
  eventAffairEssenceIds,
  (eventAffairId, ids) => eventAffairId && ids && ids[eventAffairId]
);
export const getAllEventAffairEssences = createSelector(
  eventAffairEssenceEntities,
  currentEventAffairEssenceIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getCurrentEventAffairEssence = createSelector(
  currentEventAffairEssenceId,
  eventAffairEssenceEntities,
  (id, entities) => id && entities && entities[id]
);

export const currentEventAffairEntertainmentIds = createSelector(
  currentEventAffairId,
  eventAffairEntertainmentIds,
  (eventAffairId, ids) => eventAffairId && ids && ids[eventAffairId]
);
export const getAllEventAffairEntertainments = createSelector(
  eventAffairEntertainmentEntities,
  currentEventAffairEntertainmentIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getCurrentEventAffairEntertainment = createSelector(
  currentEventAffairEntertainmentId,
  eventAffairEntertainmentEntities,
  (id, entities) => id && entities && entities[id]
);

export const currentEventAffairFoodIds = createSelector(
  currentEventAffairId,
  eventAffairFoodIds,
  (eventAffairId, ids) => eventAffairId && ids && ids[eventAffairId]
);
export const getAllEventAffairFoods = createSelector(
  eventAffairFoodEntities,
  currentEventAffairFoodIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getCurrentEventAffairFood = createSelector(
  currentEventAffairFoodId,
  eventAffairFoodEntities,
  (id, entities) => id && entities && entities[id]
);
