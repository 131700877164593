import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Show from '../containers/PageRoutes/Occasion/Details/LiveEvent/Show';

const LiveShowRoute = () => {
  return (
    <>
      <Switch>
        <Route path={`/stream_live`} component={Show} />
      </Switch>
    </>
  );
};

export default withRouter(LiveShowRoute);
