import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Button } from 'antd';
import './styles.scss';
import { CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import {
  occasionRoleNameCreate,
  updateRoleName
} from '../../../../../actions/roleName';
import { currentRoleNameData } from '../../../../../selectors/roleName';

const validateRoleNameData = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Role Name is required';
  }
  return errors;
};

const RoleNameCreate = (props) => {
  const [roleNameData, setRoleNameData] = useState({
    name: props.currentRoleNameData ? props.currentRoleNameData.roleName : '',
    current_status: props.currentRoleNameData
      ? props.currentRoleNameData.active
      : true
  });
  const [errorMessages, setErrorMessages] = useState({});
  const handleSubmit = () => {
    try {
      const validateObj = validateRoleNameData(roleNameData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('occasion_role_name[role_name]', roleNameData?.name);
        props.roleNameId
          ? formData.append(
              'occasion_role_name[active]',
              roleNameData?.current_status ? true : false
            )
          : '';
        props.roleNameId
          ? props.updateRoleName(props.occasionId, props.roleNameId, formData)
          : props.occasionRoleNameCreate(props.occasionId, formData, () => {
              setRoleNameData({});
            });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let formValue;
      let inputName;

      if (type === 'current_status') {
        value = e;
        name = type;
      }

      formValue = value || e?.target?.value;
      inputName = name || e?.target?.name;

      setRoleNameData({
        ...roleNameData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="occasion-role-name-create-section">
        <div className="occasion-role-name-create-form">
          <Form
            className="occassion-role-name-form-container"
            onFinish={handleSubmit}>
            <InputGroup
              name="name"
              placeholder=" Enter Role Name"
              label="Role Name"
              value={roleNameData?.name}
              onChange={handleInputChange}
              errorMessage={errorMessages.name}
            />
            {props.roleNameId ? (
              <InputGroup
                label="Current status"
                defaultChecked={roleNameData?.current_status}
                name="current_status"
                onChange={handleInputChange}
                fieldType="switch"
              />
            ) : (
              ''
            )}
            <SubmitButton
              buttonText={props.roleNameId ? 'Save Changes' : 'Create'}
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentRoleNameData: currentRoleNameData(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ occasionRoleNameCreate, updateRoleName }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RoleNameCreate);
