import request from './request';

export function login(payload) {
  return request('api/v2/sociana_admin/sessions', {
    method: 'POST',
    body: {
      login: {
        email_or_mobile: payload.email,
        password: payload.password
      }
    }
  });
}
