import {
  OCCASION_SOS_CREATE,
  OCCASION_SOS_CREATE_SUCCESS,
  OCCASION_SOS_CREATE_FAILED,
  OCCASION_SOS_VIEW,
  OCCASION_SOS_VIEW_SUCCESS,
  OCCASION_SOS_VIEW_FAILED,
  OCCASION_SOS_DELETE,
  OCCASION_SOS_DELETE_FAILED,
  OCCASION_SOS_DELETE_SUCCESS,
  OCCASION_SOS_UPDATE,
  OCCASION_SOS_UPDATE_FAILED,
  OCCASION_SOS_UPDATE_SUCCESS
} from '../constants/actionTypes';

export const occasionSosCreate = (ocId, formData, callback) => ({
  type: OCCASION_SOS_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionSosCreateSuccess = (data, ocId, included, links) => ({
  type: OCCASION_SOS_CREATE_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionSosCreateFailed = (error) => ({
  type: OCCASION_SOS_CREATE_FAILED,
  error
});

export const occasionSosView = (ocId, page) => ({
  type: OCCASION_SOS_VIEW,
  payload: { ocId, page }
});

export const occasionSosViewSuccess = (data, ocId, included, links) => ({
  type: OCCASION_SOS_VIEW_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionSosViewFailed = (error) => ({
  type: OCCASION_SOS_VIEW_FAILED,
  error
});

export const occasionSosDelete = (ocId, sosId) => ({
  type: OCCASION_SOS_DELETE,
  payload: { ocId, sosId }
});

export const occasionSosDeleteSuccess = (data, ocId, included, links) => ({
  type: OCCASION_SOS_DELETE_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionSosDeleteFailed = (error) => ({
  type: OCCASION_SOS_DELETE_FAILED,
  error
});

export const occasionSosUpdate = (ocId, sosId, formData, callback) => ({
  type: OCCASION_SOS_UPDATE,
  payload: { ocId, sosId, formData },
  callback
});

export const occasionSosUpdateSuccess = (data, ocId, included, links) => ({
  type: OCCASION_SOS_UPDATE_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionSosUpdateFailed = (error) => ({
  type: OCCASION_SOS_UPDATE_FAILED,
  error
});
