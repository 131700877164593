import { createSelector } from 'reselect';
import { enquiryEntities } from './entity';
import { enquiryIds } from './static';

export const getAllEnquiry = createSelector(
  enquiryEntities,
  enquiryIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
