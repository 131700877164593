import {
  CREATE_ACCESS_CONTROL,
  CREATE_ACCESS_CONTROL_FAILED,
  CREATE_ACCESS_CONTROL_SUCCESS,
  GET_ACCESS_CONTROLS,
  GET_ACCESS_CONTROLS_SUCCESS,
  GET_ACCESS_CONTROLS_FAILED,
  UPDATE_ACCESS_CONTROL,
  UPDATE_ACCESS_CONTROL_FAILED,
  UPDATE_ACCESS_CONTROL_SUCCESS
} from '../constants/actionTypes';

export const createAccessControl = (ocId, formData, callback) => ({
  type: CREATE_ACCESS_CONTROL,
  payload: { ocId, formData },
  callback
});

export const createAccessControlSuccess = (data) => ({
  type: CREATE_ACCESS_CONTROL_SUCCESS,
  payload: { data }
});

export const createAccessControlFailed = (error) => ({
  type: CREATE_ACCESS_CONTROL_FAILED,
  error
});

export const getAccessControls = (ocId, callback) => ({
  type: GET_ACCESS_CONTROLS,
  payload: { ocId },
  callback
});

export const getAccessControlsSuccess = (data) => ({
  type: GET_ACCESS_CONTROLS_SUCCESS,
  payload: { data }
});

export const getAccessControlsFailed = (error) => ({
  type: GET_ACCESS_CONTROLS_FAILED,
  error
});

export const updateAccessControl = (ocId, acId, formData, callback) => ({
  type: UPDATE_ACCESS_CONTROL,
  payload: { ocId, acId, formData },
  callback
});

export const updateAccessControlSuccess = (data) => ({
  type: UPDATE_ACCESS_CONTROL_SUCCESS,
  payload: { data }
});

export const updateAccessControlFailed = (error) => ({
  type: UPDATE_ACCESS_CONTROL_FAILED,
  error
});
