import request from './request';

export function activityTrackerPresentUserCreateApi(
  ocId,
  trackerId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/activity_tracker/activity_trackers/${trackerId}/activity_tracker_present_users`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function activityTrackerPresentUserViewApi(
  ocId,
  trackerId,
  page,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/activity_tracker/activity_trackers/${trackerId}/activity_tracker_present_users?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function deleteActivityTrackerPresentUserApi(
  ocId,
  trackerId,
  presentUserId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/activity_tracker/activity_trackers/${trackerId}/activity_tracker_present_users/${presentUserId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function searchUserByOccasionApi(ocId, query, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/search_users_by_occasion?q=${query}`,
    {
      method: 'GET',
      token
    }
  );
}
