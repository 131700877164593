import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import { createLive, getLive, showLive } from '../api/live';

import {
  CREATE_LIVE_STREAM,
  GET_LIVE_STREAM,
  SHOW_LIVE_STREAM
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  createLiveSuccess,
  createLiveFailed,
  getLiveSuccess,
  getLiveFailed,
  showLiveSuccess,
  showLiveFailed
} from '../actions/live';

export function* createLiveSaga(action) {
  try {
    const result = yield call(
      createLive,
      action.payload.occasionId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        createLiveSuccess(
          action.payload.occasionId,
          result.data,
          result.included,
          result.meta
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(createLiveFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getLiveSaga(action) {
  try {
    const result = yield call(
      getLive,
      action.payload.occasionId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getLiveSuccess(
          action.payload.occasionId,
          result.data,
          result.included,
          result.links,
          result.meta
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getLiveFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* showLiveSaga(action) {
  let result;
  try {
    result = yield call(
      showLive,
      action.payload.occasionId,
      action.payload.liveId,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        showLiveSuccess(
          action.payload.occasionId,
          result.data,
          result.included,
          result.meta,
          action.payload.liveId
        )
      );
    }
  } catch (error) {
    yield put(showLiveFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } finally {
    if (typeof action.callback === 'function') {
      action.callback(result.meta?.token, result.meta?.uid);
    }
  }
}

export default function* liveActionWatcher() {
  yield takeLatest(CREATE_LIVE_STREAM, progressSaga, createLiveSaga);
  yield takeLatest(GET_LIVE_STREAM, progressSaga, getLiveSaga);
  yield takeLatest(SHOW_LIVE_STREAM, progressSaga, showLiveSaga);
}
