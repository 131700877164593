import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SyncOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import { Button } from 'antd';
import CreateCategoryStatus from './create';
import {
  getAllCategoryStatus,
  getCategoryStatusWithGroupData
} from '../../../../../../selectors/occasionResources';
import {
  occasionCategoryStatusView,
  deleteCategoryStatus
} from '../../../../../../actions/occasionResources';

const ViewCategoryStatus = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [categoryStatusId, setCategoryStatusId] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.occasionCategoryStatusView(props.occasionId, page);
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleCreate = () => {
    setCategoryStatusId('');
    setCreateOpen(true);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="category-status-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={handleCreate}>
          Create
        </Button>
      </div>
    )
  };

  const editData = (id) => {
    setCreateOpen(true);
    setCategoryStatusId(id);
  };

  const deleteData = (id) => {
    props.deleteCategoryStatus(props.occasionId, id);
  };

  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="category-status-view-section">
          <div className="category-status-view-container">
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              isRouted={false}
              onPageChange={onPageChange}
              onDelete={deleteData}
              isEditable={editData}
              data={props.categoryStatusData}
              fetchProgress={props.fetchProgress}
              paginationLinks={props.categoryStatusLinks}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <CreateCategoryStatus
        occasionId={props.occasionId}
        categoryStatusId={categoryStatusId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  categoryStatusData: getCategoryStatusWithGroupData(store, props),
  categoryStatusLinks: store.categoryStatus.categoryStatusLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { occasionCategoryStatusView, deleteCategoryStatus },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(ViewCategoryStatus);
