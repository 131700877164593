import {
  DELETE_TICKET_GOOGLE_SHEET_FAILED,
  DELETE_TICKET_GOOGLE_SHEET_SUCCESS,
  GET_TICKET_GOOGLE_SHEET_FAILED,
  GET_TICKET_GOOGLE_SHEET_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  ticketGoogleSheetId: null,
  error: null,
  ticketGoogleSheetLinks: null
};
const ticketGoogleSheet = (state = initialState, action) => {
  switch (action.type) {
    case GET_TICKET_GOOGLE_SHEET_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.ticketGoogleSheetId || {};
      const currentTicketGoogleSheetNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        ticketGoogleSheetId: {
          ...occasionGroupedData,
          [ocId]: [...currentTicketGoogleSheetNewIds]
        },
        ticketGoogleSheetLinks: links
      };
    }

    case DELETE_TICKET_GOOGLE_SHEET_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.ticketGoogleSheetId || {};
      const currentOccasionGoogleSheetIds = occasionGroupedData[ocId] || [];
      const filteredIds = currentOccasionGoogleSheetIds.filter(
        (id) => id !== data.id
      );

      return {
        ...state,
        ticketGoogleSheetId: {
          ...occasionGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case DELETE_TICKET_GOOGLE_SHEET_FAILED:
    case GET_TICKET_GOOGLE_SHEET_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};
export default ticketGoogleSheet;
