import { createSelector } from 'reselect';
import { currentOccasionId } from './dynamicData';
import {
  galleryUUIDs,
  galleryPhotoIds,
  photosNextPresent,
  galleryVideoIds,
  videosNextPresent,
  uploadImageList
} from './static';
import { galleryEntities, mediaEntities, userEntities } from './entity';

export const getGalleryUUID = createSelector(
  currentOccasionId,
  galleryUUIDs,
  (ocId, data) => ocId && data && data[ocId]
);

export const getGalleryInformation = createSelector(
  getGalleryUUID,
  galleryEntities,
  (uuid, entities) => uuid && entities && entities[uuid]
);

export const getMediaUploadList = createSelector(
  currentOccasionId,
  uploadImageList,
  (id, list) => {
    return id && list && list[id] && Object.values(list[id]);
  }
);

export const getGalleryPhotoIds = createSelector(
  currentOccasionId,
  galleryPhotoIds,
  (ocId, data) => ocId && data && data[ocId]
);

export const getGalleryVideoIds = createSelector(
  currentOccasionId,
  galleryVideoIds,
  (ocId, data) => ocId && data && data[ocId]
);

export const getAllGalleryPhotos = createSelector(
  getGalleryPhotoIds,
  mediaEntities,
  userEntities,
  (ids, entities, users) =>
    (ids &&
      entities &&
      ids.map((id) => ({
        ...entities[id],
        user: users &&
          entities &&
          entities[id] &&
          entities[id]?.createdBy &&
          entities[id]?.createdBy?.id &&
          users[entities[id]?.createdBy?.id] && {
            ...users[entities[id]?.createdBy?.id]
          }
      }))) ||
    []
);

export const getAllGalleryVideos = createSelector(
  getGalleryVideoIds,
  mediaEntities,
  userEntities,
  (ids, entities, users) =>
    (ids &&
      entities &&
      ids.map((id) => ({
        ...entities[id],
        user: users &&
          entities &&
          entities[id] &&
          entities[id]?.createdBy &&
          entities[id]?.createdBy?.id &&
          users[entities[id]?.createdBy?.id] && {
            ...users[entities[id]?.createdBy?.id]
          }
      }))) ||
    []
);

export const getAllGalleryPhotosNextPresent = createSelector(
  currentOccasionId,
  photosNextPresent,
  (id, links) => id && links && links[id]
);

export const getAllGalleryVideosNextPresent = createSelector(
  currentOccasionId,
  videosNextPresent,
  (id, links) => id && links && links[id]
);
