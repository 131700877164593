import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Empty, List, Button } from 'antd';
import { bindActionCreators } from 'redux';
import {
  currentOccasionId,
  currentPostId
} from '../../../../../selectors/dynamicData';
import { getReportedPostLikesWithPagination } from '../../../../../actions/reports';
import { currentLikesWithUserData } from '../../../../../selectors/likes';
import { likeLinks } from '../../../../../selectors/static';
import './styles.scss';
import { Avatar } from '../../../../../components/Avatar';

const Likes = (props) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    props.getReportedPostLikesWithPagination(
      props.occasionId,
      page,
      'Post',
      props.postId
    );
  }, [page]);

  const likeRenderItem = (item) => (
    <div className="like-item">
      <Avatar
        avatarUrl={item?.user?.avatarUrl}
        name={item?.user?.name}
        className="profile-image"
      />
      <span style={{ marginLeft: '10px' }}>{item?.user?.name}</span>
    </div>
  );

  const getNextPage = (str) => {
    const str1 = str.split('=');
    const res = str1[1].split('&');
    return parseInt(res[0], 10);
  };

  const onLoadMore = () => {
    if (props?.likeLinks?.next) {
      const pageNumber = getNextPage(props?.likeLinks?.next);
      setPage(pageNumber);
    }
  };

  const loadMore = useMemo(() => {
    return props.likeLinks?.next ? (
      <div className="load-more">
        <Button onClick={onLoadMore}>loading more</Button>
      </div>
    ) : null;
  }, [props?.likeLinks?.next]);

  return (
    <div>
      <p className="like-header-text">People Who have liked</p>
      {props.likes?.length > 0 ? (
        <List
          dataSource={props?.likes}
          renderItem={likeRenderItem}
          loading={props.fetchProgress}
          loadMore={loadMore}
        />
      ) : (
        <Empty />
      )}
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  occasionId: currentOccasionId(store, props),
  postId: currentPostId(store, props),
  likes: currentLikesWithUserData(store, props),
  likeLinks: likeLinks(store, props),
  fetchProgress: store.browserState.fetchProgress
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getReportedPostLikesWithPagination }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Likes);
