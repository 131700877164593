import {
  GET_OCCASION_APP_DETAILS_FAILED,
  GET_OCCASION_APP_DETAILS_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  occasionAppData: null,
  error: null
};

const occasionApp = (state = initialState, action) => {
  switch (action.type) {
    case GET_OCCASION_APP_DETAILS_SUCCESS: {
      const { data, ocId } = action.payload;
      return {
        ...state,
        occasionAppData: { [ocId]: data.id }
      };
    }

    case GET_OCCASION_APP_DETAILS_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default occasionApp;
