import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button, AutoComplete } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { IS_MODELABLE } from '../../../Constants/index';
import {
  createWhatsAppTemplateParams,
  updateWhatsAppTemplateParams
} from '../../../../../../actions/whatsAppTemplates';
import { getCurrentWhatsappTemplatesParams } from '../../../../../../selectors/whatsappTemplates';

const { Option } = AutoComplete;

const validateForm = (values) => {
  const error = {};
  if (!values.paramKey) {
    error.paramKey = 'Param Key is required';
  }
  return error;
};

function WhatsappTemplatesParamsCreate(props) {
  const [createFormData, setCreateFormData] = React.useState({
    paramKey: props.currentParamsData?.paramKey || '',
    paramValue: props.currentParamsData?.paramsValue || '',
    selfModel: props.currentParamsData?.selfModel || '',
    isSelfModelable: props.currentParamsData?.isSelfModelable
      ? !!props.currentParamsData?.isSelfModelable
        ? 'true'
        : 'false'
      : '',
    selfModelId: props.currentParamsData?.selfModelId || '',
    selfModelAttribute: props.currentParamsData?.selfModelAttribute || '',
    parentModel: props.currentParamsData?.parentModel || '',
    isParentModelable: props.currentParamsData?.isParentModelable
      ? props.currentParamsData?.isParentModelable
        ? 'true'
        : 'false'
      : '',
    parentModelId: props.currentParamsData?.parentModelId || ''
  });
  const [
    dynamicFiledSelfModelQueryParams,
    setDynamicFiledSelfModelQueryParams
  ] = useState(props.currentParamsData?.selfModelQueryParams || []);
  const [errorMessages, setErrorMessages] = React.useState({});
  const [
    errorMessagesForDynamicField,
    setErrorMessagesForDynamicField
  ] = React.useState({});

  const handleSubmit = () => {
    try {
      const validateObj = validateForm(createFormData);

      const hasErrors = dynamicFiledSelfModelQueryParams.some(
        (field, index) => {
          if (!field.key || !field.value) {
            setErrorMessagesForDynamicField({
              ...errorMessagesForDynamicField,
              [`key${index + 1}`]: 'Both key and value is required'
            });
            return true;
          }
          setErrorMessagesForDynamicField({});
          return false;
        }
      );

      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else if (hasErrors) {
        return;
      } else {
        setErrorMessages({});
        setErrorMessagesForDynamicField({});
        const formData = new FormData();

        formData.append(
          'whatsapp_template_param[param_key]',
          createFormData.paramKey
        );
        formData.append(
          'whatsapp_template_param[params_value]',
          createFormData.paramValue
        );
        formData.append(
          'whatsapp_template_param[self_model]',
          createFormData.selfModel
        );
        formData.append(
          'whatsapp_template_param[is_self_modelable]',
          createFormData.isSelfModelable
        );
        formData.append(
          'whatsapp_template_param[self_model_id]',
          createFormData.selfModelId
        );
        formData.append(
          'whatsapp_template_param[self_model_attribute]',
          createFormData.selfModelAttribute
        );
        formData.append(
          'whatsapp_template_param[parent_model]',
          createFormData.parentModel
        );
        formData.append(
          'whatsapp_template_param[is_parent_modelable]',
          createFormData.isParentModelable
        );
        formData.append(
          'whatsapp_template_param[parent_model_id]',
          createFormData.parentModelId
        );
        dynamicFiledSelfModelQueryParams.forEach((item) => {
          formData.append(
            'whatsapp_template_param[self_model_query_params][][quary_key]',
            item.key
          );
          formData.append(
            'whatsapp_template_param[self_model_query_params][][quary_value]',
            item.value
          );
        });

        props.whatsappTemplateParamsId
          ? props.updateWhatsAppTemplateParams(
              props.whatsappTemplatesId,
              props.whatsappTemplateModelableId,
              props.whatsappTemplateParamsId,
              formData
            )
          : props.createWhatsAppTemplateParams(
              props.whatsappTemplatesId,
              props.whatsappTemplateModelableId,
              formData,
              () => {
                setCreateFormData({
                  paramKey: '',
                  paramValue: '',
                  selfModel: '',
                  isSelfModelable: '',
                  selfModelId: '',
                  selfModelAttribute: '',
                  parentModel: '',
                  isParentModelable: '',
                  parentModelId: ''
                });
                setDynamicFiledSelfModelQueryParams([]);
              }
            );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    try {
      let value;
      let name;

      const formValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setCreateFormData({
        ...createFormData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDynamicFieldChange = (index, event) => {
    const { name, value } = event.target;
    const updatedData = [...dynamicFiledSelfModelQueryParams];
    updatedData[index][name] = value;
    setDynamicFiledSelfModelQueryParams(updatedData);
  };

  const handleAddField = () => {
    setDynamicFiledSelfModelQueryParams([
      ...dynamicFiledSelfModelQueryParams,
      { key: '', value: '' }
    ]);
  };

  const handleRemoveField = (index) => {
    const updatedData = [...dynamicFiledSelfModelQueryParams];
    updatedData.splice(index, 1);
    setDynamicFiledSelfModelQueryParams(updatedData);
    setErrorMessagesForDynamicField({});
  };

  return (
    <ErrorBoundary>
      <SectionContent className="whatsapp-templates-params-create-section">
        <div className="whatsapp-templates-params-create-form">
          <Form
            className="whatsapp-templates-params-form-container"
            onFinish={handleSubmit}>
            <InputGroup
              label="Param Key"
              name="paramKey"
              placeholder="Enter Param Key"
              onChange={handleInputChange}
              value={createFormData?.paramKey}
              errorMessage={errorMessages.paramKey}
            />
            <InputGroup
              label="Param value"
              name="paramValue"
              placeholder="Enter Param Value"
              onChange={handleInputChange}
              value={createFormData?.paramValue}
            />
            <InputGroup
              label="Self Model"
              name="selfModel"
              placeholder="Enter Self Model"
              onChange={handleInputChange}
              value={createFormData?.selfModel}
            />
            <InputGroup
              fieldType="radioGroup"
              options={IS_MODELABLE}
              name="isSelfModelable"
              label="Is Self Modelable"
              onChange={handleInputChange}
              value={createFormData?.isSelfModelable}
            />
            <InputGroup
              type="number"
              label="Self Model Id"
              name="selfModelId"
              placeholder="Enter Self Model Id"
              onChange={handleInputChange}
              value={createFormData?.selfModelId}
            />
            <InputGroup
              label="Self Model Attribute"
              name="selfModelAttribute"
              placeholder="Enter Self Model Attribute"
              onChange={handleInputChange}
              value={createFormData?.selfModelAttribute}
            />
            <InputGroup
              label="Parent Model"
              name="parentModel"
              placeholder="Enter Parent Model"
              onChange={handleInputChange}
              value={createFormData?.parentModel}
            />
            <InputGroup
              fieldType="radioGroup"
              options={IS_MODELABLE}
              name="isParentModelable"
              label="Is Parent Modelable"
              onChange={handleInputChange}
              value={createFormData?.isParentModelable}
            />
            <InputGroup
              type="number"
              label="Parent Model Id"
              name="parentModelId"
              placeholder="Enter Parent Model Id"
              onChange={handleInputChange}
              value={createFormData?.parentModelId}
            />
            <span style={{ margin: '15px 0px 0px 0px' }}>Enter Query:</span>

            {dynamicFiledSelfModelQueryParams.length > 0 &&
              dynamicFiledSelfModelQueryParams.map((field, index) => {
                return (
                  <div key={index} className="added-option-container">
                    <button
                      className="option-input-remove-button"
                      onClick={() => handleRemoveField(index)}
                      type="button">
                      Remove
                    </button>
                    <div className="radio-factor-box">
                      <InputGroup
                        name="key"
                        className="option-field"
                        label="key"
                        onChange={(event) =>
                          handleDynamicFieldChange(index, event)
                        }
                        value={field.key}
                        errorMessage={
                          errorMessagesForDynamicField[`key${index + 1}`]
                        }
                      />
                      <InputGroup
                        name="value"
                        label="Value"
                        className="factor-field"
                        value={field.value}
                        onChange={(event) =>
                          handleDynamicFieldChange(index, event)
                        }
                      />
                    </div>
                  </div>
                );
              })}

            <div className="add-option-button-box">
              <button
                className="add-option-button"
                onClick={handleAddField}
                type="button">
                Add Query
              </button>
            </div>
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={
                props.whatsappTemplateParamsId ? 'Save Changes' : 'Create'
              }
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentParamsData: getCurrentWhatsappTemplatesParams(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { createWhatsAppTemplateParams, updateWhatsAppTemplateParams },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WhatsappTemplatesParamsCreate);
