import {
  CREATE_EMAIL,
  CREATE_EMAIL_SUCCESS,
  CREATE_EMAIL_FAILED,
  VIEW_EMAILS,
  VIEW_EMAILS_SUCCESS,
  VIEW_EMAILS_FAILED,
  DELETE_EMAIL,
  EDIT_EMAIL,
  EDIT_EMAIL_SUCCESS,
  EDIT_EMAIL_FAILED,
  DELETE_EMAIL_FAILED,
  DELETE_EMAIL_SUCCESS,
  SEND_EMAIL_SAVER,
  SEND_EMAIL_SAVER_SUCCESS,
  SEND_EMAIL_SAVER_FAILED
} from '../constants/actionTypes';

export const createEmail = (occasionId, formData, callback) => ({
  type: CREATE_EMAIL,
  payload: { occasionId, formData },
  callback
});

export const createEmailSuccess = (occasionId, data, included) => ({
  type: CREATE_EMAIL_SUCCESS,
  payload: { occasionId, data, included }
});

export const createEmailFailed = (error) => ({
  type: CREATE_EMAIL_FAILED,
  error
});

export const viewEmails = (occasionId, page) => ({
  type: VIEW_EMAILS,
  payload: { occasionId, page }
});

export const viewEmailsSuccess = (occasionId, data, included, links) => ({
  type: VIEW_EMAILS_SUCCESS,
  payload: { occasionId, data, included, links }
});

export const viewEmailsFailed = (error) => ({
  type: VIEW_EMAILS_FAILED,
  error
});

export const editEmail = (occasionId, emailId, formData, callback) => ({
  type: EDIT_EMAIL,
  payload: { occasionId, emailId, formData },
  callback
});

export const editEmailSuccess = (occasionId, data, included) => ({
  type: EDIT_EMAIL_SUCCESS,
  payload: { occasionId, data, included }
});

export const editEmailFailed = (error) => ({
  type: EDIT_EMAIL_FAILED,
  error
});

export const deleteEmails = (occasionId, emailId, callback) => ({
  type: DELETE_EMAIL,
  payload: { occasionId, emailId },
  callback
});

export const deleteEmailSuccess = (occasionId, data, included) => ({
  type: DELETE_EMAIL_SUCCESS,
  payload: { occasionId, data, included }
});

export const deleteEmailFailed = (error) => ({
  type: DELETE_EMAIL_FAILED,
  error
});

export const sendEmailSaver = (occasionId, emailId, formData, callback) => ({
  type: SEND_EMAIL_SAVER,
  payload: { occasionId, emailId, formData },
  callback
});

export const sendEmailSaverSuccess = (occasionId, data, included) => ({
  type: SEND_EMAIL_SAVER_SUCCESS,
  payload: { occasionId, data, included }
});

export const sendEmailSaverFailed = (error) => ({
  type: SEND_EMAIL_SAVER_FAILED,
  error
});
