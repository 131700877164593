import {
  EVENT_AFFAIR_ESSENCE_CREATE,
  EVENT_AFFAIR_ESSENCE_CREATE_FAILED,
  EVENT_AFFAIR_ESSENCE_CREATE_SUCCESS,
  EVENT_AFFAIR_ESSENCE_VIEW,
  EVENT_AFFAIR_ESSENCE_VIEW_FAILED,
  EVENT_AFFAIR_ESSENCE_VIEW_SUCCESS,
  EVENT_AFFAIR_ESSENCE_UPDATE,
  EVENT_AFFAIR_ESSENCE_UPDATE_FAILED,
  EVENT_AFFAIR_ESSENCE_UPDATE_SUCCESS,
  EVENT_AFFAIR_ESSENCE_DELETE,
  EVENT_AFFAIR_ESSENCE_DELETE_FAILED,
  EVENT_AFFAIR_ESSENCE_DELETE_SUCCESS
} from '../constants/actionTypes';

export const eventAffairEssenceCreate = (
  ocId,
  formData,
  eventId,
  eventAffairId,
  callback
) => ({
  type: EVENT_AFFAIR_ESSENCE_CREATE,
  payload: { ocId, formData, eventId, eventAffairId },
  callback
});

export const eventAffairEssenceCreateSuccess = (
  ocId,
  eventId,
  eventAffairId,
  data,
  included,
  links
) => ({
  type: EVENT_AFFAIR_ESSENCE_CREATE_SUCCESS,
  payload: { ocId, eventId, eventAffairId, data, included, links }
});

export const eventAffairEssenceCreateFailed = (error) => ({
  type: EVENT_AFFAIR_ESSENCE_CREATE_FAILED,
  error
});

export const eventAffairEssenceView = (ocId, page, eventId, eventAffairId) => ({
  type: EVENT_AFFAIR_ESSENCE_VIEW,
  payload: { ocId, page, eventId, eventAffairId }
});

export const eventAffairEssenceViewSuccess = (
  ocId,
  eventId,
  eventAffairId,
  data,
  included,
  links
) => ({
  type: EVENT_AFFAIR_ESSENCE_VIEW_SUCCESS,
  payload: { ocId, eventId, eventAffairId, data, included, links }
});

export const eventAffairEssenceViewFailed = (error) => ({
  type: EVENT_AFFAIR_ESSENCE_VIEW_FAILED,
  error
});

export const eventAffairEssenceUpdate = (
  ocId,
  eventAffairId,
  eventId,
  essenceId,
  formData,
  callback
) => ({
  type: EVENT_AFFAIR_ESSENCE_UPDATE,
  payload: { ocId, eventAffairId, eventId, essenceId, formData },
  callback
});

export const eventAffairEssenceUpdateSuccess = (
  ocId,
  eventId,
  eventAffairId,
  data,
  included,
  links
) => ({
  type: EVENT_AFFAIR_ESSENCE_UPDATE_SUCCESS,
  payload: { ocId, eventId, eventAffairId, data, included, links }
});

export const eventAffairEssenceUpdateFailed = (error) => ({
  type: EVENT_AFFAIR_ESSENCE_UPDATE_FAILED,
  error
});

export const eventAffairEssenceDelete = (
  ocId,
  eventAffairId,
  eventId,
  essenceId
) => ({
  type: EVENT_AFFAIR_ESSENCE_DELETE,
  payload: { ocId, eventAffairId, eventId, essenceId }
});

export const eventAffairEssenceDeleteSuccess = (
  ocId,
  eventId,
  eventAffairId,
  data,
  included,
  links
) => ({
  type: EVENT_AFFAIR_ESSENCE_DELETE_SUCCESS,
  payload: { ocId, eventId, eventAffairId, data, included, links }
});

export const eventAffairEssenceDeleteFailed = (error) => ({
  type: EVENT_AFFAIR_ESSENCE_DELETE_FAILED,
  error
});
