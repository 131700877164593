import { combineReducers } from 'redux';
import { REQUEST_CLEAR_STORE } from '../constants/actionTypes';
import auth from './auth';
import browserState from './browserState';
import occasion from './occasion';
import entity from './entity';
import admin from './admin';
import socianaInternalUser from './socianaInternalUser';
import accessControl from './accessControl';
import featureControl from './featureControl';
import report from './reports';
import like from './like';
import comment from './comment';
import post from './post';
import user from './user';
import gallery from './gallery';
import invitation from './Invitation';
import event from './event';
import hospitality from './hospitality';
import group from './group';
import live from './live';
import hospitalityTask from './hospitalityTask';
import trigger from './trigger';
import sponsorship from './sponsorship';
import importantContact from './importantContact';
import liveVideo from './liveVideo';
import sos from './sos';
import award from './award';
import members from './members';
import ticket from './ticket';
import ticketVariant from './ticketVariant';
import tabKeys from './tabKeys';
import ticketVariantForm from './ticketVariantForm';
import question from './question';
import purchases from './purchases';
import schedule from './schedule';
import itinerary from './itinerary';
import notifications from './notifications';
import carousel from './carousel';
import division from './division';
import subdivision from './subdivision';
import sms from './notificationSms';
import occasionFont from './occasionFont';
import guest from './guest';
import album from './album';
import occasionApp from './occasionApp';
import roleName from './roleName';
import userOccasion from './userOccasion';
import pollPost from './pollPost';
import occasionCode from './occasionCode';
import occasionWhiteList from './occasionWhiteList';
import publicAnswer from './publicAnswer';
import eventAffairEssence from './eventAffairEssence';
import eventAffairEntertainment from './eventAffairEntertainment';
import eventAffairFood from './eventAffairFood';
import discussion from './discussion';
import enquiry from './enquiry';
import occasionTabSetting from './occasionTabSetting';
import speaker from './speaker';
import speakerReference from './speakerReference';
import userDevice from './userDevice';
import activityTracker from './activityTracker';
import activityTrackerPresentUser from './activityTrackerPresentUser';
import alarm from './alarm';
import hardLogout from './hardLogout';
import eventCollaborator from './eventCollaborator';
import faceInfo from './faceInfo';
import activityTrackerAbsentUser from './activityTrackerAbsentUser';
import fixedGroupPostSetting from './fixedGroupPostSetting';
import hospitalityGoogleSheet from './hospitalityGoogleSheet';
import Competition from './competition';
import Participant from './participant';
import ticketGoogleSheet from './ticketGoogleSheet';
import whatsappForProvidedTask from './whatsappForProvidedTask';
import whatsappTemplates from './whatsAppTemplates';
import occasionResources from './occasionResources';
import categoryStatus from './categoryStatus';

const appReducers = combineReducers({
  auth,
  browserState,
  occasion,
  entity,
  admin,
  socianaInternalUser,
  accessControl,
  featureControl,
  report,
  like,
  comment,
  post,
  user,
  gallery,
  invitation,
  event,
  hospitality,
  group,
  live,
  sponsorship,
  importantContact,
  hospitalityTask,
  liveVideo,
  sos,
  live,
  award,
  members,
  ticket,
  ticketVariant,
  trigger,
  tabKeys,
  ticketVariantForm,
  question,
  purchases,
  carousel,
  schedule,
  itinerary,
  notifications,
  carousel,
  division,
  subdivision,
  sms,
  occasionFont,
  album,
  occasionApp,
  roleName,
  guest,
  userOccasion,
  pollPost,
  occasionCode,
  occasionWhiteList,
  publicAnswer,
  eventAffairEssence,
  eventAffairEntertainment,
  eventAffairFood,
  discussion,
  enquiry,
  occasionTabSetting,
  speaker,
  speakerReference,
  userDevice,
  activityTracker,
  activityTrackerPresentUser,
  alarm,
  hardLogout,
  eventCollaborator,
  faceInfo,
  activityTrackerAbsentUser,
  fixedGroupPostSetting,
  hospitalityGoogleSheet,
  Competition,
  Participant,
  ticketGoogleSheet,
  whatsappForProvidedTask,
  whatsappTemplates,
  occasionResources,
  categoryStatus
});

const rootReducers = (state, action) => {
  let newState = state;
  if (action.type === REQUEST_CLEAR_STORE) {
    newState = undefined; // resetting the store after the logout action dispatched
  }
  return appReducers(newState, action);
};

export default rootReducers;
