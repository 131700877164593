import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import Tabs from '../../../../../components/Tabs';
import { getCurrentEvent } from '../../../../../selectors/event';
import EventDetailsView from './EventDetails';
import {
  currentOccasionId,
  currentEventId
} from '../../../../../selectors/dynamicData';
import {
  resetTabIndex,
  setCurrentTabIndex
} from '../../../../../actions/tabKeys';
import EventSendCreate from './Invitation/SendCreate';
import EventPendingInvitation from './Invitation/PendingInvitation';
import EventPendingRegistration from './Invitation/PendingRegistration';
import EventMembersView from './EventMembers/index';
import EventScheduleView from './Schedule/View';
import { TabsHeader } from '../../../../../components/TabsHeader';
import { getCurrentOccasion } from '../../../../../selectors/occasion';
import EventAffairView from './EventAffair/View';
import ViewCollaborator from './EventCollaborator/View';
import EventPostCreate from './EventPost/create';
import EventPostView from './EventPost/view';

function EventDetails(props) {
  const featureNotEnabledContainer = () => {
    return (
      <div className="flex-center base-min-height">
        This feature is not enabled.
      </div>
    );
  };

  const getEventPostComponents = () => {
    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'Create',
            tabKey: '5.1',
            children: (
              <EventPostCreate
                occasionId={props.occasionId}
                eventId={props.currentEventId}
              />
            )
          },

          {
            tabName: 'View',
            tabKey: '5.2',
            children: (
              <EventPostView
                occasionId={props.occasionId}
                eventId={props.currentEventId}
              />
            )
          }
        ]}
      />
    );
  };

  const getInvitationComponents = () => {
    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'Send',
            tabKey: '2.1',
            children: (
              <EventSendCreate
                occasionId={props.occasionId}
                eventId={props.currentEventId}
              />
            )
          },
          {
            tabName: 'Pending Invitation',
            tabKey: '2.2',
            children: (
              <EventPendingInvitation
                occasionId={props.occasionId}
                eventId={props.currentEventId}
              />
            )
          },
          {
            tabName: 'Pending Registration',
            tabKey: '2.3',
            children: (
              <EventPendingRegistration
                occasionId={props.occasionId}
                eventId={props.currentEventId}
              />
            )
          }
        ]}
      />
    );
  };

  const getMemberComponents = () => {
    return (
      <EventMembersView
        occasionId={props.occasionId}
        eventId={props.currentEventId}
      />
    );
  };

  const tabPanes = [
    {
      tabName: 'Details',
      tabKey: '1',
      children: (
        <EventDetailsView
          eventDetails={props.currentEvent}
          occasionId={props.occasionId}
        />
      )
    },
    // {
    //   tabName: 'Invitation',
    //   tabKey: '2',
    //   children: getInvitationComponents()
    // },
    // {
    //   tabName: 'Members',
    //   tabKey: '3',
    //   children: getMemberComponents()
    // },
    {
      tabName: 'Schedule',
      tabKey: '2',
      children: (
        <EventScheduleView
          occasionId={props.occasionId}
          eventId={props.currentEventId}
        />
      )
    },
    {
      tabName: 'Event Affair',
      tabKey: '3',
      children:
        props.currentEvent?.uiVersion === 'Version_0' ? (
          featureNotEnabledContainer()
        ) : (
          <EventAffairView
            occasionId={props.occasionId}
            eventId={props.currentEventId}
          />
        )
    },
    {
      tabName: 'Collaborator',
      tabKey: '4',
      children: (
        <ViewCollaborator
          occasionId={props.occasionId}
          eventId={props.currentEventId}
        />
      )
    },
    {
      tabName: 'Event Post',
      tabKey: '5',
      children: getEventPostComponents()
    }
  ];
  // const goBack = () => {
  //   props.history.goBack();
  // };

  const goBack = () => {
    props.resetTabIndex('event');
    props.history.goBack();
  };

  const handleTabChange = (key) => {
    props.setCurrentTabIndex(key, 'event');
  };

  return (
    <ErrorBoundary>
      <SectionContent className="occasion-event-tab-section">
        <TabsHeader
          name={props.currentOccasion.name}
          extraData={[
            props.currentOccasion.type,
            props.currentOccasion.guestType,
            props.currentEvent.title
          ]}
          wrapperClass="section-details"
        />
        <div className="occasion-event-tab-container">
          <Tabs
            defaultActiveKey={props.activeTabKey}
            tabPanes={tabPanes}
            onChange={handleTabChange}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  activeTabKey: store.tabKeys.event,
  fetchProgress: store.browserState.fetchProgress,
  currentEvent: getCurrentEvent(store, props),
  occasionId: currentOccasionId(store, props),
  currentEventId: currentEventId(store, props),
  currentOccasion: getCurrentOccasion(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ resetTabIndex, setCurrentTabIndex }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);
