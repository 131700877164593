import { union } from 'lodash';
import {
  OCCASION_SUBDIVISION_VIEW_SUCCESS,
  OCCASION_SUBDIVISION_VIEW_FAILED,
  OCCASION_SUBDIVISION_CREATE_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  occasionSubdivisionData: null,
  error: null,
  subdivisionLinks: null
};

const subdivision = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_SUBDIVISION_VIEW_SUCCESS: {
      const { data, divisionId, links } = action.payload;
      const occasionGroupedData = state.occasionSubdivisionData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionSubdivisionData: {
          ...occasionGroupedData,
          [divisionId]: [...currentOccasionNewIds]
        },
        subdivisionLinks: links
      };
    }

    case OCCASION_SUBDIVISION_CREATE_SUCCESS: {
      const { data, divisionId } = action.payload;
      const occasionGroupedData = state.occasionSubdivisionData || {};
      const currentOccasionSubdivisionIds =
        occasionGroupedData[divisionId] || [];

      const newIds = [data.id, ...currentOccasionSubdivisionIds];
      return {
        ...state,
        occasionSubdivisionData: {
          ...occasionGroupedData,
          [divisionId]: newIds
        }
      };
    }
    case OCCASION_SUBDIVISION_VIEW_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default subdivision;
