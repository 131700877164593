import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import StreamJs from '../containers/PageRoutes/Occasion/Details/LiveEvent/Stream';

const LiveStreamRoute = () => {
  return (
    <>
      <Switch>
        <Route path={`/go_live`} component={StreamJs} />
      </Switch>
    </>
  );
};

export default withRouter(LiveStreamRoute);
