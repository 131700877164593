import { createSelector } from 'reselect';
import {
  speakerReferenceEntities,
  occasionEntities,
  occasionEventsEntities
} from './entity';
import { speakerReferenceIds } from './static';
import { currentSpeakerId, currentSpeakerReferenceId } from './dynamicData';

export const getCurrentSpeakerReferences = createSelector(
  currentSpeakerId,
  speakerReferenceIds,
  (spkrId, Ids) => spkrId && Ids && Ids[spkrId]
);
export const getAllSpeakerReferences = createSelector(
  speakerReferenceEntities,
  getCurrentSpeakerReferences,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAllSpeakerReferencesWithReferencableData = createSelector(
  getAllSpeakerReferences,
  occasionEventsEntities,
  occasionEntities,
  (data, eventEntities, ocEntities) =>
    data &&
    (eventEntities || ocEntities) &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      referencable:
        item.referencable.type === 'events'
          ? { ...eventEntities[item?.referencable?.id] }
          : { ...ocEntities[item?.referencable?.id] }
    }))
);

export const currentSpeakerReferenceData = createSelector(
  currentSpeakerReferenceId,
  speakerReferenceEntities,
  (id, entities) => id && entities && entities[id]
);
