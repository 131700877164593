import React from 'react';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import Tabs from '../../../../../../components/Tabs';
import { getCurrentEvent } from '../../../../../../selectors/event';
import { getCurrentOccasion } from '../../../../../../selectors/occasion';
import { getCurrentEventAffair } from '../../../../../../selectors/eventAffair';
import { capitalize } from '../../../../../../helpers/stringHelper';
import {
  currentOccasionId,
  currentEventId,
  currentEventAffairId
} from '../../../../../../selectors/dynamicData';
import { TabsHeader } from '../../../../../../components/TabsHeader';
import EventAffairDetailsView from './EventAffairDetails';
import EventAffairEssenceView from './Essence/View';
import ViewEventAffairEntertainment from './Entertainment/View';
import EventAffairFoodView from './Food/View';

function EventAffairDetails(props) {
  const getEventAffairComponents = () => {
    if (props.currentEventAffair?.affairType === 'food') {
      return (
        <EventAffairFoodView
          occasionId={props.occasionId}
          eventId={props.currentEventId}
          eventAffairId={props.currentEventAffairId}
        />
      );
    } else if (props.currentEventAffair?.affairType === 'essence') {
      return (
        <EventAffairEssenceView
          occasionId={props.occasionId}
          eventId={props.currentEventId}
          eventAffairId={props.currentEventAffairId}
        />
      );
    } else if (props.currentEventAffair?.affairType === 'entertainment') {
      return (
        <ViewEventAffairEntertainment
          occasionId={props.occasionId}
          eventId={props.currentEventId}
          eventAffairId={props.currentEventAffairId}
        />
      );
    }
  };

  const tabPanes = [
    {
      tabName: 'Details',
      tabKey: '1',
      children: (
        <EventAffairDetailsView eventAffairDetails={props.currentEventAffair} />
      )
    },
    {
      tabName: `${capitalize(props.currentEventAffair?.affairType)}`,
      tabKey: '2',
      children: getEventAffairComponents()
    }
  ];
  const goBack = () => {
    props.history.goBack();
  };
  return (
    <ErrorBoundary>
      <SectionContent className="occasion-event-affair-tab-section">
        <TabsHeader
          name={props.currentOccasion.name}
          extraData={[
            props.currentOccasion.type,
            props.currentOccasion.guestType,
            props.currentEvent.title,
            props.currentEventAffair.affairType
          ]}
          wrapperClass="section-details"
        />
        <div className="occasion-event-affair-tab-container">
          <Tabs
            defaultActiveKey="1"
            tabPanes={tabPanes}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentEvent: getCurrentEvent(store, props),
  occasionId: currentOccasionId(store, props),
  currentEventId: currentEventId(store, props),
  currentOccasion: getCurrentOccasion(store, props),
  currentEventAffair: getCurrentEventAffair(store, props),
  currentEventAffairId: currentEventAffairId(store, props)
});

export default connect(mapStateToProps, null)(EventAffairDetails);
