import {
  OCCASION_QUESTION_CREATE,
  OCCASION_QUESTION_CREATE_SUCCESS,
  OCCASION_QUESTION_CREATE_FAILED,
  OCCASION_QUESTION_VIEW,
  OCCASION_QUESTION_VIEW_SUCCESS,
  OCCASION_QUESTION_VIEW_FAILED
} from '../constants/actionTypes';

export const occasionQuestionCreate = (ocId, formData, ticketId, callback) => ({
  type: OCCASION_QUESTION_CREATE,
  payload: { ocId, formData, ticketId },
  callback
});

export const occasionQuestionCreateSuccess = (data, included, ticketId) => ({
  type: OCCASION_QUESTION_CREATE_SUCCESS,
  payload: { data, included, ticketId }
});

export const occasionQuestionCreateFailed = (error) => ({
  type: OCCASION_QUESTION_CREATE_FAILED,
  error
});

export const occasionQuestionView = (ocId, page, ticketId) => ({
  type: OCCASION_QUESTION_VIEW,
  payload: { ocId, page, ticketId }
});

export const occasionQuestionViewSuccess = (
  data,
  included,
  links,
  ticketId
) => ({
  type: OCCASION_QUESTION_VIEW_SUCCESS,
  payload: { data, included, links, ticketId }
});

export const occasionQuestionViewFailed = (error) => ({
  type: OCCASION_QUESTION_VIEW_FAILED,
  error
});
