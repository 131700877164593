import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import moment from 'moment';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import {
  occasionScheduleCreate,
  occasionScheduleUpdate
} from '../../../../../actions/schedule';
import { getCurrentSchedule } from '../../../../../selectors/schedule';

const validateSosData = (values) => {
  const errors = {};
  if (!values.scheduleDate) {
    errors.scheduleDate = 'Schedule Date is required';
  }
  return errors;
};

const ScheduleCreate = (props) => {
  const [scheduleData, setScheduleData] = useState({
    scheduleDate:
      props.currentScheduleData?.scheduleDate &&
      moment(props.currentScheduleData?.scheduleDate, 'YYYY/MM/DD')
  });
  const [errorMessages, setErrorMessages] = useState({});
  const handleSubmit = () => {
    try {
      const validateObj = validateSosData(scheduleData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('schedule[schedulable_type]', 'Occasion');
        formData.append('schedule[schedulable_id]', props.occasionId);
        formData.append(
          'schedule[schedule_date]',
          scheduleData.scheduleDate.format('DD/MM/YYYY')
        );
        props.scheduleId
          ? props.occasionScheduleUpdate(
              props.occasionId,
              props.scheduleId,
              formData
            )
          : props.occasionScheduleCreate(props.occasionId, formData, () => {
              setScheduleData({});
            });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let formValue;
      let inputName;
      if (type === 'scheduleDate') {
        value = moment(e, 'DD/MM/YYYY');
        name = type;
      }
      formValue = value || e?.target?.value;
      inputName = name || e?.target?.name;
      setScheduleData({
        ...scheduleData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ErrorBoundary>
      <SectionContent className="occasion-schedule-create-section">
        <div className="occasion-schedule-create-form">
          <Form
            className="occassion-schedule-form-container"
            onFinish={handleSubmit}>
            <InputGroup
              fieldType="datePicker"
              onChange={handleInputChange}
              label="Schedule Date"
              name="scheduleDate"
              value={scheduleData?.scheduleDate}
              errorMessage={errorMessages.scheduleDate}
            />
            <SubmitButton
              buttonText={props.scheduleId ? 'Save Changes' : 'Create'}
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentScheduleData: getCurrentSchedule(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { occasionScheduleCreate, occasionScheduleUpdate },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCreate);
