import React from 'react';

export const RequiredMark = ({ left = '0px' }) => {
  return (
    <span
      style={{
        position: 'absolute',
        left: left,
        top: '13px',
        color: 'red',
        fontSize: '25px'
      }}>
      *
    </span>
  );
};
