import request from './request';

export function userDeviceViewApi(userId, page, token) {
  return request(
    `api/v2/sociana_admin/get_user_devices?page=${page}&user_id=${userId}`,
    {
      method: 'GET',
      token
    }
  );
}
