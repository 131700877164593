import { union } from 'lodash';
import {
  CREATE_OCCASION_WHITE_LIST_FAILED,
  CREATE_OCCASION_WHITE_LIST_SUCCESS,
  CREATE_OCCASION_WHITE_LIST_BY_CSV_FAILED,
  CREATE_OCCASION_WHITE_LIST_BY_CSV_SUCCESS,
  DELETE_OCCASION_WHITE_LIST_FAILED,
  DELETE_OCCASION_WHITE_LIST_SUCCESS,
  SEARCH_OCCASION_WHITE_LIST_FAILED,
  SEARCH_OCCASION_WHITE_LIST_SUCCESS,
  GET_OCCASION_WHITE_LIST_FAILED,
  GET_OCCASION_WHITE_LIST_SUCCESS,
  CREATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_SUCCESS,
  CREATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_FAILED
} from '../constants/actionTypes';

const initialState = {
  occasionWhiteListData: null,
  error: null,
  occasionWhiteLinks: null,
  count: 0
};

const occasionWhiteList = (state = initialState, action) => {
  switch (action.type) {
    case GET_OCCASION_WHITE_LIST_SUCCESS:
    case SEARCH_OCCASION_WHITE_LIST_SUCCESS: {
      const { data, ocId, links, meta } = action.payload;
      const occasionGroupedData = state.occasionWhiteListData || {};
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionWhiteListData: {
          ...occasionGroupedData,
          [ocId]: [...newIds]
        },
        occasionWhiteListLinks: links,
        count: meta?.totalCount || 0
      };
    }

    case CREATE_OCCASION_WHITE_LIST_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionWhiteListData || {};
      const currentOccasionWhiteListIds = occasionGroupedData[ocId] || [];

      const newIds = [
        ...data?.map((item) => item.id),
        ...currentOccasionWhiteListIds
      ];
      return {
        ...state,
        occasionWhiteListData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }

    case CREATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionWhiteListData || {};
      const currentOccasionWhiteListIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionWhiteListIds];
      return {
        ...state,
        occasionWhiteListData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }

    case DELETE_OCCASION_WHITE_LIST_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionWhiteListData || {};
      const currentOccasionWhiteListIds = occasionGroupedData[ocId] || [];
      const filteredIds = currentOccasionWhiteListIds.filter(
        (id) => id !== data.id
      );

      return {
        ...state,
        occasionWhiteListData: {
          ...occasionGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case CREATE_OCCASION_WHITE_LIST_FAILED:
    case CREATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_FAILED:
    case SEARCH_OCCASION_WHITE_LIST_FAILED:
    case GET_OCCASION_WHITE_LIST_FAILED:
    case DELETE_OCCASION_WHITE_LIST_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default occasionWhiteList;
