import { createSelector } from 'reselect';
import { occasionAppEntities } from './entity';
import { occasionAppIds } from './static';
import { currentOccasionId } from './dynamicData';

export const currentOccasionAppIds = createSelector(
  currentOccasionId,
  occasionAppIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getAllOccasionAppData = createSelector(
  occasionAppEntities,
  currentOccasionAppIds,
  (entities, ids) => ids && entities && entities[ids]
);
