import { Empty, List, Button } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getReportsWithPagination } from '../../../../../actions/reports';
import PostCard from '../../../../../components/PostCard';
import PostCreate from '../Post/Create';
import { deletePost } from '../../../../../actions/post';
import { currentOccasionId } from '../../../../../selectors/dynamicData';
import { getPostWithReportsData } from '../../../../../selectors/report';
import { reportedPostLinks } from '../../../../../selectors/static';

const Posts = (props) => {
  const [page, setPage] = useState(1);
  const [isEditOpen, setEditOpen] = useState(null);

  const toggleEdit = (id) => {
    setEditOpen(id);
  };

  useEffect(() => {
    props.getReportsWithPagination(props.occasionId, page, 'Post');
  }, [page]);

  const onEdit = (id) => {
    toggleEdit(id);
  };

  const onDelete = (id) => {
    props.deletePost(props.occasionId, id);
  };

  const postRenderItem = (item) => (
    <PostCard
      id={item?.id}
      key={item?.id}
      totalComment={item?.totalComment}
      hashTag={item?.hashTag}
      pictureUrl={item?.pictureUrl}
      message={item?.message}
      postedByDetails={item?.postedByDetails}
      reports={item.reports}
      cdnPictureUrl={item?.cdnPictureUrl}
      videoUrls={item?.videoUrls}
      cdnVideoUrls={item?.cdnPictureUrls}
      likeCount={item?.likeCount}
      occasionId={props.occasionId}
      onDelete={onDelete}
      onEdit={onEdit}
    />
  );

  const getNextPage = (str) => {
    const str1 = str.split('=');
    const res = str1[1].split('&');
    return parseInt(res[0], 10);
  };

  const onLoadMore = () => {
    if (!!props.reportedPostLinks?.next) {
      const pageNumber = getNextPage(props?.reportedPostLinks?.next);
      setPage(pageNumber);
    }
  };

  const loadMore = useMemo(() => {
    return !!props.reportedPostLinks?.next ? (
      <div className="load-more">
        <Button onClick={onLoadMore}>loading more</Button>
      </div>
    ) : null;
  }, [props?.reportedPostLinks?.next]);

  if (!isEditOpen)
    return (
      <div className="post-container">
        {props.data?.length > 0 ? (
          <List
            dataSource={props?.data}
            renderItem={postRenderItem}
            loading={props.fetchProgress}
            loadMore={loadMore}
          />
        ) : (
          <Empty />
        )}
      </div>
    );
  return (
    <div className="post-container">
      <PostCreate
        postId={isEditOpen}
        occasionId={props.occasionId}
        toggleEdit={toggleEdit}
      />
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  occasionId: currentOccasionId(store, props),
  data: getPostWithReportsData(store, props),
  fetchProgress: store.browserState.fetchProgress,
  reportedPostLinks: reportedPostLinks(store, props)
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getReportsWithPagination, deletePost }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Posts);
