import {
  OCCASION_TICKET_CREATE,
  OCCASION_TICKET_CREATE_SUCCESS,
  OCCASION_TICKET_CREATE_FAILED,
  OCCASION_TICKET_VIEW,
  OCCASION_TICKET_VIEW_SUCCESS,
  OCCASION_TICKET_VIEW_FAILED,
  OCCASION_TICKET_UPDATE,
  OCCASION_TICKET_UPDATE_SUCCESS,
  OCCASION_TICKET_UPDATE_FAILED
} from '../constants/actionTypes';

export const occasionTicketCreate = (ocId, formData, callback) => ({
  type: OCCASION_TICKET_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionTicketCreateSuccess = (data, included, ocId) => ({
  type: OCCASION_TICKET_CREATE_SUCCESS,
  payload: { data, included, ocId }
});

export const occasionTicketCreateFailed = (error) => ({
  type: OCCASION_TICKET_CREATE_FAILED,
  error
});

export const occasionTicketView = (ocId, page) => ({
  type: OCCASION_TICKET_VIEW,
  payload: { ocId, page }
});

export const occasionTicketViewSuccess = (data, included, ocId, links) => ({
  type: OCCASION_TICKET_VIEW_SUCCESS,
  payload: { data, included, ocId, links }
});

export const occasionTicketViewFailed = (error) => ({
  type: OCCASION_TICKET_VIEW_FAILED,
  error
});

export const occasionTicketUpdate = (ocId, ticketId, formData, callback) => ({
  type: OCCASION_TICKET_UPDATE,
  payload: { ocId, ticketId, formData },
  callback
});

export const occasionTicketUpdateSuccess = (data, included) => ({
  type: OCCASION_TICKET_UPDATE_SUCCESS,
  payload: { data, included }
});

export const occasionTicketUpdateFailed = (error) => ({
  type: OCCASION_TICKET_UPDATE_FAILED,
  error
});
