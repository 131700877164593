import {
  OCCASION_TICKET_VARIANT_CREATE,
  OCCASION_TICKET_VARIANT_CREATE_SUCCESS,
  OCCASION_TICKET_VARIANT_CREATE_FAILED,
  OCCASION_TICKET_VARIANT_VIEW,
  OCCASION_TICKET_VARIANT_VIEW_SUCCESS,
  OCCASION_TICKET_VARIANT_VIEW_FAILED,
  UPDATE_TICKET_VARIANT,
  UPDATE_TICKET_VARIANT_FAILED,
  UPDATE_TICKET_VARIANT_SUCCESS
} from '../constants/actionTypes';

export const occasionTicketVariantCreate = (ocId, formData, callback) => ({
  type: OCCASION_TICKET_VARIANT_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionTicketVariantCreateSuccess = (data, included, ocId) => ({
  type: OCCASION_TICKET_VARIANT_CREATE_SUCCESS,
  payload: { data, included, ocId }
});

export const occasionTicketVariantCreateFailed = (error) => ({
  type: OCCASION_TICKET_VARIANT_CREATE_FAILED,
  error
});

export const occasionTicketVariantView = (ocId, ticketId, page) => ({
  type: OCCASION_TICKET_VARIANT_VIEW,
  payload: { ocId, ticketId, page }
});

export const occasionTicketVariantViewSuccess = (
  data,
  included,
  ocId,
  links
) => ({
  type: OCCASION_TICKET_VARIANT_VIEW_SUCCESS,
  payload: { data, included, ocId, links }
});

export const occasionTicketVariantViewFailed = (error) => ({
  type: OCCASION_TICKET_VARIANT_VIEW_FAILED,
  error
});

export const updateTicketVariant = (
  ocId,
  ticketVariantId,
  formData,
  callback
) => ({
  type: UPDATE_TICKET_VARIANT,
  payload: { ocId, ticketVariantId, formData },
  callback
});

export const updateTicketVariantSuccess = (ocId, data, included) => ({
  type: UPDATE_TICKET_VARIANT_SUCCESS,
  payload: { ocId, data, included }
});

export const updateTicketVariantFailed = (error) => ({
  type: UPDATE_TICKET_VARIANT_FAILED,
  error
});
