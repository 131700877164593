import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { SectionContent } from '../../../../../../../components/Section';
import ErrorBoundary from '../../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../../components/Table';
import {
  getAllRegisteredPurchaseData,
  getAllRegisteredPurchaseLinks
} from '../../../../../../../selectors/purchases';
import {
  getRegisteredTicketPurchases,
  registeredTicketPurchasesSearch
} from '../../../../../../../actions/purchases';
import InputGroup from '../../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../../components/InputGroup/FormComponents';
import Form from 'antd/lib/form/Form';
import { Button, AutoComplete } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { getAllOccasionTicketVariants } from '../../../../../../../selectors/ticketVariant';
import { occasionTicketVariantView } from '../../../../../../../actions/ticketVariant';

const { Option } = AutoComplete;

const RegisteredTicketPurchases = (props) => {
  const [
    getRegisteredTicketPurchasesDetails,
    setGetRegisteredTicketPurchasesDetails
  ] = React.useState({});
  const [errorMessages, setErrorMessages] = React.useState({});
  const [pageNumber, setPageNumber] = React.useState(1);
  const onPageChange = (page) => {
    if (props.tabIndex === '2.2') {
      setPageNumber(page);
      props.getRegisteredTicketPurchases(
        props.occasionId,
        props.ticketId,
        page
      );
    }
  };

  React.useEffect(() => {
    onPageChange(1);
  }, [props?.tabIndex]);

  const fetchTicketVariants = (page) => {
    props.occasionTicketVariantView(props.occasionId, props.ticketId, page);
  };

  React.useEffect(() => {
    fetchTicketVariants(1);
  }, []);

  const pageRefresh = (page) => {
    props.getRegisteredTicketPurchases(props.occasionId, props.ticketId, page);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.ticketVariantId) errors.type = 'Ticket Variant is required';

    return errors;
  };

  const handleDetails = (page) => {
    try {
      const validateObj = validate(getRegisteredTicketPurchasesDetails);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        props.registeredTicketPurchasesSearch(
          props.occasionId,
          props.ticketId,
          getRegisteredTicketPurchasesDetails.ticketVariantId,
          getRegisteredTicketPurchasesDetails.searchData,
          getRegisteredTicketPurchasesDetails.referenceId,
          (page = 1)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'searchData') {
        value = e.target.value;
        name = type;
      }
      const registeredTicketPurchasesDetailsValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      setGetRegisteredTicketPurchasesDetails({
        ...getRegisteredTicketPurchasesDetails,
        [inputName]: registeredTicketPurchasesDetailsValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleReset = (page) => {
    setGetRegisteredTicketPurchasesDetails({});
    setErrorMessages({});
    props.getRegisteredTicketPurchases(
      props.occasionId,
      props.ticketId,
      (page = 1)
    );
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height === scrollHeight && ticketVariantLinks.next) {
      fetchTicketVariants(page + 1);
      setPage((val) => val + 1);
    }
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="ticket-button-box">
        <Button
          className="refresh-button"
          onClick={() => pageRefresh(pageNumber)}>
          <SyncOutlined />
        </Button>
      </div>
    )
  };

  return (
    <ErrorBoundary>
      <SectionContent className="ticket-purchase-view-section">
        <div className="ticket-purchase-view-container">
          <Form
            className="ticket-purchase-search-container"
            onFinish={handleDetails}>
            <InputGroup
              label="Name / Phone / Email"
              className="ticket-purchase-inputbox"
              fieldType="searchbox"
              placeholder="Enter Name / Phone / Email"
              name="searchData"
              onChange={handleChange}
              value={getRegisteredTicketPurchasesDetails?.searchData}
            />
            <InputGroup
              fieldType="dropdown"
              className="ticket-purchase-inputbox"
              mode="single"
              name="ticketVariantId"
              label="Select Ticket Variant"
              placeholder="Select Ticket variant"
              onChange={handleChange}
              errorMessage={errorMessages.type}
              onPopupScroll={handleScroll}
              value={getRegisteredTicketPurchasesDetails?.ticketVariantId}>
              {props.ticketVariantData?.length > 0 &&
                props.ticketVariantData.map((item) => {
                  return (
                    <Option value={item?.id} key={item?.id}>
                      <div className="options-container">
                        <div className="ticket-purchase-details-container">
                          <p className="ticket-purchase-name">{item?.title}</p>
                        </div>
                      </div>
                    </Option>
                  );
                })}
            </InputGroup>

            <InputGroup
              label="Reference Id"
              className="ticket-purchase-inputbox"
              fieldType="searchbox"
              placeholder="Enter Reference Id"
              name="referenceId"
              onChange={handleChange}
              value={getRegisteredTicketPurchasesDetails?.referenceId}
            />

            <div style={{ flex: 1, display: 'flex' }}>
              <SubmitButton
                wrapperClass="ticket-purchase-submit-wrapper"
                className="ticket-purchase-submit-inputbox"
                buttonText="Search"
                fetchProgress={props.fetchProgress}
                disabled={props.fetchProgress}
              />
              <Button className="reset-button" onClick={handleReset}>
                Reset
              </Button>
            </div>
          </Form>
          <TableView
            onPageChange={onPageChange}
            paginationOptions={paginationOptions}
            isRouted={false}
            data={props.purchases}
            fetchProgress={props.fetchProgress}
            paginationLinks={props.purchaseLinks}
            blacklistKeys={['user', 'ticketVariant']}
            normalTime={true}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  purchases: getAllRegisteredPurchaseData(store, props),
  purchaseLinks: getAllRegisteredPurchaseLinks(store, props),
  ticketVariantData: getAllOccasionTicketVariants(store, props),
  ticketVariantLinks: store.ticketVariant.ticketVariantLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    {
      getRegisteredTicketPurchases,
      occasionTicketVariantView,
      registeredTicketPurchasesSearch
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(RegisteredTicketPurchases);
