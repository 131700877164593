import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  getReportsWithPaginationApi,
  getReportedPostCommentsWithPaginationApi,
  getReportedPostLikesWithPaginationApi
} from '../api/reports';

import {
  GET_REPORTS_WITH_PAGINATION,
  GET_REPORTED_POST_COMMENTS_WITH_PAGINATION,
  GET_REPORTED_POST_LIKES_WITH_PAGINATION
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  getReportsWithPaginationFailed,
  getReportsWithPaginationSuccess,
  getReportedPostCommentsWithPaginationFailed,
  getReportedPostCommentsWithPaginationSuccess,
  getReportedPostLikesWithPaginationFailed,
  getReportedPostLikesWithPaginationSuccess
} from '../actions/reports';

export function* getReportsWithPaginationSaga(action) {
  try {
    const result = yield call(
      getReportsWithPaginationApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getReportsWithPaginationSuccess(
          result.data,
          result?.included,
          result?.links,
          action.payload.ocId
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getReportsWithPaginationFailed(error));

    yield put(requestAutoHideAlert(error, 'Error', 4000));
  }
}

export function* getReportedPostCommentsWithPaginationSaga(action) {
  try {
    const result = yield call(
      getReportedPostCommentsWithPaginationApi,
      action.payload.ocId,
      action.payload.page,
      action.payload.commentableType,
      action.payload.commentableId,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getReportedPostCommentsWithPaginationSuccess(
          result.data,
          result?.included,
          result?.links
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getReportedPostCommentsWithPaginationFailed(error));

    yield put(requestAutoHideAlert(error, 'Error', 4000));
  }
}

export function* getReportedPostLikesWithPaginationSaga(action) {
  try {
    const result = yield call(
      getReportedPostLikesWithPaginationApi,
      action.payload.ocId,
      action.payload.page,
      action.payload.likeableType,
      action.payload.likeableId,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getReportedPostLikesWithPaginationSuccess(
          result.data,
          result?.included,
          result?.links
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getReportedPostLikesWithPaginationFailed(error));

    yield put(requestAutoHideAlert(error, 'Error', 4000));
  }
}

export default function* reportsActionWatcher() {
  yield takeLatest(
    GET_REPORTS_WITH_PAGINATION,
    progressSaga,
    getReportsWithPaginationSaga
  );
  yield takeLatest(
    GET_REPORTED_POST_COMMENTS_WITH_PAGINATION,
    progressSaga,
    getReportedPostCommentsWithPaginationSaga
  );
  yield takeLatest(
    GET_REPORTED_POST_LIKES_WITH_PAGINATION,
    progressSaga,
    getReportedPostLikesWithPaginationSaga
  );
}
