import { union } from 'lodash';
import {
  OCCASION_TICKET_VARIANT_FORM_VIEW_SUCCESS,
  OCCASION_TICKET_VARIANT_FORM_VIEW_FAILED,
  OCCASION_TICKET_VARIANT_FORM_CREATE_SUCCESS,
  OCCASION_TICKET_VARIANT_FORM_CREATE_FAILED
} from '../constants/actionTypes';

const initialState = {
  ticketVariantFormData: null,
  error: null,
  ticketVariantFormLinks: null
};

const ticketVariantForm = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_TICKET_VARIANT_FORM_VIEW_SUCCESS: {
      const { data, included, links, ticketId } = action.payload;
      const occasionGroupedData = state.ticketVariantFormData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];
      return {
        ...state,
        ticketVariantFormData: {
          ...occasionGroupedData,
          [ticketId]: [...currentOccasionNewIds]
        },
        ticketVariantFormLinks: links
      };
    }

    case OCCASION_TICKET_VARIANT_FORM_CREATE_SUCCESS: {
      const { data, included, ticketId } = action.payload;
      const occasionGroupedData = state.ticketVariantFormData || {};
      const currentTicketVariantFormIds = occasionGroupedData[ticketId] || [];

      const newIds = [data.id, ...currentTicketVariantFormIds];
      return {
        ...state,
        ticketVariantFormData: {
          ...occasionGroupedData,
          [ticketId]: newIds
        }
      };
    }

    case OCCASION_TICKET_VARIANT_FORM_VIEW_FAILED:
    case OCCASION_TICKET_VARIANT_FORM_CREATE_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default ticketVariantForm;
