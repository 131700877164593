import { union } from 'lodash';
import {
  OCCASION_POLL_POST_CREATE_FAILED,
  OCCASION_POLL_POST_CREATE_SUCCESS,
  OCCASION_POLL_POST_VIEW_FAILED,
  OCCASION_POLL_POST_VIEW_SUCCESS,
  DELETE_POLL_POST_FAILED,
  DELETE_POLL_POST_SUCCESS,
  OCCASION_POLL_POST_VIEW_BY_TYPE_SUCCESS,
  DELETE_POLL_POST_BY_TYPE_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  pollPostData: null,
  error: null,
  pollPostLinks: null,
  pollPostQuizData: null,
  pollPostPollData: null,
  pollPostQuizLinks: null,
  pollPostPollLinks: null
};

const pollPost = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_POLL_POST_VIEW_BY_TYPE_SUCCESS: {
      const { ocId, type, data, links } = action.payload;

      if (type === 'quiz') {
        const occasionGroupedData = state.pollPostQuizData || {};
        const NewIds = data && data?.length > 0 ? data.map((el) => el.id) : [];

        return {
          ...state,
          pollPostQuizData: {
            ...occasionGroupedData,
            [ocId]: [...NewIds]
          },
          pollPostQuizLinks: links
        };
      }
      if (type === 'poll') {
        const occasionGroupedData = state.pollPostPollData || {};
        const NewIds = data && data?.length > 0 ? data.map((el) => el.id) : [];

        return {
          ...state,
          pollPostPollData: {
            ...occasionGroupedData,
            [ocId]: [...NewIds]
          },
          pollPostPollLinks: links
        };
      }
      return state;
    }

    case DELETE_POLL_POST_BY_TYPE_SUCCESS: {
      const { ocId, type, data } = action.payload;
      if (type === 'quiz') {
        const occasionGroupedData = state.pollPostQuizData || {};
        const currentOccasionPollPostIds = occasionGroupedData[ocId] || [];
        const filteredIds = currentOccasionPollPostIds.filter(
          (id) => id !== data.id
        );

        return {
          ...state,
          pollPostQuizData: {
            ...occasionGroupedData,
            [ocId]: filteredIds
          }
        };
      }
      if (type === 'poll') {
        const occasionGroupedData = state.pollPostPollData || {};
        const currentOccasionPollPostIds = occasionGroupedData[ocId] || [];
        const filteredIds = currentOccasionPollPostIds.filter(
          (id) => id !== data.id
        );

        return {
          ...state,
          pollPostPollData: {
            ...occasionGroupedData,
            [ocId]: filteredIds
          }
        };
      }

      return state;
    }

    case OCCASION_POLL_POST_VIEW_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.pollPostData || {};
      const NewIds = data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        pollPostData: {
          ...occasionGroupedData,
          [ocId]: [...NewIds]
        },
        pollPostLinks: links
      };
    }

    case OCCASION_POLL_POST_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.pollPostData || {};
      const currentOccasionPollPostIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionPollPostIds];
      return {
        ...state,
        pollPostData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }

    case DELETE_POLL_POST_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.pollPostData || {};
      const currentOccasionPollPostIds = occasionGroupedData[ocId] || [];
      const filteredIds = currentOccasionPollPostIds.filter(
        (id) => id !== data.id
      );

      return {
        ...state,
        pollPostData: {
          ...occasionGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case OCCASION_POLL_POST_VIEW_FAILED:
    case OCCASION_POLL_POST_CREATE_FAILED:
    case DELETE_POLL_POST_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default pollPost;
