import {
  CREATE_FEATURE_CONTROL_SUCCESS,
  GET_FEATURE_CONTROLS_SUCCESS,
  CREATE_FEATURE_CONTROL_FAILED,
  GET_FEATURE_CONTROLS_FAILED,
  UPDATE_FEATURE_CONTROL_SUCCESS,
  UPDATE_FEATURE_CONTROL_FAILED
} from '../constants/actionTypes';

const initialState = {
  data: null,
  error: null
};

const featureControl = (state = initialState, action) => {
  switch (action.type) {
    case GET_FEATURE_CONTROLS_FAILED:
    case UPDATE_FEATURE_CONTROL_FAILED:
    case CREATE_FEATURE_CONTROL_FAILED: {
      return { ...state, error: action.error };
    }

    case UPDATE_FEATURE_CONTROL_SUCCESS:
    case CREATE_FEATURE_CONTROL_SUCCESS: {
      return {
        ...state
      };
    }

    case GET_FEATURE_CONTROLS_SUCCESS: {
      const newIds = action.payload.data.map((el) => el.id);
      return {
        ...state,
        data: newIds
      };
    }

    default:
      return state;
  }
};

export default featureControl;
