import { createSelector } from 'reselect';
import { currentOccasionId, currentGroupId } from './dynamicData';
import {
  groupEntities,
  userEntities,
  occasionGroupMemberEntities
} from './entity';
import {
  occasionGroupIds,
  occasionAllGroupIds,
  groupMemberIds,
  groupMemberLinks,
  groupOccasionUserLinks,
  groupOccasionUsers,
  groupOccasionSearchedUsers,
  groupOccasionSearchedUserLinks
} from './static';

export const currentAllOccasionGroupIds = createSelector(
  currentOccasionId,
  occasionAllGroupIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getOccasionGroupsWithAllData = createSelector(
  groupEntities,
  currentAllOccasionGroupIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const currentOccasionGroupIds = createSelector(
  currentOccasionId,
  occasionGroupIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getAllOccasionGroups = createSelector(
  groupEntities,
  currentOccasionGroupIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getCurrentGroup = createSelector(
  currentGroupId,
  groupEntities,
  (id, entities) => id && entities && entities[id]
);

export const currentGroupMemberIds = createSelector(
  currentOccasionId,
  currentGroupId,
  groupMemberIds,
  (ocId, groupId, ids) =>
    ocId && groupId && ids && ids[ocId] && ids[ocId][groupId]
);

export const getAllGroupMembers = createSelector(
  currentGroupMemberIds,
  occasionGroupMemberEntities,
  userEntities,
  (ids, entities, usersData) => {
    return (
      ids &&
      entities &&
      usersData &&
      ids.map((id) => {
        const users = usersData[entities[id]?.member?.id];
        return {
          users,
          ...entities[id]
        };
      })
    );
  }
);

export const getGroupMemberLinks = createSelector(
  currentOccasionId,
  currentGroupId,
  groupMemberLinks,
  (ocId, groupId, links) =>
    ocId && groupId && links && links[ocId] && links[ocId][groupId]
);

export const currentOccasionGroupMemberIds = createSelector(
  currentOccasionId,
  currentGroupId,
  groupOccasionUsers,
  (ocId, groupId, ids) =>
    ocId && groupId && ids && ids[ocId] && ids[ocId][groupId]
);

export const getAllOccasionGroupUsers = createSelector(
  currentOccasionGroupMemberIds,
  userEntities,
  (ids, entities) => {
    return ids && entities && ids.map((id) => entities[id]);
  }
);

export const getGroupOccasionUserLinks = createSelector(
  currentOccasionId,
  currentGroupId,
  groupOccasionUserLinks,
  (ocId, groupId, links) =>
    ocId && groupId && links && links[ocId] && links[ocId][groupId]
);

export const currentOccasionSearchedGroupMemberIds = createSelector(
  currentOccasionId,
  currentGroupId,
  groupOccasionSearchedUsers,
  (ocId, groupId, ids) =>
    ocId && groupId && ids && ids[ocId] && ids[ocId][groupId]
);

export const getAllOccasionSearchedGroupUsers = createSelector(
  currentOccasionSearchedGroupMemberIds,
  userEntities,
  (ids, entities) => {
    return ids && entities && ids.map((id) => entities[id]);
  }
);

export const getGroupOccasionSearchedUserLinks = createSelector(
  currentOccasionId,
  currentGroupId,
  groupOccasionSearchedUserLinks,
  (ocId, groupId, links) =>
    ocId && groupId && links && links[ocId] && links[ocId][groupId]
);
