import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import { SectionContent } from '../../../../components/Section';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import TableView from '../../../../components/Table';
import { getGroupMembers, deleteGroupMembers } from '../../../../actions/group';
import {
  getAllGroupMembers,
  getGroupMemberLinks
} from '../../../../selectors/group';
import './styles.scss';
import GroupMemberSearch from '../search';

const GroupMembersView = (props) => {
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.getGroupMembers(props.occasionId, props.groupId, page);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const deleteData = (id) => {
    props.deleteGroupMembers(props.occasionId, id, props.groupId);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="group-member-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <div className="count-container">
          <span>Members</span>
          <span className="count-text">{props.memberCount}</span>
        </div>
      </div>
    )
  };

  return (
    <ErrorBoundary>
      <SectionContent className="group-members-view-section">
        <div className="group-members-view-container">
          <GroupMemberSearch
            occasionId={props.occasionId}
            groupId={props.groupId}
          />
          <TableView
            isPaginated={true}
            isRouted={false}
            paginationOptions={paginationOptions}
            onPageChange={onPageChange}
            onDelete={deleteData}
            data={props.groupMembers}
            fetchProgress={props.fetchProgress}
            paginationLinks={props.groupMemberLinks}
            blacklistKeys={['groupId', 'member', 'memberId']}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  groupMembers: getAllGroupMembers(store, props),
  groupMemberLinks: getGroupMemberLinks(store, props),
  memberCount: store.group.groupMemberCount
});

const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ getGroupMembers, deleteGroupMembers }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(GroupMembersView);
