import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import { getEventPendingRegistration } from '../../../../../../actions/event';
import { getEventPendingRegistrationsWithUserData } from '../../../../../../selectors/event';

function EventPendingRegistration(props) {
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.getEventPendingRegistration(props.occasionId, props.eventId, page);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const paginationOptions = {
    showTotal: () => (
      <div className="invitation-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
      </div>
    )
  };

  return (
    <ErrorBoundary>
      <SectionContent className="event-invitation-view-section">
        <div className="event-invitation-view-container">
          <TableView
            isPaginated={true}
            paginationOptions={paginationOptions}
            fetchProgress={props.fetchProgress}
            onPageChange={onPageChange}
            paginationLinks={props.pendingRegistrationLink}
            data={props.pendingRegistrations}
            isRouted={false}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  pendingRegistrations: getEventPendingRegistrationsWithUserData(store, props),
  fetchProgress: store.browserState.fetchProgress,
  pendingRegistrationLink: store.event.eventPendingRegistrationLinks
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getEventPendingRegistration }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventPendingRegistration);
