import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import Tabs from '../../../../../components/Tabs';
import { TabsHeader } from '../../../../../components/TabsHeader';
import { getCurrentOccasion } from '../../../../../selectors/occasion';
import { currentCompetitionData } from '../../../../../selectors/competition';
import {
  currentOccasionId,
  currentCompetitionId
} from '../../../../../selectors/dynamicData';
import {
  resetTabIndex,
  setCurrentTabIndex
} from '../../../../../actions/tabKeys';
import ViewParticipant from './Participant/view';

function CompetitionDetails(props) {
  const getParticipantsComponents = () => {
    return (
      <ViewParticipant
        occasionId={props.occasionId}
        competitionId={props.currentCompetitionId}
      />
    );
  };

  const tabPanes = [
    {
      tabName: 'Participants',
      tabKey: '1',
      children: getParticipantsComponents()
    }
  ];

  const goBack = () => {
    props.resetTabIndex('competition');
    props.history.goBack();
  };

  const handleTabChange = (key) => {
    props.setCurrentTabIndex(key, 'competition');
  };

  return (
    <ErrorBoundary>
      <SectionContent className="occasion-competition-tab-section">
        <TabsHeader
          name={props.currentOccasion.name}
          extraData={[
            props.currentOccasion.type,
            props.currentOccasion.guestType,
            props.currentCompetition.name
          ]}
          wrapperClass="section-details"
        />
        <div className="occasion-competition-tab-container">
          <Tabs
            defaultActiveKey={props.activeTabKey}
            tabPanes={tabPanes}
            onChange={handleTabChange}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  activeTabKey: store.tabKeys.competition,
  fetchProgress: store.browserState.fetchProgress,
  currentCompetition: currentCompetitionData(store, props),
  occasionId: currentOccasionId(store, props),
  currentCompetitionId: currentCompetitionId(store, props),
  currentOccasion: getCurrentOccasion(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ resetTabIndex, setCurrentTabIndex }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompetitionDetails);
