import { createSelector } from 'reselect';
import { currentTicketId } from './dynamicData';
import { ticketVariantFormEntities } from './entity';
import { ticketVariantFormIds } from './static';

export const currentOccasionTicketVariantFormIds = createSelector(
  currentTicketId,
  ticketVariantFormIds,
  (ticketId, ids) => ticketId && ids && ids[ticketId]
);
export const getAllOccasionTicketVariantForms = createSelector(
  ticketVariantFormEntities,
  currentOccasionTicketVariantFormIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
