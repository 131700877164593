import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  deletePollPostApi,
  downloadPollPostByCsvApi,
  occasionPollPostCreateApi,
  occasionPollPostViewApi,
  occasionPollPostViewByTypeApi,
  updatePollPostApi
} from '../api/pollPost';
import {
  DELETE_POLL_POST,
  DELETE_POLL_POST_BY_TYPE,
  DOWNLOAD_POLL_POST_BY_CSV,
  OCCASION_POLL_POST_CREATE,
  OCCASION_POLL_POST_VIEW,
  OCCASION_POLL_POST_VIEW_BY_TYPE,
  UPDATE_POLL_POST
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  deletePollPostByTypeFailed,
  deletePollPostByTypeSuccess,
  deletePollPostFailed,
  deletePollPostSuccess,
  downloadPollPostByCsvFailed,
  downloadPollPostByCsvSuccess,
  occasionPollPostCreateFailed,
  occasionPollPostCreateSuccess,
  occasionPollPostViewByTypeFailed,
  occasionPollPostViewByTypeSuccess,
  occasionPollPostViewFailed,
  occasionPollPostViewSuccess,
  updatePollPostFailed,
  updatePollPostSuccess
} from '../actions/pollPost';

export function* occasionPollPostCreateSaga(action) {
  try {
    const result = yield call(
      occasionPollPostCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionPollPostCreateSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionPollPostCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionPollPostCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionPollPostViewSaga(action) {
  try {
    const result = yield call(
      occasionPollPostViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionPollPostViewSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionPollPostViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionPollPostUpdateSaga(action) {
  try {
    const result = yield call(
      updatePollPostApi,
      action.payload.ocId,
      action.payload.pollPostId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updatePollPostSuccess(
          action.payload.ocId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updatePollPostFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updatePollPostFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionPollPostDeleteSaga(action) {
  try {
    const result = yield call(
      deletePollPostApi,
      action.payload.ocId,
      action.payload.pollPostId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(deletePollPostSuccess(action.payload.ocId, result.data));
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deletePollPostFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* downloadPollPostByCsvSaga(action) {
  try {
    const result = yield call(
      downloadPollPostByCsvApi,
      action.payload.ocId,
      action.payload.pollPostId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(downloadPollPostByCsvSuccess(result.data, result.included));
      if (typeof action.callback === 'function') {
        action.callback(result?.blobUrl);
      }
    }
  } catch (error) {
    yield put(downloadPollPostByCsvFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionPollPostViewByTypeSaga(action) {
  try {
    const result = yield call(
      occasionPollPostViewByTypeApi,
      action.payload.ocId,
      action.payload.type,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionPollPostViewByTypeSuccess(
          action.payload.ocId,
          action.payload.type,
          result.data,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionPollPostViewByTypeFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionPollPostDeleteByTypeSaga(action) {
  try {
    const result = yield call(
      deletePollPostApi,
      action.payload.ocId,
      action.payload.pollPostId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        deletePollPostByTypeSuccess(
          action.payload.ocId,
          action.payload.type,
          result.data
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deletePollPostByTypeFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* pollPostActionWatcher() {
  yield takeLatest(
    OCCASION_POLL_POST_CREATE,
    progressSaga,
    occasionPollPostCreateSaga
  );
  yield takeLatest(
    OCCASION_POLL_POST_VIEW,
    progressSaga,
    occasionPollPostViewSaga
  );
  yield takeLatest(UPDATE_POLL_POST, progressSaga, occasionPollPostUpdateSaga);
  yield takeLatest(DELETE_POLL_POST, progressSaga, occasionPollPostDeleteSaga);
  yield takeLatest(
    DOWNLOAD_POLL_POST_BY_CSV,
    progressSaga,
    downloadPollPostByCsvSaga
  );

  yield takeLatest(
    OCCASION_POLL_POST_VIEW_BY_TYPE,
    progressSaga,
    occasionPollPostViewByTypeSaga
  );
  yield takeLatest(
    DELETE_POLL_POST_BY_TYPE,
    progressSaga,
    occasionPollPostDeleteByTypeSaga
  );
}
