import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import './styles.scss';
import {
  getFeatureControls,
  updateFeatureControl,
  createFeatureControl
} from '../../../../actions/featureControls';
import { currentOccasionId } from '../../../../selectors/dynamicData';
import { getCurrentOccasionFeatureControls } from '../../../../selectors/featureControls';
import { ControlComponent } from './ControlComponent';

const FeatureControls = (props) => {
  return (
    <div>
      <Button
        className="access-control-refresh-button "
        onClick={() => props.getFeatureControls(props.occasionId)}>
        <SyncOutlined />
      </Button>
      {Object.keys(props.featureControls?.feature)?.map((item) => {
        return (
          <ControlComponent
            createControl={props.createFeatureControl}
            updateControl={props.updateFeatureControl}
            currentControls={props.currentOccasionFeatureControls}
            data={item}
            fetchProgress={props.fetchProgress}
            entityName="feature_availability"
            filterString="feature"
            occasionId={props.occasionId}
            key={item}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  occasionId: currentOccasionId(store, props),
  currentOccasionFeatureControls: getCurrentOccasionFeatureControls(
    store,
    props
  ),
  featureControls: store.occasion.featureControls,
  fetchProgress: store.browserState.fetchProgress
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getFeatureControls, updateFeatureControl, createFeatureControl },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FeatureControls);
