import {
  OCCASION_GROUP_CREATE,
  OCCASION_GROUP_CREATE_SUCCESS,
  OCCASION_GROUP_CREATE_FAILED,
  OCCASION_GROUP_VIEW,
  OCCASION_GROUP_VIEW_SUCCESS,
  OCCASION_GROUP_VIEW_FAILED,
  OCCASION_GROUP_UPDATE,
  OCCASION_GROUP_UPDATE_SUCCESS,
  OCCASION_GROUP_UPDATE_FAILED,
  GET_GROUP_MEMBERS,
  GET_GROUP_MEMBERS_SUCCESS,
  GET_GROUP_MEMBERS_FAILED,
  CREATE_GROUP_MEMBERS,
  CREATE_GROUP_MEMBERS_SUCCESS,
  CREATE_GROUP_MEMBERS_FAILED,
  GET_GROUP_OCCASION_USERS,
  GET_GROUP_OCCASION_USERS_SUCCESS,
  GET_GROUP_OCCASION_USERS_FAILED,
  SEARCH_GROUP_OCCASION_USERS,
  SEARCH_GROUP_OCCASION_USERS_SUCCESS,
  SEARCH_GROUP_OCCASION_USERS_FAILED,
  PARSE_GROUP_MEMBERS_CSV,
  PARSE_GROUP_MEMBERS_CSV_SUCCESS,
  PARSE_GROUP_MEMBERS_CSV_FAILED,
  DELETE_GROUP_MEMBERS,
  DELETE_GROUP_MEMBERS_FAILED,
  DELETE_GROUP_MEMBERS_SUCCESS,
  DELETE_OCCASION_GROUP,
  DELETE_OCCASION_GROUP_FAILED,
  DELETE_OCCASION_GROUP_SUCCESS,
  SEARCH_GROUP_MEMBER,
  SEARCH_GROUP_MEMBER_SUCCESS,
  SEARCH_GROUP_MEMBER_FAILED
} from '../constants/actionTypes';

export const occasionGroupCreate = (ocId, formData, callback) => ({
  type: OCCASION_GROUP_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionGroupCreateSuccess = (data, included, ocId) => ({
  type: OCCASION_GROUP_CREATE_SUCCESS,
  payload: { data, included, ocId }
});

export const occasionGroupCreateFailed = (error) => ({
  type: OCCASION_GROUP_CREATE_FAILED,
  error
});

export const occasionGroupView = (ocId, page) => ({
  type: OCCASION_GROUP_VIEW,
  payload: { ocId, page }
});

export const occasionGroupViewSuccess = (data, included, ocId, links) => ({
  type: OCCASION_GROUP_VIEW_SUCCESS,
  payload: { data, included, ocId, links }
});

export const occasionGroupViewFailed = (error) => ({
  type: OCCASION_GROUP_VIEW_FAILED,
  error
});

export const occasionGroupUpdate = (ocId, groupId, formData, callback) => ({
  type: OCCASION_GROUP_UPDATE,
  payload: { ocId, groupId, formData },
  callback
});

export const occasionGroupUpdateSuccess = (data) => ({
  type: OCCASION_GROUP_UPDATE_SUCCESS,
  payload: { data }
});

export const occasionGroupUpdateFailed = (error) => ({
  type: OCCASION_GROUP_UPDATE_FAILED,
  error
});

export const getGroupMembers = (ocId, groupId, page) => ({
  type: GET_GROUP_MEMBERS,
  payload: { ocId, groupId, page }
});

export const getGroupMembersSuccess = (
  ocId,
  groupId,
  data,
  included,
  links,
  meta
) => ({
  type: GET_GROUP_MEMBERS_SUCCESS,
  payload: { ocId, groupId, data, included, links, meta }
});

export const getGroupMembersFailed = (error) => ({
  type: GET_GROUP_MEMBERS_FAILED,
  error
});

export const createGroupMembers = (ocId, formData, groupId, callback) => ({
  type: CREATE_GROUP_MEMBERS,
  payload: { ocId, formData, groupId },
  callback
});

export const createGroupMembersSuccess = (ocId, groupId, data, included) => ({
  type: CREATE_GROUP_MEMBERS_SUCCESS,
  payload: { data, included, ocId, groupId }
});

export const createGroupMembersFailed = (error) => ({
  type: CREATE_GROUP_MEMBERS_FAILED,
  error
});

export const getGroupOccasionUsers = (ocId, groupId, page) => ({
  type: GET_GROUP_OCCASION_USERS,
  payload: { ocId, groupId, page }
});

export const getGroupOccasionUsersSuccess = (
  ocId,
  groupId,
  data,
  links,
  page
) => ({
  type: GET_GROUP_OCCASION_USERS_SUCCESS,
  payload: { data, ocId, links, groupId, page }
});

export const getGroupOccasionUsersFailed = (error) => ({
  type: GET_GROUP_OCCASION_USERS_FAILED,
  error
});

export const searchGroupOccasionUsers = (ocId, groupId, page, query) => ({
  type: SEARCH_GROUP_OCCASION_USERS,
  payload: { ocId, groupId, page, query }
});

export const searchGroupOccasionUsersSuccess = (
  ocId,
  groupId,
  data,
  included,
  links,
  page
) => ({
  type: SEARCH_GROUP_OCCASION_USERS_SUCCESS,
  payload: { data, included, ocId, links, groupId, page }
});

export const searchGroupOccasionUsersFailed = (error) => ({
  type: SEARCH_GROUP_OCCASION_USERS_FAILED,
  error
});

export const parseCSVtoAddGroupMembers = (
  ocId,
  formData,
  groupId,
  callback
) => ({
  type: PARSE_GROUP_MEMBERS_CSV,
  payload: { ocId, formData, groupId },
  callback
});

export const parseCSVtoAddGroupMembersSuccess = (
  ocId,
  groupId,
  data,
  included
) => ({
  type: PARSE_GROUP_MEMBERS_CSV_SUCCESS,
  payload: { data, included, ocId, groupId }
});

export const parseCSVtoAddGroupMembersFailed = (error) => ({
  type: PARSE_GROUP_MEMBERS_CSV_FAILED,
  error
});

export const deleteGroupMembers = (ocId, memberId, groupId) => ({
  type: DELETE_GROUP_MEMBERS,
  payload: { ocId, memberId, groupId }
});

export const deleteGroupMembersSuccess = (
  ocId,
  groupId,
  data,
  included,
  links
) => ({
  type: DELETE_GROUP_MEMBERS_SUCCESS,
  payload: { ocId, groupId, data, included, links }
});

export const deleteGroupMembersFailed = (error) => ({
  type: DELETE_GROUP_MEMBERS_FAILED,
  error
});

export const deleteOccasionGroup = (ocId, groupId) => ({
  type: DELETE_OCCASION_GROUP,
  payload: { ocId, groupId }
});

export const deleteOccasionGroupSuccess = (
  ocId,
  groupId,
  data,
  included,
  links
) => ({
  type: DELETE_OCCASION_GROUP_SUCCESS,
  payload: { ocId, groupId, data, included, links }
});
export const deleteOccasionGroupFailed = (error) => ({
  type: DELETE_OCCASION_GROUP_FAILED,
  error
});
export const searchGroupMember = (
  ocId,
  groupId,
  page,
  memberType,
  searchData,
  callback
) => ({
  type: SEARCH_GROUP_MEMBER,
  payload: { ocId, groupId, page, memberType, searchData },
  callback
});

export const searchGroupMemberSuccess = (
  ocId,
  groupId,
  data,
  included,
  links,
  page,
  meta
) => ({
  type: SEARCH_GROUP_MEMBER_SUCCESS,
  payload: { ocId, groupId, data, included, links, page, meta }
});

export const searchGroupMemberFailed = (error) => ({
  type: SEARCH_GROUP_MEMBER_FAILED,
  error
});
