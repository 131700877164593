import axios from 'axios';

const objectToFormData = (obj, form, namespace) => {
  const fd = form || new FormData();
  let formKey;
  /* eslint-disable */
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = `${namespace}[${property}]`;
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (
        typeof obj[property] === 'object' &&
        !(obj[property] instanceof File)
      ) {
        objectToFormData(obj[property], fd, formKey);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  }
  /* eslint-enable */

  return fd;
};

const getDefaultOptions = (token) => {
  const defaultOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  };

  if (token) {
    defaultOptions.headers.Authorization = `Bearer ${token}`;
  }
  return defaultOptions;
};

const buildParam = (params, asJSON = true) => {
  if (asJSON) {
    return JSON.stringify(params);
  }
  if (params instanceof FormData) {
    return params;
  }
  return objectToFormData(params);
};

const request = async (uri, options = {}, updateProgress, external = false) => {
  const { token, isBlob } = options;

  const defaultOptions = getDefaultOptions(token);
  const requestOptions = options;

  requestOptions.method = options.method || defaultOptions.method;

  if (!options.formData) {
    requestOptions.headers = options.headers || defaultOptions.headers;
  } else {
    requestOptions.headers = {
      Authorization: `Bearer ${token}`
    };
  }

  requestOptions.credentials =
    options.credentials || defaultOptions.credentials;

  if (options.body) {
    if (options.formData) {
      requestOptions.body = buildParam(options.body, false);
    } else {
      requestOptions.headers['Content-Type'] =
        'application/json; charset=UTF-8';
      requestOptions.body = buildParam(options.body);
    }
  }

  const clientURL = process.env.REACT_APP_CLIENT_URL;
  const updatedURI = uri.startsWith('/') ? uri : `/${uri}`;

  const url = external ? uri : `${clientURL}${updatedURI}`;

  return axios({
    method: requestOptions.method,
    url,
    data: requestOptions.body,
    responseType: isBlob ? 'blob' : 'json',
    onUploadProgress:
      typeof updateProgress === 'function'
        ? (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            updateProgress(percentCompleted, requestOptions.body);
          }
        : null,
    headers: requestOptions.headers
  })
    .then((data) => {
      if (isBlob) {
        var binaryData = [];
        binaryData.push(data.data);
        return {
          blobUrl: URL.createObjectURL(
            new Blob(binaryData, { type: 'application/zip' })
          )
        };
      }

      return data.data;
    })
    .catch((error) => error.response?.data || error.message);
};

export default request;
