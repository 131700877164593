import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../components/Section';
import {
  getUserWithPagination,
  getUserBySearch
} from '../../../../actions/user';
import './styles.scss';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import TableView from '../../../../components/Table';
import { getAllUser } from '../../../../selectors/user';
import { renderRoute } from '../../../../components/RouterHOC';
import { adminViewRouteAccess } from '../../../../selectors/auth';
import InputGroup from '../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../components/InputGroup/FormComponents';
import Form from 'antd/lib/form/Form';
import { Button } from 'antd';
import { FROM_INTERNAL_TEAM, USER_STATUS } from '../UserConstants/Constants';

function UserView(props) {
  const [getUserDetails, setGetUserDetails] = React.useState({});
  const [errorMessages, setErrorMessages] = React.useState({});
  const onPageChange = (page) => {
    props.getUserWithPagination(page);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const validate = (values) => {
    const errors = {};
    if (!values.searchData && !values.fromInternalTeam && !values.status)
      errors.type = 'Search Data is required';

    return errors;
  };

  const handlegetUserDetails = (page) => {
    try {
      const validateObj = validate(getUserDetails);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        props.getUserBySearch(
          getUserDetails.searchData,
          getUserDetails.fromInternalTeam,
          getUserDetails.status,
          (page = 1)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'searchData') {
        value = e.target.value;
        name = type;
      }
      const userDetailsValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      setGetUserDetails({
        ...getUserDetails,
        [inputName]: userDetailsValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleReset = (page) => {
    setGetUserDetails({});
    setErrorMessages({});
    props.getUserWithPagination((page = 1));
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="user-count-container">
        <span>Users</span>
        <span className="user-count">{props.count}</span>
      </div>
    )
  };

  return (
    <ErrorBoundary>
      <SectionContent className="user-view-section">
        <div className="user-view-container">
          <Form
            className="user-input-search-container"
            onFinish={handlegetUserDetails}>
            <InputGroup
              label="Name/Phone/Email"
              className="user-inputbox"
              fieldType="searchbox"
              placeholder="Enter Name/Phone/Email"
              name="searchData"
              onChange={handleChange}
              value={getUserDetails?.searchData}
              errorMessage={errorMessages.type}
            />
            <InputGroup
              label="From Internal Team"
              className="user-inputbox"
              fieldType="dropdown"
              placeholder="Select"
              options={FROM_INTERNAL_TEAM}
              name="fromInternalTeam"
              onChange={handleChange}
              value={getUserDetails?.fromInternalTeam}
            />
            <InputGroup
              label="Status"
              className="user-inputbox"
              fieldType="dropdown"
              placeholder="Select"
              options={USER_STATUS}
              name="status"
              onChange={handleChange}
              value={getUserDetails?.status}
            />
            <div style={{ flex: 1, display: 'flex' }}>
              <SubmitButton
                wrapperClass="user-submit-wrapper"
                className="user-submit-inputbox"
                buttonText="Search"
                fetchProgress={props.fetchProgress}
                disabled={props.fetchProgress}
              />
              <Button className="reset-button" onClick={handleReset}>
                Reset
              </Button>
            </div>
          </Form>
          <TableView
            paginationOptions={paginationOptions}
            isPaginated={true}
            fetchProgress={props.fetchProgress}
            data={props.Users}
            paginationLinks={props.usersLinks}
            onPageChange={onPageChange}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  Users: getAllUser(store, props),
  fetchProgress: store.browserState.fetchProgress,
  usersLinks: store.user.links,
  count: store.user.count
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserWithPagination,
      getUserBySearch
    },
    dispatch
  );

export default renderRoute(adminViewRouteAccess)(
  connect(mapStateToProps, mapDispatchToProps)(UserView)
);
