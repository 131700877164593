import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup';
import { Switch } from '../../../../../components/InputGroup/FormComponents';
import {
  FormMap,
  SubmitButton
} from '../../../../../components/InputGroup/FormComponents';
import { getImageSource } from '../../../../../helpers/imageHelper';
import {
  occasionImportantContactCreate,
  occasionImportantContactUpdate
} from '../../../../../actions/importantContact';
import { getCurrentImportantContact } from '../../../../../selectors/importantContact';

const validateImportantContact = (values) => {
  const error = {};
  if (!values.name) {
    error.name = 'Name is required';
  }
  if (!values.category) {
    error.category = 'Category is required';
  }
  if (!values.phoneNumber && !values.email) {
    error.email = 'Phone Number OR  email is required';
  }
  if (
    !!values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    error.email = 'Invalid Email';
  }
  return error;
};

function CreateImportantContact(props) {
  const [importantContactData, setImportantContactData] = React.useState({
    name: props.currentImportantContact?.name || '',
    showcallflag:
      props.currentImportantContact?.showCallButton === false ? false : true,
    phoneNumber: props.currentImportantContact?.phone || '',
    email: props.currentImportantContact?.email || '',
    category: props.currentImportantContact?.category || '',
    description: props.currentImportantContact?.description || '',
    toggleAddress: props.currentImportantContact?.address ? true : false,
    address: props.currentImportantContact?.address || '',
    avatar: props.currentImportantContact?.avatarUrl
      ? [{ url: getImageSource(props.currentImportantContact?.avatarUrl) }]
      : null
  });
  const [errorMessages, setErrorMessages] = React.useState({});
  const [addressData, setAddressData] = React.useState({
    address: props.currentImportantContact?.address || ''
  });

  const handleSubmit = () => {
    try {
      const validateObj = validateImportantContact(importantContactData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('important_contact[name]', importantContactData.name);
        formData.append(
          'important_contact[show_call_button]',
          importantContactData?.showcallflag ? true : false
        );
        formData.append(
          'important_contact[category]',
          importantContactData.category
        );

        formData.append(
          'important_contact[phone]',
          importantContactData.phoneNumber
        );

        formData.append('important_contact[email]', importantContactData.email);
        formData.append(
          'important_contact[description]',
          importantContactData.description
        );
        if (!!importantContactData?.toggleAddress) {
          formData.append(
            'important_contact[address]',
            importantContactData.address
          );
        } else {
          formData.append('important_contact[address]', addressData.address);
        }

        if (
          importantContactData.avatar &&
          importantContactData.avatar[0]?.originFileObj
        ) {
          formData.append(
            'important_contact[avatar]',
            importantContactData.avatar[0].originFileObj
          );
        }
        props.importantContactId
          ? props.occasionImportantContactUpdate(
              props.occasionId,
              props.importantContactId,
              formData
            )
          : props.occasionImportantContactCreate(
              props.occasionId,
              formData,
              () => {
                setImportantContactData({ showcallflag: true });
              }
            );
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'avatar') {
        value = e.fileList;
        name = type;
      }
      if (type === 'showcallflag') {
        value = e;
        name = type;
      }
      if (type === 'toggleAddress') {
        value = e;
        name = type;
      }
      if (!!e.address) {
        setAddressData({ address: e.address });
      }
      const importantContactValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setImportantContactData({
        ...importantContactData,
        [inputName]: importantContactValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="important-contact-create-section">
        <div className="important-contact-create-form">
          <Form className="important-contact-form" onFinish={handleSubmit}>
            <InputGroup
              name="avatar"
              fieldType="file"
              label="User Image"
              accept="image/*"
              fileList={importantContactData?.avatar}
              onChange={handleInputChange}
            />

            <InputGroup
              fieldType="switch"
              name="showcallflag"
              label="Show Call Button"
              onChange={handleInputChange}
              value={importantContactData?.showcallflag}
            />

            <InputGroup
              label="Contact Name"
              name="name"
              placeholder="Enter Name"
              onChange={handleInputChange}
              value={importantContactData?.name}
              errorMessage={errorMessages.name}
            />
            <InputGroup
              label="Contact Category"
              name="category"
              placeholder="Enter Category"
              onChange={handleInputChange}
              value={importantContactData?.category}
              errorMessage={errorMessages.category}
            />
            <InputGroup
              label="Phone Number"
              name="phoneNumber"
              placeholder="Enter Phone Number"
              onChange={handleInputChange}
              value={importantContactData?.phoneNumber}
            />
            <InputGroup
              label="Email"
              name="email"
              placeholder="Enter Email"
              onChange={handleInputChange}
              value={importantContactData?.email}
              errorMessage={errorMessages.email}
            />
            <InputGroup
              name="description"
              placeholder="Enter Description"
              label="Description"
              fieldType="textArea"
              value={importantContactData?.description}
              onChange={handleInputChange}
            />
            <InputGroup
              fieldType="switch"
              name="toggleAddress"
              label="Toggle to Add Address Manually"
              onChange={handleInputChange}
              value={importantContactData?.toggleAddress}
            />
            {importantContactData?.toggleAddress && (
              <InputGroup
                label="Address"
                name="address"
                placeholder="Enter Address"
                onChange={handleInputChange}
                value={importantContactData?.address}
              />
            )}
            {!importantContactData?.toggleAddress && (
              <FormMap label="Address" onChange={handleInputChange} />
            )}
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={
                props.importantContactId ? 'Save Changes' : 'Create Contact'
              }
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentImportantContact: getCurrentImportantContact(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { occasionImportantContactCreate, occasionImportantContactUpdate },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateImportantContact);
