import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import TableView from '../../../../../components/Table';
import { Button } from 'antd';
import { guestView } from '../../../../../actions/guest';
import { getAllGuests } from '../../../../../selectors/guest';

const GuestView = (props) => {
  const onPageChange = (page) => {
    props.guestView(props.occasionId, props.userId, page);
  };
  const location = useLocation();
  React.useEffect(() => {
    onPageChange(1);
  }, []);
  const paginationOptions = {
    showTotal: () => <Button className="create-button">Create</Button>
  };
  return (
    <ErrorBoundary>
      <SectionContent className="member-guest-view-section">
        <div className="member-guest-view-container">
          <TableView
            isPaginated={true}
            isRouted={false}
            paginationOptions={paginationOptions}
            onPageChange={onPageChange}
            data={props.guestData}
            fetchProgress={props.fetchProgress}
            paginationLinks={props.guestLinks}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  guestData: getAllGuests(store, props),
  guestLinks: store.guest.guestLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ guestView }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(GuestView);
