import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getAccessControls,
  updateAccessControl,
  createAccessControl
} from '../../../../actions/accessControls';
import { SyncOutlined } from '@ant-design/icons';
import { currentOccasionId } from '../../../../selectors/dynamicData';
import { getCurrentOccasionAccessControls } from '../../../../selectors/accessControls';
import { Button } from 'antd';
import { ControlComponent } from './ControlComponent';

const AccessControls = (props) => {
  useEffect(() => {
    props.getAccessControls(props.occasionId);
  }, []);

  return (
    <div>
      <Button
        className="access-control-refresh-button "
        onClick={() => props.getAccessControls(props.occasionId)}>
        <SyncOutlined />
      </Button>
      {Object.keys(props.accessControls?.access_type)?.map((item) => {
        return (
          <ControlComponent
            data={item}
            filterString="accessType"
            currentControls={props.currentOccasionAccessControls}
            updateControl={props.updateAccessControl}
            createControl={props.createAccessControl}
            fetchProgress={props.fetchProgress}
            entityName="access_control"
            occasionId={props.occasionId}
            key={item}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  occasionId: currentOccasionId(store, props),
  currentOccasionAccessControls: getCurrentOccasionAccessControls(store, props),
  accessControls: store.occasion.accessControls,
  fetchProgress: store.browserState.fetchProgress
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getAccessControls, updateAccessControl, createAccessControl },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessControls);
