import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  codeCreateApi,
  codeViewApi,
  deletecodeApi,
  updatecodeApi
} from '../api/occasionCode';
import {
  CODE_CREATE,
  CODE_VIEW,
  DELETE_CODE,
  UPDATE_CODE
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  codeCreateFailed,
  codeCreateSuccess,
  codeViewFailed,
  codeViewSuccess,
  deleteCodeFailed,
  deleteCodeSuccess,
  updateCodeFailed,
  updateCodeSuccess
} from '../actions/occasionCode';

export function* codeCreateSaga(action) {
  try {
    const result = yield call(
      codeCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        codeCreateSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(codeCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(codeCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* codeViewSaga(action) {
  try {
    const result = yield call(
      codeViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        codeViewSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(codeViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* codeUpdateSaga(action) {
  try {
    const result = yield call(
      updatecodeApi,
      action.payload.ocId,
      action.payload.codeId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateCodeSuccess(action.payload.ocId, result.data, result?.included)
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateCodeFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateCodeFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* codeDeleteSaga(action) {
  try {
    const result = yield call(
      deletecodeApi,
      action.payload.ocId,
      action.payload.codeId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(deleteCodeSuccess(action.payload.ocId, result.data));
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteCodeFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* occasionCodeActionWatcher() {
  yield takeLatest(CODE_CREATE, progressSaga, codeCreateSaga);
  yield takeLatest(CODE_VIEW, progressSaga, codeViewSaga);
  yield takeLatest(UPDATE_CODE, progressSaga, codeUpdateSaga);
  yield takeLatest(DELETE_CODE, progressSaga, codeDeleteSaga);
}
