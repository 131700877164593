import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SyncOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import { Button } from 'antd';
import WhatsappTemplatesParamsCreate from './create';
import { getAllWhatsappTemplatesParamsData } from '../../../../../../selectors/whatsappTemplates';
import {
  getWhatsAppTemplateParamsWithPagination,
  deleteWhatsAppTemplateParams
} from '../../../../../../actions/whatsAppTemplates';

const WhatsappTemplateParamsView = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [
    whatsappTemplateParamsId,
    setWhatsappTemplateParamsId
  ] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.getWhatsAppTemplateParamsWithPagination(
      props.whatsappTemplatesId,
      props.whatsappTemplateModelableId,
      page
    );
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleCreate = () => {
    setWhatsappTemplateParamsId('');
    setCreateOpen(true);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="whatsapp-templates-params-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={handleCreate}>
          Create
        </Button>
      </div>
    )
  };

  const editData = (id) => {
    setWhatsappTemplateParamsId(id);
    setCreateOpen(true);
  };

  const deleteData = (id) => {
    props.deleteWhatsAppTemplateParams(
      props.whatsappTemplatesId,
      props.whatsappTemplateModelableId,
      id
    );
  };

  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="whatsapp-templates-params-view-section">
          <div className="whatsapp-templates-params-view-container">
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              isRouted={false}
              onPageChange={onPageChange}
              onDelete={deleteData}
              isEditable={editData}
              data={props.viewData}
              paginationLinks={props.Link}
              fetchProgress={props.fetchProgress}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <WhatsappTemplatesParamsCreate
        whatsappTemplatesId={props.whatsappTemplatesId}
        whatsappTemplateModelableId={props.whatsappTemplateModelableId}
        whatsappTemplateParamsId={whatsappTemplateParamsId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  viewData: getAllWhatsappTemplatesParamsData(store, props),
  Link: store.whatsappTemplates.whatsappTemplateParamsLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { getWhatsAppTemplateParamsWithPagination, deleteWhatsAppTemplateParams },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(WhatsappTemplateParamsView);
