import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  CreateTaskApi,
  hospitalityTaskCreateUpdateApi,
  ViewNotProvidedTaskApi,
  ViewNotRespondedTaskApi,
  ViewProvidedTaskApi,
  ViewRespondedTaskApi,
  ViewTaskApi,
  downloadHospitalityAnswerForProvidedTask,
  downloadHospitalityAnswerForRequestedTask,
  hospitalityTaskDeleteApi,
  deleteProvidedTaskApi,
  deleteRespondedTaskApi,
  hospitalityTaskProvideAnswerForCSVApi,
  hospitalityTaskProvideAnswerForAllApi,
  hospitalityTaskProvideAnswerApi,
  getAllTasksApi,
  searchNotProvidedTasksApi,
  searchProvidedTasksApi,
  searchNotRespondedTasksApi,
  searchRespondedTasksApi,
  hospitalityTaskRequestAnswerApi
} from '../api/hospitalityTask';
import {
  HOSPITALITY_TASK_CREATE,
  HOSPITALITY_TASK_CREATE_UPDATE,
  HOSPITALITY_TASK_NOTPROVIDED_VIEW,
  HOSPITALITY_TASK_NOTRESPONDED_VIEW,
  HOSPITALITY_TASK_PROVIDED_VIEW,
  HOSPITALITY_TASK_RESPONDED_VIEW,
  HOSPITALITY_TASK_VIEW,
  HOSPITALITY_PROVIDED_TASK_ANSWER_DOWNLOAD,
  HOSPITALITY_REQUESTED_TASK_ANSWER_DOWNLOAD,
  HOSPITALITY_TASK_DELETE,
  HOSPITALITY_REQUESTED_TASK_ANSWER_DELETE,
  HOSPITALITY_PROVIDED_TASK_ANSWER_DELETE,
  HOSPITALITY_TASK_PROVIDE_ANSWER,
  GET_ALL_TASKS,
  SEARCH_NOTPROVIDED_TASKS,
  SEARCH_PROVIDED_TASKS,
  SEARCH_NOTRESPONDED_TASKS,
  SEARCH_RESPONDED_TASKS
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  hospitalityTaskCreateFailed,
  hospitalityTaskCreateSuccess,
  hospitalityTaskCreateUpdateFailed,
  hospitalityTaskCreateUpdateSuccess,
  hospitalityTaskNotProvidedViewFailed,
  hospitalityTaskNotProvidedViewSuccess,
  hospitalityTaskNotRespondedViewFailed,
  hospitalityTaskNotRespondedViewSuccess,
  hospitalityTaskProvidedViewFailed,
  hospitalityTaskProvidedViewSuccess,
  hospitalityTaskRespondedViewFailed,
  hospitalityTaskRespondedViewSuccess,
  hospitalityTaskViewFailed,
  hospitalityTaskViewSuccess,
  hospitalityProvidedTaskDownloadFailed,
  hospitalityProvidedTaskDownloadSuccess,
  hospitalityRequestedTaskDownloadFailed,
  hospitalityRequestedTaskDownloadSuccess,
  hospitalityTaskDeleteSuccess,
  hospitalityTaskDeleteFailed,
  hospitalityRequestedTaskDeleteSuccess,
  hospitalityRequestedTaskDeleteFailed,
  hospitalityProvidedTaskDeleteSuccess,
  hospitalityProvidedTaskDeleteFailed,
  hospitalityTaskProvideAnswerSuccess,
  hospitalityTaskProvideAnswerFailed,
  getAllTasksSuccess,
  getAllTasksFailed,
  searchNotProvidedTasksSuccess,
  searchNotProvidedTasksFailed,
  searchProvidedTasksSuccess,
  searchProvidedTasksFailed,
  searchNotRespondedTasksSuccess,
  searchNotRespondedTasksFailed,
  searchRespondedTasksSuccess,
  searchRespondedTasksFailed
} from '../actions/hospitalityTask';
import { entityDeleteRequestSuccess } from '../actions/entity';

export function* hospitalityTaskCreateSaga(action) {
  try {
    const result = yield call(
      CreateTaskApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hospitalityTaskCreateSuccess(
          result.data,
          result.included,
          action.payload.hospitalityCategoryId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(hospitalityTaskCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(hospitalityTaskCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hospitalityTaskViewSaga(action) {
  try {
    const result = yield call(
      ViewTaskApi,
      action.payload.ocId,
      action.payload.page,
      action.payload.hospitalityCategoryId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hospitalityTaskViewSuccess(
          result.data,
          result.included,
          result.links,
          action.payload.hospitalityCategoryId
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(hospitalityTaskViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getAllTasksSaga(action) {
  try {
    const result = yield call(
      getAllTasksApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getAllTasksSuccess(
          result.data,
          result.included,
          result.links,
          action.payload.ocId
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getAllTasksFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hospitalityTaskCreateUpdateSaga(action) {
  try {
    const result = yield call(
      hospitalityTaskCreateUpdateApi,
      action.payload.ocId,
      action.payload.hospitalityTaskId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hospitalityTaskCreateUpdateSuccess(
          action.payload.ocId,
          action.payload.hospitalityCategoryId,
          result.data,
          result.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(hospitalityTaskCreateUpdateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(hospitalityTaskCreateUpdateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hospitalityTaskDeleteSaga(action) {
  try {
    const result = yield call(
      hospitalityTaskDeleteApi,
      action.payload.ocId,
      action.payload.hospitalityTaskId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hospitalityTaskDeleteSuccess(
          action.payload.ocId,
          action.payload.hospitalityCategoryId,
          result.data,
          result.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(hospitalityTaskDeleteFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hospitalityTaskRespondedViewSaga(action) {
  try {
    const result = yield call(
      ViewRespondedTaskApi,
      action.payload.ocId,
      action.payload.hospitalityTaskId,
      action.payload.page,

      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hospitalityTaskRespondedViewSuccess(
          action.payload.hospitalityTaskId,
          result.data,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(hospitalityTaskRespondedViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hospitalityTaskNotRespondedViewSaga(action) {
  try {
    const result = yield call(
      ViewNotRespondedTaskApi,
      action.payload.ocId,
      action.payload.hospitalityTaskId,
      action.payload.page,

      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hospitalityTaskNotRespondedViewSuccess(
          action.payload.hospitalityTaskId,
          result.data,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(hospitalityTaskNotRespondedViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hospitalityTaskProvidedViewSaga(action) {
  try {
    const result = yield call(
      ViewProvidedTaskApi,
      action.payload.ocId,
      action.payload.hospitalityTaskId,
      action.payload.page,

      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hospitalityTaskProvidedViewSuccess(
          action.payload.hospitalityTaskId,
          result.data,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(hospitalityTaskProvidedViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hospitalityTaskNotProvidedViewSaga(action) {
  try {
    const result = yield call(
      ViewNotProvidedTaskApi,
      action.payload.ocId,
      action.payload.hospitalityTaskId,
      action.payload.page,

      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hospitalityTaskNotProvidedViewSuccess(
          action.payload.hospitalityTaskId,
          result.data,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(hospitalityTaskNotProvidedViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hospitalityTaskProvidedDownloadSaga(action) {
  try {
    const result = yield call(
      downloadHospitalityAnswerForProvidedTask,
      action.payload.ocId,
      action.payload.taskId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hospitalityProvidedTaskDownloadSuccess(result.data, result.included)
      );
      if (typeof action.callback === 'function') {
        action.callback(result?.blobUrl);
      }
    }
  } catch (error) {
    yield put(hospitalityProvidedTaskDownloadFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hospitalityTaskRequestedDownloadSaga(action) {
  try {
    const result = yield call(
      downloadHospitalityAnswerForRequestedTask,
      action.payload.ocId,
      action.payload.taskId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hospitalityRequestedTaskDownloadSuccess(result.data, result.included)
      );
      if (action.callback) {
        action.callback(result?.blobUrl);
      }
    }
  } catch (error) {
    yield put(hospitalityRequestedTaskDownloadFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hospitalityTaskProvidedAnswerDeleteSaga(action) {
  try {
    const result = yield call(
      deleteProvidedTaskApi,
      action.payload.ocId,
      action.payload.hospitalityTaskId,
      action.payload.answerId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hospitalityProvidedTaskDeleteSuccess(
          action.payload.hospitalityTaskId,
          result.data,
          result.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      yield put(
        entityDeleteRequestSuccess({
          type: 'hospitalityTaskAnswers',
          id: action.payload.answerId
        })
      );
    }
  } catch (error) {
    yield put(hospitalityProvidedTaskDeleteFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hospitalityTaskRespondedAnswerDeleteSaga(action) {
  try {
    const result = yield call(
      deleteRespondedTaskApi,
      action.payload.ocId,
      action.payload.hospitalityTaskId,
      action.payload.answerId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hospitalityRequestedTaskDeleteSuccess(
          action.payload.hospitalityTaskId,
          result.data,
          result.included
        )
      );

      yield put(
        entityDeleteRequestSuccess({
          type: 'hospitalityTaskAnswers',
          id: action.payload.answerId
        })
      );

      if (action.callback) {
        action.callback(result?.blobUrl);
      }
    }
  } catch (error) {
    yield put(hospitalityRequestedTaskDeleteFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hospitalityTaskProvideAnswerSaga(action) {
  try {
    const result = yield call(
      action.payload.isCSV
        ? hospitalityTaskProvideAnswerForCSVApi
        : action.payload.sendToAll
        ? hospitalityTaskProvideAnswerForAllApi
        : action.payload.type === 'provide'
        ? hospitalityTaskProvideAnswerApi
        : hospitalityTaskRequestAnswerApi,
      action.payload.ocId,
      action.payload.hospitalityTaskId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hospitalityTaskProvideAnswerSuccess(
          action.payload.ocId,
          action.payload.hospitalityTaskId,
          result.data,
          result.included,
          !action.payload.isCSV && !action.payload.sendToAll
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(hospitalityTaskProvideAnswerFailed(error));
    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* searchNotProvidedTasksSaga(action) {
  try {
    const result = yield call(
      searchNotProvidedTasksApi,
      action.payload.ocId,
      action.payload.hospitalityTaskId,
      action.payload.taskData,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        searchNotProvidedTasksSuccess(
          action.payload.hospitalityTaskId,
          result.data,
          result.included,
          result.links,
          result?.meta
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(searchNotProvidedTasksFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* searchProvidedTasksSaga(action) {
  try {
    const result = yield call(
      searchProvidedTasksApi,
      action.payload.ocId,
      action.payload.hospitalityTaskId,
      action.payload.taskData,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        searchProvidedTasksSuccess(
          action.payload.hospitalityTaskId,
          result.data,
          result.included,
          result.links,
          result?.meta
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(searchProvidedTasksFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* searchNotRespondedTasksSaga(action) {
  try {
    const result = yield call(
      searchNotRespondedTasksApi,
      action.payload.ocId,
      action.payload.hospitalityTaskId,
      action.payload.taskData,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        searchNotRespondedTasksSuccess(
          action.payload.hospitalityTaskId,
          result.data,
          result.included,
          result.links,
          result?.meta
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(searchNotRespondedTasksFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* searchRespondedTasksSaga(action) {
  try {
    const result = yield call(
      searchRespondedTasksApi,
      action.payload.ocId,
      action.payload.hospitalityTaskId,
      action.payload.taskData,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        searchRespondedTasksSuccess(
          action.payload.hospitalityTaskId,
          result.data,
          result.included,
          result.links,
          result?.meta
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(searchRespondedTasksFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* hospitalityTaskActionWatcher() {
  yield takeLatest(
    HOSPITALITY_TASK_CREATE,
    progressSaga,
    hospitalityTaskCreateSaga
  );
  yield takeLatest(
    HOSPITALITY_TASK_VIEW,
    progressSaga,
    hospitalityTaskViewSaga
  );
  yield takeLatest(GET_ALL_TASKS, progressSaga, getAllTasksSaga);
  yield takeLatest(
    HOSPITALITY_TASK_CREATE_UPDATE,
    progressSaga,
    hospitalityTaskCreateUpdateSaga
  );
  yield takeLatest(
    HOSPITALITY_TASK_RESPONDED_VIEW,
    progressSaga,
    hospitalityTaskRespondedViewSaga
  );
  yield takeLatest(
    HOSPITALITY_TASK_NOTRESPONDED_VIEW,
    progressSaga,
    hospitalityTaskNotRespondedViewSaga
  );
  yield takeLatest(
    HOSPITALITY_TASK_PROVIDED_VIEW,
    progressSaga,
    hospitalityTaskProvidedViewSaga
  );
  yield takeLatest(
    HOSPITALITY_TASK_NOTPROVIDED_VIEW,
    progressSaga,
    hospitalityTaskNotProvidedViewSaga
  );

  yield takeLatest(
    HOSPITALITY_PROVIDED_TASK_ANSWER_DOWNLOAD,
    progressSaga,
    hospitalityTaskProvidedDownloadSaga
  );

  yield takeLatest(
    HOSPITALITY_REQUESTED_TASK_ANSWER_DOWNLOAD,
    progressSaga,
    hospitalityTaskRequestedDownloadSaga
  );

  yield takeLatest(
    HOSPITALITY_TASK_DELETE,
    progressSaga,
    hospitalityTaskDeleteSaga
  );

  yield takeLatest(
    HOSPITALITY_PROVIDED_TASK_ANSWER_DELETE,
    progressSaga,
    hospitalityTaskProvidedAnswerDeleteSaga
  );

  yield takeLatest(
    HOSPITALITY_REQUESTED_TASK_ANSWER_DELETE,
    progressSaga,
    hospitalityTaskRespondedAnswerDeleteSaga
  );

  yield takeLatest(
    HOSPITALITY_TASK_PROVIDE_ANSWER,
    progressSaga,
    hospitalityTaskProvideAnswerSaga
  );
  yield takeLatest(
    SEARCH_NOTPROVIDED_TASKS,
    progressSaga,
    searchNotProvidedTasksSaga
  );
  yield takeLatest(
    SEARCH_PROVIDED_TASKS,
    progressSaga,
    searchProvidedTasksSaga
  );

  yield takeLatest(
    SEARCH_NOTRESPONDED_TASKS,
    progressSaga,
    searchNotRespondedTasksSaga
  );
  yield takeLatest(
    SEARCH_RESPONDED_TASKS,
    progressSaga,
    searchRespondedTasksSaga
  );
}
