import {
  EVENT_COLLABORATOR_CREATE,
  EVENT_COLLABORATOR_CREATE_FAILED,
  EVENT_COLLABORATOR_CREATE_SUCCESS,
  EVENT_COLLABORATOR_VIEW,
  EVENT_COLLABORATOR_VIEW_FAILED,
  EVENT_COLLABORATOR_VIEW_SUCCESS,
  EVENT_COLLABORATOR_UPDATE,
  EVENT_COLLABORATOR_UPDATE_FAILED,
  EVENT_COLLABORATOR_UPDATE_SUCCESS,
  EVENT_COLLABORATOR_DELETE,
  EVENT_COLLABORATOR_DELETE_FAILED,
  EVENT_COLLABORATOR_DELETE_SUCCESS,
  EVENT_COLLABORATOR_SEARCH,
  EVENT_COLLABORATOR_SEARCH_FAILED,
  EVENT_COLLABORATOR_SEARCH_SUCCESS
} from '../constants/actionTypes';

export const eventCollaboratorSearch = (ocId, page, eventId, memberData) => ({
  type: EVENT_COLLABORATOR_SEARCH,
  payload: { ocId, page, eventId, memberData }
});

export const eventCollaboratorSearchSuccess = (
  ocId,
  eventId,
  data,
  included,
  links,
  meta
) => ({
  type: EVENT_COLLABORATOR_SEARCH_SUCCESS,
  payload: { ocId, eventId, data, included, links, meta }
});

export const eventCollaboratorSearchFailed = (error) => ({
  type: EVENT_COLLABORATOR_SEARCH_FAILED,
  error
});

export const eventCollaboratorCreate = (ocId, formData, eventId, callback) => ({
  type: EVENT_COLLABORATOR_CREATE,
  payload: { ocId, formData, eventId },
  callback
});

export const eventCollaboratorCreateSuccess = (
  ocId,
  eventId,
  data,
  included,
  links
) => ({
  type: EVENT_COLLABORATOR_CREATE_SUCCESS,
  payload: { ocId, eventId, data, included, links }
});

export const eventCollaboratorCreateFailed = (error) => ({
  type: EVENT_COLLABORATOR_CREATE_FAILED,
  error
});

export const eventCollaboratorView = (ocId, page, eventId) => ({
  type: EVENT_COLLABORATOR_VIEW,
  payload: { ocId, page, eventId }
});

export const eventCollaboratorViewSuccess = (
  ocId,
  eventId,
  data,
  included,
  links,
  meta
) => ({
  type: EVENT_COLLABORATOR_VIEW_SUCCESS,
  payload: { ocId, eventId, data, included, links, meta }
});

export const eventCollaboratorViewFailed = (error) => ({
  type: EVENT_COLLABORATOR_VIEW_FAILED,
  error
});

export const eventCollaboratorUpdate = (
  ocId,
  eventCollaboratorId,
  eventId,
  formData,
  callback
) => ({
  type: EVENT_COLLABORATOR_UPDATE,
  payload: { ocId, eventCollaboratorId, eventId, formData },
  callback
});

export const eventCollaboratorUpdateSuccess = (
  ocId,
  eventId,
  data,
  included,
  links
) => ({
  type: EVENT_COLLABORATOR_UPDATE_SUCCESS,
  payload: { ocId, eventId, data, included, links }
});

export const eventCollaboratorUpdateFailed = (error) => ({
  type: EVENT_COLLABORATOR_UPDATE_FAILED,
  error
});

export const eventCollaboratorDelete = (
  ocId,
  eventCollaboratorId,
  eventId
) => ({
  type: EVENT_COLLABORATOR_DELETE,
  payload: { ocId, eventCollaboratorId, eventId }
});

export const eventCollaboratorDeleteSuccess = (
  ocId,
  eventId,
  data,
  included,
  links
) => ({
  type: EVENT_COLLABORATOR_DELETE_SUCCESS,
  payload: { ocId, eventId, data, included, links }
});

export const eventCollaboratorDeleteFailed = (error) => ({
  type: EVENT_COLLABORATOR_DELETE_FAILED,
  error
});
