import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import TableView from '../../../../../components/Table';
import {
  getOccasionPendingInvitation,
  resendPendingInvitation,
  deleteOccasionPendingInvitation
} from '../../../../../actions/Invitation';
import { pendingInvitationsWithUserData } from '../../../../../selectors/invitation';
import PendingInvitationSearchTab from './PendingInvitationSearch';
import { Button } from 'antd';
import PendingInvitationEdit from './PendingInvitationEdit';

function PendingInvitation(props) {
  const [pendingInvitationId, setPendingInvitationId] = React.useState(null);
  const [isEditable, setIsEditable] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.getOccasionPendingInvitation(props.occasionId, page);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const resendInvite = (allOrId) => {
    props.resendPendingInvitation(props.occasionId, allOrId);
  };

  const customAction = (id) => {
    return (
      <Button className="action-button" onClick={() => resendInvite(id)}>
        Send
      </Button>
    );
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="occasion-invitation-button-container">
        <div className="button-content">
          <Button className="refresh-button" onClick={() => onPageChange(page)}>
            <SyncOutlined />
          </Button>
          <Button
            className="invitation-action-button"
            onClick={() => resendRegistration(true)}>
            Send Invitations to All
          </Button>
        </div>
        <div className="count-container">
          <span>Invitations</span>
          <span className="count-text">{props.count}</span>
        </div>
      </div>
    )
  };

  const deleteData = (id) => {
    props.deleteOccasionPendingInvitation(props.occasionId, id);
  };

  const editData = (id) => {
    setIsEditable(true);
    setPendingInvitationId(id);
  };
  if (!isEditable) {
    return (
      <ErrorBoundary>
        <SectionContent className="invitation-view-section">
          <div className="invitation-view-container">
            <PendingInvitationSearchTab ocId={props.occasionId} />
            <TableView
              isPaginated={true}
              fetchProgress={props.fetchProgress}
              paginationOptions={paginationOptions}
              onPageChange={onPageChange}
              onDelete={deleteData}
              isEditable={editData}
              data={props.pendingInvitations}
              isRouted={false}
              paginationLinks={props.pendingInvitationLink}
              customAction={customAction}
              normalTime={true}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <PendingInvitationEdit
        pendingInvitationId={pendingInvitationId}
        occasionId={props.occasionId}
        handleCloseClick={() => setIsEditable(false)}
      />
    </div>
  );
}
const mapStateToProps = (store, props) => ({
  pendingInvitations: pendingInvitationsWithUserData(store, props),
  fetchProgress: store.browserState.fetchProgress,
  pendingInvitationLink: store.invitation.pendingInvitationLink,
  count: store.invitation.pendingInvitationCount
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOccasionPendingInvitation,
      resendPendingInvitation,
      deleteOccasionPendingInvitation
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PendingInvitation);
