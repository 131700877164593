import request from './request';

export function activityTrackerAbsentUserViewApi(ocId, trackerId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/activity_tracker/activity_trackers/${trackerId}/get_activity_tracker_absent_users?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}
