export const EVENT_GUEST_TYPES = [
  { label: 'Open', value: 'open' },
  { label: 'Restricted', value: 'restricted' },
  { label: 'Closed', value: 'closed' }
];

export const EVENT_TAG = [
  { label: 'Normal', value: 'normal' },
  { label: 'Special', value: 'special' }
];
