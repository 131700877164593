export const camelize = (str) =>
  String(str).replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return '';
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });

export const getOccasionTypeName = (type) => {
  const camelizedType = camelize(type);
  // when type ends with 's' should add 'es' at end
  if (camelizedType.endsWith('s')) {
    return `${String(camelizedType)}es`;
  }
  // when type ends with y
  if (camelizedType.endsWith('y')) {
    // when there is a vowel before 'y' should put 's' at end
    if (/^[aeiou]$/i.test(camelizedType.charAt(camelizedType.length - 2))) {
      return `${String(camelizedType)}s`;
    }
    // when there is a consonant before 'y' should replace 'y' with 'ies'

    return String(camelizedType).replace(/.$/, 'ies');
  }
  return `${String(camelizedType)}s`;
};

export const getPseudoRandomNumber = () => {
  const randomNumber = Math.random();
  return randomNumber;
};

export const getRandomValueFromArray = (arr) => {
  const randomNumber = Math.floor(getPseudoRandomNumber() * 10);
  const randomIndex =
    randomNumber <= arr.length - 1 ? randomNumber : randomNumber % arr.length;
  return arr[randomIndex];
};

export const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};
