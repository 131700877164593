export const OCCASION_TYPES = [
  { label: 'Marriage', value: 'Marriage' },
  { label: 'Devotional', value: 'Devotional' },
  { label: 'Birthday', value: 'Birthday' },
  { label: 'Seminar', value: 'Seminar' },
  { label: 'Conference', value: 'Conference' },
  { label: 'College Fest', value: 'College Fest' },
  { label: 'Performance', value: 'Performance' },
  { label: 'Festival', value: 'Festival' },
  { label: 'Workshop', value: 'Workshop' },
  { label: 'Exhibition', value: 'Exhibition' },
  { label: 'Fashion', value: 'Fashion' },
  { label: 'Press Release', value: 'Press Release' },
  { label: 'Felicitation', value: 'Felicitation' }
];

export const GUEST_ACCESS_TYPES = {
  open: 'Open',
  restricted: 'Moderated',
  closed: 'Invite only'
};

export const ACCESS_CONTROL_TYPES = {
  guest_can_invite: 0,
  join_as_guest: 1,
  owner_destroy_post: 2,
  manager_destroy_post: 3,
  guest_create_post: 4
};

export const STATUS_TYPES = { on: 0, off: 1, disabled: 2 };

export const FEATURE_CONTROLS_TYPES = {
  sponsorships: 0,
  important_contacts: 1,
  awards: 2,
  advertisements: 3,
  tickets: 4,
  live_streams: 5,
  hospitalities: 6
};

export const GENDERS = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Unsure', value: 'unsure' },
  { label: 'Prefer Not to Disclose', value: 'prefer_not_to_disclose' }
];

export const ADMIN_ACCESS_TYPES = [
  { label: 'Admin', value: 'admin' },
  { label: 'Super Admin', value: 'super_admin' }
];

export const TABLE_KEY_VALUES = {
  avatarUrl: 'Avatar',

  floorPlanImageUrls: 'Floor Plan Images',
  cardImageUrl: 'Image',
  adress1: 'Address',
  managedFarmLogoUrl: 'Managed Farm Logo',
  avatarName: 'Avatar',
  groupPictureUrl: 'GroupIcon',
  awardImageUrl: 'AwardImage',
  sponsorshipImageUrl: 'SponsorshipImage',
  carouselImageUrl: 'Carousel Image',
  albumCardImageUrl: 'AlbumCardImage',
  documentAttachmentUrl: 'Documents',
  qrcodeUrl: 'CodeScanner',
  iconImageUrl: 'Image',
  foodImageUrl: 'Food Image',
  occasionFileThumbUrl: 'Occasion File Thumbnail',
  occasionFileUrl: 'Occasion File',
  bgImageUrl: 'Background Image',
  thankYouMediaThumbUrl: 'Thank You Media Thumbnail',
  thankYouMediaUrl: 'Thank You Media',
  splashScreenFileUrls: 'Splash Screen Files',
  backgroundFileUrl: 'Background File',
  speakerImageUrl: 'Speaker Image',
  currentRequestedTaskAnswer: 'Answer',
  currentProvidedTaskAnswer: 'Answer',
  qrCodeUrl: 'QR Code',
  alarmTime: 'Alarm Time (UTC)',
  selfieImageUrl: 'Selfie Image'
};

export const COLOR_SET = ['#cff7ea', '#fad9c5', '#d5f7cf', '#c4c8f7'];
export const RandColors = [
  '#EC5224',
  '#2474EC',
  '#24EC8C',
  '#AC24EC',
  '#EC2484',
  '#ECA824'
];

export const MEMBER_CONSTANTS = [
  { label: 'Owner', value: 'Owner' },
  { label: 'Manager', value: 'Manager' },
  { label: 'Guest', value: 'Guest' },
  { label: 'Visitor', value: 'Visitor' }
];

export const ACCESS_TYPE = [
  { label: 'Owner', value: 'owner' },
  { label: 'Manager', value: 'manager' },
  { label: 'Guest', value: 'guest' },
  { label: 'Visitor', value: 'visitor' }
];
