import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, AutoComplete, Progress } from 'antd';
import InputGroup from '../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import {
  createMedia,
  updateImageList,
  clearUploadHistory,
  getGalleryInfo
} from '../../../../../../actions/gallery';
import { currentOccasionId } from '../../../../../../selectors/dynamicData';
import { getSearchedInternalUsers } from '../../../../../../selectors/socianaInternalUser';
import { adminData } from '../../../../../../selectors/auth';
import { getProcessedVideo } from '../../../../../../helpers/videoHelper';
import '../../styles.scss';
import { getCompressImage } from '../../../../../../helpers/imageHelper';
import { getDataFromFile } from '../../../../../../helpers/authHelper';
import {
  getGalleryUUID,
  getMediaUploadList
} from '../../../../../../selectors/gallery';
import { DeleteOutlined } from '@ant-design/icons';

const validateGalleryData = (values) => {
  const errors = {};
  if (!values.media) {
    errors.media = 'You need to post atleast something';
  }
  return errors;
};

const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1820
};

const GalleryCreate = (props) => {
  const [galleryData, setGalleryData] = useState({
    searchData: { value: '', id: null },
    media: null
  });

  useEffect(() => {
    props.getGalleryInfo(props.occasionId);
  }, []);

  const [typeOfFile, setTypeOfFile] = useState('images');
  const [errorMessages, setErrorMessages] = useState({});

  const updateProgressList = (percentDone, body) => {
    props.updateImageList(
      props.occasionId,
      { ...getDataFromFile(body.get('media[file]')), completed: 'STARTED' },
      percentDone / 100
    );
  };

  const handleSubmit = async () => {
    try {
      const validateObj = validateGalleryData(galleryData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        let mediaList = [];
        if (galleryData.media) {
          await Promise.all(
            galleryData.media.map(async (file) => {
              const formData = new FormData();
              formData.append('media[mediable_type]', 'Gallary');
              formData.append('media[mediable_id]', props.galleryId);
              if (!!file?.originFileObj && file?.type?.indexOf('image') >= 0) {
                // const imgFile = await getCompressImage(
                //   file?.originFileObj,
                //   options
                // );
                // imgFile['uid'] = new Date().getTime();
                formData.append('media[file]', file?.originFileObj);
                mediaList = [...mediaList, formData];
                return;
              }

              if (!!file?.originFileObj && file?.type?.indexOf('video') >= 0) {
                formData.append('media[file]', file?.originFileObj);
                mediaList = [...mediaList, formData];
              }
            })
          );
        }
        props.createMedia(
          props.occasionId,
          mediaList,
          updateProgressList,
          typeOfFile,
          () => {
            setGalleryData({
              media: null
            });
          }
        );
      }
    } catch (error) {
      console.error('Error in submitting form', error);
    }
  };

  const clearHistory = () => {
    props.clearUploadHistory(props.occasionId);
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (type === 'media') {
          value = e.fileList;
          name = type;
        }
        if (type === 'searchData') {
          value = {
            value: e?.value,
            id: e?.key
          };
          name = type;
        }

        const assignFormValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        setGalleryData({
          ...galleryData,
          [inputName]: assignFormValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSwitchChange = () => {
    if (typeOfFile === 'images') {
      setTypeOfFile('video');
    } else {
      setTypeOfFile('images');
    }
    setGalleryData({
      ...galleryData,
      media: null
    });
  };

  return (
    <Form className="form-control-container" onFinish={handleSubmit}>
      <InputGroup
        fieldType="switch"
        label={`Toggle to upload ${
          typeOfFile === 'images' ? 'video' : 'images'
        }`}
        onChange={handleSwitchChange}
        value={typeOfFile === 'images'}
      />

      <InputGroup
        fieldType="file"
        label={`Upload ${typeOfFile}`}
        placeholder={`Select ${typeOfFile === 'images' ? 'images' : 'videos'}`}
        accept={typeOfFile === 'images' ? 'image/*' : 'video/*'}
        fileList={galleryData?.media}
        onChange={handleInputChange}
        name="media"
        errorMessage={errorMessages.media}
        multiple
        maxCount={typeOfFile === 'images' ? 200 : 5}
      />
      <SubmitButton
        buttonText={`Upload ${typeOfFile === 'images' ? 'images' : 'videos'}`}
        fetchProgress={props.fetchProgress}
        disabled={props.fetchProgress}
      />
      {props.uploadList && (
        <div className="upload-list">
          <div className="upload-list-header">
            <p className="upload-heading">Upload History</p>
            <button
              className="clear-history"
              disabled={props.fetchProgress}
              onClick={clearHistory}>
              <DeleteOutlined />
              Clear History
            </button>
          </div>
          {props.uploadList?.map((item) => {
            return (
              <p
                className={`upload-media-message-container ${
                  item.completed === 'ERRORED' ? 'errored-text' : ''
                }`}>
                <span className="upload-media-message">{item.name}</span>
                <Progress
                  type="circle"
                  percent={parseInt(item.progress) * 100}
                  width={30}
                  status={
                    item.completed === 'ERRORED' ? 'exception' : 'success'
                  }
                />
              </p>
            );
          })}
        </div>
      )}
    </Form>
  );
};

const mapStateToProps = (store, props) => ({
  occasionId: currentOccasionId(store, props),
  searchedInternalUser: getSearchedInternalUsers(store, props),
  admin: adminData(store, props),
  uploadList: getMediaUploadList(store, props),
  userAccessTypes: store.occasion.accessTypes,
  fetchProgress: store.browserState.fetchProgress,
  galleryId: getGalleryUUID(store, props)
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { createMedia, updateImageList, clearUploadHistory, getGalleryInfo },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GalleryCreate);
