import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import './styles.scss';
import { getEventMembers } from '../../../../../../actions/event';
import { eventMembersWithUserData } from '../../../../../../selectors/event';

const EventMembersView = (props) => {
  const [page, setPage] = React.useState(1);
  const location = useLocation();
  const onPageChange = (page) => {
    setPage(page);
    props.getEventMembers(props.occasionId, props.eventId, page);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const paginationOptions = {
    showTotal: () => (
      <div className="event-member-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
      </div>
    )
  };

  return (
    <ErrorBoundary>
      <SectionContent className="event-members-view-section">
        <div className="event-members-view-container">
          <TableView
            isPaginated={true}
            isRouted={false}
            paginationOptions={paginationOptions}
            onPageChange={onPageChange}
            data={props.eventMembers}
            fetchProgress={props.fetchProgress}
            paginationLinks={props.eventMemberLinks}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  eventMembers: eventMembersWithUserData(store, props),
  eventMemberLinks: store.event.eventMemberLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ getEventMembers }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(EventMembersView);
