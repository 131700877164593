import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import { capitalize } from '../../../../../helpers/stringHelper';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import { getCurrentMember } from '../../../../../selectors/members';
import { updateMember } from '../../../../../actions/members';

const MemberEdit = (props) => {
  const [memberData, setMemberData] = useState({
    accessType: props.currentMemberData?.type || '',
    roleName: props.currentMemberData?.roleName || ''
  });

  const handleSubmit = () => {
    try {
      const formData = new FormData();
      formData.append('collaborator[type]', memberData?.accessType);
      formData.append('collaborator[role_name]', memberData?.roleName);
      props.updateMember(
        props.occasionId,
        props.currentCollaboratorId,
        formData
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let formValue;
      let inputName;

      if (type === 'accessType') {
        value = e;
        name = type;
      }

      formValue = value || e?.target?.value;
      inputName = name || e?.target?.name;

      setMemberData({
        ...memberData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="occasion-member-edit-section">
        <div className="occasion-member-edit-form">
          <Form
            className="occassion-member-edit-form-container"
            onFinish={handleSubmit}>
            <InputGroup
              name="roleName"
              placeholder=" Enter Role Name"
              label="Role Name"
              value={memberData?.roleName}
              onChange={handleInputChange}
            />
            <InputGroup
              label="Access Type"
              className="memberType-inputbox"
              fieldType="dropdown"
              placeholder="Select Access Type"
              options={props?.userAccessTypes?.map((item) => ({
                label: capitalize(item),
                value: capitalize(item)
              }))}
              name="accessType"
              onSelect={(e) => handleInputChange(e, 'accessType')}
              value={memberData?.accessType}
            />
            <SubmitButton
              buttonText="Save Changes"
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentMemberData: getCurrentMember(store, props),
  userAccessTypes: store.occasion.accessTypes
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateMember }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MemberEdit);
