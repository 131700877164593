import {
  OCCASION_TICKET_VARIANT_FORM_CREATE,
  OCCASION_TICKET_VARIANT_FORM_CREATE_SUCCESS,
  OCCASION_TICKET_VARIANT_FORM_CREATE_FAILED,
  OCCASION_TICKET_VARIANT_FORM_VIEW,
  OCCASION_TICKET_VARIANT_FORM_VIEW_SUCCESS,
  OCCASION_TICKET_VARIANT_FORM_VIEW_FAILED
} from '../constants/actionTypes';

export const occasionTicketVariantFormCreate = (
  ocId,
  formData,
  ticketId,
  callback
) => ({
  type: OCCASION_TICKET_VARIANT_FORM_CREATE,
  payload: { ocId, formData, ticketId },
  callback
});

export const occasionTicketVariantFormCreateSuccess = (
  data,
  included,
  ticketId
) => ({
  type: OCCASION_TICKET_VARIANT_FORM_CREATE_SUCCESS,
  payload: { data, included, ticketId }
});

export const occasionTicketVariantFormCreateFailed = (error) => ({
  type: OCCASION_TICKET_VARIANT_FORM_CREATE_FAILED,
  error
});

export const occasionTicketVariantFormView = (ocId, page, ticketId) => ({
  type: OCCASION_TICKET_VARIANT_FORM_VIEW,
  payload: { ocId, page, ticketId }
});

export const occasionTicketVariantFormViewSuccess = (
  data,
  included,
  links,
  ticketId
) => ({
  type: OCCASION_TICKET_VARIANT_FORM_VIEW_SUCCESS,
  payload: { data, included, links, ticketId }
});

export const occasionTicketVariantFormViewFailed = (error) => ({
  type: OCCASION_TICKET_VARIANT_FORM_VIEW_FAILED,
  error
});
