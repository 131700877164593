import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, AutoComplete, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { occasionTicketVariantFormView } from '../../../../../../actions/ticketVariantForm';
import { getAllOccasionTicketVariantForms } from '../../../../../../selectors/ticketVariantForm';
import { ANSWER_TYPE, NO_OF_OPTIONS } from './QuestionConstant';
import { occasionQuestionCreate } from '../../../../../../actions/question';

const validate = (values) => {
  const error = {};
  let newArr = Object.keys(values).filter((item) =>
    item.includes('OptionData')
  );

  if (
    (Number(values.questionOptions) > newArr.length &&
      values.answerType === 'mcq') ||
    values.answerType === 'scq'
  ) {
    for (let i = 1; i <= Number(values.questionOptions); i++) {
      if (!values[`OptionData${i}`]) {
        error[`OptionData${i}`] = 'Cannot be empty';
      }
    }
  }

  newArr.forEach((item) => {
    if (!values[item]) {
      error[item] = 'Cannot be empty';
    }
  });

  if (!values.question) {
    error.question = 'Question is required';
  }

  if (!values.answerType) {
    error.answerType = 'Answer Type is required';
  }

  if (!values.ticketVariantForm) {
    error.ticketVariantForm = 'Select Ticket Variant Form';
  }

  return error;
};

const { Option } = AutoComplete;

function CreateQuestion(props) {
  const [questionData, setQuestionData] = React.useState({
    question: '',
    ticketVariantForm: '',
    answerType: '',
    questionOptions: '2'
  });
  const [errorMessages, setErrorMessages] = React.useState({});

  const onPageChange = (page) => {
    props.occasionTicketVariantFormView(props.occasionId, page, props.ticketId);
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleSubmit = () => {
    try {
      const validateObj = validate(questionData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('questionable_form[question]', questionData.question);
        formData.append(
          'questionable_form[questionable_id]',
          questionData.ticketVariantForm
        );
        if (
          questionData.answerType === 'mcq' ||
          questionData.answerType === 'scq'
        ) {
          formData.append(
            'questionable_form[answer_type]',
            questionData.answerType
          );
          for (let i = 0; i < questionData.questionOptions; i++) {
            const options = `OptionData${i + 1}`;
            formData.append(
              'questionable_form[questionable_form_option_attributes][]',
              questionData[options]
            );
          }
        } else {
          formData.append(
            'questionable_form[answer_type]',
            questionData.answerType
          );
        }
        props.occasionQuestionCreate(
          props.occasionId,
          formData,
          props.ticketId,
          () => {
            setQuestionData({});
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      const questionValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setQuestionData({
        ...questionData,
        [inputName]: questionValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  const optionsData = React.useCallback(() => {
    const arr = [];
    for (let i = 1; i <= questionData.questionOptions; i++) {
      const optiondata = `OptionData${i}`;
      const label = `Option ${i}`;
      arr.push(
        <InputGroup
          name={optiondata}
          label={label}
          onChange={handleInputChange}
          value={questionData[optiondata]}
          errorMessage={errorMessages[optiondata]}
        />
      );
    }
    return <div>{arr.map((item) => item)}</div>;
  }, [questionData, errorMessages]);
  return (
    <ErrorBoundary>
      <SectionContent className="question-create-section">
        <div className="question-create-container">
          <Form className="question-create-form" onFinish={handleSubmit}>
            <InputGroup
              fieldType="dropdown"
              className="ticket-variant-form-search"
              mode="single"
              name="ticketVariantForm"
              label="Select Ticket Variant Form"
              placeholder="Select Ticket variant Form"
              onChange={handleInputChange}
              value={questionData?.ticketVariantForm}
              errorMessage={errorMessages.ticketVariantForm}>
              {props.ticketVariantFormData?.length > 0 &&
                props.ticketVariantFormData.map((item) => {
                  return (
                    <Option value={item?.id} key={item?.id}>
                      <div className="options-container">
                        <div className="ticket-variant-form-details-container">
                          <p className="ticket-variant-form-name">
                            {item?.formName}
                          </p>
                        </div>
                      </div>
                    </Option>
                  );
                })}
            </InputGroup>
            <InputGroup
              label="Answer Type"
              fieldType="dropdown"
              placeholder="Select"
              options={ANSWER_TYPE}
              name="answerType"
              onChange={handleInputChange}
              value={questionData?.answerType}
              errorMessage={errorMessages.answerType}
            />
            <InputGroup
              label="Question"
              name="question"
              placeholder="Ask question"
              onChange={handleInputChange}
              value={questionData?.question}
              errorMessage={errorMessages.question}
            />
            {questionData.answerType == 'mcq' ||
            questionData.answerType == 'scq' ? (
              <InputGroup
                fieldType="dropdown"
                options={NO_OF_OPTIONS}
                name="questionOptions"
                label="No of Options"
                onChange={handleInputChange}
                value={questionData?.questionOptions}
              />
            ) : (
              ''
            )}
            {questionData.answerType == 'mcq' ||
            questionData.answerType == 'scq'
              ? optionsData()
              : ''}
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={'Create'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  ticketVariantFormData: getAllOccasionTicketVariantForms(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { occasionQuestionCreate, occasionTicketVariantFormView },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuestion);
