import React from 'react';
import { Tooltip, Image } from 'antd';
import './styles.scss';

export const WhatsappTemplateMessageContent = ({ data }) => {
  return (
    <div className="whatsapp-template-message-container-in-pop">
      <div className="whatsapp-template-message-header">
        <Tooltip
          overlayClassName="profile_content_tooltip"
          placement="left"
          title={data?.elementName}>
          <p className="whatsapp-template-message-name-in-pop">
            {data?.elementName}
          </p>
        </Tooltip>

        <Tooltip
          overlayClassName="profile_content_tooltip"
          placement="left"
          title={data?.messageType}>
          <p className="whatsapp-template-message-type-in-pop">
            {data?.messageType}
          </p>
        </Tooltip>
      </div>

      <div className="whatsapp-template-message-extra">
        <div>
          <b>Body: </b>
          {data?.body ? data.body : ''}
        </div>
        <div>
          <b>Footer: </b>
          {data?.body ? data.footer : ''}
        </div>
      </div>
    </div>
  );
};
