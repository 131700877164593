import { union } from 'lodash';
import {
  OCCASION_TAB_SETTING_UPDATE_FAILED,
  OCCASION_TAB_SETTING_VIEW_SUCCESS,
  OCCASION_TAB_SETTING_VIEW_FAILED,
  OCCASION_TAB_SETTING_CREATE_SUCCESS,
  OCCASION_TAB_SETTING_CREATE_FAILED
} from '../constants/actionTypes';

const initialState = {
  occasionTabSettingData: null,
  error: null,
  occasionTabSettingLinks: null
};

const occasionTabSetting = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_TAB_SETTING_VIEW_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.occasionTabSettingData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionTabSettingData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        occasionTabSettingLinks: links
      };
    }

    case OCCASION_TAB_SETTING_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionTabSettingData || {};
      const currentOccasionTabSettingIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionTabSettingIds];
      return {
        ...state,
        occasionTabSettingData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }

    case OCCASION_TAB_SETTING_VIEW_FAILED:
    case OCCASION_TAB_SETTING_CREATE_FAILED:
    case OCCASION_TAB_SETTING_UPDATE_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default occasionTabSetting;
