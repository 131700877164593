import { createSelector } from 'reselect';
import { currentOccasionId, currentActivityTrackerId } from './dynamicData';
import { activityTrackerEntities, userEntities } from './entity';
import { activityTrackerIds, blackListUserIds } from './static';

export const currentActivityTrackerIds = createSelector(
  currentOccasionId,
  activityTrackerIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getAllActivityTrackerIds = createSelector(
  activityTrackerEntities,
  currentActivityTrackerIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
export const activityTrackerWithUserData = createSelector(
  getAllActivityTrackerIds,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      createdBy: { ...entities[item?.createdBy?.id] }
    }))
);

export const currentActivityTrackerData = createSelector(
  currentActivityTrackerId,
  activityTrackerEntities,
  (id, entity) => id && entity && entity[id]
);

export const currentBlackListIds = createSelector(
  currentOccasionId,
  blackListUserIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getAllBlackListUsers = createSelector(
  userEntities,
  currentBlackListIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
