import {
  CREATE_POST_SUCCESS,
  CREATE_POST_FAILED,
  GET_POST_PAGINATION_FAILED,
  GET_POST_PAGINATION_SUCCESS,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAILED,
  EDIT_POST_FAILED
} from '../constants/actionTypes';
import { union } from 'lodash';

const initialState = {
  data: null,
  error: null,
  links: null,
  eventPostData: null,
  eventPostLinks: null
};

const post = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_POST_FAILED:
    case EDIT_POST_FAILED:
    case GET_POST_PAGINATION_FAILED:
    case CREATE_POST_FAILED: {
      return { ...state, error: action.error };
    }

    case CREATE_POST_SUCCESS: {
      const { occasionId, postableType, data } = action.payload;
      if (postableType === 'Occasion') {
        const prevData = state.data || {};
        const prevPostIds = prevData[occasionId] || [];
        const newIds = [...prevPostIds, data.id];
        return {
          ...state,
          data: {
            [occasionId]: newIds
          }
        };
      }
      if (postableType === 'Event') {
        const prevData = state.eventPostData || {};
        const prevPostIds = prevData[occasionId] || [];
        const newIds = [...prevPostIds, data.id];
        return {
          ...state,
          eventPostData: {
            [occasionId]: newIds
          }
        };
      }
      return state;
    }

    case GET_POST_PAGINATION_SUCCESS: {
      const { data, occasionId, postableType, links } = action.payload;
      if (postableType === 'Occasion') {
        const occasionGroupedData = state.data || {};
        const currentOccasionPostIds = occasionGroupedData[occasionId] || [];
        const currentOccasionPostNewIds =
          data && data?.length > 0 ? data.map((el) => el.id) : [];

        return {
          ...state,
          data: {
            ...occasionGroupedData,
            [occasionId]: [
              ...union(currentOccasionPostNewIds, currentOccasionPostIds)
            ]
          },
          links: links
        };
      }
      if (postableType === 'Event') {
        const occasionGroupedData = state.eventPostData || {};
        const currentOccasionPostIds = occasionGroupedData[occasionId] || [];
        const currentOccasionPostNewIds =
          data && data?.length > 0 ? data.map((el) => el.id) : [];

        return {
          ...state,
          eventPostData: {
            ...occasionGroupedData,
            [occasionId]: [
              ...union(currentOccasionPostNewIds, currentOccasionPostIds)
            ]
          },
          eventPostLinks: links
        };
      }
      return state;
    }

    case DELETE_POST_SUCCESS: {
      const { data, occasionId, postableType } = action.payload;
      if (postableType === 'Occasion') {
        const occasionGroupedData = state.data || {};
        const currentOccasionPostIds = occasionGroupedData[occasionId] || [];
        const currentOccasionPostNewIds = currentOccasionPostIds?.filter(
          (id) => id != data.id
        );

        return {
          ...state,
          data: {
            ...occasionGroupedData,
            [occasionId]: currentOccasionPostNewIds
          }
        };
      }
      if (postableType === 'Event') {
        const occasionGroupedData = state.eventPostData || {};
        const currentOccasionPostIds = occasionGroupedData[occasionId] || [];
        const currentOccasionPostNewIds = currentOccasionPostIds?.filter(
          (id) => id != data.id
        );

        return {
          ...state,
          eventPostData: {
            ...occasionGroupedData,
            [occasionId]: currentOccasionPostNewIds
          }
        };
      }
      return state;
    }

    default:
      return state;
  }
};

export default post;
