import { createSelector } from 'reselect';
import { userDeviceEntities } from './entity';
import { userDeviceIds } from './static';
import { currentUserId } from './dynamicData';

export const currentuserDeviceIds = createSelector(
  currentUserId,
  userDeviceIds,
  (userId, ids) => userId && ids && ids[userId]
);

export const getAllUserDeviceData = createSelector(
  userDeviceEntities,
  currentuserDeviceIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
