import React from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Button, Progress } from 'antd';
import {
  createLocationTrigger,
  updateLocationTrigger
} from '../../../../../../actions/trigger';
import { CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup/index';
import {
  SubmitButton,
  FormMap
} from '../../../../../../components/InputGroup/FormComponents';
import { currentLocationTrigger } from '../../../../../../selectors/trigger';
import { getProcessedVideo } from '../../../../../../helpers/videoHelper';

const validateLocationData = (values) => {
  const errors = {};
  if (!values.message) {
    errors.message = 'Message is required';
  }
  return errors;
};
const LocationTriggerCreate = (props) => {
  const [locationTriggerData, setLocationTriggerData] = React.useState({
    message: props.initialData?.message || '',
    triggerRadius: props.initialData?.triggerRadius || 1,
    location: props.initialData?.location || {},
    loc_trigger_media: props.initialData?.loc_trigger_media || null
  });
  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);
  const [errorMessages, setErrorMessages] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [percent, setPercent] = React.useState(0);

  const updateProgress = (e) => {
    const percentVal = Math.round(e.ratio * 100);
    setPercent(percentVal);
  };

  const handleLocationTriggerCreate = async () => {
    try {
      const validateObj = validateLocationData(locationTriggerData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        const formData = new FormData();
        if (
          locationTriggerData?.loc_trigger_media &&
          locationTriggerData?.loc_trigger_media[0]?.originFileObj
        ) {
          let file = locationTriggerData?.loc_trigger_media[0];
          if (!!file?.originFileObj && file?.type?.indexOf('image') >= 0) {
            formData.append(
              'location_trigger[media_file]',
              locationTriggerData?.loc_trigger_media[0]?.originFileObj
            );
          }
          if (!!file?.originFileObj && file?.type?.indexOf('video') >= 0) {
            setLoading(true);
            const videoFile = await getProcessedVideo(
              file?.originFileObj,
              updateProgress
            );

            formData.append('location_trigger[media_file]', videoFile);
          }
        }
        formData.append(
          'location_trigger[message]',
          locationTriggerData?.message
        );
        formData.append(
          'location_trigger[radius]',
          locationTriggerData?.triggerRadius
        );
        formData.append('location_trigger[modelable_id]', props.occasionId);
        formData.append('location_trigger[modelable_type]', 'Occasion');
        formData.append(
          'location_trigger[location]',
          locationTriggerData?.location?.address
        );
        formData.append(
          'location_trigger[latitude]',
          locationTriggerData?.location?.lat
        );
        formData.append(
          'location_trigger[longitude]',
          locationTriggerData?.location?.lng
        );
        if (props.triggerId) {
          props.updateLocationTrigger(
            props.occasionId,
            props.triggerId,
            formData,
            () => {
              setLocationTriggerData({
                triggerRadius: 1,
                message: '',
                location: {},
                loc_trigger_media: null
              });
              props.handleCloseClick();
            }
          );
        } else {
          props.createLocationTrigger(props.occasionId, formData, () => {
            setLocationTriggerData({
              triggerRadius: 1,
              message: '',
              location: {},
              loc_trigger_media: null
            });
            props.handleCloseClick();
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (isInitiallyDisabled) {
          setInitiallyDisabled(false);
        }
        if (type === 'loc_trigger_media') {
          value = e.fileList;
          name = type;
        }
        if (type === 'triggerRadius') {
          value = e;
          name = type;
        }
        if (type === 'venue') {
          value = e;
          name = 'location';
        }
        const locationTriggerValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        setLocationTriggerData({
          ...locationTriggerData,
          [inputName]: locationTriggerValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ErrorBoundary>
      <SectionContent className="location-trigger-section">
        <div className="location-trigger-create">
          <Form
            className="location-trigger-form-container"
            onFinish={handleLocationTriggerCreate}>
            <InputGroup
              fieldType="file"
              label="Upload photo, gif or video"
              accept="image/*,video/*"
              fileList={locationTriggerData?.loc_trigger_media}
              onChange={handleChange}
              name="loc_trigger_media"
              errorMessage={errorMessages.loc_trigger_media}
              multiple={false}
            />
            {loading && (
              <p className="compressing-video-message-container">
                <span className="compressing-video-message">
                  Compressing video
                </span>
                <Progress type="circle" percent={percent} width={30} />
              </p>
            )}
            <InputGroup
              label="Message"
              placeholder="Enter Message"
              name="message"
              fieldType="textArea"
              onChange={handleChange}
              value={locationTriggerData?.message}
              errorMessage={errorMessages?.message}
            />
            <FormMap
              label="Location"
              value={locationTriggerData?.location}
              errorMessage={errorMessages.location}
              onChange={handleChange}
            />
            <InputGroup
              fieldType="slider"
              max={60}
              value={locationTriggerData?.triggerRadius}
              min={1}
              label="Trigger Radius (in kms) "
              onChange={handleChange}
              name="triggerRadius"
              errorMessage={errorMessages?.triggerRadius}
            />
            <SubmitButton
              buttonText={props.triggerId ? 'Save Changes' : 'Create'}
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => {
  const currentTrigger = currentLocationTrigger(store, props);
  const initialData = {};
  if (currentTrigger) {
    initialData.message = currentTrigger.message;
    initialData.triggerRadius = currentTrigger.radius;
    initialData.location = {
      address: currentTrigger.location,
      lat: currentTrigger.latitude,
      lng: currentTrigger.longitude
    };
    initialData.loc_trigger_media = currentTrigger.loc_trigger_media;
  }
  return {
    fetchProgress: store.browserState.fetchProgress,
    initialData
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { createLocationTrigger, updateLocationTrigger },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationTriggerCreate);
