import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import {
  hardLogoutCreate,
  updateHardLogout
} from '../../../../../actions/hardLogout';
import { currentHardLogoutData } from '../../../../../selectors/hardLogout';

function CreateHardLogout(props) {
  const [hardLogoutData, setHardLogoutData] = React.useState({
    isEnable: props.currentHardLogoutData?.isEnable === true ? true : false
  });

  const handleSubmit = () => {
    try {
      const formData = new FormData();

      formData.append(
        'hard_logout[is_enable]',
        hardLogoutData.isEnable ? true : false
      );

      props.hardLogoutId
        ? props.updateHardLogout(props.occasionId, props.hardLogoutId, formData)
        : props.hardLogoutCreate(props.occasionId, formData, () => {
            setHardLogoutData({ isEnable: false });
          });
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;

      if (type === 'isEnable') {
        value = e;
        name = type;
      }

      const HardLogoutValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;

      setHardLogoutData({
        ...hardLogoutData,
        [inputName]: HardLogoutValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="hard-logout-create-section">
        <div className="hard-logout-create-form">
          <Form className="hard-logout-form" onFinish={handleSubmit}>
            <InputGroup
              label="Is Enable"
              name="isEnable"
              fieldType="switch"
              onChange={handleInputChange}
              value={hardLogoutData?.isEnable}
            />

            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.hardLogoutId ? 'Save Changes' : 'Create'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentHardLogoutData: currentHardLogoutData(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ hardLogoutCreate, updateHardLogout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateHardLogout);
