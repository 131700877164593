import { put, call, takeLatest, debounce } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  createSocianaInternalUserApi,
  editSocianaInternalUserApi,
  getActiveSocianaInternalUsersApi,
  getInactiveSocianaInternalUsersApi,
  getInternalUserParticipatedOccasions,
  searchInternalUserParticipatedOccasions,
  getCurrentOccasionInternalTeamApi,
  assignInternalTeamMemberToOccasionApi,
  searchInternalTeamUserApi,
  editInternalUserAccessSpecificationsApi,
  editInternalUserOccasionAccessSpecificationsApi,
  getInvitationWithStatusApi,
  getInternalTeamBySearchApi,
  deleteInternalUserParticipatedOccasions,
  updateAssignInternalTeamMemberToOccasionApi
} from '../api/socianaInternalUser';
import {
  CREATE_SOCIANA_INTERNAL_USER,
  EDIT_SOCIANA_INTERNAL_USER,
  GET_INTERNAL_USERS_PARTICIPATED_OCCASIONS,
  GET_SOCIANA_INTERNAL_USER_PAGINATION,
  SEARCH_OCCASIONS,
  GET_CURRENT_OCCASION_INTERNAL_MEMBERS,
  ASSIGN_INTERNAL_TEAM_MEMBER,
  SEARCH_INTERNAL_TEAM_USERS,
  EDIT_INTERNAL_USER_ACCESS_SPECIFICATIONS,
  GET_INTERNAL_TEAM_PENDING_INVITATION,
  GET_INTERNAL_TEAM_BY_SEARCH,
  DELETE_INTERNAL_USERS_PARTICIPATED_OCCASIONS,
  UPDATE_ASSIGN_INTERNAL_TEAM_MEMBER
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import { entityDeleteRequestSuccess } from '../actions/entity';
import {
  createSocianaInternalUserFailed,
  createSocianaInternalUserSuccess,
  getSocianaInternalUserWithPaginationFailed,
  getSocianaInternalUserWithPaginationSuccess,
  editSocianaInternalUserSuccess,
  editSocianaInternalUserFailed,
  getInternalUsersParticipatedOccasionsSuccess,
  getInternalUsersParticipatedOccasionsFailed,
  searchInternalUsersParticipatedOccasionsSuccess,
  searchInternalUsersParticipatedOccasionsFailed,
  getOccasionInternalTeamMemberFailed,
  getOccasionInternalTeamMemberSuccess,
  assignInternalTeamMemberFailed,
  assignInternalTeamMemberSuccess,
  searchInternalTeamMemberFailed,
  searchInternalTeamMemberSuccess,
  editInternalUserAccessSpecificationsSuccess,
  editInternalUserAccessSpecificationsFailed,
  getInternalTeamPendingInvitationSuccess,
  getInternalTeamPendingInvitationFailed,
  getInternalTeamBySearchSuccess,
  getInternalTeamBySearchFailed,
  deleteInternalUsersParticipatedOccasionsSuccess,
  deleteInternalUsersParticipatedOccasionsFailed,
  updateAssignInternalTeamMemberSuccess,
  updateAssignInternalTeamMemberFailed
} from '../actions/socianaInternalUser';

export function* createSocianaInternalUserSaga(action) {
  try {
    const result = yield call(
      createSocianaInternalUserApi,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        createSocianaInternalUserSuccess(result.data, result?.included)
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(createSocianaInternalUserFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Some error occured', 'Error', 4000));
    }
  } catch (error) {
    yield put(createSocianaInternalUserFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getSocianaInternalUsersWithPaginationSaga(action) {
  try {
    const { isActive } = action.payload;
    const result = yield call(
      isActive
        ? getActiveSocianaInternalUsersApi
        : getInactiveSocianaInternalUsersApi,
      action.payload.page,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getSocianaInternalUserWithPaginationSuccess(
          result.data,
          result?.included,
          result?.links,
          isActive,
          result?.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(
      getSocianaInternalUserWithPaginationFailed(error, action.payload.isActive)
    );

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* editSocianaInternalUserSaga(action) {
  try {
    const result = yield call(
      editSocianaInternalUserApi,
      action.payload.formData,
      action.payload.userId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(editSocianaInternalUserSuccess(result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(editSocianaInternalUserFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Some error occured', 'Error', 4000));
    }
  } catch (error) {
    yield put(editSocianaInternalUserFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getInternalUsersParticipatedOccasionsSaga(action) {
  try {
    const result = yield call(
      getInternalUserParticipatedOccasions,
      action.payload.userId,
      action.payload.page,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getInternalUsersParticipatedOccasionsSuccess(
          result.data,
          result.included,
          result.links,
          action.payload.userId,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    } else if (result?.meta && result?.meta?.message) {
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(getInternalUsersParticipatedOccasionsFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* searchInternalUsersParticipatedOccasionsSaga(action) {
  try {
    const result = yield call(
      searchInternalUserParticipatedOccasions,
      action.payload.query,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        searchInternalUsersParticipatedOccasionsSuccess(
          result.data,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    } else if (result?.meta && result?.meta?.message) {
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(searchInternalUsersParticipatedOccasionsFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* getOccasionInternalUsersWithPaginationSaga(action) {
  try {
    const result = yield call(
      getCurrentOccasionInternalTeamApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getOccasionInternalTeamMemberSuccess(
          action.payload.ocId,
          result.data,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    } else if (result?.meta && result?.meta?.message) {
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(getOccasionInternalTeamMemberFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* assignInternalTeamMemberSaga(action) {
  try {
    const result = yield call(
      assignInternalTeamMemberToOccasionApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        assignInternalTeamMemberSuccess(
          result.data,
          result.included,
          action.payload.ocId
        )
      );
      if (action.callback) {
        action.callback();
      }

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
    } else {
      yield put(assignInternalTeamMemberFailed(result));

      if (result && result.meta && result.meta.message) {
        yield put(requestAutoHideAlert(result.meta.message, 'Error'));
      } else {
        yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
      }
    }
  } catch (error) {
    yield put(assignInternalTeamMemberFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* editInternalUserAccessSpecificationsSaga(action) {
  try {
    let result;

    if (action.payload.updateOccasion) {
      result = yield call(
        editInternalUserOccasionAccessSpecificationsApi,
        action.payload.ocId,
        action.payload.userId,
        action.payload.formData,
        getUserToken()
      );
    } else {
      result = yield call(
        editInternalUserAccessSpecificationsApi,
        action.payload.ocId,
        action.payload.userId,
        action.payload.formData,
        getUserToken()
      );
    }
    if (isValidResult(result)) {
      yield put(
        editInternalUserAccessSpecificationsSuccess(
          result.data,
          result?.included
        )
      );

      if (!action.payload.updateOccasion) {
        yield put(
          entityDeleteRequestSuccess({
            id: action.payload.entityId,
            type: action.payload.entityType
          })
        );
      }
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(editInternalUserAccessSpecificationsFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* searchInternalMemberSaga(action) {
  try {
    const result = yield call(
      searchInternalTeamUserApi,
      action.payload.query,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(searchInternalTeamMemberSuccess(result.data, result?.included));
      if (action.callback) {
        action.callback();
      }
    } else if (result?.meta && result?.meta?.message) {
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(searchInternalTeamMemberFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* getInternalTeamPendingInvitationSaga(action) {
  try {
    const result = yield call(
      getInvitationWithStatusApi,
      action.payload.userId,
      action.payload.page,
      action.payload.status,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getInternalTeamPendingInvitationSuccess(
          result.data,
          result.included,
          result.links,
          action.payload.userId,
          action.payload.status
        )
      );
      if (action.callback) {
        action.callback();
      }
    } else if (result?.meta && result?.meta?.message) {
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(getInternalTeamPendingInvitationFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* getInternalTeamBySearchSaga(action) {
  try {
    const result = yield call(
      getInternalTeamBySearchApi,
      action.payload.searchData,
      action.payload.fromInternalTeam,
      action.payload.status,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getInternalTeamBySearchSuccess(
          result.data,
          result.included,
          action.payload.status,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getInternalTeamBySearchFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* deleteInternalUsersParticipatedOccasionsSaga(action) {
  try {
    const result = yield call(
      deleteInternalUserParticipatedOccasions,
      action.payload.ocId,
      action.payload.userId,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        deleteInternalUsersParticipatedOccasionsSuccess(
          result.data,
          result.included,
          result.links,
          action.payload.userId
        )
      );
      if (action.callback) {
        action.callback();
      }
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
    } else if (result?.meta && result?.meta?.message) {
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(deleteInternalUsersParticipatedOccasionsFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* updateAssignInternalTeamMemberSaga(action) {
  try {
    const result = yield call(
      updateAssignInternalTeamMemberToOccasionApi,
      action.payload.ocId,
      action.payload.userId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateAssignInternalTeamMemberSuccess(
          action.payload.userId,
          result.data,
          result.included
        )
      );
      yield put(
        entityDeleteRequestSuccess({
          type: `${String(action.payload.type).toLowerCase()}s`,
          id: result.data.id
        })
      );

      if (action.callback) {
        action.callback();
      }

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
    } else {
      yield put(updateAssignInternalTeamMemberFailed(result));

      if (result && result.meta && result.meta.message) {
        yield put(requestAutoHideAlert(result.meta.message, 'Error'));
      } else {
        yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
      }
    }
  } catch (error) {
    yield put(updateAssignInternalTeamMemberFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export default function* socianaInternalUserActionWatcher() {
  yield takeLatest(
    CREATE_SOCIANA_INTERNAL_USER,
    progressSaga,
    createSocianaInternalUserSaga
  );
  yield takeLatest(
    EDIT_SOCIANA_INTERNAL_USER,
    progressSaga,
    editSocianaInternalUserSaga
  );
  yield takeLatest(
    GET_SOCIANA_INTERNAL_USER_PAGINATION,
    progressSaga,
    getSocianaInternalUsersWithPaginationSaga
  );
  yield takeLatest(
    GET_INTERNAL_USERS_PARTICIPATED_OCCASIONS,
    progressSaga,
    getInternalUsersParticipatedOccasionsSaga
  );
  yield debounce(
    1000,
    SEARCH_OCCASIONS,
    progressSaga,
    searchInternalUsersParticipatedOccasionsSaga
  );
  yield takeLatest(
    GET_CURRENT_OCCASION_INTERNAL_MEMBERS,
    progressSaga,
    getOccasionInternalUsersWithPaginationSaga
  );
  yield takeLatest(
    ASSIGN_INTERNAL_TEAM_MEMBER,
    progressSaga,
    assignInternalTeamMemberSaga
  );
  yield takeLatest(
    EDIT_INTERNAL_USER_ACCESS_SPECIFICATIONS,
    progressSaga,
    editInternalUserAccessSpecificationsSaga
  );
  yield debounce(
    1000,
    SEARCH_INTERNAL_TEAM_USERS,
    progressSaga,
    searchInternalMemberSaga
  );
  yield takeLatest(
    GET_INTERNAL_TEAM_PENDING_INVITATION,
    progressSaga,
    getInternalTeamPendingInvitationSaga
  );
  yield takeLatest(
    GET_INTERNAL_TEAM_BY_SEARCH,
    progressSaga,
    getInternalTeamBySearchSaga
  );
  yield takeLatest(
    DELETE_INTERNAL_USERS_PARTICIPATED_OCCASIONS,
    progressSaga,
    deleteInternalUsersParticipatedOccasionsSaga
  );
  yield takeLatest(
    UPDATE_ASSIGN_INTERNAL_TEAM_MEMBER,
    progressSaga,
    updateAssignInternalTeamMemberSaga
  );
}
