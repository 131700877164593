import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import TaskCreate from './CreateTask';
import { Avatar } from '../../../../../../components/Avatar';
import FloorPlanImageButton from '../../../../../../components/FloorPlanImageButton';
import ImageModal from '../../../../../../components/ImageModal';
import IconModal from '../../../../../../components/IconModal';
import { TASK_ICONS } from './HospitalityTaskConstants';
import { getCurrentHospitality } from '../../../../../../selectors/hospitality';

const HospitalityTaskDetailsView = ({
  hospitalityId,
  hospitalityTaskId,
  hospitalityTaskDetails,
  currentHospitalityData,
  occasionId
}) => {
  const MAX_GROUP_COUNT = 2;
  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (!isEditOpen) {
    return (
      <div className="hospitality-task-details-container">
        <p className="hospitality-task-details card-details">
          <p className="fields">Avatar:</p>
          <p className="field-data card-image-container">
            <img
              className="hospitality-task-avatar"
              src={TASK_ICONS[hospitalityTaskDetails?.avatarName]}
            />
          </p>
        </p>
        <p className="hospitality-task-details">
          <p className="fields">Alias Name:</p>
          {hospitalityTaskDetails?.aliasName ? (
            <p className="field-data">{hospitalityTaskDetails?.aliasName}</p>
          ) : (
            'NA'
          )}
        </p>
        <p className="hospitality-task-details">
          <p className="fields">Name:</p>
          <p className="field-data">{hospitalityTaskDetails?.name}</p>
        </p>
        <p className="hospitality-task-details">
          <p className="fields">Description:</p>
          {hospitalityTaskDetails?.description ? (
            <p className="field-data">{hospitalityTaskDetails?.description}</p>
          ) : (
            'NA'
          )}
        </p>
        <p className="hospitality-task-details">
          <p className="fields">Task Type:</p>
          <p className="field-data">{hospitalityTaskDetails?.taskType}</p>
        </p>
        <p className="hospitality-task-details">
          <p className="fields">Task Data Type:</p>
          <p className="field-data">{hospitalityTaskDetails?.dataInputType}</p>
        </p>
        <p className="hospitality-task-details">
          <p className="fields">Task Order:</p>
          <p className="field-data">{hospitalityTaskDetails?.orderNumber}</p>
        </p>
        <p className="hospitality-task-details">
          <p className="fields">Is Public Answer:</p>
          <p className="field-data">
            {hospitalityTaskDetails?.isPublic?.toString()}
          </p>
        </p>
        <p className="hospitality-task-details">
          <p className="fields">Task Options:</p>
          {hospitalityTaskDetails?.hospitalityTaskOptions.length > 0 ? (
            <p className="field-data">
              {hospitalityTaskDetails?.hospitalityTaskOptions
                .map((item) => item.option)
                .join(', ')}
            </p>
          ) : (
            'NA'
          )}
        </p>
        <p className="hospitality-task-details">
          <p className="fields">Task Groups:</p>
          {hospitalityTaskDetails?.groups.length > 0 ? (
            <p className="field-data">
              {hospitalityTaskDetails?.groups
                .slice(0, MAX_GROUP_COUNT)
                .map((item) => (
                  <Avatar
                    avatarUrl={item?.groupPictureUrl}
                    name={item?.name}
                    className="task-group-content-image"
                  />
                ))}
              {hospitalityTaskDetails?.groups?.length > MAX_GROUP_COUNT ? (
                <FloorPlanImageButton
                  data={hospitalityTaskDetails?.groups}
                  index={1}
                  onClick={() => showModal()}
                />
              ) : (
                ''
              )}
            </p>
          ) : (
            'NA'
          )}
        </p>
        {isModalVisible ? (
          <IconModal
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            floorPlanImageUrls={hospitalityTaskDetails?.groups}
          />
        ) : (
          ''
        )}

        <button
          className="edit-details-container"
          onClick={() => setIsEditOpen(true)}>
          <EditOutlined />
          <span>Edit Details</span>
        </button>
      </div>
    );
  }
  return (
    <div className="edit-container">
      <TaskCreate
        hospitalityTaskDetails={hospitalityTaskDetails}
        hospitalityId={hospitalityId}
        hospitalityDetails={currentHospitalityData}
        hospitalityTaskId={hospitalityTaskId}
        occasionId={occasionId}
        handleCloseClick={() => setIsEditOpen(false)}
      />
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentHospitalityData: getCurrentHospitality(store, props)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HospitalityTaskDetailsView);
