import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import {
  occasionResourcesCreate,
  updateOccasionResources
} from '../../../../../actions/occasionResources';

import { getImageSource } from '../../../../../helpers/imageHelper';
import { currentOccasionResourcesData } from '../../../../../selectors/occasionResources';
import {
  OCCASION_RESOURCES_STATUS,
  OCCASION_RESOURCES_TYPES
} from './constants';

const validateOcResources = (values) => {
  const error = {};
  if (!values.name) {
    error.name = 'Name is required';
  }
  if (!values.resourceType) {
    error.resourceType = 'Resource Type  is required';
  }
  if (
    !!values.link &&
    !/^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i.test(
      values.link
    )
  ) {
    error.link = 'Enter a valid Link';
  }

  return error;
};

function CreateOccasionResources(props) {
  const [ocResourcesData, setOcResourcesData] = React.useState({
    name: props.currentOccasionResourcesData?.name || '',
    status: props.occasionResourcesId
      ? props.currentOccasionResourcesData?.active == true
        ? true
        : false
      : true,
    description: props.currentOccasionResourcesData?.description || '',
    category: props.currentOccasionResourcesData?.category || '',
    priority: props.currentOccasionResourcesData?.priority || 1,
    resourceType: props.currentOccasionResourcesData?.resourceType || '',
    link: props.currentOccasionResourcesData?.link || '',
    resourceFile: props.currentOccasionResourcesData?.resourceFileUrl
      ? [
          {
            url: getImageSource(
              props.currentOccasionResourcesData?.resourceFileUrl
            )
          }
        ]
      : [],
    resourceFiles:
      props.currentOccasionResourcesData?.resourceFilesUrls?.length > 0
        ? props.currentOccasionResourcesData?.resourceFilesUrls.map((item) => ({
            id: Object.keys(item)[0],
            url:
              Object.values(item).length > 0 &&
              getImageSource(Object.values(item)[0])
          }))
        : []
  });
  const [errorMessages, setErrorMessages] = React.useState({});

  const [dynamicMetaData, setDunamicMetaData] = React.useState(
    props.currentOccasionResourcesData?.metaData || [{ key: null, value: null }]
  );

  const [multipalLinks, setMultipalLinks] = React.useState(
    props.currentOccasionResourcesData?.multipleLinks || [
      { key: null, value: null }
    ]
  );

  const [dynamicBody, setDynamicBody] = React.useState(
    props.currentOccasionResourcesData?.body || [{ value: null }]
  );

  const handleSubmit = () => {
    try {
      const validateObj = validateOcResources(ocResourcesData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();

        formData.append('occasion_resource[name]', ocResourcesData.name);
        formData.append(
          'occasion_resource[description]',
          ocResourcesData.description
        );
        formData.append(
          'occasion_resource[category]',
          ocResourcesData.category
        );
        formData.append(
          'occasion_resource[priority]',
          ocResourcesData.priority
        );
        formData.append('occasion_resource[link]', ocResourcesData.link);
        formData.append(
          'occasion_resource[resource_type]',
          ocResourcesData.resourceType
        );
        formData.append('occasion_resource[active]', ocResourcesData.status);

        if (ocResourcesData?.resourceFiles?.length > 0) {
          ocResourcesData?.resourceFiles.forEach((image) => {
            if (image?.originFileObj)
              formData.append(
                'occasion_resource[resource_files][]',
                image?.originFileObj
              );
          });
        }

        if (
          ocResourcesData?.resourceFile?.length > 0 &&
          ocResourcesData?.resourceFile[0]?.originFileObj
        ) {
          formData.append(
            'occasion_resource[resource_file]',
            ocResourcesData?.resourceFile[0].originFileObj
          );
        }
        dynamicBody.forEach((body) => {
          if (body.value) {
            formData.append('occasion_resource[body][]', body.value);
          }
        });
        dynamicMetaData.forEach((item) => {
          if (item.key && item.value) {
            formData.append(
              `occasion_resource[meta_data][${item.key}]`,
              item.value
            );
          }
        });
        multipalLinks.forEach((item) => {
          if (
            item.key &&
            item.value &&
            !!/^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i.test(
              item.value
            )
          ) {
            formData.append(
              `occasion_resource[multiple_links][${item.key}]`,
              item.value
            );
          }
        });

        props.occasionResourcesId
          ? props.updateOccasionResources(
              props.occasionId,
              props.occasionResourcesId,
              formData
            )
          : props.occasionResourcesCreate(props.occasionId, formData, () => {
              setOcResourcesData({
                name: '',
                status: true,
                description: '',
                category: '',
                priority: 1,
                resourceType: '',
                link: '',
                resourceFile: [],
                resourceFiles: []
              });
            });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'resourceFile') {
        value = e.fileList;
        name = type;
      }

      if (type === 'resourceFiles') {
        value = e.fileList;
        name = type;
      }

      const Value = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setOcResourcesData({
        ...ocResourcesData,
        [inputName]: Value
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleBodyFieldChange = (index, event) => {
    const { name, value } = event.target;
    const updatedData = [...dynamicBody];
    updatedData[index][name] = value;
    setDynamicBody(updatedData);
  };

  const handleAddBodyField = () => {
    setDynamicBody([...dynamicBody, { value: null }]);
  };

  const handleRemoveBodyField = (index) => {
    const updatedData = [...dynamicBody];
    updatedData.splice(index, 1);
    setDynamicBody(updatedData);
  };

  const handleMultipalLinksFieldChange = (index, event) => {
    const { name, value } = event.target;
    const updatedData = [...multipalLinks];
    updatedData[index][name] = value;
    setMultipalLinks(updatedData);
  };

  const handleAddMultipalLinks = () => {
    setMultipalLinks([...multipalLinks, { key: null, value: null }]);
  };

  const handleRemoveMultipalLinks = (index) => {
    const updatedData = [...multipalLinks];
    updatedData.splice(index, 1);
    setMultipalLinks(updatedData);
  };

  const handleMetaDataFieldChange = (index, event) => {
    const { name, value } = event.target;
    const updatedData = [...dynamicMetaData];
    updatedData[index][name] = value;
    setDunamicMetaData(updatedData);
  };

  const handleAddMetaData = () => {
    setDunamicMetaData([...dynamicMetaData, { key: null, value: null }]);
  };

  const handleRemoveMetaData = (index) => {
    const updatedData = [...dynamicMetaData];
    updatedData.splice(index, 1);
    setDunamicMetaData(updatedData);
  };

  return (
    <ErrorBoundary>
      <SectionContent className="occasion-resources-create-section">
        <div className="occasion-resources-create-form">
          <Form className="occasion-resources-form" onFinish={handleSubmit}>
            <InputGroup
              label="Name"
              name="name"
              placeholder="Enter Name"
              onChange={handleInputChange}
              value={ocResourcesData?.name}
              errorMessage={errorMessages.name}
            />
            <InputGroup
              label="Category"
              name="category"
              placeholder="Enter Category"
              onChange={handleInputChange}
              value={ocResourcesData?.category}
            />

            <InputGroup
              label="Priority"
              name="priority"
              type="number"
              placeholder="Enter Priority"
              onChange={handleInputChange}
              value={ocResourcesData?.priority}
              errorMessage={errorMessages.priority}
            />

            <InputGroup
              label="Description"
              name="description"
              fieldType="textArea"
              placeholder="Enter Description"
              onChange={handleInputChange}
              value={ocResourcesData?.description}
            />

            <InputGroup
              fieldType="radioGroup"
              options={OCCASION_RESOURCES_STATUS}
              name="status"
              label="Status"
              onChange={handleInputChange}
              value={ocResourcesData?.status}
            />

            <InputGroup
              label="Resource Type"
              fieldType="dropdown"
              options={OCCASION_RESOURCES_TYPES}
              name="resourceType"
              onChange={handleInputChange}
              value={ocResourcesData?.resourceType}
              errorMessage={errorMessages.resourceType}
              disabled={props.occasionResourcesId ? true : false}
            />

            {dynamicMetaData.length > 0 &&
              dynamicMetaData.map((field, index) => {
                return (
                  <div
                    key={index}
                    className="occasion-resources-added-option-container">
                    {index != 0 && (
                      <button
                        className="occasion-resources-option-input-remove-button"
                        onClick={() => handleRemoveMetaData(index)}
                        type="button">
                        Remove
                      </button>
                    )}
                    <div className="occasion-resources-radio-factor-box">
                      <InputGroup
                        name="key"
                        className="option-field"
                        label={`Key ${index + 1}`}
                        onChange={(event) =>
                          handleMetaDataFieldChange(index, event)
                        }
                        value={field.key}
                      />
                      <InputGroup
                        name="value"
                        label={`Value ${index + 1}`}
                        className="factor-field"
                        value={field.value}
                        onChange={(event) =>
                          handleMetaDataFieldChange(index, event)
                        }
                      />
                    </div>
                  </div>
                );
              })}

            <div className="occasion-resources-add-option-button-box">
              <button
                className="occasion-resources-add-option-button"
                onClick={handleAddMetaData}
                type="button">
                Add Meta Data
              </button>
            </div>

            {(ocResourcesData.resourceType === 'general' ||
              ocResourcesData.resourceType === 'link') && (
              <InputGroup
                label="Link"
                name="link"
                placeholder="Enter Link"
                onChange={handleInputChange}
                value={ocResourcesData?.link}
                errorMessage={errorMessages.link}
              />
            )}

            {(ocResourcesData.resourceType === 'general' ||
              ocResourcesData.resourceType === 'multiple_links') &&
              multipalLinks.length > 0 &&
              multipalLinks.map((field, index) => {
                return (
                  <div
                    key={index}
                    className="occasion-resources-added-option-container">
                    {index != 0 && (
                      <button
                        className="occasion-resources-option-input-remove-button"
                        onClick={() => handleRemoveMultipalLinks(index)}
                        type="button">
                        Remove
                      </button>
                    )}
                    <div className="occasion-resources-radio-factor-box">
                      <InputGroup
                        name="key"
                        className="option-field"
                        label={`Name ${index + 1}`}
                        onChange={(event) =>
                          handleMultipalLinksFieldChange(index, event)
                        }
                        value={field.key}
                      />
                      <InputGroup
                        name="value"
                        label={`Link ${index + 1}`}
                        className="factor-field"
                        value={field.value}
                        onChange={(event) =>
                          handleMultipalLinksFieldChange(index, event)
                        }
                      />
                    </div>
                  </div>
                );
              })}

            {(ocResourcesData.resourceType === 'general' ||
              ocResourcesData.resourceType === 'multiple_links') && (
              <div className="occasion-resources-add-option-button-box">
                <button
                  className="occasion-resources-add-option-button"
                  onClick={handleAddMultipalLinks}
                  type="button">
                  Add Multipal Links
                </button>
              </div>
            )}

            {(ocResourcesData.resourceType === 'general' ||
              ocResourcesData.resourceType === 'multiline_body') &&
              dynamicBody.length > 0 &&
              dynamicBody.map((field, index) => {
                return (
                  <div
                    key={index}
                    className="occasion-resources-added-option-container">
                    {index != 0 && (
                      <button
                        className="occasion-resources-option-input-remove-button"
                        onClick={() => handleRemoveBodyField(index)}
                        type="button">
                        Remove
                      </button>
                    )}

                    <InputGroup
                      name="value"
                      fieldType="textArea"
                      label={`Body ${index + 1}`}
                      className="factor-field"
                      value={field.value}
                      onChange={(event) => handleBodyFieldChange(index, event)}
                    />
                  </div>
                );
              })}

            {(ocResourcesData.resourceType === 'general' ||
              ocResourcesData.resourceType === 'multiline_body') && (
              <div className="occasion-resources-add-option-button-box">
                <button
                  className="occasion-resources-add-option-button"
                  onClick={handleAddBodyField}
                  type="button">
                  Add Multipal Body
                </button>
              </div>
            )}

            {ocResourcesData.resourceType === 'image' ||
            ocResourcesData.resourceType === 'video' ||
            ocResourcesData.resourceType === 'file' ||
            ocResourcesData.resourceType === 'general' ? (
              <InputGroup
                fieldType="file"
                label={
                  ocResourcesData.resourceType === 'image'
                    ? 'Resource Image'
                    : ocResourcesData.resourceType === 'video'
                    ? 'Resource Video'
                    : ocResourcesData.resourceType === 'file' ||
                      ocResourcesData.resourceType === 'general'
                    ? 'Resource File'
                    : ''
                }
                accept={
                  ocResourcesData.resourceType === 'image'
                    ? 'image/*'
                    : ocResourcesData.resourceType === 'video'
                    ? 'video/*'
                    : ''
                }
                name="resourceFile"
                onChange={handleInputChange}
                fileList={ocResourcesData?.resourceFile}
              />
            ) : (
              ''
            )}

            {ocResourcesData.resourceType === 'multiple_images' ||
            ocResourcesData.resourceType === 'multiple_videos' ||
            ocResourcesData.resourceType === 'multiple_files' ||
            ocResourcesData.resourceType === 'general' ? (
              <InputGroup
                fieldType="file"
                label={
                  ocResourcesData.resourceType === 'multiple_images'
                    ? 'Resource Multiple Images'
                    : ocResourcesData.resourceType === 'multiple_videos'
                    ? 'Resource Multiple Videos'
                    : ocResourcesData.resourceType === 'multiple_files' ||
                      ocResourcesData.resourceType === 'general'
                    ? 'Resource Multiple Files'
                    : ''
                }
                multiple={true}
                accept={
                  ocResourcesData.resourceType === 'multiple_images'
                    ? 'image/*'
                    : ocResourcesData.resourceType === 'multiple_videos'
                    ? 'video/*'
                    : ''
                }
                name="resourceFiles"
                onChange={handleInputChange}
                fileList={ocResourcesData?.resourceFiles}
                noMaxCount={true}
              />
            ) : (
              ''
            )}

            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.occasionResourcesId ? 'Save Changes' : 'Create'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentOccasionResourcesData: currentOccasionResourcesData(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { occasionResourcesCreate, updateOccasionResources },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOccasionResources);
