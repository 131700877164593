import request from './request';

export function faceInfoViewApi(ocId, type, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/image_media_face_information/get_face_info_by_modelable_type?modelable_type=${type}&page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function getSelfieUserMediaApi(ocId, userId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/image_media_face_information/get_selfie_user_media?user_id=${userId}&page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function regenerateFaceInfoApi(ocId, type, typeId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/image_media_face_information/regenerate_face_info?modelable_type=${type}&modelable_id=${typeId}`,
    {
      method: 'PUT',
      token
    }
  );
}

export function createSelfieUserMediaApi(ocId, userId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/image_media_face_information/create_selfie_user_media?user_id=${userId}`,
    {
      method: 'post',
      token
    }
  );
}
export function updateSelfieImageApi(ocId, userId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/update_selfie_image/${userId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}
export function regenerateAllGallaryMediaFaceInfoApi(ocId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/image_media_face_information/regenerate_all_gallary_media_face_info`,
    {
      method: 'PUT',
      token
    }
  );
}
