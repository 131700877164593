import {
  CREATE_POST,
  CREATE_POST_SUCCESS,
  CREATE_POST_FAILED,
  GET_POST_PAGINATION,
  GET_POST_PAGINATION_SUCCESS,
  GET_POST_PAGINATION_FAILED,
  EDIT_POST,
  EDIT_POST_SUCCESS,
  EDIT_POST_FAILED,
  DELETE_POST,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAILED,
  DOWNLOAD_EXSL_EXPORT_USER_POST,
  DOWNLOAD_EXSL_EXPORT_USER_POST_FAILED,
  DOWNLOAD_EXSL_EXPORT_USER_POST_SUCCESS
} from '../constants/actionTypes';

export const createPost = (occasionId, postableType, formData, callback) => ({
  type: CREATE_POST,
  payload: { occasionId, postableType, formData },
  callback
});

export const createPostSuccess = (
  occasionId,
  postableType,
  data,
  included
) => ({
  type: CREATE_POST_SUCCESS,
  payload: { occasionId, postableType, data, included }
});

export const createPostFailed = (error) => ({
  type: CREATE_POST_FAILED,
  error
});

export const getPostWithPagination = (
  occasionId,
  postableType,
  pageNumber,
  callback
) => ({
  type: GET_POST_PAGINATION,
  payload: { occasionId, postableType, pageNumber },
  callback
});

export const getPostWithPaginationSuccess = (
  occasionId,
  postableType,
  data,
  links,
  included
) => ({
  type: GET_POST_PAGINATION_SUCCESS,
  payload: { occasionId, postableType, data, links, included }
});

export const getPostWithPaginationFailed = (error) => ({
  type: GET_POST_PAGINATION_FAILED,
  error
});

export const editPost = (
  occasionId,
  postableType,
  postId,
  formData,
  callback
) => ({
  type: EDIT_POST,
  payload: { occasionId, postableType, postId, formData },
  callback
});

export const editPostSuccess = (occasionId, data, included) => ({
  type: EDIT_POST_SUCCESS,
  payload: { occasionId, data, included }
});

export const editPostFailed = (error) => ({
  type: EDIT_POST_FAILED,
  error
});

export const deletePost = (occasionId, postableType, postId, callback) => ({
  type: DELETE_POST,
  payload: { occasionId, postableType, postId },
  callback
});

export const deletePostSuccess = (
  occasionId,
  postableType,
  data,
  included
) => ({
  type: DELETE_POST_SUCCESS,
  payload: { occasionId, postableType, data, included }
});

export const deletePostFailed = (error) => ({
  type: DELETE_POST_FAILED,
  error
});

export const downloadExslExportUserPost = (ocId, callback) => {
  return {
    type: DOWNLOAD_EXSL_EXPORT_USER_POST,
    payload: { ocId },
    callback
  };
};

export const downloadExslExportUserPostSuccess = (data, included) => {
  return {
    type: DOWNLOAD_EXSL_EXPORT_USER_POST_SUCCESS,
    payload: { data, included }
  };
};

export const downloadExslExportUserPostFailed = (error) => {
  return {
    type: DOWNLOAD_EXSL_EXPORT_USER_POST_FAILED,
    error
  };
};
