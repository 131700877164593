import { union } from 'lodash';
import {
  OCCASION_ALARM_VIEW_SUCCESS,
  OCCASION_ALARM_VIEW_FAILED,
  OCCASION_ALARM_CREATE_SUCCESS,
  OCCASION_ALARM_CREATE_FAILED,
  DELETE_ALARM_SUCCESS,
  DELETE_ALARM_FAILED
} from '../constants/actionTypes';

const initialState = {
  occasionAlarmData: null,
  alarmLinks: null,
  error: null
};

const alarm = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_ALARM_VIEW_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.occasionAlarmData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionAlarmData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        alarmLinks: links
      };
    }

    case OCCASION_ALARM_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionAlarmData || {};
      const currentOccasionAlarmIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionAlarmIds];
      return {
        ...state,
        occasionAlarmData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }

    case DELETE_ALARM_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionAlarmData || {};
      const currentOccasionAlarmIds = occasionGroupedData[ocId] || [];
      const filteredIds = currentOccasionAlarmIds.filter(
        (id) => id !== data.id
      );

      return {
        ...state,
        occasionAlarmData: {
          ...occasionGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case OCCASION_ALARM_VIEW_FAILED:
    case OCCASION_ALARM_CREATE_FAILED:
    case DELETE_ALARM_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default alarm;
