import { put, call, takeLatest, takeEvery, all } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  addMediaToAlbum,
  deleteAlbumApi,
  deleteAlbumMediaApi,
  getAlbumImagesApi,
  getAlbumVideosApi,
  occasionAlbumCreateApi,
  occasionAlbumViewApi,
  updateAlbumApi
} from '../api/album';
import {
  CREATE_ALBUM_MEDIA,
  DELETE_ALBUM,
  DELETE_ALBUM_MEDIA,
  GET_ALBUM_IMAGES,
  GET_ALBUM_VIDEOS,
  OCCASION_ALBUM_CREATE,
  OCCASION_ALBUM_VIEW,
  UPDATE_ALBUM
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import {
  createAlbumImagesSuccess,
  createAlbumMediaFailed,
  createAlbumVideosSuccess,
  deleteAlbumFailed,
  deleteAlbumMediaFailed,
  deleteAlbumMediaSuccess,
  deleteAlbumSuccess,
  getAlbumImagesFailed,
  getAlbumImagesSuccess,
  getAlbumVideosFailed,
  getAlbumVideosSuccess,
  occasionAlbumCreateFailed,
  occasionAlbumCreateSuccess,
  occasionAlbumViewFailed,
  occasionAlbumViewSuccess,
  updateAlbumFailed,
  updateAlbumImageList,
  updateAlbumSuccess
} from '../actions/album';
import { getDataFromFile, getUserToken } from '../helpers/authHelper';
import { entityDeleteRequestSuccess } from '../actions/entity';

export function* occasionAlbumCreateSaga(action) {
  try {
    const result = yield call(
      occasionAlbumCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionAlbumCreateSuccess(
          result.data,
          result.included,
          action.payload.ocId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionAlbumCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionAlbumCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionAlbumViewSaga(action) {
  try {
    const result = yield call(
      occasionAlbumViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionAlbumViewSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionAlbumViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* addMediaToAlbumSaga(action) {
  try {
    const {
      occasionId,
      formData,
      albumId,
      updateProgress,
      mediaType
    } = action.payload;
    yield all(
      formData.map((el) =>
        put(
          updateAlbumImageList(
            occasionId,
            albumId,
            { ...getDataFromFile(el.get('media[file]')), completed: 'STARTED' },
            0
          )
        )
      )
    );
    for (const key in formData) {
      if (Object.hasOwnProperty.call(formData, key)) {
        const element = formData[key];
        try {
          const result = yield call(
            addMediaToAlbum,
            occasionId,
            element,
            updateProgress,
            getUserToken()
          );

          if (isValidResult(result)) {
            if (mediaType === 'images') {
              yield put(
                createAlbumImagesSuccess(
                  occasionId,
                  albumId,
                  result.data,
                  result.included
                )
              );
            } else {
              yield put(
                createAlbumVideosSuccess(
                  occasionId,
                  albumId,
                  result.data,
                  result.included
                )
              );
            }
            yield put(
              updateAlbumImageList(
                occasionId,
                albumId,
                {
                  ...getDataFromFile(element.get('media[file]')),
                  completed: 'FINISHED'
                },
                1
              )
            );
          } else {
            throw result;
          }
        } catch (error) {
          yield put(createAlbumMediaFailed(error));
          yield put(
            updateAlbumImageList(
              action.payload.occasionId,
              action.payload.albumId,
              {
                ...getDataFromFile(element.get('media[file]')),
                completed: 'ERRORED'
              },
              1
            )
          );
          if (error && error.meta) {
            yield put(requestAutoHideAlert(error.meta.message, 'Error'));
          } else {
            yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
          }
        }
      }
    }
    if (typeof action.callback === 'function') {
      action.callback();
    }
  } catch (error) {
    console.error('Error in adding videos in saga', error);
  }
}

export function* getAlbumImagesSaga(action) {
  try {
    const { occasionId, mType, albumId, page } = action.payload;
    const result = yield call(
      getAlbumImagesApi,
      occasionId,
      mType,
      albumId,
      page,
      getUserToken()
    );

    if (action && typeof action.callback === 'function') {
      action.callback();
    }

    if (isValidResult(result)) {
      yield put(
        getAlbumImagesSuccess(
          occasionId,
          albumId,
          result.data,
          result.included,
          result.links,
          page
        )
      );
    } else if (result && result.meta) {
      yield put(getAlbumImagesFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(getAlbumImagesFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* getAlbumVideosSaga(action) {
  try {
    const { occasionId, mType, albumId, page } = action.payload;
    const result = yield call(
      getAlbumVideosApi,
      occasionId,
      mType,
      albumId,
      page,
      getUserToken()
    );

    if (action && typeof action.callback === 'function') {
      action.callback();
    }

    if (isValidResult(result)) {
      yield put(
        getAlbumVideosSuccess(
          occasionId,
          albumId,
          result.data,
          result.included,
          result.links,
          page
        )
      );
    } else if (result && result.meta) {
      yield put(getAlbumVideosFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(getAlbumVideosFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* deleteAlbumMediaSaga(action) {
  try {
    const { occasionId, mediaId, mType, albumId, mediaType } = action.payload;
    const result = yield call(
      deleteAlbumMediaApi,
      occasionId,
      mediaId,
      mType,
      albumId,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        deleteAlbumMediaSuccess(occasionId, albumId, mediaId, mediaType)
      );

      yield put(
        entityDeleteRequestSuccess({
          type: result.data.type,
          id: mediaId
        })
      );

      if (typeof action?.callback === 'function') {
        action.callback();
      }

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta?.message, 'Success', 4000));
      }
    }
  } catch (error) {
    yield put(deleteAlbumMediaFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* albumDeleteSaga(action) {
  try {
    const result = yield call(
      deleteAlbumApi,
      action.payload.ocId,
      action.payload.albumId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(deleteAlbumSuccess(action.payload.ocId, result.data));
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteAlbumFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionAlbumUpdateSaga(action) {
  try {
    const result = yield call(
      updateAlbumApi,
      action.payload.ocId,
      action.payload.albumId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateAlbumSuccess(action.payload.ocId, result.data, result?.included)
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateAlbumFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateAlbumFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* albumActionWatcher() {
  yield takeLatest(
    OCCASION_ALBUM_CREATE,
    progressSaga,
    occasionAlbumCreateSaga
  );
  yield takeLatest(OCCASION_ALBUM_VIEW, progressSaga, occasionAlbumViewSaga);
  yield takeEvery(CREATE_ALBUM_MEDIA, progressSaga, addMediaToAlbumSaga);
  yield takeLatest(GET_ALBUM_IMAGES, progressSaga, getAlbumImagesSaga);
  yield takeLatest(GET_ALBUM_VIDEOS, progressSaga, getAlbumVideosSaga);
  yield takeEvery(DELETE_ALBUM_MEDIA, progressSaga, deleteAlbumMediaSaga);
  yield takeLatest(DELETE_ALBUM, progressSaga, albumDeleteSaga);
  yield takeLatest(UPDATE_ALBUM, progressSaga, occasionAlbumUpdateSaga);
}
