import { createSelector } from 'reselect';
import { guestEntities } from './entity';
import { guestIds } from './static';
import { currentUserId } from './dynamicData';

export const currentGuestIds = createSelector(
  currentUserId,
  guestIds,
  (userId, ids) => userId && ids && ids[userId]
);

export const getAllGuests = createSelector(
  guestEntities,
  currentGuestIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
