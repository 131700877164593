import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import {
  activityTrackerCreate,
  updateActivityTracker
} from '../../../../../actions/activityTracker';
import { currentActivityTrackerData } from '../../../../../selectors/activityTracker';

const validateActivityTracker = (values) => {
  const error = {};
  if (!values.name) {
    error.name = 'Name is required';
  }

  return error;
};

function CreateActivityTracker(props) {
  const [trackerData, setTrackerData] = React.useState({
    name: props.currentActivityTrackerData?.name || '',

    description: props.currentActivityTrackerData?.description || ''
  });
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleSubmit = () => {
    try {
      const validateObj = validateActivityTracker(trackerData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();

        formData.append('activity_tracker[name]', trackerData.name);

        formData.append(
          'activity_tracker[description]',
          trackerData.description
        );

        props.trackerId
          ? props.updateActivityTracker(
              props.occasionId,
              props.trackerId,
              formData
            )
          : props.activityTrackerCreate(props.occasionId, formData, () => {
              setTrackerData({});
            });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;

      const activityTrackerValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setTrackerData({
        ...trackerData,
        [inputName]: activityTrackerValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="activity-tracker-create-section">
        <div className="activity-tracker-create-form">
          <Form className="activity-tracker-form" onFinish={handleSubmit}>
            <InputGroup
              label="Name"
              name="name"
              placeholder="Enter Name"
              onChange={handleInputChange}
              value={trackerData?.name}
              errorMessage={errorMessages.name}
            />

            <InputGroup
              label="Description"
              name="description"
              placeholder="Enter Description"
              onChange={handleInputChange}
              value={trackerData?.description}
            />
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.trackerId ? 'Save Changes' : 'Create'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentActivityTrackerData: currentActivityTrackerData(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { activityTrackerCreate, updateActivityTracker },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateActivityTracker);
