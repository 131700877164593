import {
  CREATE_TICKET_PURCHASE,
  CREATE_TICKET_PURCHASE_SUCCESS,
  CREATE_TICKET_PURCHASE_FAILED,
  GET_REGISTERED_TICKET_PURCHASES,
  GET_REGISTERED_TICKET_PURCHASES_SUCCESS,
  GET_REGISTERED_TICKET_PURCHASES_FAILED,
  GET_UNREGISTERED_TICKET_PURCHASES,
  GET_UNREGISTERED_TICKET_PURCHASES_SUCCESS,
  GET_UNREGISTERED_TICKET_PURCHASES_FAILED,
  REGISTERED_TICKET_PURCHASES_SEARCH,
  REGISTERED_TICKET_PURCHASES_SEARCH_SUCCESS,
  REGISTERED_TICKET_PURCHASES_SEARCH_FAILED,
  UNREGISTERED_TICKET_PURCHASES_SEARCH,
  UNREGISTERED_TICKET_PURCHASES_SEARCH_SUCCESS,
  UNREGISTERED_TICKET_PURCHASES_SEARCH_FAILED
} from '../constants/actionTypes';

export const createTicketPurchase = (
  ocId,
  ticketId,
  formData,
  isCSV,
  callback
) => ({
  type: CREATE_TICKET_PURCHASE,
  payload: { ocId, ticketId, formData, isCSV },
  callback
});

export const createTicketPurchaseSuccess = (
  ocId,
  ticketId,
  data,
  included
) => ({
  type: CREATE_TICKET_PURCHASE_SUCCESS,
  payload: { ocId, ticketId, data, included }
});

export const createTicketPurchaseFailed = (error) => ({
  type: CREATE_TICKET_PURCHASE_FAILED,
  error
});

export const getRegisteredTicketPurchases = (ocId, ticketId, page) => ({
  type: GET_REGISTERED_TICKET_PURCHASES,
  payload: { ocId, ticketId, page }
});

export const getRegisteredTicketPurchasesSuccess = (
  ocId,
  ticketId,
  data,
  included,
  links
) => ({
  type: GET_REGISTERED_TICKET_PURCHASES_SUCCESS,
  payload: { data, included, ocId, links, ticketId }
});

export const getRegisteredTicketPurchasesFailed = (error) => ({
  type: GET_REGISTERED_TICKET_PURCHASES_FAILED,
  error
});

export const getUnregisteredTicketPurchases = (ocId, ticketId, page) => ({
  type: GET_UNREGISTERED_TICKET_PURCHASES,
  payload: { ocId, ticketId, page }
});

export const getUnregisteredTicketPurchasesSuccess = (
  ocId,
  ticketId,
  data,
  included,
  links
) => ({
  type: GET_UNREGISTERED_TICKET_PURCHASES_SUCCESS,
  payload: { data, included, ocId, links, ticketId }
});

export const getUnregisteredTicketPurchasesFailed = (error) => ({
  type: GET_UNREGISTERED_TICKET_PURCHASES_FAILED,
  error
});

export const registeredTicketPurchasesSearch = (
  ocId,
  ticketId,
  ticketVariantId,
  searchData,
  referenceId,
  page
) => ({
  type: REGISTERED_TICKET_PURCHASES_SEARCH,
  payload: { ocId, ticketId, ticketVariantId, searchData, referenceId, page }
});

export const registeredTicketPurchasesSearchSuccess = (
  data,
  ocId,
  ticketId,
  included,
  links
) => ({
  type: REGISTERED_TICKET_PURCHASES_SEARCH_SUCCESS,
  payload: { data, ocId, ticketId, included, links }
});

export const registeredTicketPurchasesSearchFailed = (error) => ({
  type: REGISTERED_TICKET_PURCHASES_SEARCH_FAILED,
  error
});

export const unregisteredTicketPurchasesSearch = (
  ocId,
  ticketId,
  ticketVariantId,
  searchData,
  page
) => ({
  type: UNREGISTERED_TICKET_PURCHASES_SEARCH,
  payload: { ocId, ticketId, ticketVariantId, searchData, page }
});

export const unregisteredTicketPurchasesSearchSuccess = (
  data,
  ocId,
  ticketId,
  included,
  links
) => ({
  type: UNREGISTERED_TICKET_PURCHASES_SEARCH_SUCCESS,
  payload: { data, ocId, ticketId, included, links }
});

export const unregisteredTicketPurchasesSearchFailed = (error) => ({
  type: UNREGISTERED_TICKET_PURCHASES_SEARCH_FAILED,
  error
});
