import React, { useState } from 'react';
import './styles.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import { SectionContent } from '../../../../components/Section';
import {
  getSocianaInternalUserWithPagination,
  getInternalTeamBySearch
} from '../../../../actions/socianaInternalUser';
import TableView from '../../../../components/Table';
import Tabs from '../../../../components/Tabs';
import { renderRoute } from '../../../../components/RouterHOC';
import { adminViewRouteAccess } from '../../../../selectors/auth';
import {
  getSocianaInternalUsersInactiveData,
  getSocianaInternalUsersActiveData
} from '../../../../selectors/socianaInternalUser';
import { resetTabIndex } from '../../../../actions/tabKeys';
import InputGroup from '../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../components/InputGroup/FormComponents';
import Form from 'antd/lib/form/Form';
import { Button } from 'antd';

function SocianaInternalUserView(props) {
  const [isActiveTab, setIsActiveTab] = useState(true);
  const [getInternalTeamDetails, setGetInternalTeamDetails] = useState({});
  const [errorMessages, setErrorMessages] = React.useState({});

  const onPageChange = (page) => {
    props.getSocianaInternalUserWithPagination(page, isActiveTab);
  };

  React.useEffect(() => {
    onPageChange(1);
    props.resetTabIndex('socianaInternalUser');
  }, [isActiveTab]);

  const handleTabChange = (key) => {
    setIsActiveTab(key == 1);
  };

  const tableViewTab = (paginationOptions) => (
    <div>
      <Form
        className="internal-team-input-search-container"
        onFinish={handlegetInternalteamDetails}>
        <InputGroup
          label="Name/Phone/Email"
          className="internal-team-inputbox"
          fieldType="searchbox"
          placeholder="Enter Name/Phone/Email"
          name="searchData"
          onChange={handleChange}
          value={getInternalTeamDetails?.searchData}
          errorMessage={errorMessages.type}
        />
        <div style={{ flex: 1, display: 'flex' }}>
          <SubmitButton
            wrapperClass="internal-team-submit-wrapper"
            className="internal-team-submit-inputbox"
            buttonText="Search"
            fetchProgress={props.fetchProgress}
            disabled={props.fetchProgress}
          />
          <Button className="reset-button" onClick={handleReset}>
            Reset
          </Button>
        </div>
      </Form>
      <TableView
        paginationOptions={paginationOptions}
        isPaginated={true}
        fetchProgress={props.fetchProgress}
        paginationLinks={props.userLinks}
        onPageChange={onPageChange}
        data={isActiveTab ? props.activeUsers : props.inactiveUsers}
      />
    </div>
  );

  const tabPanes = () => {
    const paginationOptions = {
      showTotal: () => (
        <div className="sociana-internal-team-count-container">
          <span>Team Members</span>
          <span className="sociana-internal-team-count">{props.count}</span>
        </div>
      )
    };
    return [
      {
        tabName: 'Active',
        tabKey: '1',
        children: tableViewTab(paginationOptions)
      },
      {
        tabName: 'Inactive',
        tabKey: '2',
        children: tableViewTab(paginationOptions)
      }
    ];
  };

  const validate = (values) => {
    const errors = {};
    if (!values.searchData) errors.type = 'Search Data required';

    return errors;
  };

  const handlegetInternalteamDetails = (page) => {
    try {
      const validateObj = validate(getInternalTeamDetails);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        props.getInternalTeamBySearch(
          getInternalTeamDetails?.searchData,
          true,
          isActiveTab,
          (page = 1)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'searchData') {
        value = e.target.value;
        name = type;
      }
      const internalteamDetailsValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      setGetInternalTeamDetails({
        ...getInternalTeamDetails,
        [inputName]: internalteamDetailsValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleReset = (page) => {
    setGetInternalTeamDetails({});
    setErrorMessages({});
    props.getSocianaInternalUserWithPagination(page, isActiveTab);
  };

  return (
    <ErrorBoundary>
      <SectionContent className="internal-team-view-section">
        <div className="internal-team-view-container">
          <div className="internal-team-change-type-container">
            <Tabs
              defaultActiveKey="1"
              onChange={handleTabChange}
              tabPanes={tabPanes()}
            />
          </div>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => {
  return {
    activeUsers: getSocianaInternalUsersActiveData(store, props),
    inactiveUsers: getSocianaInternalUsersInactiveData(store, props),
    fetchProgress: store.browserState.fetchProgress,
    userLinks: store.socianaInternalUser.links,
    count: store.socianaInternalUser.count
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetTabIndex,
      getSocianaInternalUserWithPagination,
      getInternalTeamBySearch
    },
    dispatch
  );

export default renderRoute(adminViewRouteAccess)(
  connect(mapStateToProps, mapDispatchToProps)(SocianaInternalUserView)
);
