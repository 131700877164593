import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Form } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import './styles.scss';
import { SectionContent } from '../../../../../../../components/Section';
import ErrorBoundary from '../../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../../components/InputGroup';
import {
  FormMap,
  SubmitButton,
  Switch
} from '../../../../../../../components/InputGroup/FormComponents';
import { capitalize } from '../../../../../../../helpers/stringHelper';
import { getImageSource } from '../../../../../../../helpers/imageHelper';
import { getCurrentOccasion } from '../../../../../../../selectors/occasion';
import { getCurrentEvent } from '../../../../../../../selectors/event';
import {
  eventAffairEntertainmentCreate,
  eventAffairEntertainmentUpdate
} from '../../../../../../../actions/eventAffairEntertainment';
import { getCurrentEventAffairEntertainment } from '../../../../../../../selectors/eventAffair';

const validateOccasionEvent = (values, startAT, endAt) => {
  const error = {};
  const ocStartAt = new Date(startAT).getTime();
  const ocEndAt = new Date(endAt).getTime();
  const eventStartAt = new Date(values.schedule[0]).getTime();
  const eventEndAt = new Date(values.schedule[1]).getTime();
  if (!values.title) {
    error.title = 'Artist Name is Required';
  }
  if (!values.venue) {
    error.venue = 'Venue is Required';
  }
  if (!(values.schedule?.length > 0)) {
    error.schedule = 'Start and End date are required';
  } else if (!values?.schedule[0]) {
    error.schedule = 'Start date is required';
  } else if (!values?.schedule[1]) {
    error.schedule = 'End date is required';
  }
  return error;
};

function CreateEventAffairEntertainment(props) {
  const [occasionEventFormState, setOccasionEventFormState] = React.useState({
    ...props.initialValues
  });

  const [isLocationSet, setIsLocationSet] = useState(false);
  const [errorMessages, setErrorMessages] = React.useState({});
  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);
  const [locationFieldToggle, setLocationFieldToggle] = useState(true);

  const [eventCoordinates, setEventCoordinates] = React.useState({
    lat: props?.initialValues?.latitude,
    lng: props?.initialValues?.longitude
  });

  const venueInput = React.useRef();

  useEffect(() => {
    setOccasionEventFormState(props.initialValues);
  }, [props.initialValues]);

  const handleOccasionEventCreate = () => {
    try {
      const validateObj = validateOccasionEvent(
        occasionEventFormState,
        props?.currentEvent?.startAt,
        props?.currentEvent?.endAt
      );
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append(
          'event_affair_entertainment[artist_name]',
          occasionEventFormState.title
        );

        formData.append(
          'event_affair_entertainment[description]',
          occasionEventFormState.description
        );

        formData.append(
          'event_affair_entertainment[start_at]',
          new Date(occasionEventFormState.schedule[0])
        );
        formData.append(
          'event_affair_entertainment[end_at]',
          new Date(occasionEventFormState.schedule[1])
        );
        if (occasionEventFormState.venue) {
          formData.append(
            'event_affair_entertainment[venue]',
            occasionEventFormState.venue
          );
        }
        formData.append(
          'event_affair_entertainment[lattitude]',
          Number(eventCoordinates.lat).toFixed(6)
        );
        formData.append(
          'event_affair_entertainment[longitude]',
          Number(eventCoordinates.lng).toFixed(6)
        );
        if (
          occasionEventFormState.card_image &&
          occasionEventFormState.card_image[0]?.originFileObj
        ) {
          formData.append(
            'event_affair_entertainment[card_image]',
            occasionEventFormState.card_image[0].originFileObj
          );
        }
        props.entertainmentId
          ? props.eventAffairEntertainmentUpdate(
              props.occasionId,
              props.eventAffairId,
              props.eventId,
              props.entertainmentId,
              formData
            )
          : props.eventAffairEntertainmentCreate(
              props.occasionId,
              formData,
              props.eventId,
              props.eventAffairId,
              () => {
                setOccasionEventFormState();
              }
            );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type, momentData) => {
    try {
      let value;
      let name;
      if (e) {
        if (isInitiallyDisabled) {
          setInitiallyDisabled(false);
        }
        if (type === 'card_image') {
          value = e.fileList;
          name = type;
        }
        if (type === 'venue') {
          if (e.lat && e.lng) {
            setEventCoordinates({ lat: e.lat, lng: e.lng });
          }
        }

        if (type === 'schedule') {
          value = e.map((item) => moment(item, 'DD/MM/YYYY HH:mm'));
          name = type;
        }
        const occasionEventFormValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        if (type !== 'venue') {
          setOccasionEventFormState({
            ...occasionEventFormState,
            [inputName]: occasionEventFormValue
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const setVenue = (location) => {
    if (venueInput.current) {
      if (!isLocationSet && props.initialValues?.venue?.length > 0) {
        setIsLocationSet(true);
        return;
      }
      setOccasionEventFormState({ ...occasionEventFormState, venue: location });
    }
  };
  const toggleLocationField = () => {
    setLocationFieldToggle(!locationFieldToggle);
  };

  return (
    <ErrorBoundary>
      <SectionContent className="event-affair-entertainment-create-section">
        <div className="event-affair-entertainment-create-form">
          <Form
            className="event-affair-entertainment-form-container"
            onFinish={handleOccasionEventCreate}>
            <InputGroup
              label="Artist Name"
              name="title"
              placeholder="Enter Title"
              onChange={handleInputChange}
              value={occasionEventFormState?.title}
              errorMessage={errorMessages.title}
            />
            <InputGroup
              label="Description"
              name="description"
              placeholder="Enter Description"
              onChange={handleInputChange}
              fieldType="textArea"
              value={occasionEventFormState?.description}
            />

            <InputGroup
              fieldType="rangePicker"
              onChange={handleInputChange}
              placeholder={['Start Date with Time', 'End Date with Time']}
              label="Schedule"
              name="schedule"
              value={occasionEventFormState?.schedule}
              errorMessage={errorMessages.schedule}
            />
            <div>
              <span className="location-header">
                {locationFieldToggle
                  ? 'Toggle to enter coordinates manually'
                  : 'Toggle to select location on map'}
              </span>
              <Switch
                value={locationFieldToggle}
                onChange={toggleLocationField}
                noChildren={true}
              />
            </div>
            {locationFieldToggle ? (
              <FormMap
                label="Location"
                value={{
                  latitude: eventCoordinates?.lat,
                  longitude: eventCoordinates?.lng
                }}
                setVenue={setVenue}
                onChange={handleInputChange}
              />
            ) : (
              <>
                <InputGroup
                  onChange={handleInputChange}
                  placeholder="Longitude"
                  label="Longitude"
                  name="longitude"
                  value={occasionEventFormState?.longitude}
                  errorMessage={errorMessages.longitude}
                />
                <InputGroup
                  onChange={handleInputChange}
                  placeholder="Latitude"
                  label="Latitude"
                  name="latitude"
                  value={occasionEventFormState?.latitude}
                  errorMessage={errorMessages.latitude}
                />
              </>
            )}
            <InputGroup
              label="Venue"
              name="venue"
              placeholder="Enter Venue"
              ref={venueInput}
              onChange={handleInputChange}
              value={occasionEventFormState?.venue}
              errorMessage={errorMessages.venue}
            />
            <InputGroup
              fieldType="file"
              label="File"
              fileList={occasionEventFormState?.card_image}
              onChange={handleInputChange}
              name="card_image"
            />
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.entertainmentId ? 'Save Changes' : 'Create'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => {
  const entertainmentdata = getCurrentEventAffairEntertainment(store, props);
  const currentEvent = getCurrentEvent(store, props);
  const StartAt = moment(
    moment(entertainmentdata?.startAt).format('DD-MM-YYYY HH:mm'),
    'DD-MM-YYYY HH:mm'
  );
  const EndAt = moment(
    moment(entertainmentdata?.endAt).format('DD-MM-YYYY HH:mm'),
    'DD-MM-YYYY HH:mm'
  );
  const initialValues = {
    title: entertainmentdata?.artistName || '',
    description: entertainmentdata?.description || '',
    latitude: entertainmentdata?.lattitude || '',
    longitude: entertainmentdata?.longitude || '',
    schedule:
      entertainmentdata?.startAt && entertainmentdata?.endAt
        ? [StartAt, EndAt]
        : [],
    venue: entertainmentdata?.venue || '',
    card_image: entertainmentdata?.cardImageUrl
      ? [{ url: getImageSource(entertainmentdata?.cardImageUrl) }]
      : null
  };
  return {
    entertainmentdata,
    currentEvent,
    initialValues,
    fetchProgress: store.browserState.fetchProgress
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      eventAffairEntertainmentCreate,
      eventAffairEntertainmentUpdate
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEventAffairEntertainment);
