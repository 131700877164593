import React from 'react';
import { Popover } from 'antd';
import './styles.scss';
import { capitalize } from '../../helpers/stringHelper';
import { ProfileContent } from './ProfileContent';
import { ObjectContent } from './ObjectContent';
import { Avatar } from '../Avatar';
import { GroupContent } from './GroupContent';
import FloorPlanImageButton from '../FloorPlanImageButton';
import { EventContent } from './EventContent';
import { GuestContent } from './GuestContent';
import { PollPostOptionContent } from './PollPostOptionContent';
import { SheetContent } from './SheetContent';
import { WhatsappTemplateMessageContent } from './WhatsappTemplateMessageContent';
import { CustomParamsContent } from './CustomParamsContent';

const profileContentKeys = [
  'invitedBy',
  'user',
  'createdBy',
  'streamingBy',
  'users',
  'updatedBy',
  'occasion',
  'answerBy',
  'answerTo',
  'referencable',
  'scannedBy',
  'modelable'
];
const MAX_GROUP_COUNT = 3;
export const ObjectPopover = ({ dataKey, data }) => {
  const content = () => {
    if (Array.isArray(data) && dataKey === 'groups') {
      return <GroupContent data={data} />;
    }
    if (Array.isArray(data) && dataKey === 'events') {
      return <EventContent data={data} />;
    }
    if (Array.isArray(data) && dataKey === 'currentOccasionHospitalityGuests') {
      return <GuestContent data={data} />;
    }
    if (Array.isArray(data) && dataKey === 'pollPostOptions') {
      return <PollPostOptionContent data={data} />;
    }
    if (
      (Array.isArray(data) && dataKey === 'customParams') ||
      dataKey === 'selfModelQueryParams'
    ) {
      return <CustomParamsContent data={data} dataKey={dataKey} />;
    }
    if (data && dataKey === 'sheetNames') {
      return <SheetContent data={data} />;
    }
    if (
      data &&
      Object.values(data).length > 0 &&
      dataKey === 'whatsappTemplateMessage'
    ) {
      return <WhatsappTemplateMessageContent data={data} />;
    }
    if (profileContentKeys.includes(dataKey)) {
      return <ProfileContent data={data} />;
    } else {
      <ObjectContent data={data} />;
    }
  };

  return (
    <Popover
      content={content}
      placement="right"
      overlayClassName="popover-overlay"
      overlayInnerStyle={{
        width: 300,
        backgroundColor: 'transparent'
      }}>
      {!Array.isArray(data) ? (
        <div>
          {dataKey === 'header' ? (
            <div className="popover_container_division">
              {data && Object.values(data).length > 0 ? (
                <div>True</div>
              ) : (
                <div>...</div>
              )}
            </div>
          ) : dataKey === 'language' ? (
            <div className="popover_container_division">
              {data ? <div>{data?.key}</div> : <div>...</div>}
            </div>
          ) : dataKey === 'sheetNames' ? (
            <div className="popover_container_division">
              {data ? (
                <div style={{ color: '#1890ff' }}>View Sheet Details</div>
              ) : (
                <div>...</div>
              )}
            </div>
          ) : dataKey === 'whatsappTemplateMessage' ? (
            <div className="obj-popover_container_division">
              {data && Object.values(data).length ? (
                <div className="whatsapp-template-message-container">
                  <p className="whatsapp-template-message-name">
                    {data?.elementName}
                  </p>
                  <p className="whatsapp-template-message-type">
                    {data?.messageType}
                  </p>
                </div>
              ) : (
                <div>...</div>
              )}
            </div>
          ) : (
            <div>
              {data?.name || data?.title ? (
                <div className="popover_container">
                  <Avatar
                    avatarUrl={data?.avatarUrl || data?.cardImageUrl}
                    name={data?.name || data?.title}
                  />
                  <span className="user_name">
                    {data?.name || data?.title || ''}
                  </span>
                </div>
              ) : (
                <div>...</div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="array_popover_container">
          <div className="array_popover_container_division">
            {dataKey === 'groups' &&
              data?.slice(0, MAX_GROUP_COUNT).map((item) => {
                return (
                  <span className="array_popover_contant">
                    <Avatar
                      avatarUrl={item?.groupPictureUrl}
                      name={item?.name}
                      className="popover_contant_image"
                    />
                  </span>
                );
              })}
            {dataKey === 'groups' && data?.length > MAX_GROUP_COUNT ? (
              <FloorPlanImageButton data={data} index={2} />
            ) : (
              ''
            )}
          </div>
          <div className="array_popover_container_division">
            {dataKey === 'events' && data.length > 0 ? (
              data?.map((item) => {
                return (
                  <span className="event_array_popover_contant">
                    <Avatar
                      avatarUrl={item?.cardImageUrl}
                      name={item?.title}
                      className="event_popover_contant_image"
                    />
                    <span className="event_popover_contant_name">
                      {item?.title || ''}
                    </span>
                  </span>
                );
              })
            ) : (
              <div className="popover_with_no_data">
                {dataKey === 'events' ? <span>{'---'}</span> : ''}
              </div>
            )}
          </div>
          <div className="array_popover_container_division">
            {dataKey === 'currentOccasionHospitalityGuests' &&
              data?.length > 0 &&
              data?.slice(0, MAX_GROUP_COUNT).map((item) => {
                return (
                  <div className="guest_array_popover_contant">
                    <Avatar
                      avatarUrl={item?.avatarUrl}
                      name={item?.name}
                      className="guest_popover_contant_image"
                    />
                  </div>
                );
              })}
            {dataKey === 'currentOccasionHospitalityGuests' &&
            data?.length > MAX_GROUP_COUNT ? (
              <FloorPlanImageButton data={data} index={2} />
            ) : (
              ''
            )}
          </div>

          <div className="array_popover_container_division">
            {dataKey === 'pollPostOptions' &&
              data?.length > 0 &&
              data?.map((item) => item?.option || '').join(', ')}
          </div>

          <div>
            {dataKey === 'buttons' && data?.length > 0 && <div>True</div>}
            {dataKey === 'customParams' && data?.length > 0 && (
              <div style={{ color: '#1890ff' }}>View Details</div>
            )}
            {dataKey === 'selfModelQueryParams' && data?.length > 0 && (
              <div style={{ color: '#1890ff' }}>View Details</div>
            )}
            {data?.length == 0 && <div>---</div>}
          </div>
        </div>
      )}
    </Popover>
  );
};
