import React, { useState } from 'react';
import moment from 'moment';
import {
  capitalize,
  changeUnderscoreToSpace,
  decamelize
} from '../../helpers/stringHelper';
import './styles.scss';
import CardImage from './CardImage';
import { Avatar } from '../Avatar';
import { TABLE_KEY_VALUES } from '../../constants/OtherConstants';
import IdCell from './IdCell';
import FloorPlanImageButton from '../FloorPlanImageButton';
import ImageModal from '../ImageModal';
import { DataCell } from './DataCell';
import { ObjectPopover } from '../Popover';
import { Form, Radio, Tooltip, Button, Modal } from 'antd';

const cardImageKeys = [
  'cardImageUrl',
  'avatarUrl',
  'managedFarmLogoUrl',
  'groupPictureUrl',
  'thumbnailImageUrl',
  'awardImageUrl',
  'sponsorshipImageUrl',
  'carouselImageUrl',
  'albumCardImageUrl',
  'qrcodeUrl',
  'qrCodeUrl',
  'iconImageUrl',
  'video',
  'videoThumbnail',
  'foodImageUrl',
  'thumbUrl',
  'fileUrl',
  'occasionFileUrl',
  'occasionFileThumbUrl',
  'bgImageUrl',
  'thankYouMediaThumbUrl',
  'thankYouMediaUrl',
  'backgroundFileUrl',
  'speakerImageUrl',
  'selfieImageUrl'
];

const lowerCaseDataKey = ['emailOrMobile'];

export const getTableRenderItem = (
  data,
  key,
  isRouted,
  pathname,
  useLink,
  occasionId,
  onDelete,
  showDelete,
  isEditable,
  rowData,
  customAction,
  normalTime,
  isCreatable
) => {
  const [isMultipalFileView, setIsMultipalFileView] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = (type) => {
    if (type === 'multipalFileView') {
      setIsMultipalFileView(false);
    }
    setIsModalVisible(false);
  };

  const handleCancel = (type) => {
    if (type === 'multipalFileView') {
      setIsMultipalFileView(false);
    }
    setIsModalVisible(false);
  };

  const handleMultipalFilesView = () => {
    setIsMultipalFileView(true);
  };

  let routed = false;
  if (isRouted) {
    routed = true;
    if (typeof isRouted === 'object') {
      routed = rowData[isRouted?.key] === false ? false : true;
    }
  }

  if (cardImageKeys.includes(key)) {
    return data ? <CardImage data={data} keyName={key} /> : '---';
  }
  if (key === 'avatarName') {
    return data ? data : '---';
  }

  if (['documentAttachmentUrl', 'mediaFileUrl'].includes(key)) {
    return data ? (
      <a href={`${process.env.REACT_APP_CLIENT_URL}/${data}`} target="_blank">
        View File
      </a>
    ) : (
      '---'
    );
  }

  if (key === 'floorPlanImageUrls' || key === 'splashScreenFileUrls') {
    return data && data.length > 0 ? (
      <div style={{ display: 'flex' }}>
        {data.slice(0, 3).map((item) => (
          <CardImage
            data={Object.values(item)[0]}
            key={Object.values(item)[0]}
          />
        ))}
        <FloorPlanImageButton
          data={data}
          index={2}
          onClick={() => showModal()}
        />
        {isModalVisible ? (
          <ImageModal
            title={key === 'splashScreenFileUrls' ? 'Splash Screen Files' : ''}
            visible={isModalVisible}
            floorPlanImageUrls={data}
            onOk={handleOk}
            onCancel={handleCancel}
          />
        ) : (
          ''
        )}
      </div>
    ) : (
      '---'
    );
  }

  if (key === 'picture') {
    return data && data.length > 0 ? (
      <div style={{ display: 'flex' }}>
        {data.slice(0, 3).map((item) => (
          <CardImage data={item} key={item} />
        ))}
        <FloorPlanImageButton
          data={data}
          index={2}
          onClick={() => showModal()}
        />
        {isModalVisible ? (
          <ImageModal
            visible={isModalVisible}
            floorPlanImageUrls={data}
            onOk={handleOk}
            onCancel={handleCancel}
          />
        ) : (
          ''
        )}
      </div>
    ) : (
      '---'
    );
  }

  if (
    key === 'currentRequestedTaskAnswer' ||
    key === 'currentProvidedTaskAnswer'
  ) {
    if (data?.multipleDocumentAttachmentUrls) {
      return (
        <>
          <div
            style={{ cursor: 'pointer', color: 'blue' }}
            onClick={handleMultipalFilesView}>
            View All Files
          </div>
          <Modal
            centered
            title="All Documents"
            visible={isMultipalFileView}
            onOk={() => handleOk('multipalFileView')}
            onCancel={() => handleCancel('multipalFileView')}
            footer={null}>
            <div
              style={{
                width: '100%',
                height: '150px',
                overflowY: 'scroll',
                display: 'flex',
                flexFlow: 'column nowrap'
              }}>
              {Array.isArray(data.multipleDocumentAttachmentUrls) &&
                data.multipleDocumentAttachmentUrls.length > 0 &&
                data.multipleDocumentAttachmentUrls.map((item, index) => {
                  return (
                    <div style={{ marginBottom: '20px' }}>
                      <a
                        href={`${process.env.REACT_APP_CLIENT_URL}/${
                          Object.values(item)[0]
                        }`}
                        target="_blank">
                        {`File ${index + 1}`}
                      </a>
                    </div>
                  );
                })}
            </div>
          </Modal>
        </>
      );
    } else if (data?.documentAttachmentUrl) {
      return (
        <a
          href={`${process.env.REACT_APP_CLIENT_URL}/${data?.documentAttachmentUrl}`}
          target="_blank">
          File
        </a>
      );
    } else if (data?.answer) {
      return data.answer;
    }
    return '---';
  }

  if (
    isNaN(data) &&
    !isNaN(Date.parse(data)) &&
    [
      'startAt',
      'endAt',
      'triggerDateTime',
      'timeStamp',
      'triggeredAt',
      'alarmTime',
      'createdAt',
      'startTime',
      'endTime'
    ].includes(key)
  ) {
    const date = normalTime ? moment(data) : moment.utc(data);
    return date ? date.format('DD/MM/YYYY hh:mm A') : '---';
  }

  const result = data;

  if (typeof data === 'object' && data !== null) {
    if (
      Array.isArray(data) &&
      ['hospitalityTaskOptions', 'pollPostOptions'].includes(key)
    ) {
      if (data.length > 0) {
        return (
          <div className="table-options-container">
            {data
              .map((item) => {
                return item?.successFactor == null || item?.successFactor == 0
                  ? item?.correctOptionFlag && item?.correctOptionFlag == true
                    ? `${item.option}${'(T)'}`
                    : `${item.option}`
                  : item?.successFactor
                  ? item?.correctOptionFlag && item?.correctOptionFlag == true
                    ? `${item.option}[${item.successFactor}]${'(T)'}`
                    : `${item.option}[${item.successFactor}]`
                  : item.option;
              })
              .join(', ')}
          </div>
        );
      } else {
        return (
          <div className="table-option-container-with-no-data">{'---'}</div>
        );
      }
    }
    if (Array.isArray(data) && key === 'questionableFormOptions') {
      if (data.length > 0) {
        return (
          <div className="table-options-container">
            {data.map((item) => item.optionValue).join(', ')}
          </div>
        );
      } else {
        return (
          <div className="table-option-container-with-no-data">{'---'}</div>
        );
      }
    }
    if (Array.isArray(data) && key === 'description') {
      if (data.length > 0) {
        return (
          <Tooltip
            overlayClassName="description-tooltip"
            placement="left"
            title={
              <span className="description-tooltip-span">
                {data.map((item) => item).join(', ')}
              </span>
            }>
            {data.map((item) => item).join(', ')}
          </Tooltip>
        );
      } else {
        return (
          <div className="table-option-container-with-no-data">{'---'}</div>
        );
      }
    }
    if (Array.isArray(data) && ['websiteLinks'].includes(key)) {
      if (data.length > 0) {
        return (
          <div className="table-options-container">
            {data.map((item) => (
              <a style={{ marginLeft: '20px' }} href={item} target="_blank">
                {item}
              </a>
            ))}
          </div>
        );
      } else {
        return (
          <div className="table-option-container-with-no-data">{'---'}</div>
        );
      }
    }
    if (key === 'postedByGroup' || key === 'viewedByGroup') {
      return (
        <div className="postedByGroup-container">
          <Avatar avatarUrl={data?.groupPictureUrl} name={data?.name} />
          <Tooltip placement="right" title={data?.name || ''}>
            <span className="postedByGroup-content">{data?.name || ''}</span>
          </Tooltip>
        </div>
      );
    }
    return <ObjectPopover dataKey={key} data={data} />;
  }

  if (key === 'isMember' || key === 'isEnable') {
    if (data === true) {
      return (
        <div
          style={{
            backgroundColor: '#179e8a',
            borderRadius: '10px',
            color: 'white'
          }}>
          {data.toString()}
        </div>
      );
    } else {
      return data.toString();
    }
  }

  if (key === 'id') {
    return key ? (
      <IdCell
        data={data}
        isRouted={routed}
        pathname={pathname}
        useLink={useLink}
        isCreatable={isCreatable}
        isEditable={isEditable}
        onDelete={onDelete}
        showDelete={showDelete}
        occasionId={occasionId}
        customAction={customAction}
      />
    ) : (
      '---'
    );
  }
  if (typeof data === 'boolean') {
    return data.toString();
  }

  if (!isNaN(result) && typeof result === 'number') {
    return (
      <DataCell
        style={{
          textTransform: lowerCaseDataKey.includes(key) ? 'lowercase' : 'none'
        }}>
        {result}
      </DataCell>
    );
  }

  return result !== undefined &&
    result !== 'null' &&
    result !== 'Null' &&
    result !== null &&
    result !== 'undefined' &&
    result.length > 0 ? (
    <DataCell
      style={{
        textTransform: lowerCaseDataKey.includes(key) ? 'lowercase' : 'none'
      }}>
      {result}
    </DataCell>
  ) : (
    '---'
  );
};

export const getTableColumn = (
  data,
  isRouted,
  blacklistKeys = [],
  pathname = '',
  useLink = false,
  occasionId,
  isEditable,
  onDelete,
  showDelete,
  customAction,
  normalTime,
  isCreatable
) => {
  if (!!data && data.length === 0) {
    return [];
  }
  if (data) {
    let keys = data[1] ? Object.keys(data[1]) : Object.keys(data[0]);
    if (keys?.length > 0 && keys.includes('id')) {
      keys = keys.filter((key) => key !== 'id');
      keys.unshift('id');
    }

    return keys
      .filter((key) => !blacklistKeys.includes(key))
      .map((key, index, arr) => {
        const heading = TABLE_KEY_VALUES[key]
          ? TABLE_KEY_VALUES[key]
          : capitalize(decamelize(key));
        return {
          title: heading,
          dataIndex: key,
          render: (data, rowData) =>
            getTableRenderItem(
              data,
              key,
              isRouted,
              pathname,
              useLink,
              occasionId,
              onDelete,
              showDelete,
              isEditable,
              rowData,
              customAction,
              normalTime,
              isCreatable
            )
        };
      });
  }
  return [];
};
