import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { Form, Button } from 'antd';
import '../styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import { JOIN_AS } from './InvitationConstants';
import { currentPendingRegistartionData } from '../../../../../selectors/invitation';
import { updatePendingRegistration } from '../../../../../actions/Invitation';

const validator = (values) => {
  const errors = {};
  if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.text) &&
    !/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/.test(values.text)
  ) {
    errors.message = 'Invalid Input';
  }
  return errors;
};

function PendingRegistrationEdit(props) {
  const [getRegistrationDetails, setGetRegistrationDetails] = React.useState({
    text: props.currentPendingRegistartion?.emailOrMobile || '',
    joinas: props.currentPendingRegistartion?.joinAs || ''
  });
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleSubmit = () => {
    try {
      const validateObj = validator(getRegistrationDetails);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append(
          'pending_registration[email_or_mobile]',
          getRegistrationDetails?.text
        );
        formData.append(
          'pending_registration[join_as]',
          getRegistrationDetails?.joinas
        );
        props.updatePendingRegistration(
          props.occasionId,
          props.pendingRegistrationId,
          formData
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      const invitationDetailsValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      setGetRegistrationDetails({
        ...getRegistrationDetails,
        [inputName]: invitationDetailsValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="invitation-edit-section">
        <div className="invitation-edit-form">
          <Form
            className="invitation-edit-form-container"
            onFinish={handleSubmit}>
            <InputGroup
              name="text"
              fieldType="textArea"
              placeholder=" Email or Phone number"
              label="Email/Phone number"
              value={getRegistrationDetails?.text}
              onChange={handleChange}
              errorMessage={errorMessages.message}
            />
            <InputGroup
              label="Join As"
              className="invitation-inputbox"
              fieldType="dropdown"
              placeholder="Select"
              options={JOIN_AS}
              name="joinas"
              onChange={handleChange}
              value={getRegistrationDetails?.joinas}
            />
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText="Save Changes"
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentPendingRegistartion: currentPendingRegistartionData(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updatePendingRegistration }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingRegistrationEdit);
