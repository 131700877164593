import { createSelector } from 'reselect';
import { currentOccasionId, currentActivityTrackerId } from './dynamicData';
import { activityTrackerPresentUserEntities, userEntities } from './entity';
import {
  searchUserByOccasionIds,
  activityTrackerPresentUserIds
} from './static';

export const currentActivityTrackerPresentUserIds = createSelector(
  currentActivityTrackerId,
  activityTrackerPresentUserIds,
  (trId, ids) => trId && ids && ids[trId]
);
export const getAllActivityTrackerPresentUserIds = createSelector(
  activityTrackerPresentUserEntities,
  currentActivityTrackerPresentUserIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
export const activityTrackerWithUserData = createSelector(
  getAllActivityTrackerPresentUserIds,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      user: { ...entities[item?.user?.id] },
      createdBy: { ...entities[item?.createdBy?.id] }
    }))
);

export const getSearchedUsersByOccasion = createSelector(
  searchUserByOccasionIds,
  userEntities,
  (memIds, entities) =>
    (memIds &&
      memIds.length > 0 &&
      entities &&
      memIds?.map((id) => entities[id])) ||
    (Array.isArray(memIds) ? [] : null)
);
