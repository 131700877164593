import request from './request';

export function occasionQuestionCreateApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/ticket/questionable_forms`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function occasionQuestionViewApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/ticket/get_all_questionable_forms?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}
