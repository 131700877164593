import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, Form } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../components/Section';
import { GENDERS } from '../../../../constants/OtherConstants';
import {
  createSocianaInternalUser,
  editSocianaInternalUser
} from '../../../../actions/socianaInternalUser';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import InputGroup from '../../../../components/InputGroup';
import validateUser from './validate';
import { SubmitButton } from '../../../../components/InputGroup/FormComponents';
import { getImageSource } from '../../../../helpers/imageHelper';
import { renderRoute } from '../../../../components/RouterHOC';
import { adminCreateRouteAccess } from '../../../../selectors/auth';
import { getDateInMonthFormat } from '../../../../helpers/dateTimeHelper';

function SocianaInternalUserCreate(props) {
  const [initiallyButtonDisabled, setInitiallyButtonDisabled] = useState(true);
  const [
    internalTeamUserFormState,
    setInternalTeamUserFormState
  ] = React.useState({ ...props.initialValues });
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleInternalTeamUserCreate = () => {
    try {
      const validateObj = validateUser(internalTeamUserFormState, props.userId);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const data = new FormData();
        data.append('user[first_name]', internalTeamUserFormState.first_name);
        data.append('user[last_name]', internalTeamUserFormState.last_name);
        data.append('user[email]', internalTeamUserFormState.email);
        data.append('user[mobile]', internalTeamUserFormState.phone);
        data.append(
          'user[dob]',
          internalTeamUserFormState.dob.format('DD/MM/YYYY')
        );
        data.append('user[gender]', internalTeamUserFormState.gender);
        data.append('user[from_internal_team]', true);
        if (
          internalTeamUserFormState.avatar &&
          internalTeamUserFormState.avatar[0]?.originFileObj
        ) {
          data.append(
            'user[avatar]',
            internalTeamUserFormState.avatar[0]?.originFileObj
          );
        }
        if (!props.userId) {
          data.append('user[password]', internalTeamUserFormState.password);
        }
        if (props.userId) {
          props.editSocianaInternalUser(data, props.userId, () => {
            setInternalTeamUserFormState({});
            props.handleCloseClick();
          });
        } else {
          props.createSocianaInternalUser(data, () => {
            setInternalTeamUserFormState({});
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type, momentData) => {
    try {
      let value;
      let name;
      let adminFormValue;
      let inputName;
      if (initiallyButtonDisabled) {
        setInitiallyButtonDisabled(false);
      }
      if (type === 'avatar') {
        value = e.fileList;
        name = type;
      }
      if (type === 'dob') {
        value = moment(e, 'DD/MM/YYYY');
        name = type;
      }
      adminFormValue = value || e?.target?.value;
      inputName = name || e?.target?.name;

      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setInternalTeamUserFormState({
        ...internalTeamUserFormState,
        [inputName]: adminFormValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="internal-team-create-section">
        <div className="internal-team-create-form">
          <Form
            className="internal-team-form"
            onFinish={handleInternalTeamUserCreate}>
            <InputGroup
              label="First Name"
              name="first_name"
              placeholder="Enter First Name"
              onChange={handleInputChange}
              value={internalTeamUserFormState?.first_name}
              errorMessage={errorMessages.first_name}
            />
            <InputGroup
              label="Last Name"
              name="last_name"
              placeholder="Enter Last Name"
              onChange={handleInputChange}
              value={internalTeamUserFormState?.last_name}
              errorMessage={errorMessages.last_name}
            />
            <InputGroup
              label="Phone"
              name="phone"
              type="number"
              placeholder="Enter Phone Number"
              onChange={handleInputChange}
              value={internalTeamUserFormState?.phone}
              errorMessage={errorMessages.phone}
            />
            <InputGroup
              label="Email ID"
              name="email"
              placeholder="Enter Email ID"
              onChange={handleInputChange}
              value={internalTeamUserFormState?.email}
              errorMessage={errorMessages.email}
            />

            <InputGroup
              fieldType="datePicker"
              onChange={handleInputChange}
              label="Date of Birth"
              name="dob"
              value={internalTeamUserFormState?.dob}
              errorMessage={errorMessages.dob}
            />
            <InputGroup
              fieldType="radioGroup"
              options={GENDERS}
              name="gender"
              label="Gender"
              onChange={handleInputChange}
              value={internalTeamUserFormState?.gender}
              errorMessage={errorMessages.gender}
            />
            {!props.userId && (
              <InputGroup
                label="Password"
                name="password"
                placeholder="Enter Password"
                onChange={handleInputChange}
                value={internalTeamUserFormState?.password}
                errorMessage={errorMessages.password}
                type="password"
              />
            )}
            <InputGroup
              fieldType="file"
              label="Profile Picture"
              accept="image/*"
              fileList={internalTeamUserFormState?.avatar}
              onChange={handleInputChange}
              name="avatar"
              errorMessage={errorMessages.avatar}
            />
            <SubmitButton
              disabled={initiallyButtonDisabled || props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={
                props.userId ? 'Save Changes' : 'Create Internal User'
              }
            />
          </Form>
          {!!props.userId && (
            <Button
              onClick={props.handleCloseClick}
              className="edit-close-button">
              <CloseOutlined />
            </Button>
          )}
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => {
  const { userDetails } = props;
  let initialValues = {};
  if (userDetails && Object.keys(userDetails).length > 0) {
    initialValues = {
      first_name:
        userDetails?.name &&
        userDetails?.name?.substr(0, userDetails?.name?.indexOf(' ')),
      last_name:
        userDetails?.name &&
        userDetails?.name?.substr(userDetails?.name?.indexOf(' ') + 1),
      email: userDetails?.email && userDetails?.email,
      phone: userDetails?.mobile || '',
      dob: userDetails?.dob && moment(userDetails?.dob, 'DD/MM/YYYY'),
      gender: userDetails?.gender,
      avatar: userDetails?.avatarUrl && [
        { url: getImageSource(userDetails?.avatarUrl) }
      ]
    };
  }
  return {
    initialValues,
    fetchProgress: store.browserState.fetchProgress
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createSocianaInternalUser,
      editSocianaInternalUser
    },
    dispatch
  );

export default renderRoute(adminCreateRouteAccess)(
  connect(mapStateToProps, mapDispatchToProps)(SocianaInternalUserCreate)
);
