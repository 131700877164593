import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  occasionItineraryCreateApi,
  occasionItineraryDeleteApi,
  occasionItineraryUpdateApi,
  occasionItineraryViewApi
} from '../api/itinerary';
import {
  OCCASION_ITINERARY_CREATE,
  OCCASION_ITINERARY_DELETE,
  OCCASION_ITINERARY_UPDATE,
  OCCASION_ITINERARY_VIEW
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  occasionItineraryCreateFailed,
  occasionItineraryCreateSuccess,
  occasionItineraryDeleteFailed,
  occasionItineraryDeleteSuccess,
  occasionItineraryUpdateFailed,
  occasionItineraryUpdateSuccess,
  occasionItineraryViewFailed,
  occasionItineraryViewSuccess
} from '../actions/itinerary';

export function* occasionItineraryCreateSaga(action) {
  try {
    const result = yield call(
      occasionItineraryCreateApi,
      action.payload.ocId,
      action.payload.scheduleId,
      action.payload.schedulableType,
      action.payload.schedulableId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionItineraryCreateSuccess(
          result.data,
          action.payload.ocId,
          action.payload.scheduleId,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionItineraryCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionItineraryCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionItineraryViewSaga(action) {
  try {
    const result = yield call(
      occasionItineraryViewApi,
      action.payload.ocId,
      action.payload.scheduleId,
      action.payload.schedulableType,
      action.payload.schedulableId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionItineraryViewSuccess(
          result.data,
          action.payload.ocId,
          action.payload.scheduleId,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionItineraryViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionItineraryUpdateSaga(action) {
  try {
    const result = yield call(
      occasionItineraryUpdateApi,
      action.payload.ocId,
      action.payload.scheduleId,
      action.payload.itineraryId,
      action.payload.schedulableType,
      action.payload.schedulableId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionItineraryUpdateSuccess(
          result.data,
          action.payload.ocId,
          action.payload.scheduleId,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionItineraryUpdateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionItineraryUpdateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionItineraryDeleteSaga(action) {
  try {
    const result = yield call(
      occasionItineraryDeleteApi,
      action.payload.ocId,
      action.payload.scheduleId,
      action.payload.itineraryId,
      action.payload.schedulableType,
      action.payload.schedulableId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionItineraryDeleteSuccess(
          result.data,
          action.payload.ocId,
          action.payload.scheduleId,
          result.included,
          result.links
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionItineraryDeleteFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* itineraryActionWatcher() {
  yield takeLatest(
    OCCASION_ITINERARY_CREATE,
    progressSaga,
    occasionItineraryCreateSaga
  );
  yield takeLatest(
    OCCASION_ITINERARY_VIEW,
    progressSaga,
    occasionItineraryViewSaga
  );
  yield takeLatest(
    OCCASION_ITINERARY_UPDATE,
    progressSaga,
    occasionItineraryUpdateSaga
  );
  yield takeLatest(
    OCCASION_ITINERARY_DELETE,
    progressSaga,
    occasionItineraryDeleteSaga
  );
}
