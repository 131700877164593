import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import './styles.scss';
import { useHistory } from 'react-router-dom';

const BackButton = ({ path }) => {
  const history = useHistory();
  const changeRoute = () => {
    if (path) {
      history.replace(path);
    } else {
      history.goBack();
    }
  };

  return (
    <button className="backbutton-section" onClick={changeRoute}>
      <LeftOutlined className="backbutton" />
    </button>
  );
};
export default BackButton;
