import React from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import {
  ArrowRightOutlined,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import './styles.scss';
import { Button, Popconfirm, Tooltip } from 'antd';

function IdCell(props) {
  const history = useHistory();
  const showDeleteData = () => {
    if (typeof props.showDelete === 'function') {
      return props.showDelete(props.data);
    }
    return true;
  };

  if (!props.useLink)
    return (
      <>
        {props.data}{' '}
        <NavLink
          to={{
            pathname: `${props.pathname || history.location.pathname}/${
              props.data
            }`,
            state: { id: props.data }
          }}>
          {!!props.isRouted && <ArrowRightOutlined className="edit-icon" />}
        </NavLink>
        {typeof props?.isCreatable === 'function' && props.data && (
          <Tooltip title="Create">
            <Button
              className="edit-icon"
              onClick={() => props.isCreatable(props.data)}>
              Create
            </Button>
          </Tooltip>
        )}
        {typeof props?.isEditable === 'function' && props.data && (
          <Tooltip title="Edit">
            <Button
              className="edit-icon"
              onClick={() => props.isEditable(props.data)}>
              <EditOutlined />
            </Button>
          </Tooltip>
        )}
        {typeof props?.onDelete === 'function' && props.data && (
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this?"
              disabled={!showDeleteData()}
              onConfirm={() => props.onDelete(props.data)}
              okText="Yes"
              cancelText="No">
              <Button className="delete-icon" disabled={!showDeleteData()}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Tooltip>
        )}
        {typeof props?.customAction === 'function' &&
          props.customAction(props.data)}
      </>
    );
  return (
    <>
      {props.data}{' '}
      <Link
        target="_blank"
        to={{
          pathname: `${props.pathname || history.location.pathname}`,
          search: `id=${props.data}${
            props.occasionId ? '&ocId=' + props.occasionId : ''
          }`,
          state: { id: props.data }
        }}>
        {!!props.isRouted && <ArrowRightOutlined className="edit-icon" />}
      </Link>
      {typeof props?.isCreatable === 'function' && props.data && (
        <Tooltip title="Create">
          <Button
            className="edit-icon"
            onClick={() => props.isCreatable(props.data)}>
            Create
          </Button>
        </Tooltip>
      )}
      {typeof props?.isEditable === 'function' && props.data && (
        <Tooltip title="Edit">
          <Button
            className="edit-icon"
            onClick={() => props.isEditable(props.data)}>
            <EditOutlined />
          </Button>
        </Tooltip>
      )}
      {typeof props?.onDelete === 'function' && props.data && (
        <Tooltip title="Delete">
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => props.onDelete(props.data)}
            okText="Yes"
            disabled={!showDeleteData()}
            cancelText="No">
            <Button className="delete-icon" disabled={!showDeleteData()}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Tooltip>
      )}
      {typeof props?.customAction === 'function' &&
        props.customAction(props.data)}
    </>
  );
}
export default React.memo(IdCell);
