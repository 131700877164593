import { createSelector } from 'reselect';
import { userEntities } from './entity';
import {
  socianaUserAllIds,
  socianaUserId,
  socianaUserSearchIds
} from './static';
import { currentUserId } from './dynamicData';

export const getAllUser = createSelector(
  userEntities,
  socianaUserId,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getCompleteUserData = createSelector(
  userEntities,
  socianaUserAllIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getSearchedUserData = createSelector(
  userEntities,
  socianaUserSearchIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getCurrentUser = createSelector(
  socianaUserAllIds,
  currentUserId,
  userEntities,
  (allIds, userId, entities) =>
    userId && entities && allIds && allIds?.includes(userId) && entities[userId]
);
