import { createSelector } from 'reselect';
import { currentOccasionId, currentSponsorshipId } from './dynamicData';
import { sponsorshipEntities } from './entity';
import { sponsorshipIds } from './static';

export const currentOccasionSponsorshipIds = createSelector(
  currentOccasionId,
  sponsorshipIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getAllOccasionSponsorships = createSelector(
  sponsorshipEntities,
  currentOccasionSponsorshipIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const currentSponsorshipData = createSelector(
  currentSponsorshipId,
  sponsorshipEntities,
  (id, entities) => id && entities && entities[id]
);
