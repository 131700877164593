import React from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Button, Progress } from 'antd';
import {
  createTimeTrigger,
  updateTimeTrigger
} from '../../../../../../actions/trigger';
import moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup/index';
import { getAllOccasionGroups } from '../../../../../../selectors/group';
import { getImageSource } from '../../../../../../helpers/imageHelper';
import { Image } from '../../../../../../components/Image';
import { occasionGroupView } from '../../../../../../actions/group';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { currentTriggerId } from '../../../../../../selectors/dynamicData';
import { currentTimeTrigger } from '../../../../../../selectors/trigger';
import { getProcessedVideo } from '../../../../../../helpers/videoHelper';

const validateTimeData = (values, id) => {
  console.log('data', values);
  const errors = {};
  if (!values.title) {
    errors.title = 'Title is required';
  }
  if (!values.message) {
    errors.message = 'Message is required';
  }
  if (!values.date) {
    errors.date = 'Date is required (should be greater then present date)';
  }
  if (!values.time) {
    errors.time = 'Time is required';
  }
  if (values.group?.length === 0 && !id && values.sendToAll !== true) {
    errors.group = 'Group is required';
  }
  return errors;
};
const TimeTriggerCreate = (props) => {
  const [timeTriggerData, setTimeTriggerData] = React.useState({
    message: props?.initialValues?.message,
    time: props?.initialValues?.time,
    date: props?.initialValues?.date,
    title: props?.initialValues?.title,
    sendToAll: true,
    isPinned: false,
    group: [],
    time_trigger_media: props?.initialValues?.time_trigger_media || null
  });
  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);
  const [errorMessages, setErrorMessages] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [percent, setPercent] = React.useState(0);

  const updateProgress = (e) => {
    const percentVal = Math.round(e.ratio * 100);
    setPercent(percentVal);
  };
  const onPageChange = (page) => {
    props.occasionGroupView(props.occasionId, page);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleTimeTriggerCreate = async () => {
    try {
      const validateObj = validateTimeData(timeTriggerData, props.triggerId);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        const formData = new FormData();
        const dateTime =
          timeTriggerData?.date?.format('YYYY-MM-DD') +
          ' ' +
          timeTriggerData?.time?.format('HH:mm');
        if (
          timeTriggerData?.time_trigger_media &&
          timeTriggerData?.time_trigger_media[0]?.originFileObj
        ) {
          let file = timeTriggerData?.time_trigger_media[0];
          if (!!file?.originFileObj && file?.type?.indexOf('image') >= 0) {
            formData.append(
              'time_trigger[media_file]',
              timeTriggerData?.time_trigger_media[0]?.originFileObj
            );
          }
          if (!!file?.originFileObj && file?.type?.indexOf('video') >= 0) {
            setLoading(true);
            const videoFile = await getProcessedVideo(
              file?.originFileObj,
              updateProgress
            );

            formData.append('time_trigger[media_file]', videoFile);
          }
        }
        formData.append('time_trigger[title]', timeTriggerData?.title);
        formData.append('time_trigger[message]', timeTriggerData?.message);
        formData.append('time_trigger[trigger_date_time]', new Date(dateTime));
        formData.append(
          'time_trigger[send_to_all]',
          !!timeTriggerData?.sendToAll
        );
        formData.append(
          'time_trigger[is_pinned]',
          timeTriggerData.isPinned ? true : false
        );
        timeTriggerData?.group.forEach((id) => {
          formData.append('time_trigger[group_ids][]', id);
        });

        formData.append(
          'time_trigger[modelable_type]',
          props.eventId ? 'Event' : 'Occasion'
        );
        formData.append(
          'time_trigger[modelable_id]',
          props.eventId ? props.eventId : props.occasionId
        );
        props.triggerId
          ? props.updateTimeTrigger(
              props.occasionId,
              props.triggerId,
              formData,
              afterSuccess
            )
          : props.createTimeTrigger(props.occasionId, formData, afterSuccess);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const afterSuccess = () => {
    setTimeTriggerData({
      message: '',
      title: '',
      time: '',
      date: '',
      sendToAll: false,
      isPinned: false,
      group: [],
      time_trigger_media: null
    });
    props.handleCloseClick();
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'sendToAll') {
        value = e;
        name = type;
      }
      if (type === 'isPinned') {
        value = e;
        name = type;
      }
      if (isInitiallyDisabled) {
        setInitiallyDisabled(false);
      }
      if (type === 'time_trigger_media') {
        value = e.fileList;
        name = type;
      }
      if (type === 'date') {
        value = moment(e, 'DD/MM/YYYY');
        name = type;
      }
      if (type === 'time') {
        value = moment(e, 'HH:mm');
        name = type;
      }
      const timeTriggerValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setTimeTriggerData({
        ...timeTriggerData,
        [inputName]: timeTriggerValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="time-trigger-section">
        <div className="time-trigger-create">
          <Form
            className="time-trigger-form-container"
            onFinish={handleTimeTriggerCreate}>
            <InputGroup
              fieldType="file"
              label="Upload photo, gif or video"
              accept="image/*,video/*"
              fileList={timeTriggerData?.time_trigger_media}
              onChange={handleChange}
              name="time_trigger_media"
              errorMessage={errorMessages.time_trigger_media}
              multiple={false}
            />
            {loading && (
              <p className="compressing-video-message-container">
                <span className="compressing-video-message">
                  Compressing video
                </span>
                <Progress type="circle" percent={percent} width={30} />
              </p>
            )}
            <InputGroup
              label="Title"
              placeholder="Enter Title"
              name="title"
              onChange={handleChange}
              value={timeTriggerData?.title}
              errorMessage={errorMessages?.title}
            />
            <InputGroup
              label="Message"
              placeholder="Enter Message"
              name="message"
              fieldType="textArea"
              onChange={handleChange}
              value={timeTriggerData?.message}
              errorMessage={errorMessages?.message}
            />
            <div style={{ display: 'flex' }}>
              <InputGroup
                label="Date"
                placeholder="Enter Date"
                name="date"
                fieldType="datePicker"
                onChange={handleChange}
                value={timeTriggerData?.date}
                errorMessage={errorMessages?.date}
              />
              <InputGroup
                label="Time"
                placeholder="Enter Time"
                name="time"
                fieldType="timePicker"
                format="HH:mm"
                onChange={handleChange}
                value={timeTriggerData?.time}
                errorMessage={errorMessages?.time}
              />
            </div>
            {!props.triggerId && (
              <InputGroup
                label="Send to All"
                name="sendToAll"
                fieldType="switch"
                onChange={handleChange}
                value={timeTriggerData?.sendToAll}
              />
            )}

            {!props.triggerId && (
              <InputGroup
                label="Is Pinnned"
                name="isPinned"
                fieldType="switch"
                onChange={handleChange}
                value={timeTriggerData?.isPinned}
              />
            )}

            {!props.triggerId && !timeTriggerData?.sendToAll && (
              <InputGroup
                fieldType="dropdown"
                className="group-search"
                mode="multiple"
                name="group"
                label="Select Group"
                placeholder="Select Group"
                onChange={handleChange}
                errorMessage={errorMessages?.group}
                value={timeTriggerData?.group}>
                {props.groupData?.length > 0 &&
                  props.groupData.map((item) => {
                    return (
                      <Option value={item?.id} key={item?.id}>
                        <div className="options-container">
                          <p className="avatar-image-container">
                            <Image
                              src={getImageSource(item?.groupPictureUrl)}
                              preview={false}
                            />
                          </p>
                          <div className="occasion-group-details-container">
                            <p className="occasion-group-name">{item?.name}</p>
                          </div>
                        </div>
                      </Option>
                    );
                  })}
              </InputGroup>
            )}
            <SubmitButton
              buttonText={props.triggerId ? 'Save Changes' : 'Create'}
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => {
  const currentTimeTriggerId = currentTriggerId(store, props);
  let timeTrigger;
  if (currentTimeTriggerId) {
    timeTrigger = { ...currentTimeTrigger(store, props) };
  }

  const initialValues = {
    time_trigger_media: timeTrigger?.time_trigger_media,
    title: timeTrigger?.title || '',
    message: timeTrigger?.message || '',
    sendToAll: !!timeTrigger?.sendToAll,
    date: timeTrigger?.triggerDateTime
      ? moment.utc(timeTrigger?.triggerDateTime)
      : null,
    time: timeTrigger?.triggerDateTime
      ? moment.utc(timeTrigger?.triggerDateTime)
      : null
  };

  return {
    currentTimeTriggerId,
    initialValues,
    groupData: getAllOccasionGroups(store, props),
    fetchProgress: store.browserState.fetchProgress
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { createTimeTrigger, updateTimeTrigger, occasionGroupView },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeTriggerCreate);
