import { put, call, takeLatest, debounce } from 'redux-saga/effects';
import progressSaga from './progressSaga';

import { GET_ENQUIRY_PAGINATION } from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import { getEnquiryWithPaginationApi } from '../api/enquiry';
import {
  getEnquiryWithPaginationFailed,
  getEnquiryWithPaginationSuccess
} from '../actions/enquiry';

export function* getEnquirySagaWithPagination(action) {
  try {
    const result = yield call(
      getEnquiryWithPaginationApi,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getEnquiryWithPaginationSuccess(
          result.data,
          result.links,
          action.payload.page,
          result?.meta
        )
      );

      if (action.callback) {
        action.callback();
      }
    } else if (result?.meta && result?.meta?.message) {
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(getEnquiryWithPaginationFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* enquiryActionWatcher() {
  yield takeLatest(
    GET_ENQUIRY_PAGINATION,
    progressSaga,
    getEnquirySagaWithPagination
  );
}
