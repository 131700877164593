import request from './request';

export function getOccasionSettings(token) {
  return request('api/v2/sociana_admin/occasion_settings', {
    method: 'GET',
    token
  });
}

export function getOccasionWithPagination(page, token) {
  return request(`api/v2/sociana_admin/oc?page=${page}`, {
    method: 'GET',
    token
  });
}

export function createNewOccasion(formData, token) {
  return request('api/v2/sociana_admin/oc', {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function updateOccasion(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function getOccasionByFieldApi(
  ocName,
  startDate,
  endDate,
  guestType,
  ocType,
  ocCode,
  page,
  token
) {
  let query = `page=${page}`;
  if (!!ocName) {
    query += `&name=${ocName}`;
  }
  if (!!startDate) {
    query += `&start_at=${startDate}`;
  }
  if (!!endDate) {
    query += `&end_at=${endDate}`;
  }
  if (!!guestType) {
    query += `&guest_type=${guestType}`;
  }
  if (!!ocType) {
    query += `&type=${ocType}`;
  }
  if (!!ocCode) {
    query += `&occasion_code=${ocCode}`;
  }
  return request(`api/v2/sociana_admin/search_occasion_by_field?${query}`, {
    method: 'GET',
    token
  });
}

export function updateOccasionCodeApi(ocId, occasionCodeId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_codes/${occasionCodeId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function updateOccasionFontApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/update_occasion_font`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}
