import {
  CREATE_SOCIANA_INTERNAL_USER_SUCCESS,
  CREATE_SOCIANA_INTERNAL_USER_FAILED,
  GET_SOCIANA_INTERNAL_USER_PAGINATION_FAILED,
  GET_SOCIANA_INTERNAL_USER_PAGINATION_SUCCESS,
  GET_INTERNAL_USERS_PARTICIPATED_OCCASIONS_SUCCESS,
  GET_INTERNAL_USERS_PARTICIPATED_OCCASIONS_FAILED,
  SEARCH_INTERNAL_TEAM_USERS_SUCCESS,
  EDIT_INTERNAL_USER_ACCESS_SPECIFICATIONS_FAILED,
  EDIT_INTERNAL_USER_ACCESS_SPECIFICATIONS_SUCCESS,
  GET_INTERNAL_TEAM_PENDING_INVITATION_SUCCESS,
  GET_INTERNAL_TEAM_BY_SEARCH_SUCCESS,
  DELETE_INTERNAL_USERS_PARTICIPATED_OCCASIONS_SUCCESS,
  DELETE_INTERNAL_USERS_PARTICIPATED_OCCASIONS_FAILED
} from '../constants/actionTypes';
import { union } from '../helpers/extraHelpers';

const initialState = {
  allIds: null,
  activeIds: null,
  inactiveIds: null,
  participatedOccasions: null,
  participatedOccasionsLinks: null,
  error: null,
  links: null,
  searchIds: null,
  pendingInvitationIds: null,
  pendingInvitationLinks: null,
  participatedOccasionsCount: 0,
  count: 0
};

const socianaInternalUser = (state = initialState, action) => {
  switch (action.type) {
    case GET_INTERNAL_USERS_PARTICIPATED_OCCASIONS_FAILED:
    case GET_SOCIANA_INTERNAL_USER_PAGINATION_FAILED:
    case EDIT_INTERNAL_USER_ACCESS_SPECIFICATIONS_FAILED:
    case CREATE_SOCIANA_INTERNAL_USER_FAILED:
    case DELETE_INTERNAL_USERS_PARTICIPATED_OCCASIONS_FAILED: {
      return { ...state, error: action.error };
    }

    case SEARCH_INTERNAL_TEAM_USERS_SUCCESS: {
      const { data } = action.payload;
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];
      return { ...state, searchIds: newIds };
    }

    case EDIT_INTERNAL_USER_ACCESS_SPECIFICATIONS_SUCCESS:
    case CREATE_SOCIANA_INTERNAL_USER_SUCCESS: {
      return {
        ...state
      };
    }

    case GET_SOCIANA_INTERNAL_USER_PAGINATION_SUCCESS:
    case GET_INTERNAL_TEAM_BY_SEARCH_SUCCESS: {
      const { data, links, isActive, meta } = action.payload;
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];
      const stateData = isActive ? state.activeIds : state.inactiveIds;
      let allIds = union(newIds, stateData || []);
      return {
        ...state,
        activeIds: isActive ? newIds : state.activeIds,
        inactiveIds: !isActive ? newIds : state.inactiveIds,
        links,
        allIds,
        count: meta?.totalCount || 0
      };
    }

    case GET_INTERNAL_USERS_PARTICIPATED_OCCASIONS_SUCCESS: {
      const { data, links, userId, meta } = action.payload;
      const prevData = state.participatedOccasions || {};
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];
      return {
        ...state,
        participatedOccasions: { [userId]: [...newIds] },
        participatedOccasionsLinks: links,
        participatedOccasionsCount: meta?.totalCount || 0
      };
    }

    case DELETE_INTERNAL_USERS_PARTICIPATED_OCCASIONS_SUCCESS: {
      const { data, userId } = action.payload;
      const prevData = state.participatedOccasions || {};
      const prevInternalTeamOccasionData = prevData[userId] || [];
      const filterIds = prevInternalTeamOccasionData.filter(
        (id) => id !== data.id
      );
      return {
        ...state,
        participatedOccasions: { [userId]: filterIds }
      };
    }

    case GET_INTERNAL_TEAM_PENDING_INVITATION_SUCCESS: {
      const { data, links, userId, status } = action.payload;
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];
      const prevUserData =
        (state.pendingInvitationIds && state.pendingInvitationIds[userId]) ||
        {};
      const prevLinks = state.pendingInvitationLinks || {};
      return {
        ...state,
        pendingInvitationIds: {
          [userId]: { ...prevUserData, [status]: newIds }
        },
        pendingInvitationLinks: {
          ...prevLinks,
          [status]: links
        }
      };
    }

    default:
      return state;
  }
};

export default socianaInternalUser;
