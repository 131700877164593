import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../../components/Section';
import ErrorBoundary from '../../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../../components/Table';
import { Button } from 'antd';
import { getSelfieUserMedia } from '../../../../../../../actions/faceInfo';
import { getSelfieUserMediaWithUserData } from '../../../../../../../selectors/faceInfo';

const ViewSelfieUserMedia = (props) => {
  const [page, setPage] = React.useState(1);

  const onPageChange = (pageNumber) => {
    setPage(pageNumber);
    props.getSelfieUserMedia(props.occasionId, props.userId, pageNumber);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const paginationOptions = {
    showTotal: () => (
      <div className="selfie-user-media-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <div className="count-box">
          <span>Total Selfie</span>
          <span className="count">{props.count}</span>
        </div>
      </div>
    )
  };

  const getData = () => {
    if (props.selfieUserData?.length > 0) {
      return props?.selfieUserData?.map((item) => {
        return {
          id: item?.id,
          fileUrl: item?.thumbUrl ? item?.thumbUrl : item?.fileUrl,
          name: item?.name,
          createdAt: item?.createdAt,
          meta: item?.meta,
          kind: item?.kind,
          createdBy: item?.createdBy
        };
      });
    } else {
      return [];
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="selfie-user-media-section">
        <div className="selfie-user-media-container">
          <TableView
            isPaginated={true}
            paginationOptions={paginationOptions}
            isRouted={false}
            data={getData()}
            onPageChange={onPageChange}
            fetchProgress={props?.fetchProgress}
            paginationLinks={props?.selfieUserLinks}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  selfieUserData: getSelfieUserMediaWithUserData(store, props),
  selfieUserLinks: store.faceInfo.selfieUserMediaLinks,
  count: store.faceInfo.selfieUserMediaCount
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ getSelfieUserMedia }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(ViewSelfieUserMedia);
