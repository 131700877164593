import React, { useState } from 'react';
import { Tooltip, Typography } from 'antd';
const { Paragraph } = Typography;

export const DataCell = ({ children, ellipsis, ...props }) => {
  const [truncated, setTruncated] = useState(false);

  return (
    <Tooltip
      title={truncated ? children : undefined}
      overlayClassName="data-cell-tooltip"
      placement="right">
      <Paragraph
        {...props}
        ellipsis={{ ...ellipsis, onEllipsis: setTruncated }}>
        <>{children}</>
      </Paragraph>
    </Tooltip>
  );
};
