import { createSelector } from 'reselect';
import { currentOccasionId } from './dynamicData';
import { hospitalityGoogleSheetEntities } from './entity';
import { hospitalityGoogleSheetIds } from './static';

export const currentHospitalityGoogleSheetIds = createSelector(
  currentOccasionId,
  hospitalityGoogleSheetIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getHospitalityGoogleSheetData = createSelector(
  hospitalityGoogleSheetEntities,
  currentHospitalityGoogleSheetIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
