import React from 'react';
import LiveStreamRoute from '../routes/LiveStreamRoute';
import './styles.scss';

const LiveStream = () => {
  return (
    <div className="live-video-container">
      <LiveStreamRoute />
    </div>
  );
};

export default LiveStream;
