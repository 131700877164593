import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  getOccasionAppDetailsApi,
  occasionAppCreateApi,
  updateOccasionAppApi
} from '../api/occasionApp';
import {
  GET_OCCASION_APP_DETAILS,
  OCCASION_APP_CREATE,
  UPDATE_OCCASION_APP
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  getOccasionAppDetailsFailed,
  getOccasionAppDetailsSuccess,
  occasionAppCreateFailed,
  occasionAppCreateSuccess,
  updateOccasionAppFailed,
  updateOccasionAppSuccess
} from '../actions/occasionApp';

export function* getOccasionAppDetailsSaga(action) {
  try {
    const result = yield call(
      getOccasionAppDetailsApi,
      action.payload.ocId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getOccasionAppDetailsSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getOccasionAppDetailsFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionAppCreateSaga(action) {
  try {
    const result = yield call(
      occasionAppCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionAppCreateSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionAppCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionAppCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionAppUpdateSaga(action) {
  try {
    const result = yield call(
      updateOccasionAppApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateOccasionAppSuccess(
          action.payload.ocId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateOccasionAppFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateOccasionAppFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* occasionAppActionWatcher() {
  yield takeLatest(
    GET_OCCASION_APP_DETAILS,
    progressSaga,
    getOccasionAppDetailsSaga
  );
  yield takeLatest(OCCASION_APP_CREATE, progressSaga, occasionAppCreateSaga);
  yield takeLatest(UPDATE_OCCASION_APP, progressSaga, occasionAppUpdateSaga);
}
