import {
  OCCASION_EVENT_CREATE,
  OCCASION_EVENT_CREATE_SUCCESS,
  OCCASION_EVENT_CREATE_FAILED,
  OCCASION_EVENT_VIEW,
  OCCASION_EVENT_VIEW_SUCCESS,
  OCCASION_EVENT_VIEW_FAILED,
  OCCASION_EVENT_UPDATE,
  OCCASION_EVENT_UPDATE_SUCCESS,
  OCCASION_EVENT_UPDATE_FAILED,
  GET_EVENT_SEARCH,
  GET_EVENT_SEARCH_SUCCESS,
  GET_EVENT_SEARCH_FAILED,
  DELETE_EVENT,
  DELETE_EVENT_FAILED,
  DELETE_EVENT_SUCCESS,
  MULTIPLE_EVENT_INVITATIONS,
  MULTIPLE_EVENT_INVITATIONS_SUCCESS,
  MULTIPLE_EVENT_INVITATIONS_FAILED,
  PARSE_CSV_EVENT,
  PARSE_CSV_EVENT_SUCCESS,
  PARSE_CSV_EVENT_FAILED,
  GET_EVENT_MEMBERS,
  GET_EVENT_MEMBERS_FAILED,
  GET_EVENT_MEMBERS_SUCCESS,
  GET_EVENT_PENDING_INVITATION,
  GET_EVENT_PENDING_INVITATION_SUCCESS,
  GET_EVENT_PENDING_INVITATION_FAILED,
  GET_EVENT_PENDING_REGISTRATION,
  GET_EVENT_PENDING_REGISTRATION_SUCCESS,
  GET_EVENT_PENDING_REGISTRATION_FAILED,
  EVENT_SCHEDULE_CREATE,
  EVENT_SCHEDULE_CREATE_FAILED,
  EVENT_SCHEDULE_CREATE_SUCCESS,
  EVENT_SCHEDULE_VIEW,
  EVENT_SCHEDULE_VIEW_FAILED,
  EVENT_SCHEDULE_VIEW_SUCCESS,
  EVENT_SCHEDULE_UPDATE,
  EVENT_SCHEDULE_UPDATE_FAILED,
  EVENT_SCHEDULE_UPDATE_SUCCESS,
  EVENT_SCHEDULE_DELETE,
  EVENT_SCHEDULE_DELETE_FAILED,
  EVENT_SCHEDULE_DELETE_SUCCESS,
  EVENT_AFFAIR_CREATE,
  EVENT_AFFAIR_CREATE_FAILED,
  EVENT_AFFAIR_CREATE_SUCCESS,
  EVENT_AFFAIR_VIEW,
  EVENT_AFFAIR_VIEW_FAILED,
  EVENT_AFFAIR_VIEW_SUCCESS,
  EVENT_AFFAIR_UPDATE,
  EVENT_AFFAIR_UPDATE_FAILED,
  EVENT_AFFAIR_UPDATE_SUCCESS,
  EVENT_AFFAIR_DELETE,
  EVENT_AFFAIR_DELETE_FAILED,
  EVENT_AFFAIR_DELETE_SUCCESS,
  CREATE_EVENT_POST,
  CREATE_EVENT_POST_SUCCESS,
  CREATE_EVENT_POST_FAILED,
  GET_EVENT_POST_PAGINATION,
  GET_EVENT_POST_PAGINATION_SUCCESS,
  GET_EVENT_POST_PAGINATION_FAILED,
  EDIT_EVENT_POST,
  EDIT_EVENT_POST_SUCCESS,
  EDIT_EVENT_POST_FAILED,
  DELETE_EVENT_POST,
  DELETE_EVENT_POST_SUCCESS,
  DELETE_EVENT_POST_FAILED
} from '../constants/actionTypes';

export const createEventPost = (ocId, eventId, formData, callback) => ({
  type: CREATE_EVENT_POST,
  payload: { ocId, eventId, formData },
  callback
});

export const createEventPostSuccess = (ocId, eventId, data, included) => ({
  type: CREATE_EVENT_POST_SUCCESS,
  payload: { ocId, eventId, data, included }
});

export const createEventPostFailed = (error) => ({
  type: CREATE_EVENT_POST_FAILED,
  error
});

export const getEventPostWithPagination = (
  ocId,
  eventId,
  pageNumber,
  callback
) => ({
  type: GET_EVENT_POST_PAGINATION,
  payload: { ocId, eventId, pageNumber },
  callback
});

export const getEventPostWithPaginationSuccess = (
  ocId,
  eventId,
  data,
  links,
  included
) => ({
  type: GET_EVENT_POST_PAGINATION_SUCCESS,
  payload: { ocId, eventId, data, links, included }
});

export const getEventPostWithPaginationFailed = (error) => ({
  type: GET_EVENT_POST_PAGINATION_FAILED,
  error
});

export const editEventPost = (
  occasionId,
  postableType,
  postId,
  formData,
  callback
) => ({
  type: EDIT_EVENT_POST,
  payload: { occasionId, postableType, postId, formData },
  callback
});

export const editEventPostSuccess = (occasionId, data, included) => ({
  type: EDIT_EVENT_POST_SUCCESS,
  payload: { occasionId, data, included }
});

export const editEventPostFailed = (error) => ({
  type: EDIT_EVENT_POST_FAILED,
  error
});

export const deleteEventPost = (
  ocId,
  eventId,
  postableType,
  postId,
  callback
) => ({
  type: DELETE_EVENT_POST,
  payload: { ocId, eventId, postableType, postId },
  callback
});

export const deleteEventPostSuccess = (
  ocId,
  eventId,
  postableType,
  data,
  included
) => ({
  type: DELETE_EVENT_POST_SUCCESS,
  payload: { ocId, eventId, postableType, data, included }
});

export const deleteEventPostFailed = (error) => ({
  type: DELETE_EVENT_POST_FAILED,
  error
});

export const occasionEventCreate = (ocId, formData, callback) => ({
  type: OCCASION_EVENT_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionEventCreateSuccess = (data, ocId) => ({
  type: OCCASION_EVENT_CREATE_SUCCESS,
  payload: { data, ocId }
});

export const occasionEventCreateFailed = (error) => ({
  type: OCCASION_EVENT_CREATE_FAILED,
  error
});

export const occasionEventView = (ocId, page) => ({
  type: OCCASION_EVENT_VIEW,
  payload: { ocId, page }
});

export const occasionEventViewSuccess = (data, ocId, links) => ({
  type: OCCASION_EVENT_VIEW_SUCCESS,
  payload: { data, links, ocId }
});

export const occasionEventViewFailed = (error) => ({
  type: OCCASION_EVENT_VIEW_FAILED,
  error
});

export const occasionEventUpdate = (ocId, eventId, formData, callback) => ({
  type: OCCASION_EVENT_UPDATE,
  payload: { ocId, eventId, formData },
  callback
});

export const occasionEventUpdateSuccess = (data) => ({
  type: OCCASION_EVENT_UPDATE_SUCCESS,
  payload: { data }
});

export const occasionEventUpdateFailed = (error) => ({
  type: OCCASION_EVENT_UPDATE_FAILED,
  error
});

export const getEventSearch = (
  ocId,
  title,
  startDate,
  endDate,
  guestType,
  tag,
  page
) => ({
  type: GET_EVENT_SEARCH,
  payload: { ocId, title, startDate, endDate, guestType, tag, page }
});

export const getEventSearchSuccess = (data, ocId, links) => ({
  type: GET_EVENT_SEARCH_SUCCESS,
  payload: { data, ocId, links }
});

export const getEventSearchFailed = (error) => ({
  type: GET_EVENT_SEARCH_FAILED,
  error
});

export const deleteEvent = (ocId, eventId, callback) => ({
  type: DELETE_EVENT,
  payload: { ocId, eventId },
  callback
});

export const deleteEventSuccess = (ocId, data) => ({
  type: DELETE_EVENT_SUCCESS,
  payload: { ocId, data }
});

export const deleteEventFailed = (error) => ({
  type: DELETE_EVENT_FAILED,
  error
});

export const multipleEventInvitations = (
  ocId,
  eventId,
  formData,
  callback
) => ({
  type: MULTIPLE_EVENT_INVITATIONS,
  payload: { ocId, eventId, formData },
  callback
});

export const multipleEventInvitationsSuccess = (eventId, data) => ({
  type: MULTIPLE_EVENT_INVITATIONS_SUCCESS,
  payload: { eventId, data }
});

export const multipleEventInvitationsFailed = (error) => ({
  type: MULTIPLE_EVENT_INVITATIONS_FAILED,
  error
});

export const parse_csvEvent = (ocId, eventId, formData, callback) => ({
  type: PARSE_CSV_EVENT,
  payload: { ocId, eventId, formData },
  callback
});

export const parse_csvEventSuccess = (eventId, data) => ({
  type: PARSE_CSV_EVENT_SUCCESS,
  payload: { eventId, data }
});

export const parse_csvEventFailed = (error) => ({
  type: PARSE_CSV_EVENT_FAILED,
  error
});

export const getEventMembers = (ocId, eventId, page) => ({
  type: GET_EVENT_MEMBERS,
  payload: { ocId, eventId, page }
});

export const getEventMembersSuccess = (eventId, data, links, included) => ({
  type: GET_EVENT_MEMBERS_SUCCESS,
  payload: { eventId, data, links, included }
});

export const getEventMembersFailed = (error) => ({
  type: GET_EVENT_MEMBERS_FAILED
});

export const getEventPendingInvitation = (ocId, eventId, page) => ({
  type: GET_EVENT_PENDING_INVITATION,
  payload: { ocId, eventId, page }
});

export const getEventPendingInvitationSuccess = (
  eventId,
  data,
  links,
  included
) => ({
  type: GET_EVENT_PENDING_INVITATION_SUCCESS,
  payload: { eventId, data, links, included }
});

export const getEventPendingInvitationFailed = (error) => ({
  type: GET_EVENT_PENDING_INVITATION_FAILED,
  error
});

export const getEventPendingRegistration = (ocId, eventId, page) => ({
  type: GET_EVENT_PENDING_REGISTRATION,
  payload: { ocId, eventId, page }
});

export const getEventPendingRegistrationSuccess = (
  eventId,
  data,
  links,
  included
) => ({
  type: GET_EVENT_PENDING_REGISTRATION_SUCCESS,
  payload: { eventId, data, links, included }
});

export const getEventPendingRegistrationFailed = (error) => ({
  type: GET_EVENT_PENDING_REGISTRATION_FAILED,
  error
});

export const eventScheduleCreate = (ocId, formData, eventId, callback) => ({
  type: EVENT_SCHEDULE_CREATE,
  payload: { ocId, formData, eventId },
  callback
});

export const eventScheduleCreateSuccess = (
  ocId,
  eventId,
  data,
  included,
  links
) => ({
  type: EVENT_SCHEDULE_CREATE_SUCCESS,
  payload: { ocId, eventId, data, included, links }
});

export const eventScheduleCreateFailed = (error) => ({
  type: EVENT_SCHEDULE_CREATE_FAILED,
  error
});

export const eventScheduleView = (ocId, scheduleType, page, eventId) => ({
  type: EVENT_SCHEDULE_VIEW,
  payload: { ocId, scheduleType, page, eventId }
});

export const eventScheduleViewSuccess = (
  ocId,
  eventId,
  data,
  included,
  links
) => ({
  type: EVENT_SCHEDULE_VIEW_SUCCESS,
  payload: { ocId, eventId, data, included, links }
});

export const eventScheduleViewFailed = (error) => ({
  type: EVENT_SCHEDULE_VIEW_FAILED,
  error
});

export const eventScheduleUpdate = (
  ocId,
  scheduleId,
  eventId,
  formData,
  callback
) => ({
  type: EVENT_SCHEDULE_UPDATE,
  payload: { ocId, scheduleId, eventId, formData },
  callback
});

export const eventScheduleUpdateSuccess = (
  ocId,
  eventId,
  data,
  included,
  links
) => ({
  type: EVENT_SCHEDULE_UPDATE_SUCCESS,
  payload: { ocId, eventId, data, included, links }
});

export const eventScheduleUpdateFailed = (error) => ({
  type: EVENT_SCHEDULE_UPDATE_FAILED,
  error
});

export const eventScheduleDelete = (ocId, scheduleId, eventId) => ({
  type: EVENT_SCHEDULE_DELETE,
  payload: { ocId, scheduleId, eventId }
});

export const eventScheduleDeleteSuccess = (
  ocId,
  eventId,
  data,
  included,
  links
) => ({
  type: EVENT_SCHEDULE_DELETE_SUCCESS,
  payload: { ocId, eventId, data, included, links }
});

export const eventScheduleDeleteFailed = (error) => ({
  type: EVENT_SCHEDULE_DELETE_FAILED,
  error
});

export const eventAffairCreate = (ocId, formData, eventId, callback) => ({
  type: EVENT_AFFAIR_CREATE,
  payload: { ocId, formData, eventId },
  callback
});

export const eventAffairCreateSuccess = (
  ocId,
  eventId,
  data,
  included,
  links
) => ({
  type: EVENT_AFFAIR_CREATE_SUCCESS,
  payload: { ocId, eventId, data, included, links }
});

export const eventAffairCreateFailed = (error) => ({
  type: EVENT_AFFAIR_CREATE_FAILED,
  error
});

export const eventAffairView = (ocId, page, eventId) => ({
  type: EVENT_AFFAIR_VIEW,
  payload: { ocId, page, eventId }
});

export const eventAffairViewSuccess = (
  ocId,
  eventId,
  data,
  included,
  links
) => ({
  type: EVENT_AFFAIR_VIEW_SUCCESS,
  payload: { ocId, eventId, data, included, links }
});

export const eventAffairViewFailed = (error) => ({
  type: EVENT_AFFAIR_VIEW_FAILED,
  error
});

export const eventAffairUpdate = (
  ocId,
  eventAffairId,
  eventId,
  formData,
  callback
) => ({
  type: EVENT_AFFAIR_UPDATE,
  payload: { ocId, eventAffairId, eventId, formData },
  callback
});

export const eventAffairUpdateSuccess = (
  ocId,
  eventId,
  data,
  included,
  links
) => ({
  type: EVENT_AFFAIR_UPDATE_SUCCESS,
  payload: { ocId, eventId, data, included, links }
});

export const eventAffairUpdateFailed = (error) => ({
  type: EVENT_AFFAIR_UPDATE_FAILED,
  error
});

export const eventAffairDelete = (ocId, eventAffairId, eventId) => ({
  type: EVENT_AFFAIR_DELETE,
  payload: { ocId, eventAffairId, eventId }
});

export const eventAffairDeleteSuccess = (
  ocId,
  eventId,
  data,
  included,
  links
) => ({
  type: EVENT_AFFAIR_DELETE_SUCCESS,
  payload: { ocId, eventId, data, included, links }
});

export const eventAffairDeleteFailed = (error) => ({
  type: EVENT_AFFAIR_DELETE_FAILED,
  error
});
