import {
  CREATE_SOCIANA_INTERNAL_USER,
  CREATE_SOCIANA_INTERNAL_USER_FAILED,
  CREATE_SOCIANA_INTERNAL_USER_SUCCESS,
  GET_SOCIANA_INTERNAL_USER_PAGINATION,
  GET_SOCIANA_INTERNAL_USER_PAGINATION_FAILED,
  GET_SOCIANA_INTERNAL_USER_PAGINATION_SUCCESS,
  EDIT_SOCIANA_INTERNAL_USER,
  EDIT_SOCIANA_INTERNAL_USER_SUCCESS,
  EDIT_SOCIANA_INTERNAL_USER_FAILED,
  GET_INTERNAL_USERS_PARTICIPATED_OCCASIONS,
  GET_INTERNAL_USERS_PARTICIPATED_OCCASIONS_SUCCESS,
  GET_INTERNAL_USERS_PARTICIPATED_OCCASIONS_FAILED,
  SEARCH_OCCASIONS,
  SEARCH_OCCASIONS_SUCCESS,
  SEARCH_OCCASIONS_FAILED,
  GET_CURRENT_OCCASION_INTERNAL_MEMBERS,
  GET_CURRENT_OCCASION_INTERNAL_MEMBERS_SUCCESS,
  GET_CURRENT_OCCASION_INTERNAL_MEMBERS_FAILED,
  ASSIGN_INTERNAL_TEAM_MEMBER,
  ASSIGN_INTERNAL_TEAM_MEMBER_FAILED,
  ASSIGN_INTERNAL_TEAM_MEMBER_SUCCESS,
  SEARCH_INTERNAL_TEAM_USERS,
  SEARCH_INTERNAL_TEAM_USERS_FAILED,
  SEARCH_INTERNAL_TEAM_USERS_SUCCESS,
  EDIT_INTERNAL_USER_ACCESS_SPECIFICATIONS,
  EDIT_INTERNAL_USER_ACCESS_SPECIFICATIONS_FAILED,
  EDIT_INTERNAL_USER_ACCESS_SPECIFICATIONS_SUCCESS,
  GET_INTERNAL_TEAM_PENDING_INVITATION,
  GET_INTERNAL_TEAM_PENDING_INVITATION_SUCCESS,
  GET_INTERNAL_TEAM_PENDING_INVITATION_FAILED,
  GET_INTERNAL_TEAM_BY_SEARCH,
  GET_INTERNAL_TEAM_BY_SEARCH_SUCCESS,
  GET_INTERNAL_TEAM_BY_SEARCH_FAILED,
  UPDATE_ASSIGN_INTERNAL_TEAM_MEMBER,
  UPDATE_ASSIGN_INTERNAL_TEAM_MEMBER_FAILED,
  UPDATE_ASSIGN_INTERNAL_TEAM_MEMBER_SUCCESS,
  DELETE_INTERNAL_USERS_PARTICIPATED_OCCASIONS,
  DELETE_INTERNAL_USERS_PARTICIPATED_OCCASIONS_FAILED,
  DELETE_INTERNAL_USERS_PARTICIPATED_OCCASIONS_SUCCESS
} from '../constants/actionTypes';

export const createSocianaInternalUser = (formData, callback) => ({
  type: CREATE_SOCIANA_INTERNAL_USER,
  payload: { formData },
  callback
});

export const createSocianaInternalUserSuccess = (data, included) => ({
  type: CREATE_SOCIANA_INTERNAL_USER_SUCCESS,
  payload: { data, included }
});

export const createSocianaInternalUserFailed = (error) => ({
  type: CREATE_SOCIANA_INTERNAL_USER_FAILED,
  error
});

export const getSocianaInternalUserWithPagination = (page, isActive) => ({
  type: GET_SOCIANA_INTERNAL_USER_PAGINATION,
  payload: { page, isActive }
});

export const getSocianaInternalUserWithPaginationSuccess = (
  data,
  included,
  links,
  isActive,
  meta
) => ({
  type: GET_SOCIANA_INTERNAL_USER_PAGINATION_SUCCESS,
  payload: { data, links, isActive, included, meta }
});

export const getSocianaInternalUserWithPaginationFailed = (
  error,
  isActive
) => ({
  type: GET_SOCIANA_INTERNAL_USER_PAGINATION_FAILED,
  payload: { isActive },
  error
});

export const editSocianaInternalUser = (formData, userId, callback) => ({
  type: EDIT_SOCIANA_INTERNAL_USER,
  payload: { formData, userId },
  callback
});

export const editSocianaInternalUserSuccess = (data) => ({
  type: EDIT_SOCIANA_INTERNAL_USER_SUCCESS,
  payload: { data }
});

export const editSocianaInternalUserFailed = (error) => ({
  type: EDIT_SOCIANA_INTERNAL_USER_FAILED,
  error
});

export const getInternalUsersParticipatedOccasions = (userId, page) => ({
  type: GET_INTERNAL_USERS_PARTICIPATED_OCCASIONS,
  payload: { userId, page }
});

export const getInternalUsersParticipatedOccasionsSuccess = (
  data,
  included,
  links,
  userId,
  meta
) => ({
  type: GET_INTERNAL_USERS_PARTICIPATED_OCCASIONS_SUCCESS,
  payload: { data, included, links, userId, meta }
});

export const getInternalUsersParticipatedOccasionsFailed = (error) => ({
  type: GET_INTERNAL_USERS_PARTICIPATED_OCCASIONS_FAILED,
  error
});

export const searchInternalUsersParticipatedOccasions = (query) => ({
  type: SEARCH_OCCASIONS,
  payload: { query }
});

export const searchInternalUsersParticipatedOccasionsSuccess = (
  data,
  included,
  links
) => ({
  type: SEARCH_OCCASIONS_SUCCESS,
  payload: { data, included, links }
});

export const searchInternalUsersParticipatedOccasionsFailed = (error) => ({
  type: SEARCH_OCCASIONS_FAILED,
  error
});

export const assignInternalTeamMember = (ocId, formData, callback) => ({
  type: ASSIGN_INTERNAL_TEAM_MEMBER,
  payload: { ocId, formData },
  callback
});

export const assignInternalTeamMemberSuccess = (data, included, ocId) => ({
  type: ASSIGN_INTERNAL_TEAM_MEMBER_SUCCESS,
  payload: { data, included, ocId }
});

export const assignInternalTeamMemberFailed = (error) => ({
  type: ASSIGN_INTERNAL_TEAM_MEMBER_FAILED,
  error
});

export const getOccasionInternalTeamMember = (page, ocId) => ({
  type: GET_CURRENT_OCCASION_INTERNAL_MEMBERS,
  payload: { page, ocId }
});

export const getOccasionInternalTeamMemberSuccess = (
  occasionId,
  data,
  included,
  links
) => ({
  type: GET_CURRENT_OCCASION_INTERNAL_MEMBERS_SUCCESS,
  payload: { data, included, links, occasionId }
});

export const getOccasionInternalTeamMemberFailed = (error) => ({
  type: GET_CURRENT_OCCASION_INTERNAL_MEMBERS_FAILED,
  error
});

export const searchInternalTeamMember = (query) => ({
  type: SEARCH_INTERNAL_TEAM_USERS,
  payload: { query }
});

export const searchInternalTeamMemberSuccess = (data, included) => ({
  type: SEARCH_INTERNAL_TEAM_USERS_SUCCESS,
  payload: { data, included }
});

export const searchInternalTeamMemberFailed = (error) => ({
  type: SEARCH_INTERNAL_TEAM_USERS_FAILED,
  error
});

export const editInternalUserAccessSpecifications = (
  ocId,
  userId,
  formData,
  updateOccasion = false,
  collaboratorType,
  entityId,
  entityType,
  callback
) => ({
  type: EDIT_INTERNAL_USER_ACCESS_SPECIFICATIONS,
  payload: {
    ocId,
    userId,
    formData,
    updateOccasion,
    collaboratorType,
    entityId,
    entityType
  },
  callback
});

export const editInternalUserAccessSpecificationsSuccess = (
  data,
  included
) => ({
  type: EDIT_INTERNAL_USER_ACCESS_SPECIFICATIONS_SUCCESS,
  payload: { data, included }
});

export const editInternalUserAccessSpecificationsFailed = (error) => ({
  type: EDIT_INTERNAL_USER_ACCESS_SPECIFICATIONS_FAILED,
  error
});

export const getInternalTeamPendingInvitation = (page, userId, status) => ({
  type: GET_INTERNAL_TEAM_PENDING_INVITATION,
  payload: { page, userId, status }
});

export const getInternalTeamPendingInvitationSuccess = (
  data,
  included,
  links,
  userId,
  status
) => ({
  type: GET_INTERNAL_TEAM_PENDING_INVITATION_SUCCESS,
  payload: { data, included, links, userId, status }
});

export const getInternalTeamPendingInvitationFailed = (error) => ({
  type: GET_INTERNAL_TEAM_PENDING_INVITATION_FAILED,
  error
});

export const getInternalTeamBySearch = (
  searchData,
  fromInternalTeam,
  status,
  page
) => ({
  type: GET_INTERNAL_TEAM_BY_SEARCH,
  payload: { searchData, fromInternalTeam, status, page }
});

export const getInternalTeamBySearchSuccess = (
  data,
  included,
  isActive,
  links,
  meta
) => ({
  type: GET_INTERNAL_TEAM_BY_SEARCH_SUCCESS,
  payload: { data, included, isActive, links, meta }
});

export const getInternalTeamBySearchFailed = (error) => ({
  type: GET_INTERNAL_TEAM_BY_SEARCH_FAILED,
  error
});

export const updateAssignInternalTeamMember = (
  ocId,
  userId,
  formData,
  type,
  callback
) => ({
  type: UPDATE_ASSIGN_INTERNAL_TEAM_MEMBER,
  payload: { ocId, userId, formData, type },
  callback
});

export const updateAssignInternalTeamMemberSuccess = (
  userId,
  data,
  included
) => ({
  type: UPDATE_ASSIGN_INTERNAL_TEAM_MEMBER_SUCCESS,
  payload: { userId, data, included }
});

export const updateAssignInternalTeamMemberFailed = (error) => ({
  type: UPDATE_ASSIGN_INTERNAL_TEAM_MEMBER_FAILED,
  error
});

export const deleteInternalUsersParticipatedOccasions = (ocId, userId) => ({
  type: DELETE_INTERNAL_USERS_PARTICIPATED_OCCASIONS,
  payload: { ocId, userId }
});

export const deleteInternalUsersParticipatedOccasionsSuccess = (
  data,
  included,
  links,
  userId
) => ({
  type: DELETE_INTERNAL_USERS_PARTICIPATED_OCCASIONS_SUCCESS,
  payload: { data, included, links, userId }
});

export const deleteInternalUsersParticipatedOccasionsFailed = (error) => ({
  type: DELETE_INTERNAL_USERS_PARTICIPATED_OCCASIONS_FAILED,
  error
});
