import { union } from 'lodash';
import {
  FACE_INFO_MEDIA_VIEW_FAILED,
  FACE_INFO_MEDIA_VIEW_SUCCESS,
  FACE_INFO_USER_VIEW_FAILED,
  FACE_INFO_USER_VIEW_SUCCESS,
  GET_SELFIE_USER_MEDIA_FAILED,
  GET_SELFIE_USER_MEDIA_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  faceInfoMediaData: null,
  faceInfoMediaLinks: null,
  faceInfoUserData: null,
  faceInfoUserLinks: null,
  selfieUserMediaData: null,
  selfieUserMediaLinks: null,
  faceInfoUserCount: 0,
  faceInfoMediaCount: 0,
  selfieUserMediaCount: 0,
  error: null
};

const faceInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_SELFIE_USER_MEDIA_SUCCESS: {
      const { data, userId, links, meta } = action.payload;
      const occasionGroupedData = state.selfieUserMediaData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        selfieUserMediaData: {
          ...occasionGroupedData,
          [userId]: [...currentOccasionNewIds]
        },
        selfieUserMediaLinks: links,
        selfieUserMediaCount: meta?.totalCount || 0
      };
    }

    case FACE_INFO_MEDIA_VIEW_SUCCESS: {
      const { data, ocId, links, meta } = action.payload;
      const occasionGroupedData = state.faceInfoMediaData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        faceInfoMediaData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        faceInfoMediaLinks: links,
        faceInfoMediaCount: meta?.totalCount || 0
      };
    }

    case FACE_INFO_USER_VIEW_SUCCESS: {
      const { data, ocId, links, meta } = action.payload;
      const occasionGroupedData = state.faceInfoUserData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        faceInfoUserData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        faceInfoUserLinks: links,
        faceInfoUserCount: meta?.totalCount || 0
      };
    }

    case GET_SELFIE_USER_MEDIA_FAILED:
    case FACE_INFO_MEDIA_VIEW_FAILED:
    case FACE_INFO_USER_VIEW_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default faceInfo;
