import React, { forwardRef, useState } from 'react';
import {
  DatePicker as AntdDatePicker,
  Input as AntdInput,
  Radio,
  Switch as AntdSwitch,
  Upload,
  Button,
  Form,
  TimePicker as AntdTimePicker,
  Select,
  AutoComplete,
  Slider as AntdSlider,
  Col,
  InputNumber,
  Row
} from 'antd';
import {
  LoadingOutlined,
  UploadOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  CloseOutlined,
  DeleteOutlined,
  CheckOutlined
} from '@ant-design/icons';
import './styles.scss';
import Map from '../Map';

const { TextArea: AntdTextArea } = AntdInput;
const { RangePicker: AntdRangePicker } = AntdDatePicker;

export const RangePicker = ({
  timeFormat = 'HH:mm',
  format = 'DD-MM-YYYY HH:mm',
  onChange = () => {},
  name,
  startDate,
  endDate,
  ...otherProps
}) => {
  const disabledDate = (current) => {
    if (startDate && endDate) {
      return current && (current < startDate || current > endDate.endOf('day'));
    }
  };
  return (
    <AntdRangePicker
      allowClear={false}
      getPopupContainer={(trigger) => trigger.parentElement}
      panelRender={(node) => <div className="range-picker-panel">{node}</div>}
      showTime={{ format: timeFormat }}
      format={format}
      disabledDate={disabledDate}
      popupStyle={{ width: '100%' }}
      onChange={(moment, e) => onChange(e, name, moment)}
      {...otherProps}
    />
  );
};
export const DatePicker = ({
  format = 'DD/MM/YYYY',
  name,
  type = 'date',
  onChange = () => {},
  ...otherProps
}) => {
  return (
    <AntdDatePicker
      allowClear={false}
      getPopupContainer={(trigger) => trigger.parentElement}
      format={format}
      onChange={(moment, e) => onChange(e, name, moment)}
      name={name}
      picker={type}
      {...otherProps}
    />
  );
};

export const TimePicker = ({
  format = 'h:mm a',
  name,
  onChange = () => {},
  ...otherProps
}) => {
  return (
    <AntdTimePicker
      allowClear={false}
      getPopupContainer={(trigger) => trigger.parentElement}
      format={format}
      onChange={(moment, e) => onChange(e, name, moment)}
      {...otherProps}
    />
  );
};

export const Input = forwardRef(
  (
    {
      name,
      className = '',
      placeholder = '',
      onChange = () => {},
      value = '',
      type = 'text',
      ...otherProps
    },
    ref
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => {
      setIsPasswordVisible((prevIsPasswordVisible) => !prevIsPasswordVisible);
    };
    return (
      <>
        <AntdInput
          ref={ref}
          name={name}
          type={isPasswordVisible ? 'text' : type}
          className={`input-box ${className}`}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          {...otherProps}
        />
        {type === 'password' && (
          <span className="password-eye-container">
            {isPasswordVisible ? (
              <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
            ) : (
              <EyeOutlined onClick={togglePasswordVisibility} />
            )}
          </span>
        )}
      </>
    );
  }
);

export const TextArea = ({
  name,
  className = '',
  placeholder = '',
  onChange = () => {},
  value = '',
  type = 'text',
  rows = 4,
  ...otherProps
}) => {
  return (
    <AntdTextArea
      name={name}
      rows={rows}
      type={type}
      className={`textarea-input-box ${className}`}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      {...otherProps}
    />
  );
};

export const RadioGroup = ({
  options,
  name,
  onChange = () => {},
  optionType = 'button',
  buttonStyle = 'solid',
  value = '',
  ...otherProps
}) => {
  return (
    <Radio.Group
      options={options}
      name={name}
      onChange={onChange}
      optionType={optionType}
      buttonStyle={buttonStyle}
      value={value}
      {...otherProps}
    />
  );
};

export const DropDownList = ({
  options,
  name,
  onChange = () => {},
  value = '',
  mode = null,
  ...otherProps
}) => {
  return (
    <Select
      mode={mode}
      options={options}
      name={name}
      onChange={(e) => onChange({ target: { value: e, name } })}
      value={value}
      {...otherProps}
    />
  );
};

export const Switch = ({
  name,
  onChange = () => {},
  defaultChecked,
  value,
  noChildren = false,
  ...otherProps
}) => {
  return (
    <AntdSwitch
      defaultChecked={defaultChecked}
      name={name}
      onChange={(e) => onChange(e, name)}
      checkedChildren={
        !noChildren && <CheckOutlined className="ant-switch-checked-child" />
      }
      unCheckedChildren={
        !noChildren && <CloseOutlined className="ant-switch-unchecked-child" />
      }
      checked={value}
      {...otherProps}
    />
  );
};

export const Search = ({
  options,
  name,
  onChange = () => {},
  value = '',
  ...otherProps
}) => {
  return (
    <AutoComplete
      options={options}
      name={name}
      menuItemSelectedIcon={
        <CheckOutlined style={{ fontSize: '1.5rem', margin: 10 }} />
      }
      onChange={(e) => onChange({ target: { value: e, name } })}
      value={value}
      {...otherProps}
    />
  );
};

export const FileUpload = ({
  action = null,
  maxCount = 1,
  listType = 'picture-card',
  accept = '',
  fileList = [],
  onChange = () => {},
  name,
  noMaxCount = false,
  ...otherProps
}) => {
  return (
    <Upload
      action={action}
      maxCount={noMaxCount ? null : maxCount}
      listType={listType}
      accept={accept}
      showUploadList={{
        removeIcon: (file) =>
          typeof file.remove === 'boolean' ? <></> : <DeleteOutlined />
      }}
      onRemove={(file) => typeof file.remove !== 'boolean'}
      fileList={fileList}
      onChange={(e) => onChange(e, name)}
      {...otherProps}>
      <Button className="upload-button" type="primary" htmlType="button">
        <UploadOutlined />
        Upload
      </Button>
    </Upload>
  );
};

export const SubmitButton = ({
  disabled = false,
  fetchProgress = false,
  className = '',
  type = 'primary',
  htmlType = 'submit',
  buttonText = 'Submit',
  wrapperClass = ''
}) => {
  return (
    <Form.Item className={`form-item ${wrapperClass}`}>
      <Button
        disabled={disabled}
        className={`submit-button ${className}`}
        type={type}
        htmlType={htmlType}>
        {!fetchProgress ? buttonText : <LoadingOutlined spin />}
      </Button>
    </Form.Item>
  );
};

export const FormMap = ({
  label = 'Location',
  errorMessage,
  setVenue = () => {},
  onChange = () => {},
  className = '',
  ...otherProps
}) => {
  return (
    <Form.Item className="form-item" label={label}>
      {!!errorMessage && <p className="error-message-style">{errorMessage}</p>}
      <Map
        className={`map-container ${className}`}
        setVenue={setVenue}
        onChange={onChange}
        {...otherProps}
      />
    </Form.Item>
  );
};

export const Slider = ({
  min = 0,
  max = 100,
  onChange = () => {},
  className = '',
  value = '',
  name = '',
  ...otherProps
}) => {
  return (
    <Row>
      <AntdSlider
        min={min}
        max={max}
        onChange={(e) => onChange(e, name)}
        style={{ width: '70%' }}
        value={value}
      />
      <InputNumber
        min={min}
        max={max}
        style={{ margin: '0 16px', width: '20%' }}
        value={value}
        onChange={(e) => onChange(e, name)}
      />
    </Row>
  );
};

export const ColorPicker = ({
  name,
  className = '',
  onChange = () => {},
  value = '',
  type = 'color',
  ...otherProps
}) => {
  return (
    <AntdInput
      name={name}
      type={type}
      className={`colorpicker-input-box ${className}`}
      onChange={onChange}
      value={value}
      {...otherProps}
    />
  );
};
