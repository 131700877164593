import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  occasionTicketVariantCreateApi,
  occasionTicketVariantViewApi,
  updateTicketVariantApi
} from '../api/ticketVariant';
import {
  OCCASION_TICKET_VARIANT_CREATE,
  OCCASION_TICKET_VARIANT_VIEW,
  UPDATE_TICKET_VARIANT
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  occasionTicketVariantCreateFailed,
  occasionTicketVariantCreateSuccess,
  occasionTicketVariantViewFailed,
  occasionTicketVariantViewSuccess,
  updateTicketVariantFailed,
  updateTicketVariantSuccess
} from '../actions/ticketVariant';

export function* occasionTicketVariantCreateSaga(action) {
  try {
    const result = yield call(
      occasionTicketVariantCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionTicketVariantCreateSuccess(
          result.data,
          result.included,
          action.payload.ocId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionTicketVariantCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionTicketVariantCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionTicketVariantViewSaga(action) {
  try {
    const result = yield call(
      occasionTicketVariantViewApi,
      action.payload.ocId,
      action.payload.ticketId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionTicketVariantViewSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionTicketVariantViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* UpdateTicketVariantSaga(action) {
  try {
    const result = yield call(
      updateTicketVariantApi,
      action.payload.ocId,
      action.payload.ticketVariantId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateTicketVariantSuccess(
          action.payload.ocId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateTicketVariantFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateTicketVariantFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* ticketVariantActionWatcher() {
  yield takeLatest(
    OCCASION_TICKET_VARIANT_CREATE,
    progressSaga,
    occasionTicketVariantCreateSaga
  );
  yield takeLatest(
    OCCASION_TICKET_VARIANT_VIEW,
    progressSaga,
    occasionTicketVariantViewSaga
  );
  yield takeLatest(
    UPDATE_TICKET_VARIANT,
    progressSaga,
    UpdateTicketVariantSaga
  );
}
