import request from './request';

export function occasionTicketCreateApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/ticket/tickets`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function occasionTicketViewApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/ticket/tickets?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function updateOccasionTicketApi(ocId, ticketId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/ticket/tickets/${ticketId}`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}
