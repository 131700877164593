import request from './request';

export function createSocianaInternalUserApi(formData, token) {
  return request('api/v2/sociana_admin/users', {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function editSocianaInternalUserApi(formData, userId, token) {
  return request(`api/v2/sociana_admin/users/${userId}`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function getInternalUserParticipatedOccasions(userId, page, token) {
  return request(
    `api/v2/sociana_admin/internal_team_occasions/${userId}?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function searchInternalUserParticipatedOccasions(query, token) {
  return request(`api/v2/sociana_admin/occasion_search?q=${query}`, {
    method: 'GET',
    token
  });
}

export function getActiveSocianaInternalUsersApi(page, token) {
  return request(
    `api/v2/sociana_admin/get_active_internal_team_users?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function getInactiveSocianaInternalUsersApi(page, token) {
  return request(
    `api/v2/sociana_admin/get_inactive_internal_team_users?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function searchInternalTeamUserApi(query, token) {
  return request(
    `api/v2/sociana_admin/search_intern_team_users?q=${query}&from_internal_team=true`,
    {
      method: 'GET',
      token
    }
  );
}

export function getCurrentOccasionInternalTeamApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/internal_team_collaborators?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function assignInternalTeamMemberToOccasionApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/internal_team_collaborators`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function editInternalUserAccessSpecificationsApi(
  ocId,
  userId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/internal_team_collaborators/${userId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function editInternalUserOccasionAccessSpecificationsApi(
  ocId,
  userId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/internal_team_occasions/${userId}`,
    {
      method: 'PATCH',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function getInvitationWithStatusApi(userId, page, status, token) {
  return request(
    `api/v2/sociana_admin/internal_team_pending_invitations/${userId}?status=${status}&page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function getInternalTeamBySearchApi(
  searchData,
  fromInternalTeam,
  status,
  page,
  token
) {
  return request(
    `api/v2/sociana_admin/search_intern_team_users?page=${page}&q=${searchData}&from_internal_team=${fromInternalTeam}&active=${status}`,
    {
      method: 'GET',
      token
    }
  );
}

export function updateAssignInternalTeamMemberToOccasionApi(
  ocId,
  userId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/internal_team_occasions/${userId}`,
    {
      method: 'PATCH',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function deleteInternalUserParticipatedOccasions(ocId, userId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/internal_team_occasions/${userId}`,
    {
      method: 'DELETE',
      token
    }
  );
}
