import { createSelector } from 'reselect';
import { currentCompetitionId, currentParticipantId } from './dynamicData';
import { userEntities, participantEntities } from './entity';
import { participantIds } from './static';

export const currentParticipantIds = createSelector(
  currentCompetitionId,
  participantIds,
  (compId, ids) => compId && ids && ids[compId]
);
export const getAllParticipants = createSelector(
  participantEntities,
  currentParticipantIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
export const getParticipantsWithUserData = createSelector(
  getAllParticipants,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      user: { ...entities[item?.user?.id] }
    }))
);

export const getCurrentParticipant = createSelector(
  currentParticipantId,
  participantEntities,
  (id, entities) => id && entities && entities[id]
);

export const getCurrentParticipantWithUserData = createSelector(
  getCurrentParticipant,
  userEntities,
  (data, entities) =>
    data && entities && { ...data, user: { ...entities[data?.user?.id] } }
);
