import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  createParticipantApi,
  createParticipantBycsvApi,
  deleteParticipantApi,
  getParticipantApi,
  updateParticipantApi
} from '../api/participant';
import {
  CREATE_PARTICIPANT,
  CREATE_PARTICIPANT_BY_CSV,
  DELETE_PARTICIPANT,
  GET_PARTICIPANT,
  UPDATE_PARTICIPANT
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  deleteParticipantFailed,
  deleteParticipantSuccess,
  getParticipantFailed,
  getParticipantSuccess,
  participantCreateBycsvFailed,
  participantCreateBycsvSuccess,
  participantCreateFailed,
  participantCreateSuccess,
  updateParticipantFailed,
  updateParticipantSuccess
} from '../actions/participant';

export function* createParticipantSaga(action) {
  try {
    const result = yield call(
      createParticipantApi,
      action.payload.ocId,
      action.payload.competitionId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        participantCreateSuccess(
          result.data,
          action.payload.ocId,
          action.payload.competitionId,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(participantCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(participantCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* createParticipantBycsvSaga(action) {
  try {
    const result = yield call(
      createParticipantBycsvApi,
      action.payload.ocId,
      action.payload.competitionId,
      action.payload.groupId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        participantCreateBycsvSuccess(
          result.data,
          action.payload.ocId,
          action.payload.competitionId,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(participantCreateBycsvFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(participantCreateBycsvFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getParticipantSaga(action) {
  try {
    const result = yield call(
      getParticipantApi,
      action.payload.ocId,
      action.payload.competitionId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getParticipantSuccess(
          result.data,
          action.payload.ocId,
          action.payload.competitionId,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getParticipantFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* deleteParticipantSaga(action) {
  try {
    const result = yield call(
      deleteParticipantApi,
      action.payload.ocId,
      action.payload.competitionId,
      action.payload.participantId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        deleteParticipantSuccess(
          action.payload.ocId,
          action.payload.competitionId,
          result.data
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteParticipantFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updateParticipantSaga(action) {
  try {
    const result = yield call(
      updateParticipantApi,
      action.payload.ocId,
      action.payload.competitionId,
      action.payload.participantId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateParticipantSuccess(
          action.payload.ocId,
          action.payload.competitionId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateParticipantFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateParticipantFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* participantWatcher() {
  yield takeLatest(CREATE_PARTICIPANT, progressSaga, createParticipantSaga);
  yield takeLatest(
    CREATE_PARTICIPANT_BY_CSV,
    progressSaga,
    createParticipantBycsvSaga
  );
  yield takeLatest(GET_PARTICIPANT, progressSaga, getParticipantSaga);
  yield takeLatest(DELETE_PARTICIPANT, progressSaga, deleteParticipantSaga);
  yield takeLatest(UPDATE_PARTICIPANT, progressSaga, updateParticipantSaga);
}
