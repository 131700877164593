import {
  CREATE_FEATURE_CONTROL,
  CREATE_FEATURE_CONTROL_FAILED,
  CREATE_FEATURE_CONTROL_SUCCESS,
  GET_FEATURE_CONTROLS,
  GET_FEATURE_CONTROLS_SUCCESS,
  GET_FEATURE_CONTROLS_FAILED,
  UPDATE_FEATURE_CONTROL,
  UPDATE_FEATURE_CONTROL_FAILED,
  UPDATE_FEATURE_CONTROL_SUCCESS
} from '../constants/actionTypes';

export const createFeatureControl = (ocId, formData, callback) => ({
  type: CREATE_FEATURE_CONTROL,
  payload: { ocId, formData },
  callback
});

export const createFeatureControlSuccess = (data) => ({
  type: CREATE_FEATURE_CONTROL_SUCCESS,
  payload: { data }
});

export const createFeatureControlFailed = (error) => ({
  type: CREATE_FEATURE_CONTROL_FAILED,
  error
});

export const getFeatureControls = (ocId, callback) => ({
  type: GET_FEATURE_CONTROLS,
  payload: { ocId },
  callback
});

export const getFeatureControlsSuccess = (data) => ({
  type: GET_FEATURE_CONTROLS_SUCCESS,
  payload: { data }
});

export const getFeatureControlsFailed = (error) => ({
  type: GET_FEATURE_CONTROLS_FAILED,
  error
});

export const updateFeatureControl = (ocId, acId, formData, callback) => ({
  type: UPDATE_FEATURE_CONTROL,
  payload: { ocId, acId, formData },
  callback
});

export const updateFeatureControlSuccess = (data) => ({
  type: UPDATE_FEATURE_CONTROL_SUCCESS,
  payload: { data }
});

export const updateFeatureControlFailed = (error) => ({
  type: UPDATE_FEATURE_CONTROL_FAILED,
  error
});
