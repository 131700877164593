import { createSelector } from 'reselect';
import {
  hospitalityTaskEntities,
  userEntities,
  groupEntities,
  hospitalityTaskOptionEntities,
  respondedTaskEntities,
  hospitalityGuestEntities,
  guestEntities,
  hospitalityTaskAnswerEntities
} from './entity';
import {
  hospitalityTaskIds,
  notRespondedTaskIds,
  respondedTaskIds,
  notProvidedTaskIds,
  providedTaskIds,
  allHospitalityTasksIds,
  notProvidedSearchIds,
  providedSearchIds,
  notRespondedSearchIds,
  respondedSearchIds
} from './static';
import {
  currentHospitalityCategoryId,
  currentHospitalityTaskId,
  currentOccasionId
} from './dynamicData';

export const currentAllHospitalityTaskIds = createSelector(
  currentOccasionId,
  allHospitalityTasksIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const currentHospitalityCategoryIds = createSelector(
  currentHospitalityCategoryId,
  hospitalityTaskIds,
  (hcId, ids) => hcId && ids && ids[hcId]
);

export const getAllHospitalityTasksByOccasion = createSelector(
  hospitalityTaskEntities,
  currentAllHospitalityTaskIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAllHospitalityTask = createSelector(
  hospitalityTaskEntities,
  currentHospitalityCategoryIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const hospitalityTaskWithUserData = createSelector(
  getAllHospitalityTask,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      createdBy: { ...entities[item?.createdBy?.id] }
    }))
);

export const hospitalityTaskWithGroupData = createSelector(
  hospitalityTaskWithUserData,
  groupEntities,
  (data, entities) =>
    (data &&
      entities &&
      data?.length > 0 &&
      data.map((item) => ({
        ...item,
        groups: [...item?.groups?.map((el) => entities[el?.id])]
      }))) ||
    (Array.isArray(data) ? data : null)
);

export const hospitalityTaskWithTaskOptionsData = createSelector(
  hospitalityTaskWithGroupData,
  hospitalityTaskOptionEntities,
  (data, entities) =>
    (data &&
      entities &&
      data?.length > 0 &&
      data.map((item) => ({
        ...item,
        hospitalityTaskOptions: [
          ...item?.hospitalityTaskOptions?.map((el) => entities[el?.id])
        ]
      }))) ||
    (Array.isArray(data) ? data : null)
);

export const currentHospitalityTaskData = createSelector(
  currentHospitalityTaskId,
  hospitalityTaskEntities,
  (id, entities) => id && entities && entities[id]
);

export const CurrentHospitalityTaskWithGroupData = createSelector(
  currentHospitalityTaskData,
  groupEntities,
  (data, entities) =>
    data &&
    entities && {
      ...data,
      groups: data.groups.map((item) => entities[item.id])
    }
);

export const currentHospitalityTaskWithTaskOptionsData = createSelector(
  CurrentHospitalityTaskWithGroupData,
  hospitalityTaskOptionEntities,
  (data, entities) =>
    data &&
    entities && {
      ...data,
      hospitalityTaskOptions: data.hospitalityTaskOptions.map(
        (item) => entities[item.id]
      )
    }
);

export const currentNotRespondedTaskIds = createSelector(
  currentHospitalityTaskId,
  notRespondedTaskIds,
  (taskId, ids) => taskId && ids && ids[taskId]
);

export const getAllNotRespondedTask = createSelector(
  userEntities,
  currentNotRespondedTaskIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAllNotResondedTaskWithGuestData = createSelector(
  getAllNotRespondedTask,
  hospitalityGuestEntities,
  hospitalityTaskAnswerEntities,
  (data, entities, answerEntities) =>
    data &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      currentOccasionHospitalityGuests:
        item?.currentOccasionHospitalityGuests?.length > 0
          ? item?.currentOccasionHospitalityGuests?.map((el) => ({
              ...entities[el?.id],
              currentTaskAnswer: entities[el?.id]?.currentTaskAnswer?.map(
                (item) => answerEntities[item?.id]
              )
            }))
          : []
    }))
);

export const currentRespondedTaskIds = createSelector(
  currentHospitalityTaskId,
  respondedTaskIds,
  (taskId, ids) => taskId && ids && ids[taskId]
);

export const getAllResondedTask = createSelector(
  userEntities,
  currentRespondedTaskIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAllResondedTaskWithUserData = createSelector(
  getAllResondedTask,
  hospitalityGuestEntities,
  hospitalityTaskAnswerEntities,
  (data, entities, answerEntities) =>
    data &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      currentOccasionHospitalityGuests:
        item?.currentOccasionHospitalityGuests?.length > 0
          ? item?.currentOccasionHospitalityGuests?.map((el) => ({
              ...entities[el?.id],
              currentTaskAnswer: entities[el?.id]?.currentTaskAnswer?.map(
                (item) => answerEntities[item?.id]
              )
            }))
          : [],
      currentRequestedTaskAnswer:
        answerEntities[item?.currentRequestedTaskAnswer?.id]
    }))
);

export const currentNotProvidedTaskIds = createSelector(
  currentHospitalityTaskId,
  notProvidedTaskIds,
  (taskId, ids) => taskId && ids && ids[taskId]
);

export const getAllNotProvidedTask = createSelector(
  userEntities,
  currentNotProvidedTaskIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAllNotProvidedTaskWithGuestData = createSelector(
  getAllNotProvidedTask,
  hospitalityGuestEntities,
  hospitalityTaskAnswerEntities,
  (data, entities, answerEntities) =>
    data &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      currentOccasionHospitalityGuests:
        item?.currentOccasionHospitalityGuests?.length > 0
          ? item?.currentOccasionHospitalityGuests?.map((el) => ({
              ...entities[el?.id],
              currentTaskAnswer: entities[el?.id]?.currentTaskAnswer?.map(
                (item) => answerEntities[item?.id]
              )
            }))
          : []
    }))
);

export const currentProvidedTaskIds = createSelector(
  currentHospitalityTaskId,
  providedTaskIds,
  (taskId, ids) => taskId && ids && ids[taskId]
);

export const getAllProvidedTask = createSelector(
  userEntities,
  currentProvidedTaskIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAllProvidedTaskWithUserData = createSelector(
  getAllProvidedTask,
  hospitalityGuestEntities,
  hospitalityTaskAnswerEntities,
  (data, entities, answerEntities) =>
    data &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      currentOccasionHospitalityGuests:
        item?.currentOccasionHospitalityGuests?.length > 0
          ? item?.currentOccasionHospitalityGuests?.map((el) => ({
              ...entities[el?.id],
              currentTaskAnswer: entities[el?.id]?.currentTaskAnswer?.map(
                (item) => answerEntities[item?.id]
              )[0]
            }))
          : [],
      currentProvidedTaskAnswer:
        answerEntities[item?.currentProvidedTaskAnswer?.id]
    }))
);

export const currentNotProvidedTaskSearchIds = createSelector(
  currentHospitalityTaskId,
  notProvidedSearchIds,
  (taskId, ids) => taskId && ids && ids[taskId]
);

export const getAllNotProvidedTaskSearch = createSelector(
  userEntities,
  currentNotProvidedTaskSearchIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAllNotProvidedTaskSearchWithGuestData = createSelector(
  getAllNotProvidedTaskSearch,
  hospitalityGuestEntities,
  hospitalityTaskAnswerEntities,
  (data, entities, answerEntities) =>
    data &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      currentOccasionHospitalityGuests:
        item?.currentOccasionHospitalityGuests?.length > 0
          ? item?.currentOccasionHospitalityGuests?.map((el) => ({
              ...entities[el?.id],
              currentTaskAnswer: entities[el?.id]?.currentTaskAnswer?.map(
                (item) => answerEntities[item?.id]
              )
            }))
          : []
    }))
);

export const currentProvidedTaskSearchIds = createSelector(
  currentHospitalityTaskId,
  providedSearchIds,
  (taskId, ids) => taskId && ids && ids[taskId]
);

export const getAllProvidedTaskSearch = createSelector(
  userEntities,
  currentProvidedTaskSearchIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAllProvidedTaskSearchWithGuestData = createSelector(
  getAllProvidedTaskSearch,
  hospitalityGuestEntities,
  hospitalityTaskAnswerEntities,
  (data, entities, answerEntities) =>
    data &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      currentOccasionHospitalityGuests:
        item?.currentOccasionHospitalityGuests?.length > 0
          ? item?.currentOccasionHospitalityGuests?.map((el) => ({
              ...entities[el?.id],
              currentTaskAnswer: entities[el?.id]?.currentTaskAnswer?.map(
                (item) => answerEntities[item?.id]
              )[0]
            }))
          : [],
      currentProvidedTaskAnswer:
        answerEntities[item?.currentProvidedTaskAnswer?.id]
    }))
);

export const currentNotRespondedTaskSearchIds = createSelector(
  currentHospitalityTaskId,
  notRespondedSearchIds,
  (taskId, ids) => taskId && ids && ids[taskId]
);

export const getAllNotRespondedTaskSearch = createSelector(
  userEntities,
  currentNotRespondedTaskSearchIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAllNotResondedTaskSearchWithGuestData = createSelector(
  getAllNotRespondedTaskSearch,
  hospitalityGuestEntities,
  hospitalityTaskAnswerEntities,
  (data, entities, answerEntities) =>
    data &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      currentOccasionHospitalityGuests:
        item?.currentOccasionHospitalityGuests?.length > 0
          ? item?.currentOccasionHospitalityGuests?.map((el) => ({
              ...entities[el?.id],
              currentTaskAnswer: entities[el?.id]?.currentTaskAnswer?.map(
                (item) => answerEntities[item?.id]
              )
            }))
          : []
    }))
);

export const currentRespondedTaskSearchIds = createSelector(
  currentHospitalityTaskId,
  respondedSearchIds,
  (taskId, ids) => taskId && ids && ids[taskId]
);

export const getAllResondedTaskSearch = createSelector(
  userEntities,
  currentRespondedTaskSearchIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAllResondedTaskSearchWithGuestData = createSelector(
  getAllResondedTaskSearch,
  hospitalityGuestEntities,
  hospitalityTaskAnswerEntities,
  (data, entities, answerEntities) =>
    data &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      currentOccasionHospitalityGuests:
        item?.currentOccasionHospitalityGuests?.length > 0
          ? item?.currentOccasionHospitalityGuests?.map((el) => ({
              ...entities[el?.id],
              currentTaskAnswer: entities[el?.id]?.currentTaskAnswer?.map(
                (item) => answerEntities[item?.id]
              )
            }))
          : [],
      currentRequestedTaskAnswer:
        answerEntities[item?.currentRequestedTaskAnswer?.id]
    }))
);
