import request from './request';

export function eventAffairEntertainmentCreateApi(
  ocId,
  formData,
  eventId,
  eventAffairId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/event_affairs/${eventAffairId}/event_affair_entertainments`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function eventAffairEntertainmentViewApi(
  ocId,
  page,
  eventId,
  eventAffairId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/event_affairs/${eventAffairId}/event_affair_entertainments?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function eventAffairEntertainmentUpdateApi(
  ocId,
  eventAffairId,
  eventId,
  entertainmentId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/event_affairs/${eventAffairId}/event_affair_entertainments/${entertainmentId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function eventAffairEntertainmentDeleteApi(
  ocId,
  eventAffairId,
  eventId,
  entertainmentId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/event_affairs/${eventAffairId}/event_affair_entertainments/${entertainmentId}`,
    {
      method: 'DELETE',
      token
    }
  );
}
