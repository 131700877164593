import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EditOutlined, SyncOutlined } from '@ant-design/icons';
import './styles.scss';
import { getAllOccasionAppData } from '../../../../../selectors/occasionApp';
import { getOccasionAppDetails } from '../../../../../actions/occasionApp';
import { adminData } from '../../../../../selectors/auth';
import OccasionAppCreate from './Create';

const OccasionAppDetails = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  React.useEffect(() => {
    props.getOccasionAppDetails(props.occasionId);
  }, []);

  const onPageRefresh = () => {
    props.getOccasionAppDetails(props.occasionId);
  };

  const handleCreate = () => {
    setCreateOpen(true);
  };

  if (!createOpen) {
    return (
      <>
        {props.adminData?.accessType == 'super_admin' && (
          <div className="occasion-app-button-box">
            {!props.occasionAppData?.appId ? (
              <div>
                <button
                  className="occasion-app-refresh-button"
                  onClick={onPageRefresh}>
                  <SyncOutlined />
                </button>
                <button
                  className="occasion-app-create-button"
                  onClick={handleCreate}>
                  Create
                </button>
              </div>
            ) : (
              <button
                className="occasion-app-edit-details-container"
                onClick={handleCreate}>
                <EditOutlined />
                <span className="details">Edit</span>
              </button>
            )}
          </div>
        )}
        <div
          className="occasion-app-details-container"
          style={{
            overflowX: !props.occasionAppData?.appId ? 'hidden' : 'scroll',
            marginTop: !props.occasionAppData?.appId ? '50px' : '0px'
          }}>
          {props.occasionAppData ? (
            <div className="occasion-app-details-content">
              {props.occasionAppData?.appId && (
                <div className="occasion-app-details">
                  <b>App Id :</b>
                  <span className="occasion-app-details-text">
                    {props.occasionAppData?.appId}
                  </span>
                </div>
              )}

              {props.occasionAppData?.appName && (
                <div className="occasion-app-details">
                  <b>App Name :</b>
                  <span className="occasion-app-details-text">
                    {props.occasionAppData?.appName}
                  </span>
                </div>
              )}

              {props.occasionAppData?.firebaseServerKey && (
                <div className="occasion-app-details">
                  <b>Firebase Server Key :</b>
                  <span className="occasion-app-details-text">
                    {props.occasionAppData?.firebaseServerKey}
                  </span>
                </div>
              )}

              {props.occasionAppData?.appstoreUrl && (
                <div className="occasion-app-details">
                  <b>App Store URL :</b>
                  <span className="occasion-app-details-text">
                    <a href={props.occasionAppData?.appstoreUrl} target="blank">
                      {props.occasionAppData?.appstoreUrl}
                    </a>
                  </span>
                </div>
              )}

              {props.occasionAppData?.playstoreUrl && (
                <div className="occasion-app-details">
                  <b>Play Store URL :</b>
                  <span className="occasion-app-details-text">
                    <a
                      href={props.occasionAppData?.appstoreUrl}
                      target="_blank">
                      {props.occasionAppData?.playstoreUrl}
                    </a>
                  </span>
                </div>
              )}

              {props.occasionAppData?.fcmJsonFileUrl && (
                <div className="occasion-app-details">
                  <b>FCM JSON URL :</b>
                  <span className="occasion-app-details-text">
                    <a
                      href={`${process.env.REACT_APP_CLIENT_URL}/${props.occasionAppData?.fcmJsonFileUrl}`}
                      target="_blank">
                      Firebase JSON File
                    </a>
                  </span>
                </div>
              )}
            </div>
          ) : (
            'No personalized app present!'
          )}
        </div>
      </>
    );
  }
  return (
    <div className="edit-container">
      <OccasionAppCreate
        occasionId={props.occasionId}
        appData={props.occasionAppData}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  occasionAppData: getAllOccasionAppData(store, props),
  adminData: adminData(store, props)
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ getOccasionAppDetails }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(OccasionAppDetails);
