import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import { SectionContent } from '../../../../../../components/Section';
import {
  deleteEmails,
  viewEmails
} from '../../../../../../actions/notifications';
import { currentOccasionId } from '../../../../../../selectors/dynamicData';
import {
  getEmailWithUserData,
  getEmailLinks
} from '../../../../../../selectors/notifications';
import './styles.scss';
import { Button } from 'antd';
import CreateLive from '../Create/index';
import Modal from 'antd/lib/modal/Modal';
import SendEmail from '../SendEmail';

const NotificationView = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [emailId, setEmailId] = useState(null);
  const [visible, setVisibility] = useState(false);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.viewEmails(props.occasionId, page);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const paginationOptions = {
    showTotal: () => (
      <div className="email-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={() => setCreateOpen(true)}>
          Create
        </Button>
      </div>
    )
  };

  const editData = (id) => {
    setCreateOpen(true);
    setEmailId(id);
  };

  const deleteData = (id) => {
    props.deleteEmails(props.occasionId, id);
  };

  const closeCreate = () => {
    setCreateOpen(false);
    setEmailId(null);
  };

  const openModal = (id) => {
    setVisibility(true);
    setEmailId(id);
  };

  const closeModal = () => {
    setVisibility(false);
    setEmailId(null);
  };

  const customAction = (id) => {
    return (
      <Button className="action-button" onClick={() => openModal(id)}>
        Send
      </Button>
    );
  };

  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="notification-view-section">
          <div className="notification-view-container">
            <TableView
              isPaginated={true}
              isRouted={false}
              paginationOptions={paginationOptions}
              fetchProgress={props.fetchProgress}
              onPageChange={onPageChange}
              data={props.emails}
              onDelete={deleteData}
              isEditable={editData}
              occasionId={props.occasionId}
              paginationLinks={props.emailLinks}
              customAction={customAction}
            />
          </div>
        </SectionContent>
        <Modal
          centered
          title="Send Email"
          visible={visible}
          footer={null}
          className="email-modal"
          onCancel={closeModal}>
          <SendEmail
            occasionId={props.occasionId}
            emailId={emailId}
            closeModal={closeModal}
          />
        </Modal>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <CreateLive
        emailId={emailId}
        occasionId={props.occasionId}
        handleCloseClick={closeCreate}
      />
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  occasionId: currentOccasionId(store, props),
  emails: getEmailWithUserData(store, props),
  emailLinks: getEmailLinks(store, props)
});

const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ viewEmails, deleteEmails }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(NotificationView);
