import request from './request';

export function createEmailApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/email_saver/email_savers`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function viewEmailApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/email_saver/email_savers?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function editEmailApi(ocId, emailId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/email_saver/email_savers/${emailId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function deleteEmailApi(ocId, emailId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/email_saver/email_savers/${emailId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function sendEmailSaverApi(ocId, emailId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/email_saver/${emailId}/trigger`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}
