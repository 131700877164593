import { union } from 'lodash';
import {
  USER_DEVICE_VIEW_SUCCESS,
  USER_DEVICE_VIEW_FAILED
} from '../constants/actionTypes';

const initialState = {
  userDeviceData: null,
  error: null,
  userDeviceLinks: null,
  count: 0
};

const userDevice = (state = initialState, action) => {
  switch (action.type) {
    case USER_DEVICE_VIEW_SUCCESS: {
      const { data, userId, links, meta } = action.payload;
      const userGroupedData = state.userDeviceData || {};
      const NewIds = data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        userDeviceData: {
          ...userGroupedData,
          [userId]: [...NewIds]
        },
        userDeviceLinks: links,
        count: meta?.totalCount || 0
      };
    }

    case USER_DEVICE_VIEW_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default userDevice;
