import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import {
  blackListUserView,
  deleteBlackListUser
} from '../../../../../../actions/activityTracker';
import { getAllBlackListUsers } from '../../../../../../selectors/activityTracker';
import { Button } from 'antd';
import BlackListCreate from './create';

const ViewBlackListUser = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.blackListUserView(props.occasionId, page);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleCreate = () => {
    setCreateOpen(true);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="activity-tracker-black-list-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={handleCreate}>
          Create
        </Button>
        <div className="count-box">
          <span>Total User</span>
          <span className="count">{props.count}</span>
        </div>
      </div>
    )
  };

  const deleteData = (id) => {
    props.deleteBlackListUser(props.occasionId, id);
  };

  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="activity-tracker-black-list-view-section">
          <div className="activity-tracker-black-list-view-container">
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              onPageChange={onPageChange}
              onDelete={deleteData}
              data={props.blackListData}
              fetchProgress={props.fetchProgress}
              paginationLinks={props.blackListLinks}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <BlackListCreate
        occasionId={props.occasionId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  blackListData: getAllBlackListUsers(store, props),
  blackListLinks: store.activityTracker.blackListUserLinks,
  count: store.activityTracker.blackListUserCount
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { blackListUserView, deleteBlackListUser },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(ViewBlackListUser);
