import {
  HARD_LOGOUT_CREATE,
  HARD_LOGOUT_CREATE_FAILED,
  HARD_LOGOUT_CREATE_SUCCESS,
  HARD_LOGOUT_VIEW,
  HARD_LOGOUT_VIEW_FAILED,
  HARD_LOGOUT_VIEW_SUCCESS,
  UPDATE_HARD_LOGOUT,
  UPDATE_HARD_LOGOUT_FAILED,
  UPDATE_HARD_LOGOUT_SUCCESS,
  HARD_LOGOUT_USER_VIEW,
  HARD_LOGOUT_USER_VIEW_FAILED,
  HARD_LOGOUT_USER_VIEW_SUCCESS,
  HARD_LOGOUT_USER_DELETE,
  HARD_LOGOUT_USER_DELETE_FAILED,
  HARD_LOGOUT_USER_DELETE_SUCCESS,
  HARD_LOGOUT_ALL_USER_DELETE,
  HARD_LOGOUT_ALL_USER_DELETE_FAILED,
  HARD_LOGOUT_ALL_USER_DELETE_SUCCESS
} from '../constants/actionTypes';
export const hardLogoutCreate = (ocId, formData, callback) => ({
  type: HARD_LOGOUT_CREATE,
  payload: { ocId, formData },
  callback
});

export const hardLogoutCreateSuccess = (data, included, ocId) => ({
  type: HARD_LOGOUT_CREATE_SUCCESS,
  payload: { data, included, ocId }
});

export const hardLogoutCreateFailed = (error) => ({
  type: HARD_LOGOUT_CREATE_FAILED,
  error
});

export const hardLogoutView = (ocId, page) => ({
  type: HARD_LOGOUT_VIEW,
  payload: { ocId, page }
});

export const hardLogoutViewSuccess = (data, included, ocId, links, meta) => ({
  type: HARD_LOGOUT_VIEW_SUCCESS,
  payload: { data, included, ocId, links, meta }
});

export const hardLogoutViewFailed = (error) => ({
  type: HARD_LOGOUT_VIEW_FAILED,
  error
});

export const updateHardLogout = (ocId, hardLogoutId, formData, callback) => ({
  type: UPDATE_HARD_LOGOUT,
  payload: { ocId, hardLogoutId, formData },
  callback
});

export const updateHardLogoutSuccess = (ocId, data, included) => ({
  type: UPDATE_HARD_LOGOUT_SUCCESS,
  payload: { ocId, data, included }
});

export const updateHardLogoutFailed = (error) => ({
  type: UPDATE_HARD_LOGOUT_FAILED,
  error
});

export const hardLogoutUserView = (ocId, hardLogoutId, page) => ({
  type: HARD_LOGOUT_USER_VIEW,
  payload: { ocId, hardLogoutId, page }
});

export const hardLogoutUserViewSuccess = (
  data,
  included,
  ocId,
  hardLogoutId,
  links,
  meta
) => ({
  type: HARD_LOGOUT_USER_VIEW_SUCCESS,
  payload: { data, included, ocId, hardLogoutId, links, meta }
});

export const hardLogoutUserViewFailed = (error) => ({
  type: HARD_LOGOUT_USER_VIEW_FAILED,
  error
});

export const hardLogoutUserDelete = (ocId, hardLogoutId, hardLogoutUserId) => ({
  type: HARD_LOGOUT_USER_DELETE,
  payload: { ocId, hardLogoutId, hardLogoutUserId }
});

export const hardLogoutUserDeleteSuccess = (
  data,
  included,
  ocId,
  hardLogoutId,
  links
) => ({
  type: HARD_LOGOUT_USER_DELETE_SUCCESS,
  payload: { data, included, ocId, hardLogoutId, links }
});

export const hardLogoutUserDeleteFailed = (error) => ({
  type: HARD_LOGOUT_USER_DELETE_FAILED,
  error
});

export const hardLogoutAllUserDelete = (ocId, hardLogoutId) => ({
  type: HARD_LOGOUT_ALL_USER_DELETE,
  payload: { ocId, hardLogoutId }
});

export const hardLogoutAllUserDeleteSuccess = (
  data,
  included,
  ocId,
  hardLogoutId,
  links
) => ({
  type: HARD_LOGOUT_ALL_USER_DELETE_SUCCESS,
  payload: { data, included, ocId, hardLogoutId, links }
});

export const hardLogoutAllUserDeleteFailed = (error) => ({
  type: HARD_LOGOUT_ALL_USER_DELETE_FAILED,
  error
});
