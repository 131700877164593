import {
  FETCH_STARTED,
  FETCH_COMPLETED,
  ACTIVATE_ALERT,
  REMOVE_ALERT,
  UPDATE_ALERT_MESSAGE,
  UPDATE_PROGRESS,
  NETWORK_ONLINE,
  NETWORK_OFFLINE
} from '../constants/actionTypes';

const initalState = {
  fetchProgress: false,
  online: true,
  alert: {
    message: '',
    visible: false,
    dismissible: true,
    type: null
  },
  progress: {
    message: '',
    progress: 0
  }
};

const browserState = (state = initalState, action) => {
  switch (action.type) {
    case FETCH_STARTED: {
      return { ...state, fetchProgress: true };
    }
    case FETCH_COMPLETED: {
      return { ...state, fetchProgress: false };
    }
    case ACTIVATE_ALERT: {
      return {
        ...state,
        alert: {
          ...state.alert,
          message: action.alertData,
          type: action.alertType,
          visible: true,
          dismissible: action.dismissible
        }
      };
    }
    case REMOVE_ALERT: {
      return {
        ...state,
        alert: {
          ...state.alert,
          visible: false,
          dismissible: true
        }
      };
    }

    case NETWORK_OFFLINE: {
      return {
        ...state,
        online: false
      };
    }

    case NETWORK_ONLINE: {
      return {
        ...state,
        online: true
      };
    }

    default:
      return state;
  }
};

export default browserState;
