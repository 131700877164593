import request from './request';

export function getReportsWithPaginationApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/get_reported_posts?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function getReportedPostCommentsWithPaginationApi(
  ocId,
  page,
  commentableType,
  commentableId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/get_reported_post_comments?commentable_type=${commentableType}&commentable_id=${commentableId}&page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function getReportedPostLikesWithPaginationApi(
  ocId,
  page,
  likeableType,
  likeableId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/get_reported_post_likes?likeable_type=${likeableType}&likeable_id=${likeableId}&page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}
