import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../../../components/Section';
import ErrorBoundary from '../../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../../components/InputGroup/FormComponents';
import {
  eventAffairEssenceCreate,
  eventAffairEssenceUpdate
} from '../../../../../../../actions/eventAffairEssence';
import { getCurrentEventAffairEssence } from '../../../../../../../selectors/eventAffair';
import { getImageSource } from '../../../../../../../helpers/imageHelper';

const EventAffairEssenceCreate = (props) => {
  const [essenceData, setEssenceDate] = useState({
    description: props.currentEssenceDate?.description || '',
    file: props.currentEssenceDate?.fileUrl && [
      { url: getImageSource(props.currentEssenceDate?.fileUrl) }
    ]
  });

  const handleSubmit = () => {
    try {
      const formData = new FormData();
      formData.append(
        'event_affair_essence[description]',
        essenceData.description
      );

      if (essenceData.file && essenceData.file[0]?.originFileObj) {
        formData.append(
          'event_affair_essence[file]',
          essenceData.file[0]?.originFileObj
        );
      }

      props.essenceId
        ? props.eventAffairEssenceUpdate(
            props.occasionId,
            props.eventAffairId,
            props.eventId,
            props.essenceId,
            formData
          )
        : props.eventAffairEssenceCreate(
            props.occasionId,
            formData,
            props.eventId,
            props.eventAffairId,
            () => {
              setEssenceDate({});
            }
          );
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let formValue;
      let inputName;
      if (type === 'file') {
        value = e.fileList;
        name = type;
      }
      formValue = value || e?.target?.value;
      inputName = name || e?.target?.name;
      setEssenceDate({
        ...essenceData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="event-affair-essence-create-section">
        <div className="event-affair-essence-create-form">
          <Form
            className="event-affair-essence-form-container"
            onFinish={handleSubmit}>
            <InputGroup
              label="Description"
              name="description"
              placeholder="Enter Description"
              onChange={handleInputChange}
              fieldType="textArea"
              value={essenceData?.description}
            />

            <InputGroup
              fieldType="file"
              label="File"
              fileList={essenceData?.file}
              onChange={handleInputChange}
              name="file"
            />

            <SubmitButton
              buttonText={props.essenceId ? 'Save Changes' : 'Create'}
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentEssenceDate: getCurrentEventAffairEssence(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { eventAffairEssenceCreate, eventAffairEssenceUpdate },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventAffairEssenceCreate);
