import {
  GET_SPEAKER_REFERENCE_PAGINATION_SUCCESS,
  GET_SPEAKER_REFERENCE_PAGINATION_FAILED,
  EDIT_SPEAKER_REFERENCE_FAILED,
  DELETE_SPEAKER_REFERENCE_SUCCESS,
  DELETE_SPEAKER_REFERENCE_FAILED
} from '../constants/actionTypes';
import { union } from 'lodash';

const initialState = {
  speakerReferenceData: null,
  error: null,
  speakerReferenceLinks: null
};

const speakerReference = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPEAKER_REFERENCE_PAGINATION_FAILED:
    case EDIT_SPEAKER_REFERENCE_FAILED:
    case DELETE_SPEAKER_REFERENCE_FAILED: {
      return { ...state, error: action.error };
    }

    case GET_SPEAKER_REFERENCE_PAGINATION_SUCCESS: {
      const { data, speakerId, links } = action.payload;
      const occasionGroupedData = state.speakerReferenceData || {};
      const currentspeakerReferenceNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        speakerReferenceData: {
          ...occasionGroupedData,
          [speakerId]: [...currentspeakerReferenceNewIds]
        },
        occasionSpeakerLinks: links
      };
    }

    case DELETE_SPEAKER_REFERENCE_SUCCESS: {
      const { data, speakerId } = action.payload;

      const occasionGroupedData = state.speakerReferenceData || {};
      const currentspeakerReferenceIds = occasionGroupedData[speakerId] || [];
      const currentspeakerReferenceNewIds = currentspeakerReferenceIds?.filter(
        (id) => id != data.id
      );

      return {
        ...state,
        speakerReferenceData: {
          ...occasionGroupedData,
          [speakerId]: currentspeakerReferenceNewIds
        }
      };
    }

    default:
      return state;
  }
};

export default speakerReference;
