import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Button, AutoComplete, Image, Radio } from 'antd';
import './styles.scss';
import { CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import {
  occasionPollPostCreate,
  updatePollPost
} from '../../../../../../actions/pollPost';
import { getSearchedInternalUsers } from '../../../../../../selectors/socianaInternalUser';
import { currentOccasionId } from '../../../../../../selectors/dynamicData';
import { currentPollPostData } from '../../../../../../selectors/pollPost';
import { getImageSource } from '../../../../../../helpers/imageHelper';
import { searchInternalTeamMember } from '../../../../../../actions/socianaInternalUser';
import { QUESTION_TYPE } from '../constants';
import { occasionGroupView } from '../../../../../../actions/group';
import { getOccasionGroupsWithAllData } from '../../../../../../selectors/group';

const validatePollPostData = (values, Id, key) => {
  const errors = {};
  if (!values.question) {
    errors.question = 'Question is required';
  }
  if (!values.searchData.value && !values.toggleToUserId) {
    errors.searchData = 'Internal Team Member is required';
  }
  if (values.toggleToUserId && !values.userId) {
    errors.userId = 'User Id is required';
  }
  if (!values.option1 && !Id) {
    errors.option1 = 'Option1 is required';
  }
  if (!values.option2 && !Id) {
    errors.option2 = 'Option2 is required';
  }

  if (values.group?.length === 0 && !Id && key?.status === 'on') {
    errors.group = 'Group is required';
  }

  return errors;
};

const { Option } = AutoComplete;

const PollPostCreate = (props) => {
  const [pollPostData, setPollPostData] = useState({ ...props.initialValues });
  const [errorMessages, setErrorMessages] = useState({});
  const [addOption, setAddOption] = useState([]);
  const [page, setPage] = React.useState(1);
  const [flagOption, setFlagOption] = useState('');

  const onPageChange = (page) => {
    props.occasionGroupView(props.occasionId, page);
  };

  React.useEffect(() => {
    onPageChange(page);
  }, [page]);

  const getPage = (str) => {
    const decodedURI = decodeURI(str);
    const number = decodedURI
      .split('page[number]')[1]
      .replace('=', '')
      .split('&');
    return (number && parseInt(number)) || 1;
  };

  const onEndReached = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height >= scrollHeight - 20) {
      if (props.allGroupsLinks?.next) {
        const page = getPage(props.allGroupsLinks?.next);
        setPage(page);
      }
    }
  };

  const handleSubmit = () => {
    try {
      const validateObj = validatePollPostData(
        pollPostData,
        props.pollPostId,
        props.modelGroupPollPostKey
      );

      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const data = { ...pollPostData };
        const options = Object.keys(data)
          .filter((key) => key.includes('option'))
          .map((key) => data[key]);
        const successFactors = Object.keys(data)
          .filter((key) => key.includes('successFactor'))
          .map((key) => data[key]);
        const formData = new FormData();
        if (!props.pollPostId) {
          options.forEach((option, index) => {
            if (index + 1 === flagOption) {
              formData.append(
                `poll_post[poll_post_option_attributes][${option}][${
                  successFactors[index] ? successFactors[index] : 0
                }]`,
                true
              );
            } else {
              formData.append(
                `poll_post[poll_post_option_attributes][${option}][${
                  successFactors[index] ? successFactors[index] : 0
                }]`,
                false
              );
            }
          });
        }
        if (pollPostData?.explanationDsecription) {
          formData.append(
            'poll_post[poll_post_explanation_description]',
            pollPostData.explanationDsecription
          );
        }

        if (pollPostData?.question_type) {
          formData.append(
            'poll_post[question_type]',
            pollPostData.question_type
          );
        }

        if (pollPostData?.images?.length > 0) {
          pollPostData?.images.forEach((image) => {
            if (image?.originFileObj)
              formData.append(
                'poll_post[poll_post_explanation_pictures][]',
                image?.originFileObj
              );
          });
        }

        if (
          pollPostData?.video?.length > 0 &&
          pollPostData?.video[0]?.originFileObj
        ) {
          formData.append(
            'poll_post[poll_post_explanation_video]',
            pollPostData.video[0].originFileObj
          );
        }

        if (!pollPostData?.toggleToUserId) {
          formData.append(
            'poll_post[created_by_id]',
            pollPostData.searchData.id
          );
        } else {
          formData.append('poll_post[created_by_id]', pollPostData.userId);
        }
        if (!props.pollPostId) {
          formData.append(
            'poll_post[is_visible]',
            pollPostData?.isVisible == true ? true : false
          );
        }
        formData.append('poll_post[question]', data.question || '');
        formData.append('poll_post[description]', data.dsecription || '');

        const purgedFloorPlans = props?.initialValues?.images?.filter(
          ({ id: id1 }) =>
            !pollPostData?.images?.some(({ id: id2 }) => id2 == id1)
        );

        if (purgedFloorPlans?.length > 0) {
          purgedFloorPlans.forEach((item) => {
            if (item?.id) {
              formData.append(
                'poll_post[purge_poll_post_explanation_pictures_ids][]',
                item.id
              );
            }
          });
        }

        if (!props.pollPostId && props.modelGroupPollPostKey?.status === 'on') {
          pollPostData?.group.forEach((id) => {
            formData.append('poll_post[model_group_ids][]', id);
          });
        }

        props.pollPostId
          ? props.updatePollPost(
              props.occasionId,
              props.pollPostId,
              formData,
              () => {
                props.handleCloseClick();
              }
            )
          : props.occasionPollPostCreate(props.occasionId, formData, () => {
              setPollPostData({});
              setAddOption([]);
              setFlagOption('');
              props.handleCloseClick();
            });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let formValue;
      let inputName;

      if (type === 'searchData') {
        value = {
          value: e?.value,
          id: e?.key
        };
        name = type;
      }

      if (type === 'toggle') {
        value = e;
        name = type;
      }

      if (type === 'toggleToUserId') {
        value = e;
        name = type;
      }

      if (type === 'isVisible') {
        value = e;
        name = type;
      }

      if (type === 'images') {
        value = e.fileList;
        name = type;
      }

      if (type === 'video') {
        value = e.fileList;
        name = type;
      }

      if (type === `option${addOption.length + 3}`) {
        value = e.target.value;
        name = type;
      }

      if (type === `successfactor${addOption.length + 3}`) {
        value = e.target.value;
        name = type;
      }

      formValue = value || e?.target?.value;
      inputName = name || e?.target?.name;

      setPollPostData({
        ...pollPostData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = (val) => {
    setPollPostData({ ...pollPostData, searchData: { value: val, id: null } });
    if (val.length > 0) {
      props.searchInternalTeamMember(val);
    }
  };

  const addClick = () => {
    const label = `Option ${addOption.length + 3}`;
    const optionData = `option${addOption.length + 3}`;
    setAddOption((prevAddOption) => {
      return [...prevAddOption, optionData];
    });
  };

  const deleteField = (index) => {
    const Left = addOption.slice(0, index);
    const Right = addOption.slice(index + 1);
    const option = addOption[index];
    delete pollPostData[option];
    delete pollPostData[`successFactor${index + 3}`];
    setAddOption(Left.concat(Right));
  };
  const handleChange = (e) => {
    setFlagOption(e.target.value);
  };

  return (
    <ErrorBoundary>
      <SectionContent className="occasion-poll-post-create-section">
        <div className="occasion-poll-post-create-form">
          <Form
            className="occassion-poll-post-form-container"
            onFinish={handleSubmit}>
            <InputGroup
              name="question"
              placeholder="Ask Question"
              label="Question"
              value={pollPostData?.question}
              onChange={handleInputChange}
              errorMessage={errorMessages.question}
            />
            <InputGroup
              name="dsecription"
              placeholder="Enter Description"
              label="Description"
              fieldType="textArea"
              value={pollPostData?.dsecription}
              onChange={handleInputChange}
            />

            {!props.pollPostId && props.modelGroupPollPostKey?.status === 'on' && (
              <InputGroup
                fieldType="dropdown"
                className="group-search"
                mode="multiple"
                name="group"
                label="Select Group"
                placeholder="Select Group"
                onPopupScroll={onEndReached}
                onChange={handleInputChange}
                errorMessage={errorMessages?.group}
                value={pollPostData?.group}>
                {props.groupData?.length > 0 &&
                  props.groupData.map((item) => {
                    return (
                      <Option value={item?.id} key={item?.id}>
                        <div className="options-container">
                          <p className="avatar-image-container">
                            <Image
                              src={getImageSource(item?.groupPictureUrl)}
                              preview={false}
                            />
                          </p>
                          <div className="occasion-group-details-container">
                            <p className="occasion-group-name">{item?.name}</p>
                          </div>
                        </div>
                      </Option>
                    );
                  })}
              </InputGroup>
            )}

            <InputGroup
              fieldType="switch"
              name="toggleToUserId"
              label="Toggle to Add user Id"
              onChange={handleInputChange}
              value={pollPostData?.toggleToUserId}
            />

            {!pollPostData?.toggleToUserId && (
              <InputGroup
                fieldType="searchbox"
                name="searchData"
                label="Search Internal Team Member"
                loading={props.fetchProgress}
                notFoundContent="No Data"
                errorMessage={errorMessages?.searchData}
                value={pollPostData?.searchData?.value}
                onSelect={(e, option) =>
                  handleInputChange(option, 'searchData')
                }
                onSearch={handleSearch}
                placeholder="Add created by">
                {props.searchedInternalUser?.length > 0 &&
                  pollPostData?.searchData?.value?.length > 0 &&
                  props.searchedInternalUser.map((item) => {
                    return (
                      <Option value={item?.name} key={item?.id}>
                        <div className="options-container">
                          <p className="avatar-image-container">
                            <Image
                              src={getImageSource(item?.avatarUrl)}
                              preview={false}
                            />
                          </p>
                          <div className="internal-member-occasion-details-container">
                            <p className="internal-member-occasion-name">
                              {item?.name}
                            </p>
                            <p className="internal-member-occasion-type">
                              {item?.email}
                            </p>
                          </div>
                        </div>
                      </Option>
                    );
                  })}
              </InputGroup>
            )}
            {pollPostData?.toggleToUserId && (
              <InputGroup
                name="userId"
                placeholder="Enter User Id"
                label="User Id"
                value={pollPostData?.userId}
                onChange={handleInputChange}
                errorMessage={errorMessages?.userId}
              />
            )}

            {!props.pollPostId && (
              <InputGroup
                label="Question Type"
                className="code-inputbox"
                fieldType="dropdown"
                placeholder="Select"
                options={QUESTION_TYPE}
                name="question_type"
                onChange={handleInputChange}
                value={pollPostData?.question_type}
              />
            )}

            <span style={{ margin: '15px 0px 0px 0px' }}>Enter Options :</span>
            <div style={{ color: 'red', fontSize: '12px' }}>
              **For Success Factor: The sum of all options' success factors must
              be equal to 100
            </div>

            <Radio.Group onChange={handleChange} value={flagOption}>
              {!props.pollPostId ? (
                <>
                  {pollPostData.question_type === 'quiz' && (
                    <Radio value={1}>1</Radio>
                  )}
                  <div className="radio-factor-box">
                    <InputGroup
                      name="option1"
                      label="Option1"
                      className="option-field"
                      value={pollPostData?.option1}
                      onChange={handleInputChange}
                      errorMessage={errorMessages.option1}
                    />
                    <InputGroup
                      name="successFactor1"
                      label="Success Factor"
                      className="factor-field"
                      value={pollPostData?.successFactor1}
                      onChange={handleInputChange}
                    />
                  </div>
                </>
              ) : (
                ''
              )}
              {!props.pollPostId ? (
                <>
                  {pollPostData.question_type === 'quiz' && (
                    <Radio value={2}>2</Radio>
                  )}
                  <div className="radio-factor-box">
                    <InputGroup
                      name="option2"
                      label="Option2"
                      className="option-field"
                      value={pollPostData?.option2}
                      onChange={handleInputChange}
                      errorMessage={errorMessages.option2}
                    />
                    <InputGroup
                      name="successFactor2"
                      label="Success Factor"
                      className="factor-field"
                      value={pollPostData?.successFactor2}
                      onChange={handleInputChange}
                    />
                  </div>
                </>
              ) : (
                ''
              )}

              {addOption.length > 0 &&
                !props.pollPostId &&
                addOption.map((item, index) => {
                  return (
                    <div key={index} className="added-option-container">
                      {pollPostData.question_type === 'quiz' && (
                        <Radio value={index + 3}>{index + 3}</Radio>
                      )}
                      <button
                        className="option-input-remove-button"
                        onClick={() => deleteField(index)}
                        type="button">
                        Remove
                      </button>
                      <div className="radio-factor-box">
                        <InputGroup
                          name={item}
                          className="option-field"
                          label={`Option ${index + 3}`}
                          onChange={handleInputChange}
                          value={pollPostData[item]}
                        />
                        <InputGroup
                          name={`successFactor${index + 3}`}
                          label="Success Factor"
                          className="factor-field"
                          value={pollPostData[`successFactor${index + 3}`]}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  );
                })}
            </Radio.Group>
            {!props.pollPostId ? (
              <div className="add-option-button-box">
                <button
                  className="add-option-button"
                  onClick={addClick}
                  type="button">
                  Add Option
                </button>
              </div>
            ) : (
              ''
            )}
            <InputGroup
              fieldType="switch"
              name="toggle"
              label="Toggle to Add Poll Post Explanations"
              onChange={handleInputChange}
              value={pollPostData?.toggle}
            />
            {pollPostData?.toggle && (
              <InputGroup
                name="explanationDsecription"
                placeholder="Enter Description"
                label="Description"
                fieldType="textArea"
                value={pollPostData?.explanationDsecription}
                onChange={handleInputChange}
              />
            )}
            {pollPostData?.toggle && (
              <div className="poll-post-media-field">
                {!pollPostData?.video.length > 0 && (
                  <InputGroup
                    fieldType="file"
                    label="Images"
                    multiple={true}
                    accept="image/*"
                    name="images"
                    onChange={handleInputChange}
                    fileList={pollPostData?.images}
                    noMaxCount={true}
                  />
                )}
                {!pollPostData?.images.length > 0 && (
                  <InputGroup
                    fieldType="file"
                    label="Video"
                    accept="video/*"
                    fileList={pollPostData?.video}
                    onChange={handleInputChange}
                    name="video"
                  />
                )}
              </div>
            )}
            {!props.pollPostId && (
              <InputGroup
                fieldType="switch"
                name="isVisible"
                label="Toggle to Make It Visible"
                onChange={handleInputChange}
                value={pollPostData?.isVisible}
              />
            )}
            <SubmitButton
              buttonText={props.pollPostId ? 'Save Changes' : 'Create'}
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => {
  const occasionId = currentOccasionId(store, props);
  const groupData = getOccasionGroupsWithAllData(store, props);
  const allGroupsLinks = store.group.groupLinks;
  const currentPollPost = currentPollPostData(store, props);
  const searchedInternalUser = getSearchedInternalUsers(store, props);

  const initialValues = {
    question: currentPollPost?.question || '',
    dsecription: currentPollPost?.description || '',
    group: [],
    searchData: currentPollPost?.searchData || {
      value: '',
      id: null
    },
    toggleToUserId: false,
    isVisible: true,
    userId: currentPollPost?.searchData?.id || '',
    toggle:
      currentPollPost?.pollPostExplanation?.description ||
      currentPollPost?.pollPostExplanation?.pictureUrls ||
      currentPollPost?.pollPostExplanation?.videoUrl
        ? true
        : false,
    explanationDsecription:
      currentPollPost?.pollPostExplanation?.description || '',
    images:
      currentPollPost?.pollPostExplanation?.pictureUrls?.length > 0
        ? currentPollPost?.pollPostExplanation?.pictureUrls.map((item) => ({
            id: Object.keys(item)[0],
            url:
              Object.values(item).length > 0 &&
              getImageSource(Object.values(item)[0])
          }))
        : [],
    video: currentPollPost?.pollPostExplanation?.videoUrl
      ? [
          {
            url: getImageSource(currentPollPost?.pollPostExplanation?.videoUrl)
          }
        ]
      : []
  };
  return {
    occasionId,
    initialValues,
    searchedInternalUser,
    fetchProgress: store.browserState.fetchProgress,
    currentPollPost,
    groupData,
    allGroupsLinks
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      occasionPollPostCreate,
      updatePollPost,
      searchInternalTeamMember,
      occasionGroupView
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PollPostCreate);
