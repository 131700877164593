import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import Tabs from '../../../../../../components/Tabs';
import { currentHospitalityTaskId } from '../../../../../../selectors/dynamicData';
import {
  currentHospitalityTaskWithTaskOptionsData,
  currentHospitalityTaskData,
  CurrentHospitalityTaskWithGroupData
} from '../../../../../../selectors/hospitalityTask';
import HospitalityTaskDetailsView from './TaskDetails';
import { TabsHeader } from '../../../../../../components/TabsHeader';
import { getCurrentOccasion } from '../../../../../../selectors/occasion';
import { getCurrentHospitality } from '../../../../../../selectors/hospitality';
import ProvidedIndex from './providedSubTab/index';
import NotProvidedIndex from './notProvidedSubTab/index';
import NotRespondedIndex from './notRespondedSubTab/index';
import RespondedIndex from './respondedSubTab/index';
import PublicAnswer from './PublicAnswer';

function TaskDetails(props) {
  const publicAnswerNotEnabled = () => {
    return (
      <div className="flex-center base-min-height">
        Public Answer is not enabled.
      </div>
    );
  };

  const getRespondedComponent = () => {
    return (
      <RespondedIndex
        occasionId={props.match.params.occasionId}
        hospitalityTaskId={props.currentHospitalityTaskId}
        dataInputType={props.currentTaskData?.dataInputType}
      />
    );
  };
  const getNotRespondedComponent = () => {
    return (
      <NotRespondedIndex
        occasionId={props.match.params.occasionId}
        hospitalityTaskId={props.currentHospitalityTaskId}
        dataInputType={props.currentTaskData?.dataInputType}
      />
    );
  };
  const getProvidedComponent = () => {
    return (
      <ProvidedIndex
        occasionId={props.match.params.occasionId}
        hospitalityTaskId={props.currentHospitalityTaskId}
        dataInputType={props.currentTaskData?.dataInputType}
      />
    );
  };
  const getNotProvidedComponent = () => {
    return (
      <NotProvidedIndex
        occasionId={props.match.params.occasionId}
        hospitalityTaskId={props.currentHospitalityTaskId}
        dataInputType={props.currentTaskData?.dataInputType}
      />
    );
  };
  const getPublicAnswerTab = () => {
    if (props.currentTaskData?.isPublic) {
      return (
        <PublicAnswer
          occasionId={props.match.params.occasionId}
          hospitalityTaskId={props.currentHospitalityTaskId}
        />
      );
    }
    return publicAnswerNotEnabled();
  };

  const tabPanes = [
    {
      tabName: 'Details',
      tabKey: '1',
      children: (
        <HospitalityTaskDetailsView
          hospitalityTaskId={props.currentHospitalityTaskId}
          hospitalityTaskDetails={
            props?.currentTaskWithOptionData
              ? props.currentTaskWithOptionData
              : props?.currentTaskWithGroupData
              ? props.currentTaskWithGroupData
              : currentTaskData
          }
          hospitalityId={props.match.params.hospitalityId}
          occasionId={props.match.params.occasionId}
        />
      )
    },
    {
      tabName:
        props.currentTaskData?.taskType === 'request'
          ? 'Responded'
          : 'Provided',
      tabKey: '2',
      children:
        props.currentTaskData?.taskType === 'request'
          ? getRespondedComponent()
          : getProvidedComponent()
    },
    {
      tabName:
        props.currentTaskData?.taskType === 'request'
          ? 'Not Responded'
          : 'Not Provided',
      tabKey: '3',
      children:
        props.currentTaskData?.taskType === 'request'
          ? getNotRespondedComponent()
          : getNotProvidedComponent()
    },
    props.currentTaskData?.taskType === 'provide' && {
      tabName: 'Public Answer',
      tabKey: '4',
      children: getPublicAnswerTab()
    }
  ];
  const goBack = () => {
    props.history.goBack();
  };

  return (
    <ErrorBoundary>
      <SectionContent className="hospitality-task-tab-section">
        <TabsHeader
          name={props.currentOccasion?.name}
          extraData={[
            props.currentOccasion?.type,
            props.currentOccasion?.guestType,
            props.currentHospitality?.name,
            props.currentTaskData?.name
          ]}
          wrapperClass="section-details"
        />
        <div className="hospitality-task-tab-container">
          <Tabs
            defaultActiveKey="1"
            tabPanes={tabPanes}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentTaskWithOptionData: currentHospitalityTaskWithTaskOptionsData(
    store,
    props
  ),
  currentHospitalityTaskId: currentHospitalityTaskId(store, props),
  currentOccasion: getCurrentOccasion(store, props),
  currentHospitality: getCurrentHospitality(store, props),
  currentTaskWithGroupData: CurrentHospitalityTaskWithGroupData(store, props),
  currentTaskData: currentHospitalityTaskData(store, props)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails);
