import {
  OCCASION_ROLE_NAME_CREATE,
  OCCASION_ROLE_NAME_CREATE_FAILED,
  OCCASION_ROLE_NAME_CREATE_SUCCESS,
  OCCASION_ROLE_NAME_VIEW,
  OCCASION_ROLE_NAME_VIEW_FAILED,
  OCCASION_ROLE_NAME_VIEW_SUCCESS,
  UPDATE_ROLE_NAME,
  UPDATE_ROLE_NAME_FAILED,
  UPDATE_ROLE_NAME_SUCCESS,
  DELETE_ROLE_NAME,
  DELETE_ROLE_NAME_FAILED,
  DELETE_ROLE_NAME_SUCCESS
} from '../constants/actionTypes';

export const occasionRoleNameCreate = (ocId, formData, callback) => ({
  type: OCCASION_ROLE_NAME_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionRoleNameCreateSuccess = (data, ocId, included, links) => ({
  type: OCCASION_ROLE_NAME_CREATE_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionRoleNameCreateFailed = (error) => ({
  type: OCCASION_ROLE_NAME_CREATE_FAILED,
  error
});

export const occasionRoleNameView = (ocId, page) => ({
  type: OCCASION_ROLE_NAME_VIEW,
  payload: { ocId, page }
});

export const occasionRoleNameViewSuccess = (data, ocId, included, links) => ({
  type: OCCASION_ROLE_NAME_VIEW_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionRoleNameViewFailed = (error) => ({
  type: OCCASION_ROLE_NAME_VIEW_FAILED,
  error
});

export const updateRoleName = (ocId, roleId, formData, callback) => ({
  type: UPDATE_ROLE_NAME,
  payload: { ocId, roleId, formData },
  callback
});

export const updateRoleNameSuccess = (ocId, data, included) => ({
  type: UPDATE_ROLE_NAME_SUCCESS,
  payload: { ocId, data, included }
});

export const updateRoleNameFailed = (error) => ({
  type: UPDATE_ROLE_NAME_FAILED,
  error
});

export const deleteRoleName = (ocId, roleId, callback) => ({
  type: DELETE_ROLE_NAME,
  payload: { ocId, roleId },
  callback
});

export const deleteRoleNameSuccess = (ocId, data) => ({
  type: DELETE_ROLE_NAME_SUCCESS,
  payload: { ocId, data }
});

export const deleteRoleNameFailed = (error) => ({
  type: DELETE_ROLE_NAME_FAILED,
  error
});
