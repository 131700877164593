import {
  EVENT_AFFAIR_FOOD_CREATE,
  EVENT_AFFAIR_FOOD_CREATE_FAILED,
  EVENT_AFFAIR_FOOD_CREATE_SUCCESS,
  EVENT_AFFAIR_FOOD_VIEW,
  EVENT_AFFAIR_FOOD_VIEW_FAILED,
  EVENT_AFFAIR_FOOD_VIEW_SUCCESS,
  EVENT_AFFAIR_FOOD_UPDATE,
  EVENT_AFFAIR_FOOD_UPDATE_FAILED,
  EVENT_AFFAIR_FOOD_UPDATE_SUCCESS,
  EVENT_AFFAIR_FOOD_DELETE,
  EVENT_AFFAIR_FOOD_DELETE_FAILED,
  EVENT_AFFAIR_FOOD_DELETE_SUCCESS
} from '../constants/actionTypes';

export const eventAffairFoodCreate = (
  ocId,
  formData,
  eventId,
  eventAffairId,
  callback
) => ({
  type: EVENT_AFFAIR_FOOD_CREATE,
  payload: { ocId, formData, eventId, eventAffairId },
  callback
});

export const eventAffairFoodCreateSuccess = (
  ocId,
  eventId,
  eventAffairId,
  data,
  included,
  links
) => ({
  type: EVENT_AFFAIR_FOOD_CREATE_SUCCESS,
  payload: { ocId, eventId, eventAffairId, data, included, links }
});

export const eventAffairFoodCreateFailed = (error) => ({
  type: EVENT_AFFAIR_FOOD_CREATE_FAILED,
  error
});

export const eventAffairFoodView = (ocId, page, eventId, eventAffairId) => ({
  type: EVENT_AFFAIR_FOOD_VIEW,
  payload: { ocId, page, eventId, eventAffairId }
});

export const eventAffairFoodViewSuccess = (
  ocId,
  eventId,
  eventAffairId,
  data,
  included,
  links
) => ({
  type: EVENT_AFFAIR_FOOD_VIEW_SUCCESS,
  payload: { ocId, eventId, eventAffairId, data, included, links }
});

export const eventAffairFoodViewFailed = (error) => ({
  type: EVENT_AFFAIR_FOOD_VIEW_FAILED,
  error
});

export const eventAffairFoodUpdate = (
  ocId,
  eventAffairId,
  eventId,
  foodId,
  formData,
  callback
) => ({
  type: EVENT_AFFAIR_FOOD_UPDATE,
  payload: { ocId, eventAffairId, eventId, foodId, formData },
  callback
});

export const eventAffairFoodUpdateSuccess = (
  ocId,
  eventId,
  eventAffairId,
  data,
  included,
  links
) => ({
  type: EVENT_AFFAIR_FOOD_UPDATE_SUCCESS,
  payload: { ocId, eventId, eventAffairId, data, included, links }
});

export const eventAffairFoodUpdateFailed = (error) => ({
  type: EVENT_AFFAIR_FOOD_UPDATE_FAILED,
  error
});

export const eventAffairFoodDelete = (
  ocId,
  eventAffairId,
  eventId,
  foodId
) => ({
  type: EVENT_AFFAIR_FOOD_DELETE,
  payload: { ocId, eventAffairId, eventId, foodId }
});

export const eventAffairFoodDeleteSuccess = (
  ocId,
  eventId,
  eventAffairId,
  data,
  included,
  links
) => ({
  type: EVENT_AFFAIR_FOOD_DELETE_SUCCESS,
  payload: { ocId, eventId, eventAffairId, data, included, links }
});

export const eventAffairFoodDeleteFailed = (error) => ({
  type: EVENT_AFFAIR_FOOD_DELETE_FAILED,
  error
});
