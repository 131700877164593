import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import {
  occasionSosCreate,
  occasionSosUpdate
} from '../../../../../actions/sos';
import { currentSosData } from '../../../../../selectors/sos';

const validateSosData = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Title is required';
  }
  if (values.order && Number(values.order) <= 0) {
    errors.order = 'Should be positive';
  }
  if (values.order && isNaN(values.order)) {
    errors.order = 'Should be number';
  }
  if (!values.phoneNo) {
    errors.phoneNo = 'Phone Number is required';
  }
  if (
    values.phoneNo &&
    !/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/.test(values.phoneNo)
  ) {
    errors.phoneNo = 'Invalid Input';
  }

  return errors;
};

const SosCreate = (props) => {
  const [sosData, setSosData] = useState({
    title: props.currentSosData ? props.currentSosData.title : '',
    phoneNo: props.currentSosData ? props.currentSosData.phone : '',
    order: props.currentSosData?.orderNumber || '',
    current_status: props.currentSosData
      ? props.currentSosData.status === 'active'
        ? true
        : false
      : ''
  });
  const [errorMessages, setErrorMessages] = useState({});
  const handleSubmit = () => {
    try {
      const validateObj = validateSosData(sosData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('save_our_soul[title]', sosData?.title);
        formData.append('save_our_soul[phone]', sosData?.phoneNo);
        formData.append(
          'save_our_soul[order_number]',
          sosData?.order ? Number(sosData.order) : 1
        );
        props.sosId
          ? formData.append(
              'save_our_soul[status]',
              sosData?.current_status ? 'active' : 'in_active'
            )
          : '';
        props.sosId
          ? props.occasionSosUpdate(props.occasionId, props.sosId, formData)
          : props.occasionSosCreate(props.occasionId, formData, () => {
              setSosData({});
            });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let formValue;
      let inputName;

      if (type === 'current_status') {
        value = e;
        name = type;
      }

      formValue = value || e?.target?.value;
      inputName = name || e?.target?.name;

      setSosData({
        ...sosData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="occasion-sos-create-section">
        <div className="occasion-sos-create-form">
          <Form
            className="occassion-sos-form-container"
            onFinish={handleSubmit}>
            <InputGroup
              name="title"
              placeholder=" Enter Title"
              label="Title"
              value={sosData?.title}
              onChange={handleInputChange}
              errorMessage={errorMessages.title}
            />
            <InputGroup
              name="phoneNo"
              placeholder=" Enter Phone Number"
              label="Phone Number"
              value={sosData?.phoneNo}
              onChange={handleInputChange}
              errorMessage={errorMessages.phoneNo}
            />
            <InputGroup
              label="Order"
              placeholder="Enter order"
              name="order"
              onChange={handleInputChange}
              value={sosData?.order}
              errorMessage={errorMessages?.order}
            />
            {props.sosId ? (
              <InputGroup
                label="Current status"
                defaultChecked={sosData?.current_status}
                name="current_status"
                onChange={handleInputChange}
                fieldType="switch"
              />
            ) : (
              ''
            )}
            <SubmitButton
              buttonText={props.sosId ? 'Save Changes' : 'Create'}
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentSosData: currentSosData(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ occasionSosCreate, occasionSosUpdate }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SosCreate);
