import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { TICKET_VARIANT_STATUS } from './TicketVariantConstants';
import {
  occasionTicketVariantCreate,
  updateTicketVariant
} from '../../../../../../actions/ticketVariant';
import { getCurrentTicketVariant } from '../../../../../../selectors/ticketVariant';

const validate = (values) => {
  const error = {};
  if (!values.title) {
    error.title = 'Title is required';
  }
  if (!values.quantity) {
    error.quantity = 'Quantity is required';
  } else if (isNaN(values.quantity)) {
    error.quantity = 'Quantity should be number';
  } else if (parseInt(values.quantity) < 0) {
    error.quantity = 'Quantity cannot be negative';
  }

  if (!values.basePrice) {
    error.basePrice = 'Base Price is required';
  } else if (isNaN(values.basePrice)) {
    error.basePrice = 'Base Price should be number';
  } else if (parseInt(values.basePrice) < 0) {
    error.basePrice = 'Base Price cannot be negative';
  }

  if (!values.maximumBuy) {
    error.maximumBuy = 'Maximum Buy is required';
  } else if (isNaN(values.maximumBuy)) {
    error.maximumBuy = 'Maximum Buy should be number';
  } else if (parseInt(values.maximumBuy) < 0) {
    error.maximumBuy = 'Maximum Buy cannot be negative';
  }

  if (!values.minimumBuy) {
    error.minimumBuy = 'Minimum Buy is required';
  } else if (isNaN(values.minimumBuy)) {
    error.minimumBuy = 'Minimum Buy should be number';
  } else if (parseInt(values.minimumBuy) < 0) {
    error.minimumBuy = 'Minimun Buy cannot be negative';
  }

  if (
    !!values.maximumBuy &&
    !!values.minimumBuy &&
    parseInt(values.minimumBuy) > parseInt(values.maximumBuy)
  ) {
    error.minimumBuy = 'Minimum Buy should be less';
  }
  return error;
};

function CreateTicketVariant(props) {
  const [ticketVariantData, setTicketVariantData] = React.useState({
    basePrice: props.currentTicketVariantData?.basePrice || '',
    isActive:
      props.currentTicketVariantData?.isActive === true
        ? 'true'
        : props.currentTicketVariantData?.isActive === false
        ? 'false'
        : '',
    maximumBuy: props.currentTicketVariantData?.maximumBuy || '',
    minimumBuy: props.currentTicketVariantData?.minimumBuy || '',
    perks: props.currentTicketVariantData?.perks || '',
    quantity: props.currentTicketVariantData?.quantity || '',
    title: props.currentTicketVariantData?.title || ''
  });
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleSubmit = () => {
    try {
      const validateObj = validate(ticketVariantData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('ticket_variant[title]', ticketVariantData.title);
        formData.append('ticket_variant[perks]', ticketVariantData.perks);
        formData.append('ticket_variant[quantity]', ticketVariantData.quantity);
        formData.append(
          'ticket_variant[maximum_buy]',
          ticketVariantData.maximumBuy
        );
        formData.append(
          'ticket_variant[minimum_buy]',
          ticketVariantData.minimumBuy
        );
        formData.append(
          'ticket_variant[is_active]',
          ticketVariantData.isActive
        );
        formData.append('ticket_variant[currency]', 'INR');
        formData.append(
          'ticket_variant[base_price]',
          `${ticketVariantData.basePrice}00`
        );
        formData.append('ticket_variant[ticket_id]', props.ticketDetails.id);

        props.ticketVariantId
          ? props.updateTicketVariant(
              props.occasionId,
              props.ticketVariantId,
              formData
            )
          : props.occasionTicketVariantCreate(props.occasionId, formData);
        setTicketVariantData({});
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      const ticketVariantValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setTicketVariantData({
        ...ticketVariantData,
        [inputName]: ticketVariantValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ErrorBoundary>
      <SectionContent className="ticket-variant-create-section">
        <div className="ticket-variant-create-form">
          <Form className="ticket-variant-form" onFinish={handleSubmit}>
            <InputGroup
              label="Title"
              name="title"
              placeholder="Enter title"
              onChange={handleInputChange}
              value={ticketVariantData?.title}
              errorMessage={errorMessages.title}
            />
            <InputGroup
              label="Perks"
              name="perks"
              placeholder="Enter perks"
              onChange={handleInputChange}
              value={ticketVariantData?.perks}
            />
            <InputGroup
              label="Quantity"
              name="quantity"
              placeholder="Enter Quantity"
              onChange={handleInputChange}
              value={ticketVariantData?.quantity}
              errorMessage={errorMessages.quantity}
            />
            <InputGroup
              label="Base Price"
              name="basePrice"
              placeholder="Enter Base Price"
              onChange={handleInputChange}
              value={ticketVariantData?.basePrice}
              errorMessage={errorMessages.basePrice}
            />
            <InputGroup
              fieldType="radioGroup"
              label="Status"
              name="isActive"
              options={TICKET_VARIANT_STATUS}
              onChange={handleInputChange}
              value={ticketVariantData?.isActive}
            />
            <InputGroup
              label="Maximum Buy"
              name="maximumBuy"
              placeholder="Enter Maximum Buy"
              onChange={handleInputChange}
              value={ticketVariantData?.maximumBuy}
              errorMessage={errorMessages.maximumBuy}
            />
            <InputGroup
              label="Minimum Buy"
              name="minimumBuy"
              placeholder="Enter Minimum Buy"
              onChange={handleInputChange}
              value={ticketVariantData?.minimumBuy}
              errorMessage={errorMessages.minimumBuy}
            />

            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={'Create Ticket Variant'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentTicketVariantData: getCurrentTicketVariant(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { occasionTicketVariantCreate, updateTicketVariant },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTicketVariant);
