import React from 'react';
import Lottie from 'react-lottie';
import LOADER from './loader.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LOADER,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};
const Loader = () => {
  return <Lottie options={defaultOptions} height={120} width={120} speed={2} />;
};

export default Loader;
