import React from 'react';
import './styles.scss';
import {
  HomeFilled,
  PlusOutlined,
  MinusOutlined,
  MenuFoldOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { Collapse, Menu, Image } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import { TABNAME_MAPPING } from '../../constants/DashboardConstants';
import SOCIANA_EMBLEM from '../../assets/sociana-emblem.png';

// Refer : https://ant.design/components/collapse/

const { Panel } = Collapse;

const PanelHeader = (props) => {
  const getTabIcon = () => {
    switch (props.tabName) {
      case 'occasion':
        return;

      default:
        return <HomeFilled className="icon-size" />;
    }
  };

  return (
    <div
      className={`panel-header-container ${
        props.location.pathname.indexOf(props.currentRouteName) === 0
          ? 'green-filled'
          : 'white-filled'
      }`}>
      {getTabIcon()}
      <span className="tab-name">{props.tabName}</span>
    </div>
  );
};

function TabLayout({ isTabExpaned, tabsToShow, toggleTab }) {
  const location = useLocation();
  const getTabPanes = () => {
    const tabsMasterData = tabsToShow;

    return (
      tabsMasterData &&
      Object.keys(tabsMasterData).length > 0 &&
      Object.keys(tabsMasterData).map((tabName, index, tabs) => {
        return (
          <>
            <Panel
              className={!isTabExpaned ? '' : 'ant-menu-tab-closed'}
              showArrow={true}
              header={
                <PanelHeader
                  tabName={TABNAME_MAPPING[tabName]}
                  location={location}
                  currentRouteName={`/dashboard/${tabName}`}
                />
              }
              key={tabName}>
              {tabsMasterData[tabName].map((item) => {
                return (
                  <div className="tab-item-container">
                    <span
                      className={`tab-item-dot ${
                        location?.pathname?.split('/')?.includes(tabName) &&
                        location?.pathname?.split('/')?.includes(item)
                          ? 'active-tab-item'
                          : 'inactive-tab-item'
                      }`}
                    />
                    <NavLink
                      className="tab-item"
                      to={`/dashboard/${tabName}/${item}`}>
                      {item}
                    </NavLink>
                  </div>
                );
              })}
            </Panel>
            {index !== tabs.length - 1 && <div className="panel-divider" />}
          </>
        );
      })
    );
  };

  return (
    <>
      <Menu mode="inline" inlineCollapsed={isTabExpaned}>
        <div className="drawer-header">
          <NavLink
            to="/dashboard"
            className={!isTabExpaned ? 'show-image' : 'hide-image'}>
            <Image
              className="image-style"
              preview={false}
              src={SOCIANA_EMBLEM}
            />
          </NavLink>
          {!isTabExpaned ? (
            <CloseOutlined onClick={toggleTab} className="toggle-icon-style" />
          ) : (
            <MenuFoldOutlined
              onClick={toggleTab}
              className="toggle-icon-style"
            />
          )}
        </div>
        <Collapse
          accordion
          defaultActiveKey={['1']}
          expandIcon={({ isActive }) => {
            return !isActive ? (
              <PlusOutlined className="expand-icon" />
            ) : (
              <MinusOutlined className="expand-icon" />
            );
          }}
          expandIconPosition="right">
          {getTabPanes()}
        </Collapse>
      </Menu>
    </>
  );
}

export default TabLayout;
