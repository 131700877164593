import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { SyncOutlined } from '@ant-design/icons';
import '../styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import {
  hospitalityTaskView,
  hospitalityTaskDelete
} from '../../../../../../actions/hospitalityTask';
import { hospitalityTaskWithTaskOptionsData } from '../../../../../../selectors/hospitalityTask';
import { TASK_ICONS } from './HospitalityTaskConstants';
import { Button } from 'antd';
import TaskCreate from './CreateTask';

const TaskView = (props) => {
  const location = useLocation();
  const [createOpen, setCreateOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.hospitalityTaskView(
      props.occasionId,
      page,
      props.hospitalityDetails?.id
    );
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const paginationOptions = {
    showTotal: () => (
      <div className="task-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={() => setCreateOpen(true)}>
          Create
        </Button>
      </div>
    )
  };

  const deleteData = (id) => {
    props.hospitalityTaskDelete(
      props.occasionId,
      id,
      props.hospitalityDetails?.id
    );
  };

  const getData = () => {
    if (props.taskData?.length > 0) {
      return props?.taskData?.map((item) => {
        return {
          id: item?.id,
          aliasName: item?.aliasName,
          name: item?.name,
          description: item?.description,
          order: item?.orderNumber,
          taskType: item?.taskType,
          isPublic: item?.isPublic.toString(),
          dataInputType: item?.dataInputType,
          avatarName: (
            <img
              className="hospitality-task-view-image"
              src={TASK_ICONS[item?.avatarName]}></img>
          ),
          createdBy: item?.createdBy,
          groups: item?.groups,
          hospitalityTaskOptions: item?.hospitalityTaskOptions
        };
      });
    } else {
      return [];
    }
  };
  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="task-view-section">
          <div className="task-view-container">
            <TableView
              paginationOptions={paginationOptions}
              isPaginated={true}
              isRouted={true}
              pathname={`${location.pathname}/hospitalityTask/view`}
              paginationLinks={props.taskLinks}
              onPageChange={onPageChange}
              onDelete={deleteData}
              fetchProgress={props.fetchProgress}
              data={getData()}
              blacklistKeys={['hospitalityCategoryId']}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <TaskCreate
        occasionId={props.occasionId}
        hospitalityId={props.hospitalityId}
        hospitalityDetails={props.hospitalityDetails}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  taskData: hospitalityTaskWithTaskOptionsData(store, props),
  taskLinks: store.hospitalityTask.taskLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { hospitalityTaskView, hospitalityTaskDelete },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(TaskView);
