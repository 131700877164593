import { createSelector } from 'reselect';
import { getOccasionTypeName } from '../helpers/occasionHelper';
import { occasionTypes, memberAccessTypes } from './static';

export const occasionGroupMemberEntities = (store) =>
  store.entity.occasionGroupMembers;

export const hospitalityTaskPublicAnswersEntities = (store) =>
  store.entity.hospitalityTaskPublicAnswers;

export const liveEntities = (store) => store.entity.liveStreams;
export const galleryEntities = (store) => store.entity.gallaries;
export const mediaEntities = (store) => store.entity.media;

export const socianaAdmins = (store) => store.entity.socianaAdmins;
export const allEntities = (store) => store.entity;
export const userEntities = (store) => store.entity.users;

export const userDeviceEntities = (store) => store.entity.userDevices;
export const accessControlEntities = (store) => store.entity.accessControls;
export const featureControlEntities = (store) =>
  store.entity.featureAvailabilities;
export const reportEntities = (store) => store.entity.reports;
export const postEntities = (store) => store.entity.posts;
export const likeEntities = (store) => store.entity.likes;
export const commentEntities = (store) => store.entity.comments;
export const adminOccasionEntities = (store) =>
  store.entity.socianaAdminOccasions;
export const pendingInvitationEntities = (store) =>
  store.entity.pendingInvitations;
export const pendingRegistrationEntities = (store) =>
  store.entity.pendingRegistrations;
export const occasionEventsEntities = (store) => store.entity.events;
export const hospitalityCategoryEntities = (store) =>
  store.entity.hospitalityCategories;
export const groupEntities = (store) => store.entity.groups;
export const hospitalityTaskEntities = (store) => store.entity.hospitalityTasks;
export const hospitalityTaskOptionEntities = (store) =>
  store.entity.hospitalityTaskOptions;
export const hospitalityTaskAnswerEntities = (store) =>
  store.entity.hospitalityTaskAnswers;

export const locationTriggerEntities = (store) => store.entity.locationTriggers;
export const actionTriggerEntities = (store) => store.entity.actionTriggers;
export const timeTriggerEntities = (store) => store.entity.timeTriggers;
export const sponsorshipEntities = (store) => store.entity.sponsorships;
export const importantContactEntities = (store) =>
  store.entity.importantContacts;
export const occasionCodeEntities = (store) => store.entity.occasionCodes;
export const occasionLiveVideoEntities = (store) => store.entity.liveVideoUrls;
export const occasionSosEntities = (store) => store.entity.saveOurSouls;
export const awardEntities = (store) => store.entity.awards;
export const alarmEntities = (store) => store.entity.alarms;
export const ticketEntities = (store) => store.entity.tickets;
export const ticketVariantEntities = (store) => store.entity.ticketVariants;
export const ticketVariantFormEntities = (store) =>
  store.entity.ticketVariantForms;
export const questionEntities = (store) => store.entity.questionableForms;
export const questionOptionEntities = (store) =>
  store.entity.questionableFormOptions;

export const ticketPurchaseEntities = (store) => store.entity.ticketPurchases;
export const pendingTicketPurchaseEntities = (store) =>
  store.entity.pendingRegistrationTicketPurchases;
export const scheduleEntities = (store) => store.entity.schedules;
export const itineraryEntities = (store) => store.entity.itineraries;
export const emailSaverEntities = (store) => store.entity.emailSavers;
export const carouselSegmentDetailEntities = (store) =>
  store.entity.carouselSegmentDetails;
export const carouselSegmentEntities = (store) => store.entity.carouselSegments;
export const divisionEntities = (store) => store.entity.divisionSegments;
export const occasionSmsEntities = (store) => store.entity.smsSavers;
export const subDivisionEntities = (store) => store.entity.subdivisionSegments;
export const guestEntities = (store) => store.entity.hospitalityGuests;
export const albumEntities = (store) => store.entity.albums;
export const eventMembersEntities = (store) => store.entity.eventCollaborators;
export const eventPendingInvitationEntities = (store) =>
  store.entity.pendingInvitations;
export const eventPendingRegistrationEntities = (store) =>
  store.entity.pendingRegistrations;
export const occasionAppEntities = (store) => store.entity.occasionApps;
export const occasionRoleNameEntities = (store) =>
  store.entity.occasionRoleNames;
export const respondedTaskEntities = (store) =>
  store.entity.hospitalityTaskAnswers;
export const hospitalityGuestEntities = (store) =>
  store.entity.hospitalityGuests;
export const contactUsEntities = (store) => store.entity.contactUsForms;
export const occasionPollPostEntities = (store) => store.entity.pollPosts;
export const occasionPollPostOptionEntities = (store) =>
  store.entity.pollPostOptions;
export const codeEntities = (store) => store.entity.occasionCodes;
export const occasionWhiteListEntities = (store) =>
  store.entity.occasionWhiteLists;

export const eventAffairEntities = (store) => store.entity.eventAffairs;
export const eventCollaboratorEntities = (store) =>
  store.entity.eventCollaborators;
export const eventAffairEssenceEntities = (store) =>
  store.entity.eventAffairEssences;
export const eventAffairEntertainmentEntities = (store) =>
  store.entity.eventAffairEntertainments;
export const eventAffairFoodEntities = (store) => store.entity.eventAffairFoods;

export const pollPostExplanationEntities = (store) =>
  store.entity.pollPostExplanations;

export const discussionEntities = (store) => store.entity.feedbacks;
export const enquiryEntities = (store) => store.entity.enquiryRequests;
export const occasionTabSettingEntities = (store) =>
  store.entity.occasionTabSettings;
export const activityTrackerEntities = (store) => store.entity.activityTrackers;
export const activityTrackerPresentUserEntities = (store) =>
  store.entity.activityTrackerPresentUsers;
export const speakerEntities = (store) => store.entity.speakers;
export const speakerReferenceEntities = (store) =>
  store.entity.speakerReferences;

export const hardLogoutEntities = (store) => store.entity.hardLogouts;

export const hardLogoutUserEntities = (store) => store.entity.userHardLogouts;

export const hospitalityGoogleSheetEntities = (store) =>
  store.entity.occasionGoogleSheets;

export const fixedGroupPostSettingEntities = (store) =>
  store.entity.fixedGroupPostSettings;

export const competitionEntities = (store) => store.entity.competitions;
export const participantEntities = (store) => store.entity.participants;
export const whatsappTemplateModelablesEntities = (store) =>
  store.entity.whatsappTemplateModelables;
export const whatsappTemplateMessagesEntities = (store) =>
  store.entity.whatsappTemplateMessages;

export const whatsappMessagesEntities = (store) =>
  store.entity.whatsappMessages;

export const whatsappTemplateParamsEntities = (store) =>
  store.entity.whatsappTemplateParams;

export const occasionResourcesEntities = (store) =>
  store.entity.occasionResources;

export const categoryStatusEntities = (store) =>
  store.entity.occasionResourceCategoryStatuses;

export const occasionEntities = createSelector(
  occasionTypes,
  allEntities,
  (types, entities) => {
    let res = {};
    if (
      types &&
      types.length > 0 &&
      entities &&
      Object.keys(entities).length > 0
    ) {
      types.forEach((type) => {
        const typeName = getOccasionTypeName(type);
        const entity = entities[typeName];
        if (entity) {
          res = { ...res, ...entity };
        }
      });
    }
    return res;
  }
);

export const memberEntities = createSelector(
  memberAccessTypes,
  allEntities,
  (types, entities) => {
    const storedMembers = {};

    if (
      types &&
      types.length > 0 &&
      entities &&
      Object.keys(entities).length > 0
    ) {
      types.forEach((type) => {
        const typeName = `${String(type).toLowerCase()}s`;
        const entity = entities[typeName];
        if (entity) {
          Object.keys(entity).forEach((memberId) => {
            const currentMember = entity[memberId];

            if (!storedMembers[memberId]) {
              storedMembers[memberId] = currentMember;
            } else {
              //  if already a prevData exists
              const prevMemberUpdatedAt =
                storedMembers[memberId].updatedAt || null;

              if (
                !prevMemberUpdatedAt ||
                new Date(currentMember.updatedAt) >
                  new Date(storedMembers[memberId].updatedAt)
              ) {
                //  both having updatedAt field then
                storedMembers[memberId] = currentMember;
              }
            }
          });
        }
      });
    }
    return storedMembers;
  }
);
