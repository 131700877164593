import {
  CREATE_ADMIN,
  CREATE_ADMIN_FAILED,
  CREATE_ADMIN_SUCCESS,
  GET_ADMIN_PAGINATION,
  GET_ADMIN_PAGINATION_FAILED,
  GET_ADMIN_PAGINATION_SUCCESS,
  GET_ADMIN_OCCASION_MEMBER,
  GET_ADMIN_OCCASION_MEMBER_SUCCESS,
  GET_ADMIN_OCCASION_MEMBER_FAILED,
  CREATE_ADMIN_OCCASION,
  CREATE_ADMIN_OCCASION_SUCCESS,
  CREATE_ADMIN_OCCASION_FAILED,
  EDIT_ADMIN,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_FAILED,
  GET_ADMIN_BY_SEARCH,
  GET_ADMIN_BY_SEARCH_SUCCESS,
  GET_ADMIN_BY_SEARCH_FAILED,
  UPDATE_ADMIN_OCCASION,
  UPDATE_ADMIN_OCCASION_FAILED,
  UPDATE_ADMIN_OCCASION_SUCCESS,
  DELETE_ADMIN_OCCASION_MEMBER,
  DELETE_ADMIN_OCCASION_MEMBER_FAILED,
  DELETE_ADMIN_OCCASION_MEMBER_SUCCESS
} from '../constants/actionTypes';

export const createAdmin = (formData, callback) => ({
  type: CREATE_ADMIN,
  payload: { formData },
  callback
});

export const createAdminSuccess = (data) => ({
  type: CREATE_ADMIN_SUCCESS,
  payload: { data }
});

export const createAdminFailed = (error) => ({
  type: CREATE_ADMIN_FAILED,
  error
});

export const getAdminWithPagination = (page, isActive) => ({
  type: GET_ADMIN_PAGINATION,
  payload: { page, isActive }
});

export const getAdminWithPaginationSuccess = (data, links, isActive, meta) => ({
  type: GET_ADMIN_PAGINATION_SUCCESS,
  payload: { data, links, isActive, meta }
});

export const getAdminWithPaginationFailed = (error, isActive) => ({
  type: GET_ADMIN_PAGINATION_FAILED,
  payload: { isActive },
  error
});

export const createAdminOccasion = (ocId, formData, adminId, callback) => ({
  type: CREATE_ADMIN_OCCASION,
  payload: { ocId, formData, adminId },
  callback
});

export const createAdminOccasionSuccess = (data, included, adminId) => ({
  type: CREATE_ADMIN_OCCASION_SUCCESS,
  payload: { data, included, adminId }
});

export const createAdminOccasionFailed = (error) => ({
  type: CREATE_ADMIN_OCCASION_FAILED,
  error
});

export const getAdminOccasionMember = (page, adminId, adminStatus) => ({
  type: GET_ADMIN_OCCASION_MEMBER,
  payload: { page, adminId, adminStatus }
});

export const getAdminOccasionMemberSuccess = (
  data,
  included,
  links,
  adminId,
  meta
) => ({
  type: GET_ADMIN_OCCASION_MEMBER_SUCCESS,
  payload: { data, included, links, adminId, meta }
});

export const getAdminOccasionMemberFailed = (error) => ({
  type: GET_ADMIN_OCCASION_MEMBER_FAILED,
  error
});

export const updateAdmin = (adminId, formData, callback) => ({
  type: EDIT_ADMIN,
  payload: { adminId, formData },
  callback
});

export const updateAdminSuccess = (data) => ({
  type: EDIT_ADMIN_SUCCESS,
  payload: { data }
});

export const updateAdminFailed = (error) => ({
  type: EDIT_ADMIN_FAILED,
  error
});

export const getAdminBySearch = (searchData, status, page) => ({
  type: GET_ADMIN_BY_SEARCH,
  payload: { searchData, status, page }
});

export const getAdminBySearchSuccess = (
  data,
  included,
  isActive,
  links,
  meta
) => ({
  type: GET_ADMIN_BY_SEARCH_SUCCESS,
  payload: { data, included, isActive, links, meta }
});

export const getAdminBySearchFailed = (error) => ({
  type: GET_ADMIN_BY_SEARCH_FAILED,
  error
});

export const updateAdminOccasion = (
  ocId,
  adminOccasionId,
  formData,
  adminId,
  callback
) => ({
  type: UPDATE_ADMIN_OCCASION,
  payload: { ocId, adminOccasionId, formData, adminId },
  callback
});

export const updateAdminOccasionSuccess = (data, included, adminId) => ({
  type: UPDATE_ADMIN_OCCASION_SUCCESS,
  payload: { data, included, adminId }
});

export const updateAdminOccasionFailed = (error) => ({
  type: UPDATE_ADMIN_OCCASION_FAILED,
  error
});

export const deleteAdminOccasionMember = (ocId, adminOccasionId, adminId) => ({
  type: DELETE_ADMIN_OCCASION_MEMBER,
  payload: { ocId, adminOccasionId, adminId }
});

export const deleteAdminOccasionMemberSuccess = (
  data,
  included,
  links,
  adminId,
  meta
) => ({
  type: DELETE_ADMIN_OCCASION_MEMBER_SUCCESS,
  payload: { data, included, links, adminId, meta }
});

export const deleteAdminOccasionMemberFailed = (error) => ({
  type: DELETE_ADMIN_OCCASION_MEMBER_FAILED,
  error
});
