import React, { useState, useCallback } from 'react';
import { Image } from '../../../../../../../components/Image';
import { VideoPlayer } from '../../../../../../../components/VideoPlayer';
import { getImageSource } from '../../../../../../../helpers/imageHelper';
import {
  LoadingOutlined,
  EyeOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import './styles.scss';

function AlbumVideo({ video, onDeleteClick }) {
  const [toggleModal, setToggleModal] = useState(false);
  const [loader, setLoader] = useState(true);

  const toggleModalClick = useCallback(() => {
    setToggleModal((val) => !val);
  }, []);

  const onImageLoad = useCallback(() => {
    setLoader(false);
  }, []);

  const onDelete = (e) => {
    e.stopPropagation();
    const result = window.confirm(
      'Are you sure you want to delete this video?'
    );
    if (result && typeof onDeleteClick === 'function') {
      onDeleteClick(video.id);
    }
  };

  return (
    <div className="gallery-video-box">
      <Image
        className={`gallery-video ${loader ? 'invisible' : 'visible'}`}
        preview={false}
        imageUrl={getImageSource(video.thumbUrl)}
        onLoad={onImageLoad}
      />
      {loader && (
        <div className="ant-image gallery-mask show-element">
          <LoadingOutlined />
        </div>
      )}
      {!loader && (
        <div
          className="ant-image gallery-mask hide-element"
          onClick={toggleModalClick}>
          <EyeOutlined />
          &nbsp;Preview
        </div>
      )}
      {video.user?.name && (
        <div
          className="ant-image gallery-name show-element"
          onClick={toggleModalClick}>
          <span
            style={{
              overflow: 'hidden',
              display: 'block',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
            {video?.user?.name}
          </span>
          {typeof onDeleteClick === 'function' && (
            <DeleteOutlined
              style={{ color: 'red', cursor: 'pointer' }}
              onClick={onDelete}
            />
          )}
        </div>
      )}
      <Modal
        bodyStyle={{ padding: 0 }}
        visible={toggleModal}
        onCancel={toggleModalClick}
        centered
        closable={false}
        footer={null}>
        <VideoPlayer
          className="gallery-video"
          preview
          videoUrl={getImageSource(video?.fileUrl)}
          cdnVideoUrl={getImageSource(video?.cdnFileUrl, null, true)}
        />
      </Modal>
    </div>
  );
}

export default AlbumVideo;
