import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { getImageSource } from '../../../../../helpers/imageHelper';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import {
  occasionGroupCreate,
  occasionGroupUpdate
} from '../../../../../actions/group';

const validateGroupData = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Group Name is required';
  }
  return errors;
};

const CreateGroup = (props) => {
  const [getGroupDetails, setGetGroupDetails] = React.useState({
    name: props.groupDetails?.name || '',
    description: props.groupDetails?.description || '',
    groupImage: props.groupDetails?.groupPictureUrl
      ? [{ url: getImageSource(props.groupDetails?.groupPictureUrl) }]
      : null
  });
  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleGroupCreate = () => {
    try {
      const validateObj = validateGroupData(getGroupDetails);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        const formData = new FormData();
        formData.append('group[name]', getGroupDetails?.name);
        formData.append('group[description]', getGroupDetails?.description);
        if (
          getGroupDetails.groupImage &&
          getGroupDetails.groupImage[0]?.originFileObj
        ) {
          formData.append(
            'group[group_picture]',
            getGroupDetails.groupImage[0].originFileObj
          );
        }
        props.groupId
          ? props.occasionGroupUpdate(
              props.occasionId,
              props.groupId,
              formData,
              callback
            )
          : props.occasionGroupCreate(props.occasionId, formData, callback);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const callback = () => {
    setGetGroupDetails({});
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (isInitiallyDisabled) {
          setInitiallyDisabled(false);
        }
        if (type === 'name') {
          value = e.target.value;
          name = type;
        }
        if (type === 'description') {
          value = e.target.value;
          name = type;
        }
        if (type === 'groupImage') {
          value = e.fileList;
          name = type;
        }
        const groupDetailsValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        setGetGroupDetails({
          ...getGroupDetails,
          [inputName]: groupDetailsValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="occasion-group-section">
        <div className="occasion-group-create">
          <Form className="group-form-container" onFinish={handleGroupCreate}>
            <InputGroup
              label="Group Name"
              placeholder="Enter Name"
              name="name"
              onChange={handleChange}
              value={getGroupDetails?.name}
              errorMessage={errorMessages?.name}
            />
            <InputGroup
              label="Description"
              placeholder="Enter description"
              name="description"
              fieldType="textArea"
              onChange={handleChange}
              value={getGroupDetails?.description}
            />
            <InputGroup
              fieldType="file"
              label="Group Icon"
              accept="image/*"
              fileList={getGroupDetails?.groupImage}
              onChange={handleChange}
              name="groupImage"
            />
            <SubmitButton
              buttonText={props.groupId ? 'Save Changes' : 'Create'}
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      occasionGroupCreate,
      occasionGroupUpdate
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);
