import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import './styles.scss';
import { SectionContent } from '../../../../../../../components/Section';
import ErrorBoundary from '../../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../../components/Table';
import CreateEventAffairEntertainment from './Create';
import {
  eventAffairEntertainmentView,
  eventAffairEntertainmentDelete
} from '../../../../../../../actions/eventAffairEntertainment';
import { getAllEventAffairEntertainments } from '../../../../../../../selectors/eventAffair';

const ViewEventAffairEntertainment = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [entertainmentId, setEntertainmentId] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.eventAffairEntertainmentView(
      props.occasionId,
      page,
      props.eventId,
      props.eventAffairId
    );
  };

  const location = useLocation();
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleCreate = () => {
    setEntertainmentId('');
    setCreateOpen(true);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={handleCreate}>
          Create
        </Button>
      </div>
    )
  };

  const editData = (id) => {
    setCreateOpen(true);
    setEntertainmentId(id);
  };

  const deleteData = (id) => {
    props.eventAffairEntertainmentDelete(
      props.occasionId,
      props.eventAffairId,
      props.eventId,
      id
    );
  };

  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="event-affair-entertainment-view-section">
          <div className="event-affair-entertainment-view-container">
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              isRouted={false}
              onPageChange={onPageChange}
              isEditable={editData}
              onDelete={deleteData}
              data={props.eventAffairEntertainmentData}
              fetchProgress={props.fetchProgress}
              paginationLinks={props.eventAffairEntertainmentLinks}
              normalTime={true}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <CreateEventAffairEntertainment
        entertainmentId={entertainmentId}
        occasionId={props.occasionId}
        eventId={props.eventId}
        eventAffairId={props.eventAffairId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  eventAffairEntertainmentData: getAllEventAffairEntertainments(store, props),
  eventAffairEntertainmentLinks:
    store.eventAffairEntertainment.eventAffairEntertainmentLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { eventAffairEntertainmentView, eventAffairEntertainmentDelete },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(ViewEventAffairEntertainment);
