import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  eventAffairEntertainmentCreateApi,
  eventAffairEntertainmentDeleteApi,
  eventAffairEntertainmentUpdateApi,
  eventAffairEntertainmentViewApi
} from '../api/EventAffairEntertainment';
import {
  EVENT_AFFAIR_ENTERTAINMENT_CREATE,
  EVENT_AFFAIR_ENTERTAINMENT_DELETE,
  EVENT_AFFAIR_ENTERTAINMENT_UPDATE,
  EVENT_AFFAIR_ENTERTAINMENT_VIEW
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  eventAffairEntertainmentCreateFailed,
  eventAffairEntertainmentCreateSuccess,
  eventAffairEntertainmentDeleteFailed,
  eventAffairEntertainmentDeleteSuccess,
  eventAffairEntertainmentUpdateFailed,
  eventAffairEntertainmentUpdateSuccess,
  eventAffairEntertainmentViewFailed,
  eventAffairEntertainmentViewSuccess
} from '../actions/eventAffairEntertainment';

export function* eventAffairEntertainmentCreateSaga(action) {
  try {
    const result = yield call(
      eventAffairEntertainmentCreateApi,
      action.payload.ocId,
      action.payload.formData,
      action.payload.eventId,
      action.payload.eventAffairId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventAffairEntertainmentCreateSuccess(
          action.payload.ocId,
          action.payload.eventId,
          action.payload.eventAffairId,
          result.data,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(eventAffairEntertainmentCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(eventAffairEntertainmentCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventAffairEntertainmentViewSaga(action) {
  try {
    const result = yield call(
      eventAffairEntertainmentViewApi,
      action.payload.ocId,
      action.payload.page,
      action.payload.eventId,
      action.payload.eventAffairId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventAffairEntertainmentViewSuccess(
          action.payload.ocId,
          action.payload.eventId,
          action.payload.eventAffairId,
          result.data,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(eventAffairEntertainmentViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventAffairEntertainmentUpdateSaga(action) {
  try {
    const result = yield call(
      eventAffairEntertainmentUpdateApi,
      action.payload.ocId,
      action.payload.eventAffairId,
      action.payload.eventId,
      action.payload.entertainmentId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventAffairEntertainmentUpdateSuccess(
          action.payload.ocId,
          action.payload.eventId,
          action.payload.eventAffairId,
          result.data,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(eventAffairEntertainmentUpdateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(eventAffairEntertainmentUpdateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventAffairEntertainmentDeleteSaga(action) {
  try {
    const result = yield call(
      eventAffairEntertainmentDeleteApi,
      action.payload.ocId,
      action.payload.eventAffairId,
      action.payload.eventId,
      action.payload.entertainmentId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventAffairEntertainmentDeleteSuccess(
          action.payload.ocId,
          action.payload.eventId,
          action.payload.eventAffairId,
          result.data,
          result.included,
          result.links
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(eventAffairEntertainmentDeleteFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* eventAffairEntertainmentActionWatcher() {
  yield takeLatest(
    EVENT_AFFAIR_ENTERTAINMENT_CREATE,
    progressSaga,
    eventAffairEntertainmentCreateSaga
  );
  yield takeLatest(
    EVENT_AFFAIR_ENTERTAINMENT_VIEW,
    progressSaga,
    eventAffairEntertainmentViewSaga
  );

  yield takeLatest(
    EVENT_AFFAIR_ENTERTAINMENT_UPDATE,
    progressSaga,
    eventAffairEntertainmentUpdateSaga
  );
  yield takeLatest(
    EVENT_AFFAIR_ENTERTAINMENT_DELETE,
    progressSaga,
    eventAffairEntertainmentDeleteSaga
  );
}
