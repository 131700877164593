import {
  GET_ENQUIRY_PAGINATION,
  GET_ENQUIRY_PAGINATION_SUCCESS,
  GET_ENQUIRY_PAGINATION_FAILED
} from '../constants/actionTypes';

export const getEnquiryWithPagination = (page) => ({
  type: GET_ENQUIRY_PAGINATION,
  payload: { page }
});

export const getEnquiryWithPaginationSuccess = (data, links, page, meta) => ({
  type: GET_ENQUIRY_PAGINATION_SUCCESS,
  payload: { data, links, page, meta }
});

export const getEnquiryWithPaginationFailed = (error) => ({
  type: GET_ENQUIRY_PAGINATION_FAILED,
  error
});
