import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { Form, AutoComplete, Button, Checkbox } from 'antd';
import { SectionContent } from '../../../../../components/Section';
import { CloseOutlined } from '@ant-design/icons';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup/index';
import { Image } from '../../../../../components/Image';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import { getAllHospitalityTasksByOccasion } from '../../../../../selectors/hospitalityTask';
import { getImageSource } from '../../../../../helpers/imageHelper';
import { getAllTasks } from '../../../../../actions/hospitalityTask';
import {
  getExslExport,
  downloadExslExport
} from '../../../../../actions/hospitality';
import { ORDER_BY } from './Constant';
import useLinkDownloader from '../../../../../hooks/useLinkDownloader';

const { Option } = AutoComplete;

const ExslExport = (props) => {
  const [exslExportData, setExslExportData] = React.useState({
    taskIds: [],
    orderWith: [],
    orderBy: 'asc',
    remember: ''
  });
  const [page, setPage] = React.useState(1);
  const { downloadLinkFile } = useLinkDownloader();

  const onPageChange = (page) => {
    props.getAllTasks(props.occasionId, page);
  };

  React.useEffect(() => {
    onPageChange(page);
  }, [page]);

  React.useEffect(() => {
    props.getExslExport(props.occasionId, exslExportData.taskIds);
  }, [exslExportData.taskIds]);

  const getPage = (str) => {
    const decodedURI = decodeURI(str);
    const number = decodedURI
      .split('page[number]')[1]
      .replace('=', '')
      .split('&');
    return (number && parseInt(number)) || 1;
  };

  const onEndReached = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height >= scrollHeight - 20) {
      if (props.allTasksLinks?.next) {
        const page = getPage(props.allTasksLinks?.next);
        setPage(page);
      }
    }
  };

  const handleSubmit = () => {
    try {
      props.downloadExslExport(
        props.occasionId,
        exslExportData.remember === true ? true : false,
        exslExportData.taskIds,
        exslExportData.orderWith.length > 0 ? exslExportData.orderBy : '',
        exslExportData.orderWith,
        (blobUrl) => {
          downloadLinkFile(
            blobUrl,
            `${props.occasionName}_Hospitality_Task_Answers`,
            'xlsx'
          );
          setExslExportData({
            taskIds: [],
            orderWith: [],
            orderBy: 'asc',
            remember: ''
          });
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (e.target.name === 'remember') {
          value = e.target.checked;
          name = e.target.name;
        }

        const exslValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;

        setExslExportData({
          ...exslExportData,
          [inputName]: exslValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="hospitality-section">
        <div className="hospitality-create-form">
          <Form className="hospitality-form-container" onFinish={handleSubmit}>
            <InputGroup
              fieldType="dropdown"
              className="group-search"
              mode="multiple"
              name="taskIds"
              label="Select Hospitality Tasks"
              placeholder="Select Hospitality Tasks"
              onChange={handleChange}
              onPopupScroll={onEndReached}
              value={exslExportData?.taskIds}>
              {props.allTasksData?.length > 0 &&
                props.allTasksData.map((item) => {
                  return (
                    <Option value={item?.id} key={item?.id}>
                      <div className="options-container">
                        <div className="occasion-group-details-container">
                          <p className="occasion-group-name">{item?.name}</p>
                          {item.aliasName ? (
                            <p
                              style={{
                                fontSize: '10px',
                                marginTop: '0px'
                              }}>
                              {item?.aliasName}
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </Option>
                  );
                })}
            </InputGroup>

            <InputGroup
              fieldType="dropdown"
              className="group-search"
              mode="multiple"
              name="orderWith"
              label="Order With"
              placeholder="Select Order With"
              onChange={handleChange}
              value={exslExportData?.orderWith}>
              {props.orderWithData?.length > 0 &&
                props.orderWithData.map((item) => {
                  return (
                    <Option
                      value={item}
                      key={item}
                      disabled={
                        exslExportData.orderWith.length > 1
                          ? exslExportData.orderWith.includes(item)
                            ? false
                            : true
                          : false
                      }>
                      <div className="options-container">
                        <div className="occasion-group-details-container">
                          <p className="occasion-group-name">{item}</p>
                        </div>
                      </div>
                    </Option>
                  );
                })}
            </InputGroup>

            {exslExportData?.orderWith.length > 0 ? (
              <InputGroup
                fieldType="dropdown"
                label="Order By"
                options={ORDER_BY}
                name="orderBy"
                onChange={handleChange}
                value={exslExportData?.orderBy}
              />
            ) : (
              ''
            )}

            <Form.Item className="form-item">
              <Checkbox
                name="remember"
                onChange={handleChange}
                className="checkbox-input">
                Show Guest Details As User Details
              </Checkbox>
            </Form.Item>

            <SubmitButton
              buttonText="Download"
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  allTasksData: getAllHospitalityTasksByOccasion(store, props),
  allTasksLinks: store.hospitalityTask.allTasksLinks,
  orderWithData: store.hospitality.exslExportData
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getAllTasks, getExslExport, downloadExslExport },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ExslExport);
