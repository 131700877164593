import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import Tabs from '../../../../../../components/Tabs';
import {
  currentOccasionId,
  currentDivisionId
} from '../../../../../../selectors/dynamicData';
import { getCurrentOccasion } from '../../../../../../selectors/occasion';
import { TabsHeader } from '../../../../../../components/TabsHeader';
import { getCurrentDivision } from '../../../../../../selectors/division';
import SubdivisionView from '../Division/Subdivision/View';

function DivisionDetails(props) {
  const getSubdivisionComponent = () => {
    return (
      <SubdivisionView
        occasionId={props.occasionId}
        divisionId={props.divisionId}
      />
    );
  };

  const tabPanes = [
    {
      tabName: 'Subdivision',
      tabKey: '1',
      children: getSubdivisionComponent()
    }
  ];
  const goBack = () => {
    props.history.goBack();
  };
  return (
    <ErrorBoundary>
      <SectionContent className="occasion-division-details-section">
        <TabsHeader
          name={props.currentOccasion.name}
          extraData={[
            props.currentOccasion.type,
            props.currentOccasion.guestType,
            props.currentDivision.title
          ]}
          wrapperClass="section-details"
        />
        <div className="occasion-division-details-container">
          <Tabs
            defaultActiveKey="1"
            tabPanes={tabPanes}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  occasionId: currentOccasionId(store, props),
  currentOccasion: getCurrentOccasion(store, props),
  divisionId: currentDivisionId(store, props),
  currentDivision: getCurrentDivision(store, props)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DivisionDetails);
