import { union } from 'lodash';
import {
  GET_PROVIDED_ANSWERED_USERS_FAILED,
  GET_PROVIDED_ANSWERED_USERS_SUCCESS,
  GET_WHATSAPP_MESSAGE_MODELABLE_FAILED,
  GET_WHATSAPP_MESSAGE_MODELABLE_SUCCESS,
  GET_WHATSAPP_TEMPLATE_MODELABLES_FAILED,
  GET_WHATSAPP_TEMPLATE_MODELABLES_SUCCESS,
  CLEAR_PROVIDED_USER_STORE
} from '../constants/actionTypes';

const initialState = {
  allWhatsappTemplateModelables: null,
  whatsappTemplateModelablesLinks: null,
  allUnansweredWhatsappTemplateModelables: null,
  unansweredWhatsappTemplateModelablesLinks: null,

  allProvidedAnsweredUsers: null,
  providedAnsweredUsersLinks: null,
  whatsappMessagesData: null,
  whatsappMessagesLinks: null,
  providedCount: 0,
  isAnswered: false,
  allProvidedUnansweredUsers: null,
  providedUnansweredUsersLinks: null,
  unansweredWhatsappMessagesData: null,
  unansweredWhatsappMessagesLinks: null,
  unansweredCount: 0,
  error: null
};

const whatsappForProvidedTask = (state = initialState, action) => {
  switch (action.type) {
    case GET_WHATSAPP_MESSAGE_MODELABLE_SUCCESS: {
      const { data, taskId, operationType, links, meta } = action.payload;
      if (operationType === 'AnsweredHospitalityTask') {
        const occasionGroupedData = state.whatsappMessagesData || {};
        const currentOccasionNewIds =
          data && data?.length > 0 ? data.map((el) => el.id) : [];

        return {
          ...state,
          whatsappMessagesData: {
            ...occasionGroupedData,
            [taskId]: [...currentOccasionNewIds]
          },
          whatsappMessagesLinks: links,
          providedCount: meta?.totalCount || 0,
          isAnswered: true
        };
      } else if (operationType === 'UnansweredHospitalityTask') {
        const occasionGroupedData = state.unansweredWhatsappMessagesData || {};
        const currentOccasionNewIds =
          data && data?.length > 0 ? data.map((el) => el.id) : [];

        return {
          ...state,
          unansweredWhatsappMessagesData: {
            ...occasionGroupedData,
            [taskId]: [...currentOccasionNewIds]
          },
          unansweredWhatsappMessagesLinks: links,
          unansweredCount: meta?.totalCount || 0,
          isAnswered: false
        };
      }
    }

    case GET_WHATSAPP_TEMPLATE_MODELABLES_SUCCESS: {
      const { data, taskId, operationType, links } = action.payload;
      if (operationType === 'AnsweredHospitalityTask') {
        const AllGroupedData = state.allWhatsappTemplateModelables || {};
        const allData = AllGroupedData?.[taskId] || [];
        const currentOccasionNewIds =
          data && data?.length > 0 ? data.map((el) => el.id) : [];

        return {
          ...state,
          allWhatsappTemplateModelables: {
            ...AllGroupedData,
            [taskId]: union([...allData, ...currentOccasionNewIds])
          },
          whatsappTemplateModelablesLinks: links,
          isAnswered: true
        };
      } else if (operationType === 'UnansweredHospitalityTask') {
        const AllGroupedData =
          state.allUnansweredWhatsappTemplateModelables || {};
        const allData = AllGroupedData?.[taskId] || [];
        const currentOccasionNewIds =
          data && data?.length > 0 ? data.map((el) => el.id) : [];

        return {
          ...state,
          allUnansweredWhatsappTemplateModelables: {
            ...AllGroupedData,
            [taskId]: union([...allData, ...currentOccasionNewIds])
          },
          unansweredWhatsappTemplateModelablesLinks: links,
          isAnswered: false
        };
      }
    }

    case GET_PROVIDED_ANSWERED_USERS_SUCCESS: {
      const { data, userType, taskId, links } = action.payload;
      if (userType === 'answered') {
        const AllGroupedData = state.allProvidedAnsweredUsers || {};
        const allData = AllGroupedData?.[taskId] || [];
        const currentOccasionNewIds =
          data && data?.length > 0 ? data.map((el) => el.id) : [];

        return {
          ...state,
          allProvidedAnsweredUsers: {
            ...AllGroupedData,
            [taskId]: union([...allData, ...currentOccasionNewIds])
          },
          providedAnsweredUsersLinks: links,
          isAnswered: true
        };
      } else if (userType === 'unanswered') {
        const AllGroupedData = state.allProvidedUnansweredUsers || {};
        const allData = AllGroupedData?.[taskId] || [];
        const currentOccasionNewIds =
          data && data?.length > 0 ? data.map((el) => el.id) : [];

        return {
          ...state,
          allProvidedUnansweredUsers: {
            ...AllGroupedData,
            [taskId]: union([...allData, ...currentOccasionNewIds])
          },
          providedUnansweredUsersLinks: links,
          isAnswered: false
        };
      }
    }

    case CLEAR_PROVIDED_USER_STORE: {
      const { userType, taskId } = action.payload;
      if (userType === 'answered') {
        return {
          ...state,
          allProvidedAnsweredUsers: {
            [taskId]: null
          },
          providedAnsweredUsersLinks: null,
          isAnswered: true
        };
      } else if (userType === 'unanswered') {
        return {
          ...state,
          allProvidedUnansweredUsers: {
            [taskId]: null
          },
          providedUnansweredUsersLinks: null,
          isAnswered: false
        };
      }
    }

    case GET_WHATSAPP_MESSAGE_MODELABLE_FAILED:
    case GET_WHATSAPP_TEMPLATE_MODELABLES_FAILED:
    case GET_PROVIDED_ANSWERED_USERS_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default whatsappForProvidedTask;
