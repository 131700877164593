import {
  ACTIVITY_TRACKER_PRESENT_USER_CREATE,
  ACTIVITY_TRACKER_PRESENT_USER_CREATE_FAILED,
  ACTIVITY_TRACKER_PRESENT_USER_CREATE_SUCCESS,
  ACTIVITY_TRACKER_PRESENT_USER_VIEW,
  ACTIVITY_TRACKER_PRESENT_USER_VIEW_FAILED,
  ACTIVITY_TRACKER_PRESENT_USER_VIEW_SUCCESS,
  DELETE_ACTIVITY_TRACKER_PRESENT_USER,
  DELETE_ACTIVITY_TRACKER_PRESENT_USER_FAILED,
  DELETE_ACTIVITY_TRACKER_PRESENT_USER_SUCCESS,
  SEARCH_USERS_BY_OCCASION,
  SEARCH_USERS_BY_OCCASION_FAILED,
  SEARCH_USERS_BY_OCCASION_SUCCESS
} from '../constants/actionTypes';
export const activityTrackerPresentUserCreate = (
  ocId,
  trackerId,
  formData,
  callback
) => ({
  type: ACTIVITY_TRACKER_PRESENT_USER_CREATE,
  payload: { ocId, trackerId, formData },
  callback
});

export const activityTrackerPresentUserCreateSuccess = (
  data,
  included,
  ocId,
  trackerId
) => ({
  type: ACTIVITY_TRACKER_PRESENT_USER_CREATE_SUCCESS,
  payload: { data, included, ocId, trackerId }
});

export const activityTrackerPresentUserCreateFailed = (error) => ({
  type: ACTIVITY_TRACKER_PRESENT_USER_CREATE_FAILED,
  error
});

export const activityTrackerPresentUserView = (ocId, trackerId, page) => ({
  type: ACTIVITY_TRACKER_PRESENT_USER_VIEW,
  payload: { ocId, trackerId, page }
});

export const activityTrackerPresentUserViewSuccess = (
  data,
  included,
  ocId,
  trackerId,
  links,
  meta
) => ({
  type: ACTIVITY_TRACKER_PRESENT_USER_VIEW_SUCCESS,
  payload: { data, included, ocId, trackerId, links, meta }
});

export const activityTrackerPresentUserViewFailed = (error) => ({
  type: ACTIVITY_TRACKER_PRESENT_USER_VIEW_FAILED,
  error
});

export const deleteActivityTrackerPresentUser = (
  ocId,
  trackerId,
  presentUserId,
  callback
) => ({
  type: DELETE_ACTIVITY_TRACKER_PRESENT_USER,
  payload: { ocId, trackerId, presentUserId },
  callback
});

export const deleteActivityTrackerPresentUserSuccess = (
  ocId,
  trackerId,
  data
) => ({
  type: DELETE_ACTIVITY_TRACKER_PRESENT_USER_SUCCESS,
  payload: { ocId, trackerId, data }
});

export const deleteActivityTrackerPresentUserFailed = (error) => ({
  type: DELETE_ACTIVITY_TRACKER_PRESENT_USER_FAILED,
  error
});

export const searchUserByOccasion = (ocId, query) => ({
  type: SEARCH_USERS_BY_OCCASION,
  payload: { ocId, query }
});

export const searchUserByOccasionSuccess = (data, included) => ({
  type: SEARCH_USERS_BY_OCCASION_SUCCESS,
  payload: { data, included }
});

export const searchUserByOccasionFailed = (error) => ({
  type: SEARCH_USERS_BY_OCCASION_FAILED,
  error
});
