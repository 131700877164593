import request from './request';

export function createSpeaker(
  ocId,
  referencable_type,
  referencable_id,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/speakers?referencable_type=${referencable_type}&referencable_id=${referencable_id}`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function viewSpeaker(ocId, pageNumber, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/speakers?page=${pageNumber}`,
    {
      method: 'GET',
      token
    }
  );
}

export function editSpeakerApi(ocId, speakerId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/speakers/${speakerId}`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function deleteSpeakerApi(ocId, speakerId, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/speakers/${speakerId}`, {
    method: 'DELETE',
    token
  });
}
