import React, { useEffect } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UnAuthorizedRoutes from './UnAuthorizedRoutes';
import Dashboard from '../containers/Dashboard';
import { RoutingEndPoints } from './RoutingEndPoints';
import ProtectedRouter from '../components/ProtectedRouter';
import { countCharactersInAString } from '../helpers/stringHelper';
import {
  requestNetworkOffline,
  requestNetworkOnline,
  activateAlert,
  removeAlert
} from '../actions/alert';
import { checkIfUserIsAuthenticated } from '../helpers/authHelper';
import LiveStream from '../containers/LiveStream';
import LiveShow from '../containers/LiveShow';

const RootRoute = (props) => {
  const { pathname, search, state, hash } = useLocation();
  const history = useHistory();
  const { onlineStatus } = props;

  const networkIsOnline = () => {
    props.requestNetworkOnline();
  };
  const networkIsOffline = () => {
    props.requestNetworkOffline();
  };

  useEffect(() => {
    if (navigator.onLine) {
      networkIsOnline();
    } else {
      networkIsOffline();
    }

    window.addEventListener('offline', networkIsOffline);
    window.addEventListener('online', networkIsOnline);
    return () => {
      window.removeEventListener('offline', networkIsOffline);
      window.removeEventListener('online', networkIsOnline);
    };
  }, []);

  useEffect(() => {
    if (!onlineStatus) {
      props.activateAlert('Error', 'You are offline!', false);
    } else {
      props.removeAlert();
    }
  }, [onlineStatus]);

  useEffect(() => {
    const isLoggedIn = checkIfUserIsAuthenticated();

    if (isLoggedIn) {
      const noOfSlash = countCharactersInAString(pathname, '/');
      if (
        (pathname === '/signin/' || noOfSlash === 1) &&
        pathname !== '/go_live' &&
        pathname !== '/stream_live'
      ) {
        history.replace({
          pathname: RoutingEndPoints.authorized.dashboard.path,
          search,
          state,
          hash
        });
      } else {
        history.replace({ pathname, search, state, hash });
      }
    } else if (
      pathname.toLowerCase() !== '/forgotpassword' &&
      pathname.toLowerCase() !== '/resetpassword'
    ) {
      history.replace({
        pathname: RoutingEndPoints.unAuthorized.signIn.path,
        search,
        state,
        hash
      });
    } else {
      history.replace({ pathname, search, state, hash });
    }
  }, [history, pathname, props?.auth]);
  return (
    <>
      <UnAuthorizedRoutes />
      <ProtectedRouter path="/dashboard" component={Dashboard} />
      <ProtectedRouter path="/go_live" component={LiveStream} />
      <ProtectedRouter path="/stream_live" component={LiveShow} />
    </>
  );
};
const mapStateToProps = (store, props) => ({
  auth: store.auth.success,
  onlineStatus: store.browserState.online
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestNetworkOnline,
      requestNetworkOffline,
      activateAlert,
      removeAlert
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RootRoute)
);
