import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, Form } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../components/Section';
import { GENDERS } from '../../../../constants/OtherConstants';
import { createSocianaInternalUser } from '../../../../actions/socianaInternalUser';
import { editUser } from '../../../../actions/user';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import InputGroup from '../../../../components/InputGroup';
import validate from './Validate';
import { SubmitButton } from '../../../../components/InputGroup/FormComponents';
import { getImageSource } from '../../../../helpers/imageHelper';
import { getDateInMonthFormat } from '../../../../helpers/dateTimeHelper';
import { renderRoute } from '../../../../components/RouterHOC';
import { adminCreateRouteAccess } from '../../../../selectors/auth';

function UserCreate(props) {
  const [initiallyButtonDisabled, setInitiallyButtonDisabled] = useState(true);
  const [UserFormState, setUserFormState] = React.useState({
    ...props.initialValues
  });
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleUserCreate = () => {
    try {
      const validateObj = validate(UserFormState, props.userId);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const data = new FormData();
        data.append('user[first_name]', UserFormState.first_name);
        data.append('user[last_name]', UserFormState.last_name);
        data.append('user[email]', UserFormState.email);
        data.append('user[dob]', UserFormState.dob.format('DD/MM/YYYY'));
        data.append('user[gender]', UserFormState.gender);
        data.append('user[from_internal_team]', false);
        if (UserFormState.avatar && UserFormState.avatar[0]?.originFileObj) {
          data.append('user[avatar]', UserFormState.avatar[0]?.originFileObj);
        }
        if (props.userId) {
          props.editUser(data, props.userId, () => {
            setUserFormState({});
            props.handleCloseClick();
          });
        } else {
          props.createSocianaInternalUser(data, () => {
            setUserFormState({});
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let adminFormValue;
      let inputName;
      if (initiallyButtonDisabled) {
        setInitiallyButtonDisabled(false);
      }
      if (type === 'avatar') {
        value = e.fileList;
        name = type;
      }
      if (type === 'dob') {
        value = moment(e, 'DD/MM/YYYY');
        name = type;
      }
      adminFormValue = value || e?.target?.value;
      inputName = name || e?.target?.name;

      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setUserFormState({
        ...UserFormState,
        [inputName]: adminFormValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="user-create-section">
        <div className="user-create-form">
          <Form className="user-form" onFinish={handleUserCreate}>
            <InputGroup
              label="First Name"
              name="first_name"
              placeholder="Enter First Name"
              onChange={handleInputChange}
              value={UserFormState?.first_name}
              errorMessage={errorMessages.first_name}
            />
            <InputGroup
              label="Last Name"
              name="last_name"
              placeholder="Enter Last Name"
              onChange={handleInputChange}
              value={UserFormState?.last_name}
              errorMessage={errorMessages.last_name}
            />
            <InputGroup
              label="Email ID"
              name="email"
              placeholder="Enter Email ID"
              onChange={handleInputChange}
              value={UserFormState?.email}
              errorMessage={errorMessages.email}
            />
            <InputGroup
              fieldType="datePicker"
              onChange={handleInputChange}
              label="Date of Birth"
              name="dob"
              value={UserFormState?.dob}
              errorMessage={errorMessages.dob}
            />
            <InputGroup
              fieldType="radioGroup"
              options={GENDERS}
              name="gender"
              label="Gender"
              onChange={handleInputChange}
              value={UserFormState?.gender}
              errorMessage={errorMessages.gender}
            />
            {!props.userId && (
              <InputGroup
                label="Password"
                name="password"
                placeholder="Enter Password"
                onChange={handleInputChange}
                value={UserFormState?.password}
                errorMessage={errorMessages.password}
                type="password"
              />
            )}
            <InputGroup
              fieldType="file"
              label="Profile Picture"
              accept="image/*"
              fileList={UserFormState?.avatar}
              onChange={handleInputChange}
              name="avatar"
              errorMessage={errorMessages.avatar}
            />
            <SubmitButton
              disabled={initiallyButtonDisabled || props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.userId ? 'Save Changes' : 'Create User'}
            />
          </Form>
          {!!props.userId && (
            <Button
              onClick={props.handleCloseClick}
              className="edit-close-button">
              <CloseOutlined />
            </Button>
          )}
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => {
  const { userDetails } = props;
  let initialValues = {};
  if (userDetails && Object.keys(userDetails).length > 0) {
    initialValues = {
      first_name:
        userDetails?.name &&
        userDetails?.name?.substr(0, userDetails?.name?.indexOf(' ')),
      last_name:
        userDetails?.name &&
        userDetails?.name?.substr(userDetails?.name?.indexOf(' ') + 1),
      email: userDetails?.email && userDetails?.email,
      dob: userDetails?.dob && moment(userDetails?.dob, 'DD/MM/YYYY'),
      gender: userDetails?.gender,
      avatar: userDetails?.avatarUrl && [
        { url: getImageSource(userDetails?.avatarUrl) }
      ]
    };
  }

  return {
    initialValues,
    fetchProgress: store.browserState.fetchProgress
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createSocianaInternalUser,
      editUser
    },
    dispatch
  );

export default renderRoute(adminCreateRouteAccess)(
  connect(mapStateToProps, mapDispatchToProps)(UserCreate)
);
