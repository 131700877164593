import {
  OCCASION_ALBUM_CREATE,
  OCCASION_ALBUM_CREATE_FAILED,
  OCCASION_ALBUM_CREATE_SUCCESS,
  OCCASION_ALBUM_VIEW,
  OCCASION_ALBUM_VIEW_FAILED,
  OCCASION_ALBUM_VIEW_SUCCESS,
  CREATE_ALBUM_MEDIA,
  CREATE_ALBUM_MEDIA_FAILED,
  CREATE_ALBUM_IMAGES_SUCCESS,
  CREATE_ALBUM_VIDEOS_SUCCESS,
  GET_ALBUM_IMAGES,
  GET_ALBUM_IMAGES_FAILED,
  GET_ALBUM_IMAGES_SUCCESS,
  GET_ALBUM_VIDEOS,
  GET_ALBUM_VIDEOS_FAILED,
  GET_ALBUM_VIDEOS_SUCCESS,
  DELETE_ALBUM_MEDIA,
  DELETE_ALBUM_MEDIA_FAILED,
  DELETE_ALBUM_MEDIA_SUCCESS,
  UPLOAD_ALBUM_MEDIA_LIST,
  CLEAR_ALBUM_UPLOAD_IMAGE_LIST,
  DELETE_ALBUM,
  DELETE_ALBUM_FAILED,
  DELETE_ALBUM_SUCCESS,
  UPDATE_ALBUM,
  UPDATE_ALBUM_FAILED,
  UPDATE_ALBUM_SUCCESS
} from '../constants/actionTypes';
export const occasionAlbumCreate = (ocId, formData, callback) => ({
  type: OCCASION_ALBUM_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionAlbumCreateSuccess = (data, included, ocId) => ({
  type: OCCASION_ALBUM_CREATE_SUCCESS,
  payload: { data, included, ocId }
});

export const occasionAlbumCreateFailed = (error) => ({
  type: OCCASION_ALBUM_CREATE_FAILED,
  error
});

export const occasionAlbumView = (ocId, page) => ({
  type: OCCASION_ALBUM_VIEW,
  payload: { ocId, page }
});

export const occasionAlbumViewSuccess = (data, included, ocId, links) => ({
  type: OCCASION_ALBUM_VIEW_SUCCESS,
  payload: { data, included, ocId, links }
});

export const occasionAlbumViewFailed = (error) => ({
  type: OCCASION_ALBUM_VIEW_FAILED,
  error
});

export const createAlbumMedia = (
  occasionId,
  formData,
  albumId,
  updateProgress,
  mediaType = 'photo',
  callback
) => ({
  type: CREATE_ALBUM_MEDIA,
  payload: { occasionId, formData, albumId, updateProgress, mediaType },
  callback
});

export const createAlbumImagesSuccess = (
  occasionId,
  albumId,
  data,
  included
) => ({
  type: CREATE_ALBUM_IMAGES_SUCCESS,
  payload: { occasionId, albumId, data, included }
});

export const createAlbumVideosSuccess = (
  occasionId,
  albumId,
  data,
  included
) => ({
  type: CREATE_ALBUM_VIDEOS_SUCCESS,
  payload: { occasionId, albumId, data, included }
});

export const createAlbumMediaFailed = (error) => ({
  type: CREATE_ALBUM_MEDIA_FAILED,
  error
});

export const getAlbumImages = (occasionId, mType, albumId, page, callback) => ({
  type: GET_ALBUM_IMAGES,
  payload: { occasionId, mType, albumId, page },
  callback
});

export const getAlbumImagesSuccess = (
  occasionId,
  albumId,
  data,
  included,
  links,
  page
) => ({
  type: GET_ALBUM_IMAGES_SUCCESS,
  payload: { occasionId, albumId, data, included, links, page }
});

export const getAlbumImagesFailed = (error) => ({
  type: GET_ALBUM_IMAGES_FAILED,
  error
});

export const getAlbumVideos = (occasionId, mType, albumId, page, callback) => ({
  type: GET_ALBUM_VIDEOS,
  payload: { occasionId, mType, albumId, page },
  callback
});

export const getAlbumVideosSuccess = (
  occasionId,
  albumId,
  data,
  included,
  links,
  page
) => ({
  type: GET_ALBUM_VIDEOS_SUCCESS,
  payload: { occasionId, albumId, data, included, links, page }
});

export const getAlbumVideosFailed = (error) => ({
  type: GET_ALBUM_VIDEOS_FAILED,
  error
});

export const deleteAlbumMedia = (
  occasionId,
  mediaId,
  mType,
  albumId,
  mediaType = 'photo',
  callback
) => ({
  type: DELETE_ALBUM_MEDIA,
  payload: { occasionId, mediaId, mType, albumId, mediaType },
  callback
});

export const deleteAlbumMediaSuccess = (
  occasionId,
  albumId,
  mediaId,
  mediaType
) => ({
  type: DELETE_ALBUM_MEDIA_SUCCESS,
  payload: { occasionId, albumId, mediaId, mediaType }
});

export const deleteAlbumMediaFailed = (error) => ({
  type: DELETE_ALBUM_MEDIA_FAILED,
  error
});

export const updateAlbumImageList = (occasionId, albumId, data, progress) => ({
  type: UPLOAD_ALBUM_MEDIA_LIST,
  payload: { occasionId, albumId, data, progress }
});

export const clearAlbumUploadHistory = (occasionId, albumId) => ({
  type: CLEAR_ALBUM_UPLOAD_IMAGE_LIST,
  payload: { occasionId, albumId }
});

export const deleteAlbum = (ocId, albumId, callback) => ({
  type: DELETE_ALBUM,
  payload: { ocId, albumId },
  callback
});

export const deleteAlbumSuccess = (ocId, data) => ({
  type: DELETE_ALBUM_SUCCESS,
  payload: { ocId, data }
});

export const deleteAlbumFailed = (error) => ({
  type: DELETE_ALBUM_FAILED,
  error
});

export const updateAlbum = (ocId, albumId, formData, callback) => ({
  type: UPDATE_ALBUM,
  payload: { ocId, albumId, formData },
  callback
});

export const updateAlbumSuccess = (ocId, data, included) => ({
  type: UPDATE_ALBUM_SUCCESS,
  payload: { ocId, data, included }
});

export const updateAlbumFailed = (error) => ({
  type: UPDATE_ALBUM_FAILED,
  error
});
