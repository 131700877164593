import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import './styles.scss';
import { renderRoute } from '../../../../components/RouterHOC';
import { SectionContent } from '../../../../components/Section';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import TableView from '../../../../components/Table';
import { adminViewRouteAccess } from '../../../../selectors/auth';
import {
  getWhatsAppTempletsWithPagination,
  deleteWhatsappTemplate
} from '../../../../actions/whatsAppTemplates';
import { getWhatsappTemplatesWithOccasionData } from '../../../../selectors/whatsappTemplates';

const ViewWhatsappTemplates = (props) => {
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.getWhatsAppTempletsWithPagination(page);
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const location = useLocation();

  const paginationOptions = {
    showTotal: () => (
      <Button
        className="whatsapp-templates-refresh-button"
        onClick={() => onPageChange(page)}>
        <SyncOutlined />
      </Button>
    )
  };

  const deleteData = (id) => {
    props.deleteWhatsappTemplate(id);
  };

  return (
    <ErrorBoundary>
      <SectionContent className="whatsapp-templates-view-section">
        <div className="whatsapp-templates-view-container">
          <TableView
            isPaginated={true}
            paginationOptions={paginationOptions}
            isRouted={true}
            // pathname={${location.pathname}}
            onPageChange={onPageChange}
            onDelete={deleteData}
            data={props.whatsappTemplatesData}
            fetchProgress={props.fetchProgress}
            paginationLinks={props.whatsappTemplatesLinks}
            blacklistKeys={['staticOccParams']}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  whatsappTemplatesData: getWhatsappTemplatesWithOccasionData(store, props),
  whatsappTemplatesLinks: store.whatsappTemplates.whatsappTemplatesLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { getWhatsAppTempletsWithPagination, deleteWhatsappTemplate },
    dispatch
  );
};

export default renderRoute(adminViewRouteAccess)(
  connect(mapStateToProps, mapDispatchProps)(ViewWhatsappTemplates)
);
