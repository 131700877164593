import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, AutoComplete, Image, Spin, Empty, Progress, Button } from 'antd';
import moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../../components/Section';
import InputGroup from '../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { searchInternalTeamMember } from '../../../../../../actions/socianaInternalUser';
import {
  createEventPost,
  editEventPost
} from '../../../../../../actions/event';
import {
  currentOccasionId,
  currentPostId
} from '../../../../../../selectors/dynamicData';
import { getSearchedInternalUsers } from '../../../../../../selectors/socianaInternalUser';
import { getImageSource } from '../../../../../../helpers/imageHelper';
import { capitalize } from '../../../../../../helpers/stringHelper';
import { adminData } from '../../../../../../selectors/auth';
import { getProcessedVideo } from '../../../../../../helpers/videoHelper';
import './styles.scss';
import { getCurrentEventPost } from '../../../../../../selectors/event';
import { Switch } from '../../../../../../components/InputGroup/FormComponents';

const { Option } = AutoComplete;

const validatePostData = (values, isUserIdOpen) => {
  const errors = {};
  if (
    (!values?.searchData?.id || !values?.searchData?.value) &&
    !isUserIdOpen
  ) {
    errors.searchData = 'Select a valid user from the list';
  }
  if (isUserIdOpen && !values.userId) {
    errors.userId = 'User Id is required';
  }
  if (!values?.message && !values.media) {
    errors.media = 'You need to post atleast something';
    errors.message = 'You need to post atleast something';
  }
  if (!values?.priority) {
    errors.priority = 'Priority is required';
  }
  return errors;
};

const EventPostCreate = (props) => {
  const [postData, setPostData] = useState({
    searchData: props.initialData?.searchData || { value: '', id: null },
    message: props.initialData?.message || '',
    hashtags: props.initialData?.hashtags || '',
    media: props.initialData?.media || null,
    date: props.initialData?.date || null,
    time: props.initialData?.time || null,
    priority: props.initialData?.priority || 1,
    event: '',
    userId: props.initialData?.userId || ''
  });

  const [typeOfFile, setTypeOfFile] = useState('images');
  const [errorMessages, setErrorMessages] = useState({});
  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [page, setPage] = useState(1);

  const [isDateFieldOpen, setIsDateFieldOpen] = useState(false);
  const [isUserIdOpen, setIsUserIdOpen] = useState(false);

  const updateProgress = (e) => {
    const percentVal = Math.round(e.ratio * 100);
    setPercent(percentVal);
  };

  const handleSubmit = async () => {
    try {
      const validateObj = validatePostData(postData, isUserIdOpen);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();

        const dateTime =
          postData?.date?.format('YYYY-MM-DD') +
          ' ' +
          postData?.time?.format('HH:mm');

        if (!props.postId) {
          if (!isUserIdOpen) {
            formData.append('post[user_id]', postData.searchData.id);
          } else {
            formData.append('post[user_id]', postData.userId);
          }
        }
        if (postData?.date && postData?.time) {
          formData.append('post[revised_created_at]', new Date(dateTime));
        }
        if (postData.message) {
          formData.append('post[message]', postData.message);
        }
        if (postData.hashtags) {
          formData.append('post[hash_tag]', postData.hashtags);
        }
        formData.append('post[priority]', postData.priority);
        formData.append('post[posting][][postable_type]', 'Event');
        formData.append('post[posting][][postable_id]', props.eventId);

        if (postData.media) {
          await Promise.all(
            postData.media.map(async (file) => {
              if (!(file?.originFileObj instanceof File)) {
                return;
              }
              if (!!file?.originFileObj && file?.type?.indexOf('image') >= 0) {
                formData.append('post[pictures][]', file?.originFileObj);
              }

              if (!!file?.originFileObj && file?.type?.indexOf('video') >= 0) {
                setLoading(true);
                const videoFile = await getProcessedVideo(
                  file?.originFileObj,
                  updateProgress
                );

                formData.append('post[videos][]', videoFile);
              }
            })
          );
        }

        if (props.postId) {
          props.editEventPost(
            props.occasionId,
            props.postableType,
            props.postId,
            formData,
            () => {
              setLoading(false);
              setPercent(0);
              setPostData({
                searchData: { value: '', id: null },
                message: '',
                hashtags: '',
                media: null
              });
              props.toggleEdit(null);
            }
          );
        } else {
          props.createEventPost(
            props.occasionId,
            props.eventId,
            formData,
            () => {
              setLoading(false);
              setPercent(0);
              setPostData({
                searchData: { value: '', id: null },
                message: '',
                hashtags: '',
                media: null,
                priority: 1
              });
              setIsDateFieldOpen(false);
            }
          );
        }
      }
    } catch (error) {
      console.error('Error in submitting form', error);
    }
  };

  const handleSearch = (val) => {
    setPostData({ ...postData, searchData: { value: val, id: null } });
    if (val.length > 0) {
      props.searchInternalTeamMember(val);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (type === 'media') {
          value = e.fileList;
          name = type;
        }

        if (type === 'message') {
          value = e;
          name = type;
        }
        if (type === 'searchData') {
          value = {
            value: e?.value,
            id: e?.key
          };
          name = type;
        }

        if (type === 'date') {
          value = moment(e, 'DD/MM/YYYY');
          name = type;
        }
        if (type === 'time') {
          value = moment(e, 'HH:mm');
          name = type;
        }

        const assignFormValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        setPostData({
          ...postData,
          [inputName]: assignFormValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSwitchChange = () => {
    if (typeOfFile === 'images') {
      setTypeOfFile('video');
    } else {
      setTypeOfFile('images');
    }
    setPostData({
      ...postData,
      media: null
    });
  };

  const updateSwitch = (e) => {
    setIsDateFieldOpen(e);
  };

  const handleUserIdToggle = (e) => {
    setIsUserIdOpen(e);
  };

  return (
    <SectionContent className="post-create-section">
      <div className="post-create-form">
        <Form className="post-form" onFinish={handleSubmit}>
          <InputGroup
            fieldType="switch"
            label={`Toggle to upload ${
              typeOfFile === 'images' ? 'video' : 'images'
            }`}
            onChange={handleSwitchChange}
            value={typeOfFile === 'images'}
          />

          <InputGroup
            fieldType="file"
            label={`Upload ${typeOfFile}`}
            accept={typeOfFile === 'images' ? 'image/*' : 'video/*'}
            fileList={postData?.media}
            onChange={handleInputChange}
            name="media"
            errorMessage={errorMessages.media}
            multiple={typeOfFile === 'images'}
            maxCount={typeOfFile === 'images' ? 5 : 1}
          />
          {loading && (
            <p className="compressing-video-message-container">
              <span className="compressing-video-message">
                Compressing video{' '}
              </span>
              <Progress type="circle" percent={percent} width={30} />
            </p>
          )}

          <InputGroup
            onChange={handleInputChange}
            value={postData?.hashtags}
            fieldType="textArea"
            name="hashtags"
            label="Enter Hashtags"
            errorMessage={errorMessages?.hashtags}
            placeholder="Enter hashtags space or comma separated"
          />
          <InputGroup
            onChange={handleInputChange}
            value={postData?.message}
            fieldType="textArea"
            name="message"
            label="Enter Caption"
            errorMessage={errorMessages?.message}
            placeholder="Enter Caption"
          />

          <InputGroup
            name="priority"
            placeholder="Enter priority"
            label="Priority"
            value={postData?.priority}
            onChange={handleInputChange}
            errorMessage={errorMessages?.priority}
          />

          {!props.postId && (
            <InputGroup
              fieldType="switch"
              name="toggleToUserId"
              label="Toggle to Add user Id"
              onChange={handleUserIdToggle}
              value={isUserIdOpen}
            />
          )}
          {!isUserIdOpen && (
            <InputGroup
              fieldType="searchbox"
              name="searchData"
              label="Search Internal Team Member"
              disabled={props.postId ? true : false}
              loading={props.fetchProgress}
              notFoundContent="No Data"
              errorMessage={errorMessages?.searchData}
              value={postData?.searchData?.value}
              onSelect={(e, option) => handleInputChange(option, 'searchData')}
              onSearch={handleSearch}
              placeholder="Search Internal Team Member">
              {props.searchedInternalUser?.length > 0 &&
                postData?.searchData?.value?.length > 0 &&
                props.searchedInternalUser.map((item) => {
                  return (
                    <Option value={item?.name} key={item?.id}>
                      <div className="options-container">
                        <p className="avatar-image-container">
                          <Image
                            src={getImageSource(item?.avatarUrl)}
                            preview={false}
                          />
                        </p>
                        <div className="internal-member-occasion-details-container">
                          <p className="internal-member-occasion-name">
                            {item?.name}
                          </p>
                          <p className="internal-member-occasion-type">
                            {item?.email}
                          </p>
                        </div>
                      </div>
                    </Option>
                  );
                })}
            </InputGroup>
          )}
          {isUserIdOpen && !props.postId && (
            <InputGroup
              name="userId"
              placeholder="Enter User Id"
              label="User Id"
              value={postData?.userId}
              onChange={handleInputChange}
              errorMessage={errorMessages?.userId}
            />
          )}
          <div className="post-date-container">
            <span style={{ marginRight: '20px' }}>
              Toggle To Add Revised Date Time
            </span>
            <Switch
              value={postData?.date && postData.time ? true : isDateFieldOpen}
              onChange={(e) => updateSwitch(e)}
            />
            {isDateFieldOpen || postData?.date || postData.time ? (
              <div className="post-date-contant">
                <InputGroup
                  placeholder="Enter Date"
                  name="date"
                  fieldType="datePicker"
                  onChange={handleInputChange}
                  value={postData?.date}
                />
                <InputGroup
                  placeholder="Enter Time"
                  name="time"
                  fieldType="timePicker"
                  format="HH:mm"
                  onChange={handleInputChange}
                  value={postData?.time}
                />
              </div>
            ) : (
              ''
            )}
          </div>
          <SubmitButton
            buttonText={props.postId ? 'Edit Post' : 'Create Post'}
            fetchProgress={props.fetchProgress || loading}
            disabled={props.fetchProgress || loading}
          />
        </Form>
        {typeof props.toggleEdit === 'function' && (
          <Button
            onClick={() => props.toggleEdit(null)}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        )}
      </div>
    </SectionContent>
  );
};

const mapStateToProps = (store, props) => {
  const postId = currentPostId(store, props);
  const post = getCurrentEventPost(store, props);

  let initialData = {};
  if (postId && post) {
    const datetimeArray = post?.createdAt?.split(' ');
    initialData.date = datetimeArray[0]
      ? moment(datetimeArray[0], 'DD/MM/YYYY')
      : null;
    initialData.time = datetimeArray[1]
      ? moment(datetimeArray[1], 'HH:mm')
      : null;
    initialData.message = post.message;
    initialData.hashtags = post.hashTag;
    initialData.priority = post?.priority;
    initialData.userId = post?.user?.id || '';
    initialData.searchData = {
      value: post?.user?.name,
      id: post?.user?.id
    };

    const videoData =
      post?.videoUrls?.length > 0
        ? post?.videoUrls.map((item, index) => ({
            id: index,
            url: getImageSource(item),
            name: item,
            remove: false
          }))
        : null;

    const imageData =
      post?.pictureUrl?.length > 0
        ? post?.pictureUrl.map((item, index) => ({
            id: index,
            url: getImageSource(item),
            name: item,
            remove: false
          }))
        : null;

    const media = imageData || videoData;

    initialData.media = media;
  }

  return {
    occasionId: currentOccasionId(store, props),
    searchedInternalUser: getSearchedInternalUsers(store, props),
    admin: adminData(store, props),
    userAccessTypes: store.occasion.accessTypes,
    fetchProgress: store.browserState.fetchProgress,
    initialData
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { searchInternalTeamMember, createEventPost, editEventPost },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EventPostCreate);
