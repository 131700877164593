import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import Tabs from '../../../../../components/Tabs';
import { getCurrentHospitality } from '../../../../../selectors/hospitality';
import HospitalityDetailsView from './HospitalityDetails';
import {
  currentOccasionId,
  currentHospitalityCategoryId
} from '../../../../../selectors/dynamicData';
import TaskView from '../Hospitality/HospitalityTask/ViewTask';
import { getCurrentOccasion } from '../../../../../selectors/occasion';
import { TabsHeader } from '../../../../../components/TabsHeader';

function HospitalityDetails(props) {
  const getTaskComponent = () => {
    return (
      <TaskView
        occasionId={props.occasionId}
        hospitalityDetails={props.currentHospitality}
        hospitalityId={props.hospitalityId}
      />
    );
  };

  const tabPanes = [
    {
      tabName: 'Details',
      tabKey: '1',
      children: (
        <HospitalityDetailsView
          hospitalityDetails={props.currentHospitality}
          occasionId={props.occasionId}
        />
      )
    },
    {
      tabName: 'Task',
      tabKey: '2',
      children: getTaskComponent()
    }
  ];
  const goBack = () => {
    props.history.goBack();
  };
  return (
    <ErrorBoundary>
      <SectionContent className="occasion-hospitality-details-section">
        <TabsHeader
          name={props.currentOccasion.name}
          extraData={[
            props.currentOccasion.type,
            props.currentOccasion.guestType,
            props.currentHospitality.name
          ]}
          wrapperClass="section-details"
        />
        <div className="occasion-hospitality-details-container">
          <Tabs
            defaultActiveKey="1"
            tabPanes={tabPanes}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentHospitality: getCurrentHospitality(store, props),
  occasionId: currentOccasionId(store, props),
  hospitalityId: currentHospitalityCategoryId(store, props),
  currentOccasion: getCurrentOccasion(store, props)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HospitalityDetails);
