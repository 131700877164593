import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'antd';
import '../View/styles.scss';

import InputGroup from '../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../components/InputGroup/FormComponents';
import Form from 'antd/lib/form/Form';
import { getGroupMembers, searchGroupMember } from '../../../../actions/group';

function GroupMemberSearch(props) {
  const [getMemberDetails, setGetMemberDetails] = React.useState({});

  const handleMemberDetails = (page) => {
    try {
      props.searchGroupMember(
        props.occasionId,
        props.groupId,
        (page = 1),
        getMemberDetails?.memberType,
        getMemberDetails?.searchData
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;

      const memberDetailsValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      setGetMemberDetails({
        ...getMemberDetails,
        [inputName]: memberDetailsValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleReset = (page) => {
    setGetMemberDetails({});
    props.getGroupMembers(props.occasionId, props.groupId, (page = 1));
  };

  return (
    <Form
      className="group-member-search-container"
      onFinish={handleMemberDetails}>
      <InputGroup
        label="Name/Phone/Email"
        className="group-member-inputbox"
        fieldType="searchbox"
        placeholder="Enter Name/Phone/Email"
        name="searchData"
        onChange={handleChange}
        value={getMemberDetails?.searchData}
      />
      <InputGroup
        label="Member Type"
        className="group-member-inputbox"
        fieldType="dropdown"
        placeholder="Select"
        options={[
          { label: 'normal', value: 'normal' },
          { label: 'admin', value: 'admin' }
        ]}
        name="memberType"
        onChange={handleChange}
        value={getMemberDetails?.memberType}
      />
      <div style={{ flex: 1, display: 'flex' }}>
        <SubmitButton
          wrapperClass="group-member-submit-wrapper"
          className="group-member-submit-inputbox"
          buttonText="Search"
          fetchProgress={props.fetchProgress}
          disabled={props.fetchProgress}
        />
        <Button className="reset-button" onClick={handleReset}>
          Reset
        </Button>
      </div>
    </Form>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getGroupMembers, searchGroupMember }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GroupMemberSearch);
