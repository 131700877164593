import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import { Button } from 'antd';
import DivisionCreate from './Create';
import { occasionDivisionView } from '../../../../../../actions/division';
import { getAllDivisionData } from '../../../../../../selectors/division';

const DivisionView = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.occasionDivisionView(props.occasionId, page);
  };

  const location = useLocation();

  React.useEffect(() => {
    onPageChange(1);
  }, []);
  const paginationOptions = {
    showTotal: () => (
      <div className="button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={() => setCreateOpen(true)}>
          Create
        </Button>
      </div>
    )
  };

  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="division-view-section">
          <div className="division-view-container">
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              isRouted={{ key: 'subdivisionFlag' }}
              pathname={`${location.pathname}/division/view`}
              onPageChange={onPageChange}
              data={props.divisionData}
              fetchProgress={props.fetchProgress}
              blacklistKeys={['subdivisionSegments']}
              paginationLinks={props.divisionLink}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <DivisionCreate
        occasionId={props.occasionId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  divisionData: getAllDivisionData(store, props),
  divisionLink: store.division.divisionLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ occasionDivisionView }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(DivisionView);
