import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import Tabs from '../../../../../../components/Tabs';
import TimeTriggerDetailsView from './Details';

import {
  currentOccasionId,
  currentTriggerId
} from '../../../../../../selectors/dynamicData';

import { currentTimeTrigger } from '../../../../../../selectors/trigger';
import { TabsHeader } from '../../../../../../components/TabsHeader';
import { getCurrentOccasion } from '../../../../../../selectors/occasion';

function TimeTiggerDetails(props) {
  const tabPanes = [
    {
      tabName: 'Details',
      tabKey: '1',
      children: (
        <TimeTriggerDetailsView
          timeTriggerDetails={props.currentTimeTriggerData}
          occasionId={props.occasionId}
        />
      )
    }
  ];

  const goBack = () => {
    props.history.goBack();
  };

  return (
    <ErrorBoundary>
      <SectionContent className="time-trigger-tab-section">
        <TabsHeader
          name={props.currentOccasion.name}
          extraData={[
            props.currentOccasion.type,
            props.currentOccasion.guestType,
            props.currentTimeTriggerData.title
          ]}
          wrapperClass="section-details"
        />
        <div className="time-trigger-tab-container">
          <Tabs
            defaultActiveKey="1"
            tabPanes={tabPanes}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  activeTabKey: store.tabKeys.event,
  fetchProgress: store.browserState.fetchProgress,
  occasionId: currentOccasionId(store, props),
  currentOccasion: getCurrentOccasion(store, props),
  currentTimeTriggerId: currentTriggerId(store, props),
  currentTimeTriggerData: currentTimeTrigger(store, props)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TimeTiggerDetails);
