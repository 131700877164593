import { createSelector } from 'reselect';
import { speakerEntities } from './entity';
import { occasionSpeakerIds } from './static';
import { currentOccasionId, currentSpeakerId } from './dynamicData';

export const getCurrentOccasionSpeakers = createSelector(
  currentOccasionId,
  occasionSpeakerIds,
  (ocId, Ids) => ocId && Ids && Ids[ocId]
);
export const getAllOccasionSpeakers = createSelector(
  speakerEntities,
  getCurrentOccasionSpeakers,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const currentSpeakerData = createSelector(
  currentSpeakerId,
  speakerEntities,
  (id, entities) => id && entities && entities[id]
);
