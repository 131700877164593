import Car from '../../../../../../assets/hospitality-task-car.png';
import RoomService from '../../../../../../assets/hospitality-task-room-service.png';
import Food from '../../../../../../assets/hospitality-task-food.png';
import FileDocument from '../../../../../../assets/hospitality-task-file-document.png';
import Hotel from '../../../../../../assets/hospitality-task-hotel.png';
import AddressCard from '../../../../../../assets/hospitality-task-address-card.png';
import Clock from '../../../../../../assets/hospitality-task-clock.png';

import AirplaneLanding from '../../../../../../assets/hospitality-airplane-landing.png';
import AirplaneTakeOff from '../../../../../../assets/hospitality-airplane-take-off.png';
import Crowd from '../../../../../../assets/hospitality-crowd.png';
import Clock2 from '../../../../../../assets/hospitality-imac-clock.png';
import UserGroup from '../../../../../../assets/hospitality-male-user-group.png';
import PassportTicket from '../../../../../../assets/hospitality-passport-and-tickets.png';
import Schedule from '../../../../../../assets/hospitality-schedule.png';
import Ticket from '../../../../../../assets/hospitality-ticket.png';
import TravelDocument from '../../../../../../assets/hospitality-travel-document.png';

export const TASK_ICONS = {
  car: Car,
  'room-service': RoomService,
  food: Food,
  'file-document': FileDocument,
  hotel: Hotel,
  'address-card': AddressCard,
  clock: Clock,
  'airplane-landing': AirplaneLanding,
  'airplane-take-off': AirplaneTakeOff,
  crowd: Crowd,
  'imac-clock': Clock2,
  'user-group': UserGroup,
  'passport-ticket': PassportTicket,
  schedule: Schedule,
  ticket: Ticket,
  'travel-document': TravelDocument
};

export const TASK_TYPE = [
  { label: 'Request', value: 'request' },
  { label: 'Provide', value: 'provide' }
];

export const DATA_INPUT_TYPE = [
  { label: 'Document', value: 'document' },
  { label: 'Multiple Documents', value: 'multiple_documents' },
  { label: 'Text', value: 'text' },
  { label: 'Number', value: 'number' },
  { label: 'DateTime', value: 'datetime' },
  { label: 'MCQ', value: 'mcq' },
  { label: 'SCQ', value: 'scq' }
];

export const NO_OF_OPTIONS = [
  { label: '2(Two)', value: '2' },
  { label: '3(Three)', value: '3' },
  { label: '4(Four)', value: '4' },
  { label: '5(Five)', value: '5' },
  { label: '6(Six)', value: '6' }
];
