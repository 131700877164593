import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  deleteTicketGoogleSheetApi,
  getTicketGoogleSheetApi,
  ticketGoogleSheetCreateApi
} from '../api/ticketGoogleSheet';
import {
  DELETE_TICKET_GOOGLE_SHEET,
  GET_TICKET_GOOGLE_SHEET,
  TICKET_GOOGLE_SHEET_CREATE
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  deleteTicketGoogleSheetFailed,
  deleteTicketGoogleSheetSuccess,
  getTicketGoogleSheetFailed,
  getTicketGoogleSheetSuccess,
  ticketGoogleSheetCreateFailed,
  ticketGoogleSheetCreateSuccess
} from '../actions/ticketGoogleSheet';

export function* ticketGoogleSheetViewSaga(action) {
  try {
    const result = yield call(
      getTicketGoogleSheetApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getTicketGoogleSheetSuccess(
          result.data,
          result.included,
          result.links,
          action.payload.ocId
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getTicketGoogleSheetFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* ticketGoogleSheetCreateSaga(action) {
  try {
    const result = yield call(
      ticketGoogleSheetCreateApi,
      action.payload.ocId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        ticketGoogleSheetCreateSuccess(result.data, action.payload.ocId)
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(ticketGoogleSheetCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(ticketGoogleSheetCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* ticketGoogleSheetDeleteSaga(action) {
  try {
    const result = yield call(
      deleteTicketGoogleSheetApi,
      action.payload.ocId,
      action.payload.ticketGoogleSheetId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        deleteTicketGoogleSheetSuccess(action.payload.ocId, result.data)
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteTicketGoogleSheetFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* ticketGoogelSheetActionWatcher() {
  yield takeLatest(
    GET_TICKET_GOOGLE_SHEET,
    progressSaga,
    ticketGoogleSheetViewSaga
  );
  yield takeLatest(
    TICKET_GOOGLE_SHEET_CREATE,
    progressSaga,
    ticketGoogleSheetCreateSaga
  );
  yield takeLatest(
    DELETE_TICKET_GOOGLE_SHEET,
    progressSaga,
    ticketGoogleSheetDeleteSaga
  );
}
