import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import TableView from '../../../../../components/Table';
import { UserOccasionView } from '../../../../../actions/userOccasion';
import { getAllUserOccasionData } from '../../../../../selectors/userOccasion';

const UserOccasion = (props) => {
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.UserOccasionView(props.userId, page);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const paginationOptions = {
    showTotal: () => (
      <div className="button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
      </div>
    )
  };

  return (
    <ErrorBoundary>
      <SectionContent className="user-occasion-view-section">
        <div className="user-occasion-view-container">
          <TableView
            isPaginated={true}
            isRouted={false}
            paginationOptions={paginationOptions}
            onPageChange={onPageChange}
            data={props.userOccasionData}
            fetchProgress={props.fetchProgress}
            blacklistKeys={[
              'occasionCodes',
              'occasionFont',
              'specificSocianaAdminOccasion'
            ]}
            paginationLinks={props.userOccasionLinks}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  userOccasionData: getAllUserOccasionData(store, props),
  userOccasionLinks: store.userOccasion.userOccasionLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ UserOccasionView }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(UserOccasion);
