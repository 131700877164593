import {
  GET_OCCASION_SETTINGS,
  GET_OCCASION_SETTINGS_FAILED,
  GET_OCCASION_SETTINGS_SUCCESS,
  CREATE_OCCASION,
  EDIT_OCCASION,
  CREATE_OCCASION_SUCCESS,
  CREATE_OCCASION_FAILED,
  EDIT_OCCASION_SUCCESS,
  EDIT_OCCASION_FAILED,
  GET_OCCASION_PAGINATION,
  GET_OCCASION_PAGINATION_SUCCESS,
  GET_OCCASION_PAGINATION_FAILED,
  GET_OCCASION_BY_FIELD,
  GET_OCCASION_BY_FIELD_SUCCESS,
  GET_OCCASION_BY_FIELD_FAILED,
  UPDATE_OCCASION_CODE,
  UPDATE_OCCASION_CODE_FAILED,
  UPDATE_OCCASION_CODE_SUCCESS,
  UPDATE_OCCASION_FONT,
  UPDATE_OCCASION_FONT_FAILED,
  UPDATE_OCCASION_FONT_SUCCESS
} from '../constants/actionTypes';

export const getOccasionSettings = () => ({
  type: GET_OCCASION_SETTINGS
});

export const getOccasionSettingsSuccess = (data) => ({
  type: GET_OCCASION_SETTINGS_SUCCESS,
  payload: data
});

export const getOccasionSettingsFailed = (error) => ({
  type: GET_OCCASION_SETTINGS_FAILED,
  error
});

export const getOccasionWithPagination = (page) => ({
  type: GET_OCCASION_PAGINATION,
  payload: { page }
});

export const getOccasionWithPaginationSuccess = (
  data,
  included,
  links,
  meta,
  page
) => ({
  type: GET_OCCASION_PAGINATION_SUCCESS,
  payload: { data, included, links, meta, page }
});

export const getOccasionWithPaginationFailed = (error) => ({
  type: GET_OCCASION_PAGINATION_FAILED,
  error
});

export const createOccasion = (formData, callback) => ({
  type: CREATE_OCCASION,
  payload: { formData },
  callback
});

export const createOccasionSuccess = (data) => ({
  type: CREATE_OCCASION_SUCCESS,
  payload: { data }
});

export const createOccasionFailed = (error) => ({
  type: CREATE_OCCASION_FAILED,
  error
});

export const updateOccasion = (occasionId, formData, prevType, callback) => ({
  type: EDIT_OCCASION,
  payload: { occasionId, formData, prevType },
  callback
});

export const updateOccasionSuccess = (data) => ({
  type: EDIT_OCCASION_SUCCESS,
  payload: { data }
});

export const updateOccasionFailed = (error) => ({
  type: EDIT_OCCASION_FAILED,
  error
});

export const getOccasionByField = (
  ocName,
  startDate,
  endDate,
  guestType,
  ocType,
  ocCode,
  page
) => ({
  type: GET_OCCASION_BY_FIELD,
  payload: { ocName, startDate, endDate, guestType, ocType, ocCode, page }
});

export const getOccasionByFieldSuccess = (data, included, links, meta) => ({
  type: GET_OCCASION_BY_FIELD_SUCCESS,
  payload: { data, included, links, meta }
});

export const getOccasionByFieldFailed = (error) => ({
  type: GET_OCCASION_BY_FIELD_FAILED,
  error
});

export const updateOccasionCode = (ocId, occasionCodeId, formData) => ({
  type: UPDATE_OCCASION_CODE,
  payload: { ocId, occasionCodeId, formData }
});

export const updateOccasionCodeSuccess = (data, included) => ({
  type: UPDATE_OCCASION_CODE_SUCCESS,
  payload: { data, included }
});

export const updateOccasionCodeFailed = (error) => ({
  type: UPDATE_OCCASION_CODE_FAILED,
  error
});

export const updateOccasionFont = (ocId, formData, callback) => ({
  type: UPDATE_OCCASION_FONT,
  payload: { ocId, formData },
  callback
});

export const updateOccasionFontSuccess = (data, included, ocId) => ({
  type: UPDATE_OCCASION_FONT_SUCCESS,
  payload: { data, included, ocId }
});

export const updateOccasionFontFailed = (error) => ({
  type: UPDATE_OCCASION_FONT_FAILED,
  error
});
