import { createSelector } from 'reselect';
import {
  adminOccasionEntities,
  occasionEntities,
  socianaAdmins
} from './entity';
import { currentAdminId, currentSocianaAdminOccasionId } from './dynamicData';
import { adminOccasionData, activeAdminIds, inactiveAdminIds } from './static';

export const getAllActiveAdmins = createSelector(
  activeAdminIds,
  socianaAdmins,
  (ids, entities) =>
    (entities && ids && ids?.length > 0 && ids.map((id) => entities[id])) ||
    (ids && Array.isArray(Object.keys(ids)) ? [] : null)
);

export const getAllInactiveAdmins = createSelector(
  inactiveAdminIds,
  socianaAdmins,
  (ids, entities) =>
    (entities && ids && ids?.length > 0 && ids.map((id) => entities[id])) ||
    (ids && Array.isArray(Object.keys(ids)) ? [] : null)
);

export const getCurrentAdmin = createSelector(
  currentAdminId,
  socianaAdmins,
  (aId, entities) =>
    aId && entities && Object.keys(entities).length > 0 && entities[aId]
);

export const getCurrentAdminSocianaOccasionIds = createSelector(
  currentAdminId,
  adminOccasionData,
  (id, data) => id && data && data[id]
);

export const getAdminOccasions = createSelector(
  adminOccasionEntities,
  getCurrentAdminSocianaOccasionIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAdminOccasionsWithData = createSelector(
  getAdminOccasions,
  occasionEntities,
  (data, entities) =>
    (data &&
      entities &&
      data?.length > 0 &&
      data.map((el) => ({
        ...el,
        occasion: { ...entities[el?.occasion?.id] }
      }))) ||
    (Array.isArray(data) ? [] : null)
);

export const getCurrentAdminOccasionData = createSelector(
  currentSocianaAdminOccasionId,
  adminOccasionEntities,
  (aOcId, entities) =>
    aOcId && entities && Object.keys(entities).length > 0 && entities[aOcId]
);
