import request from './request';

export function createParticipantApi(ocId, competitionId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/competition/participants?competition_id=${competitionId}`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function createParticipantBycsvApi(
  ocId,
  competitionId,
  groupId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/competition/create_participants_by_csv?competition_id=${competitionId}&group_id=${groupId}`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function getParticipantApi(ocId, competitionId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/competition/participants?page=${page}&competition_id=${competitionId}`,
    {
      method: 'GET',
      token
    }
  );
}

export function deleteParticipantApi(
  ocId,
  competitionId,
  participantId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/competition/participants/${participantId}?competition_id=${competitionId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function updateParticipantApi(
  ocId,
  competitionId,
  participantId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/competition/participants/${participantId}?competition_id=${competitionId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}
