import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Image, AutoComplete, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { getOccasionEventsWithAllData } from '../../../../../../selectors/event';
import { getImageSource } from '../../../../../../helpers/imageHelper';
import { SPEAKER_TYPES } from '../Constants';
import { createSpeaker, editSpeaker } from '../../../../../../actions/speaker';
import { occasionEventView } from '../../../../../../actions/event';
import { RequiredMark } from '../../../../../../components/RequiredMark';
import { currentSpeakerData } from '../../../../../../selectors/speaker';

const validate = (values) => {
  const error = {};
  if (!values.name) {
    error.name = 'Name is required';
  }

  if (
    !!values.phoneNumber &&
    !/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/.test(
      values.phoneNumber
    )
  ) {
    error.phoneNumber = 'Invalid Phone Number';
  }
  if (
    !!values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    error.email = 'Invalid Email';
  }

  return error;
};

const { Option } = AutoComplete;

function CreateSpeaker(props) {
  const [speakerData, setSpeakerData] = React.useState({
    name: props.currentSpeaker?.name || '',
    phoneNumber: props.currentSpeaker?.phone || '',
    email: props.currentSpeaker?.email || '',
    designation: props.currentSpeaker?.designation || '',
    organization: props.currentSpeaker?.organization || '',
    websiteLink: props.currentSpeaker?.websiteLinks || null,
    about: props.currentSpeaker?.about || '',
    priority: props.currentSpeaker?.priority || '',
    avatar: props.currentSpeaker?.speakerImageUrl
      ? [
          {
            url: getImageSource(props.currentSpeaker?.speakerImageUrl)
          }
        ]
      : null,
    speakerType: 'Occasion',
    event: ''
  });
  const [errorMessages, setErrorMessages] = React.useState({});
  const [page, setPage] = React.useState(1);

  const onPageChange = (page) => {
    props.occasionEventView(props.occasionId, page);
  };
  React.useEffect(() => {
    onPageChange(page);
  }, [page]);

  const getPage = (str) => {
    const decodedURI = decodeURI(str);
    const number = decodedURI
      .split('page[number]')[1]
      .replace('=', '')
      .split('&');
    return (number && parseInt(number)) || 1;
  };

  const onEndReached = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height >= scrollHeight - 20) {
      if (props.eventLinks?.next) {
        const page = getPage(props.eventLinks?.next);
        setPage(page);
      }
    }
  };

  const handleSubmit = () => {
    try {
      const validateObj = validate(speakerData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('speaker[name]', speakerData.name);
        formData.append(
          'speaker[designation]',
          speakerData?.designation ? speakerData.designation : ''
        );
        formData.append(
          'speaker[organization]',
          speakerData?.organization ? speakerData.organization : ''
        );
        formData.append(
          'speaker[phone]',
          speakerData?.phoneNumber ? speakerData.phoneNumber : ''
        );
        formData.append(
          'speaker[email]',
          speakerData?.email ? speakerData.email : ''
        );
        if (!props.speakerId) {
          formData.append(
            'speaker[speaker_reference_priority]',
            speakerData?.priority ? speakerData.priority : '1'
          );
        }
        formData.append(
          'speaker[about]',
          speakerData?.about ? speakerData.about : ''
        );
        if (!props.speakerId) {
          speakerData?.websiteLink &&
            speakerData?.websiteLink
              .split(',')
              ?.map((item) =>
                formData.append('speaker[website_links][]', item)
              );
        } else {
          speakerData?.websiteLink &&
            speakerData?.websiteLink?.map((item) =>
              formData.append('speaker[website_links][]', item)
            );
        }
        if (speakerData.avatar && speakerData.avatar[0]?.originFileObj) {
          formData.append(
            'speaker[speaker_image]',
            speakerData.avatar[0].originFileObj
          );
        }

        props.speakerId
          ? props.editSpeaker(props.occasionId, props.speakerId, formData)
          : props.createSpeaker(
              props.occasionId,
              speakerData?.speakerType,
              speakerData?.speakerType === 'Occasion'
                ? props.occasionId
                : speakerData?.event,
              formData
            );
        setSpeakerData({
          name: '',
          phoneNumber: '',
          email: '',
          designation: '',
          organization: '',
          websiteLink: null,
          about: '',
          Priority: '',
          speakerType: 'Occasion',
          event: ''
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'avatar') {
        value = e.fileList;
        name = type;
      }
      const speakerValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setSpeakerData({
        ...speakerData,
        [inputName]: speakerValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ErrorBoundary>
      <SectionContent className="speaker-create-section">
        <div className="speaker-create-form">
          <Form className="speaker-form" onFinish={handleSubmit}>
            <InputGroup
              name="avatar"
              fieldType="file"
              label="Sponsor Image"
              accept="image/*"
              fileList={speakerData?.avatar}
              onChange={handleInputChange}
            />
            <div style={{ position: 'relative' }}>
              <RequiredMark left="95px" />
              <InputGroup
                label="Speaker Name"
                name="name"
                placeholder="Enter Name"
                onChange={handleInputChange}
                value={speakerData?.name}
                errorMessage={errorMessages?.name}
              />
            </div>
            <InputGroup
              label="Designation"
              name="designation"
              placeholder="Enter Designation"
              onChange={handleInputChange}
              value={speakerData?.designation}
            />
            <InputGroup
              label="Organization"
              name="organization"
              placeholder="Enter Organization"
              onChange={handleInputChange}
              value={speakerData?.organization}
            />
            <InputGroup
              label="Phone Number"
              name="phoneNumber"
              placeholder="Enter Phone Number"
              onChange={handleInputChange}
              value={speakerData?.phoneNumber}
              errorMessage={errorMessages?.phoneNumber}
            />

            <InputGroup
              label="Email"
              name="email"
              placeholder="Enter Email"
              onChange={handleInputChange}
              value={speakerData?.email}
              errorMessage={errorMessages?.email}
            />
            <InputGroup
              label="Website Link"
              name="websiteLink"
              placeholder="Enter Website Link (separated by comma)"
              onChange={handleInputChange}
              value={speakerData?.websiteLink}
            />

            <InputGroup
              label="About"
              name="about"
              fieldType="textArea"
              placeholder="Enter About"
              onChange={handleInputChange}
              value={speakerData?.about}
            />
            {!props.speakerId && (
              <InputGroup
                label="Priority"
                type="Number"
                name="priority"
                placeholder="Enter Priority"
                onChange={handleInputChange}
                value={speakerData?.priority}
              />
            )}
            {!props?.speakerId && (
              <InputGroup
                fieldType="radioGroup"
                options={SPEAKER_TYPES}
                name="speakerType"
                label="Speaker For..."
                onChange={handleInputChange}
                value={speakerData?.speakerType}
              />
            )}
            {!props?.speakerId && speakerData?.speakerType === 'Event' && (
              <InputGroup
                fieldType="dropdown"
                className="event-search"
                mode="single"
                name="event"
                label="Select Event"
                placeholder="Select Event"
                onChange={handleInputChange}
                onPopupScroll={onEndReached}
                value={speakerData?.event}>
                {props.eventData?.length > 0 &&
                  props.eventData.map((item) => {
                    return (
                      <Option value={item?.id} key={item?.id}>
                        <div className="options-container">
                          <p className="avatar-image-container">
                            <Image
                              src={getImageSource(item?.cardImageUrl)}
                              preview={false}
                            />
                          </p>
                          <div className="occasion-event-details-container">
                            <p className="occasion-event-name">{item?.title}</p>
                          </div>
                        </div>
                      </Option>
                    );
                  })}
              </InputGroup>
            )}

            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.speakerId ? 'Save Changes' : 'Create Speaker'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  eventData: getOccasionEventsWithAllData(store, props),
  eventLinks: store.event.occasionEventLinks,
  currentSpeaker: currentSpeakerData(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { createSpeaker, editSpeaker, occasionEventView },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateSpeaker);
