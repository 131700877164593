import {
  GET_HOSPITALITY_TASK_PUBLIC_ANSWER,
  GET_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED,
  GET_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS,
  CREATE_HOSPITALITY_TASK_PUBLIC_ANSWER,
  CREATE_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED,
  CREATE_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS,
  UPDATE_HOSPITALITY_TASK_PUBLIC_ANSWER,
  UPDATE_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED,
  UPDATE_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS,
  DELETE_HOSPITALITY_TASK_PUBLIC_ANSWER,
  DELETE_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED,
  DELETE_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS
} from '../constants/actionTypes';

export const getHospitalityTaskPublicAnswer = (
  ocId,
  taskId,
  page,
  callback
) => ({
  type: GET_HOSPITALITY_TASK_PUBLIC_ANSWER,
  payload: { ocId, taskId, page },
  callback
});

export const getHospitalityTaskPublicAnswerSuccess = (
  ocId,
  taskId,
  data,
  included,
  links
) => ({
  type: GET_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS,
  payload: { ocId, taskId, data, included, links }
});

export const getHospitalityTaskPublicAnswerFailed = (error) => ({
  type: GET_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED,
  error
});

export const createHospitalityTaskPublicAnswer = (
  ocId,
  taskId,
  formData,
  callback
) => ({
  type: CREATE_HOSPITALITY_TASK_PUBLIC_ANSWER,
  payload: { ocId, taskId, formData },
  callback
});

export const createHospitalityTaskPublicAnswerSuccess = (
  ocId,
  taskId,
  data,
  included,
  links
) => ({
  type: CREATE_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS,
  payload: { ocId, taskId, data, included, links }
});

export const createHospitalityTaskPublicAnswerFailed = (error) => ({
  type: CREATE_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED,
  error
});

export const updateHospitalityTaskPublicAnswer = (
  ocId,
  taskId,
  answerId,
  formData,
  callback
) => ({
  type: UPDATE_HOSPITALITY_TASK_PUBLIC_ANSWER,
  payload: { ocId, taskId, formData, answerId },
  callback
});

export const updateHospitalityTaskPublicAnswerSuccess = (
  ocId,
  taskId,
  data,
  included,
  links
) => ({
  type: UPDATE_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS,
  payload: { ocId, taskId, data, included, links }
});

export const updateHospitalityTaskPublicAnswerFailed = (error) => ({
  type: UPDATE_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED,
  error
});

export const deleteHospitalityTaskPublicAnswer = (
  ocId,
  taskId,
  answerId,
  callback
) => ({
  type: DELETE_HOSPITALITY_TASK_PUBLIC_ANSWER,
  payload: { ocId, taskId, answerId },
  callback
});

export const deleteHospitalityTaskPublicAnswerSuccess = (
  ocId,
  taskId,
  data,
  included,
  links
) => ({
  type: DELETE_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS,
  payload: { ocId, taskId, data, included, links }
});

export const deleteHospitalityTaskPublicAnswerFailed = (error) => ({
  type: DELETE_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED,
  error
});
