import request from './request';

export function occasionEventCreateApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/events`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function occasionEventViewApi(ocId, page, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/events?page=${page}`, {
    method: 'GET',
    token
  });
}

export function updateOccasionEventApi(ocId, eventId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/events/${eventId}`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}
export function eventSearchApi(
  ocId,
  title,
  startDate,
  endDate,
  guestType,
  tag,
  page,
  token
) {
  let query = `page=${page}`;
  if (!!title) {
    query += `&title=${title}`;
  }
  if (!!startDate) {
    query += `&from_date=${startDate}`;
  }
  if (!!endDate) {
    query += `&to_date=${endDate}`;
  }
  if (!!guestType) {
    query += `&guest_type=${guestType}`;
  }
  if (!!tag) {
    query += `&tag=${tag}`;
  }
  return request(`api/v2/sociana_admin/oc/${ocId}/search_event?${query}`, {
    method: 'GET',
    token
  });
}

export function deleteEventApi(ocId, eventId, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/events/${eventId}`, {
    method: 'DELETE',
    token
  });
}

export function multipleEventInvitationsApi(ocId, eventId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/multiple_event_invitation`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function parse_csvEventApi(ocId, eventId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/parse_csv`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function getEventMembersApi(ocId, eventId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/get_event_collaborators?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function getEventPendingInvitationApi(ocId, eventId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/get_pending_invitations_for_event?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function getEventPendingRegistrationApi(ocId, eventId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/get_pending_registrations_for_event?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function eventScheduleCreateApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/schedules`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function eventScheduleViewApi(ocId, scheduleType, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/schedules?schedulable_type=${scheduleType}&page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function eventScheduleUpdateApi(ocId, scheduleId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/schedules/${scheduleId}`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function eventScheduleDeleteApi(ocId, scheduleId, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/schedules/${scheduleId}`, {
    method: 'DELETE',
    token
  });
}

export function eventAffairCreateApi(ocId, formData, eventId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/event_affairs`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function eventAffairViewApi(ocId, page, eventId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/event_affairs?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function eventAffairUpdateApi(
  ocId,
  eventAffairId,
  eventId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/event_affairs/${eventAffairId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function eventAffairDeleteApi(ocId, eventAffairId, eventId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/event_affairs/${eventAffairId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function createEventPostApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/posts`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function viewEventPostApi(ocId, eventId, pageNumber, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/get_posts_by_event?event_id=${eventId}&page=${pageNumber}`,
    {
      method: 'GET',
      token
    }
  );
}

export function editEventPostApi(ocId, postableType, postId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/posts/${postId}?postable_type=${postableType}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function deleteEventPostApi(ocId, postableType, postId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/posts/${postId}?postable_type=${postableType}`,
    {
      method: 'DELETE',
      token
    }
  );
}
