import { createSelector } from 'reselect';
import { occasionTabSettingEntities } from './entity';
import { occasionTabSettingIds } from './static';
import { currentOccasionId, currentOccasionTabSettingId } from './dynamicData';

export const currentOccasionTabSettingIds = createSelector(
  currentOccasionId,
  occasionTabSettingIds,
  (hcId, ids) => hcId && ids && ids[hcId]
);

export const getAllOccasionTabSettingData = createSelector(
  occasionTabSettingEntities,
  currentOccasionTabSettingIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const currentOccasionTabSettingData = createSelector(
  currentOccasionTabSettingId,
  occasionTabSettingEntities,
  (id, entities) => id && entities && entities[id]
);
