import request from './request';
export function getUserWithPagination(page, token) {
  return request(`api/v2/sociana_admin/users?page=${page}`, {
    method: 'GET',
    token
  });
}

export function editUserApi(formData, userId, token) {
  return request(`api/v2/sociana_admin/users/${userId}`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function getUserBySearchApi(
  searchData,
  fromInternalTeam,
  status,
  page,
  token
) {
  let query = `page=${page}`;
  if (!!fromInternalTeam) {
    query += `&from_internal_team=${fromInternalTeam}`;
  }
  if (!!searchData) {
    query += `&q=${searchData}`;
  }
  if (!!status) {
    query += `&active=${status}`;
  }
  return request(`api/v2/sociana_admin/search_users?${query}`, {
    method: 'GET',
    token
  });
}

export function searchUsersApi(ocId, query, page, token) {
  let queryText = `page=${page}`;
  if (!!query) {
    queryText += `&q=${query}`;
  }
  return request(
    `api/v2/sociana_admin/oc/${ocId}/search_users_by_occasion?${queryText}`,
    {
      method: 'GET',
      token
    }
  );
}

export function getUserByOccasionApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/get_users_by_occasion?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function qrCodeCreateApi(userId, color, bgColor, token) {
  let query = `${userId}?`;

  if (color) {
    query += `color=${color.replace(/#/g, '%23')}`;
  }

  if (bgColor) {
    query += `&bg_color=${bgColor.replace(/#/g, '%23')}`;
  }

  return request(`api/v2/sociana_admin/generate_qr_code/${query}`, {
    method: 'POST',
    token
  });
}

export function qrCodeDeleteApi(qrCodeId, token) {
  return request(`api/v2/sociana_admin/remove_qr_code/${qrCodeId}`, {
    method: 'DELETE',
    token
  });
}
