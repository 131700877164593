import request from './request';

export function createMessagesApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/send_bulk_template_messages`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}
export function getWhatsappMessageByModelableApi(
  ocId,
  modelableType,
  taskId,
  operationType,
  page,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/get_whatsapp_messages_by_modelable?page=${page}&modelable_type=${modelableType}&modelable_id=${taskId}&operation_type=${operationType}`,
    {
      method: 'GET',
      token
    }
  );
}

export function getWhatsappTemplateModelablesApi(
  ocId,
  modelableType,
  taskId,
  operationType,
  page,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/get_whatsapp_template_modelables_by_modelable?modelable_type=${modelableType}&modelable_id=${taskId}&page=${page}&operation_type=${operationType}`,
    {
      method: 'GET',
      token
    }
  );
}

export function getProvidedAnsweredUsersApi(
  ocId,
  taskId,
  userType,
  page,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_tasks/${taskId}/get_hospitality_task_users_by_task_type?page=${page}&user_type=${userType}`,
    {
      method: 'GET',
      token
    }
  );
}
