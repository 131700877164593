import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  deleteSosApi,
  occasionSosCreateApi,
  occasionSosViewApi,
  updateSosApi
} from '../api/sos';
import {
  OCCASION_SOS_CREATE,
  OCCASION_SOS_DELETE,
  OCCASION_SOS_UPDATE,
  OCCASION_SOS_VIEW
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  occasionSosCreateFailed,
  occasionSosCreateSuccess,
  occasionSosDeleteFailed,
  occasionSosDeleteSuccess,
  occasionSosUpdateFailed,
  occasionSosUpdateSuccess,
  occasionSosViewFailed,
  occasionSosViewSuccess
} from '../actions/sos';

export function* occasionSosCreateSaga(action) {
  try {
    const result = yield call(
      occasionSosCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionSosCreateSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionSosCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionSosCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionSosViewSaga(action) {
  try {
    const result = yield call(
      occasionSosViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionSosViewSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionSosViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* deleteSosSaga(action) {
  try {
    const result = yield call(
      deleteSosApi,
      action.payload.ocId,
      action.payload.sosId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionSosDeleteSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionSosDeleteFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updateSosSaga(action) {
  try {
    const result = yield call(
      updateSosApi,
      action.payload.ocId,
      action.payload.sosId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionSosUpdateSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionSosUpdateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionSosUpdateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* sosActionWatcher() {
  yield takeLatest(OCCASION_SOS_CREATE, progressSaga, occasionSosCreateSaga);
  yield takeLatest(OCCASION_SOS_VIEW, progressSaga, occasionSosViewSaga);
  yield takeLatest(OCCASION_SOS_DELETE, progressSaga, deleteSosSaga);
  yield takeLatest(OCCASION_SOS_UPDATE, progressSaga, updateSosSaga);
}
