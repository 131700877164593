import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import '../styles.scss';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import Tabs from '../../../../../../components/Tabs';
import { getCurrentTicket } from '../../../../../../selectors/ticket';
import {
  currentOccasionId,
  currentTicketId
} from '../../../../../../selectors/dynamicData';
import RegisteredTicketPurchases from './Registered';
import UnregisteredTicketPurchases from './Unregistered';
import TicketPurchaseCreate from './Create';
import { getCurrentOccasion } from '../../../../../../selectors/occasion';

function TicketPurchaseIndex(props) {
  const [tabIndex, setTabIndex] = useState('2.1');
  const onTabChange = (index) => {
    setTabIndex(index);
  };

  return (
    <ErrorBoundary>
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        onChange={onTabChange}
        tabPanes={[
          {
            tabName: 'Create',
            tabKey: '2.1',
            children: (
              <TicketPurchaseCreate
                occasionId={props.occasionId}
                ticketDetails={props.ticketDetails}
                ticketId={props.currentTicketId}
                tabIndex={tabIndex}
              />
            )
          },
          {
            tabName: 'Registered Purchases',
            tabKey: '2.2',
            children: (
              <RegisteredTicketPurchases
                occasionId={props.occasionId}
                ticketId={props.currentTicketId}
                tabIndex={tabIndex}
              />
            )
          },
          {
            tabName: 'Unregistered Purchases',
            tabKey: '2.3',
            children: (
              <UnregisteredTicketPurchases
                occasionId={props.occasionId}
                ticketId={props.currentTicketId}
                tabIndex={tabIndex}
              />
            )
          }
        ]}
      />
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  ticketDetails: getCurrentTicket(store, props),
  occasionId: currentOccasionId(store, props),
  currentOccasion: getCurrentOccasion(store, props),
  currentTicketId: currentTicketId(store, props)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketPurchaseIndex);
