import { union } from 'lodash';
import {
  OCCASION_ITINERARY_VIEW_SUCCESS,
  OCCASION_ITINERARY_VIEW_FAILED,
  OCCASION_ITINERARY_CREATE_SUCCESS,
  OCCASION_ITINERARY_DELETE_SUCCESS,
  OCCASION_ITINERARY_DELETE_FAILED
} from '../constants/actionTypes';

const initialState = {
  occasionItineraryData: null,
  error: null,
  itineraryLinks: null
};

const itinerary = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_ITINERARY_VIEW_SUCCESS: {
      const { data, scheduleId, links } = action.payload;
      const occasionGroupedData = state.occasionItineraryData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionItineraryData: {
          ...occasionGroupedData,
          [scheduleId]: [...currentOccasionNewIds]
        },
        itineraryLinks: links
      };
    }

    case OCCASION_ITINERARY_CREATE_SUCCESS: {
      const { data, scheduleId } = action.payload;
      const occasionGroupedData = state.occasionItineraryData || {};
      const currentOccasionItineraryIds = occasionGroupedData[scheduleId] || [];

      const newIds = [data.id, ...currentOccasionItineraryIds];
      return {
        ...state,
        occasionItineraryData: {
          ...occasionGroupedData,
          [scheduleId]: newIds
        }
      };
    }

    case OCCASION_ITINERARY_DELETE_SUCCESS: {
      const { data, scheduleId, links } = action.payload;
      const occasionGroupedData = state.occasionItineraryData || {};
      const currentOccasionItineraryIds = occasionGroupedData[scheduleId] || [];
      const filteredIds = currentOccasionItineraryIds.filter(
        (id) => id !== data.id
      );

      return {
        ...state,
        occasionItineraryData: {
          ...occasionGroupedData,
          [scheduleId]: filteredIds
        }
      };
    }

    case OCCASION_ITINERARY_VIEW_FAILED:
    case OCCASION_ITINERARY_DELETE_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default itinerary;
