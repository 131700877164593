import { union } from 'lodash';
import {
  CREATE_PARTICIPANT_BY_CSV_FAILED,
  CREATE_PARTICIPANT_FAILED,
  CREATE_PARTICIPANT_SUCCESS,
  DELETE_PARTICIPANT_FAILED,
  DELETE_PARTICIPANT_SUCCESS,
  GET_PARTICIPANT_FAILED,
  GET_PARTICIPANT_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  participantData: null,
  error: null,
  participantLinks: null
};

const Participant = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTICIPANT_SUCCESS: {
      const { data, competitionId, links } = action.payload;
      const occasionGroupedData = state.participantData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        participantData: {
          ...occasionGroupedData,
          [competitionId]: [...currentOccasionNewIds]
        },
        participantLinks: links
      };
    }

    case CREATE_PARTICIPANT_SUCCESS: {
      const { data, competitionId } = action.payload;
      const occasionGroupedData = state.participantData || {};
      const currentParticipantIds = occasionGroupedData[competitionId] || [];

      const newIds = [data.id, ...currentParticipantIds];
      return {
        ...state,
        participantData: {
          ...occasionGroupedData,
          [competitionId]: newIds
        }
      };
    }

    case DELETE_PARTICIPANT_SUCCESS: {
      const { data, competitionId } = action.payload;
      const occasionGroupedData = state.participantData || {};
      const currentParticipantIds = occasionGroupedData[competitionId] || [];
      const filteredIds = currentParticipantIds.filter((id) => id !== data.id);

      return {
        ...state,
        participantData: {
          ...occasionGroupedData,
          [competitionId]: filteredIds
        }
      };
    }

    case CREATE_PARTICIPANT_BY_CSV_FAILED:
    case CREATE_PARTICIPANT_FAILED:
    case GET_PARTICIPANT_FAILED:
    case DELETE_PARTICIPANT_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default Participant;
