import { union } from 'lodash';
import {
  OCCASION_ROLE_NAME_CREATE_FAILED,
  OCCASION_ROLE_NAME_CREATE_SUCCESS,
  OCCASION_ROLE_NAME_VIEW_FAILED,
  OCCASION_ROLE_NAME_VIEW_SUCCESS,
  DELETE_ROLE_NAME_FAILED,
  DELETE_ROLE_NAME_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  occasionRoleNameData: null,
  error: null,
  roleLinks: null
};

const roleName = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_ROLE_NAME_VIEW_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.occasionRoleNameData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionRoleNameData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        roleLinks: links
      };
    }

    case OCCASION_ROLE_NAME_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionRoleNameData || {};
      const currentOccasionRoleNameIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionRoleNameIds];
      return {
        ...state,
        occasionRoleNameData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }

    case DELETE_ROLE_NAME_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionRoleNameData || {};
      const currentOccasionRoleNameIds = occasionGroupedData[ocId] || [];
      const filteredIds = currentOccasionRoleNameIds.filter(
        (id) => id !== data.id
      );

      return {
        ...state,
        occasionRoleNameData: {
          ...occasionGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case OCCASION_ROLE_NAME_VIEW_FAILED:
    case OCCASION_ROLE_NAME_CREATE_FAILED:
    case DELETE_ROLE_NAME_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default roleName;
