import {
  CREATE_LIVE_STREAM,
  CREATE_LIVE_STREAM_SUCCESS,
  CREATE_LIVE_STREAM_FAILED,
  GET_LIVE_STREAM,
  GET_LIVE_STREAM_SUCCESS,
  GET_LIVE_STREAM_FAILED,
  SHOW_LIVE_STREAM,
  SHOW_LIVE_STREAM_SUCCESS,
  SHOW_LIVE_STREAM_FAILED
} from '../constants/actionTypes';

export const createLive = (occasionId, formData, callback) => ({
  type: CREATE_LIVE_STREAM,
  payload: { occasionId, formData },
  callback
});

export const createLiveSuccess = (occasionId, data, included, meta) => ({
  type: CREATE_LIVE_STREAM_SUCCESS,
  payload: { occasionId, data, included, meta }
});

export const createLiveFailed = (error) => ({
  type: CREATE_LIVE_STREAM_FAILED,
  error
});

export const getLive = (occasionId, page, callback) => ({
  type: GET_LIVE_STREAM,
  payload: { occasionId, page },
  callback
});

export const getLiveSuccess = (occasionId, data, included, links, meta) => ({
  type: GET_LIVE_STREAM_SUCCESS,
  payload: { occasionId, data, included, links, meta }
});

export const getLiveFailed = (error) => ({
  type: GET_LIVE_STREAM_FAILED,
  error
});

export const showLive = (occasionId, liveId, callback) => {
  return {
    type: SHOW_LIVE_STREAM,
    payload: { occasionId, liveId },
    callback
  };
};

export const showLiveSuccess = (occasionId, data, included, meta, liveId) => ({
  type: SHOW_LIVE_STREAM_SUCCESS,
  payload: { occasionId, data, included, meta, liveId }
});

export const showLiveFailed = (error) => ({
  type: SHOW_LIVE_STREAM_FAILED,
  error
});
