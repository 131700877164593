import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  createAccessControlApi,
  getAccessControlsApi,
  updateAccessControlApi
} from '../api/accessControl';

import {
  CREATE_ACCESS_CONTROL,
  GET_ACCESS_CONTROLS,
  UPDATE_ACCESS_CONTROL
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  getAccessControlsFailed,
  getAccessControlsSuccess,
  createAccessControlFailed,
  createAccessControlSuccess,
  updateAccessControlFailed,
  updateAccessControlSuccess
} from '../actions/accessControls';

export function* createAccessControlSaga(action) {
  try {
    const result = yield call(
      createAccessControlApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (typeof action.callback === 'function') {
      action.callback();
    }
    if (isValidResult(result)) {
      yield put(createAccessControlSuccess(result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
    } else if (result.meta && result.meta.message) {
      yield put(createAccessControlFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    if (typeof action.callback === 'function') {
      action.callback();
    }
    yield put(createAccessControlFailed(error));
    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updateAccessControlSaga(action) {
  try {
    const result = yield call(
      updateAccessControlApi,
      action.payload.ocId,
      action.payload.acId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(updateAccessControlSuccess(result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateAccessControlFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateAccessControlFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getAccessControlsSaga(action) {
  try {
    const result = yield call(
      getAccessControlsApi,
      action.payload.ocId,
      getUserToken()
    );

    if (typeof action.callback === 'function') {
      action.callback();
    }

    if (isValidResult(result)) {
      yield put(getAccessControlsSuccess(result.data));
      if (action.callback) {
        action.callback();
      }
    } else if (result?.meta && result?.meta?.message) {
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(getAccessControlsFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* accessControlsActionWatcher() {
  yield takeLatest(
    CREATE_ACCESS_CONTROL,
    progressSaga,
    createAccessControlSaga
  );
  yield takeLatest(
    UPDATE_ACCESS_CONTROL,
    progressSaga,
    updateAccessControlSaga
  );
  yield takeLatest(GET_ACCESS_CONTROLS, progressSaga, getAccessControlsSaga);
}
