import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import Tabs from '../../../../../components/Tabs';
import {
  currentOccasionId,
  currentHardLogoutId
} from '../../../../../selectors/dynamicData';
import { getCurrentOccasion } from '../../../../../selectors/occasion';
import { TabsHeader } from '../../../../../components/TabsHeader';
import ViewHardLogoutUser from './UserView';

function hardLayoutDetails(props) {
  const getHardLogoutComponent = () => {
    return (
      <ViewHardLogoutUser
        occasionId={props.occasionId}
        hardLogoutId={props.currentHardLogoutId}
      />
    );
  };

  const tabPanes = [
    {
      tabName: 'Hard Logout Users',
      tabKey: '1',
      children: getHardLogoutComponent()
    }
  ];
  const goBack = () => {
    props.history.goBack();
  };
  return (
    <ErrorBoundary>
      <SectionContent className="hard-logout-details-section">
        <TabsHeader
          name={props.currentOccasion.name}
          extraData={[
            props.currentOccasion.type,
            props.currentOccasion.guestType,
            'HardLogoutUsers'
          ]}
          wrapperClass="section-details"
        />
        <div className="hard-logout-details-container">
          <Tabs
            defaultActiveKey="1"
            tabPanes={tabPanes}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  occasionId: currentOccasionId(store, props),
  currentOccasion: getCurrentOccasion(store, props),
  currentHardLogoutId: currentHardLogoutId(store, props)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(hardLayoutDetails);
