import React, { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import Tabs from '../../../../../../components/Tabs';

import {
  currentOccasionId,
  currentMediaId
} from '../../../../../../selectors/dynamicData';
import { getCurrentOccasion } from '../../../../../../selectors/occasion';
import { TabsHeader } from '../../../../../../components/TabsHeader';
import { currentFaceInfoMediaData } from '../../../../../../selectors/faceInfo';
import MediaDetailsTab from './details';

function FaceInfoMediaDetails(props) {
  const detailsComponent = () => {
    return (
      <MediaDetailsTab
        mediaDetails={props.currentMediaData}
        occasionId={props.occasionId}
      />
    );
  };
  const tabPanes = useMemo(() => {
    return [
      {
        tabName: 'Details',
        tabKey: '1',
        children: detailsComponent()
      }
    ];
  }, [props?.currentMediaData, props?.occasionId]);

  const goBack = () => {
    props.history.goBack();
  };

  return (
    <ErrorBoundary>
      <SectionContent className="face-info-media-section">
        <TabsHeader
          name={props.currentOccasion.name}
          extraData={[
            props.currentOccasion.type,
            props.currentOccasion.guestType,
            'Media',
            props.currentMediaData?.name
          ]}
          wrapperClass="section-details"
        />
        <div className="face-info-media-section-container">
          <Tabs
            defaultActiveKey="1"
            tabPanes={tabPanes}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  occasionId: currentOccasionId(store, props),
  currentOccasion: getCurrentOccasion(store, props),
  currentMediaId: currentMediaId(store, props),
  currentMediaData: currentFaceInfoMediaData(store, props)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FaceInfoMediaDetails);
