import request from './request';

export function viewSpeakerReference(ocId, speakerId, pageNumber, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/speakers/${speakerId}/speaker_references?page=${pageNumber}`,
    {
      method: 'GET',
      token
    }
  );
}

export function editSpeakerReferenceApi(
  ocId,
  speakerId,
  speakerReferenceId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/speakers/${speakerId}/speaker_references/${speakerReferenceId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function deleteSpeakerReferenceApi(
  ocId,
  speakerId,
  speakerReferenceId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/speakers/${speakerId}/speaker_references/${speakerReferenceId}`,
    {
      method: 'DELETE',
      token
    }
  );
}
