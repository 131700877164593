import {
  GET_ENQUIRY_PAGINATION_SUCCESS,
  GET_ENQUIRY_PAGINATION_FAILED
} from '../constants/actionTypes';
import { union } from '../helpers/extraHelpers';

const initialState = {
  allIds: null,
  data: null,
  error: null,
  links: null,
  count: 0
};

const enquiry = (state = initialState, action) => {
  switch (action.type) {
    case GET_ENQUIRY_PAGINATION_SUCCESS: {
      const { data, links, page, meta } = action.payload;

      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];
      const allIds = page !== 1 ? union(state.allIds || [], newIds) : newIds;
      return {
        ...state,
        data: newIds,
        allIds,
        links,
        count: meta?.totalCount || 0
      };
    }

    case GET_ENQUIRY_PAGINATION_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};
export default enquiry;
