export const validateLogin = (data) => {
  const re1 = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  let errorObj = {
    email: null,
    password: null
  };

  if (!re1.test(data.email)) {
    errorObj = {
      ...errorObj,
      email: 'Please provide a valid email id'
    };
  }
  if (!data.password) {
    errorObj.password = 'Please provide a password.';
  } else if (data.password.length < 6) {
    errorObj.password = 'Password should be min 6 characters.';
  }
  return errorObj;
};
