import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { Form, AutoComplete, Image, Spin, Empty, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import InputGroup from '../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import {
  searchInternalTeamMember,
  assignInternalTeamMember
} from '../../../../../actions/socianaInternalUser';
import { currentOccasionId } from '../../../../../selectors/dynamicData';
import { getSearchedInternalUsers } from '../../../../../selectors/socianaInternalUser';
import { getImageSource } from '../../../../../helpers/imageHelper';
import { capitalize } from '../../../../../helpers/stringHelper';
import { adminData } from '../../../../../selectors/auth';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { SectionContent } from '../../../../../components/Section';

const { Option } = AutoComplete;

const validateAssign = (values) => {
  const errors = {};
  if (!values?.searchData?.id || !values?.searchData?.value)
    errors.searchData = 'Select a valid occasion from the list';
  if (!values?.accessType)
    errors.accessType = 'Select a valid access type from the list';
  if (!values?.rolename) errors.rolename = 'Provide a valid rolename';
  return errors;
};

const AssignInternalTeam = (props) => {
  const [assignForm, setAssignForm] = useState({
    searchData: { value: '', id: null },
    accessType: '',
    rolename: ''
  });

  const handleAssign = () => {
    const validateObj = validateAssign(assignForm);
    if (Object.keys(validateObj).length > 0) {
      setErrorMessages(validateObj);
    } else {
      setErrorMessages({});
      const formData = new FormData();
      formData.append('internal_team[user_id]', assignForm.searchData.id);
      formData.append('internal_team[type]', capitalize(assignForm.accessType));
      formData.append('internal_team[role_name]', assignForm.rolename);

      props.assignInternalTeamMember(props.occasionId, formData, () => {
        setAssignForm({
          searchData: { value: '', id: null },
          accessType: '',
          rolename: ''
        });
      });
    }
  };
  const handleSearch = (val) => {
    setAssignForm({ ...assignForm, searchData: { value: val, id: null } });
    if (val.length > 0) {
      props.searchInternalTeamMember(val);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (type === 'accessType') {
          value = e;
          name = type;
        }
        if (type === 'searchData') {
          value = {
            value: e?.value,
            id: e?.key
          };
          name = type;
        }

        const assignFormValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        setAssignForm({
          ...assignForm,
          [inputName]: assignFormValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [errorMessages, setErrorMessages] = useState({});

  return (
    <ErrorBoundary>
      <SectionContent className="occasion-internal-team-create-section">
        <div className="occasion-internal-team-create">
          <Form
            className="occasion-internal-team-form-container"
            onFinish={handleAssign}>
            <InputGroup
              fieldType="searchbox"
              className="occasion-searchData"
              name="searchData"
              label="Search Internal Team Member"
              loading={props.fetchProgress}
              notFoundContent="No Data"
              errorMessage={errorMessages?.searchData}
              value={assignForm?.searchData?.value}
              onSelect={(e, option) => handleInputChange(option, 'searchData')}
              onSearch={handleSearch}
              placeholder="Search Internal Team Member">
              {props.searchedInternalUser?.length > 0 &&
                assignForm?.searchData?.value?.length > 0 &&
                props.searchedInternalUser.map((item) => {
                  return (
                    <Option value={item?.name} key={item?.id}>
                      <div className="options-container">
                        <p className="avatar-image-container">
                          <Image
                            src={getImageSource(item?.avatarUrl)}
                            preview={false}
                          />
                        </p>
                        <div className="internal-member-occasion-details-container">
                          <p className="internal-member-occasion-name">
                            {item?.name}
                          </p>
                          <p className="internal-member-occasion-type">
                            {item?.email}
                          </p>
                        </div>
                      </div>
                    </Option>
                  );
                })}
            </InputGroup>
            <InputGroup
              fieldType="dropdown"
              className="occasion-searchData"
              name="accessType"
              label="Select Member Access Type"
              errorMessage={errorMessages?.accessType}
              optionLabelProp="label"
              value={assignForm?.accessType}
              placeholder="Select Access Type"
              onSelect={(e) => handleInputChange(e, 'accessType')}
              options={props.userAccessTypes
                ?.filter(
                  (item) =>
                    (item !== 'owner' && props.admin.accessType === 'admin') ||
                    props.admin.accessType === 'super_admin'
                )
                ?.map((item) => ({
                  label: capitalize(item),
                  value: item
                }))}
            />
            <InputGroup
              onChange={handleInputChange}
              value={assignForm?.rolename}
              name="rolename"
              label="Enter Rolename"
              errorMessage={errorMessages?.rolename}
              placeholder="Enter Rolename"
            />
            <SubmitButton
              buttonText="Assign"
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => ({
  occasionId: currentOccasionId(store, props),
  searchedInternalUser: getSearchedInternalUsers(store, props),
  admin: adminData(store, props),
  userAccessTypes: store.occasion.accessTypes,
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { searchInternalTeamMember, assignInternalTeamMember },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignInternalTeam);
