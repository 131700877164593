import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { Form, AutoComplete, Image } from 'antd';
import '../styles.scss';
import InputGroup from '../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import { JOIN_AS } from './InvitationConstants';
import {
  getOccasionPendingRegistration,
  pendingRegistrationSearch
} from '../../../../../actions/Invitation';
import { searchInternalTeamMember } from '../../../../../actions/socianaInternalUser';
import { getSearchedInternalUsers } from '../../../../../selectors/socianaInternalUser';
import { getImageSource } from '../../../../../helpers/imageHelper';

const { Option } = AutoComplete;

function PendingRegistrationSearchTab(props) {
  const [getRegistrationDetails, setGetRegistrationDetails] = React.useState(
    {}
  );
  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);
  const [errorMessages, setErrorMessages] = React.useState({});

  const validate = (values) => {
    const errors = {};
    if (
      !!values.text &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.text) &&
      !/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/.test(values.text)
    ) {
      errors.type = 'invalid Input';
    }
    return errors;
  };

  const handleGetRegistrationDetails = (page) => {
    try {
      const validateObj = validate(getRegistrationDetails);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        props.pendingRegistrationSearch(
          props.ocId,
          getRegistrationDetails?.text,
          getRegistrationDetails?.joinas,
          getRegistrationDetails?.invitedby?.id,
          (page = 1)
        );
        setGetRegistrationDetails({});
        setErrorMessages({});
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (isInitiallyDisabled) {
          setInitiallyDisabled(false);
        }
        if (type === 'joinas') {
          value = e.target.value;
          name = type;
        }
        if (type === 'invitedby') {
          value = {
            value: e?.value,
            id: e?.key
          };
          name = type;
        }
        if (type === 'text') {
          value = e.target.value;
          name = type;
        }
        const registrationDetailsValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        setGetRegistrationDetails({
          ...getRegistrationDetails,
          [inputName]: registrationDetailsValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleReset = (page) => {
    setGetRegistrationDetails({});
    setErrorMessages({});
    props.getOccasionPendingRegistration(props.ocId, (page = 1));
  };

  const handleInvitedSearch = (val) => {
    setGetRegistrationDetails({
      ...getRegistrationDetails,
      invitedby: { value: val, id: null }
    });
    if (val.length > 0) {
      props.searchInternalTeamMember(val);
    }
  };

  return (
    <Form
      className="invitation-input-search-container"
      onFinish={handleGetRegistrationDetails}>
      <InputGroup
        className="invitation-text-box"
        label="Email/Phone Number"
        placeholder="Enter Email/Phone Number"
        name="text"
        onChange={handleChange}
        value={getRegistrationDetails?.text}
        errorMessage={errorMessages.type}
      />
      <InputGroup
        label="Join As"
        className="invitation-inputbox"
        fieldType="dropdown"
        placeholder="Select"
        options={JOIN_AS}
        name="joinas"
        onChange={handleChange}
        value={getRegistrationDetails?.joinas}
      />
      <InputGroup
        className="invitation-id-box"
        fieldType="searchbox"
        label="Invited By"
        notFoundContent="No Data"
        placeholder="Search"
        name="invitedby"
        value={getRegistrationDetails?.invitedby?.value}
        onSelect={(e, option) => handleChange(option, 'invitedby')}
        onSearch={handleInvitedSearch}>
        {props.searchedInternalUser?.length > 0 &&
          getRegistrationDetails?.invitedby?.value?.length > 0 &&
          props.searchedInternalUser.map((item) => {
            return (
              <Option value={item?.name} key={item?.id}>
                <div className="options-container">
                  <p className="avatar-image-container">
                    <Image
                      src={getImageSource(item?.avatarUrl)}
                      preview={false}
                    />
                  </p>
                  <div className="internal-member-occasion-details-container">
                    <p className="internal-member-occasion-name">
                      {item?.name}
                    </p>
                    <p className="internal-member-occasion-type">
                      {item?.email}
                    </p>
                  </div>
                </div>
              </Option>
            );
          })}
      </InputGroup>
      <div className="invitation-button-box">
        <SubmitButton
          className="invitation-submit-inputbox"
          buttonText="Search"
          fetchProgress={props.fetchProgress}
          disabled={props.fetchProgress}
        />
        <Button className="reset-button" onClick={handleReset}>
          Reset
        </Button>
      </div>
    </Form>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  searchedInternalUser: getSearchedInternalUsers(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOccasionPendingRegistration,
      pendingRegistrationSearch,
      searchInternalTeamMember
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingRegistrationSearchTab);
