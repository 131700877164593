import { createSelector } from 'reselect';
import { currentActivityTrackerId } from './dynamicData';
import { userEntities } from './entity';
import { activityTrackerAbsentUserIds } from './static';

export const currentActivityTrackerAbsentUserIds = createSelector(
  currentActivityTrackerId,
  activityTrackerAbsentUserIds,
  (trId, ids) => trId && ids && ids[trId]
);
export const getAllActivityTrackerAbsentUsers = createSelector(
  userEntities,
  currentActivityTrackerAbsentUserIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
