import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SyncOutlined } from '@ant-design/icons';
import './styles.scss';
import { Button } from 'antd';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import TableView from '../../../../../components/Table';
import { occasionDiscussionWithUserData } from '../../../../../selectors/discussion';
import {
  occasionDiscussionView,
  occasionDiscussionDelete
} from '../../../../../actions/discussion';

const ViewDiscussion = (props) => {
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.occasionDiscussionView(props.occasionId, page);
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);
  const paginationOptions = {
    showTotal: () => (
      <div className="button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <div className="count-container">
          <span>Discussions</span>
          <span className="discussion-count">{props.count}</span>
        </div>
      </div>
    )
  };

  const deleteData = (id) => {
    props.occasionDiscussionDelete(props.occasionId, id);
  };

  return (
    <ErrorBoundary>
      <SectionContent className="discussion-view-section">
        <div className="discussion-view-container">
          <TableView
            isPaginated={true}
            paginationOptions={paginationOptions}
            isRouted={false}
            onPageChange={onPageChange}
            onDelete={deleteData}
            data={props.discussionData}
            fetchProgress={props.fetchProgress}
            paginationLinks={props.discussionLinks}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  discussionData: occasionDiscussionWithUserData(store, props),
  discussionLinks: store.discussion.discussionLinks,
  count: store.discussion.count
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { occasionDiscussionView, occasionDiscussionDelete },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(ViewDiscussion);
