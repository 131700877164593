import request from './request';

export function getWhatsappTemplatesWithPaginationApi(page, token) {
  return request(
    `api/v2/sociana_admin/get_all_whatsapp_template_messages?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}
export async function getWatiWithPaginationApi(page, requestOptions) {
  const response = await fetch(
    `https://live-mt-server.wati.io/300219/api/v1/getMessageTemplates?pageSize=25&pageNumber=${page}`,
    requestOptions
  );
  let result = await response.json();
  return result;
}

export function createWhatsappTemplateApi(formData, token) {
  return request(`api/v2/sociana_admin/whatsapp_template_messages`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}
export function deleteWhatsappTemplateApi(whatsappTemplateId, token) {
  return request(
    `api/v2/sociana_admin/whatsapp_template_messages/${whatsappTemplateId}`,
    {
      method: 'DELETE',
      token
    }
  );
}
export function updateWhatsappTemplateApi(whatsappTemplateId, formData, token) {
  return request(
    `api/v2/sociana_admin/whatsapp_template_messages/${whatsappTemplateId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function WhatsAppTemplateModelableViewApi(wtId, page, token) {
  return request(
    `api/v2/sociana_admin/whatsapp_template_messages/${wtId}/whatsapp_template_modelables?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function WhatsAppTemplateModelableCreateApi(
  wtId,
  modelableId,
  modelableType,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/whatsapp_template_messages/${wtId}/whatsapp_template_modelables?modelable_type=${modelableType}&modelable_id=${modelableId}`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function deleteWhatsAppTemplateModelableApi(
  wtId,
  whatsappTemplateModelableId,
  token
) {
  return request(
    `api/v2/sociana_admin/whatsapp_template_messages/${wtId}/whatsapp_template_modelables/${whatsappTemplateModelableId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function updateWhatsAppTemplateModelableApi(
  wtId,
  whatsappTemplateModelableId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/whatsapp_template_messages/${wtId}/whatsapp_template_modelables/${whatsappTemplateModelableId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

// ********************************************************************

export function WhatsAppTemplateParamsViewApi(wtId, wmId, page, token) {
  return request(
    `api/v2/sociana_admin/whatsapp_template_messages/${wtId}/whatsapp_template_modelables/${wmId}/whatsapp_template_params?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function WhatsAppTemplateParamsCreateApi(wtId, wmId, formData, token) {
  return request(
    `api/v2/sociana_admin/whatsapp_template_messages/${wtId}/whatsapp_template_modelables/${wmId}/whatsapp_template_params`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function updateWhatsAppTemplateParamsApi(
  wtId,
  wmId,
  wpId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/whatsapp_template_messages/${wtId}/whatsapp_template_modelables/${wmId}/whatsapp_template_params/${wpId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function deleteWhatsAppTemplateParamsApi(wtId, wmId, wpId, token) {
  return request(
    `api/v2/sociana_admin/whatsapp_template_messages/${wtId}/whatsapp_template_modelables/${wmId}/whatsapp_template_params/${wpId}`,
    {
      method: 'DELETE',
      token
    }
  );
}
