import {
  OCCASION_FIXED_GROUP_POST_SETTING_CREATE,
  OCCASION_FIXED_GROUP_POST_SETTING_CREATE_FAILED,
  OCCASION_FIXED_GROUP_POST_SETTING_CREATE_SUCCESS,
  OCCASION_FIXED_GROUP_POST_SETTING_VIEW,
  OCCASION_FIXED_GROUP_POST_SETTING_VIEW_FAILED,
  OCCASION_FIXED_GROUP_POST_SETTING_VIEW_SUCCESS,
  UPDATE_FIXED_GROUP_POST_SETTING,
  UPDATE_FIXED_GROUP_POST_SETTING_FAILED,
  UPDATE_FIXED_GROUP_POST_SETTING_SUCCESS,
  DELETE_FIXED_GROUP_POST_SETTING,
  DELETE_FIXED_GROUP_POST_SETTING_FAILED,
  DELETE_FIXED_GROUP_POST_SETTING_SUCCESS
} from '../constants/actionTypes';
export const occasionFixedGroupPostSettingCreate = (
  ocId,
  formData,
  callback
) => ({
  type: OCCASION_FIXED_GROUP_POST_SETTING_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionFixedGroupPostSettingCreateSuccess = (
  data,
  included,
  ocId
) => ({
  type: OCCASION_FIXED_GROUP_POST_SETTING_CREATE_SUCCESS,
  payload: { data, included, ocId }
});

export const occasionFixedGroupPostSettingCreateFailed = (error) => ({
  type: OCCASION_FIXED_GROUP_POST_SETTING_CREATE_FAILED,
  error
});

export const occasionFixedGroupPostSettingView = (ocId, page) => ({
  type: OCCASION_FIXED_GROUP_POST_SETTING_VIEW,
  payload: { ocId, page }
});

export const occasionFixedGroupPostSettingViewSuccess = (
  data,
  included,
  ocId,
  links
) => ({
  type: OCCASION_FIXED_GROUP_POST_SETTING_VIEW_SUCCESS,
  payload: { data, included, ocId, links }
});

export const occasionFixedGroupPostSettingViewFailed = (error) => ({
  type: OCCASION_FIXED_GROUP_POST_SETTING_VIEW_FAILED,
  error
});

export const updateFixedGroupPostSetting = (
  ocId,
  gpsId,
  formData,
  callback
) => ({
  type: UPDATE_FIXED_GROUP_POST_SETTING,
  payload: { ocId, gpsId, formData },
  callback
});

export const updateFixedGroupPostSettingSuccess = (ocId, data, included) => ({
  type: UPDATE_FIXED_GROUP_POST_SETTING_SUCCESS,
  payload: { ocId, data, included }
});

export const updateFixedGroupPostSettingFailed = (error) => ({
  type: UPDATE_FIXED_GROUP_POST_SETTING_FAILED,
  error
});

export const deleteFixedGroupPostSetting = (ocId, gpsId, callback) => ({
  type: DELETE_FIXED_GROUP_POST_SETTING,
  payload: { ocId, gpsId },
  callback
});

export const deleteFixedGroupPostSettingSuccess = (ocId, data) => ({
  type: DELETE_FIXED_GROUP_POST_SETTING_SUCCESS,
  payload: { ocId, data }
});

export const deleteFixedGroupPostSettingFailed = (error) => ({
  type: DELETE_FIXED_GROUP_POST_SETTING_FAILED,
  error
});
