import {
  OCCASION_ITINERARY_CREATE,
  OCCASION_ITINERARY_CREATE_FAILED,
  OCCASION_ITINERARY_CREATE_SUCCESS,
  OCCASION_ITINERARY_VIEW,
  OCCASION_ITINERARY_VIEW_FAILED,
  OCCASION_ITINERARY_VIEW_SUCCESS,
  OCCASION_ITINERARY_UPDATE,
  OCCASION_ITINERARY_UPDATE_FAILED,
  OCCASION_ITINERARY_UPDATE_SUCCESS,
  OCCASION_ITINERARY_DELETE,
  OCCASION_ITINERARY_DELETE_FAILED,
  OCCASION_ITINERARY_DELETE_SUCCESS
} from '../constants/actionTypes';

export const occasionItineraryCreate = (
  ocId,
  scheduleId,
  schedulableType,
  schedulableId,
  formData,
  callback
) => ({
  type: OCCASION_ITINERARY_CREATE,
  payload: { ocId, scheduleId, schedulableType, schedulableId, formData },
  callback
});

export const occasionItineraryCreateSuccess = (
  data,
  ocId,
  scheduleId,
  included,
  links
) => ({
  type: OCCASION_ITINERARY_CREATE_SUCCESS,
  payload: { data, ocId, scheduleId, included, links }
});

export const occasionItineraryCreateFailed = (error) => ({
  type: OCCASION_ITINERARY_CREATE_FAILED,
  error
});

export const occasionItineraryView = (
  ocId,
  scheduleId,
  schedulableType,
  schedulableId
) => ({
  type: OCCASION_ITINERARY_VIEW,
  payload: { ocId, scheduleId, schedulableType, schedulableId }
});

export const occasionItineraryViewSuccess = (
  data,
  ocId,
  scheduleId,
  included,
  links
) => ({
  type: OCCASION_ITINERARY_VIEW_SUCCESS,
  payload: { data, ocId, scheduleId, included, links }
});

export const occasionItineraryViewFailed = (error) => ({
  type: OCCASION_ITINERARY_VIEW_FAILED,
  error
});

export const occasionItineraryUpdate = (
  ocId,
  scheduleId,
  itineraryId,
  schedulableType,
  schedulableId,
  formData,
  callback
) => ({
  type: OCCASION_ITINERARY_UPDATE,
  payload: {
    ocId,
    scheduleId,
    itineraryId,
    schedulableType,
    schedulableId,
    formData
  },
  callback
});

export const occasionItineraryUpdateSuccess = (
  data,
  ocId,
  scheduleId,
  included,
  links
) => ({
  type: OCCASION_ITINERARY_UPDATE_SUCCESS,
  payload: { data, ocId, scheduleId, included, links }
});

export const occasionItineraryUpdateFailed = (error) => ({
  type: OCCASION_ITINERARY_UPDATE_FAILED,
  error
});

export const occasionItineraryDelete = (
  ocId,
  scheduleId,
  itineraryId,
  schedulableType,
  schedulableId
) => ({
  type: OCCASION_ITINERARY_DELETE,
  payload: { ocId, scheduleId, itineraryId, schedulableType, schedulableId }
});

export const occasionItineraryDeleteSuccess = (
  data,
  ocId,
  scheduleId,
  included,
  links
) => ({
  type: OCCASION_ITINERARY_DELETE_SUCCESS,
  payload: { data, ocId, scheduleId, included, links }
});

export const occasionItineraryDeleteFailed = (error) => ({
  type: OCCASION_ITINERARY_DELETE_FAILED,
  error
});
