import { put, call, takeLatest, debounce } from 'redux-saga/effects';
import progressSaga from './progressSaga';
import {
  WhatsAppTemplateModelableCreateApi,
  WhatsAppTemplateModelableViewApi,
  WhatsAppTemplateParamsCreateApi,
  WhatsAppTemplateParamsViewApi,
  createWhatsappTemplateApi,
  deleteWhatsAppTemplateModelableApi,
  deleteWhatsAppTemplateParamsApi,
  deleteWhatsappTemplateApi,
  getWatiWithPaginationApi,
  getWhatsappTemplatesWithPaginationApi,
  updateWhatsAppTemplateModelableApi,
  updateWhatsAppTemplateParamsApi,
  updateWhatsappTemplateApi
} from '../api/whatsAppTemplates';
import {
  CREATE_WHATSAPP_TEMPLATE_MODELABLE,
  CREATE_WHATSAPP_TEMPLATE_PARAMS,
  CREATE_WHATSAPP_TEMPLETS,
  DELETE_WHATSAPP_TEMPLATE_MODELABLE,
  DELETE_WHATSAPP_TEMPLATE_PARAMS,
  DELETE_WHATSAPP_TEMPLETS,
  GET_WATI_PAGINATION,
  GET_WHATSAPP_TEMPLATE_MODELABLE_PAGINATION,
  GET_WHATSAPP_TEMPLATE_PARAMS_PAGINATION,
  GET_WHATSAPP_TEMPLETS_PAGINATION,
  UPDATE_WHATSAPP_TEMPLATE_MODELABLE,
  UPDATE_WHATSAPP_TEMPLATE_PARAMS,
  UPDATE_WHATSAPP_TEMPLETS
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { entityDeleteRequestSuccess } from '../actions/entity';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import { getOccasionTypeName } from '../helpers/occasionHelper';
import {
  createWhatsAppTemplateModelableFailed,
  createWhatsAppTemplateModelableSuccess,
  createWhatsAppTemplateParamsFailed,
  createWhatsAppTemplateParamsSuccess,
  createWhatsappTemplateFailed,
  createWhatsappTemplateSuccess,
  deleteWhatsAppTemplateModelableFailed,
  deleteWhatsAppTemplateModelableSuccess,
  deleteWhatsAppTemplateParamsFailed,
  deleteWhatsAppTemplateParamsSuccess,
  deleteWhatsappTemplateFailed,
  deleteWhatsappTemplateSuccess,
  getWatiWithPaginationFailed,
  getWatiWithPaginationSuccess,
  getWhatsAppTemplateModelableWithPaginationFailed,
  getWhatsAppTemplateModelableWithPaginationSuccess,
  getWhatsAppTemplateParamsWithPaginationFailed,
  getWhatsAppTemplateParamsWithPaginationSuccess,
  getWhatsAppTempletsWithPaginationFailed,
  getWhatsAppTempletsWithPaginationSuccess,
  updateWhatsAppTemplateModelableFailed,
  updateWhatsAppTemplateModelableSuccess,
  updateWhatsAppTemplateParamsFailed,
  updateWhatsAppTemplateParamsSuccess,
  updateWhatsappTemplateFailed,
  updateWhatsappTemplateSuccess
} from '../actions/whatsAppTemplates';

export function* getWhatsAppTempletsSagaWithPagination(action) {
  try {
    const result = yield call(
      getWhatsappTemplatesWithPaginationApi,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getWhatsAppTempletsWithPaginationSuccess(
          result.data,
          result.links,
          result?.meta
        )
      );

      if (action.callback) {
        action.callback();
      }
    } else if (result?.meta && result?.meta?.message) {
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(getWhatsAppTempletsWithPaginationFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getWatiSagaWithPagination(action) {
  try {
    const result = yield call(
      getWatiWithPaginationApi,
      action.payload.page,
      action.payload.requestOptions
    );
    if (result.result === 'success') {
      yield put(
        getWatiWithPaginationSuccess(
          result.messageTemplates,
          result.link,
          action.payload.page
        )
      );

      if (action.callback) {
        action.callback();
      }
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(getWatiWithPaginationFailed(error));

    if (error) {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* createWhatsappTemplateSaga(action) {
  try {
    const result = yield call(
      createWhatsappTemplateApi,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(createWhatsappTemplateSuccess(result.data, result.included));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(createWhatsappTemplateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(createWhatsappTemplateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updateWhatsappTemplateSaga(action) {
  try {
    const result = yield call(
      updateWhatsappTemplateApi,
      action.payload.whatsappTemplateId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(updateWhatsappTemplateSuccess(result.data, result?.included));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateWhatsappTemplateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateWhatsappTemplateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* deleteWhatsappTemplateSaga(action) {
  try {
    const result = yield call(
      deleteWhatsappTemplateApi,
      action.payload.whatsappTemplateId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(deleteWhatsappTemplateSuccess(result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteWhatsappTemplateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* whatsAppTemplateModelableCreateSaga(action) {
  try {
    const result = yield call(
      WhatsAppTemplateModelableCreateApi,
      action.payload.wtId,
      action.payload.modelableId,
      action.payload.modelableType,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        createWhatsAppTemplateModelableSuccess(
          action.payload.wtId,
          result.data,
          result.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(createWhatsAppTemplateModelableFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(createWhatsAppTemplateModelableFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* whatsAppTemplateModelableViewSaga(action) {
  try {
    const result = yield call(
      WhatsAppTemplateModelableViewApi,
      action.payload.wtId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getWhatsAppTemplateModelableWithPaginationSuccess(
          result.data,
          result.included,
          action.payload.wtId,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getWhatsAppTemplateModelableWithPaginationFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* whatsAppTemplateModelableUpdateSaga(action) {
  try {
    const result = yield call(
      updateWhatsAppTemplateModelableApi,
      action.payload.wtId,
      action.payload.whatsappTemplateModelableId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateWhatsAppTemplateModelableSuccess(result.data, result?.included)
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateWhatsAppTemplateModelableFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateWhatsAppTemplateModelableFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* whatsAppTemplateModelableDeleteSaga(action) {
  try {
    const result = yield call(
      deleteWhatsAppTemplateModelableApi,
      action.payload.wtId,
      action.payload.whatsappTemplateModelableId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        deleteWhatsAppTemplateModelableSuccess(action.payload.wtId, result.data)
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteWhatsAppTemplateModelableFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

// *******************************************************************************

export function* whatsAppTemplateParamsCreateSaga(action) {
  try {
    const result = yield call(
      WhatsAppTemplateParamsCreateApi,
      action.payload.wtId,
      action.payload.wmId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        createWhatsAppTemplateParamsSuccess(
          action.payload.wmId,
          result.data,
          result.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(createWhatsAppTemplateParamsFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(createWhatsAppTemplateParamsFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* whatsAppTemplateParamsViewSaga(action) {
  try {
    const result = yield call(
      WhatsAppTemplateParamsViewApi,
      action.payload.wtId,
      action.payload.wmId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getWhatsAppTemplateParamsWithPaginationSuccess(
          result.data,
          result.included,
          action.payload.wmId,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getWhatsAppTemplateParamsWithPaginationFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* whatsAppTemplateParamsUpdateSaga(action) {
  try {
    const result = yield call(
      updateWhatsAppTemplateParamsApi,
      action.payload.wtId,
      action.payload.wmId,
      action.payload.wpId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(updateWhatsAppTemplateParamsSuccess(result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateWhatsAppTemplateParamsFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateWhatsAppTemplateParamsFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* whatsAppTemplateParamsDeleteSaga(action) {
  try {
    const result = yield call(
      deleteWhatsAppTemplateParamsApi,
      action.payload.wtId,
      action.payload.wmId,
      action.payload.wpId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        deleteWhatsAppTemplateParamsSuccess(action.payload.wmId, result.data)
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteWhatsAppTemplateParamsFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* whatsappTemplatesActionWatcher() {
  yield takeLatest(
    GET_WHATSAPP_TEMPLETS_PAGINATION,
    progressSaga,
    getWhatsAppTempletsSagaWithPagination
  );
  yield takeLatest(
    GET_WATI_PAGINATION,
    progressSaga,
    getWatiSagaWithPagination
  );
  yield takeLatest(
    CREATE_WHATSAPP_TEMPLETS,
    progressSaga,
    createWhatsappTemplateSaga
  );
  yield takeLatest(
    UPDATE_WHATSAPP_TEMPLETS,
    progressSaga,
    updateWhatsappTemplateSaga
  );
  yield takeLatest(
    DELETE_WHATSAPP_TEMPLETS,
    progressSaga,
    deleteWhatsappTemplateSaga
  );

  yield takeLatest(
    GET_WHATSAPP_TEMPLATE_MODELABLE_PAGINATION,
    progressSaga,
    whatsAppTemplateModelableViewSaga
  );
  yield takeLatest(
    CREATE_WHATSAPP_TEMPLATE_MODELABLE,
    progressSaga,
    whatsAppTemplateModelableCreateSaga
  );
  yield takeLatest(
    UPDATE_WHATSAPP_TEMPLATE_MODELABLE,
    progressSaga,
    whatsAppTemplateModelableUpdateSaga
  );
  yield takeLatest(
    DELETE_WHATSAPP_TEMPLATE_MODELABLE,
    progressSaga,
    whatsAppTemplateModelableDeleteSaga
  );

  yield takeLatest(
    GET_WHATSAPP_TEMPLATE_PARAMS_PAGINATION,
    progressSaga,
    whatsAppTemplateParamsViewSaga
  );
  yield takeLatest(
    CREATE_WHATSAPP_TEMPLATE_PARAMS,
    progressSaga,
    whatsAppTemplateParamsCreateSaga
  );
  yield takeLatest(
    UPDATE_WHATSAPP_TEMPLATE_PARAMS,
    progressSaga,
    whatsAppTemplateParamsUpdateSaga
  );
  yield takeLatest(
    DELETE_WHATSAPP_TEMPLATE_PARAMS,
    progressSaga,
    whatsAppTemplateParamsDeleteSaga
  );
}
