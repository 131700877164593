import { createSelector } from 'reselect';
import { occasionEntities } from './entity';
import { userOccasionIds } from './static';
import { currentUserId } from './dynamicData';

export const currentuserOccasionIds = createSelector(
  currentUserId,
  userOccasionIds,
  (userId, ids) => userId && ids && ids[userId]
);

export const getAllUserOccasionData = createSelector(
  occasionEntities,
  currentuserOccasionIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
