import { createSelector } from 'reselect';
import { currentLiveVideoId, currentOccasionId } from './dynamicData';
import { liveEntities, userEntities } from './entity';
import {
  occasionLiveIds,
  currentLiveId,
  occasionLiveTokens,
  occasionLiveUIDs
} from './static';

export const currentLiveIds = createSelector(
  currentOccasionId,
  occasionLiveIds,
  (ocId, data) => ocId && data && data[ocId]
);

export const getAllLive = createSelector(
  liveEntities,
  currentLiveIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAllLiveWithUsers = createSelector(
  getAllLive,
  userEntities,
  (data, users) => {
    const newData =
      (data &&
        data.length > 0 &&
        data.map((el) => {
          return {
            ...el,
            streamingBy: {
              ...users[el.streamingBy.id]
            }
          };
        })) ||
      [];
    return newData;
  }
);

export const getCurrentLive = createSelector(
  currentLiveId,
  liveEntities,
  (id, entities) => id && entities && entities[id]
);

export const getCurrentLiveToken = createSelector(
  currentOccasionId,
  currentLiveId,
  occasionLiveTokens,
  (ocId, id, tokens) => tokens && ocId && id && tokens[ocId] && tokens[ocId][id]
);

export const getCurrentLiveUID = createSelector(
  currentOccasionId,
  currentLiveId,
  occasionLiveUIDs,
  (ocId, id, uids) => uids && ocId && id && uids[ocId] && uids[ocId][id]
);

export const getLiveShowData = createSelector(
  liveEntities,
  currentLiveVideoId,
  (entities, id) => id && entities && entities[id]
);
