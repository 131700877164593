import { createSelector } from 'reselect';
import {
  userEntities,
  whatsappTemplateModelablesEntities,
  whatsappTemplateMessagesEntities,
  whatsappMessagesEntities
} from './entity';
import {
  allWhatsappTemplateModelablesIds,
  allUnansweredWhatsappTemplateModelablesIds,
  allProvidedAnsweredUsersIds,
  whatsappMessagesIds,
  allProvidedUnansweredUsersIds,
  unansweredWhatsappMessagesIds
} from './static';
import { currentHospitalityTaskId } from './dynamicData';

export const currentWhatsappTemplateModelablesIds = createSelector(
  currentHospitalityTaskId,
  allWhatsappTemplateModelablesIds,
  (hcId, ids) => hcId && ids && ids[hcId]
);

export const getAllWhatsappTemplateModelablesData = createSelector(
  whatsappTemplateModelablesEntities,
  currentWhatsappTemplateModelablesIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAllWhatsappTemplateModelablesWithTemplateMessagesData = createSelector(
  getAllWhatsappTemplateModelablesData,
  whatsappTemplateMessagesEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      whatsappTemplateMessage: {
        ...entities[item?.whatsappTemplateMessage?.id]
      }
    }))
);

export const currentProvidedAnsweredUsersIds = createSelector(
  currentHospitalityTaskId,
  allProvidedAnsweredUsersIds,
  (hcId, ids) => hcId && ids && ids[hcId]
);

export const getAllProvidedAnsweredUsersData = createSelector(
  userEntities,
  currentProvidedAnsweredUsersIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const currentWhatsappMessageIds = createSelector(
  currentHospitalityTaskId,
  whatsappMessagesIds,
  (hcId, ids) => hcId && ids && ids[hcId]
);

export const getAllWhatsappMessagesData = createSelector(
  whatsappMessagesEntities,
  currentWhatsappMessageIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getWhatsappMessagesWithUserAndTemplateMessageData = createSelector(
  getAllWhatsappMessagesData,
  whatsappTemplateMessagesEntities,
  userEntities,
  (data, entities, userEntities) =>
    data &&
    entities &&
    userEntities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      whatsappTemplateMessage: {
        ...entities[item?.whatsappTemplateMessage?.id]
      },
      user: { ...userEntities[item?.user?.id] }
    }))
);

export const currentProvidedUnansweredUsersIds = createSelector(
  currentHospitalityTaskId,
  allProvidedUnansweredUsersIds,
  (hcId, ids) => hcId && ids && ids[hcId]
);

export const getAllProvidedUnansweredUsersData = createSelector(
  userEntities,
  currentProvidedUnansweredUsersIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const currentUnansweredWhatsappMessageIds = createSelector(
  currentHospitalityTaskId,
  unansweredWhatsappMessagesIds,
  (hcId, ids) => hcId && ids && ids[hcId]
);

export const getAllUnansweredWhatsappMessagesData = createSelector(
  whatsappMessagesEntities,
  currentUnansweredWhatsappMessageIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getUnansweredWhatsappMessagesWithUserAndTemplateMessageData = createSelector(
  getAllUnansweredWhatsappMessagesData,
  whatsappTemplateMessagesEntities,
  userEntities,
  (data, entities, userEntities) =>
    data &&
    entities &&
    userEntities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      whatsappTemplateMessage: {
        ...entities[item?.whatsappTemplateMessage?.id]
      },
      user: { ...userEntities[item?.user?.id] }
    }))
);

export const currentUnansweredWhatsappTemplateModelablesIds = createSelector(
  currentHospitalityTaskId,
  allUnansweredWhatsappTemplateModelablesIds,
  (hcId, ids) => hcId && ids && ids[hcId]
);

export const getAllUnansweredWhatsappTemplateModelablesData = createSelector(
  whatsappTemplateModelablesEntities,
  currentUnansweredWhatsappTemplateModelablesIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAllUnansweredWhatsappTemplateModelablesWithTemplateMessagesData = createSelector(
  getAllUnansweredWhatsappTemplateModelablesData,
  whatsappTemplateMessagesEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      whatsappTemplateMessage: {
        ...entities[item?.whatsappTemplateMessage?.id]
      }
    }))
);
