import {
  GUEST_VIEW,
  GUEST_VIEW_FAILED,
  GUEST_VIEW_SUCCESS
} from '../constants/actionTypes';

export const guestView = (ocId, userId, page) => ({
  type: GUEST_VIEW,
  payload: { ocId, userId, page }
});

export const guestViewSuccess = (data, included, links, userId) => ({
  type: GUEST_VIEW_SUCCESS,
  payload: { data, included, links, userId }
});

export const guestViewFailed = (error) => ({
  type: GUEST_VIEW_FAILED,
  error
});
