import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import HospitalityCreate from './CreateCategory';
import { IMAGE_RADIO } from './HospitalityConstants';

const HospitalityDetailsView = ({ hospitalityDetails, occasionId }) => {
  const [isEditOpen, setIsEditOpen] = React.useState(false);
  if (!isEditOpen) {
    return (
      <div className="hospitality-details-container">
        <p className="hospitality-details card-details">
          <p className="fields">Avatar:</p>
          <p className="field-data card-image-container">
            <img
              className="hospitality-avatar"
              src={IMAGE_RADIO[hospitalityDetails?.avatarName]}></img>
          </p>
        </p>
        <p className="hospitality-details">
          <p className="fields">Name:</p>
          <p className="field-data">{hospitalityDetails?.name}</p>
        </p>
        <p className="hospitality-details">
          <p className="fields">Description:</p>
          {hospitalityDetails?.description ? (
            <p className="field-data">{hospitalityDetails?.description}</p>
          ) : (
            'NA'
          )}
        </p>
        <p className="hospitality-details">
          <p className="fields">Purpose:</p>
          {hospitalityDetails?.purpose ? (
            <p className="field-data">{hospitalityDetails?.purpose}</p>
          ) : (
            'NA'
          )}
        </p>
        <p className="hospitality-details">
          <p className="fields">Order:</p>
          <p className="field-data">{hospitalityDetails?.orderNumber}</p>
        </p>
        <button
          className="edit-details-container"
          onClick={() => setIsEditOpen(true)}>
          <EditOutlined />
          <span>Edit Details</span>
        </button>
      </div>
    );
  }
  return (
    <div className="edit-container">
      <HospitalityCreate
        hospitalityDetails={hospitalityDetails}
        occasionId={occasionId}
        hospitalityCategoryId={hospitalityDetails.id}
        handleCloseClick={() => setIsEditOpen(false)}
      />
    </div>
  );
};
export default HospitalityDetailsView;
