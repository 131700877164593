import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { Form, Empty } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup/index';
import { ListItem } from '../../../../../../components/ListItem';

const MultiSelect = (props) => {
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);

  useEffect(() => {
    fetchDetails(page);
  }, [page]);

  useEffect(() => {
    fetchSearchDeails(searchPage);
  }, [searchPage]);

  const fetchDetails = (page) => {
    props.fetchPage(...props?.params, page);
  };

  const fetchSearchDeails = (page) => {
    if (props.users.name.length > 0) {
      props.fetchSearchDetails(...props?.params, page, props.users.name);
    }
  };
  const handleChange = (e) => {
    try {
      if (e) {
        const value = e?.target?.value;
        const inputName = e?.target?.name;

        if (value) {
          props.fetchSearchDetails(...props.params, 1, value);
        }

        if (searchPage !== 1) {
          setSearchPage(1);
        }

        if (Object.keys(props.errorMessages).length > 0) {
          props.setErrorMessages({});
        }
        props.setUsers({
          ...props.users,
          [inputName]: value
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onEndReached = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height === scrollHeight) {
      if (props.links.next && !props.users.name) {
        setPage((val) => val + 1);
      } else if (props.searchedUsersLinks && props.users.name) {
        setSearchPage((val) => val + 1);
      }
    }
  };

  const onSelectUser = (data) => {
    const found = props.selectedUsers.find((el) => el.id === data.id);
    if (!found) {
      props.setSelectedUsers([...props.selectedUsers, data]);
    } else {
      const filteredList = props.selectedUsers.filter(
        (el) => el.id !== data.id
      );
      props.setSelectedUsers([...filteredList]);
    }
  };

  const getFilteredList = () => {
    const selectedUserIds = props.selectedUsers.map((data) => data?.id);

    const userList = props.users.name ? props.searchedUsers : props.allUsers;

    const filteredList =
      userList &&
      userList?.filter((el) => !selectedUserIds.includes(el.id) && el);
    return filteredList || [];
  };

  const renderSelectedUsers = () => {
    return (
      <div className="selected-users-container" onScroll={onEndReached}>
        {props.selectedUsers &&
          props.selectedUsers.map((item) => {
            return (
              <ListItem
                key={item.id}
                data={item}
                onSelect={onSelectUser}
                selected={true}
              />
            );
          })}
      </div>
    );
  };

  const renderListBox = () => {
    return (
      <div className="users-list-box" onScroll={onEndReached}>
        {getFilteredList().length === 0 && !props.fetchProgress && (
          <div className="users-empty-list">
            <Empty />
          </div>
        )}
        {getFilteredList().map((item) => {
          return <ListItem key={item.id} data={item} onSelect={onSelectUser} />;
        })}
        {props.fetchProgress && (
          <div className="users-list-loader">
            <LoadingOutlined className="loader-icon" />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <InputGroup
        placeholder="Enter Name"
        name="name"
        onChange={handleChange}
        value={props.users?.name}
        errorMessage={props.errorMessages?.name}
      />
      {renderSelectedUsers()}
      {renderListBox()}
    </>
  );
};

export default MultiSelect;
