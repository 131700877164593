import { delay, put, takeLatest } from 'redux-saga/effects';
import { activateAlert, removeAlert } from '../actions/alert';
import {
  REQUEST_AUTO_DISMISS_ALERT,
  REQUEST_HIDE_ALERT,
  REQUEST_SHOW_ALERT
} from '../constants/actionTypes';

export function* showAlertSaga(action) {
  try {
    yield put(activateAlert(action.alertType, action.alertData));
  } catch (error) {
    console.error(error);
  }
}

export function* dismissAlertSaga() {
  try {
    yield put(removeAlert());
  } catch (error) {
    console.error(error);
  }
}

export function* autoDismissAlert(action) {
  try {
    yield put(activateAlert(action.alertType, action.alertData));

    yield delay(action.delay);

    yield put(removeAlert());
  } catch (error) {
    console.error(error);
  }
}

export default function* alertWatcher() {
  yield takeLatest(REQUEST_SHOW_ALERT, showAlertSaga);
  yield takeLatest(REQUEST_HIDE_ALERT, dismissAlertSaga);
  yield takeLatest(REQUEST_AUTO_DISMISS_ALERT, autoDismissAlert);
}
