import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  createNewOccasion,
  getOccasionWithPagination,
  getOccasionSettings,
  updateOccasion,
  getOccasionByFieldApi,
  updateOccasionCodeApi,
  updateOccasionFontApi
} from '../api/occasion';

import {
  CREATE_OCCASION,
  EDIT_OCCASION,
  GET_OCCASION_BY_FIELD,
  GET_OCCASION_PAGINATION,
  GET_OCCASION_SETTINGS,
  UPDATE_OCCASION_CODE,
  UPDATE_OCCASION_FONT
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { entityDeleteRequestSuccess } from '../actions/entity';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import { getOccasionTypeName } from '../helpers/occasionHelper';
import {
  createOccasionSuccess,
  createOccasionFailed,
  getOccasionWithPaginationSuccess,
  getOccasionWithPaginationFailed,
  getOccasionSettingsFailed,
  getOccasionSettingsSuccess,
  updateOccasionSuccess,
  updateOccasionFailed,
  getOccasionByFieldSuccess,
  getOccasionByFieldFailed,
  updateOccasionCodeFailed,
  updateOccasionCodeSuccess,
  updateOccasionFontSuccess,
  updateOccasionFontFailed
} from '../actions/occasion';

export function* getOccasionSettingsSaga() {
  try {
    const result = yield call(getOccasionSettings, getUserToken());

    if (isValidResult(result)) {
      yield put(getOccasionSettingsSuccess(result));
    } else if (result?.meta && result?.meta?.message) {
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(getOccasionSettingsFailed(error));
    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getOccasionsSagaWithPagination(action) {
  try {
    const result = yield call(
      getOccasionWithPagination,
      action.payload.page,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getOccasionWithPaginationSuccess(
          result.data,
          result?.included,
          result.links,
          result?.meta,
          action.payload.page
        )
      );
      // if (result.meta && result.meta.message) {
      //   yield put(requestAutoHideAlert(result.meta.message, 'Success'));
      // }
      if (action.callback) {
        action.callback();
      }
    } else if (result?.meta && result?.meta?.message) {
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(getOccasionWithPaginationFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* createOccasionSaga(action) {
  try {
    const result = yield call(
      createNewOccasion,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(createOccasionSuccess(result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(createOccasionFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(createOccasionFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updateOccasionSaga(action) {
  try {
    const result = yield call(
      updateOccasion,
      action.payload.occasionId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(updateOccasionSuccess(result.data));

      if (result.data.type !== getOccasionTypeName(action.payload.prevType)) {
        yield put(
          entityDeleteRequestSuccess({
            type: getOccasionTypeName(action.payload.prevType),
            id: action.payload.occasionId
          })
        );
      }
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateOccasionFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Some error occured', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateOccasionFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updateOccasionCodeSaga(action) {
  try {
    const result = yield call(
      updateOccasionCodeApi,
      action.payload.ocId,
      action.payload.occasionCodeId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(updateOccasionCodeSuccess(result.data, result?.included));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateOccasionCodeFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Some error occured', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateOccasionCodeFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getOccasionsByFieldSaga(action) {
  try {
    const result = yield call(
      getOccasionByFieldApi,
      action.payload.ocName,
      action.payload.startDate,
      action.payload.endDate,
      action.payload.guestType,
      action.payload.ocType,
      action.payload.ocCode,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getOccasionByFieldSuccess(
          result.data,
          result.included,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getOccasionByFieldFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* updateOccasionFontSaga(action) {
  try {
    const result = yield call(
      updateOccasionFontApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateOccasionFontSuccess(
          result.data,
          result?.included,
          action.payload.ocId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateOccasionFontFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Some error occured', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateOccasionFontFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* occasionActionWatcher() {
  yield takeLatest(GET_OCCASION_SETTINGS, getOccasionSettingsSaga);
  yield takeLatest(CREATE_OCCASION, progressSaga, createOccasionSaga);
  yield takeLatest(EDIT_OCCASION, progressSaga, updateOccasionSaga);
  yield takeLatest(UPDATE_OCCASION_CODE, progressSaga, updateOccasionCodeSaga);
  yield takeLatest(
    GET_OCCASION_PAGINATION,
    progressSaga,
    getOccasionsSagaWithPagination
  );
  yield takeLatest(
    GET_OCCASION_BY_FIELD,
    progressSaga,
    getOccasionsByFieldSaga
  );
  yield takeLatest(UPDATE_OCCASION_FONT, progressSaga, updateOccasionFontSaga);
}
