import {
  GET_WHATSAPP_TEMPLETS_PAGINATION,
  GET_WHATSAPP_TEMPLETS_PAGINATION_SUCCESS,
  GET_WHATSAPP_TEMPLETS_PAGINATION_FAILED,
  GET_WATI_PAGINATION,
  GET_WATI_PAGINATION_SUCCESS,
  GET_WATI_PAGINATION_FAILED,
  CREATE_WHATSAPP_TEMPLETS,
  CREATE_WHATSAPP_TEMPLETS_SUCCESS,
  CREATE_WHATSAPP_TEMPLETS_FAILED,
  DELETE_WHATSAPP_TEMPLETS,
  DELETE_WHATSAPP_TEMPLETS_SUCCESS,
  DELETE_WHATSAPP_TEMPLETS_FAILED,
  UPDATE_WHATSAPP_TEMPLETS,
  UPDATE_WHATSAPP_TEMPLETS_SUCCESS,
  UPDATE_WHATSAPP_TEMPLETS_FAILED,
  GET_WHATSAPP_TEMPLATE_MODELABLE_PAGINATION,
  GET_WHATSAPP_TEMPLATE_MODELABLE_PAGINATION_SUCCESS,
  GET_WHATSAPP_TEMPLATE_MODELABLE_PAGINATION_FAILED,
  CREATE_WHATSAPP_TEMPLATE_MODELABLE,
  CREATE_WHATSAPP_TEMPLATE_MODELABLE_SUCCESS,
  CREATE_WHATSAPP_TEMPLATE_MODELABLE_FAILED,
  DELETE_WHATSAPP_TEMPLATE_MODELABLE,
  DELETE_WHATSAPP_TEMPLATE_MODELABLE_SUCCESS,
  DELETE_WHATSAPP_TEMPLATE_MODELABLE_FAILED,
  UPDATE_WHATSAPP_TEMPLATE_MODELABLE,
  UPDATE_WHATSAPP_TEMPLATE_MODELABLE_SUCCESS,
  UPDATE_WHATSAPP_TEMPLATE_MODELABLE_FAILED,
  GET_WHATSAPP_TEMPLATE_PARAMS_PAGINATION,
  GET_WHATSAPP_TEMPLATE_PARAMS_PAGINATION_SUCCESS,
  GET_WHATSAPP_TEMPLATE_PARAMS_PAGINATION_FAILED,
  CREATE_WHATSAPP_TEMPLATE_PARAMS,
  CREATE_WHATSAPP_TEMPLATE_PARAMS_SUCCESS,
  CREATE_WHATSAPP_TEMPLATE_PARAMS_FAILED,
  DELETE_WHATSAPP_TEMPLATE_PARAMS,
  DELETE_WHATSAPP_TEMPLATE_PARAMS_SUCCESS,
  DELETE_WHATSAPP_TEMPLATE_PARAMS_FAILED,
  UPDATE_WHATSAPP_TEMPLATE_PARAMS,
  UPDATE_WHATSAPP_TEMPLATE_PARAMS_SUCCESS,
  UPDATE_WHATSAPP_TEMPLATE_PARAMS_FAILED
} from '../constants/actionTypes';
export const getWhatsAppTempletsWithPagination = (page) => ({
  type: GET_WHATSAPP_TEMPLETS_PAGINATION,
  payload: { page }
});

export const getWhatsAppTempletsWithPaginationSuccess = (
  data,
  links,
  meta
) => ({
  type: GET_WHATSAPP_TEMPLETS_PAGINATION_SUCCESS,
  payload: { data, links, meta }
});

export const getWhatsAppTempletsWithPaginationFailed = (error) => ({
  type: GET_WHATSAPP_TEMPLETS_PAGINATION_FAILED,
  error
});

export const getWatiWithPagination = (page, requestOptions) => ({
  type: GET_WATI_PAGINATION,
  payload: { page, requestOptions }
});

export const getWatiWithPaginationSuccess = (data, link, page) => ({
  type: GET_WATI_PAGINATION_SUCCESS,
  payload: { data, link, page }
});

export const getWatiWithPaginationFailed = (error) => ({
  type: GET_WATI_PAGINATION_FAILED,
  error
});
export const createWhatsappTemplate = (formData, callback) => ({
  type: CREATE_WHATSAPP_TEMPLETS,
  payload: { formData },
  callback
});

export const createWhatsappTemplateSuccess = (data, included) => ({
  type: CREATE_WHATSAPP_TEMPLETS_SUCCESS,
  payload: { data, included }
});

export const createWhatsappTemplateFailed = (error) => ({
  type: CREATE_WHATSAPP_TEMPLETS_FAILED,
  error
});

export const updateWhatsappTemplate = (
  whatsappTemplateId,
  formData,
  callback
) => ({
  type: UPDATE_WHATSAPP_TEMPLETS,
  payload: { whatsappTemplateId, formData },
  callback
});

export const updateWhatsappTemplateSuccess = (data, included) => ({
  type: UPDATE_WHATSAPP_TEMPLETS_SUCCESS,
  payload: { data, included }
});

export const updateWhatsappTemplateFailed = (error) => ({
  type: UPDATE_WHATSAPP_TEMPLETS_FAILED,
  error
});

export const deleteWhatsappTemplate = (whatsappTemplateId) => ({
  type: DELETE_WHATSAPP_TEMPLETS,
  payload: { whatsappTemplateId }
});

export const deleteWhatsappTemplateSuccess = (data) => ({
  type: DELETE_WHATSAPP_TEMPLETS_SUCCESS,
  payload: { data }
});

export const deleteWhatsappTemplateFailed = (error) => ({
  type: DELETE_WHATSAPP_TEMPLETS_FAILED,
  error
});

export const getWhatsAppTemplateModelableWithPagination = (wtId, page) => ({
  type: GET_WHATSAPP_TEMPLATE_MODELABLE_PAGINATION,
  payload: { wtId, page }
});

export const getWhatsAppTemplateModelableWithPaginationSuccess = (
  data,
  included,
  wtId,
  link
) => ({
  type: GET_WHATSAPP_TEMPLATE_MODELABLE_PAGINATION_SUCCESS,
  payload: { data, included, wtId, link }
});

export const getWhatsAppTemplateModelableWithPaginationFailed = (error) => ({
  type: GET_WHATSAPP_TEMPLATE_MODELABLE_PAGINATION_FAILED,
  error
});

export const createWhatsAppTemplateModelable = (
  wtId,
  modelableId,
  modelableType,
  formData,
  callback
) => ({
  type: CREATE_WHATSAPP_TEMPLATE_MODELABLE,
  payload: { wtId, modelableId, modelableType, formData },
  callback
});
export const createWhatsAppTemplateModelableSuccess = (
  wtId,
  data,
  included
) => ({
  type: CREATE_WHATSAPP_TEMPLATE_MODELABLE_SUCCESS,
  payload: { wtId, data, included }
});

export const createWhatsAppTemplateModelableFailed = (error) => ({
  type: CREATE_WHATSAPP_TEMPLATE_MODELABLE_FAILED,
  error
});

export const updateWhatsAppTemplateModelable = (
  wtId,
  whatsappTemplateModelableId,
  formData,
  callback
) => ({
  type: UPDATE_WHATSAPP_TEMPLATE_MODELABLE,
  payload: { wtId, whatsappTemplateModelableId, formData },
  callback
});
export const updateWhatsAppTemplateModelableSuccess = (data, included) => ({
  type: UPDATE_WHATSAPP_TEMPLATE_MODELABLE_SUCCESS,
  payload: { data, included }
});

export const updateWhatsAppTemplateModelableFailed = (error) => ({
  type: UPDATE_WHATSAPP_TEMPLATE_MODELABLE_FAILED,
  error
});

export const deleteWhatsAppTemplateModelable = (
  wtId,
  whatsappTemplateModelableId
) => ({
  type: DELETE_WHATSAPP_TEMPLATE_MODELABLE,
  payload: { wtId, whatsappTemplateModelableId }
});

export const deleteWhatsAppTemplateModelableSuccess = (wtId, data) => ({
  type: DELETE_WHATSAPP_TEMPLATE_MODELABLE_SUCCESS,
  payload: { wtId, data }
});

export const deleteWhatsAppTemplateModelableFailed = (error) => ({
  type: DELETE_WHATSAPP_TEMPLATE_MODELABLE_FAILED,
  error
});

// **************************************************************

export const getWhatsAppTemplateParamsWithPagination = (wtId, wmId, page) => ({
  type: GET_WHATSAPP_TEMPLATE_PARAMS_PAGINATION,
  payload: { wtId, wmId, page }
});

export const getWhatsAppTemplateParamsWithPaginationSuccess = (
  data,
  included,
  wmId,
  link
) => ({
  type: GET_WHATSAPP_TEMPLATE_PARAMS_PAGINATION_SUCCESS,
  payload: { data, included, wmId, link }
});

export const getWhatsAppTemplateParamsWithPaginationFailed = (error) => ({
  type: GET_WHATSAPP_TEMPLATE_PARAMS_PAGINATION_FAILED,
  error
});

export const createWhatsAppTemplateParams = (
  wtId,
  wmId,
  formData,
  callback
) => ({
  type: CREATE_WHATSAPP_TEMPLATE_PARAMS,
  payload: { wtId, wmId, formData },
  callback
});
export const createWhatsAppTemplateParamsSuccess = (wmId, data, included) => ({
  type: CREATE_WHATSAPP_TEMPLATE_PARAMS_SUCCESS,
  payload: { wmId, data, included }
});

export const createWhatsAppTemplateParamsFailed = (error) => ({
  type: CREATE_WHATSAPP_TEMPLATE_PARAMS_FAILED,
  error
});

export const updateWhatsAppTemplateParams = (
  wtId,
  wmId,
  wpId,
  formData,
  callback
) => ({
  type: UPDATE_WHATSAPP_TEMPLATE_PARAMS,
  payload: { wtId, wmId, wpId, formData },
  callback
});
export const updateWhatsAppTemplateParamsSuccess = (data) => ({
  type: UPDATE_WHATSAPP_TEMPLATE_PARAMS_SUCCESS,
  payload: { data }
});

export const updateWhatsAppTemplateParamsFailed = (error) => ({
  type: UPDATE_WHATSAPP_TEMPLATE_PARAMS_FAILED,
  error
});

export const deleteWhatsAppTemplateParams = (wtId, wmId, wpId) => ({
  type: DELETE_WHATSAPP_TEMPLATE_PARAMS,
  payload: { wtId, wmId, wpId }
});

export const deleteWhatsAppTemplateParamsSuccess = (wmId, data) => ({
  type: DELETE_WHATSAPP_TEMPLATE_PARAMS_SUCCESS,
  payload: { wmId, data }
});

export const deleteWhatsAppTemplateParamsFailed = (error) => ({
  type: DELETE_WHATSAPP_TEMPLATE_PARAMS_FAILED,
  error
});
