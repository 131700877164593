import { createSelector } from 'reselect';
import { currentTicketId } from './dynamicData';
import { questionEntities, questionOptionEntities } from './entity';
import { questionIds } from './static';

export const currentOccasionQuestionIds = createSelector(
  currentTicketId,
  questionIds,
  (ticketId, ids) => ticketId && ids && ids[ticketId]
);
export const getAllOccasionQuestions = createSelector(
  questionEntities,
  currentOccasionQuestionIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
export const getAllQuestionWithOptionsData = createSelector(
  getAllOccasionQuestions,
  questionOptionEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      questionableFormOptions: [
        ...item?.questionableFormOptions?.map((el) => entities[el?.id])
      ]
    }))
);
