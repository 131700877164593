import { createSelector } from 'reselect';
import { emailSaverEntities, userEntities } from './entity';
import { notificationIds, notificationLinks } from './static';
import { currentEmailId, currentOccasionId } from './dynamicData';
import { concatArrayData } from '../helpers/extraHelpers';

export const currentNotificationIds = createSelector(
  currentOccasionId,
  notificationIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getEmailData = createSelector(
  currentNotificationIds,
  emailSaverEntities,
  (ids, entities) => {
    return (
      ids &&
      entities &&
      ids?.length > 0 &&
      ids.map((id) => {
        const bodyArr = entities[id].body;
        const imageLinksArr = entities[id].imageAttachmentLinks;
        const fileLinksArr = entities[id].fileUrlLinks;

        let body = concatArrayData(bodyArr, '\n');
        let imageAttachmentLinks = concatArrayData(imageLinksArr, ',');
        let fileUrlLinks = concatArrayData(fileLinksArr, ',');
        return {
          ...entities[id],
          body,
          imageAttachmentLinks,
          fileUrlLinks
        };
      })
    );
  }
);

export const getEmailWithUserData = createSelector(
  getEmailData,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      user: { ...entities[item?.user?.id] }
    }))
);

export const getEmailLinks = createSelector(
  currentOccasionId,
  notificationLinks,
  (ocId, links) => ocId && links && links[ocId]
);

export const getCurrentEmail = createSelector(
  currentEmailId,
  emailSaverEntities,
  (id, entities) => id && entities && entities[id]
);
