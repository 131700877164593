import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import { userOccasionViewApi } from '../api/userOccasion';
import { USER_OCCASION_VIEW } from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  UserOccasionViewFailed,
  UserOccasionViewSuccess
} from '../actions/userOccasion';

export function* userOccasionViewSaga(action) {
  try {
    const result = yield call(
      userOccasionViewApi,
      action.payload.userId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        UserOccasionViewSuccess(
          action.payload.userId,
          result.data,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(UserOccasionViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* userOccasionActionWatcher() {
  yield takeLatest(USER_OCCASION_VIEW, progressSaga, userOccasionViewSaga);
}
