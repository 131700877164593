import { createSelector } from 'reselect';
import { featureControlEntities } from './entity';
import { currentOccasionId } from './dynamicData';
import { featureIds } from './static';

export const getCurrentOccasionFeatureControls = createSelector(
  currentOccasionId,
  featureControlEntities,
  (ocId, entities) =>
    (ocId &&
      entities &&
      Object.keys(entities).length > 0 &&
      Object.values(entities).filter((item) => item.occasionId == ocId)) ||
    (entities && Array.isArray(Object.keys(entities)) ? [] : null)
);

export const getAllFeatures = createSelector(
  featureIds,
  featureControlEntities,
  (ids, entities) => ids && entities && ids.map((id) => entities[id])
);
