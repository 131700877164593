import React from 'react';
import CardImage from '../../../../../components/Table/CardImage';

const SpeakerDetailsView = ({ speakerDetails, occasionId }) => {
  return (
    <div className="speaker-details-container">
      {!!speakerDetails?.speakerImageUrl && (
        <p className="speaker-details">
          <p className="fields">Avatar:</p>
          <p className="field-data">
            {' '}
            <CardImage data={speakerDetails?.speakerImageUrl} />
          </p>
        </p>
      )}
      <p className="speaker-details">
        <p className="fields">Name:</p>
        <p className="field-data">{speakerDetails?.name}</p>
      </p>
      {!!speakerDetails?.designation && (
        <p className="speaker-details">
          <p className="fields">Designation:</p>
          <p className="field-data">{speakerDetails?.designation}</p>
        </p>
      )}
      {!!speakerDetails?.organization && (
        <p className="speaker-details">
          <p className="fields">Organization:</p>
          <p className="field-data">{speakerDetails?.organization}</p>
        </p>
      )}
      {!!speakerDetails?.email && (
        <p className="speaker-details">
          <p className="fields">Email:</p>
          <p className="field-data">{speakerDetails?.email}</p>
        </p>
      )}
      {!!speakerDetails?.phone && (
        <p className="speaker-details">
          <p className="fields">Phone:</p>
          <p className="field-data">{speakerDetails?.phone}</p>
        </p>
      )}
      {!!speakerDetails?.priority && (
        <p className="speaker-details">
          <p className="fields">Priority:</p>
          <p className="field-data">{speakerDetails?.priority}</p>
        </p>
      )}
      {!!speakerDetails?.about && (
        <p className="speaker-details">
          <p className="fields">About:</p>
          <p className="field-data">{speakerDetails?.about}</p>
        </p>
      )}
      {Array.isArray(speakerDetails.websiteLinks) &&
        speakerDetails?.websiteLinks.length > 0 && (
          <p className="speaker-websiteLinks">
            <p className="websiteLinks-container">WebsiteLink:</p>
            {speakerDetails?.websiteLinks?.map((item) => (
              <ul style={{ margin: '0', padding: '0 10px 0 25px' }}>
                <li>
                  <a href={item} target="_blank">
                    {item}
                  </a>
                </li>
              </ul>
            ))}
          </p>
        )}
    </div>
  );
};
export default SpeakerDetailsView;
