import React from 'react';
import LiveShowRoute from '../routes/LiveShowRoute';
import './styles.scss';

const LiveShow = () => {
  return (
    <div className="live-video-container">
      <LiveShowRoute />
    </div>
  );
};

export default LiveShow;
