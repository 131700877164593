import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../components/Section';
import { getCurrentOccasion } from '../../../../selectors/occasion';
import './styles.scss';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import Tabs from '../../../../components/Tabs';
import { currentOccasionId } from '../../../../selectors/dynamicData';
import OccasionDetailsView from './OccasionDetails';
import AccessControls from './AccessControls';
import FeatureControls from './FeatureControls';
import ViewInternalTeam from './InternalTeam/ViewInternalTeam';
import Posts from './Reports/posts';
import { renderRoute } from '../../../../components/RouterHOC';
import PostCreate from './Post/Create';
import PostsView from './Post/view';
import EventPostsView from './Post/eventPostView';
import GalleryCreate from './Gallery/Create';
import GalleryImageView from './Gallery/Images';
import GalleryVideoView from './Gallery/Videos';
import SendCreate from './Invitation/SendCreate';
import PendingInvitation from './Invitation/PendingInvitation';
import PendingRegistration from './Invitation/PendingRegistration';
import ViewOccasionEvent from './Events/EventView';
import HospitalityView from './Hospitality/ViewCategory';
import { TabsHeader } from '../../../../components/TabsHeader';
import ViewGroup from './Group/GroupView';
import LocationTriggerView from './Trigger/Location/view';
import ActionTriggerView from './Trigger/Action/view';
import TimeTriggerView from './Trigger/Time/view';
import LiveVideoLinkCreate from './LiveVideos/LiveVideoCreate';
import LiveVideoView from './LiveVideos/LiveVideoView';
import SosView from './SOS/SosView';
import ViewLive from './LiveEvent/View';
import MembersView from './Members/View';
import { getFeatureControls } from '../../../../actions/featureControls';
import { getAccessControls } from '../../../../actions/accessControls';
import { getAllFeatures } from '../../../../selectors/featureControls';
import { resetTabIndex, setCurrentTabIndex } from '../../../../actions/tabKeys';
import ViewSponsorship from './Sponsorship/SponsorshipView';
import ViewImportantContact from './ImportantContact/importantContactView';
import ViewAward from './Award/AwardView';
import ViewTicket from './Ticket/TicketView';
import ScheduleView from './Schedule/ScheduleView';
import NotificationView from './Notification/View';
import CarouselView from './SocianaThink/Carousel/View';
import DivisionView from './SocianaThink/Division/View';
import SmsView from './Notification/Sms/View';
import ViewAlbum from './Gallery/Album/View';
import OccasionAppDetails from './OccasionApp/Details';
import RoleNameView from './RoleName/View';
import ContactUsView from './Hospitality/ContactUs/View';
import PollPostView from './PollPost/View/index';
import PollPostQuizView from './PollPost/View/quizView';
import PollPostPollView from './PollPost/View/pollView';
import OccasionCodeView from './OccasionCode/View';
import WhiteListView from './occasionWhiteList/View';
import { getAllAccessControls } from '../../../../selectors/accessControls';
import ViewDiscussion from './Discussion/view';
import OccasionTabSettingView from './OccasionTabSetting/View/index';
import SpeakerView from './Speakers/View';
import ViewActivityTracker from './ActivityTracker/view';
import ViewBlackListUser from './ActivityTracker/BlackListUsers/view';
import ViewAlarm from './Alarm/View';
import ViewHardLogout from './HardLogout/View';
import ViewFaceInfoUser from './FaceInfo/User/View';
import ViewFaceInfoMedia from './FaceInfo/Media/View';
import ViewFixedGroupPostSetting from './FixedGroupPostSetting/view';
import HospitalityGoogleSheetView from './Hospitality/GoogleSheet/View';
import ViewCompetition from './Competition/view';
import CreateMemberByCsv from './Members/Create/index';
import TicketGoogleSheetView from './Ticket/GoogleSheet/view';
import ViewOccasionResources from './occasionResources/view';
import ViewCategoryStatus from './occasionResources/CategoryStatus/view';

function OccasionDetails(props) {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      props.getFeatureControls(props.occasionId, () => {
        setLoader(false);
      });
      props.getAccessControls(props.occasionId, () => {
        setLoader(false);
      });
    }, [10]);
  }, []);

  const loaderContainer = () => {
    return (
      <div className="flex-center base-min-height">
        <LoadingOutlined className="font-2x" />
      </div>
    );
  };

  const featureNotEnabledContainer = () => {
    return (
      <div className="flex-center base-min-height">
        This feature is not enabled.
      </div>
    );
  };

  const getRequiredData = (key) =>
    props.features?.find((el) => el.feature === key);

  const getRequiredAccessControlData = (key) =>
    props.accessControls?.find((el) => el.accessType === key);

  const getWhiteListComponent = () => {
    const allow_only_whitelist_members = getRequiredAccessControlData(
      'allow_only_whitelist_members'
    );

    if (loader) {
      return loaderContainer();
    }

    if (allow_only_whitelist_members?.status !== 'on') {
      return featureNotEnabledContainer();
    }
    return <WhiteListView occasionId={props.occasionId} />;
  };

  const getOccasionTabSettingComponent = () => {
    const custom_occasion_tab = getRequiredAccessControlData(
      'custom_occasion_tab'
    );

    if (loader) {
      return loaderContainer();
    }

    if (custom_occasion_tab?.status !== 'on') {
      return featureNotEnabledContainer();
    }
    return <OccasionTabSettingView occasionId={props.occasionId} />;
  };

  const getFixedGroupPostSettingComponent = () => {
    const custom_occasion_tab = getRequiredAccessControlData(
      'fixed_group_post_setting'
    );

    if (loader) {
      return loaderContainer();
    }

    if (custom_occasion_tab?.status !== 'on') {
      return featureNotEnabledContainer();
    }
    return <ViewFixedGroupPostSetting occasionId={props.occasionId} />;
  };

  const getControlsComponent = () => {
    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'Access Controls',
            tabKey: '2.1',
            children: <AccessControls occasionId={props.occasionId} />
          },
          {
            tabName: 'Feature Controls',
            tabKey: '2.2',
            children: <FeatureControls occasionId={props.occasionId} />
          },
          {
            tabName: 'Occasion Tab Setting',
            tabKey: '2.3',
            children: getOccasionTabSettingComponent()
          },
          {
            tabName: 'Occasion Code',
            tabKey: '2.4',
            children: <OccasionCodeView occasionId={props.occasionId} />
          },
          {
            tabName: 'Role Name',
            tabKey: '2.5',
            children: <RoleNameView occasionId={props.occasionId} />
          },
          {
            tabName: 'White List',
            tabKey: '2.6',
            children: getWhiteListComponent()
          },
          {
            tabName: 'Occasion App',
            tabKey: '2.7',
            children: <OccasionAppDetails occasionId={props.occasionId} />
          },
          {
            tabName: 'Fixed Group Post Setting',
            tabKey: '2.8',
            children: getFixedGroupPostSettingComponent()
          }
        ]}
      />
    );
  };

  const getInternalTeamComponent = () => {
    return <ViewInternalTeam occasionId={props.occasionId} />;
  };

  const getMembersComponent = () => {
    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'View',
            tabKey: '4.1',
            children: (
              <MembersView
                occasionId={props.occasionId}
                occasionDetails={props.currentOccasion}
              />
            )
          },
          {
            tabName: 'Create',
            tabKey: '4.2',
            children: <CreateMemberByCsv occasionId={props.occasionId} />
          }
        ]}
      />
    );
  };

  const getReportsComponent = () => {
    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'Posts',
            tabKey: '5.1',
            children: <Posts occasionId={props.occasionId} />
          }
        ]}
      />
    );
  };

  const getPostComponent = () => {
    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'Create',
            tabKey: '6.1',
            children: <PostCreate occasionId={props.occasionId} />
          },
          {
            tabName: 'Occasion Posts',
            tabKey: '6.2',
            children: (
              <PostsView
                occasionId={props.occasionId}
                currentOccasion={props.currentOccasion}
              />
            )
          },
          {
            tabName: 'Event Posts',
            tabKey: '6.3',
            children: <EventPostsView occasionId={props.occasionId} />
          }
        ]}
      />
    );
  };

  const getDiscussionComponent = () => {
    return (
      <ViewDiscussion
        occasionId={props.occasionId}
        currentOccasion={props.currentOccasion}
      />
    );
  };

  const getInvitationComponent = () => {
    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'Send',
            tabKey: '8.1',
            children: <SendCreate occasionId={props.occasionId} />
          },
          {
            tabName: 'Pending Invitation',
            tabKey: '8.2',
            children: <PendingInvitation occasionId={props.occasionId} />
          },
          {
            tabName: 'Pending Registration',
            tabKey: '8.3',
            children: <PendingRegistration occasionId={props.occasionId} />
          }
        ]}
      />
    );
  };

  const getEventComponent = () => {
    return (
      <ViewOccasionEvent
        occasionId={props.occasionId}
        currentOccasion={props.currentOccasion}
        modelGroupEventKey={getRequiredAccessControlData('model_group_event')}
      />
    );
  };

  const getScheduleComponent = () => {
    return (
      <ScheduleView
        occasionId={props.occasionId}
        currentOccasion={props.currentOccasion}
      />
    );
  };

  const getLiveStream = () => {
    const liveStream = getRequiredData('live_streams');

    if (loader) {
      return loaderContainer();
    }

    if (liveStream?.status !== 'on') {
      return featureNotEnabledContainer();
    }

    return <ViewLive occasionId={props.occasionId} />;
  };

  const getGalleryComponent = () => {
    const galleryData = getRequiredData('gallaries');

    if (loader) {
      return loaderContainer();
    }

    if (galleryData?.status !== 'on') {
      return featureNotEnabledContainer();
    }

    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'Create',
            tabKey: '12.1',
            children: (
              <GalleryCreate
                occasionId={props.occasionId}
                galleryId={props.galleryId}
              />
            )
          },
          {
            tabName: 'Images',
            tabKey: '12.2',
            children: (
              <GalleryImageView
                occasionId={props.occasionId}
                galleryId={props.galleryId}
              />
            )
          },
          {
            tabName: 'Videos',
            tabKey: '12.3',
            children: (
              <GalleryVideoView
                occasionId={props.occasionId}
                galleryId={props.galleryId}
              />
            )
          },
          {
            tabName: 'Album',
            tabKey: '12.4',
            children: <ViewAlbum occasionId={props.occasionId} />
          }
        ]}
      />
    );
  };

  const getGroupComponent = () => {
    return <ViewGroup occasionId={props.occasionId} />;
  };

  const getHospitalityComponent = () => {
    const hospitalityData = getRequiredData('hospitalities');

    if (loader) {
      return loaderContainer();
    }

    if (hospitalityData?.status !== 'on') {
      return featureNotEnabledContainer();
    }
    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'Category',
            tabKey: '14.1',
            children: (
              <HospitalityView
                occasionId={props.occasionId}
                occasionDetails={props.currentOccasion}
              />
            )
          },
          {
            tabName: 'Contact Us',
            tabKey: '14.2',
            children: <ContactUsView occasionId={props.occasionId} />
          },
          {
            tabName: 'Google Sheet',
            tabKey: '14.3',
            children: (
              <HospitalityGoogleSheetView occasionId={props.occasionId} />
            )
          }
        ]}
      />
    );
  };

  const getSosComponent = () => {
    return <SosView occasionId={props.occasionId} />;
  };

  const getLiveVideoComponent = () => {
    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'Create',
            tabKey: '16.1',
            children: <LiveVideoLinkCreate occasionId={props.occasionId} />
          },
          {
            tabName: 'View',
            tabKey: '16.2',
            children: <LiveVideoView occasionId={props.occasionId} />
          }
        ]}
      />
    );
  };

  const getImportantContactComponent = () => {
    const importantContacts = getRequiredData('important_contacts');

    if (loader) {
      return loaderContainer();
    }

    if (importantContacts?.status !== 'on') {
      return featureNotEnabledContainer();
    }
    return <ViewImportantContact occasionId={props.occasionId} />;
  };

  const getSponsorshipComponent = () => {
    const sponsorships = getRequiredData('sponsorships');

    if (loader) {
      return loaderContainer();
    }

    if (sponsorships?.status !== 'on') {
      return featureNotEnabledContainer();
    }
    return <ViewSponsorship occasionId={props.occasionId} />;
  };

  const getAwardComponent = () => {
    const awards = getRequiredData('awards');

    if (loader) {
      return loaderContainer();
    }

    if (awards?.status !== 'on') {
      return featureNotEnabledContainer();
    }
    return <ViewAward occasionId={props.occasionId} />;
  };

  const getCompetitionComponent = () => {
    const awards = getRequiredData('competition');

    if (loader) {
      return loaderContainer();
    }

    if (awards?.status !== 'on') {
      return featureNotEnabledContainer();
    }
    return <ViewCompetition occasionId={props.occasionId} />;
  };

  const getOccasionResourcesComponent = () => {
    const resource = getRequiredData('enable_occasion_resources');

    if (loader) {
      return loaderContainer();
    }

    if (resource?.status !== 'on') {
      return featureNotEnabledContainer();
    }
    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'View',
            tabKey: '31.1',
            children: <ViewOccasionResources occasionId={props.occasionId} />
          },
          {
            tabName: 'Category Status',
            tabKey: '31.2',
            children: <ViewCategoryStatus occasionId={props.occasionId} />
          }
        ]}
      />
    );
  };

  const getTicketComponent = () => {
    const tickets = getRequiredData('tickets');

    if (loader) {
      return loaderContainer();
    }

    if (tickets?.status !== 'on') {
      return featureNotEnabledContainer();
    }
    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'View',
            tabKey: '20.1',
            children: <ViewTicket occasionId={props.occasionId} />
          },
          {
            tabName: 'Google Sheet',
            tabKey: '20.2',
            children: <TicketGoogleSheetView occasionId={props.occasionId} />
          }
        ]}
      />
    );
  };

  const getTriggerComponent = () => {
    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'Time',
            tabKey: '21.1',
            children: <TimeTriggerView occasionId={props.occasionId} />
          },
          {
            tabName: 'Location',
            tabKey: '21.2',
            children: <LocationTriggerView occasionId={props.occasionId} />
          },
          {
            tabName: 'Action',
            tabKey: '21.3',
            children: <ActionTriggerView occasionId={props.occasionId} />
          }
        ]}
      />
    );
  };

  const getNotificationEmailComponent = () => {
    const email_notifications = getRequiredData('email_notifications');

    if (loader) {
      return loaderContainer();
    }

    if (email_notifications?.status !== 'on') {
      return featureNotEnabledContainer();
    }
    return <NotificationView occasionId={props.occasionId} />;
  };

  const getNotificationSmsComponent = () => {
    const sms_notifications = getRequiredData('sms_notifications');

    if (loader) {
      return loaderContainer();
    }

    if (sms_notifications?.status !== 'on') {
      return featureNotEnabledContainer();
    }
    return <SmsView occasionId={props.occasionId} />;
  };

  const getNotificationComponent = () => {
    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'Email',
            tabKey: '22.1',
            children: getNotificationEmailComponent()
          },
          {
            tabName: 'SMS',
            tabKey: '22.2',
            children: getNotificationSmsComponent()
          }
        ]}
      />
    );
  };

  const getSocianaThinkComponent = () => {
    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'Carousel',
            tabKey: '23.1',
            children: <CarouselView occasionId={props.occasionId} />
          },
          {
            tabName: 'Division',
            tabKey: '23.2',
            children: <DivisionView occasionId={props.occasionId} />
          }
        ]}
      />
    );
  };

  const getPollPostComponent = () => {
    const poll_posts = getRequiredData('poll_posts');

    if (loader) {
      return loaderContainer();
    }

    if (poll_posts?.status !== 'on') {
      return featureNotEnabledContainer();
    }
    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'All View',
            tabKey: '24.1',
            children: (
              <PollPostView
                occasionId={props.occasionId}
                modelGroupPollPostKey={getRequiredAccessControlData(
                  'model_group_poll_post'
                )}
              />
            )
          },
          {
            tabName: 'Quiz View',
            tabKey: '24.2',
            children: <PollPostQuizView occasionId={props.occasionId} />
          },
          {
            tabName: 'Poll View',
            tabKey: '24.3',
            children: <PollPostPollView occasionId={props.occasionId} />
          }
        ]}
      />
    );
  };

  const getActivityTrackerComponent = () => {
    const poll_posts = getRequiredData('activity_trackers');

    if (loader) {
      return loaderContainer();
    }

    if (poll_posts?.status !== 'on') {
      return featureNotEnabledContainer();
    }
    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'View',
            tabKey: '26.1',
            children: <ViewActivityTracker occasionId={props.occasionId} />
          },
          {
            tabName: 'Black List Users',
            tabKey: '26.2',
            children: <ViewBlackListUser occasionId={props.occasionId} />
          }
        ]}
      />
    );
  };
  const getAlarmComponent = () => {
    const poll_posts = getRequiredData('alarms');

    if (loader) {
      return loaderContainer();
    }

    if (poll_posts?.status !== 'on') {
      return featureNotEnabledContainer();
    }
    return <ViewAlarm occasionId={props.occasionId} />;
  };

  const getFaceInfoComponent = () => {
    const faceInfo = getRequiredData('facial_recognition_for_gallery');

    if (loader) {
      return loaderContainer();
    }

    if (faceInfo?.status !== 'on') {
      return featureNotEnabledContainer();
    }
    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'User',
            tabKey: '29.1',
            children: <ViewFaceInfoUser occasionId={props.occasionId} />
          },
          {
            tabName: 'Media',
            tabKey: '29.2',
            children: <ViewFaceInfoMedia occasionId={props.occasionId} />
          }
        ]}
      />
    );
  };

  const tabPanes = [
    {
      tabName: 'Controls',
      tabKey: '2',
      children: getControlsComponent()
    },
    {
      tabName: 'Internal Team',
      tabKey: '3',
      children: getInternalTeamComponent()
    },
    {
      tabName: 'Members',
      tabKey: '4',
      children: getMembersComponent()
    },
    {
      tabName: 'Reports',
      tabKey: '5',
      children: getReportsComponent()
    },
    {
      tabName: 'Post',
      tabKey: '6',
      children: getPostComponent()
    },
    {
      tabName: 'Discussion',
      tabKey: '7',
      children: getDiscussionComponent()
    },
    {
      tabName: 'Invitation',
      tabKey: '8',
      children: getInvitationComponent()
    },
    {
      tabName: 'Event',
      tabKey: '9',
      children: getEventComponent()
    },
    {
      tabName: 'Schedule',
      tabKey: '10',
      children: getScheduleComponent()
    },
    { tabName: 'Live Stream', tabKey: '11', children: getLiveStream() },
    {
      tabName: 'Gallery',
      tabKey: '12',
      children: getGalleryComponent()
    },
    {
      tabName: 'Group',
      tabKey: '13',
      children: getGroupComponent()
    },
    {
      tabName: 'Hospitality',
      tabKey: '14',
      children: getHospitalityComponent()
    },
    {
      tabName: 'SOS',
      tabKey: '15',
      children: getSosComponent()
    },
    {
      tabName: 'Live Video',
      tabKey: '16',
      children: getLiveVideoComponent()
    },
    {
      tabName: 'Important Contact',
      tabKey: '17',
      children: getImportantContactComponent()
    },
    {
      tabName: 'Sponsorship',
      tabKey: '18',
      children: getSponsorshipComponent()
    },
    {
      tabName: 'Award',
      tabKey: '19',
      children: getAwardComponent()
    },
    {
      tabName: 'Ticket',
      tabKey: '20',
      children: getTicketComponent()
    },
    {
      tabName: 'Trigger',
      tabKey: '21',
      children: getTriggerComponent()
    },
    {
      tabName: 'Notification',
      tabKey: '22',
      children: getNotificationComponent()
    },
    {
      tabName: 'Sociana Think',
      tabKey: '23',
      children: getSocianaThinkComponent()
    },
    {
      tabName: 'Poll Post',
      tabKey: '24',
      children: getPollPostComponent()
    },
    {
      tabName: 'Speakers',
      tabKey: '25',
      children: <SpeakerView occasionId={props.occasionId} />
    },
    {
      tabName: 'Activity Tracker',
      tabKey: '26',
      children: getActivityTrackerComponent()
    },
    {
      tabName: 'Alarm',
      tabKey: '27',
      children: getAlarmComponent()
    },
    {
      tabName: 'Hard Logout',
      tabKey: '28',
      children: <ViewHardLogout occasionId={props.occasionId} />
    },
    {
      tabName: 'Face Information',
      tabKey: '29',
      children: getFaceInfoComponent()
    },
    {
      tabName: 'Competition',
      tabKey: '30',
      children: getCompetitionComponent()
    },
    {
      tabName: 'Occasion Resources',
      tabKey: '31',
      children: getOccasionResourcesComponent()
    }
  ];

  const goBack = () => {
    props.resetTabIndex('occasion');
    props.history.goBack();
  };

  const handleTabChange = (key) => {
    props.setCurrentTabIndex(key, 'occasion');
  };

  tabPanes
    .sort((a, b) => a.tabName.localeCompare(b.tabName))
    .unshift({
      tabName: 'Details',
      tabKey: '1',
      children: <OccasionDetailsView occasion={props.currentOccasion} />
    });

  return (
    <ErrorBoundary>
      <SectionContent className="occasion-tab-section">
        <TabsHeader
          name={props.currentOccasion.name}
          extraData={[
            props.currentOccasion.type,
            props.currentOccasion.guestType
          ]}
          wrapperClass="section-details"
        />
        <div className="occasion-tab-container">
          <Tabs
            defaultActiveKey={props.currentTab}
            tabPanes={tabPanes}
            onChange={handleTabChange}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  occasionId: currentOccasionId(store, props),
  currentOccasion: getCurrentOccasion(store, props),
  features: getAllFeatures(store, props),
  currentTab: store.tabKeys.occasion,
  accessControls: getAllAccessControls(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFeatureControls,
      resetTabIndex,
      setCurrentTabIndex,
      getAccessControls
    },
    dispatch
  );

export default renderRoute(getCurrentOccasion)(
  connect(mapStateToProps, mapDispatchToProps)(OccasionDetails)
);
