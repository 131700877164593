import request from './request';

export function getLocationTriggerWithPaginationApi(
  ocId,
  page,
  modelableType,
  modelableId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/location_triggers?page=${page}&modelable_id=${modelableId}&modelable_type=${modelableType}`,
    {
      method: 'GET',
      token
    }
  );
}

export function createLocationTriggerApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/location_triggers`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function getActionTriggerWithPaginationApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/action_triggers?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function createActionTriggerApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/action_triggers`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function getTimeTriggerWithPaginationApi(
  ocId,
  page,
  modelableType,
  modelableId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/time_triggers?page=${page}&modelable_id=${modelableId}&modelable_type=${modelableType}`,
    {
      method: 'GET',
      token
    }
  );
}

export function searchTimeTriggerByPinnedWithPaginationApi(
  ocId,
  page,
  isPinned,
  modelableType,
  modelableId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/search_time_trigger_by_field?page=${page}&is_pinned=${isPinned}&modelable_id=${modelableId}&modelable_type=${modelableType}`,
    {
      method: 'GET',
      token
    }
  );
}

export function createTimeTriggerApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/time_triggers`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function deleteTimeTriggerApi(ocId, triggerId, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/time_triggers/${triggerId}`, {
    method: 'DELETE',
    token
  });
}

export function updateTimeTriggerApi(ocId, triggerId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/time_triggers/${triggerId}`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function updateTimeTriggerIsPinnedApi(ocId, triggerId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/update_time_trigger_is_pinned/${triggerId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function deleteActionTriggerApi(ocId, triggerId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/action_triggers/${triggerId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function updateActionTriggerApi(ocId, triggerId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/action_triggers/${triggerId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function deleteLocationTriggerApi(ocId, triggerId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/location_triggers/${triggerId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function updateLocationTriggerApi(ocId, triggerId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/location_triggers/${triggerId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}
