import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import { getImageSource } from '../../../../../helpers/imageHelper';
import InputGroup from '../../../../../components/InputGroup';
import {
  FormMap,
  SubmitButton
} from '../../../../../components/InputGroup/FormComponents';
import { Switch } from '../../../../../components/InputGroup/FormComponents';
import {
  occasionSponsorshipCreate,
  occasionSponsorshipUpdate
} from '../../../../../actions/sponsorship';
import { currentSponsorshipData } from '../../../../../selectors/sponsorship';
import { RequiredMark } from '../../../../../components/RequiredMark';

const validateSponsorship = (values) => {
  const error = {};
  if (!values.name) {
    error.name = 'Name is required';
  } else if (values.name.length < 3 || values.name.length > 100) {
    error.name = 'Must be between 3 to 100 characters';
  }
  if (values.amount && isNaN(values.amount)) {
    error.amount = 'Amount should be a number';
  } else if (parseInt(values.amount) < 0) {
    error.amount = 'Amount cannot be negative';
  }

  if (!values.category) {
    error.category = 'Category is required';
  }
  if (
    !!values.phoneNumber &&
    !/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/.test(
      values.phoneNumber
    )
  ) {
    error.phoneNumber = 'Invalid Phone Number';
  }
  if (
    !!values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    error.email = 'Invalid Email';
  }
  return error;
};

function CreateSponsorship(props) {
  const [sponsorData, setSponsorData] = React.useState({
    name: props.currentSponsorshipData?.name || '',
    amount: props.currentSponsorshipData?.amount || '',
    category: props.currentSponsorshipData?.category || '',
    phoneNumber: props.currentSponsorshipData?.phone || '',
    email: props.currentSponsorshipData?.email || '',
    websiteLink: props.currentSponsorshipData?.websiteLink || '',
    description: props.currentSponsorshipData?.description || '',
    priority: props.currentSponsorshipData?.priority || '',
    address: props.currentSponsorshipData?.address || '',
    avatar: props.currentSponsorshipData?.sponsorshipImageUrl
      ? [
          {
            url: getImageSource(
              props.currentSponsorshipData?.sponsorshipImageUrl
            )
          }
        ]
      : null
  });
  const [errorMessages, setErrorMessages] = React.useState({});
  const [isLocationActive, setLocationActive] = React.useState(false);
  const [isLocationSet, setIsLocationSet] = React.useState(false);
  const [addressData, setAddressData] = React.useState({});
  const venueInput = React.useRef();
  const toggleLocation = (e) => {
    setLocationActive(e);
  };

  const setVenue = (location) => {
    if (venueInput.current) {
      if (!isLocationSet && sponsorData?.address?.length > 0) {
        setIsLocationSet(true);
        return;
      }
      setSponsorData({ ...sponsorData, address: location });
    }
  };

  const handleSubmit = () => {
    try {
      const validateObj = validateSponsorship(sponsorData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('sponsorship[name]', sponsorData.name);
        formData.append(
          'sponsorship[amount]',
          sponsorData?.amount ? sponsorData.amount : ''
        );
        formData.append('sponsorship[category]', sponsorData.category);
        formData.append(
          'sponsorship[phone]',
          sponsorData?.phoneNumber ? sponsorData.phoneNumber : ''
        );
        formData.append(
          'sponsorship[email]',
          sponsorData?.email ? sponsorData.email : ''
        );
        if (isLocationActive) {
          formData.append('sponsorship[address]', sponsorData.address);
        }
        formData.append(
          'sponsorship[priority]',
          sponsorData?.priority ? sponsorData.priority : '1'
        );
        if (sponsorData.avatar && sponsorData.avatar[0]?.originFileObj) {
          formData.append(
            'sponsorship[sponsorship_image]',
            sponsorData.avatar[0].originFileObj
          );
        }

        formData.append(
          'sponsorship[website_link]',
          sponsorData?.websiteLink ? sponsorData.websiteLink : ''
        );

        formData.append(
          'sponsorship[description]',
          sponsorData?.description ? sponsorData.description : ''
        );

        props.sponsorshipId
          ? props.occasionSponsorshipUpdate(
              props.occasionId,
              props.sponsorshipId,
              formData
            )
          : props.occasionSponsorshipCreate(props.occasionId, formData, () => {
              setSponsorData({});
              setLocationActive(false);
            });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'avatar') {
        value = e.fileList;
        name = type;
      }
      if (!!e.address) {
        setAddressData({ address: e.address });
      }
      const formValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setSponsorData({
        ...sponsorData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="sponsorship-create-section">
        <div className="sponsorship-create-form">
          <Form className="sponsorship-form" onFinish={handleSubmit}>
            <InputGroup
              name="avatar"
              fieldType="file"
              label="Sponsor Image"
              accept="image/*"
              fileList={sponsorData?.avatar}
              onChange={handleInputChange}
              errorMessage={errorMessages?.avatar}
            />
            <div style={{ position: 'relative' }}>
              <RequiredMark left="100px" />
              <InputGroup
                label="Sponsor Name"
                name="name"
                placeholder="Enter Name"
                onChange={handleInputChange}
                value={sponsorData?.name}
                errorMessage={errorMessages?.name}
              />
            </div>

            <InputGroup
              label="Amount (in rupees)"
              name="amount"
              placeholder="Enter Given Amount"
              onChange={handleInputChange}
              value={sponsorData?.amount}
              errorMessage={errorMessages?.amount}
            />

            <div style={{ position: 'relative' }}>
              <RequiredMark left="145px" />
              <InputGroup
                label="Sponsorship Category"
                name="category"
                placeholder="Enter Category"
                onChange={handleInputChange}
                value={sponsorData?.category}
                errorMessage={errorMessages?.category}
              />
            </div>

            <InputGroup
              label="Phone Number"
              name="phoneNumber"
              placeholder="Enter Phone Number"
              onChange={handleInputChange}
              value={sponsorData?.phoneNumber}
              errorMessage={errorMessages?.phoneNumber}
            />

            <InputGroup
              label="Email"
              name="email"
              placeholder="Enter Email"
              onChange={handleInputChange}
              value={sponsorData?.email}
              errorMessage={errorMessages?.email}
            />

            <span style={{ marginBottom: '10px' }}>{`Toggle To ${
              isLocationActive ? 'Hide Address' : 'Add Address'
            }`}</span>
            <Switch
              value={isLocationActive}
              onChange={(e) => toggleLocation(e)}
            />

            {!!isLocationActive && (
              <>
                <InputGroup
                  label="Address"
                  name="address"
                  placeholder="Enter Address"
                  ref={venueInput}
                  onChange={handleInputChange}
                  value={sponsorData?.address}
                />
                <FormMap
                  label="Sponsor Address"
                  setVenue={setVenue}
                  onChange={handleInputChange}
                />
              </>
            )}
            <InputGroup
              label="Website Link"
              name="websiteLink"
              placeholder="Enter Website Link"
              onChange={handleInputChange}
              value={sponsorData?.websiteLink}
            />
            <InputGroup
              label="Description"
              name="description"
              fieldType="textArea"
              placeholder="Enter Description"
              onChange={handleInputChange}
              value={sponsorData?.description}
            />
            <InputGroup
              label="Priority"
              type="Number"
              name="priority"
              placeholder="Enter Priority"
              onChange={handleInputChange}
              value={sponsorData?.priority}
            />
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.sponsorshipId ? 'Save Changes' : 'Create'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentSponsorshipData: currentSponsorshipData(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { occasionSponsorshipCreate, occasionSponsorshipUpdate },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateSponsorship);
