import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { Form, AutoComplete, Image, Spin, Empty, Button } from 'antd';
import InputGroup from '../../../../../components/InputGroup/index';
import { Avatar } from '../../../../../components/Avatar';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import {
  createAdminOccasion,
  updateAdminOccasion
} from '../../../../../actions/admin';
import { searchInternalUsersParticipatedOccasions } from '../../../../../actions/socianaInternalUser';
import { getImageSource } from '../../../../../helpers/imageHelper';
import { getSearchedParticipatedOccasions } from '../../../../../selectors/socianaInternalUser';
import DEFAULT_IMAGE from '../../../../../assets/default-placeholder-image.png';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import { getCurrentAdminOccasionData } from '../../../../../selectors/admin';

const { Option } = AutoComplete;

const validate = (values, adminOccasionId) => {
  const errors = {};
  if (!adminOccasionId) {
    if (!values.toggleToOccasionId) {
      if (!values?.searchData?.id || !values?.searchData?.value)
        errors.searchData = 'Select a valid occasion from the list';
    }

    if (!values?.optionType)
      errors.optionType = 'Select a valid option type from the list';
    if (values.toggleToOccasionId && !values.occasionId)
      errors.occasionId = 'Occasion Id is required';
  }
  return errors;
};

const CreateAdminOccasion = (props) => {
  const [createForm, setcreateForm] = useState({
    searchData: { value: '', id: null },
    optionType: props.adminOccasionData?.operationType || '',
    toggleToOccasionId: false
  });

  const handleAssign = () => {
    const validateObj = validate(createForm, props.adminOccasionId);
    if (Object.keys(validateObj).length > 0) {
      setErrorMessages(validateObj);
    } else {
      setErrorMessages({});
      const formData = new FormData();
      if (!props.adminOccasionId) {
        formData.append(
          'sociana_admin_occasion[sociana_admin_id]',
          props.adminId
        );
      }
      formData.append(
        'sociana_admin_occasion[operation_type]',
        createForm.optionType
      );
      let ocId = !!createForm.toggleToOccasionId
        ? createForm.occasionId
        : createForm.searchData?.id;
      props.adminOccasionId
        ? props.updateAdminOccasion(
            props.ocId,
            props.adminOccasionId,
            formData,
            props.adminId
          )
        : props.createAdminOccasion(ocId, formData, props.adminId);
    }
  };
  const handleSearch = (val) => {
    setcreateForm({ ...createForm, searchData: { value: val, id: null } });
    if (val.length > 0) {
      props.searchInternalUsersParticipatedOccasions(val);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;

      if (type === 'optionType') {
        value = e;
        name = type;
      }
      if (type === 'searchData') {
        value = {
          value: e?.value,
          id: e?.key
        };
        name = type;
      }

      if (type === 'toggleToOccasionId') {
        value = e;
        name = type;
      }

      const createFormValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setcreateForm({
        ...createForm,
        [inputName]: createFormValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  const [errorMessages, setErrorMessages] = useState({});

  return (
    <ErrorBoundary>
      <SectionContent className="admin-occasion-create-section">
        <div className="admin-occasion-create-form">
          <Form
            className="admin-occasion-form-container"
            onFinish={handleAssign}>
            <InputGroup
              fieldType="dropdown"
              name="OptionType"
              label="Option Type"
              placeholder="Enter Type"
              errorMessage={errorMessages?.optionType}
              optionLabelProp="label"
              value={createForm?.optionType}
              onSelect={(e) => handleInputChange(e, 'optionType')}
              options={[
                { label: 'Read and Write', value: 'read_and_write' },
                { label: 'Read', value: 'read' }
              ]}
            />
            {!props.adminOccasionId && (
              <InputGroup
                fieldType="switch"
                name="toggleToOccasionId"
                label="Toggle to Add Occasion Id"
                onChange={handleInputChange}
                value={createForm?.toggleToOccasionId}
              />
            )}
            {createForm?.toggleToOccasionId && !props.adminOccasionId && (
              <InputGroup
                name="occasionId"
                placeholder="Enter Occasion Id"
                label="Occasion Id"
                value={createForm?.occasionId}
                onChange={handleInputChange}
                errorMessage={errorMessages?.occasionId}
              />
            )}
            {!createForm?.toggleToOccasionId && !props.adminOccasionId ? (
              <InputGroup
                fieldType="searchbox"
                name="SearchData"
                label="Search Occasion"
                placeholder="Search Occasion Name"
                loading={props.fetchProgress}
                notFoundContent={
                  props.fetchProgress ? (
                    <div className="spin-container">
                      <Spin />
                    </div>
                  ) : (
                    <Empty />
                  )
                }
                errorMessage={errorMessages?.searchData}
                value={createForm?.searchData?.value}
                onSelect={(e, option) =>
                  handleInputChange(option, 'searchData')
                }
                onSearch={handleSearch}>
                {props.participatedOccasions &&
                  props.participatedOccasions?.length > 0 &&
                  props.participatedOccasions?.map((item) => {
                    return (
                      <Option
                        key={item?.id}
                        value={item?.name}
                        label={item?.name}>
                        <div className="options-container">
                          <Avatar
                            avatarUrl={item?.cardImageUrl}
                            name={item?.name}
                            className="avatar-image-container"
                          />
                          <div className="internal-member-occasion-details-container">
                            <p className="internal-member-occasion-name">
                              {item?.name}
                            </p>
                            <p className="internal-member-occasion-type">
                              <p>{item?.type}</p>
                              <p className="guest-field">{item?.guestType}</p>
                            </p>
                          </div>
                        </div>
                      </Option>
                    );
                  })}
              </InputGroup>
            ) : (
              ''
            )}
            <SubmitButton
              buttonText={props.adminOccasionId ? 'SaveChanges' : 'Assign'}
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => ({
  participatedOccasions: getSearchedParticipatedOccasions(store, props),
  userAccessTypes: store.occasion.accessTypes,
  fetchProgress: store.browserState.fetchProgress,
  adminOccasionData: getCurrentAdminOccasionData(store, props)
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      searchInternalUsersParticipatedOccasions,
      createAdminOccasion,
      updateAdminOccasion
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAdminOccasion);
