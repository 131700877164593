import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, AutoComplete, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { TICKET_VARIANT_OPEN_STATUS } from './TicketVariantFormConstants';
import { getAllOccasionTicketVariants } from '../../../../../../selectors/ticketVariant';
import { occasionTicketVariantView } from '../../../../../../actions/ticketVariant';
import { occasionTicketVariantFormCreate } from '../../../../../../actions/ticketVariantForm';

const validate = (values) => {
  const error = {};
  if (!values.name) {
    error.name = 'Name is required';
  }

  if (!values.ticketVariant) {
    error.ticketVariant = 'Select Ticket Variant';
  }
  if (!values.status) {
    error.status = 'Select One';
  }

  return error;
};

const { Option } = AutoComplete;

function TicketVariantFormCreate(props) {
  const [ticketVariantFormData, setTicketVariantFormData] = React.useState({});
  const [errorMessages, setErrorMessages] = React.useState({});

  const onPageChange = (page) => {
    props.occasionTicketVariantView(props.occasionId, props.ticketId, page);
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleSubmit = () => {
    try {
      const validateObj = validate(ticketVariantFormData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append(
          'ticket_variant_form[form_name]',
          ticketVariantFormData.name
        );
        formData.append(
          'ticket_variant_form[open_when]',
          ticketVariantFormData.status
        );
        formData.append(
          'ticket_variant_form[ticket_variant_id]',
          ticketVariantFormData.ticketVariant
        );
        props.occasionTicketVariantFormCreate(
          props.occasionId,
          formData,
          props.ticketId,
          () => {
            setTicketVariantFormData({});
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      const ticketVariantFormValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setTicketVariantFormData({
        ...ticketVariantFormData,
        [inputName]: ticketVariantFormValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ErrorBoundary>
      <SectionContent className="ticket-variant-form-create-section">
        <div className="ticket-variant-form-create">
          <Form className="ticket-variant-form-tag" onFinish={handleSubmit}>
            <InputGroup
              label="Name"
              name="name"
              placeholder="Enter Name"
              onChange={handleInputChange}
              value={ticketVariantFormData?.name}
              errorMessage={errorMessages.name}
            />
            <InputGroup
              fieldType="radioGroup"
              label="Open Status"
              name="status"
              options={TICKET_VARIANT_OPEN_STATUS}
              onChange={handleInputChange}
              value={ticketVariantFormData?.status}
              errorMessage={errorMessages.status}
            />
            <InputGroup
              fieldType="dropdown"
              className="ticket-variant-search"
              mode="single"
              name="ticketVariant"
              label="Select Ticket Variant"
              placeholder="Select Ticket variant"
              onChange={handleInputChange}
              value={ticketVariantFormData?.ticketVariant}
              errorMessage={errorMessages.ticketVariant}>
              {props.ticketVariantData?.length > 0 &&
                props.ticketVariantData.map((item) => {
                  return (
                    <Option value={item?.id} key={item?.id}>
                      <div className="options-container">
                        <div className="ticket-variant-details-container">
                          <p className="ticket-variant-name">{item?.title}</p>
                        </div>
                      </div>
                    </Option>
                  );
                })}
            </InputGroup>
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={'Create'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  ticketVariantData: getAllOccasionTicketVariants(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { occasionTicketVariantFormCreate, occasionTicketVariantView },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketVariantFormCreate);
