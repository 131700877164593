import {
  USER_DEVICE_VIEW,
  USER_DEVICE_VIEW_SUCCESS,
  USER_DEVICE_VIEW_FAILED
} from '../constants/actionTypes';

export const UserDeviceView = (userId, page) => ({
  type: USER_DEVICE_VIEW,
  payload: { userId, page }
});

export const UserDeviceViewSuccess = (userId, data, included, links, meta) => ({
  type: USER_DEVICE_VIEW_SUCCESS,
  payload: { userId, data, included, links, meta }
});

export const UserDeviceViewFailed = (error) => ({
  type: USER_DEVICE_VIEW_FAILED,
  error
});
