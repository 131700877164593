import request from './request';

export function createOccasionWhiteListApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/occasion_white_lists`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function createOccasionWhiteListWithDealerCodeApi(
  ocId,
  formData,
  token
) {
  return request(`api/v2/sociana_admin/oc/${ocId}/create_with_dealer_code`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function createOccasionWhiteListBycsvApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/create_occasion_white_list_by_csv`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function getOccasionWhiteListApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_white_lists?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function searchOccasionWhiteListApi(ocId, page, text, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/search_occasion_white_list?page=${page}&email_or_mobile=${text}`,
    {
      method: 'GET',
      token
    }
  );
}

export function deleteOccasionWhiteListApi(ocId, listId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_white_lists/${listId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function updateOccasionWhiteListWithDealerCodeApi(
  ocId,
  whiteListId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_white_lists/${whiteListId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}
