import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'antd';
import {
  deleteMedia,
  getGalleryVideos
} from '../../../../../../actions/gallery';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import { currentOccasionId } from '../../../../../../selectors/dynamicData';
import {
  getAllGalleryVideos,
  getAllGalleryVideosNextPresent,
  getGalleryUUID
} from '../../../../../../selectors/gallery';
import { LoadingOutlined, ArrowDownOutlined } from '@ant-design/icons';
import GalleryVideo from './GalleryVideo';
import './styles.scss';

function GalleryVideoView(props) {
  const [page, setPage] = useState(1);

  useEffect(() => {
    props.getGalleryVideos(props.occasionId, props.galleryId, 'Gallary', page);
  }, []);

  const getNextPage = (str) => {
    const decodedURI = decodeURI(str);
    const number = new URLSearchParams(decodedURI).get('page[number]');
    return number ? parseInt(number) : 1;
  };

  const pagination = () => {
    if (props.videosNextPresent) {
      const nextPage = getNextPage(props.videosNextPresent);
      setPage(nextPage);
      props.getGalleryVideos(
        props.occasionId,
        props.galleryId,
        'Gallary',
        nextPage
      );
    }
  };

  const getFilteredList = (list = [], length = 0) => {
    let arr = [];
    for (let i = 0; i < list.length; i = i + length) {
      let end = length + i < list.length ? length + i : list.length;
      arr = [...arr, list.slice(i, end)];
    }
    return arr;
  };

  const onDeleteClick = (id) => {
    if (id) {
      props.deleteMedia(
        props.occasionId,
        props.galleryId,
        id,
        'Gallary',
        'video'
      );
    }
  };

  return (
    <ErrorBoundary>
      <div className="gallery-view-container">
        {page === 1 &&
          !props.fetchProgress &&
          props.videos.length === 0 &&
          'No Videos To Display!'}
        {page === 1 && props.fetchProgress && props.videos.length === 0 && (
          <LoadingOutlined style={{ fontSize: 40 }} />
        )}
        <div className="gallery-video-container">
          {props.videos.length > 0 &&
            getFilteredList(props.videos, 3).map((item, index) => {
              return (
                <div className="gallery-video-row" key={index}>
                  {item.map((e, index) => (
                    <GalleryVideo
                      video={e}
                      key={e.id}
                      onDeleteClick={onDeleteClick}
                    />
                  ))}
                </div>
              );
            })}
          {props.videosNextPresent && (
            <Button className="gallery-load-more" onClick={pagination}>
              Load More{' '}
              {props.fetchProgress && page !== 1 ? (
                <LoadingOutlined />
              ) : (
                <ArrowDownOutlined />
              )}
            </Button>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  occasionId: currentOccasionId(store, props),
  videos: getAllGalleryVideos(store, props),
  videosNextPresent: getAllGalleryVideosNextPresent(store, props),
  fetchProgress: store.browserState.fetchProgress,
  galleryId: getGalleryUUID(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGalleryVideos,
      deleteMedia
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GalleryVideoView);
