import React, { useState } from 'react';
import './styles.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Input, Form, Button, Checkbox, Image } from 'antd';
import { resetPassword } from '../../actions/forgotPassword';
import RESETPASSWORD_SUCCESS_ICON from '../../assets/linkSent-success-icon.png';
import RESETPASSWORD_FAILED_ICON from '../../assets/resetPassword-failed-icon.png';
import RESETPASSWORD_BACKGROUND_IMAGE from '../../assets/forgotPassword-background-image.png';
import RESETPASSWORD_IMAGE from '../../assets/resetPassword-image.png';
import InputGroup from '../../components/InputGroup';
import { SubmitButton } from '../../components/InputGroup/FormComponents';
import BackButton from '../../components/BackButton';
import { useHistory } from 'react-router-dom';
import ErrorBoundary from '../../components/ErrorBoundary';

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email is required';
  } else if (values.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
      errors.email = 'Invalid Email';
  }
  if (!values.password) errors.password = 'Password is required';
  else if (values.password.length < 6)
    errors.password = 'Minimum 6 characters long';

  if (!values.password_confirmation)
    errors.password_confirmation = 'Password is required';
  else if (values.password_confirmation.length < 6)
    errors.password = 'Minimum 6 characters long';
};

const queryParams = new URLSearchParams(window.location.search);
const token = queryParams.get('token');
const email = queryParams.get('email');

const ResetPasswordPage = (props) => {
  const [currentPage, setcurrentPage] = useState('');
  const history = useHistory();
  const [resetPasswordFormState, setResetPasswordFormState] = useState({
    email: email,
    password: '',
    password_confirmation: ''
  });
  const [errorMessages, setErrorMessages] = useState({});

  const handleResetpassword = () => {
    try {
      const validateObj = validate(resetPasswordFormState);
      if (Object.keys(validateObj ? validateObj : {}).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});

        const formdata = new FormData();
        formdata.append('email', resetPasswordFormState?.email);
        formdata.append('password[password]', resetPasswordFormState?.password);
        formdata.append(
          'password[password_confirmation]',
          resetPasswordFormState?.password_confirmation
        );
        formdata.append('reset_password_token', token);
        props.resetPassword(formdata, (data) => {
          setResetPasswordFormState({});
          data ? setcurrentPage('success') : setcurrentPage('failed');
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleInputChange = (e) => {
    let value;
    if (e.target.name === 'email') {
      value = e.target.value;
    }
    if (e.target.name === 'password') {
      value = e.target.value;
    }
    if (e.target.name === 'password_confirmation') {
      value = e.target.value;
    }
    const inputName = e.target.name;
    if (
      errorMessages.email ||
      errorMessages.password ||
      errorMessages.password_confirmation
    ) {
      setErrorMessages({});
    }
    setResetPasswordFormState({
      ...resetPasswordFormState,
      [inputName]: value
    });
  };

  return (
    <ErrorBoundary>
      <div className="resetpassword-container">
        <BackButton path={'/signin'} />
        <div className="resetpassword-image-box">
          <Image
            className="resetpasswordsuccess-image-style"
            preview={false}
            src={RESETPASSWORD_BACKGROUND_IMAGE}
          />
        </div>
        <div className="resetpassword-content">
          <div className="resetpassword-card">
            {currentPage === '' ? (
              <>
                <h1 className="resetpassword-header">Create new password</h1>
                <Form
                  className="resetpassword-form-container"
                  onFinish={handleResetpassword}>
                  <InputGroup
                    name="email"
                    label="Email Address"
                    className="resetpassword-input-box"
                    placeholder="Enter email or mobile"
                    onChange={handleInputChange}
                    value={resetPasswordFormState?.email}
                    errorMessages={errorMessages.email}
                  />

                  <InputGroup
                    className=" resetpassword-input-box"
                    name="password"
                    label="Password"
                    type="password"
                    placeholder="Enter password"
                    onChange={handleInputChange}
                    value={resetPasswordFormState?.password}
                    errorMessages={errorMessages.password}
                  />

                  <InputGroup
                    className=" resetpassword-input-box"
                    name="password_confirmation"
                    label="Confirm Password"
                    type="password"
                    placeholder="Enter confirm password"
                    onChange={handleInputChange}
                    value={resetPasswordFormState?.password_confirmation}
                    errorMessages={errorMessages.password_confirmation}
                  />

                  <SubmitButton
                    className="resetpassword-submit-button"
                    buttonText={'Reset my password'}
                    fetchProgress={props.fetchProgress}
                  />
                </Form>{' '}
              </>
            ) : (
              <>
                <div
                  className="resetpassword-icon-box"
                  style={{
                    backgroundColor:
                      currentPage === 'success' ? '#179e8a' : 'white'
                  }}>
                  <Image
                    className={`resetpassword${
                      currentPage === 'success' ? 'success' : 'failed'
                    }-image-style`}
                    preview={false}
                    src={
                      currentPage === 'success'
                        ? RESETPASSWORD_SUCCESS_ICON
                        : RESETPASSWORD_FAILED_ICON
                    }
                  />
                </div>
                <p className="resetpassword-text">
                  {currentPage === 'success'
                    ? 'Your password successfully updated 🎉'
                    : 'Sorry! something went wrong.'}
                </p>
                <Button
                  className="resetpassword-response-button"
                  onClick={() => {
                    currentPage === 'success'
                      ? history.push('/signin')
                      : setcurrentPage('');
                  }}>
                  {currentPage === 'success' ? 'Login now' : 'Try again'}
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="resetpassword-image1-box">
          <Image
            className="resetpassword-image1-style"
            preview={false}
            src={RESETPASSWORD_IMAGE}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPassword
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
