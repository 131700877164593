import {
  EVENT_AFFAIR_ENTERTAINMENT_CREATE,
  EVENT_AFFAIR_ENTERTAINMENT_CREATE_FAILED,
  EVENT_AFFAIR_ENTERTAINMENT_CREATE_SUCCESS,
  EVENT_AFFAIR_ENTERTAINMENT_VIEW,
  EVENT_AFFAIR_ENTERTAINMENT_VIEW_FAILED,
  EVENT_AFFAIR_ENTERTAINMENT_VIEW_SUCCESS,
  EVENT_AFFAIR_ENTERTAINMENT_UPDATE,
  EVENT_AFFAIR_ENTERTAINMENT_UPDATE_FAILED,
  EVENT_AFFAIR_ENTERTAINMENT_UPDATE_SUCCESS,
  EVENT_AFFAIR_ENTERTAINMENT_DELETE,
  EVENT_AFFAIR_ENTERTAINMENT_DELETE_FAILED,
  EVENT_AFFAIR_ENTERTAINMENT_DELETE_SUCCESS
} from '../constants/actionTypes';

export const eventAffairEntertainmentCreate = (
  ocId,
  formData,
  eventId,
  eventAffairId,
  callback
) => ({
  type: EVENT_AFFAIR_ENTERTAINMENT_CREATE,
  payload: { ocId, formData, eventId, eventAffairId },
  callback
});

export const eventAffairEntertainmentCreateSuccess = (
  ocId,
  eventId,
  eventAffairId,
  data,
  included,
  links
) => ({
  type: EVENT_AFFAIR_ENTERTAINMENT_CREATE_SUCCESS,
  payload: { ocId, eventId, eventAffairId, data, included, links }
});

export const eventAffairEntertainmentCreateFailed = (error) => ({
  type: EVENT_AFFAIR_ENTERTAINMENT_CREATE_FAILED,
  error
});

export const eventAffairEntertainmentView = (
  ocId,
  page,
  eventId,
  eventAffairId
) => ({
  type: EVENT_AFFAIR_ENTERTAINMENT_VIEW,
  payload: { ocId, page, eventId, eventAffairId }
});

export const eventAffairEntertainmentViewSuccess = (
  ocId,
  eventId,
  eventAffairId,
  data,
  included,
  links
) => ({
  type: EVENT_AFFAIR_ENTERTAINMENT_VIEW_SUCCESS,
  payload: { ocId, eventId, eventAffairId, data, included, links }
});

export const eventAffairEntertainmentViewFailed = (error) => ({
  type: EVENT_AFFAIR_ENTERTAINMENT_VIEW_FAILED,
  error
});

export const eventAffairEntertainmentUpdate = (
  ocId,
  eventAffairId,
  eventId,
  entertainmentId,
  formData,
  callback
) => ({
  type: EVENT_AFFAIR_ENTERTAINMENT_UPDATE,
  payload: { ocId, eventAffairId, eventId, entertainmentId, formData },
  callback
});

export const eventAffairEntertainmentUpdateSuccess = (
  ocId,
  eventId,
  eventAffairId,
  data,
  included,
  links
) => ({
  type: EVENT_AFFAIR_ENTERTAINMENT_UPDATE_SUCCESS,
  payload: { ocId, eventId, eventAffairId, data, included, links }
});

export const eventAffairEntertainmentUpdateFailed = (error) => ({
  type: EVENT_AFFAIR_ENTERTAINMENT_UPDATE_FAILED,
  error
});

export const eventAffairEntertainmentDelete = (
  ocId,
  eventAffairId,
  eventId,
  entertainmentId
) => ({
  type: EVENT_AFFAIR_ENTERTAINMENT_DELETE,
  payload: { ocId, eventAffairId, eventId, entertainmentId }
});

export const eventAffairEntertainmentDeleteSuccess = (
  ocId,
  eventId,
  eventAffairId,
  data,
  included,
  links
) => ({
  type: EVENT_AFFAIR_ENTERTAINMENT_DELETE_SUCCESS,
  payload: { ocId, eventId, eventAffairId, data, included, links }
});

export const eventAffairEntertainmentDeleteFailed = (error) => ({
  type: EVENT_AFFAIR_ENTERTAINMENT_DELETE_FAILED,
  error
});
