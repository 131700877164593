import { Image } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ZoomInOutlined, EditOutlined } from '@ant-design/icons';
import { getImageSource } from '../../../../helpers/imageHelper';
import SocianaInternalUserCreate from '../Create/index';
import { getDateInMonthFormat } from '../../../../helpers/dateTimeHelper';
import { useLocation } from 'react-router-dom';
import { Switch } from '../../../../components/InputGroup/FormComponents';
import { editSocianaInternalUser } from '../../../../actions/socianaInternalUser';

const InternalUserDetails = ({
  userDetails,
  editSocianaInternalUser,
  fetchProgress
}) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const dob = userDetails?.dob;
  const location = useLocation();

  const editInternalTeamMembershipStatus = (e) => {
    const data = new FormData();
    data.append('user[from_internal_team]', e ? true : false);
    editSocianaInternalUser(data, userDetails?.id);
  };

  const editStatus = (e) => {
    const data = new FormData();
    data.append('user[active]', e ? true : false);
    editSocianaInternalUser(data, userDetails?.id);
  };

  if (!isEditOpen) {
    return (
      <div className="user-details-container">
        <p className="user-details avatar-details">
          <p className="fields">Avatar:</p>
          <p className="fields-data avatar-image-container">
            <Image
              src={getImageSource(userDetails?.avatarUrl)}
              preview={{
                maskClassName: 'preview-style',
                mask: <ZoomInOutlined />
              }}
              className="image-style"
            />
          </p>
        </p>

        <p className="user-details">
          <p className="fields">Name:</p>
          <p className="field-data">{userDetails?.name}</p>
        </p>
        <p className="user-details">
          <p className="fields">Email Address:</p>
          <p className="field-data">{userDetails?.email}</p>
        </p>
        {userDetails.mobile && (
          <p className="user-details">
            <p className="fields">Phone Number:</p>
            <p className="field-data">{userDetails?.mobile}</p>
          </p>
        )}
        <p className="user-details">
          <p className="fields">Date of Birth:</p>
          <p className="field-data">{dob ? dob : 'Not Provided'}</p>
        </p>
        <p className="user-details">
          <p className="fields">Gender:</p>
          <p className="field-data gender">
            {userDetails?.gender === 'prefer_not_to_disclose'
              ? 'Prefer Not To Disclose'
              : userDetails?.gender}
          </p>
        </p>
        <p className="user-details">
          <p className="fields">Is Internal Team Member:</p>
          <p className="field-data">
            {userDetails?.fromInternalTeam ? 'Yes' : 'No'}
            <Switch
              loading={fetchProgress}
              value={userDetails?.fromInternalTeam}
              style={{ marginLeft: 10 }}
              onChange={(e) => editInternalTeamMembershipStatus(e)}
            />
          </p>
        </p>
        <p className="user-details">
          <p className="fields">Is Active:</p>
          <p className="field-data">
            {userDetails?.active ? 'Yes' : 'No'}
            <Switch
              loading={fetchProgress}
              value={userDetails?.active}
              style={{ marginLeft: 10 }}
              onChange={(e) => editStatus(e)}
            />
          </p>
        </p>
        <button
          className="edit-details-container"
          onClick={() => setIsEditOpen(true)}>
          <EditOutlined />
          <span>Edit Details</span>
        </button>
      </div>
    );
  }
  return (
    <div className="edit-container">
      <SocianaInternalUserCreate
        location={location}
        userId={userDetails?.id}
        allowAccessToPage
        userDetails={userDetails}
        handleCloseClick={() => setIsEditOpen(false)}
      />
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ editSocianaInternalUser }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalUserDetails);
