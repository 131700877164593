import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import TableView from '../../../../../components/Table';
import {
  hardLogoutUserView,
  hardLogoutUserDelete,
  hardLogoutAllUserDelete
} from '../../../../../actions/hardLogout';
import { getHardLogoutUserWithUserData } from '../../../../../selectors/hardLogout';
import { Button } from 'antd';

const ViewHardLogoutUser = (props) => {
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.hardLogoutUserView(props.occasionId, props.hardLogoutId, page);
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const deleteData = (id) => {
    props.hardLogoutUserDelete(props.occasionId, props.hardLogoutId, id);
  };

  const handleDelete = (id) => {
    props.hardLogoutAllUserDelete(props.occasionId, props.hardLogoutId);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="hard-logout-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={handleDelete}>
          Delete All Hard Logout Users
        </Button>
      </div>
    )
  };

  return (
    <ErrorBoundary>
      <SectionContent className="hard-logout-view-section">
        <div className="hard-logout-view-container">
          <TableView
            isPaginated={true}
            paginationOptions={paginationOptions}
            isRouted={false}
            onPageChange={onPageChange}
            onDelete={deleteData}
            data={props.hardLogoutUserData}
            fetchProgress={props.fetchProgress}
            paginationLinks={props.hardLogoutUserLinks}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  hardLogoutUserData: getHardLogoutUserWithUserData(store, props),
  hardLogoutUserLinks: store.hardLogout.hardLogoutUserLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { hardLogoutUserView, hardLogoutUserDelete, hardLogoutAllUserDelete },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(ViewHardLogoutUser);
