import {
  EVENT_COLLABORATOR_CREATE_FAILED,
  EVENT_COLLABORATOR_CREATE_SUCCESS,
  EVENT_COLLABORATOR_VIEW_FAILED,
  EVENT_COLLABORATOR_VIEW_SUCCESS,
  EVENT_COLLABORATOR_DELETE_FAILED,
  EVENT_COLLABORATOR_DELETE_SUCCESS,
  EVENT_COLLABORATOR_SEARCH_SUCCESS,
  RESET_EVENT_MEMBERS
} from '../constants/actionTypes';

const initialState = {
  eventCollaboratorData: null,
  eventCollaboratorLinks: null,
  eventCollaboratorSearchData: null,
  eventCollaboratorSearchLinks: null,
  eventCollaboratorCount: 0,
  eventCollaboratorSearchCount: 0,
  isSearch: false,
  error: null
};

const eventCollaborator = (state = initialState, action) => {
  switch (action.type) {
    case EVENT_COLLABORATOR_VIEW_SUCCESS: {
      const { ocId, eventId, data, links, meta } = action.payload;
      const occasionGroupedData = state.eventCollaboratorData || {};
      const currentEventNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        eventCollaboratorData: {
          ...occasionGroupedData,
          [eventId]: [...currentEventNewIds]
        },
        eventCollaboratorLinks: links,
        isSearch: false,
        eventCollaboratorCount: meta?.totalCount || 0
      };
    }

    case EVENT_COLLABORATOR_SEARCH_SUCCESS: {
      const { eventId, data, links, meta } = action.payload;
      const currentEventNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        eventCollaboratorSearchData: {
          [eventId]: [...currentEventNewIds]
        },
        eventCollaboratorSearchLinks: links,
        isSearch: true,
        eventCollaboratorSearchCount: meta?.totalCount || 0
      };
    }

    case RESET_EVENT_MEMBERS: {
      return {
        ...state,
        isSearch: false
      };
    }

    case EVENT_COLLABORATOR_DELETE_SUCCESS: {
      const { ocId, eventId, data } = action.payload;
      const occasionGroupedData = state.eventCollaboratorData || {};
      const currentEventCollaboratorIds = occasionGroupedData[eventId] || [];

      const filterIds = currentEventCollaboratorIds.filter(
        (id) => id !== data.id
      );
      return {
        ...state,
        eventCollaboratorData: {
          ...occasionGroupedData,
          [eventId]: filterIds
        }
      };
    }

    case EVENT_COLLABORATOR_CREATE_FAILED:
    case EVENT_COLLABORATOR_DELETE_FAILED:
    case EVENT_COLLABORATOR_VIEW_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default eventCollaborator;
