import React from 'react';
import { Button, Tabs as AntdTabs } from 'antd';
import './styles.scss';

const { TabPane } = AntdTabs;
const Tabs = ({
  tabPanes = [],
  defaultActiveKey,
  size = 'large',
  preset = 'primary',
  onChange = () => {},
  ...otherProps
}) => {
  const extraContent = () => {
    if (
      otherProps?.onExtraButtonPress &&
      typeof otherProps.onExtraButtonPress === 'function'
    ) {
      return (
        <Button
          className="tab-extra-content"
          onClick={otherProps.onExtraButtonPress}>
          Back
        </Button>
      );
    }
    return <></>;
  };
  return (
    <div className={`${preset}-tab-view`}>
      <AntdTabs
        defaultActiveKey={defaultActiveKey}
        size={size}
        onChange={onChange}
        tabBarExtraContent={extraContent()}
        {...otherProps}>
        {tabPanes.map((item) => {
          return (
            <TabPane
              tab={item.tabName}
              key={item.tabKey}
              disabled={item.disabled}>
              {item.children}
            </TabPane>
          );
        })}
      </AntdTabs>
    </div>
  );
};

export default Tabs;
