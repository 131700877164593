import { createSelector } from 'reselect';
import { currentOccasionId, currentCompetitionId } from './dynamicData';
import { competitionEntities } from './entity';
import { competitionIds } from './static';

export const currentOccasionCompetitionIds = createSelector(
  currentOccasionId,
  competitionIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getAllOccasionCompetition = createSelector(
  competitionEntities,
  currentOccasionCompetitionIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const currentCompetitionData = createSelector(
  currentCompetitionId,
  competitionEntities,
  (id, entity) => id && entity && entity[id]
);
