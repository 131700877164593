import { union } from 'lodash';
import {
  CREATE_EMAIL_SUCCESS,
  CREATE_EMAIL_FAILED,
  VIEW_EMAILS_SUCCESS,
  VIEW_EMAILS_FAILED,
  DELETE_EMAIL_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  data: null,
  error: null,
  links: null
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case VIEW_EMAILS_FAILED:
    case CREATE_EMAIL_FAILED: {
      return { ...state, error: action.error };
    }

    case VIEW_EMAILS_SUCCESS: {
      const { occasionId, data, links } = action.payload;
      const prevData = state.data || {};
      const notificationIds = data?.map((el) => el.id);

      const prevLinks = state.links || {};
      const notificationLinks = links;
      return {
        ...state,
        data: {
          ...prevData,
          [occasionId]: notificationIds
        },
        links: {
          ...prevLinks,
          [occasionId]: notificationLinks
        }
      };
    }

    case CREATE_EMAIL_SUCCESS: {
      const { occasionId, data } = action.payload;
      const prevData = state.data || {};

      const ids = prevData[occasionId] || [];

      const newData = union([data?.id], ids);

      return {
        ...state,
        data: {
          ...prevData,
          [occasionId]: newData
        }
      };
    }

    case DELETE_EMAIL_SUCCESS: {
      const { data, occasionId } = action.payload;
      const prevData = state.data || {};
      const prevOcData = prevData[occasionId] || [];
      const filteredIds = prevOcData.filter((id) => id !== data.id);

      return {
        ...state,
        data: {
          ...prevOcData,
          [occasionId]: filteredIds
        }
      };
    }
    default:
      return state;
  }
};

export default notifications;
