import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  hardLogoutAllUserDeleteApi,
  hardLogoutCreateApi,
  hardLogoutUserDeleteApi,
  hardLogoutUserViewApi,
  hardLogoutViewApi,
  updateHardLogoutApi
} from '../api/hardLogout';
import {
  HARD_LOGOUT_ALL_USER_DELETE,
  HARD_LOGOUT_CREATE,
  HARD_LOGOUT_USER_DELETE,
  HARD_LOGOUT_USER_VIEW,
  HARD_LOGOUT_VIEW,
  UPDATE_HARD_LOGOUT
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  hardLogoutAllUserDeleteFailed,
  hardLogoutAllUserDeleteSuccess,
  hardLogoutCreateFailed,
  hardLogoutCreateSuccess,
  hardLogoutUserDeleteFailed,
  hardLogoutUserDeleteSuccess,
  hardLogoutUserViewFailed,
  hardLogoutUserViewSuccess,
  hardLogoutViewFailed,
  hardLogoutViewSuccess,
  updateHardLogoutFailed,
  updateHardLogoutSuccess
} from '../actions/hardLogout';

export function* hardLogoutCreateSaga(action) {
  try {
    const result = yield call(
      hardLogoutCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hardLogoutCreateSuccess(
          result.data,
          result.included,
          action.payload.ocId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(hardLogoutCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(hardLogoutCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hardLogoutViewSaga(action) {
  try {
    const result = yield call(
      hardLogoutViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hardLogoutViewSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(hardLogoutViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hardLogoutUpdateSaga(action) {
  try {
    const result = yield call(
      updateHardLogoutApi,
      action.payload.ocId,
      action.payload.hardLogoutId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateHardLogoutSuccess(
          action.payload.ocId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateHardLogoutFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateHardLogoutFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hardLogoutUserViewSaga(action) {
  try {
    const result = yield call(
      hardLogoutUserViewApi,
      action.payload.ocId,
      action.payload.hardLogoutId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hardLogoutUserViewSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          action.payload.hardLogoutId,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(hardLogoutUserViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hardLogoutUserDeleteSaga(action) {
  try {
    const result = yield call(
      hardLogoutUserDeleteApi,
      action.payload.ocId,
      action.payload.hardLogoutId,
      action.payload.hardLogoutUserId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hardLogoutUserDeleteSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          action.payload.hardLogoutId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(hardLogoutUserDeleteFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hardLogoutAllUserDeleteSaga(action) {
  try {
    const result = yield call(
      hardLogoutAllUserDeleteApi,
      action.payload.ocId,
      action.payload.hardLogoutId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hardLogoutAllUserDeleteSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          action.payload.hardLogoutId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(hardLogoutAllUserDeleteFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* hardLogoutActionWatcher() {
  yield takeLatest(HARD_LOGOUT_CREATE, progressSaga, hardLogoutCreateSaga);
  yield takeLatest(HARD_LOGOUT_VIEW, progressSaga, hardLogoutViewSaga);
  yield takeLatest(UPDATE_HARD_LOGOUT, progressSaga, hardLogoutUpdateSaga);
  yield takeLatest(HARD_LOGOUT_USER_VIEW, progressSaga, hardLogoutUserViewSaga);
  yield takeLatest(
    HARD_LOGOUT_USER_DELETE,
    progressSaga,
    hardLogoutUserDeleteSaga
  );
  yield takeLatest(
    HARD_LOGOUT_ALL_USER_DELETE,
    progressSaga,
    hardLogoutAllUserDeleteSaga
  );
}
