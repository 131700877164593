import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Form, AutoComplete } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup';
import { Image } from '../../../../../components/Image';
import {
  FormMap,
  SubmitButton,
  Switch
} from '../../../../../components/InputGroup/FormComponents';
import { capitalize } from '../../../../../helpers/stringHelper';
import { getImageSource } from '../../../../../helpers/imageHelper';
import { getCurrentOccasion } from '../../../../../selectors/occasion';
import { getCurrentEvent } from '../../../../../selectors/event';
import {
  occasionEventCreate,
  occasionEventUpdate
} from '../../../../../actions/event';
import { EVENT_GUEST_TYPES, EVENT_TAG } from './EventConstants';
import { occasionGroupView } from '../../../../../actions/group';
import { getOccasionGroupsWithAllData } from '../../../../../selectors/group';

const validateOccasionEvent = (values, startAT, endAt, eventId, key) => {
  const error = {};
  const ocStartAt = new Date(startAT).getTime();
  const ocEndAt = new Date(endAt).getTime();
  const eventStartAt = new Date(values.schedule[0]).getTime();
  const eventEndAt = new Date(values.schedule[1]).getTime();
  if (!values.title) {
    error.title = 'Event Title is Required';
  }
  if (values.restrict_count < 0) {
    error.restrict_count = 'Restrict Count should be positive';
  }

  if (values.event_post_pagination_limit <= 0) {
    error.event_post_pagination_limit =
      'Event Post Pagination Limit Should Be Greater than One';
  }

  if (!(values.schedule?.length > 0)) {
    error.schedule = 'Start and End date are required';
  } else if (!values?.schedule[0]) {
    error.schedule = 'Start date is required';
  } else if (!values?.schedule[1]) {
    error.schedule = 'End date is required';
  }
  if (values.group?.length === 0 && !eventId && key?.status === 'on') {
    error.group = 'Group is required';
  }
  return error;
};

const { Option } = AutoComplete;

function CreateOccasionEvent(props) {
  const [occasionEventFormState, setOccasionEventFormState] = React.useState({
    ...props.initialValues
  });
  const [isLocationActive, setLocationActive] = React.useState(false);
  const [isLocationSet, setIsLocationSet] = useState(false);
  const [errorMessages, setErrorMessages] = React.useState({});
  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);
  const [locationFieldToggle, setLocationFieldToggle] = useState(true);
  const [page, setPage] = React.useState(1);
  const [eventCoordinates, setEventCoordinates] = React.useState({
    lat: props?.initialValues?.latitude,
    lng: props?.initialValues?.longitude
  });

  const venueInput = React.useRef();

  const uiVersionOptions = Object.keys(props.versionOptions);

  useEffect(() => {
    setOccasionEventFormState(props.initialValues);
  }, [props.initialValues]);

  const onPageChange = (page) => {
    props.occasionGroupView(props.occasionId, page);
  };

  React.useEffect(() => {
    onPageChange(page);
  }, [page]);

  const getPage = (str) => {
    const decodedURI = decodeURI(str);
    const number = decodedURI
      .split('page[number]')[1]
      .replace('=', '')
      .split('&');
    return (number && parseInt(number)) || 1;
  };

  const onEndReached = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height >= scrollHeight - 20) {
      if (props.allGroupsLinks?.next) {
        const page = getPage(props.allGroupsLinks?.next);
        setPage(page);
      }
    }
  };

  const handleOccasionEventCreate = () => {
    try {
      const validateObj = validateOccasionEvent(
        occasionEventFormState,
        props.currentOccasion.startAt,
        props.currentOccasion.endAt,
        props.eventId,
        props.modelGroupEventKey
      );
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('event[title]', occasionEventFormState.title);

        formData.append(
          'event[description]',
          occasionEventFormState.description
        );

        formData.append(
          'event[start_at]',
          new Date(occasionEventFormState.schedule[0])
        );
        formData.append(
          'event[end_at]',
          new Date(occasionEventFormState.schedule[1])
        );

        formData.append('event[venue]', occasionEventFormState.venue);

        formData.append('event[adress1]', occasionEventFormState.address);

        formData.append('event[city]', occasionEventFormState.city);
        formData.append('event[dress_code]', occasionEventFormState.dress_code);
        formData.append('event[ex_info]', occasionEventFormState.extra_info);

        if (occasionEventFormState.restrict_count) {
          formData.append(
            'event[event_restrict_count]',
            occasionEventFormState.restrict_count
          );
        }

        if (occasionEventFormState.event_post_pagination_limit) {
          formData.append(
            'event[event_post_pagination_limit]',
            occasionEventFormState.event_post_pagination_limit
          );
        }

        formData.append('event[ui_version]', occasionEventFormState.uiversion);
        if (isLocationActive || !props.eventId) {
          formData.append(
            'event[lattitude]',
            Number(eventCoordinates.lat).toFixed(6)
          );
          formData.append(
            'event[longitude]',
            Number(eventCoordinates.lng).toFixed(6)
          );
        }
        formData.append('event[guest_type]', occasionEventFormState.guest_type);

        formData.append('event[tag]', occasionEventFormState.tag);

        if (
          occasionEventFormState.card_image &&
          occasionEventFormState.card_image[0]?.originFileObj
        ) {
          formData.append(
            'event[card_image]',
            occasionEventFormState.card_image[0].originFileObj
          );
        }
        if (
          occasionEventFormState.bg_image &&
          occasionEventFormState.bg_image[0]?.originFileObj
        ) {
          formData.append(
            'event[bg_image]',
            occasionEventFormState.bg_image[0].originFileObj
          );
        }
        if (!props.eventId && props.modelGroupEventKey?.status === 'on') {
          occasionEventFormState?.group.forEach((id) => {
            formData.append('event[model_group_ids][]', id);
          });
        }

        props.eventId
          ? props.occasionEventUpdate(props.occasionId, props.eventId, formData)
          : props.occasionEventCreate(props.occasionId, formData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type, momentData) => {
    try {
      let value;
      let name;
      if (e) {
        if (isInitiallyDisabled) {
          setInitiallyDisabled(false);
        }
        if (type === 'card_image' || type === 'bg_image') {
          value = e.fileList;
          name = type;
        }
        if (type === 'venue') {
          if (e.lat && e.lng) {
            setEventCoordinates({ lat: e.lat, lng: e.lng });
          }
        }

        if (type === 'schedule') {
          value = e.map((item) => moment(item, 'DD/MM/YYYY HH:mm'));
          name = type;
        }
        const occasionEventFormValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        if (type !== 'venue') {
          setOccasionEventFormState({
            ...occasionEventFormState,
            [inputName]: occasionEventFormValue
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const setVenue = (location) => {
    if (venueInput.current) {
      if (!isLocationSet && props.initialValues?.venue?.length > 0) {
        setIsLocationSet(true);
        return;
      }
      setOccasionEventFormState({ ...occasionEventFormState, venue: location });
    }
  };
  const toggleLocationField = () => {
    setLocationFieldToggle(!locationFieldToggle);
  };

  const toggleLocation = (e) => {
    setLocationActive(e);
  };

  return (
    <ErrorBoundary>
      <SectionContent className="occasion-event-create-section">
        <div className="occasion-event-create-form">
          <Form
            className="occasion-event-form"
            onFinish={handleOccasionEventCreate}>
            <InputGroup
              label="Title"
              name="title"
              placeholder="Enter Title"
              onChange={handleInputChange}
              value={occasionEventFormState?.title}
              errorMessage={errorMessages.title}
            />
            <InputGroup
              label="Description"
              name="description"
              placeholder="Enter Description"
              onChange={handleInputChange}
              fieldType="textArea"
              value={occasionEventFormState?.description}
            />

            {!props.eventId && props.modelGroupEventKey?.status === 'on' && (
              <InputGroup
                fieldType="dropdown"
                className="group-search"
                mode="multiple"
                name="group"
                label="Select Group"
                placeholder="Select Group"
                onPopupScroll={onEndReached}
                onChange={handleInputChange}
                errorMessage={errorMessages?.group}
                value={occasionEventFormState?.group}>
                {props.groupData?.length > 0 &&
                  props.groupData.map((item) => {
                    return (
                      <Option value={item?.id} key={item?.id}>
                        <div className="options-container">
                          <p className="avatar-image-container">
                            <Image
                              src={getImageSource(item?.groupPictureUrl)}
                              preview={false}
                            />
                          </p>
                          <div className="occasion-group-details-container">
                            <p className="occasion-group-name">{item?.name}</p>
                          </div>
                        </div>
                      </Option>
                    );
                  })}
              </InputGroup>
            )}

            <InputGroup
              fieldType="rangePicker"
              onChange={handleInputChange}
              placeholder={['Start Date with Time', 'End Date with Time']}
              label="Schedule"
              name="schedule"
              value={occasionEventFormState?.schedule}
              errorMessage={errorMessages.schedule}
            />

            {props.eventId && (
              <div style={{ marginBottom: '10px' }}>
                <span className="location-header">
                  {isLocationActive
                    ? 'Toggle to hide location on map'
                    : 'Toggle to select location on map'}
                </span>
                <Switch
                  value={isLocationActive}
                  onChange={(e) => toggleLocation(e)}
                />
              </div>
            )}
            {(isLocationActive || !props.eventId) && (
              <div>
                <div>
                  <span className="location-header">
                    {locationFieldToggle
                      ? 'Toggle to enter coordinates manually'
                      : 'Toggle to select location on map'}
                  </span>
                  <Switch
                    value={locationFieldToggle}
                    onChange={toggleLocationField}
                    noChildren={true}
                  />
                </div>
                {locationFieldToggle ? (
                  <FormMap
                    label="Location"
                    value={{
                      latitude: eventCoordinates?.lat,
                      longitude: eventCoordinates?.lng
                    }}
                    setVenue={setVenue}
                    onChange={handleInputChange}
                  />
                ) : (
                  <>
                    <InputGroup
                      onChange={handleInputChange}
                      placeholder="Longitude"
                      label="Longitude"
                      name="longitude"
                      value={occasionEventFormState?.longitude}
                      errorMessage={errorMessages.longitude}
                    />
                    <InputGroup
                      onChange={handleInputChange}
                      placeholder="Latitude"
                      label="Latitude"
                      name="latitude"
                      value={occasionEventFormState?.latitude}
                      errorMessage={errorMessages.latitude}
                    />
                  </>
                )}
              </div>
            )}
            <InputGroup
              label="Venue"
              name="venue"
              placeholder="Enter Venue"
              ref={venueInput}
              onChange={handleInputChange}
              value={occasionEventFormState?.venue}
            />
            <InputGroup
              label="Address"
              name="address"
              placeholder="Enter Address"
              onChange={handleInputChange}
              value={occasionEventFormState?.address}
            />
            <InputGroup
              label="City"
              name="city"
              placeholder="Enter City Name"
              onChange={handleInputChange}
              value={occasionEventFormState?.city}
            />
            <InputGroup
              fieldType="radioGroup"
              options={EVENT_GUEST_TYPES}
              name="guest_type"
              label="Guest Access"
              onChange={handleInputChange}
              value={occasionEventFormState?.guest_type}
            />
            <InputGroup
              fieldType="dropdown"
              className="event-ui-version"
              options={uiVersionOptions?.map((item) => ({
                label: capitalize(item),
                value: item
              }))}
              name="uiversion"
              label="Ui Version"
              onChange={handleInputChange}
              value={occasionEventFormState?.uiversion}
              errorMessage={errorMessages.uiversion}
            />
            <InputGroup
              fieldType="radioGroup"
              options={EVENT_TAG}
              name="tag"
              label="Tag Access"
              onChange={handleInputChange}
              value={occasionEventFormState?.tag}
            />
            <InputGroup
              label="DressCode"
              name="dress_code"
              placeholder="Enter Dress Code"
              onChange={handleInputChange}
              value={occasionEventFormState?.dress_code}
            />
            <InputGroup
              label="Extra Info"
              name="extra_info"
              placeholder="Enter Extra Info"
              onChange={handleInputChange}
              value={occasionEventFormState?.extra_info}
            />
            {props.eventId && (
              <InputGroup
                label="Restrict Count"
                name="restrict_count"
                type="number"
                placeholder="Enter Restrict Count"
                onChange={handleInputChange}
                value={occasionEventFormState?.restrict_count}
                errorMessage={errorMessages.restrict_count}
              />
            )}

            {props.eventId && (
              <InputGroup
                label="Event Post Pagination Limit"
                name="event_post_pagination_limit"
                type="number"
                placeholder="Enter Event Post Pagination Limit"
                onChange={handleInputChange}
                value={occasionEventFormState?.event_post_pagination_limit}
                errorMessage={errorMessages.event_post_pagination_limit}
              />
            )}

            <InputGroup
              fieldType="file"
              label="Event Image"
              accept="image/*"
              fileList={occasionEventFormState?.card_image}
              onChange={handleInputChange}
              name="card_image"
            />
            <InputGroup
              fieldType="file"
              label="Background Image"
              accept="image/*"
              fileList={occasionEventFormState?.bg_image}
              onChange={handleInputChange}
              name="bg_image"
            />
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.eventId ? 'Save Changes' : 'Create Event'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => {
  const currentOccasion = getCurrentOccasion(store, props);
  const groupData = getOccasionGroupsWithAllData(store, props);
  const allGroupsLinks = store.group.groupLinks;
  const versionOptions = store.occasion.events.ui_version;
  const currentEvent = getCurrentEvent(store, props);
  const StartAt = moment(
    moment(currentEvent?.startAt).format('DD-MM-YYYY HH:mm'),
    'DD-MM-YYYY HH:mm'
  );
  const EndAt = moment(
    moment(currentEvent?.endAt).format('DD-MM-YYYY HH:mm'),
    'DD-MM-YYYY HH:mm'
  );
  const initialValues = {
    title: currentEvent?.title || '',
    description: currentEvent?.description || '',
    group: [],
    latitude: currentEvent?.lattitude || '',
    longitude: currentEvent?.longitude || '',
    schedule:
      currentEvent?.startAt && currentEvent?.endAt ? [StartAt, EndAt] : [],
    venue: currentEvent?.venue || '',
    address: currentEvent?.adress1 || '',
    extra_info: currentEvent?.exInfo || '',
    restrict_count: currentEvent?.eventRestrictCount || '',
    event_post_pagination_limit: currentEvent?.eventPostPaginationLimit || 1,
    city: currentEvent?.city || '',
    guest_type: currentEvent?.guestType?.toLowerCase() || 'open',
    tag: currentEvent?.tag.toLowerCase() || 'normal',
    uiversion: currentEvent?.uiVersion?.toLowerCase() || 'version_0',
    dress_code: currentEvent?.dressCode || '',
    card_image: currentEvent?.cardImageUrl
      ? [{ url: getImageSource(currentEvent?.cardImageUrl) }]
      : null,
    bg_image: currentEvent?.bgImageUrl
      ? [{ url: getImageSource(currentEvent?.bgImageUrl) }]
      : null
  };
  return {
    currentOccasion,
    currentEvent,
    initialValues,
    versionOptions,
    groupData,
    allGroupsLinks,
    fetchProgress: store.browserState.fetchProgress
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      occasionGroupView,
      occasionEventCreate,
      occasionEventUpdate
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOccasionEvent);
