import request from './request';

export function occasionImportantContactCreateApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/important_contact/important_contacts`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function occasionImportantContactViewApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/important_contact/important_contacts?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function occasionImportantContactDeleteApi(
  ocId,
  importantContactId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/important_contact/important_contacts/${importantContactId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function occasionImportantContactUpdateApi(
  ocId,
  importantContactId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/important_contact/important_contacts/${importantContactId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}
