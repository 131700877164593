import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, Form, AutoComplete } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../components/Section';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import InputGroup from '../../../../components/InputGroup';
import { SubmitButton } from '../../../../components/InputGroup/FormComponents';
import { Avatar } from '../../../../components/Avatar';
import { WatiCard } from '../../../../components/WatiCard/index';
import { renderRoute } from '../../../../components/RouterHOC';
import { capitalize, getNameInitials } from '../../../../helpers/stringHelper';
import { getRandomColor } from '../../../../helpers/imageHelper';
import { adminCreateRouteAccess } from '../../../../selectors/auth';
import { allOccasionsData } from '../../../../selectors/occasion';
import { getOccasionWithPagination } from '../../../../actions/occasion';
import { getWatiWithPagination } from '../../../../actions/whatsAppTemplates';
import {
  createWhatsappTemplate,
  updateWhatsappTemplate
} from '../../../../actions/whatsAppTemplates';
import { MESSAGE_TYPES } from '../Constants/index';

const { Option } = AutoComplete;

const validate = (values, id) => {
  const error = {};
  if (!values.occasionId) {
    error.occasionId = 'Occasion is required';
  }
  if (!id && !values.messageType) {
    error.messageType = 'Message Type is required';
  }
  if (!id && !values.watiId) {
    error.watiId = 'Whatsapp Template is required';
  }
  return error;
};

function WhatsappTemplatesCreate(props) {
  const [initiallyButtonDisabled, setInitiallyButtonDisabled] = useState(true);
  const [createFormData, setCreateFormData] = React.useState({
    occasionId: props.templateDetails?.occasion?.id || '',
    watiId: '',
    messageType: ''
  });
  const [errorMessages, setErrorMessages] = React.useState({});
  const [occasionPage, setOccasionPage] = React.useState(1);
  const [watiPage, setWatiPage] = React.useState(1);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append(
      'Authorization',
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI0ZmVkMDg3MS1iZDZjLTQyZjEtYTE3ZC0yZjVkYjYyMWZjYWYiLCJ1bmlxdWVfbmFtZSI6InByYXZlZW4uYmFoZXRpQGFsaWVuYnJhaW5zLmluIiwibmFtZWlkIjoicHJhdmVlbi5iYWhldGlAYWxpZW5icmFpbnMuaW4iLCJlbWFpbCI6InByYXZlZW4uYmFoZXRpQGFsaWVuYnJhaW5zLmluIiwiYXV0aF90aW1lIjoiMTEvMDYvMjAyMyAxMzozNDoyNiIsImRiX25hbWUiOiJtdC1wcm9kLVRlbmFudHMiLCJ0ZW5hbnRfaWQiOiIzMDAyMTkiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.GgyedOXZ9FRZbcGTgSg8f-JvEtMLuoaB8hb5xaSgDaU'
    );
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    props.getWatiWithPagination(watiPage, requestOptions);
  }, [watiPage]);

  useEffect(() => {
    props.getOccasionWithPagination(occasionPage);
  }, [occasionPage]);

  const getOccasionPage = (str) => {
    const decodedURI = decodeURI(str);
    const number = decodedURI
      .split('page[number]')[1]
      .replace('=', '')
      .split('&');
    return (number && parseInt(number)) || 1;
  };

  const getWatiPage = (str) => {
    const decodedURI = decodeURI(str);
    const number = decodedURI.split('pageNumber')[1].replace('=', '');
    return (number && parseInt(number)) || 1;
  };

  const onEndReachedForOccasion = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height >= scrollHeight - 20) {
      if (props.occasionLinks?.next) {
        const page = getOccasionPage(props.occasionLinks?.next);
        setOccasionPage(page);
      }
    }
  };

  const onEndReachedForWati = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height >= scrollHeight - 20) {
      if (props.watiLink?.nextPage) {
        const page = getWatiPage(props.watiLink.nextPage);
        setWatiPage(page);
      }
    }
  };

  const handleSubmit = () => {
    try {
      const validateObj = validate(createFormData, props.whatsappTemplatesId);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const data = new FormData();
        if (props.whatsappTemplatesId) {
          data.append(
            'whatsapp_template_message[occasion_id]',
            createFormData.occasionId
          );
        } else {
          const watiData = props.watiAllData.find(
            (el) => el.id === createFormData.watiId
          );
          data.append(
            'whatsapp_template_message[occasion_id]',
            createFormData.occasionId
          );
          data.append(
            'whatsapp_template_message[message_type]',
            createFormData.messageType
          );
          {
            watiData.id &&
              data.append('whatsapp_template_message[message_id]', watiData.id);
          }
          {
            watiData.elementName &&
              data.append(
                'whatsapp_template_message[element_name]',
                watiData.elementName
              );
          }
          {
            watiData.status &&
              data.append('whatsapp_template_message[status]', watiData.status);
          }
          {
            watiData.bodyOriginal &&
              data.append(
                'whatsapp_template_message[body]',
                watiData.bodyOriginal
              );
          }
          {
            watiData.footer &&
              data.append('whatsapp_template_message[footer]', watiData.footer);
          }
          {
            watiData.buttonsType &&
              watiData.buttonsType !== 'none' &&
              data.append(
                'whatsapp_template_message[button_type]',
                watiData.buttonsType
              );
          }
          {
            watiData.category &&
              data.append(
                'whatsapp_template_message[category]',
                watiData.category
              );
          }
          if (watiData.header && Object.values(watiData.header).length > 0) {
            {
              watiData.header.type &&
                data.append(
                  'whatsapp_template_message[header][type]',
                  watiData.header.type
                );
            }
            {
              watiData.header.text &&
                data.append(
                  'whatsapp_template_message[header][text]',
                  watiData.header.text
                );
            }
            {
              watiData.header.typeString &&
                data.append(
                  'whatsapp_template_message[header][typeString]',
                  watiData.header.typeString
                );
            }
            {
              watiData.header.link &&
                data.append(
                  'whatsapp_template_message[header][link]',
                  watiData.header.link
                );
            }
            {
              watiData.header.mediaHeaderId &&
                data.append(
                  'whatsapp_template_message[header][mediaHeaderId]',
                  watiData.header.mediaHeaderId
                );
            }
            {
              watiData.header.mediaFromPC &&
                data.append(
                  'whatsapp_template_message[header][mediaFromPC]',
                  watiData.header.mediaFromPC
                );
            }
            {
              watiData.header.headerOriginal &&
                data.append(
                  'whatsapp_template_message[header][headerOriginal]',
                  watiData.header.headerOriginal
                );
            }
            {
              watiData.header.headerParamMapping &&
                data.append(
                  'whatsapp_template_message[header][headerParamMapping]',
                  watiData.header.headerParamMapping
                );
            }
          }
          if (
            watiData.language &&
            Object.values(watiData.language).length > 0
          ) {
            {
              watiData.language.key &&
                data.append(
                  'whatsapp_template_message[language][key]',
                  watiData.language.key
                );
            }
            {
              watiData.language.value &&
                data.append(
                  'whatsapp_template_message[language][value]',
                  watiData.language.value
                );
            }
            {
              watiData.language.text &&
                data.append(
                  'whatsapp_template_message[language][text]',
                  watiData.language.text
                );
            }
          }

          if (watiData.buttons && watiData.buttons.length > 0) {
            watiData.buttons.forEach((el) => {
              data.append(
                'whatsapp_template_message[buttons][][type]',
                el.type
              );
              if (el.parameter && Object.values(el.parameter).length > 0) {
                {
                  el.parameter.text &&
                    data.append(
                      'whatsapp_template_message[buttons][][parameter][text]',
                      el.parameter.text
                    );
                }
                {
                  el.parameter.phoneNumber &&
                    data.append(
                      'whatsapp_template_message[buttons][][parameter][phoneNumber]',
                      el.parameter.phoneNumber
                    );
                }
                {
                  el.parameter.url &&
                    data.append(
                      'whatsapp_template_message[buttons][][parameter][url]',
                      el.parameter.url
                    );
                }
                {
                  el.parameter.urlOriginal &&
                    data.append(
                      'whatsapp_template_message[buttons][][parameter][urlOriginal]',
                      el.parameter.urlOriginal
                    );
                }
                {
                  el.parameter.urlType &&
                    data.append(
                      'whatsapp_template_message[buttons][][parameter][urlType]',
                      el.parameter.urlType
                    );
                }
                if (
                  el.parameter.buttonParamMapping &&
                  Object.values(el.parameter.buttonParamMapping).length > 0
                ) {
                  {
                    el.parameter.buttonParamMapping.index &&
                      data.append(
                        'whatsapp_template_message[buttons][][parameter][buttonParamMapping][index]',
                        el.parameter.buttonParamMapping.index
                      );
                  }
                  {
                    el.parameter.buttonParamMapping.paramName &&
                      data.append(
                        'whatsapp_template_message[buttons][][parameter][buttonParamMapping][paramName]',
                        el.parameter.buttonParamMapping.paramName
                      );
                  }
                }
              }
            });
          }
          if (watiData.customParams && watiData.customParams.length > 0) {
            watiData.customParams.forEach((el) => {
              {
                el.paramName &&
                  data.append(
                    'whatsapp_template_message[custom_params][][paramName]',
                    el.paramName
                  );
              }
              {
                el.paramValue &&
                  data.append(
                    'whatsapp_template_message[custom_params][][paramValue]',
                    el.paramValue
                  );
              }
            });
          }
        }

        if (props.whatsappTemplatesId) {
          props.updateWhatsappTemplate(props.whatsappTemplatesId, data, () => {
            props.handleCloseClick();
          });
        } else {
          props.createWhatsappTemplate(data, () => {
            setCreateFormData({});
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let formValue;
      let inputName;
      if (initiallyButtonDisabled) {
        setInitiallyButtonDisabled(false);
      }
      formValue = value || e?.target?.value;
      inputName = name || e?.target?.name;

      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setCreateFormData({
        ...createFormData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="whatsapp-templates-create-section">
        <div className="whatsapp-templates-create-form">
          <Form className="whatsapp-templates-form" onFinish={handleSubmit}>
            <InputGroup
              fieldType="dropdown"
              className="occasion-search"
              mode="single"
              name="occasionId"
              label="Select Occasion"
              placeholder="Select Occasion"
              onPopupScroll={onEndReachedForOccasion}
              onChange={handleInputChange}
              errorMessage={errorMessages.occasionId}
              value={createFormData?.occasionId}>
              {props.occasionData?.length > 0 &&
                props.occasionData.map((item) => {
                  return (
                    <Option value={item?.id} key={item?.id}>
                      <div className="options-container">
                        <p className="avatar-image-container">
                          <Avatar
                            avatarUrl={item?.cardImageUrl}
                            name={item?.name}
                            className="wati-image"
                          />
                        </p>
                        <div className="occasion-group-details-container">
                          <p className="occasion-group-name">{item?.name}</p>
                        </div>
                      </div>
                    </Option>
                  );
                })}
            </InputGroup>

            {!props.whatsappTemplatesId && (
              <InputGroup
                label="Message Type"
                className="occasion-search"
                fieldType="dropdown"
                placeholder="Select"
                options={MESSAGE_TYPES}
                name="messageType"
                onChange={handleInputChange}
                errorMessage={errorMessages.messageType}
                value={createFormData?.messageType}
              />
            )}

            {!props.whatsappTemplatesId && (
              <InputGroup
                fieldType="dropdown"
                className="wati-search"
                mode="single"
                name="watiId"
                label="Select Template"
                placeholder="Select Template"
                onPopupScroll={onEndReachedForWati}
                onChange={handleInputChange}
                errorMessage={errorMessages.watiId}
                value={createFormData.watiId}>
                {props.watiAllData?.length > 0 &&
                  props.watiAllData.map((item) => {
                    return (
                      <Option value={item?.id} key={item?.id}>
                        <div className="wati-options-container">
                          <div className="wati-details-container">
                            <p className="wati-name">{item?.elementName}</p>
                            <p className="wati-id">{item.id}</p>
                            <p className="wati-type">{item?.category}</p>
                          </div>
                        </div>
                      </Option>
                    );
                  })}
              </InputGroup>
            )}
            {createFormData.watiId && (
              <WatiCard id={createFormData.watiId} data={props.watiAllData} />
            )}
            <SubmitButton
              disabled={initiallyButtonDisabled || props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={
                !!props.whatsappTemplatesId ? 'Save Changes' : 'Create'
              }
            />
          </Form>
          {!!props.whatsappTemplatesId && (
            <Button
              onClick={props.handleCloseClick}
              className="edit-close-button">
              <CloseOutlined />
            </Button>
          )}
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  occasionData: allOccasionsData(store, props),
  occasionLinks: store.occasion.links,
  watiAllData: store.whatsappTemplates.watiAllData,
  watiLink: store.whatsappTemplates.watiLink
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOccasionWithPagination,
      getWatiWithPagination,
      createWhatsappTemplate,
      updateWhatsappTemplate
    },
    dispatch
  );

export default renderRoute(adminCreateRouteAccess)(
  connect(mapStateToProps, mapDispatchToProps)(WhatsappTemplatesCreate)
);
