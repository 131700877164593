import {
  CREATE_MESSAGES_FOR_PROVIDED_TASK,
  CREATE_MESSAGES_FOR_PROVIDED_TASK_FAILED,
  CREATE_MESSAGES_FOR_PROVIDED_TASK_SUCCESS,
  GET_WHATSAPP_TEMPLATE_MODELABLES,
  GET_WHATSAPP_TEMPLATE_MODELABLES_FAILED,
  GET_WHATSAPP_TEMPLATE_MODELABLES_SUCCESS,
  GET_PROVIDED_ANSWERED_USERS,
  GET_PROVIDED_ANSWERED_USERS_FAILED,
  GET_PROVIDED_ANSWERED_USERS_SUCCESS,
  GET_WHATSAPP_MESSAGE_MODELABLE,
  GET_WHATSAPP_MESSAGE_MODELABLE_FAILED,
  GET_WHATSAPP_MESSAGE_MODELABLE_SUCCESS,
  CLEAR_PROVIDED_USER_STORE
} from '../constants/actionTypes';
export const clearProvidedAnswerStore = (userType, taskId) => ({
  type: CLEAR_PROVIDED_USER_STORE,
  payload: { userType, taskId }
});

export const createMessages = (ocId, taskId, formData, callback) => ({
  type: CREATE_MESSAGES_FOR_PROVIDED_TASK,
  payload: { ocId, taskId, formData },
  callback
});

export const createMessagesSuccess = (data, included, ocId, taskId) => ({
  type: CREATE_MESSAGES_FOR_PROVIDED_TASK_SUCCESS,
  payload: { data, included, ocId, taskId }
});

export const createMessagesFailed = (error) => ({
  type: CREATE_MESSAGES_FOR_PROVIDED_TASK_FAILED,
  error
});

export const getWhatsappMessageByModelable = (
  ocId,
  modelableType,
  taskId,
  operationType,
  page
) => ({
  type: GET_WHATSAPP_MESSAGE_MODELABLE,
  payload: { ocId, modelableType, taskId, operationType, page }
});

export const getWhatsappMessageByModelableSuccess = (
  data,
  included,
  ocId,
  taskId,
  operationType,
  links,
  meta
) => ({
  type: GET_WHATSAPP_MESSAGE_MODELABLE_SUCCESS,
  payload: { data, included, ocId, taskId, operationType, links, meta }
});

export const getWhatsappMessageByModelableFailed = (error) => ({
  type: GET_WHATSAPP_MESSAGE_MODELABLE_FAILED,
  error
});

export const getWhatsappTemplateModelables = (
  ocId,
  modelableType,
  taskId,
  operationType,
  page
) => ({
  type: GET_WHATSAPP_TEMPLATE_MODELABLES,
  payload: { ocId, modelableType, taskId, operationType, page }
});

export const getWhatsappTemplateModelablesSuccess = (
  data,
  included,
  ocId,
  taskId,
  operationType,
  links
) => ({
  type: GET_WHATSAPP_TEMPLATE_MODELABLES_SUCCESS,
  payload: { data, included, ocId, taskId, operationType, links }
});

export const getWhatsappTemplateModelablesFailed = (error) => ({
  type: GET_WHATSAPP_TEMPLATE_MODELABLES_FAILED,
  error
});

export const getProvidedAnsweredUsers = (ocId, taskId, userType, page) => ({
  type: GET_PROVIDED_ANSWERED_USERS,
  payload: { ocId, taskId, userType, page }
});

export const getProvidedAnsweredUsersSuccess = (
  data,
  included,
  ocId,
  taskId,
  userType,
  links
) => ({
  type: GET_PROVIDED_ANSWERED_USERS_SUCCESS,
  payload: { data, included, ocId, taskId, userType, links }
});

export const getProvidedAnsweredUsersFailed = (error) => ({
  type: GET_PROVIDED_ANSWERED_USERS_FAILED,
  error
});
