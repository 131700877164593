import { createSelector } from 'reselect';
import { toCamel } from '../helpers/occasionHelper';
import { socianaAdmins } from './entity';
import { userAccessType, userId, userTabAccess } from './static';
import { allowAccessToPage, currentTab } from './dynamicData';

export const adminData = createSelector(
  userId,
  socianaAdmins,
  (id, admins) => id && admins && admins[id]
);

export const adminCreateRouteAccess = createSelector(
  userTabAccess,
  userAccessType,
  currentTab,
  allowAccessToPage,
  (allTabs, accessType, tab, isAccessAllowed) => {
    const camelizedType = accessType && toCamel(accessType);
    return (
      (allTabs &&
        accessType &&
        tab &&
        allTabs[camelizedType] &&
        allTabs[camelizedType][tab] &&
        allTabs[camelizedType][tab]?.find((el) => el === 'create')) ||
      isAccessAllowed
    );
  }
);

export const adminViewRouteAccess = createSelector(
  userTabAccess,
  userAccessType,
  currentTab,
  allowAccessToPage,
  (allTabs, accessType, tab, isAccessAllowed) => {
    const camelizedType = accessType && toCamel(accessType);
    return (
      (allTabs &&
        accessType &&
        tab &&
        allTabs[camelizedType] &&
        allTabs[camelizedType][tab] &&
        allTabs[camelizedType][tab]?.find((el) => el === 'view')) ||
      isAccessAllowed
    );
  }
);
