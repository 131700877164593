import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button, AutoComplete } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import { MODELABLE_TYPE, OPERATION_TYPE } from '../../Constants/index';
import { Avatar } from '../../../../../components/Avatar';
import { getAllHospitalityTasksByOccasion } from '../../../../../selectors/hospitalityTask';
import { getAllTasks } from '../../../../../actions/hospitalityTask';
import {
  createWhatsAppTemplateModelable,
  updateWhatsAppTemplateModelable
} from '../../../../../actions/whatsAppTemplates';
import { getCurrentWhatsappTemplatesModelable } from '../../../../../selectors/whatsappTemplates';

const { Option } = AutoComplete;

const validateForm = (values, id) => {
  const error = {};

  if (!values.operationType) {
    error.operationType = 'Operation Type is required';
  }

  if (!id && !values.modelableType) {
    error.modelableType = 'Modelable Type is required';
  }

  if (
    !id &&
    values.modelableType === 'Occasion' &&
    !values.occasionModelableId
  ) {
    error.occasionModelableId = 'Modelable Id is required';
  }
  if (
    !id &&
    values.modelableType === 'HospitalityTask' &&
    !values.taskModelableId
  ) {
    error.taskModelableId = 'Modelable Id is required';
  }
  return error;
};

function WhatsappTemplatesModelableCreate(props) {
  const [createFormData, setCreateFormData] = React.useState({
    operationType: props.currentModelableData?.operationType || '',
    modelableType: '',
    occasionModelableId: '',
    taskModelableId: ''
  });
  const [page, setPage] = React.useState(1);
  const [errorMessages, setErrorMessages] = React.useState({});

  useEffect(() => {
    if (createFormData.modelableType === 'HospitalityTask') {
      props.getAllTasks(props.occasion.id, page);
    }
  }, [createFormData.modelableType, page]);

  const getPage = (str) => {
    const decodedURI = decodeURI(str);
    const str1 = decodedURI.split('=');
    const res = str1[1].split('&');
    const number = res[0];
    return (number && parseInt(number)) || 1;
  };
  const onEndReached = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height >= scrollHeight - 20) {
      if (props.taskLinks?.next) {
        const pageNumber = getPage(props.taskLinks.next);
        setPage(pageNumber);
      }
    }
  };

  const handleSubmit = () => {
    try {
      const validateObj = validateForm(
        createFormData,
        props.whatsappTemplatesModelableId
      );
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();

        formData.append(
          'whatsapp_template_modelable[operation_type]',
          createFormData.operationType
        );

        let modelableId = '';
        if (createFormData.modelableType === 'HospitalityTask') {
          modelableId = createFormData.taskModelableId;
        } else if (createFormData.modelableType === 'Occasion') {
          modelableId = createFormData.occasionModelableId;
        }

        props.whatsappTemplatesModelableId
          ? props.updateWhatsAppTemplateModelable(
              props.whatsappTemplatesId,
              props.whatsappTemplatesModelableId,
              formData
            )
          : props.createWhatsAppTemplateModelable(
              props.whatsappTemplatesId,
              modelableId,
              createFormData.modelableType,
              formData,
              () => {
                setCreateFormData({
                  modelableType: '',
                  operationType: '',
                  occasionModelableId: '',
                  taskModelableId: ''
                });
              }
            );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    try {
      let value;
      let name;

      const formValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setCreateFormData({
        ...createFormData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="wt-modelable-create-section">
        <div className="wt-modelable-create-form">
          <Form className="wt-modelable-form" onFinish={handleSubmit}>
            <InputGroup
              label="Operation Type"
              className="wt-modelable-search"
              fieldType="dropdown"
              placeholder="Select"
              options={OPERATION_TYPE}
              name="operationType"
              onChange={handleInputChange}
              errorMessage={errorMessages.operationType}
              value={createFormData?.operationType}
            />

            {!props.whatsappTemplatesModelableId && (
              <InputGroup
                label="Modelable Type"
                className="wt-modelable-search"
                fieldType="dropdown"
                placeholder="Select"
                options={MODELABLE_TYPE}
                name="modelableType"
                onChange={handleInputChange}
                errorMessage={errorMessages.modelableType}
                value={createFormData?.modelableType}
              />
            )}

            {!props.whatsappTemplatesModelableId &&
              createFormData.modelableType === 'HospitalityTask' && (
                <InputGroup
                  label="Hospitality Task"
                  className="wt-modelable-search"
                  mode="single"
                  fieldType="dropdown"
                  placeholder="Select"
                  name="taskModelableId"
                  onChange={handleInputChange}
                  onPopupScroll={onEndReached}
                  errorMessage={errorMessages.taskModelableId}
                  value={createFormData?.taskModelableId}>
                  {props.allTaskData?.length > 0 &&
                    props.allTaskData.map((item) => {
                      return (
                        <Option value={item?.id} key={item?.id}>
                          <div className="options-container">
                            <div className="occasion-group-details-container">
                              <p className="occasion-group-name">
                                {item?.name}
                              </p>
                              <p className="occasion-group-type bold">
                                {item?.taskType}
                              </p>
                              <p className="occasion-group-type">
                                {item?.dataInputType}
                              </p>
                            </div>
                          </div>
                        </Option>
                      );
                    })}
                </InputGroup>
              )}
            {!props.whatsappTemplatesModelableId &&
              createFormData.modelableType === 'Occasion' && (
                <InputGroup
                  label="Occasion"
                  className="wt-modelable-search"
                  mode="single"
                  fieldType="dropdown"
                  placeholder="Select"
                  name="occasionModelableId"
                  onChange={handleInputChange}
                  errorMessage={errorMessages.occasionModelableId}
                  value={createFormData?.occasionModelableId}>
                  <Option value={props.occasion?.id} key={props.occasion?.id}>
                    <div className="options-container">
                      <p className="avatar-image-container">
                        <Avatar
                          avatarUrl={props.occasion?.cardImageUrl}
                          name={props.occasion?.name}
                          className="wati-image"
                        />
                      </p>
                      <div className="occasion-group-details-container">
                        <p className="occasion-group-name">
                          {props.occasion?.name}
                        </p>
                      </div>
                    </div>
                  </Option>
                </InputGroup>
              )}

            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={
                props.whatsappTemplatesModelableId ? 'Save Changes' : 'Create'
              }
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  allTaskData: getAllHospitalityTasksByOccasion(store, props),
  taskLinks: store.hospitalityTask.allTasksLinks,
  currentModelableData: getCurrentWhatsappTemplatesModelable(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllTasks,
      createWhatsAppTemplateModelable,
      updateWhatsAppTemplateModelable
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WhatsappTemplatesModelableCreate);
