import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Select, Form, Image, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../components/Section';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import { Avatar } from '../../../../components/Avatar';
import {
  searchInternalUsersParticipatedOccasions,
  assignInternalTeamMember,
  getInternalUsersParticipatedOccasions,
  updateAssignInternalTeamMember
} from '../../../../actions/socianaInternalUser';
import { getSearchedParticipatedOccasions } from '../../../../selectors/socianaInternalUser';
import InputGroup from '../../../../components/InputGroup';
import { SubmitButton } from '../../../../components/InputGroup/FormComponents';
import { capitalize } from '../../../../helpers/stringHelper';
import './styles.scss';
import { getImageSource } from '../../../../helpers/imageHelper';
import DEFAULT_IMAGE from '../../../../assets/default-placeholder-image.png';
import validateRoleAssign from './validate';
import { adminData } from '../../../../selectors/auth';
import { getCurrentInternalTeamOccasion } from '../../../../selectors/socianaInternalUser';

const { Option } = Select;
const InternalUserOccasionAssign = (props) => {
  const [errorMessages, setErrorMessages] = useState({});
  const [assignForm, setAssignForm] = useState({
    searchData: { value: '', id: null },
    accessType: props.currentInternalTeamOccasionDetails?.type || '',
    rolename: props.currentInternalTeamOccasionDetails?.roleName || ''
  });

  const handleSearch = (val) => {
    setAssignForm({ ...assignForm, searchData: { value: val, id: null } });
    if (val.length > 0) {
      props.searchInternalUsersParticipatedOccasions(val);
    }
  };
  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (type === 'accessType') {
          value = e;
          name = type;
        }
        if (type === 'searchData') {
          value = {
            value: e?.value,
            id: e?.key
          };
          name = type;
        }
        const assignFormValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        setAssignForm({
          ...assignForm,
          [inputName]: assignFormValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onFormSubmit = () => {
    try {
      const validateObj = validateRoleAssign(
        assignForm,
        props.internalTeamOccasionId
      );
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        if (!props.internalTeamOccasionId) {
          formData.append('internal_team[user_id]', props.userId);
        }
        formData.append(
          props.internalTeamOccasionId
            ? 'internal_teams_occasion[type]'
            : 'internal_team[type]',
          capitalize(assignForm.accessType)
        );
        formData.append(
          props.internalTeamOccasionId
            ? 'internal_teams_occasion[role_name]'
            : 'internal_team[role_name]',
          assignForm.rolename
        );
        props.internalTeamOccasionId
          ? props.updateAssignInternalTeamMember(
              props.ocId,
              props.userId,
              formData,
              props.currentInternalTeamOccasionDetails?.type
            )
          : props.assignInternalTeamMember(
              assignForm.searchData?.id,
              formData,
              () => {
                setAssignForm({
                  searchData: { value: '', id: null },
                  accessType: '',
                  rolename: ''
                });
                props.getInternalUsersParticipatedOccasions(props.userId, 1);
              }
            );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="internal-team-create-section">
        <div className="internal-team-create-container">
          <Form className="control-container" onSubmitCapture={onFormSubmit}>
            {!props.internalTeamOccasionId ? (
              <InputGroup
                className="control-container-search"
                fieldType="searchbox"
                notFoundContent="No Data"
                showSearch={true}
                name="searchData"
                label="Search Occasion"
                loading={props.fetchProgress}
                value={assignForm?.searchData?.value}
                onSelect={(e, option) =>
                  handleInputChange(option, 'searchData')
                }
                onSearch={handleSearch}
                errorMessage={errorMessages?.searchData}
                optionLabelProp="label"
                placeholder="Search Internal Team Member">
                {props.participatedOccasions &&
                  props.participatedOccasions?.length > 0 &&
                  props.participatedOccasions?.map((item) => {
                    return (
                      <Option
                        key={item?.id}
                        value={item?.name}
                        label={item?.name}>
                        <div className="options-container">
                          <Avatar
                            avatarUrl={item?.cardImageUrl}
                            name={item?.name}
                            className="avatar-image-container"
                          />
                          <div className="internal-member-occasion-details-container">
                            <p className="internal-member-occasion-name">
                              {item?.name}
                            </p>
                            <p className="internal-member-occasion-type">
                              <p>{item?.type}</p>
                              <p className="guest-field">
                                {capitalize(item?.guestType)}
                              </p>
                            </p>
                          </div>
                        </div>
                      </Option>
                    );
                  })}
              </InputGroup>
            ) : (
              ''
            )}
            <InputGroup
              fieldType="dropdown"
              name="accessType"
              optionLabelProp="label"
              value={assignForm?.accessType}
              placeholder="Select Access Type"
              label="Select Member Access Type"
              onSelect={(e) => handleInputChange(e, 'accessType')}
              errorMessage={errorMessages?.accessType}
              options={props?.userAccessTypes
                ?.filter(
                  (item) =>
                    (item !== 'owner' && props.admin.accessType === 'admin') ||
                    props.admin.accessType === 'super_admin'
                )
                ?.map((item) => ({
                  label: capitalize(item),
                  value: item
                }))}
            />
            <InputGroup
              onChange={handleInputChange}
              value={assignForm?.rolename}
              name="rolename"
              label="Enter Rolename"
              placeholder="Enter Rolename"
            />
            <SubmitButton
              buttonText="Assign"
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => ({
  participatedOccasions: getSearchedParticipatedOccasions(store, props),
  fetchProgress: store.browserState.fetchProgress,
  userAccessTypes: store.occasion.accessTypes,
  admin: adminData(store, props),
  currentInternalTeamOccasionDetails: getCurrentInternalTeamOccasion(
    store,
    props
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchInternalUsersParticipatedOccasions,
      assignInternalTeamMember,
      getInternalUsersParticipatedOccasions,
      updateAssignInternalTeamMember
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalUserOccasionAssign);
