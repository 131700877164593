import React from 'react';
import { Image } from 'antd';
import { ZoomInOutlined } from '@ant-design/icons';
import { getImageSource } from '../../../../../../helpers/imageHelper';
import Item from 'antd/lib/list/Item';

const EventAffairDetailsView = ({ eventAffairDetails }) => {
  return (
    <div className="event-details-container">
      <p className="event-details card-details">
        <p className="fields">Image:</p>
        <p className="field-data card-image-container">
          <Image
            src={getImageSource(eventAffairDetails?.iconImageUrl)}
            preview={{
              maskClassName: 'preview-style',
              mask: <ZoomInOutlined />
            }}
            className="image-style"
          />
        </p>
      </p>
      <p className="event-details">
        <p className="fields">Affair Type:</p>
        <p className="field-data">{eventAffairDetails?.affairType}</p>
      </p>
      {eventAffairDetails?.extraInfo ? (
        <p className="event-details">
          <p className="fields">ExtraInfo:</p>
          <p className="field-data">{eventAffairDetails?.extraInfo}</p>
        </p>
      ) : (
        ''
      )}
      <p className="event-details">
        <p className="fields">Color Code:</p>
        <p className="field-data">
          <span
            className="color-code-span"
            style={{
              backgroundColor: `${eventAffairDetails?.colorCode}`
            }}></span>
          {eventAffairDetails?.colorCode}
        </p>
      </p>
      {eventAffairDetails.description ? (
        <p className="event-details">
          <p className="fields">Description:</p>
          <p className="field-data">
            {eventAffairDetails.description?.map((item) => item).join(', ')}
          </p>
        </p>
      ) : (
        ''
      )}
    </div>
  );
};
export default EventAffairDetailsView;
