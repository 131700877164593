import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button, AutoComplete } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup';
import { getImageSource } from '../../../../../helpers/imageHelper';
import { Image } from '../../../../../components/Image';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import { occasionGroupView } from '../../../../../actions/group';
import { getOccasionGroupsWithAllData } from '../../../../../selectors/group';
import {
  occasionFixedGroupPostSettingCreate,
  updateFixedGroupPostSetting
} from '../../../../../actions/fixedGroupPostSetting';
import { currentFixedGroupPostSettingData } from '../../../../../selectors/fixedGroupPostSetting';

const validateData = (values) => {
  const errors = {};
  if (!values.postedByGroup) {
    errors.postedByGroup = 'PostedByGroup is required';
  }
  if (!values.viewedByGroup) {
    errors.viewedByGroup = 'ViewedByGroup  is required';
  }
  return errors;
};

const { Option } = AutoComplete;

function CreateFixedGroupPostSetting(props) {
  const [gpsData, setGpsData] = React.useState({
    postedByGroup: props.currentGpsData?.postedByGroup?.id || '',
    viewedByGroup: props.currentGpsData?.viewedByGroup?.id || ''
  });
  const [errorMessages, setErrorMessages] = React.useState({});
  const [page, setPage] = React.useState(1);

  const onPageChange = (page) => {
    props.occasionGroupView(props.occasionId, page);
  };

  React.useEffect(() => {
    onPageChange(page);
  }, [page]);

  const getPage = (str) => {
    const decodedURI = decodeURI(str);
    const number = decodedURI
      .split('page[number]')[1]
      .replace('=', '')
      .split('&');
    return (number && parseInt(number)) || 1;
  };

  const onEndReached = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height >= scrollHeight - 20) {
      if (props.allGroupsLinks?.next) {
        const page = getPage(props.allGroupsLinks?.next);
        setPage(page);
      }
    }
  };

  const handleSubmit = () => {
    try {
      const validateObj = validateData(gpsData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();

        formData.append(
          'fixed_group_post_setting[posted_by_group_id]',
          gpsData.postedByGroup
        );
        formData.append(
          'fixed_group_post_setting[viewed_by_group_id]',
          gpsData.viewedByGroup
        );

        props.fixedGroupPostSettingId
          ? props.updateFixedGroupPostSetting(
              props.occasionId,
              props.fixedGroupPostSettingId,
              formData
            )
          : props.occasionFixedGroupPostSettingCreate(
              props.occasionId,
              formData,
              () => {
                setGpsData({});
              }
            );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;

      const GPSValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setGpsData({
        ...gpsData,
        [inputName]: GPSValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="fixedGroupPostSetting-create-section">
        <div className="fixedGroupPostSetting-create-form">
          <Form className="fixedGroupPostSetting-form" onFinish={handleSubmit}>
            <InputGroup
              fieldType="dropdown"
              className="group-search"
              mode="single"
              name="postedByGroup"
              label="Posted By Group"
              placeholder="Select Group"
              onPopupScroll={onEndReached}
              onChange={handleInputChange}
              errorMessage={errorMessages?.postedByGroup}
              value={gpsData?.postedByGroup}>
              {props.groupData?.length > 0 &&
                props.groupData.map((item) => {
                  return (
                    <Option value={item?.id} key={item?.id}>
                      <div className="options-container">
                        <p className="avatar-image-container">
                          <Image
                            src={getImageSource(item?.groupPictureUrl)}
                            preview={false}
                          />
                        </p>
                        <div className="occasion-group-details-container">
                          <p className="occasion-group-name">{item?.name}</p>
                        </div>
                      </div>
                    </Option>
                  );
                })}
            </InputGroup>
            <InputGroup
              fieldType="dropdown"
              className="group-search"
              mode="single"
              name="viewedByGroup"
              label="Viewed By Group"
              placeholder="Select Group"
              onPopupScroll={onEndReached}
              onChange={handleInputChange}
              errorMessage={errorMessages?.viewedByGroup}
              value={gpsData?.viewedByGroup}>
              {props.groupData?.length > 0 &&
                props.groupData.map((item) => {
                  return (
                    <Option value={item?.id} key={item?.id}>
                      <div className="options-container">
                        <p className="avatar-image-container">
                          <Image
                            src={getImageSource(item?.groupPictureUrl)}
                            preview={false}
                          />
                        </p>
                        <div className="occasion-group-details-container">
                          <p className="occasion-group-name">{item?.name}</p>
                        </div>
                      </div>
                    </Option>
                  );
                })}
            </InputGroup>

            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={
                props.fixedGroupPostSettingId ? 'Save Changes' : 'Create'
              }
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentGpsData: currentFixedGroupPostSettingData(store, props),
  groupData: getOccasionGroupsWithAllData(store, props),
  allGroupsLinks: store.group.groupLinks
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      occasionGroupView,
      occasionFixedGroupPostSettingCreate,
      updateFixedGroupPostSetting
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateFixedGroupPostSetting);
