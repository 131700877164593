import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { resetTabIndex } from '../../../../../actions/tabKeys';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import TableView from '../../../../../components/Table';
import {
  occasionGroupView,
  deleteOccasionGroup
} from '../../../../../actions/group';
import { getAllOccasionGroups } from '../../../../../selectors/group';
import { Button } from 'antd';
import CreateGroup from './GroupCreate';

const ViewGroup = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.occasionGroupView(props.occasionId, page);
  };

  const location = useLocation();
  React.useEffect(() => {
    onPageChange(1);
    props.resetTabIndex('groups');
  }, []);

  const deleteData = (id) => {
    props.deleteOccasionGroup(props.occasionId, id);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="group-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={() => setCreateOpen(true)}>
          Create
        </Button>
      </div>
    )
  };
  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="group-view-section">
          <div className="group-view-container">
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              pathname={`${location.pathname}/group/view`}
              onPageChange={onPageChange}
              onDelete={deleteData}
              data={props.groups}
              paginationLinks={props.groupLinks}
              fetchProgress={props.fetchProgress}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <CreateGroup
        occasionId={props.occasionId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  groups: getAllOccasionGroups(store, props),
  groupLinks: store.group.groupLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { occasionGroupView, resetTabIndex, deleteOccasionGroup },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(ViewGroup);
