import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import {
  occasionAppCreate,
  updateOccasionApp
} from '../../../../../actions/occasionApp';
import { getImageSource } from '../../../../../helpers/imageHelper';

const validateApp = (values) => {
  const error = {};
  if (!values.app_id) {
    error.app_id = 'App Id is required';
  }
  return error;
};

function OccasionAppCreate(props) {
  const [occasionAppData, setOccasionAppData] = React.useState({
    name: props.appData?.appName ? props.appData?.appName : '',
    app_id: props.appData?.appId ? props.appData?.appId : '',
    appStore_url: props.appData?.appstoreUrl ? props.appData?.appstoreUrl : '',
    playStore_url: props.appData?.playstoreUrl
      ? props.appData?.playstoreUrl
      : '',
    serverKey: props.appData?.firebaseServerKey
      ? props.appData?.firebaseServerKey
      : '',
    fmc_json_file: props.appData?.fcmJsonFileUrl
      ? [{ url: getImageSource(props.appData?.fcmJsonFileUrl) }]
      : null
  });
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleSubmit = () => {
    try {
      const validateObj = validateApp(occasionAppData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('occasion_app[app_name]', occasionAppData?.name);
        formData.append('occasion_app[app_id]', occasionAppData?.app_id);
        formData.append(
          'occasion_app[appstore_url]',
          occasionAppData?.appStore_url
        );
        formData.append(
          'occasion_app[playstore_url]',
          occasionAppData?.playStore_url
        );
        formData.append(
          'occasion_app[firebase_server_key]',
          occasionAppData?.serverKey
        );

        if (
          occasionAppData.fmc_json_file &&
          occasionAppData.fmc_json_file[0]?.originFileObj
        ) {
          formData.append(
            'occasion_app[fcm_json_file]',
            occasionAppData.fmc_json_file[0].originFileObj
          );
        }

        props.appData?.appId
          ? props.updateOccasionApp(props.occasionId, formData)
          : props.occasionAppCreate(props.occasionId, formData, () => {
              setOccasionAppData({});
            });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'fmc_json_file') {
        value = e.fileList;
        name = type;
      }
      const inputValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }

      setOccasionAppData({
        ...occasionAppData,
        [inputName]: inputValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ErrorBoundary>
      <SectionContent className="occasion-app-create-section">
        <div className="occasion-app-create-form">
          <Form className="occasion-app-form" onFinish={handleSubmit}>
            <InputGroup
              label="Firebase Project Id"
              name="name"
              placeholder="Enter App Name"
              onChange={handleInputChange}
              value={occasionAppData?.name}
            />
            <InputGroup
              label="App Id"
              name="app_id"
              placeholder="Enter App Id"
              onChange={handleInputChange}
              value={occasionAppData?.app_id}
              errorMessage={errorMessages.app_id}
            />
            <InputGroup
              label="AppStore URL"
              name="appStore_url"
              placeholder="Enter AppStore Url"
              onChange={handleInputChange}
              value={occasionAppData?.appStore_url}
            />
            <InputGroup
              label="PlayStore URL"
              name="playStore_url"
              placeholder="Enter PlayStore Url"
              onChange={handleInputChange}
              value={occasionAppData?.playStore_url}
            />
            <InputGroup
              label="FireBase Server Key"
              name="serverKey"
              placeholder="Enter FireBase Server Key"
              onChange={handleInputChange}
              value={occasionAppData?.serverKey}
            />
            <InputGroup
              fieldType="file"
              label="FCM JSON File"
              accept=".json"
              fileList={occasionAppData?.fmc_json_file}
              onChange={handleInputChange}
              name="fmc_json_file"
            />
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.appData?.appId ? 'Save Changes' : 'Create'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ occasionAppCreate, updateOccasionApp }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OccasionAppCreate);
