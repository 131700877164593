import React, { useState } from 'react';
import {
  MailOutlined,
  PhoneOutlined,
  CalendarOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { capitalize } from '../../helpers/stringHelper';
import './styles.scss';
import { Avatar } from '../Avatar';

export const GuestContent = ({ data }) => {
  return (
    <div className="guest-content">
      {data?.map((item) => {
        return (
          <div className="guest-content-box">
            <Avatar
              avatarUrl={item?.avatarUrl}
              name={item?.name}
              className="group-content-image"
            />
            <div className="guest-content-text">
              <span className="guest-content-name">{item.name || ''}</span>
              {item?.email && !item?.phone && (
                <div className="guest-content-extras-content">
                  <MailOutlined className="guest-content-icon" />
                  <span className="guest-content-extra-item">{item.email}</span>
                </div>
              )}
              {item?.phone && !item?.email && (
                <div className="guest-content-extras-content">
                  <PhoneOutlined className="guest-content-icon" />
                  <span className="guest-content-extra-item">{item.phone}</span>
                </div>
              )}
              {item?.email && item?.phone && (
                <div className="guest-content-extras-content">
                  <MailOutlined className="guest-content-icon" />
                  <span className="guest-content-extra-item">{item.email}</span>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
