import React from 'react';
import { Dot } from '../Dot';
import './styles.scss';

const TabsHeader = ({
  name,
  extraData = [],
  wrapperClass,
  isActive = null
}) => {
  return (
    <div className={`tabs-header ${wrapperClass}`}>
      <div className="tabs-header-name">{name}</div>
      {extraData.map((item, index) => {
        return (
          <div className="tabs-header-extra-container">
            <Dot size={5} color="black" />
            <div className="tabs-header-extra">{item}</div>
          </div>
        );
      })}
      {isActive !== null && isActive !== undefined && (
        <Dot color={isActive ? 'green' : 'red'} />
      )}
    </div>
  );
};

export { TabsHeader };
