import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  eventAffairEssenceCreateApi,
  eventAffairEssenceDeleteApi,
  eventAffairEssenceUpdateApi,
  eventAffairEssenceViewApi
} from '../api/eventAffairEssence';
import {
  EVENT_AFFAIR_ESSENCE_CREATE,
  EVENT_AFFAIR_ESSENCE_DELETE,
  EVENT_AFFAIR_ESSENCE_UPDATE,
  EVENT_AFFAIR_ESSENCE_VIEW
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  eventAffairEssenceCreateFailed,
  eventAffairEssenceCreateSuccess,
  eventAffairEssenceDeleteFailed,
  eventAffairEssenceDeleteSuccess,
  eventAffairEssenceUpdateFailed,
  eventAffairEssenceUpdateSuccess,
  eventAffairEssenceViewFailed,
  eventAffairEssenceViewSuccess
} from '../actions/eventAffairEssence';

export function* eventAffairEssenceCreateSaga(action) {
  try {
    const result = yield call(
      eventAffairEssenceCreateApi,
      action.payload.ocId,
      action.payload.formData,
      action.payload.eventId,
      action.payload.eventAffairId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventAffairEssenceCreateSuccess(
          action.payload.ocId,
          action.payload.eventId,
          action.payload.eventAffairId,
          result.data,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(eventAffairEssenceCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(eventAffairEssenceCreateFailed(error));

    if (
      error &&
      error?.errors?.description_or_file &&
      error?.errors?.description_or_file.length > 0
    ) {
      yield put(
        requestAutoHideAlert(
          error?.errors?.description_or_file[0],
          'Error',
          4000
        )
      );
    } else if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventAffairEssenceViewSaga(action) {
  try {
    const result = yield call(
      eventAffairEssenceViewApi,
      action.payload.ocId,
      action.payload.page,
      action.payload.eventId,
      action.payload.eventAffairId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventAffairEssenceViewSuccess(
          action.payload.ocId,
          action.payload.eventId,
          action.payload.eventAffairId,
          result.data,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(eventAffairEssenceViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventAffairEssenceUpdateSaga(action) {
  try {
    const result = yield call(
      eventAffairEssenceUpdateApi,
      action.payload.ocId,
      action.payload.eventAffairId,
      action.payload.eventId,
      action.payload.essenceId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventAffairEssenceUpdateSuccess(
          action.payload.ocId,
          action.payload.eventId,
          action.payload.eventAffairId,
          result.data,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(eventAffairEssenceUpdateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(eventAffairEssenceUpdateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventAffairEssenceDeleteSaga(action) {
  try {
    const result = yield call(
      eventAffairEssenceDeleteApi,
      action.payload.ocId,
      action.payload.eventAffairId,
      action.payload.eventId,
      action.payload.essenceId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventAffairEssenceDeleteSuccess(
          action.payload.ocId,
          action.payload.eventId,
          action.payload.eventAffairId,
          result.data,
          result.included,
          result.links
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(eventAffairEssenceDeleteFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* eventAffairEssenceActionWatcher() {
  yield takeLatest(
    EVENT_AFFAIR_ESSENCE_CREATE,
    progressSaga,
    eventAffairEssenceCreateSaga
  );
  yield takeLatest(
    EVENT_AFFAIR_ESSENCE_VIEW,
    progressSaga,
    eventAffairEssenceViewSaga
  );

  yield takeLatest(
    EVENT_AFFAIR_ESSENCE_UPDATE,
    progressSaga,
    eventAffairEssenceUpdateSaga
  );
  yield takeLatest(
    EVENT_AFFAIR_ESSENCE_DELETE,
    progressSaga,
    eventAffairEssenceDeleteSaga
  );
}
