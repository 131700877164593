import React from 'react';
import './styles.scss';

export const Dot = ({ color, size }) => {
  return (
    <div
      style={{ backgroundColor: color, width: size, height: size }}
      className="dot-container"
    />
  );
};
