import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  occasionImportantContactCreateApi,
  occasionImportantContactDeleteApi,
  occasionImportantContactUpdateApi,
  occasionImportantContactViewApi
} from '../api/importantContact';
import {
  OCCASION_IMPORTANT_CONTACT_CREATE,
  OCCASION_IMPORTANT_CONTACT_DELETE,
  OCCASION_IMPORTANT_CONTACT_UPDATE,
  OCCASION_IMPORTANT_CONTACT_VIEW
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  occasionImportantContactCreateFailed,
  occasionImportantContactCreateSuccess,
  occasionImportantContactDeleteFailed,
  occasionImportantContactDeleteSuccess,
  occasionImportantContactUpdateFailed,
  occasionImportantContactUpdateSuccess,
  occasionImportantContactViewFailed,
  occasionImportantContactViewSuccess
} from '../actions/importantContact';

export function* occasionImportantContactCreateSaga(action) {
  try {
    const result = yield call(
      occasionImportantContactCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionImportantContactCreateSuccess(
          result.data,
          result.included,
          action.payload.ocId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionImportantContactCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionImportantContactCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionImportantContactViewSaga(action) {
  try {
    const result = yield call(
      occasionImportantContactViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionImportantContactViewSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionImportantContactViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionImportantContactUpdateSaga(action) {
  try {
    const result = yield call(
      occasionImportantContactUpdateApi,
      action.payload.ocId,
      action.payload.importantContactId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionImportantContactUpdateSuccess(
          result.data,
          result.included,
          action.payload.ocId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionImportantContactUpdateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionImportantContactUpdateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionImportantContactDeleteSaga(action) {
  try {
    const result = yield call(
      occasionImportantContactDeleteApi,
      action.payload.ocId,
      action.payload.importantContactId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionImportantContactDeleteSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          result.links
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionImportantContactDeleteFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* importantContactActionWatcher() {
  yield takeLatest(
    OCCASION_IMPORTANT_CONTACT_CREATE,
    progressSaga,
    occasionImportantContactCreateSaga
  );
  yield takeLatest(
    OCCASION_IMPORTANT_CONTACT_VIEW,
    progressSaga,
    occasionImportantContactViewSaga
  );
  yield takeLatest(
    OCCASION_IMPORTANT_CONTACT_UPDATE,
    progressSaga,
    occasionImportantContactUpdateSaga
  );
  yield takeLatest(
    OCCASION_IMPORTANT_CONTACT_DELETE,
    progressSaga,
    occasionImportantContactDeleteSaga
  );
}
