export const userId = (store) => store.auth.data;
export const userTabAccess = (store) => store.auth.tabAccess;
export const userType = (store) => store.auth.type;
export const userAccessType = (store) => store.auth.accessType;

export const occasionTypes = (store) => store.occasion.types;
export const occasionIds = (store) => store.occasion.data;
export const allOccasionIds = (store) => store.occasion.allData;
export const participatedOccasionIds = (store) =>
  store.socianaInternalUser?.participatedOccasions;
export const searchOccasionIds = (store) => store.occasion?.searchData;
export const memberAccessTypes = (store) => store.occasion.accessTypes;
export const currentOccasionInternalMembers = (store) =>
  store.occasion?.internalTeamMembers;
export const currentOccasionInternalMemberLinks = (store) =>
  store.occasion?.internalTeamMembersLinks;

export const activeAdminIds = (store) => store.admin.activeIds;
export const inactiveAdminIds = (store) => store.admin.inactiveIds;

export const socianaInternalUserActiveData = (store) =>
  store.socianaInternalUser.activeIds;
export const socianaInternalUserInactiveData = (store) =>
  store.socianaInternalUser.inactiveIds;

export const searchInternalMemberIds = (store) =>
  store.socianaInternalUser.searchIds;
export const reportedPostIds = (store) => store.report.posts.data;
export const reportedPostLinks = (store) => store.report.posts.links;
export const likeIds = (store) => store.like.data;
export const likeLinks = (store) => store.like.links;
export const commentIds = (store) => store.comment.data;
export const commentLinks = (store) => store.comment.links;
export const adminOccasionData = (store) => store.admin.adminOccasionsData;
export const adminOccasionLinks = (store) => store.admin.adminOccasionLinks;

export const usersPendingInvitationIds = (store) =>
  store.socianaInternalUser.pendingInvitationIds;
export const usersPendingInvitationLinks = (store) =>
  store.socianaInternalUser.pendingInvitationLinks;

export const socianaUserId = (store) => store.user.data;
export const socianaUserAllIds = (store) => store.user.allIds;
export const socianaUserSearchIds = (store) => store.user.searchData;

export const socianaInternalUserUserAllIds = (store) =>
  store.socianaInternalUser.allIds;

export const galleryUUIDs = (store) => store.gallery.data;
export const galleryPhotoIds = (store) => store.gallery.photos;
export const photosNextPresent = (store) => store.gallery.photosNextPresent;
export const galleryVideoIds = (store) => store.gallery.videos;
export const videosNextPresent = (store) => store.gallery.videosNextPresent;
export const uploadImageList = (store) => store.gallery.uploadImageList;
export const pendingInvitationIds = (store) =>
  store.invitation.pendingInvitationData;
export const pendingRegistrationIds = (store) =>
  store.invitation.pendingRegistrationData;

export const pendingInvitationAllIds = (store) =>
  store.invitation.allPendingInvitationData;
export const pendingInvitationSearchIds = (store) =>
  store.invitation.searchPendingInvitationData;

export const pendingRegistrationAllIds = (store) =>
  store.invitation.allPendingRegistrationData;
export const pendingRegistrationSearchIds = (store) =>
  store.invitation.searchPendingRegistrationData;

export const occasionEventIds = (store) => store.event.occasionEventData;
export const occasionAllEventIds = (store) => store.event.occasionEventAllData;
export const hospitalityCategoryIds = (store) =>
  store.hospitality.hospitalityCategoryId;
export const occasionGroupIds = (store) => store.group.occasionGroupTabData;
export const occasionAllGroupIds = (store) => store.group.allGroupData;
export const occasionLiveIds = (store) => store.live.data;
export const currentLiveId = (store) => store.live.currentLiveId;
export const occasionLiveTokens = (store) => store.live.tokens;
export const occasionLiveUIDs = (store) => store.live.uids;
export const hospitalityTaskIds = (store) =>
  store.hospitalityTask.hospitalityTaskId;

export const allHospitalityTasksIds = (store) =>
  store.hospitalityTask.allTasksData;

export const locationTriggerIds = (store) => store.trigger.locationTriggerData;
export const actionTriggerIds = (store) => store.trigger.actionTriggerData;
export const timeTriggerIds = (store) => store.trigger.timeTriggerData;

export const occasionLiveVideoIds = (store) =>
  store.liveVideo.occasionLiveVideoData;
export const occasionSosIds = (store) => store.sos.occasionSosData;

export const occasionPostIds = (store) => store.post.data;
export const postLinks = (store) => store.post.links;
export const eventPostIds = (store) => store.post.eventPostData;
export const eventPostLinks = (store) => store.post.eventPostLinks;
export const featureIds = (store) => store.featureControl.data;

export const memberIds = (store) => store.members.data;
export const memberLinks = (store) => store.members.links;

export const searchedMemberIds = (store) => store.members.searchedIds;
export const searchedMemberLinks = (store) => store.members.searchedLinks;

export const sponsorshipIds = (store) =>
  store.sponsorship.occasionSponsorshipData;
export const importantContactIds = (store) =>
  store.importantContact.occasionImportantContactData;
export const awardIds = (store) => store.award.occasionAwardData;

export const groupMemberIds = (store) => store.group.members;
export const groupMemberLinks = (store) => store.group.memberLinks;

export const groupOccasionUsers = (store) => store.group.users;
export const groupOccasionUserLinks = (store) => store.group.userLinks;

export const groupOccasionSearchedUsers = (store) => store.group.searchedUsers;
export const groupOccasionSearchedUserLinks = (store) =>
  store.group.searchedUsersLinks;
export const ticketIds = (store) => store.ticket.occasionTicketData;
export const ticketVariantIds = (store) =>
  store.ticketVariant.occasionTicketVariantData;
export const ticketVariantFormIds = (store) =>
  store.ticketVariantForm.ticketVariantFormData;
export const questionIds = (store) => store.question.occasionQuestionData;

export const registeredPurchaseIds = (store) => store.purchases.registeredData;
export const registeredPurchaseLinks = (store) =>
  store.purchases.registeredLinks;

export const unregisteredPurchaseIds = (store) =>
  store.purchases.unregisteredData;
export const unregisteredPurchaseLinks = (store) =>
  store.purchases.unregisteredLinks;
export const scheduleIds = (store) => store.schedule.occasionScheduleData;
export const itineraryIds = (store) => store.itinerary.occasionItineraryData;

export const notificationIds = (store) => store.notifications.data;
export const notificationLinks = (store) => store.notifications.links;
export const carouselIds = (store) => store.carousel.occasionCarouselData;
export const divisionIds = (store) => store.division.occasionDivisionData;
export const occasionSmsIds = (store) => store.sms.occasionSmsData;
export const subDivisionIds = (store) =>
  store.subdivision.occasionSubdivisionData;
export const guestIds = (store) => store.guest.memberGuestData;
export const albumIds = (store) => store.album.occasionAlbumData;
export const albumPhotoIds = (store) => store.album.photos;
export const albumPhotosNextPresent = (store) => store.album.photosNextPresent;
export const albumVideoIds = (store) => store.album.videos;
export const albumVideosNextPresent = (store) => store.album.videosNextPresent;
export const uploadAlbumImageList = (store) => store.album.uploadImageList;
export const eventMembersIds = (store) => store.event.eventmembers;
export const eventPendingInvitationIds = (store) =>
  store.event.eventPendingInvitation;
export const eventPendingRegistrationIds = (store) =>
  store.event.eventPendingRegistration;
export const occasionAppIds = (store) => store.occasionApp.occasionAppData;
export const occasionRoleNameIds = (store) =>
  store.roleName.occasionRoleNameData;
export const userOccasionIds = (store) => store.userOccasion.userOccasionData;
export const userDeviceIds = (store) => store.userDevice.userDeviceData;
export const notRespondedTaskIds = (store) =>
  store.hospitalityTask.notRespondedTaskId;
export const respondedTaskIds = (store) =>
  store.hospitalityTask.respondedTaskId;
export const contactUsIds = (store) => store.hospitality.contactUsId;

export const providedSearchIds = (store) =>
  store.hospitalityTask.providedSearchData;
export const notProvidedSearchIds = (store) =>
  store.hospitalityTask.notProvidedSearchData;

export const respondedSearchIds = (store) =>
  store.hospitalityTask.respondedSearchData;
export const notRespondedSearchIds = (store) =>
  store.hospitalityTask.notRespondedSearchData;

export const notProvidedTaskIds = (store) =>
  store.hospitalityTask.notProvidedTaskId;
export const providedTaskIds = (store) => store.hospitalityTask.providedTaskId;
export const occasionPollPostIds = (store) => store.pollPost.pollPostData;
export const occasionPollPostQuizIds = (store) =>
  store.pollPost.pollPostQuizData;
export const occasionPollPostPollIds = (store) =>
  store.pollPost.pollPostPollData;
export const codeIds = (store) => store.occasionCode.occasionCodeData;
export const occasionWhiteListIds = (store) =>
  store.occasionWhiteList.occasionWhiteListData;
export const accessControlIds = (store) => store.accessControl.data;

export const publicAnswerIds = (store) => store.publicAnswer.data;
export const publicAnswerLinks = (store) => store.publicAnswer.links;
export const eventScheduleIds = (store) => store.event.eventScheduleData;
export const occasionEventPostIds = (store) => store.event.eventPostData;
export const eventAffairIds = (store) => store.event.eventAffairData;
export const eventCollaboratorIds = (store) =>
  store.eventCollaborator.eventCollaboratorData;
export const eventCollaboratorSearchIds = (store) =>
  store.eventCollaborator.eventCollaboratorSearchData;

export const eventAffairEssenceIds = (store) =>
  store.eventAffairEssence.eventAffairEssenceData;
export const eventAffairEntertainmentIds = (store) =>
  store.eventAffairEntertainment.eventAffairEntertainmentData;
export const eventAffairFoodIds = (store) =>
  store.eventAffairFood.eventAffairFoodData;

export const discussionIds = (store) => store.discussion.occasionDiscussionData;

export const enquiryIds = (store) => store.enquiry.data;

export const occasionTabSettingIds = (store) =>
  store.occasionTabSetting.occasionTabSettingData;

export const occasionSpeakerIds = (store) => store.speaker.occasionSpeakerData;
export const speakerReferenceIds = (store) =>
  store.speakerReference.speakerReferenceData;

export const activityTrackerIds = (store) =>
  store.activityTracker.activityTrackerData;

export const blackListUserIds = (store) =>
  store.activityTracker.blackListUserData;

export const searchUserByOccasionIds = (store) =>
  store.activityTrackerPresentUser.searchIds;

export const activityTrackerPresentUserIds = (store) =>
  store.activityTrackerPresentUser.activityTrackerPresentUserData;

export const activityTrackerAbsentUserIds = (store) =>
  store.activityTrackerAbsentUser.activityTrackerAbsentUserData;

export const alarmIds = (store) => store.alarm.occasionAlarmData;

export const faceInfoUserIds = (store) => store.faceInfo.faceInfoUserData;
export const faceInfoMediaIds = (store) => store.faceInfo.faceInfoMediaData;
export const selFieUserMediaIds = (store) => store.faceInfo.selfieUserMediaData;

export const hardLogoutIds = (store) => store.hardLogout.hardLogoutData;

export const hardLogoutUserIds = (store) => store.hardLogout.hardLogoutUserdata;

export const fixedGroupPostSettingIds = (store) =>
  store.fixedGroupPostSetting.occasionFixedGroupPostSettingData;
export const hospitalityGoogleSheetIds = (store) =>
  store.hospitalityGoogleSheet.hospitalityGoogleSheetId;

export const competitionIds = (store) => store.Competition.competitionData;

export const participantIds = (store) => store.Participant.participantData;
export const ticketGoogleSheetIds = (store) =>
  store.ticketGoogleSheet.ticketGoogleSheetId;

export const allWhatsappTemplateModelablesIds = (store) =>
  store.whatsappForProvidedTask.allWhatsappTemplateModelables;

export const allUnansweredWhatsappTemplateModelablesIds = (store) =>
  store.whatsappForProvidedTask.allUnansweredWhatsappTemplateModelables;

export const allProvidedAnsweredUsersIds = (store) =>
  store.whatsappForProvidedTask.allProvidedAnsweredUsers;

export const whatsappMessagesIds = (store) =>
  store.whatsappForProvidedTask.whatsappMessagesData;

export const allProvidedUnansweredUsersIds = (store) =>
  store.whatsappForProvidedTask.allProvidedUnansweredUsers;

export const unansweredWhatsappMessagesIds = (store) =>
  store.whatsappForProvidedTask.unansweredWhatsappMessagesData;

export const whatsappTemplateMessagesIds = (store) =>
  store.whatsappTemplates.whatsappTemplatesData;

export const whatsappTemplateModelableIds = (store) =>
  store.whatsappTemplates.whatsappTemplateModelableData;

export const whatsappTemplateParamsIds = (store) =>
  store.whatsappTemplates.whatsappTemplateParamsData;

export const occasionResourcesIds = (store) =>
  store.occasionResources.occasionResourcesIds;

export const categoryStatusIds = (store) =>
  store.categoryStatus.categoryStatusIds;
