import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED
} from '../constants/actionTypes';

export const forgotPassword = (formData, callback) => ({
  type: FORGOT_PASSWORD,
  payload: { formData },
  callback
});

export const forgotPasswordSuccess = (data, included) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: { data, included }
});

export const forgotPasswordFailed = (error) => ({
  type: FORGOT_PASSWORD_FAILED,
  error
});

export const resetPassword = (formData, callback) => ({
  type: RESET_PASSWORD,
  payload: { formData },
  callback
});

export const resetPasswordSuccess = (data, included) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: { data, included }
});

export const resetPasswordFailed = (error) => ({
  type: RESET_PASSWORD_FAILED,
  error
});
