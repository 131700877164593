import {
  OCCASION_RESOURCES_VIEW,
  OCCASION_RESOURCES_VIEW_SUCCESS,
  OCCASION_RESOURCES_VIEW_FAILED,
  DELETE_OCCASION_RESOURCES,
  DELETE_OCCASION_RESOURCES_FAILED,
  DELETE_OCCASION_RESOURCES_SUCCESS,
  OCCASION_RESOURCES_CREATE,
  OCCASION_RESOURCES_CREATE_FAILED,
  OCCASION_RESOURCES_CREATE_SUCCESS,
  UPDATE_OCCASION_RESOURCES,
  UPDATE_OCCASION_RESOURCES_FAILED,
  UPDATE_OCCASION_RESOURCES_SUCCESS,
  OCCASION_CATEGORY_STATUS_CREATE,
  OCCASION_CATEGORY_STATUS_CREATE_FAILED,
  OCCASION_CATEGORY_STATUS_CREATE_SUCCESS,
  OCCASION_CATEGORY_STATUS_VIEW,
  OCCASION_CATEGORY_STATUS_VIEW_FAILED,
  OCCASION_CATEGORY_STATUS_VIEW_SUCCESS,
  UPDATE_CATEGORY_STATUS,
  UPDATE_CATEGORY_STATUS_FAILED,
  UPDATE_CATEGORY_STATUS_SUCCESS,
  DELETE_CATEGORY_STATUS,
  DELETE_CATEGORY_STATUS_FAILED,
  DELETE_CATEGORY_STATUS_SUCCESS
} from '../constants/actionTypes';

export const occasionCategoryStatusCreate = (ocId, formData, callback) => ({
  type: OCCASION_CATEGORY_STATUS_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionCategoryStatusCreateSuccess = (data, included, ocId) => ({
  type: OCCASION_CATEGORY_STATUS_CREATE_SUCCESS,
  payload: { data, included, ocId }
});

export const occasionCategoryStatusCreateFailed = (error) => ({
  type: OCCASION_CATEGORY_STATUS_CREATE_FAILED,
  error
});

export const occasionCategoryStatusView = (ocId, page) => ({
  type: OCCASION_CATEGORY_STATUS_VIEW,
  payload: { ocId, page }
});

export const occasionCategoryStatusViewSuccess = (
  data,
  included,
  ocId,
  links
) => ({
  type: OCCASION_CATEGORY_STATUS_VIEW_SUCCESS,
  payload: { data, included, ocId, links }
});

export const occasionCategoryStatusViewFailed = (error) => ({
  type: OCCASION_CATEGORY_STATUS_VIEW_FAILED,
  error
});

export const updateCategoryStatus = (
  ocId,
  categoryStatusId,
  formData,
  callback
) => ({
  type: UPDATE_CATEGORY_STATUS,
  payload: { ocId, categoryStatusId, formData },
  callback
});

export const updateCategoryStatusSuccess = (ocId, data, included) => ({
  type: UPDATE_CATEGORY_STATUS_SUCCESS,
  payload: { ocId, data, included }
});

export const updateCategoryStatusFailed = (error) => ({
  type: UPDATE_CATEGORY_STATUS_FAILED,
  error
});

export const deleteCategoryStatus = (ocId, categoryStatusId, callback) => ({
  type: DELETE_CATEGORY_STATUS,
  payload: { ocId, categoryStatusId },
  callback
});

export const deleteCategoryStatusSuccess = (ocId, data) => ({
  type: DELETE_CATEGORY_STATUS_SUCCESS,
  payload: { ocId, data }
});

export const deleteCategoryStatusFailed = (error) => ({
  type: DELETE_CATEGORY_STATUS_FAILED,
  error
});

export const occasionResourcesCreate = (ocId, formData, callback) => ({
  type: OCCASION_RESOURCES_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionResourcesCreateSuccess = (ocId, data) => ({
  type: OCCASION_RESOURCES_CREATE_SUCCESS,
  payload: { ocId, data }
});

export const occasionResourcesCreateFailed = (error) => ({
  type: OCCASION_RESOURCES_CREATE_FAILED,
  error
});

export const updateOccasionResources = (
  ocId,
  ocResourceId,
  formData,
  callback
) => ({
  type: UPDATE_OCCASION_RESOURCES,
  payload: { ocId, ocResourceId, formData },
  callback
});

export const updateOccasionResourcesSuccess = (ocId, data) => ({
  type: UPDATE_OCCASION_RESOURCES_SUCCESS,
  payload: { ocId, data }
});

export const updateOccasionResourcesFailed = (error) => ({
  type: UPDATE_OCCASION_RESOURCES_FAILED,
  error
});

export const occasionResourcesView = (ocId, page) => ({
  type: OCCASION_RESOURCES_VIEW,
  payload: { ocId, page }
});

export const occasionResourcesViewSuccess = (ocId, data, links) => ({
  type: OCCASION_RESOURCES_VIEW_SUCCESS,
  payload: { ocId, data, links }
});

export const occasionResourcesViewFailed = (error) => ({
  type: OCCASION_RESOURCES_VIEW_FAILED,
  error
});

export const deleteOccasionResources = (ocId, ocResourceId, callback) => ({
  type: DELETE_OCCASION_RESOURCES,
  payload: { ocId, ocResourceId },
  callback
});

export const deleteOccasionResourcesSuccess = (ocId, data) => ({
  type: DELETE_OCCASION_RESOURCES_SUCCESS,
  payload: { ocId, data }
});

export const deleteOccasionResourcesFailed = (error) => ({
  type: DELETE_OCCASION_RESOURCES_FAILED,
  error
});
