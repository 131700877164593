const validate = (values, id) => {
  const errors = {};
  if (!id) {
    if (!values?.searchData?.id || !values?.searchData?.value)
      errors.searchData = 'Select a valid occasion from the list';

    if (!values?.accessType)
      errors.accessType = 'Select a valid access type from the list';
  }
  return errors;
};

export default validate;
