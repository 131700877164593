import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button, AutoComplete } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup';
import { getImageSource } from '../../../../../../helpers/imageHelper';
import { Image } from '../../../../../../components/Image';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { occasionGroupView } from '../../../../../../actions/group';
import { getOccasionGroupsWithAllData } from '../../../../../../selectors/group';
import { OCCASION_RESOURCES_STATUS } from '../constants';
import { currentCategoryStatusData } from '../../../../../../selectors/occasionResources';
import {
  occasionCategoryStatusCreate,
  updateCategoryStatus
} from '../../../../../../actions/occasionResources';

const validateCategoryStatusData = (values) => {
  const errors = {};
  if (!values.categoryName) {
    errors.categoryName = ' Category Name is required';
  }

  return errors;
};

const { Option } = AutoComplete;

function CreateCategoryStatus(props) {
  const [categoryStatusData, setCategorySTatusData] = React.useState({
    categoryName: props.currentCategoryStatus?.categoryName || '',
    status: props.categoryStatusId
      ? props.currentCategoryStatus?.isActive == true
        ? true
        : false
      : true,
    group:
      props.currentCategoryStatus?.groups?.length > 0
        ? props.currentCategoryStatus?.groups.map((item) => item.id)
        : []
  });
  const [errorMessages, setErrorMessages] = React.useState({});
  const [page, setPage] = React.useState(1);

  const onPageChange = (page) => {
    props.occasionGroupView(props.occasionId, page);
  };

  React.useEffect(() => {
    onPageChange(page);
  }, [page]);

  const getPage = (str) => {
    const decodedURI = decodeURI(str);
    const number = decodedURI
      .split('page[number]')[1]
      .replace('=', '')
      .split('&');
    return (number && parseInt(number)) || 1;
  };

  const onEndReached = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height >= scrollHeight - 20) {
      if (props.allGroupsLinks?.next) {
        const page = getPage(props.allGroupsLinks?.next);
        setPage(page);
      }
    }
  };

  const handleSubmit = () => {
    try {
      const validateObj = validateCategoryStatusData(categoryStatusData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append(
          'occasion_resource_category_status[category_name]',
          categoryStatusData.categoryName
        );

        formData.append(
          'occasion_resource_category_status[is_active]',
          categoryStatusData.status
        );

        if (categoryStatusData?.group.length > 0) {
          categoryStatusData?.group.forEach((id) => {
            formData.append(
              'occasion_resource_category_status[group_ids][]',
              id
            );
          });
        }
        props.categoryStatusId
          ? props.updateCategoryStatus(
              props.occasionId,
              props.categoryStatusId,
              formData
            )
          : props.occasionCategoryStatusCreate(
              props.occasionId,
              formData,
              () => {
                setCategorySTatusData({
                  categoryName: '',
                  status: true,
                  group: []
                });
              }
            );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    try {
      const Value = e?.target?.value;
      const inputName = e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setCategorySTatusData({
        ...categoryStatusData,
        [inputName]: Value
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="category-status-create-section">
        <div className="category-status-create-form">
          <Form className="category-status-form" onFinish={handleSubmit}>
            <InputGroup
              label="Category Name"
              name="categoryName"
              placeholder="Enter Category Name"
              onChange={handleInputChange}
              value={categoryStatusData?.categoryName}
              errorMessage={errorMessages.categoryName}
            />
            <InputGroup
              fieldType="radioGroup"
              options={OCCASION_RESOURCES_STATUS}
              name="status"
              label="Status"
              onChange={handleInputChange}
              value={categoryStatusData?.status}
            />
            <InputGroup
              fieldType="dropdown"
              className="group-search"
              mode="multiple"
              name="group"
              label="Select Group"
              placeholder="Select Group"
              onPopupScroll={onEndReached}
              onChange={handleInputChange}
              value={categoryStatusData?.group}>
              {props.groupData?.length > 0 &&
                props.groupData.map((item) => {
                  return (
                    <Option value={item?.id} key={item?.id}>
                      <div className="options-container">
                        <p className="avatar-image-container">
                          <Image
                            src={getImageSource(item?.groupPictureUrl)}
                            preview={false}
                          />
                        </p>
                        <div className="occasion-group-details-container">
                          <p className="occasion-group-name">{item?.name}</p>
                        </div>
                      </div>
                    </Option>
                  );
                })}
            </InputGroup>

            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.categoryStatusId ? 'Save Changes' : 'Create'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  groupData: getOccasionGroupsWithAllData(store, props),
  currentCategoryStatus: currentCategoryStatusData(store, props),
  allGroupsLinks: store.group.groupLinks
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { occasionGroupView, occasionCategoryStatusCreate, updateCategoryStatus },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCategoryStatus);
