import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../../components/Section';
import '../styles.scss';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import Tabs from '../../../../../components/Tabs';
import { TabsHeader } from '../../../../../components/TabsHeader';
import WhatsappTemplateParamsView from './WhatsAppTemplateParams/view';
import {
  getCurrentWhatsappTemplatesModelable,
  getCurrentWhatsappTemplatesWithOccasionData
} from '../../../../../selectors/whatsappTemplates';
import {
  currentWhatsappTemplatesId,
  currentWhatsappTemplateModelableId
} from '../../../../../selectors/dynamicData';

function WhatsappTemplatesModelableDetails(props) {
  const getWhatsAppTemplateParamsComponent = () => {
    return (
      <WhatsappTemplateParamsView
        whatsappTemplatesId={props.whatsappTemplatesMessageId}
        whatsappTemplateModelableId={props.whatsappTemplateModelableId}
      />
    );
  };

  const tabPanes = [
    {
      tabName: 'WhatsApp Template Params',
      tabKey: '1',
      children: getWhatsAppTemplateParamsComponent()
    }
  ];
  const goBack = () => {
    props.history.goBack();
  };

  return (
    <ErrorBoundary>
      <SectionContent className="whatsapp-templates-tab-section">
        <TabsHeader
          name={props.WhatsappTemplatesMessageData.elementName}
          extraData={[
            props.currentWhatsappTemplatesModelableData.operationType
          ]}
          wrapperClass="section-details"
        />
        <div className="whatsapp-templates-tab-container">
          <Tabs
            defaultActiveKey="1"
            tabPanes={tabPanes}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentWhatsappTemplatesModelableData: getCurrentWhatsappTemplatesModelable(
    store,
    props
  ),
  WhatsappTemplatesMessageData: getCurrentWhatsappTemplatesWithOccasionData(
    store,
    props
  ),
  whatsappTemplatesMessageId: currentWhatsappTemplatesId(store, props),
  whatsappTemplateModelableId: currentWhatsappTemplateModelableId(store, props)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WhatsappTemplatesModelableDetails);
