import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button, AutoComplete } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup';
import { getImageSource } from '../../../../../../helpers/imageHelper';
import { Image } from '../../../../../../components/Image';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { occasionGroupView } from '../../../../../../actions/group';
import { getOccasionGroupsWithAllData } from '../../../../../../selectors/group';
import { getUserByOccasion } from '../../../../../../actions/user';
import { getCompleteUserData } from '../../../../../../selectors/user';
import { getCurrentParticipantWithUserData } from '../../../../../../selectors/participant';

import {
  participantCreate,
  participantCreateBycsv,
  updateParticipant
} from '../../../../../../actions/participant';

const validateParticipant = (values) => {
  const errors = {};
  if (values.group?.length === 0) {
    errors.group = 'Group is required';
  }
  if (!values.score) {
    errors.score = 'Score is required';
  }
  if (!!values.toggle && !values.csv_file) {
    errors.csv_file = 'A CSV file is required';
  }
  return errors;
};

const { Option } = AutoComplete;

function CreateParticipant(props) {
  const [participantData, setParticipantData] = React.useState({
    category: props.currentParticipantData?.category || '',
    score: props.currentParticipantData?.score || '',
    participant_type: props.currentParticipantData?.participantType || '',
    user: props.currentParticipantData?.user?.id
      ? `${props.currentParticipantData?.user?.id}`
      : '',
    group: props.currentParticipantData?.group?.id
      ? `${props.currentParticipantData?.group?.id}`
      : '',
    toggle: false,
    csv_file: null
  });
  const [errorMessages, setErrorMessages] = React.useState({});
  const [page, setPage] = React.useState(1);

  const onPageChange = (page) => {
    props.occasionGroupView(props.occasionId, page);
    props.getUserByOccasion(props.occasionId, page);
  };

  React.useEffect(() => {
    onPageChange(page);
  }, [page]);

  const getPage = (str) => {
    const decodedURI = decodeURI(str);
    const number = decodedURI
      .split('page[number]')[1]
      .replace('=', '')
      .split('&');
    return (number && parseInt(number)) || 1;
  };

  const onEndReachedForUser = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height >= scrollHeight - 20) {
      if (props.usersLinks?.next) {
        const page = getPage(props.usersLinks?.next);
        setPage(page);
      }
    }
  };

  const onEndReached = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height >= scrollHeight - 20) {
      if (props.allGroupsLinks?.next) {
        const page = getPage(props.allGroupsLinks?.next);
        setPage(page);
      }
    }
  };

  const handleSubmit = () => {
    try {
      const validateObj = validateParticipant(participantData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        if (!participantData.toggle) {
          formData.append('participant[category]', participantData.category);
          formData.append(
            'participant[participant_type]',
            participantData.participant_type
          );
          formData.append('participant[score]', participantData.score);
          formData.append('participant[group_id]', participantData.group);
          formData.append('participant[user_id]', participantData.user);
        } else {
          formData.append(
            'csv_file',
            participantData.csv_file[0].originFileObj
          );
        }
        !!participantData?.toggle
          ? props.participantCreateBycsv(
              props.occasionId,
              props.competitionId,
              participantData.group,
              formData,
              () => {
                setParticipantData({
                  category: '',
                  score: '',
                  participant_type: '',
                  user: '',
                  group: '',
                  toggle: false,
                  csv_file: null
                });
              }
            )
          : props.participantId
          ? props.updateParticipant(
              props.occasionId,
              props.competitionId,
              props.participantId,
              formData
            )
          : props.participantCreate(
              props.occasionId,
              props.competitionId,
              formData,
              () => {
                setParticipantData({
                  category: '',
                  score: '',
                  participant_type: '',
                  user: '',
                  group: '',
                  toggle: false,
                  csv_file: null
                });
              }
            );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'csv_file') {
        value = e.fileList;
        name = type;
      }
      if (type === 'toggle') {
        value = e;
        name = type;
      }

      const participantValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setParticipantData({
        ...participantData,
        [inputName]: participantValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="participant-create-section">
        <div className="participant-create-form">
          <Form className="participant-form" onFinish={handleSubmit}>
            <InputGroup
              fieldType="switch"
              name="toggle"
              label="Toggle to upload CSV"
              onChange={handleInputChange}
            />
            {participantData.toggle && (
              <InputGroup
                fieldType="file"
                accept=".csv"
                name="csv_file"
                label="CSV file"
                fileList={participantData?.csv_file}
                errorMessage={errorMessages.csv_file}
                onChange={handleInputChange}
              />
            )}

            {!participantData.toggle && (
              <InputGroup
                label="Category"
                name="category"
                placeholder="Enter Category"
                onChange={handleInputChange}
                value={participantData?.category}
              />
            )}
            {!participantData.toggle && (
              <InputGroup
                label="Score"
                name="score"
                placeholder="Enter Score"
                onChange={handleInputChange}
                value={participantData?.score}
                errorMessage={errorMessages.score}
              />
            )}
            {!participantData.toggle && (
              <InputGroup
                label="Participant Type"
                name="participant_type"
                placeholder="Enter Participant Type"
                onChange={handleInputChange}
                value={participantData?.participant_type}
              />
            )}
            {!props.participantId && (
              <InputGroup
                fieldType="dropdown"
                className="group-search"
                mode="single"
                name="group"
                label="Select Group"
                placeholder="Select Group"
                onPopupScroll={onEndReached}
                onChange={handleInputChange}
                errorMessage={errorMessages?.group}
                value={participantData?.group}>
                {props.groupData?.length > 0 &&
                  props.groupData.map((item) => {
                    return (
                      <Option value={item?.id} key={item?.id}>
                        <div className="options-container">
                          <p className="avatar-image-container">
                            <Image
                              src={getImageSource(item?.groupPictureUrl)}
                              preview={false}
                            />
                          </p>
                          <div className="occasion-group-details-container">
                            <p className="occasion-group-name">{item?.name}</p>
                          </div>
                        </div>
                      </Option>
                    );
                  })}
              </InputGroup>
            )}
            {!props.participantId && !participantData.toggle && (
              <InputGroup
                fieldType="dropdown"
                className="user-search"
                mode="single"
                name="user"
                label="Select User"
                placeholder="Select User"
                onPopupScroll={onEndReachedForUser}
                onChange={handleInputChange}
                value={participantData?.user}>
                {props.userData?.length > 0 &&
                  props.userData.map((item) => {
                    return (
                      <Option value={item?.id} key={item?.id}>
                        <div className="options-container">
                          <p className="avatar-image-container">
                            <Image
                              src={getImageSource(item?.groupPictureUrl)}
                              preview={false}
                            />
                          </p>
                          <div className="occasion-group-details-container">
                            <p className="occasion-group-name">{item?.name}</p>
                          </div>
                        </div>
                      </Option>
                    );
                  })}
              </InputGroup>
            )}

            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.participantId ? 'Save Changes' : 'Create'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  groupData: getOccasionGroupsWithAllData(store, props),
  userData: getCompleteUserData(store, props),
  usersLinks: store.user.links,
  currentParticipantData: getCurrentParticipantWithUserData(store, props),
  allGroupsLinks: store.group.groupLinks
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      occasionGroupView,
      getUserByOccasion,
      participantCreate,
      participantCreateBycsv,
      updateParticipant
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateParticipant);
