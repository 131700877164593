import request from './request';

export function occasionAlarmCreateApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/alarm/alarms`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function occasionAlarmViewApi(ocId, page, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/alarm/alarms?page=${page}`, {
    method: 'GET',
    token
  });
}

export function deleteAlarmApi(ocId, alarmId, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/alarm/alarms/${alarmId}`, {
    method: 'DELETE',
    token
  });
}

export function updateAlarmApi(ocId, alarmId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/alarm/alarms/${alarmId}`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}
