import {
  OCCASION_SCHEDULE_CREATE,
  OCCASION_SCHEDULE_CREATE_SUCCESS,
  OCCASION_SCHEDULE_CREATE_FAILED,
  OCCASION_SCHEDULE_VIEW,
  OCCASION_SCHEDULE_VIEW_SUCCESS,
  OCCASION_SCHEDULE_VIEW_FAILED,
  OCCASION_SCHEDULE_UPDATE,
  OCCASION_SCHEDULE_UPDATE_FAILED,
  OCCASION_SCHEDULE_UPDATE_SUCCESS,
  OCCASION_SCHEDULE_DELETE,
  OCCASION_SCHEDULE_DELETE_FAILED,
  OCCASION_SCHEDULE_DELETE_SUCCESS
} from '../constants/actionTypes';

export const occasionScheduleCreate = (ocId, formData, callback) => ({
  type: OCCASION_SCHEDULE_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionScheduleCreateSuccess = (data, ocId, included, links) => ({
  type: OCCASION_SCHEDULE_CREATE_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionScheduleCreateFailed = (error) => ({
  type: OCCASION_SCHEDULE_CREATE_FAILED,
  error
});

export const occasionScheduleView = (ocId, scheduleType, page) => ({
  type: OCCASION_SCHEDULE_VIEW,
  payload: { ocId, scheduleType, page }
});

export const occasionScheduleViewSuccess = (data, ocId, included, links) => ({
  type: OCCASION_SCHEDULE_VIEW_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionScheduleViewFailed = (error) => ({
  type: OCCASION_SCHEDULE_VIEW_FAILED,
  error
});

export const occasionScheduleUpdate = (
  ocId,
  scheduleId,
  formData,
  callback
) => ({
  type: OCCASION_SCHEDULE_UPDATE,
  payload: { ocId, scheduleId, formData },
  callback
});

export const occasionScheduleUpdateSuccess = (data, ocId, included, links) => ({
  type: OCCASION_SCHEDULE_UPDATE_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionScheduleUpdateFailed = (error) => ({
  type: OCCASION_SCHEDULE_UPDATE_FAILED,
  error
});

export const occasionScheduleDelete = (ocId, scheduleId) => ({
  type: OCCASION_SCHEDULE_DELETE,
  payload: { ocId, scheduleId }
});

export const occasionScheduleDeleteSuccess = (data, ocId, included, links) => ({
  type: OCCASION_SCHEDULE_DELETE_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionScheduleDeleteFailed = (error) => ({
  type: OCCASION_SCHEDULE_DELETE_FAILED,
  error
});
