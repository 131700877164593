import {
  OCCASION_DIVISION_CREATE,
  OCCASION_DIVISION_CREATE_FAILED,
  OCCASION_DIVISION_CREATE_SUCCESS,
  OCCASION_DIVISION_VIEW,
  OCCASION_DIVISION_VIEW_FAILED,
  OCCASION_DIVISION_VIEW_SUCCESS
} from '../constants/actionTypes';

export const occasionDivisionCreate = (ocId, formData, callback) => ({
  type: OCCASION_DIVISION_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionDivisionCreateSuccess = (data, ocId, included, links) => ({
  type: OCCASION_DIVISION_CREATE_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionDivisionCreateFailed = (error) => ({
  type: OCCASION_DIVISION_CREATE_FAILED,
  error
});

export const occasionDivisionView = (ocId, page) => ({
  type: OCCASION_DIVISION_VIEW,
  payload: { ocId, page }
});

export const occasionDivisionViewSuccess = (data, ocId, included, links) => ({
  type: OCCASION_DIVISION_VIEW_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionDivisionViewFailed = (error) => ({
  type: OCCASION_DIVISION_VIEW_FAILED,
  error
});
