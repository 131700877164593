export const ANSWER_TYPE = [
  { label: 'Document', value: 'document' },
  { label: 'Text', value: 'text' },
  { label: 'Number', value: 'number' },
  { label: 'DateTime', value: 'datetime' },
  { label: 'MCQ', value: 'mcq' },
  { label: 'SCQ', value: 'scq' }
];

export const NO_OF_OPTIONS = [
  { label: '2(Two)', value: '2' },
  { label: '3(Three)', value: '3' },
  { label: '4(Four)', value: '4' },
  { label: '5(Five)', value: '5' },
  { label: '6(Six)', value: '6' }
];
