import { createSelector } from 'reselect';
import { currentOccasionId, currentTicketId } from './dynamicData';
import { ticketEntities, occasionEventsEntities } from './entity';
import { ticketIds } from './static';

export const currentOccasionTicketIds = createSelector(
  currentOccasionId,
  ticketIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getAllOccasionTickets = createSelector(
  ticketEntities,
  currentOccasionTicketIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
export const occasionTicketWithEventData = createSelector(
  getAllOccasionTickets,
  occasionEventsEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      events: [...item?.events?.map((el) => entities[el?.id])]
    }))
);

export const getCurrentTicket = createSelector(
  currentTicketId,
  ticketEntities,
  (ocId, entities) => ocId && entities && entities[ocId]
);
