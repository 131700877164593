import { useCallback } from 'react';

const useLinkDownloader = () => {
  const downloadLinkFile = useCallback((url, fileName, extension) => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `${fileName}.${extension}`;
    link.href = url;
    link.click();
    link.remove();
  }, []);

  return {
    downloadLinkFile
  };
};

export default useLinkDownloader;
