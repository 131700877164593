import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import { Button, Tooltip } from 'antd';
import {
  faceInfoUserView,
  regenerateFaceInfo
} from '../../../../../../actions/faceInfo';
import { getAllFaceInfoUsers } from '../../../../../../selectors/faceInfo';

const ViewFaceInfoUser = (props) => {
  const [page, setPage] = React.useState(1);

  const onPageChange = (pageNumber) => {
    setPage(pageNumber);
    props.faceInfoUserView(props.occasionId, 'User', pageNumber);
  };

  const location = useLocation();

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleAction = (id) => {
    props.regenerateFaceInfo(props.occasionId, 'User', id);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="face-info-user-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <div className="count-box">
          <span>Total User</span>
          <span className="count">{props.count}</span>
        </div>
      </div>
    )
  };

  const customAction = (id) => {
    return (
      <Tooltip placement="right" title={'Regenerate Face Info'}>
        <Button className="action-button" onClick={() => handleAction(id)}>
          <SmileOutlined />
        </Button>
      </Tooltip>
    );
  };

  return (
    <ErrorBoundary>
      <SectionContent className="face-info-user-view-section">
        <div className="face-info-user-view-container">
          <TableView
            isPaginated={true}
            pathname={`${location.pathname}/user/view`}
            paginationOptions={paginationOptions}
            isRouted={true}
            data={props?.userData}
            onPageChange={onPageChange}
            fetchProgress={props?.fetchProgress}
            paginationLinks={props?.userLinks}
            customAction={customAction}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  userData: getAllFaceInfoUsers(store, props),
  userLinks: store.faceInfo.faceInfoUserLinks,
  count: store.faceInfo.faceInfoUserCount
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ faceInfoUserView, regenerateFaceInfo }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(ViewFaceInfoUser);
