import { union } from 'lodash';
import {
  OCCASION_TICKET_VARIANT_VIEW_SUCCESS,
  OCCASION_TICKET_VARIANT_VIEW_FAILED,
  OCCASION_TICKET_VARIANT_CREATE_SUCCESS,
  OCCASION_TICKET_VARIANT_CREATE_FAILED
} from '../constants/actionTypes';

const initialState = {
  occasionTicketVariantData: null,
  error: null,
  ticketVariantLinks: null
};

const ticketVariant = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_TICKET_VARIANT_VIEW_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.occasionTicketVariantData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];
      return {
        ...state,
        occasionTicketVariantData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        ticketVariantLinks: links
      };
    }

    case OCCASION_TICKET_VARIANT_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionTicketVariantData || {};
      const currentOccasionTicketVariantIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionTicketVariantIds];
      return {
        ...state,
        occasionTicketVariantData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }

    case OCCASION_TICKET_VARIANT_VIEW_FAILED:
    case OCCASION_TICKET_VARIANT_CREATE_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default ticketVariant;
