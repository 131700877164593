import {
  GET_HOSPITALITY_GOOGLE_SHEET,
  GET_HOSPITALITY_GOOGLE_SHEET_FAILED,
  GET_HOSPITALITY_GOOGLE_SHEET_SUCCESS,
  HOSPITALITY_GOOGLE_SHEET_CREATE,
  HOSPITALITY_GOOGLE_SHEET_CREATE_FAILED,
  HOSPITALITY_GOOGLE_SHEET_CREATE_SUCCESS,
  HOSPITALITY_GOOGLE_SHEET_REGENERATE,
  HOSPITALITY_GOOGLE_SHEET_REGENERATE_FAILED,
  HOSPITALITY_GOOGLE_SHEET_REGENERATE_SUCCESS,
  HOSPITALITY_GOOGLE_SHEET_SYNC,
  HOSPITALITY_GOOGLE_SHEET_SYNC_FAILED,
  HOSPITALITY_GOOGLE_SHEET_SYNC_SUCCESS,
  DELETE_HOSPITALITY_GOOGLE_SHEET,
  DELETE_HOSPITALITY_GOOGLE_SHEET_FAILED,
  DELETE_HOSPITALITY_GOOGLE_SHEET_SUCCESS
} from '../constants/actionTypes';

export const getHospitalityGoogleSheet = (ocId, page) => ({
  type: GET_HOSPITALITY_GOOGLE_SHEET,
  payload: { ocId, page }
});

export const getHospitalityGoogleSheetSuccess = (
  data,
  included,
  links,
  ocId
) => ({
  type: GET_HOSPITALITY_GOOGLE_SHEET_SUCCESS,
  payload: { data, included, links, ocId }
});

export const getHospitalityGoogleSheetFailed = (error) => ({
  type: GET_HOSPITALITY_GOOGLE_SHEET_FAILED,
  error
});

export const hospitalityGoogleSheetCreate = (ocId, callback) => ({
  type: HOSPITALITY_GOOGLE_SHEET_CREATE,
  payload: { ocId },
  callback
});

export const hospitalityGoogleSheetCreateSuccess = (data, ocId) => ({
  type: HOSPITALITY_GOOGLE_SHEET_CREATE_SUCCESS,
  payload: { data, ocId }
});

export const hospitalityGoogleSheetCreateFailed = (error) => ({
  type: HOSPITALITY_GOOGLE_SHEET_CREATE_FAILED,
  error
});

export const hospitalityGoogleSheetRegenerate = (ocId, callback) => ({
  type: HOSPITALITY_GOOGLE_SHEET_REGENERATE,
  payload: { ocId },
  callback
});

export const hospitalityGoogleSheetRegenerateSuccess = (data, ocId) => ({
  type: HOSPITALITY_GOOGLE_SHEET_REGENERATE_SUCCESS,
  payload: { data, ocId }
});

export const hospitalityGoogleSheetRegenerateFailed = (error) => ({
  type: HOSPITALITY_GOOGLE_SHEET_REGENERATE_FAILED,
  error
});

export const hospitalityGoogleSheetSync = (ocId, callback) => ({
  type: HOSPITALITY_GOOGLE_SHEET_SYNC,
  payload: { ocId },
  callback
});

export const hospitalityGoogleSheetSyncSuccess = (data, ocId) => ({
  type: HOSPITALITY_GOOGLE_SHEET_SYNC_SUCCESS,
  payload: { data, ocId }
});

export const hospitalityGoogleSheetSyncFailed = (error) => ({
  type: HOSPITALITY_GOOGLE_SHEET_SYNC_FAILED,
  error
});

export const deleteHospitalityGoogleSheet = (
  ocId,
  hospitalityGoogleSheetId,
  callback
) => ({
  type: DELETE_HOSPITALITY_GOOGLE_SHEET,
  payload: { ocId, hospitalityGoogleSheetId },
  callback
});

export const deleteHospitalityGoogleSheetSuccess = (ocId, data) => ({
  type: DELETE_HOSPITALITY_GOOGLE_SHEET_SUCCESS,
  payload: { ocId, data }
});

export const deleteHospitalityGoogleSheetFailed = (error) => ({
  type: DELETE_HOSPITALITY_GOOGLE_SHEET_FAILED,
  error
});
