import { put, call, takeLatest, debounce } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import { forgotPasswordApi, resetPasswordApi } from '../api/forgotPassword';
import { FORGOT_PASSWORD, RESET_PASSWORD } from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  forgotPasswordSuccess,
  forgotPasswordFailed,
  resetPasswordSuccess,
  resetPasswordFailed
} from '../actions/forgotPassword';

export function* forgotPasswordSaga(action) {
  try {
    const result = yield call(
      forgotPasswordApi,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(forgotPasswordSuccess(result.meta.message, result?.included));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback(result?.meta?.message);
      }
    } else if (result && result.meta) {
      yield put(forgotPasswordFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Some error occured', 'Error', 4000));
    }
  } catch (error) {
    yield put(forgotPasswordFailed(error));

    yield put(requestAutoHideAlert(error, 'Error', 4000));
  }
}

export function* resetPasswordSaga(action) {
  try {
    const result = yield call(
      resetPasswordApi,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(resetPasswordSuccess(result.meta.message, result?.included));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback(true);
      }
    } else if (result && result.meta) {
      yield put(resetPasswordFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Some error occured', 'Error', 4000));
    }
  } catch (error) {
    yield put(resetPasswordFailed(error));

    yield put(requestAutoHideAlert(error, 'Error', 4000));
    if (typeof action.callback === 'function') {
      action.callback(false);
    }
  }
}

export default function* forgotPasswordActionWatcher() {
  yield takeLatest(FORGOT_PASSWORD, progressSaga, forgotPasswordSaga);
  yield takeLatest(RESET_PASSWORD, progressSaga, resetPasswordSaga);
}
