import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import { guestViewApi } from '../api/guest';
import { GUEST_VIEW } from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import { guestViewFailed, guestViewSuccess } from '../actions/guest';

export function* guestViewSaga(action) {
  try {
    const result = yield call(
      guestViewApi,
      action.payload.ocId,
      action.payload.userId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        guestViewSuccess(
          result.data,
          result.included,
          result.links,
          action.payload.userId
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(guestViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* guestActionWatcher() {
  yield takeLatest(GUEST_VIEW, progressSaga, guestViewSaga);
}
