import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import moment from 'moment';
import { Form, Button, AutoComplete, Image } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import {
  blackListUserCreateByCsv,
  blackListUserCreate
} from '../../../../../../actions/activityTracker';
import { searchUserByOccasion } from '../../../../../../actions/activityTrackerPresentUser';
import { getSearchedUsersByOccasion } from '../../../../../../selectors/activityTrackerPresentUser';
import { getImageSource } from '../../../../../../helpers/imageHelper';

const { Option } = AutoComplete;

const validateSosData = (values) => {
  const errors = {};
  if (values.toggle) {
    if (!values.csv) {
      errors.csv = 'A CSV file is required';
    }
  }
  if (!values.searchData.value) {
    errors.searchData = 'User is required';
  }

  return errors;
};

const BlackListCreate = (props) => {
  const [blackListUserData, setBlackListUserData] = useState({
    searchData: { value: '', id: null },
    toggle: false,
    csv: null
  });
  const [errorMessages, setErrorMessages] = useState({});
  const handleSubmit = () => {
    try {
      const validateObj = validateSosData(blackListUserData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        if (!blackListUserData.toggle) {
          formData.append(
            'activity_tracker_black_list[user_id]',
            blackListUserData.searchData.id
          );
        }

        if (blackListUserData.toggle) {
          if (blackListUserData.csv[0]) {
            formData.append(
              'csv_file',
              blackListUserData.csv[0]?.originFileObj
            );
          }
        }

        blackListUserData.toggle
          ? props.blackListUserCreateByCsv(props.occasionId, formData, () => {
              setBlackListUserData({
                searchData: { value: '', id: null },
                toggle: false,
                csv: null
              });
            })
          : props.blackListUserCreate(props.occasionId, formData, () => {
              setBlackListUserData({
                searchData: { value: '', id: null },
                toggle: false,
                csv: null
              });
            });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let formValue;
      let inputName;

      if (type === 'csv') {
        value = e.fileList;
        name = type;
      }
      if (type === 'toggle') {
        value = e;
        name = type;
      }

      if (type === 'searchData') {
        value = {
          value: e?.value,
          id: e?.key
        };
        name = type;
      }

      formValue = value || e?.target?.value;
      inputName = name || e?.target?.name;
      setBlackListUserData({
        ...blackListUserData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = (val) => {
    setBlackListUserData({
      ...blackListUserData,
      searchData: { value: val, id: null }
    });
    if (val.length > 0) {
      props.searchUserByOccasion(props.occasionId, val);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="activity-tracker-black-list-create-section">
        <div className="activity-tracker-black-list-create-form">
          <Form
            className="activity-tracker-black-list-form"
            onFinish={handleSubmit}>
            <InputGroup
              fieldType="switch"
              name="toggle"
              label="Toggle to upload CSV"
              onChange={handleInputChange}
            />
            {blackListUserData.toggle ? (
              <InputGroup
                fieldType="file"
                accept=".csv"
                name="csv"
                label="CSV file"
                fileList={blackListUserData?.csv}
                onChange={handleInputChange}
                errorMessage={errorMessages?.csv}
              />
            ) : (
              <InputGroup
                fieldType="searchbox"
                className="user-search"
                name="searchData"
                label="Search User"
                loading={props.fetchProgress}
                notFoundContent="No Data"
                errorMessage={errorMessages?.searchData}
                value={blackListUserData?.searchData?.value}
                onSelect={(e, option) =>
                  handleInputChange(option, 'searchData')
                }
                onSearch={handleSearch}
                placeholder="Search User">
                {props.searchUserByOccasionData?.length > 0 &&
                  blackListUserData?.searchData?.value?.length > 0 &&
                  props.searchUserByOccasionData.map((item) => {
                    return (
                      <Option value={item?.name} key={item?.id}>
                        <div className="options-container">
                          <p className="avatar-image-container">
                            <Image
                              src={getImageSource(item?.avatarUrl, null, false)}
                              preview={false}
                            />
                          </p>
                          <div className="internal-member-occasion-details-container">
                            <p className="internal-member-occasion-name">
                              {item?.name}
                            </p>
                            <p className="internal-member-occasion-type">
                              {item?.email}
                            </p>
                          </div>
                        </div>
                      </Option>
                    );
                  })}
              </InputGroup>
            )}

            <SubmitButton
              buttonText="Create"
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  searchUserByOccasionData: getSearchedUsersByOccasion(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { searchUserByOccasion, blackListUserCreateByCsv, blackListUserCreate },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BlackListCreate);
