import { createSelector } from 'reselect';
import {
  whatsappTemplateMessagesEntities,
  occasionEntities,
  whatsappTemplateModelablesEntities,
  hospitalityTaskEntities,
  whatsappTemplateParamsEntities
} from './entity';
import {
  whatsappTemplateMessagesIds,
  whatsappTemplateModelableIds,
  whatsappTemplateParamsIds
} from './static';
import {
  currentWhatsappTemplatesId,
  currentWhatsappTemplateModelableId,
  currentWhatsappTemplateParamsId
} from './dynamicData';

export const getWhatsappTemplatesData = createSelector(
  whatsappTemplateMessagesEntities,
  whatsappTemplateMessagesIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getWhatsappTemplatesWithOccasionData = createSelector(
  getWhatsappTemplatesData,
  occasionEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      occasion: {
        ...entities[item?.occasion?.id]
      }
    }))
);

export const getCurrentWhatsappTemplates = createSelector(
  currentWhatsappTemplatesId,
  whatsappTemplateMessagesEntities,
  (templateId, entities) => templateId && entities && entities[templateId]
);

export const getCurrentWhatsappTemplatesWithOccasionData = createSelector(
  getCurrentWhatsappTemplates,
  occasionEntities,
  (data, entities) =>
    data &&
    entities && {
      ...data,
      occasion: {
        ...entities[data.occasion?.id]
      }
    }
);

export const currentWhatsappTemplatesModelableIds = createSelector(
  currentWhatsappTemplatesId,
  whatsappTemplateModelableIds,
  (wtId, ids) => wtId && ids && ids[wtId]
);
export const getAllWhatsappTemplatesModelable = createSelector(
  whatsappTemplateModelablesEntities,
  currentWhatsappTemplatesModelableIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
export const getWhatsappTemplatesModelableWithAllData = createSelector(
  getAllWhatsappTemplatesModelable,
  hospitalityTaskEntities,
  occasionEntities,
  (data, taskEntities, ocEntities) => {
    return (
      ((data && taskEntities) || (data && ocEntities)) &&
      data?.length > 0 &&
      data.map((item) => {
        let modelableData = {};
        if (item?.modelable) {
          if (item?.modelable?.type === 'hospitalityTasks') {
            modelableData = { ...taskEntities[item.modelable?.id] };
          } else {
            modelableData = { ...ocEntities[item.modelable?.id] };
          }
        }
        return {
          ...item,
          modelable: modelableData
        };
      })
    );
  }
);

export const getCurrentWhatsappTemplatesModelable = createSelector(
  currentWhatsappTemplateModelableId,
  whatsappTemplateModelablesEntities,
  (id, entities) => id && entities && entities[id]
);

export const currentWhatsappTemplatesParamsIds = createSelector(
  currentWhatsappTemplateModelableId,
  whatsappTemplateParamsIds,
  (wmId, ids) => wmId && ids && ids[wmId]
);
export const getAllWhatsappTemplatesParams = createSelector(
  whatsappTemplateParamsEntities,
  currentWhatsappTemplatesParamsIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const currentWhatsappTemplatesParams = createSelector(
  currentWhatsappTemplateParamsId,
  whatsappTemplateParamsEntities,
  (id, entities) => id && entities && entities[id]
);

export const getAllWhatsappTemplatesParamsData = createSelector(
  getAllWhatsappTemplatesParams,
  (data) => {
    return (
      data &&
      data.map((item) => {
        const QueryArray = [];
        if (Array.isArray(item?.selfModelQueryParams)) {
          if (item?.selfModelQueryParams?.length > 0) {
            item?.selfModelQueryParams?.map((item) => {
              const kstr1 = item.split(',');
              const kstr2 = kstr1[0].split('=>"');
              const kstr3 = kstr2[1].split('"');

              const vstr2 = kstr1[1].split('=>"');
              const vstr3 = vstr2[1].split('"');
              const obj = { key: kstr3[0], value: vstr3[0] };
              return QueryArray.push(obj);
            });
          }
        }
        return { ...item, selfModelQueryParams: QueryArray };
      })
    );
  }
);

export const getCurrentWhatsappTemplatesParams = createSelector(
  currentWhatsappTemplatesParams,
  (data) => {
    const QueryArray = [];
    if (Array.isArray(data?.selfModelQueryParams)) {
      if (data?.selfModelQueryParams?.length > 0) {
        data?.selfModelQueryParams?.map((item) => {
          const kstr1 = item.split(',');
          const kstr2 = kstr1[0].split('=>"');
          const kstr3 = kstr2[1].split('"');

          const vstr2 = kstr1[1].split('=>"');
          const vstr3 = vstr2[1].split('"');
          const obj = { key: kstr3[0], value: vstr3[0] };
          return QueryArray.push(obj);
        });
      }
    }
    return QueryArray.length > 0
      ? { ...data, selfModelQueryParams: QueryArray }
      : data;
  }
);
