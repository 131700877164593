import request from './request';

export function occasionSosCreateApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/save_our_souls`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function occasionSosViewApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/save_our_souls?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function deleteSosApi(ocId, sosId, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/save_our_souls/${sosId}`, {
    method: 'DELETE',
    token
  });
}

export function updateSosApi(ocId, sosId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/save_our_souls/${sosId}`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}
