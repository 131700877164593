import { union } from 'lodash';
import {
  ACTIVITY_TRACKER_ABSENT_USER_VIEW_FAILED,
  ACTIVITY_TRACKER_ABSENT_USER_VIEW_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  activityTrackerAbsentUserData: null,
  activityTrackerAbsentUserLinks: null,
  absentUserCount: null,
  error: null
};

const activityTrackerAbsentUser = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVITY_TRACKER_ABSENT_USER_VIEW_SUCCESS: {
      const { data, trackerId, links, meta } = action.payload;
      const activityTrackerAbsentUserGroupedData =
        state.activityTrackerAbsentUserData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        activityTrackerAbsentUserData: {
          ...activityTrackerAbsentUserGroupedData,
          [trackerId]: [...currentOccasionNewIds]
        },
        activityTrackerAbsentUserLinks: links,
        absentUserCount: meta?.totalCount || 0
      };
    }

    case ACTIVITY_TRACKER_ABSENT_USER_VIEW_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default activityTrackerAbsentUser;
