import {
  OCCASION_IMPORTANT_CONTACT_CREATE,
  OCCASION_IMPORTANT_CONTACT_CREATE_SUCCESS,
  OCCASION_IMPORTANT_CONTACT_CREATE_FAILED,
  OCCASION_IMPORTANT_CONTACT_VIEW,
  OCCASION_IMPORTANT_CONTACT_VIEW_SUCCESS,
  OCCASION_IMPORTANT_CONTACT_VIEW_FAILED,
  OCCASION_IMPORTANT_CONTACT_UPDATE,
  OCCASION_IMPORTANT_CONTACT_UPDATE_SUCCESS,
  OCCASION_IMPORTANT_CONTACT_UPDATE_FAILED,
  OCCASION_IMPORTANT_CONTACT_DELETE,
  OCCASION_IMPORTANT_CONTACT_DELETE_SUCCESS,
  OCCASION_IMPORTANT_CONTACT_DELETE_FAILED
} from '../constants/actionTypes';

export const occasionImportantContactCreate = (ocId, formData, callback) => ({
  type: OCCASION_IMPORTANT_CONTACT_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionImportantContactCreateSuccess = (
  data,
  included,
  ocId
) => ({
  type: OCCASION_IMPORTANT_CONTACT_CREATE_SUCCESS,
  payload: { data, included, ocId }
});

export const occasionImportantContactCreateFailed = (error) => ({
  type: OCCASION_IMPORTANT_CONTACT_CREATE_FAILED,
  error
});

export const occasionImportantContactView = (ocId, page) => ({
  type: OCCASION_IMPORTANT_CONTACT_VIEW,
  payload: { ocId, page }
});

export const occasionImportantContactViewSuccess = (
  data,
  included,
  ocId,
  links
) => ({
  type: OCCASION_IMPORTANT_CONTACT_VIEW_SUCCESS,
  payload: { data, included, ocId, links }
});

export const occasionImportantContactViewFailed = (error) => ({
  type: OCCASION_IMPORTANT_CONTACT_VIEW_FAILED,
  error
});

export const occasionImportantContactUpdate = (
  ocId,
  importantContactId,
  formData,
  callback
) => ({
  type: OCCASION_IMPORTANT_CONTACT_UPDATE,
  payload: { ocId, importantContactId, formData },
  callback
});

export const occasionImportantContactUpdateSuccess = (
  data,
  included,
  ocId
) => ({
  type: OCCASION_IMPORTANT_CONTACT_UPDATE_SUCCESS,
  payload: { data, included, ocId }
});

export const occasionImportantContactUpdateFailed = (error) => ({
  type: OCCASION_IMPORTANT_CONTACT_UPDATE_FAILED,
  error
});

export const occasionImportantContactDelete = (ocId, importantContactId) => ({
  type: OCCASION_IMPORTANT_CONTACT_DELETE,
  payload: { ocId, importantContactId }
});

export const occasionImportantContactDeleteSuccess = (
  data,
  included,
  ocId,
  links
) => ({
  type: OCCASION_IMPORTANT_CONTACT_DELETE_SUCCESS,
  payload: { data, included, ocId, links }
});

export const occasionImportantContactDeleteFailed = (error) => ({
  type: OCCASION_IMPORTANT_CONTACT_DELETE_FAILED,
  error
});
