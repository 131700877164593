import { union } from 'lodash';
import {
  OCCASION_EVENT_VIEW_SUCCESS,
  OCCASION_EVENT_VIEW_FAILED,
  OCCASION_EVENT_CREATE_SUCCESS,
  GET_EVENT_SEARCH_SUCCESS,
  DELETE_EVENT_SUCCESS,
  GET_EVENT_MEMBERS_SUCCESS,
  GET_EVENT_PENDING_INVITATION_SUCCESS,
  GET_EVENT_PENDING_REGISTRATION_SUCCESS,
  GET_EVENT_PENDING_REGISTRATION_FAILED,
  GET_EVENT_PENDING_INVITATION_FAILED,
  EVENT_SCHEDULE_VIEW_SUCCESS,
  EVENT_SCHEDULE_CREATE_SUCCESS,
  EVENT_SCHEDULE_VIEW_FAILED,
  EVENT_SCHEDULE_CREATE_FAILED,
  EVENT_SCHEDULE_DELETE_SUCCESS,
  EVENT_SCHEDULE_DELETE_FAILED,
  EVENT_AFFAIR_VIEW_SUCCESS,
  EVENT_AFFAIR_CREATE_SUCCESS,
  EVENT_AFFAIR_DELETE_SUCCESS,
  EVENT_AFFAIR_VIEW_FAILED,
  EVENT_AFFAIR_DELETE_FAILED,
  EVENT_AFFAIR_CREATE_FAILED,
  GET_EVENT_POST_PAGINATION_SUCCESS,
  CREATE_EVENT_POST_SUCCESS,
  DELETE_EVENT_POST_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  occasionEventData: null,
  occasionEventAllData: null,
  occasionEventLinks: null,
  eventmembers: null,
  eventMemberLinks: null,
  eventPendingInvitation: null,
  eventPendingRegistration: null,
  eventPendingInvitationLinks: null,
  eventPendingRegistrationLinks: null,
  eventScheduleData: null,
  eventScheduleLinks: null,
  eventAffairData: null,
  eventAffairLinks: null,
  eventPostData: null,
  eventPostLinks: null,
  error: null
};

const event = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENT_POST_PAGINATION_SUCCESS: {
      const { ocId, eventId, data, links } = action.payload;
      const occasionGroupedData = state.eventPostData || {};
      const currentEventNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        eventPostData: {
          ...occasionGroupedData,
          [eventId]: [...currentEventNewIds]
        },
        eventPostLinks: links
      };
    }

    case CREATE_EVENT_POST_SUCCESS: {
      const { ocId, eventId, data } = action.payload;
      const occasionGroupedData = state.eventPostData || {};
      const currentEventPostIds = occasionGroupedData[eventId] || [];

      const newIds = [data.id, ...currentEventPostIds];
      return {
        ...state,
        eventPostData: {
          ...occasionGroupedData,
          [eventId]: newIds
        }
      };
    }

    case DELETE_EVENT_POST_SUCCESS: {
      const { ocId, eventId, data } = action.payload;
      const occasionGroupedData = state.eventPostData || {};
      const currentEventPostIds = occasionGroupedData[eventId] || [];

      const filterIds = currentEventPostIds.filter((id) => id !== data.id);
      return {
        ...state,
        eventPostData: {
          ...occasionGroupedData,
          [eventId]: filterIds
        }
      };
    }

    case OCCASION_EVENT_VIEW_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const allGroupedData = state.occasionEventAllData || {};
      const allData = allGroupedData?.[ocId] || [];
      const occasionGroupedData = state.occasionEventData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionEventAllData: {
          ...allGroupedData,
          [ocId]: union([...allData, ...currentOccasionNewIds])
        },
        occasionEventData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        occasionEventLinks: links
      };
    }

    case OCCASION_EVENT_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionEventData || {};
      const currentOccasionEventIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionEventIds];
      return {
        ...state,
        occasionEventData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }
    case GET_EVENT_SEARCH_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.occasionEventData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionEventData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        occasionEventLinks: links
      };
    }

    case DELETE_EVENT_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionEventData || {};
      const allGroupedData = state.occasionEventAllData || {};
      const allData = allGroupedData?.[ocId] || [];
      const currentOccasionEventIds = occasionGroupedData[ocId] || [];
      const filteredIds = currentOccasionEventIds.filter(
        (id) => id !== data.id
      );
      const filteredIdsForAllData = allData.filter((id) => id !== data.id);
      return {
        ...state,
        occasionEventAllData: {
          ...allGroupedData,
          [ocId]: union(filteredIdsForAllData)
        },
        occasionEventData: {
          ...occasionGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case GET_EVENT_MEMBERS_SUCCESS: {
      const { data, eventId, links, included } = action.payload;
      const memberGroupedData = state.eventmembers || {};
      const currentMemberNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        eventmembers: {
          ...memberGroupedData,
          [eventId]: [...currentMemberNewIds]
        },
        eventMemberLinks: links
      };
    }

    case GET_EVENT_PENDING_INVITATION_SUCCESS: {
      const { data, eventId, links } = action.payload;
      const occasionGroupedData = state.eventPendingInvitation || {};
      const NewIds = data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        eventPendingInvitation: {
          ...occasionGroupedData,
          [eventId]: [...NewIds]
        },
        eventPendingInvitationLinks: links
      };
    }

    case GET_EVENT_PENDING_REGISTRATION_SUCCESS: {
      const { data, eventId, links } = action.payload;
      const occasionGroupedData = state.eventPendingRegistration || {};
      const NewIds = data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        eventPendingRegistration: {
          ...occasionGroupedData,
          [eventId]: [...NewIds]
        },
        eventPendingRegistrationLinks: links
      };
    }

    case EVENT_SCHEDULE_VIEW_SUCCESS: {
      const { ocId, eventId, data, links } = action.payload;
      const occasionGroupedData = state.eventScheduleData || {};
      const currentEventNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        eventScheduleData: {
          ...occasionGroupedData,
          [eventId]: [...currentEventNewIds]
        },
        eventScheduleLinks: links
      };
    }

    case EVENT_SCHEDULE_CREATE_SUCCESS: {
      const { ocId, eventId, data } = action.payload;
      const occasionGroupedData = state.eventScheduleData || {};
      const currentEventScheduleIds = occasionGroupedData[eventId] || [];

      const newIds = [data.id, ...currentEventScheduleIds];
      return {
        ...state,
        eventScheduleData: {
          ...occasionGroupedData,
          [eventId]: newIds
        }
      };
    }

    case EVENT_SCHEDULE_DELETE_SUCCESS: {
      const { ocId, eventId, data } = action.payload;
      const occasionGroupedData = state.eventScheduleData || {};
      const currentEventScheduleIds = occasionGroupedData[eventId] || [];

      const filterIds = currentEventScheduleIds.filter((id) => id !== data.id);
      return {
        ...state,
        eventScheduleData: {
          ...occasionGroupedData,
          [eventId]: filterIds
        }
      };
    }

    case EVENT_AFFAIR_VIEW_SUCCESS: {
      const { ocId, eventId, data, links } = action.payload;
      const occasionGroupedData = state.eventAffairData || {};
      const currentEventNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        eventAffairData: {
          ...occasionGroupedData,
          [eventId]: [...currentEventNewIds]
        },
        eventAffairLinks: links
      };
    }

    case EVENT_AFFAIR_CREATE_SUCCESS: {
      const { ocId, eventId, data } = action.payload;
      const occasionGroupedData = state.eventAffairData || {};
      const currentEventAffairIds = occasionGroupedData[eventId] || [];

      const newIds = [data.id, ...currentEventAffairIds];
      return {
        ...state,
        eventAffairData: {
          ...occasionGroupedData,
          [eventId]: newIds
        }
      };
    }

    case EVENT_AFFAIR_DELETE_SUCCESS: {
      const { ocId, eventId, data } = action.payload;
      const occasionGroupedData = state.eventAffairData || {};
      const currentEventAffairIds = occasionGroupedData[eventId] || [];

      const filterIds = currentEventAffairIds.filter((id) => id !== data.id);
      return {
        ...state,
        eventAffairData: {
          ...occasionGroupedData,
          [eventId]: filterIds
        }
      };
    }

    case OCCASION_EVENT_VIEW_FAILED:
    case EVENT_SCHEDULE_DELETE_FAILED:
    case GET_EVENT_PENDING_REGISTRATION_FAILED:
    case GET_EVENT_PENDING_INVITATION_FAILED:
    case OCCASION_EVENT_VIEW_FAILED:
    case EVENT_SCHEDULE_VIEW_FAILED:
    case EVENT_SCHEDULE_CREATE_FAILED:
    case EVENT_AFFAIR_VIEW_FAILED:
    case EVENT_AFFAIR_DELETE_FAILED:
    case EVENT_AFFAIR_CREATE_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default event;
