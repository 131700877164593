import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'antd';
import './styles.scss';

import InputGroup from '../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import Form from 'antd/lib/form/Form';
import {
  occasionEventView,
  getEventSearch
} from '../../../../../../actions/event';
import { MEMBER_CONSTANTS } from '../../../../../../constants/OtherConstants';

function MemberSearch(props) {
  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (type === 'info') {
          value = e.target.value;
          name = type;
        }
        if (type === 'type') {
          value = e.target.value;
          name = type;
        }
        const eventDetailsValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        props.setMemberData({
          ...props.memberData,
          [inputName]: eventDetailsValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Form className="member-search-container" onFinish={props.searchMemberData}>
      <InputGroup
        label="Enter Name/Phone/Email"
        className="member-inputbox"
        fieldType="searchbox"
        placeholder="Name/Phone/Email"
        name="info"
        onChange={handleChange}
        value={props.memberData?.info}
      />
      <InputGroup
        label="Member Type"
        className="member-inputbox"
        fieldType="dropdown"
        placeholder="Select"
        options={MEMBER_CONSTANTS}
        name="type"
        onChange={handleChange}
        value={props.memberData?.type}
      />
      <div className="member-button-container">
        <SubmitButton
          wrapperClass="member-submit-wrapper"
          className="member-submit-inputbox"
          buttonText="Search"
          fetchProgress={props.fetchProgress}
          disabled={props.fetchProgress}
        />
        <Button className="reset-button" onClick={props.resetSearch}>
          Reset
        </Button>
      </div>
    </Form>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      occasionEventView,
      getEventSearch
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MemberSearch);
