import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  createFeatureControlApi,
  getFeatureControlsApi,
  updateFeatureControlApi
} from '../api/featureControl';

import {
  CREATE_FEATURE_CONTROL,
  GET_FEATURE_CONTROLS,
  GET_FEATURE_CONTROLS_FAILED,
  UPDATE_FEATURE_CONTROL
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  getFeatureControlsFailed,
  getFeatureControlsSuccess,
  createFeatureControlFailed,
  createFeatureControlSuccess,
  updateFeatureControlFailed,
  updateFeatureControlSuccess
} from '../actions/featureControls';

export function* createFeatureControlSaga(action) {
  try {
    const result = yield call(
      createFeatureControlApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(createFeatureControlSuccess(result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(createFeatureControlFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Some error occured', 'Error', 4000));
    }
  } catch (error) {
    yield put(createFeatureControlFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updateFeatureControlSaga(action) {
  try {
    const result = yield call(
      updateFeatureControlApi,
      action.payload.ocId,
      action.payload.acId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(updateFeatureControlSuccess(result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateFeatureControlFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Some error occured', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateFeatureControlFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getFeatureControlsSaga(action) {
  try {
    const result = yield call(
      getFeatureControlsApi,
      action.payload.ocId,
      getUserToken()
    );

    if (typeof action.callback === 'function') {
      action.callback();
    }

    if (isValidResult(result)) {
      yield put(getFeatureControlsSuccess(result.data));
      if (action.callback) {
        action.callback();
      }
    } else if (result?.meta && result?.meta?.message) {
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(getFeatureControlsFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* featureControlsActionWatcher() {
  yield takeLatest(
    CREATE_FEATURE_CONTROL,
    progressSaga,
    createFeatureControlSaga
  );
  yield takeLatest(
    UPDATE_FEATURE_CONTROL,
    progressSaga,
    updateFeatureControlSaga
  );
  yield takeLatest(GET_FEATURE_CONTROLS, progressSaga, getFeatureControlsSaga);
}
