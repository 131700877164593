import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button } from 'antd';
import './styles.scss';
import { EVENT_GUEST_TYPES, EVENT_TAG } from './EventConstants';

import InputGroup from '../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import Form from 'antd/lib/form/Form';
import {
  occasionEventView,
  getEventSearch
} from '../../../../../actions/event';

function EventSearch(props) {
  const [getEventDetails, setGetEventDetails] = React.useState({});
  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);
  const [errorMessages, setErrorMessages] = React.useState({});

  const validate = (values, ocStartDate, ocEndDate) => {
    const errors = {};
    if (
      values?.startDate &&
      values?.endDate &&
      values.startDate > values.endDate
    ) {
      errors.endDate = 'End date should be greater then Start date';
    }
    if (
      new Date(ocStartDate).getTime() >
      new Date(values?.startDate?.format('MM/DD/YYYY')).getTime()
    ) {
      errors.startDate = 'Start date must be greater then occasion startAt';
    }
    if (
      new Date(ocEndDate).getTime() <
      new Date(values?.endDate?.format('MM/DD/YYYY')).getTime()
    ) {
      errors.endDate = 'End date must be less then occasion endAt';
    }
    return errors;
  };

  const handlegetEventDetails = (page) => {
    try {
      const validateObj = validate(
        getEventDetails,
        props.startDate,
        props.endDate
      );
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        props.getEventSearch(
          props.ocId,
          getEventDetails?.title,
          getEventDetails?.startDate?.format('DD/MM/YYYY'),
          getEventDetails?.endDate?.format('DD/MM/YYYY'),
          getEventDetails?.guestType,
          getEventDetails?.tag,
          (page = 1)
        );
        setGetEventDetails({});
        setErrorMessages({});
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (isInitiallyDisabled) {
          setInitiallyDisabled(false);
        }
        if (type === 'title') {
          value = e.target.value;
          name = type;
        }
        if (type === 'startDate') {
          value = moment(e, 'DD/MM/YYYY');
          name = type;
        }
        if (type === 'endDate') {
          value = moment(e, 'DD/MM/YYYY');
          name = type;
        }
        if (type === 'guestType') {
          value = e.target.value;
          name = type;
        }
        if (type === 'tag') {
          value = e.target.value;
          name = type;
        }
        const eventDetailsValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        setGetEventDetails({
          ...getEventDetails,
          [inputName]: eventDetailsValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleReset = (page) => {
    setGetEventDetails({});
    setErrorMessages({});
    props.occasionEventView(props.ocId, (page = 1));
  };

  return (
    <Form className="event-search-container" onFinish={handlegetEventDetails}>
      <InputGroup
        label="Title"
        className="event-inputbox"
        fieldType="searchbox"
        placeholder="Enter Title"
        name="title"
        onChange={handleChange}
        value={getEventDetails?.title}
      />
      <InputGroup
        label="Start Date"
        className="event-date-inputbox"
        fieldType="datePicker"
        placeholder="Start Date"
        name="startDate"
        onChange={handleChange}
        value={getEventDetails?.startDate}
        errorMessage={errorMessages.startDate}
      />
      <InputGroup
        label="End Date"
        className="event-date-inputbox"
        fieldType="datePicker"
        placeholder="End Date"
        name="endDate"
        onChange={handleChange}
        value={getEventDetails?.endDate}
        errorMessage={errorMessages.endDate}
      />
      <InputGroup
        label="Guest Type"
        className="event-inputbox"
        fieldType="dropdown"
        placeholder="Select"
        options={EVENT_GUEST_TYPES}
        name="guestType"
        onChange={handleChange}
        value={getEventDetails?.guestType}
      />
      <InputGroup
        label="Tag"
        className="event-inputbox"
        fieldType="dropdown"
        placeholder="Select"
        options={EVENT_TAG}
        name="tag"
        onChange={handleChange}
        value={getEventDetails?.tag}
      />
      <div style={{ flex: 1, display: 'flex' }}>
        <SubmitButton
          wrapperClass="event-submit-wrapper"
          className="event-submit-inputbox"
          buttonText="Search"
          fetchProgress={props.fetchProgress}
          disabled={props.fetchProgress}
        />
        <Button className="reset-button" onClick={handleReset}>
          Reset
        </Button>
      </div>
    </Form>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      occasionEventView,
      getEventSearch
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EventSearch);
