import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  deleteRoleNameApi,
  occasionRoleNameCreateApi,
  occasionRoleNameViewApi,
  updateRoleNameApi
} from '../api/roleName';
import {
  DELETE_ROLE_NAME,
  OCCASION_ROLE_NAME_CREATE,
  OCCASION_ROLE_NAME_VIEW,
  UPDATE_ROLE_NAME
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  deleteRoleNameFailed,
  deleteRoleNameSuccess,
  occasionRoleNameCreateFailed,
  occasionRoleNameCreateSuccess,
  occasionRoleNameViewFailed,
  occasionRoleNameViewSuccess,
  updateRoleNameFailed,
  updateRoleNameSuccess
} from '../actions/roleName';

export function* occasionRoleNameCreateSaga(action) {
  try {
    const result = yield call(
      occasionRoleNameCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionRoleNameCreateSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionRoleNameCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionRoleNameCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionRoleNameViewSaga(action) {
  try {
    const result = yield call(
      occasionRoleNameViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionRoleNameViewSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionRoleNameViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionRoleNameUpdateSaga(action) {
  try {
    const result = yield call(
      updateRoleNameApi,
      action.payload.ocId,
      action.payload.roleId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateRoleNameSuccess(
          action.payload.ocId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateRoleNameFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateRoleNameFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionRoleNameDeleteSaga(action) {
  try {
    const result = yield call(
      deleteRoleNameApi,
      action.payload.ocId,
      action.payload.roleId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(deleteRoleNameSuccess(action.payload.ocId, result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteRoleNameFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* roleNameActionWatcher() {
  yield takeLatest(
    OCCASION_ROLE_NAME_CREATE,
    progressSaga,
    occasionRoleNameCreateSaga
  );
  yield takeLatest(
    OCCASION_ROLE_NAME_VIEW,
    progressSaga,
    occasionRoleNameViewSaga
  );
  yield takeLatest(UPDATE_ROLE_NAME, progressSaga, occasionRoleNameUpdateSaga);
  yield takeLatest(DELETE_ROLE_NAME, progressSaga, occasionRoleNameDeleteSaga);
}
