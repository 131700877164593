import { union } from 'lodash';
import {
  OCCASION_AWARD_VIEW_SUCCESS,
  OCCASION_AWARD_VIEW_FAILED,
  OCCASION_AWARD_CREATE_SUCCESS,
  OCCASION_AWARD_CREATE_FAILED,
  DELETE_AWARD_SUCCESS,
  DELETE_AWARD_FAILED
} from '../constants/actionTypes';

const initialState = {
  occasionAwardData: null,
  awardLinks: null,
  error: null
};

const award = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_AWARD_VIEW_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.occasionAwardData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionAwardData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        awardLinks: links
      };
    }

    case OCCASION_AWARD_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionAwardData || {};
      const currentOccasionAwardIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionAwardIds];
      return {
        ...state,
        occasionAwardData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }

    case DELETE_AWARD_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionAwardData || {};
      const currentOccasionAwardIds = occasionGroupedData[ocId] || [];
      const filteredIds = currentOccasionAwardIds.filter(
        (id) => id !== data.id
      );

      return {
        ...state,
        occasionAwardData: {
          ...occasionGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case OCCASION_AWARD_VIEW_FAILED:
    case OCCASION_AWARD_CREATE_FAILED:
    case DELETE_AWARD_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default award;
