import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Image, AutoComplete, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import { getAllOccasionEvents } from '../../../../../selectors/event';
import { getImageSource } from '../../../../../helpers/imageHelper';
import {
  occasionTicketCreate,
  occasionTicketUpdate
} from '../../../../../actions/ticket';
import { occasionEventView } from '../../../../../actions/event';

const validate = (values) => {
  const error = {};
  if (!values.name) {
    error.name = 'Name is required';
  }

  return error;
};

const { Option } = AutoComplete;

function CreateTicket(props) {
  const [ticketData, setTicketData] = React.useState({
    name: props.ticketDetails?.name || '',
    tnc: props.ticketDetails?.tnc || '',
    faqs: props.ticketDetails?.faqs || '',
    description: props.ticketDetails?.description || '',
    remark: props.ticketDetails?.remark || '',
    event: ''
  });
  const [errorMessages, setErrorMessages] = React.useState({});

  const onPageChange = (page) => {
    props.occasionEventView(props.occasionId, page);
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleSubmit = () => {
    try {
      const validateObj = validate(ticketData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('ticket[name]', ticketData.name);
        formData.append('ticket[description]', ticketData.description);
        formData.append('ticket[tnc]', ticketData.tnc);
        formData.append('ticket[faqs]', ticketData.faqs);
        formData.append('ticket[remark]', ticketData.remark);
        formData.append(`ticket[ticketing]${[ticketData.event]}`, 'Event');
        props.ticketId
          ? props.occasionTicketUpdate(
              props.occasionId,
              props.ticketId,
              formData
            )
          : props.occasionTicketCreate(props.occasionId, formData);
        !props.ticketId && setTicketData({});
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      const ticketValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setTicketData({
        ...ticketData,
        [inputName]: ticketValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ErrorBoundary>
      <SectionContent className="ticket-create-section">
        <div className="ticket-create-form">
          <Form className="ticket-form" onFinish={handleSubmit}>
            {!props.ticketId ? (
              <InputGroup
                fieldType="dropdown"
                className="event-search"
                mode="single"
                name="event"
                label="Select Event"
                placeholder="Select Event"
                onChange={handleInputChange}
                value={ticketData?.event}>
                {props.eventData?.length > 0 &&
                  props.eventData.map((item) => {
                    return (
                      <Option value={item?.id} key={item?.id}>
                        <div className="options-container">
                          <p className="avatar-image-container">
                            <Image
                              src={getImageSource(item?.cardImageUrl)}
                              preview={false}
                            />
                          </p>
                          <div className="occasion-event-details-container">
                            <p className="occasion-event-name">{item?.title}</p>
                          </div>
                        </div>
                      </Option>
                    );
                  })}
              </InputGroup>
            ) : (
              ''
            )}
            <InputGroup
              label="Name"
              name="name"
              placeholder="Enter Name"
              onChange={handleInputChange}
              value={ticketData?.name}
              errorMessage={errorMessages.name}
            />
            <InputGroup
              label="Description"
              name="description"
              fieldType="textArea"
              placeholder="Enter Description"
              onChange={handleInputChange}
              value={ticketData?.description}
            />
            <InputGroup
              label="Terms and Conditions"
              name="tnc"
              fieldType="textArea"
              placeholder="Terms And Conditions"
              onChange={handleInputChange}
              value={ticketData?.tnc}
            />
            <InputGroup
              label="Questions"
              name="faqs"
              placeholder="Ask Questions"
              onChange={handleInputChange}
              value={ticketData?.faqs}
            />
            <InputGroup
              label="Remark"
              name="remark"
              fieldType="textArea"
              placeholder="Enter Remark"
              onChange={handleInputChange}
              value={ticketData?.remark}
            />

            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.ticketId ? 'Save Changes' : 'Create Ticket'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  eventData: getAllOccasionEvents(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { occasionTicketCreate, occasionTicketUpdate, occasionEventView },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateTicket);
