import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Button } from 'antd';
import './styles.scss';
import { CloseOutlined } from '@ant-design/icons';
import InputGroup from '../../../../../../components/InputGroup/index';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { createLive } from '../../../../../../actions/live';
import { currentOccasionId } from '../../../../../../selectors/dynamicData';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
  getCurrentLive,
  getCurrentLiveToken,
  getCurrentLiveUID
} from '../../../../../../selectors/live';
import { adminData } from '../../../../../../selectors/auth';

const validateLiveData = (values) => {
  const errors = {};
  if (!values.media) {
    errors.media = 'You need to post atleast something.';
  }
  if (!values.name) {
    errors.name = 'Name is required.';
  }
  if (!values.description) {
    errors.description = 'Description is required.';
  }
  return errors;
};

const CreateLive = (props) => {
  const [liveData, setLiveData] = useState({
    name: '',
    description: '',
    media: null
  });

  const [isLive, setLive] = useState(false);

  const [errorMessages, setErrorMessages] = useState({});

  const handleSubmit = async () => {
    try {
      const validateObj = validateLiveData(liveData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        if (liveData.media && liveData?.media[0]?.originFileObj) {
          formData.append(
            'live_stream[thumbnail_image]',
            liveData.media[0].originFileObj
          );
        }
        if (liveData.name) {
          formData.append('live_stream[title]', liveData.name);
        }
        if (liveData.description) {
          formData.append('live_stream[description]', liveData.description);
        }
        props.createLive(props.occasionId, formData, () => {
          setLiveData({
            name: '',
            description: '',
            media: null
          });
          setLive(true);
        });
      }
    } catch (error) {
      console.error('Error in submitting form', error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (type === 'media') {
          value = e.fileList;
          name = type;
        }

        const assignFormValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        setLiveData({
          ...liveData,
          [inputName]: assignFormValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="live-stream-create-section">
        <div className="live-stream-create">
          <Form className="live-stream-form-container" onFinish={handleSubmit}>
            {!isLive && (
              <>
                <InputGroup
                  fieldType="file"
                  label="Upload Live Stream Banner Image"
                  placeholder="Select image"
                  accept="image/*"
                  fileList={liveData?.media}
                  onChange={handleInputChange}
                  name="media"
                  errorMessage={errorMessages.media}
                />
                <InputGroup
                  label="Live Stream Name"
                  name="name"
                  placeholder="Enter Name"
                  onChange={handleInputChange}
                  value={liveData?.name}
                  errorMessage={errorMessages.name}
                />
                <InputGroup
                  label="Live Stream Description"
                  name="description"
                  fieldType="textArea"
                  placeholder="Enter Description"
                  onChange={handleInputChange}
                  value={liveData?.description}
                  errorMessage={errorMessages.description}
                />
                <SubmitButton
                  buttonText="Create"
                  fetchProgress={props.fetchProgress}
                  disabled={props.fetchProgress}
                />
              </>
            )}
            {isLive && (
              <Link
                target="_blank"
                className="go-live-button"
                to={{
                  pathname: '/go_live',
                  search: `?name=${props.currentLive?.title}&token=${props.currentToken}&uid=${props.uid}`
                }}>
                Go Live <ArrowRightOutlined />
              </Link>
            )}
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => ({
  occasionId: currentOccasionId(store, props),
  fetchProgress: store.browserState.fetchProgress,
  currentLive: getCurrentLive(store, props),
  currentToken: getCurrentLiveToken(store, props),
  adminData: adminData(store, props),
  uid: getCurrentLiveUID(store, props)
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ createLive }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateLive);
