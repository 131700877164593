import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { Form, Radio, Tooltip, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import { hospitalityCategoryCreate } from '../../../../../actions/hospitality';
import { IMAGE_RADIO } from './HospitalityConstants';
import { hospitalityCategoryUpdate } from '../../../../../actions/hospitality';

const validateHospitalityCategory = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Category Name is required';
  }
  if (!values.avatarname) {
    errors.avatarname = 'Select one of them';
  }
  if (values.order && Number(values.order) <= 0) {
    errors.order = 'Should be positive';
  }
  if (values.order && isNaN(values.order)) {
    errors.order = 'Should be number';
  }
  return errors;
};

const HospitalityCreate = (props) => {
  const [getHospitalityDetails, setGetHospitalityDetails] = React.useState({
    name: props.hospitalityDetails?.name || '',
    description: props.hospitalityDetails?.description || '',
    purpose: props.hospitalityDetails?.purpose || '',
    avatarname: props.hospitalityDetails?.avatarName || '',
    order: props.hospitalityDetails?.orderNumber || ''
  });
  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleHospitalityCreate = () => {
    try {
      const validateObj = validateHospitalityCategory(getHospitalityDetails);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        const formData = new FormData();
        formData.append(
          'hospitality_categories[name]',
          getHospitalityDetails?.name
        );
        formData.append(
          'hospitality_categories[description]',
          getHospitalityDetails?.description || ''
        );
        formData.append(
          'hospitality_categories[purpose]',
          getHospitalityDetails?.purpose || ''
        );
        formData.append(
          'hospitality_categories[order_number]',
          getHospitalityDetails?.order ? Number(getHospitalityDetails.order) : 1
        );
        formData.append(
          'hospitality_categories[avatar_name]',
          getHospitalityDetails?.avatarname
        );
        props.hospitalityCategoryId
          ? props.hospitalityCategoryUpdate(
              props.occasionId,
              props.hospitalityCategoryId,
              formData
            )
          : props.hospitalityCategoryCreate(props.occasionId, formData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (isInitiallyDisabled) {
          setInitiallyDisabled(false);
        }
        if (type === 'name') {
          value = e.target.value;
          name = type;
        }
        if (type === 'description') {
          value = e.target.value;
          name = type;
        }
        if (type === 'purpose') {
          value = e.target.value;
          name = type;
        }
        if (type === 'order') {
          value = e.target.value;
          name = type;
        }
        if (type === 'avatarname') {
          value = e.target.value;
          name = type;
        }
        const hospitalityDetailsValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        setGetHospitalityDetails({
          ...getHospitalityDetails,
          [inputName]: hospitalityDetailsValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ErrorBoundary>
      <SectionContent className="hospitality-section">
        <div className="hospitality-create-form">
          <Form
            className="hospitality-form-container"
            onFinish={handleHospitalityCreate}>
            <InputGroup
              label="Category Name"
              placeholder="Enter Name"
              name="name"
              onChange={handleChange}
              value={getHospitalityDetails?.name}
              errorMessage={errorMessages?.name}
            />
            <InputGroup
              label="Description"
              placeholder="Enter description"
              name="description"
              fieldType="textArea"
              onChange={handleChange}
              value={getHospitalityDetails?.description}
            />
            <InputGroup
              label="Purpose"
              placeholder="Enter purpose"
              name="purpose"
              onChange={handleChange}
              value={getHospitalityDetails?.purpose}
            />
            <InputGroup
              label="Order"
              placeholder="Enter order"
              name="order"
              onChange={handleChange}
              value={getHospitalityDetails?.order}
              errorMessage={errorMessages?.order}
            />
            <InputGroup
              fieldType="radioGroup"
              className="hospitality-searchbox"
              label="Select Avatar"
              name="avatarname"
              onChange={handleChange}
              value={getHospitalityDetails?.avatarname}
              errorMessage={errorMessages?.avatarname}>
              {Object.keys(IMAGE_RADIO).map((item) => {
                return (
                  <Tooltip
                    overlayClassName="hospitality-tooltip"
                    placement="top"
                    title={
                      <span className="hospitality-tooltip-span">{item}</span>
                    }>
                    <Radio.Button value={item}>
                      <img
                        className="hospitality-image"
                        src={IMAGE_RADIO[item]}></img>
                    </Radio.Button>
                  </Tooltip>
                );
              })}
            </InputGroup>
            <SubmitButton
              buttonText={
                props.hospitalityCategoryId ? 'Save Changes' : 'Create'
              }
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hospitalityCategoryCreate,
      hospitalityCategoryUpdate
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HospitalityCreate);
