import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { Form } from 'antd';
import './styles.scss';
import InputGroup from '../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import {
  getOccasionWhiteList,
  searchOccasionWhiteList
} from '../../../../../actions/occasionWhiteList';

function SearchWhiteList(props) {
  const [getDetails, setGetDetails] = React.useState({});

  const handleGetDetails = (page) => {
    try {
      props.searchOccasionWhiteList(
        props.occasionId,
        (page = 1),
        getDetails?.text
      );
      setGetDetails({});
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;

      if (type === 'text') {
        value = e.target.value;
        name = type;
      }
      const formValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      setGetDetails({
        ...getDetails,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleReset = (page) => {
    setGetDetails({});
    props.getOccasionWhiteList(props.occasionId, (page = 1));
  };

  return (
    <div className="white-list-input-search-container">
      <Form
        className="white-list-input-search-content"
        onFinish={handleGetDetails}>
        <InputGroup
          className="white-list-text-box"
          label="Email/Phone Number/Dealer Code"
          placeholder="Enter Email/Phone Number/Dealer Code"
          name="text"
          onChange={handleChange}
          value={getDetails?.text}
        />
        <div style={{ flex: 1, display: 'flex' }}>
          <SubmitButton
            wrapperClass="whit-list-submit-wrapper"
            className="white-list-submit-inputbox"
            buttonText="Search"
            fetchProgress={props.fetchProgress}
            disabled={props.fetchProgress}
          />
          <Button className="reset-button" onClick={handleReset}>
            Reset
          </Button>
        </div>
      </Form>
    </div>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOccasionWhiteList,
      searchOccasionWhiteList
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchWhiteList);
