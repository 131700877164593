import { createSelector } from 'reselect';
import { currentOccasionId, currentTicketVariantId } from './dynamicData';
import { ticketVariantEntities } from './entity';
import { ticketVariantIds } from './static';

export const currentOccasionTicketVariantIds = createSelector(
  currentOccasionId,
  ticketVariantIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getAllOccasionTicketVariants = createSelector(
  ticketVariantEntities,
  currentOccasionTicketVariantIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => ({
        ...entities[id],
        basePrice: (parseInt(entities[id]?.basePrice) / 100).toFixed(2)
      }))) ||
    (Array.isArray(ids) ? [] : null)
);

export const getCurrentTicketVariant = createSelector(
  currentTicketVariantId,
  ticketVariantEntities,
  (ocId, entities) => ocId && entities && entities[ocId]
);
