const initialState = {
  data: null,
  success: false,
  errorMessage: '',
  type: '',
  accessType: null,
  tabAccess: null
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS': {
      return {
        ...state,
        data: action.payload.data.id,
        type: action.payload.data.type,
        success: true,
        errorMessage: '',
        accessType: action.payload.data?.attributes?.accessType,
        tabAccess: action.payload.data?.attributes?.tabAccess
      };
    }
    case 'LOGIN_FAILURE': {
      return {
        ...state,
        success: false,
        errorMessage: action.payload?.meta?.message
      };
    }
    case 'LOGOUT_SUCCESS': {
      return {
        ...state,
        success: false
      };
    }
    default:
      return state;
  }
};

export default auth;
