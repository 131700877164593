import { createPortal } from 'react-dom';

const Portal = ({ id, children }) => {
  const applyAbosluteStyles = (container) => {
    const styles = {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0'
    };
    Object.keys(styles).forEach((key) => {
      container.style[key] = styles[key];
    });
  };

  const containerRef = () => {
    const node = document.getElementById(id);
    applyAbosluteStyles(node);
    return node;
  };

  return createPortal(children, containerRef());
};

export default Portal;
