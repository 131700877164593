import React from 'react';
import { useStore } from 'react-redux';
import NotFoundPage from '../../containers/NotFoundPage';

export const renderRoute = (selector) => {
  // renderRoute takes in a selector as the route which is to be displayed depends whether its data is available in the store

  const next = (Component) => {
    // renderRoute returns a function next which takes a Component as its arg

    const Route = (props) => {
      // next fuction returns the Route component which decides whether to display the required component or PageNot Found

      const store = useStore();
      const dependentData =
        selector && typeof selector === 'function'
          ? selector(store.getState(), props)
          : null;

      // the route which is to be displayed depends upong the value of the variable dependentData, if it is null it means there is no data corresponding to the selector in the store
      if (dependentData) {
        return <Component {...props} />;
      }
      return <NotFoundPage />;
    };

    return Route;
  };

  return next;
};
