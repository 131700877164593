import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import { DEV_MODE } from '../constants/development';

export const MAX_VIDEO_FILE_SIZE_WITHOUT_COMPRESSION = 15; // in mb

export const getProcessedVideo = async (file, progress) => {
  if (file.size / 1000000 > MAX_VIDEO_FILE_SIZE_WITHOUT_COMPRESSION) {
    const ffmpeg = createFFmpeg({
      log: DEV_MODE,
      progress: (ratio) => {
        if (typeof progress === 'function') {
          progress(ratio);
        }
      }
    });
    try {
      const { name, type } = file;

      // 'Loading ffmpeg-core.js'
      await ffmpeg.load();

      const outputUrl = `Compressed_${name}`;

      // Compression started
      ffmpeg.FS('writeFile', name, await fetchFile(file));

      // libx264 is the encoder, -preset fast denotes encoding speed, -crf value determines whether it is lossless where 0 is lossless 51 is worst visual quality 17 is just visually lossless
      await ffmpeg.run(
        '-i',
        name,
        '-c:v',
        'libx264',
        '-preset',
        'fast',
        '-crf',
        '17',
        outputUrl
      );

      // Compression completed
      const data = ffmpeg.FS('readFile', outputUrl);

      return new File([data.buffer], outputUrl, {
        lastModified: new Date(),
        type
      });
    } catch (error) {
      console.error('Error in compressing video file', error);
      return file;
    }
  }
  return file;
};
