import { call, takeLatest, put } from 'redux-saga/effects';
import { login } from '../api/auth';
import {
  loginSuccessful,
  logOutSuccessfull,
  loginFailure,
  requestClearStore
} from '../actions/auth';
import { isValidResult } from '../helpers/responseHelper';
import { rememberUser } from '../helpers/authHelper';
import progressSaga from './progressSaga';
import { requestAutoHideAlert } from '../actions/alert';

function* authSaga(action) {
  try {
    const result = yield call(login, action.payload);

    if (isValidResult(result)) {
      yield put(loginSuccessful(result));
      rememberUser(result, action.payload.remember);
      yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      if (action.callback) {
        action.callback();
      }
    } else {
      yield put(loginFailure(result));
      if (result?.meta && result?.meta?.message) {
        yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
      } else {
        yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
      }
    }
  } catch (error) {
    yield put(loginFailure(error));
    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

function* logOutSaga(action) {
  try {
    if (action.callback) {
      yield action.callback();
    }
    yield put(logOutSuccessfull());
    yield put(requestClearStore());
  } catch (error) {
    console.error(error);
  }
}

export default function* authSagaActionWatcher() {
  yield takeLatest('START_LOGIN', progressSaga, authSaga);
  yield takeLatest('START_LOGOUT', logOutSaga);
}
