export const TABNAME_MAPPING = {
  occasion: 'Occasion',
  admin: 'Admin',
  socianaInternalUser: 'Internal Team',
  user: 'User',
  enquiryRequest: 'Enquiry',
  whatsappTemplates: 'WhatsApp Templates'
};

export const USER_ACCESS_TYPES = {
  super_admin: 'superAdmin',
  admin: 'admin'
};
