import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useLinkDownloader from '../../../../../hooks/useLinkDownloader';
import { activityTrackerDownloadExslExport } from '../../../../../actions/activityTracker';

const ActivityTrackerDetailsView = ({
  activityTrackerDetails,
  occasionId,
  occasionName,
  activityTrackerDownloadExslExport
}) => {
  const { downloadLinkFile } = useLinkDownloader();

  const onDownload = () => {
    try {
      activityTrackerDownloadExslExport(
        occasionId,
        activityTrackerDetails?.id,
        (blobUrl) => {
          downloadLinkFile(
            blobUrl,
            `${occasionName} ${activityTrackerDetails?.name}`,
            'xlsx'
          );
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="activity-tracker-details-container">
      <p className="activity-tracker-details">
        <p className="fields">Name:</p>
        <p className="field-data">{activityTrackerDetails?.name}</p>
      </p>
      {!!activityTrackerDetails?.description && (
        <p className="activity-tracker-details">
          <p className="fields">Description:</p>
          <p className="field-data">{activityTrackerDetails?.description}</p>
        </p>
      )}

      <p className="activity-tracker-details">
        <button className="activity-tracker-exls-export" onClick={onDownload}>
          Export Activity Tracker User Details
        </button>
      </p>
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ activityTrackerDownloadExslExport }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityTrackerDetailsView);
