import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import { userDeviceViewApi } from '../api/userDevice';
import { USER_DEVICE_VIEW } from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  UserDeviceViewFailed,
  UserDeviceViewSuccess
} from '../actions/userDevice';

export function* userDeviceViewSaga(action) {
  try {
    const result = yield call(
      userDeviceViewApi,
      action.payload.userId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        UserDeviceViewSuccess(
          action.payload.userId,
          result.data,
          result.included,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(UserDeviceViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* userDeviceActionWatcher() {
  yield takeLatest(USER_DEVICE_VIEW, progressSaga, userDeviceViewSaga);
}
