import { createSelector } from 'reselect';
import {
  locationTriggerEntities,
  actionTriggerEntities,
  timeTriggerEntities,
  userEntities,
  groupEntities
} from './entity';
import { locationTriggerIds, actionTriggerIds, timeTriggerIds } from './static';
import {
  currentEventId,
  currentOccasionId,
  currentTriggerId
} from './dynamicData';

export const currentLocationTriggerIds = createSelector(
  currentOccasionId,
  currentEventId,
  locationTriggerIds,
  (ocId, evId, ids) => ids && (ocId ? ids[ocId] : ids[evId])
);

export const currentTimeTriggerIds = createSelector(
  currentOccasionId,
  currentEventId,
  timeTriggerIds,
  (ocId, evId, ids) => ids && (ocId ? ids[ocId] : ids[evId])
);

export const currentActionTriggerIds = createSelector(
  currentOccasionId,
  actionTriggerIds,
  (ocId, ids) => ids && ocId && ids[ocId]
);

export const locationTriggers = createSelector(
  currentLocationTriggerIds,
  userEntities,
  locationTriggerEntities,
  (ids, uEntities, entities) =>
    ids &&
    uEntities &&
    entities &&
    ids.length > 0 &&
    ids.map((id) => ({
      ...entities[id],
      createdBy: { ...uEntities[entities[id]?.createdBy?.id] }
    }))
);

export const actionTriggers = createSelector(
  currentActionTriggerIds,
  userEntities,
  actionTriggerEntities,
  (ids, uEntities, entities) =>
    ids &&
    uEntities &&
    entities &&
    ids.length > 0 &&
    ids.map((id) => ({
      ...entities[id],
      createdBy: { ...uEntities[entities[id]?.createdBy?.id] }
    }))
);

export const timeTriggers = createSelector(
  currentTimeTriggerIds,
  userEntities,
  timeTriggerEntities,
  groupEntities,
  (ids, uEntities, entities, gEntities) =>
    ids &&
    uEntities &&
    entities &&
    gEntities &&
    ids.length > 0 &&
    ids.map((id) => ({
      ...entities[id],
      createdBy: { ...uEntities[entities[id]?.createdBy?.id] },
      groups: [...entities[id]?.groups?.map((el) => gEntities[el?.id])]
    }))
);

export const currentTimeTrigger = createSelector(
  currentTriggerId,
  timeTriggerEntities,
  (id, entities) => id && entities && entities[id]
);

export const currentActionTrigger = createSelector(
  currentTriggerId,
  actionTriggerEntities,
  (id, entities) => id && entities && entities[id]
);

export const currentLocationTrigger = createSelector(
  currentTriggerId,
  locationTriggerEntities,
  (id, entities) => id && entities && entities[id]
);
