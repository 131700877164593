import { union } from 'lodash';
import {
  OCCASION_SMS_VIEW_SUCCESS,
  OCCASION_SMS_VIEW_FAILED,
  OCCASION_SMS_CREATE_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  occasionSmsData: null,
  error: null,
  smsLinks: null
};

const sms = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_SMS_VIEW_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.occasionSmsData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionSmsData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        smsLinks: links
      };
    }

    case OCCASION_SMS_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionSmsData || {};
      const currentOccasionSmsIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionSmsIds];
      return {
        ...state,
        occasionSmsData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }
    case OCCASION_SMS_VIEW_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default sms;
