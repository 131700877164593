import React, { useMemo, useState } from 'react';
import { Image } from 'antd';
import { LoadingOutlined, ZoomInOutlined } from '@ant-design/icons';
import { capitalize, getNameInitials } from '../../helpers/stringHelper';
import { getRandomColor } from '../../helpers/imageHelper';
import './styles.scss';

export const Avatar = ({
  avatarUrl,
  name,
  className,
  preview,
  previewClassNmae
}) => {
  const [loading, setLoading] = useState(!!avatarUrl);
  const [isImageValid, setIsImageValid] = useState(true);
  const handleImageError = () => {
    setLoading(false);
    setIsImageValid(false);
  };
  const handleImageOnLoad = (e) => {
    setLoading(false);
  };

  const randomColor = useMemo(() => {
    return getRandomColor();
  }, [avatarUrl, name]);

  return (
    <div className={`avatar_image_container ${className}`}>
      {loading && <LoadingOutlined className="loading-icon" />}
      {isImageValid && (
        <Image
          onLoad={handleImageOnLoad}
          preview={
            preview
              ? {
                  maskClassName: `preview-style ${previewClassNmae}`,
                  mask: <ZoomInOutlined />
                }
              : false
          }
          style={{ display: !loading ? 'inherit' : 'none' }}
          className="avatar_image"
          src={`${process.env.REACT_APP_CLIENT_URL}/${avatarUrl}`}
          onError={handleImageError}
        />
      )}
      {!isImageValid && (
        <span
          style={{
            backgroundColor: randomColor,
            display: !loading ? 'inherit' : 'none'
          }}
          className="avatar_image">
          {getNameInitials(capitalize(name || ''))}
        </span>
      )}
    </div>
  );
};
