import React from 'react';
import './styles.scss';

export const SheetContent = ({ data }) => {
  const idData = Object.keys(data);
  const nameData = Object.values(data);

  return (
    <div className="sheet-container">
      <div className="sheet-id-content">
        <h3 className="sheet-header">ID</h3>
        {idData.map((id) => {
          return <div className="sheet-content-id-box"> {id}</div>;
        })}
      </div>

      <div className="sheet-name-content">
        <h3 className="sheet-header">Name</h3>
        {nameData.map((name) => {
          return <div className="sheet-content-name-box"> {name}</div>;
        })}
      </div>
    </div>
  );
};
