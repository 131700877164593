import React from 'react';
import {
  MenuFoldOutlined,
  CloseOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import './styles.scss';
import { NavLink } from 'react-router-dom';

function Header({ isTabExpaned, toggleTab, signOut }) {
  return (
    <div className="header-container">
      {!isTabExpaned ? (
        <CloseOutlined
          onClick={toggleTab}
          className="heading-toggle-icon-style"
        />
      ) : (
        <MenuFoldOutlined
          onClick={toggleTab}
          className="heading-toggle-icon-style"
        />
      )}
      <NavLink to="/dashboard" className="heading-style">
        SOCIANA
      </NavLink>
      <LogoutOutlined className="logout-icon-style" onClick={signOut} />
    </div>
  );
}

export default Header;
