import React, { useState } from 'react';
import { Modal, Image } from 'antd';
import { Avatar } from '../Avatar';
import { capitalize } from '../../helpers/stringHelper';

const IconModal = ({ visible, floorPlanImageUrls, onOk, onCancel }) => {
  return (
    <>
      <Modal
        centered
        title="Groups"
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        footer={null}>
        <div className="groups-container">
          {floorPlanImageUrls.map((item) => {
            return (
              <div className="group-content-box">
                <span className="group-content-name">
                  <br />
                  {capitalize(item?.name || '')}
                </span>
                <Avatar
                  avatarUrl={item?.groupPictureUrl}
                  name={item?.name}
                  className="group-content-image"
                />
                <br />
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
};
export default IconModal;
