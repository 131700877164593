import React, { useState } from 'react';
import { Image as AntdImage } from 'antd';

export const Image = ({ imageUrl, cdnImageUrl = '', ...otherProps }) => {
  const [image, setImageUrl] = useState(cdnImageUrl || imageUrl);

  const handleImageError = () => {
    if (imageUrl) {
      setImageUrl(imageUrl);
    } else {
      console.error('Error in displaying image');
    }
  };

  return (
    <AntdImage
      src={image}
      width="100%"
      onError={handleImageError}
      controls={true}
      {...otherProps}
    />
  );
};
