import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';

import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  ACTIVITY_TRACKER_PRESENT_USER_CREATE,
  ACTIVITY_TRACKER_PRESENT_USER_VIEW,
  DELETE_ACTIVITY_TRACKER_PRESENT_USER,
  SEARCH_USERS_BY_OCCASION
} from '../constants/actionTypes';
import {
  activityTrackerPresentUserCreateApi,
  activityTrackerPresentUserViewApi,
  deleteActivityTrackerPresentUserApi,
  searchUserByOccasionApi
} from '../api/activityTrackerPresentUser';
import {
  activityTrackerPresentUserCreateFailed,
  activityTrackerPresentUserCreateSuccess,
  activityTrackerPresentUserViewFailed,
  activityTrackerPresentUserViewSuccess,
  deleteActivityTrackerPresentUserFailed,
  deleteActivityTrackerPresentUserSuccess,
  searchUserByOccasionFailed,
  searchUserByOccasionSuccess
} from '../actions/activityTrackerPresentUser';

export function* activityTrackerPresentUserCreateSaga(action) {
  try {
    const result = yield call(
      activityTrackerPresentUserCreateApi,
      action.payload.ocId,
      action.payload.trackerId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        activityTrackerPresentUserCreateSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          action.payload.trackerId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(activityTrackerPresentUserCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(activityTrackerPresentUserCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* activityTrackerPresentUserViewSaga(action) {
  try {
    const result = yield call(
      activityTrackerPresentUserViewApi,
      action.payload.ocId,
      action.payload.trackerId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        activityTrackerPresentUserViewSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          action.payload.trackerId,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(activityTrackerPresentUserViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* activityTrackerPresentUserDeleteSaga(action) {
  try {
    const result = yield call(
      deleteActivityTrackerPresentUserApi,
      action.payload.ocId,
      action.payload.trackerId,
      action.payload.presentUserId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        deleteActivityTrackerPresentUserSuccess(
          action.payload.ocId,
          action.payload.trackerId,
          result.data
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteActivityTrackerPresentUserFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* searchUserByOccasionSaga(action) {
  try {
    const result = yield call(
      searchUserByOccasionApi,
      action.payload.ocId,
      action.payload.query,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(searchUserByOccasionSuccess(result.data, result?.included));
      if (action.callback) {
        action.callback();
      }
    } else if (result?.meta && result?.meta?.message) {
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(searchUserByOccasionFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export default function* activityTrackerPresentUserActionWatcher() {
  yield takeLatest(
    ACTIVITY_TRACKER_PRESENT_USER_CREATE,
    progressSaga,
    activityTrackerPresentUserCreateSaga
  );
  yield takeLatest(
    ACTIVITY_TRACKER_PRESENT_USER_VIEW,
    progressSaga,
    activityTrackerPresentUserViewSaga
  );
  yield takeLatest(
    DELETE_ACTIVITY_TRACKER_PRESENT_USER,
    progressSaga,
    activityTrackerPresentUserDeleteSaga
  );
  yield takeLatest(
    SEARCH_USERS_BY_OCCASION,
    progressSaga,
    searchUserByOccasionSaga
  );
}
