import { union } from 'lodash';
import {
  ACTIVITY_TRACKER_PRESENT_USER_CREATE_SUCCESS,
  ACTIVITY_TRACKER_PRESENT_USER_CREATE_FAILED,
  ACTIVITY_TRACKER_PRESENT_USER_VIEW_FAILED,
  ACTIVITY_TRACKER_PRESENT_USER_VIEW_SUCCESS,
  DELETE_ACTIVITY_TRACKER_PRESENT_USER_FAILED,
  DELETE_ACTIVITY_TRACKER_PRESENT_USER_SUCCESS,
  SEARCH_USERS_BY_OCCASION_FAILED,
  SEARCH_USERS_BY_OCCASION_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  activityTrackerPresentUserData: null,
  activityTrackerPresentUserLinks: null,
  presentUserCount: 0,
  searchIds: null,
  error: null
};

const activityTrackerPresentUser = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVITY_TRACKER_PRESENT_USER_VIEW_SUCCESS: {
      const { data, trackerId, links, meta } = action.payload;
      const activityTrackerPresentUserGroupedData =
        state.activityTrackerPresentUserData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        activityTrackerPresentUserData: {
          ...activityTrackerPresentUserGroupedData,
          [trackerId]: [...currentOccasionNewIds]
        },
        activityTrackerPresentUserLinks: links,
        presentUserCount: meta?.totalCount || 0
      };
    }

    case ACTIVITY_TRACKER_PRESENT_USER_CREATE_SUCCESS: {
      const { data, trackerId } = action.payload;
      const activityTrackerPresentUserGroupedData =
        state.activityTrackerPresentUserData || {};
      const currentActivityTrackerPresentUserIds =
        activityTrackerPresentUserGroupedData[trackerId] || [];

      const newIds = [data.id, ...currentActivityTrackerPresentUserIds];
      return {
        ...state,
        activityTrackerPresentUserData: {
          ...activityTrackerPresentUserGroupedData,
          [trackerId]: newIds
        }
      };
    }

    case DELETE_ACTIVITY_TRACKER_PRESENT_USER_SUCCESS: {
      const { data, trackerId } = action.payload;
      const activityTrackerPresentUserGroupedData =
        state.activityTrackerPresentUserData || {};
      const currentActivityTrackerPresentUserIds =
        activityTrackerPresentUserGroupedData[trackerId] || [];
      const filteredIds = currentActivityTrackerPresentUserIds.filter(
        (id) => id !== data.id
      );

      return {
        ...state,
        activityTrackerPresentUserData: {
          ...activityTrackerPresentUserGroupedData,
          [trackerId]: filteredIds
        }
      };
    }

    case SEARCH_USERS_BY_OCCASION_SUCCESS: {
      const { data } = action.payload;
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];
      return { ...state, searchIds: newIds };
    }

    case ACTIVITY_TRACKER_PRESENT_USER_CREATE_FAILED:
    case ACTIVITY_TRACKER_PRESENT_USER_VIEW_FAILED:
    case DELETE_ACTIVITY_TRACKER_PRESENT_USER_FAILED:
    case SEARCH_USERS_BY_OCCASION_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default activityTrackerPresentUser;
