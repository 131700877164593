import { createSelector } from 'reselect';
import { accessControlEntities } from './entity';
import { currentOccasionId } from './dynamicData';
import { accessControlIds } from './static';

export const getCurrentOccasionAccessControls = createSelector(
  currentOccasionId,
  accessControlEntities,
  (ocId, entities) =>
    (ocId &&
      entities &&
      Object.keys(entities).length > 0 &&
      Object.values(entities).filter((item) => item.occasionId == ocId)) ||
    (entities && Array.isArray(Object.keys(entities)) ? [] : null)
);

export const getAllAccessControls = createSelector(
  accessControlIds,
  accessControlEntities,
  (ids, entities) => ids && entities && ids.map((id) => entities[id])
);
