import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import TableView from '../../../../../components/Table';
import {
  occasionImportantContactView,
  occasionImportantContactDelete
} from '../../../../../actions/importantContact';
import { getAllOccasionImportantContacts } from '../../../../../selectors/importantContact';
import { Button } from 'antd';
import CreateImportantContact from './ImportantContactCreate';

const ViewImportantContact = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [importantContactId, setImportantContactId] = React.useState(null);
  const onPageChange = (page) => {
    setPage(page);
    props.occasionImportantContactView(props.occasionId, page);
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleCreate = () => {
    setImportantContactId('');
    setCreateOpen(true);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="important-contact-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={handleCreate}>
          Create
        </Button>
      </div>
    )
  };

  const editData = (id) => {
    setCreateOpen(true);
    setImportantContactId(id);
  };

  const deleteData = (id) => {
    props.occasionImportantContactDelete(props.occasionId, id);
  };

  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="important-contact-view-section">
          <div className="important-contact-view-container">
            <TableView
              isRouted={false}
              onPageChange={onPageChange}
              onDelete={deleteData}
              isEditable={editData}
              paginationOptions={paginationOptions}
              data={props.importantContactData}
              fetchProgress={props.fetchProgress}
              paginationLinks={props.importantContactLinks}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <CreateImportantContact
        importantContactId={importantContactId}
        occasionId={props.occasionId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  importantContactData: getAllOccasionImportantContacts(store, props),
  importantContactLinks: store.importantContact.importantContactLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { occasionImportantContactView, occasionImportantContactDelete },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(ViewImportantContact);
