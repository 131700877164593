import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import { Button } from 'antd';
import SmsCreate from '../Sms/Create';
import { occasionSmsWithUserData } from '../../../../../../selectors/notificationSms';
import { occasionSmsView } from '../../../../../../actions/notificationSms';

const SmsView = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.occasionSmsView(props.occasionId, page);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);
  const paginationOptions = {
    showTotal: () => (
      <div className="sms-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={() => setCreateOpen(true)}>
          Create
        </Button>
      </div>
    )
  };
  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="sms-view-section">
          <div className="sms-view-container">
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              isRouted={false}
              onPageChange={onPageChange}
              data={props.smsData}
              fetchProgress={props.fetchProgress}
              paginationLinks={props.smsLink}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <SmsCreate
        occasionId={props.occasionId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  smsData: occasionSmsWithUserData(store, props),
  smsLink: store.sms.smsLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ occasionSmsView }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(SmsView);
