const validate = (values) => {
  const errors = {};
  if (!values.type) errors.type = 'Occasion Type is required';
  if (!values.guest_type) errors.guest_type = 'Guest Type is required';
  if (!values.name) {
    errors.name = 'Occasion name is required';
  } else if (values.name.length < 4 || values.name.length > 100) {
    errors.name = 'Must be between 4 to 100 characters';
  }
  if (!values.description)
    errors.description = 'Occasion description is required';
  if (!values.venue) errors.venue = 'Occasion venue is required';
  if (!(values.schedule?.length > 0)) {
    errors.schedule = 'Start and End date are required';
  } else if (!values?.schedule[0]) {
    errors.schedule = 'Start date is required';
  } else if (!values?.schedule[1]) {
    errors.schedule = 'End date is required';
  }
  // if (!values.occasionPlace.details.formatted_address)
  //   errors.occasionPlace = 'Occasion place is required';
  return errors;
};

export default validate;
