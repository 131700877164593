import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  occasionDiscussionDeleteApi,
  occasionDiscussionViewApi
} from '../api/discussion';
import {
  OCCASION_DISCUSSION_DELETE,
  OCCASION_DISCUSSION_VIEW
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  occasionDiscussionDeleteFailed,
  occasionDiscussionDeleteSuccess,
  occasionDiscussionViewFailed,
  occasionDiscussionViewSuccess
} from '../actions/discussion';

export function* occasionDiscussionViewSaga(action) {
  try {
    const result = yield call(
      occasionDiscussionViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionDiscussionViewSuccess(
          action.payload.ocId,
          result.data,
          result.included,
          result.links,
          result?.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionDiscussionViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionDiscussionDeleteSaga(action) {
  try {
    const result = yield call(
      occasionDiscussionDeleteApi,
      action.payload.ocId,
      action.payload.discussionId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionDiscussionDeleteSuccess(
          action.payload.ocId,
          result.data,
          result.included,
          result.links
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionDiscussionDeleteFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* discussionActionWatcher() {
  yield takeLatest(
    OCCASION_DISCUSSION_VIEW,
    progressSaga,
    occasionDiscussionViewSaga
  );
  yield takeLatest(
    OCCASION_DISCUSSION_DELETE,
    progressSaga,
    occasionDiscussionDeleteSaga
  );
}
