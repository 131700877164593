import { createSelector } from 'reselect';
import { subDivisionEntities } from './entity';
import { subDivisionIds } from './static';
import { currentDivisionId } from './dynamicData';

export const currentSubDivisionIds = createSelector(
  currentDivisionId,
  subDivisionIds,
  (divisionId, ids) => divisionId && ids && ids[divisionId]
);

export const getAllSubDivisionData = createSelector(
  subDivisionEntities,
  currentSubDivisionIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
