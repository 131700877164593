import React, { useState } from 'react';
import moment from 'moment';
import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { bindActionCreators } from 'redux';
import CardImage from '../../../../components/Table/CardImage';
import { GUEST_ACCESS_TYPES } from '../../../../constants/OtherConstants';
import OccasionCreate from '../Create/index';
import { updateOccasionCode } from '../../../../actions/occasion';
import FloorPlanImageButton from '../../../../components/FloorPlanImageButton';
import ImageModal from '../../../../components/ImageModal';
import { useLocation } from 'react-router-dom';
import { requestAutoHideAlert } from '../../../../actions/alert';
import { Switch } from '../../../../components/InputGroup/FormComponents';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import InputGroup from '../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../components/InputGroup/FormComponents';
import { FONT_VALUE } from './OccasionConstant';
import { updateOccasionFont } from '../../../../actions/occasion';

const OccasionDetailsView = ({
  occasion,
  updateOccasionCode,
  fetchProgress,
  requestAutoHideAlert,
  updateOccasionFont
}) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSplashScreenModalVisible, setIsSplashScreenModalVisible] = useState(
    false
  );
  const [fontEditOpen, setFontEditOpen] = useState(false);
  const [fontData, setFontData] = useState({});
  const showModal = (title) => {
    if (title == 'floorPlanImages') {
      setIsModalVisible(true);
    }
    if (title == 'splashScreenFiles') {
      setIsSplashScreenModalVisible(true);
    }
  };
  const handleOk = (title) => {
    if (title == 'floorPlanImages') {
      setIsModalVisible(false);
    }
    if (title == 'splashScreenFiles') {
      setIsSplashScreenModalVisible(false);
    }
  };

  const handleCancel = (title) => {
    if (title == 'floorPlanImages') {
      setIsModalVisible(false);
    }
    if (title == 'splashScreenFiles') {
      setIsSplashScreenModalVisible(false);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      const inputValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;

      setFontData({
        ...fontData,
        [inputName]: inputValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCopyToClipboard = (code) => {
    const message = `${occasion?.name} is happening, and we want you to be a part of it and create memories with them with seamless and happy experience. \n\nYou can join the occasion using code ${code}`;
    navigator.clipboard.writeText(message);
    requestAutoHideAlert('Copied to Clipboard!', 'Success');
  };

  const updateOccasionCodeSwitch = (e, codeId) => {
    const data = new FormData();
    data.append('occasion_code[status]', e ? 'active' : 'in_active');
    updateOccasionCode(occasion.id, codeId, data);
  };

  const onFontSubmit = () => {
    const occasionFontData = new FormData();
    occasionFontData.append('occasion_font[font_family]', fontData.font);
    updateOccasionFont(occasion.id, occasionFontData, () => {
      setFontEditOpen(false);
    });
  };
  const startDate = moment(occasion?.startAt).format('DD/MM/YYYY hh:mm A');
  const endDate = moment(occasion?.endAt).format('DD/MM/YYYY hh:mm A');

  const location = useLocation();

  if (!isEditOpen) {
    return (
      <div className="occasion-details-container">
        {!!occasion?.cardImageUrl && (
          <div className="occasion-details">
            <b>Avatar:</b>
            <CardImage data={occasion?.cardImageUrl} />
          </div>
        )}
        {!!occasion?.occasionFileUrl && (
          <div className="occasion-details">
            <b>Occasion File:</b>
            <CardImage data={occasion?.occasionFileUrl} />
          </div>
        )}

        {!!occasion?.backgroundFileUrl && (
          <div className="occasion-details">
            <b>Background File:</b>
            <CardImage data={occasion?.backgroundFileUrl} />
          </div>
        )}

        {occasion?.splashScreenFileUrls?.length > 0 && (
          <div className="occasion-details">
            <b>Splash Screen Files:</b>
            <div className="floorPlanImageDisplay-box">
              {occasion?.splashScreenFileUrls.slice(0, 4).map((url) => (
                <CardImage data={Object.values(url)[0]} />
              ))}
              <FloorPlanImageButton
                data={occasion.splashScreenFileUrls}
                index={3}
                onClick={() => showModal('splashScreenFiles')}
              />
            </div>
            {isSplashScreenModalVisible ? (
              <ImageModal
                visible={isSplashScreenModalVisible}
                onOk={() => handleOk('splashScreenFiles')}
                onCancel={() => handleCancel('splashScreenFiles')}
                floorPlanImageUrls={occasion.splashScreenFileUrls}
                title={'Splash Screen Files'}
              />
            ) : (
              ''
            )}
          </div>
        )}

        {!!occasion?.thankYouMediaUrl && (
          <div className="occasion-details">
            <b>Thank You Media:</b>
            <CardImage data={occasion?.thankYouMediaUrl} />
          </div>
        )}
        <div className="occasion-details">
          <b>Name:</b> <span className="details">{occasion?.name}</span>
        </div>
        <div className="occasion-details">
          <b>Occasion View:</b>{' '}
          <span className="details">{occasion?.occasionView}</span>
        </div>
        <div className="occasion-details">
          <b>Description:</b>{' '}
          <span className="details">{occasion?.description}</span>
        </div>
        {occasion?.premagicProjectId && (
          <div className="occasion-details">
            <b>Premagic Project Id:</b>{' '}
            <span className="details">{occasion?.premagicProjectId}</span>
          </div>
        )}
        <div className="occasion-details">
          <b>Start At:</b>{' '}
          <span className="details">{startDate.toLocaleString()}</span>
        </div>
        <div className="occasion-details">
          <b>End At:</b>{' '}
          <span className="details">{endDate.toLocaleString()}</span>
        </div>
        <div className="occasion-details">
          <b>Venue:</b> <span className="details">{occasion?.venue}</span>
        </div>
        <div className="occasion-details">
          <b>Occasion Type:</b>{' '}
          <span className="details">{occasion?.type}</span>
        </div>
        <p className="occasion-details">
          <b>Lattitude:</b>
          <p className="details">{occasion?.lattitude}</p>
        </p>
        <p className="occasion-details">
          <b>Longitude:</b>
          <p className="details">{occasion?.longitude}</p>
        </p>
        <div className="occasion-details">
          <b>Guest Type:</b>{' '}
          <span className="details">
            {GUEST_ACCESS_TYPES[occasion?.guestType]}
          </span>
        </div>
        <div className="occasion-details">
          <b>Landing Page:</b>{' '}
          <p className="details">{occasion?.landingPage}</p>
        </div>
        {occasion?.floorPlanImageUrls?.length > 0 && (
          <div className="occasion-details">
            <b>Floor Plan Images:</b>
            <div className="floorPlanImageDisplay-box">
              {occasion?.floorPlanImageUrls.slice(0, 5).map((url) => (
                <CardImage data={Object.values(url)[0]} />
              ))}
              <FloorPlanImageButton
                data={occasion.floorPlanImageUrls}
                index={4}
                onClick={() => showModal('floorPlanImages')}
              />
            </div>
            {isModalVisible ? (
              <ImageModal
                visible={isModalVisible}
                onOk={() => handleOk('floorPlanImages')}
                onCancel={() => handleCancel('floorPlanImages')}
                floorPlanImageUrls={occasion.floorPlanImageUrls}
              />
            ) : (
              ''
            )}
          </div>
        )}
        {occasion?.managedTitle && (
          <div className="occasion-details">
            <b>Managed Title:</b>
            <span className="details">{occasion?.managedTitle}</span>
          </div>
        )}
        {occasion?.managedFarmLogoUrl && (
          <div className="occasion-details">
            <b>Managed Farm Logo:</b>
            <CardImage data={occasion?.managedFarmLogoUrl} />
          </div>
        )}
        {occasion?.totalMemberCount >= 0 ? (
          <div className="occasion-details">
            <b>Total Members:</b>
            <span className="details">{occasion?.totalMemberCount}</span>
          </div>
        ) : (
          ''
        )}
        {occasion?.activeInternalMemberCount >= 0 ? (
          <div className="occasion-details">
            <b>Internal Members:</b>
            <span className="details">
              {occasion?.activeInternalMemberCount}
            </span>
          </div>
        ) : (
          ''
        )}
        {occasion?.occasionCodes.length > 0 && (
          <div>
            {occasion?.occasionCodes.map((item) => {
              return (
                <div key={item.id} className="occasion-details">
                  <b>{item?.enterAs} Code:</b>
                  <span className="details" style={{ marginRight: 10 }}>
                    {item.code}
                  </span>
                  <Switch
                    loading={fetchProgress}
                    value={item.status === 'active'}
                    onChange={(e) => updateOccasionCodeSwitch(e, item.id)}
                  />
                  <Button
                    className="copyToClipboardButton"
                    onClick={() => handleCopyToClipboard(item.code)}>
                    Copy to Clipboard
                  </Button>
                </div>
              );
            })}
          </div>
        )}

        {!fontEditOpen ? (
          <div className="occasion-font-details">
            <b>Font:</b>{' '}
            <div className="occasion-font-details-content">
              <span className="occasion-font-span">
                {occasion.occasionFont
                  ? occasion.occasionFont.fontFamily
                  : 'NunitoSans'}{' '}
              </span>
              <button
                className="font-edit-button"
                onClick={() => setFontEditOpen(true)}>
                <EditOutlined />
                <span className="details">Edit Font</span>
              </button>
            </div>
          </div>
        ) : (
          <div className="occasion-font-edit-details">
            <b>Font:</b>
            <div className="font-edit-container">
              <Form className="font-edit-form" onFinish={onFontSubmit}>
                <InputGroup
                  fieldType="dropdown"
                  options={FONT_VALUE}
                  name="font"
                  className="font-edit-form-input"
                  onChange={handleInputChange}
                  value={fontData?.font}
                />
                <SubmitButton
                  className="font-edit-form-submitButton"
                  buttonText="Save Changes"></SubmitButton>
              </Form>
              <button
                className="font-close-button"
                onClick={() => setFontEditOpen(false)}>
                <CloseOutlined />
              </button>
            </div>
          </div>
        )}

        <button
          className="edit-details-container"
          onClick={() => setIsEditOpen(true)}>
          <EditOutlined />
          <span className="details">Edit</span>
        </button>
      </div>
    );
  }
  return (
    <div className="edit-container">
      <OccasionCreate
        location={location}
        allowAccessToPage
        occasionId={occasion?.id}
        handleCloseClick={() => setIsEditOpen(false)}
      />
    </div>
  );
};

const mapStateToProps = (store, props) => {
  const occasionCodesIds = props.occasion?.occasionCodes;
  const occasionCodes = store.entity?.occasionCodes;
  const occasionCodeData =
    occasionCodesIds.length > 0 && occasionCodes
      ? occasionCodesIds.map((el) => ({ ...occasionCodes[el.id] }))
      : [];

  const occasionFontIds =
    store.occasionFont.occasionFontData?.[props.occasion.id];
  const occasionFonts = store.entity?.occasionFonts;
  const occasionFont =
    occasionFontIds && occasionFonts
      ? { ...occasionFonts[occasionFontIds] }
      : null;

  const occasion = {
    ...props.occasion,
    occasionCodes: occasionCodeData,
    occasionFont: occasionFont
  };
  const fetchProgress = store.browserState.fetchProgress;
  return { occasion: occasion, fetchProgress };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { updateOccasionCode, requestAutoHideAlert, updateOccasionFont },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OccasionDetailsView);
