import React, { useState } from 'react';
import { capitalize } from '../../helpers/stringHelper';
import './styles.scss';
import { Avatar } from '../Avatar';

export const GroupContent = ({ data }) => {
  return (
    <div className="group-content">
      {data?.map((item) => {
        return (
          <div className="group-content-box">
            <span className="group-content-name">{item?.name || ''}</span>
            <Avatar
              avatarUrl={item?.groupPictureUrl}
              name={item?.name}
              className="group-content-image"
            />
          </div>
        );
      })}
    </div>
  );
};
