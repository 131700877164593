import { createSelector } from 'reselect';
import { postEntities, userEntities, occasionEventsEntities } from './entity';
import { occasionPostIds, eventPostIds } from './static';
import { getCurrentOccasionMembers } from './socianaInternalUser';
import { currentOccasionId, currentPostId } from './dynamicData';

export const getCurrentOccasionPosts = createSelector(
  currentOccasionId,
  occasionPostIds,
  (ocId, Ids) => ocId && Ids && Ids[ocId]
);
export const getPosts = createSelector(
  getCurrentOccasionPosts,
  postEntities,
  (ids, entities) =>
    ids && entities && ids?.length > 0 && ids.map((id) => entities[id])
);

export const getCurrentPost = createSelector(
  currentPostId,
  postEntities,
  userEntities,
  (id, entities, users) => {
    const user = entities && id && users && users[entities[id]?.user?.id];
    return id && entities && entities[id] && { ...entities[id], user };
  }
);

export const getPostsWithPostedByData = createSelector(
  getPosts,
  userEntities,
  getCurrentOccasionMembers,
  (data, entities, memDetails) => {
    return (
      data &&
      entities &&
      data?.length > 0 &&
      data.map((item) => {
        const postedByDetails = entities[item?.user?.id];
        const postedByCollaboratorData = memDetails?.filter(
          (item) => item?.userId == postedByDetails?.id
        );
        return {
          ...item,
          postedByDetails: {
            ...postedByDetails,
            roleName:
              (postedByCollaboratorData &&
                postedByCollaboratorData[0]?.roleName) ||
              ''
          }
        };
      })
    );
  }
);

export const getCurrentOccasionEventPosts = createSelector(
  currentOccasionId,
  eventPostIds,
  (ocId, Ids) => ocId && Ids && Ids[ocId]
);
export const getEventPosts = createSelector(
  getCurrentOccasionEventPosts,
  postEntities,
  (ids, entities) =>
    ids && entities && ids?.length > 0 && ids.map((id) => entities[id])
);

export const getEventPostsWithPostedByData = createSelector(
  getEventPosts,
  userEntities,
  occasionEventsEntities,
  getCurrentOccasionMembers,
  (data, entities, eventEntities, memDetails) => {
    return (
      data &&
      entities &&
      eventEntities &&
      data?.length > 0 &&
      data.map((item) => {
        const events = item?.events
          ? { ...eventEntities[item?.events[0]?.id] }
          : {};
        const postedByDetails = entities[item?.user?.id];
        const postedByCollaboratorData = memDetails?.filter(
          (item) => item?.userId == postedByDetails?.id
        );
        return {
          ...item,
          events: events,
          postedByDetails: {
            ...postedByDetails,
            roleName:
              (postedByCollaboratorData &&
                postedByCollaboratorData[0]?.roleName) ||
              ''
          }
        };
      })
    );
  }
);
