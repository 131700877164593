export const MESSAGE_TYPES = [
  {
    label: 'Requested normal task answered',
    value: 'requested_normal_task_answered'
  },
  {
    label: 'Requested document task answered',
    value: 'requested_document_task_answered'
  },
  {
    label: 'Requested normal task unanswered',
    value: 'requested_normal_task_unanswered'
  },
  {
    label: 'Requested document task unanswered',
    value: 'requested_document_task_unanswered'
  },
  {
    label: 'Provided normal task answered',
    value: 'provided_normal_task_answered'
  },
  {
    label: 'Provided document task answered',
    value: 'provided_document_task_answered'
  },
  {
    label: 'Provided normal task unanswered',
    value: 'provided_normal_task_unanswered'
  },
  {
    label: 'Provided document task unanswered',
    value: 'provided_document_task_unanswered'
  },
  {
    label: 'Occasion announcement',
    value: 'occasion_announcement'
  }
];

export const MODELABLE_TYPE = [
  {
    label: 'Hospitality Task',
    value: 'HospitalityTask'
  },
  {
    label: 'Occasion',
    value: 'Occasion'
  }
];

export const OPERATION_TYPE = [
  {
    label: 'Answered Hospitality Task',
    value: 'AnsweredHospitalityTask'
  },
  {
    label: 'Unanswered Hospitality Task',
    value: 'UnansweredHospitalityTask'
  },
  {
    label: 'Occasion Announcement',
    value: 'OccasionAnnouncement'
  }
];

export const IS_MODELABLE = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' }
];
