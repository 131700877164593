import {
  SET_CURRENT_TAB_INDEX,
  RESET_TAB_INDEX
} from '../constants/actionTypes';

const initialState = {
  occasion: 1,
  groups: 1,
  tickets: 1,
  socianaInternalUser: 1,
  admin: 1,
  event: 1
};

const tabKeys = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_TAB_INDEX: {
      const { tabName, tabIndex } = action.payload;
      return {
        ...state,
        [tabName]: tabIndex
      };
    }

    case RESET_TAB_INDEX: {
      const { tabName } = action.payload;
      return {
        ...state,
        [tabName]: 1
      };
    }
    default:
      return state;
  }
};

export default tabKeys;
