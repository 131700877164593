import request from './request';

export function occasionSubdivisionCreateApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/sociana_think/subdivision_segments`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function occasionSubdivisionViewApi(ocId, divisionId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/sociana_think/subdivision_segments?page=${page}&division_segment_id=${divisionId}`,
    {
      method: 'GET',
      token
    }
  );
}
