import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import moment from 'moment';
import { Form, Button, AutoComplete, Image } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import {
  activityTrackerPresentUserCreate,
  searchUserByOccasion
} from '../../../../../../actions/activityTrackerPresentUser';
import { getSearchedUsersByOccasion } from '../../../../../../selectors/activityTrackerPresentUser';
import { getImageSource } from '../../../../../../helpers/imageHelper';

const { Option } = AutoComplete;

const validateSosData = (values) => {
  const errors = {};

  if (!values.searchData.value) {
    errors.searchData = 'User is required';
  }

  return errors;
};

const PresentUserCreate = (props) => {
  const [presentUserData, setPresentUserData] = useState({
    searchData: { value: '', id: null }
  });
  const [errorMessages, setErrorMessages] = useState({});
  const handleSubmit = () => {
    try {
      const validateObj = validateSosData(presentUserData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append(
          'activity_tracker_present_user[user_id]',
          presentUserData.searchData.id
        );
        props.activityTrackerPresentUserCreate(
          props.occasionId,
          props.activityTrackerId,
          formData,
          () => {
            setPresentUserData({
              searchData: { value: '', id: null }
            });
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let formValue;
      let inputName;

      if (type === 'searchData') {
        value = {
          value: e?.value,
          id: e?.key
        };
        name = type;
      }

      formValue = value || e?.target?.value;
      inputName = name || e?.target?.name;
      setPresentUserData({
        ...presentUserData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = (val) => {
    setPresentUserData({
      ...presentUserData,
      searchData: { value: val, id: null }
    });
    if (val.length > 0) {
      props.searchUserByOccasion(props.occasionId, val);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="present-user-create-section">
        <div className="present-user-create-form">
          <Form className="present-user-form-container" onFinish={handleSubmit}>
            <InputGroup
              fieldType="searchbox"
              className="user-search"
              name="searchData"
              label="Search User"
              loading={props.fetchProgress}
              notFoundContent="No Data"
              errorMessage={errorMessages?.searchData}
              value={presentUserData?.searchData?.value}
              onSelect={(e, option) => handleInputChange(option, 'searchData')}
              onSearch={handleSearch}
              placeholder="Search User">
              {props.searchUserByOccasionData?.length > 0 &&
                presentUserData?.searchData?.value?.length > 0 &&
                props.searchUserByOccasionData.map((item) => {
                  return (
                    <Option value={item?.name} key={item?.id}>
                      <div className="options-container">
                        <p className="avatar-image-container">
                          <Image
                            src={getImageSource(item?.avatarUrl, null, false)}
                            preview={false}
                          />
                        </p>
                        <div className="internal-member-occasion-details-container">
                          <p className="internal-member-occasion-name">
                            {item?.name}
                          </p>
                          <p className="internal-member-occasion-type">
                            {item?.email}
                          </p>
                        </div>
                      </div>
                    </Option>
                  );
                })}
            </InputGroup>

            <SubmitButton
              buttonText="Create"
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  searchUserByOccasionData: getSearchedUsersByOccasion(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { activityTrackerPresentUserCreate, searchUserByOccasion },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PresentUserCreate);
