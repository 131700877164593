export const currentOccasionId = (store, props) => {
  if (props.occasion) {
    return props.occasion.id;
  }
  if (props.occasionId) {
    return props.occasionId;
  }
  if (props.match?.params?.occasionId) {
    return props.match?.params?.occasionId;
  }
  const queryParams = new URLSearchParams(props.location?.search);
  const ocId = queryParams?.get('ocId');
  if (ocId) {
    return ocId;
  }
  return props.match?.params?.id;
};

export const currentUserId = (store, props) => {
  if (props.user) {
    return props.user.id;
  }
  if (props.userId) {
    return props.userId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.userId;
};

export const currentMediaId = (store, props) => {
  if (props.media) {
    return props.media.id;
  }
  if (props.mediaId) {
    return props.mediaId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.mediaId;
};

export const currentAdminId = (store, props) => {
  if (props.admin) {
    return props.admin.id;
  }
  if (props.adminId) {
    return props.adminId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.adminId;
};

export const currentCollaboratorId = (store, props) => {
  if (props.currentCollaborator?.id) {
    return props.currentCollaborator.id;
  }
  if (props.currentCollaboratorId) {
    return props.currentCollaboratorId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.currentCollaboratorId;
};

export const currentCollaboratorType = (store, props) => {
  if (props.currentCollaborator?.type) {
    return props.currentCollaborator.type;
  }
  if (props.currentCollaboratorType) {
    return props.currentCollaboratorType;
  }
  if (props.match?.params?.type) {
    return props.match?.params?.type;
  }
  return props.match?.params?.currentCollaboratorType;
};

export const currentPostId = (store, props) => {
  if (props.post) {
    return props.post.id;
  }
  if (props.postId) {
    return props.postId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.postId;
};

export const invitationStatus = (store, props) => props.status;

export const currentTab = (store, props) => {
  const { pathname } = props.location;

  return pathname.trim().split('/')[2];
};

export const allowAccessToPage = (store, props) => {
  return props.allowAccessToPage;
};

export const currentEventId = (store, props) => {
  if (props.event) {
    return props.event.id;
  }
  if (props.eventId) {
    return props.eventId;
  }
  if (props.match?.params?.eventId) {
    return props.match?.params?.eventId;
  }
  return props.match?.params?.id;
};
export const currentGroupId = (store, props) => {
  if (props.group) {
    return props.group.id;
  }
  if (props.groupId) {
    return props.groupId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.groupId;
};
export const currentHospitalityCategoryId = (store, props) => {
  if (props.hospitality) {
    return props.hospitality.id;
  }
  if (props.hospitalityId) {
    return props.hospitalityId;
  }
  if (props.match?.params?.hospitalityId) {
    return props.match?.params?.hospitalityId;
  }
  return props.match?.params?.id;
};

export const currentLiveVideoId = (store, props) => {
  if (props.video) {
    return props.video.id;
  }
  if (props.videoId) {
    return props.videoId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  const queryParams = new URLSearchParams(props.location?.search);
  const videoId = queryParams?.get('id');
  if (videoId) {
    return videoId;
  }
  return props.match?.params?.videoId;
};

export const currentTicketId = (store, props) => {
  if (props.ticket) {
    return props.ticket.id;
  }
  if (props.ticketId) {
    return props.ticketId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.ticketId;
};

export const currentCompetitionId = (store, props) => {
  if (props.competition) {
    return props.competition.id;
  }
  if (props.competitionId) {
    return props.competitionId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.competitionId;
};

export const currentParticipantId = (store, props) => {
  if (props.participant) {
    return props.participant.id;
  }
  if (props.participantId) {
    return props.participantId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.participantId;
};

export const currentTicketVariantId = (store, props) => {
  if (props.ticketVariant) {
    return props.ticketVariant.id;
  }
  if (props.ticketVariantId) {
    return props.ticketVariantId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.ticketVariantId;
};

export const currentTriggerId = (store, props) => {
  if (props.trigger) {
    return props.trigger.id;
  }
  if (props.triggerId) {
    return props.triggerId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.triggerId;
};

export const currentDivisionId = (store, props) => {
  if (props.division) {
    return props.division.id;
  }
  if (props.divisionId) {
    return props.divisionId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.divisionId;
};

export const currentScheduleId = (store, props) => {
  if (props.schedule) {
    return props.schedule.id;
  }
  if (props.scheduleId) {
    return props.scheduleId;
  }
  if (props.match?.params?.scheduleId) {
    return props.match?.params?.scheduleId;
  }
  return props.match?.params?.id;
};

export const currentEmailId = (store, props) => {
  if (props.email) {
    return props.email.id;
  }
  if (props.emailId) {
    return props.emailId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.emailId;
};

export const currentAlbumId = (store, props) => {
  if (props.album) {
    return props.album.id;
  }
  if (props.albumId) {
    return props.albumId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.albumId;
};

export const currentRoleNameId = (store, props) => {
  if (props.roleName) {
    return props.roleName.id;
  }
  if (props.roleNameId) {
    return props.roleNameId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.roleNameId;
};

export const currentAwardId = (store, props) => {
  if (props.award) {
    return props.award.id;
  }
  if (props.awardId) {
    return props.awardId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.awardId;
};

export const currentAlarmId = (store, props) => {
  if (props.alarm) {
    return props.alarm.id;
  }
  if (props.alarmId) {
    return props.alarmId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.alarmId;
};

export const currentHardLogoutId = (store, props) => {
  if (props.hardLogout) {
    return props.hardLogout.id;
  }
  if (props.hardLogoutId) {
    return props.hardLogoutId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.hardLogoutId;
};

export const currentHospitalityTaskId = (store, props) => {
  if (props.hospitalityTask) {
    return props.hospitalityTask.id;
  }
  if (props.hospitalityTaskId) {
    return props.hospitalityTaskId;
  }
  if (props.match?.params?.hospitalityTaskId) {
    return props.match?.params?.hospitalityTaskId;
  }
  return props.match?.params?.id;
};
export const currentImportantContactId = (store, props) => {
  if (props.importantContact) {
    return props.importantContact.id;
  }
  if (props.importantContactId) {
    return props.importantContactId;
  }
  if (props.match?.params?.importantContactId) {
    return props.match?.params?.importantContactId;
  }
  return props.match?.params?.id;
};

export const currentItineraryId = (store, props) => {
  if (props.itinerary) {
    return props.itinerary.id;
  }
  if (props.itineraryId) {
    return props.itineraryId;
  }
  if (props.match?.params?.itineraryId) {
    return props.match?.params?.itineraryId;
  }
  return props.match?.params?.id;
};
export const currentSosId = (store, props) => {
  if (props.sos) {
    return props.sos.id;
  }
  if (props.sosId) {
    return props.sosId;
  }
  if (props.match?.params?.sosId) {
    return props.match?.params?.sosId;
  }
  return props.match?.params?.id;
};

export const currentSponsorshipId = (store, props) => {
  if (props.sponsorship) {
    return props.sponsorship.id;
  }
  if (props.sponsorshipId) {
    return props.sponsorshipId;
  }
  if (props.match?.params?.sponsorshipId) {
    return props.match?.params?.sponsorshipId;
  }
  return props.match?.params?.id;
};

export const currentPollPostId = (store, props) => {
  if (props.pollPost) {
    return props.pollPost.id;
  }
  if (props.pollPostId) {
    return props.pollPostId;
  }
  if (props.match?.params?.pollPostId) {
    return props.match?.params?.pollPostId;
  }
  return props.match?.params?.id;
};
export const currentCodeId = (store, props) => {
  if (props.occasionCode) {
    return props.occasionCode.id;
  }
  if (props.occasionCodeId) {
    return props.occasionCodeId;
  }
  if (props.match?.params?.occasionCodeId) {
    return props.match?.params?.occasionCodeId;
  }
  return props.match?.params?.id;
};
export const currentPendingInvitationId = (store, props) => {
  if (props.pendingInvitation) {
    return props.pendingInvitation.id;
  }
  if (props.pendingInvitationId) {
    return props.pendingInvitationId;
  }
  if (props.match?.params?.pendingInvitationId) {
    return props.match?.params?.pendingInvitationId;
  }
  return props.match?.params?.id;
};

export const currentPendingRegistartionId = (store, props) => {
  if (props.pendingRegistration) {
    return props.pendingRegistration.id;
  }
  if (props.pendingRegistrationId) {
    return props.pendingRegistrationId;
  }
  if (props.match?.params?.pendingRegistrationId) {
    return props.match?.params?.pendingRegistrationId;
  }
  return props.match?.params?.id;
};

export const currentHospitalityPublicAnswerId = (store, props) => {
  if (props.publicAnswer) {
    return props.publicAnswer.id;
  }
  if (props.publicAnswerId) {
    return props.publicAnswerId;
  }
  if (props.match?.params?.publicAnswerId) {
    return props.match?.params?.publicAnswerId;
  }
  return props.match?.params?.id;
};

export const currentInternalTeamOccasionId = (store, props) => {
  if (props.internalTeamOccasion) {
    return props.internalTeamOccasion.id;
  }
  if (props.internalTeamOccasionId) {
    return props.internalTeamOccasionId;
  }
  if (props.match?.params?.internalTeamOccasionId) {
    return props.match?.params?.internalTeamOccasionId;
  }
  return props.match?.params?.id;
};
export const currentSocianaAdminOccasionId = (store, props) => {
  if (props.adminOccasion) {
    return props.adminOccasion.id;
  }
  if (props.adminOccasionId) {
    return props.adminOccasionId;
  }
  if (props.match?.params?.adminOccasionId) {
    return props.match?.params?.adminOccasionId;
  }
  return props.match?.params?.id;
};

export const currentEventAffairId = (store, props) => {
  if (props.eventAffair) {
    return props.eventAffair.id;
  }
  if (props.eventAffairId) {
    return props.eventAffairId;
  }
  if (props.match?.params?.eventAffairId) {
    return props.match?.params?.eventAffairId;
  }
  return props.match?.params?.id;
};

export const currentEventCollaboratorId = (store, props) => {
  if (props.eventCollaborator) {
    return props.eventCollaborator.id;
  }
  if (props.eventCollaboratorId) {
    return props.eventCollaboratorId;
  }
  if (props.match?.params?.eventCollaboratorId) {
    return props.match?.params?.eventCollaboratorId;
  }
  return props.match?.params?.id;
};

export const currentEventAffairEssenceId = (store, props) => {
  if (props.essence) {
    return props.essence.id;
  }
  if (props.essenceId) {
    return props.essenceId;
  }
  if (props.match?.params?.essenceId) {
    return props.match?.params?.essenceId;
  }
  if (props.eventAffairEssence) {
    return props.eventAffairEssence.id;
  }
  if (props.eventAffairEssenceId) {
    return props.eventAffairEssenceId;
  }
  return props.match?.params?.id;
};

export const currentEventAffairEntertainmentId = (store, props) => {
  if (props.entertainment) {
    return props.entertainment.id;
  }
  if (props.entertainmentId) {
    return props.entertainmentId;
  }
  if (props.match?.params?.entertainmentId) {
    return props.match?.params?.entertainmentId;
  }
  if (props.eventAffairEntertainment) {
    return props.eventAffairEntertainment.id;
  }
  if (props.eventAffairEntertainmentId) {
    return props.eventAffairEntertainmentId;
  }
  return props.match?.params?.id;
};

export const currentEventAffairFoodId = (store, props) => {
  if (props.food) {
    return props.food.id;
  }
  if (props.foodId) {
    return props.foodId;
  }
  if (props.match?.params?.foodId) {
    return props.match?.params?.foodId;
  }
  if (props.eventAffairFood) {
    return props.eventAffairFood.id;
  }
  if (props.eventAffairFoodId) {
    return props.eventAffairFoodId;
  }
  return props.match?.params?.id;
};

export const currentOccasionTabSettingId = (store, props) => {
  if (props.occasionTabSetting) {
    return props.occasionTabSetting.id;
  }
  if (props.occasionTabSettingId) {
    return props.occasionTabSettingId;
  }
  if (props.match?.params?.occasionTabSettingId) {
    return props.match?.params?.occasionTabSettingId;
  }
  return props.match?.params?.id;
};

export const currentSpeakerId = (store, props) => {
  if (props.speaker) {
    return props.speaker.id;
  }
  if (props.speakerId) {
    return props.speakerId;
  }
  if (props.match?.params?.speakerId) {
    return props.match?.params?.speakerId;
  }
  return props.match?.params?.id;
};

export const currentSpeakerReferenceId = (store, props) => {
  if (props.speakerReference) {
    return props.speakerReference.id;
  }
  if (props.speakerReferenceId) {
    return props.speakerReferenceId;
  }
  if (props.match?.params?.speakerReferenceId) {
    return props.match?.params?.speakerReferenceId;
  }
  return props.match?.params?.id;
};

export const currentWhiteListId = (store, props) => {
  if (props.whiteList) {
    return props.whiteList.id;
  }
  if (props.whiteListId) {
    return props.whiteListId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.whiteListId;
};

export const currentActivityTrackerId = (store, props) => {
  if (props.tracker) {
    return props.tracker.id;
  }
  if (props.trackerId) {
    return props.trackerId;
  }
  if (props.activityTracker) {
    return props.activityTracker.id;
  }
  if (props.activityTrackerId) {
    return props.activityTrackerId;
  }
  if (props.match?.params?.trackerId) {
    return props.match?.params?.trackerId;
  }
  if (props.match?.params?.id) {
    return props.match?.params?.id;
  }
  return props.match?.params?.activityTrackerId;
};

export const currentFixedGroupPostSettingId = (store, props) => {
  if (props.fixedGroupPostSetting) {
    return props.fixedGroupPostSetting.id;
  }
  if (props.fixedGroupPostSettingId) {
    return props.fixedGroupPostSettingId;
  }

  if (props.match?.params?.fixedGroupPostSettingId) {
    return props.match?.params?.fixedGroupPostSettingId;
  }
  return props.match?.params?.id;
};

export const currentWhatsappTemplatesId = (store, props) => {
  if (props.whatsappTemplates) {
    return props.whatsappTemplates.id;
  }
  if (props.whatsappTemplatesId) {
    return props.whatsappTemplatesId;
  }
  if (props.match?.params?.whatsappTemplatesId) {
    return props.match?.params?.whatsappTemplatesId;
  }
  return props.match?.params?.id;
};

export const currentWhatsappTemplateModelableId = (store, props) => {
  if (props.whatsappTemplateModelable) {
    return props.whatsappTemplateModelable.id;
  }
  if (props.whatsappTemplatesModelableId) {
    return props.whatsappTemplatesModelableId;
  }
  if (props.whatsappTemplateModelableId) {
    return props.whatsappTemplateModelableId;
  }
  if (props.match?.params?.whatsappTemplateModelableId) {
    return props.match?.params?.whatsappTemplateModelableId;
  }
  return props.match?.params?.id;
};

export const currentWhatsappTemplateParamsId = (store, props) => {
  if (props.whatsappTemplateParams) {
    return props.whatsappTemplateParams.id;
  }
  if (props.whatsappTemplateParamsId) {
    return props.whatsappTemplateParamsId;
  }
  if (props.whatsappTemplatesParamsId) {
    return props.whatsappTemplatesParamsId;
  }
  if (props.match?.params?.whatsappTemplateParamsId) {
    return props.match?.params?.whatsappTemplateParamsId;
  }
  return props.match?.params?.id;
};

export const currentOccasionResourcesId = (store, props) => {
  if (props.occasionResources) {
    return props.occasionResources.id;
  }
  if (props.occasionResourcesId) {
    return props.occasionResourcesId;
  }

  if (props.match?.params?.occasionResourcesId) {
    return props.match?.params?.occasionResourcesId;
  }
  return props.match?.params?.id;
};

export const currentCategoryStatusId = (store, props) => {
  if (props.categoryStatus) {
    return props.categoryStatus.id;
  }
  if (props.categoryStatusId) {
    return props.categoryStatusId;
  }

  if (props.match?.params?.categoryStatusId) {
    return props.match?.params?.categoryStatusId;
  }
  return props.match?.params?.id;
};
