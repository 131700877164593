import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Button } from 'antd';
import './styles.scss';
import { CloseOutlined } from '@ant-design/icons';
import InputGroup from '../../../../../../components/InputGroup/index';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import {
  createEmail,
  editEmail
} from '../../../../../../actions/notifications';
import {
  currentEmailId,
  currentOccasionId
} from '../../../../../../selectors/dynamicData';
import { getEmailFormData } from './utils';
import { getCurrentEmail } from '../../../../../../selectors/notifications';

const validateLiveData = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Name is required.';
  }
  if (!values.subject) {
    errors.subject = 'Subject is required.';
  }
  if (!values.valediction) {
    errors.valediction = 'Valediction is required.';
  }
  if (!values.sender) {
    errors.sender = 'Sender is required.';
  }
  if (!values.body) {
    errors.body = 'Body is required.';
  }
  return errors;
};

const NotificationCreate = (props) => {
  const [emailData, setEmailData] = useState({
    name: props.initialData?.name || '',
    subject: props.initialData?.subject || '',
    valediction: props.initialData?.valediction || '',
    sender: props.initialData?.sender || '',
    imageLinks: props.initialData?.imageLinks || '',
    fileLinks: props.initialData?.fileLinks || '',
    body: props.initialData?.body || ''
  });

  const [errorMessages, setErrorMessages] = useState({});

  const handleSubmit = async () => {
    try {
      const validateObj = validateLiveData(emailData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = getEmailFormData(emailData);

        if (props.emailId) {
          props.editEmail(props.occasionId, props.emailId, formData, () => {
            setEmailData({
              name: '',
              subject: '',
              valediction: '',
              sender: '',
              imageLinks: '',
              fileLinks: '',
              body: ''
            });
            props.handleCloseClick();
          });
        } else {
          props.createEmail(props.occasionId, formData, () => {
            setEmailData({
              name: '',
              subject: '',
              valediction: '',
              sender: '',
              imageLinks: '',
              fileLinks: '',
              body: ''
            });
            props.handleCloseClick();
          });
        }
      }
    } catch (error) {
      console.error('Error in submitting form', error);
    }
  };

  const handleInputChange = (e) => {
    try {
      let value;
      let name;
      if (e) {
        const assignFormValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        setEmailData({
          ...emailData,
          [inputName]: assignFormValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="notification-create-section">
        <div className="notification-create">
          <Form className="notification-form-container" onFinish={handleSubmit}>
            <InputGroup
              label="Name"
              name="name"
              placeholder="Enter Name"
              onChange={handleInputChange}
              value={emailData?.name}
              errorMessage={errorMessages?.name}
            />
            <InputGroup
              label="Subject"
              name="subject"
              placeholder="Enter Subject"
              onChange={handleInputChange}
              value={emailData?.subject}
              errorMessage={errorMessages?.subject}
            />
            <InputGroup
              label="Valediction"
              name="valediction"
              placeholder="Enter Valediction"
              onChange={handleInputChange}
              value={emailData?.valediction}
              errorMessage={errorMessages?.valediction}
            />
            <InputGroup
              label="Sender"
              name="sender"
              placeholder="Enter Sender"
              onChange={handleInputChange}
              value={emailData?.sender}
              errorMessage={errorMessages?.sender}
            />
            <InputGroup
              label="Image Links"
              name="imageLinks"
              fieldType="textArea"
              placeholder="Enter Image Links (',' separated)"
              onChange={handleInputChange}
              value={emailData?.imageLinks}
            />
            <InputGroup
              label="File Links"
              name="fileLinks"
              fieldType="textArea"
              placeholder="Enter File Links (',' seperated)"
              onChange={handleInputChange}
              value={emailData?.fileLinks}
            />
            <InputGroup
              label="Body"
              name="body"
              fieldType="textArea"
              placeholder="Enter Body"
              onChange={handleInputChange}
              value={emailData?.body}
              errorMessage={errorMessages?.body}
            />
            <SubmitButton
              buttonText="Create"
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => {
  const occasionId = currentOccasionId(store, props);
  const fetchProgress = store.browserState.fetchProgress;
  const emailId = currentEmailId(store, props);
  const currentEmail = getCurrentEmail(store, props);

  let initialData = {};
  if (currentEmail) {
    initialData.name = currentEmail.name;
    initialData.subject = currentEmail.subject;
    initialData.valediction = currentEmail.valediction;
    initialData.sender = currentEmail.sender;
    initialData.body = '';
    initialData.fileLinks = '';
    initialData.imageLinks = '';
    currentEmail.body.forEach((element, index) => {
      if (index === currentEmail.body.length - 1) {
        initialData.body += element;
      } else {
        initialData.body += element + '\n';
      }
    });

    if (currentEmail.fileUrlLinks && currentEmail.fileUrlLinks?.length > 0) {
      currentEmail.fileUrlLinks.forEach((element, index) => {
        if (index === currentEmail.fileUrlLinks.length - 1) {
          initialData.fileLinks += element;
        } else {
          initialData.fileLinks += element + ', ';
        }
      });
    }

    if (
      currentEmail.imageAttachmentLinks &&
      currentEmail.imageAttachmentLinks?.length > 0
    ) {
      currentEmail.imageAttachmentLinks.forEach((element, index) => {
        if (index === currentEmail.imageAttachmentLinks.length - 1) {
          initialData.imageLinks += element;
        } else {
          initialData.imageLinks += element + ', ';
        }
      });
    }
  }
  return {
    occasionId,
    fetchProgress,
    emailId,
    initialData
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ createEmail, editEmail }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCreate);
