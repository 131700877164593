import { createSelector } from 'reselect';
import {
  allEntities,
  occasionEntities,
  userEntities,
  memberEntities,
  pendingInvitationEntities
} from './entity';
import {
  currentUserId,
  currentCollaboratorId,
  currentCollaboratorType,
  currentOccasionId,
  invitationStatus,
  currentInternalTeamOccasionId
} from './dynamicData';
import {
  participatedOccasionIds,
  searchOccasionIds,
  searchInternalMemberIds,
  usersPendingInvitationIds,
  usersPendingInvitationLinks,
  socianaInternalUserActiveData,
  socianaInternalUserInactiveData,
  socianaInternalUserUserAllIds,
  currentOccasionInternalMembers,
  currentOccasionInternalMemberLinks
} from './static';

export const getCurrentParticipatedOccasionIds = createSelector(
  currentUserId,
  participatedOccasionIds,
  (id, ids) => {
    return id && ids && ids[id];
  }
);

export const getInternalUsersParticipatedData = createSelector(
  getCurrentParticipatedOccasionIds,
  memberEntities,
  (ids, entities) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getInternalUsersParticipatedWithOccasion = createSelector(
  getInternalUsersParticipatedData,
  occasionEntities,
  (data, entities) => {
    return (
      data &&
      entities &&
      data?.length > 0 &&
      data.map((el) => ({ ...el, occasion: entities[el.occasion.id] }))
    );
  }
);

export const getSearchedParticipatedOccasions = createSelector(
  searchOccasionIds,
  occasionEntities,
  (ids, entities) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getSocianaInternalUsersActiveData = createSelector(
  socianaInternalUserActiveData,
  userEntities,
  (ids, entities) =>
    ids && entities && ids?.length > 0 && ids.map((id) => entities[id])
);

export const getSocianaInternalUsersInactiveData = createSelector(
  socianaInternalUserInactiveData,
  userEntities,
  (ids, entities) =>
    ids && entities && ids?.length > 0 && ids.map((id) => entities[id])
);

export const getUserDetails = createSelector(
  socianaInternalUserUserAllIds,
  currentUserId,
  userEntities,
  (allIds, id, entities) =>
    allIds && id && entities && allIds?.includes(id) && entities[id]
);

export const getSearchedInternalUsers = createSelector(
  searchInternalMemberIds,
  userEntities,
  (memIds, entities) =>
    (memIds &&
      memIds.length > 0 &&
      entities &&
      memIds?.map((id) => entities[id])) ||
    (Array.isArray(memIds) ? [] : null)
);

export const currentCollaboratorDetails = createSelector(
  currentCollaboratorId,
  currentCollaboratorType,
  allEntities,
  (id, type, entities) => id && type && entities[type] && entities[type][id]
);

export const getCurrentOccasionMembers = createSelector(
  currentOccasionId,
  currentOccasionInternalMembers,
  (ocId, ids) => {
    return ocId && ids && ids[ocId];
  }
);

export const getCurrentOccasionInternalTeam = createSelector(
  getCurrentOccasionMembers,
  memberEntities,
  userEntities,
  (ids, entities, users) => {
    return (
      ids &&
      users &&
      entities &&
      ids?.length > 0 &&
      ids.map((id) => {
        return {
          users: {
            ...users[entities[id]?.user?.id]
          },
          ...entities[id]
        };
      })
    );
  }
);

export const getCurrentOccasionInternalTeamLinks = createSelector(
  currentOccasionId,
  currentOccasionInternalMemberLinks,
  (id, links) => {
    return id && links && links[id];
  }
);

export const getCurrentCollaboratorDetails = createSelector(
  currentCollaboratorId,
  memberEntities,
  (id, entities) => id && entities && entities[id]
);

export const currentUserPendingInvitation = createSelector(
  currentUserId,
  usersPendingInvitationIds,
  (id, data) => id && data && data[id]
);

export const currentStatusInvitation = createSelector(
  currentUserPendingInvitation,
  invitationStatus,
  (data, status) => data && status && data[status]
);

export const getPendingInvitationsData = createSelector(
  currentStatusInvitation,
  pendingInvitationEntities,
  (ids, entities) =>
    ids && entities && ids?.length > 0 && ids.map((id) => entities[id])
);

export const getPendingInvitationWithOccasion = createSelector(
  getPendingInvitationsData,
  occasionEntities,
  (data, entities) => {
    return (
      data &&
      entities &&
      data?.length > 0 &&
      data.map((el) => ({ ...el, ...entities[el.occasion.id] }))
    );
  }
);

export const currentStatusInvitationLink = createSelector(
  invitationStatus,
  usersPendingInvitationLinks,
  (status, links) => status && links && links[status]
);

export const getCurrentInternalTeamOccasion = createSelector(
  currentInternalTeamOccasionId,
  memberEntities,
  (tId, ids) => {
    return tId && ids && ids[tId];
  }
);
