import { put, call, takeLatest, debounce } from 'redux-saga/effects';
import progressSaga from './progressSaga';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  multipleInvitationsApi,
  parse_csvApi,
  getOccasionPendingInvitationApi,
  getOccasionPendingRegistrationApi,
  pendingInvitationSearchApi,
  pendingRegistrationSearchApi,
  searchPendingInvitationApi,
  searchPendingRegistrationApi,
  resendPendingRegistrationApi,
  resendPendingInvitationApi,
  deleteOccasionPendingInvitationApi,
  deleteOccasionPendingRegistrationApi,
  updatePendingInvitationApi,
  updatePendingRegistrationApi
} from '../api/Invitation';
import {
  multipleInvitationsSuccess,
  multipleInvitationsFailed,
  parse_csvSuccess,
  parse_csvFailed,
  getOccasionPendingInvitationSuccess,
  getOccasionPendingInvitationFailed,
  getOccasionPendingRegistrationSuccess,
  getOccasionPendingRegistrationFailed,
  pendingInvitationSearchSuccess,
  pendingInvitationSearchFailed,
  pendingRegistrationSearchSuccess,
  pendingRegistrationSearchFailed,
  searchPendingInvitationSuccess,
  searchPendingInvitationFailed,
  searchPendingRegistrationsSuccess,
  searchPendingRegistrationsFailed,
  resendPendingInvitationSuccess,
  resendPendingRegistrationsSuccess,
  resendPendingRegistrationsFailed,
  resendPendingInvitationFailed,
  deleteOccasionPendingInvitationSuccess,
  deleteOccasionPendingInvitationFailed,
  deleteOccasionPendingRegistrationFailed,
  updatePendingInvitationSuccess,
  updatePendingInvitationFailed,
  updatePendingRegistrationSuccess,
  updatePendingRegistrationFailed
} from '../actions/Invitation';
import {
  DELETE_OCCASION_PENDING_INVITATION,
  DELETE_OCCASION_PENDING_REGISTRATION,
  GET_OCCASION_PENDING_INVITATION,
  GET_OCCASION_PENDING_REGISTRATION,
  MULTIPLE_INVITATIONS,
  PARSE_CSV,
  PENDING_INVITATION_SEARCH,
  PENDING_REGISTRATION_SEARCH,
  RESEND_PENDING_INVITATION,
  RESEND_PENDING_REGISTRATION,
  SEARCH_PENDING_INVITATIONS,
  SEARCH_PENDING_REGISTRATIONS,
  UPDATE_PENDING_INVITATION,
  UPDATE_PENDING_REGISTRATION
} from '../constants/actionTypes';

export function* multipelInvitationSaga(action) {
  try {
    const result = yield call(
      multipleInvitationsApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(multipleInvitationsSuccess(result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(multipleInvitationsFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(multipleInvitationsFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}
export function* parse_csvSaga(action) {
  try {
    const result = yield call(
      parse_csvApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(parse_csvSuccess(result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(parse_csvFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(parse_csvFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getOccasionPendingInvitationSaga(action) {
  try {
    const result = yield call(
      getOccasionPendingInvitationApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getOccasionPendingInvitationSuccess(
          result.data,
          result.links,
          result.included,
          action.payload.page,
          result.meta
        )
      );

      if (action.callback) {
        action.callback();
      }
    } else if (result?.meta && result?.meta?.message) {
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(getOccasionPendingInvitationFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getOccasionPendingRegistrationSaga(action) {
  try {
    const result = yield call(
      getOccasionPendingRegistrationApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getOccasionPendingRegistrationSuccess(
          result.data,
          result.links,
          result.included,
          action.payload.page,
          result.meta
        )
      );

      if (action.callback) {
        action.callback();
      }
    } else if (result?.meta && result?.meta?.message) {
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(getOccasionPendingRegistrationFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* pendingInvitationSearchSaga(action) {
  try {
    const result = yield call(
      pendingInvitationSearchApi,
      action.payload.ocId,
      action.payload.joinas,
      action.payload.status,
      action.payload.invitedby,
      action.payload.user,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        pendingInvitationSearchSuccess(
          result.data,
          result.included,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(pendingInvitationSearchFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* pendingRegistrationSearchSaga(action) {
  try {
    const result = yield call(
      pendingRegistrationSearchApi,
      action.payload.ocId,
      action.payload.text,
      action.payload.joinas,
      action.payload.invitedby,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        pendingRegistrationSearchSuccess(
          result.data,
          result.included,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(pendingRegistrationSearchFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* searchPendingInvitationSaga(action) {
  try {
    const result = yield call(
      searchPendingInvitationApi,
      action.payload.ocId,
      action.payload.query,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        searchPendingInvitationSuccess(
          result.data,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(searchPendingInvitationFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* searchPendingRegistrationsSaga(action) {
  try {
    const result = yield call(
      searchPendingRegistrationApi,
      action.payload.ocId,
      action.payload.query,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        searchPendingRegistrationsSuccess(
          result.data,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(searchPendingRegistrationsFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* resendPendingRegistrationSaga(action) {
  try {
    const result = yield call(
      resendPendingRegistrationApi,
      action.payload.ocId,
      action.payload.allOrId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        resendPendingRegistrationsSuccess(
          result.data,
          result.included,
          result.links
        )
      );
      if (result?.meta && result?.meta?.message) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(resendPendingRegistrationsFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* resendPendingInvitationSaga(action) {
  try {
    const result = yield call(
      resendPendingInvitationApi,
      action.payload.ocId,
      action.payload.allOrId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        resendPendingInvitationSuccess(
          result.data,
          result.included,
          result.links
        )
      );
      if (result?.meta && result?.meta?.message) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(resendPendingInvitationFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* deleteOccasionPendingInvitationSaga(action) {
  try {
    const result = yield call(
      deleteOccasionPendingInvitationApi,
      action.payload.ocId,
      action.payload.pendingInvitationId,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        deleteOccasionPendingInvitationSuccess(
          result.data,
          result.links,
          result.included
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteOccasionPendingInvitationFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* deleteOccasionPendingRegistrationSaga(action) {
  try {
    const result = yield call(
      deleteOccasionPendingRegistrationApi,
      action.payload.ocId,
      action.payload.pendingRegistrationId,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getOccasionPendingRegistrationSuccess(
          result.data,
          result.links,
          result.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteOccasionPendingRegistrationFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updatePendingInvitationSaga(action) {
  try {
    const result = yield call(
      updatePendingInvitationApi,
      action.payload.ocId,
      action.payload.pendingInvitationId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(updatePendingInvitationSuccess(result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updatePendingInvitationFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updatePendingInvitationFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updatePendingRegistrationSaga(action) {
  try {
    const result = yield call(
      updatePendingRegistrationApi,
      action.payload.ocId,
      action.payload.pendingRegistrationId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(updatePendingRegistrationSuccess(result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updatePendingRegistrationFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updatePendingRegistrationFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* invitationActionWatcher() {
  yield takeLatest(MULTIPLE_INVITATIONS, progressSaga, multipelInvitationSaga);
  yield takeLatest(PARSE_CSV, progressSaga, parse_csvSaga);
  yield takeLatest(
    GET_OCCASION_PENDING_INVITATION,
    progressSaga,
    getOccasionPendingInvitationSaga
  );
  yield takeLatest(
    GET_OCCASION_PENDING_REGISTRATION,
    progressSaga,
    getOccasionPendingRegistrationSaga
  );
  yield takeLatest(
    PENDING_INVITATION_SEARCH,
    progressSaga,
    pendingInvitationSearchSaga
  );
  yield takeLatest(
    PENDING_REGISTRATION_SEARCH,
    progressSaga,
    pendingRegistrationSearchSaga
  );
  yield takeLatest(
    RESEND_PENDING_REGISTRATION,
    progressSaga,
    resendPendingRegistrationSaga
  );
  yield takeLatest(
    RESEND_PENDING_INVITATION,
    progressSaga,
    resendPendingInvitationSaga
  );
  yield debounce(
    1000,
    SEARCH_PENDING_INVITATIONS,
    progressSaga,
    searchPendingInvitationSaga
  );
  yield debounce(
    1000,
    SEARCH_PENDING_REGISTRATIONS,
    progressSaga,
    searchPendingRegistrationsSaga
  );

  yield takeLatest(
    DELETE_OCCASION_PENDING_INVITATION,
    progressSaga,
    deleteOccasionPendingInvitationSaga
  );
  yield takeLatest(
    DELETE_OCCASION_PENDING_REGISTRATION,
    progressSaga,
    deleteOccasionPendingRegistrationSaga
  );
  yield takeLatest(
    UPDATE_PENDING_INVITATION,
    progressSaga,
    updatePendingInvitationSaga
  );
  yield takeLatest(
    UPDATE_PENDING_REGISTRATION,
    progressSaga,
    updatePendingRegistrationSaga
  );
}
