import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';

import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  ACTIVITY_TRACKER_CREATE,
  ACTIVITY_TRACKER_DOWNLOAD_EXSL_EXPORT,
  ACTIVITY_TRACKER_VIEW,
  BLACK_LIST_USER_CREATE,
  BLACK_LIST_USER_CREATE_BY_CSV,
  BLACK_LIST_USER_VIEW,
  DELETE_ACTIVITY_TRACKER,
  DELETE_BLACK_LIST_USER,
  UPDATE_ACTIVITY_TRACKER
} from '../constants/actionTypes';
import {
  activityTrackerCreateApi,
  activityTrackerDownloadExslExportApi,
  activityTrackerViewApi,
  blackListUserCreateApi,
  blackListUserCreateByCsvApi,
  blackListUserViewApi,
  deleteActivityTrackerApi,
  deleteBlackListUserApi,
  updateActivityTrackerApi
} from '../api/activityTracker';
import {
  activityTrackerCreateFailed,
  activityTrackerCreateSuccess,
  activityTrackerDownloadExslExportFailed,
  activityTrackerDownloadExslExportSuccess,
  activityTrackerViewFailed,
  activityTrackerViewSuccess,
  blackListUserCreateByCsvFailed,
  blackListUserCreateByCsvSuccess,
  blackListUserCreateFailed,
  blackListUserCreateSuccess,
  blackListUserViewFailed,
  blackListUserViewSuccess,
  deleteActivityTrackerFailed,
  deleteActivityTrackerSuccess,
  deleteBlackListUserFailed,
  deleteBlackListUserSuccess,
  updateActivityTrackerFailed,
  updateActivityTrackerSuccess
} from '../actions/activityTracker';

export function* activityTrackerCreateSaga(action) {
  try {
    const result = yield call(
      activityTrackerCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        activityTrackerCreateSuccess(
          result.data,
          result.included,
          action.payload.ocId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(activityTrackerCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(activityTrackerCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* activityTrackerViewSaga(action) {
  try {
    const result = yield call(
      activityTrackerViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        activityTrackerViewSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(activityTrackerViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* activityTrackerUpdateSaga(action) {
  try {
    const result = yield call(
      updateActivityTrackerApi,
      action.payload.ocId,
      action.payload.trackerId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateActivityTrackerSuccess(
          action.payload.ocId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateActivityTrackerFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateActivityTrackerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* activityTrackerDeleteSaga(action) {
  try {
    const result = yield call(
      deleteActivityTrackerApi,
      action.payload.ocId,
      action.payload.trackerId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(deleteActivityTrackerSuccess(action.payload.ocId, result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteActivityTrackerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* activityTrackerDownloadExslExportSaga(action) {
  try {
    const result = yield call(
      activityTrackerDownloadExslExportApi,
      action.payload.ocId,
      action.payload.trackerId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        activityTrackerDownloadExslExportSuccess(result.data, result.included)
      );
      if (typeof action.callback === 'function') {
        action.callback(result?.blobUrl);
      }
    }
  } catch (error) {
    yield put(activityTrackerDownloadExslExportFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* blackListUserDeleteSaga(action) {
  try {
    const result = yield call(
      deleteBlackListUserApi,
      action.payload.ocId,
      action.payload.userId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(deleteBlackListUserSuccess(action.payload.ocId, result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteBlackListUserFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* blackListUserViewSaga(action) {
  try {
    const result = yield call(
      blackListUserViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        blackListUserViewSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(blackListUserViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* blackListUserCreateSaga(action) {
  try {
    const result = yield call(
      blackListUserCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        blackListUserCreateSuccess(
          result.data,
          result.included,
          action.payload.ocId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(blackListUserCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(blackListUserCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* blackListUserCreateByCsvSaga(action) {
  try {
    const result = yield call(
      blackListUserCreateByCsvApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        blackListUserCreateByCsvSuccess(
          result.data,
          result.included,
          action.payload.ocId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(blackListUserCreateByCsvFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(blackListUserCreateByCsvFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* activityTrackerActionWatcher() {
  yield takeLatest(
    ACTIVITY_TRACKER_CREATE,
    progressSaga,
    activityTrackerCreateSaga
  );
  yield takeLatest(
    ACTIVITY_TRACKER_VIEW,
    progressSaga,
    activityTrackerViewSaga
  );
  yield takeLatest(
    UPDATE_ACTIVITY_TRACKER,
    progressSaga,
    activityTrackerUpdateSaga
  );
  yield takeLatest(
    DELETE_ACTIVITY_TRACKER,
    progressSaga,
    activityTrackerDeleteSaga
  );
  yield takeLatest(
    ACTIVITY_TRACKER_DOWNLOAD_EXSL_EXPORT,
    progressSaga,
    activityTrackerDownloadExslExportSaga
  );
  yield takeLatest(
    DELETE_BLACK_LIST_USER,
    progressSaga,
    blackListUserDeleteSaga
  );
  yield takeLatest(BLACK_LIST_USER_VIEW, progressSaga, blackListUserViewSaga);
  yield takeLatest(
    BLACK_LIST_USER_CREATE,
    progressSaga,
    blackListUserCreateSaga
  );
  yield takeLatest(
    BLACK_LIST_USER_CREATE_BY_CSV,
    progressSaga,
    blackListUserCreateByCsvSaga
  );
}
