import { put, call, takeLatest } from 'redux-saga/effects';
import progressSaga from './progressSaga';
import {
  viewEmailApi,
  createEmailApi,
  editEmailApi,
  deleteEmailApi,
  sendEmailSaverApi
} from '../api/notifications';
import {
  VIEW_EMAILS,
  CREATE_EMAIL,
  EDIT_EMAIL,
  DELETE_EMAIL,
  SEND_EMAIL_SAVER
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  createEmailSuccess,
  createEmailFailed,
  viewEmailsSuccess,
  viewEmailsFailed,
  editEmailSuccess,
  editEmailFailed,
  deleteEmailSuccess,
  deleteEmailFailed,
  sendEmailSaverSuccess,
  sendEmailSaverFailed
} from '../actions/notifications';

export function* createEmailSaga(action) {
  try {
    const result = yield call(
      createEmailApi,
      action.payload.occasionId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        createEmailSuccess(action.payload.occasionId, result.data, result.links)
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(createEmailFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* viewEmailsSaga(action) {
  try {
    const result = yield call(
      viewEmailApi,
      action.payload.occasionId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        viewEmailsSuccess(
          action.payload.occasionId,
          result.data,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(viewEmailsFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* editEmailsSaga(action) {
  try {
    const result = yield call(
      editEmailApi,
      action.payload.occasionId,
      action.payload.emailId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        editEmailSuccess(
          action.payload.occasionId,
          result.data,
          result.included,
          result.links
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(editEmailFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* deleteEmailsSaga(action) {
  try {
    const result = yield call(
      deleteEmailApi,
      action.payload.occasionId,
      action.payload.emailId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        deleteEmailSuccess(
          action.payload.occasionId,
          result.data,
          result.included,
          result.links
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteEmailFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* sendEmailSaverSaga(action) {
  try {
    const result = yield call(
      sendEmailSaverApi,
      action.payload.occasionId,
      action.payload.emailId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        sendEmailSaverSuccess(
          action.payload.occasionId,
          result.data,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(sendEmailSaverFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export default function* notificationsActionWatcher() {
  yield takeLatest(CREATE_EMAIL, progressSaga, createEmailSaga);
  yield takeLatest(VIEW_EMAILS, progressSaga, viewEmailsSaga);
  yield takeLatest(DELETE_EMAIL, progressSaga, deleteEmailsSaga);
  yield takeLatest(EDIT_EMAIL, progressSaga, editEmailsSaga);
  yield takeLatest(SEND_EMAIL_SAVER, progressSaga, sendEmailSaverSaga);
}
