import { union } from 'lodash';
import {
  USER_OCCASION_VIEW_SUCCESS,
  USER_OCCASION_VIEW_FAILED
} from '../constants/actionTypes';

const initialState = {
  userOccasionData: null,
  error: null,
  userOccasionLinks: null
};

const userOccasion = (state = initialState, action) => {
  switch (action.type) {
    case USER_OCCASION_VIEW_SUCCESS: {
      const { data, userId, links } = action.payload;
      const userGroupedData = state.userOccasionData || {};
      const NewIds = data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        userOccasionData: {
          ...userGroupedData,
          [userId]: [...NewIds]
        },
        userOccasionLinks: links
      };
    }

    case USER_OCCASION_VIEW_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default userOccasion;
