import { union } from 'lodash';
import {
  GET_LOCATION_TRIGGER_SUCCESS,
  GET_LOCATION_TRIGGER_FAILED,
  CREATE_LOCATION_TRIGGER_SUCCESS,
  CREATE_LOCATION_TRIGGER_FAILED,
  GET_ACTION_TRIGGER_FAILED,
  GET_ACTION_TRIGGER_SUCCESS,
  CREATE_ACTION_TRIGGER_FAILED,
  CREATE_ACTION_TRIGGER_SUCCESS,
  CREATE_TIME_TRIGGER_FAILED,
  CREATE_TIME_TRIGGER_SUCCESS,
  GET_TIME_TRIGGER_FAILED,
  GET_TIME_TRIGGER_SUCCESS,
  DELETE_TIME_TRIGGER_SUCCESS,
  UPDATE_LOCATION_TRIGGER_FAILED,
  UPDATE_ACTION_TRIGGER_FAILED,
  DELETE_ACTION_TRIGGER_FAILED,
  DELETE_LOCATION_TRIGGER_FAILED,
  DELETE_ACTION_TRIGGER_SUCCESS,
  DELETE_LOCATION_TRIGGER_SUCCESS,
  SEARCH_TIME_TRIGGER_BY_PINNED_SUCCESS,
  SEARCH_TIME_TRIGGER_BY_PINNED_FAILED
} from '../constants/actionTypes';
const initialState = {
  locationTriggerData: null,
  actionTriggerData: null,
  timeTriggerData: null,
  timeTriggerLinks: null,
  locationTriggerLinks: null,
  actionTriggerLinks: null,
  error: null
};

const trigger = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCATION_TRIGGER_SUCCESS: {
      const { data, occasionId, links } = action.payload;
      const locationTriggerGroupedData = state.locationTriggerData || {};
      const currentOccasionNewLocationTriggerIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        locationTriggerData: {
          ...locationTriggerGroupedData,
          [occasionId]: currentOccasionNewLocationTriggerIds
        },
        locationTriggerLinks: links
      };
    }

    case CREATE_LOCATION_TRIGGER_SUCCESS: {
      const { data, occasionId } = action.payload;
      const locationTriggerGroupedData = state.locationTriggerData || {};
      const currentOccasionLocationTriggerIds =
        locationTriggerGroupedData[occasionId] || [];

      const newIds = [data.id, ...currentOccasionLocationTriggerIds];
      return {
        ...state,
        locationTriggerData: {
          ...locationTriggerGroupedData,
          [occasionId]: newIds
        }
      };
    }

    case GET_ACTION_TRIGGER_SUCCESS: {
      const { data, occasionId, links } = action.payload;
      const actionTriggerGroupedData = state.actionTriggerData || {};
      const currentOccasionNewActionTriggerIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        actionTriggerData: {
          ...actionTriggerGroupedData,
          [occasionId]: currentOccasionNewActionTriggerIds
        },
        actionTriggerLinks: links
      };
    }

    case CREATE_ACTION_TRIGGER_SUCCESS: {
      const { data, occasionId } = action.payload;
      const actionTriggerGroupedData = state.actionTriggerData || {};
      const currentOccasionActionTriggerIds =
        actionTriggerGroupedData[occasionId] || [];

      const newIds = [data.id, ...currentOccasionActionTriggerIds];
      return {
        ...state,
        actionTriggerData: {
          ...actionTriggerGroupedData,
          [occasionId]: newIds
        }
      };
    }

    case SEARCH_TIME_TRIGGER_BY_PINNED_SUCCESS:
    case GET_TIME_TRIGGER_SUCCESS: {
      const { data, occasionId, links } = action.payload;
      const timeTriggerGroupedData = state.timeTriggerData || {};
      const currentOccasionNewTimeTriggerIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        timeTriggerData: {
          ...timeTriggerGroupedData,
          [occasionId]: currentOccasionNewTimeTriggerIds
        },
        timeTriggerLinks: links
      };
    }

    case CREATE_TIME_TRIGGER_SUCCESS: {
      const { data, occasionId } = action.payload;
      const timeTriggerGroupedData = state.timeTriggerData || {};
      const currentOccasionTimeTriggerIds =
        timeTriggerGroupedData[occasionId] || [];

      const newIds = [data.id, ...currentOccasionTimeTriggerIds];
      return {
        ...state,
        timeTriggerData: {
          ...timeTriggerGroupedData,
          [occasionId]: newIds
        }
      };
    }

    case DELETE_TIME_TRIGGER_SUCCESS: {
      const { data, occasionId } = action.payload;
      const timeTriggerGroupedData = state.timeTriggerData || {};
      const currentOccasionTimeTriggerIds =
        timeTriggerGroupedData[occasionId] || [];
      const filteredIds = currentOccasionTimeTriggerIds.filter(
        (id) => id !== data.id
      );

      return {
        ...state,
        timeTriggerData: {
          ...timeTriggerGroupedData,
          [occasionId]: filteredIds
        }
      };
    }

    case DELETE_LOCATION_TRIGGER_SUCCESS: {
      const { data, occasionId } = action.payload;
      const locationTriggerGroupedData = state.locationTriggerData || {};
      const currentOccasionLocationTriggerIds =
        locationTriggerGroupedData[occasionId] || [];
      const filteredIds = currentOccasionLocationTriggerIds.filter(
        (id) => id !== data?.id
      );

      return {
        ...state,
        locationTriggerData: {
          ...locationTriggerGroupedData,
          [occasionId]: filteredIds
        }
      };
    }

    case DELETE_ACTION_TRIGGER_SUCCESS: {
      const { data, occasionId } = action.payload;
      const actionTriggerGroupedData = state.actionTriggerData || {};
      const currentOccasionActionTriggerIds =
        actionTriggerGroupedData[occasionId] || [];
      const filteredIds = currentOccasionActionTriggerIds.filter(
        (id) => id !== data?.id
      );

      return {
        ...state,
        actionTriggerData: {
          ...actionTriggerGroupedData,
          [occasionId]: filteredIds
        }
      };
    }
    case SEARCH_TIME_TRIGGER_BY_PINNED_FAILED:
    case UPDATE_LOCATION_TRIGGER_FAILED:
    case UPDATE_ACTION_TRIGGER_FAILED:
    case DELETE_ACTION_TRIGGER_FAILED:
    case DELETE_LOCATION_TRIGGER_FAILED:
    case CREATE_TIME_TRIGGER_FAILED:
    case GET_TIME_TRIGGER_FAILED:
    case GET_ACTION_TRIGGER_FAILED:
    case CREATE_ACTION_TRIGGER_FAILED:
    case GET_LOCATION_TRIGGER_FAILED:
    case CREATE_LOCATION_TRIGGER_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default trigger;
