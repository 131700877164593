import { union } from 'lodash';
import {
  EVENT_AFFAIR_ENTERTAINMENT_VIEW_SUCCESS,
  EVENT_AFFAIR_ENTERTAINMENT_CREATE_SUCCESS,
  EVENT_AFFAIR_ENTERTAINMENT_VIEW_FAILED,
  EVENT_AFFAIR_ENTERTAINMENT_CREATE_FAILED,
  EVENT_AFFAIR_ENTERTAINMENT_DELETE_SUCCESS,
  EVENT_AFFAIR_ENTERTAINMENT_DELETE_FAILED
} from '../constants/actionTypes';

const initialState = {
  eventAffairEntertainmentData: null,
  eventAffairEntertainmentLinks: null,
  error: null
};

const eventAffairEntertainment = (state = initialState, action) => {
  switch (action.type) {
    case EVENT_AFFAIR_ENTERTAINMENT_VIEW_SUCCESS: {
      const { ocId, eventId, eventAffairId, data, links } = action.payload;
      const occasionGroupedData = state.eventAffairEntertainmentData || {};
      const currentEventEntertainmentNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        eventAffairEntertainmentData: {
          ...occasionGroupedData,
          [eventAffairId]: [...currentEventEntertainmentNewIds]
        },
        eventAffairEntertainmentLinks: links
      };
    }

    case EVENT_AFFAIR_ENTERTAINMENT_CREATE_SUCCESS: {
      const { ocId, eventId, eventAffairId, data } = action.payload;
      const occasionGroupedData = state.eventAffairEntertainmentData || {};
      const currentEventAffairEntertainmentIds =
        occasionGroupedData[eventAffairId] || [];

      const newIds = [data.id, ...currentEventAffairEntertainmentIds];
      return {
        ...state,
        eventAffairEntertainmentData: {
          ...occasionGroupedData,
          [eventAffairId]: newIds
        }
      };
    }

    case EVENT_AFFAIR_ENTERTAINMENT_DELETE_SUCCESS: {
      const { ocId, eventId, eventAffairId, data } = action.payload;
      const occasionGroupedData = state.eventAffairEntertainmentData || {};
      const currentEventAffairEntertainmentIds =
        occasionGroupedData[eventAffairId] || [];

      const filterIds = currentEventAffairEntertainmentIds.filter(
        (id) => id !== data.id
      );
      return {
        ...state,
        eventAffairEntertainmentData: {
          ...occasionGroupedData,
          [eventAffairId]: filterIds
        }
      };
    }

    case EVENT_AFFAIR_ENTERTAINMENT_VIEW_FAILED:
    case EVENT_AFFAIR_ENTERTAINMENT_CREATE_FAILED:
    case EVENT_AFFAIR_ENTERTAINMENT_DELETE_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default eventAffairEntertainment;
