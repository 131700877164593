import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { SUBDIVISION_FLAG } from './DivisionConstant';
import { occasionDivisionCreate } from '../../../../../../actions/division';

const validateDivision = (values) => {
  const error = {};
  if (!values.title) {
    error.title = 'Title is required';
  }
  if (!values.priority) {
    error.priority = 'Priority is required';
  }
  return error;
};

function DivisionCreate(props) {
  const [divisionData, setDivisionData] = React.useState({});
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleSubmit = () => {
    try {
      const validateObj = validateDivision(divisionData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('division_segment[title]', divisionData.title);
        formData.append(
          'division_segment[reference_name]',
          divisionData.name ? divisionData.name : null
        );
        formData.append(
          'division_segment[description]',
          divisionData.description ? divisionData.description : null
        );
        formData.append(
          'division_segment[occasion_segment_view_priority]',
          divisionData.priority
        );
        formData.append(
          'division_segment[reference_link]',
          divisionData.link ? divisionData.link : null
        );
        formData.append(
          'division_segment[subdivision_flag]',
          divisionData.flag ? divisionData.flag : null
        );
        props.occasionDivisionCreate(props.occasionId, formData);
        setDivisionData({});
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;

      const formValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setDivisionData({
        ...divisionData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ErrorBoundary>
      <SectionContent className="division-create-section">
        <div className="division-create-form">
          <Form className="division-form" onFinish={handleSubmit}>
            <InputGroup
              label="Title"
              name="title"
              placeholder="Enter Title"
              onChange={handleInputChange}
              value={divisionData?.title}
              errorMessage={errorMessages?.title}
            />
            <InputGroup
              label="Reference Name"
              name="name"
              placeholder="Enter Reference Name"
              onChange={handleInputChange}
              value={divisionData?.name}
            />
            <InputGroup
              label="Priority"
              type="Number"
              name="priority"
              placeholder="Enter Priority"
              onChange={handleInputChange}
              value={divisionData?.priority}
              errorMessage={errorMessages?.priority}
            />
            <InputGroup
              label="Description"
              name="description"
              fieldType="textArea"
              placeholder="Enter Description"
              onChange={handleInputChange}
              value={divisionData?.description}
            />
            <InputGroup
              label="Reference Link"
              name="link"
              placeholder="Enter Reference Link"
              onChange={handleInputChange}
              value={divisionData?.link}
            />
            <InputGroup
              label="Subdivision Flag"
              fieldType="dropdown"
              placeholder="Select"
              options={SUBDIVISION_FLAG}
              name="flag"
              onChange={handleInputChange}
              value={divisionData?.flag}
            />
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={'Create'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ occasionDivisionCreate }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DivisionCreate);
