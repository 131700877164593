import request from './request';

export function createFeatureControlApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/feature_availabilities`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function updateFeatureControlApi(ocId, acId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/feature_availabilities/${acId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function getFeatureControlsApi(ocId, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/feature_availabilities`, {
    method: 'GET',
    token
  });
}
