import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import {
  getTimeTrigger,
  deleteTimeTrigger,
  searchTimeTriggerByPinned
} from '../../../../../../actions/trigger';
import { Button, Form } from 'antd';
import InputGroup from '../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { timeTriggers } from '../../../../../../selectors/trigger';
import TimeTriggerCreate from './create';
import { SEARCH_TYPES } from './constent';

const TimeTriggerView = (props) => {
  const [createOpen, setCreateOpen] = useState(false);
  const [getUserDetails, setGetUserDetails] = React.useState({});
  const [pinnedSearchData, setPinnedSearchData] = useState({
    searchByData: ''
  });
  const [triggerId, setTriggerId] = useState(null);
  const [page, setPage] = React.useState(1);
  const location = useLocation();
  const onPageChange = (page) => {
    setPage(page);
    pinnedSearchData?.searchByData === 'all' ||
    pinnedSearchData?.searchByData === ''
      ? props.getTimeTrigger(
          props.occasionId,
          page,
          props.eventId ? props.eventId : props.occasionId,
          props.eventId ? 'Event' : 'Occasion'
        )
      : props.searchTimeTriggerByPinned(
          props.occasionId,
          page,
          pinnedSearchData.searchByData,
          props.eventId ? props.eventId : props.occasionId,
          props.eventId ? 'Event' : 'Occasion'
        );
  };

  const validate = (values) => {
    const errors = {};
    if (!values.searchData && !values.fromInternalTeam && !values.status)
      errors.type = 'Search Data is required';

    return errors;
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handlegetUserDetails = (page) => {
    try {
      const validateObj = validate(getUserDetails);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        props.getUserBySearch(
          getUserDetails.searchData,
          getUserDetails.fromInternalTeam,
          getUserDetails.status,
          (page = 1)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  const submitSearchByPinned = () => {
    try {
      pinnedSearchData.searchByData === 'all'
        ? props.getTimeTrigger(
            props.occasionId,
            page,
            props.eventId ? props.eventId : props.occasionId,
            props.eventId ? 'Event' : 'Occasion'
          )
        : props.searchTimeTriggerByPinned(
            props.occasionId,
            page,
            pinnedSearchData.searchByData,
            props.eventId ? props.eventId : props.occasionId,
            props.eventId ? 'Event' : 'Occasion'
          );
    } catch (error) {
      console.error(error);
    }
  };
  const handleSearch = (e, type) => {
    try {
      let value;
      let name;
      const pinnedSearchValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      setPinnedSearchData({
        ...pinnedSearchData,
        [inputName]: pinnedSearchValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'searchData') {
        value = e.target.value;
        name = type;
      }
      const userDetailsValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      setGetUserDetails({
        ...getUserDetails,
        [inputName]: userDetailsValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleReset = (page) => {
    setGetUserDetails({});
    setErrorMessages({});
    props.getUserWithPagination((page = 1));
  };

  const handleCreate = () => {
    setTriggerId('');
    setCreateOpen(true);
  };

  const paginationOptions = {
    showTotal: () => (
      <>
        <div className="button-box">
          <Button className="refresh-button" onClick={() => onPageChange(page)}>
            <SyncOutlined />
          </Button>
          <Button className="create-button" onClick={handleCreate}>
            Create
          </Button>
        </div>
      </>
    )
  };

  const editData = (id) => {
    setCreateOpen(true);
    setTriggerId(id);
  };

  const deleteData = (id) => {
    props.deleteTimeTrigger(props.occasionId, id);
  };

  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="time-trigger-view-section">
          <div className="time-trigger-view-container">
            <Form
              className="user-input-search-container"
              onFinish={handlegetUserDetails}>
              <InputGroup
                label="Name/Phone/Email"
                className="user-inputbox"
                fieldType="searchbox"
                placeholder="Enter Name/Phone/Email"
                name="searchData"
                onChange={handleChange}
                value={getUserDetails?.searchData}
              />
              {/* <InputGroup
                label="From Internal Team"
                className="user-inputbox"
                fieldType="dropdown"
                placeholder="Select"
                options={[
                  { label: 'True', value: 'true' },
                  { label: 'False', value: 'false' }
                ]}
                name="fromInternalTeam"
                onChange={handleChange}
                value={getUserDetails?.fromInternalTeam}
              /> */}
              <div style={{ flex: 1, display: 'flex' }}>
                <SubmitButton
                  wrapperClass="user-submit-wrapper"
                  className="user-submit-inputbox"
                  buttonText="Search"
                  fetchProgress={props.fetchProgress}
                  disabled={props.fetchProgress}
                />
                <Button className="reset-button" onClick={handleReset}>
                  Reset
                </Button>
              </div>
            </Form>
            <Form
              className="trigger-input-search-container"
              onFinish={submitSearchByPinned}>
              <InputGroup
                className="time-trigger-inputbox"
                label="Search by Pinned"
                fieldType="dropdown"
                placeholder="Select"
                options={SEARCH_TYPES}
                name="searchByData"
                onChange={handleSearch}
                value={pinnedSearchData?.searchByData}
              />
              <SubmitButton
                className="tigger-submit-inputbox"
                buttonText="Search"
                fetchProgress={props.fetchProgress}
                disabled={props.fetchProgress}
              />
            </Form>
            <TableView
              paginationOptions={paginationOptions}
              onPageChange={onPageChange}
              isPaginated={true}
              pathname={`${location.pathname}/timeTrigger/view`}
              onDelete={deleteData}
              isEditable={editData}
              data={props.timeTriggerData}
              fetchProgress={props.fetchProgress}
              normalTime={true}
              paginationLinks={props.timeTriggerLinks}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }

  return (
    <div className="edit-container">
      <TimeTriggerCreate
        triggerId={triggerId}
        occasionId={props.occasionId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  timeTriggerData: timeTriggers(store, props),
  timeTriggerLinks: store.trigger.timeTriggerLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { getTimeTrigger, deleteTimeTrigger, searchTimeTriggerByPinned },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(TimeTriggerView);
