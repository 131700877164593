import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  EditOutlined,
  ZoomInOutlined,
  CloseOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import { getImageSource } from '../../../../helpers/imageHelper';
import CardImage from '../../../../components/Table/CardImage';
import AdminCreate from '../Create/index';
import { ADMIN_ACCESS_TYPES } from '../../../../constants/OtherConstants';
import { Form, Button } from 'antd';
import InputGroup from '../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../components/InputGroup/FormComponents';
import {
  capitalize,
  changeUnderscoreToSpace
} from '../../../../helpers/stringHelper';
import { useLocation } from 'react-router-dom';
import { Switch } from '../../../../components/InputGroup/FormComponents';
import { updateAdmin } from '../../../../actions/admin';

const AdminDetails = ({ adminDetails, fetchProgress, updateAdmin }) => {
  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const [adminTypeEditOpen, setAdminTypeEditOpen] = React.useState(false);
  const [adminValidTillEditOpen, setAdminValidTillEditOpen] = React.useState(
    false
  );
  const [adminEditData, setAdminEditData] = React.useState({
    accessType: adminDetails?.accessType || '',
    validTill:
      adminDetails?.validTill && moment(adminDetails?.validTill, 'DD/MM/YYYY')
  });

  const dob = adminDetails?.dob;
  const location = useLocation();

  const onSubmit = (e) => {
    const data = new FormData();
    data.append('sociana_admin[current_status]', e ? 'active' : 'inactive');
    data.append('sociana_admin[access_type]', adminEditData?.accessType);
    data.append(
      'sociana_admin[valid_till]',
      adminEditData?.validTill.format('DD/MM/YYYY')
    );
    updateAdmin(adminDetails?.id, data, () => {
      setAdminValidTillEditOpen(false);
      setAdminTypeEditOpen(false);
    });
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'validTill') {
        value = moment(e, 'DD/MM/YYYY');
        name = type;
      }
      const inputValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;

      setAdminEditData({
        ...adminEditData,
        [inputName]: inputValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  if (!isEditOpen) {
    return (
      <div className="user-details-container">
        {!!adminDetails?.avatarUrl && (
          <p className="user-details avatar-details">
            <p className="fields">Avatar:</p>
            <p className="fields-data avatar-image-container">
              <CardImage data={adminDetails?.avatarUrl} />
            </p>
          </p>
        )}

        <p className="user-details">
          <p className="fields">Name:</p>
          <p className="field-data">{adminDetails?.name}</p>
        </p>
        <p className="user-details">
          <p className="fields">Email Address:</p>
          <p className="field-data">{adminDetails?.email}</p>
        </p>
        <p className="user-details">
          <p className="fields">Phone Number:</p>
          <p className="field-data">{adminDetails?.phone}</p>
        </p>
        <p className="user-details">
          <p className="fields">Date of Birth:</p>
          <p className="field-data">{dob ? dob : 'Not Provided'}</p>
        </p>

        {!adminValidTillEditOpen ? (
          <div className="admin-type-details">
            <b>Valid Till:</b>{' '}
            <div className="admin-type-details-content">
              <span className="admin-type-span">
                {adminDetails?.validTill
                  ? adminDetails.validTill
                  : 'Not Provided'}
              </span>
              <button
                className="admin-type-edit-button"
                onClick={() => setAdminValidTillEditOpen(true)}>
                <CalendarOutlined />
              </button>
            </div>
          </div>
        ) : (
          <div className="admin-type-edit-details">
            <b>Valid Till:</b>
            <div className="admin-type-edit-container">
              <Form className="admin-type-edit-form" onFinish={onSubmit}>
                <InputGroup
                  fieldType="datePicker"
                  className="admin-valid-till-edit-form-input"
                  onChange={handleChange}
                  name="validTill"
                  value={adminEditData?.validTill}
                />
                <SubmitButton
                  className="admin-valid-till-edit-form-submitButton"
                  buttonText="Save"></SubmitButton>
              </Form>
              <button
                className="admin-type-close-button"
                onClick={() => setAdminValidTillEditOpen(false)}>
                <CloseOutlined />
              </button>
            </div>
          </div>
        )}

        <p className="user-details">
          <p className="fields">Gender:</p>
          <p className="field-data">
            {capitalize(changeUnderscoreToSpace(adminDetails?.gender))}
          </p>
        </p>
        <p className="user-details">
          <p className="fields">Current Status:</p>
          <p className="field-data">
            {capitalize(adminDetails?.currentStatus)}
            <Switch
              loading={fetchProgress}
              value={adminDetails?.currentStatus === 'active'}
              style={{ marginLeft: 10 }}
              onChange={(e) => onSubmit(e)}
            />
          </p>
        </p>

        {!adminTypeEditOpen ? (
          <div className="admin-type-details">
            <b>Access Type:</b>{' '}
            <div className="admin-type-details-content">
              <span className="admin-type-span">
                {capitalize(changeUnderscoreToSpace(adminDetails?.accessType))}
              </span>
              <button
                className="admin-type-edit-button"
                onClick={() => setAdminTypeEditOpen(true)}>
                <EditOutlined />
                <span className="details">Edit</span>
              </button>
            </div>
          </div>
        ) : (
          <div className="admin-type-edit-details">
            <b>Access Type:</b>
            <div className="admin-type-edit-container">
              <Form className="admin-type-edit-form" onFinish={onSubmit}>
                <InputGroup
                  fieldType="dropdown"
                  options={ADMIN_ACCESS_TYPES}
                  name="accessType"
                  className="admin-type-edit-form-input"
                  onChange={handleChange}
                  value={adminEditData?.accessType}
                />
                <SubmitButton
                  className="admin-type-edit-form-submitButton"
                  buttonText="Save Changes"></SubmitButton>
              </Form>
              <button
                className="admin-type-close-button"
                onClick={() => setAdminTypeEditOpen(false)}>
                <CloseOutlined />
              </button>
            </div>
          </div>
        )}

        <button
          className="edit-details-container"
          onClick={() => setIsEditOpen(true)}>
          <EditOutlined />
          <span>Edit Details</span>
        </button>
      </div>
    );
  } else {
    return (
      <div className="edit-container">
        <AdminCreate
          location={location}
          allowAccessToPage
          adminId={adminDetails?.id}
          handleCloseClick={() => setIsEditOpen(false)}
        />
      </div>
    );
  }
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateAdmin }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDetails);
