import { Empty, List, Button } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SyncOutlined } from '@ant-design/icons';
import { getPostWithPagination, deletePost } from '../../../../../actions/post';
import PostCard from '../../../../../components/PostCard';
import PostCreate from './Create';
import { currentOccasionId } from '../../../../../selectors/dynamicData';
import { getEventPostsWithPostedByData } from '../../../../../selectors/post';
import { eventPostLinks } from '../../../../../selectors/static';

const EventPostsView = (props) => {
  const [page, setPage] = useState(1);
  const [isEditOpen, setEditOpen] = useState(null);

  const toggleEdit = (id) => {
    setEditOpen(id);
  };

  useEffect(() => {
    props.getPostWithPagination(props.occasionId, 'Event', page);
  }, [page]);

  const onPageRefresh = (page) => {
    props.getPostWithPagination(props.occasionId, 'Event', page);
  };

  const onEdit = (id) => {
    toggleEdit(id);
  };

  const onDelete = (id) => {
    props.deletePost(props.occasionId, 'Event', id);
  };

  const postRenderItem = (item) => (
    <PostCard
      id={item?.id}
      key={item?.id}
      totalComment={item?.totalComment}
      hashTag={item?.hashTag}
      pictureUrl={item?.pictureUrl}
      message={item?.message}
      postedByDetails={item?.postedByDetails}
      cdnPictureUrl={item?.cdnPictureUrl}
      videoUrls={item?.videoUrls}
      cdnVideoUrls={item?.cdnPictureUrls}
      likeCount={item?.likeCount}
      occasionId={props.occasionId}
      onDelete={onDelete}
      onEdit={onEdit}
      disableLike={true}
      disableComment={true}
      createdAt={item?.createdAt}
      events={item?.events}
    />
  );

  const getNextPage = (str) => {
    const str1 = str.split('=');
    const res = str1[1].split('&');
    return parseInt(res[0], 10);
  };

  const onLoadMore = () => {
    if (!!props.postLinks?.next) {
      const pageNumber = getNextPage(props?.postLinks?.next);
      setPage(pageNumber);
    }
  };

  const loadMore = useMemo(() => {
    return !!props.postLinks?.next ? (
      <div className="load-more">
        <Button onClick={onLoadMore}>loading more</Button>
      </div>
    ) : null;
  }, [props?.postLinks?.next]);

  if (!isEditOpen)
    return (
      <div className="post-container">
        <div className="button-box">
          <Button
            className="refresh-button"
            onClick={() => onPageRefresh(page)}>
            <SyncOutlined />
          </Button>
        </div>
        {props.data?.length > 0 ? (
          <List
            dataSource={props?.data}
            renderItem={postRenderItem}
            loading={props.fetchProgress}
            loadMore={loadMore}
          />
        ) : (
          <Empty />
        )}
      </div>
    );

  return (
    <div className="post-container">
      <PostCreate
        postId={isEditOpen}
        occasionId={props.occasionId}
        toggleEdit={toggleEdit}
        postableType="Event"
      />
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  occasionId: currentOccasionId(store, props),
  data: getEventPostsWithPostedByData(store, props),
  fetchProgress: store.browserState.fetchProgress,
  postLinks: eventPostLinks(store, props)
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getPostWithPagination, deletePost }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EventPostsView);
