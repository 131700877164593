import request from './request';

export function activityTrackerCreateApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/activity_tracker/activity_trackers`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function activityTrackerViewApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/activity_tracker/activity_trackers?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function deleteActivityTrackerApi(ocId, trackerId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/activity_tracker/activity_trackers/${trackerId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function updateActivityTrackerApi(ocId, trackerId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/activity_tracker/activity_trackers/${trackerId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function activityTrackerDownloadExslExportApi(ocId, trackerId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/activity_tracker/activity_trackers/${trackerId}/xlsx_export_activity_tracker_Present_user_details`,
    {
      method: 'GET',
      token,
      isBlob: true
    }
  );
}

export function blackListUserCreateByCsvApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/create_activity_tracker_black_list_by_csv`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function blackListUserCreateApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/activity_tracker_black_lists`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function blackListUserViewApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/activity_tracker_black_lists?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function deleteBlackListUserApi(ocId, userId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/activity_tracker_black_lists/${userId}`,
    {
      method: 'DELETE',
      token
    }
  );
}
