import {
  CREATE_PARTICIPANT,
  CREATE_PARTICIPANT_BY_CSV,
  CREATE_PARTICIPANT_BY_CSV_FAILED,
  CREATE_PARTICIPANT_BY_CSV_SUCCESS,
  CREATE_PARTICIPANT_FAILED,
  CREATE_PARTICIPANT_SUCCESS,
  GET_PARTICIPANT,
  GET_PARTICIPANT_FAILED,
  GET_PARTICIPANT_SUCCESS,
  DELETE_PARTICIPANT,
  DELETE_PARTICIPANT_FAILED,
  DELETE_PARTICIPANT_SUCCESS,
  UPDATE_PARTICIPANT,
  UPDATE_PARTICIPANT_FAILED,
  UPDATE_PARTICIPANT_SUCCESS
} from '../constants/actionTypes';

export const participantCreate = (ocId, competitionId, formData, callback) => ({
  type: CREATE_PARTICIPANT,
  payload: { ocId, competitionId, formData },
  callback
});

export const participantCreateSuccess = (
  data,
  ocId,
  competitionId,
  included,
  links
) => ({
  type: CREATE_PARTICIPANT_SUCCESS,
  payload: { data, ocId, competitionId, included, links }
});

export const participantCreateFailed = (error) => ({
  type: CREATE_PARTICIPANT_FAILED,
  error
});

export const participantCreateBycsv = (
  ocId,
  competitionId,
  groupId,
  formData,
  callback
) => ({
  type: CREATE_PARTICIPANT_BY_CSV,
  payload: { ocId, competitionId, groupId, formData },
  callback
});

export const participantCreateBycsvSuccess = (
  data,
  ocId,
  competitionId,
  included,
  links
) => ({
  type: CREATE_PARTICIPANT_BY_CSV_SUCCESS,
  payload: { data, ocId, competitionId, included, links }
});

export const participantCreateBycsvFailed = (error) => ({
  type: CREATE_PARTICIPANT_BY_CSV_FAILED,
  error
});

export const getParticipant = (ocId, competitionId, page) => ({
  type: GET_PARTICIPANT,
  payload: { ocId, competitionId, page }
});

export const getParticipantSuccess = (
  data,
  ocId,
  competitionId,
  included,
  links,
  meta
) => ({
  type: GET_PARTICIPANT_SUCCESS,
  payload: { data, ocId, competitionId, included, links, meta }
});

export const getParticipantFailed = (error) => ({
  type: GET_PARTICIPANT_FAILED,
  error
});

export const deleteParticipant = (
  ocId,
  competitionId,
  participantId,
  callback
) => ({
  type: DELETE_PARTICIPANT,
  payload: { ocId, competitionId, participantId },
  callback
});

export const deleteParticipantSuccess = (ocId, competitionId, data) => ({
  type: DELETE_PARTICIPANT_SUCCESS,
  payload: { ocId, competitionId, data }
});

export const deleteParticipantFailed = (error) => ({
  type: DELETE_PARTICIPANT_FAILED,
  error
});

export const updateParticipant = (
  ocId,
  competitionId,
  participantId,
  formData,
  callback
) => ({
  type: UPDATE_PARTICIPANT,
  payload: { ocId, competitionId, participantId, formData },
  callback
});

export const updateParticipantSuccess = (
  data,
  ocId,
  competitionId,
  included
) => ({
  type: UPDATE_PARTICIPANT_SUCCESS,
  payload: { data, ocId, competitionId, included }
});

export const updateParticipantFailed = (error) => ({
  type: UPDATE_PARTICIPANT_FAILED,
  error
});
