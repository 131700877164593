import { createSelector } from 'reselect';
import { currentOccasionId, currentHardLogoutId } from './dynamicData';
import {
  hardLogoutEntities,
  hardLogoutUserEntities,
  userEntities
} from './entity';
import { hardLogoutIds, hardLogoutUserIds } from './static';

export const currentHardLogoutIds = createSelector(
  currentOccasionId,
  hardLogoutIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getAllHardLogouts = createSelector(
  hardLogoutEntities,
  currentHardLogoutIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const currentHardLogoutData = createSelector(
  currentHardLogoutId,
  hardLogoutEntities,
  (id, entity) => id && entity && entity[id]
);

export const currentHardLogoutUserIds = createSelector(
  currentHardLogoutId,
  hardLogoutUserIds,
  (HLID, ids) => HLID && ids && ids[HLID]
);
export const getHardLogoutUserData = createSelector(
  hardLogoutUserEntities,
  currentHardLogoutUserIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
export const getHardLogoutUserWithUserData = createSelector(
  getHardLogoutUserData,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      user: { ...entities[item?.user?.id] }
    }))
);
