import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  deleteSpeakerReferenceApi,
  editSpeakerReferenceApi,
  viewSpeakerReference
} from '../api/speakerReference';

import {
  DELETE_SPEAKER_REFERENCE,
  EDIT_SPEAKER_REFERENCE,
  GET_SPEAKER_REFERENCE_PAGINATION
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  deleteSpeakerReferenceFailed,
  deleteSpeakerReferenceSuccess,
  editSpeakerReferenceFailed,
  editSpeakerReferenceSuccess,
  getSpeakerReferenceWithPaginationFailed,
  getSpeakerReferenceWithPaginationSuccess
} from '../actions/speakerReference';

export function* getSpeakersReferenceWithPaginationSaga(action) {
  try {
    const result = yield call(
      viewSpeakerReference,
      action.payload.ocId,
      action.payload.speakerId,
      action.payload.pageNumber,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getSpeakerReferenceWithPaginationSuccess(
          action.payload.ocId,
          action.payload.speakerId,
          result.data,
          result?.links,
          result?.included
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getSpeakerReferenceWithPaginationFailed(error));

    yield put(requestAutoHideAlert(error, 'Error', 4000));
  }
}

export function* editSpeakerReferenceSaga(action) {
  try {
    const result = yield call(
      editSpeakerReferenceApi,
      action.payload.ocId,
      action.payload.speakerId,
      action.payload.speakerReferenceId,
      action.payload.formData,
      getUserToken()
    );
    if (typeof action.callback === 'function') {
      action.callback();
    }
    if (isValidResult(result)) {
      yield put(
        editSpeakerReferenceSuccess(
          action.payload.ocId,
          action.payload.speakerId,
          result.data,
          result.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
    }
  } catch (error) {
    yield put(editSpeakerReferenceFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* deleteSpeakerReferenceSaga(action) {
  try {
    const result = yield call(
      deleteSpeakerReferenceApi,
      action.payload.ocId,
      action.payload.speakerId,
      action.payload.speakerReferenceId,
      getUserToken()
    );
    if (typeof action.callback === 'function') {
      action.callback();
    }
    if (isValidResult(result)) {
      yield put(
        deleteSpeakerReferenceSuccess(
          action.payload.ocId,
          action.payload.speakerId,
          result.data,
          result.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
    }
  } catch (error) {
    yield put(deleteSpeakerReferenceFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* speakerReferenceActionWatcher() {
  yield takeLatest(
    GET_SPEAKER_REFERENCE_PAGINATION,
    progressSaga,
    getSpeakersReferenceWithPaginationSaga
  );
  yield takeLatest(
    EDIT_SPEAKER_REFERENCE,
    progressSaga,
    editSpeakerReferenceSaga
  );
  yield takeLatest(
    DELETE_SPEAKER_REFERENCE,
    progressSaga,
    deleteSpeakerReferenceSaga
  );
}
