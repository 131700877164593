import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { RoutingEndPoints } from './RoutingEndPoints';
import OccasionCreate from '../containers/PageRoutes/Occasion/Create';
import OccasionView from '../containers/PageRoutes/Occasion/View';
import OccasionDetails from '../containers/PageRoutes/Occasion/Details';
import AdminCreate from '../containers/PageRoutes/Admin/Create';
import AdminView from '../containers/PageRoutes/Admin/View';
import SocianaInternalUserCreate from '../containers/PageRoutes/SocianaInternalUser/Create';
import SocianaInternalUserView from '../containers/PageRoutes/SocianaInternalUser/View';
import InternalUserTabView from '../containers/PageRoutes/SocianaInternalUser/Details';
import Home from '../containers/PageRoutes/Home';
import AdminTabView from '../containers/PageRoutes/Admin/Details';
import NotFoundPage from '../containers/NotFoundPage';
import UserCreate from '../containers/PageRoutes/User/Create';
import UserView from '../containers/PageRoutes/User/View';
import EnquiryView from '../containers/PageRoutes/Enquiry/View';
import UserDetails from '../containers/PageRoutes/User/Details/index';
import EventDetails from '../containers/PageRoutes/Occasion/Details/Events/index';
import GroupDetails from '../containers/PageRoutes/Occasion/Details/Group/index';
import HospitalityDetails from '../containers/PageRoutes/Occasion/Details/Hospitality/index';
import TicketDetails from '../containers/PageRoutes/Occasion/Details/Ticket/index';
import DivisionDetails from '../containers/PageRoutes/Occasion/Details/SocianaThink/Division/index';
import ScheduleDetails from '../containers/PageRoutes/Occasion/Details/Schedule/index';
import MemberDetails from '../containers/PageRoutes/Occasion/Details/Members/index';
import AlbumDetails from '../containers/PageRoutes/Occasion/Details/Gallery/Album/index';
import TaskDetails from '../containers/PageRoutes/Occasion/Details/Hospitality/HospitalityTask/index';
import EventScheduleDetails from '../containers/PageRoutes/Occasion/Details/Events/Schedule/index';
import EventAffairDetails from '../containers/PageRoutes/Occasion/Details/Events/EventAffair/index';
import SpeakerDetails from '../containers/PageRoutes/Occasion/Details/Speakers/index';
import TimeTriggerDetails from '../containers/PageRoutes/Occasion/Details/Trigger/Time/index';
import ActivityTrackerDetails from '../containers/PageRoutes/Occasion/Details/ActivityTracker/index';
import hardLayoutDetails from '../containers/PageRoutes/Occasion/Details/HardLogout/index';
import FaceInfoUserDetails from '../containers/PageRoutes/Occasion/Details/FaceInfo/User/index';
import FaceInfoMediaDetails from '../containers/PageRoutes/Occasion/Details/FaceInfo/Media/index';
import CompetitionDetails from '../containers/PageRoutes/Occasion/Details/Competition/index';
import ViewWhatsappTemplates from '../containers/PageRoutes/WhatsappTemplates/View/index';
import WhatsappTemplatesCreate from '../containers/PageRoutes/WhatsappTemplates/Create/index';
import WhatsappTemplatesDetails from '../containers/PageRoutes/WhatsappTemplates/Details/index';
import WhatsappTemplatesModelableDetails from '../containers/PageRoutes/WhatsappTemplates/Details/WhatsappTemplatesModelable/index';

const AuthorizedRoutes = () => {
  const {
    authorized: {
      occasion,
      admin,
      dashboard,
      socianaInternalUser,
      user,
      event,
      group,
      hospitality,
      ticket,
      division,
      schedule,
      member,
      album,
      hospitalityTask,
      eventAffair,
      enquiryRequest,
      speaker,
      timeTrigger,
      activityTracker,
      hardLogout,
      faceInfoMedia,
      faceInfoUser,
      competition,
      whatsAppTemplates,
      whatsappTemplatesModelable
    }
  } = RoutingEndPoints;

  return (
    <>
      <Switch>
        <Route exact path={dashboard.path} component={Home} />
        <Route exact path={occasion.create.path} component={OccasionCreate} />
        <Route exact path={occasion.view.path} component={OccasionView} />
        <Route
          exact
          path={`${occasion.view.path}/:id`}
          component={OccasionDetails}
        />
        <Route
          exact
          path={`${occasion.view.path}/:occasionId${event.view.path}/:id`}
          component={EventDetails}
        />
        <Route
          exact
          path={`${occasion.view.path}/:occasionId${competition.view.path}/:id`}
          component={CompetitionDetails}
        />
        <Route
          exact
          path={`${occasion.view.path}/:occasionId${hardLogout.view.path}/:id`}
          component={hardLayoutDetails}
        />
        <Route
          exact
          path={`${occasion.view.path}/:occasionId${group.view.path}/:id`}
          component={GroupDetails}
        />
        <Route
          exact
          path={`${occasion.view.path}/:occasionId${activityTracker.view.path}/:id`}
          component={ActivityTrackerDetails}
        />
        <Route
          exact
          path={`${occasion.view.path}/:occasionId${hospitality.view.path}/:id`}
          component={HospitalityDetails}
        />
        <Route
          exact
          path={`${occasion.view.path}/:occasionId${ticket.view.path}/:id`}
          component={TicketDetails}
        />
        <Route
          exact
          path={`${occasion.view.path}/:occasionId${timeTrigger.view.path}/:id`}
          component={TimeTriggerDetails}
        />
        <Route
          exact
          path={`${occasion.view.path}/:occasionId${speaker.view.path}/:id`}
          component={SpeakerDetails}
        />
        <Route
          exact
          path={`${occasion.view.path}/:occasionId${division.view.path}/:id`}
          component={DivisionDetails}
        />
        <Route
          exact
          path={`${occasion.view.path}/:occasionId${schedule.view.path}/:id`}
          component={ScheduleDetails}
        />
        <Route
          exact
          path={`${occasion.view.path}/:occasionId${member.view.path}/:id`}
          component={MemberDetails}
        />
        <Route
          path={`${occasion.view.path}/:occasionId${album.view.path}/:id`}
          component={AlbumDetails}
        />
        <Route
          path={`${occasion.view.path}/:occasionId${faceInfoUser.view.path}/:id`}
          component={FaceInfoUserDetails}
        />
        <Route
          path={`${occasion.view.path}/:occasionId${faceInfoMedia.view.path}/:id`}
          component={FaceInfoMediaDetails}
        />
        <Route
          path={`${occasion.view.path}/:occasionId${hospitality.view.path}/:hospitalityId${hospitalityTask.view.path}/:id`}
          component={TaskDetails}
        />
        <Route
          path={`${occasion.view.path}/:occasionId${event.view.path}/:eventId${schedule.view.path}/:id`}
          component={EventScheduleDetails}
        />
        <Route
          path={`${occasion.view.path}/:occasionId${event.view.path}/:eventId${eventAffair.view.path}/:id`}
          component={EventAffairDetails}
        />
        <Route exact path={admin.create.path} component={AdminCreate} />
        <Route exact path={admin.view.path} component={AdminView} />
        <Route exact path={`${admin.view.path}/:id`} component={AdminTabView} />
        <Route
          exact
          path={socianaInternalUser.create.path}
          component={SocianaInternalUserCreate}
        />
        <Route
          exact
          path={socianaInternalUser.view.path}
          component={SocianaInternalUserView}
        />
        <Route
          exact
          path={`${socianaInternalUser.view.path}/:id`}
          component={InternalUserTabView}
        />
        <Route exact path={user.create.path} component={UserCreate} />

        <Route exact path={user.view.path} component={UserView} />
        <Route exact path={`${user.view.path}/:id`} component={UserDetails} />
        <Route exact path={enquiryRequest.view.path} component={EnquiryView} />
        <Route
          exact
          path={whatsAppTemplates.create.path}
          component={WhatsappTemplatesCreate}
        />
        <Route
          exact
          path={whatsAppTemplates.view.path}
          component={ViewWhatsappTemplates}
        />
        <Route
          exact
          path={`${whatsAppTemplates.view.path}/:id`}
          component={WhatsappTemplatesDetails}
        />
        <Route
          exact
          path={`${whatsAppTemplates.view.path}/:whatsappTemplatesId${whatsappTemplatesModelable.view.path}/:id`}
          component={WhatsappTemplatesModelableDetails}
        />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </>
  );
};

export default withRouter(AuthorizedRoutes);
