import request from './request';

export function getHospitalityGoogleSheetApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/get_occasion_google_sheet_by_model_type?page=${page}&model_type=hospitality_task`,
    {
      method: 'GET',
      token
    }
  );
}

export function hospitalityGoogleSheetCreateApi(ocId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/create_hospitality_task_google_sheet`,
    {
      method: 'POST',
      token
    }
  );
}

export function hospitalityGoogleSheetRegenerateApi(ocId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/regenerate_google_sheet_data`,
    {
      method: 'PUT',
      token
    }
  );
}

export function hospitalityGoogleSheetSyncApi(ocId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/sync_google_sheet_data`,
    {
      method: 'PUT',
      token
    }
  );
}

export function deleteHospitalityGoogleSheetApi(
  ocId,
  hospitalityGoogleSheetId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_google_sheets/${hospitalityGoogleSheetId}`,
    {
      method: 'DELETE',
      token
    }
  );
}
