import { Image, Popconfirm, Button, Popover } from 'antd';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import {
  ZoomInOutlined,
  EditOutlined,
  DeleteOutlined,
  SyncOutlined,
  PhoneOutlined
} from '@ant-design/icons';
import WhatsappTemplatesCreate from '../Create/index';
import { Avatar } from '../../../../components/Avatar';
import { ProfileContent } from '../../../../components/Popover/ProfileContent';

const WhatsappTemplatesDetailsView = ({
  templateDetails,
  whatsappTemplatesId
}) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const location = useLocation();

  const content = () => {
    if (templateDetails?.occasion) {
      return <ProfileContent data={templateDetails?.occasion} />;
    }
  };

  console.log('templates', templateDetails);
  // header
  const renderHeader = () => {
    if (
      templateDetails.header &&
      Object.keys(templateDetails.header).length > 0
    ) {
      if (templateDetails.header.typeString === 'video') {
        return (
          <>
            <div className="whatsapp-templates-header-media-box">
              <video src={templateDetails.header.mediaFromPC} controls />
            </div>
          </>
        );
      } else if (templateDetails.header.typeString === 'image') {
        return (
          <>
            <div className="whatsapp-templates-header-media-box">
              <img
                src={
                  'https://m.greaterkashmir.com/sortd-service/imaginary/v22-01/jpg/large/high?url=Z3JlYXRlcmthc2htaXItc29ydGQtcHJvLXByb2Qtc29ydGQvbWVkaWE3MDhkMjk5MC04YjA5LTExZWUtYjRjZi1kZjI5ODA0ZmNiOWIuanBn'
                }
              />
            </div>
          </>
        );
      } else if (templateDetails.header.typeString === 'text') {
        return (
          <>
            {templateDetails.header.text ? (
              <p className="field-data">{templateDetails.header.text}</p>
            ) : null}
          </>
        );
      }
    }
    return <p className="field-data">...</p>;
  };

  // body

  const renderBody = () => {
    if (templateDetails.body) {
      let customParams = [];
      templateDetails?.customParams?.map((item) => {
        const kstr1 = item.split(',');
        const kstr2 = kstr1[0].split('=>"');
        const kstr3 = kstr2[1].split('"');

        const vstr2 = kstr1[1].split('=>"');
        const vstr3 = vstr2[1].split('"');
        const obj = { paramName: kstr3[0], paramValue: vstr3[0] };
        customParams.push(obj);
      });

      const replacedTextone = templateDetails.body.replace(
        /{{(.*?)}}/g,
        (match, paramName) => {
          const param = customParams.find((p) => p.paramName === paramName);
          return param ? param.paramValue : match;
        }
      );
      return <pre className="field-data">{replacedTextone}</pre>;
    }
    return <p className="field-data">...</p>;
  };

  // customParams

  const renderCustomParams = () => {
    if (
      templateDetails.customParams &&
      templateDetails.customParams.length > 0
    ) {
      let customParams = [];
      templateDetails?.customParams?.map((item) => {
        const kstr1 = item.split('",');
        const kstr2 = kstr1[0].split('=>"');
        const kstr3 = kstr2[1].split('"');

        const vstr2 = kstr1[1].split('=>"');
        const vstr3 = vstr2[1].split('"');
        const obj = { paramName: kstr3[0], paramValue: vstr3[0] };
        customParams.push(obj);
      });

      return (
        <p className="whatsapp-templates-details">
          <p className="fields">Custom Params:</p>
          <div className="field-data-box">
            {customParams &&
              customParams.map((item, index) => (
                <p key={index} className="field-data">
                  <strong>{item.paramName}:</strong> {item.paramValue}
                </p>
              ))}
          </div>
        </p>
      );
    }
    return (
      <p className="whatsapp-templates-details">
        <p className="fields">Custom Params:</p>
        <p className="field-data">...</p>
      </p>
    );
  };

  // button
  const renderButton = () => {
    if (templateDetails.buttonType) {
      if (templateDetails.buttonType === 'call_to_action') {
        return (
          <div className="whatsapp-templates-url-button-box">
            {templateDetails.buttons.map((item) => {
              const regex = /"([^"]+)"=>"([^"]+)"/g;
              const keyValuePairs = item.match(regex);
              const resultObject = keyValuePairs.reduce((acc, pair) => {
                const [key, value] = pair.split('"=>"');
                acc[key.replace('"', '')] = value.replace('"', '');
                return acc;
              }, {});

              if (resultObject?.type === 'url') {
                return (
                  <div className="field-data-box">
                    <p className="field-data">
                      <strong>Type:</strong>{' '}
                      {resultObject?.type ? resultObject.type : '...'}
                    </p>
                    <p className="field-data">
                      <strong>Text:</strong>{' '}
                      {resultObject?.text ? resultObject?.text : '...'}
                    </p>
                    <p className="field-data">
                      <strong>Index:</strong>{' '}
                      {resultObject?.index ? resultObject?.index : '...'}
                    </p>
                    <p className="field-data">
                      <strong>Param Name:</strong>{' '}
                      {resultObject?.paramName
                        ? resultObject?.paramName
                        : '...'}
                    </p>
                    <p className="field-data">
                      <strong>Url:</strong>{' '}
                      {resultObject?.url ? resultObject?.url : '...'}
                    </p>
                    <p className="field-data">
                      <strong>Url Original:</strong>{' '}
                      {resultObject?.urlOriginal
                        ? resultObject?.urlOriginal
                        : '...'}
                    </p>
                    <p className="field-data">
                      <strong>Url Type:</strong>{' '}
                      {resultObject?.urlType ? resultObject?.urlType : '...'}
                    </p>
                  </div>
                );
              } else if (resultObject?.type === 'call') {
                return (
                  <div className="field-data-box">
                    <p className="field-data">
                      <strong>Type:</strong>{' '}
                      {resultObject?.type ? resultObject.type : '...'}
                    </p>
                    <p className="field-data">
                      <strong>Text:</strong>{' '}
                      {resultObject?.text ? resultObject?.text : '...'}
                    </p>
                    <p className="field-data">
                      <strong>Index:</strong>{' '}
                      {resultObject?.index ? resultObject?.index : '...'}
                    </p>
                    <p className="field-data">
                      <strong>Param Name:</strong>{' '}
                      {resultObject?.paramName
                        ? resultObject?.paramName
                        : '...'}
                    </p>
                    <p className="field-data">
                      <strong>Url:</strong>{' '}
                      {resultObject?.url ? resultObject?.url : '...'}
                    </p>
                    <p className="field-data">
                      <strong>Url Original:</strong>{' '}
                      {resultObject?.urlOriginal
                        ? resultObject?.urlOriginal
                        : '...'}
                    </p>
                    <p className="field-data">
                      <strong>Url Type:</strong>{' '}
                      {resultObject?.urlType ? resultObject?.urlType : '...'}
                    </p>
                  </div>
                );
              }
            })}
          </div>
        );
      } else if (templateDetails.buttonType === 'quick_reply') {
        return (
          <div className="whatsapp-templates-quick_reply-button-box">
            {templateDetails.buttons.map((item) => {
              const regex = /"([^"]+)"=>"([^"]+)"/g;
              const keyValuePairs = item.match(regex);
              const resultObject = keyValuePairs.reduce((acc, pair) => {
                const [key, value] = pair.split('"=>"');
                acc[key.replace('"', '')] = value.replace('"', '');
                return acc;
              }, {});

              return (
                <div className="field-data-box">
                  <p className="field-data">
                    <strong>Type:</strong>{' '}
                    {resultObject?.type ? resultObject.type : '...'}
                  </p>
                  <p className="field-data">
                    <strong>Text:</strong>{' '}
                    {resultObject?.text ? resultObject?.text : '...'}
                  </p>
                  <p className="field-data">
                    <strong>Index:</strong>{' '}
                    {resultObject?.index ? resultObject?.index : '...'}
                  </p>
                  <p className="field-data">
                    <strong>Param Name:</strong>{' '}
                    {resultObject?.paramName ? resultObject?.paramName : '...'}
                  </p>
                  <p className="field-data">
                    <strong>Url:</strong>{' '}
                    {resultObject?.url ? resultObject?.url : '...'}
                  </p>
                  <p className="field-data">
                    <strong>Url Original:</strong>{' '}
                    {resultObject?.urlOriginal
                      ? resultObject?.urlOriginal
                      : '...'}
                  </p>
                  <p className="field-data">
                    <strong>Url Type:</strong>{' '}
                    {resultObject?.urlType ? resultObject?.urlType : '...'}
                  </p>
                </div>
              );
            })}
          </div>
        );
      }
    }
    return <p className="field-data">...</p>;
  };

  if (!isEditOpen) {
    return (
      <div className="whatsapp-templates-details-container">
        <p className="whatsapp-templates-details">
          <p className="fields">occasion:</p>
          <p className="field-data">
            <Popover
              content={content}
              placement="right"
              overlayClassName="popover-overlay"
              overlayInnerStyle={{
                width: 300,
                backgroundColor: 'transparent'
              }}>
              <div className="wati-occasion-popover-container">
                <p className="avatar-image-container">
                  <Avatar
                    avatarUrl={templateDetails?.occasion?.cardImageUrl}
                    name={templateDetails?.occasion?.name}
                    preview={true}
                    className="wati-image"
                  />
                </p>
                <p className="field-data-name">
                  {templateDetails?.occasion?.name}
                </p>
              </div>
            </Popover>
          </p>
        </p>

        <p className="whatsapp-templates-details">
          <p className="fields">Name:</p>
          <p className="field-data">
            {templateDetails?.elementName
              ? templateDetails?.elementName
              : '...'}
          </p>
        </p>
        <p className="whatsapp-templates-details">
          <p className="fields">Header:</p>
          {renderHeader()}
        </p>
        <p className="whatsapp-templates-details">
          <p className="fields">Body:</p>
          {renderBody()}
        </p>
        {renderCustomParams()}
        <p className="whatsapp-templates-details">
          <p className="fields">Button:</p>
          {renderButton()}
        </p>
        <p className="whatsapp-templates-details">
          <p className="fields">Category:</p>
          <p className="field-data">
            {templateDetails?.category ? templateDetails?.category : '...'}
          </p>
        </p>
        {templateDetails?.language && (
          <p className="whatsapp-templates-details">
            <p className="fields">Language:</p>
            <div className="field-data-box">
              <p className="field-data">
                <strong>Key:</strong> {templateDetails?.language?.key}
              </p>
              <p className="field-data">
                <strong>Text:</strong> {templateDetails?.language?.text}
              </p>
              <p className="field-data">
                <strong>Value:</strong> {templateDetails?.language?.value}
              </p>
            </div>
          </p>
        )}
        <p className="whatsapp-templates-details">
          <p className="fields">Message Id:</p>
          <p className="field-data">
            {templateDetails?.messageId ? templateDetails?.messageId : '...'}
          </p>
        </p>
        <p className="whatsapp-templates-details">
          <p className="fields">Message Type:</p>
          <p className="field-data">
            {templateDetails?.messageType
              ? templateDetails?.messageType
              : '...'}
          </p>
        </p>
        <p className="whatsapp-templates-details">
          <p className="fields">Footer:</p>
          <p className="field-data">
            {templateDetails?.footer ? templateDetails?.footer : '...'}
          </p>
        </p>
        <button
          className="edit-details-container"
          onClick={() => setIsEditOpen(true)}>
          <EditOutlined />
          <span>Edit Details</span>
        </button>
      </div>
    );
  }
  return (
    <div className="edit-container">
      <WhatsappTemplatesCreate
        location={location}
        whatsappTemplatesId={whatsappTemplatesId}
        allowAccessToPage
        templateDetails={templateDetails}
        handleCloseClick={() => setIsEditOpen(false)}
      />
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WhatsappTemplatesDetailsView);
