import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import {
  hospitalityTaskProvidedView,
  hospitalityProvidedTaskDownload,
  hospitalityProvidedTaskDelete,
  searchProvidedTasks
} from '../../../../../../actions/hospitalityTask';
import {
  getAllProvidedTaskWithUserData,
  currentHospitalityTaskWithTaskOptionsData,
  getAllProvidedTaskSearchWithGuestData
} from '../../../../../../selectors/hospitalityTask';
import { RESET_PROVIDEDTASKS } from '../../../../../../constants/actionTypes';
import AddProvidedAnswerForAll from './AddProvidedAnswerForAll';
import useLinkDownloader from '../../../../../../hooks/useLinkDownloader';
import { DeleteOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Modal } from 'antd';
import ProvidedTaskSearch from './ProvidedTaskSearch';

const ProvidedView = (props) => {
  const [page, setPage] = useState(1);
  const [openSingleAnswer, setOpenSingleAnswer] = useState(false);
  const [isMultipalFileView, setIsMultipalFileView] = useState(false);
  const dispatch = useDispatch();
  const [taskData, setTaskData] = React.useState({
    info: ''
  });

  const onSearch = () => {
    try {
      if (taskData.info) {
        props.searchProvidedTasks(
          props.occasionId,
          props.hospitalityTaskId,
          taskData,
          1
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const resetSearch = () => {
    dispatch({ type: RESET_PROVIDEDTASKS });
    setTaskData({
      info: ''
    });
  };

  const handleMultipalFilesView = () => {
    setIsMultipalFileView(true);
  };
  const handleOk = (type) => {
    if (type === 'multipalFileView') {
      setIsMultipalFileView(false);
    }
  };

  const handleCancel = (type) => {
    if (type === 'multipalFileView') {
      setIsMultipalFileView(false);
    }
  };

  const onPageChange = (page) => {
    setPage(page);
    props.hospitalityTaskProvidedView(
      props.occasionId,
      props.hospitalityTaskId,
      page
    );
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleCreateSingleAnswer = (userId, guestId) => {
    let data = {
      userId,
      guestId
    };
    setOpenSingleAnswer(data);
  };

  const { downloadLinkFile } = useLinkDownloader();

  const handleDownloadProvidedData = () => {
    props.hospitalityProvidedTaskDownload(
      props.occasionId,
      props.hospitalityTaskId,
      (blobUrl) => {
        downloadLinkFile(
          blobUrl,
          `${props?.currentTask?.name}_${props.currentTask?.id}_provided`,
          'csv'
        );
      }
    );
  };

  const paginationOptions = {
    showTotal: () => {
      return (
        <div className="button-box">
          <Button className="refresh-button" onClick={() => onPageChange(page)}>
            <SyncOutlined />
          </Button>
          {props.currentTask?.dataInputType != 'document' && (
            <Button
              className="download-button"
              onClick={handleDownloadProvidedData}>
              Download
            </Button>
          )}
        </div>
      );
    }
  };

  const handleDelete = (id) => {
    props.hospitalityProvidedTaskDelete(
      props.occasionId,
      props.hospitalityTaskId,
      id
    );
  };

  const expandableComponent = (record) => {
    const { currentOccasionHospitalityGuests: guests } = record;

    if (guests?.length > 0) {
      return (
        <div style={{ display: 'flex' }}>
          <p style={{ textAlign: 'left' }}>Guests:</p>
          <ul>
            {guests?.length > 0 &&
              guests?.map((item) => {
                return (
                  <li
                    style={{
                      textAlign: 'left',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                    <span>
                      Name:{' '}
                      <span style={{ margin: '0 10px 0 10px' }}>
                        {item?.name}
                      </span>
                    </span>
                    {item?.currentTaskAnswer?.multipleDocumentAttachmentUrls ? (
                      <>
                        <div
                          style={{ cursor: 'pointer', color: 'blue' }}
                          onClick={handleMultipalFilesView}>
                          View All Files
                        </div>
                        <Modal
                          centered
                          title="All Documents"
                          visible={isMultipalFileView}
                          onOk={() => handleOk('multipalFileView')}
                          onCancel={() => handleCancel('multipalFileView')}
                          footer={null}>
                          <div
                            style={{
                              width: '100%',
                              height: '150px',
                              overflowY: 'scroll',
                              display: 'flex',
                              flexFlow: 'column nowrap'
                            }}>
                            {Array.isArray(
                              item.currentTaskAnswer
                                .multipleDocumentAttachmentUrls
                            ) &&
                              item.currentTaskAnswer
                                .multipleDocumentAttachmentUrls.length > 0 &&
                              item.currentTaskAnswer.multipleDocumentAttachmentUrls.map(
                                (item, index) => {
                                  return (
                                    <div style={{ marginBottom: '20px' }}>
                                      <a
                                        href={`${
                                          process.env.REACT_APP_CLIENT_URL
                                        }/${Object.values(item)[0]}`}
                                        target="_blank">
                                        {`File ${index + 1}`}
                                      </a>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </Modal>
                      </>
                    ) : item?.currentTaskAnswer?.documentAttachmentUrl ? (
                      <a
                        href={`${process.env.REACT_APP_CLIENT_URL}/${item?.currentTaskAnswer?.documentAttachmentUrl}`}
                        target="_blank">
                        File
                      </a>
                    ) : item?.currentTaskAnswer?.answer ? (
                      <span>Answer: {item?.currentTaskAnswer?.answer}</span>
                    ) : (
                      <Button
                        onClick={() =>
                          handleCreateSingleAnswer(record?.userId, item?.id)
                        }
                        style={{
                          marginLeft: 10,
                          display: 'flex',
                          width: 150,
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                        Add Answer
                      </Button>
                    )}
                    {item?.currentTaskAnswer &&
                      Object.keys(item?.currentTaskAnswer)?.length > 0 && (
                        <Popconfirm
                          title="Are you sure to delete this?"
                          onConfirm={() =>
                            handleDelete(item?.currentTaskAnswer?.id)
                          }
                          okText="Yes"
                          cancelText="No">
                          <Button className="delete-icon">
                            <DeleteOutlined />
                          </Button>
                        </Popconfirm>
                      )}
                  </li>
                );
              })}
          </ul>
        </div>
      );
    } else {
      return <p style={{ textAlign: 'left' }}>No Guests present.</p>;
    }
  };

  if (openSingleAnswer) {
    return (
      <div className="edit-container">
        <AddProvidedAnswerForAll
          occasionId={props.occasionId}
          hospitalityTaskId={props.hospitalityTaskId}
          sendToAll={false}
          userData={openSingleAnswer}
          type="provide"
          hospitalityTaskDetails={props.currentTask}
          dataInputType={props.dataInputType}
          handleCloseClick={() => setOpenSingleAnswer(false)}
        />
      </div>
    );
  }

  const getData = () => {
    if (
      props.isSearch
        ? props.providedSearchData?.length > 0
        : props.providedTask?.length > 0
    ) {
      const data = props.isSearch
        ? props.providedSearchData
        : props.providedTask;

      return data.map((item) => {
        return {
          id: item?.currentProvidedTaskAnswer?.id,
          userId: item?.id,
          name: item?.name,
          email: item?.email,
          mobile: item?.mobile,
          currentProvidedTaskAnswer: item?.currentProvidedTaskAnswer,
          currentOccasionHospitalityGuests:
            item?.currentOccasionHospitalityGuests,
          avatarUrl: item?.avatarUrl
        };
      });
    } else {
      return [];
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="task-request-view-section">
        <div className="task-request-view-container">
          <ProvidedTaskSearch
            occasionId={props.occasionId}
            taskData={taskData}
            setTaskData={setTaskData}
            onSearch={onSearch}
            resetSearch={resetSearch}
          />
          <TableView
            isPaginated={true}
            isRouted={false}
            expandable={expandableComponent}
            paginationOptions={paginationOptions}
            onPageChange={onPageChange}
            onDelete={handleDelete}
            fetchProgress={props.fetchProgress}
            data={getData()}
            blacklistKeys={[
              'hospitalityTaskId',
              'hospitalityGuestId',
              'answerToId',
              'answerById',
              'answerBy',
              'answerTo',
              'dob',
              'gender',
              'userId'
            ]}
            paginationLinks={
              props.isSearch ? props.providedSearchLinks : props.providedLinks
            }
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentTask: currentHospitalityTaskWithTaskOptionsData(store, props),
  providedTask: getAllProvidedTaskWithUserData(store, props),
  providedSearchData: getAllProvidedTaskSearchWithGuestData(store, props),
  providedLinks: store.hospitalityTask.providedtaskLinks,
  providedSearchLinks: store.hospitalityTask.providedSearchLinks,
  isSearch: store.hospitalityTask.isProvidedSearch
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    {
      hospitalityTaskProvidedView,
      hospitalityProvidedTaskDownload,
      hospitalityProvidedTaskDelete,
      searchProvidedTasks
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(ProvidedView);
