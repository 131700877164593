export const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');
  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'pm') {
    hours = parseInt(hours, 10) + 12;
  }

  return { hours, minutes };
};

export const getMomentTime = (moment) => {
  const date = new Date(moment.toDate()).getTime();
  return date;
};

export const getSchedule = (date, time) => {
  const datetimeArray = [];
  if (time) {
    const { hours, minutes } = convertTime12to24(time);
    datetimeArray.push([hours, minutes]);
  }
  if (date) {
    const [day, month, year] = date.split('/');
    datetimeArray.push([day, month, year]);
  }
  const updatedDateTimeArray = datetimeArray.map((item) =>
    Number.isNaN(item) ? 0 : item
  );
  const datetime = new Date(...updatedDateTimeArray);
  return datetime;
};

export const getDateTimeMeridianFormat = (date, time) => {
  return `${date} ${time}`;
};

export const getDate = (date) => {
  const day = date?.getDate();
  return day < 10 ? `0${day}` : day;
};

export const getMonth = (date) => {
  const month = date?.getMonth() + 1;
  switch (month) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default:
      return 'Invalid Month';
  }
};

export const getDateInMonthFormat = (date) => {
  try {
    const dateStr = date.split(' ');
    let dateArr;
    if (date.indexOf('-') > 0) {
      dateArr = dateStr[0].split('-');
    } else {
      dateArr = dateStr[0].split('/');
    }
    return `${dateArr[1]}-${dateArr[0]}-${dateArr[2]} ${dateStr[1] || ''}`;
  } catch (error) {
    console.error(error);
    return date;
  }
};
