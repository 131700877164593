import { createSelector } from 'reselect';
import { currentOccasionId, currentAlarmId } from './dynamicData';
import { alarmEntities, groupEntities, userEntities } from './entity';
import { alarmIds } from './static';

export const currentOccasionAlarmIds = createSelector(
  currentOccasionId,
  alarmIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getAlarms = createSelector(
  currentOccasionAlarmIds,
  userEntities,
  alarmEntities,
  groupEntities,
  (ids, uEntities, entities, gEntities) =>
    ids &&
    uEntities &&
    entities &&
    gEntities &&
    ids.length > 0 &&
    ids.map((id) => ({
      ...entities[id],
      createdBy: { ...uEntities[entities[id]?.createdBy?.id] },
      groups: [...entities[id]?.groups?.map((el) => gEntities[el?.id])]
    }))
);

export const currentAlarmData = createSelector(
  currentAlarmId,
  alarmEntities,
  (id, entity) => id && entity && entity[id]
);
