import request from './request';

export function occasionSponsorshipCreateApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/sponsorship/sponsorships`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function occasionSponsorshipViewApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/sponsorship/sponsorships?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function deleteSponsorshipApi(ocId, sponsorshipId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/sponsorship/sponsorships/${sponsorshipId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function updateSponsorshipApi(ocId, sponsorshipId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/sponsorship/sponsorships/${sponsorshipId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}
