import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import Tabs from '../../../../components/Tabs';
import { renderRoute } from '../../../../components/RouterHOC';
import { TabsHeader } from '../../../../components/TabsHeader';
import { getCurrentWhatsappTemplatesWithOccasionData } from '../../../../selectors/whatsappTemplates';
import { currentWhatsappTemplatesId } from '../../../../selectors/dynamicData';
import WhatsappTemplatesDetailsView from './details';
import ViewWhatsappTemplatesModelable from './WhatsappTemplatesModelable/view';

function WhatsappTemplatesDetails(props) {
  const tabPanes = [
    {
      tabName: 'Details',
      tabKey: '1',
      children: (
        <WhatsappTemplatesDetailsView
          templateDetails={props.currentWhatsappTemplateData}
          whatsappTemplatesId={props.currentWhatsappTemplateId}
        />
      )
    },
    {
      tabName: 'WhatsApp Template Modelable',
      tabKey: '2',
      children: (
        <ViewWhatsappTemplatesModelable
          templateDetails={props.currentWhatsappTemplateData}
          whatsappTemplatesId={props.currentWhatsappTemplateId}
        />
      )
    }
  ];

  const goBack = () => {
    props.history.goBack();
  };

  return (
    <ErrorBoundary>
      <SectionContent className="whatsapp-templates-tab-section">
        <TabsHeader
          name={props.currentWhatsappTemplateData.elementName}
          extraData={[props.currentWhatsappTemplateData.messageId]}
          wrapperClass="section-details"
        />
        <div className="whatsapp-templates-tab-container">
          <Tabs
            defaultActiveKey="1"
            tabPanes={tabPanes}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentWhatsappTemplateData: getCurrentWhatsappTemplatesWithOccasionData(
    store,
    props
  ),
  currentWhatsappTemplateId: currentWhatsappTemplatesId(store, props)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default renderRoute(getCurrentWhatsappTemplatesWithOccasionData)(
  connect(mapStateToProps, mapDispatchToProps)(WhatsappTemplatesDetails)
);
