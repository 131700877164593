import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import TableView from '../../../../../components/Table';
import { Button } from 'antd';
import WhiteListCreate from './Create';
import WhitListCreateWithDealerCode from './CreateWithDealerCode';
import {
  getOccasionWhiteList,
  deleteOccasionWhiteList
} from '../../../../../actions/occasionWhiteList';
import { getAllOccasionWhiteListData } from '../../../../../selectors/occasionWhiteList';
import SearchWhiteList from './SearchWhiteList';

const WhiteListView = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [
    openCreateWithDealerCode,
    setOpenCreateWithDealerCode
  ] = React.useState(false);
  const [whiteListId, setWhiteListId] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.getOccasionWhiteList(props.occasionId, page);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleCreate = () => {
    setCreateOpen(true);
  };

  const handleCreateWithDealerCode = () => {
    setWhiteListId('');
    setOpenCreateWithDealerCode(true);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="white-list-button-box">
        <div className="button-box-content">
          <Button className="refresh-button" onClick={() => onPageChange(page)}>
            <SyncOutlined />
          </Button>
          <Button className="create-button" onClick={handleCreate}>
            Create
          </Button>
          <Button
            className="create-button"
            onClick={handleCreateWithDealerCode}>
            Create With Dealer Code
          </Button>
        </div>
        <div className="list-count-container">
          <span>List Count</span>
          <span className="count-text">{props.count}</span>
        </div>
      </div>
    )
  };

  const editData = (id) => {
    setOpenCreateWithDealerCode(true);
    setWhiteListId(id);
  };

  const deleteData = (id) => {
    props.deleteOccasionWhiteList(props.occasionId, id);
  };

  if (!createOpen && !openCreateWithDealerCode) {
    return (
      <ErrorBoundary>
        <SectionContent className="occasion-white-list-view-section">
          <div className="occasion-white-list-view-container">
            <SearchWhiteList occasionId={props.occasionId} />
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              isRouted={false}
              onPageChange={onPageChange}
              isEditable={editData}
              onDelete={deleteData}
              data={props?.occasionWhiteListData}
              fetchProgress={props.fetchProgress}
              paginationLinks={props.occasionWhiteListLinks}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  } else if (createOpen) {
    return (
      <div className="edit-container">
        <WhiteListCreate
          occasionId={props.occasionId}
          handleCloseClick={() => setCreateOpen(false)}
        />
      </div>
    );
  }
  return (
    <div className="edit-container">
      <WhitListCreateWithDealerCode
        occasionId={props.occasionId}
        whiteListId={whiteListId}
        handleCloseClick={() => setOpenCreateWithDealerCode(false)}
        handlePageRefresh={() => onPageChange()}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  occasionWhiteListData: getAllOccasionWhiteListData(store, props),
  occasionWhiteListLinks: store.occasionWhiteList.occasionWhiteListLinks,
  count: store.occasionWhiteList.count
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { getOccasionWhiteList, deleteOccasionWhiteList },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(WhiteListView);
