import React from 'react';
import { Image } from 'antd';
import { ZoomInOutlined, EditOutlined } from '@ant-design/icons';
import { getImageSource } from '../../../../../helpers/imageHelper';
import CreateOccasionEvent from './EventCreate';
import moment from 'moment';

const EventDetailsView = ({ eventDetails, occasionId }) => {
  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const startDate = eventDetails?.startAt
    ? moment(eventDetails?.startAt).format('DD/MM/YYYY hh:mm A')
    : 'NA';
  const endDate = eventDetails?.endAt
    ? moment(eventDetails?.endAt).format('DD/MM/YYYY hh:mm A')
    : 'NA';
  if (!isEditOpen) {
    return (
      <div className="event-details-container">
        <p className="event-details card-details">
          <p className="fields">Image:</p>
          <p className="field-data card-image-container">
            <Image
              src={getImageSource(eventDetails?.cardImageUrl)}
              preview={{
                maskClassName: 'preview-style',
                mask: <ZoomInOutlined />
              }}
              className="image-style"
            />
          </p>
        </p>
        <p className="event-details card-details">
          <p className="fields">Background Image:</p>
          <p className="field-data card-image-container">
            <Image
              src={getImageSource(eventDetails?.bgImageUrl)}
              preview={{
                maskClassName: 'preview-style',
                mask: <ZoomInOutlined />
              }}
              className="image-style"
            />
          </p>
        </p>
        <p className="event-details">
          <p className="fields">Title:</p>
          <p className="field-data">{eventDetails?.title}</p>
        </p>
        {eventDetails.description ? (
          <p className="event-details">
            <p className="fields">Description:</p>
            <p className="field-data">{eventDetails?.description}</p>
          </p>
        ) : (
          ''
        )}
        <p className="event-details">
          <p className="fields">Start Date:</p>
          <p className="field-data">{startDate}</p>
        </p>
        <p className="event-details">
          <p className="fields">End Date:</p>
          <p className="field-data">{endDate}</p>
        </p>
        {eventDetails.venue ? (
          <p className="event-details">
            <p className="fields">Venue:</p>
            <p className="field-data">{eventDetails?.venue}</p>
          </p>
        ) : (
          ''
        )}
        {eventDetails.adress1 ? (
          <p className="event-details">
            <p className="fields">Address:</p>
            <p className="field-data">{eventDetails?.adress1}</p>
          </p>
        ) : (
          ''
        )}
        {eventDetails.lattitude ? (
          <p className="event-details">
            <p className="fields">Lattitude:</p>
            <p className="field-data">{eventDetails?.lattitude}</p>
          </p>
        ) : (
          ''
        )}
        {eventDetails.longitude ? (
          <p className="event-details">
            <p className="fields">Longitude:</p>
            <p className="field-data">{eventDetails?.longitude}</p>
          </p>
        ) : (
          ''
        )}
        {eventDetails.city ? (
          <p className="event-details">
            <p className="fields">City:</p>
            <p className="field-data">{eventDetails?.city}</p>
          </p>
        ) : (
          ''
        )}
        <p className="event-details">
          <p className="fields">Guest Type:</p>
          <p className="field-data">{eventDetails?.guestType}</p>
        </p>
        <p className="event-details">
          <p className="fields">Tag:</p>
          <p className="field-data">{eventDetails?.tag}</p>
        </p>
        <p className="event-details">
          <p className="fields">Ui Version:</p>
          <p className="field-data">{eventDetails?.uiVersion}</p>
        </p>
        {eventDetails.dressCode ? (
          <p className="event-details">
            <p className="fields">Dress Code:</p>
            <p className="field-data">{eventDetails?.dressCode}</p>
          </p>
        ) : (
          ''
        )}
        {eventDetails.exInfo ? (
          <p className="event-details">
            <p className="fields">Extra Info:</p>
            <p className="field-data">{eventDetails?.exInfo}</p>
          </p>
        ) : (
          ''
        )}
        {eventDetails.eventPostPaginationLimit ? (
          <p className="event-details">
            <p className="fields">Event Post Pagination Limit:</p>
            <p className="field-data">
              {eventDetails?.eventPostPaginationLimit}
            </p>
          </p>
        ) : (
          ''
        )}
        <button
          className="edit-details-container"
          onClick={() => setIsEditOpen(true)}>
          <EditOutlined />
          <span>Edit</span>
        </button>
      </div>
    );
  }
  return (
    <div className="edit-container">
      <CreateOccasionEvent
        eventId={eventDetails?.id}
        occasionId={occasionId}
        eventDetails={eventDetails}
        handleCloseClick={() => setIsEditOpen(false)}
      />
    </div>
  );
};
export default EventDetailsView;
