import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  occasionQuestionCreateApi,
  occasionQuestionViewApi
} from '../api/question';
import {
  OCCASION_QUESTION_CREATE,
  OCCASION_QUESTION_VIEW
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  occasionQuestionCreateFailed,
  occasionQuestionCreateSuccess,
  occasionQuestionViewFailed,
  occasionQuestionViewSuccess
} from '../actions/question';

export function* occasionQuestionCreateSaga(action) {
  try {
    const result = yield call(
      occasionQuestionCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionQuestionCreateSuccess(
          result.data,
          result.included,
          action.payload.ticketId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionQuestionCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionQuestionCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionQuestionViewSaga(action) {
  try {
    const result = yield call(
      occasionQuestionViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionQuestionViewSuccess(
          result.data,
          result.included,
          result.links,
          action.payload.ticketId
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionQuestionViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* questionActionWatcher() {
  yield takeLatest(
    OCCASION_QUESTION_CREATE,
    progressSaga,
    occasionQuestionCreateSaga
  );
  yield takeLatest(
    OCCASION_QUESTION_VIEW,
    progressSaga,
    occasionQuestionViewSaga
  );
}
