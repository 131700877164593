import request from './request';

export function createLive(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/live_stream/live_streams`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function getLive(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/live_stream/live_streams?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function showLive(ocId, liveId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/live_stream/join_live_stream`,
    {
      method: 'POST',
      body: {
        live_stream_id: liveId
      },
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}
