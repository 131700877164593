import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { Form, AutoComplete, Button } from 'antd';
import { SectionContent } from '../../../../../components/Section';
import { CloseOutlined } from '@ant-design/icons';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import { updateHospitalityAllAnswers } from '../../../../../actions/hospitality';

const validateHospitality = (values) => {
  const errors = {};

  if (!values.xlsx_file) errors.xlsx_file = 'Document is required';

  return errors;
};

const UpdateAnswerByXlsx = (props) => {
  const [hospitalityAnswer, setHospitalityAnswer] = React.useState({
    xlsx_file: null
  });

  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleHospitalityCreate = () => {
    try {
      const validateObj = validateHospitality(hospitalityAnswer);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        const formData = new FormData();

        formData.append(
          'spreadsheet_file',
          hospitalityAnswer.xlsx_file[0].originFileObj
        );

        props.updateHospitalityAllAnswers(props.occasionId, formData, () => {
          setHospitalityAnswer({});
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (isInitiallyDisabled) {
          setInitiallyDisabled(false);
        }

        if (type === 'xlsx_file') {
          value = e.fileList;
          name = type;
        }

        const hospitalityAnswerValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }

        setHospitalityAnswer({
          ...hospitalityAnswer,
          [inputName]: hospitalityAnswerValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="hospitality-section">
        <div className="hospitality-create-form">
          <Form
            className="hospitality-form-container"
            onFinish={handleHospitalityCreate}>
            <InputGroup
              fieldType="file"
              accept=".xlsx"
              name="xlsx_file"
              label="xlsx file"
              fileList={hospitalityAnswer?.xlsx_file}
              errorMessage={errorMessages.xlsx_file}
              onChange={handleChange}
            />
            <SubmitButton
              buttonText="Add Answer"
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateHospitalityAllAnswers }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAnswerByXlsx);
