import {
  CREATE_OCCASION_SUCCESS,
  CREATE_OCCASION_FAILED,
  GET_OCCASION_PAGINATION_SUCCESS,
  GET_OCCASION_PAGINATION_FAILED,
  GET_OCCASION_SETTINGS_FAILED,
  GET_OCCASION_SETTINGS_SUCCESS,
  EDIT_OCCASION_FAILED,
  EDIT_OCCASION_SUCCESS,
  SEARCH_OCCASIONS_FAILED,
  SEARCH_OCCASIONS_SUCCESS,
  GET_OCCASION_BY_FIELD_SUCCESS,
  GET_CURRENT_OCCASION_INTERNAL_MEMBERS_SUCCESS,
  ASSIGN_INTERNAL_TEAM_MEMBER_SUCCESS
} from '../constants/actionTypes';
import { union } from '../helpers/extraHelpers';

const initialState = {
  data: null,
  allData: null,
  error: null,
  links: null,
  types: null,
  guestTypes: null,
  customNotificationActions: null,
  adInterval: null,
  currencies: null,
  accessControls: null,
  featureControls: null,
  searchData: null,
  internalTeamMembers: null,
  internalTeamMembersLinks: null,
  count: 0
};

const occasion = (state = initialState, action) => {
  switch (action.type) {
    case GET_OCCASION_PAGINATION_SUCCESS:
    case GET_OCCASION_BY_FIELD_SUCCESS: {
      const { data, links, meta, page } = action.payload;

      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];
      const allIds = page !== 1 ? union(state.allData || [], newIds) : newIds;
      return {
        ...state,
        data: newIds,
        allData: allIds,
        links,
        count: meta?.totalCount || 0
      };
    }

    case SEARCH_OCCASIONS_FAILED:
    case GET_OCCASION_PAGINATION_FAILED:
    case GET_OCCASION_SETTINGS_FAILED:
    case EDIT_OCCASION_FAILED:
    case CREATE_OCCASION_FAILED: {
      return { ...state, error: action.error };
    }

    case EDIT_OCCASION_SUCCESS:
    case CREATE_OCCASION_SUCCESS: {
      return {
        ...state
      };
    }

    case GET_OCCASION_SETTINGS_SUCCESS: {
      const {
        guest_types: guestTypes,
        types,
        access_type: accessTypes,
        notification_message_actions: customNotificationActions,
        advertisement,
        currencies,
        access_controls: accessControls,
        feature_availabilities: featureControls,
        events
      } = action.payload.data;

      return {
        ...state,
        guestTypes,
        types,
        accessTypes,
        customNotificationActions,
        adInterval:
          advertisement && advertisement.interval ? advertisement.interval : {},
        currencies,
        accessControls,
        featureControls,
        events
      };
    }

    case SEARCH_OCCASIONS_SUCCESS: {
      const { data } = action.payload;
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];
      return {
        ...state,
        searchData: newIds
      };
    }

    case GET_CURRENT_OCCASION_INTERNAL_MEMBERS_SUCCESS: {
      const { data, links, occasionId } = action.payload;
      const prevData = state.internalTeamMembers || {};
      const prevLinks = state.internalTeamMembersLinks || {};
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        internalTeamMembers: {
          ...prevData,
          [occasionId]: newIds
        },
        internalTeamMembersLinks: {
          ...prevLinks,
          [occasionId]: links
        }
      };
    }

    case ASSIGN_INTERNAL_TEAM_MEMBER_SUCCESS: {
      const { data, ocId } = action.payload;
      const prevData = state.internalTeamMembers || {};
      const prevIds = prevData[ocId] || [];
      const newIds = (data && data.id && [data.id]) || [];

      return {
        ...state,
        internalTeamMembers: {
          ...prevData,
          [ocId]: union(newIds, prevIds)
        }
      };
    }

    default:
      return state;
  }
};

export default occasion;
