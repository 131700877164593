import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { Form, Empty, AutoComplete, Button } from 'antd';
import { LoadingOutlined, CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../../../../components/Section';
import ErrorBoundary from '../../../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../../../components/InputGroup/FormComponents';
import { ListItem } from '../../../../../../../../components/ListItem';
import { WatiCard } from '../../../../../../../../components/WatiCard/index';
import {
  createMessages,
  getWhatsappTemplateModelables,
  getProvidedAnsweredUsers,
  clearProvidedAnswerStore
} from '../../../../../../../../actions/whatsappForProvidedTask';
import {
  getAllWhatsappTemplateModelablesWithTemplateMessagesData,
  getAllProvidedAnsweredUsersData,
  getAllProvidedUnansweredUsersData,
  getAllUnansweredWhatsappTemplateModelablesWithTemplateMessagesData
} from '../../../../../../../../selectors/whatsappForProvidedTask';

const { Option } = AutoComplete;
const validateUsers = (values, users) => {
  const errors = {};

  if (!values.templateModelableIds) {
    errors.templateModelableIds = 'Template is required';
  }
  if (!values.toggle) {
    if (users.length === 0) {
      errors.selectedUsers = 'User is required';
    }
  }
  return errors;
};

const MessagesCreate = (props) => {
  const [whatsappData, setWhatsappData] = React.useState({
    toggle: true,
    templateModelableIds: ''
  });
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userPage, setUserPage] = useState();
  const [modelablePage, setModelablePage] = useState(1);
  const [errorMessages, setErrorMessages] = React.useState({});

  useEffect(() => {
    if (whatsappData.toggle) {
      props.clearProvidedAnswerStore(props.userType, props.hospitalityTaskId);
      setUserPage(1);
    } else {
      props.getProvidedAnsweredUsers(
        props.occasionId,
        props.hospitalityTaskId,
        props.userType,
        userPage ? userPage : 1
      );
    }
  }, [userPage, whatsappData.toggle]);

  useEffect(() => {
    props.getWhatsappTemplateModelables(
      props.occasionId,
      'HospitalityTask',
      props.hospitalityTaskId,
      props.operationType,
      modelablePage
    );
  }, [modelablePage]);

  const onSubmit = () => {
    try {
      const validateObj = validateUsers(whatsappData, selectedUsers);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        const formData = new FormData();
        formData.append(
          'whatsapp_message[whatsapp_template_modelable_id]',
          whatsappData.templateModelableIds
        );
        formData.append(
          'whatsapp_message[send_to_all]',
          whatsappData.toggle ? true : false
        );
        formData.append(
          'whatsapp_message[operation_type]',
          props.operationType
        );
        if (!whatsappData.toggle) {
          selectedUsers.forEach((el) =>
            formData.append('whatsapp_message[user_ids][]', el.id)
          );
        }
        props.createMessages(
          props.occasionId,
          props.hospitalityTaskId,
          formData,
          callback
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const callback = () => {
    setWhatsappData({
      toggle: true,
      templateModelableIds: []
    });
    setSelectedUsers([]);
    props.handleCloseClick();
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'toggle') {
        value = e;
        name = type;
        if (e === true) {
          props.clearProvidedAnswerStore(
            props.userType,
            props.hospitalityTaskId
          );
          setUserPage(1);
        } else {
          setUserPage();
        }
      }

      const messageValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setWhatsappData({
        ...whatsappData,
        [inputName]: messageValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getPageModelable = (str) => {
    const decodedURI = decodeURI(str);
    const number = new URLSearchParams(decodedURI).get('page[number]');
    return (number && parseInt(number)) || 1;
  };
  const onEndReachedModelable = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height >= scrollHeight - 20) {
      if (
        !!props.isAnswered
          ? props.templateModelablesLinks?.next
          : props.unansweredTemplateModelablesLinks?.next
      ) {
        const page = getPageModelable(
          !!props.isAnswered
            ? props.templateModelablesLinks?.next
            : props.unansweredTemplateModelablesLinks?.next
        );
        setModelablePage(page);
      }
    }
  };

  const getPageUser = (str) => {
    const decodedURI = decodeURI(str);
    const str1 = decodedURI.split('=');
    const res = str1[1].split('&');
    const number = res[0];
    return (number && parseInt(number)) || 1;
  };

  const onEndReached = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height >= scrollHeight - 350) {
      if (!!props.isAnswered) {
        if (props.providedAnsweredUsersDataLinks?.next) {
          const page = getPageUser(props.providedAnsweredUsersDataLinks?.next);
          setUserPage(page);
        }
      }
      if (!props.isAnswered) {
        if (props.providedUnansweredUsersDataLinks?.next) {
          const page = getPageUser(
            props.providedUnansweredUsersDataLinks?.next
          );
          setUserPage(page);
        }
      }
    }
  };

  const onSelectUser = (data) => {
    const found = selectedUsers.find((el) => el.id === data.id);
    if (!found) {
      setSelectedUsers([...selectedUsers, data]);
    } else {
      const filteredList = selectedUsers.filter((el) => el.id !== data.id);
      setSelectedUsers([...filteredList]);
    }
  };

  const getFilteredList = () => {
    const selectedUserIds = selectedUsers.map((data) => data?.id);

    const userList = !!props.isAnswered
      ? props.providedAnsweredUsersData
      : props.providedUnansweredUsersData;

    const filteredList = userList?.filter(
      (el) => !selectedUserIds.includes(el.id) && el
    );

    return filteredList || [];
  };

  const renderSelectedUsers = () => {
    return (
      <div className="selected-users-container" onScroll={onEndReached}>
        {selectedUsers &&
          selectedUsers.map((item) => {
            return (
              <ListItem
                key={item.id}
                data={item}
                onSelect={onSelectUser}
                selected={true}
                // disabled={!!item.currentOccasionGroupMember}
              />
            );
          })}
      </div>
    );
  };

  const renderListBox = () => {
    return (
      <div className="users-list-box" onScroll={onEndReached}>
        {getFilteredList().length === 0 && !props.fetchProgress && (
          <div className="users-empty-list">
            <Empty />
          </div>
        )}
        {getFilteredList().map((item) => {
          return (
            <ListItem
              key={item.id}
              data={item}
              onSelect={onSelectUser}
              // disabled={!!item.currentOccasionGroupMember}
            />
          );
        })}
        {!!props.isAnswered && !props.providedAnsweredUsersDataLinks?.next && (
          <p className="no-more-data">--------</p>
        )}
        {!props.isAnswered && !props.providedUnansweredUsersDataLinks?.next && (
          <p className="no-more-data">--------</p>
        )}
        {props.fetchProgress && (
          <div className="users-list-loader">
            <LoadingOutlined className="loader-icon" />
          </div>
        )}
      </div>
    );
  };

  return (
    <ErrorBoundary>
      <SectionContent className="provided-task-whatsapp-create-section">
        <div className="provided-task-whatsapp-create-form">
          <Form
            className="provided-task-whatsapp-create-form-container"
            onFinish={onSubmit}>
            {!!props.isAnswered && (
              <InputGroup
                fieldType="dropdown"
                className="template-modelable-search"
                mode="single"
                name="templateModelableIds"
                label="Select Template"
                placeholder="Select Template"
                onPopupScroll={onEndReachedModelable}
                onChange={handleChange}
                errorMessage={errorMessages.templateModelableIds}
                value={whatsappData?.templateModelableIds}>
                {props.templateModelablesData?.length > 0 &&
                  props.templateModelablesData.map((item) => {
                    return (
                      <Option value={item?.id} key={item?.id}>
                        <div className="options-container">
                          <div className="occasion-group-details-container">
                            <p className="occasion-group-name">
                              {item?.whatsappTemplateMessage?.elementName}
                            </p>
                            <p className="occasion-group-type">
                              {item?.whatsappTemplateMessage?.messageType}
                            </p>
                          </div>
                        </div>
                      </Option>
                    );
                  })}
              </InputGroup>
            )}
            {!props.isAnswered && (
              <InputGroup
                fieldType="dropdown"
                className="template-modelable-search"
                mode="single"
                name="templateModelableIds"
                label="Select Template"
                placeholder="Select Template"
                onPopupScroll={onEndReachedModelable}
                onChange={handleChange}
                errorMessage={errorMessages.templateModelableIds}
                value={whatsappData?.templateModelableIds}>
                {props.unansweredTemplateModelablesData?.length > 0 &&
                  props.unansweredTemplateModelablesData.map((item) => {
                    return (
                      <Option value={item?.id} key={item?.id}>
                        <div className="options-container">
                          <div className="occasion-group-details-container">
                            <p className="occasion-group-name">
                              {item?.whatsappTemplateMessage?.elementName}
                            </p>
                            <p className="occasion-group-type">
                              {item?.whatsappTemplateMessage?.messageType}
                            </p>
                          </div>
                        </div>
                      </Option>
                    );
                  })}
              </InputGroup>
            )}
            <WatiCard
              id={whatsappData?.templateModelableIds}
              data={
                !!props.isAnswered
                  ? props.templateModelablesData
                  : props.unansweredTemplateModelablesData
              }
              accessDataFromKey={true}
            />
            <InputGroup
              fieldType="switch"
              name="toggle"
              label="Send To All"
              onChange={handleChange}
              value={whatsappData.toggle}
              errorMessage={errorMessages.selectedUsers}
            />
            {!whatsappData.toggle && renderSelectedUsers()}
            {!whatsappData.toggle && renderListBox()}
            <SubmitButton
              buttonText="Create"
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  templateModelablesData: getAllWhatsappTemplateModelablesWithTemplateMessagesData(
    store,
    props
  ),
  templateModelablesLinks:
    store.whatsappForProvidedTask.whatsappTemplateModelablesLinks,
  providedAnsweredUsersData: getAllProvidedAnsweredUsersData(store, props),
  providedAnsweredUsersDataLinks:
    store.whatsappForProvidedTask.providedAnsweredUsersLinks,
  providedUnansweredUsersData: getAllProvidedUnansweredUsersData(store, props),
  providedUnansweredUsersDataLinks:
    store.whatsappForProvidedTask.providedUnansweredUsersLinks,
  isAnswered: store.whatsappForProvidedTask.isAnswered,
  unansweredTemplateModelablesData: getAllUnansweredWhatsappTemplateModelablesWithTemplateMessagesData(
    store,
    props
  ),
  unansweredTemplateModelablesLinks:
    store.whatsappForProvidedTask.unansweredWhatsappTemplateModelablesLinks
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createMessages,
      getWhatsappTemplateModelables,
      getProvidedAnsweredUsers,
      clearProvidedAnswerStore
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MessagesCreate);
