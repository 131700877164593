import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import { Button } from 'antd';
import CreateQuestion from './QuestionCreate';
import { occasionQuestionView } from '../../../../../../actions/question';
import { getAllQuestionWithOptionsData } from '../../../../../../selectors/question';

const ViewQuestion = (props) => {
  const [createOpen, setCreateOpen] = useState(false);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.occasionQuestionView(props.occasionId, page, props.ticketId);
  };
  const location = useLocation();
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const paginationOptions = {
    showTotal: () => (
      <div className="button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={() => setCreateOpen(true)}>
          Create
        </Button>
      </div>
    )
  };
  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="question-view-section">
          <div className="question-view-container">
            <TableView
              paginationOptions={paginationOptions}
              onPageChange={onPageChange}
              isRouted={false}
              data={props.questionData}
              fetchProgress={props.fetchProgress}
              paginationLinks={props.questionLink}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }

  return (
    <div className="edit-container">
      <CreateQuestion
        occasionId={props.occasionId}
        ticketId={props.ticketId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  questionData: getAllQuestionWithOptionsData(store, props),
  questionLink: store.question.questionLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ occasionQuestionView }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(ViewQuestion);
