import request from './request';

export function eventCollaboratorCreateApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/create_event_collaborator_by_group`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function eventCollaboratorViewApi(ocId, page, eventId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/get_event_collaborators_by_event?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function eventCollaboratorUpdateApi(
  ocId,
  eventCollaboratorId,
  eventId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/event_collaborators/${eventCollaboratorId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function eventCollaboratorDeleteApi(
  ocId,
  eventCollaboratorId,
  eventId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/event_collaborators/${eventCollaboratorId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function eventCollaboratorSearchApi(
  ocId,
  page,
  eventId,
  memberData,
  token
) {
  let params = '';

  if (memberData.info) {
    params += `&name_or_email_or_mobile=${memberData.info}`;
  }

  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/search_event_collaborator?page=${page}${params}`,
    {
      method: 'GET',
      token
    }
  );
}
