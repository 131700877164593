import React, { useState } from 'react';
import { Avatar } from '../Avatar';
import { Modal } from 'antd';
import './styles.scss';

const MoreButton = ({ item, count, onClick }) => {
  return (
    <div className="more-button-container">
      <button onClick={onClick} className="more-button">
        {count}+
      </button>
      <Avatar
        className="image-avatar"
        avatarUrl={item?.reportedByDetails?.avatarUrl}
        name={item?.reportedByDetails?.name}
      />
    </div>
  );
};

const SingleReportedByModal = ({ data }) => {
  const { reportedByDetails } = data;
  return (
    <div className="single-reportedBy-modal-container ">
      <Avatar
        avatarUrl={reportedByDetails?.avatarUrl}
        name={reportedByDetails?.name}
      />
      <p className="modal-name">{reportedByDetails?.name}</p>
      <p className="modal-message">{data?.message}</p>
    </div>
  );
};
const AllReportedByModal = ({ reportedBy }) => {
  return (
    <div className="all-reportedBy-modal-container">
      <p className="modal-header">People who has reported</p>
      {reportedBy?.map((item) => {
        const { reportedByDetails } = item;
        return (
          <div className="modal-content">
            <Avatar
              className="avatar-small"
              avatarUrl={reportedByDetails?.avatarUrl}
              name={reportedByDetails?.name}
            />
            <div>
              <span className="content-name">{reportedByDetails?.name}</span>
              <p className="content-message">{item?.message}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ReportedBy = ({ reportedBy, maxCount = 5 }) => {
  const [selectedId, setSelectedId] = useState('');
  const handleMoreButtonClick = () => {
    setSelectedId('all');
  };
  return (
    <div className="reportedBy-main-container">
      {reportedBy
        ?.filter((item, index) => index + 1 <= maxCount)
        .map((item, index) => {
          const { reportedByDetails } = item;
          return (
            <div
              className="reportedBy-content"
              style={{ zIndex: index * 10 }}
              onClick={() => setSelectedId(item.id)}>
              <Avatar
                className="image-avatar"
                avatarUrl={reportedByDetails?.avatarUrl}
                name={reportedByDetails?.name}
              />
            </div>
          );
        })}
      {reportedBy.length > maxCount && (
        <MoreButton
          item={reportedBy[maxCount]}
          count={reportedBy.length - maxCount}
          onClick={handleMoreButtonClick}
        />
      )}
      <Modal
        className="modal-container"
        footer={null}
        visible={selectedId.length > 0}
        onCancel={() => setSelectedId('')}>
        {!(selectedId === 'all') ? (
          <SingleReportedByModal
            data={reportedBy.filter((item) => item?.id === selectedId)[0]}
          />
        ) : (
          <AllReportedByModal reportedBy={reportedBy} />
        )}
      </Modal>
    </div>
  );
};

export default ReportedBy;
