import {
  CREATE_TICKET_PURCHASE_SUCCESS,
  CREATE_TICKET_PURCHASE_FAILED,
  GET_REGISTERED_TICKET_PURCHASES_SUCCESS,
  GET_REGISTERED_TICKET_PURCHASES_FAILED,
  GET_UNREGISTERED_TICKET_PURCHASES_SUCCESS,
  GET_UNREGISTERED_TICKET_PURCHASES_FAILED,
  REGISTERED_TICKET_PURCHASES_SEARCH_SUCCESS,
  UNREGISTERED_TICKET_PURCHASES_SEARCH_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  registeredData: null,
  error: null,
  registeredLinks: null,
  unregisteredData: null,
  unregisteredLinks: null
};

const purchases = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TICKET_PURCHASE_SUCCESS: {
      return {
        ...state
      };
    }

    case GET_REGISTERED_TICKET_PURCHASES_SUCCESS:
    case REGISTERED_TICKET_PURCHASES_SEARCH_SUCCESS: {
      const { data, ticketId, ocId, links } = action.payload;

      const prevData = state.registeredData || {};
      const prevPurchaseData = (prevData && prevData[ocId]) || {};
      const newIds = data ? data.map((el) => el.id) : [];

      const prevLinks = state.registeredLinks || {};
      const prevPurchaseLinks = (prevLinks && prevLinks[ocId]) || {};

      return {
        ...state,
        registeredData: {
          ...prevData,
          [ocId]: {
            ...prevPurchaseData,
            [ticketId]: newIds
          }
        },
        registeredLinks: {
          ...prevLinks,
          [ocId]: {
            ...prevPurchaseLinks,
            [ticketId]: links
          }
        }
      };
    }

    case GET_UNREGISTERED_TICKET_PURCHASES_SUCCESS:
    case UNREGISTERED_TICKET_PURCHASES_SEARCH_SUCCESS: {
      const { data, ticketId, ocId, links } = action.payload;

      const prevData = state.unregisteredData || {};
      const prevPurchaseData = (prevData && prevData[ocId]) || {};
      const newIds = data ? data.map((el) => el.id) : [];

      const prevLinks = state.unregisteredLinks || {};
      const prevPurchaseLinks = (prevLinks && prevLinks[ocId]) || {};

      return {
        ...state,
        unregisteredData: {
          ...prevData,
          [ocId]: {
            ...prevPurchaseData,
            [ticketId]: newIds
          }
        },
        unregisteredLinks: {
          ...prevLinks,
          [ocId]: {
            ...prevPurchaseLinks,
            [ticketId]: links
          }
        }
      };
    }

    case CREATE_TICKET_PURCHASE_FAILED:
    case GET_UNREGISTERED_TICKET_PURCHASES_FAILED:
    case GET_REGISTERED_TICKET_PURCHASES_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default purchases;
