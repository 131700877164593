import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import {
  getAdminOccasionMember,
  deleteAdminOccasionMember
} from '../../../../../actions/admin';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import TableView from '../../../../../components/Table';
import { adminOccasionLinks } from '../../../../../selectors/static';
import AssignAdminOccasion from './AssignAdminOccasion';
import {
  getAdminOccasionsWithData,
  getCurrentAdmin
} from '../../../../../selectors/admin';

const ViewAdminOccasion = (props) => {
  const [assignOpen, setAssignOpen] = React.useState(false);
  const [adminOccasionId, setAdminOccasionId] = React.useState(null);
  const [ocId, setOcId] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.getAdminOccasionMember(
      page,
      props.adminId,
      props.currentAdmin.currentStatus
    );
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleCreate = () => {
    setOcId('');
    setAdminOccasionId('');
    setAssignOpen(true);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="award-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        {props.currentAdmin?.accessType !== 'super_admin' &&
        props.currentAdmin?.currentStatus === 'active' ? (
          <Button className="create-button" onClick={handleCreate}>
            Assign
          </Button>
        ) : (
          ''
        )}
        <div className="admin-occasion-count-container">
          <span>Occasion</span>
          <span className="admin-occasion-count">{props.count}</span>
        </div>
      </div>
    )
  };

  const editData = (adminOcId) => {
    const result = props.adminOccasionData.find(({ id }) => id === adminOcId);
    setOcId(result.occasion.id);
    setAssignOpen(true);
    setAdminOccasionId(adminOcId);
  };

  const deleteData = (adminOcId) => {
    const result = props.adminOccasionData.find(({ id }) => id === adminOcId);
    props.deleteAdminOccasionMember(
      result.occasion.id,
      adminOcId,
      props.currentAdmin.id
    );
  };

  if (!assignOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="admin-occasion-view-section">
          <div className="admin-occasion-view-container">
            {props.currentAdmin?.accessType === 'super_admin' ? (
              props.superAdminOccasionsMessage
            ) : (
              <TableView
                isPaginated={true}
                fetchProgress={props.fetchProgress}
                paginationOptions={paginationOptions}
                onPageChange={onPageChange}
                onDelete={deleteData}
                isEditable={editData}
                data={props.adminOccasionData}
                isRouted={false}
                paginationLinks={props.adminOccasionLinks}
                blacklistKeys={['socianaAdmin']}
              />
            )}
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <AssignAdminOccasion
        adminId={props.currentAdmin.id}
        ocId={ocId}
        adminOccasionId={adminOccasionId}
        handleCloseClick={() => setAssignOpen(false)}
      />
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  adminOccasionLinks: store.admin.adminOccasionsLinks,
  adminOccasionData: getAdminOccasionsWithData(store, props),
  currentAdmin: getCurrentAdmin(store, props),
  superAdminOccasionsMessage: store.admin.adminOccasionsMessage,
  count: store.admin.adminOccasionsCount
});

const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { getAdminOccasionMember, deleteAdminOccasionMember },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(ViewAdminOccasion);
