import React from 'react';
import { ZoomInOutlined, EditOutlined } from '@ant-design/icons';
import CreateTicket from './TicketCreate';

const TicketDetailsView = ({ ticketDetails, occasionId }) => {
  const [isEditOpen, setIsEditOpen] = React.useState(false);
  if (!isEditOpen) {
    return (
      <div className="ticket-details-container">
        <p className="ticket-details">
          <p className="fields">Name:</p>
          <p className="field-data">{ticketDetails?.name}</p>
        </p>
        {ticketDetails.description ? (
          <p className="ticket-details">
            <p className="fields">Description:</p>
            <p className="field-data">{ticketDetails?.description}</p>
          </p>
        ) : (
          ''
        )}
        {ticketDetails.tnc ? (
          <p className="ticket-details">
            <p className="fields">Terms And Conditions:</p>
            <p className="field-data">{ticketDetails?.tnc}</p>
          </p>
        ) : (
          ''
        )}
        {ticketDetails.faqs ? (
          <p className="ticket-details">
            <p className="fields">Frequently Asked Questions:</p>
            <p className="field-data">{ticketDetails?.faqs}</p>
          </p>
        ) : (
          ''
        )}
        {ticketDetails.remark ? (
          <p className="ticket-details">
            <p className="fields">Remark:</p>
            <p className="field-data">{ticketDetails?.remark}</p>
          </p>
        ) : (
          ''
        )}
        <button
          className="edit-details-container"
          onClick={() => setIsEditOpen(true)}>
          <EditOutlined />
          <span>Edit</span>
        </button>
      </div>
    );
  }
  return (
    <div className="edit-container">
      <CreateTicket
        ticketId={ticketDetails?.id}
        occasionId={occasionId}
        ticketDetails={ticketDetails}
        handleCloseClick={() => setIsEditOpen(false)}
      />
    </div>
  );
};
export default TicketDetailsView;
