import { createSelector } from 'reselect';
import { currentOccasionId } from './dynamicData';
import { occasionEntities } from './entity';
import { occasionIds, allOccasionIds } from './static';

export const getAllOccasions = createSelector(
  occasionEntities,
  occasionIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const allOccasionsData = createSelector(
  occasionEntities,
  allOccasionIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getCurrentOccasion = createSelector(
  currentOccasionId,
  occasionEntities,
  (ocId, entities) => ocId && entities && entities[ocId]
);
