import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import { Image } from 'antd';
import { SectionContent } from '../../../components/Section';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { adminData } from '../../../selectors/auth';
import { getDate, getMonth } from '../../../helpers/dateTimeHelper';
import UpdatesContainer from './UpdatesContainer';
import DASHBOARD_IMAGE from '../../../assets/dashboard-image.svg';

function Home(props) {
  const date = new Date();
  const day = getDate(date);
  const month = getMonth(date);
  return (
    <ErrorBoundary>
      <SectionContent className="home-section">
        <div className="home-image-container">
          <Image preview={false} src={DASHBOARD_IMAGE} className="home-image" />
        </div>
        <div className="home-content">
          <header className="home-header">
            <h1 className="home-heading">Welcome</h1>
            <h1 className="home-heading">{props.adminData?.name}</h1>
          </header>

          <div className="home-date-container">
            <p className="home-date">{day}</p>
            <p className="home-month">{month}</p>
          </div>
          <UpdatesContainer
            updates={[
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
            ]}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  adminData: adminData(store, props)
});

export default connect(mapStateToProps, null)(Home);
