import { union } from 'lodash';
import {
  OCCASION_CAROUSEL_VIEW_SUCCESS,
  OCCASION_CAROUSEL_VIEW_FAILED,
  OCCASION_CAROUSEL_CREATE_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  occasionCarouselData: null,
  error: null,
  carouselLinks: null
};

const carousel = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_CAROUSEL_VIEW_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.occasionCarouselData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionCarouselData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        carouselLinks: links
      };
    }

    case OCCASION_CAROUSEL_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionCarouselData || {};
      const currentOccasionCarouselIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionCarouselIds];
      return {
        ...state,
        occasionCarouselData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }
    case OCCASION_CAROUSEL_VIEW_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default carousel;
