import {
  SET_CURRENT_TAB_INDEX,
  RESET_TAB_INDEX
} from '../constants/actionTypes';

export const setCurrentTabIndex = (tabIndex, tabName) => ({
  type: SET_CURRENT_TAB_INDEX,
  payload: { tabIndex, tabName }
});

export const resetTabIndex = (tabName) => ({
  type: RESET_TAB_INDEX,
  payload: { tabName }
});
