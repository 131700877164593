import React, { useCallback, useState } from 'react';
import {
  LoadingOutlined,
  EyeOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { getImageSource } from '../../../../../../../helpers/imageHelper';
import { Image } from '../../../../../../../components/Image';
import Modal from 'antd/lib/modal/Modal';
import './styles.scss';

function AlbumImage({ image, onDeleteClick }) {
  const [toggleModal, setToggleModal] = useState(false);
  const [loader, setLoader] = useState(true);

  const toggleModalClick = useCallback(() => {
    setToggleModal((val) => !val);
  }, []);

  const onImageLoad = useCallback(() => {
    setLoader(false);
  }, []);

  const onDelete = (e) => {
    e.stopPropagation();
    const result = window.confirm(
      'Are you sure you want to delete this photo?'
    );
    if (result && typeof onDeleteClick === 'function') {
      onDeleteClick(image.id);
    }
  };

  return (
    <div className="gallery-image-box">
      <Image
        className={`gallery-image ${loader ? 'invisible' : 'visible'}`}
        preview={false}
        onClick={toggleModalClick}
        onLoad={onImageLoad}
        imageUrl={getImageSource(image.fileUrl)}
      />
      {loader && (
        <div className="ant-image gallery-mask show-element">
          <LoadingOutlined />
        </div>
      )}
      {!loader && (
        <div
          className="ant-image gallery-mask hide-element"
          onClick={toggleModalClick}>
          <EyeOutlined />
          &nbsp;Preview
        </div>
      )}
      <div
        className="ant-image gallery-name show-element"
        onClick={toggleModalClick}>
        <span
          style={{
            overflow: 'hidden',
            display: 'block',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}>
          {image?.user?.name}
        </span>
        {typeof onDeleteClick === 'function' && (
          <DeleteOutlined
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={onDelete}
          />
        )}
      </div>
      <Modal
        wrapClassName="gallery-image-modal"
        bodyStyle={{ padding: 0 }}
        visible={toggleModal}
        onCancel={toggleModalClick}
        centered
        closable={false}
        footer={null}>
        <Image
          className="gallery-video"
          preview={false}
          imageUrl={getImageSource(image?.fileUrl)}
        />
      </Modal>
    </div>
  );
}

export default AlbumImage;
