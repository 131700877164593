import {
  DELETE_HOSPITALITY_GOOGLE_SHEET_FAILED,
  DELETE_HOSPITALITY_GOOGLE_SHEET_SUCCESS,
  GET_HOSPITALITY_GOOGLE_SHEET_FAILED,
  GET_HOSPITALITY_GOOGLE_SHEET_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  hospitalityGoogleSheetId: null,
  error: null,
  hospitalityGoogleSheetLinks: null
};
const hospitalityGoogleSheet = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOSPITALITY_GOOGLE_SHEET_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.hospitalityGoogleSheetId || {};
      const currentHospitalityGoogleSheetNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        hospitalityGoogleSheetId: {
          ...occasionGroupedData,
          [ocId]: [...currentHospitalityGoogleSheetNewIds]
        },
        hospitalityGoogleSheetLinks: links
      };
    }

    case DELETE_HOSPITALITY_GOOGLE_SHEET_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.hospitalityGoogleSheetId || {};
      const currentOccasionGoogleSheetIds = occasionGroupedData[ocId] || [];
      const filteredIds = currentOccasionGoogleSheetIds.filter(
        (id) => id !== data.id
      );

      return {
        ...state,
        hospitalityGoogleSheetId: {
          ...occasionGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case DELETE_HOSPITALITY_GOOGLE_SHEET_FAILED:
    case GET_HOSPITALITY_GOOGLE_SHEET_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};
export default hospitalityGoogleSheet;
