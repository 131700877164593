import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import TableView from '../../../../../components/Table';
import { getInternalTeamPendingInvitation } from '../../../../../actions/socianaInternalUser';
import {
  getPendingInvitationWithOccasion,
  currentStatusInvitationLink
} from '../../../../../selectors/socianaInternalUser';

const Invitation = (props) => {
  const onPageChange = (page) => {
    props.getInternalTeamPendingInvitation(page, props.userId, props.status);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, [props.status]);

  return (
    <ErrorBoundary>
      <div>
        <TableView
          data={props.invitationData}
          isPaginated={true}
          paginationLinks={props.invitationLinks}
          blacklistKeys={['occasion', 'invitedBy']}
          onPageChange={onPageChange}
          fetchProgress={props.fetchProgress}
          isRouted={false}
        />
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  invitationData: getPendingInvitationWithOccasion(store, props),
  invitationLinks: currentStatusInvitationLink(store, props)
});

const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ getInternalTeamPendingInvitation }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(Invitation);
