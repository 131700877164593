import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import TableView from '../../../../../components/Table';
import {
  getOccasionPendingRegistration,
  resendPendingRegistrations,
  deleteOccasionPendingRegistration
} from '../../../../../actions/Invitation';
import { pendingRegistrationWtihUserData } from '../../../../../selectors/invitation';
import PendingRegistrationSearchTab from './PendingRegistrationSearch';
import { Button } from 'antd';
import PendingRegistrationEdit from './PendingRegistrationEdit';

function PendingRegistration(props) {
  const [pendingRegistrationId, setPendingRegistrationId] = React.useState(
    null
  );
  const [isEditable, setIsEditable] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.getOccasionPendingRegistration(props.occasionId, page);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const resendRegistration = (allOrId) => {
    props.resendPendingRegistrations(props.occasionId, allOrId);
  };

  const customAction = (id) => {
    return (
      <Button className="action-button" onClick={() => resendRegistration(id)}>
        Send
      </Button>
    );
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="occasion-invitation-button-container">
        <div className="button-content">
          <Button className="refresh-button" onClick={() => onPageChange(page)}>
            <SyncOutlined />
          </Button>
          <Button
            className="invitation-action-button"
            onClick={() => resendRegistration(true)}>
            Send Invitations to All
          </Button>
        </div>
        <div className="count-container">
          <span>Registrations</span>
          <span className="count-text">{props.count}</span>
        </div>
      </div>
    )
  };

  const deleteData = (id) => {
    props.deleteOccasionPendingRegistration(props.occasionId, id);
  };

  const editData = (id) => {
    setIsEditable(true);
    setPendingRegistrationId(id);
  };
  if (!isEditable) {
    return (
      <ErrorBoundary>
        <SectionContent className="invitation-view-section">
          <div className="invitation-view-container">
            <PendingRegistrationSearchTab ocId={props.occasionId} />
            <TableView
              isPaginated={true}
              fetchProgress={props.fetchProgress}
              paginationOptions={paginationOptions}
              onPageChange={onPageChange}
              onDelete={deleteData}
              isEditable={editData}
              paginationLinks={props.pendingRegistrationLink}
              data={props.pendingRegistrations}
              isRouted={false}
              customAction={customAction}
              normalTime={true}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <PendingRegistrationEdit
        pendingRegistrationId={pendingRegistrationId}
        occasionId={props.occasionId}
        handleCloseClick={() => setIsEditable(false)}
      />
    </div>
  );
}

const mapStateToProps = (store, props) => ({
  pendingRegistrations: pendingRegistrationWtihUserData(store, props),
  fetchProgress: store.browserState.fetchProgress,
  pendingRegistrationLink: store.invitation.pendingRegistrationLink,
  count: store.invitation.pendingRegistrationCount
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOccasionPendingRegistration,
      resendPendingRegistrations,
      deleteOccasionPendingRegistration
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingRegistration);
