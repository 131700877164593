import { createSelector } from 'reselect';
import { currentOccasionId, currentAlbumId } from './dynamicData';
import { albumEntities, userEntities, mediaEntities } from './entity';
import {
  albumIds,
  albumPhotoIds,
  albumPhotosNextPresent,
  albumVideoIds,
  albumVideosNextPresent,
  uploadAlbumImageList
} from './static';

export const currentOccasionAlbumIds = createSelector(
  currentOccasionId,
  albumIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getAllOccasionAlbums = createSelector(
  albumEntities,
  currentOccasionAlbumIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
export const occasionAlbumWithUserData = createSelector(
  getAllOccasionAlbums,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      createdBy: { ...entities[item?.createdBy?.id] }
    }))
);

export const getCurrentAlbum = createSelector(
  currentAlbumId,
  albumEntities,
  (id, entities) => id && entities && entities[id]
);

export const getAlbumMediaUploadList = createSelector(
  currentAlbumId,
  uploadAlbumImageList,
  (id, list) => {
    return id && list && list[id] && Object.values(list[id]);
  }
);

export const getAlbumPhotoIds = createSelector(
  currentAlbumId,
  albumPhotoIds,
  (ocId, data) => ocId && data && data[ocId]
);

export const getAlbumVideoIds = createSelector(
  currentAlbumId,
  albumVideoIds,
  (ocId, data) => ocId && data && data[ocId]
);

export const getAllAlbumPhotos = createSelector(
  getAlbumPhotoIds,
  mediaEntities,
  userEntities,
  (ids, entities, users) =>
    (ids &&
      entities &&
      ids.map((id) => ({
        ...entities[id],
        user: users &&
          entities &&
          entities[id] &&
          entities[id]?.createdBy &&
          entities[id]?.createdBy?.id &&
          users[entities[id]?.createdBy?.id] && {
            ...users[entities[id]?.createdBy?.id]
          }
      }))) ||
    []
);

export const getAllAlbumVideos = createSelector(
  getAlbumVideoIds,
  mediaEntities,
  userEntities,
  (ids, entities, users) =>
    (ids &&
      entities &&
      ids.map((id) => ({
        ...entities[id],
        user: users &&
          entities &&
          entities[id] &&
          entities[id]?.createdBy &&
          entities[id]?.createdBy?.id &&
          users[entities[id]?.createdBy?.id] && {
            ...users[entities[id]?.createdBy?.id]
          }
      }))) ||
    []
);

export const getAllAlbumPhotosNextPresent = createSelector(
  currentAlbumId,
  albumPhotosNextPresent,
  (id, links) => id && links && links[id]
);

export const getAllAlbumVideosNextPresent = createSelector(
  currentAlbumId,
  albumVideosNextPresent,
  (id, links) => id && links && links[id]
);
