import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../styles.scss';
import { Form, AutoComplete, Button } from 'antd';
import { SectionContent } from '../../../../../../components/Section';
import { CloseOutlined } from '@ant-design/icons';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import {
  createHospitalityTaskPublicAnswer,
  updateHospitalityTaskPublicAnswer
} from '../../../../../../actions/publicAnswer';
import moment from 'moment';
import { getCurrentHospitalityPublicAnswer } from '../../../../../../selectors/publicAnswer';

const { Option } = AutoComplete;

const validateHospitalityTask = (values, dataType) => {
  const errors = {};

  if (dataType === 'datetime') {
    if (!values.answerDate) errors.answerDate = 'Date is required';
    if (!values.answerTime) errors.answerTime = 'Time is required';
  }

  if (dataType === 'document') {
    if (!values.answer) errors.answer = 'Document is required';
  }

  if (dataType === 'text') {
    if (!values.answer) errors.answer = 'Value is required';
  }

  if (dataType === 'number') {
    if (!values.answer) errors.answer = 'Number is required';
    if (isNaN(values.answer)) errors.answer = 'Not a number';
  }

  if (dataType === 'scq') {
    if (!values.answer) errors.answer = 'Select an option';
  }

  if (dataType === 'mcq') {
    if (!values.answer || values.answer.length === 0)
      errors.answer = 'Select an option';
  }

  return errors;
};

const AddPublicAnswer = (props) => {
  const [hospitalityTaskAnswer, setHospitalityTaskAnswer] = React.useState({
    answer:
      props.hospitalityTaskDetails?.dataInputType === 'mcq'
        ? props.initialData?.answer || []
        : props.hospitalityTaskDetails?.dataInputType === 'document'
        ? props.initialData?.answer || null
        : props.initialData?.answer || '',
    answerDate: props.initialData?.answerDate || '',
    answerTime: props.initialData?.answerTime || ''
  });

  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleHospitalityTaskCreate = () => {
    try {
      const validateObj = validateHospitalityTask(
        hospitalityTaskAnswer,
        props.hospitalityTaskDetails?.dataInputType
      );
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        const formData = new FormData();

        if (props.hospitalityTaskDetails?.dataInputType === 'document') {
          formData.append(
            'hospitality_task_public_answer[document_attachment]',
            hospitalityTaskAnswer.answer[0].originFileObj
          );
        }

        if (props.hospitalityTaskDetails?.dataInputType === 'mcq') {
          let str = '';
          hospitalityTaskAnswer.answer &&
            hospitalityTaskAnswer.answer.forEach((el, index, all) => {
              if (index === all.length - 1) {
                str += el;
              } else {
                str += el + ',';
              }
            });
          formData.append('hospitality_task_public_answer[public_answer]', str);
        }

        if (props.hospitalityTaskDetails?.dataInputType === 'datetime') {
          const date = moment(hospitalityTaskAnswer.answerDate).format(
            'YYYY-MM-DD'
          );
          const time = moment(hospitalityTaskAnswer.answerTime).format('HH:mm');
          const dateTime = new Date(`${date} ${time}`);
          formData.append(
            'hospitality_task_public_answer[public_answer]',
            dateTime
          );
        }

        if (
          props.hospitalityTaskDetails?.dataInputType === 'text' ||
          props.hospitalityTaskDetails?.dataInputType === 'scq' ||
          props.hospitalityTaskDetails?.dataInputType === 'number'
        ) {
          formData.append(
            'hospitality_task_public_answer[public_answer]',
            hospitalityTaskAnswer.answer
          );
        }

        if (props.publicAnswerId) {
          props.updateHospitalityTaskPublicAnswer(
            props.occasionId,
            props.hospitalityTaskId,
            props.publicAnswerId,
            formData,
            callback
          );
        } else {
          props.createHospitalityTaskPublicAnswer(
            props.occasionId,
            props.hospitalityTaskId,
            formData,
            callback
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const callback = () => {
    setHospitalityTaskAnswer({
      answer:
        props.hospitalityTaskDetails?.dataInputType === 'mcq'
          ? []
          : props.hospitalityTaskDetails?.dataInputType === 'document'
          ? null
          : '',
      answerDate: '',
      answerTime: ''
    });
    props.handleCloseClick && props.handleCloseClick();
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (isInitiallyDisabled) {
          setInitiallyDisabled(false);
        }

        if (type === 'answer') {
          value = e.fileList;
          name = type;
        }
        if (type === 'answerDate') {
          value = moment(e, 'DD/MM/YYYY');
          name = type;
        }
        if (type === 'answerTime') {
          value = moment(e, 'HH:mm');
          name = type;
        }
        const hospitalityTaskAnswerValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        setHospitalityTaskAnswer({
          ...hospitalityTaskAnswer,
          [inputName]: hospitalityTaskAnswerValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const optionsData = () => {
    const arr =
      (props.hospitalityTaskDetails &&
        props.hospitalityTaskDetails.hospitalityTaskOptions &&
        props.hospitalityTaskDetails.hospitalityTaskOptions.map((el) => ({
          label: el.option,
          name: el.option
        }))) ||
      [];

    return arr;
  };

  return (
    <ErrorBoundary>
      <SectionContent className="hospitality-section">
        <div className="hospitality-create-form">
          <Form
            className="hospitality-form-container"
            onFinish={handleHospitalityTaskCreate}>
            {props.hospitalityTaskDetails.dataInputType === 'datetime' && (
              <>
                <InputGroup
                  fieldType="datePicker"
                  name="answerDate"
                  label="Provide Date"
                  value={hospitalityTaskAnswer?.answerDate}
                  onChange={handleChange}
                  errorMessage={errorMessages.answerDate}
                />
                <InputGroup
                  fieldType="timePicker"
                  name="answerTime"
                  label="Provide Time"
                  format="HH:mm"
                  value={hospitalityTaskAnswer?.answerTime}
                  onChange={handleChange}
                  errorMessage={errorMessages.answerTime}
                />
              </>
            )}
            {(props.hospitalityTaskDetails.dataInputType === 'number' ||
              props.hospitalityTaskDetails.dataInputType === 'text') && (
              <InputGroup
                fieldType={
                  props.hospitalityTaskDetails.dataInputType === 'number'
                    ? 'text'
                    : 'textArea'
                }
                name="answer"
                label="Your Answer"
                value={hospitalityTaskAnswer?.answer}
                onChange={handleChange}
                errorMessage={errorMessages.answer}
              />
            )}
            {props.hospitalityTaskDetails.dataInputType === 'document' && (
              <InputGroup
                fieldType="file"
                name="answer"
                label="Your Answer"
                accept="*/*"
                fileList={hospitalityTaskAnswer?.answer}
                onChange={handleChange}
                errorMessage={errorMessages.answer}
              />
            )}
            {(props.hospitalityTaskDetails.dataInputType === 'mcq' ||
              props.hospitalityTaskDetails.dataInputType === 'scq') && (
              <InputGroup
                fieldType="dropdown"
                className="dropdown-input"
                name="answer"
                label="Your Answer"
                mode={
                  props.hospitalityTaskDetails.dataInputType === 'mcq'
                    ? 'multiple'
                    : 'single'
                }
                placeholder="Select Options"
                onChange={handleChange}
                value={hospitalityTaskAnswer?.answer}
                errorMessage={errorMessages.answer}>
                {optionsData()?.length > 0 &&
                  optionsData().map((item, index) => {
                    return (
                      <Option value={item?.label} key={index}>
                        <div style={{ display: 'flex' }}>
                          <span>{item.name}</span>
                        </div>
                      </Option>
                    );
                  })}
              </InputGroup>
            )}
            <SubmitButton
              buttonText="Add Answer"
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => {
  const currentAnswer = getCurrentHospitalityPublicAnswer(store, props);
  let initialData = {};
  if (currentAnswer?.documentAttachmentUrl) {
    initialData.answer = [
      {
        name: currentAnswer?.documentAttachmentUrl,
        url: currentAnswer?.documentAttachmentUrl
      }
    ];
  }

  if (
    props.hospitalityTaskDetails?.dataInputType === 'text' ||
    props.hospitalityTaskDetails?.dataInputType === 'scq' ||
    props.hospitalityTaskDetails?.dataInputType === 'number'
  ) {
    if (currentAnswer?.publicAnswer) {
      initialData.answer = currentAnswer?.publicAnswer;
    }
  }

  if (props.hospitalityTaskDetails?.dataInputType === 'mcq') {
    if (currentAnswer?.publicAnswer) {
      const options = currentAnswer?.publicAnswer.split(',');
      initialData.answer = options;
    }
  }

  if (props.hospitalityTaskDetails?.dataInputType === 'datetime') {
    if (currentAnswer?.publicAnswer) {
      initialData.answerDate = moment(currentAnswer?.publicAnswer);
      initialData.answerTime = moment(currentAnswer?.publicAnswer);
    }
  }

  return {
    fetchProgress: store.browserState.fetchProgress,
    currentAnswer,
    initialData
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createHospitalityTaskPublicAnswer,
      updateHospitalityTaskPublicAnswer
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddPublicAnswer);
