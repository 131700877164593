import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import Tabs from '../../../../../components/Tabs';
import { currentSpeakerData } from '../../../../../selectors/speaker';
import { getCurrentOccasion } from '../../../../../selectors/occasion';
import {
  currentOccasionId,
  currentSpeakerId
} from '../../../../../selectors/dynamicData';
import {
  resetTabIndex,
  setCurrentTabIndex
} from '../../../../../actions/tabKeys';
import { TabsHeader } from '../../../../../components/TabsHeader/index';
import SpeakerDetailsView from './details';
import SpeakerReferenceView from './Reference/view';

function SpeakerDetails(props) {
  const tabPanes = [
    {
      tabName: 'Details',
      tabKey: '1',
      children: (
        <SpeakerDetailsView
          occasionId={props.occasionId}
          speakerDetails={props.speakerDetails}
        />
      )
    },
    {
      tabName: 'References',
      tabKey: '2',
      children: (
        <SpeakerReferenceView
          occasionId={props.occasionId}
          speakerId={props.currentSpeakerId}
        />
      )
    }
  ];

  const goBack = () => {
    props.resetTabIndex('speaker');
    props.history.goBack();
  };

  const handleTabChange = (key) => {
    props.setCurrentTabIndex(key, 'speaker');
  };

  return (
    <ErrorBoundary>
      <SectionContent className="speaker-tab-section">
        <TabsHeader
          name={props.currentOccasion.name}
          extraData={[
            props.currentOccasion.type,
            props.currentOccasion.guestType,
            props.speakerDetails.name
          ]}
          wrapperClass="section-details"
        />
        <div className="speaker-tab-container">
          <Tabs
            defaultActiveKey={props.activeTabKey}
            tabPanes={tabPanes}
            onChange={handleTabChange}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  activeTabKey: store.tabKeys.speaker,
  fetchProgress: store.browserState.fetchProgress,
  speakerDetails: currentSpeakerData(store, props),
  occasionId: currentOccasionId(store, props),
  currentOccasion: getCurrentOccasion(store, props),
  currentSpeakerId: currentSpeakerId(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setCurrentTabIndex, resetTabIndex }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SpeakerDetails);
