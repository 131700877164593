import request from './request';

export function occasionAwardCreateApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/award/awards`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function occasionAwardViewApi(ocId, page, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/award/awards?page=${page}`, {
    method: 'GET',
    token
  });
}

export function deleteAwardApi(ocId, awardId, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/award/awards/${awardId}`, {
    method: 'DELETE',
    token
  });
}

export function updateAwardApi(ocId, awardId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/award/awards/${awardId}`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}
