import { union } from 'lodash';
import {
  OCCASION_DIVISION_VIEW_SUCCESS,
  OCCASION_DIVISION_VIEW_FAILED,
  OCCASION_DIVISION_CREATE_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  occasionDivisionData: null,
  error: null,
  divisionLinks: null
};

const division = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_DIVISION_VIEW_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.occasionDivisionData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionDivisionData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        divisionLinks: links
      };
    }

    case OCCASION_DIVISION_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionDivisionData || {};
      const currentOccasionDivisionIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionDivisionIds];
      return {
        ...state,
        occasionDivisionData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }
    case OCCASION_DIVISION_VIEW_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default division;
