import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { SyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { SectionContent } from '../../../../components/Section';
import { getOccasionWithPagination } from '../../../../actions/occasion';
import { resetTabIndex } from '../../../../actions/tabKeys';
import { seperateWordsWithSpace } from '../../../../helpers/stringHelper';
import {
  getAllOccasions,
  getCurrentOccasion
} from '../../../../selectors/occasion';
import './styles.scss';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import TableView from '../../../../components/Table';
import InputGroup from '../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../components/InputGroup/FormComponents';
import Form from 'antd/lib/form/Form';
import { getOccasionByField } from '../../../../actions/occasion';
import { renderRoute } from '../../../../components/RouterHOC';
import { adminViewRouteAccess } from '../../../../selectors/auth';
function OccasionView(props) {
  const [getOccasionDetails, setgetOccasionDetails] = useState({});
  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [errorMessages, setErrorMessages] = React.useState({});
  const onPageChange = (page) => {
    setPage(page);
    props.getOccasionWithPagination(page);
  };
  React.useEffect(() => {
    onPageChange(1);
    props.resetTabIndex('occasion');
  }, []);

  const validate = (values) => {
    const errors = {};
    if (
      values?.startDate &&
      values?.endDate &&
      values.startDate > values.endDate
    )
      errors.type = 'invalid date';

    return errors;
  };

  const handlegetOccasionDetails = (page) => {
    try {
      const validateObj = validate(getOccasionDetails);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        props.getOccasionByField(
          getOccasionDetails?.ocName,
          getOccasionDetails?.startDate?.format('DD/MM/YYYY'),
          getOccasionDetails?.endDate?.format('MM/DD/YYYY'),
          getOccasionDetails?.guestType,
          getOccasionDetails?.ocType,
          getOccasionDetails?.ocCode,
          (page = 1)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (isInitiallyDisabled) {
          setInitiallyDisabled(false);
        }
        if (type === 'ocName') {
          value = e.target.value;
          name = type;
        }
        if (type === 'startDate') {
          value = moment(e, 'DD/MM/YYYY');
          name = type;
        }
        if (type === 'endDate') {
          value = moment(e, 'DD/MM/YYYY');
          name = type;
        }
        if (type === 'guestType') {
          value = e.target.value;
          name = type;
        }
        if (type === 'ocType') {
          value = e.target.value;
          name = type;
        }
        if (type === 'ocCode') {
          value = e.target.value;
          name = type;
        }
        const occasionDetailsValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        setgetOccasionDetails({
          ...getOccasionDetails,
          [inputName]: occasionDetailsValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleReset = (page) => {
    setgetOccasionDetails({});
    setErrorMessages({});
    props.getOccasionWithPagination((page = 1));
  };

  const getOccasionTypes = () =>
    props.occasionTypes
      ? props.occasionTypes.map((el) => ({
          label: seperateWordsWithSpace(el),
          value: el
        }))
      : [];

  const getOccasionGuestTypes = () =>
    props.occasionGuestTypes && Object.keys(props.occasionGuestTypes).length > 0
      ? Object.keys(props.occasionGuestTypes).map((el) => ({
          label: seperateWordsWithSpace(el),
          value: el
        }))
      : [];

  const paginationOptions = {
    showTotal: () => (
      <div className="occasion-view-button-box">
        <div className="occasion-count-container">
          <span>Occasions</span>
          <span className="occasion-count">{props.count}</span>
        </div>
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
      </div>
    )
  };

  return (
    <ErrorBoundary>
      <SectionContent className="occasion-view-section">
        <div className="occasion-view-container">
          <Form
            className="occasion-input-search-container"
            onFinish={handlegetOccasionDetails}>
            <InputGroup
              label="Occasion Name"
              className="occasion-inputbox"
              fieldType="searchbox"
              placeholder="Occasion name"
              name="ocName"
              onChange={handleChange}
              value={getOccasionDetails?.ocName}
            />
            <InputGroup
              label="Start Date"
              className="occasion-date-inputbox"
              fieldType="datePicker"
              placeholder="Start Date"
              name="startDate"
              onChange={handleChange}
              value={getOccasionDetails?.startDate}
            />
            <InputGroup
              label="End Date"
              className="occasion-date-inputbox"
              fieldType="datePicker"
              placeholder="End Date"
              name="endDate"
              onChange={handleChange}
              value={getOccasionDetails?.endDate}
              errorMessage={errorMessages.type}
            />
            <InputGroup
              label="Guest Type"
              className="occasion-inputbox"
              fieldType="dropdown"
              placeholder="Select"
              options={getOccasionGuestTypes()}
              name="guestType"
              onChange={handleChange}
              value={getOccasionDetails?.guestType}
            />
            <InputGroup
              label="Occasion Type"
              className="occasion-inputbox"
              fieldType="dropdown"
              placeholder="Select"
              options={getOccasionTypes()}
              name="ocType"
              onChange={handleChange}
              value={getOccasionDetails?.ocType}
            />
            <InputGroup
              label="Occasion Code"
              className="occasion-inputbox"
              fieldType="searchbox"
              placeholder="Occasion Code"
              name="ocCode"
              onChange={handleChange}
              value={getOccasionDetails?.ocCode}
            />
            <div style={{ flex: 1, display: 'flex' }}>
              <SubmitButton
                wrapperClass="occasion-submit-wrapper"
                className="occasion-submit-inputbox"
                buttonText="Search"
                fetchProgress={props.fetchProgress}
                disabled={props.fetchProgress}
              />
              <Button className="reset-button" onClick={handleReset}>
                Reset
              </Button>
            </div>
          </Form>
          <TableView
            paginationOptions={paginationOptions}
            isPaginated={true}
            fetchProgress={props.fetchProgress}
            data={props.allOccasions}
            blacklistKeys={[
              'occasionCodes',
              'specificSocianaAdminOccasion',
              'occasionFont',
              'occasionFileCdnUrl',
              'thankYouMediaCdnUrl',
              'totalMemberCount',
              'activeInternalMemberCount'
            ]}
            paginationLinks={props.occasionLinks}
            onPageChange={onPageChange}
            normalTime={true}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  currentOccasion: getCurrentOccasion(store, props),
  allOccasions: getAllOccasions(store, props),
  fetchProgress: store.browserState.fetchProgress,
  occasionLinks: store.occasion.links,
  occasionTypes: store.occasion.types,
  occasionGuestTypes: store.occasion.guestTypes,
  occasionNames: store.occasion,
  count: store.occasion.count
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOccasionWithPagination,
      resetTabIndex,
      getOccasionByField
    },
    dispatch
  );

export default renderRoute(adminViewRouteAccess)(
  connect(mapStateToProps, mapDispatchToProps)(OccasionView)
);
