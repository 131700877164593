import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, AutoComplete, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../../components/Section';
import ErrorBoundary from '../../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../../../components/InputGroup/FormComponents';
import { getAllDivisionData } from '../../../../../../../selectors/division';
import { occasionDivisionView } from '../../../../../../../actions/division';
import { occasionSubdivisionCreate } from '../../../../../../../actions/subdivision';

const validateSubdivision = (values) => {
  const error = {};
  if (!values.priority) {
    error.priority = 'Priority is required';
  }
  if (!values.division) {
    error.division = 'Division is required';
  }
  return error;
};

const { Option } = AutoComplete;

function SubdivisionCreate(props) {
  const [subdivisionData, setSubdivisionData] = React.useState({});
  const [errorMessages, setErrorMessages] = React.useState({});

  const onPageChange = (page) => {
    props.occasionDivisionView(props.occasionId, page);
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleSubmit = () => {
    try {
      const validateObj = validateSubdivision(subdivisionData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('subdivision_segment[title]', subdivisionData.title);
        formData.append(
          'subdivision_segment[reference_name]',
          subdivisionData.name ? subdivisionData.name : null
        );
        formData.append(
          'subdivision_segment[description]',
          subdivisionData.description ? subdivisionData.description : null
        );
        formData.append(
          'subdivision_segment[priority]',
          subdivisionData.priority
        );
        formData.append(
          'subdivision_segment[reference_link]',
          subdivisionData.link ? subdivisionData.link : null
        );
        formData.append(
          'subdivision_segment[division_segment_id]',
          subdivisionData.division
        );
        if (
          subdivisionData.cardImage &&
          subdivisionData.cardImage[0]?.originFileObj
        ) {
          formData.append(
            'subdivision_segment[card_image]',
            subdivisionData.cardImage[0].originFileObj
          );
        }
        props.occasionSubdivisionCreate(
          props.occasionId,
          props.divisionId,
          formData
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;

      if (type === 'cardImage') {
        value = e.fileList;
        name = type;
      }

      const formValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setSubdivisionData({
        ...subdivisionData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ErrorBoundary>
      <SectionContent className="subdivision-create-section">
        <div className="subdivision-create-form">
          <Form className="subdivision-form" onFinish={handleSubmit}>
            <InputGroup
              label="Title"
              name="title"
              placeholder="Enter Title"
              onChange={handleInputChange}
              value={subdivisionData?.title}
            />
            <InputGroup
              fieldType="dropdown"
              className="division-search"
              mode="single"
              name="division"
              label="Select Division"
              placeholder="Select Division"
              errorMessage={errorMessages?.division}
              onChange={handleInputChange}
              value={subdivisionData?.division}>
              {props.divisionData?.length > 0 &&
                props.divisionData.map((item) => {
                  return (
                    <Option value={item?.id} key={item?.id}>
                      <div className="options-container">
                        <div className="division-details-container">
                          <p className="division-name">{item?.title}</p>
                        </div>
                      </div>
                    </Option>
                  );
                })}
            </InputGroup>
            <InputGroup
              label="Reference Name"
              name="name"
              placeholder="Enter Reference Name"
              onChange={handleInputChange}
              value={subdivisionData?.name}
            />
            <InputGroup
              label="Priority"
              type="Number"
              name="priority"
              placeholder="Enter Priority"
              onChange={handleInputChange}
              value={subdivisionData?.priority}
              errorMessage={errorMessages?.priority}
            />
            <InputGroup
              label="Description"
              name="description"
              fieldType="textArea"
              placeholder="Enter Description"
              onChange={handleInputChange}
              value={subdivisionData?.description}
            />
            <InputGroup
              label="Reference Link"
              name="link"
              placeholder="Enter Reference Link"
              onChange={handleInputChange}
              value={subdivisionData?.link}
            />
            <InputGroup
              name="cardImage"
              fieldType="file"
              label="Image"
              accept="image/*"
              fileList={subdivisionData?.cardImage}
              onChange={handleInputChange}
            />
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={'Create'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  divisionData: getAllDivisionData(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { occasionDivisionView, occasionSubdivisionCreate },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SubdivisionCreate);
