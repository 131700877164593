import { useState, useRef } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { DEV_MODE } from '../constants/development';

export const useAgora = ({ role = 'audience', container }) => {
  const [tracks, setTracks] = useState({
    localAudioTrack: null,
    localVideoTrack: null
  });

  const [streamState, setStreamState] = useState(0);
  // 0 if host hasn't started streaming, 1 for ongoing stream, 2 if host left stream

  const client = useRef(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const setAgoraClient = async ({ appId, channel, token, uid }) => {
    try {
      setLoading(true);
      setError(null);
      if (!DEV_MODE) {
        AgoraRTC.setLogLevel(3);
      }
      // for safari 12.1 or earlier codec h264 is recommended otherwise vp8 is recommended
      client.current = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });

      const Client = client.current;

      await Client.setClientRole(role);

      await Client.join(appId, channel, token, uid);

      if (role === 'host') {
        const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        // Create a video track from the video captured by a camera.
        const localVideoTrack = await AgoraRTC.createCameraVideoTrack({
          encoderConfig: '720p_1',
          optimizationMode: 'detail'
        });

        setTracks({
          localAudioTrack,
          localVideoTrack
        });
      }
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const publishStream = async () => {
    try {
      setError(null);
      if (client.current) {
        client.current.publish([
          tracks.localAudioTrack,
          tracks.localVideoTrack
        ]);
      }
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  const endStream = async () => {
    try {
      if (tracks.localAudioTrack && tracks.localVideoTrack) {
        await tracks.localAudioTrack.close();
        await tracks.localVideoTrack.close();
        setTracks({
          localAudioTrack: null,
          localVideoTrack: null
        });
        // Leave the channel.
        stopVideo();
      }
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  const stopVideo = async () => {
    try {
      setError(null);
      if (client.current) {
        client.current.unpublish();
      }
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  const playLiveVideo = async () => {
    try {
      // user not published yet
      client.current.on('user-published', async (user, mediaType) => {
        // Subscribe to a remote user.

        await client.current.subscribe(user, mediaType);

        // If the subscribed track is video.
        if (mediaType === 'video') {
          // Get `RemoteVideoTrack` in the `user` object.
          const remoteVideoTrack = user.videoTrack;

          // Play the remote video track.
          // Pass the DIV container and the SDK dynamically creates a player in the container for playing the remote video track.

          if (container.current) {
            remoteVideoTrack.play(container.current);
            setStreamState(1);
          }

          // Or just pass the ID of the DIV container.
          // remoteVideoTrack.play(playerContainer.id);
        }

        // If the subscribed track is audio.
        if (mediaType === 'audio') {
          // Get `RemoteAudioTrack` in the `user` object.
          const remoteAudioTrack = user.audioTrack;
          // Play the audio track. No need to pass any DOM element.
          remoteAudioTrack.play();
        }
      });
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  const hasHostLeft = async () => {
    try {
      client.current.on('user-unpublished', async (user, reason) => {
        // called when user unpublishes audio and video track
        setStreamState(2);
      });
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  const getStats = () => {
    try {
      if (client.current) {
        return client.current.getRTCStats();
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (role === 'host') {
    return {
      publishStream,
      endStream,
      setAgoraClient,
      localVideoTrack: tracks.localVideoTrack,
      localAudioTrack: tracks.localAudioTrack,
      error,
      loading,
      getStats
    };
  }

  return {
    playLiveVideo,
    stopVideo,
    setAgoraClient,
    error,
    loading,
    getStats,
    streamState,
    hasHostLeft
  };
};
