import { createSelector } from 'reselect';
import {
  currentOccasionId,
  currentFixedGroupPostSettingId
} from './dynamicData';
import { fixedGroupPostSettingEntities, groupEntities } from './entity';
import { fixedGroupPostSettingIds } from './static';

export const currentOccasionFixedGroupPostSettingIds = createSelector(
  currentOccasionId,
  fixedGroupPostSettingIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getAllOccasionFixedGroupPostSettings = createSelector(
  fixedGroupPostSettingEntities,
  currentOccasionFixedGroupPostSettingIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
export const occasionFixedGroupPostSettingWithGroupData = createSelector(
  getAllOccasionFixedGroupPostSettings,
  groupEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      postedByGroup: { ...entities[item?.postedByGroup?.id] },
      viewedByGroup: { ...entities[item?.viewedByGroup?.id] }
    }))
);

export const currentFixedGroupPostSettingData = createSelector(
  currentFixedGroupPostSettingId,
  fixedGroupPostSettingEntities,
  (id, entity) => id && entity && entity[id]
);
