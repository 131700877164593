import { put, call, takeLatest, takeEvery, all } from 'redux-saga/effects';
import {
  GET_GALLERY_IMAGES,
  CREATE_MEDIA,
  GET_GALLERY_VIDEOS,
  GET_GALLERY_INFO,
  DELETE_MEDIA
} from '../constants/actionTypes';
import { isValidResult } from '../helpers/responseHelper';
import {
  getGalleryImages,
  getGalleryVideos,
  addMediaToGalleries,
  getGalleriesInfo,
  deleteMediaApi
} from '../api/gallery';
import {
  getGalleryImagesSuccess,
  getGalleryImagesFailed,
  createMediaFailed,
  updateImageList,
  getGalleryVideosSuccess,
  getGalleryVideosFailed,
  createImagesSuccess,
  createVideosSuccess,
  getGalleryInfoSuccess,
  getGalleryInfoFailed,
  deleteMediaSuccess,
  deleteMediaFailed
} from '../actions/gallery';
import progressSaga from './progressSaga';
import { requestAutoHideAlert } from '../actions/alert';
import { getDataFromFile, getUserToken } from '../helpers/authHelper';
import { entityDeleteRequestSuccess } from '../actions/entity';

export function* getGalleryInfoSaga(action) {
  try {
    const { occasionId } = action.payload;
    const result = yield call(getGalleriesInfo, occasionId, getUserToken());

    if (isValidResult(result)) {
      yield put(
        getGalleryInfoSuccess(occasionId, result.data, result.included)
      );
    } else {
      throw result;
    }
  } catch (error) {
    yield put(getGalleryInfoFailed(error));
  }
}

export function* getGalleryImagesSaga(action) {
  try {
    const { occasionId, uuid, mType, page } = action.payload;
    const result = yield call(
      getGalleryImages,
      occasionId,
      uuid,
      mType,
      page,
      getUserToken()
    );

    if (action && typeof action.callback === 'function') {
      action.callback();
    }

    if (isValidResult(result)) {
      yield put(
        getGalleryImagesSuccess(
          occasionId,
          result.data,
          result.included,
          result.links,
          page
        )
      );
    } else if (result && result.meta) {
      yield put(getGalleryImagesFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(getGalleryImagesFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* getGalleryVideosSaga(action) {
  try {
    const { occasionId, uuid, mType, page } = action.payload;
    const result = yield call(
      getGalleryVideos,
      occasionId,
      uuid,
      mType,
      page,
      getUserToken()
    );

    if (action && typeof action.callback === 'function') {
      action.callback();
    }

    if (isValidResult(result)) {
      yield put(
        getGalleryVideosSuccess(
          occasionId,
          result.data,
          result.included,
          result.links,
          page
        )
      );
    } else if (result && result.meta) {
      yield put(getGalleryVideosFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(getGalleryVideosFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* addMediaToGallerySaga(action) {
  try {
    const { occasionId, formData, updateProgress, mediaType } = action.payload;
    yield all(
      formData.map((el) =>
        put(
          updateImageList(
            occasionId,
            { ...getDataFromFile(el.get('media[file]')), completed: 'STARTED' },
            0
          )
        )
      )
    );
    for (const key in formData) {
      if (Object.hasOwnProperty.call(formData, key)) {
        const element = formData[key];
        try {
          const result = yield call(
            addMediaToGalleries,
            occasionId,
            element,
            updateProgress,
            getUserToken()
          );

          if (isValidResult(result)) {
            if (mediaType === 'images') {
              yield put(
                createImagesSuccess(occasionId, result.data, result.included)
              );
            } else {
              yield put(
                createVideosSuccess(occasionId, result.data, result.included)
              );
            }
            yield put(
              updateImageList(
                occasionId,
                {
                  ...getDataFromFile(element.get('media[file]')),
                  completed: 'FINISHED'
                },
                1
              )
            );
          } else {
            throw result;
          }
        } catch (error) {
          yield put(createMediaFailed(error));
          yield put(
            updateImageList(
              action.payload.occasionId,
              {
                ...getDataFromFile(element.get('media[file]')),
                completed: 'ERRORED'
              },
              1
            )
          );
          if (error && error.meta) {
            yield put(requestAutoHideAlert(error.meta.message, 'Error'));
          } else {
            yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
          }
        }
      }
    }
    if (typeof action.callback === 'function') {
      action.callback();
    }
  } catch (error) {
    console.error('Error in adding videos in saga', error);
  }
}

export function* deleteGalleryMediaSaga(action) {
  try {
    const { occasionId, uuid, mediaId, mType, mediaType } = action.payload;
    const result = yield call(
      deleteMediaApi,
      occasionId,
      uuid,
      mediaId,
      mType,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(deleteMediaSuccess(occasionId, uuid, mediaId, mediaType));

      yield put(
        entityDeleteRequestSuccess({
          type: result.data.type,
          id: mediaId
        })
      );

      if (typeof action?.callback === 'function') {
        action.callback();
      }

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta?.message, 'Success', 4000));
      }
    }
  } catch (error) {
    yield put(deleteMediaFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export default function* gallerySagaActionWatcher() {
  yield takeLatest(GET_GALLERY_INFO, progressSaga, getGalleryInfoSaga);
  yield takeLatest(GET_GALLERY_IMAGES, progressSaga, getGalleryImagesSaga);
  yield takeLatest(GET_GALLERY_VIDEOS, progressSaga, getGalleryVideosSaga);
  yield takeEvery(CREATE_MEDIA, progressSaga, addMediaToGallerySaga);
  yield takeEvery(DELETE_MEDIA, progressSaga, deleteGalleryMediaSaga);
}
