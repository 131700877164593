import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import { Button } from 'antd';
import ItineraryCreate from './ItineraryCreate';
import { occasionItineraryWithUserData } from '../../../../../../selectors/itinerary';
import {
  occasionItineraryDelete,
  occasionItineraryView
} from '../../../../../../actions/itinerary';

const ItineraryView = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [itineraryId, setItineraryId] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.occasionItineraryView(
      props.occasionId,
      props.scheduleId,
      'Occasion',
      props.occasionId,
      page
    );
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleCreate = () => {
    setItineraryId('');
    setCreateOpen(true);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={handleCreate}>
          Create
        </Button>
      </div>
    )
  };

  const editData = (id) => {
    setCreateOpen(true);
    setItineraryId(id);
  };

  const deleteData = (id) => {
    props.occasionItineraryDelete(
      props.occasionId,
      props.scheduleId,
      id,
      'Occasion',
      props.occasionId
    );
  };

  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="itinerary-view-section">
          <div className="itinerary-view-container">
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              isRouted={false}
              onPageChange={onPageChange}
              onDelete={deleteData}
              isEditable={editData}
              data={props.itineraryData}
              fetchProgress={props.fetchProgress}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <ItineraryCreate
        itineraryId={itineraryId}
        occasionId={props.occasionId}
        scheduleId={props.scheduleId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  itineraryData: occasionItineraryWithUserData(store, props)
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { occasionItineraryDelete, occasionItineraryView },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(ItineraryView);
