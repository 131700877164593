import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  deleteFixedGroupPostSettingApi,
  occasionFixedGroupPostSettingCreateApi,
  occasionFixedGroupPostSettingViewApi,
  updateFixedGroupPostSettingApi
} from '../api/fixedGroupPostSetting';
import {
  DELETE_FIXED_GROUP_POST_SETTING,
  OCCASION_FIXED_GROUP_POST_SETTING_CREATE,
  OCCASION_FIXED_GROUP_POST_SETTING_VIEW,
  UPDATE_FIXED_GROUP_POST_SETTING
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  deleteFixedGroupPostSettingFailed,
  deleteFixedGroupPostSettingSuccess,
  occasionFixedGroupPostSettingCreateFailed,
  occasionFixedGroupPostSettingCreateSuccess,
  occasionFixedGroupPostSettingViewFailed,
  occasionFixedGroupPostSettingViewSuccess,
  updateFixedGroupPostSettingFailed,
  updateFixedGroupPostSettingSuccess
} from '../actions/fixedGroupPostSetting';

export function* occasionFixedGroupPostSettingCreateSaga(action) {
  try {
    const result = yield call(
      occasionFixedGroupPostSettingCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionFixedGroupPostSettingCreateSuccess(
          result.data,
          result.included,
          action.payload.ocId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionFixedGroupPostSettingCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionFixedGroupPostSettingCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionFixedGroupPostSettingViewSaga(action) {
  try {
    const result = yield call(
      occasionFixedGroupPostSettingViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionFixedGroupPostSettingViewSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionFixedGroupPostSettingViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionFixedGroupPostSettingUpdateSaga(action) {
  try {
    const result = yield call(
      updateFixedGroupPostSettingApi,
      action.payload.ocId,
      action.payload.gpsId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateFixedGroupPostSettingSuccess(
          action.payload.ocId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateFixedGroupPostSettingFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateFixedGroupPostSettingFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionFixedGroupPostSettingDeleteSaga(action) {
  try {
    const result = yield call(
      deleteFixedGroupPostSettingApi,
      action.payload.ocId,
      action.payload.gpsId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        deleteFixedGroupPostSettingSuccess(action.payload.ocId, result.data)
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteFixedGroupPostSettingFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* fixedGroupPostSettingActionWatcher() {
  yield takeLatest(
    OCCASION_FIXED_GROUP_POST_SETTING_CREATE,
    progressSaga,
    occasionFixedGroupPostSettingCreateSaga
  );
  yield takeLatest(
    OCCASION_FIXED_GROUP_POST_SETTING_VIEW,
    progressSaga,
    occasionFixedGroupPostSettingViewSaga
  );
  yield takeLatest(
    UPDATE_FIXED_GROUP_POST_SETTING,
    progressSaga,
    occasionFixedGroupPostSettingUpdateSaga
  );
  yield takeLatest(
    DELETE_FIXED_GROUP_POST_SETTING,
    progressSaga,
    occasionFixedGroupPostSettingDeleteSaga
  );
}
