import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Input, Form, Button, Checkbox, Image } from 'antd';
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import ErrorBoundary from '../../components/ErrorBoundary';
import { startLogin } from '../../actions/auth';
import { Section } from '../../components/Section';
import Container from '../../components/Container';
import SOCIANA_LOGO from '../../assets/sociana-logo.png';
import LOGIN_PAGE from '../../assets/login-page.png';
import './styles.scss';
import { validateLogin } from './validate';

function Login(props) {
  const [loginFormState, setLoginFormState] = React.useState({});
  const [isPasswordVisible, setPasswordVisibility] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState({});

  const moveToDashBoard = () => {
    props.history.push('/dashboard');
  };

  const handleSignIn = () => {
    const validateObj = validateLogin(loginFormState);
    if (validateObj.email || validateObj.password) {
      setErrorMessages(validateObj);
    } else {
      setErrorMessages({});
      props.startLogin(loginFormState, moveToDashBoard);
    }
  };

  /**
   * Handles Email/Mobile and Password Changes
   */
  const handleInputChange = (e) => {
    let value;
    if (e.target.name === 'remember') value = e.target.checked;
    else value = e.target.value;
    const inputName = e.target.name;
    if (errorMessages.email || errorMessages.password) {
      setErrorMessages({});
    }
    setLoginFormState({
      ...loginFormState,
      [inputName]: value
    });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!isPasswordVisible);
  };

  return (
    <ErrorBoundary>
      <Section id="login">
        <Container>
          <div className="login-container">
            <div className="left-container">
              <div className="sociana-logo">
                <Image
                  className="image-style"
                  preview={false}
                  src={SOCIANA_LOGO}
                />
              </div>
              <h1 className="left-container-header">
                India’s first End to End Event Experience App
              </h1>
              <div className="login-page-image">
                <Image
                  className="image-style"
                  preview={false}
                  src={LOGIN_PAGE}
                />
              </div>
            </div>
            <div className="right-container">
              <h1 className="right-container-header">Get Started</h1>
              <p className="right-container-header-subtext">
                Customisable spaces for every Occasions
              </p>
              <Form className="login-form" onFinish={handleSignIn}>
                <Form.Item className="form-item" label="Email Address">
                  <Input
                    name="email"
                    className="email-input-box"
                    placeholder="Enter email or mobile"
                    onChange={handleInputChange}
                  />
                  {errorMessages.email && (
                    <p className="error-message-style">{errorMessages.email}</p>
                  )}
                </Form.Item>
                <Form.Item
                  className="form-item position-relative"
                  label="Password">
                  <Input
                    className="password-input-box"
                    name="password"
                    type={isPasswordVisible ? 'text' : 'password'}
                    placeholder="Enter password"
                    onChange={handleInputChange}
                  />
                  <span className="password-eye-container">
                    {isPasswordVisible ? (
                      <EyeInvisibleOutlined
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <EyeOutlined onClick={togglePasswordVisibility} />
                    )}
                  </span>
                </Form.Item>
                {errorMessages.password && (
                  <p className="error-message-style">
                    {errorMessages.password}
                  </p>
                )}
                <div className="checkbox-and-fgtpassword-container">
                  <Form.Item className="form-item">
                    <Checkbox
                      name="remember"
                      onChange={handleInputChange}
                      className="checkbox-input">
                      Remember me
                    </Checkbox>
                  </Form.Item>
                  <NavLink to="/forgotPassword" className="fgt-password">
                    Forgot Password
                  </NavLink>
                </div>

                <Form.Item className="form-item">
                  <Button
                    disabled={props.fetchProgress}
                    className="submit-button"
                    type="primary"
                    htmlType="submit">
                    {!props.fetchProgress ? 'Login' : <LoadingOutlined spin />}
                  </Button>
                </Form.Item>
              </Form>
              <div className="right-privacy-container">
                <p className="privacy-text">
                  Sociana admin panel powered by Alien Brains Private Limited
                </p>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startLogin
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
