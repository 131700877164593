import request from './request';

export function createTicketPurchaseApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/ticket/pending_registration_ticket_purchases`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function createTicketPurchaseApiCSV(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/ticket/create_by_csv`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function getRegisteredTicketPurchasesApi(ocId, ticketId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/ticket/fetch_ticket_purchases_by_ticket/${ticketId}?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function getUnregisteredTicketPurchasesApi(ocId, ticketId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/ticket/fetch_pending_ticket_purchases_by_ticket/${ticketId}?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function registeredTicketPurchasesSearchApi(
  ocId,
  ticketvariantId,
  searchData,
  referenceId,
  page,
  token
) {
  let query = `page=${page}`;
  if (!!ticketvariantId) {
    query += `&ticket_variant_id=${ticketvariantId}`;
  }
  if (!!searchData) {
    query += `&email_or_phone=${searchData}`;
  }
  if (!!referenceId) {
    query += `&reference_id=${referenceId}`;
  }
  return request(
    `api/v2/sociana_admin/oc/${ocId}/ticket/registered_purchases/filter?${query}`,
    {
      method: 'GET',
      token
    }
  );
}

export function unregisteredTicketPurchasesSearchApi(
  ocId,
  ticketvariantId,
  searchData,
  page,
  token
) {
  let query = `page=${page}`;
  if (!!ticketvariantId) {
    query += `&ticket_variant_id=${ticketvariantId}`;
  }
  if (!!searchData) {
    query += `&email_or_phone=${searchData}`;
  }
  return request(
    `api/v2/sociana_admin/oc/${ocId}/ticket/unregistered_purchases/filter?${query}`,
    {
      method: 'GET',
      token
    }
  );
}
