import {
  COMPETITION_CREATE,
  COMPETITION_CREATE_FAILED,
  COMPETITION_CREATE_SUCCESS,
  COMPETITION_VIEW,
  COMPETITION_VIEW_FAILED,
  COMPETITION_VIEW_SUCCESS,
  DELETE_COMPETITION,
  DELETE_COMPETITION_FAILED,
  DELETE_COMPETITION_SUCCESS,
  UPDATE_COMPETITION,
  UPDATE_COMPETITION_FAILED,
  UPDATE_COMPETITION_SUCCESS
} from '../constants/actionTypes';
export const competitionCreate = (ocId, formData, callback) => ({
  type: COMPETITION_CREATE,
  payload: { ocId, formData },
  callback
});

export const competitionCreateSuccess = (data, included, ocId) => ({
  type: COMPETITION_CREATE_SUCCESS,
  payload: { data, included, ocId }
});

export const competitionCreateFailed = (error) => ({
  type: COMPETITION_CREATE_FAILED,
  error
});

export const competitionView = (ocId, page) => ({
  type: COMPETITION_VIEW,
  payload: { ocId, page }
});

export const competitionViewSuccess = (data, included, ocId, links) => ({
  type: COMPETITION_VIEW_SUCCESS,
  payload: { data, included, ocId, links }
});

export const competitionViewFailed = (error) => ({
  type: COMPETITION_VIEW_FAILED,
  error
});

export const updateCompetition = (ocId, competitionId, formData, callback) => ({
  type: UPDATE_COMPETITION,
  payload: { ocId, competitionId, formData },
  callback
});

export const updateCompetitionSuccess = (ocId, data, included) => ({
  type: UPDATE_COMPETITION_SUCCESS,
  payload: { ocId, data, included }
});

export const updateCompetitionFailed = (error) => ({
  type: UPDATE_COMPETITION_FAILED,
  error
});

export const deleteCompetition = (ocId, competitionId, callback) => ({
  type: DELETE_COMPETITION,
  payload: { ocId, competitionId },
  callback
});

export const deleteCompetitionSuccess = (ocId, data) => ({
  type: DELETE_COMPETITION_SUCCESS,
  payload: { ocId, data }
});

export const deleteCompetitionFailed = (error) => ({
  type: DELETE_COMPETITION_FAILED,
  error
});
