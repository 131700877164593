import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import { SectionContent } from '../../../components/Section';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { adminData } from '../../../selectors/auth';
import { getDate, getMonth } from '../../../helpers/dateTimeHelper';

function UpdatesContainer(props) {
  return (
    <div className="home-updates-container">
      <div className="home-updates">
        <p className="home-updates-header">Updates</p>
        {props.updates?.length > 0 ? (
          props.updates?.map((data) => <p className="home-update">{data}</p>)
        ) : (
          <p className="home-update">No updates</p>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  adminData: adminData(store, props)
});

export default connect(mapStateToProps, null)(UpdatesContainer);
