import {
  MULTIPLE_INVITATIONS,
  MULTIPLE_INVITATIONS_SUCCESS,
  MULTIPLE_INVITATIONS_FAILED,
  PARSE_CSV,
  PARSE_CSV_SUCCESS,
  PARSE_CSV_FAILED,
  GET_OCCASION_PENDING_INVITATION,
  GET_OCCASION_PENDING_INVITATION_SUCCESS,
  GET_OCCASION_PENDING_INVITATION_FAILED,
  GET_OCCASION_PENDING_REGISTRATION,
  GET_OCCASION_PENDING_REGISTRATION_SUCCESS,
  GET_OCCASION_PENDING_REGISTRATION_FAILED,
  PENDING_INVITATION_SEARCH,
  PENDING_INVITATION_SEARCH_SUCCESS,
  PENDING_INVITATION_SEARCH_FAILED,
  PENDING_REGISTRATION_SEARCH,
  PENDING_REGISTRATION_SEARCH_SUCCESS,
  PENDING_REGISTRATION_SEARCH_FAILED,
  SEARCH_PENDING_INVITATIONS,
  SEARCH_PENDING_INVITATIONS_SUCCESS,
  SEARCH_PENDING_INVITATIONS_FAILED,
  SEARCH_PENDING_REGISTRATIONS,
  SEARCH_PENDING_REGISTRATIONS_FAILED,
  SEARCH_PENDING_REGISTRATIONS_SUCCESS,
  RESEND_PENDING_REGISTRATION,
  RESEND_PENDING_REGISTRATION_FAILED,
  RESEND_PENDING_REGISTRATION_SUCCESS,
  RESEND_PENDING_INVITATION,
  RESEND_PENDING_INVITATION_FAILED,
  RESEND_PENDING_INVITATION_SUCCESS,
  DELETE_OCCASION_PENDING_INVITATION,
  DELETE_OCCASION_PENDING_INVITATION_FAILED,
  DELETE_OCCASION_PENDING_INVITATION_SUCCESS,
  UPDATE_PENDING_INVITATION,
  UPDATE_PENDING_INVITATION_FAILED,
  UPDATE_PENDING_INVITATION_SUCCESS,
  DELETE_OCCASION_PENDING_REGISTRATION,
  DELETE_OCCASION_PENDING_REGISTRATION_FAILED,
  DELETE_OCCASION_PENDING_REGISTRATION_SUCCESS,
  UPDATE_PENDING_REGISTRATION,
  UPDATE_PENDING_REGISTRATION_FAILED,
  UPDATE_PENDING_REGISTRATION_SUCCESS
} from '../constants/actionTypes';

export const multipleInvitations = (ocId, formData, callback) => ({
  type: MULTIPLE_INVITATIONS,
  payload: { ocId, formData },
  callback
});

export const multipleInvitationsSuccess = (data) => ({
  type: MULTIPLE_INVITATIONS_SUCCESS,
  payload: { data }
});

export const multipleInvitationsFailed = (error) => ({
  type: MULTIPLE_INVITATIONS_FAILED,
  error
});

export const parse_csv = (ocId, formData, callback) => ({
  type: PARSE_CSV,
  payload: { ocId, formData },
  callback
});

export const parse_csvSuccess = (data) => ({
  type: PARSE_CSV_SUCCESS,
  payload: { data }
});

export const parse_csvFailed = (error) => ({
  type: PARSE_CSV_FAILED,
  error
});

export const getOccasionPendingInvitation = (ocId, page) => ({
  type: GET_OCCASION_PENDING_INVITATION,
  payload: { ocId, page }
});

export const getOccasionPendingInvitationSuccess = (
  data,
  links,
  included,
  page,
  meta
) => ({
  type: GET_OCCASION_PENDING_INVITATION_SUCCESS,
  payload: { data, links, included, page, meta }
});

export const getOccasionPendingInvitationFailed = (error) => ({
  type: GET_OCCASION_PENDING_INVITATION_FAILED,
  error
});

export const getOccasionPendingRegistration = (ocId, page) => ({
  type: GET_OCCASION_PENDING_REGISTRATION,
  payload: { ocId, page }
});

export const getOccasionPendingRegistrationSuccess = (
  data,
  links,
  included,
  page,
  meta
) => ({
  type: GET_OCCASION_PENDING_REGISTRATION_SUCCESS,
  payload: { data, links, included, page, meta }
});

export const getOccasionPendingRegistrationFailed = (error) => ({
  type: GET_OCCASION_PENDING_REGISTRATION_FAILED,
  error
});

export const pendingInvitationSearch = (
  ocId,
  joinas,
  status,
  invitedby,
  user,
  page
) => ({
  type: PENDING_INVITATION_SEARCH,
  payload: { ocId, joinas, status, invitedby, user, page }
});

export const pendingInvitationSearchSuccess = (
  data,
  included,
  links,
  meta
) => ({
  type: PENDING_INVITATION_SEARCH_SUCCESS,
  payload: { data, included, links, meta }
});

export const pendingInvitationSearchFailed = (error) => ({
  type: PENDING_INVITATION_SEARCH_FAILED,
  error
});

export const pendingRegistrationSearch = (
  ocId,
  text,
  joinas,
  invitedby,
  page
) => ({
  type: PENDING_REGISTRATION_SEARCH,
  payload: { ocId, text, joinas, invitedby, page }
});

export const pendingRegistrationSearchSuccess = (
  data,
  included,
  links,
  meta
) => ({
  type: PENDING_REGISTRATION_SEARCH_SUCCESS,
  payload: { data, included, links, meta }
});

export const pendingRegistrationSearchFailed = (error) => ({
  type: PENDING_REGISTRATION_SEARCH_FAILED,
  error
});

export const searchPendingInvitation = (ocId, page, query) => ({
  type: SEARCH_PENDING_INVITATIONS,
  payload: { ocId, page, query }
});

export const searchPendingInvitationSuccess = (data, included, links) => ({
  type: SEARCH_PENDING_INVITATIONS_SUCCESS,
  payload: { data, included, links }
});

export const searchPendingInvitationFailed = (error) => ({
  type: SEARCH_PENDING_INVITATIONS_FAILED,
  error
});

export const searchPendingRegistrations = (ocId, page, query) => ({
  type: SEARCH_PENDING_REGISTRATIONS,
  payload: { ocId, query, page }
});

export const searchPendingRegistrationsSuccess = (data, included, links) => ({
  type: SEARCH_PENDING_REGISTRATIONS_SUCCESS,
  payload: { data, included, links }
});

export const searchPendingRegistrationsFailed = (error) => ({
  type: SEARCH_PENDING_REGISTRATIONS_FAILED,
  error
});

export const resendPendingRegistrations = (ocId, allOrId) => ({
  type: RESEND_PENDING_REGISTRATION,
  payload: { ocId, allOrId }
});

export const resendPendingRegistrationsSuccess = (data, included, links) => ({
  type: RESEND_PENDING_REGISTRATION_SUCCESS,
  payload: { data, included, links }
});

export const resendPendingRegistrationsFailed = (error) => ({
  type: RESEND_PENDING_REGISTRATION_FAILED,
  error
});

export const resendPendingInvitation = (ocId, allOrId) => ({
  type: RESEND_PENDING_INVITATION,
  payload: { ocId, allOrId }
});

export const resendPendingInvitationSuccess = (data, included, links) => ({
  type: RESEND_PENDING_INVITATION_SUCCESS,
  payload: { data, included, links }
});

export const resendPendingInvitationFailed = (error) => ({
  type: RESEND_PENDING_INVITATION_FAILED,
  error
});

export const deleteOccasionPendingInvitation = (ocId, pendingInvitationId) => ({
  type: DELETE_OCCASION_PENDING_INVITATION,
  payload: { ocId, pendingInvitationId }
});

export const deleteOccasionPendingInvitationSuccess = (
  data,
  links,
  included,
  page
) => ({
  type: DELETE_OCCASION_PENDING_INVITATION_SUCCESS,
  payload: { data, links, included, page }
});

export const deleteOccasionPendingInvitationFailed = (error) => ({
  type: DELETE_OCCASION_PENDING_INVITATION_FAILED,
  error
});

export const updatePendingInvitation = (
  ocId,
  pendingInvitationId,
  formData,
  callback
) => ({
  type: UPDATE_PENDING_INVITATION,
  payload: { ocId, pendingInvitationId, formData },
  callback
});

export const updatePendingInvitationSuccess = (data, links, included) => ({
  type: UPDATE_PENDING_INVITATION_SUCCESS,
  payload: { data, links, included }
});

export const updatePendingInvitationFailed = (error) => ({
  type: UPDATE_PENDING_INVITATION_FAILED,
  error
});

export const deleteOccasionPendingRegistration = (
  ocId,
  pendingRegistrationId
) => ({
  type: DELETE_OCCASION_PENDING_REGISTRATION,
  payload: { ocId, pendingRegistrationId }
});

export const deleteOccasionPendingRegistrationSuccess = (
  data,
  links,
  included,
  page
) => ({
  type: DELETE_OCCASION_PENDING_REGISTRATION_SUCCESS,
  payload: { data, links, included, page }
});

export const deleteOccasionPendingRegistrationFailed = (error) => ({
  type: DELETE_OCCASION_PENDING_REGISTRATION_FAILED,
  error
});

export const updatePendingRegistration = (
  ocId,
  pendingRegistrationId,
  formData,
  callback
) => ({
  type: UPDATE_PENDING_REGISTRATION,
  payload: { ocId, pendingRegistrationId, formData },
  callback
});

export const updatePendingRegistrationSuccess = (data, links, included) => ({
  type: UPDATE_PENDING_REGISTRATION_SUCCESS,
  payload: { data, links, included }
});

export const updatePendingRegistrationFailed = (error) => ({
  type: UPDATE_PENDING_REGISTRATION_FAILED,
  error
});
