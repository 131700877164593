import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import TableView from '../../../../../components/Table';
import WhatsappTemplatesModelableCreate from './create';
import {
  getAllWhatsappTemplatesModelable,
  getWhatsappTemplatesModelableWithAllData
} from '../../../../../selectors/whatsappTemplates';
import {
  getWhatsAppTemplateModelableWithPagination,
  deleteWhatsAppTemplateModelable
} from '../../../../../actions/whatsAppTemplates';

const ViewWhatsappTemplatesModelable = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [
    whatsappTemplatesModelableId,
    setWhatsappTemplatesModelableId
  ] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.getWhatsAppTemplateModelableWithPagination(
      props.whatsappTemplatesId,
      page
    );
  };
  const location = useLocation();

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleCreate = () => {
    setWhatsappTemplatesModelableId('');
    setCreateOpen(true);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="wt-modelable-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={handleCreate}>
          Create
        </Button>
      </div>
    )
  };

  const editData = (id) => {
    setCreateOpen(true);
    setWhatsappTemplatesModelableId(id);
  };

  const deleteData = (id) => {
    props.deleteWhatsAppTemplateModelable(props.whatsappTemplatesId, id);
  };

  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="wt-modelable-view-section">
          <div className="wt-modelable-view-container">
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              pathname={`${location.pathname}/whatsappTemplatesModelable/view`}
              isRouted={true}
              onPageChange={onPageChange}
              onDelete={deleteData}
              isEditable={editData}
              data={props.viewData}
              fetchProgress={props.fetchProgress}
              paginationLinks={props.Link}
              blacklistKeys={['whatsappTemplateMessage']}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <WhatsappTemplatesModelableCreate
        occasion={props.templateDetails.occasion}
        whatsappTemplatesId={props.whatsappTemplatesId}
        whatsappTemplatesModelableId={whatsappTemplatesModelableId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  viewData: getWhatsappTemplatesModelableWithAllData(store, props),
  Link: store.whatsappTemplates.whatsappTemplateModelableLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    {
      getWhatsAppTemplateModelableWithPagination,
      deleteWhatsAppTemplateModelable
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(ViewWhatsappTemplatesModelable);
