import { Form } from 'antd';
import React, { forwardRef, useMemo } from 'react';
import {
  FileUpload,
  RadioGroup,
  DatePicker,
  Input,
  TimePicker,
  Switch,
  RangePicker,
  DropDownList,
  Search,
  Slider,
  TextArea,
  ColorPicker
} from './FormComponents';
import './styles.scss';

const InputGroup = (
  {
    label,
    name,
    fieldType,
    onChange,
    value,
    type,
    className,
    errorMessage,
    ...otherProps
  },
  ref
) => {
  const Field = useMemo(() => {
    switch (fieldType) {
      case 'datePicker':
        return DatePicker;
      case 'textArea':
        return TextArea;
      case 'rangePicker':
        return RangePicker;
      case 'timePicker':
        return TimePicker;
      case 'radioGroup':
        return RadioGroup;
      case 'file':
        return FileUpload;
      case 'switch':
        return Switch;
      case 'dropdown':
        return DropDownList;
      case 'searchbox':
        return Search;
      case 'slider':
        return Slider;
      case 'colorPicker':
        return ColorPicker;
      default:
        return Input;
    }
  }, [fieldType]);
  return (
    <Form.Item
      className={`form-item ${type === 'password' ? 'position-relative' : ''}`}
      label={label}>
      <Field
        name={name}
        className={className}
        ref={ref}
        onChange={onChange}
        value={value}
        type={type}
        {...otherProps}
      />
      {!!errorMessage && <p className="error-message-style">{errorMessage}</p>}
    </Form.Item>
  );
};

export default forwardRef(InputGroup);
