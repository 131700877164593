import {
  CODE_CREATE,
  CODE_CREATE_FAILED,
  CODE_CREATE_SUCCESS,
  CODE_VIEW,
  CODE_VIEW_FAILED,
  CODE_VIEW_SUCCESS,
  UPDATE_CODE,
  UPDATE_CODE_FAILED,
  UPDATE_CODE_SUCCESS,
  DELETE_CODE,
  DELETE_CODE_FAILED,
  DELETE_CODE_SUCCESS
} from '../constants/actionTypes';

export const codeCreate = (ocId, formData, callback) => ({
  type: CODE_CREATE,
  payload: { ocId, formData },
  callback
});

export const codeCreateSuccess = (data, ocId, included, links) => ({
  type: CODE_CREATE_SUCCESS,
  payload: { data, ocId, included, links }
});

export const codeCreateFailed = (error) => ({
  type: CODE_CREATE_FAILED,
  error
});

export const codeView = (ocId, page) => ({
  type: CODE_VIEW,
  payload: { ocId, page }
});

export const codeViewSuccess = (data, ocId, included, links) => ({
  type: CODE_VIEW_SUCCESS,
  payload: { data, ocId, included, links }
});

export const codeViewFailed = (error) => ({
  type: CODE_VIEW_FAILED,
  error
});

export const updateCode = (ocId, codeId, formData, callback) => ({
  type: UPDATE_CODE,
  payload: { ocId, codeId, formData },
  callback
});

export const updateCodeSuccess = (ocId, data, included) => ({
  type: UPDATE_CODE_SUCCESS,
  payload: { ocId, data, included }
});

export const updateCodeFailed = (error) => ({
  type: UPDATE_CODE_FAILED,
  error
});

export const deleteCode = (ocId, codeId, callback) => ({
  type: DELETE_CODE,
  payload: { ocId, codeId },
  callback
});

export const deleteCodeSuccess = (ocId, data) => ({
  type: DELETE_CODE_SUCCESS,
  payload: { ocId, data }
});

export const deleteCodeFailed = (error) => ({
  type: DELETE_CODE_FAILED,
  error
});
