import {
  OCCASION_DISCUSSION_DELETE_FAILED,
  OCCASION_DISCUSSION_DELETE_SUCCESS,
  OCCASION_DISCUSSION_VIEW_FAILED,
  OCCASION_DISCUSSION_VIEW_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  occasionDiscussionData: null,
  error: null,
  discussionLinks: null,
  count: 0
};

const discussion = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_DISCUSSION_VIEW_SUCCESS: {
      const { ocId, data, links, meta } = action.payload;
      const occasionGroupedData = state.occasionDiscussionData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionDiscussionData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        discussionLinks: links,
        count: meta?.totalCount || 0
      };
    }

    case OCCASION_DISCUSSION_DELETE_SUCCESS: {
      const { ocId, data } = action.payload;
      const occasionGroupedData = state.occasionDiscussionData || {};
      const currentOccasionDiscussionIds = occasionGroupedData[ocId] || [];
      const filteredIds = currentOccasionDiscussionIds.filter(
        (id) => id !== data.id
      );

      return {
        ...state,
        occasionDiscussionData: {
          ...occasionGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case OCCASION_DISCUSSION_VIEW_FAILED:
    case OCCASION_DISCUSSION_DELETE_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default discussion;
