import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  occasionTicketCreateApi,
  occasionTicketViewApi,
  updateOccasionTicketApi
} from '../api/ticket';
import {
  OCCASION_TICKET_CREATE,
  OCCASION_TICKET_UPDATE,
  OCCASION_TICKET_VIEW
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  occasionTicketCreateFailed,
  occasionTicketCreateSuccess,
  occasionTicketUpdateFailed,
  occasionTicketUpdateSuccess,
  occasionTicketViewFailed,
  occasionTicketViewSuccess
} from '../actions/ticket';

export function* occasionTicketCreateSaga(action) {
  try {
    const result = yield call(
      occasionTicketCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionTicketCreateSuccess(
          result.data,
          result.included,
          action.payload.ocId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionTicketCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionTicketCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionTicketViewSaga(action) {
  try {
    const result = yield call(
      occasionTicketViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionTicketViewSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionTicketViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionTicketUpdateSaga(action) {
  try {
    const result = yield call(
      updateOccasionTicketApi,
      action.payload.ocId,
      action.payload.ticketId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(occasionTicketUpdateSuccess(result.data, result.included));
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionTicketUpdateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Some error occured', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionTicketUpdateFailed(error));
    yield put(requestAutoHideAlert(error, 'Error', 4000));
  }
}

export default function* ticketActionWatcher() {
  yield takeLatest(
    OCCASION_TICKET_CREATE,
    progressSaga,
    occasionTicketCreateSaga
  );
  yield takeLatest(OCCASION_TICKET_VIEW, progressSaga, occasionTicketViewSaga);
  yield takeLatest(
    OCCASION_TICKET_UPDATE,
    progressSaga,
    occasionTicketUpdateSaga
  );
}
