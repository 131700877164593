import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ErrorBoundary from '../../../../../../../components/ErrorBoundary';
import Tabs from '../../../../../../../components/Tabs';
import RespondedView from '../Responded';
import WhatsAppView from '../providedSubTab/WhatsApp/index';

function RespondedIndex(props) {
  const [tabIndex, setTabIndex] = useState('2.1');
  const onTabChange = (index) => {
    setTabIndex(index);
  };

  return (
    <ErrorBoundary>
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        onChange={onTabChange}
        tabPanes={[
          {
            tabName: 'View',
            tabKey: '2.1',
            children: (
              <RespondedView
                occasionId={props.occasionId}
                hospitalityTaskId={props.hospitalityTaskId}
                dataInputType={props.dataInputType}
                tabIndex={tabIndex}
              />
            )
          },
          {
            tabName: 'WhatsApp Messages',
            tabKey: '2.2',
            children: (
              <WhatsAppView
                occasionId={props.occasionId}
                hospitalityTaskId={props.hospitalityTaskId}
                operationType={'AnsweredHospitalityTask'}
                userType={'answered'}
                tabIndex={tabIndex}
              />
            )
          }
        ]}
      />
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RespondedIndex);
