import { createSelector } from 'reselect';
import {
  pendingInvitationEntities,
  pendingRegistrationEntities,
  userEntities
} from './entity';
import {
  pendingInvitationIds,
  pendingRegistrationIds,
  pendingInvitationAllIds,
  pendingRegistrationAllIds,
  pendingInvitationSearchIds,
  pendingRegistrationSearchIds
} from './static';
import {
  currentPendingInvitationId,
  currentPendingRegistartionId
} from './dynamicData';

export const getAllOccasionsPendingInvitation = createSelector(
  pendingInvitationEntities,
  pendingInvitationIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const pendingInvitationsWithUserData = createSelector(
  getAllOccasionsPendingInvitation,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      invitedBy: { ...entities[item?.invitedBy?.id] },
      user: { ...entities[item?.user?.id] }
    }))
);

export const getCompleteOccasionsPendingInvitation = createSelector(
  pendingInvitationEntities,
  pendingInvitationAllIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const allPendingInvitationsWithUserData = createSelector(
  getCompleteOccasionsPendingInvitation,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      invitedBy: { ...entities[item?.invitedBy?.id] },
      ...entities[item?.user?.id],
      userId: item?.user?.id
    }))
);

export const getAllSearchedOccasionsPendingInvitation = createSelector(
  pendingInvitationEntities,
  pendingInvitationSearchIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const allSearchedPendingInvitationsWithUserData = createSelector(
  getAllSearchedOccasionsPendingInvitation,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      invitedBy: { ...entities[item?.invitedBy?.id] },
      user: { ...entities[item?.user?.id] }
    }))
);

export const getAllOccasionsPendingRegistration = createSelector(
  pendingRegistrationEntities,
  pendingRegistrationIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const pendingRegistrationWtihUserData = createSelector(
  getAllOccasionsPendingRegistration,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      invitedBy: { ...entities[item?.invitedBy?.id] }
    }))
);

export const getCompleteOccasionsPendingRegistration = createSelector(
  pendingRegistrationEntities,
  pendingRegistrationAllIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const allPendingRegistrationWithUserData = createSelector(
  getCompleteOccasionsPendingRegistration,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      invitedBy: { ...entities[item?.invitedBy?.id] }
    }))
);

export const getAllSearchedOccasionsPendingRegistration = createSelector(
  pendingRegistrationEntities,
  pendingRegistrationSearchIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const allSearchedPendingRegistrationWithUserData = createSelector(
  getAllSearchedOccasionsPendingRegistration,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      invitedBy: { ...entities[item?.invitedBy?.id] }
    }))
);

export const currentPendingInvitationData = createSelector(
  pendingInvitationEntities,
  currentPendingInvitationId,
  (entities, ids) => entities && ids && entities[ids]
);

export const currentPendingRegistartionData = createSelector(
  pendingRegistrationEntities,
  currentPendingRegistartionId,
  (entities, ids) => entities && ids && entities[ids]
);
