import {
  USER_OCCASION_VIEW,
  USER_OCCASION_VIEW_SUCCESS,
  USER_OCCASION_VIEW_FAILED
} from '../constants/actionTypes';

export const UserOccasionView = (userId, page) => ({
  type: USER_OCCASION_VIEW,
  payload: { userId, page }
});

export const UserOccasionViewSuccess = (userId, data, included, links) => ({
  type: USER_OCCASION_VIEW_SUCCESS,
  payload: { userId, data, included, links }
});

export const UserOccasionViewFailed = (error) => ({
  type: USER_OCCASION_VIEW_FAILED,
  error
});
