import { GET_REPORTED_POST_COMMENTS_WITH_PAGINATION_SUCCESS } from '../constants/actionTypes';

const initialState = {
  data: null,
  links: null
};

const comment = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTED_POST_COMMENTS_WITH_PAGINATION_SUCCESS: {
      const { data, links } = action.payload;
      return {
        ...state,
        data: data.map((el) => el.id),
        links
      };
    }

    default:
      return state;
  }
};

export default comment;
