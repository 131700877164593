import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeAuthCookie } from '../helpers/authHelper';
import TabLayout from './Drawer';
import Header from './Header/index';
import './styles.scss';
import { getOccasionSettings } from '../actions/occasion';
import { DrawerSection } from '../components/Section';
import AuthorizedRoutes from '../routes/AuthorizedRoutes';
import { USER_ACCESS_TYPES } from '../constants/DashboardConstants';
import { adminData } from '../selectors/auth';
import { startLogout } from '../actions/auth';
import ErrorBoundary from '../components/ErrorBoundary';

function Dashboard(props) {
  const [isTabExpaned, setTabExpanded] = React.useState(false);

  useEffect(() => {
    props.getOccasionSettings();
  }, []);

  const toggleTab = () => {
    setTabExpanded(!isTabExpaned);
  };

  const logOutCallback = () => {
    removeAuthCookie();
  };

  const signOut = () => {
    props.startLogout(logOutCallback);
  };

  return (
    <>
      <ErrorBoundary>
        <div className="main-container">
          <Header
            toggleTab={toggleTab}
            isTabExpaned={isTabExpaned}
            signOut={signOut}
          />
          <TabLayout
            tabsToShow={props.tabsToShow}
            toggleTab={toggleTab}
            isTabExpaned={isTabExpaned}
          />
          <DrawerSection>
            <AuthorizedRoutes />
          </DrawerSection>
        </div>
      </ErrorBoundary>
    </>
  );
}

const mapStateToProps = (store, props) => {
  const data = adminData(store, props);
  const { tabAccess } = store.auth;
  const accessType = data?.accessType
    ? USER_ACCESS_TYPES[data.accessType]
    : null;
  const tabsToShow = accessType && tabAccess ? tabAccess[accessType] : null;
  return {
    data,
    tabsToShow
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startLogout,
      getOccasionSettings
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
