import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../styles.scss';
import { Form, Radio, Tooltip, Image, AutoComplete, Button } from 'antd';
import { SectionContent } from '../../../../../../components/Section';
import { CloseOutlined } from '@ant-design/icons';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import {
  hospitalityTaskCreate,
  hospitalityTaskCreateUpdate
} from '../../../../../../actions/hospitalityTask';
import { getAllOccasionGroups } from '../../../../../../selectors/group';
import { getImageSource } from '../../../../../../helpers/imageHelper';
import {
  TASK_ICONS,
  TASK_TYPE,
  DATA_INPUT_TYPE,
  NO_OF_OPTIONS
} from '../HospitalityTask/HospitalityTaskConstants';
import { occasionGroupView } from '../../../../../../actions/group';

const { Option } = AutoComplete;

const validateHospitalityTask = (values, taskId) => {
  const errors = {};
  if (!values.taskName) {
    errors.taskName = 'Task Name is required';
  }
  if (!values.taskType) {
    errors.taskType = 'TaskType is required';
  }
  if (!values.taskAvatarName) {
    errors.taskAvatarName = 'Select one of them';
  }
  if (!values.dataInputType && !taskId) {
    errors.dataInputType = 'Data Input Type is required';
  }
  if (values.order && Number(values.order) <= 0) {
    errors.order = 'Should be positive';
  }
  if (values.order && isNaN(values.order)) {
    errors.order = 'Should be number';
  }
  return errors;
};

const TaskCreate = (props) => {
  const [hospitalityTaskDetails, setHospitalityTaskDetails] = React.useState({
    taskGroup: props.hospitalityTaskDetails
      ? props.hospitalityTaskDetails.groups.map((item) => item.id)
      : [],
    taskName: props.hospitalityTaskDetails
      ? props.hospitalityTaskDetails.name
      : '',
    alias_name: props.hospitalityTaskDetails
      ? props.hospitalityTaskDetails?.aliasName
      : '',
    description: props.hospitalityTaskDetails
      ? props.hospitalityTaskDetails.description
      : '',
    taskType: props.hospitalityTaskDetails
      ? props.hospitalityTaskDetails.taskType
      : '',
    taskAvatarName: props.hospitalityTaskDetails
      ? props.hospitalityTaskDetails.avatarName
      : '',
    dataInputType: '',
    taskOptions: '2',
    publicAnswer: props.hospitalityTaskDetails?.isPublicAnswer || false,
    order: props.hospitalityTaskDetails?.orderNumber || ''
  });
  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);
  const [errorMessages, setErrorMessages] = React.useState({});

  const onPageChange = (page) => {
    props.occasionGroupView(props.occasionId, page);
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleHospitalityTaskCreate = () => {
    try {
      const validateObj = validateHospitalityTask(
        hospitalityTaskDetails,
        props.hospitalityTaskId
      );
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        const formData = new FormData();
        formData.append(
          'hospitality_task[name]',
          hospitalityTaskDetails?.taskName
        );
        formData.append(
          'hospitality_task[alias_name]',
          hospitalityTaskDetails?.alias_name
        );
        formData.append(
          'hospitality_task[description]',
          hospitalityTaskDetails?.description || ''
        );
        formData.append(
          'hospitality_task[task_type]',
          hospitalityTaskDetails.taskType
        );
        formData.append(
          'hospitality_task[avatar_name]',
          hospitalityTaskDetails?.taskAvatarName
        );
        formData.append(
          'hospitality_task[is_public]',
          hospitalityTaskDetails?.publicAnswer
        );
        formData.append(
          'hospitality_task[order_number]',
          hospitalityTaskDetails?.order
            ? Number(hospitalityTaskDetails?.order)
            : 1
        );
        formData.append(
          'hospitality_task[hospitality_category_id]',
          props.hospitalityTaskId
            ? props.hospitalityId
            : props.hospitalityDetails?.id
        );
        if (!props.hospitalityTaskId) {
          if (
            hospitalityTaskDetails.dataInputType === 'mcq' ||
            hospitalityTaskDetails.dataInputType === 'scq'
          ) {
            formData.append(
              'hospitality_task[data_input_type]',
              hospitalityTaskDetails?.dataInputType
            );
            for (let i = 0; i < hospitalityTaskDetails.taskOptions; i++) {
              const options = `taskOptionData${i + 1}`;
              formData.append(
                'hospitality_task[task_options][]',
                hospitalityTaskDetails[options]
              );
            }
          } else {
            formData.append(
              'hospitality_task[data_input_type]',
              hospitalityTaskDetails?.dataInputType
            );
          }
        }
        if (hospitalityTaskDetails.taskGroup.length > 0) {
          hospitalityTaskDetails.taskGroup?.map((item) => {
            formData.append('hospitality_task[group_ids][]', item);
          });
        }
        props.hospitalityTaskId
          ? props.hospitalityTaskCreateUpdate(
              props.occasionId,
              props.hospitalityTaskId,
              formData,
              props.hospitalityId,
              callback
            )
          : props.hospitalityTaskCreate(
              props.occasionId,
              formData,
              props.hospitalityDetails?.id,
              callback
            );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const callback = () => {
    setHospitalityTaskDetails({
      taskGroup: [],
      taskName: '',
      description: '',
      taskType: '',
      taskAvatarName: '',
      dataInputType: '',
      taskOptions: '2',
      order: '',
      publicAnswer: false
    });
    props.handleCloseClick && props.handleCloseClick();
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (isInitiallyDisabled) {
          setInitiallyDisabled(false);
        }
        if (type === 'taskOptions') {
          value = e.target.value;
          name = type;
        }
        const hospitalityTaskDetailsValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        setHospitalityTaskDetails({
          ...hospitalityTaskDetails,
          [inputName]: hospitalityTaskDetailsValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const optionsData = React.useCallback(() => {
    const arr = [];
    for (let i = 1; i <= hospitalityTaskDetails.taskOptions; i++) {
      const optiondata = `taskOptionData${i}`;
      const label = `Task Option ${i}`;
      arr.push(
        <InputGroup
          name={optiondata}
          label={label}
          onChange={handleChange}
          value={hospitalityTaskDetails[optiondata]}
        />
      );
    }

    return <div>{arr.map((item) => item)}</div>;
  }, [hospitalityTaskDetails]);
  return (
    <ErrorBoundary>
      <SectionContent className="hospitality-section">
        <div className="hospitality-create-form">
          <Form
            className="hospitality-form-container"
            onFinish={handleHospitalityTaskCreate}>
            <InputGroup
              label="Category Name"
              name="name"
              disabled={true}
              value={props.hospitalityDetails?.name}
            />
            <InputGroup
              label="Alias Name"
              placeholder="Enter Alias Name"
              name="alias_name"
              onChange={handleChange}
              value={hospitalityTaskDetails?.alias_name}
            />
            <InputGroup
              fieldType="dropdown"
              className="group-search"
              mode="multiple"
              name="taskGroup"
              label="Select Group"
              placeholder="Select Group"
              onChange={handleChange}
              value={hospitalityTaskDetails?.taskGroup}>
              {props.groupData?.length > 0 &&
                props.groupData.map((item) => {
                  return (
                    <Option value={item?.id} key={item?.id}>
                      <div className="options-container">
                        <p className="avatar-image-container">
                          <Image
                            src={getImageSource(item?.groupPictureUrl)}
                            preview={false}
                          />
                        </p>
                        <div className="occasion-group-details-container">
                          <p className="occasion-group-name">{item?.name}</p>
                        </div>
                      </div>
                    </Option>
                  );
                })}
            </InputGroup>
            <InputGroup
              label="Task Name"
              placeholder="Enter Name"
              name="taskName"
              onChange={handleChange}
              value={hospitalityTaskDetails?.taskName}
              errorMessage={errorMessages?.taskName}
            />
            <InputGroup
              label="Description"
              placeholder="Enter description"
              name="description"
              fieldType="textArea"
              onChange={handleChange}
              value={hospitalityTaskDetails?.description}
            />
            <InputGroup
              fieldType="radioGroup"
              className="hospitality-task-type"
              label="Type of task"
              name="taskType"
              options={TASK_TYPE}
              onChange={handleChange}
              value={hospitalityTaskDetails?.taskType}
              errorMessage={errorMessages?.taskType}
            />
            <InputGroup
              label="Task Order"
              placeholder="Enter Order"
              name="order"
              onChange={handleChange}
              value={hospitalityTaskDetails?.order}
              errorMessage={errorMessages?.order}
            />
            <InputGroup
              fieldType="radioGroup"
              options={[
                { label: 'True', value: true },
                { label: 'False', value: false }
              ]}
              name="publicAnswer"
              label="Is Public Answer"
              onChange={handleChange}
              value={hospitalityTaskDetails?.publicAnswer}
              errorMessage={errorMessages.publicAnswer}
            />
            <InputGroup
              fieldType="radioGroup"
              className="hospitality-searchbox"
              label="Select Task Avatar"
              name="taskAvatarName"
              onChange={handleChange}
              value={hospitalityTaskDetails?.taskAvatarName}
              errorMessage={errorMessages?.taskAvatarName}>
              {Object.keys(TASK_ICONS).map((item) => {
                return (
                  <Tooltip
                    overlayClassName="hospitality-tooltip"
                    placement="top"
                    title={
                      <span className="hospitality-tooltip-span">{item}</span>
                    }>
                    <Radio.Button value={item}>
                      <img
                        className="hospitality-task-avatar-image"
                        src={TASK_ICONS[item]}></img>
                    </Radio.Button>
                  </Tooltip>
                );
              })}
            </InputGroup>
            {!props.hospitalityTaskId ? (
              <InputGroup
                fieldType="dropdown"
                className="task-data-search"
                options={DATA_INPUT_TYPE}
                name="dataInputType"
                label="Data Input Type"
                onChange={handleChange}
                value={hospitalityTaskDetails?.dataInputType}
                errorMessage={errorMessages.dataInputType}
              />
            ) : (
              ''
            )}
            {hospitalityTaskDetails.dataInputType == 'mcq' ||
            hospitalityTaskDetails.dataInputType == 'scq' ? (
              <InputGroup
                fieldType="dropdown"
                className="task-data-search"
                options={NO_OF_OPTIONS}
                name="taskOptions"
                label="No of Options"
                onChange={handleChange}
                value={hospitalityTaskDetails?.taskOptions}
              />
            ) : (
              ''
            )}
            {hospitalityTaskDetails.dataInputType == 'mcq' ||
            hospitalityTaskDetails.dataInputType == 'scq'
              ? optionsData()
              : ''}
            <SubmitButton
              buttonText={
                props.hospitalityTaskId ? 'Update Task' : 'Create Task'
              }
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  groupData: getAllOccasionGroups(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hospitalityTaskCreate,
      occasionGroupView,
      hospitalityTaskCreateUpdate
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TaskCreate);
