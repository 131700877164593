import {
  OCCASION_SPONSORSHIP_CREATE,
  OCCASION_SPONSORSHIP_CREATE_SUCCESS,
  OCCASION_SPONSORSHIP_CREATE_FAILED,
  OCCASION_SPONSORSHIP_VIEW,
  OCCASION_SPONSORSHIP_VIEW_SUCCESS,
  OCCASION_SPONSORSHIP_VIEW_FAILED,
  OCCASION_SPONSORSHIP_DELETE,
  OCCASION_SPONSORSHIP_DELETE_FAILED,
  OCCASION_SPONSORSHIP_DELETE_SUCCESS,
  OCCASION_SPONSORSHIP_UPDATE,
  OCCASION_SPONSORSHIP_UPDATE_FAILED,
  OCCASION_SPONSORSHIP_UPDATE_SUCCESS
} from '../constants/actionTypes';

export const occasionSponsorshipCreate = (ocId, formData, callback) => ({
  type: OCCASION_SPONSORSHIP_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionSponsorshipCreateSuccess = (data, included, ocId) => ({
  type: OCCASION_SPONSORSHIP_CREATE_SUCCESS,
  payload: { data, included, ocId }
});

export const occasionSponsorshipCreateFailed = (error) => ({
  type: OCCASION_SPONSORSHIP_CREATE_FAILED,
  error
});

export const occasionSponsorshipView = (ocId, page) => ({
  type: OCCASION_SPONSORSHIP_VIEW,
  payload: { ocId, page }
});

export const occasionSponsorshipViewSuccess = (
  data,
  included,
  ocId,
  links
) => ({
  type: OCCASION_SPONSORSHIP_VIEW_SUCCESS,
  payload: { data, included, ocId, links }
});

export const occasionSponsorshipViewFailed = (error) => ({
  type: OCCASION_SPONSORSHIP_VIEW_FAILED,
  error
});

export const occasionSponsorshipDelete = (ocId, sponsorshipId) => ({
  type: OCCASION_SPONSORSHIP_DELETE,
  payload: { ocId, sponsorshipId }
});

export const occasionSponsorshipDeleteSuccess = (
  data,
  ocId,
  included,
  links
) => ({
  type: OCCASION_SPONSORSHIP_DELETE_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionSponsorshipDeleteFailed = (error) => ({
  type: OCCASION_SPONSORSHIP_DELETE_FAILED,
  error
});

export const occasionSponsorshipUpdate = (
  ocId,
  sponsorshipId,
  formData,
  callback
) => ({
  type: OCCASION_SPONSORSHIP_UPDATE,
  payload: { ocId, sponsorshipId, formData },
  callback
});

export const occasionSponsorshipUpdateSuccess = (
  data,
  ocId,
  included,
  links
) => ({
  type: OCCASION_SPONSORSHIP_UPDATE_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionSponsorshipUpdateFailed = (error) => ({
  type: OCCASION_SPONSORSHIP_UPDATE_FAILED,
  error
});
