import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../components/Section';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import './styles.scss';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import TableView from '../../../../components/Table';
import { renderRoute } from '../../../../components/RouterHOC';
import { adminViewRouteAccess } from '../../../../selectors/auth';
import { getEnquiryWithPagination } from '../../../../actions/enquiry';
import { getAllEnquiry } from '../../../../selectors/enquiry';

function EnquiryView(props) {
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.getEnquiryWithPagination(page);
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const paginationOptions = {
    showTotal: () => (
      <div className="enquiry-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <div className="enquiry-count-container">
          <span>Enquiry</span>
          <span className="enquiry-count">{props.count}</span>
        </div>
      </div>
    )
  };

  return (
    <ErrorBoundary>
      <SectionContent className="enquiry-view-section">
        <div className="enquiry-view-container">
          <TableView
            isPaginated={true}
            paginationOptions={paginationOptions}
            isRouted={false}
            onPageChange={onPageChange}
            fetchProgress={props.fetchProgress}
            data={props.enquiry}
            paginationLinks={props.enquiryLinks}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  enquiry: getAllEnquiry(store, props),
  fetchProgress: store.browserState.fetchProgress,
  enquiryLinks: store.enquiry.links,
  count: store.enquiry.count
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getEnquiryWithPagination }, dispatch);

export default renderRoute(adminViewRouteAccess)(
  connect(mapStateToProps, mapDispatchToProps)(EnquiryView)
);
