export const PARENT_TAB_KEY = [
  { label: 'No Parent', value: 'no_parent' },
  { label: 'Happenings', value: 'happenings' },
  { label: 'Stories', value: 'stories' },
  { label: 'Youtube Videos', value: 'youtube_videos' },
  { label: 'Events', value: 'events' },
  { label: 'Details', value: 'details' },
  { label: 'Details Info', value: 'details_info' },
  { label: 'Members', value: 'members' },
  { label: 'Speakers', value: 'speakers' },
  { label: 'Sponsorships', value: 'sponsorships' },
  { label: 'Important Contacts', value: 'important_contacts' },
  { label: 'Awards', value: 'awards' },
  { label: 'Advertisements', value: 'advertisements' },
  { label: 'Tickets', value: 'tickets' },
  { label: 'Live Streams', value: 'live_streams' },
  { label: 'Hospitalities', value: 'hospitalities' },
  { label: 'Gallaries', value: 'gallaries' },
  { label: 'Email Notifications', value: 'email_notifications' },
  { label: 'Sms Notifications', value: 'sms_notifications' },
  { label: 'Poll Posts', value: 'poll_posts' },
  { label: 'Feedbacks', value: 'feedbacks' },
  { label: 'Leaderboard ', value: 'leaderboard ' }
];

export const TAB_KEY = [
  { label: 'Happenings', value: 'happenings' },
  { label: 'Stories', value: 'stories' },
  { label: 'Youtube Videos', value: 'youtube_videos' },
  { label: 'Events', value: 'events' },
  { label: 'Details', value: 'details' },
  { label: 'Details Info', value: 'details_info' },
  { label: 'Members', value: 'members' },
  { label: 'Speakers', value: 'speakers' },
  { label: 'Sponsorships', value: 'sponsorships' },
  { label: 'Important Contacts', value: 'important_contacts' },
  { label: 'Awards', value: 'awards' },
  { label: 'Advertisements', value: 'advertisements' },
  { label: 'Tickets', value: 'tickets' },
  { label: 'Live Streams', value: 'live_streams' },
  { label: 'Hospitalities', value: 'hospitalities' },
  { label: 'Gallaries', value: 'gallaries' },
  { label: 'Email Notifications', value: 'email_notifications' },
  { label: 'Sms Notifications', value: 'sms_notifications' },
  { label: 'Poll Posts', value: 'poll_posts' },
  { label: 'Feedbacks', value: 'feedbacks' },
  { label: 'Leaderboard ', value: 'leaderboard ' }
];

export const STATUS = [
  { label: 'On', value: 'on' },
  { label: 'Off', value: 'off' },
  { label: 'Disabled', value: 'disabled' }
];
