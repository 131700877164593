import request from './request';

export function occasionCategoryStatusCreateApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_resource/occasion_resource_category_statuses`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function occasionCategoryStatusViewApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_resource/occasion_resource_category_statuses?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function deleteCategoryStatusApi(ocId, categoryStatusId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_resource/occasion_resource_category_statuses/${categoryStatusId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function updateCategoryStatusApi(
  ocId,
  categoryStatusId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_resource/occasion_resource_category_statuses/${categoryStatusId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function occasionResourcesViewApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_resource/occasion_resources?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function deleteOccasionResourcesApi(ocId, ocResourceId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_resource/occasion_resources/${ocResourceId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function occasionResourcesCreateApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_resource/occasion_resources`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function updateOccasionResourcesApi(
  ocId,
  ocResourceId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_resource/occasion_resources/${ocResourceId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}
