import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, AutoComplete, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../../components/Section';
import ErrorBoundary from '../../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../../../components/InputGroup/FormComponents';
import { getAllOccasionTicketVariants } from '../../../../../../../selectors/ticketVariant';
import { occasionTicketVariantView } from '../../../../../../../actions/ticketVariant';
import { createTicketPurchase } from '../../../../../../../actions/purchases';
import { validateEmailOrMobile } from '../../../../../../../helpers/extraHelpers';

const validate = (values, isCSV) => {
  const error = {};

  if (!isCSV) {
    if (!values.email_or_mobile) {
      error.email_or_mobile = 'Email or Mobile is required';
    } else if (!validateEmailOrMobile(values.email_or_mobile)) {
      error.email_or_mobile = 'Email or Mobile is invalid';
    }

    if (!values.ticketVariant) {
      error.ticketVariant = 'Select Ticket Variant';
    }

    if (!values.amount) {
      error.amount = 'Amount is required';
    } else if (isNaN(values.amount)) {
      error.amount = 'Amount should be a number';
    } else if (parseInt(values.amount) < 0) {
      error.amount = 'Amount cannot be negative';
    }

    if (!values.quantity) {
      error.quantity = 'Quantity is required';
    } else if (isNaN(values.quantity)) {
      error.quantity = 'Quantity should be number';
    } else if (parseInt(values.quantity) < 0) {
      error.quantity = 'Quantity cannot be negative';
    }
  } else {
    if (!values.csv_file?.length > 0) {
      error.quantity = 'CSV file is required';
    }
  }
  return error;
};

const { Option } = AutoComplete;

function CreateTicketPurchase(props) {
  const [purchaseData, setPurchaseData] = React.useState({
    email_or_mobile: '',
    ticketVariant: '',
    amount: '',
    quantity: '',
    csv_file: []
  });
  const [errorMessages, setErrorMessages] = React.useState({});

  const [page, setPage] = useState(1);
  const [uploadCSV, setUploadCSV] = useState(false);

  const fetchTicketVariants = (page) => {
    props.occasionTicketVariantView(props.occasionId, props.ticketId, page);
  };

  React.useEffect(() => {
    fetchTicketVariants(page);
  }, []);

  const handleSubmit = () => {
    try {
      const validateObj = validate(purchaseData, uploadCSV);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();

        if (!uploadCSV) {
          formData.append(
            'pending_registration_ticket_purchase[amount]',
            `${purchaseData.amount}00`
          );
          formData.append(
            'pending_registration_ticket_purchase[quantity]',
            purchaseData.quantity
          );
          formData.append(
            'pending_registration_ticket_purchase[email_or_mobile]',
            purchaseData.email_or_mobile
          );
          formData.append(
            'pending_registration_ticket_purchase[ticket_variant_id]',
            purchaseData.ticketVariant
          );
        } else if (purchaseData.csv_file?.length > 0) {
          formData.append('csv_file', purchaseData.csv_file[0].originFileObj);
        }

        props.createTicketPurchase(
          props.occasionId,
          props.ticketId,
          formData,
          uploadCSV,
          resetData
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const resetData = () => {
    setPurchaseData({
      email_or_mobile: '',
      ticketVariant: '',
      amount: '',
      quantity: '',
      csv_file: []
    });
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;

      if (type === 'csv_file') {
        value = e.fileList;
        name = type;
      }

      const ticketVariantFormValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setPurchaseData({
        ...purchaseData,
        [inputName]: ticketVariantFormValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height === scrollHeight && ticketVariantLinks.next) {
      fetchTicketVariants(page + 1);
      setPage((val) => val + 1);
    }
  };

  const renderNormalForm = () => {
    return (
      <>
        <InputGroup
          fieldType="dropdown"
          className="ticket-purchase-search"
          mode="single"
          name="ticketVariant"
          label="Select Ticket Variant"
          placeholder="Select Ticket variant"
          onChange={handleInputChange}
          onPopupScroll={handleScroll}
          value={purchaseData?.ticketVariant}
          errorMessage={errorMessages.ticketVariant}>
          {props.ticketVariantData?.length > 0 &&
            props.ticketVariantData.map((item) => {
              return (
                <Option value={item?.id} key={item?.id}>
                  <div className="options-container">
                    <div className="ticket-purchase-details-container">
                      <p className="ticket-purchase-name">{item?.title}</p>
                    </div>
                  </div>
                </Option>
              );
            })}
        </InputGroup>
        <InputGroup
          label="Email or Mobile"
          name="email_or_mobile"
          placeholder="Enter Email or Mobile"
          onChange={handleInputChange}
          value={purchaseData?.email_or_mobile}
          errorMessage={errorMessages.email_or_mobile}
        />
        <InputGroup
          label="Enter Amount"
          name="amount"
          placeholder="Enter Amount"
          onChange={handleInputChange}
          value={purchaseData?.amount}
          errorMessage={errorMessages.amount}
        />
        <InputGroup
          label="Quantity"
          name="quantity"
          placeholder="Enter Quantity"
          onChange={handleInputChange}
          value={purchaseData?.quantity}
          errorMessage={errorMessages.quantity}
        />
      </>
    );
  };

  const renderCSV = () => {
    return (
      <>
        <InputGroup
          fieldType="file"
          accept=".csv"
          name="csv_file"
          label="CSV file"
          fileList={purchaseData?.csv_file}
          errorMessage={errorMessages.quantity}
          onChange={handleInputChange}
        />
        <a
          target="_blank"
          download
          href="https://firebasestorage.googleapis.com/v0/b/sociana-1b60a.appspot.com/o/ticket%2Fticket%20purchase%2Fsample.csv?alt=media&token=a199d105-cbc5-42ef-b094-c14fa836ffcc">
          Download CSV Sample
        </a>
      </>
    );
  };

  const handleSwitchChange = () => {
    setUploadCSV((val) => !val);
    resetData();
  };

  return (
    <ErrorBoundary>
      <SectionContent className="ticket-purchase-create-section">
        <div className="ticket-purchase-create">
          <Form className="ticket-purchase-tag" onFinish={handleSubmit}>
            <InputGroup
              fieldType="switch"
              label={`Toggle to ${!uploadCSV ? 'upload CSV' : 'fill form'}`}
              onChange={handleSwitchChange}
              value={uploadCSV}
            />
            {uploadCSV ? renderCSV() : renderNormalForm()}
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={'Create'}
            />
          </Form>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  ticketVariantData: getAllOccasionTicketVariants(store, props),
  ticketVariantLinks: store.ticketVariant.ticketVariantLinks
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { createTicketPurchase, occasionTicketVariantView },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTicketPurchase);
