import React, { useState } from 'react';
import { Form, Image } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './styles.scss';
import { forgotPassword } from '../../actions/forgotPassword';
import InputGroup from '../../components/InputGroup';
import { SubmitButton } from '../../components/InputGroup/FormComponents';
import LINKSENT_SUCCESS_ICON from '../../assets/linkSent-success-icon.png';
import FORGOTPASSWORD_BACKGROUND_IMAGE from '../../assets/forgotPassword-background-image.png';
import FORGOTPASSWORD_IMAGE from '../../assets/forgotPassword-image.png';
import BackButton from '../../components/BackButton/index';
import { Section } from '../../components/Section';
import ErrorBoundary from '../../components/ErrorBoundary';

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email is required';
  } else if (values.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
      errors.email = 'Invalid Email';
  }
};
const ForgotPasswordPage = (props) => {
  const [forgotPasswordFormState, setforgotPasswordFormState] = useState({
    email: ''
  });
  const [errorMessages, setErrorMessages] = useState({});
  const [message, setmessage] = useState('');
  const [forgotPasswordSubmit, setforgotPasswordSubmit] = useState(false);
  const handleForgotpassword = () => {
    try {
      const validateObj = validate(forgotPasswordFormState);
      if (Object.keys(validateObj ? validateObj : {}).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        props.forgotPassword(forgotPasswordFormState, (data) => {
          setmessage(data);
          setforgotPasswordFormState({}), setforgotPasswordSubmit(true);
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleInputChange = (e) => {
    try {
      setforgotPasswordFormState({
        email: e.target.value
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ErrorBoundary>
      <Section>
        <div className="forgotpassword-container">
          <BackButton path={'/signin'} />
          <div className="forgotpassword-image-box">
            <Image
              className="forgotpassword-image-style"
              preview={false}
              src={FORGOTPASSWORD_BACKGROUND_IMAGE}
            />
          </div>
          <div className="forgotpassword-content">
            <div className="fgoretpassword-form">
              {forgotPasswordSubmit ? (
                <>
                  <div className="forgotpassword-form-container">
                    <div className="forgotpassword-response-content">
                      <div className="forgotpassword-icon-box">
                        <Image
                          className="forgotpasswordsuccess-image-style"
                          preview={false}
                          src={LINKSENT_SUCCESS_ICON}
                        />
                      </div>
                      <p className="forgotpassword-text">{message}</p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h1 className="forgotpassword-header">Forgot password</h1>
                  <Form
                    className="forgotpassword-form-container"
                    onFinish={handleForgotpassword}>
                    <InputGroup
                      label="Email Address"
                      name="email"
                      className="forgotPassword-input-box"
                      placeholder="Enter your email address "
                      onChange={handleInputChange}
                      value={forgotPasswordFormState?.email}
                      errorMessage={errorMessages?.email}
                    />
                    <SubmitButton
                      className="forgotPassword-submit-button"
                      buttonText={'Submit'}
                      fetchProgress={props.fetchProgress}
                    />
                  </Form>
                </>
              )}
            </div>
          </div>
          <div className="forgotpassword-image1-box">
            <Image
              className="forgotpassword-image1-style"
              preview={false}
              src={FORGOTPASSWORD_IMAGE}
            />
          </div>
        </div>
      </Section>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      forgotPassword
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
