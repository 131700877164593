import request from './request';

export function occasionDiscussionViewApi(ocId, page, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/feedbacks?page=${page}`, {
    method: 'GET',
    token
  });
}

export function occasionDiscussionDeleteApi(ocId, discussionId, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/feedbacks/${discussionId}`, {
    method: 'DELETE',
    token
  });
}
