export const LANDING_PAGE = [
  { label: 'Home', value: 'home' },
  { label: 'Hospitality', value: 'hospitality' },
  { label: 'Post', value: 'post' }
];

export const OCCASION_VIEW = [
  { label: 'Normal', value: 'normal' },
  { label: 'Letlovefly', value: 'letlovefly' }
];
