import React from 'react';
import { Spin } from 'antd';
import Alert from './components/Alert';
import RootRoute from './routes';
import Loader from './components/Loader';

Spin.setDefaultIndicator(<Loader />);

function App() {
  return (
    <>
      <RootRoute />
      <Alert />
    </>
  );
}

export default App;
