import React from 'react';
import './styles.scss';
import { VideoPlayer } from '../../../../../components/VideoPlayer';
import { MoreOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Button, Space } from 'antd';

export const LiveVideoComponent = ({ url, videoId, deleteLiveVideo }) => {
  const menu = (
    <Menu>
      <Menu.Item>
        <Button
          className="live-video-delete-button"
          onClick={() => deleteLiveVideo(videoId)}>
          Delete
        </Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="live-video-content">
      <Space direction="vertical">
        <Space wrap>
          <Dropdown overlay={menu} placement="bottomRight">
            <MoreOutlined className="live-video-moreoutlined" />
          </Dropdown>
        </Space>
      </Space>
      <VideoPlayer url={url} width="100%" />
    </div>
  );
};
