import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  createPost,
  deletePostApi,
  downloadExslExportUserPostApi,
  editPostApi,
  viewPost
} from '../api/post';

import {
  CREATE_POST,
  DELETE_POST,
  DOWNLOAD_EXSL_EXPORT_USER_POST,
  EDIT_POST,
  GET_POST_PAGINATION
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  createPostSuccess,
  createPostFailed,
  getPostWithPaginationSuccess,
  getPostWithPaginationFailed,
  editPostSuccess,
  editPostFailed,
  deletePostSuccess,
  deletePostFailed,
  downloadExslExportUserPostSuccess,
  downloadExslExportUserPostFailed
} from '../actions/post';

export function* createPostSaga(action) {
  try {
    const result = yield call(
      createPost,
      action.payload.occasionId,
      action.payload.formData,
      getUserToken()
    );
    if (typeof action.callback === 'function') {
      action.callback();
    }
    if (isValidResult(result)) {
      yield put(
        createPostSuccess(
          action.payload.occasionId,
          action.payload.postableType,
          result.data,
          result.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
    } else if (result && result.meta) {
      yield put(createPostFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(createPostFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getPostsWithPaginationSaga(action) {
  try {
    const result = yield call(
      viewPost,
      action.payload.occasionId,
      action.payload.postableType,
      action.payload.pageNumber,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getPostWithPaginationSuccess(
          action.payload.occasionId,
          action.payload.postableType,
          result.data,
          result?.links,
          result?.included
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getPostWithPaginationFailed(error));

    yield put(requestAutoHideAlert(error, 'Error', 4000));
  }
}

export function* editPostSaga(action) {
  try {
    const result = yield call(
      editPostApi,
      action.payload.occasionId,
      action.payload.postableType,
      action.payload.postId,
      action.payload.formData,
      getUserToken()
    );
    if (typeof action.callback === 'function') {
      action.callback();
    }
    if (isValidResult(result)) {
      yield put(
        editPostSuccess(action.payload.occasionId, result.data, result.included)
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
    }
  } catch (error) {
    yield put(editPostFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* deletePostSaga(action) {
  try {
    const result = yield call(
      deletePostApi,
      action.payload.occasionId,
      action.payload.postableType,
      action.payload.postId,
      getUserToken()
    );
    if (typeof action.callback === 'function') {
      action.callback();
    }
    if (isValidResult(result)) {
      yield put(
        deletePostSuccess(
          action.payload.occasionId,
          action.payload.postableType,
          result.data,
          result.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
    }
  } catch (error) {
    yield put(deletePostFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* downloadExslExportUserPostSaga(action) {
  try {
    const result = yield call(
      downloadExslExportUserPostApi,
      action.payload.ocId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        downloadExslExportUserPostSuccess(result.data, result.included)
      );
      if (typeof action.callback === 'function') {
        action.callback(result?.blobUrl);
      }
    }
  } catch (error) {
    yield put(downloadExslExportUserPostFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* postActionWatcher() {
  yield takeLatest(CREATE_POST, progressSaga, createPostSaga);
  yield takeLatest(
    GET_POST_PAGINATION,
    progressSaga,
    getPostsWithPaginationSaga
  );
  yield takeLatest(EDIT_POST, progressSaga, editPostSaga);
  yield takeLatest(DELETE_POST, progressSaga, deletePostSaga);
  yield takeLatest(
    DOWNLOAD_EXSL_EXPORT_USER_POST,
    progressSaga,
    downloadExslExportUserPostSaga
  );
}
