import React, { useState } from 'react';
import './styles.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import { SectionContent } from '../../../../components/Section';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import {
  getAdminWithPagination,
  getAdminBySearch
} from '../../../../actions/admin';
import {
  getAllActiveAdmins,
  getAllInactiveAdmins
} from '../../../../selectors/admin';
import { resetTabIndex } from '../../../../actions/tabKeys';
import TableView from '../../../../components/Table';
import Tabs from '../../../../components/Tabs';
import { renderRoute } from '../../../../components/RouterHOC';
import { adminViewRouteAccess } from '../../../../selectors/auth';
import InputGroup from '../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../components/InputGroup/FormComponents';
import Form from 'antd/lib/form/Form';
import { Button } from 'antd';

function AdminView(props) {
  const [isActiveTab, setIsActiveTab] = useState(true);
  const [getAdminDetails, setGetAdminDetails] = useState({});
  const [activePage, setActivePage] = React.useState(1);
  const [inActivePage, setInActivePage] = React.useState(1);
  const [errorMessages, setErrorMessages] = React.useState({});
  const onPageChange = (page) => {
    if (!!isActiveTab) {
      setActivePage(page);
    } else {
      setInActivePage(page);
    }
    props.getAdminWithPagination(page, isActiveTab);
  };

  React.useEffect(() => {
    if (!!isActiveTab) {
      onPageChange(activePage);
    } else {
      onPageChange(inActivePage);
    }
    props.resetTabIndex('admin');
  }, [isActiveTab]);

  const handleTabChange = (key) => {
    setIsActiveTab(key == 1);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.searchData) errors.type = 'Search Data required';
    return errors;
  };
  const handlegetAdminDetails = (page) => {
    try {
      const validateObj = validate(getAdminDetails);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        props.getAdminBySearch(
          getAdminDetails?.searchData,
          isActiveTab,
          (page = 1)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'searchData') {
        value = e.target.value;
        name = type;
      }
      const adminDetailsValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      setGetAdminDetails({
        ...getAdminDetails,
        [inputName]: adminDetailsValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleReset = (page) => {
    setGetAdminDetails({});
    setErrorMessages({});
    props.getAdminWithPagination(page, isActiveTab);
  };

  const tableViewTab = (paginationOptions) => {
    return (
      <div>
        <Form
          className="admin-input-search-container"
          onFinish={handlegetAdminDetails}>
          <InputGroup
            label="Name/Phone/Email"
            className="admin-inputbox"
            fieldType="searchbox"
            placeholder="Enter Name/Phone/Email"
            name="searchData"
            onChange={handleChange}
            value={getAdminDetails?.searchData}
            errorMessage={errorMessages.type}
          />
          <div style={{ flex: 1, display: 'flex' }}>
            <SubmitButton
              wrapperClass="admin-submit-wrapper"
              className="admin-submit-inputbox"
              buttonText="Search"
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
            <Button className="reset-button" onClick={handleReset}>
              Reset
            </Button>
          </div>
        </Form>
        <TableView
          paginationOptions={paginationOptions}
          isPaginated={true}
          fetchProgress={props.fetchProgress}
          paginationLinks={props.adminLinks}
          onPageChange={onPageChange}
          data={isActiveTab ? props.activeAdmins : props.inactiveAdmins}
        />
      </div>
    );
  };

  const tabPanes = () => {
    const paginationOptions = {
      showTotal: () => (
        <div className="admin-button-box">
          <Button
            className="refresh-button"
            onClick={
              isActiveTab
                ? () => onPageChange(activePage)
                : () => onPageChange(inActivePage)
            }>
            <SyncOutlined />
          </Button>
          <div className="admin-count-container">
            <span>Admins</span>
            <span className="admin-count">{props.count}</span>
          </div>
        </div>
      )
    };
    return [
      {
        tabName: 'Active',
        tabKey: '1',
        children: tableViewTab(paginationOptions)
      },
      {
        tabName: 'Inactive',
        tabKey: '2',
        children: tableViewTab(paginationOptions)
      }
    ];
  };

  return (
    <ErrorBoundary>
      <SectionContent className="admin-view-section">
        <div className="admin-view-container">
          <div className="admin-change-type-container">
            <Tabs
              defaultActiveKey="1"
              onChange={handleTabChange}
              tabPanes={tabPanes()}
            />
          </div>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  activeAdmins: getAllActiveAdmins(store, props),
  inactiveAdmins: getAllInactiveAdmins(store, props),
  fetchProgress: store.browserState.fetchProgress,
  adminLinks: store.admin.links,
  count: store.admin.count
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetTabIndex,
      getAdminWithPagination,
      getAdminBySearch
    },
    dispatch
  );

export default renderRoute(adminViewRouteAccess)(
  connect(mapStateToProps, mapDispatchToProps)(AdminView)
);
