import { union } from 'lodash';
import {
  OCCASION_LIVE_VIDEO_VIEW_SUCCESS,
  OCCASION_LIVE_VIDEO_VIEW_FAILED,
  OCCASION_LIVE_VIDEO_CREATE_SUCCESS,
  OCCASION_LIVE_VIDEO_DELETE_SUCCESS,
  OCCASION_LIVE_VIDEO_DELETE_FAILED
} from '../constants/actionTypes';

const initialState = {
  occasionLiveVideoData: null,
  error: null
};

const liveVideo = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_LIVE_VIDEO_VIEW_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionLiveVideoData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionLiveVideoData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        }
      };
    }

    case OCCASION_LIVE_VIDEO_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionLiveVideoData || {};
      const currentOccasionLiveVideoIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionLiveVideoIds];
      return {
        ...state,
        occasionLiveVideoData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }
    case OCCASION_LIVE_VIDEO_DELETE_SUCCESS: {
      const { ocId, videoId } = action.payload;
      const occasionGroupedData = state.occasionLiveVideoData || {};
      const currentOccasionLiveVideoIds = occasionGroupedData[ocId] || [];
      const newIds = currentOccasionLiveVideoIds.filter((item) => {
        return item != videoId;
      });
      return {
        ...state,
        occasionLiveVideoData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }
    case OCCASION_LIVE_VIDEO_VIEW_FAILED:
    case OCCASION_LIVE_VIDEO_DELETE_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default liveVideo;
