import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import {
  hospitalityTaskNotProvidedView,
  searchNotProvidedTasks
} from '../../../../../../actions/hospitalityTask';
import {
  currentHospitalityTaskData,
  currentHospitalityTaskWithTaskOptionsData,
  getAllNotProvidedTaskWithGuestData,
  getAllNotProvidedTaskSearchWithGuestData
} from '../../../../../../selectors/hospitalityTask';
import { RESET_NOTPROVIDEDTASKS } from '../../../../../../constants/actionTypes';
import { Button, Modal } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import AddProvidedAnswerForAll from './AddProvidedAnswerForAll';
import AddProvidedCSV from './AddProvidedCSV';
import ProvidedTaskSearch from './ProvidedTaskSearch';

const NotProvidedView = (props) => {
  const [page, setPage] = useState(1);
  const [openCSV, setOpenCSV] = useState(false);
  const [openSingleAnswer, setOpenSingleAnswer] = useState(false);
  const [openAnswerForAll, setOpenAnswerForAll] = useState(false);
  const [isMultipalFileView, setIsMultipalFileView] = useState(false);
  const dispatch = useDispatch();
  const [taskData, setTaskData] = React.useState({
    info: ''
  });

  const onSearch = () => {
    try {
      if (taskData.info) {
        props.searchNotProvidedTasks(
          props.occasionId,
          props.hospitalityTaskId,
          taskData,
          1
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const resetSearch = () => {
    dispatch({ type: RESET_NOTPROVIDEDTASKS });
    setTaskData({
      info: ''
    });
  };

  const handleMultipalFilesView = () => {
    setIsMultipalFileView(true);
  };
  const handleOk = (type) => {
    if (type === 'multipalFileView') {
      setIsMultipalFileView(false);
    }
  };

  const handleCancel = (type) => {
    if (type === 'multipalFileView') {
      setIsMultipalFileView(false);
    }
  };

  const onPageChange = (page) => {
    setPage(page);
    props.hospitalityTaskNotProvidedView(
      props.occasionId,
      props.hospitalityTaskId,
      page
    );
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const toggleOpenCSV = () => {
    setOpenCSV((val) => !val);
  };

  const toggleOpenAnswerForAll = () => {
    setOpenAnswerForAll((val) => !val);
  };

  const paginationOptions = {
    showTotal: () => {
      return (
        <div className="button-box">
          <Button className="refresh-button" onClick={() => onPageChange(page)}>
            <SyncOutlined />
          </Button>
          <Button className="download-button" onClick={toggleOpenCSV}>
            Add Answers using CSV
          </Button>
          <Button className="download-button" onClick={toggleOpenAnswerForAll}>
            Add Same Answer for all Members
          </Button>
        </div>
      );
    }
  };

  const handleCreateSingleAnswer = (userId, guestId) => {
    let data = {
      userId,
      guestId
    };
    setOpenSingleAnswer(data);
  };

  const expandableComponent = (record) => {
    const { currentOccasionHospitalityGuests: guests } = record;

    if (guests?.length > 0) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ textAlign: 'left' }}>Guests:</p>
          <ul>
            {guests?.length > 0 &&
              guests?.map((item) => {
                return (
                  <li
                    style={{
                      textAlign: 'left',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}>
                    <span>Name: {item?.name}</span>
                    {item?.currentTaskAnswer?.length > 0 ? (
                      item?.currentTaskAnswer[0]
                        ?.multipleDocumentAttachmentUrls ? (
                        <>
                          <div onClick={handleMultipalFilesView}>
                            Answer:
                            <span
                              style={{
                                cursor: 'pointer',
                                color: 'blue',
                                marginLeft: '5px'
                              }}>
                              View All Files
                            </span>
                          </div>
                          <Modal
                            centered
                            title="All Documents"
                            visible={isMultipalFileView}
                            onOk={() => handleOk('multipalFileView')}
                            onCancel={() => handleCancel('multipalFileView')}
                            footer={null}>
                            <div
                              style={{
                                width: '100%',
                                height: '150px',
                                overflowY: 'scroll',
                                display: 'flex',
                                flexFlow: 'column nowrap'
                              }}>
                              {Array.isArray(item.currentTaskAnswer) &&
                                item.currentTaskAnswer.length > 0 &&
                                item.currentTaskAnswer[0].multipleDocumentAttachmentUrls.map(
                                  (item, index) => {
                                    return (
                                      <div style={{ marginBottom: '20px' }}>
                                        <a
                                          href={`${
                                            process.env.REACT_APP_CLIENT_URL
                                          }/${Object.values(item)[0]}`}
                                          target="_blank">
                                          {`File ${index + 1}`}
                                        </a>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </Modal>
                        </>
                      ) : item?.currentTaskAnswer[0]?.documentAttachmentUrl ? (
                        <a
                          href={`${process.env.REACT_APP_CLIENT_URL}/${item?.currentTaskAnswer[0]?.documentAttachmentUrl}`}
                          target="_blank">
                          File
                        </a>
                      ) : (
                        <span>
                          Answer: {item?.currentTaskAnswer[0]?.answer}
                        </span>
                      )
                    ) : (
                      <Button
                        onClick={() =>
                          handleCreateSingleAnswer(record.id, item.id)
                        }
                        style={{ marginLeft: 10 }}>
                        Add Answer
                      </Button>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
      );
    } else {
      return <p style={{ textAlign: 'left' }}>No Guests present.</p>;
    }
  };

  if (openCSV) {
    return (
      <div className="edit-container">
        <AddProvidedCSV
          occasionId={props.occasionId}
          hospitalityTaskId={props.hospitalityTaskId}
          hospitalityTaskDetails={props.hospitalityTask}
          handleCloseClick={toggleOpenCSV}
        />
      </div>
    );
  }

  if (openAnswerForAll) {
    return (
      <div className="edit-container">
        <AddProvidedAnswerForAll
          occasionId={props.occasionId}
          hospitalityTaskId={props.hospitalityTaskId}
          sendToAll={true}
          hospitalityTaskDetails={props.hospitalityTask}
          dataInputType={props.dataInputType}
          handleCloseClick={toggleOpenAnswerForAll}
        />
      </div>
    );
  }

  if (openSingleAnswer) {
    return (
      <div className="edit-container">
        <AddProvidedAnswerForAll
          occasionId={props.occasionId}
          hospitalityTaskId={props.hospitalityTaskId}
          sendToAll={false}
          userData={openSingleAnswer}
          type="provide"
          hospitalityTaskDetails={props.hospitalityTask}
          dataInputType={props.dataInputType}
          handleCloseClick={() => setOpenSingleAnswer(false)}
        />
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <SectionContent className="task-request-view-section">
        <div className="task-request-view-container">
          <ProvidedTaskSearch
            occasionId={props.occasionId}
            taskData={taskData}
            setTaskData={setTaskData}
            onSearch={onSearch}
            resetSearch={resetSearch}
          />
          <TableView
            isPaginated={true}
            isRouted={false}
            paginationOptions={paginationOptions}
            expandable={expandableComponent}
            onPageChange={onPageChange}
            isCreatable={handleCreateSingleAnswer}
            fetchProgress={props.fetchProgress}
            data={
              props.isSearch
                ? props.notProvidedSearchData
                : props.notProvidedData
            }
            blacklistKeys={[
              'currentOccasionHospitalityGuests',
              'dob',
              'gender'
            ]}
            paginationLinks={
              props.isSearch
                ? props.notProvidedSearchLinks
                : props.notProvidedLinks
            }
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  hospitalityTask: currentHospitalityTaskWithTaskOptionsData(store, props),
  notProvidedData: getAllNotProvidedTaskWithGuestData(store, props),
  notProvidedSearchData: getAllNotProvidedTaskSearchWithGuestData(store, props),
  notProvidedLinks: store.hospitalityTask.notProvidedtaskLinks,
  notProvidedSearchLinks: store.hospitalityTask.notProvidedSearchLinks,
  isSearch: store.hospitalityTask.isNotProvidedSearch
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { hospitalityTaskNotProvidedView, searchNotProvidedTasks },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(NotProvidedView);
