import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  createTicketPurchaseApi,
  createTicketPurchaseApiCSV,
  getRegisteredTicketPurchasesApi,
  getUnregisteredTicketPurchasesApi,
  registeredTicketPurchasesSearchApi,
  unregisteredTicketPurchasesSearchApi
} from '../api/purchases';
import {
  CREATE_TICKET_PURCHASE,
  GET_REGISTERED_TICKET_PURCHASES,
  GET_UNREGISTERED_TICKET_PURCHASES,
  REGISTERED_TICKET_PURCHASES_SEARCH,
  UNREGISTERED_TICKET_PURCHASES_SEARCH
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  createTicketPurchaseFailed,
  createTicketPurchaseSuccess,
  getRegisteredTicketPurchasesFailed,
  getRegisteredTicketPurchasesSuccess,
  getUnregisteredTicketPurchasesFailed,
  getUnregisteredTicketPurchasesSuccess,
  registeredTicketPurchasesSearchFailed,
  registeredTicketPurchasesSearchSuccess,
  unregisteredTicketPurchasesSearchFailed,
  unregisteredTicketPurchasesSearchSuccess
} from '../actions/purchases';

export function* createTicketPurchaseSaga(action) {
  try {
    const result = yield call(
      !action.payload.isCSV
        ? createTicketPurchaseApi
        : createTicketPurchaseApiCSV,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        createTicketPurchaseSuccess(
          action.payload.ocId,
          action.payload.ticketId,
          result.data,
          result.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(createTicketPurchaseFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getRegisteredTicketPurchasesSaga(action) {
  try {
    const result = yield call(
      getRegisteredTicketPurchasesApi,
      action.payload.ocId,
      action.payload.ticketId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getRegisteredTicketPurchasesSuccess(
          action.payload.ocId,
          action.payload.ticketId,
          result.data,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getRegisteredTicketPurchasesFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getUnregisteredTicketPurchasesSaga(action) {
  try {
    const result = yield call(
      getUnregisteredTicketPurchasesApi,
      action.payload.ocId,
      action.payload.ticketId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getUnregisteredTicketPurchasesSuccess(
          action.payload.ocId,
          action.payload.ticketId,
          result.data,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getUnregisteredTicketPurchasesFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* registeredTicketPurchasesSearchSaga(action) {
  try {
    const result = yield call(
      registeredTicketPurchasesSearchApi,
      action.payload.ocId,
      action.payload.ticketVariantId,
      action.payload.searchData,
      action.payload.referenceId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        registeredTicketPurchasesSearchSuccess(
          result.data,
          action.payload.ocId,
          action.payload.ticketId,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(registeredTicketPurchasesSearchFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* unregisteredTicketPurchasesSearchSaga(action) {
  try {
    const result = yield call(
      unregisteredTicketPurchasesSearchApi,
      action.payload.ocId,
      action.payload.ticketVariantId,
      action.payload.searchData,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        unregisteredTicketPurchasesSearchSuccess(
          result.data,
          action.payload.ocId,
          action.payload.ticketId,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(unregisteredTicketPurchasesSearchFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export default function* ticketPurchasesActionWatcher() {
  yield takeLatest(
    CREATE_TICKET_PURCHASE,
    progressSaga,
    createTicketPurchaseSaga
  );
  yield takeLatest(
    GET_REGISTERED_TICKET_PURCHASES,
    progressSaga,
    getRegisteredTicketPurchasesSaga
  );
  yield takeLatest(
    GET_UNREGISTERED_TICKET_PURCHASES,
    progressSaga,
    getUnregisteredTicketPurchasesSaga
  );
  yield takeLatest(
    REGISTERED_TICKET_PURCHASES_SEARCH,
    progressSaga,
    registeredTicketPurchasesSearchSaga
  );
  yield takeLatest(
    UNREGISTERED_TICKET_PURCHASES_SEARCH,
    progressSaga,
    unregisteredTicketPurchasesSearchSaga
  );
}
