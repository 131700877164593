import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import Tabs from '../../../../components/Tabs';
import { currentUserId } from '../../../../selectors/dynamicData';
import InternalUserDetails from './InternalUserDetails';
import { resetTabIndex, setCurrentTabIndex } from '../../../../actions/tabKeys';
import { getUserDetails } from '../../../../selectors/socianaInternalUser';
import InternalUserOccasionAssign from './InternalUserOccasionAssign';
import InternalUserOccasionView from './InternalUserOccasionView';
import Invitation from './InternalUserPendingInvitation/index';
import { renderRoute } from '../../../../components/RouterHOC';
import { TabsHeader } from '../../../../components/TabsHeader';

function InternalUserTabView(props) {
  const getPendingInvitationComponent = () => {
    return (
      <Tabs
        preset="secondary"
        type="card"
        size="small"
        tabPanes={[
          {
            tabName: 'Pending',
            tabKey: '3.1',
            children: (
              <Invitation userId={props.userDetails?.id} status="no_response" />
            )
          },
          {
            tabName: 'Approved',
            tabKey: '3.2',
            children: (
              <Invitation userId={props.userDetails?.id} status="accepted" />
            )
          },
          {
            tabName: 'Rejected',
            tabKey: '3.3',
            children: (
              <Invitation userId={props.userDetails?.id} status="rejected" />
            )
          }
        ]}
      />
    );
  };
  const tabPanes = [
    {
      tabName: 'Details',
      tabKey: '1',
      children: <InternalUserDetails userDetails={props.userDetails} />
    },
    {
      tabName: 'Occasion',
      tabKey: '2',
      children: <InternalUserOccasionView userId={props.userDetails?.id} />
    },
    {
      tabName: 'Pending Invitation',
      tabKey: '3',
      children: getPendingInvitationComponent()
    }
  ];

  const goBack = () => {
    props.resetTabIndex('socianaInternalUser');
    props.history.goBack();
  };

  const handleTabChange = (key) => {
    props.setCurrentTabIndex(key, 'socianaInternalUser');
  };

  return (
    <ErrorBoundary>
      <SectionContent className="internal-user-tab-section">
        <TabsHeader
          name={props.userDetails.name}
          extraData={[props.userDetails.email]}
          wrapperClass="section-details"
          isActive={props.userDetails.active}
        />
        <div className="internal-user-tab-container">
          <Tabs
            defaultActiveKey={props.activeTabKey}
            tabPanes={tabPanes}
            onChange={handleTabChange}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  activeTabKey: store.tabKeys.socianaInternalUser,
  fetchProgress: store.browserState.fetchProgress,
  userId: currentUserId(store, props),
  userDetails: getUserDetails(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ resetTabIndex, setCurrentTabIndex }, dispatch);

export default renderRoute(getUserDetails)(
  connect(mapStateToProps, mapDispatchToProps)(InternalUserTabView)
);
