import { createSelector } from 'reselect';
import { reportEntities, postEntities, userEntities } from './entity';
import { reportedPostIds } from './static';
import { getCurrentOccasionMembers } from './socianaInternalUser';
import { currentOccasionId } from './dynamicData';

export const getCurrentOccasionReports = createSelector(
  currentOccasionId,
  reportedPostIds,
  (ocId, Ids) => ocId && Ids && Ids[ocId]
);
export const getPostReports = createSelector(
  getCurrentOccasionReports,
  postEntities,
  (ids, entities) =>
    ids && entities && ids?.length > 0 && ids.map((id) => entities[id])
);

export const getPostsReportWithPostedByData = createSelector(
  getPostReports,
  userEntities,
  getCurrentOccasionMembers,
  (data, entities, memDetails) => {
    return (
      data &&
      entities &&
      memDetails &&
      data?.length > 0 &&
      data.map((item) => {
        const postedByDetails = entities[item?.user?.id];
        const postedByCollaboratorData = memDetails?.filter(
          (item) => item?.userId == postedByDetails?.id
        );
        return {
          ...item,
          postedByDetails: {
            ...postedByDetails,
            roleName: postedByCollaboratorData[0]?.roleName
          }
        };
      })
    );
  }
);

export const getReportsWithReportedByData = createSelector(
  reportEntities,
  userEntities,
  getCurrentOccasionMembers,
  (rEntities, uEntities, memDetails) => {
    return (
      rEntities &&
      uEntities &&
      memDetails &&
      Object.keys(rEntities)?.length > 0 &&
      Object.values(rEntities).map((item) => {
        const reportedByDetails = uEntities[item?.reportedBy?.id];
        const reportedByCollaboratorData = memDetails?.filter(
          (item) => item?.userId == reportedByDetails?.id
        );
        return {
          ...item,
          reportedByDetails: {
            ...reportedByDetails,
            roleName: reportedByCollaboratorData[0]?.roleName
          }
        };
      })
    );
  }
);

export const getPostWithReportsData = createSelector(
  getPostsReportWithPostedByData,
  getReportsWithReportedByData,
  (data, entities) =>
    data &&
    entities &&
    data.map((el) => ({
      ...el,
      reports: entities.filter((item) => el.id == item.reportableId)
    }))
);
