import request from './request';

export function hospitalityCreateApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_categories`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function hospitalityViewApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/get_hospitality_categories_with_pagination?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function getExslExportApi(ocId, taskIds, token) {
  let query = '';
  if (Array.isArray(taskIds) && taskIds.length > 0) {
    taskIds.forEach((id) => {
      query += `&hospitality_tasks[]=${id}`;
    });
  }

  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/get_xlsx_export_columns?${query}`,
    {
      method: 'GET',
      token
    }
  );
}

export function updateHospitalityCategoryApi(
  ocId,
  hospitalityCategoryId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_categories/${hospitalityCategoryId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function deleteHospitalityCategoryApi(
  ocId,
  hospitalityCategoryId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_categories/${hospitalityCategoryId}`,
    {
      method: 'DELETE',
      token
    }
  );
}
export function getContactUsApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/contact_us_forms?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function updateHospitalityProvideAnswerForCSVApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/update_provided_task_hospitality_answers_the_by_csv_using_alias_name`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function updateHospitalityAllAnswerSForXLSXApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/update_hospitality_task_answers_by_xlsx`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function downloadExslExportApi(
  ocId,
  remember,
  taskIds,
  orderBy,
  orderWith,
  token
) {
  let query = `show_guest_details_as_user_details=${remember}`;
  if (Array.isArray(taskIds) && taskIds?.length > 0) {
    taskIds.forEach((id) => {
      query += `&hospitality_tasks[]=${id}`;
    });
  }
  if (Array.isArray(orderWith) && orderWith?.length > 0) {
    orderWith.forEach((el) => {
      query += `&order_by[value][]=${el}`;
    });
  }
  if (orderBy && orderWith?.length > 0) {
    query += `&order_by[key]=${orderBy}`;
  }
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/xlsx_export_all_hospitality_task_answers?${query}`,
    {
      method: 'GET',
      token,
      isBlob: true
    }
  );
}
