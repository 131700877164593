import { createSelector } from 'reselect';
import {
  currentOccasionId,
  currentHospitalityTaskId,
  currentHospitalityPublicAnswerId
} from './dynamicData';
import { hospitalityTaskPublicAnswersEntities, userEntities } from './entity';
import { publicAnswerIds, publicAnswerLinks } from './static';

export const currentHospitalityPublicAnswerIds = createSelector(
  currentOccasionId,
  currentHospitalityTaskId,
  publicAnswerIds,
  (ocId, taskId, ids) => ocId && ids && taskId && ids[ocId][taskId]
);

export const getAllHospitalityPublicAnswerData = createSelector(
  hospitalityTaskPublicAnswersEntities,
  currentHospitalityPublicAnswerIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAllHospitalityPublicAnswerDataWithUsers = createSelector(
  getAllHospitalityPublicAnswerData,
  userEntities,
  (data, entities) =>
    (data &&
      entities &&
      data?.length > 0 &&
      data.map((item) => ({
        ...item,
        answerBy: { ...entities[item?.answerBy?.id] }
      }))) ||
    []
);

export const getCurrentHospitalityPublicAnswer = createSelector(
  currentHospitalityPublicAnswerId,
  hospitalityTaskPublicAnswersEntities,
  (id, entities) => id && entities && entities[id]
);

export const getAllHospitalityPublicAnswerLinks = createSelector(
  currentOccasionId,
  currentHospitalityTaskId,
  publicAnswerLinks,
  (ocId, taskId, links) =>
    ocId && links && taskId && links[ocId] && links[ocId][taskId]
);
