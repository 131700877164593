import {
  HOSPITALITY_CATEGORY_CREATE,
  HOSPITALITY_CATEGORY_CREATE_SUCCESS,
  HOSPITALITY_CATEGORY_CREATE_FAILED,
  HOSPITALITY_CATEGORY_VIEW,
  HOSPITALITY_CATEGORY_VIEW_SUCCESS,
  HOSPITALITY_CATEGORY_VIEW_FAILED,
  HOSPITALITY_CATEGORY_UPDATE,
  HOSPITALITY_CATEGORY_UPDATE_SUCCESS,
  HOSPITALITY_CATEGORY_UPDATE_FAILED,
  DELETE_HOSPITALITY_CATEGORY,
  DELETE_HOSPITALITY_CATEGORY_FAILED,
  DELETE_HOSPITALITY_CATEGORY_SUCCESS,
  GET_CONTACTUS,
  GET_CONTACTUS_FAILED,
  GET_CONTACTUS_SUCCESS,
  UPDATE_HOSPITALITY_PROVIDE_ANSWER,
  UPDATE_HOSPITALITY_PROVIDE_ANSWER_FAILED,
  UPDATE_HOSPITALITY_PROVIDE_ANSWER_SUCCESS,
  GET_EXSL_EXPORT,
  GET_EXSL_EXPORT_FAILED,
  GET_EXSL_EXPORT_SUCCESS,
  DOWNLOAD_EXSL_EXPORT,
  DOWNLOAD_EXSL_EXPORT_FAILED,
  DOWNLOAD_EXSL_EXPORT_SUCCESS,
  UPDATE_HOSPITALITY_ALL_ANSWERS,
  UPDATE_HOSPITALITY_ALL_ANSWERS_FAILED,
  UPDATE_HOSPITALITY_ALL_ANSWERS_SUCCESS
} from '../constants/actionTypes';

export const hospitalityCategoryCreate = (ocId, formData, callback) => ({
  type: HOSPITALITY_CATEGORY_CREATE,
  payload: { ocId, formData },
  callback
});

export const hospitalityCategoryCreateSuccess = (data, ocId) => ({
  type: HOSPITALITY_CATEGORY_CREATE_SUCCESS,
  payload: { data, ocId }
});

export const hospitalityCategoryCreateFailed = (error) => ({
  type: HOSPITALITY_CATEGORY_CREATE_FAILED,
  error
});

export const hospitalityCategoryView = (ocId, page) => ({
  type: HOSPITALITY_CATEGORY_VIEW,
  payload: { ocId, page }
});

export const hospitalityCategoryViewSuccess = (
  data,
  included,
  links,
  ocId,
  page
) => ({
  type: HOSPITALITY_CATEGORY_VIEW_SUCCESS,
  payload: { data, included, links, ocId, page }
});

export const hospitalityCategoryViewFailed = (error) => ({
  type: HOSPITALITY_CATEGORY_VIEW_FAILED,
  error
});

export const getExslExport = (ocId, taskIds) => ({
  type: GET_EXSL_EXPORT,
  payload: { ocId, taskIds }
});

export const getExslExportSuccess = (data) => ({
  type: GET_EXSL_EXPORT_SUCCESS,
  payload: { data }
});

export const getExslExportFailed = (error) => ({
  type: GET_EXSL_EXPORT_FAILED,
  error
});

export const downloadExslExport = (
  ocId,
  remember,
  taskIds,
  orderBy,
  orderWith,
  callback
) => {
  return {
    type: DOWNLOAD_EXSL_EXPORT,
    payload: { ocId, remember, taskIds, orderBy, orderWith },
    callback
  };
};

export const downloadExslExportSuccess = (data, included) => {
  return {
    type: DOWNLOAD_EXSL_EXPORT_SUCCESS,
    payload: { data, included }
  };
};

export const downloadExslExportFailed = (error) => {
  return {
    type: DOWNLOAD_EXSL_EXPORT_FAILED,
    error
  };
};

export const hospitalityCategoryUpdate = (
  ocId,
  hospitalityCategoryId,
  formData,
  callback
) => ({
  type: HOSPITALITY_CATEGORY_UPDATE,
  payload: { ocId, hospitalityCategoryId, formData },
  callback
});

export const hospitalityCategoryUpdateSuccess = (data) => ({
  type: HOSPITALITY_CATEGORY_UPDATE_SUCCESS,
  payload: { data }
});

export const hospitalityCategoryUpdateFailed = (error) => ({
  type: HOSPITALITY_CATEGORY_UPDATE_FAILED,
  error
});

export const deleteHospitalityCategory = (
  ocId,
  hospitalityCategoryId,
  callback
) => ({
  type: DELETE_HOSPITALITY_CATEGORY,
  payload: { ocId, hospitalityCategoryId },
  callback
});

export const deleteHospitalityCategorySuccess = (ocId, data) => ({
  type: DELETE_HOSPITALITY_CATEGORY_SUCCESS,
  payload: { ocId, data }
});

export const deleteHospitalityCategoryFailed = (error) => ({
  type: DELETE_HOSPITALITY_CATEGORY_FAILED,
  error
});
export const getContactUs = (ocId, page) => ({
  type: GET_CONTACTUS,
  payload: { ocId, page }
});

export const getContactUsSuccess = (data, included, links, ocId) => ({
  type: GET_CONTACTUS_SUCCESS,
  payload: { data, included, links, ocId }
});

export const getContactUsFailed = (error) => ({
  type: GET_CONTACTUS_FAILED,
  error
});

export const updateHospitalityProvideAnswer = (ocId, formData, callback) => ({
  type: UPDATE_HOSPITALITY_PROVIDE_ANSWER,
  payload: {
    ocId,
    formData
  },
  callback
});

export const updateHospitalityProvideAnswerSuccess = (
  ocId,
  data,
  included
) => ({
  type: UPDATE_HOSPITALITY_PROVIDE_ANSWER_SUCCESS,
  payload: { ocId, data, included }
});

export const updateHospitalityProvideAnswerFailed = (error) => ({
  type: UPDATE_HOSPITALITY_PROVIDE_ANSWER_FAILED,
  error
});

export const updateHospitalityAllAnswers = (ocId, formData, callback) => ({
  type: UPDATE_HOSPITALITY_ALL_ANSWERS,
  payload: {
    ocId,
    formData
  },
  callback
});

export const updateHospitalityAllAnswersSuccess = (ocId, data, included) => ({
  type: UPDATE_HOSPITALITY_ALL_ANSWERS_SUCCESS,
  payload: { ocId, data, included }
});

export const updateHospitalityAllAnswersFailed = (error) => ({
  type: UPDATE_HOSPITALITY_ALL_ANSWERS_FAILED,
  error
});
