import request from './request';

export function occasionAlbumCreateApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/albums`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function occasionAlbumViewApi(ocId, page, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/albums?page=${page}`, {
    method: 'GET',
    token
  });
}

export function addMediaToAlbum(occasionId, formData, updateProgress, token) {
  return request(
    `api/v2/sociana_admin/oc/${occasionId}/media`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    },
    updateProgress
  );
}

export function getAlbumImagesApi(occasionId, mType, albumId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${occasionId}/get_image_media?mediable_type=${mType}&mediable_id=${albumId}&page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function getAlbumVideosApi(occasionId, mType, albumId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${occasionId}/get_video_media?mediable_type=${mType}&mediable_id=${albumId}&page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function deleteAlbumMediaApi(
  occasionId,
  mediaId,
  mType,
  albumId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${occasionId}/media/${mediaId}?mediable_type=${mType}&mediable_id=${albumId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function deleteAlbumApi(ocId, albumId, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/albums/${albumId}`, {
    method: 'DELETE',
    token
  });
}

export function updateAlbumApi(ocId, albumId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/albums/${albumId}`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}
