import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  createLocationTriggerApi,
  getLocationTriggerWithPaginationApi,
  createActionTriggerApi,
  getActionTriggerWithPaginationApi,
  createTimeTriggerApi,
  getTimeTriggerWithPaginationApi,
  deleteTimeTriggerApi,
  updateTimeTriggerApi,
  updateActionTriggerApi,
  deleteActionTriggerApi,
  updateLocationTriggerApi,
  deleteLocationTriggerApi,
  updateTimeTriggerIsPinnedApi,
  searchTimeTriggerByPinnedWithPaginationApi
} from '../api/trigger';
import {
  GET_LOCATION_TRIGGER,
  GET_ACTION_TRIGGER,
  CREATE_ACTION_TRIGGER,
  CREATE_LOCATION_TRIGGER,
  GET_TIME_TRIGGER,
  CREATE_TIME_TRIGGER,
  DELETE_TIME_TRIGGER,
  UPDATE_TIME_TRIGGER,
  DELETE_ACTION_TRIGGER,
  UPDATE_ACTION_TRIGGER,
  DELETE_LOCATION_TRIGGER,
  UPDATE_LOCATION_TRIGGER,
  UPDATE_TIME_TRIGGER_IS_PINNED,
  SEARCH_TIME_TRIGGER_BY_PINNED
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  createLocationTriggerFailed,
  createLocationTriggerSuccess,
  getLocationTriggerFailed,
  getLocationTriggerSuccess,
  getActionTriggerFailed,
  getActionTriggerSuccess,
  createActionTriggerFailed,
  createActionTriggerSuccess,
  getTimeTriggerSuccess,
  getTimeTriggerFailed,
  createTimeTriggerFailed,
  createTimeTriggerSuccess,
  deleteTimeTriggerSuccess,
  deleteTimeTriggerFailed,
  updateTimeTriggerFailed,
  updateTimeTriggerSuccess,
  updateActionTriggerSuccess,
  updateActionTriggerFailed,
  deleteActionTriggerSuccess,
  deleteActionTriggerFailed,
  updateLocationTriggerSuccess,
  updateLocationTriggerFailed,
  deleteLocationTriggerSuccess,
  deleteLocationTriggerFailed,
  updateTimeTriggerIsPinnedSuccess,
  updateTimeTriggerIsPinnedFailed,
  searchTimeTriggerByPinnedSuccess,
  searchTimeTriggerByPinnedFailed
} from '../actions/trigger';

export function* locationTriggerCreateSaga(action) {
  try {
    const result = yield call(
      createLocationTriggerApi,
      action.payload.occasionId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        createLocationTriggerSuccess(
          action.payload.occasionId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(createLocationTriggerFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(createLocationTriggerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* locationTriggerViewSaga(action) {
  try {
    const result = yield call(
      getLocationTriggerWithPaginationApi,
      action.payload.occasionId,
      action.payload.page,
      action.payload.modelableType,
      action.payload.modelableId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getLocationTriggerSuccess(
          action.payload.occasionId,
          result.data,
          result.included,
          result.links
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getLocationTriggerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* actionTriggerCreateSaga(action) {
  try {
    const result = yield call(
      createActionTriggerApi,
      action.payload.occasionId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        createActionTriggerSuccess(
          action.payload.occasionId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(createActionTriggerFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(createActionTriggerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* actionTriggerViewSaga(action) {
  try {
    const result = yield call(
      getActionTriggerWithPaginationApi,
      action.payload.occasionId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getActionTriggerSuccess(
          action.payload.occasionId,
          result.data,
          result.included,
          result.links
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getActionTriggerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* timeTriggerCreateSaga(action) {
  try {
    const result = yield call(
      createTimeTriggerApi,
      action.payload.occasionId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        createTimeTriggerSuccess(
          action.payload.occasionId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(createTimeTriggerFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(createTimeTriggerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* timeTriggerViewSaga(action) {
  try {
    const result = yield call(
      getTimeTriggerWithPaginationApi,
      action.payload.occasionId,
      action.payload.page,
      action.payload.modelableType,
      action.payload.modelableId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getTimeTriggerSuccess(
          action.payload.occasionId,
          result.data,
          result.included,
          result.links
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getTimeTriggerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* searchTimeTriggerByPinnedSaga(action) {
  try {
    const result = yield call(
      searchTimeTriggerByPinnedWithPaginationApi,
      action.payload.occasionId,
      action.payload.page,
      action.payload.isPinned,
      action.payload.modelableType,
      action.payload.modelableId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        searchTimeTriggerByPinnedSuccess(
          action.payload.occasionId,
          result.data,
          result.included,
          result.links
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(searchTimeTriggerByPinnedFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* timeTriggerUpdateSaga(action) {
  try {
    const result = yield call(
      updateTimeTriggerApi,
      action.payload.occasionId,
      action.payload.triggerId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateTimeTriggerSuccess(
          action.payload.occasionId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateTimeTriggerFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateTimeTriggerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* timeTriggerUpdateIsPinnedSaga(action) {
  try {
    const result = yield call(
      updateTimeTriggerIsPinnedApi,
      action.payload.occasionId,
      action.payload.triggerId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateTimeTriggerIsPinnedSuccess(
          action.payload.occasionId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateTimeTriggerIsPinnedFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateTimeTriggerIsPinnedFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* timeTriggerDeleteSaga(action) {
  try {
    const result = yield call(
      deleteTimeTriggerApi,
      action.payload.occasionId,
      action.payload.triggerId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        deleteTimeTriggerSuccess(action.payload.occasionId, result.data)
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteTimeTriggerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* actionTriggerUpdateSaga(action) {
  try {
    const result = yield call(
      updateActionTriggerApi,
      action.payload.occasionId,
      action.payload.triggerId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateActionTriggerSuccess(
          action.payload.occasionId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(updateActionTriggerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* actionTriggerDeleteSaga(action) {
  try {
    const result = yield call(
      deleteActionTriggerApi,
      action.payload.occasionId,
      action.payload.triggerId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        deleteActionTriggerSuccess(action.payload.occasionId, result.data)
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteActionTriggerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* locationTriggerUpdateSaga(action) {
  try {
    const result = yield call(
      updateLocationTriggerApi,
      action.payload.occasionId,
      action.payload.triggerId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateLocationTriggerSuccess(
          action.payload.occasionId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(updateLocationTriggerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* locationTriggerDeleteSaga(action) {
  try {
    const result = yield call(
      deleteLocationTriggerApi,
      action.payload.occasionId,
      action.payload.triggerId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        deleteLocationTriggerSuccess(action.payload.occasionId, result.data)
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteLocationTriggerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* triggerActionWatcher() {
  yield takeLatest(GET_LOCATION_TRIGGER, progressSaga, locationTriggerViewSaga);
  yield takeLatest(
    CREATE_LOCATION_TRIGGER,
    progressSaga,
    locationTriggerCreateSaga
  );
  yield takeLatest(GET_ACTION_TRIGGER, progressSaga, actionTriggerViewSaga);
  yield takeLatest(
    CREATE_ACTION_TRIGGER,
    progressSaga,
    actionTriggerCreateSaga
  );
  yield takeLatest(GET_TIME_TRIGGER, progressSaga, timeTriggerViewSaga);
  yield takeLatest(
    SEARCH_TIME_TRIGGER_BY_PINNED,
    progressSaga,
    searchTimeTriggerByPinnedSaga
  );
  yield takeLatest(CREATE_TIME_TRIGGER, progressSaga, timeTriggerCreateSaga);
  yield takeLatest(DELETE_TIME_TRIGGER, progressSaga, timeTriggerDeleteSaga);
  yield takeLatest(UPDATE_TIME_TRIGGER, progressSaga, timeTriggerUpdateSaga);
  yield takeLatest(
    UPDATE_TIME_TRIGGER_IS_PINNED,
    progressSaga,
    timeTriggerUpdateIsPinnedSaga
  );
  yield takeLatest(
    DELETE_ACTION_TRIGGER,
    progressSaga,
    actionTriggerDeleteSaga
  );
  yield takeLatest(
    UPDATE_ACTION_TRIGGER,
    progressSaga,
    actionTriggerUpdateSaga
  );
  yield takeLatest(
    DELETE_LOCATION_TRIGGER,
    progressSaga,
    locationTriggerDeleteSaga
  );
  yield takeLatest(
    UPDATE_LOCATION_TRIGGER,
    progressSaga,
    locationTriggerUpdateSaga
  );
}
