import {
  OCCASION_AWARD_CREATE,
  OCCASION_AWARD_CREATE_SUCCESS,
  OCCASION_AWARD_CREATE_FAILED,
  OCCASION_AWARD_VIEW,
  OCCASION_AWARD_VIEW_SUCCESS,
  OCCASION_AWARD_VIEW_FAILED,
  UPDATE_AWARD,
  UPDATE_AWARD_FAILED,
  UPDATE_AWARD_SUCCESS,
  DELETE_AWARD,
  DELETE_AWARD_FAILED,
  DELETE_AWARD_SUCCESS
} from '../constants/actionTypes';
export const occasionAwardCreate = (ocId, formData, callback) => ({
  type: OCCASION_AWARD_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionAwardCreateSuccess = (data, included, ocId) => ({
  type: OCCASION_AWARD_CREATE_SUCCESS,
  payload: { data, included, ocId }
});

export const occasionAwardCreateFailed = (error) => ({
  type: OCCASION_AWARD_CREATE_FAILED,
  error
});

export const occasionAwardView = (ocId, page) => ({
  type: OCCASION_AWARD_VIEW,
  payload: { ocId, page }
});

export const occasionAwardViewSuccess = (data, included, ocId, links) => ({
  type: OCCASION_AWARD_VIEW_SUCCESS,
  payload: { data, included, ocId, links }
});

export const occasionAwardViewFailed = (error) => ({
  type: OCCASION_AWARD_VIEW_FAILED,
  error
});

export const updateAward = (ocId, awardId, formData, callback) => ({
  type: UPDATE_AWARD,
  payload: { ocId, awardId, formData },
  callback
});

export const updateAwardSuccess = (ocId, data, included) => ({
  type: UPDATE_AWARD_SUCCESS,
  payload: { ocId, data, included }
});

export const updateAwardFailed = (error) => ({
  type: UPDATE_AWARD_FAILED,
  error
});

export const deleteAward = (ocId, awardId, callback) => ({
  type: DELETE_AWARD,
  payload: { ocId, awardId },
  callback
});

export const deleteAwardSuccess = (ocId, data) => ({
  type: DELETE_AWARD_SUCCESS,
  payload: { ocId, data }
});

export const deleteAwardFailed = (error) => ({
  type: DELETE_AWARD_FAILED,
  error
});
