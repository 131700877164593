import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { Form, Button } from 'antd';
import '../styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import { JOIN_AS, STATUS } from './InvitationConstants';
import { currentPendingInvitationData } from '../../../../../selectors/invitation';
import { updatePendingInvitation } from '../../../../../actions/Invitation';
function PendingInvitationEdit(props) {
  const [getInvitationDetails, setGetInvitationDetails] = React.useState({
    joinas: props.currentPendingInvitationData?.joinAs || '',
    status: props.currentPendingInvitationData?.status || ''
  });

  const handleSubmit = () => {
    try {
      const formData = new FormData();
      formData.append(
        'pending_invitation[join_as]',
        getInvitationDetails?.joinas
      );
      formData.append(
        'pending_invitation[status]',
        getInvitationDetails?.status
      );
      props.updatePendingInvitation(
        props.occasionId,
        props.pendingInvitationId,
        formData
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      const invitationDetailsValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      setGetInvitationDetails({
        ...getInvitationDetails,
        [inputName]: invitationDetailsValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="invitation-edit-section">
        <div className="invitation-edit-form">
          <Form
            className="invitation-edit-form-container"
            onFinish={handleSubmit}>
            <InputGroup
              label="Join As"
              className="invitation-inputbox"
              fieldType="dropdown"
              placeholder="Select"
              options={JOIN_AS}
              name="joinas"
              onChange={handleChange}
              value={getInvitationDetails?.joinas}
            />
            <InputGroup
              label="Status"
              className="invitation-inputbox"
              fieldType="dropdown"
              placeholder="Select"
              options={STATUS}
              name="status"
              onChange={handleChange}
              value={getInvitationDetails?.status}
            />
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText="Save Changes"
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentPendingInvitationData: currentPendingInvitationData(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updatePendingInvitation }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingInvitationEdit);
