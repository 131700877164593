import {
  CREATE_LIVE_STREAM_SUCCESS,
  CREATE_LIVE_STREAM_FAILED,
  GET_LIVE_STREAM_SUCCESS,
  GET_LIVE_STREAM_FAILED,
  SHOW_LIVE_STREAM_FAILED,
  SHOW_LIVE_STREAM_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  data: null,
  tokens: null,
  currentLiveId: null,
  error: null,
  uids: null,
  links: null
};

const live = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LIVE_STREAM_FAILED:
    case GET_LIVE_STREAM_FAILED:
    case CREATE_LIVE_STREAM_FAILED: {
      return { ...state, error: action.error };
    }

    case CREATE_LIVE_STREAM_SUCCESS: {
      const { occasionId, data, meta } = action.payload;
      const prevData = state.data || {};
      const prevLiveIds = prevData[occasionId] || [];
      const newIds = [...prevLiveIds, data.id];
      const prevTokens = state.tokens || {};
      const prevTokenOcIds = prevTokens[occasionId] || {};
      const prevUIDs = state.uids || {};
      const prevUIDOcIds = prevUIDs[occasionId] || {};
      return {
        ...state,
        data: {
          ...prevData,
          [occasionId]: newIds
        },
        tokens: {
          ...prevTokens,
          [occasionId]: {
            ...prevTokenOcIds,
            [data.id]: meta?.token || null
          }
        },
        uids: {
          ...prevUIDs,
          [occasionId]: {
            ...prevUIDOcIds,
            [data.id]: meta?.uid || null
          }
        },
        currentLiveId: data.id
      };
    }

    case GET_LIVE_STREAM_SUCCESS: {
      const { data, links, occasionId } = action.payload;
      const prevIds = state.data || {};
      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];
      return {
        ...state,
        data: {
          ...prevIds,
          [occasionId]: newIds
        },
        links
      };
    }

    default:
      return state;
  }
};

export default live;
