import DEFAULT_PROFILE_PIC from '../assets/default-profile-pic.png';
import { RandColors } from '../constants/OtherConstants';
import imageCompression from 'browser-image-compression';

export const getImageSource = (imageUrl, defaultImageUrl, isCDN) => {
  if (imageUrl) {
    if (isCDN) {
      return `https://${imageUrl}`;
    }
    return `${process.env.REACT_APP_CLIENT_URL}${imageUrl}`;
  }
  if (defaultImageUrl) {
    return defaultImageUrl;
  }
  return DEFAULT_PROFILE_PIC;
};

export const getRandomColor = () => {
  const rand = Math.floor(Math.random() * 10);
  const index = rand % RandColors.length;
  return RandColors[index];
};

export const getCompressImage = async (imageUrl, options) => {
  try {
    const { name, type } = imageUrl;
    const compressedFile = await imageCompression(imageUrl, options);
    return new File([compressedFile], name, {
      lastModified: new Date(),
      type
    });
  } catch (error) {
    console.error('Error in compressing video file', error);
    return imageUrl;
  }
};
