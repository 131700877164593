import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import Tabs from '../../../../components/Tabs';
import { getCurrentUser } from '../../../../selectors/user';
import UserDetailsView from '../../User/Details/UserDetailsView';
import { renderRoute } from '../../../../components/RouterHOC';
import { TabsHeader } from '../../../../components/TabsHeader';
import UserOccasion from './UserOccasion/index';
import ViewUserDevice from './UserDevice/UserDeviceView';
import { currentUserId } from '../../../../selectors/dynamicData';

function UserDetails(props) {
  const getOccasionComponent = () => {
    return <UserOccasion userId={props.currentUserId} />;
  };

  const getUserDeviceComponent = () => {
    return <ViewUserDevice userId={props.currentUserId} />;
  };

  const tabPanes = [
    {
      tabName: 'Details',
      tabKey: '1',
      children: <UserDetailsView userDetails={props.currentUser} />
    },
    {
      tabName: 'Occasion',
      tabKey: '2',
      children: getOccasionComponent()
    },
    {
      tabName: 'User Device',
      tabKey: '3',
      children: getUserDeviceComponent()
    }
  ];

  const goBack = () => {
    props.history.goBack();
  };

  return (
    <ErrorBoundary>
      <SectionContent className="user-tab-section">
        <TabsHeader
          name={props.currentUser.name}
          extraData={[props.currentUser.email]}
          wrapperClass="section-details"
          isActive={props.currentUser.active}
        />
        <div className="user-tab-container">
          <Tabs
            defaultActiveKey="1"
            tabPanes={tabPanes}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentUser: getCurrentUser(store, props),
  currentUserId: currentUserId(store, props)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default renderRoute(getCurrentUser)(
  connect(mapStateToProps, mapDispatchToProps)(UserDetails)
);
