import request from './request';

export function getGalleriesInfo(occasionId, token) {
  return request(`api/v2/sociana_admin/oc/${occasionId}/gallaries`, {
    method: 'GET',
    token
  });
}

export function getGalleryImages(occasionId, uuid, mType, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${occasionId}/get_image_media?mediable_type=${mType}&mediable_id=${uuid}&page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function getGalleryVideos(occasionId, uuid, mType, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${occasionId}/get_video_media?mediable_type=${mType}&mediable_id=${uuid}&page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function addMediaToGalleries(
  occasionId,
  formData,
  updateProgress,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${occasionId}/media`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    },
    updateProgress
  );
}

export function deleteMediaApi(occasionId, uuid, mediaId, mType, token) {
  return request(
    `api/v2/sociana_admin/oc/${occasionId}/media/${mediaId}?mediable_type=${mType}&mediable_id=${uuid}`,
    {
      method: 'DELETE',
      token
    }
  );
}
