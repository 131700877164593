import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { SectionContent } from '../../../../../../../components/Section';
import ErrorBoundary from '../../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../../components/Table';
import {
  getAllUnregisteredPurchaseData,
  getAllUnregisteredPurchaseLinks
} from '../../../../../../../selectors/purchases';
import {
  getUnregisteredTicketPurchases,
  unregisteredTicketPurchasesSearch
} from '../../../../../../../actions/purchases';
import InputGroup from '../../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../../components/InputGroup/FormComponents';
import Form from 'antd/lib/form/Form';
import { Button, AutoComplete } from 'antd';
import { getAllOccasionTicketVariants } from '../../../../../../../selectors/ticketVariant';
import { occasionTicketVariantView } from '../../../../../../../actions/ticketVariant';

const { Option } = AutoComplete;

const UnregisteredTicketPurchases = (props) => {
  const [
    getUnregisteredTicketPurchasesDetails,
    setGetUnregisteredTicketPurchasesDetails
  ] = React.useState({});
  const [errorMessages, setErrorMessages] = React.useState({});
  const onPageChange = (page) => {
    if (props.tabIndex === '2.3') {
      props.getUnregisteredTicketPurchases(
        props.occasionId,
        props.ticketId,
        page
      );
    }
  };

  React.useEffect(() => {
    onPageChange(1);
  }, [props?.tabIndex]);

  const fetchTicketVariants = (page) => {
    props.occasionTicketVariantView(props.occasionId, props.ticketId, page);
  };

  React.useEffect(() => {
    fetchTicketVariants(1);
  }, []);

  const validate = (values) => {
    const errors = {};
    if (!values.ticketVariant) errors.type = 'Ticket Variant is required';

    return errors;
  };

  const handleDetails = (page) => {
    try {
      const validateObj = validate(getUnregisteredTicketPurchasesDetails);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        props.unregisteredTicketPurchasesSearch(
          props.occasionId,
          props.ticketId,
          getUnregisteredTicketPurchasesDetails.ticketVariant,
          getUnregisteredTicketPurchasesDetails.searchData,
          (page = 1)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'searchData') {
        value = e.target.value;
        name = type;
      }
      const unregisteredTicketPurchasesDetailsValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      setGetUnregisteredTicketPurchasesDetails({
        ...getUnregisteredTicketPurchasesDetails,
        [inputName]: unregisteredTicketPurchasesDetailsValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleReset = (page) => {
    setGetUnregisteredTicketPurchasesDetails({});
    setErrorMessages({});
    props.getUnregisteredTicketPurchases(
      props.occasionId,
      props.ticketId,
      (page = 1)
    );
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height === scrollHeight && ticketVariantLinks.next) {
      fetchTicketVariants(page + 1);
      setPage((val) => val + 1);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="ticket-purchase-view-section">
        <div className="ticket-purchase-view-container">
          <Form
            className="ticket-purchase-search-container"
            onFinish={handleDetails}>
            <InputGroup
              label=" Phone / Email"
              className="ticket-purchase-inputbox"
              fieldType="searchbox"
              placeholder="Enter Phone / Email"
              name="searchData"
              onChange={handleChange}
              value={getUnregisteredTicketPurchasesDetails?.searchData}
            />
            <InputGroup
              fieldType="dropdown"
              className="ticket-purchase-inputbox"
              mode="single"
              name="ticketVariant"
              label="Select Ticket Variant"
              placeholder="Select Ticket variant"
              onChange={handleChange}
              onPopupScroll={handleScroll}
              value={getUnregisteredTicketPurchasesDetails?.ticketVariant}
              errorMessage={errorMessages.type}>
              {props.ticketVariantData?.length > 0 &&
                props.ticketVariantData.map((item) => {
                  return (
                    <Option value={item?.id} key={item?.id}>
                      <div className="options-container">
                        <div className="ticket-purchase-details-container">
                          <p className="ticket-purchase-name">{item?.title}</p>
                        </div>
                      </div>
                    </Option>
                  );
                })}
            </InputGroup>

            <div style={{ flex: 1, display: 'flex' }}>
              <SubmitButton
                wrapperClass="ticket-purchase-submit-wrapper"
                className="ticket-purchase-submit-inputbox"
                buttonText="Search"
                fetchProgress={props.fetchProgress}
                disabled={props.fetchProgress}
              />
              <Button className="reset-button" onClick={handleReset}>
                Reset
              </Button>
            </div>
          </Form>
          <TableView
            onPageChange={onPageChange}
            isRouted={false}
            data={props.purchases}
            fetchProgress={props.fetchProgress}
            paginationLinks={props.purchaseLinks}
            blacklistKeys={['ticketVariant']}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  purchases: getAllUnregisteredPurchaseData(store, props),
  purchaseLinks: getAllUnregisteredPurchaseLinks(store, props),
  ticketVariantData: getAllOccasionTicketVariants(store, props),
  ticketVariantLinks: store.ticketVariant.ticketVariantLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    {
      getUnregisteredTicketPurchases,
      occasionTicketVariantView,
      unregisteredTicketPurchasesSearch
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(UnregisteredTicketPurchases);
