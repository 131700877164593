import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  currentLiveVideoId,
  currentOccasionId
} from '../../../../../../selectors/dynamicData';
import { showLive } from '../../../../../../actions/live';
import { getLiveShowData } from '../../../../../../selectors/live';
import { useAgora } from '../../../../../../hooks/useAgora';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import './styles.scss';

const Show = (props) => {
  const container = useRef(null);
  const token = useRef(null);
  const uid = useRef(null);
  const {
    stopVideo,
    playLiveVideo,
    setAgoraClient,
    loading,
    error,
    streamState,
    hasHostLeft
  } = useAgora({
    role: 'audience',
    container
  });

  const showLiveStream = () => {
    setTimeout(() => {
      props.showLive(
        props.occasionId,
        props.liveVideoId,
        async (userToken, uId) => {
          token.current = userToken;
          uid.current = uId;
          await setClient();
        }
      );
    }, 100);
  };

  useEffect(() => {
    showLiveStream();
    return stopVideo;
  }, []);

  const setClient = async () => {
    await setAgoraClient({
      appId: process.env.REACT_AGORA_APP_ID,
      token: token.current,
      channel: props.live?.title,
      uid: uid.current
    });
    playLiveVideo();
    hasHostLeft();
  };

  if (loading) {
    return (
      <div className="show-video-container black-background">
        <LoadingOutlined className="loader-icon" />
        <p className="text-box">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="show-video-container black-background column">
        <p className="text-box">Something went wrong!</p>
        <p className="text-box">
          Retry <ReloadOutlined onClick={showLiveStream} />
        </p>
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <div className="show-video-container">
        <div
          ref={container}
          className="video-player"
          style={{ display: streamState === 1 ? 'flex' : 'none' }}
        />
        <p
          className="text-box"
          style={{ display: streamState === 0 ? 'flex' : 'none' }}>
          Host hasn't started the live stream yet. Please wait.
        </p>
        <p
          className="text-box"
          style={{ display: streamState === 2 ? 'flex' : 'none' }}>
          The live stream has ended.
        </p>
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => ({
  occasionId: currentOccasionId(store, props),
  liveVideoId: currentLiveVideoId(store, props),
  live: getLiveShowData(store, props)
});

const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ showLive }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(Show);
