import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import Tabs from '../../../../../components/Tabs';
import { getCurrentMemberWithUserData } from '../../../../../selectors/members';
import MemberDetailsView from './MemberDetails';
import {
  currentCollaboratorId,
  currentOccasionId
} from '../../../../../selectors/dynamicData';
import GuestView from './GuestView';
import { TabsHeader } from '../../../../../components/TabsHeader/index';
import { getCurrentOccasion } from '../../../../../selectors/occasion';

function MemberDetails(props) {
  const getGuestsComponent = () => {
    return (
      <GuestView
        occasionId={props.currectOccasionId}
        userId={props.currentMember.userId}
      />
    );
  };
  const tabPanes = [
    {
      tabName: 'Details',
      tabKey: '1',
      children: <MemberDetailsView memberData={props.currentMember} />
    },
    {
      tabName: 'Guests',
      tabKey: '2',
      children: getGuestsComponent()
    }
  ];
  const goBack = () => {
    props.history.goBack();
  };

  return (
    <ErrorBoundary>
      <SectionContent className="occasion-member-tab-section">
        <TabsHeader
          name={props.currentOccasion.name}
          extraData={[
            props.currentOccasion.type,
            props.currentOccasion.guestType,
            'Member',
            props.currentMember.name
          ]}
          wrapperClass="section-details"
        />
        <div className="occasion-member-tab-container">
          <Tabs
            defaultActiveKey="1"
            tabPanes={tabPanes}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentMember: getCurrentMemberWithUserData(store, props),
  currentMemberId: currentCollaboratorId(store, props),
  currentOccasion: getCurrentOccasion(store, props),
  currectOccasionId: currentOccasionId(store, props)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MemberDetails);
