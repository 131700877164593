import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../../components/Section';
import ErrorBoundary from '../../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../../components/Table';
import { Button } from 'antd';
import EventItineraryCreate from './ItineraryCreate';
import { occasionItineraryWithUserData } from '../../../../../../../selectors/itinerary';
import {
  occasionItineraryView,
  occasionItineraryDelete
} from '../../../../../../../actions/itinerary';

const EventItineraryView = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [itineraryId, setItineraryId] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.occasionItineraryView(
      props.occasionId,
      props.scheduleId,
      'Event',
      props.eventId,
      page
    );
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleCreate = () => {
    setItineraryId('');
    setCreateOpen(true);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={handleCreate}>
          Create
        </Button>
      </div>
    )
  };

  const editData = (id) => {
    setCreateOpen(true);
    setItineraryId(id);
  };

  const deleteData = (id) => {
    props.occasionItineraryDelete(
      props.occasionId,
      props.scheduleId,
      id,
      'Event',
      props.eventId
    );
  };

  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="event-itinerary-view-section">
          <div className="event-itinerary-view-container">
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              isRouted={false}
              onPageChange={onPageChange}
              onDelete={deleteData}
              isEditable={editData}
              data={props.itineraryData}
              fetchProgress={props.fetchProgress}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <EventItineraryCreate
        itineraryId={itineraryId}
        occasionId={props.occasionId}
        scheduleId={props.scheduleId}
        eventId={props.eventId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  itineraryData: occasionItineraryWithUserData(store, props)
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { occasionItineraryView, occasionItineraryDelete },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(EventItineraryView);
