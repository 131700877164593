import { union } from 'lodash';
import {
  OCCASION_GROUP_VIEW_SUCCESS,
  OCCASION_GROUP_VIEW_FAILED,
  OCCASION_GROUP_CREATE_SUCCESS,
  OCCASION_GROUP_CREATE_FAILED,
  GET_GROUP_MEMBERS_FAILED,
  GET_GROUP_MEMBERS_SUCCESS,
  CREATE_GROUP_MEMBERS_SUCCESS,
  CREATE_GROUP_MEMBERS_FAILED,
  GET_GROUP_OCCASION_USERS_SUCCESS,
  GET_GROUP_OCCASION_USERS_FAILED,
  SEARCH_GROUP_OCCASION_USERS_SUCCESS,
  SEARCH_GROUP_OCCASION_USERS_FAILED,
  DELETE_GROUP_MEMBERS_SUCCESS,
  DELETE_OCCASION_GROUP_SUCCESS,
  DELETE_OCCASION_GROUP_FAILED,
  SEARCH_GROUP_MEMBER_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  members: null,
  occasionGroupTabData: null,
  allGroupData: null,
  error: null,
  groupLinks: null,
  memberLinks: null,
  users: null,
  userLinks: null,
  searchedUsers: null,
  searchedUsersLinks: null,
  groupMemberCount: 0
};

const group = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_GROUP_VIEW_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.occasionGroupTabData || {};
      const AllGroupedData = state.allGroupData || {};
      const allData = AllGroupedData?.[ocId] || [];
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        allGroupData: {
          ...AllGroupedData,
          [ocId]: union([...allData, ...currentOccasionNewIds])
        },
        occasionGroupTabData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        groupLinks: links
      };
    }

    case OCCASION_GROUP_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionGroupTabData || {};
      const currentOccasionGroupIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionGroupIds];
      return {
        ...state,
        occasionGroupTabData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }

    case GET_GROUP_MEMBERS_SUCCESS:
    case SEARCH_GROUP_MEMBER_SUCCESS: {
      const { data, ocId, links, groupId, meta } = action.payload;
      const prevMembersData = state.members || {};
      const prevMembersOccasionData = prevMembersData[ocId] || {};
      const membersData = data?.map((el) => el.id);

      const prevMemberLinks = state.memberLinks || {};
      const prevMemberLinksOccasion = prevMemberLinks[ocId] || {};
      return {
        ...state,
        members: {
          ...prevMembersData,
          [ocId]: {
            ...prevMembersOccasionData,
            [groupId]: membersData
          }
        },
        memberLinks: {
          ...prevMemberLinks,
          [ocId]: {
            ...prevMemberLinksOccasion,
            [groupId]: links
          }
        },
        groupMemberCount: meta?.totalCount || 0
      };
    }

    case CREATE_GROUP_MEMBERS_SUCCESS: {
      const { data, ocId, groupId } = action.payload;

      const prevMembersData = state.members || {};
      const prevMembersOccasionData = prevMembersData[ocId] || {};
      const prevMembersGroupData = prevMembersOccasionData[groupId] || [];
      const ids = data.map((el) => el.id);
      const newMemberIds = union(ids, prevMembersGroupData);
      return {
        ...state,
        members: {
          ...prevMembersData,
          [ocId]: {
            ...prevMembersOccasionData,
            [groupId]: newMemberIds
          }
        }
      };
    }

    case GET_GROUP_OCCASION_USERS_SUCCESS: {
      const { data, ocId, links, groupId, page } = action.payload;
      const prevUsersData = state.users || {};
      const prevUsersOccasionData = prevUsersData[ocId] || {};
      const prevUsersGroupData = prevUsersOccasionData[groupId] || [];
      const dataIds = data.map((el) => el.id);
      const newUserIds =
        page === 1 ? dataIds : union(prevUsersGroupData, dataIds);

      const prevUserLinks = state.userLinks || {};
      const prevUserLinksOccasion = prevUserLinks[ocId] || {};
      return {
        ...state,
        users: {
          ...prevUsersData,
          [ocId]: {
            ...prevUsersOccasionData,
            [groupId]: newUserIds
          }
        },
        userLinks: {
          ...prevUserLinks,
          [ocId]: {
            ...prevUserLinksOccasion,
            [groupId]: links
          }
        }
      };
    }

    case SEARCH_GROUP_OCCASION_USERS_SUCCESS: {
      const { data, ocId, links, groupId, page } = action.payload;
      const prevSearchedUsersData = state.searchedUsers || {};
      const prevSearchedUsersOccasionData = prevSearchedUsersData[ocId] || {};
      const prevSearchedUsersGroupData =
        prevSearchedUsersOccasionData[groupId] || [];
      const dataIds = data.map((el) => el.id);
      const newUserIds =
        page !== 1 ? union(prevSearchedUsersGroupData, dataIds) : dataIds;

      const prevSearchedUserLinks = state.searchedUsersLinks || {};
      const prevSearchedUserLinksOccasion = prevSearchedUserLinks[ocId] || {};
      return {
        ...state,
        searchedUsers: {
          ...prevSearchedUsersData,
          [ocId]: {
            ...prevSearchedUsersOccasionData,
            [groupId]: newUserIds
          }
        },
        searchedUsersLinks: {
          ...prevSearchedUserLinks,
          [ocId]: {
            ...prevSearchedUserLinksOccasion,
            [groupId]: links
          }
        }
      };
    }

    case DELETE_GROUP_MEMBERS_SUCCESS: {
      const { ocId, groupId, data } = action.payload;
      const prevMembersData = state.members || {};
      const prevMembersOccasionData = prevMembersData[ocId] || {};
      const filterData = prevMembersOccasionData[groupId].filter(
        (id) => id != data.id
      );
      return {
        ...state,
        members: {
          ...prevMembersData,
          [ocId]: { ...prevMembersOccasionData, [groupId]: filterData }
        }
      };
    }

    case DELETE_OCCASION_GROUP_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionGroupTabData || {};
      const currentOccasionGroupIds = occasionGroupedData[ocId] || [];
      const AllGroupedData = state.allGroupData || {};
      const allData = AllGroupedData?.[ocId] || [];
      const filteredIds = currentOccasionGroupIds.filter(
        (id) => id !== data.id
      );
      const filteredIdsForAllData = allData.filter((id) => id !== data.id);
      return {
        ...state,
        allGroupData: {
          ...AllGroupedData,
          [ocId]: union(filteredIdsForAllData)
        },
        occasionGroupTabData: {
          ...occasionGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case SEARCH_GROUP_OCCASION_USERS_FAILED:
    case GET_GROUP_OCCASION_USERS_FAILED:
    case CREATE_GROUP_MEMBERS_FAILED:
    case GET_GROUP_MEMBERS_FAILED:
    case OCCASION_GROUP_VIEW_FAILED:
    case DELETE_OCCASION_GROUP_FAILED:
    case OCCASION_GROUP_CREATE_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default group;
