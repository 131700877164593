import request from './request';

export function hardLogoutCreateApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/hard_logouts`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function hardLogoutViewApi(ocId, page, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/hard_logouts?page=${page}`, {
    method: 'GET',
    token
  });
}

export function updateHardLogoutApi(ocId, hardLogoutId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hard_logouts/${hardLogoutId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function hardLogoutUserViewApi(ocId, hardLogoutId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hard_logouts/${hardLogoutId}/user_hard_logouts?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function hardLogoutUserDeleteApi(
  ocId,
  hardLogoutId,
  hardLogoutUserId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hard_logouts/${hardLogoutId}/user_hard_logouts/${hardLogoutUserId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function hardLogoutAllUserDeleteApi(ocId, hardLogoutId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hard_logouts/${hardLogoutId}/delete_all_user_hard_logouts`,
    {
      method: 'DELETE',
      token
    }
  );
}
