import { union } from 'lodash';
import {
  OCCASION_CATEGORY_STATUS_VIEW_SUCCESS,
  OCCASION_CATEGORY_STATUS_CREATE_SUCCESS,
  DELETE_CATEGORY_STATUS_SUCCESS,
  OCCASION_CATEGORY_STATUS_CREATE_FAILED,
  OCCASION_CATEGORY_STATUS_VIEW_FAILED,
  DELETE_CATEGORY_STATUS_FAILED
} from '../constants/actionTypes';

const initialState = {
  categoryStatusIds: null,
  categoryStatusLinks: null,
  error: null
};

const categoryStatus = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_CATEGORY_STATUS_VIEW_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.categoryStatusIds || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        categoryStatusIds: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        categoryStatusLinks: links
      };
    }

    case OCCASION_CATEGORY_STATUS_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.categoryStatusIds || {};
      const currentCategoryStatusIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentCategoryStatusIds];
      return {
        ...state,
        categoryStatusIds: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }

    case DELETE_CATEGORY_STATUS_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.categoryStatusIds || {};
      const currentCategoryStatusIds = occasionGroupedData[ocId] || [];
      const filteredIds = currentCategoryStatusIds.filter(
        (id) => id !== data.id
      );

      return {
        ...state,
        categoryStatusIds: {
          ...occasionGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case OCCASION_CATEGORY_STATUS_CREATE_FAILED:
    case OCCASION_CATEGORY_STATUS_VIEW_FAILED:
    case DELETE_CATEGORY_STATUS_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default categoryStatus;
