import { createSelector } from 'reselect';
import { userEntities, occasionSosEntities } from './entity';
import { occasionSosIds } from './static';
import { currentOccasionId, currentSosId } from './dynamicData';

export const currentSosIds = createSelector(
  currentOccasionId,
  occasionSosIds,
  (hcId, ids) => hcId && ids && ids[hcId]
);

export const getAllSosData = createSelector(
  occasionSosEntities,
  currentSosIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const occasionSosWithUserData = createSelector(
  getAllSosData,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      createdBy: { ...entities[item?.createdBy?.id] }
    }))
);

export const currentSosData = createSelector(
  currentSosId,
  occasionSosEntities,
  (id, entities) => id && entities && entities[id]
);
