import { union } from 'lodash';
import {
  GUEST_VIEW_SUCCESS,
  GUEST_VIEW_FAILED
} from '../constants/actionTypes';

const initialState = {
  memberGuestData: null,
  error: null,
  guestLinks: null
};
const guest = (state = initialState, action) => {
  switch (action.type) {
    case GUEST_VIEW_SUCCESS: {
      const { data, userId, links } = action.payload;
      const memberGroupedData = state.memberGuestData || {};
      const currentGuestNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        memberGuestData: {
          ...memberGroupedData,
          [userId]: [...currentGuestNewIds]
        },
        guestLinks: links
      };
    }

    case GUEST_VIEW_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};
export default guest;
