import request from './request';
export function forgotPasswordApi(formData, token) {
  return request('api/v2/sociana_admin/reset_password', {
    method: 'POST',
    body: formData,
    token
  });
}

export function resetPasswordApi(formData, token) {
  return request('api/v2/sociana_admin/update_password', {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}
