import request from './request';

export function guestViewApi(ocId, userId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/hospitality/hospitality_guests?user_id=${userId}&page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}
