import {
  OCCASION_POLL_POST_CREATE,
  OCCASION_POLL_POST_CREATE_FAILED,
  OCCASION_POLL_POST_CREATE_SUCCESS,
  OCCASION_POLL_POST_VIEW,
  OCCASION_POLL_POST_VIEW_FAILED,
  OCCASION_POLL_POST_VIEW_SUCCESS,
  UPDATE_POLL_POST,
  UPDATE_POLL_POST_FAILED,
  UPDATE_POLL_POST_SUCCESS,
  DELETE_POLL_POST,
  DELETE_POLL_POST_FAILED,
  DELETE_POLL_POST_SUCCESS,
  DOWNLOAD_POLL_POST_BY_CSV,
  DOWNLOAD_POLL_POST_BY_CSV_FAILED,
  DOWNLOAD_POLL_POST_BY_CSV_SUCCESS,
  OCCASION_POLL_POST_VIEW_BY_TYPE,
  OCCASION_POLL_POST_VIEW_BY_TYPE_FAILED,
  OCCASION_POLL_POST_VIEW_BY_TYPE_SUCCESS,
  DELETE_POLL_POST_BY_TYPE,
  DELETE_POLL_POST_BY_TYPE_FAILED,
  DELETE_POLL_POST_BY_TYPE_SUCCESS
} from '../constants/actionTypes';

export const occasionPollPostViewByType = (ocId, type, page) => ({
  type: OCCASION_POLL_POST_VIEW_BY_TYPE,
  payload: { ocId, type, page }
});

export const occasionPollPostViewByTypeSuccess = (
  ocId,
  type,
  data,
  included,
  links
) => ({
  type: OCCASION_POLL_POST_VIEW_BY_TYPE_SUCCESS,
  payload: { ocId, type, data, included, links }
});

export const occasionPollPostViewByTypeFailed = (error) => ({
  type: OCCASION_POLL_POST_VIEW_BY_TYPE_FAILED,
  error
});

export const deletePollPostByType = (ocId, pollPostId, type, callback) => ({
  type: DELETE_POLL_POST_BY_TYPE,
  payload: { ocId, pollPostId, type },
  callback
});

export const deletePollPostByTypeSuccess = (ocId, type, data) => ({
  type: DELETE_POLL_POST_BY_TYPE_SUCCESS,
  payload: { ocId, type, data }
});

export const deletePollPostByTypeFailed = (error) => ({
  type: DELETE_POLL_POST_BY_TYPE_FAILED,
  error
});

export const occasionPollPostCreate = (ocId, formData, callback) => ({
  type: OCCASION_POLL_POST_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionPollPostCreateSuccess = (data, ocId, included, links) => ({
  type: OCCASION_POLL_POST_CREATE_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionPollPostCreateFailed = (error) => ({
  type: OCCASION_POLL_POST_CREATE_FAILED,
  error
});

export const occasionPollPostView = (ocId, page) => ({
  type: OCCASION_POLL_POST_VIEW,
  payload: { ocId, page }
});

export const occasionPollPostViewSuccess = (data, ocId, included, links) => ({
  type: OCCASION_POLL_POST_VIEW_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionPollPostViewFailed = (error) => ({
  type: OCCASION_POLL_POST_VIEW_FAILED,
  error
});

export const updatePollPost = (ocId, pollPostId, formData, callback) => ({
  type: UPDATE_POLL_POST,
  payload: { ocId, pollPostId, formData },
  callback
});

export const updatePollPostSuccess = (ocId, data, included) => ({
  type: UPDATE_POLL_POST_SUCCESS,
  payload: { ocId, data, included }
});

export const updatePollPostFailed = (error) => ({
  type: UPDATE_POLL_POST_FAILED,
  error
});

export const deletePollPost = (ocId, pollPostId, callback) => ({
  type: DELETE_POLL_POST,
  payload: { ocId, pollPostId },
  callback
});

export const deletePollPostSuccess = (ocId, data) => ({
  type: DELETE_POLL_POST_SUCCESS,
  payload: { ocId, data }
});

export const deletePollPostFailed = (error) => ({
  type: DELETE_POLL_POST_FAILED,
  error
});

export const downloadPollPostByCsv = (ocId, pollPostId, callback) => {
  return {
    type: DOWNLOAD_POLL_POST_BY_CSV,
    payload: { ocId, pollPostId },
    callback
  };
};

export const downloadPollPostByCsvSuccess = (data, included) => {
  return {
    type: DOWNLOAD_POLL_POST_BY_CSV_SUCCESS,
    payload: { data, included }
  };
};

export const downloadPollPostByCsvFailed = (error) => {
  return {
    type: DOWNLOAD_POLL_POST_BY_CSV_FAILED,
    error
  };
};
