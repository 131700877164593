import { createSelector } from 'reselect';
import { currentOccasionId } from './dynamicData';
import { hospitalityGoogleSheetEntities } from './entity';
import { ticketGoogleSheetIds } from './static';

export const currentTicketGoogleSheetIds = createSelector(
  currentOccasionId,
  ticketGoogleSheetIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getTicketGoogleSheetData = createSelector(
  hospitalityGoogleSheetEntities,
  currentTicketGoogleSheetIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
