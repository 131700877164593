import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { memberCreateByCsv } from '../../../../../../actions/members';

const validateData = (values) => {
  const error = {};
  if (!values.csv) {
    error.csv = 'Csv file is required';
  }
  return error;
};

function CreateMemberByCsv(props) {
  const [memberData, setMemberData] = React.useState({
    csv: null
  });
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleSubmit = () => {
    try {
      const validateObj = validateData(memberData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('csv_file', memberData.csv[0]?.originFileObj);
        props.memberCreateByCsv(props.occasionId, formData, () => {
          setMemberData({});
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'csv') {
        value = e.fileList;
        name = type;
      }
      const memberValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setMemberData({
        ...memberData,
        [inputName]: memberValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="member-create-by-csv-section">
        <div className="member-create-by-csv-form-container">
          <Form className="member-create-by-csv-form" onFinish={handleSubmit}>
            <InputGroup
              fieldType="file"
              accept=".csv"
              name="csv"
              label="CSV file"
              fileList={memberData?.csv}
              onChange={handleInputChange}
              errorMessage={errorMessages?.csv}
            />
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText="Create"
            />
          </Form>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ memberCreateByCsv }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateMemberByCsv);
