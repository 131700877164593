import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Button } from 'antd';
import './styles.scss';
import { CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { PARENT_TAB_KEY, STATUS, TAB_KEY } from '../Constants';
import { currentOccasionTabSettingData } from '../../../../../../selectors/occasionTabSetting';
import {
  occasionTabSettingUpdate,
  occasionTabSettingCreate
} from '../../../../../../actions/occasionTabSetting';
import { RequiredMark } from '../../../../../../components/RequiredMark';

const OccasionTabSettingCreate = (props) => {
  const [occasionTabSettingData, setOccasionTabSettingData] = useState({
    tabName: props.currentOccasionTabSettingData?.tabName || '',
    parentTabKey:
      props.currentOccasionTabSettingData?.parentTabKey ||
      PARENT_TAB_KEY[0].value,
    status: props.currentOccasionTabSettingData?.status || '',
    tabKey: props.currentOccasionTabSettingData?.tabKey || '',
    priority: props.currentOccasionTabSettingData?.priority || ''
  });

  const handleSubmit = () => {
    try {
      const formData = new FormData();
      formData.append(
        'occasion_tab_setting[tab_name]',
        occasionTabSettingData?.tabName
      );

      formData.append(
        'occasion_tab_setting[parent_tab_key]',
        occasionTabSettingData?.parentTabKey
      );

      formData.append(
        'occasion_tab_setting[priority]',
        occasionTabSettingData?.priority ? occasionTabSettingData.priority : '1'
      );

      if (!props.occasionTabSettingId) {
        formData.append(
          'occasion_tab_setting[tab_key]',
          occasionTabSettingData?.tabKey
        );
      }
      formData.append(
        'occasion_tab_setting[status]',
        occasionTabSettingData?.status
      );

      props.occasionTabSettingId
        ? props.occasionTabSettingUpdate(
            props.occasionId,
            props.occasionTabSettingId,
            formData
          )
        : props.occasionTabSettingCreate(props.occasionId, formData, () => {
            setOccasionTabSettingData({});
          });
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let formValue;
      let inputName;

      formValue = value || e?.target?.value;
      inputName = name || e?.target?.name;

      setOccasionTabSettingData({
        ...occasionTabSettingData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="occasion-tab-setting-create-section">
        <div className="occasion-tab-setting-create-form">
          <Form
            className="occassion-tab-setting-form-container"
            onFinish={handleSubmit}>
            <InputGroup
              label="Tab Key"
              className="parentTabKey-inputbox"
              fieldType="dropdown"
              placeholder="Select"
              options={TAB_KEY}
              name="tabKey"
              disabled={!!props.occasionTabSettingId}
              onChange={handleInputChange}
              value={occasionTabSettingData?.tabKey}
            />
            <InputGroup
              label="Parent Tab Key"
              className="parentTabKey-inputbox"
              fieldType="dropdown"
              placeholder="Select"
              options={PARENT_TAB_KEY}
              name="parentTabKey"
              onChange={handleInputChange}
              value={occasionTabSettingData?.parentTabKey}
            />

            <div style={{ position: 'relative' }}>
              <RequiredMark left="50px" />
              <InputGroup
                label="Status"
                className="parentTabKey-inputbox"
                fieldType="dropdown"
                placeholder="Select"
                options={STATUS}
                name="status"
                onChange={handleInputChange}
                value={occasionTabSettingData?.status}
              />
            </div>

            <InputGroup
              name="tabName"
              placeholder=" Enter Tab Name"
              label="Tab Name"
              value={occasionTabSettingData?.tabName}
              onChange={handleInputChange}
            />

            <InputGroup
              label="Priority"
              type="Number"
              name="priority"
              placeholder="Enter Priority"
              onChange={handleInputChange}
              value={occasionTabSettingData?.priority}
            />

            <SubmitButton
              buttonText={
                props.occasionTabSettingId ? 'Save Changes' : 'Create'
              }
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentOccasionTabSettingData: currentOccasionTabSettingData(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { occasionTabSettingCreate, occasionTabSettingUpdate },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OccasionTabSettingCreate);
