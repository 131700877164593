import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import { SectionContent } from '../../../../../../components/Section';
import { getLive } from '../../../../../../actions/live';
import { currentOccasionId } from '../../../../../../selectors/dynamicData';
import {
  getAllLive,
  getAllLiveWithUsers
} from '../../../../../../selectors/live';
import './styles.scss';
import { Button } from 'antd';
import CreateLive from '../Create/index';

const LiveStreamView = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.getLive(props.occasionId, page);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const paginationOptions = {
    showTotal: () => (
      <div className="live-stream-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={() => setCreateOpen(true)}>
          Create
        </Button>
      </div>
    )
  };
  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="live-stream-view-section">
          <div className="live-stream-view-container">
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              fetchProgress={props.fetchProgress}
              onPageChange={onPageChange}
              data={props.allLive}
              isRouted={true}
              pathname={'/stream_live'}
              useLink={true}
              occasionId={props.occasionId}
              paginationLinks={props.liveLinks}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <CreateLive
        occasionId={props.occasionId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  occasionId: currentOccasionId(store, props),
  allLive: getAllLiveWithUsers(store, props),
  liveLinks: store.live.links
});

const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ getLive }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(LiveStreamView);
