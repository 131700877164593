import request from './request';

export function occasionLiveVideoCreateApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/live_video_urls`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function occasionLiveVideoViewApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/live_video_urls?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function occasionLiveVideoDeleteApi(ocId, videoId, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/live_video_urls/${videoId}`, {
    method: 'DELETE',
    token
  });
}
