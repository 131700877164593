export const JOIN_AS = [
  { label: 'Visitor', value: 'Visitor' },
  { label: 'Guest', value: 'Guest' }
];

export const STATUS = [
  { label: 'No response', value: 'no_response' },
  { label: 'Accepted', value: 'accepted' },
  { label: 'Rejected ', value: 'rejected ' }
];
