import request from './request';

export function getTicketGoogleSheetApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/get_occasion_google_sheet_by_model_type?page=${page}&model_type=ticket_purchases`,
    {
      method: 'GET',
      token
    }
  );
}

export function ticketGoogleSheetCreateApi(ocId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/ticket/create_ticket_purchases_google_sheet`,
    {
      method: 'POST',
      token
    }
  );
}

export function deleteTicketGoogleSheetApi(ocId, ticketGoogleSheetId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/occasion_google_sheets/${ticketGoogleSheetId}`,
    {
      method: 'DELETE',
      token
    }
  );
}
