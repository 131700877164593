import {
  GET_LOCATION_TRIGGER,
  GET_LOCATION_TRIGGER_SUCCESS,
  GET_LOCATION_TRIGGER_FAILED,
  CREATE_LOCATION_TRIGGER,
  CREATE_LOCATION_TRIGGER_FAILED,
  CREATE_LOCATION_TRIGGER_SUCCESS,
  GET_ACTION_TRIGGER,
  GET_ACTION_TRIGGER_FAILED,
  GET_ACTION_TRIGGER_SUCCESS,
  CREATE_ACTION_TRIGGER,
  CREATE_ACTION_TRIGGER_FAILED,
  CREATE_ACTION_TRIGGER_SUCCESS,
  CREATE_TIME_TRIGGER,
  CREATE_TIME_TRIGGER_FAILED,
  CREATE_TIME_TRIGGER_SUCCESS,
  GET_TIME_TRIGGER,
  GET_TIME_TRIGGER_FAILED,
  GET_TIME_TRIGGER_SUCCESS,
  UPDATE_TIME_TRIGGER,
  UPDATE_TIME_TRIGGER_FAILED,
  UPDATE_TIME_TRIGGER_SUCCESS,
  DELETE_TIME_TRIGGER,
  DELETE_TIME_TRIGGER_FAILED,
  DELETE_TIME_TRIGGER_SUCCESS,
  UPDATE_LOCATION_TRIGGER,
  UPDATE_LOCATION_TRIGGER_SUCCESS,
  UPDATE_LOCATION_TRIGGER_FAILED,
  DELETE_LOCATION_TRIGGER,
  DELETE_LOCATION_TRIGGER_SUCCESS,
  DELETE_LOCATION_TRIGGER_FAILED,
  UPDATE_ACTION_TRIGGER,
  UPDATE_ACTION_TRIGGER_SUCCESS,
  UPDATE_ACTION_TRIGGER_FAILED,
  DELETE_ACTION_TRIGGER,
  DELETE_ACTION_TRIGGER_SUCCESS,
  DELETE_ACTION_TRIGGER_FAILED,
  UPDATE_TIME_TRIGGER_IS_PINNED,
  UPDATE_TIME_TRIGGER_IS_PINNED_FAILED,
  UPDATE_TIME_TRIGGER_IS_PINNED_SUCCESS,
  SEARCH_TIME_TRIGGER_BY_PINNED,
  SEARCH_TIME_TRIGGER_BY_PINNED_FAILED,
  SEARCH_TIME_TRIGGER_BY_PINNED_SUCCESS
} from '../constants/actionTypes';

export const createLocationTrigger = (occasionId, formData, callback) => ({
  type: CREATE_LOCATION_TRIGGER,
  payload: { occasionId, formData },
  callback
});

export const createLocationTriggerSuccess = (occasionId, data, included) => ({
  type: CREATE_LOCATION_TRIGGER_SUCCESS,
  payload: { occasionId, data, included }
});

export const createLocationTriggerFailed = (error) => ({
  type: CREATE_LOCATION_TRIGGER_FAILED,
  error
});

export const getLocationTrigger = (
  occasionId,
  page,
  modelableId,
  modelableType,
  callback
) => ({
  type: GET_LOCATION_TRIGGER,
  payload: { occasionId, page, modelableId, modelableType },
  callback
});

export const getLocationTriggerSuccess = (
  occasionId,
  data,
  included,
  links
) => ({
  type: GET_LOCATION_TRIGGER_SUCCESS,
  payload: { occasionId, data, included, links }
});

export const getLocationTriggerFailed = (error) => ({
  type: GET_LOCATION_TRIGGER_FAILED,
  error
});

export const createActionTrigger = (occasionId, formData, callback) => ({
  type: CREATE_ACTION_TRIGGER,
  payload: { occasionId, formData },
  callback
});

export const createActionTriggerSuccess = (occasionId, data, included) => ({
  type: CREATE_ACTION_TRIGGER_SUCCESS,
  payload: { occasionId, data, included }
});

export const createActionTriggerFailed = (error) => ({
  type: CREATE_ACTION_TRIGGER_FAILED,
  error
});

export const getActionTrigger = (occasionId, page, callback) => ({
  type: GET_ACTION_TRIGGER,
  payload: { occasionId, page },
  callback
});

export const getActionTriggerSuccess = (occasionId, data, included, links) => ({
  type: GET_ACTION_TRIGGER_SUCCESS,
  payload: { occasionId, data, included, links }
});

export const getActionTriggerFailed = (error) => ({
  type: GET_ACTION_TRIGGER_FAILED,
  error
});

export const createTimeTrigger = (occasionId, formData, callback) => ({
  type: CREATE_TIME_TRIGGER,
  payload: { occasionId, formData },
  callback
});

export const createTimeTriggerSuccess = (occasionId, data, included) => ({
  type: CREATE_TIME_TRIGGER_SUCCESS,
  payload: { occasionId, data, included }
});

export const createTimeTriggerFailed = (error) => ({
  type: CREATE_TIME_TRIGGER_FAILED,
  error
});

export const getTimeTrigger = (
  occasionId,
  page,
  modelableId,
  modelableType,
  callback
) => ({
  type: GET_TIME_TRIGGER,
  payload: { occasionId, page, modelableId, modelableType },
  callback
});

export const getTimeTriggerSuccess = (occasionId, data, included, links) => ({
  type: GET_TIME_TRIGGER_SUCCESS,
  payload: { occasionId, data, included, links }
});

export const getTimeTriggerFailed = (error) => ({
  type: GET_TIME_TRIGGER_FAILED,
  error
});

export const searchTimeTriggerByPinned = (
  occasionId,
  page,
  isPinned,
  modelableId,
  modelableType,
  callback
) => ({
  type: SEARCH_TIME_TRIGGER_BY_PINNED,
  payload: { occasionId, isPinned, page, modelableId, modelableType },
  callback
});

export const searchTimeTriggerByPinnedSuccess = (
  occasionId,
  data,
  included,
  links
) => ({
  type: SEARCH_TIME_TRIGGER_BY_PINNED_SUCCESS,
  payload: { occasionId, data, included, links }
});

export const searchTimeTriggerByPinnedFailed = (error) => ({
  type: SEARCH_TIME_TRIGGER_BY_PINNED_FAILED,
  error
});

export const updateTimeTrigger = (
  occasionId,
  triggerId,
  formData,
  callback
) => ({
  type: UPDATE_TIME_TRIGGER,
  payload: { occasionId, triggerId, formData },
  callback
});

export const updateTimeTriggerSuccess = (occasionId, data, included) => ({
  type: UPDATE_TIME_TRIGGER_SUCCESS,
  payload: { occasionId, data, included }
});

export const updateTimeTriggerFailed = (error) => ({
  type: UPDATE_TIME_TRIGGER_FAILED,
  error
});

export const updateTimeTriggerIsPinned = (
  occasionId,
  triggerId,
  formData,
  callback
) => ({
  type: UPDATE_TIME_TRIGGER_IS_PINNED,
  payload: { occasionId, triggerId, formData },
  callback
});

export const updateTimeTriggerIsPinnedSuccess = (
  occasionId,
  data,
  included
) => ({
  type: UPDATE_TIME_TRIGGER_IS_PINNED_SUCCESS,
  payload: { occasionId, data, included }
});

export const updateTimeTriggerIsPinnedFailed = (error) => ({
  type: UPDATE_TIME_TRIGGER_IS_PINNED_FAILED,
  error
});

export const deleteTimeTrigger = (occasionId, triggerId, callback) => ({
  type: DELETE_TIME_TRIGGER,
  payload: { occasionId, triggerId },
  callback
});

export const deleteTimeTriggerSuccess = (occasionId, data) => ({
  type: DELETE_TIME_TRIGGER_SUCCESS,
  payload: { occasionId, data }
});

export const deleteTimeTriggerFailed = (error) => ({
  type: DELETE_TIME_TRIGGER_FAILED,
  error
});

export const updateActionTrigger = (
  occasionId,
  triggerId,
  formData,
  callback
) => ({
  type: UPDATE_ACTION_TRIGGER,
  payload: { occasionId, triggerId, formData },
  callback
});

export const updateActionTriggerSuccess = (occasionId, data, included) => ({
  type: UPDATE_ACTION_TRIGGER_SUCCESS,
  payload: { occasionId, data, included }
});

export const updateActionTriggerFailed = (error) => ({
  type: UPDATE_ACTION_TRIGGER_FAILED,
  error
});

export const deleteActionTrigger = (occasionId, triggerId, callback) => ({
  type: DELETE_ACTION_TRIGGER,
  payload: { occasionId, triggerId },
  callback
});

export const deleteActionTriggerSuccess = (occasionId, data) => ({
  type: DELETE_ACTION_TRIGGER_SUCCESS,
  payload: { occasionId, data }
});

export const deleteActionTriggerFailed = (error) => ({
  type: DELETE_ACTION_TRIGGER_FAILED,
  error
});

export const updateLocationTrigger = (
  occasionId,
  triggerId,
  formData,
  callback
) => ({
  type: UPDATE_LOCATION_TRIGGER,
  payload: { occasionId, triggerId, formData },
  callback
});

export const updateLocationTriggerSuccess = (occasionId, data, included) => ({
  type: UPDATE_LOCATION_TRIGGER_SUCCESS,
  payload: { occasionId, data, included }
});

export const updateLocationTriggerFailed = (error) => ({
  type: UPDATE_LOCATION_TRIGGER_FAILED,
  error
});

export const deleteLocationTrigger = (occasionId, triggerId, callback) => ({
  type: DELETE_LOCATION_TRIGGER,
  payload: { occasionId, triggerId },
  callback
});

export const deleteLocationTriggerSuccess = (occasionId, data) => ({
  type: DELETE_LOCATION_TRIGGER_SUCCESS,
  payload: { occasionId, data }
});

export const deleteLocationTriggerFailed = (error) => ({
  type: DELETE_LOCATION_TRIGGER_FAILED,
  error
});
