import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import Tabs from '../../../../components/Tabs';
import { currentUserId } from '../../../../selectors/dynamicData';
import { getCurrentAdmin } from '../../../../selectors/admin';
import AdminDetails from './AdminDetails';
import { resetTabIndex, setCurrentTabIndex } from '../../../../actions/tabKeys';
import AssignAdminOccasion from './AdminOccasion/AssignAdminOccasion';
import ViewAdminOccasion from './AdminOccasion/ViewAdminOccasion';
import { renderRoute } from '../../../../components/RouterHOC';
import { TabsHeader } from '../../../../components/TabsHeader';

function AdminTabView(props) {
  // const getOccasionComponent = () => {
  //   return (
  //     <Tabs
  //       preset="secondary"
  //       type="card"
  //       size="small"
  //       tabPanes={[
  //         props.adminDetails.accessType !== 'super_admin' && {
  //           tabName: 'Assign',
  //           tabKey: '2.1',
  //           children: <AssignAdminOccasion adminId={props.adminDetails.id} />
  //         },
  //         {
  //           tabName: 'View',
  //           tabKey: '2.2',
  //           children: (
  //             <ViewAdminOccasion
  //               adminId={props.adminDetails.id}
  //               adminDetails={props.adminDetails}
  //             />
  //           )
  //         }
  //       ].filter((el) => el)}
  //     />
  //   );
  // };
  const tabPanes = [
    {
      tabName: 'Details',
      tabKey: '1',
      children: <AdminDetails adminDetails={props.adminDetails} />
    },
    {
      tabName: 'Occasion',
      tabKey: '2',
      children: (
        <ViewAdminOccasion
          adminId={props.adminDetails.id}
          adminDetails={props.adminDetails}
        />
      )
    }
  ];

  const goBack = () => {
    props.resetTabIndex('admin');
    props.history.goBack();
  };

  const handleTabChange = (key) => {
    props.setCurrentTabIndex(key, 'admin');
  };
  return (
    <ErrorBoundary>
      <SectionContent className="admin-tab-section">
        <TabsHeader
          name={props.adminDetails.name}
          extraData={[props.adminDetails.email]}
          wrapperClass="section-details"
          isActive={props.adminDetails.currentStatus === 'active'}
        />
        <div className="admin-tab-container">
          <Tabs
            defaultActiveKey={props.activeTabKey}
            tabPanes={tabPanes}
            onChange={handleTabChange}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  activeTabKey: store.tabKeys.admin,
  fetchProgress: store.browserState.fetchProgress,
  userId: currentUserId(store, props),
  adminDetails: getCurrentAdmin(store, props),
  userType: store.auth.type
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ resetTabIndex, setCurrentTabIndex }, dispatch);

export default renderRoute(getCurrentAdmin)(
  connect(mapStateToProps, mapDispatchToProps)(AdminTabView)
);
