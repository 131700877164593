import { createSelector } from 'reselect';
import { currentOccasionId, currentScheduleId } from './dynamicData';
import { userEntities, scheduleEntities } from './entity';
import { scheduleIds } from './static';

export const currentOccasionScheduleIds = createSelector(
  currentOccasionId,
  scheduleIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getAllOccasionSchedules = createSelector(
  scheduleEntities,
  currentOccasionScheduleIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
export const occasionScheduleWithUserData = createSelector(
  getAllOccasionSchedules,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      createdBy: { ...entities[item?.createdBy?.id] },
      updatedBy: { ...entities[item?.updatedBy?.id] }
    }))
);
export const getCurrentSchedule = createSelector(
  currentScheduleId,
  scheduleEntities,
  (id, entities) => id && entities && entities[id]
);
