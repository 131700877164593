import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import TableView from '../../../../../components/Table';
import Tabs from '../../../../../components/Tabs';
import {
  hospitalityCategoryView,
  deleteHospitalityCategory
} from '../../../../../actions/hospitality';
import { getAllHospitalityCategoryData } from '../../../../../selectors/hospitality';
import { IMAGE_RADIO } from './HospitalityConstants';
import { Button } from 'antd';
import HospitalityCreate from './CreateCategory';
import UpdateAnswerByCsv from './UpdateAnswerByCsv';
import UpdateAnswerByXlsx from './UpdateAnswerByXlsx';
import ExslExport from './ExslExport';

const HospitalityView = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [openCSV, setOpenCSV] = React.useState(false);
  const [openExsl, setOpenExsl] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.hospitalityCategoryView(props.occasionId, page);
  };
  const location = useLocation();
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const toggleOpenCSV = () => {
    setOpenCSV((val) => !val);
  };

  const toggleOpenExsl = () => {
    setOpenExsl((val) => !val);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="hospitality-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={() => setCreateOpen(true)}>
          Create
        </Button>
        <Button className="download-button" onClick={toggleOpenCSV}>
          Update Answers
        </Button>
        <Button className="download-button" onClick={toggleOpenExsl}>
          Export All Task Answers
        </Button>
      </div>
    )
  };

  const deleteData = (id) => {
    props.deleteHospitalityCategory(props.occasionId, id);
  };

  const tabPanes = [
    {
      tabName: 'Update All Answers Using XLSX',
      tabKey: '1',
      children: (
        <UpdateAnswerByXlsx
          occasionId={props.occasionId}
          handleCloseClick={toggleOpenCSV}
        />
      )
    },
    {
      tabName: 'Update Provided Answers Using CSV',
      tabKey: '2',
      children: (
        <UpdateAnswerByCsv
          occasionId={props.occasionId}
          handleCloseClick={toggleOpenCSV}
        />
      )
    }
  ];

  if (openCSV) {
    return (
      <div className="edit-container">
        <Tabs defaultActiveKey="1" tabPanes={tabPanes} />
      </div>
    );
  }

  if (openExsl) {
    return (
      <div className="edit-container">
        <ExslExport
          occasionId={props.occasionId}
          occasionName={props.occasionDetails?.name}
          handleCloseClick={toggleOpenExsl}
        />
      </div>
    );
  }

  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="hospitality-view-section">
          <div className="hospitality-view-container">
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              pathname={`${location.pathname}/hospitalityCategory/view`}
              onPageChange={onPageChange}
              onDelete={deleteData}
              fetchProgress={props.fetchProgress}
              paginationLinks={props.categoryLinks}
              data={props.hospitalityCategoryData?.map((item) => {
                return {
                  id: item?.id,
                  name: item?.name,
                  description: item?.description,
                  purpose: item?.purpose,
                  order: item?.orderNumber,
                  avatarName: (
                    <img
                      className="hospitality-view-image"
                      src={IMAGE_RADIO[item?.avatarName]}></img>
                  )
                };
              })}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <HospitalityCreate
        occasionId={props.occasionId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  hospitalityCategoryData: getAllHospitalityCategoryData(store, props),
  categoryLinks: store.hospitality.categoryLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { hospitalityCategoryView, deleteHospitalityCategory },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(HospitalityView);
