import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  createSpeaker,
  deleteSpeakerApi,
  editSpeakerApi,
  viewSpeaker
} from '../api/speaker';

import {
  CREATE_SPEAKER,
  DELETE_SPEAKER,
  EDIT_SPEAKER,
  GET_SPEAKER_PAGINATION
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  createSpeakerFailed,
  createSpeakerSuccess,
  deleteSpeakerFailed,
  deleteSpeakerSuccess,
  editSpeakerFailed,
  editSpeakerSuccess,
  getSpeakerWithPaginationFailed,
  getSpeakerWithPaginationSuccess
} from '../actions/speaker';

export function* createSpeakerSaga(action) {
  try {
    const result = yield call(
      createSpeaker,
      action.payload.ocId,
      action.payload.referencable_type,
      action.payload.referencable_id,
      action.payload.formData,
      getUserToken()
    );
    if (typeof action.callback === 'function') {
      action.callback();
    }
    if (isValidResult(result)) {
      yield put(
        createSpeakerSuccess(
          action.payload.ocId,
          action.payload.referencable_type,
          result.data,
          result.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
    } else if (result && result.meta) {
      yield put(createSpeakerFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(createSpeakerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getSpeakersWithPaginationSaga(action) {
  try {
    const result = yield call(
      viewSpeaker,
      action.payload.ocId,
      action.payload.pageNumber,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getSpeakerWithPaginationSuccess(
          action.payload.ocId,
          result.data,
          result?.links,
          result?.included
        )
      );
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getSpeakerWithPaginationFailed(error));

    yield put(requestAutoHideAlert(error, 'Error', 4000));
  }
}

export function* editSpeakerSaga(action) {
  try {
    const result = yield call(
      editSpeakerApi,
      action.payload.ocId,
      action.payload.speakerId,
      action.payload.formData,
      getUserToken()
    );
    if (typeof action.callback === 'function') {
      action.callback();
    }
    if (isValidResult(result)) {
      yield put(
        editSpeakerSuccess(action.payload.ocId, result.data, result.included)
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
    }
  } catch (error) {
    yield put(editSpeakerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* deleteSpeakerSaga(action) {
  try {
    const result = yield call(
      deleteSpeakerApi,
      action.payload.ocId,
      action.payload.speakerId,
      getUserToken()
    );
    if (typeof action.callback === 'function') {
      action.callback();
    }
    if (isValidResult(result)) {
      yield put(
        deleteSpeakerSuccess(action.payload.ocId, result.data, result.included)
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
    }
  } catch (error) {
    yield put(deleteSpeakerFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* speakerActionWatcher() {
  yield takeLatest(CREATE_SPEAKER, progressSaga, createSpeakerSaga);
  yield takeLatest(
    GET_SPEAKER_PAGINATION,
    progressSaga,
    getSpeakersWithPaginationSaga
  );
  yield takeLatest(EDIT_SPEAKER, progressSaga, editSpeakerSaga);
  yield takeLatest(DELETE_SPEAKER, progressSaga, deleteSpeakerSaga);
}
