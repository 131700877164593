import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button, AutoComplete } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup';
import { getImageSource } from '../../../../../../helpers/imageHelper';
import { Image } from '../../../../../../components/Image';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { occasionGroupView } from '../../../../../../actions/group';
import { getOccasionGroupsWithAllData } from '../../../../../../selectors/group';
import { ACCESS_TYPE } from '../../../../../../constants/OtherConstants';
import { getCurrentEventCollaborator } from '../../../../../../selectors/event';
import {
  eventCollaboratorCreate,
  eventCollaboratorUpdate
} from '../../../../../../actions/eventCollaborator';

const validateCollaboratorData = (values) => {
  const errors = {};
  if (values.group?.length === 0 && values.forAllMember !== true) {
    errors.group = 'Group is required';
  }
  return errors;
};

const { Option } = AutoComplete;

function CreateCollaborator(props) {
  const [collaboratorData, setCollaboratorData] = React.useState({
    access_type: props.currentCollaboratorData?.accessType || 'guest',
    forAllMember: true,
    group: []
  });
  const [errorMessages, setErrorMessages] = React.useState({});
  const [page, setPage] = React.useState(1);

  const onPageChange = (page) => {
    props.occasionGroupView(props.occasionId, page);
  };

  React.useEffect(() => {
    onPageChange(page);
  }, [page]);

  const getPage = (str) => {
    const decodedURI = decodeURI(str);
    const number = decodedURI
      .split('page[number]')[1]
      .replace('=', '')
      .split('&');
    return (number && parseInt(number)) || 1;
  };

  const onEndReached = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height >= scrollHeight - 20) {
      if (props.allGroupsLinks?.next) {
        const page = getPage(props.allGroupsLinks?.next);
        setPage(page);
      }
    }
  };

  const handleSubmit = () => {
    try {
      const validateObj = validateCollaboratorData(collaboratorData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();

        formData.append(
          'event_collaborator[access_type]',
          collaboratorData?.access_type
        );

        if (!props.eventCollaboratorId) {
          formData.append('event_collaborator[event_id]', props.eventId);
        }

        if (!props.eventCollaboratorId) {
          formData.append(
            'event_collaborator[for_all_member]',
            collaboratorData?.forAllMember ? true : false
          );
        }

        if (!collaboratorData?.forAllMember) {
          collaboratorData?.group.forEach((id) => {
            formData.append('event_collaborator[group_ids][]', id);
          });
        }

        props.eventCollaboratorId
          ? props.eventCollaboratorUpdate(
              props.occasionId,
              props.eventCollaboratorId,
              props.eventId,
              formData
            )
          : props.eventCollaboratorCreate(
              props.occasionId,
              formData,
              props.eventId,
              () => {
                setCollaboratorData({
                  access_type: 'guest',
                  forAllMember: true,

                  group: []
                });

                props.handleCloseClick();
                props.afterCreatePageRefresh(1);
              }
            );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;

      if (type === 'forAllMember') {
        value = e;
        name = type;
      }

      const collaboratorValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setCollaboratorData({
        ...collaboratorData,
        [inputName]: collaboratorValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="collaborator-create-section">
        <div className="collaborator-create-form">
          <Form className="collaborator-form" onFinish={handleSubmit}>
            <InputGroup
              fieldType="dropdown"
              className="type-field"
              options={ACCESS_TYPE}
              name="access_type"
              label="Access Type"
              onChange={handleInputChange}
              value={collaboratorData?.access_type}
            />
            {!props.eventCollaboratorId && (
              <InputGroup
                label="For All Members"
                name="forAllMember"
                fieldType="switch"
                onChange={handleInputChange}
                value={collaboratorData?.forAllMember}
              />
            )}
            {!props.eventCollaboratorId && !collaboratorData?.forAllMember && (
              <InputGroup
                fieldType="dropdown"
                className="group-search"
                mode="multiple"
                name="group"
                label="Select Group"
                placeholder="Select Group"
                onPopupScroll={onEndReached}
                onChange={handleInputChange}
                errorMessage={errorMessages?.group}
                value={collaboratorData?.group}>
                {props.groupData?.length > 0 &&
                  props.groupData.map((item) => {
                    return (
                      <Option value={item?.id} key={item?.id}>
                        <div className="options-container">
                          <p className="avatar-image-container">
                            <Image
                              src={getImageSource(item?.groupPictureUrl)}
                              preview={false}
                            />
                          </p>
                          <div className="occasion-group-details-container">
                            <p className="occasion-group-name">{item?.name}</p>
                          </div>
                        </div>
                      </Option>
                    );
                  })}
              </InputGroup>
            )}
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.eventCollaboratorId ? 'Save Changes' : 'Create'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  groupData: getOccasionGroupsWithAllData(store, props),
  currentCollaboratorData: getCurrentEventCollaborator(store, props),
  allGroupsLinks: store.group.groupLinks
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { occasionGroupView, eventCollaboratorCreate, eventCollaboratorUpdate },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateCollaborator);
