import { createSelector } from 'reselect';
import { memberEntities, userEntities } from './entity';
import {
  memberIds,
  memberLinks,
  searchedMemberIds,
  searchedMemberLinks
} from './static';
import { currentOccasionId, currentCollaboratorId } from './dynamicData';

export const currentMemberIds = createSelector(
  currentOccasionId,
  memberIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getMembersData = createSelector(
  currentMemberIds,
  memberEntities,
  userEntities,
  (ids, entities, users) => {
    return (
      ids &&
      users &&
      entities &&
      ids?.length > 0 &&
      ids.map((id) => {
        return {
          users: {
            ...users[entities[id]?.user?.id]
          },
          ...entities[id]
        };
      })
    );
  }
);

export const getMemberLinks = createSelector(
  currentOccasionId,
  memberLinks,
  (ocId, links) => ocId && links && links[ocId]
);

export const currentSearchedMemberIds = createSelector(
  currentOccasionId,
  searchedMemberIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getSearchedMembersData = createSelector(
  currentSearchedMemberIds,
  memberEntities,
  userEntities,
  (ids, entities, users) => {
    return (
      ids &&
      users &&
      entities &&
      ids?.length > 0 &&
      ids.map((id) => {
        return {
          users: {
            ...users[entities[id]?.user?.id]
          },
          ...entities[id]
        };
      })
    );
  }
);

export const getSearchedMemberLinks = createSelector(
  currentOccasionId,
  searchedMemberLinks,
  (ocId, links) => ocId && links && links[ocId]
);

export const getCurrentMember = createSelector(
  currentCollaboratorId,
  memberEntities,
  (id, entities) => id && entities && entities[id]
);

export const getCurrentMemberWithUserData = createSelector(
  getCurrentMember,
  userEntities,
  (data, entities) =>
    data && entities && { ...data, ...entities[data?.user?.id] }
);
