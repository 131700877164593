import React from 'react';
import './styles.scss';

const FloorPlanImageButton = ({ data, index, onClick }) => {
  return (
    <div className=" FloorPlanImage-container">
      <div className="See-More-button-box">
        <button className="See-More-button" onClick={onClick}>
          <span>+{data.slice(index + 1).length} More</span>
        </button>
      </div>
    </div>
  );
};
export default FloorPlanImageButton;
