import React from 'react';
import { Avatar } from '../../../../../components/Avatar';
import CardImage from '../../../../../components/Table/CardImage';
import './styles.scss';

const MemberDetailsView = ({ memberData }) => {
  return (
    <div className="member-details-container">
      <p className="member-details ">
        <p className="fields">Avatar :</p>
        <p className="field-data card-image-container">
          <Avatar
            avatarUrl={memberData?.avatarUrl}
            name={memberData?.name}
            className="member-image"
          />
        </p>
      </p>
      <p className="member-details">
        <p className="fields">User Name:</p>
        <p className="field-data">{memberData?.name}</p>
      </p>
      {memberData?.email && (
        <p className="member-details">
          <p className="fields">User Email:</p>
          <p className="field-data">{memberData?.email}</p>
        </p>
      )}
      {memberData?.mobile && (
        <p className="member-details">
          <p className="fields">User Phone No:</p>
          <p className="field-data">{memberData?.mobile}</p>
        </p>
      )}
      {memberData?.dob && (
        <p className="member-details">
          <p className="fields">User Dob:</p>
          <p className="field-data">{memberData?.dob}</p>
        </p>
      )}
      {memberData?.type && (
        <p className="member-details">
          <p className="fields">Member Type:</p>
          <p className="field-data">{memberData?.type}</p>
        </p>
      )}
      {memberData?.roleName && (
        <p className="member-details">
          <p className="fields">Role Name:</p>
          <p className="field-data">{memberData?.roleName}</p>
        </p>
      )}
      {memberData?.qrCodeUrl && (
        <p className="member-details">
          <p className="fields">Qr Code:</p>
          <p className="field-data card-image-container">
            <CardImage data={memberData?.qrCodeUrl} />
          </p>
        </p>
      )}
    </div>
  );
};
export default MemberDetailsView;
