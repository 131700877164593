import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { SyncOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import TableView from '../../../../../components/Table';
import {
  competitionView,
  deleteCompetition
} from '../../../../../actions/competition';
import { getAllOccasionCompetition } from '../../../../../selectors/competition';
import { Button } from 'antd';
import CreateCompetition from './create';

const ViewCompetition = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [competitionId, setCompetitiondId] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const location = useLocation();
  const onPageChange = (page) => {
    setPage(page);
    props.competitionView(props.occasionId, page);
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleCreate = () => {
    setCompetitiondId('');
    setCreateOpen(true);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="competition-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={handleCreate}>
          Create
        </Button>
      </div>
    )
  };

  const editData = (id) => {
    setCreateOpen(true);
    setCompetitiondId(id);
  };

  const deleteData = (id) => {
    props.deleteCompetition(props.occasionId, id);
  };

  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="competition-view-section">
          <div className="competition-view-container">
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              pathname={`${location.pathname}/competition/view`}
              isRouted={true}
              onPageChange={onPageChange}
              onDelete={deleteData}
              isEditable={editData}
              data={props.competitionData}
              fetchProgress={props.fetchProgress}
              paginationLinks={props.competitionLinks}
              normalTime={true}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <CreateCompetition
        occasionId={props.occasionId}
        competitionId={competitionId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  competitionData: getAllOccasionCompetition(store, props),
  competitionLinks: store.Competition.competitionLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ competitionView, deleteCompetition }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(ViewCompetition);
