export const ENTITY_DELETE_REQUEST_SUCCESS = 'ENTITY_DELETE_REQUEST_SUCCESS';
export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const REQUEST_CLEAR_STORE = 'REQUEST_CLEAR_STORE';

export const FETCH_STARTED = 'FETCH_STARTED';
export const FETCH_COMPLETED = 'FETCH_COMPLETED';

export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REQUEST_AUTO_DISMISS_ALERT = 'REQUEST_AUTO_DISMISS_ALERT';
export const REQUEST_HIDE_ALERT = 'REQUEST_HIDE_ALERT';
export const REQUEST_SHOW_ALERT = 'REQUEST_SHOW_ALERT';
export const ACTIVATE_ALERT = 'ACTIVATE_ALERT';

/* ALARM */

export const OCCASION_ALARM_CREATE = 'OCCASION_ALARM_CREATE';
export const OCCASION_ALARM_CREATE_FAILED = 'OCCASION_ALARM_CREATE_FAILED';
export const OCCASION_ALARM_CREATE_SUCCESS = 'OCCASION_ALARM_CREATE_SUCCESS';
export const OCCASION_ALARM_VIEW = 'OCCASION_ALARM_VIEW';
export const OCCASION_ALARM_VIEW_FAILED = 'OCCASION_ALARM_VIEW_FAILED';
export const OCCASION_ALARM_VIEW_SUCCESS = 'OCCASION_ALARM_VIEW_SUCCESS';
export const UPDATE_ALARM = 'UPDATE_ALARM';
export const UPDATE_ALARM_FAILED = 'UPDATE_ALARM_FAILED';
export const UPDATE_ALARM_SUCCESS = 'UPDATE_ALARM_SUCCESS';
export const DELETE_ALARM = 'DELETE_ALARM';
export const DELETE_ALARM_FAILED = 'DELETE_ALARM_FAILED';
export const DELETE_ALARM_SUCCESS = 'DELETE_ALARM_SUCCESS';

/* OCCASION */
export const GET_OCCASION_SETTINGS = 'GET_OCCASION_SETTINGS';
export const GET_OCCASION_SETTINGS_SUCCESS = 'GET_OCCASION_SETTINGS_SUCCESS';
export const GET_OCCASION_SETTINGS_FAILED = 'GET_OCCASION_SETTINGS_FAILED';
export const GET_OCCASION_PAGINATION = 'GET_OCCASION_PAGINATION';
export const GET_OCCASION_PAGINATION_SUCCESS =
  'GET_OCCASION_PAGINATION_SUCCESS';
export const GET_OCCASION_PAGINATION_FAILED = 'GET_OCCASION_PAGINATION_FAILED';
export const CREATE_OCCASION = 'CREATE_OCCASION';
export const CREATE_OCCASION_SUCCESS = 'CREATE_OCCASION_SUCCESS';
export const CREATE_OCCASION_FAILED = 'CREATE_OCCASION_FAILED';
export const EDIT_OCCASION = 'EDIT_OCCASION';
export const EDIT_OCCASION_SUCCESS = 'EDIT_OCCASION_SUCCESS';
export const EDIT_OCCASION_FAILED = 'EDIT_OCCASION_FAILED';
export const GET_OCCASION_BY_FIELD = 'GET_OCCASION_BY_FIELD';
export const GET_OCCASION_BY_FIELD_SUCCESS = 'GET_OCCASION_BY_FIELD_SUCCESS';
export const GET_OCCASION_BY_FIELD_FAILED = 'GET_OCCASION_BY_FIELD_FAILED';

/* INVITATION */

export const MULTIPLE_INVITATIONS = 'MULTIPLE_INVITATIONS';
export const MULTIPLE_INVITATIONS_SUCCESS = 'MULTIPLE_INVITATIONS_SUCCESS';
export const MULTIPLE_INVITATIONS_FAILED = 'MULTIPLE_INVITATIONS_FAILED';
export const PARSE_CSV = 'PARSE_CSV';
export const PARSE_CSV_SUCCESS = 'PARSE_CSV_SUCCESS';
export const PARSE_CSV_FAILED = 'PARSE_CSV_FAILED';
export const GET_OCCASION_PENDING_INVITATION =
  'GET_OCCASION_PENDING_INVITATION';
export const GET_OCCASION_PENDING_INVITATION_SUCCESS =
  'GET_OCCASION_PENDING_INVITATION_SUCCESS';
export const GET_OCCASION_PENDING_INVITATION_FAILED =
  'GET_OCCASION_PENDING_INVITATION_FAILED';
export const GET_OCCASION_PENDING_REGISTRATION =
  'GET_OCCASION_PENDING_REGISTRATION';
export const GET_OCCASION_PENDING_REGISTRATION_SUCCESS =
  'GET_OCCASION_PENDING_REGISTRATION_SUCCESS';
export const GET_OCCASION_PENDING_REGISTRATION_FAILED =
  'GET_OCCASION_PENDING_REGISTRATION_FAILED';
export const PENDING_INVITATION_SEARCH = 'PENDING_INVITATION_SEARCH';
export const PENDING_INVITATION_SEARCH_SUCCESS =
  'PENDING_INVITATION_SEARCH_SUCCESS';
export const PENDING_INVITATION_SEARCH_FAILED =
  'PENDING_INVITATION_SEARCH_FAILED';
export const PENDING_REGISTRATION_SEARCH = 'PENDING_REGISTRATION_SEARCH';
export const PENDING_REGISTRATION_SEARCH_SUCCESS =
  'PENDING_REGISTRATION_SEARCH_SUCCESS';
export const PENDING_REGISTRATION_SEARCH_FAILED =
  'PENDING_REGISTRATION_SEARCH_FAILED';
export const SEARCH_PENDING_INVITATIONS = 'SEARCH_PENDING_INVITATIONS';
export const SEARCH_PENDING_INVITATIONS_SUCCESS =
  'SEARCH_PENDING_INVITATIONS_SUCCESS';
export const SEARCH_PENDING_INVITATIONS_FAILED =
  'SEARCH_PENDING_INVITATIONS_FAILED';
export const SEARCH_PENDING_REGISTRATIONS = 'SEARCH_PENDING_REGISTRATIONS';
export const SEARCH_PENDING_REGISTRATIONS_SUCCESS =
  'SEARCH_PENDING_REGISTRATIONS_SUCCESS';
export const SEARCH_PENDING_REGISTRATIONS_FAILED =
  'SEARCH_PENDING_REGISTRATIONS_FAILED';
export const RESEND_PENDING_REGISTRATION = 'RESEND_PENDING_REGISTRATION';
export const RESEND_PENDING_REGISTRATION_SUCCESS =
  'RESEND_PENDING_REGISTRATION_SUCCESS';
export const RESEND_PENDING_REGISTRATION_FAILED =
  'RESEND_PENDING_REGISTRATION_FAILED';

export const RESEND_PENDING_INVITATION = 'RESEND_PENDING_INVITATION';
export const RESEND_PENDING_INVITATION_SUCCESS =
  'RESEND_PENDING_INVITATION_SUCCESS';
export const RESEND_PENDING_INVITATION_FAILED =
  'RESEND_PENDING_INVITATION_FAILED';

export const DELETE_OCCASION_PENDING_INVITATION =
  'DELETE_OCCASION_PENDING_INVITATION';
export const DELETE_OCCASION_PENDING_INVITATION_FAILED =
  'DELETE_OCCASION_PENDING_INVITATION_FAILED';
export const DELETE_OCCASION_PENDING_INVITATION_SUCCESS =
  'DELETE_OCCASION_PENDING_INVITATION_SUCCESS';
export const UPDATE_PENDING_INVITATION = 'UPDATE_PENDING_INVITATION';
export const UPDATE_PENDING_INVITATION_FAILED =
  'UPDATE_PENDING_INVITATION_FAILED';
export const UPDATE_PENDING_INVITATION_SUCCESS =
  'UPDATE_PENDING_INVITATION_SUCCESS';

export const DELETE_OCCASION_PENDING_REGISTRATION =
  'DELETE_OCCASION_PENDING_REGISTRATION';
export const DELETE_OCCASION_PENDING_REGISTRATION_FAILED =
  'DELETE_OCCASION_PENDING_REGISTRATION_FAILED';
export const DELETE_OCCASION_PENDING_REGISTRATION_SUCCESS =
  'DELETE_OCCASION_PENDING_REGISTRATION_SUCCESS';
export const UPDATE_PENDING_REGISTRATION = 'UPDATE_PENDING_REGISTRATION';
export const UPDATE_PENDING_REGISTRATION_FAILED =
  'UPDATE_PENDING_REGISTRATION_FAILED';
export const UPDATE_PENDING_REGISTRATION_SUCCESS =
  'UPDATE_PENDING_REGISTRATION_SUCCESS';

/* EVENT */

export const OCCASION_EVENT_CREATE = 'OCCASION_EVENT_CREATE';
export const OCCASION_EVENT_CREATE_SUCCESS = 'OCCASION_EVENT_CREATE_SUCCESS';
export const OCCASION_EVENT_CREATE_FAILED = 'OCCASION_EVENT_CREATE_FAILED';
export const OCCASION_EVENT_VIEW = 'OCCASION_EVENT_VIEW';
export const OCCASION_EVENT_VIEW_SUCCESS = 'OCCASION_EVENT_VIEW_SUCCESS';
export const OCCASION_EVENT_VIEW_FAILED = 'OCCASION_EVENT_VIEW_FAILED';
export const OCCASION_EVENT_UPDATE = 'OCCASION_EVENT_UPDATE';
export const OCCASION_EVENT_UPDATE_SUCCESS = 'OCCASION_EVENT_UPDATE_SUCCESS';
export const OCCASION_EVENT_UPDATE_FAILED = 'OCCASION_EVENT_UPDATE_FAILED';
export const GET_EVENT_SEARCH = 'GET_EVENT_SEARCH';
export const GET_EVENT_SEARCH_SUCCESS = 'GET_EVENT_SEARCH_SUCCESS';
export const GET_EVENT_SEARCH_FAILED = 'GET_EVENT_SEARCH_FAILED';
export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILED = 'DELETE_EVENT_FAILED';
export const MULTIPLE_EVENT_INVITATIONS = 'MULTIPLE_EVENT_INVITATIONS';
export const MULTIPLE_EVENT_INVITATIONS_SUCCESS =
  'MULTIPLE_EVENT_INVITATIONS_SUCCESS';
export const MULTIPLE_EVENT_INVITATIONS_FAILED =
  'MULTIPLE_EVENT_INVITATIONS_FAILED';
export const PARSE_CSV_EVENT = 'PARSE_CSV_EVENT';
export const PARSE_CSV_EVENT_SUCCESS = 'PARSE_CSV_EVENT_SUCCESS';
export const PARSE_CSV_EVENT_FAILED = 'PARSE_CSV_EVENT_FAILED';

export const GET_EVENT_MEMBERS = 'GET_EVENT_MEMBERS';
export const GET_EVENT_MEMBERS_SUCCESS = 'GET_EVENT_MEMBERS_SUCCESS';
export const GET_EVENT_MEMBERS_FAILED = 'GET_EVENT_MEMBERS_FAILED';
export const GET_EVENT_PENDING_INVITATION = 'GET_EVENT_PENDING_INVITATION';
export const GET_EVENT_PENDING_INVITATION_SUCCESS =
  'GET_EVENT_PENDING_INVITATION_SUCCESS';
export const GET_EVENT_PENDING_INVITATION_FAILED =
  'GET_EVENT_PENDING_INVITATION_FAILED';
export const GET_EVENT_PENDING_REGISTRATION = 'GET_EVENT_PENDING_REGISTRATION';
export const GET_EVENT_PENDING_REGISTRATION_SUCCESS =
  'GET_EVENT_PENDING_REGISTRATION_SUCCESS';
export const GET_EVENT_PENDING_REGISTRATION_FAILED =
  'GET_EVENT_PENDING_REGISTRATION_FAILED';

export const EVENT_SCHEDULE_CREATE = 'EVENT_SCHEDULE_CREATE';
export const EVENT_SCHEDULE_CREATE_SUCCESS = 'EVENT_SCHEDULE_CREATE_SUCCESS';
export const EVENT_SCHEDULE_CREATE_FAILED = 'EVENT_SCHEDULE_CREATE_FAILED';
export const EVENT_SCHEDULE_VIEW = 'EVENT_SCHEDULE_VIEW';
export const EVENT_SCHEDULE_VIEW_SUCCESS = 'EVENT_SCHEDULE_VIEW_SUCCESS';
export const EVENT_SCHEDULE_VIEW_FAILED = 'EVENT_SCHEDULE_VIEW_FAILED';

export const EVENT_SCHEDULE_UPDATE = 'EVENT_SCHEDULE_UPDATE';
export const EVENT_SCHEDULE_UPDATE_SUCCESS = 'EVENT_SCHEDULE_UPDATE_SUCCESS';
export const EVENT_SCHEDULE_UPDATE_FAILED = 'EVENT_SCHEDULE_UPDATE_FAILED';

export const EVENT_SCHEDULE_DELETE = 'EVENT_SCHEDULE_DELETE';
export const EVENT_SCHEDULE_DELETE_SUCCESS = 'EVENT_SCHEDULE_DELETE_SUCCESS';
export const EVENT_SCHEDULE_DELETE_FAILED = 'EVENT_SCHEDULE_DELETE_FAILED';

export const EVENT_AFFAIR_CREATE = 'EVENT_AFFAIR_CREATE';
export const EVENT_AFFAIR_CREATE_SUCCESS = 'EVENT_AFFAIR_CREATE_SUCCESS';
export const EVENT_AFFAIR_CREATE_FAILED = 'EVENT_AFFAIR_CREATE_FAILED';
export const EVENT_AFFAIR_VIEW = 'EVENT_AFFAIR_VIEW';
export const EVENT_AFFAIR_VIEW_SUCCESS = 'EVENT_AFFAIR_VIEW_SUCCESS';
export const EVENT_AFFAIR_VIEW_FAILED = 'EVENT_AFFAIR_VIEW_FAILED';

export const EVENT_AFFAIR_UPDATE = 'EVENT_AFFAIR_UPDATE';
export const EVENT_AFFAIR_UPDATE_SUCCESS = 'EVENT_AFFAIR_UPDATE_SUCCESS';
export const EVENT_AFFAIR_UPDATE_FAILED = 'EVENT_AFFAIR_UPDATE_FAILED';

export const EVENT_AFFAIR_DELETE = 'EVENT_AFFAIR_DELETE';
export const EVENT_AFFAIR_DELETE_SUCCESS = 'EVENT_AFFAIR_DELETE_SUCCESS';
export const EVENT_AFFAIR_DELETE_FAILED = 'EVENT_AFFAIR_DELETE_FAILED';

export const EVENT_AFFAIR_ESSENCE_CREATE = 'EVENT_AFFAIR_ESSENCE_CREATE';
export const EVENT_AFFAIR_ESSENCE_CREATE_SUCCESS =
  'EVENT_AFFAIR_ESSENCE_CREATE_SUCCESS';
export const EVENT_AFFAIR_ESSENCE_CREATE_FAILED =
  'EVENT_AFFAIR_ESSENCE_CREATE_FAILED';
export const EVENT_AFFAIR_ESSENCE_VIEW = 'EVENT_AFFAIR_ESSENCE_VIEW';
export const EVENT_AFFAIR_ESSENCE_VIEW_SUCCESS =
  'EVENT_AFFAIR_ESSENCE_VIEW_SUCCESS';
export const EVENT_AFFAIR_ESSENCE_VIEW_FAILED =
  'EVENT_AFFAIR_ESSENCE_VIEW_FAILED';

export const EVENT_AFFAIR_ESSENCE_UPDATE = 'EVENT_AFFAIR_ESSENCE_UPDATE';
export const EVENT_AFFAIR_ESSENCE_UPDATE_SUCCESS =
  'EVENT_AFFAIR_ESSENCE_UPDATE_SUCCESS';
export const EVENT_AFFAIR_ESSENCE_UPDATE_FAILED =
  'EVENT_AFFAIR_ESSENCE_UPDATE_FAILED';

export const EVENT_AFFAIR_ESSENCE_DELETE = 'EVENT_AFFAIR_ESSENCE_DELETE';
export const EVENT_AFFAIR_ESSENCE_DELETE_SUCCESS =
  'EVENT_AFFAIR_ESSENCE_DELETE_SUCCESS';
export const EVENT_AFFAIR_ESSENCE_DELETE_FAILED =
  'EVENT_AFFAIR_ESSENCE_DELETE_FAILED';

export const EVENT_AFFAIR_ENTERTAINMENT_CREATE =
  'EVENT_AFFAIR_ENTERTAINMENT_CREATE';
export const EVENT_AFFAIR_ENTERTAINMENT_CREATE_SUCCESS =
  'EVENT_AFFAIR_ENTERTAINMENT_CREATE_SUCCESS';
export const EVENT_AFFAIR_ENTERTAINMENT_CREATE_FAILED =
  'EVENT_AFFAIR_ENTERTAINMENT_CREATE_FAILED';
export const EVENT_AFFAIR_ENTERTAINMENT_VIEW =
  'EVENT_AFFAIR_ENTERTAINMENT_VIEW';
export const EVENT_AFFAIR_ENTERTAINMENT_VIEW_SUCCESS =
  'EVENT_AFFAIR_ENTERTAINMENT_VIEW_SUCCESS';
export const EVENT_AFFAIR_ENTERTAINMENT_VIEW_FAILED =
  'EVENT_AFFAIR_ENTERTAINMENT_VIEW_FAILED';

export const EVENT_AFFAIR_ENTERTAINMENT_UPDATE =
  'EVENT_AFFAIR_ENTERTAINMENT_UPDATE';
export const EVENT_AFFAIR_ENTERTAINMENT_UPDATE_SUCCESS =
  'EVENT_AFFAIR_ENTERTAINMENT_UPDATE_SUCCESS';
export const EVENT_AFFAIR_ENTERTAINMENT_UPDATE_FAILED =
  'EVENT_AFFAIR_ENTERTAINMENT_UPDATE_FAILED';

export const EVENT_AFFAIR_ENTERTAINMENT_DELETE =
  'EVENT_AFFAIR_ENTERTAINMENT_DELETE';
export const EVENT_AFFAIR_ENTERTAINMENT_DELETE_SUCCESS =
  'EVENT_AFFAIR_ENTERTAINMENT_DELETE_SUCCESS';
export const EVENT_AFFAIR_ENTERTAINMENT_DELETE_FAILED =
  'EVENT_AFFAIR_ENTERTAINMENT_DELETE_FAILED';

export const EVENT_AFFAIR_FOOD_CREATE = 'EVENT_AFFAIR_FOOD_CREATE';
export const EVENT_AFFAIR_FOOD_CREATE_SUCCESS =
  'EVENT_AFFAIR_FOOD_CREATE_SUCCESS';
export const EVENT_AFFAIR_FOOD_CREATE_FAILED =
  'EVENT_AFFAIR_FOOD_CREATE_FAILED';
export const EVENT_AFFAIR_FOOD_VIEW = 'EVENT_AFFAIR_FOOD_VIEW';
export const EVENT_AFFAIR_FOOD_VIEW_SUCCESS = 'EVENT_AFFAIR_FOOD_VIEW_SUCCESS';
export const EVENT_AFFAIR_FOOD_VIEW_FAILED = 'EVENT_AFFAIR_FOOD_VIEW_FAILED';

export const EVENT_AFFAIR_FOOD_UPDATE = 'EVENT_AFFAIR_FOOD_UPDATE';
export const EVENT_AFFAIR_FOOD_UPDATE_SUCCESS =
  'EVENT_AFFAIR_FOOD_UPDATE_SUCCESS';
export const EVENT_AFFAIR_FOOD_UPDATE_FAILED =
  'EVENT_AFFAIR_FOOD_UPDATE_FAILED';

export const EVENT_AFFAIR_FOOD_DELETE = 'EVENT_AFFAIR_FOOD_DELETE';
export const EVENT_AFFAIR_FOOD_DELETE_SUCCESS =
  'EVENT_AFFAIR_FOOD_DELETE_SUCCESS';
export const EVENT_AFFAIR_FOOD_DELETE_FAILED =
  'EVENT_AFFAIR_FOOD_DELETE_FAILED';

export const CREATE_EVENT_POST = 'CREATE_EVENT_POST';
export const CREATE_EVENT_POST_SUCCESS = 'CREATE_EVENT_POST_SUCCESS';
export const CREATE_EVENT_POST_FAILED = 'CREATE_EVENT_POST_FAILED';

export const GET_EVENT_POST_PAGINATION = 'GET_EVENT_POST_PAGINATION';
export const GET_EVENT_POST_PAGINATION_SUCCESS =
  'GET_EVENT_POST_PAGINATION_SUCCESS';
export const GET_EVENT_POST_PAGINATION_FAILED =
  'GET_EVENT_POST_PAGINATION_FAILED';

export const EDIT_EVENT_POST = 'EDIT_EVENT_POST';
export const EDIT_EVENT_POST_SUCCESS = 'EDIT_EVENT_POST_SUCCESS';
export const EDIT_EVENT_POST_FAILED = 'EDIT_EVENT_POST_FAILED';

export const DELETE_EVENT_POST = 'DELETE_EVENT_POST';
export const DELETE_EVENT_POST_SUCCESS = 'DELETE_EVENT_POST_SUCCESS';
export const DELETE_EVENT_POST_FAILED = 'DELETE_EVENT_POST_FAILED';

/* GROUP */

export const OCCASION_GROUP_CREATE = 'OCCASION_GROUP_CREATE';
export const OCCASION_GROUP_CREATE_SUCCESS = 'OCCASION_GROUP_CREATE_SUCCESS';
export const OCCASION_GROUP_CREATE_FAILED = 'OCCASION_GROUP_CREATE_FAILED';
export const OCCASION_GROUP_VIEW = 'OCCASION_GROUP_VIEW';
export const OCCASION_GROUP_VIEW_SUCCESS = 'OCCASION_GROUP_VIEW_SUCCESS';
export const OCCASION_GROUP_VIEW_FAILED = 'OCCASION_GROUP_VIEW_FAILED';
export const OCCASION_GROUP_UPDATE = 'OCCASION_GROUP_UPDATE';
export const OCCASION_GROUP_UPDATE_SUCCESS = 'OCCASION_GROUP_UPDATE_SUCCESS';
export const OCCASION_GROUP_UPDATE_FAILED = 'OCCASION_GROUP_UPDATE_FAILED';

export const DELETE_OCCASION_GROUP = 'DELETE_OCCASION_GROUP';
export const DELETE_OCCASION_GROUP_SUCCESS = 'DELETE_OCCASION_GROUP_SUCCESS';
export const DELETE_OCCASION_GROUP_FAILED = 'DELETE_OCCASION_GROUP_FAILED';
export const SEARCH_GROUP_MEMBER = 'SEARCH_GROUP_MEMBER';
export const SEARCH_GROUP_MEMBER_SUCCESS = 'SEARCH_GROUP_MEMBER_SUCCESS';
export const SEARCH_GROUP_MEMBER_FAILED = 'SEARCH_GROUP_MEMBER_FAILED';

/* HOSPITALITY */

export const HOSPITALITY_CATEGORY_CREATE = 'HOSPITALITY_CATEGORY_CREATE';
export const HOSPITALITY_CATEGORY_CREATE_SUCCESS =
  'HOSPITALITY_CATEGORY_CREATE_SUCCESS';
export const HOSPITALITY_CATEGORY_CREATE_FAILED =
  'HOSPITALITY_CATEGORY_CREATE_FAILED';
export const HOSPITALITY_CATEGORY_VIEW = 'HOSPITALITY_CATEGORY_VIEW';
export const HOSPITALITY_CATEGORY_VIEW_SUCCESS =
  'HOSPITALITY_CATEGORY_VIEW_SUCCESS';
export const HOSPITALITY_CATEGORY_VIEW_FAILED =
  'HOSPITALITY_CATEGORY_VIEW_FAILED';
export const HOSPITALITY_CATEGORY_UPDATE = 'HOSPITALITY_CATEGORY_UPDATE';
export const HOSPITALITY_CATEGORY_UPDATE_SUCCESS =
  'HOSPITALITY_CATEGORY_UPDATE_SUCCESS';
export const HOSPITALITY_CATEGORY_UPDATE_FAILED =
  'HOSPITALITY_CATEGORY_UPDATE_FAILED';
export const GET_CONTACTUS = 'GET_CONTACTUS';
export const GET_CONTACTUS_SUCCESS = 'GET_CONTACTUS_SUCCESS';
export const GET_CONTACTUS_FAILED = 'GET_CONTACTUS_FAILED';

export const DELETE_HOSPITALITY_CATEGORY = 'DELETE_HOSPITALITY_CATEGORY';
export const DELETE_HOSPITALITY_CATEGORY_SUCCESS =
  'DELETE_HOSPITALITY_CATEGORY_SUCCESS';
export const DELETE_HOSPITALITY_CATEGORY_FAILED =
  'DELETE_HOSPITALITY_CATEGORY_FAILED';

export const UPDATE_HOSPITALITY_PROVIDE_ANSWER =
  'UPDATE_HOSPITALITY_PROVIDE_ANSWER';

export const UPDATE_HOSPITALITY_PROVIDE_ANSWER_SUCCESS =
  'UPDATE_HOSPITALITY_PROVIDE_ANSWER_SUCCESS';

export const UPDATE_HOSPITALITY_PROVIDE_ANSWER_FAILED =
  'UPDATE_HOSPITALITY_PROVIDE_ANSWER_FAILED';

export const UPDATE_HOSPITALITY_ALL_ANSWERS = 'UPDATE_HOSPITALITY_ALL_ANSWERS';

export const UPDATE_HOSPITALITY_ALL_ANSWERS_SUCCESS =
  'UPDATE_HOSPITALITY_ALL_ANSWERS_SUCCESS';

export const UPDATE_HOSPITALITY_ALL_ANSWERS_FAILED =
  'UPDATE_HOSPITALITY_ALL_ANSWERS_FAILED';

export const GET_EXSL_EXPORT = 'GET_EXSL_EXPORT';
export const GET_EXSL_EXPORT_FAILED = 'GET_EXSL_EXPORT_FAILED';
export const GET_EXSL_EXPORT_SUCCESS = 'GET_EXSL_EXPORT_SUCCESS';

export const DOWNLOAD_EXSL_EXPORT = 'DOWNLOAD_EXSL_EXPORT';
export const DOWNLOAD_EXSL_EXPORT_FAILED = 'DOWNLOAD_EXSL_EXPORT_FAILED';
export const DOWNLOAD_EXSL_EXPORT_SUCCESS = 'DOWNLOAD_EXSL_EXPORT_SUCCESS';

/* TASK */
export const HOSPITALITY_TASK_CREATE = 'HOSPITALITY_TASK_CREATE';
export const HOSPITALITY_TASK_CREATE_SUCCESS =
  'HOSPITALITY_TASK_CREATE_SUCCESS';
export const HOSPITALITY_TASK_CREATE_FAILED = 'HOSPITALITY_TASK_CREATE_FAILED';
export const HOSPITALITY_TASK_VIEW = 'HOSPITALITY_TASK_VIEW';
export const HOSPITALITY_TASK_VIEW_SUCCESS = 'HOSPITALITY_TASK_VIEW_SUCCESS';
export const HOSPITALITY_TASK_VIEW_FAILED = 'HOSPITALITY_TASK_VIEW_FAILED';

export const HOSPITALITY_TASK_CREATE_UPDATE = 'HOSPITALITY_TASK_CREATE_UPDATE';
export const HOSPITALITY_TASK_CREATE_UPDATE_SUCCESS =
  'HOSPITALITY_TASK_CREATE_UPDATE_SUCCESS';
export const HOSPITALITY_TASK_CREATE_UPDATE_FAILED =
  'HOSPITALITY_TASK_CREATE_UPDATE_FAILED';

export const HOSPITALITY_TASK_DELETE = 'HOSPITALITY_TASK_DELETE';
export const HOSPITALITY_TASK_DELETE_SUCCESS =
  'HOSPITALITY_TASK_DELETE_SUCCESS';
export const HOSPITALITY_TASK_DELETE_FAILED = 'HOSPITALITY_TASK_DELETE_FAILED';

export const HOSPITALITY_TASK_RESPONDED_VIEW =
  'HOSPITALITY_TASK_RESPONDED_VIEW';
export const HOSPITALITY_TASK_RESPONDED_VIEW_SUCCESS =
  'HOSPITALITY_TASK_RESPONDED_VIEW_SUCCESS';
export const HOSPITALITY_TASK_RESPONDED_VIEW_FAILED =
  'HOSPITALITY_TASK_RESPONDED_VIEW_FAILED';

export const HOSPITALITY_TASK_NOTRESPONDED_VIEW =
  'HOSPITALITY_TASK_NOTRESPONDED_VIEW';
export const HOSPITALITY_TASK_NOTRESPONDED_VIEW_SUCCESS =
  'HOSPITALITY_TASK_NOTRESPONDED_VIEW_SUCCESS';
export const HOSPITALITY_TASK_NOTRESPONDED_VIEW_FAILED =
  'HOSPITALITY_TASK_NOTRESPONDED_VIEW_FAILED';

export const HOSPITALITY_TASK_PROVIDED_VIEW = 'HOSPITALITY_TASK_PROVIDED_VIEW';
export const HOSPITALITY_TASK_PROVIDED_VIEW_SUCCESS =
  'HOSPITALITY_TASK_PROVIDED_VIEW_SUCCESS';
export const HOSPITALITY_TASK_PROVIDED_VIEW_FAILED =
  'HOSPITALITY_TASK_PROVIDED_VIEW_FAILED';

export const HOSPITALITY_TASK_NOTPROVIDED_VIEW =
  'HOSPITALITY_TASK_NOTPROVIDED_VIEW';
export const HOSPITALITY_TASK_NOTPROVIDED_VIEW_SUCCESS =
  'HOSPITALITY_TASK_NOTPROVIDED_VIEW_SUCCESS';
export const HOSPITALITY_TASK_NOTPROVIDED_VIEW_FAILED =
  'HOSPITALITY_TASK_NOTPROVIDED_VIEW_FAILED';

export const HOSPITALITY_PROVIDED_TASK_ANSWER_DOWNLOAD =
  'HOSPITALITY_PROVIDED_TASK_ANSWER_DOWNLOAD';
export const HOSPITALITY_PROVIDED_TASK_ANSWER_DOWNLOAD_SUCCESS =
  'HOSPITALITY_PROVIDED_TASK_ANSWER_DOWNLOAD_SUCCESS';
export const HOSPITALITY_PROVIDED_TASK_ANSWER_DOWNLOAD_FAILED =
  'HOSPITALITY_PROVIDED_TASK_ANSWER_DOWNLOAD_FAILED';

export const HOSPITALITY_REQUESTED_TASK_ANSWER_DOWNLOAD =
  'HOSPITALITY_REQUESTED_TASK_ANSWER_DOWNLOAD';
export const HOSPITALITY_REQUESTED_TASK_ANSWER_DOWNLOAD_SUCCESS =
  'HOSPITALITY_REQUESTED_TASK_ANSWER_DOWNLOAD_SUCCESS';
export const HOSPITALITY_REQUESTED_TASK_ANSWER_DOWNLOAD_FAILED =
  'HOSPITALITY_REQUESTED_TASK_ANSWER_DOWNLOAD_FAILED';

export const HOSPITALITY_REQUESTED_TASK_ANSWER_DELETE =
  'HOSPITALITY_REQUESTED_TASK_ANSWER_DELETE';
export const HOSPITALITY_REQUESTED_TASK_ANSWER_DELETE_SUCCESS =
  'const HOSPITALITY_REQUESTED_TASK_ANSWER_DELETE_SUCCESS';
export const HOSPITALITY_REQUESTED_TASK_ANSWER_DELETE_FAILED =
  'HOSPITALITY_REQUESTED_TASK_ANSWER_DELETE_FAILED';

export const HOSPITALITY_PROVIDED_TASK_ANSWER_DELETE =
  'HOSPITALITY_PROVIDED_TASK_ANSWER_DELETE';
export const HOSPITALITY_PROVIDED_TASK_ANSWER_DELETE_SUCCESS =
  'HOSPITALITY_PROVIDED_TASK_ANSWER_DELETE_SUCCESS';
export const HOSPITALITY_PROVIDED_TASK_ANSWER_DELETE_FAILED =
  'HOSPITALITY_PROVIDED_TASK_ANSWER_DELETE_FAILED';

export const HOSPITALITY_PROVIDED_TASK_ANSWER_UPDATE =
  'HOSPITALITY_PROVIDED_TASK_ANSWER_UPDATE';
export const HOSPITALITY_PROVIDED_TASK_ANSWER_UPDATE_SUCCESS =
  'HOSPITALITY_PROVIDED_TASK_ANSWER_UPDATE_SUCCESS';
export const HOSPITALITY_PROVIDED_TASK_ANSWER_UPDATE_FAILED =
  'HOSPITALITY_PROVIDED_TASK_ANSWER_UPDATE_FAILED';
export const HOSPITALITY_TASK_PROVIDE_ANSWER =
  'HOSPITALITY_TASK_PROVIDE_ANSWER';
export const HOSPITALITY_TASK_PROVIDE_ANSWER_SUCCESS =
  'HOSPITALITY_TASK_PROVIDE_ANSWER_SUCCESS';
export const HOSPITALITY_TASK_PROVIDE_ANSWER_FAILED =
  'HOSPITALITY_TASK_PROVIDE_ANSWER_FAILED';

export const GET_HOSPITALITY_TASK_PUBLIC_ANSWER =
  'GET_HOSPITALITY_TASK_PUBLIC_ANSWER';
export const GET_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS =
  'GET_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS';
export const GET_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED =
  'GET_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED';

export const UPDATE_HOSPITALITY_TASK_PUBLIC_ANSWER =
  'UPDATE_HOSPITALITY_TASK_PUBLIC_ANSWER';
export const UPDATE_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS =
  'UPDATE_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS';
export const UPDATE_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED =
  'UPDATE_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED';

export const DELETE_HOSPITALITY_TASK_PUBLIC_ANSWER =
  'DELETE_HOSPITALITY_TASK_PUBLIC_ANSWER';
export const DELETE_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS =
  'DELETE_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS';
export const DELETE_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED =
  'DELETE_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED';

export const CREATE_HOSPITALITY_TASK_PUBLIC_ANSWER =
  'CREATE_HOSPITALITY_TASK_PUBLIC_ANSWER';
export const CREATE_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS =
  'CREATE_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS';
export const CREATE_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED =
  'CREATE_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED';

export const GET_ALL_TASKS = 'GET_ALL_TASKS';
export const GET_ALL_TASKS_FAILED = 'GET_ALL_TASKS_FAILED';
export const GET_ALL_TASKS_SUCCESS = 'GET_ALL_TASKS_SUCCESS';

export const RESET_NOTPROVIDEDTASKS = 'RESET_NOTPROVIDEDTASKS';

export const SEARCH_NOTPROVIDED_TASKS = 'SEARCH_NOTPROVIDED_TASKS';
export const SEARCH_NOTPROVIDED_TASKS_FAILED =
  'SEARCH_NOTPROVIDED_TASKS_FAILED';
export const SEARCH_NOTPROVIDED_TASKS_SUCCESS =
  'SEARCH_NOTPROVIDED_TASKS_SUCCESS';

export const RESET_PROVIDEDTASKS = 'RESET_PROVIDEDTASKS';

export const SEARCH_PROVIDED_TASKS = 'SEARCH_PROVIDED_TASKS';
export const SEARCH_PROVIDED_TASKS_FAILED = 'SEARCH_PROVIDED_TASKS_FAILED';
export const SEARCH_PROVIDED_TASKS_SUCCESS = 'SEARCH_PROVIDED_TASKS_SUCCESS';

export const SEARCH_RESPONDED_TASKS = 'SEARCH_RESPONDED_TASKS';
export const SEARCH_RESPONDED_TASKS_FAILED = 'SEARCH_RESPONDED_TASKS_FAILED';
export const SEARCH_RESPONDED_TASKS_SUCCESS = 'SEARCH_RESPONDED_TASKS_SUCCESS';
export const SEARCH_NOTRESPONDED_TASKS = 'SEARCH_NOTRESPONDED_TASKS';
export const SEARCH_NOTRESPONDED_TASKS_FAILED =
  'SEARCH_NOTRESPONDED_TASKS_FAILED';
export const SEARCH_NOTRESPONDED_TASKS_SUCCESS =
  'SEARCH_NOTRESPONDED_TASKS_SUCCESS';
export const RESET_RESPONDEDTASKS = 'RESET_RESPONDEDTASKS';
export const RESET_NOTRESPONDEDTASKS = 'RESET_NOTRESPONDEDTASKS';

/* SPONSORSHIP */
export const OCCASION_SPONSORSHIP_CREATE = 'OCCASION_SPONSORSHIP_CREATE';
export const OCCASION_SPONSORSHIP_CREATE_SUCCESS =
  'OCCASION_SPONSORSHIP_CREATE_SUCCESS';
export const OCCASION_SPONSORSHIP_CREATE_FAILED =
  'OCCASION_SPONSORSHIP_CREATE_FAILED';
export const OCCASION_SPONSORSHIP_VIEW = 'OCCASION_SPONSORSHIP_VIEW';
export const OCCASION_SPONSORSHIP_VIEW_SUCCESS =
  'OCCASION_SPONSORSHIP_VIEW_SUCCESS';
export const OCCASION_SPONSORSHIP_VIEW_FAILED =
  'OCCASION_SPONSORSHIP_VIEW_FAILED';

export const OCCASION_SPONSORSHIP_DELETE = 'OCCASION_SPONSORSHIP_DELETE';
export const OCCASION_SPONSORSHIP_DELETE_FAILED =
  'OCCASION_SPONSORSHIP_DELETE_FAILED';
export const OCCASION_SPONSORSHIP_DELETE_SUCCESS =
  'OCCASION_SPONSORSHIP_DELETE_SUCCESS';
export const OCCASION_SPONSORSHIP_UPDATE = 'OCCASION_SPONSORSHIP_UPDATE';
export const OCCASION_SPONSORSHIP_UPDATE_FAILED =
  'OCCASION_SPONSORSHIP_UPDATE_FAILED';
export const OCCASION_SPONSORSHIP_UPDATE_SUCCESS =
  'OCCASION_SPONSORSHIP_UPDATE_SUCCESS';

/* IMPORTANT CONTACT */
export const OCCASION_IMPORTANT_CONTACT_CREATE =
  'OCCASION_IMPORTANT_CONTACT_CREATE';
export const OCCASION_IMPORTANT_CONTACT_CREATE_SUCCESS =
  'OCCASION_IMPORTANT_CONTACT_CREATE_SUCCESS';
export const OCCASION_IMPORTANT_CONTACT_CREATE_FAILED =
  'OCCASION_IMPORTANT_CONTACT_CREATE_FAILED';
export const OCCASION_IMPORTANT_CONTACT_VIEW =
  'OCCASION_IMPORTANT_CONTACT_VIEW';
export const OCCASION_IMPORTANT_CONTACT_VIEW_SUCCESS =
  'OCCASION_IMPORTANT_CONTACT_VIEW_SUCCESS';
export const OCCASION_IMPORTANT_CONTACT_VIEW_FAILED =
  'OCCASION_IMPORTANT_CONTACT_VIEW_FAILED';

export const OCCASION_IMPORTANT_CONTACT_UPDATE =
  'OCCASION_IMPORTANT_CONTACT_UPDATE';
export const OCCASION_IMPORTANT_CONTACT_UPDATE_SUCCESS =
  'OCCASION_IMPORTANT_CONTACT_UPDATE_SUCCESS';
export const OCCASION_IMPORTANT_CONTACT_UPDATE_FAILED =
  'OCCASION_IMPORTANT_CONTACT_UPDATE_FAILED';
export const OCCASION_IMPORTANT_CONTACT_DELETE =
  'OCCASION_IMPORTANT_CONTACT_DELETE';
export const OCCASION_IMPORTANT_CONTACT_DELETE_SUCCESS =
  'OCCASION_IMPORTANT_CONTACT_DELETE_SUCCESS';
export const OCCASION_IMPORTANT_CONTACT_DELETE_FAILED =
  'OCCASION_IMPORTANT_CONTACT_DELETE_FAILED';

/* LIVE VIDEO */
export const OCCASION_LIVE_VIDEO_CREATE = 'OCCASION_LIVE_VIDEO_CREATE';
export const OCCASION_LIVE_VIDEO_CREATE_SUCCESS =
  'OCCASION_LIVE_VIDEO_CREATE_SUCCESS';
export const OCCASION_LIVE_VIDEO_CREATE_FAILED =
  'OCCASION_LIVE_VIDEO_CREATE_FAILED';
export const OCCASION_LIVE_VIDEO_VIEW = 'OCCASION_LIVE_VIDEO_VIEW';
export const OCCASION_LIVE_VIDEO_VIEW_SUCCESS =
  'OCCASION_LIVE_VIDEO_VIEW_SUCCESS';
export const OCCASION_LIVE_VIDEO_VIEW_FAILED =
  'OCCASION_LIVE_VIDEO_VIEW_FAILED';
export const OCCASION_LIVE_VIDEO_DELETE = 'OCCASION_LIVE_VIDEO_DELETE';
export const OCCASION_LIVE_VIDEO_DELETE_SUCCESS =
  'OCCASION_LIVE_VIDEO_DELETE_SUCCESS';
export const OCCASION_LIVE_VIDEO_DELETE_FAILED =
  'OCCASION_LIVE_VIDEO_DELETE_FAILED';
/* SOS */

export const OCCASION_SOS_CREATE = 'OCCASION_SOS_CREATE';
export const OCCASION_SOS_CREATE_SUCCESS = 'OCCASION_SOS_CREATE_SUCCESS';
export const OCCASION_SOS_CREATE_FAILED = 'OCCASION_SOS_CREATE_FAILED';
export const OCCASION_SOS_VIEW = 'OCCASION_SOS_VIEW';
export const OCCASION_SOS_VIEW_SUCCESS = 'OCCASION_SOS_VIEW_SUCCESS';
export const OCCASION_SOS_VIEW_FAILED = 'OCCASION_SOS_VIEW_FAILED';
export const OCCASION_SOS_DELETE = 'OCCASION_SOS_DELETE';
export const OCCASION_SOS_DELETE_SUCCESS = 'OCCASION_SOS_DELETE_SUCCESS';
export const OCCASION_SOS_DELETE_FAILED = 'OCCASION_SOS_DELETE_FAILED';
export const OCCASION_SOS_UPDATE = 'OCCASION_SOS_UPDATE';
export const OCCASION_SOS_UPDATE_SUCCESS = 'OCCASION_SOS_UPDATE_SUCCESS';
export const OCCASION_SOS_UPDATE_FAILED = 'OCCASION_SOS_UPDATE_FAILED';

/* AWARD */
export const OCCASION_AWARD_CREATE = 'OCCASION_AWARD_CREATE';
export const OCCASION_AWARD_CREATE_SUCCESS = 'OCCASION_AWARD_CREATE_SUCCESS';
export const OCCASION_AWARD_CREATE_FAILED = 'OCCASION_AWARD_CREATE_FAILED';
export const OCCASION_AWARD_VIEW = 'OCCASION_AWARD_VIEW';
export const OCCASION_AWARD_VIEW_SUCCESS = 'OCCASION_AWARD_VIEW_SUCCESS';
export const OCCASION_AWARD_VIEW_FAILED = 'OCCASION_AWARD_VIEW_FAILED';

export const UPDATE_AWARD = 'UPDATE_AWARD';
export const UPDATE_AWARD_FAILED = ' UPDATE_AWARD_FAILED';
export const UPDATE_AWARD_SUCCESS = ' UPDATE_AWARD_SUCCESS';
export const DELETE_AWARD = ' DELETE_AWARD';
export const DELETE_AWARD_FAILED = ' DELETE_AWARD_FAILED';
export const DELETE_AWARD_SUCCESS = ' DELETE_AWARD_SUCCESS';

/* TICKET */
export const OCCASION_TICKET_CREATE = 'OCCASION_TICKET_CREATE';
export const OCCASION_TICKET_CREATE_SUCCESS = 'OCCASION_TICKET_CREATE_SUCCESS';
export const OCCASION_TICKET_CREATE_FAILED = 'OCCASION_TICKET_CREATE_FAILED';
export const OCCASION_TICKET_VIEW = 'OCCASION_TICKET_VIEW';
export const OCCASION_TICKET_VIEW_SUCCESS = 'OCCASION_TICKET_VIEW_SUCCESS';
export const OCCASION_TICKET_VIEW_FAILED = 'OCCASION_TICKET_VIEW_FAILED';
export const OCCASION_TICKET_UPDATE = 'OCCASION_TICKET_UPDATE';
export const OCCASION_TICKET_UPDATE_SUCCESS = 'OCCASION_TICKET_UPDATE_SUCCESS';
export const OCCASION_TICKET_UPDATE_FAILED = 'OCCASION_TICKET_UPDATE_FAILED';

/* COMPETITION */

export const COMPETITION_CREATE = 'COMPETITION_CREATE';
export const COMPETITION_CREATE_FAILED = 'COMPETITION_CREATE_FAILED';
export const COMPETITION_CREATE_SUCCESS = 'COMPETITION_CREATE_SUCCESS';
export const COMPETITION_VIEW = 'COMPETITION_VIEW';
export const COMPETITION_VIEW_FAILED = 'COMPETITION_VIEW_FAILED';
export const COMPETITION_VIEW_SUCCESS = 'COMPETITION_VIEW_SUCCESS';
export const DELETE_COMPETITION = 'DELETE_COMPETITION';
export const DELETE_COMPETITION_FAILED = 'DELETE_COMPETITION_FAILED';
export const DELETE_COMPETITION_SUCCESS = 'DELETE_COMPETITION_SUCCESS';
export const UPDATE_COMPETITION = 'UPDATE_COMPETITION';
export const UPDATE_COMPETITION_FAILED = 'UPDATE_COMPETITION_FAILED';
export const UPDATE_COMPETITION_SUCCESS = 'UPDATE_COMPETITION_SUCCESS';

export const CREATE_PARTICIPANT = 'CREATE_PARTICIPANT';
export const CREATE_PARTICIPANT_BY_CSV = 'CREATE_PARTICIPANT_BY_CSV';
export const CREATE_PARTICIPANT_BY_CSV_FAILED =
  'CREATE_PARTICIPANT_BY_CSV_FAILED';
export const CREATE_PARTICIPANT_BY_CSV_SUCCESS =
  'CREATE_PARTICIPANT_BY_CSV_SUCCESS';
export const CREATE_PARTICIPANT_FAILED = 'CREATE_PARTICIPANT_FAILED';
export const CREATE_PARTICIPANT_SUCCESS = 'CREATE_PARTICIPANT_SUCCESS';
export const GET_PARTICIPANT = 'GET_PARTICIPANT';
export const GET_PARTICIPANT_FAILED = 'GET_PARTICIPANT_FAILED';
export const GET_PARTICIPANT_SUCCESS = 'GET_PARTICIPANT_SUCCESS';
export const DELETE_PARTICIPANT = 'DELETE_PARTICIPANT';
export const DELETE_PARTICIPANT_FAILED = 'DELETE_PARTICIPANT_FAILED';
export const DELETE_PARTICIPANT_SUCCESS = 'DELETE_PARTICIPANT_SUCCESS';
export const UPDATE_PARTICIPANT = 'UPDATE_PARTICIPANT';
export const UPDATE_PARTICIPANT_FAILED = 'UPDATE_PARTICIPANT_FAILED';
export const UPDATE_PARTICIPANT_SUCCESS = 'UPDATE_PARTICIPANT_SUCCESS';

/* TICKET VARIANT */
export const OCCASION_TICKET_VARIANT_CREATE = 'OCCASION_TICKET_VARIANT_CREATE';
export const OCCASION_TICKET_VARIANT_CREATE_SUCCESS =
  'OCCASION_TICKET_VARIANT_CREATE_SUCCESS';
export const OCCASION_TICKET_VARIANT_CREATE_FAILED =
  'OCCASION_TICKET_VARIANT_CREATE_FAILED';
export const OCCASION_TICKET_VARIANT_VIEW = 'OCCASION_TICKET_VARIANT_VIEW';
export const OCCASION_TICKET_VARIANT_VIEW_SUCCESS =
  'OCCASION_TICKET_VARIANT_VIEW_SUCCESS';
export const OCCASION_TICKET_VARIANT_VIEW_FAILED =
  'OCCASION_TICKET_VARIANT_VIEW_FAILED';

export const UPDATE_TICKET_VARIANT = 'UPDATE_TICKET_VARIANT';
export const UPDATE_TICKET_VARIANT_FAILED = 'UPDATE_TICKET_VARIANT_FAILED';
export const UPDATE_TICKET_VARIANT_SUCCESS = 'UPDATE_TICKET_VARIANT_SUCCESS';

/* TICKET VARIANT FORM */
export const OCCASION_TICKET_VARIANT_FORM_CREATE =
  'OCCASION_TICKET_VARIANT_FORM_CREATE';
export const OCCASION_TICKET_VARIANT_FORM_CREATE_SUCCESS =
  'OCCASION_TICKET_VARIANT_FORM_CREATE_SUCCESS';
export const OCCASION_TICKET_VARIANT_FORM_CREATE_FAILED =
  'OCCASION_TICKET_VARIANT_FORM_CREATE_FAILED';
export const OCCASION_TICKET_VARIANT_FORM_VIEW =
  'OCCASION_TICKET_VARIANT_FORM_VIEW';
export const OCCASION_TICKET_VARIANT_FORM_VIEW_SUCCESS =
  'OCCASION_TICKET_VARIANT_FORM_VIEW_SUCCESS';
export const OCCASION_TICKET_VARIANT_FORM_VIEW_FAILED =
  'OCCASION_TICKET_VARIANT_FORM_VIEW_FAILED';

/* TICKET GOOGLE SHEET */
export const GET_TICKET_GOOGLE_SHEET = 'GET_TICKET_GOOGLE_SHEET';
export const GET_TICKET_GOOGLE_SHEET_FAILED = 'GET_TICKET_GOOGLE_SHEET_FAILED';
export const GET_TICKET_GOOGLE_SHEET_SUCCESS =
  'GET_TICKET_GOOGLE_SHEET_SUCCESS';
export const TICKET_GOOGLE_SHEET_CREATE = 'TICKET_GOOGLE_SHEET_CREATE';
export const TICKET_GOOGLE_SHEET_CREATE_FAILED =
  'TICKET_GOOGLE_SHEET_CREATE_FAILED';
export const TICKET_GOOGLE_SHEET_CREATE_SUCCESS =
  'TICKET_GOOGLE_SHEET_CREATE_SUCCESS';
export const DELETE_TICKET_GOOGLE_SHEET = 'DELETE_TICKET_GOOGLE_SHEET';
export const DELETE_TICKET_GOOGLE_SHEET_SUCCESS =
  'DELETE_TICKET_GOOGLE_SHEET_SUCCESS';
export const DELETE_TICKET_GOOGLE_SHEET_FAILED =
  'DELETE_TICKET_GOOGLE_SHEET_FAILED';

/* QUESTION */
export const OCCASION_QUESTION_CREATE = 'OCCASION_QUESTION_CREATE';
export const OCCASION_QUESTION_CREATE_SUCCESS =
  'OCCASION_QUESTION_CREATE_SUCCESS';
export const OCCASION_QUESTION_CREATE_FAILED =
  'OCCASION_QUESTION_CREATE_FAILED';
export const OCCASION_QUESTION_VIEW = 'OCCASION_QUESTION_VIEW';
export const OCCASION_QUESTION_VIEW_SUCCESS = 'OCCASION_QUESTION_VIEW_SUCCESS';
export const OCCASION_QUESTION_VIEW_FAILED = 'OCCASION_QUESTION_VIEW_FAILED';

/* ADMIN */

export const CREATE_ADMIN = 'CREATE_ADMIN';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAILED = 'CREATE_ADMIN_FAILED';
export const GET_ADMIN_PAGINATION = 'GET_ADMIN_PAGINATION';
export const GET_ADMIN_PAGINATION_SUCCESS = 'GET_ADMIN_PAGINATION_SUCCESS';
export const GET_ADMIN_PAGINATION_FAILED = 'GET_ADMIN_PAGINATION_FAILED';
export const CREATE_ADMIN_OCCASION = 'CREATE_ADMIN_OCCASION';
export const CREATE_ADMIN_OCCASION_SUCCESS = 'CREATE_ADMIN_OCCASION_SUCCESS';
export const CREATE_ADMIN_OCCASION_FAILED = 'CREATE_ADMIN_OCCASION_FAILED';
export const GET_ADMIN_OCCASION_MEMBER = 'GET_ADMIN_OCCASION_MEMBER';
export const GET_ADMIN_OCCASION_MEMBER_SUCCESS =
  'GET_ADMIN_OCCASION_MEMBER_SUCCESS';
export const GET_ADMIN_OCCASION_MEMBER_FAILED =
  'GET_ADMIN_OCCASION_MEMBER_FAILED';
export const EDIT_ADMIN = 'EDIT_ADMIN';
export const EDIT_ADMIN_SUCCESS = 'EDIT_ADMIN_SUCCESS';
export const EDIT_ADMIN_FAILED = 'EDIT_ADMIN_FAILED';
export const GET_ADMIN_BY_SEARCH = 'GET_ADMIN_BY_SEARCH';
export const GET_ADMIN_BY_SEARCH_SUCCESS = 'GET_ADMIN_BY_SEARCH_SUCCESS';
export const GET_ADMIN_BY_SEARCH_FAILED = 'GET_ADMIN_BY_SEARCH_SUCCESS';

export const UPDATE_ADMIN_OCCASION = 'UPDATE_ADMIN_OCCASION';
export const UPDATE_ADMIN_OCCASION_SUCCESS = 'UPDATE_ADMIN_OCCASION_SUCCESS';
export const UPDATE_ADMIN_OCCASION_FAILED = 'UPDATE_ADMIN_OCCASION_FAILED';
export const DELETE_ADMIN_OCCASION_MEMBER = 'DELETE_ADMIN_OCCASION_MEMBER';
export const DELETE_ADMIN_OCCASION_MEMBER_SUCCESS =
  'DELETE_ADMIN_OCCASION_MEMBER_SUCCESS';
export const DELETE_ADMIN_OCCASION_MEMBER_FAILED =
  'DELETE_ADMIN_OCCASION_MEMBER_FAILED';

/* FROM INTERNAL TEAM */
export const CREATE_SOCIANA_INTERNAL_USER = 'CREATE_SOCIANA_INTERNAL_USER';
export const CREATE_SOCIANA_INTERNAL_USER_SUCCESS =
  'CREATE_SOCIANA_INTERNAL_USER_SUCCESS';
export const CREATE_SOCIANA_INTERNAL_USER_FAILED =
  'CREATE_SOCIANA_INTERNAL_USER_FAILED';
export const GET_SOCIANA_INTERNAL_USER_PAGINATION =
  'GET_SOCIANA_INTERNAL_USER_PAGINATION';
export const GET_SOCIANA_INTERNAL_USER_PAGINATION_SUCCESS =
  'GET_SOCIANA_INTERNAL_USER_PAGINATION_SUCCESS';
export const GET_SOCIANA_INTERNAL_USER_PAGINATION_FAILED =
  'GET_SOCIANA_INTERNAL_USER_PAGINATION_FAILED';
export const SEARCH_INTERNAL_TEAM_USERS = 'SEARCH_INTERNAL_TEAM_USERS';
export const SEARCH_INTERNAL_TEAM_USERS_SUCCESS =
  'SEARCH_INTERNAL_TEAM_USERS_SUCCESS';
export const SEARCH_INTERNAL_TEAM_USERS_FAILED =
  'SEARCH_INTERNAL_TEAM_USERS_FAILED';
export const GET_CURRENT_OCCASION_INTERNAL_MEMBERS =
  'GET_CURRENT_OCCASION_INTERNAL_MEMBERS';
export const GET_CURRENT_OCCASION_INTERNAL_MEMBERS_SUCCESS =
  'GET_CURRENT_OCCASION_INTERNAL_MEMBERS_SUCCESS';
export const GET_CURRENT_OCCASION_INTERNAL_MEMBERS_FAILED =
  'GET_CURRENT_OCCASION_INTERNAL_MEMBERS_FAILED';
export const ASSIGN_INTERNAL_TEAM_MEMBER = 'ASSIGN_INTERNAL_TEAM_MEMBER';
export const ASSIGN_INTERNAL_TEAM_MEMBER_SUCCESS =
  'ASSIGN_INTERNAL_TEAM_MEMBER_SUCCESS';
export const ASSIGN_INTERNAL_TEAM_MEMBER_FAILED =
  'ASSIGN_INTERNAL_TEAM_MEMBER_FAILED';
export const EDIT_SOCIANA_INTERNAL_USER = 'EDIT_SOCIANA_INTERNAL_USER';
export const EDIT_SOCIANA_INTERNAL_USER_SUCCESS =
  'EDIT_SOCIANA_INTERNAL_USER_SUCCESS';
export const EDIT_SOCIANA_INTERNAL_USER_FAILED =
  'EDIT_SOCIANA_INTERNAL_USER_FAILED';
export const GET_INTERNAL_USERS_PARTICIPATED_OCCASIONS =
  'GET_INTERNAL_USERS_PARTICIPATED_OCCASIONS';
export const GET_INTERNAL_USERS_PARTICIPATED_OCCASIONS_SUCCESS =
  'GET_INTERNAL_USERS_PARTICIPATED_OCCASIONS_SUCCESS';
export const GET_INTERNAL_USERS_PARTICIPATED_OCCASIONS_FAILED =
  'GET_INTERNAL_USERS_PARTICIPATED_OCCASIONS_FAILED';
export const SEARCH_OCCASIONS = 'SEARCH_OCCASIONS';
export const SEARCH_OCCASIONS_SUCCESS = 'SEARCH_OCCASIONS_SUCCESS';
export const SEARCH_OCCASIONS_FAILED = 'SEARCH_OCCASIONS_FAILED';
export const EDIT_INTERNAL_USER_ACCESS_SPECIFICATIONS =
  'EDIT_INTERNAL_USER_ACCESS_SPECIFICATIONS';
export const EDIT_INTERNAL_USER_ACCESS_SPECIFICATIONS_SUCCESS =
  'EDIT_INTERNAL_USER_ACCESS_SPECIFICATIONS_SUCCESS';
export const EDIT_INTERNAL_USER_ACCESS_SPECIFICATIONS_FAILED =
  'EDIT_INTERNAL_USER_ACCESS_SPECIFICATIONS_FAILED';
export const GET_INTERNAL_TEAM_PENDING_INVITATION =
  'GET_INTERNAL_TEAM_PENDING_INVITATION';
export const GET_INTERNAL_TEAM_PENDING_INVITATION_SUCCESS =
  'GET_INTERNAL_TEAM_PENDING_INVITATION_SUCCESS';
export const GET_INTERNAL_TEAM_PENDING_INVITATION_FAILED =
  'GET_INTERNAL_TEAM_PENDING_INVITATION_FAILED';
export const GET_INTERNAL_TEAM_BY_SEARCH = 'GET_INTERNAL_TEAM_BY_SEARCH';
export const GET_INTERNAL_TEAM_BY_SEARCH_SUCCESS =
  'GET_INTERNAL_TEAM_BY_SEARCH_SUCCESS';
export const GET_INTERNAL_TEAM_BY_SEARCH_FAILED =
  'GET_INTERNAL_TEAM_BY_SEARCH_SUCCESS';

export const UPDATE_ASSIGN_INTERNAL_TEAM_MEMBER =
  'UPDATE_ASSIGN_INTERNAL_TEAM_MEMBER';
export const UPDATE_ASSIGN_INTERNAL_TEAM_MEMBER_SUCCESS =
  'UPDATE_ASSIGN_INTERNAL_TEAM_MEMBER_SUCCESS';
export const UPDATE_ASSIGN_INTERNAL_TEAM_MEMBER_FAILED =
  'UPDATE_ASSIGN_INTERNAL_TEAM_MEMBER_FAILED';

export const DELETE_INTERNAL_USERS_PARTICIPATED_OCCASIONS =
  'DELETE_INTERNAL_USERS_PARTICIPATED_OCCASIONS';
export const DELETE_INTERNAL_USERS_PARTICIPATED_OCCASIONS_SUCCESS =
  'DELETE_INTERNAL_USERS_PARTICIPATED_OCCASIONS_SUCCESS';
export const DELETE_INTERNAL_USERS_PARTICIPATED_OCCASIONS_FAILED =
  'DELETE_INTERNAL_USERS_PARTICIPATED_OCCASIONS_FAILED';

/* ACCESS CONTROLS */
export const CREATE_ACCESS_CONTROL = 'CREATE_ACCESS_CONTROL';
export const CREATE_ACCESS_CONTROL_SUCCESS = 'CREATE_ACCESS_CONTROL_SUCCESS';
export const CREATE_ACCESS_CONTROL_FAILED = 'CREATE_ACCESS_CONTROL_FAILED';
export const UPDATE_ACCESS_CONTROL = 'UPDATE_ACCESS_CONTROL';
export const UPDATE_ACCESS_CONTROL_SUCCESS = 'UPDATE_ACCESS_CONTROL_SUCCESS';
export const UPDATE_ACCESS_CONTROL_FAILED = 'UPDATE_ACCESS_CONTROL_FAILED';
export const GET_ACCESS_CONTROLS = 'GET_ACCESS_CONTROLS';
export const GET_ACCESS_CONTROLS_SUCCESS = 'GET_ACCESS_CONTROLS_SUCCESS';
export const GET_ACCESS_CONTROLS_FAILED = 'GET_ACCESS_CONTROLS_FAILED';

/* FEATURE CONTROLS */
export const CREATE_FEATURE_CONTROL = 'CREATE_FEATURE_CONTROL';
export const CREATE_FEATURE_CONTROL_SUCCESS = 'CREATE_FEATURE_CONTROL_SUCCESS';
export const CREATE_FEATURE_CONTROL_FAILED = 'CREATE_FEATURE_CONTROL_FAILED';
export const UPDATE_FEATURE_CONTROL = 'UPDATE_FEATURE_CONTROL';
export const UPDATE_FEATURE_CONTROL_SUCCESS = 'UPDATE_FEATURE_CONTROL_SUCCESS';
export const UPDATE_FEATURE_CONTROL_FAILED = 'UPDATE_FEATURE_CONTROL_FAILED';
export const GET_FEATURE_CONTROLS = 'GET_FEATURE_CONTROLS';
export const GET_FEATURE_CONTROLS_SUCCESS = 'GET_FEATURE_CONTROLS_SUCCESS';
export const GET_FEATURE_CONTROLS_FAILED = 'GET_FEATURE_CONTROLS_FAILED';

/* REPORTS */

export const GET_REPORTS_WITH_PAGINATION = 'GET_REPORTS_WITH_PAGINATION';
export const GET_REPORTS_WITH_PAGINATION_SUCCESS =
  'GET_REPORTS_WITH_PAGINATION_SUCCESS';
export const GET_REPORTS_WITH_PAGINATION_FAILED =
  'GET_REPORTS_WITH_PAGINATION_FAILED';

/* LIKES */

export const GET_REPORTED_POST_LIKES_WITH_PAGINATION =
  'GET_LIKES_WITH_PAGINATION';
export const GET_REPORTED_POST_LIKES_WITH_PAGINATION_SUCCESS =
  'GET_LIKES_WITH_PAGINATION_SUCCESS';
export const GET_REPORTED_POST_LIKES_WITH_PAGINATION_FAILED =
  'GET_LIKES_WITH_PAGINATION_FAILED';

/* COMMENTS */
export const GET_REPORTED_POST_COMMENTS_WITH_PAGINATION =
  'GET_COMMENTS_WITH_PAGINATION';
export const GET_REPORTED_POST_COMMENTS_WITH_PAGINATION_SUCCESS =
  'GET_COMMENTS_WITH_PAGINATION_SUCCESS';
export const GET_REPORTED_POST_COMMENTS_WITH_PAGINATION_FAILED =
  '  GET_COMMENTS_WITH_PAGINATION_FAILED';

/* FORGOT PASSWORD */
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

/* RESET PASSWORD */
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

/* POST */
export const CREATE_POST = 'CREATE_POST';
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export const CREATE_POST_FAILED = 'CREATE_POST_FAILED';

export const GET_POST_PAGINATION = 'GET_POST_PAGINATION';
export const GET_POST_PAGINATION_SUCCESS = 'GET_POST_PAGINATION_SUCCESS';
export const GET_POST_PAGINATION_FAILED = 'GET_POST_PAGINATION_FAILED';

export const EDIT_POST = 'EDIT_POST';
export const EDIT_POST_SUCCESS = 'EDIT_POST_SUCCESS';
export const EDIT_POST_FAILED = 'EDIT_POST_FAILED';

export const DELETE_POST = 'DELETE_POST';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export const DELETE_POST_FAILED = 'DELETE_POST_FAILED';

export const DOWNLOAD_EXSL_EXPORT_USER_POST = 'DOWNLOAD_EXSL_EXPORT_USER_POST';
export const DOWNLOAD_EXSL_EXPORT_USER_POST_FAILED =
  'DOWNLOAD_EXSL_EXPORT_USER_POST_FAILED';
export const DOWNLOAD_EXSL_EXPORT_USER_POST_SUCCESS =
  'DOWNLOAD_EXSL_EXPORT_USER_POST_SUCCESS';

/*.USER.*/
export const GET_USER_PAGINATION = 'GET_USER_PAGINATION';
export const GET_USER_PAGINATION_SUCCESS = 'GET_USER_PAGINATION_SUCCESS';
export const GET_USER_PAGINATION_FAILED = 'GET_USER_PAGINATION_FAILED';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILED = 'EDIT_USER_FAILED';
export const GET_USER_BY_SEARCH = 'GET_USER_BY_SEARCH';
export const GET_USER_BY_SEARCH_SUCCESS = 'GET_USER_BY_SEARCH_SUCCESS';
export const GET_USER_BY_SEARCH_FAILED = 'GET_USER_BY_SEARCH_SUCCESS';

export const SEARCH_USERS = 'SEARCH_USERS';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAILED = 'SEARCH_USERS_FAILED';

export const GET_USER_BY_OCCASION = 'GET_USER_BY_OCCASION';
export const GET_USER_BY_OCCASION_SUCCESS = 'GET_USER_BY_OCCASION_SUCCESS';
export const GET_USER_BY_OCCASION_FAILED = 'GET_USER_BY_OCCASION_FAILED';

export const USER_OCCASION_VIEW = 'USER_OCCASION_VIEW';
export const USER_OCCASION_VIEW_SUCCESS = 'USER_OCCASION_VIEW_SUCCESS';
export const USER_OCCASION_VIEW_FAILED = 'USER_OCCASION_VIEW_FAILED';

export const QR_CODE_CREATE = 'QR_CODE_CREATE';
export const QR_CODE_CREATE_FAILED = 'QR_CODE_CREATE_FAILED';
export const QR_CODE_CREATE_SUCCESS = 'QR_CODE_CREATE_SUCCESS';

export const QR_CODE_DELETE = 'QR_CODE_DELETE';
export const QR_CODE_DELETE_FAILED = 'QR_CODE_DELETE_FAILED';
export const QR_CODE_DELETE_SUCCESS = 'QR_CODE_DELETE_SUCCESS';

/* NETWORK STATUS */
export const NETWORK_ONLINE = 'NETWORK_ONLINE';
export const NETWORK_OFFLINE = 'NETWORK_OFFLINE';

/* GALLERY */
export const CREATE_MEDIA = 'CREATE_MEDIA';
export const CREATE_IMAGES_SUCCESS = 'CREATE_IMAGES_SUCCESS';
export const CREATE_VIDEOS_SUCCESS = 'CREATE_VIDEOS_SUCCESS';
export const CREATE_MEDIA_FAILED = 'CREATE_MEDIA_FAILED';

export const GET_GALLERY_IMAGES = 'GET_GALLERY_IMAGES';
export const GET_GALLERY_IMAGES_SUCCESS = 'GET_GALLERY_IMAGES_SUCCESS';
export const GET_GALLERY_IMAGES_FAILED = 'GET_GALLERY_IMAGES_FAILED';

export const GET_GALLERY_VIDEOS = 'GET_GALLERY_VIDEOS';
export const GET_GALLERY_VIDEOS_SUCCESS = 'GET_GALLERY_VIDEOS_SUCCESS';
export const GET_GALLERY_VIDEOS_FAILED = 'GET_GALLERY_VIDEOS_FAILED';

export const UPLOAD_MEDIA_LIST = 'UPLOAD_MEDIA_LIST';

export const GET_GALLERY_INFO = 'GET_GALLERY_INFO';
export const GET_GALLERY_INFO_SUCCESS = 'GET_GALLERY_INFO_SUCCESS';
export const GET_GALLERY_INFO_FAILED = 'GET_GALLERY_INFO_FAILED';

export const DELETE_MEDIA = 'DELETE_MEDIA';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_FAILED = 'DELETE_MEDIA_FAILED';

export const CLEAR_UPLOAD_IMAGE_LIST = 'CLEAR_UPLOAD_IMAGE_LIST';

/*TRIGGER*/
/*LOCATION*/
export const GET_LOCATION_TRIGGER = 'GET_LOCATION_TRIGGER';
export const GET_LOCATION_TRIGGER_SUCCESS = 'GET_LOCATION_TRIGGER_SUCCESS';
export const GET_LOCATION_TRIGGER_FAILED = 'GET_LOCATION_TRIGGER_FAILED';
export const CREATE_LOCATION_TRIGGER = 'CREATE_LOCATION_TRIGGER';
export const CREATE_LOCATION_TRIGGER_SUCCESS =
  'CREATE_LOCATION_TRIGGER_SUCCESS';
export const CREATE_LOCATION_TRIGGER_FAILED = 'CREATE_LOCATION_TRIGGER_FAILED';

/*ACTION*/
export const GET_ACTION_TRIGGER = 'GET_ACTION_TRIGGER';
export const GET_ACTION_TRIGGER_SUCCESS = 'GET_ACTION_TRIGGER_SUCCESS';
export const GET_ACTION_TRIGGER_FAILED = 'GET_ACTION_TRIGGER_FAILED';
export const CREATE_ACTION_TRIGGER = 'CREATE_ACTION_TRIGGER';
export const CREATE_ACTION_TRIGGER_SUCCESS = 'CREATE_ACTION_TRIGGER_SUCCESS';
export const CREATE_ACTION_TRIGGER_FAILED = 'CREATE_ACTION_TRIGGER_FAILED';

/*TIME*/

export const UPDATE_TIME_TRIGGER_IS_PINNED = 'UPDATE_TIME_TRIGGER_IS_PINNED';
export const UPDATE_TIME_TRIGGER_IS_PINNED_FAILED =
  'UPDATE_TIME_TRIGGER_IS_PINNED_FAILED';
export const UPDATE_TIME_TRIGGER_IS_PINNED_SUCCESS =
  'UPDATE_TIME_TRIGGER_IS_PINNED_SUCCESS';

export const SEARCH_TIME_TRIGGER_BY_PINNED = 'SEARCH_TIME_TRIGGER_BY_PINNED';
export const SEARCH_TIME_TRIGGER_BY_PINNED_FAILED =
  'SEARCH_TIME_TRIGGER_BY_PINNED_FAILED';
export const SEARCH_TIME_TRIGGER_BY_PINNED_SUCCESS =
  'SEARCH_TIME_TRIGGER_BY_PINNED_SUCCESS';

export const GET_TIME_TRIGGER = 'GET_TIME_TRIGGER';
export const GET_TIME_TRIGGER_SUCCESS = 'GET_TIME_TRIGGER_SUCCESS';
export const GET_TIME_TRIGGER_FAILED = 'GET_TIME_TRIGGER_FAILED';
export const CREATE_TIME_TRIGGER = 'CREATE_TIME_TRIGGER';
export const CREATE_TIME_TRIGGER_SUCCESS = 'CREATE_TIME_TRIGGER_SUCCESS';
export const CREATE_TIME_TRIGGER_FAILED = 'CREATE_TIME_TRIGGER_FAILED';
export const UPDATE_TIME_TRIGGER = 'UPDATE_TIME_TRIGGER';
export const UPDATE_TIME_TRIGGER_SUCCESS = 'UPDATE_TIME_TRIGGER_SUCCESS';
export const UPDATE_TIME_TRIGGER_FAILED = 'UPDATE_TIME_TRIGGER_FAILED';
export const DELETE_TIME_TRIGGER = 'DELETE_TIME_TRIGGER';
export const DELETE_TIME_TRIGGER_SUCCESS = 'DELETE_TIME_TRIGGER_SUCCESS';
export const DELETE_TIME_TRIGGER_FAILED = 'DELETE_TIME_TRIGGER_FAILED';
export const UPDATE_ACTION_TRIGGER = 'UPDATE_ACTION_TRIGGER';
export const UPDATE_ACTION_TRIGGER_SUCCESS = 'UPDATE_ACTION_TRIGGER_SUCCESS';
export const UPDATE_ACTION_TRIGGER_FAILED = 'UPDATE_ACTION_TRIGGER_FAILED';
export const DELETE_ACTION_TRIGGER = 'DELETE_ACTION_TRIGGER';
export const DELETE_ACTION_TRIGGER_SUCCESS = 'DELETE_ACTION_TRIGGER_SUCCESS';
export const DELETE_ACTION_TRIGGER_FAILED = 'DELETE_ACTION_TRIGGER_FAILED';

export const UPDATE_LOCATION_TRIGGER = 'UPDATE_LOCATION_TRIGGER';
export const UPDATE_LOCATION_TRIGGER_SUCCESS =
  'UPDATE_LOCATION_TRIGGER_SUCCESS';
export const UPDATE_LOCATION_TRIGGER_FAILED = 'UPDATE_LOCATION_TRIGGER_FAILED';
export const DELETE_LOCATION_TRIGGER = 'DELETE_LOCATION_TRIGGER';
export const DELETE_LOCATION_TRIGGER_SUCCESS =
  'DELETE_LOCATION_TRIGGER_SUCCESS';
export const DELETE_LOCATION_TRIGGER_FAILED = 'DELETE_LOCATION_TRIGGER_FAILED';

/*LIVE STREAM*/
export const CREATE_LIVE_STREAM = 'CREATE_LIVE_STREAM';
export const CREATE_LIVE_STREAM_SUCCESS = 'CREATE_LIVE_STREAM_SUCCESS';
export const CREATE_LIVE_STREAM_FAILED = 'CREATE_LIVE_STREAM_FAILED';
export const GET_LIVE_STREAM = 'GET_LIVE_STREAM';
export const GET_LIVE_STREAM_SUCCESS = 'GET_LIVE_STREAM_SUCCESS';
export const GET_LIVE_STREAM_FAILED = 'GET_LIVE_STREAM_FAILED';
export const SHOW_LIVE_STREAM = 'SHOW_LIVE_STREAM';
export const SHOW_LIVE_STREAM_SUCCESS = 'SHOW_LIVE_STREAM_SUCCESS';
export const SHOW_LIVE_STREAM_FAILED = 'SHOW_LIVE_STREAM_FAILED';

/* MEMBERS */

export const GET_ALL_MEMBERS = 'GET_ALL_MEMBERS';
export const GET_ALL_MEMBERS_SUCCESS = 'GET_ALL_MEMBERS_SUCCESS';
export const GET_ALL_MEMBERS_FAILED = 'GET_ALL_MEMBERS_FAILED';

export const SEARCH_MEMBERS = 'SEARCH_MEMBERS';
export const SEARCH_MEMBERS_SUCCESS = 'SEARCH_MEMBERS_SUCCESS';
export const SEARCH_MEMBERS_FAILED = 'SEARCH_MEMBERS_FAILED';

export const RESET_MEMBERS = 'RESET_MEMBERS';

export const DELETE_MEMBERS = 'DELETE_MEMBERS';
export const DELETE_MEMBERS_SUCCESS = 'DELETE_MEMBERS_SUCCESS';
export const DELETE_MEMBERS_FAILED = 'DELETE_MEMBERS_FAILED';

export const UPDATE_MEMBERS = 'UPDATE_MEMBERS';
export const UPDATE_MEMBERS_SUCCESS = 'UPDATE_MEMBERS_SUCCESS';
export const UPDATE_MEMBERS_FAILED = 'UPDATE_MEMBERS_FAILED';

export const DOWNLOAD_MEMBER_EXLS = 'DOWNLOAD_MEMBER_EXLS';
export const DOWNLOAD_MEMBER_EXLS_FAILED = 'DOWNLOAD_MEMBER_EXLS_FAILED';
export const DOWNLOAD_MEMBER_EXLS_SUCCESS = 'DOWNLOAD_MEMBER_EXLS_SUCCESS';

export const DOWNLOAD_QR_CODE = 'DOWNLOAD_QR_CODE';
export const DOWNLOAD_QR_CODE_FAILED = 'DOWNLOAD_QR_CODE_FAILED';
export const DOWNLOAD_QR_CODE_SUCCESS = 'DOWNLOAD_QR_CODE_SUCCESS';

export const MEMBER_DOWNLOAD_EXSL_EXPORT = 'MEMBER_DOWNLOAD_EXSL_EXPORT';
export const MEMBER_DOWNLOAD_EXSL_EXPORT_FAILED =
  'MEMBER_DOWNLOAD_EXSL_EXPORT_FAILED';
export const MEMBER_DOWNLOAD_EXSL_EXPORT_SUCCESS =
  'MEMBER_DOWNLOAD_EXSL_EXPORT_SUCCESS';

export const MEMBER_CREATE_BY_CSV = 'MEMBER_CREATE_BY_CSV';
export const MEMBER_CREATE_BY_CSV_FAILED = 'MEMBER_CREATE_BY_CSV_FAILED';
export const MEMBER_CREATE_BY_CSV_SUCCESS = 'MEMBER_CREATE_BY_CSV_SUCCESS';

/* OCCASION CODE */

export const UPDATE_OCCASION_CODE = 'UPDATE_OCCASION_CODE';
export const UPDATE_OCCASION_CODE_SUCCESS = 'UPDATE_OCCASION_CODE_SUCCESS';
export const UPDATE_OCCASION_CODE_FAILED = 'UPDATE_OCCASION_CODE_FAILED';

export const GET_GROUP_MEMBERS = 'GET_GROUP_MEMBERS';
export const GET_GROUP_MEMBERS_SUCCESS = 'GET_GROUP_MEMBERS_SUCCESS';
export const GET_GROUP_MEMBERS_FAILED = 'GET_GROUP_MEMBERS_FAILED';

export const CREATE_GROUP_MEMBERS = 'CREATE_GROUP_MEMBERS';
export const CREATE_GROUP_MEMBERS_SUCCESS = 'CREATE_GROUP_MEMBERS_SUCCESS';
export const CREATE_GROUP_MEMBERS_FAILED = 'CREATE_GROUP_MEMBERS_FAILED';

export const GET_GROUP_OCCASION_USERS = 'GET_GROUP_OCCASION_USERS';
export const GET_GROUP_OCCASION_USERS_SUCCESS =
  'GET_GROUP_OCCASION_USERS_SUCCESS';
export const GET_GROUP_OCCASION_USERS_FAILED =
  'GET_GROUP_OCCASION_USERS_FAILED';

export const SEARCH_GROUP_OCCASION_USERS = 'SEARCH_GROUP_OCCASION_USERS';
export const SEARCH_GROUP_OCCASION_USERS_SUCCESS =
  'SEARCH_GROUP_OCCASION_USERS_SUCCESS';
export const SEARCH_GROUP_OCCASION_USERS_FAILED =
  'SEARCH_GROUP_OCCASION_USERS_FAILED';

export const SET_CURRENT_TAB_INDEX = 'SET_CURRENT_TAB_INDEX';
export const RESET_TAB_INDEX = 'RESET_TAB_INDEX';

/*  TICKET PURCHASES */
export const CREATE_TICKET_PURCHASE = 'CREATE_TICKET_PURCHASE';
export const CREATE_TICKET_PURCHASE_SUCCESS = 'CREATE_TICKET_PURCHASE_SUCCESS';
export const CREATE_TICKET_PURCHASE_FAILED = 'CREATE_TICKET_PURCHASE_FAILED';

export const GET_REGISTERED_TICKET_PURCHASES =
  'GET_REGISTERED_TICKET_PURCHASES';
export const GET_REGISTERED_TICKET_PURCHASES_SUCCESS =
  'GET_REGISTERED_TICKET_PURCHASES_SUCCESS';
export const GET_REGISTERED_TICKET_PURCHASES_FAILED =
  'GET_REGISTERED_TICKET_PURCHASES_FAILED';

export const GET_UNREGISTERED_TICKET_PURCHASES =
  'GET_UNREGISTERED_TICKET_PURCHASES';
export const GET_UNREGISTERED_TICKET_PURCHASES_SUCCESS =
  'GET_UNREGISTERED_TICKET_PURCHASES_SUCCESS';
export const GET_UNREGISTERED_TICKET_PURCHASES_FAILED =
  'GET_UNREGISTERED_TICKET_PURCHASES_FAILED';

export const REGISTERED_TICKET_PURCHASES_SEARCH =
  'REGISTERED_TICKET_PURCHASES_SEARCH';
export const REGISTERED_TICKET_PURCHASES_SEARCH_SUCCESS =
  'REGISTERED_TICKET_PURCHASES_SEARCH_SUCCESS';
export const REGISTERED_TICKET_PURCHASES_SEARCH_FAILED =
  'REGISTERED_TICKET_PURCHASES_SEARCH_FAILED';

export const UNREGISTERED_TICKET_PURCHASES_SEARCH =
  'UNREGISTERED_TICKET_PURCHASES_SEARCH';
export const UNREGISTERED_TICKET_PURCHASES_SEARCH_SUCCESS =
  'UNREGISTERED_TICKET_PURCHASES_SEARCH_SUCCESS';
export const UNREGISTERED_TICKET_PURCHASES_SEARCH_FAILED =
  'UNREGISTERED_TICKET_PURCHASES_SEARCH_FAILED';

/* SCHEDULE */
export const OCCASION_SCHEDULE_CREATE = 'OCCASION_SCHEDULE_CREATE';
export const OCCASION_SCHEDULE_CREATE_SUCCESS =
  'OCCASION_SCHEDULE_CREATE_SUCCESS';
export const OCCASION_SCHEDULE_CREATE_FAILED =
  'OCCASION_SCHEDULE_CREATE_FAILED';
export const OCCASION_SCHEDULE_VIEW = 'OCCASION_SCHEDULE_VIEW';
export const OCCASION_SCHEDULE_VIEW_SUCCESS = 'OCCASION_SCHEDULE_VIEW_SUCCESS';
export const OCCASION_SCHEDULE_VIEW_FAILED = 'OCCASION_SCHEDULE_VIEW_FAILED';

export const OCCASION_SCHEDULE_UPDATE = 'OCCASION_SCHEDULE_UPDATE';
export const OCCASION_SCHEDULE_UPDATE_SUCCESS =
  'OCCASION_SCHEDULE_UPDATE_SUCCESS';
export const OCCASION_SCHEDULE_UPDATE_FAILED =
  'OCCASION_SCHEDULE_UPDATE_FAILED';

export const OCCASION_SCHEDULE_DELETE = 'OCCASION_SCHEDULE_DELETE';
export const OCCASION_SCHEDULE_DELETE_SUCCESS =
  'OCCASION_SCHEDULE_DELETE_SUCCESS';
export const OCCASION_SCHEDULE_DELETE_FAILED =
  'OCCASION_SCHEDULE_DELETE_FAILED';

/* ITINERARY */
export const OCCASION_ITINERARY_CREATE = 'OCCASION_ITINERARY_CREATE';
export const OCCASION_ITINERARY_CREATE_SUCCESS =
  'OCCASION_ITINERARY_CREATE_SUCCESS';
export const OCCASION_ITINERARY_CREATE_FAILED =
  'OCCASION_ITINERARY_CREATE_FAILED';
export const OCCASION_ITINERARY_VIEW = 'OCCASION_ITINERARY_VIEW';
export const OCCASION_ITINERARY_VIEW_SUCCESS =
  'OCCASION_ITINERARY_VIEW_SUCCESS';
export const OCCASION_ITINERARY_VIEW_FAILED = 'OCCASION_ITINERARY_VIEW_FAILED';

export const OCCASION_ITINERARY_UPDATE = 'OCCASION_ITINERARY_UPDATE';
export const OCCASION_ITINERARY_UPDATE_SUCCESS =
  'OCCASION_ITINERARY_UPDATE_SUCCESS';
export const OCCASION_ITINERARY_UPDATE_FAILED =
  'OCCASION_ITINERARY_UPDATE_FAILED';

export const OCCASION_ITINERARY_DELETE = 'OCCASION_ITINERARY_DELETE';
export const OCCASION_ITINERARY_DELETE_SUCCESS =
  'OCCASION_ITINERARY_DELETE_SUCCESS';
export const OCCASION_ITINERARY_DELETE_FAILED =
  'OCCASION_ITINERARY_DELETE_FAILED';

/* NOTIFICATIONS */

export const CREATE_EMAIL = 'CREATE_EMAIL';
export const CREATE_EMAIL_SUCCESS = 'CREATE_EMAIL_SUCCESS';
export const CREATE_EMAIL_FAILED = 'CREATE_EMAIL_FAILED';

export const VIEW_EMAILS = 'VIEW_EMAILS';
export const VIEW_EMAILS_SUCCESS = 'VIEW_EMAILS_SUCCESS';
export const VIEW_EMAILS_FAILED = 'VIEW_EMAILS_FAILED';

export const EDIT_EMAIL = 'EDIT_EMAIL';
export const EDIT_EMAIL_SUCCESS = 'EDIT_EMAIL_SUCCESS';
export const EDIT_EMAIL_FAILED = 'EDIT_EMAIL_FAILED';

export const DELETE_EMAIL = 'DELETE_EMAIL';
export const DELETE_EMAIL_SUCCESS = 'DELETE_EMAIL_SUCCESS';
export const DELETE_EMAIL_FAILED = 'DELETE_EMAIL_FAILED';

export const SEND_EMAIL_SAVER = 'SEND_EMAIL_SAVER';
export const SEND_EMAIL_SAVER_SUCCESS = 'SEND_EMAIL_SAVER_SUCCESS';
export const SEND_EMAIL_SAVER_FAILED = 'SEND_EMAIL_SAVER_FAILED';

/* CAROUSEL */

export const OCCASION_CAROUSEL_CREATE = 'OCCASION_CAROUSEL_CREATE';
export const OCCASION_CAROUSEL_CREATE_SUCCESS =
  'OCCASION_CAROUSEL_CREATE_SUCCESS';
export const OCCASION_CAROUSEL_CREATE_FAILED =
  'OCCASION_CAROUSEL_CREATE_FAILED';
export const OCCASION_CAROUSEL_VIEW = 'OCCASION_CAROUSEL_VIEW';
export const OCCASION_CAROUSEL_VIEW_SUCCESS = 'OCCASION_CAROUSEL_VIEW_SUCCESS';
export const OCCASION_CAROUSEL_VIEW_FAILED = 'OCCASION_CAROUSEL_VIEW_FAILED';

/* DIVISION */

export const OCCASION_DIVISION_CREATE = 'OCCASION_DIVISION_CREATE';
export const OCCASION_DIVISION_CREATE_SUCCESS =
  'OCCASION_DIVISION_CREATE_SUCCESS';
export const OCCASION_DIVISION_CREATE_FAILED =
  'OCCASION_DIVISION_CREATE_FAILED';
export const OCCASION_DIVISION_VIEW = 'OCCASION_DIVISION_VIEW';
export const OCCASION_DIVISION_VIEW_SUCCESS = 'OCCASION_DIVISION_VIEW_SUCCESS';
export const OCCASION_DIVISION_VIEW_FAILED = 'OCCASION_DIVISION_VIEW_FAILED';

/* SUBDIVISION */

export const OCCASION_SUBDIVISION_CREATE = 'OCCASION_SUBDIVISION_CREATE';
export const OCCASION_SUBDIVISION_CREATE_SUCCESS =
  'OCCASION_SUBDIVISION_CREATE_SUCCESS';
export const OCCASION_SUBDIVISION_CREATE_FAILED =
  'OCCASION_SUBDIVISION_CREATE_FAILED';
export const OCCASION_SUBDIVISION_VIEW = 'OCCASION_SUBDIVISION_VIEW';
export const OCCASION_SUBDIVISION_VIEW_SUCCESS =
  'OCCASION_SUBDIVISION_VIEW_SUCCESS';
export const OCCASION_SUBDIVISION_VIEW_FAILED =
  'OCCASION_SUBDIVISION_VIEW_FAILED';
/* SMS */

export const OCCASION_SMS_CREATE = 'OCCASION_SMS_CREATE';
export const OCCASION_SMS_CREATE_SUCCESS = 'OCCASION_SMS_CREATE_SUCCESS';
export const OCCASION_SMS_CREATE_FAILED = 'OCCASION_SMS_CREATE_FAILED';
export const OCCASION_SMS_VIEW = 'OCCASION_SMS_VIEW';
export const OCCASION_SMS_VIEW_SUCCESS = 'OCCASION_SMS_VIEW_SUCCESS';
export const OCCASION_SMS_VIEW_FAILED = 'OCCASION_SMS_VIEW_FAILED';

/* OCCASION FONT */
export const UPDATE_OCCASION_FONT = 'UPDATE_OCCASION_FONT';
export const UPDATE_OCCASION_FONT_SUCCESS = 'UPDATE_OCCASION_FONT_SUCCESS';
export const UPDATE_OCCASION_FONT_FAILED = 'UPDATE_OCCASION_FONT_FAILED';

/* GUEST */
export const GUEST_VIEW = 'GUEST_VIEW';
export const GUEST_VIEW_SUCCESS = 'GUEST_VIEW_SUCCESS';
export const GUEST_VIEW_FAILED = 'GUEST_VIEW_FAILED';
/* ALBUM */
export const OCCASION_ALBUM_CREATE = 'OCCASION_ALBUM_CREATE';
export const OCCASION_ALBUM_CREATE_SUCCESS = 'OCCASION_ALBUM_CREATE_SUCCESS';
export const OCCASION_ALBUM_CREATE_FAILED = 'OCCASION_ALBUM_CREATE_FAILED';
export const OCCASION_ALBUM_VIEW = 'OCCASION_ALBUM_VIEW';
export const OCCASION_ALBUM_VIEW_SUCCESS = 'OCCASION_ALBUM_VIEW_SUCCESS';
export const OCCASION_ALBUM_VIEW_FAILED = 'OCCASION_ALBUM_VIEW_FAILED';
export const CREATE_ALBUM_MEDIA = 'CREATE_ALBUM_MEDIA';
export const CREATE_ALBUM_MEDIA_FAILED = 'CREATE_ALBUM_MEDIA_FAILED';
export const CREATE_ALBUM_IMAGES_SUCCESS = 'CREATE_ALBUM_IMAGES_SUCCESS';
export const CREATE_ALBUM_VIDEOS_SUCCESS = 'CREATE_ALBUM_VIDEOS_SUCCESS';
export const GET_ALBUM_IMAGES = 'GET_ALBUM_IMAGES';
export const GET_ALBUM_IMAGES_SUCCESS = 'GET_ALBUM_IMAGES_SUCCESS';
export const GET_ALBUM_IMAGES_FAILED = 'GET_ALBUM_IMAGES_FAILED';

export const GET_ALBUM_VIDEOS = 'GET_ALBUM_VIDEOS';
export const GET_ALBUM_VIDEOS_SUCCESS = 'GET_ALBUM_VIDEOS_SUCCESS';
export const GET_ALBUM_VIDEOS_FAILED = 'GET_ALBUM_VIDEOS_FAILED';

export const DELETE_ALBUM_MEDIA = 'DELETE_ALBUM_MEDIA';
export const DELETE_ALBUM_MEDIA_SUCCESS = 'DELETE_ALBUM_MEDIA_SUCCESS';
export const DELETE_ALBUM_MEDIA_FAILED = 'DELETE_ALBUM_MEDIA_FAILED';
export const CLEAR_ALBUM_UPLOAD_IMAGE_LIST = 'CLEAR_ALBUM_UPLOAD_IMAGE_LIST';
export const UPLOAD_ALBUM_MEDIA_LIST = 'UPLOAD_ALBUM_MEDIA_LIST';
export const DELETE_ALBUM = 'DELETE_ALBUM';
export const DELETE_ALBUM_SUCCESS = 'DELETE_ALBUM_SUCCESS';
export const DELETE_ALBUM_FAILED = 'DELETE_ALBUM_FAILED';

/* FACE INFO */

export const REGENERATE_FACE_INFO = 'REGENERATE_FACE_INFO';
export const REGENERATE_FACE_INFO_FAILED = 'REGENERATE_FACE_INFO_FAILED';
export const REGENERATE_FACE_INFO_SUCCESS = 'REGENERATE_FACE_INFO_SUCCESS';
export const FACE_INFO_MEDIA_VIEW = 'FACE_INFO_MEDIA_VIEW';
export const FACE_INFO_MEDIA_VIEW_FAILED = 'FACE_INFO_MEDIA_VIEW_FAILED';
export const FACE_INFO_MEDIA_VIEW_SUCCESS = 'FACE_INFO_MEDIA_VIEW_SUCCESS';
export const FACE_INFO_USER_VIEW = 'FACE_INFO_USER_VIEW';
export const FACE_INFO_USER_VIEW_FAILED = 'FACE_INFO_USER_VIEW_FAILED';
export const FACE_INFO_USER_VIEW_SUCCESS = 'FACE_INFO_USER_VIEW_SUCCESS';
export const UPDATE_ALBUM = 'UPDATE_ALBUM';
export const UPDATE_ALBUM_FAILED = 'UPDATE_ALBUM_FAILED';
export const UPDATE_ALBUM_SUCCESS = 'UPDATE_ALBUM_SUCCESS';

export const CREATE_SELFIE_USER_MEDIA = 'CREATE_SELFIE_USER_MEDIA';
export const CREATE_SELFIE_USER_MEDIA_FAILED =
  'CREATE_SELFIE_USER_MEDIA_FAILED';
export const CREATE_SELFIE_USER_MEDIA_SUCCESS =
  'CREATE_SELFIE_USER_MEDIA_SUCCESS';
export const UPDATE_SELFIE_IMAGE = 'UPDATE_SELFIE_IMAGE';
export const UPDATE_SELFIE_IMAGE_FAILED = 'UPDATE_SELFIE_IMAGE_FAILED';
export const UPDATE_SELFIE_IMAGE_SUCCESS = 'UPDATE_SELFIE_IMAGE_SUCCESS';
export const REGENERATE_ALL_GALLARY_MEDIA = 'REGENERATE_ALL_GALLARY_MEDIA';
export const REGENERATE_ALL_GALLARY_MEDIA_FAILED =
  'REGENERATE_ALL_GALLARY_MEDIA_FAILED';
export const REGENERATE_ALL_GALLARY_MEDIA_SUCCESS =
  'REGENERATE_ALL_GALLARY_MEDIA_SUCCESS';

export const GET_SELFIE_USER_MEDIA = 'GET_SELFIE_USER_MEDIA';
export const GET_SELFIE_USER_MEDIA_FAILED = 'GET_SELFIE_USER_MEDIA_FAILED';
export const GET_SELFIE_USER_MEDIA_SUCCESS = 'GET_SELFIE_USER_MEDIA_SUCCESS';

/* OCCASION APP */
export const GET_OCCASION_APP_DETAILS = 'GET_OCCASION_APP_DETAILS';
export const GET_OCCASION_APP_DETAILS_SUCCESS =
  'GET_OCCASION_APP_DETAILS_SUCCESS';
export const GET_OCCASION_APP_DETAILS_FAILED =
  'GET_OCCASION_APP_DETAILS_FAILED';
export const OCCASION_APP_CREATE = 'OCCASION_APP_CREATE';
export const OCCASION_APP_CREATE_FAILED = 'OCCASION_APP_CREATE_FAILED';
export const OCCASION_APP_CREATE_SUCCESS = 'OCCASION_APP_CREATE_SUCCESS';
export const UPDATE_OCCASION_APP = 'UPDATE_OCCASION_APP';
export const UPDATE_OCCASION_APP_FAILED = 'UPDATE_OCCASION_APP_FAILED';
export const UPDATE_OCCASION_APP_SUCCESS = 'UPDATE_OCCASION_APP_SUCCESS';

/* ROLE NAME */
export const OCCASION_ROLE_NAME_CREATE = 'OCCASION_ROLE_NAME_CREATE';
export const OCCASION_ROLE_NAME_CREATE_FAILED =
  'OCCASION_ROLE_NAME_CREATE_FAILED';
export const OCCASION_ROLE_NAME_CREATE_SUCCESS =
  'OCCASION_ROLE_NAME_CREATE_SUCCESS';
export const OCCASION_ROLE_NAME_VIEW = 'OCCASION_ROLE_NAME_VIEW';
export const OCCASION_ROLE_NAME_VIEW_FAILED = 'OCCASION_ROLE_NAME_VIEW_FAILED';
export const OCCASION_ROLE_NAME_VIEW_SUCCESS =
  'OCCASION_ROLE_NAME_VIEW_SUCCESS';
export const UPDATE_ROLE_NAME = 'UPDATE_ROLE_NAME';
export const UPDATE_ROLE_NAME_FAILED = ' UPDATE_ROLE_NAME_FAILED';
export const UPDATE_ROLE_NAME_SUCCESS = ' UPDATE_ROLE_NAME_SUCCESS';
export const DELETE_ROLE_NAME = ' DELETE_ROLE_NAME';
export const DELETE_ROLE_NAME_FAILED = ' DELETE_ROLE_NAME_FAILED';
export const DELETE_ROLE_NAME_SUCCESS = ' DELETE_ROLE_NAME_SUCCESS';

/* GROUP MEMBERS */

export const PARSE_GROUP_MEMBERS_CSV = 'PARSE_GROUP_MEMBERS_CSV';
export const PARSE_GROUP_MEMBERS_CSV_SUCCESS =
  'PARSE_GROUP_MEMBERS_CSV_SUCCESS';
export const PARSE_GROUP_MEMBERS_CSV_FAILED = 'PARSE_GROUP_MEMBERS_CSV_FAILED';

export const DELETE_GROUP_MEMBERS = 'DELETE_GROUP_MEMBERS';
export const DELETE_GROUP_MEMBERS_SUCCESS = 'DELETE_GROUP_MEMBERS_SUCCESS';
export const DELETE_GROUP_MEMBERS_FAILED = 'DELETE_GROUP_MEMBERS_FAILED';

/* POLL POST */
export const OCCASION_POLL_POST_CREATE = 'OCCASION_POLL_POST_CREATE';
export const OCCASION_POLL_POST_CREATE_FAILED =
  'OCCASION_POLL_POST_CREATE_FAILED';
export const OCCASION_POLL_POST_CREATE_SUCCESS =
  'OCCASION_POLL_POST_CREATE_SUCCESS';
export const OCCASION_POLL_POST_VIEW = 'OCCASION_POLL_POST_VIEW';
export const OCCASION_POLL_POST_VIEW_FAILED = 'OCCASION_POLL_POST_VIEW_FAILED';
export const OCCASION_POLL_POST_VIEW_SUCCESS =
  'OCCASION_POLL_POST_VIEW_SUCCESS';
export const UPDATE_POLL_POST = 'UPDATE_POLL_POST';
export const UPDATE_POLL_POST_FAILED = ' UPDATE_POLL_POST_FAILED';
export const UPDATE_POLL_POST_SUCCESS = ' UPDATE_POLL_POST_SUCCESS';
export const DELETE_POLL_POST = ' DELETE_POLL_POST';
export const DELETE_POLL_POST_FAILED = ' DELETE_POLL_POST_FAILED';
export const DELETE_POLL_POST_SUCCESS = ' DELETE_POLL_POST_SUCCESS';

export const DOWNLOAD_POLL_POST_BY_CSV = 'DOWNLOAD_POLL_POST_BY_CSV';
export const DOWNLOAD_POLL_POST_BY_CSV_FAILED =
  'DOWNLOAD_POLL_POST_BY_CSV_FAILED';
export const DOWNLOAD_POLL_POST_BY_CSV_SUCCESS =
  'DOWNLOAD_POLL_POST_BY_CSV_SUCCESS';

export const OCCASION_POLL_POST_VIEW_BY_TYPE =
  'OCCASION_POLL_POST_VIEW_BY_TYPE';
export const OCCASION_POLL_POST_VIEW_BY_TYPE_FAILED =
  'OCCASION_POLL_POST_VIEW_BY_TYPE_FAILED';
export const OCCASION_POLL_POST_VIEW_BY_TYPE_SUCCESS =
  'OCCASION_POLL_POST_VIEW_BY_TYPE_SUCCESS';
export const DELETE_POLL_POST_BY_TYPE = 'DELETE_POLL_POST_BY_TYPE';
export const DELETE_POLL_POST_BY_TYPE_FAILED =
  'DELETE_POLL_POST_BY_TYPE_FAILED';
export const DELETE_POLL_POST_BY_TYPE_SUCCESS =
  'DELETE_POLL_POST_BY_TYPE_SUCCESS';

/* OCCASION CODE */
export const CODE_CREATE = 'CODE_CREATE';
export const CODE_CREATE_FAILED = 'CODE_CREATE_FAILED';
export const CODE_CREATE_SUCCESS = 'CODE_CREATE_SUCCESS';
export const CODE_VIEW = 'CODE_VIEW';
export const CODE_VIEW_FAILED = 'CODE_VIEW_FAILED';
export const CODE_VIEW_SUCCESS = 'CODE_VIEW_SUCCESS';
export const UPDATE_CODE = 'UPDATE_CODE';
export const UPDATE_CODE_FAILED = ' UPDATE_CODE_FAILED';
export const UPDATE_CODE_SUCCESS = ' UPDATE_CODE_SUCCESS';
export const DELETE_CODE = ' DELETE_CODE';
export const DELETE_CODE_FAILED = ' DELETE_CODE_FAILED';
export const DELETE_CODE_SUCCESS = ' DELETE_CODE_SUCCESS';

/* OCCASION WHITE LIST */

export const CREATE_OCCASION_WHITE_LIST = 'CREATE_OCCASION_WHITE_LIST';
export const CREATE_OCCASION_WHITE_LIST_FAILED =
  'CREATE_OCCASION_WHITE_LIST_FAILED';
export const CREATE_OCCASION_WHITE_LIST_SUCCESS =
  'CREATE_OCCASION_WHITE_LIST_SUCCESS';
export const CREATE_OCCASION_WHITE_LIST_BY_CSV =
  'CREATE_OCCASION_WHITE_LIST_BY_CSV';
export const CREATE_OCCASION_WHITE_LIST_BY_CSV_FAILED =
  'CREATE_OCCASION_WHITE_LIST_BY_CSV_FAILED';
export const CREATE_OCCASION_WHITE_LIST_BY_CSV_SUCCESS =
  'CREATE_OCCASION_WHITE_LIST_BY_CSV_SUCCESS';
export const DELETE_OCCASION_WHITE_LIST = 'DELETE_OCCASION_WHITE_LIST';
export const DELETE_OCCASION_WHITE_LIST_FAILED =
  'DELETE_OCCASION_WHITE_LIST_FAILED';
export const DELETE_OCCASION_WHITE_LIST_SUCCESS =
  'DELETE_OCCASION_WHITE_LIST_SUCCESS';
export const SEARCH_OCCASION_WHITE_LIST = 'SEARCH_OCCASION_WHITE_LIST';
export const SEARCH_OCCASION_WHITE_LIST_FAILED =
  'SEARCH_OCCASION_WHITE_LIST_FAILED';
export const SEARCH_OCCASION_WHITE_LIST_SUCCESS =
  'SEARCH_OCCASION_WHITE_LIST_SUCCESS';
export const GET_OCCASION_WHITE_LIST = 'GET_OCCASION_WHITE_LIST';
export const GET_OCCASION_WHITE_LIST_FAILED = 'GET_OCCASION_WHITE_LIST_FAILED';
export const GET_OCCASION_WHITE_LIST_SUCCESS =
  'GET_OCCASION_WHITE_LIST_SUCCESS';

export const CREATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE =
  'CREATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE';
export const CREATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_FAILED =
  'CREATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_FAILED';
export const CREATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_SUCCESS =
  'CREATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_SUCCESS';

export const UPDATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE =
  'UPDATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE';
export const UPDATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_FAILED =
  'UPDATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_FAILED';
export const UPDATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_SUCCESS =
  'UPDATE_OCCASION_WHITE_LIST_WITH_DEALER_CODE_SUCCESS';

/* DISCUSSION */

export const OCCASION_DISCUSSION_VIEW = 'OCCASION_DISCUSSION_VIEW';
export const OCCASION_DISCUSSION_VIEW_SUCCESS =
  'OCCASION_DISCUSSION_VIEW_SUCCESS';
export const OCCASION_DISCUSSION_VIEW_FAILED =
  'OCCASION_DISCUSSION_VIEW_FAILED';
export const OCCASION_DISCUSSION_DELETE = 'OCCASION_DISCUSSION_DELETE';
export const OCCASION_DISCUSSION_DELETE_FAILED =
  'OCCASION_DISCUSSION_DELETE_FAILED';
export const OCCASION_DISCUSSION_DELETE_SUCCESS =
  'OCCASION_DISCUSSION_DELETE_SUCCESS';

/* ENQUIRY*/
export const GET_ENQUIRY_PAGINATION = 'GET_ENQUIRY_PAGINATION';
export const GET_ENQUIRY_PAGINATION_SUCCESS = 'GET_ENQUIRY_PAGINATION_SUCCESS';
export const GET_ENQUIRY_PAGINATION_FAILED = 'GET_ENQUIRY_PAGINATION_FAILED';

/*OCCASION TAB SETTING*/

export const OCCASION_TAB_SETTING_VIEW = 'OCCASION_TAB_SETTING_VIEW';
export const OCCASION_TAB_SETTING_VIEW_SUCCESS =
  'OCCASION_TAB_SETTING_VIEW_SUCCESS';
export const OCCASION_TAB_SETTING_VIEW_FAILED =
  'OCCASION_TAB_SETTING_VIEW_FAILED';
export const OCCASION_TAB_SETTING_UPDATE = 'OCCASION_TAB_SETTING_UPDATE';
export const OCCASION_TAB_SETTING_UPDATE_FAILED =
  'OCCASION_TAB_SETTING_UPDATE_FAILED';
export const OCCASION_TAB_SETTING_UPDATE_SUCCESS =
  'OCCASION_TAB_SETTING_UPDATE_SUCCESS';
export const OCCASION_TAB_SETTING_CREATE = 'OCCASION_TAB_SETTING_CREATE';
export const OCCASION_TAB_SETTING_CREATE_FAILED =
  'OCCASION_TAB_SETTING_CREATE_FAILED';
export const OCCASION_TAB_SETTING_CREATE_SUCCESS =
  'OCCASION_TAB_SETTING_CREATE_SUCCESS';

/* SPEAKER */

export const CREATE_SPEAKER = 'CREATE_SPEAKER';
export const CREATE_SPEAKER_SUCCESS = 'CREATE_SPEAKER_SUCCESS';
export const CREATE_SPEAKER_FAILED = 'CREATE_SPEAKER_FAILED';
export const GET_SPEAKER_PAGINATION = 'GET_SPEAKER_PAGINATION';
export const GET_SPEAKER_PAGINATION_SUCCESS = 'GET_SPEAKER_PAGINATION_SUCCESS';
export const GET_SPEAKER_PAGINATION_FAILED = 'GET_SPEAKER_PAGINATION_FAILED';
export const EDIT_SPEAKER = 'EDIT_SPEAKER';
export const EDIT_SPEAKER_SUCCESS = 'EDIT_SPEAKER_SUCCESS';
export const EDIT_SPEAKER_FAILED = 'EDIT_SPEAKER_FAILED';
export const DELETE_SPEAKER = 'DELETE_SPEAKER';
export const DELETE_SPEAKER_SUCCESS = 'DELETE_SPEAKER_SUCCESS';
export const DELETE_SPEAKER_FAILED = 'DELETE_SPEAKER_FAILED';
export const GET_SPEAKER_REFERENCE_PAGINATION =
  'GET_SPEAKER_REFERENCE_PAGINATION';
export const GET_SPEAKER_REFERENCE_PAGINATION_SUCCESS =
  'GET_SPEAKER_REFERENCE_PAGINATION_SUCCESS';
export const GET_SPEAKER_REFERENCE_PAGINATION_FAILED =
  'GET_SPEAKER_REFERENCE_PAGINATION_FAILED';
export const EDIT_SPEAKER_REFERENCE = 'EDIT_SPEAKER_REFERENCE';
export const EDIT_SPEAKER_REFERENCE_SUCCESS = 'EDIT_SPEAKER_REFERENCE_SUCCESS';
export const EDIT_SPEAKER_REFERENCE_FAILED = 'EDIT_SPEAKER_REFERENCE_FAILED';
export const DELETE_SPEAKER_REFERENCE = 'DELETE_SPEAKER_REFERENCE';
export const DELETE_SPEAKER_REFERENCE_SUCCESS =
  'DELETE_SPEAKER_REFERENCE_SUCCESS';
export const DELETE_SPEAKER_REFERENCE_FAILED =
  'DELETE_SPEAKER_REFERENCE_FAILED';

/*USER dEVICE*/

export const USER_DEVICE_VIEW = 'USER_DEVICE_VIEW';
export const USER_DEVICE_VIEW_FAILED = 'USER_DEVICE_VIEW_FAILED';
export const USER_DEVICE_VIEW_SUCCESS = 'USER_DEVICE_VIEW_SUCCESS';

/*ACTIVITY TRACKER*/

export const ACTIVITY_TRACKER_CREATE = 'ACTIVITY_TRACKER_CREATE';
export const ACTIVITY_TRACKER_CREATE_FAILED = 'ACTIVITY_TRACKER_CREATE_FAILED';
export const ACTIVITY_TRACKER_CREATE_SUCCESS =
  'ACTIVITY_TRACKER_CREATE_SUCCESS';
export const ACTIVITY_TRACKER_VIEW = 'ACTIVITY_TRACKER_VIEW';
export const ACTIVITY_TRACKER_VIEW_FAILED = 'ACTIVITY_TRACKER_VIEW_FAILED';
export const ACTIVITY_TRACKER_VIEW_SUCCESS = 'ACTIVITY_TRACKER_VIEW_SUCCESS';
export const UPDATE_ACTIVITY_TRACKER = 'UPDATE_ACTIVITY_TRACKER';
export const UPDATE_ACTIVITY_TRACKER_FAILED = 'UPDATE_ACTIVITY_TRACKER_FAILED';
export const UPDATE_ACTIVITY_TRACKER_SUCCESS =
  'UPDATE_ACTIVITY_TRACKER_SUCCESS';
export const DELETE_ACTIVITY_TRACKER = 'DELETE_ACTIVITY_TRACKER';
export const DELETE_ACTIVITY_TRACKER_FAILED = 'DELETE_ACTIVITY_TRACKER_FAILED';
export const DELETE_ACTIVITY_TRACKER_SUCCESS =
  'DELETE_ACTIVITY_TRACKER_SUCCESS';

export const ACTIVITY_TRACKER_DOWNLOAD_EXSL_EXPORT =
  'ACTIVITY_TRACKER_DOWNLOAD_EXSL_EXPORT';
export const ACTIVITY_TRACKER_DOWNLOAD_EXSL_EXPORT_FAILED =
  'ACTIVITY_TRACKER_DOWNLOAD_EXSL_EXPORT_FAILED';
export const ACTIVITY_TRACKER_DOWNLOAD_EXSL_EXPORT_SUCCESS =
  'ACTIVITY_TRACKER_DOWNLOAD_EXSL_EXPORT_SUCCESS';

export const BLACK_LIST_USER_CREATE_BY_CSV = 'BLACK_LIST_USER_CREATE_BY_CSV';
export const BLACK_LIST_USER_CREATE_BY_CSV_FAILED =
  'BLACK_LIST_USER_CREATE_BY_CSV_FAILED';
export const BLACK_LIST_USER_CREATE_BY_CSV_SUCCESS =
  'BLACK_LIST_USER_CREATE_BY_CSV_SUCCESS';
export const BLACK_LIST_USER_CREATE = 'BLACK_LIST_USER_CREATE';
export const BLACK_LIST_USER_CREATE_FAILED = 'BLACK_LIST_USER_CREATE_FAILED';
export const BLACK_LIST_USER_CREATE_SUCCESS = 'BLACK_LIST_USER_CREATE_SUCCESS';
export const BLACK_LIST_USER_VIEW = 'BLACK_LIST_USER_VIEW';
export const BLACK_LIST_USER_VIEW_FAILED = 'BLACK_LIST_USER_VIEW_FAILED';
export const BLACK_LIST_USER_VIEW_SUCCESS = 'BLACK_LIST_USER_VIEW_SUCCESS';
export const DELETE_BLACK_LIST_USER = 'DELETE_BLACK_LIST_USER';
export const DELETE_BLACK_LIST_USER_FAILED = 'DELETE_BLACK_LIST_USER_FAILED';
export const DELETE_BLACK_LIST_USER_SUCCESS = 'DELETE_BLACK_LIST_USER_SUCCESS';

/*ACTIVITY TRACKER PRESENT USER*/

export const ACTIVITY_TRACKER_PRESENT_USER_CREATE =
  'ACTIVITY_TRACKER_PRESENT_USER_CREATE';
export const ACTIVITY_TRACKER_PRESENT_USER_CREATE_FAILED =
  'ACTIVITY_TRACKER_PRESENT_USER_CREATE_FAILED';
export const ACTIVITY_TRACKER_PRESENT_USER_CREATE_SUCCESS =
  'ACTIVITY_TRACKER_PRESENT_USER_CREATE_SUCCESS';
export const ACTIVITY_TRACKER_PRESENT_USER_VIEW =
  'ACTIVITY_TRACKER_PRESENT_USER_VIEW';
export const ACTIVITY_TRACKER_PRESENT_USER_VIEW_FAILED =
  'ACTIVITY_TRACKER_PRESENT_USER_VIEW_FAILED';
export const ACTIVITY_TRACKER_PRESENT_USER_VIEW_SUCCESS =
  'ACTIVITY_TRACKER_PRESENT_USER_VIEW_SUCCESS';
export const DELETE_ACTIVITY_TRACKER_PRESENT_USER =
  'DELETE_ACTIVITY_TRACKER_PRESENT_USER';
export const DELETE_ACTIVITY_TRACKER_PRESENT_USER_FAILED =
  'DELETE_ACTIVITY_TRACKER_PRESENT_USER_FAILED';
export const DELETE_ACTIVITY_TRACKER_PRESENT_USER_SUCCESS =
  'DELETE_ACTIVITY_TRACKER_PRESENT_USER_SUCCESS';
export const SEARCH_USERS_BY_OCCASION = 'SEARCH_USERS_BY_OCCASION';
export const SEARCH_USERS_BY_OCCASION_FAILED =
  'SEARCH_USERS_BY_OCCASION_FAILED';
export const SEARCH_USERS_BY_OCCASION_SUCCESS =
  'SEARCH_USERS_BY_OCCASION_SUCCESS';

/*ACTIVITY TRACKER ABSENT USER */

export const ACTIVITY_TRACKER_ABSENT_USER_VIEW =
  'ACTIVITY_TRACKER_ABSENT_USER_VIEW';
export const ACTIVITY_TRACKER_ABSENT_USER_VIEW_FAILED =
  'ACTIVITY_TRACKER_ABSENT_USER_VIEW_FAILED';
export const ACTIVITY_TRACKER_ABSENT_USER_VIEW_SUCCESS =
  'ACTIVITY_TRACKER_ABSENT_USER_VIEW_SUCCESS';

/* HARD LOGOUT */

export const HARD_LOGOUT_CREATE = 'HARD_LOGOUT_CREATE';
export const HARD_LOGOUT_CREATE_FAILED = 'HARD_LOGOUT_CREATE_FAILED';
export const HARD_LOGOUT_CREATE_SUCCESS = 'HARD_LOGOUT_CREATE_SUCCESS';
export const HARD_LOGOUT_VIEW = 'HARD_LOGOUT_VIEW';
export const HARD_LOGOUT_VIEW_FAILED = 'HARD_LOGOUT_VIEW_FAILED';
export const HARD_LOGOUT_VIEW_SUCCESS = 'HARD_LOGOUT_VIEW_SUCCESS';
export const UPDATE_HARD_LOGOUT = 'UPDATE_HARD_LOGOUT';
export const UPDATE_HARD_LOGOUT_FAILED = 'UPDATE_HARD_LOGOUT_FAILED';
export const UPDATE_HARD_LOGOUT_SUCCESS = 'UPDATE_HARD_LOGOUT_SUCCESS';
export const HARD_LOGOUT_USER_VIEW = 'HARD_LOGOUT_USER_VIEW';
export const HARD_LOGOUT_USER_VIEW_FAILED = 'HARD_LOGOUT_USER_VIEW_FAILED';
export const HARD_LOGOUT_USER_VIEW_SUCCESS = 'HARD_LOGOUT_USER_VIEW_SUCCESS';
export const HARD_LOGOUT_USER_DELETE = 'HARD_LOGOUT_USER_DELETE';
export const HARD_LOGOUT_USER_DELETE_FAILED = 'HARD_LOGOUT_USER_DELETE_FAILED';
export const HARD_LOGOUT_USER_DELETE_SUCCESS =
  'HARD_LOGOUT_USER_DELETE_SUCCESS';
export const HARD_LOGOUT_ALL_USER_DELETE = 'HARD_LOGOUT_ALL_USER_DELETE';
export const HARD_LOGOUT_ALL_USER_DELETE_FAILED =
  'HARD_LOGOUT_ALL_USER_DELETE_FAILED';
export const HARD_LOGOUT_ALL_USER_DELETE_SUCCESS =
  'HARD_LOGOUT_ALL_USER_DELETE_SUCCESS';

/*EVENT COLLABORATOR*/

export const EVENT_COLLABORATOR_CREATE = 'EVENT_COLLABORATOR_CREATE';
export const EVENT_COLLABORATOR_CREATE_FAILED =
  'EVENT_COLLABORATOR_CREATE_FAILED';
export const EVENT_COLLABORATOR_CREATE_SUCCESS =
  'EVENT_COLLABORATOR_CREATE_SUCCESS';
export const EVENT_COLLABORATOR_VIEW = 'EVENT_COLLABORATOR_VIEW';
export const EVENT_COLLABORATOR_VIEW_FAILED = 'EVENT_COLLABORATOR_VIEW_FAILED';
export const EVENT_COLLABORATOR_VIEW_SUCCESS =
  'EVENT_COLLABORATOR_VIEW_SUCCESS';
export const EVENT_COLLABORATOR_UPDATE = 'EVENT_COLLABORATOR_UPDATE';
export const EVENT_COLLABORATOR_UPDATE_FAILED =
  'EVENT_COLLABORATOR_UPDATE_FAILED';
export const EVENT_COLLABORATOR_UPDATE_SUCCESS =
  'EVENT_COLLABORATOR_UPDATE_SUCCESS';
export const EVENT_COLLABORATOR_DELETE = 'EVENT_COLLABORATOR_DELETE';
export const EVENT_COLLABORATOR_DELETE_FAILED =
  'EVENT_COLLABORATOR_DELETE_FAILED';
export const EVENT_COLLABORATOR_DELETE_SUCCESS =
  'EVENT_COLLABORATOR_DELETE_SUCCESS';

export const RESET_EVENT_MEMBERS = 'RESET_EVENT_MEMBERS';
export const EVENT_COLLABORATOR_SEARCH = 'EVENT_COLLABORATOR_SEARCH';
export const EVENT_COLLABORATOR_SEARCH_FAILED =
  'EVENT_COLLABORATOR_SEARCH_FAILED';
export const EVENT_COLLABORATOR_SEARCH_SUCCESS =
  'EVENT_COLLABORATOR_SEARCH_SUCCESS';

/* FIXED GROUP POST SETTING */

export const OCCASION_FIXED_GROUP_POST_SETTING_CREATE =
  'OCCASION_FIXED_GROUP_POST_SETTING_CREATE';
export const OCCASION_FIXED_GROUP_POST_SETTING_CREATE_FAILED =
  'OCCASION_FIXED_GROUP_POST_SETTING_CREATE_FAILED';
export const OCCASION_FIXED_GROUP_POST_SETTING_CREATE_SUCCESS =
  'OCCASION_FIXED_GROUP_POST_SETTING_CREATE_SUCCESS';
export const OCCASION_FIXED_GROUP_POST_SETTING_VIEW =
  'OCCASION_FIXED_GROUP_POST_SETTING_VIEW';
export const OCCASION_FIXED_GROUP_POST_SETTING_VIEW_FAILED =
  'OCCASION_FIXED_GROUP_POST_SETTING_VIEW_FAILED';
export const OCCASION_FIXED_GROUP_POST_SETTING_VIEW_SUCCESS =
  'OCCASION_FIXED_GROUP_POST_SETTING_VIEW_SUCCESS';
export const UPDATE_FIXED_GROUP_POST_SETTING =
  'UPDATE_FIXED_GROUP_POST_SETTING';
export const UPDATE_FIXED_GROUP_POST_SETTING_FAILED =
  'UPDATE_FIXED_GROUP_POST_SETTING_FAILED';
export const UPDATE_FIXED_GROUP_POST_SETTING_SUCCESS =
  'UPDATE_FIXED_GROUP_POST_SETTING_SUCCESS';
export const DELETE_FIXED_GROUP_POST_SETTING =
  'DELETE_FIXED_GROUP_POST_SETTING';
export const DELETE_FIXED_GROUP_POST_SETTING_FAILED =
  'DELETE_FIXED_GROUP_POST_SETTING_FAILED';
export const DELETE_FIXED_GROUP_POST_SETTING_SUCCESS =
  'DELETE_FIXED_GROUP_POST_SETTING_SUCCESS';

/* HOSPITALITY GOOGLE SHEET */

export const GET_HOSPITALITY_GOOGLE_SHEET = 'GET_HOSPITALITY_GOOGLE_SHEET';
export const GET_HOSPITALITY_GOOGLE_SHEET_FAILED =
  'GET_HOSPITALITY_GOOGLE_SHEET_FAILED';
export const GET_HOSPITALITY_GOOGLE_SHEET_SUCCESS =
  'GET_HOSPITALITY_GOOGLE_SHEET_SUCCESS';
export const HOSPITALITY_GOOGLE_SHEET_CREATE =
  'HOSPITALITY_GOOGLE_SHEET_CREATE';
export const HOSPITALITY_GOOGLE_SHEET_CREATE_FAILED =
  'HOSPITALITY_GOOGLE_SHEET_CREATE_FAILED';
export const HOSPITALITY_GOOGLE_SHEET_CREATE_SUCCESS =
  'HOSPITALITY_GOOGLE_SHEET_CREATE_SUCCESS';
export const HOSPITALITY_GOOGLE_SHEET_REGENERATE =
  'HOSPITALITY_GOOGLE_SHEET_REGENERATE';
export const HOSPITALITY_GOOGLE_SHEET_REGENERATE_FAILED =
  'HOSPITALITY_GOOGLE_SHEET_REGENERATE_FAILED';
export const HOSPITALITY_GOOGLE_SHEET_REGENERATE_SUCCESS =
  'HOSPITALITY_GOOGLE_SHEET_REGENERATE_SUCCESS';
export const HOSPITALITY_GOOGLE_SHEET_SYNC = 'HOSPITALITY_GOOGLE_SHEET_SYNC';
export const HOSPITALITY_GOOGLE_SHEET_SYNC_FAILED =
  'HOSPITALITY_GOOGLE_SHEET_SYNC_FAILED';
export const HOSPITALITY_GOOGLE_SHEET_SYNC_SUCCESS =
  'HOSPITALITY_GOOGLE_SHEET_SYNC_SUCCESS';
export const DELETE_HOSPITALITY_GOOGLE_SHEET =
  'DELETE_HOSPITALITY_GOOGLE_SHEET';
export const DELETE_HOSPITALITY_GOOGLE_SHEET_FAILED =
  'DELETE_HOSPITALITY_GOOGLE_SHEET_FAILED';
export const DELETE_HOSPITALITY_GOOGLE_SHEET_SUCCESS =
  'DELETE_HOSPITALITY_GOOGLE_SHEET_SUCCESS';

/* TASK WHATSAPP */

export const CREATE_MESSAGES_FOR_PROVIDED_TASK_FAILED =
  'CREATE_MESSAGES_FOR_PROVIDED_TASK_FAILED';
export const CREATE_MESSAGES_FOR_PROVIDED_TASK_SUCCESS =
  'CREATE_MESSAGES_FOR_PROVIDED_TASK_SUCCESS';
export const GET_WHATSAPP_TEMPLATE_MODELABLES =
  'GET_WHATSAPP_TEMPLATE_MODELABLES';
export const GET_WHATSAPP_TEMPLATE_MODELABLES_FAILED =
  'GET_WHATSAPP_TEMPLATE_MODELABLES_FAILED';
export const GET_WHATSAPP_TEMPLATE_MODELABLES_SUCCESS =
  'GET_WHATSAPP_TEMPLATE_MODELABLES_SUCCESS';
export const GET_PROVIDED_ANSWERED_USERS = 'GET_PROVIDED_ANSWERED_USERS';
export const GET_PROVIDED_ANSWERED_USERS_FAILED =
  'GET_PROVIDED_ANSWERED_USERS_FAILED';
export const GET_PROVIDED_ANSWERED_USERS_SUCCESS =
  'GET_PROVIDED_ANSWERED_USERS_SUCCESS';
export const CREATE_MESSAGES_FOR_PROVIDED_TASK =
  'CREATE_MESSAGES_FOR_PROVIDED_TASK';

export const GET_WHATSAPP_MESSAGE_MODELABLE = 'GET_WHATSAPP_MESSAGE_MODELABLE';
export const GET_WHATSAPP_MESSAGE_MODELABLE_FAILED =
  'GET_WHATSAPP_MESSAGE_MODELABLE_FAILED';
export const GET_WHATSAPP_MESSAGE_MODELABLE_SUCCESS =
  'GET_WHATSAPP_MESSAGE_MODELABLE_SUCCESS';

export const CLEAR_PROVIDED_USER_STORE = 'CLEAR_PROVIDED_USER_STORE';

/* WHATSAPP TEMPLATES */

export const GET_WHATSAPP_TEMPLETS_PAGINATION =
  'constGET_WHATSAPP_TEMPLETS_PAGINATION';
export const GET_WHATSAPP_TEMPLETS_PAGINATION_SUCCESS =
  'constGET_WHATSAPP_TEMPLETS_PAGINATION_SUCCESS';
export const GET_WHATSAPP_TEMPLETS_PAGINATION_FAILED =
  'constGET_WHATSAPP_TEMPLETS_PAGINATION_FAILED';

export const GET_WATI_PAGINATION = 'GET_WATI_PAGINATION';
export const GET_WATI_PAGINATION_SUCCESS = 'GET_WATI_PAGINATION_SUCCESS';
export const GET_WATI_PAGINATION_FAILED = 'GET_WATI_PAGINATION_FAILED';

export const CREATE_WHATSAPP_TEMPLETS = 'CREATE_WHATSAPP_TEMPLETS';
export const CREATE_WHATSAPP_TEMPLETS_SUCCESS =
  'CREATE_WHATSAPP_TEMPLETS_SUCCESS';
export const CREATE_WHATSAPP_TEMPLETS_FAILED =
  'CREATE_WHATSAPP_TEMPLETS_FAILED';
export const DELETE_WHATSAPP_TEMPLETS = 'DELETE_WHATSAPP_TEMPLETS';
export const DELETE_WHATSAPP_TEMPLETS_SUCCESS =
  'DELETE_WHATSAPP_TEMPLETS_SUCCESS';
export const DELETE_WHATSAPP_TEMPLETS_FAILED =
  'DELETE_WHATSAPP_TEMPLETS_FAILED';
export const UPDATE_WHATSAPP_TEMPLETS = 'UPDATE_WHATSAPP_TEMPLETS';
export const UPDATE_WHATSAPP_TEMPLETS_SUCCESS =
  'UPDATE_WHATSAPP_TEMPLETS_SUCCESS';
export const UPDATE_WHATSAPP_TEMPLETS_FAILED =
  'UPDATE_WHATSAPP_TEMPLETS_FAILED';

export const GET_WHATSAPP_TEMPLATE_MODELABLE_PAGINATION =
  'GET_WHATSAPP_TEMPLATE_MODELABLE_PAGINATION';
export const GET_WHATSAPP_TEMPLATE_MODELABLE_PAGINATION_SUCCESS =
  'GET_WHATSAPP_TEMPLATE_MODELABLE_PAGINATION_SUCCESS';
export const GET_WHATSAPP_TEMPLATE_MODELABLE_PAGINATION_FAILED =
  'GET_WHATSAPP_TEMPLATE_MODELABLE_PAGINATION_FAILED';
export const CREATE_WHATSAPP_TEMPLATE_MODELABLE =
  'CREATE_WHATSAPP_TEMPLATE_MODELABLE';
export const CREATE_WHATSAPP_TEMPLATE_MODELABLE_SUCCESS =
  'CREATE_WHATSAPP_TEMPLATE_MODELABLE_SUCCESS';
export const CREATE_WHATSAPP_TEMPLATE_MODELABLE_FAILED =
  'CREATE_WHATSAPP_TEMPLATE_MODELABLE_FAILED';
export const DELETE_WHATSAPP_TEMPLATE_MODELABLE =
  'DELETE_WHATSAPP_TEMPLATE_MODELABLE';
export const DELETE_WHATSAPP_TEMPLATE_MODELABLE_SUCCESS =
  'DELETE_WHATSAPP_TEMPLATE_MODELABLE_SUCCESS';
export const DELETE_WHATSAPP_TEMPLATE_MODELABLE_FAILED =
  'DELETE_WHATSAPP_TEMPLATE_MODELABLE_FAILED';
export const UPDATE_WHATSAPP_TEMPLATE_MODELABLE =
  'UPDATE_WHATSAPP_TEMPLATE_MODELABLE';
export const UPDATE_WHATSAPP_TEMPLATE_MODELABLE_SUCCESS =
  'UPDATE_WHATSAPP_TEMPLATE_MODELABLE_SUCCESS';
export const UPDATE_WHATSAPP_TEMPLATE_MODELABLE_FAILED =
  'UPDATE_WHATSAPP_TEMPLATE_MODELABLE_FAILED';

export const GET_WHATSAPP_TEMPLATE_PARAMS_PAGINATION =
  'GET_WHATSAPP_TEMPLATE_PARAMS_PAGINATION';
export const GET_WHATSAPP_TEMPLATE_PARAMS_PAGINATION_SUCCESS =
  'GET_WHATSAPP_TEMPLATE_PARAMS_PAGINATION_SUCCESS';
export const GET_WHATSAPP_TEMPLATE_PARAMS_PAGINATION_FAILED =
  'GET_WHATSAPP_TEMPLATE_PARAMS_PAGINATION_FAILED';
export const CREATE_WHATSAPP_TEMPLATE_PARAMS =
  'CREATE_WHATSAPP_TEMPLATE_PARAMS';
export const CREATE_WHATSAPP_TEMPLATE_PARAMS_SUCCESS =
  'CREATE_WHATSAPP_TEMPLATE_PARAMS_SUCCESS';
export const CREATE_WHATSAPP_TEMPLATE_PARAMS_FAILED =
  'CREATE_WHATSAPP_TEMPLATE_PARAMS_FAILED';
export const DELETE_WHATSAPP_TEMPLATE_PARAMS =
  'DELETE_WHATSAPP_TEMPLATE_PARAMS';
export const DELETE_WHATSAPP_TEMPLATE_PARAMS_SUCCESS =
  'DELETE_WHATSAPP_TEMPLATE_PARAMS_SUCCESS';
export const DELETE_WHATSAPP_TEMPLATE_PARAMS_FAILED =
  'DELETE_WHATSAPP_TEMPLATE_PARAMS_FAILED';
export const UPDATE_WHATSAPP_TEMPLATE_PARAMS =
  'UPDATE_WHATSAPP_TEMPLATE_PARAMS';
export const UPDATE_WHATSAPP_TEMPLATE_PARAMS_SUCCESS =
  'UPDATE_WHATSAPP_TEMPLATE_PARAMS_SUCCESS';
export const UPDATE_WHATSAPP_TEMPLATE_PARAMS_FAILED =
  'UPDATE_WHATSAPP_TEMPLATE_PARAMS_FAILED';

/* OCCASION RESOURCES */

export const OCCASION_RESOURCES_VIEW = 'OCCASION_RESOURCES_VIEW';
export const OCCASION_RESOURCES_VIEW_SUCCESS =
  'OCCASION_RESOURCES_VIEW_SUCCESS';
export const OCCASION_RESOURCES_VIEW_FAILED = 'OCCASION_RESOURCES_VIEW_FAILED';
export const DELETE_OCCASION_RESOURCES = 'DELETE_OCCASION_RESOURCES';
export const DELETE_OCCASION_RESOURCES_FAILED =
  'DELETE_OCCASION_RESOURCES_FAILED';
export const DELETE_OCCASION_RESOURCES_SUCCESS =
  'DELETE_OCCASION_RESOURCES_SUCCESS';

export const OCCASION_RESOURCES_CREATE = 'OCCASION_RESOURCES_CREATE';
export const OCCASION_RESOURCES_CREATE_FAILED =
  'OCCASION_RESOURCES_CREATE_FAILED';
export const OCCASION_RESOURCES_CREATE_SUCCESS =
  'OCCASION_RESOURCES_CREATE_SUCCESS';
export const UPDATE_OCCASION_RESOURCES = 'UPDATE_OCCASION_RESOURCES';
export const UPDATE_OCCASION_RESOURCES_FAILED =
  'UPDATE_OCCASION_RESOURCES_FAILED';
export const UPDATE_OCCASION_RESOURCES_SUCCESS =
  'UPDATE_OCCASION_RESOURCES_SUCCESS';

export const OCCASION_CATEGORY_STATUS_CREATE =
  'OCCASION_CATEGORY_STATUS_CREATE';
export const OCCASION_CATEGORY_STATUS_CREATE_FAILED =
  'OCCASION_CATEGORY_STATUS_CREATE_FAILED';
export const OCCASION_CATEGORY_STATUS_CREATE_SUCCESS =
  'OCCASION_CATEGORY_STATUS_CREATE_SUCCESS';
export const OCCASION_CATEGORY_STATUS_VIEW = 'OCCASION_CATEGORY_STATUS_VIEW';
export const OCCASION_CATEGORY_STATUS_VIEW_FAILED =
  'OCCASION_CATEGORY_STATUS_VIEW_FAILED';
export const OCCASION_CATEGORY_STATUS_VIEW_SUCCESS =
  'OCCASION_CATEGORY_STATUS_VIEW_SUCCESS';
export const UPDATE_CATEGORY_STATUS = 'UPDATE_CATEGORY_STATUS';
export const UPDATE_CATEGORY_STATUS_FAILED = 'UPDATE_CATEGORY_STATUS_FAILED';
export const UPDATE_CATEGORY_STATUS_SUCCESS = 'UPDATE_CATEGORY_STATUS_SUCCESS';
export const DELETE_CATEGORY_STATUS = 'DELETE_CATEGORY_STATUS';
export const DELETE_CATEGORY_STATUS_FAILED = 'DELETE_CATEGORY_STATUS_FAILED';
export const DELETE_CATEGORY_STATUS_SUCCESS = 'DELETE_CATEGORY_STATUS_SUCCESS';
