import { union } from 'lodash';
import {
  HOSPITALITY_CATEGORY_CREATE_SUCCESS,
  HOSPITALITY_CATEGORY_VIEW_SUCCESS,
  HOSPITALITY_CATEGORY_VIEW_FAILED,
  DELETE_HOSPITALITY_CATEGORY_SUCCESS,
  DELETE_HOSPITALITY_CATEGORY_FAILED,
  GET_CONTACTUS_SUCCESS,
  GET_EXSL_EXPORT_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  hospitalityCategoryId: null,
  error: null,
  categoryLinks: null,
  contactUsId: null,
  contactUsLinks: null,
  exslExportData: null
};
const hospitality = (state = initialState, action) => {
  switch (action.type) {
    case HOSPITALITY_CATEGORY_VIEW_SUCCESS: {
      const { data, ocId, links, page } = action.payload;
      const occasionGroupedData = state.hospitalityCategoryId || {};
      const currentHospitalityCategoryIds = occasionGroupedData[ocId] || [];
      const currentHospitalityCategoryNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        hospitalityCategoryId: {
          ...occasionGroupedData,
          [ocId]: currentHospitalityCategoryNewIds
        },
        categoryLinks: links
      };
    }

    case GET_EXSL_EXPORT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        exslExportData: data
      };
    }

    case GET_CONTACTUS_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.contactUsId || {};
      const currentContactUsNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        contactUsId: {
          ...occasionGroupedData,
          [ocId]: [...currentContactUsNewIds]
        },
        contactUsLinks: links
      };
    }

    case HOSPITALITY_CATEGORY_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.hospitalityCategoryId || {};
      const currentHospitalityCategoryIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentHospitalityCategoryIds];
      return {
        ...state,
        hospitalityCategoryId: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }

    case DELETE_HOSPITALITY_CATEGORY_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.hospitalityCategoryId || {};
      const currentHospitalityCategoryIds = occasionGroupedData[ocId] || [];
      const filteredIds = currentHospitalityCategoryIds.filter(
        (id) => id !== data.id
      );

      return {
        ...state,
        hospitalityCategoryId: {
          ...occasionGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case HOSPITALITY_CATEGORY_VIEW_FAILED:
    case DELETE_HOSPITALITY_CATEGORY_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};
export default hospitality;
