import request from './request';

export function eventAffairEssenceCreateApi(
  ocId,
  formData,
  eventId,
  eventAffairId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/event_affairs/${eventAffairId}/event_affair_essences`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function eventAffairEssenceViewApi(
  ocId,
  page,
  eventId,
  eventAffairId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/event_affairs/${eventAffairId}/event_affair_essences?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function eventAffairEssenceUpdateApi(
  ocId,
  eventAffairId,
  eventId,
  essenceId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/event_affairs/${eventAffairId}/event_affair_essences/${essenceId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function eventAffairEssenceDeleteApi(
  ocId,
  eventAffairId,
  eventId,
  essenceId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/events/${eventId}/event_affairs/${eventAffairId}/event_affair_essences/${essenceId}`,
    {
      method: 'DELETE',
      token
    }
  );
}
