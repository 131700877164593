import {
  GET_OCCASION_PENDING_INVITATION_SUCCESS,
  GET_OCCASION_PENDING_INVITATION_FAILED,
  GET_OCCASION_PENDING_REGISTRATION_SUCCESS,
  GET_OCCASION_PENDING_REGISTRATION_FAILED,
  PENDING_INVITATION_SEARCH_SUCCESS,
  PENDING_REGISTRATION_SEARCH_SUCCESS,
  SEARCH_PENDING_INVITATIONS_SUCCESS,
  SEARCH_PENDING_REGISTRATIONS_SUCCESS,
  SEARCH_PENDING_INVITATIONS_FAILED,
  SEARCH_PENDING_REGISTRATIONS_FAILED,
  DELETE_OCCASION_PENDING_INVITATION_SUCCESS,
  DELETE_OCCASION_PENDING_REGISTRATION_SUCCESS,
  DELETE_OCCASION_PENDING_INVITATION_FAILED,
  DELETE_OCCASION_PENDING_REGISTRATION_FAILED
} from '../constants/actionTypes';
import { union } from '../helpers/extraHelpers';

const initialState = {
  pendingInvitationData: null,
  allPendingInvitationData: null,
  searchPendingInvitationData: null,
  searchPendingInvitationLink: null,
  pendingInvitationLink: null,
  pendingRegistrationData: null,
  allPendingRegistrationData: null,
  searchPendingRegistrationData: null,
  searchPendingRegistrationLink: null,
  pendingRegistrationLink: null,
  pendingInvitationCount: 0,
  pendingRegistrationCount: 0
};
const invitation = (state = initialState, action) => {
  switch (action.type) {
    case GET_OCCASION_PENDING_INVITATION_SUCCESS:
    case PENDING_INVITATION_SEARCH_SUCCESS: {
      const { data, links, page, meta } = action.payload;

      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];
      const allIds =
        page !== 1
          ? union(state.allPendingInvitationData || [], newIds)
          : newIds;
      return {
        ...state,
        pendingInvitationData: newIds,
        pendingInvitationLink: links,
        allPendingInvitationData: allIds,
        pendingInvitationCount: meta?.totalCount || 0
      };
    }

    case SEARCH_PENDING_INVITATIONS_SUCCESS: {
      const { data, links } = action.payload;

      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];
      return {
        ...state,
        searchPendingInvitationData: newIds,
        searchPendingInvitationLink: links
      };
    }

    case GET_OCCASION_PENDING_REGISTRATION_SUCCESS:
    case PENDING_REGISTRATION_SEARCH_SUCCESS: {
      const { data, links, page, meta } = action.payload;

      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];
      const allIds =
        page !== 1
          ? union(state.allPendingRegistrationData || [], newIds)
          : newIds;
      return {
        ...state,
        pendingRegistrationData: newIds,
        pendingRegistrationLink: links,
        allPendingRegistrationData: allIds,
        pendingRegistrationCount: meta?.totalCount || 0
      };
    }

    case SEARCH_PENDING_REGISTRATIONS_SUCCESS: {
      const { data, links } = action.payload;

      const newIds = data && data?.length > 0 ? data.map((el) => el.id) : [];
      return {
        ...state,
        searchPendingRegistrationData: newIds,
        searchPendingRegistrationLink: links
      };
    }

    case DELETE_OCCASION_PENDING_INVITATION_SUCCESS: {
      const { data } = action.payload;
      const prvData = state.pendingInvitationData || [];
      const filterIds = prvData.filter((id) => id != data.id);
      const prvAllData = state.allPendingInvitationData || [];
      const allFilterIds = prvAllData.filter((id) => id != data.id);
      return {
        ...prvData,
        pendingInvitationData: filterIds,
        allPendingInvitationData: allFilterIds
      };
    }

    case DELETE_OCCASION_PENDING_REGISTRATION_SUCCESS: {
      const { data } = action.payload;
      const prvData = state.pendingRegistrationData || [];
      const filterIds = prvData.filter((id) => id != data.id);
      const prvAllData = state.allPendingRegistartionData || [];
      const allFilterIds = prvAllData.filter((id) => id != data.id);
      return {
        ...prvData,
        pendingRegistrationData: filterIds,
        allPendingRegistrationData: allFilterIds
      };
    }

    case SEARCH_PENDING_INVITATIONS_FAILED:
    case SEARCH_PENDING_REGISTRATIONS_FAILED:
    case GET_OCCASION_PENDING_INVITATION_FAILED:
    case GET_OCCASION_PENDING_REGISTRATION_FAILED:
    case DELETE_OCCASION_PENDING_INVITATION_FAILED:
    case DELETE_OCCASION_PENDING_REGISTRATION_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};
export default invitation;
