import { createSelector } from 'reselect';
import {
  occasionPollPostEntities,
  occasionPollPostOptionEntities,
  userEntities,
  pollPostExplanationEntities
} from './entity';
import {
  occasionPollPostIds,
  occasionPollPostQuizIds,
  occasionPollPostPollIds
} from './static';
import { currentOccasionId, currentPollPostId } from './dynamicData';

export const currentpollpostIds = createSelector(
  currentOccasionId,
  occasionPollPostIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getAllPollPostData = createSelector(
  occasionPollPostEntities,
  currentpollpostIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAllPollPostWithOptionsData = createSelector(
  getAllPollPostData,
  occasionPollPostOptionEntities,
  userEntities,
  pollPostExplanationEntities,
  (data, entities, userEntities, explanationEntities) =>
    data &&
    entities &&
    userEntities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      createdBy: { ...userEntities[item?.createdBy?.id] },
      pollPostOptions: [...item?.pollPostOptions?.map((el) => entities[el.id])],
      pollPostExplanation: explanationEntities
        ? {
            ...explanationEntities[item?.pollPostExplanation?.id]
          }
        : {}
    }))
);

export const currentpollpostQuizIds = createSelector(
  currentOccasionId,
  occasionPollPostQuizIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getAllPollPostQuizData = createSelector(
  occasionPollPostEntities,
  currentpollpostQuizIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAllPollPostQuizWithOptionsData = createSelector(
  getAllPollPostQuizData,
  occasionPollPostOptionEntities,
  userEntities,
  pollPostExplanationEntities,
  (data, entities, userEntities, explanationEntities) =>
    data &&
    entities &&
    userEntities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      createdBy: { ...userEntities[item?.createdBy?.id] },
      pollPostOptions: [...item?.pollPostOptions?.map((el) => entities[el.id])],
      pollPostExplanation: explanationEntities
        ? {
            ...explanationEntities[item?.pollPostExplanation?.id]
          }
        : {}
    }))
);

export const currentpollpostPollIds = createSelector(
  currentOccasionId,
  occasionPollPostPollIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getAllPollPostPollData = createSelector(
  occasionPollPostEntities,
  currentpollpostPollIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAllPollPostPollWithOptionsData = createSelector(
  getAllPollPostPollData,
  occasionPollPostOptionEntities,
  userEntities,
  pollPostExplanationEntities,
  (data, entities, userEntities, explanationEntities) =>
    data &&
    entities &&
    userEntities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      createdBy: { ...userEntities[item?.createdBy?.id] },
      pollPostOptions: [...item?.pollPostOptions?.map((el) => entities[el.id])],
      pollPostExplanation: explanationEntities
        ? {
            ...explanationEntities[item?.pollPostExplanation?.id]
          }
        : {}
    }))
);

export const currentPollPostData = createSelector(
  currentPollPostId,
  occasionPollPostEntities,
  userEntities,
  pollPostExplanationEntities,
  occasionPollPostOptionEntities,
  (id, entities, users, explanationEntities, optionEntities) => {
    const createdBy =
      entities && id && users && users[entities[id]?.createdBy?.id];
    return (
      id &&
      entities &&
      entities[id] && {
        ...entities[id],
        searchData: {
          value: createdBy?.name,
          id: createdBy?.id
        },
        pollPostOptions: [
          ...entities[id].pollPostOptions?.map((el) => optionEntities[el?.id])
        ],
        pollPostExplanation: explanationEntities
          ? {
              ...explanationEntities[entities[id]?.pollPostExplanation?.id]
            }
          : {}
      }
    );
  }
);
