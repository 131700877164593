import { union } from 'lodash';
import {
  GET_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS,
  CREATE_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS,
  DELETE_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS,
  GET_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED,
  CREATE_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED,
  DELETE_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED,
  UPDATE_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED
} from '../constants/actionTypes';

const initialState = {
  data: null,
  error: null,
  links: null
};
const publicAnswer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS: {
      const { data, ocId, links, taskId } = action.payload;
      const occasionData = state.data || {};
      const currentHospitalityOcIds = occasionData[ocId] || {};
      const currentHospitalityTaskIds = currentHospitalityOcIds[taskId] || [];
      const currentHospitalityPublicAnswerIds =
        data && data?.id ? [data.id] : [];

      const occasionLinks = state.links || {};
      const currentHospitalityOcLinks = occasionLinks[ocId] || {};

      return {
        ...state,
        data: {
          ...occasionData,
          [ocId]: {
            ...currentHospitalityOcIds,
            [taskId]: currentHospitalityPublicAnswerIds
          }
        },
        links: {
          ...occasionLinks,
          [ocId]: {
            ...currentHospitalityOcLinks,
            [taskId]: links
          }
        }
      };
    }

    case CREATE_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS: {
      const { data, ocId, taskId } = action.payload;
      const occasionData = state.data || {};
      const currentHospitalityOcIds = occasionData[ocId] || {};
      const currentHospitalityTaskIds = currentHospitalityOcIds[taskId] || [];
      const currentHospitalityPublicAnswerIds = union(
        [data.id],
        currentHospitalityTaskIds
      );

      return {
        ...state,
        data: {
          ...occasionData,
          [ocId]: {
            ...currentHospitalityOcIds,
            [taskId]: currentHospitalityPublicAnswerIds
          }
        }
      };
    }

    case DELETE_HOSPITALITY_TASK_PUBLIC_ANSWER_SUCCESS: {
      const { data, ocId, taskId } = action.payload;
      const occasionData = state.data || {};
      const currentHospitalityOcIds = occasionData[ocId] || {};
      const currentHospitalityTaskIds = currentHospitalityOcIds[taskId] || [];
      const currentHospitalityPublicAnswerIds = currentHospitalityTaskIds.filter(
        (el) => el !== data.id
      );

      return {
        ...state,
        data: {
          ...occasionData,
          [ocId]: {
            ...currentHospitalityOcIds,
            [taskId]: currentHospitalityPublicAnswerIds
          }
        }
      };
    }

    case GET_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED:
    case UPDATE_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED:
    case CREATE_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED:
    case DELETE_HOSPITALITY_TASK_PUBLIC_ANSWER_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};
export default publicAnswer;
