import React, { useState } from 'react';
import {
  MailOutlined,
  CalendarOutlined,
  LoadingOutlined,
  GlobalOutlined,
  PhoneOutlined,
  ZoomInOutlined
} from '@ant-design/icons';
import { Tooltip, Image } from 'antd';
import { capitalize } from '../../helpers/stringHelper';
import { getImageSource } from '../../helpers/imageHelper';
import './styles.scss';
import { Avatar } from '../Avatar';
import CardImage from '../Table/CardImage';

export const ProfileContent = ({ data }) => {
  return (
    <div className="profile_content">
      {data?.qrCodeUrl ? (
        <div className="profile_content_header">
          <Tooltip
            overlayClassName="profile_content_tooltip"
            placement="right"
            title={data?.name || data?.title || ''}>
            <span className="profile_content_username">
              {data?.title || data?.name || ''}
            </span>
          </Tooltip>
          {!!data?.type && (
            <span className="profile_content_designation">{data.type}</span>
          )}
          <Avatar
            avatarUrl={data?.avatarUrl || data?.cardImageUrl}
            name={data?.name || data?.title}
            className="profile_content_image"
          />
          <Avatar
            avatarUrl={data?.qrCodeUrl}
            preview={true}
            previewClassNmae="qr_code_preview_style"
            className="profile_content_qr_code"
          />
        </div>
      ) : (
        <div className="profile_content_header">
          <Tooltip
            overlayClassName="profile_content_tooltip"
            placement="right"
            title={data?.name || data?.title || ''}>
            <span className="profile_content_username">
              {data?.title || data?.name || ''}
            </span>
          </Tooltip>
          {!!data?.type && (
            <span className="profile_content_designation">{data.type}</span>
          )}
          <Avatar
            avatarUrl={data?.avatarUrl || data?.cardImageUrl}
            name={data?.name || data?.title}
            className="profile_content_image"
          />
        </div>
      )}
      <div className="profile_content_extras">
        {data?.email && (
          <div className="profile_content_extras_content">
            <MailOutlined className="profile_content_icon" />
            <span className="profile_content_extra_item">{data.email}</span>
          </div>
        )}
        {data?.mobile && (
          <div className="profile_content_extras_content">
            <PhoneOutlined className="profile_content_icon" />
            <span className="profile_content_extra_item">{data.mobile}</span>
          </div>
        )}

        {data?.dob && (
          <div className="profile_content_extras_content">
            <CalendarOutlined className="profile_content_icon" />
            <span className="profile_content_extra_item">{data.dob}</span>
          </div>
        )}
        {data?.startAt && data?.endAt && (
          <div className="profile_content_extras_content">
            <CalendarOutlined className="profile_content_icon" />
            <span className="profile_content_extra_item">{`${new Date(
              data.startAt
            ).toLocaleDateString()} - ${new Date(
              data.endAt
            ).toLocaleDateString()}`}</span>
          </div>
        )}
        {data?.venue && (
          <div className="profile_content_extras_content">
            <GlobalOutlined className="profile_content_icon" />
            <Tooltip
              overlayClassName="profile_content_tooltip"
              placement="right"
              title={data.venue}>
              <span className="profile_content_venue_item">{data.venue}</span>
            </Tooltip>
          </div>
        )}
        {data?.taskType && (
          <div className="profile_content_extras_content">
            <span className="profile_content_extra_item">
              <strong>Task Type: </strong>
              {data.taskType}
            </span>
          </div>
        )}
        {data?.dataInputType && (
          <div className="profile_content_extras_content">
            <span className="profile_content_extra_item">
              <strong>Data Input Type: </strong>
              {data.dataInputType}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
