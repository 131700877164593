import {
  REQUEST_SHOW_ALERT,
  REQUEST_HIDE_ALERT,
  REQUEST_AUTO_DISMISS_ALERT,
  NETWORK_ONLINE,
  NETWORK_OFFLINE,
  ACTIVATE_ALERT,
  REMOVE_ALERT
} from '../constants/actionTypes';

export const requestShowAlert = (alertData, alertType) => ({
  type: REQUEST_SHOW_ALERT,
  alertData,
  alertType
});

export const requestHideAlert = () => ({
  type: REQUEST_HIDE_ALERT
});

export const requestAutoHideAlert = (alertData, alertType, delay = 2000) => ({
  type: REQUEST_AUTO_DISMISS_ALERT,
  alertData,
  alertType,
  delay
});

export const activateAlert = (type, data, dismissible = true) => ({
  type: ACTIVATE_ALERT,
  alertType: type,
  alertData: data,
  dismissible
});

export const removeAlert = () => ({
  type: REMOVE_ALERT
});

export const requestNetworkOnline = () => ({
  type: NETWORK_ONLINE
});

export const requestNetworkOffline = () => ({
  type: NETWORK_OFFLINE
});
