import {
  GET_SPEAKER_REFERENCE_PAGINATION,
  GET_SPEAKER_REFERENCE_PAGINATION_SUCCESS,
  GET_SPEAKER_REFERENCE_PAGINATION_FAILED,
  EDIT_SPEAKER_REFERENCE,
  EDIT_SPEAKER_REFERENCE_SUCCESS,
  EDIT_SPEAKER_REFERENCE_FAILED,
  DELETE_SPEAKER_REFERENCE,
  DELETE_SPEAKER_REFERENCE_SUCCESS,
  DELETE_SPEAKER_REFERENCE_FAILED
} from '../constants/actionTypes';

export const getSpeakerReferenceWithPagination = (
  ocId,
  speakerId,
  pageNumber,
  callback
) => ({
  type: GET_SPEAKER_REFERENCE_PAGINATION,
  payload: { ocId, speakerId, pageNumber },
  callback
});

export const getSpeakerReferenceWithPaginationSuccess = (
  ocId,
  speakerId,
  data,
  links,
  included
) => ({
  type: GET_SPEAKER_REFERENCE_PAGINATION_SUCCESS,
  payload: { ocId, speakerId, data, links, included }
});

export const getSpeakerReferenceWithPaginationFailed = (error) => ({
  type: GET_SPEAKER_REFERENCE_PAGINATION_FAILED,
  error
});

export const editSpeakerReference = (
  ocId,
  speakerId,
  speakerReferenceId,
  formData,
  callback
) => ({
  type: EDIT_SPEAKER_REFERENCE,
  payload: { ocId, speakerId, speakerReferenceId, formData },
  callback
});

export const editSpeakerReferenceSuccess = (ocId, data, included) => ({
  type: EDIT_SPEAKER_REFERENCE_SUCCESS,
  payload: { ocId, data, included }
});

export const editSpeakerReferenceFailed = (error) => ({
  type: EDIT_SPEAKER_REFERENCE_FAILED,
  error
});

export const deleteSpeakerReference = (
  ocId,
  speakerId,
  speakerReferenceId,
  callback
) => ({
  type: DELETE_SPEAKER_REFERENCE,
  payload: { ocId, speakerId, speakerReferenceId },
  callback
});

export const deleteSpeakerReferenceSuccess = (
  ocId,
  speakerId,
  data,
  included
) => ({
  type: DELETE_SPEAKER_REFERENCE_SUCCESS,
  payload: {
    ocId,
    speakerId,
    data,
    included
  }
});

export const deleteSpeakerReferenceFailed = (error) => ({
  type: DELETE_SPEAKER_REFERENCE_FAILED,
  error
});
