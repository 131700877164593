import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Form } from 'antd';
import { isEqual } from 'lodash';
import moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../components/Section';
import { GUEST_ACCESS_TYPES } from '../../../../constants/OtherConstants';
import { LANDING_PAGE, OCCASION_VIEW } from '../Constant/index';
import validateOccasion from './validate';
import { seperateWordsWithSpace } from '../../../../helpers/stringHelper';
import {
  createOccasion,
  updateOccasion,
  getOccasionSettings
} from '../../../../actions/occasion';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import InputGroup from '../../../../components/InputGroup';
import {
  FormMap,
  SubmitButton,
  Switch
} from '../../../../components/InputGroup/FormComponents';
import { currentOccasionId } from '../../../../selectors/dynamicData';
import { getCurrentOccasion } from '../../../../selectors/occasion';
import { getImageSource } from '../../../../helpers/imageHelper';
import { renderRoute } from '../../../../components/RouterHOC';
import { adminCreateRouteAccess } from '../../../../selectors/auth';

function OccasionCreate(props) {
  const [occasionFormState, setOccasionFormState] = React.useState({
    ...props.initialValues
  });
  const [isLocationActive, setLocationActive] = React.useState(false);
  const [isLocationSet, setIsLocationSet] = useState(false);
  const [errorMessages, setErrorMessages] = React.useState({});
  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);
  const [locationFieldToggle, setLocationFieldToggle] = useState(true);
  const venueInput = React.useRef();

  useEffect(() => {
    if (!props.occasionTypes) {
      props.getOccasionSettings();
    }
  }, []);

  useEffect(() => {
    setOccasionFormState(props.initialValues);
  }, [props.initialValues]);

  const handleOccasionCreate = () => {
    try {
      const validateObj = validateOccasion(occasionFormState);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('occasion[name]', occasionFormState.name);
        formData.append(
          'occasion[occasion_view]',
          occasionFormState.occasionView
        );
        if (occasionFormState.guest_type) {
          formData.append('occasion[guest_type]', occasionFormState.guest_type);
        }

        formData.append(
          'occasion[managed_title]',
          occasionFormState.managed_title
        );

        if (occasionFormState.type) {
          formData.append('occasion[type]', occasionFormState.type);
        }
        if (occasionFormState.landingPage) {
          formData.append(
            'occasion[landing_page]',
            occasionFormState.landingPage
          );
        }
        if (occasionFormState.venue) {
          formData.append('occasion[venue]', occasionFormState.venue);
        }
        // if (
        //   occasionFormState.occasionPlace &&
        //   occasionFormState.occasionPlace.details
        // ) {
        //   formData.append(
        //     'occasion[venue]',
        //     occasionFormState.occasionPlace.details?.formatted_address
        //   );
        if (isLocationActive || !props.occasionId) {
          formData.append(
            'occasion[lattitude]',
            Number(occasionFormState.latitude).toFixed(6)
          );
          formData.append(
            'occasion[longitude]',
            Number(occasionFormState.longitude).toFixed(6)
          );
        }
        // } else {
        //   formData.append('occasion[venue]', occasionFormState.occasionPlace);
        // }
        formData.append('occasion[description]', occasionFormState.description);
        formData.append(
          'occasion[premagic_project_id]',
          occasionFormState?.premagicProjectId
        );
        if (occasionFormState?.schedule[0]) {
          formData.append(
            'occasion[start_at]',
            new Date(occasionFormState.schedule[0])
          );
        }
        if (occasionFormState?.schedule[1]) {
          formData.append(
            'occasion[end_at]',
            new Date(occasionFormState.schedule[1])
          );
        }

        if (
          occasionFormState.card_image &&
          occasionFormState?.card_image[0]?.originFileObj
        ) {
          formData.append(
            'occasion[card_image]',
            occasionFormState.card_image[0].originFileObj
          );
        }

        if (
          occasionFormState.card_image &&
          occasionFormState.card_image.length === 0
        ) {
          formData.append(
            'occasion[purge_has_one_attached_file_names][]',
            'card_image'
          );
        }

        if (
          occasionFormState.file &&
          occasionFormState?.file[0]?.originFileObj
        ) {
          formData.append(
            'occasion[occasion_file]',
            occasionFormState.file[0].originFileObj
          );
        }

        if (occasionFormState.file && occasionFormState.file.length === 0) {
          formData.append(
            'occasion[purge_has_one_attached_file_names][]',
            'occasion_file'
          );
        }

        if (
          occasionFormState.backgroundFile &&
          occasionFormState?.backgroundFile[0]?.originFileObj
        ) {
          formData.append(
            'occasion[background_file]',
            occasionFormState.backgroundFile[0].originFileObj
          );
        }

        if (
          occasionFormState.backgroundFile &&
          occasionFormState.backgroundFile.length === 0
        ) {
          formData.append(
            'occasion[purge_has_one_attached_file_names][]',
            'background_file'
          );
        }

        if (occasionFormState.splashScreenFile) {
          occasionFormState.splashScreenFile.forEach((file) => {
            if (file?.originFileObj)
              formData.append(
                'occasion[splash_screen_files][]',
                file?.originFileObj
              );
          });
        }
        const purgedSplashScreenFiles = props?.initialValues?.splashScreenFile?.filter(
          ({ id: id1 }) =>
            !occasionFormState?.splashScreenFile?.some(
              ({ id: id2 }) => id2 == id1
            )
        );

        if (purgedSplashScreenFiles?.length > 0) {
          purgedSplashScreenFiles.forEach((item) => {
            if (item?.id) {
              formData.append(
                'occasion[purge_splash_screen_files_ids][]',
                item.id
              );
            }
          });
        }

        if (
          occasionFormState.thankYouMedia &&
          occasionFormState?.thankYouMedia[0]?.originFileObj
        ) {
          formData.append(
            'occasion[thank_you_media]',
            occasionFormState.thankYouMedia[0].originFileObj
          );
        }

        if (
          occasionFormState.thankYouMedia &&
          occasionFormState.thankYouMedia.length === 0
        ) {
          formData.append(
            'occasion[purge_has_one_attached_file_names][]',
            'thank_you_media'
          );
        }

        if (
          occasionFormState.managed_farm_logo &&
          occasionFormState?.managed_farm_logo[0]?.originFileObj
        ) {
          formData.append(
            'occasion[managed_farm_logo]',
            occasionFormState.managed_farm_logo[0].originFileObj
          );
        }

        if (
          occasionFormState.managed_farm_logo &&
          occasionFormState.managed_farm_logo.length === 0
        ) {
          formData.append(
            'occasion[purge_has_one_attached_file_names][]',
            'managed_farm_logo'
          );
        }

        if (occasionFormState.floor_plan_images) {
          occasionFormState.floor_plan_images.forEach((image) => {
            if (image?.originFileObj)
              formData.append(
                'occasion[floor_plan_images][]',
                image?.originFileObj
              );
          });
        }
        const purgedFloorPlans = props?.initialValues?.floor_plan_images?.filter(
          ({ id: id1 }) =>
            !occasionFormState?.floor_plan_images?.some(
              ({ id: id2 }) => id2 == id1
            )
        );

        if (purgedFloorPlans?.length > 0) {
          purgedFloorPlans.forEach((item) => {
            if (item?.id) {
              formData.append(
                'occasion[purge_floor_plan_images_ids][]',
                item.id
              );
            }
          });
        }

        props.occasionId
          ? props.updateOccasion(
              props.occasionId,
              formData,
              props.initialValues.type,
              () => {
                setInitiallyDisabled(true);
                props.handleCloseClick();
              }
            )
          : props.createOccasion(formData, () => {
              setOccasionFormState({});
              setInitiallyDisabled(true);
            });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type, momentData) => {
    try {
      let value;
      let name;
      if (e) {
        if (isInitiallyDisabled) {
          setInitiallyDisabled(false);
        }
        if (
          type === 'card_image' ||
          type === 'floor_plan_images' ||
          type === 'managed_farm_logo' ||
          type === 'file' ||
          type === 'thankYouMedia' ||
          type === 'splashScreenFile' ||
          type === 'backgroundFile'
        ) {
          value = e.fileList;
          name = type;
        }

        if (type === 'schedule') {
          value = e.map((item) => moment(item, 'DD/MM/YYYY hh:mm'));
          name = type;
        }
        const occasionFormValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        if (type === 'venue') {
          setOccasionFormState({
            ...occasionFormState,
            latitude: e?.lat || '',
            longitude: e?.lng || ''
          });
        } else {
          setOccasionFormState({
            ...occasionFormState,
            [inputName]: occasionFormValue
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setVenue = (location) => {
    if (venueInput.current) {
      if (!isLocationSet && props.initialValues?.venue?.length > 0) {
        setIsLocationSet(true);
        return;
      }
      setOccasionFormState({ ...occasionFormState, venue: location });
    }
  };

  const toggleLocationField = () => {
    setLocationFieldToggle(!locationFieldToggle);
  };

  const getOccasionTypes = () =>
    props.occasionTypes
      ? props.occasionTypes.map((el) => ({
          label: seperateWordsWithSpace(el),
          value: el
        }))
      : [];

  const getOccasionGuestTypes = () =>
    props.occasionGuestTypes && Object.keys(props.occasionGuestTypes).length > 0
      ? Object.keys(props.occasionGuestTypes).map((key) => ({
          value: key,
          label: GUEST_ACCESS_TYPES[key]
        }))
      : [];

  const toggleLocation = (e) => {
    setLocationActive(e);
  };

  return (
    <ErrorBoundary>
      <SectionContent className="occasion-create-section">
        <div className="occasion-create-form">
          <Form className="occasion-form" onFinish={handleOccasionCreate}>
            <InputGroup
              fieldType="file"
              label="Occasion Image"
              accept="image/*"
              fileList={occasionFormState?.card_image}
              onChange={handleInputChange}
              name="card_image"
              errorMessage={errorMessages.card_image}
            />
            <InputGroup
              fieldType="file"
              label="Occasion File"
              fileList={occasionFormState?.file}
              onChange={handleInputChange}
              name="file"
            />
            <InputGroup
              fieldType="file"
              label="Splash Screen Files"
              fileList={occasionFormState?.splashScreenFile}
              onChange={handleInputChange}
              name="splashScreenFile"
              multiple={true}
              noMaxCount={true}
            />
            <InputGroup
              fieldType="file"
              label="Thank You Media"
              accept="image/*,video/*"
              fileList={occasionFormState?.thankYouMedia}
              onChange={handleInputChange}
              name="thankYouMedia"
            />
            <InputGroup
              fieldType="file"
              label="Background File"
              fileList={occasionFormState?.backgroundFile}
              onChange={handleInputChange}
              name="backgroundFile"
            />
            <InputGroup
              fieldType="dropdown"
              options={getOccasionTypes()}
              name="type"
              label="Occasion Category"
              onChange={handleInputChange}
              value={occasionFormState?.type}
              errorMessage={errorMessages.type}
            />
            <InputGroup
              fieldType="dropdown"
              options={LANDING_PAGE}
              name="landingPage"
              label="Landing Page"
              onChange={handleInputChange}
              value={occasionFormState?.landingPage}
            />
            <InputGroup
              fieldType="dropdown"
              options={OCCASION_VIEW}
              name="occasionView"
              label="Occasion View"
              onChange={handleInputChange}
              value={occasionFormState?.occasionView}
            />
            <InputGroup
              fieldType="radioGroup"
              options={getOccasionGuestTypes()}
              name="guest_type"
              label="Guest Access"
              onChange={handleInputChange}
              value={occasionFormState?.guest_type}
              errorMessage={errorMessages.guest_type}
            />
            <InputGroup
              label="Occasion Name"
              name="name"
              placeholder="Enter Occasion Name"
              onChange={handleInputChange}
              value={occasionFormState?.name}
              errorMessage={errorMessages.name}
            />
            <InputGroup
              label="Occasion Description"
              name="description"
              fieldType="textArea"
              placeholder="Enter Occasion Description"
              onChange={handleInputChange}
              value={occasionFormState?.description}
              errorMessage={errorMessages.description}
            />
            <InputGroup
              label="Premagic Project Id"
              name="premagicProjectId"
              placeholder="Enter Premagic Project Id"
              onChange={handleInputChange}
              value={occasionFormState?.premagicProjectId}
            />
            <InputGroup
              label="Occasion Venue"
              name="venue"
              placeholder="Enter Venue"
              ref={venueInput}
              onChange={handleInputChange}
              value={occasionFormState?.venue}
              errorMessage={errorMessages.venue}
            />

            {props.occasionId && (
              <div style={{ marginBottom: '10px' }}>
                <span className="location-header">
                  {isLocationActive
                    ? 'Toggle to hide location on map'
                    : 'Toggle to select location on map'}
                </span>
                <Switch
                  value={isLocationActive}
                  onChange={(e) => toggleLocation(e)}
                />
              </div>
            )}
            {(isLocationActive || !props.occasionId) && (
              <div>
                <div>
                  <span className="location-header">
                    {locationFieldToggle
                      ? 'Toggle to enter coordinates manually'
                      : 'Toggle to select location on map'}
                  </span>
                  <Switch
                    value={locationFieldToggle}
                    onChange={toggleLocationField}
                    noChildren={true}
                  />
                </div>
                {locationFieldToggle ? (
                  <FormMap
                    label="Location"
                    errorMessage={errorMessages.location}
                    setVenue={setVenue}
                    value={{
                      latitude: occasionFormState?.latitude,
                      longitude: occasionFormState?.longitude
                    }}
                    onChange={handleInputChange}
                  />
                ) : (
                  <>
                    <InputGroup
                      onChange={handleInputChange}
                      placeholder="Longitude"
                      label="Longitude"
                      name="longitude"
                      value={occasionFormState?.longitude}
                      errorMessage={errorMessages.longitude}
                    />
                    <InputGroup
                      onChange={handleInputChange}
                      placeholder="Latitude"
                      label="Latitude"
                      name="latitude"
                      value={occasionFormState?.latitude}
                      errorMessage={errorMessages.latitude}
                    />
                  </>
                )}
              </div>
            )}
            <InputGroup
              fieldType="rangePicker"
              onChange={handleInputChange}
              placeholder={['Start Date with Time', 'End Date with Time']}
              label="Schedule"
              name="schedule"
              value={occasionFormState?.schedule}
              errorMessage={errorMessages.schedule}
            />
            <InputGroup
              fieldType="file"
              label="Floor Plans"
              multiple={true}
              accept="image/*"
              name="floor_plan_images"
              onChange={handleInputChange}
              fileList={occasionFormState?.floor_plan_images}
              errorMessage={errorMessages.floor_plan_images}
              noMaxCount={true}
            />
            <InputGroup
              label="Managed by Title"
              name="managed_title"
              placeholder="Enter Managed by name"
              onChange={handleInputChange}
              value={occasionFormState?.managed_title}
              errorMessage={errorMessages.managed_title}
            />
            <InputGroup
              fieldType="file"
              label="Managed Farm Logo"
              accept="image/*"
              fileList={occasionFormState?.managed_farm_logo}
              onChange={handleInputChange}
              name="managed_farm_logo"
              errorMessage={errorMessages.managed_farm_logo}
            />
            <SubmitButton
              disabled={
                props.fetchProgress ||
                isEqual(props.initialValues, occasionFormState) ||
                isInitiallyDisabled
              }
              fetchProgress={props.fetchProgress}
              buttonText={props.occasionId ? 'Save Changes' : 'Create Occasion'}
            />
          </Form>
          {!!props.occasionId && (
            <Button
              onClick={props.handleCloseClick}
              className="edit-close-button">
              <CloseOutlined />
            </Button>
          )}
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => {
  const occasionId = currentOccasionId(store, props);
  const currentOccasion = getCurrentOccasion(store, props);
  const floorPlanData =
    currentOccasion?.floorPlanImageUrls?.length > 0
      ? currentOccasion?.floorPlanImageUrls.map((item) => ({
          id: Object.keys(item)[0],
          url:
            Object.values(item)?.length > 0 &&
            getImageSource(Object.values(item)[0])
        }))
      : null;
  const splashScreenFileData =
    currentOccasion?.splashScreenFileUrls?.length > 0
      ? currentOccasion?.splashScreenFileUrls.map((item) => ({
          id: Object.keys(item)[0],
          url:
            Object.values(item)?.length > 0 &&
            getImageSource(Object.values(item)[0])
        }))
      : null;
  const initialValues = {
    name: currentOccasion?.name || '',
    description: currentOccasion?.description || '',
    premagicProjectId: currentOccasion?.premagicProjectId || '',
    type: currentOccasion?.type || '',
    guest_type: currentOccasion?.guestType || '',
    landingPage: currentOccasion?.landingPage || '',
    occasionView: currentOccasion?.occasionView || 'normal',
    venue: currentOccasion?.venue || '',
    card_image: currentOccasion?.cardImageUrl
      ? [{ url: getImageSource(currentOccasion?.cardImageUrl) }]
      : null,
    file: currentOccasion?.occasionFileUrl
      ? [{ url: getImageSource(currentOccasion?.occasionFileUrl) }]
      : null,
    splashScreenFile: splashScreenFileData,
    backgroundFile: currentOccasion?.backgroundFileUrl
      ? [{ url: getImageSource(currentOccasion?.backgroundFileUrl) }]
      : null,
    thankYouMedia: currentOccasion?.thankYouMediaUrl
      ? [{ url: getImageSource(currentOccasion?.thankYouMediaUrl) }]
      : null,
    floor_plan_images: floorPlanData,
    latitude: currentOccasion?.lattitude || '',
    longitude: currentOccasion?.longitude || '',
    managed_title: currentOccasion?.managedTitle || '',
    managed_farm_logo: currentOccasion?.managedFarmLogoUrl
      ? [{ url: getImageSource(currentOccasion?.managedFarmLogoUrl) }]
      : null,
    schedule: [moment(currentOccasion?.startAt), moment(currentOccasion?.endAt)]
  };
  return {
    occasionId,
    initialValues,
    fetchProgress: store.browserState.fetchProgress,
    occasionTypes: store.occasion.types,
    occasionGuestTypes: store.occasion.guestTypes
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createOccasion,
      updateOccasion,
      getOccasionSettings
    },
    dispatch
  );

export default renderRoute(adminCreateRouteAccess)(
  connect(mapStateToProps, mapDispatchToProps)(OccasionCreate)
);
