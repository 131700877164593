export const changeUnderscoreToSpace = (word) =>
  String(word).split('_').join(' ');

export const seperateWordsWithSpace = (word) =>
  String(word).replace(/([a-z\d])([A-Z])/g, '$1' + ' ' + '$2');

export const decamelize = (str, separator) => {
  separator = typeof separator === 'undefined' ? ' ' : separator;

  return str
    .replace(/([a-z\d])([A-Z])/g, `$1${separator}$2`)
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, `$1${separator}$2`)
    .toLowerCase();
};

export const capitalize = (word) =>
  word.replace(/(^|\s)\S/g, (l) => String(l).toUpperCase());

export const countCharactersInAString = (str, character) => {
  const re = new RegExp(character, 'g');
  const count = str.match(re).length;
  return count;
};

export const getNameInitials = (name) => {
  if (typeof name !== 'string') {
    return '';
  }

  const arr = name.trim().split(' ');
  return arr.length > 1
    ? `${arr[0].charAt(0)}${arr[arr.length - 1].charAt(0)}`
    : `${arr[0].charAt(0)}`;
};
