import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useAgora } from '../../../../../../hooks/useAgora';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import { MediaPlayer } from '../../../../../../components/MediaPlayer';
import {
  CaretRightFilled,
  LoadingOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import './styles.scss';
import { useLocation } from 'react-router-dom';
import { Dot } from '../../../../../../components/Dot';

const Stream = () => {
  const [streamControl, setStreamControl] = useState(false);
  const {
    localVideoTrack,
    publishStream,
    endStream,
    setAgoraClient,
    error,
    loading
  } = useAgora({
    role: 'host'
  });
  const location = useLocation();

  useEffect(async () => {
    setClient();
    return await endStream;
  }, []);

  const setClient = () => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token').replaceAll(' ', '+');
    const channel = queryParams.get('name');
    const uid = queryParams.get('uid');

    setAgoraClient({
      appId: process.env.REACT_AGORA_APP_ID,
      token,
      channel,
      uid
    });
  };

  const startStreaming = () => {
    publishStream();
  };

  const streamController = () => {
    setStreamControl((prevValue) => {
      if (!prevValue) {
        startStreaming();
        return !prevValue;
      } else {
        if (!window.confirm('Are you sure you want to quit the stream?')) {
          return prevValue;
        }
        endStream();
        return null;
      }
    });
  };

  if (loading) {
    return (
      <div className="stream-video-container black-background">
        <LoadingOutlined className="loader-icon" />
        <p className="text-box">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="stream-video-container black-background column">
        <p className="text-box">Something went wrong!</p>
        <p className="text-box">
          Retry <ReloadOutlined onClick={setClient} />
        </p>
      </div>
    );
  }

  if (streamControl === null) {
    return (
      <div className="stream-video-container">
        <p className="text-box black-text">You have stopped the live stream.</p>
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <div className="stream-video-container">
        {streamControl && (
          <div className="live-icon">
            <Dot color="white" size={8} /> Live
          </div>
        )}
        <MediaPlayer videoTrack={localVideoTrack} />
        {streamControl !== null && (
          <div className="stream-controls">
            <Button
              title={!streamControl ? 'Start Streaming' : 'Stop Streaming'}
              className={`stream-btn ${!streamControl ? 'white' : 'red'}`}
              onClick={streamController}>
              {!streamControl ? (
                <CaretRightFilled className="caret-icon" />
              ) : (
                <div className="stop-icon" />
              )}
            </Button>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default Stream;
