import request from './request';

export function occasionCarouselCreateApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/sociana_think/carousel_segment_details`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function occasionCarouselViewApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/sociana_think/carousel_segment_details?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}
