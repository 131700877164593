export const RoutingEndPoints = {
  unAuthorized: {
    signIn: {
      path: '/signin'
    },
    forgotPassword: {
      path: '/forgotPassword'
    },
    resetPassword: {
      path: '/resetPassword'
    }
  },
  authorized: {
    dashboard: {
      path: '/dashboard'
    },
    occasion: {
      path: '/dashboard/occasion',
      create: {
        path: '/dashboard/occasion/create'
      },
      view: {
        path: '/dashboard/occasion/view'
      },
      edit: {
        path: '/dashboard/occasion/edit/:occasionId'
      }
    },
    event: {
      view: {
        path: '/event/view'
      }
    },
    competition: {
      view: {
        path: '/competition/view'
      }
    },
    hardLogout: {
      view: {
        path: '/hardLogout/view'
      }
    },
    eventAffair: {
      view: {
        path: '/eventAffair/view'
      }
    },
    schedule: {
      view: {
        path: '/schedule/view'
      }
    },
    group: {
      view: {
        path: '/group/view'
      }
    },
    activityTracker: {
      view: {
        path: '/activityTracker/view'
      }
    },
    hospitality: {
      view: {
        path: '/hospitalityCategory/view'
      }
    },
    ticket: {
      view: {
        path: '/ticket/view'
      }
    },
    speaker: {
      view: {
        path: '/speaker/view'
      }
    },
    division: {
      view: {
        path: '/division/view'
      }
    },
    member: {
      view: {
        path: '/member/view'
      }
    },
    album: {
      view: {
        path: '/album/view'
      }
    },
    faceInfoMedia: {
      view: {
        path: '/media/view'
      }
    },
    faceInfoUser: {
      view: {
        path: '/user/view'
      }
    },
    hospitalityTask: {
      view: {
        path: '/hospitalityTask/view'
      }
    },
    timeTrigger: {
      view: {
        path: '/timeTrigger/view'
      }
    },
    admin: {
      path: '/dashboard/admin',
      create: {
        path: '/dashboard/admin/create'
      },
      view: {
        path: '/dashboard/admin/view'
      }
    },
    socianaInternalUser: {
      path: '/dashboard/socianaInternalUser',
      create: {
        path: '/dashboard/socianaInternalUser/create'
      },
      view: {
        path: '/dashboard/socianaInternalUser/view'
      },
      edit: {
        path: '/dashboard/socianaInternalUser/edit'
      }
    },
    user: {
      path: '/dashboard/user',
      create: {
        path: '/dashboard/user/create'
      },
      view: {
        path: '/dashboard/user/view'
      }
    },
    enquiryRequest: {
      path: '/dashboard/enquiryRequest',
      view: {
        path: '/dashboard/enquiryRequest/view'
      }
    },
    whatsAppTemplates: {
      path: '/dashboard/whatsAppTemplates',
      create: {
        path: '/dashboard/whatsAppTemplates/create'
      },
      view: {
        path: '/dashboard/whatsAppTemplates/view'
      }
    },
    whatsappTemplatesModelable: {
      view: {
        path: '/whatsappTemplatesModelable/view'
      }
    }
  }
};
