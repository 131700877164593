import { createSelector } from 'reselect';
import { currentTicketId, currentOccasionId } from './dynamicData';
import {
  ticketPurchaseEntities,
  ticketVariantEntities,
  userEntities,
  pendingTicketPurchaseEntities
} from './entity';
import {
  registeredPurchaseIds,
  registeredPurchaseLinks,
  unregisteredPurchaseIds,
  unregisteredPurchaseLinks
} from './static';

export const currentRegisteredPurchaseIds = createSelector(
  currentOccasionId,
  currentTicketId,
  registeredPurchaseIds,
  (ocId, ticketId, ids) =>
    ocId && ticketId && ids && ids[ocId] && ids[ocId][ticketId]
);

export const getAllRegisteredPurchaseData = createSelector(
  currentRegisteredPurchaseIds,
  ticketPurchaseEntities,
  ticketVariantEntities,
  userEntities,
  (ids, entities, variants, userData) =>
    (ids &&
      entities &&
      ids.map((id) => {
        const userId = entities[id]?.user?.id;
        const ticketVariantId = entities[id]?.ticketVariant?.id;
        const scannedBy = entities[id]?.scannedBy?.id;
        const users = userId && userData ? userData[userId] : '';
        const ticketVariants =
          ticketVariantId && variants ? variants[ticketVariantId]?.title : '';

        return {
          ...entities[id],
          amount: (parseInt(entities[id]?.amount) / 100).toFixed(2),
          scannedBy: scannedBy ? { ...userData[scannedBy] } : null,
          ticketVariants,
          users
        };
      })) ||
    []
);

export const getAllRegisteredPurchaseLinks = createSelector(
  currentOccasionId,
  currentTicketId,
  registeredPurchaseLinks,
  (ocId, ticketId, links) =>
    ocId && ticketId && links && links[ocId] && links[ocId][ticketId]
);

export const currentUnregisteredPurchaseIds = createSelector(
  currentOccasionId,
  currentTicketId,
  unregisteredPurchaseIds,
  (ocId, ticketId, ids) =>
    ocId && ticketId && ids && ids[ocId] && ids[ocId][ticketId]
);

export const getAllUnregisteredPurchaseData = createSelector(
  currentUnregisteredPurchaseIds,
  pendingTicketPurchaseEntities,
  ticketVariantEntities,
  (ids, entities, variants) =>
    (ids &&
      entities &&
      ids.map((id) => {
        const ticketVariantId = entities[id]?.ticketVariant?.id;

        const ticketVariants =
          ticketVariantId && variants ? variants[ticketVariantId]?.title : '';

        return {
          ...entities[id],
          amount: (parseInt(entities[id]?.amount) / 100).toFixed(2),
          ticketVariants
        };
      })) ||
    []
);

export const getAllUnregisteredPurchaseLinks = createSelector(
  currentOccasionId,
  currentTicketId,
  unregisteredPurchaseLinks,
  (ocId, ticketId, links) =>
    ocId && ticketId && links && links[ocId] && links[ocId][ticketId]
);
