import { UPDATE_OCCASION_FONT_SUCCESS } from '../constants/actionTypes';

const initialState = {
  occasionFontData: null
};

const occasionFont = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_OCCASION_FONT_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionFontData || {};
      return {
        ...state,
        occasionFontData: {
          ...occasionGroupedData,
          [ocId]: data.id
        }
      };
    }

    default:
      return state;
  }
};

export default occasionFont;
