import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import { getTableColumn } from './TableColumns';
import './styles.scss';

const TableView = ({
  isPaginated = true,
  data = [],
  paginationOptions = {},
  onPageChange = () => {},
  paginationLinks = {},
  fetchProgress = false,
  className = '',
  pathname = '',
  expandable,
  isEditable,
  isCreatable,
  onDelete,
  showDelete,
  isRouted = true,
  blacklistKeys = [],
  useLink = false,
  occasionId,
  customAction,
  normalTime
}) => {
  const [options, setOptions] = useState({});
  const getPageSize = useMemo(() => {
    if (paginationLinks?.self) {
      const decodedURI = decodeURI(paginationLinks?.self);
      const size = new URLSearchParams(decodedURI).get('page[size]');
      return size ? parseInt(size) : 10;
    }
    return 10;
  }, [paginationLinks?.self]);

  const getTotalPages = useMemo(() => {
    if (paginationLinks?.last) {
      const decodedURI = decodeURI(paginationLinks?.last);
      const number = decodedURI
        .split('page[number]')[1]
        .replace('=', '')
        .split('&');
      return number ? parseInt(number) * getPageSize : 1;
    }
    return 1;
  }, [paginationLinks?.last, getPageSize]);

  useEffect(() => {
    if (isPaginated) {
      setOptions({
        position: ['topRight'],
        pageSize: getPageSize,
        total: getTotalPages,
        onChange: onPageChange,
        showSizeChanger: false,
        showQuickJumper: true,
        responsive: true
      });
    }
  }, [isPaginated, getPageSize, getTotalPages, onPageChange]);

  const expandableConfig = () => {
    return {
      expandedRowRender: (record) => expandable(record)
    };
  };

  return (
    <Table
      pagination={{
        ...options,
        ...paginationOptions
      }}
      className={className}
      loading={fetchProgress}
      columns={getTableColumn(
        data,
        isRouted,
        blacklistKeys,
        pathname,
        useLink,
        occasionId,
        isEditable,
        onDelete,
        showDelete,
        customAction,
        normalTime,
        isCreatable
      )}
      rowKey={(record) => record?.id}
      scroll={{
        x: '100%'
      }}
      dataSource={data}
      expandable={
        expandable && typeof expandable === 'function'
          ? expandableConfig()
          : undefined
      }
    />
  );
};

export default TableView;
