import {
  OCCASION_SUBDIVISION_CREATE,
  OCCASION_SUBDIVISION_CREATE_FAILED,
  OCCASION_SUBDIVISION_CREATE_SUCCESS,
  OCCASION_SUBDIVISION_VIEW,
  OCCASION_SUBDIVISION_VIEW_FAILED,
  OCCASION_SUBDIVISION_VIEW_SUCCESS
} from '../constants/actionTypes';

export const occasionSubdivisionCreate = (
  ocId,
  divisionId,
  formData,
  callback
) => ({
  type: OCCASION_SUBDIVISION_CREATE,
  payload: { ocId, divisionId, formData },
  callback
});

export const occasionSubdivisionCreateSuccess = (
  data,
  ocId,
  divisionId,
  included,
  links
) => ({
  type: OCCASION_SUBDIVISION_CREATE_SUCCESS,
  payload: { data, ocId, divisionId, included, links }
});

export const occasionSubdivisionCreateFailed = (error) => ({
  type: OCCASION_SUBDIVISION_CREATE_FAILED,
  error
});

export const occasionSubdivisionView = (ocId, divisionId, page) => ({
  type: OCCASION_SUBDIVISION_VIEW,
  payload: { ocId, divisionId, page }
});

export const occasionSubdivisionViewSuccess = (
  data,
  ocId,
  divisionId,
  included,
  links
) => ({
  type: OCCASION_SUBDIVISION_VIEW_SUCCESS,
  payload: { data, ocId, divisionId, included, links }
});

export const occasionSubdivisionViewFailed = (error) => ({
  type: OCCASION_SUBDIVISION_VIEW_FAILED,
  error
});
