import { union } from 'lodash';
import {
  ACTIVITY_TRACKER_CREATE_FAILED,
  ACTIVITY_TRACKER_CREATE_SUCCESS,
  ACTIVITY_TRACKER_VIEW_FAILED,
  ACTIVITY_TRACKER_VIEW_SUCCESS,
  BLACK_LIST_USER_CREATE_SUCCESS,
  BLACK_LIST_USER_VIEW_SUCCESS,
  DELETE_ACTIVITY_TRACKER_FAILED,
  DELETE_ACTIVITY_TRACKER_SUCCESS,
  DELETE_BLACK_LIST_USER_SUCCESS,
  DELETE_BLACK_LIST_USER_FAILED,
  BLACK_LIST_USER_CREATE_FAILED,
  BLACK_LIST_USER_VIEW_FAILED
} from '../constants/actionTypes';

const initialState = {
  activityTrackerData: null,
  activityTrackerLinks: null,
  error: null,
  blackListUserData: null,
  blackListUserLinks: null,
  blackListUserCount: 0
};

const activityTracker = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVITY_TRACKER_VIEW_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const activityTrackerGroupedData = state.activityTrackerData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        activityTrackerData: {
          ...activityTrackerGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        activityTrackerLinks: links
      };
    }

    case ACTIVITY_TRACKER_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const activityTrackerGroupedData = state.activityTrackerData || {};
      const currentOccasionAwardIds = activityTrackerGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionAwardIds];
      return {
        ...state,
        activityTrackerData: {
          ...activityTrackerGroupedData,
          [ocId]: newIds
        }
      };
    }

    case DELETE_ACTIVITY_TRACKER_SUCCESS: {
      const { data, ocId } = action.payload;
      const activityTrackerGroupedData = state.activityTrackerData || {};
      const currentActivityTrackerIds = activityTrackerGroupedData[ocId] || [];
      const filteredIds = currentActivityTrackerIds.filter(
        (id) => id !== data.id
      );

      return {
        ...state,
        activityTrackerData: {
          ...activityTrackerGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case BLACK_LIST_USER_VIEW_SUCCESS: {
      const { data, ocId, links, meta } = action.payload;
      const blackListGroupedData = state.blackListUserData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        blackListUserData: {
          ...blackListGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        blackListUserLinks: links,
        blackListUserCount: meta?.totalCount || 0
      };
    }

    case BLACK_LIST_USER_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const blackListGroupedData = state.blackListUserData || {};
      const currentOccasionBlackListIds = blackListGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionBlackListIds];
      return {
        ...state,
        blackListUserData: {
          ...blackListGroupedData,
          [ocId]: newIds
        }
      };
    }

    case DELETE_BLACK_LIST_USER_SUCCESS: {
      const { data, ocId } = action.payload;
      const blackListGroupedData = state.blackListUserData || {};
      const currentBlackListIds = blackListGroupedData[ocId] || [];
      const filteredIds = currentBlackListIds.filter((id) => id !== data.id);

      return {
        ...state,
        blackListUserData: {
          ...blackListGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case DELETE_BLACK_LIST_USER_FAILED:
    case BLACK_LIST_USER_CREATE_FAILED:
    case BLACK_LIST_USER_VIEW_FAILED:
    case ACTIVITY_TRACKER_VIEW_FAILED:
    case ACTIVITY_TRACKER_CREATE_FAILED:
    case DELETE_ACTIVITY_TRACKER_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default activityTracker;
