import { Empty, List, Button } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SyncOutlined } from '@ant-design/icons';
import {
  getPostWithPagination,
  deletePost,
  downloadExslExportUserPost
} from '../../../../../actions/post';
import PostCard from '../../../../../components/PostCard';
import PostCreate from './Create';
import './styles.scss';
import { currentOccasionId } from '../../../../../selectors/dynamicData';
import { getPostsWithPostedByData } from '../../../../../selectors/post';
import { postLinks } from '../../../../../selectors/static';
import useLinkDownloader from '../../../../../hooks/useLinkDownloader';

const PostsView = (props) => {
  const [page, setPage] = useState(1);
  const [isEditOpen, setEditOpen] = useState(null);

  const { downloadLinkFile } = useLinkDownloader();

  const toggleEdit = (id) => {
    setEditOpen(id);
  };

  useEffect(() => {
    props.getPostWithPagination(props.occasionId, 'Occasion', page);
  }, [page]);

  const onPageRefresh = (page) => {
    props.getPostWithPagination(props.occasionId, 'Occasion', page);
  };

  const onEdit = (id) => {
    toggleEdit(id);
  };

  const onDelete = (id) => {
    props.deletePost(props.occasionId, 'Occasion', id);
  };

  const postRenderItem = (item) => (
    <PostCard
      id={item?.id}
      key={item?.id}
      totalComment={item?.totalComment}
      hashTag={item?.hashTag}
      pictureUrl={item?.pictureUrl}
      message={item?.message}
      postedByDetails={item?.postedByDetails}
      cdnPictureUrl={item?.cdnPictureUrl}
      videoUrls={item?.videoUrls}
      cdnVideoUrls={item?.cdnPictureUrls}
      likeCount={item?.likeCount}
      occasionId={props.occasionId}
      onDelete={onDelete}
      onEdit={onEdit}
      disableLike={true}
      disableComment={true}
      createdAt={item?.createdAt}
    />
  );

  const handleDownload = () => {
    try {
      props.downloadExslExportUserPost(props.occasionId, (blobUrl) => {
        downloadLinkFile(
          blobUrl,
          `${props.currentOccasion?.name} User Post Details`,
          'xlsx'
        );
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getNextPage = (str) => {
    const str1 = str.split('=');
    const res = str1[1].split('&');
    return parseInt(res[0], 10);
  };

  const onLoadMore = () => {
    if (!!props.postLinks?.next) {
      const pageNumber = getNextPage(props?.postLinks?.next);
      setPage(pageNumber);
    }
  };

  const loadMore = useMemo(() => {
    return !!props.postLinks?.next ? (
      <div className="load-more">
        <Button onClick={onLoadMore}>loading more</Button>
      </div>
    ) : null;
  }, [props?.postLinks?.next]);

  if (!isEditOpen)
    return (
      <div className="post-main-container">
        <Button className="download-button" onClick={handleDownload}>
          Export User Post Details
        </Button>
        <div className="post-container">
          <div className="button-box">
            <Button
              className="refresh-button"
              onClick={() => onPageRefresh(page)}>
              <SyncOutlined />
            </Button>
          </div>
          {props.data?.length > 0 ? (
            <List
              dataSource={props?.data}
              renderItem={postRenderItem}
              loading={props.fetchProgress}
              loadMore={loadMore}
            />
          ) : (
            <Empty />
          )}
        </div>
      </div>
    );

  return (
    <div className="post-container">
      <PostCreate
        postId={isEditOpen}
        occasionId={props.occasionId}
        toggleEdit={toggleEdit}
        postableType="Occasion"
      />
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  occasionId: currentOccasionId(store, props),
  data: getPostsWithPostedByData(store, props),
  fetchProgress: store.browserState.fetchProgress,
  postLinks: postLinks(store, props)
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getPostWithPagination, deletePost, downloadExslExportUserPost },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PostsView);
