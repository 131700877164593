import React from 'react';
import { Image } from 'antd';
import { ZoomInOutlined, EditOutlined } from '@ant-design/icons';
import { getImageSource } from '../../../../../helpers/imageHelper';
import CreateGroup from './GroupCreate';

const GroupDetailsView = ({ groupDetails, occasionId }) => {
  const [isEditOpen, setIsEditOpen] = React.useState(false);
  if (!isEditOpen) {
    return (
      <div className="group-details-container">
        <p className="group-details card-details">
          <p className="fields">GroupIcon:</p>
          <p className="field-data card-image-container">
            <Image
              src={getImageSource(groupDetails?.groupPictureUrl)}
              preview={{
                maskClassName: 'preview-style',
                mask: <ZoomInOutlined />
              }}
              className="image-style"
            />
          </p>
        </p>
        <p className="group-details">
          <p className="fields">Name:</p>
          <p className="field-data">{groupDetails?.name}</p>
        </p>
        <p className="group-details">
          <p className="fields">Description:</p>
          {groupDetails?.description ? (
            <p className="field-data">{groupDetails?.description}</p>
          ) : (
            'NA'
          )}
        </p>
        <button
          className="edit-details-container"
          onClick={() => setIsEditOpen(true)}>
          <EditOutlined />
          <span>Edit Details</span>
        </button>
      </div>
    );
  }
  return (
    <div className="edit-container">
      <CreateGroup
        groupId={groupDetails?.id}
        occasionId={occasionId}
        groupDetails={groupDetails}
        handleCloseClick={() => setIsEditOpen(false)}
      />
    </div>
  );
};
export default GroupDetailsView;
