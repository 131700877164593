import React, { useState } from 'react';
import { capitalize } from '../../helpers/stringHelper';
import './styles.scss';
import { Avatar } from '../Avatar';

export const PollPostOptionContent = ({ data }) => {
  if (data.length > 0) {
    let totalCount = 0;
    data.forEach((item) => {
      totalCount += item.answerCount;
    });
    const totalOption = data.length;
    return (
      <div className="poll-post-content">
        {data?.map((item) => {
          return (
            <div className="poll-post-content-box">
              <div className="poll-post-option-content">
                <div
                  style={{
                    width: `${(item?.answerCount / totalCount) * 100}%`,
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#179e8a',
                    margin: '0px',
                    borderRadius: '10px'
                  }}>
                  <span style={{ marginLeft: '5px' }}>{`${
                    (item?.answerCount / totalCount) * 100
                  }%`}</span>
                  <span className="poll-post-option">
                    {capitalize(item?.option || '')}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return '';
};
