import request from './request';

export function competitionCreateApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/competition/competitions`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function competitionViewApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/competition/competitions?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function deleteCompetitionApi(ocId, competitionId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/competition/competitions/${competitionId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function updateCompetitionApi(ocId, competitionId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/competition/competitions/${competitionId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}
