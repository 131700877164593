import React from 'react';
import './styles.scss';
import { Button, Image } from 'antd';
import { SectionContent } from '../../components/Section';
import NOT_FOUND_IMAGE1 from '../../assets/notFound-page-image1.png';
import NOT_FOUND_IMAGE2 from '../../assets/notFound-page-image2.png';
import { useHistory } from 'react-router-dom';
const NotFoundPage = () => {
  const history = useHistory();
  const backToHome = () => {
    history.replace('/dashboard');
  };
  return (
    <SectionContent className="notfound-section">
      <div className=" notfound-container">
        <div className="notfound-background-image-box">
          <Image
            className="notfound-background-image"
            src={NOT_FOUND_IMAGE2}
            preview={false}
          />
        </div>
        <div className="notfound-box">
          <div className="notfound-content">
            <div className="notfound-textbox">
              <h1 className="notfound-heading1">Oops!</h1>
              <h3 className="notfound-heading2">Not Enough Data</h3>
              <p className="notfound-text">
                You are seeing this page because this URL does not exist.
                Please,report to us if you think this is an error
              </p>
            </div>
            <div className="notfound-image-box">
              <Image
                className="notfound-image"
                src={NOT_FOUND_IMAGE1}
                preview={false}
              />
            </div>
          </div>

          <Button className="notfound-button" onClick={backToHome}>
            Back to home{' '}
          </Button>
        </div>
      </div>
    </SectionContent>
  );
};
export default NotFoundPage;
