import { createSelector } from 'reselect';
import {
  currentOccasionId,
  currentOccasionResourcesId,
  currentCategoryStatusId
} from './dynamicData';
import {
  categoryStatusEntities,
  groupEntities,
  occasionResourcesEntities
} from './entity';
import { categoryStatusIds, occasionResourcesIds } from './static';

export const currentOccasionResourcesIds = createSelector(
  currentOccasionId,
  occasionResourcesIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getAllOccasionResources = createSelector(
  occasionResourcesEntities,
  currentOccasionResourcesIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const currentOccasionResourcesData = createSelector(
  currentOccasionResourcesId,
  occasionResourcesEntities,
  (id, entity) => {
    const prvMetaData = (id && entity && entity[id]['metaData']) || {};
    const prvMultipleLinks =
      (id && entity && entity[id]['multipleLinks']) || {};
    const prvBody = (id && entity && entity[id]['body']) || [];
    return (
      id &&
      entity && {
        ...entity[id],
        metaData:
          Object.keys(prvMetaData).length > 0
            ? Object.keys(prvMetaData).map((key) => ({
                key: key,
                value: prvMetaData[key]
              }))
            : null,
        multipleLinks:
          Object.keys(prvMultipleLinks).length > 0
            ? Object.keys(prvMultipleLinks).map((key) => ({
                key: key,
                value: prvMultipleLinks[key]
              }))
            : null,
        body:
          prvBody.length > 0 ? prvBody.map((item) => ({ value: item })) : null
      }
    );
  }
);

export const currentCategoryStatusIds = createSelector(
  currentOccasionId,
  categoryStatusIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getAllCategoryStatus = createSelector(
  categoryStatusEntities,
  currentCategoryStatusIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getCategoryStatusWithGroupData = createSelector(
  getAllCategoryStatus,
  groupEntities,
  (data, entities) =>
    data &&
    entities &&
    data.length > 0 &&
    data.map((item) => ({
      ...item,
      groups: [...item?.groups?.map((el) => entities[el?.id])]
    }))
);

export const currentCategoryStatusData = createSelector(
  currentCategoryStatusId,
  categoryStatusEntities,
  (id, entities) => id && entities && entities[id]
);
