import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import { resetTabIndex } from '../../../../../actions/tabKeys';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import TableView from '../../../../../components/Table';
import { occasionTicketView } from '../../../../../actions/ticket';
import { occasionTicketWithEventData } from '../../../../../selectors/ticket';
import { Button } from 'antd';
import CreateTicket from './TicketCreate';

const ViewTicket = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.occasionTicketView(props.occasionId, page);
  };

  const location = useLocation();

  React.useEffect(() => {
    onPageChange(1);
    props.resetTabIndex('tickets');
  }, []);
  const paginationOptions = {
    showTotal: () => (
      <div className="button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={() => setCreateOpen(true)}>
          Create
        </Button>
      </div>
    )
  };
  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="ticket-view-section">
          <div className="ticket-view-container">
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              isRouted={true}
              pathname={`${location.pathname}/ticket/view`}
              onPageChange={onPageChange}
              data={props.ticketData}
              fetchProgress={props.fetchProgress}
              paginationLinks={props.ticketLinks}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <CreateTicket
        occasionId={props.occasionId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  ticketData: occasionTicketWithEventData(store, props),
  ticketLinks: store.ticket.ticketLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ occasionTicketView, resetTabIndex }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(ViewTicket);
