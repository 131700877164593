import {
  GET_USER_PAGINATION,
  GET_USER_PAGINATION_SUCCESS,
  GET_USER_PAGINATION_FAILED,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILED,
  GET_USER_BY_SEARCH,
  GET_USER_BY_SEARCH_SUCCESS,
  GET_USER_BY_SEARCH_FAILED,
  SEARCH_USERS,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAILED,
  GET_USER_BY_OCCASION,
  GET_USER_BY_OCCASION_FAILED,
  GET_USER_BY_OCCASION_SUCCESS,
  QR_CODE_CREATE,
  QR_CODE_CREATE_FAILED,
  QR_CODE_CREATE_SUCCESS,
  QR_CODE_DELETE,
  QR_CODE_DELETE_FAILED,
  QR_CODE_DELETE_SUCCESS
} from '../constants/actionTypes';
import { userId } from '../selectors/static';

export const getUserWithPagination = (page) => ({
  type: GET_USER_PAGINATION,
  payload: { page }
});

export const getUserWithPaginationSuccess = (data, links, meta) => ({
  type: GET_USER_PAGINATION_SUCCESS,
  payload: { data, links, meta }
});

export const getUserWithPaginationFailed = (error) => ({
  type: GET_USER_PAGINATION_FAILED,
  error
});

export const editUser = (formData, userId, callback) => ({
  type: EDIT_USER,
  payload: { formData, userId },
  callback
});

export const editUserSuccess = (data) => ({
  type: EDIT_USER_SUCCESS,
  payload: { data }
});

export const editUserFailed = (error) => ({
  type: EDIT_USER_FAILED,
  error
});

export const getUserBySearch = (
  searchData,
  fromInternalTeam,
  status,
  page
) => ({
  type: GET_USER_BY_SEARCH,
  payload: { searchData, fromInternalTeam, status, page }
});

export const getUserBySearchSuccess = (data, included, links, meta) => ({
  type: GET_USER_BY_SEARCH_SUCCESS,
  payload: { data, included, links, meta }
});

export const getUserBySearchFailed = (error) => ({
  type: GET_USER_BY_SEARCH_FAILED,
  error
});

export const searchUsers = (ocId, page, query) => ({
  type: SEARCH_USERS,
  payload: { query, page, ocId }
});

export const searchUsersSuccess = (data, included, links, page) => ({
  type: SEARCH_USERS_SUCCESS,
  payload: { data, included, links, page }
});

export const searchUsersFailed = (error) => ({
  type: SEARCH_USERS_FAILED,
  error
});

export const getUserByOccasion = (ocId, page) => ({
  type: GET_USER_BY_OCCASION,
  payload: { page, ocId }
});

export const getUserByOccasionSuccess = (data, included, links, page) => ({
  type: GET_USER_BY_OCCASION_SUCCESS,
  payload: { data, included, links, page }
});

export const getUserByOccasionFailed = (error) => ({
  type: GET_USER_BY_OCCASION_FAILED,
  error
});

export const qrCodeCreate = (userId, color, bgColor, callback) => ({
  type: QR_CODE_CREATE,
  payload: { userId, color, bgColor },
  callback
});

export const qrCodeCreateSuccess = (data, included) => ({
  type: QR_CODE_CREATE_SUCCESS,
  payload: { data, included }
});

export const qrCodeCreateFailed = (error) => ({
  type: QR_CODE_CREATE_FAILED,
  error
});

export const qrCodeDelete = (qrCodeId) => ({
  type: QR_CODE_DELETE,
  payload: { qrCodeId }
});

export const qrCodeDeleteSuccess = (data) => ({
  type: QR_CODE_DELETE_SUCCESS,
  payload: { data }
});

export const qrCodeDeleteFailed = (error) => ({
  type: QR_CODE_DELETE_FAILED,
  error
});
