import request from './request';

export function getMembers(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/get_collaborators?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}
export function memberCreateByCsvApi(ocId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/create_collaborators_by_csv`,
    {
      method: 'POST',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function searchMembersApi(ocId, memberData, page, token) {
  let params = '';

  if (memberData.info) {
    params += `&name_or_email_or_mobile=${memberData.info}`;
  }

  if (memberData.type) {
    params += `&type=${memberData.type}`;
  }

  return request(
    `api/v2/sociana_admin/oc/${ocId}/search_collaborators?page=${page}${params}`,
    {
      method: 'GET',
      token
    }
  );
}

export function deleteMemberApi(ocId, memberId, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/collaborators/${memberId}`, {
    method: 'DELETE',
    token
  });
}

export function updateMemberApi(ocId, memberId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/collaborators/${memberId}`, {
    method: 'PUT',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function downloadMemberDetailsByExlsApi(ocId, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/export_collaborators`, {
    method: 'GET',
    token,
    isBlob: true
  });
}

export function downloadQrCodeApi(ocId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/download_user_qrcode_by_occasion`,
    {
      method: 'GET',
      token,
      isBlob: true
    }
  );
}

export function memberDownloadExslExportApi(ocId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/export_user_device_present_collaborator`,
    {
      method: 'GET',
      token,
      isBlob: true
    }
  );
}
