import { union } from 'lodash';
import {
  EVENT_AFFAIR_ESSENCE_VIEW_SUCCESS,
  EVENT_AFFAIR_ESSENCE_CREATE_SUCCESS,
  EVENT_AFFAIR_ESSENCE_VIEW_FAILED,
  EVENT_AFFAIR_ESSENCE_CREATE_FAILED,
  EVENT_AFFAIR_ESSENCE_DELETE_SUCCESS,
  EVENT_AFFAIR_ESSENCE_DELETE_FAILED
} from '../constants/actionTypes';

const initialState = {
  eventAffairEssenceData: null,
  eventAffairEssenceLinks: null,
  error: null
};

const eventAffairEssence = (state = initialState, action) => {
  switch (action.type) {
    case EVENT_AFFAIR_ESSENCE_VIEW_SUCCESS: {
      const { ocId, eventId, eventAffairId, data, links } = action.payload;
      const occasionGroupedData = state.eventAffairEssenceData || {};
      const currentEventEssenceNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        eventAffairEssenceData: {
          ...occasionGroupedData,
          [eventAffairId]: [...currentEventEssenceNewIds]
        },
        eventAffairEssenceLinks: links
      };
    }

    case EVENT_AFFAIR_ESSENCE_CREATE_SUCCESS: {
      const { ocId, eventId, eventAffairId, data } = action.payload;
      const occasionGroupedData = state.eventAffairEssenceData || {};
      const currentEventAffairEssenceIds =
        occasionGroupedData[eventAffairId] || [];

      const newIds = [data.id, ...currentEventAffairEssenceIds];
      return {
        ...state,
        eventAffairEssenceData: {
          ...occasionGroupedData,
          [eventAffairId]: newIds
        }
      };
    }

    case EVENT_AFFAIR_ESSENCE_DELETE_SUCCESS: {
      const { ocId, eventId, eventAffairId, data } = action.payload;
      const occasionGroupedData = state.eventAffairEssenceData || {};
      const currentEventAffairEssenceIds =
        occasionGroupedData[eventAffairId] || [];

      const filterIds = currentEventAffairEssenceIds.filter(
        (id) => id !== data.id
      );
      return {
        ...state,
        eventAffairEssenceData: {
          ...occasionGroupedData,
          [eventAffairId]: filterIds
        }
      };
    }

    case EVENT_AFFAIR_ESSENCE_VIEW_FAILED:
    case EVENT_AFFAIR_ESSENCE_CREATE_FAILED:
    case EVENT_AFFAIR_ESSENCE_DELETE_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default eventAffairEssence;
