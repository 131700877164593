import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import TableView from '../../../../../components/Table';
import { UserDeviceView } from '../../../../../actions/userDevice';
import { getAllUserDeviceData } from '../../../../../selectors/userDevice';

const ViewUserDevice = (props) => {
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.UserDeviceView(props.userId, page);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const paginationOptions = {
    showTotal: () => (
      <div className="button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <div className="user-device-count-box">
          <span>TotalDevice</span>
          <span className="user-device-count">{props.userDeviceCount}</span>
        </div>
      </div>
    )
  };

  return (
    <ErrorBoundary>
      <SectionContent className="user-device-view-section">
        <div className="user-device-view-container">
          <TableView
            isPaginated={true}
            isRouted={false}
            paginationOptions={paginationOptions}
            onPageChange={onPageChange}
            data={props.userDeviceData}
            fetchProgress={props.fetchProgress}
            paginationLinks={props.userDeviceLinks}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  userDeviceData: getAllUserDeviceData(store, props),
  userDeviceLinks: store.userDevice.userDeviceLinks,
  userDeviceCount: store.userDevice.count
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ UserDeviceView }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(ViewUserDevice);
