import React from 'react';
import './styles.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import InternalUserOccasionAssign from './InternalUserOccasionAssign';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import { SectionContent } from '../../../../components/Section';
import {
  getInternalUsersParticipatedOccasions,
  deleteInternalUsersParticipatedOccasions
} from '../../../../actions/socianaInternalUser';
import TableView from '../../../../components/Table';
import { getInternalUsersParticipatedWithOccasion } from '../../../../selectors/socianaInternalUser';

function InternalUserOccasionView(props) {
  const [assignOpen, setAssignOpen] = React.useState(false);
  const [internalTeamOccasionId, setInternalTeamOccasionId] = React.useState(
    null
  );
  const [ocId, setOcId] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.getInternalUsersParticipatedOccasions(props.userId, page);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleCreate = () => {
    setOcId('');
    setInternalTeamOccasionId('');
    setAssignOpen(true);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="internal-team-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={handleCreate}>
          Assign
        </Button>

        <div className="internal-team-occasion-count-container">
          <span>Occasion</span>
          <span className="internal-team-occasion-count">{props.count}</span>
        </div>
      </div>
    )
  };

  const editData = (internalTeamOcId) => {
    const result = props.participatedOccasions.find(
      ({ id }) => id === internalTeamOcId
    );
    setOcId(result.occasion.id);
    setAssignOpen(true);
    setInternalTeamOccasionId(internalTeamOcId);
  };

  const deleteData = (internalTeamOcId) => {
    const result = props.participatedOccasions.find(
      ({ id }) => id === internalTeamOcId
    );
    props.deleteInternalUsersParticipatedOccasions(
      result.occasion.id,
      props.userId
    );
  };

  if (!assignOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="internal-team-participated-occasions-view-section">
          <div className="internal-team-participated-occasions-container">
            <TableView
              isPaginated={true}
              isRouted={false}
              paginationOptions={paginationOptions}
              onDelete={deleteData}
              isEditable={editData}
              fetchProgress={props.fetchProgress}
              paginationLinks={props.participatedOccasionsLinks}
              onPageChange={onPageChange}
              blacklistKeys={['occasionId', 'userId']}
              data={props.participatedOccasions}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <InternalUserOccasionAssign
        ocId={ocId}
        internalTeamOccasionId={internalTeamOccasionId}
        userId={props.userId}
        handleCloseClick={() => setAssignOpen(false)}
      />
    </div>
  );
}

const mapStateToProps = (store, props) => ({
  participatedOccasions: getInternalUsersParticipatedWithOccasion(store, props),
  fetchProgress: store.browserState.fetchProgress,
  participatedOccasionsLinks:
    store.socianaInternalUser?.participatedOccasionsLinks,
  count: store.socianaInternalUser.participatedOccasionsCount
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInternalUsersParticipatedOccasions,
      deleteInternalUsersParticipatedOccasions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalUserOccasionView);
