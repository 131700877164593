import { put, call, takeLatest, debounce } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  occasionGroupCreateApi,
  occasionGroupViewApi,
  updateOccasionGroupApi,
  getGroupMembersApi,
  createGroupMembersApi,
  getGroupOccasionUsersApi,
  searchGroupOccasionUsersApi,
  parseCSVtoAddGroupMembersApi,
  deleteGroupMembersApi,
  deleteOccasionGroupApi,
  SearchGroupMemberApi
} from '../api/group';
import {
  OCCASION_GROUP_CREATE,
  OCCASION_GROUP_UPDATE,
  OCCASION_GROUP_VIEW,
  GET_GROUP_MEMBERS,
  CREATE_GROUP_MEMBERS,
  GET_GROUP_OCCASION_USERS,
  SEARCH_GROUP_OCCASION_USERS,
  PARSE_GROUP_MEMBERS_CSV,
  DELETE_GROUP_MEMBERS,
  DELETE_OCCASION_GROUP,
  SEARCH_GROUP_MEMBER
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  occasionGroupCreateFailed,
  occasionGroupCreateSuccess,
  occasionGroupUpdateFailed,
  occasionGroupUpdateSuccess,
  occasionGroupViewFailed,
  occasionGroupViewSuccess,
  getGroupMembersSuccess,
  getGroupMembersFailed,
  createGroupMembersSuccess,
  createGroupMembersFailed,
  getGroupOccasionUsersSuccess,
  getGroupOccasionUsersFailed,
  searchGroupOccasionUsersSuccess,
  searchGroupOccasionUsersFailed,
  parseCSVtoAddGroupMembersSuccess,
  parseCSVtoAddGroupMembersFailed,
  deleteGroupMembersSuccess,
  deleteGroupMembersFailed,
  deleteOccasionGroupSuccess,
  deleteOccasionGroupFailed,
  searchGroupMemberSuccess,
  searchGroupMemberFailed
} from '../actions/group';

export function* occasionGroupCreateSaga(action) {
  try {
    const result = yield call(
      occasionGroupCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionGroupCreateSuccess(
          result.data,
          result.included,
          action.payload.ocId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionGroupCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionGroupViewSaga(action) {
  try {
    const result = yield call(
      occasionGroupViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionGroupViewSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          result.links
        )
      );
      if (typeof action?.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionGroupViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionGroupUpdateSaga(action) {
  try {
    const result = yield call(
      updateOccasionGroupApi,
      action.payload.ocId,
      action.payload.groupId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(occasionGroupUpdateSuccess(result.data));
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionGroupUpdateFailed(error));
    yield put(requestAutoHideAlert(error, 'Error', 4000));
  }
}

export function* getGroupMembersSaga(action) {
  try {
    const result = yield call(
      getGroupMembersApi,
      action.payload.ocId,
      action.payload.groupId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getGroupMembersSuccess(
          action.payload.ocId,
          action.payload.groupId,
          result.data,
          result.included,
          result.links,
          result.meta
        )
      );
      if (typeof action?.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getGroupMembersFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* createGroupMemberSaga(action) {
  try {
    const result = yield call(
      createGroupMembersApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        createGroupMembersSuccess(
          action.payload.ocId,
          action.payload.groupId,
          result.data,
          result.included
        )
      );
      if (typeof action?.callback === 'function') {
        action.callback();
      }
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta?.message, 'Success', 4000));
      }
    }
  } catch (error) {
    yield put(createGroupMembersFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getGroupOccasionUsersSaga(action) {
  try {
    const result = yield call(
      getGroupOccasionUsersApi,
      action.payload.ocId,
      action.payload.groupId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getGroupOccasionUsersSuccess(
          action.payload.ocId,
          action.payload.groupId,
          result.data,
          result.links,
          action.payload.page
        )
      );
      if (typeof action?.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getGroupOccasionUsersFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* searchGroupOccasionUsersSaga(action) {
  try {
    const result = yield call(
      searchGroupOccasionUsersApi,
      action.payload.ocId,
      action.payload.groupId,
      action.payload.page,
      action.payload.query,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        searchGroupOccasionUsersSuccess(
          action.payload.ocId,
          action.payload.groupId,
          result.data,
          result.included,
          result.links,
          action.payload.page
        )
      );
      if (typeof action?.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(searchGroupOccasionUsersFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* deleteGroupMembersSaga(action) {
  try {
    const result = yield call(
      deleteGroupMembersApi,
      action.payload.ocId,
      action.payload.memberId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        deleteGroupMembersSuccess(
          action.payload.ocId,
          action.payload.groupId,
          result.data,
          result.included,
          result.links
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta?.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteGroupMembersFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* parseCSVtoAddGroupMembersSaga(action) {
  try {
    const result = yield call(
      parseCSVtoAddGroupMembersApi,
      action.payload.ocId,
      action.payload.formData,
      action.payload.groupId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        parseCSVtoAddGroupMembersSuccess(
          action.payload.ocId,
          action.payload.groupId,
          result.data,
          result.included
        )
      );
      if (typeof action?.callback === 'function') {
        action.callback();
      }
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta?.message, 'Success', 4000));
      }
    }
  } catch (error) {
    yield put(parseCSVtoAddGroupMembersFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* deleteOccasionGroupSaga(action) {
  try {
    const result = yield call(
      deleteOccasionGroupApi,
      action.payload.ocId,
      action.payload.groupId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        deleteOccasionGroupSuccess(
          action.payload.ocId,
          action.payload.groupId,
          result.data,
          result.included,
          result.links
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta?.message, 'Success', 4000));
      }
      if (typeof action?.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteOccasionGroupFailed(error));
    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}
export function* searchGroupMemberSaga(action) {
  try {
    const result = yield call(
      SearchGroupMemberApi,
      action.payload.ocId,
      action.payload.groupId,
      action.payload.page,
      action.payload.memberType,
      action.payload.searchData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        searchGroupMemberSuccess(
          action.payload.ocId,
          action.payload.groupId,
          result.data,
          result.included,
          result.links,
          action.payload.page,
          result.meta
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta?.message, 'Success', 4000));
      }

      if (typeof action?.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(searchGroupMemberFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* groupActionWatcher() {
  yield takeLatest(
    OCCASION_GROUP_CREATE,
    progressSaga,
    occasionGroupCreateSaga
  );
  yield takeLatest(OCCASION_GROUP_VIEW, progressSaga, occasionGroupViewSaga);
  yield takeLatest(
    OCCASION_GROUP_UPDATE,
    progressSaga,
    occasionGroupUpdateSaga
  );
  yield takeLatest(GET_GROUP_MEMBERS, progressSaga, getGroupMembersSaga);
  yield takeLatest(CREATE_GROUP_MEMBERS, progressSaga, createGroupMemberSaga);
  yield takeLatest(
    GET_GROUP_OCCASION_USERS,
    progressSaga,
    getGroupOccasionUsersSaga
  );
  yield takeLatest(
    PARSE_GROUP_MEMBERS_CSV,
    progressSaga,
    parseCSVtoAddGroupMembersSaga
  );
  yield debounce(
    1000,
    SEARCH_GROUP_OCCASION_USERS,
    progressSaga,
    searchGroupOccasionUsersSaga
  );
  yield takeLatest(DELETE_GROUP_MEMBERS, progressSaga, deleteGroupMembersSaga);
  yield takeLatest(
    DELETE_OCCASION_GROUP,
    progressSaga,
    deleteOccasionGroupSaga
  );
  yield takeLatest(SEARCH_GROUP_MEMBER, progressSaga, searchGroupMemberSaga);
}
