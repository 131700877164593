import {
  OCCASION_LIVE_VIDEO_CREATE,
  OCCASION_LIVE_VIDEO_CREATE_SUCCESS,
  OCCASION_LIVE_VIDEO_CREATE_FAILED,
  OCCASION_LIVE_VIDEO_VIEW,
  OCCASION_LIVE_VIDEO_VIEW_SUCCESS,
  OCCASION_LIVE_VIDEO_VIEW_FAILED,
  OCCASION_LIVE_VIDEO_DELETE,
  OCCASION_LIVE_VIDEO_DELETE_SUCCESS,
  OCCASION_LIVE_VIDEO_DELETE_FAILED
} from '../constants/actionTypes';

export const occasionLiveVideoCreate = (ocId, formData, callback) => ({
  type: OCCASION_LIVE_VIDEO_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionLiveVideoCreateSuccess = (data, included, ocId) => ({
  type: OCCASION_LIVE_VIDEO_CREATE_SUCCESS,
  payload: { data, included, ocId }
});

export const occasionLiveVideoCreateFailed = (error) => ({
  type: OCCASION_LIVE_VIDEO_CREATE_FAILED,
  error
});

export const occasionLiveVideoView = (ocId, page) => ({
  type: OCCASION_LIVE_VIDEO_VIEW,
  payload: { ocId, page }
});

export const occasionLiveVideoViewSuccess = (data, included, ocId, links) => ({
  type: OCCASION_LIVE_VIDEO_VIEW_SUCCESS,
  payload: { data, included, ocId, links }
});

export const occasionLiveVideoViewFailed = (error) => ({
  type: OCCASION_LIVE_VIDEO_VIEW_FAILED,
  error
});

export const occasionLiveVideoDelete = (ocId, videoId) => ({
  type: OCCASION_LIVE_VIDEO_DELETE,
  payload: { ocId, videoId }
});

export const occasionLiveVideoDeleteSuccess = (ocId, videoId) => ({
  type: OCCASION_LIVE_VIDEO_DELETE_SUCCESS,
  payload: { ocId, videoId }
});

export const occasionLiveVideoDeleteFailed = (error) => ({
  type: OCCASION_LIVE_VIDEO_DELETE_FAILED,
  error
});
