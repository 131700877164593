import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { occasionCarouselCreate } from '../../../../../../actions/carousel';

const validateCarousel = (values) => {
  const error = {};
  if (!values.priority) {
    error.priority = 'Priority is required';
  }
  return error;
};

function CarouselCreate(props) {
  const [carouselData, setCarouselData] = React.useState({
    title: '',
    description: '',
    carouselImage: null
  });
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleSubmit = () => {
    try {
      const validateObj = validateCarousel(carouselData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('carousel_segment_detail[title]', carouselData.title);
        formData.append(
          'carousel_segment_detail[description]',
          carouselData.description
        );
        formData.append(
          'carousel_segment_detail[priority]',
          carouselData.priority
        );
        if (
          carouselData.carouselImage &&
          carouselData.carouselImage[0]?.originFileObj
        ) {
          formData.append(
            'carousel_segment_detail[carousel_image]',
            carouselData.carouselImage[0].originFileObj
          );
        }
        props.occasionCarouselCreate(props.occasionId, formData);
        setCarouselData({});
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'carouselImage') {
        value = e.fileList;
        name = type;
      }
      const formValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setCarouselData({
        ...carouselData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ErrorBoundary>
      <SectionContent className="carousel-create-section">
        <div className="carousel-create-form">
          <Form className="carousel-form" onFinish={handleSubmit}>
            <InputGroup
              label="Title"
              name="title"
              placeholder="Enter Title"
              onChange={handleInputChange}
              value={carouselData?.title}
            />
            <InputGroup
              label="Priority"
              type="Number"
              name="priority"
              placeholder="Enter Priority"
              onChange={handleInputChange}
              value={carouselData?.priority}
              errorMessage={errorMessages?.priority}
            />
            <InputGroup
              label="Description"
              name="description"
              fieldType="textArea"
              placeholder="Enter Description"
              onChange={handleInputChange}
              value={carouselData?.description}
            />
            <InputGroup
              name="carouselImage"
              fieldType="file"
              label="Carousel Image"
              accept="image/*"
              fileList={carouselData?.carouselImage}
              onChange={handleInputChange}
            />
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={'Create'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ occasionCarouselCreate }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarouselCreate);
