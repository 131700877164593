import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  deleteCategoryStatusApi,
  deleteOccasionResourcesApi,
  occasionCategoryStatusCreateApi,
  occasionCategoryStatusViewApi,
  occasionResourcesCreateApi,
  occasionResourcesViewApi,
  updateCategoryStatusApi,
  updateOccasionResourcesApi
} from '../api/occasionResources';
import {
  DELETE_CATEGORY_STATUS,
  DELETE_OCCASION_RESOURCES,
  OCCASION_CATEGORY_STATUS_CREATE,
  OCCASION_CATEGORY_STATUS_VIEW,
  OCCASION_RESOURCES_CREATE,
  OCCASION_RESOURCES_VIEW,
  UPDATE_CATEGORY_STATUS,
  UPDATE_OCCASION_RESOURCES
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  deleteCategoryStatusFailed,
  deleteCategoryStatusSuccess,
  deleteOccasionResourcesFailed,
  deleteOccasionResourcesSuccess,
  occasionCategoryStatusCreateFailed,
  occasionCategoryStatusCreateSuccess,
  occasionCategoryStatusViewFailed,
  occasionCategoryStatusViewSuccess,
  occasionResourcesCreateFailed,
  occasionResourcesCreateSuccess,
  occasionResourcesViewFailed,
  occasionResourcesViewSuccess,
  updateCategoryStatusFailed,
  updateCategoryStatusSuccess,
  updateOccasionResourcesFailed,
  updateOccasionResourcesSuccess
} from '../actions/occasionResources';

export function* occasionCategoryStatusCreateSaga(action) {
  try {
    const result = yield call(
      occasionCategoryStatusCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionCategoryStatusCreateSuccess(
          result.data,
          result.included,
          action.payload.ocId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionCategoryStatusCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionCategoryStatusCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionCategoryStatusViewSaga(action) {
  try {
    const result = yield call(
      occasionCategoryStatusViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionCategoryStatusViewSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionCategoryStatusViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionCategoryStatusUpdateSaga(action) {
  try {
    const result = yield call(
      updateCategoryStatusApi,
      action.payload.ocId,
      action.payload.categoryStatusId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateCategoryStatusSuccess(
          action.payload.ocId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateCategoryStatusFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateCategoryStatusFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionCategoryStatusDeleteSaga(action) {
  try {
    const result = yield call(
      deleteCategoryStatusApi,
      action.payload.ocId,
      action.payload.categoryStatusId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(deleteCategoryStatusSuccess(action.payload.ocId, result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteCategoryStatusFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionResourcesCreateSaga(action) {
  try {
    const result = yield call(
      occasionResourcesCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionResourcesCreateSuccess(action.payload.ocId, result.data)
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionResourcesCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionResourcesCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updateOccasionResourcesSaga(action) {
  try {
    const result = yield call(
      updateOccasionResourcesApi,
      action.payload.ocId,
      action.payload.ocResourceId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateOccasionResourcesSuccess(action.payload.ocId, result.data)
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateOccasionResourcesFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateOccasionResourcesFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionResourcesViewSaga(action) {
  try {
    const result = yield call(
      occasionResourcesViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionResourcesViewSuccess(
          action.payload.ocId,
          result.data,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionResourcesViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionResourcesDeleteSaga(action) {
  try {
    const result = yield call(
      deleteOccasionResourcesApi,
      action.payload.ocId,
      action.payload.ocResourceId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        deleteOccasionResourcesSuccess(action.payload.ocId, result.data)
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteOccasionResourcesFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* occasionResourcesActionWatcher() {
  yield takeLatest(
    OCCASION_RESOURCES_VIEW,
    progressSaga,
    occasionResourcesViewSaga
  );

  yield takeLatest(
    DELETE_OCCASION_RESOURCES,
    progressSaga,
    occasionResourcesDeleteSaga
  );

  yield takeLatest(
    OCCASION_RESOURCES_CREATE,
    progressSaga,
    occasionResourcesCreateSaga
  );

  yield takeLatest(
    UPDATE_OCCASION_RESOURCES,
    progressSaga,
    updateOccasionResourcesSaga
  );

  yield takeLatest(
    OCCASION_CATEGORY_STATUS_CREATE,
    progressSaga,
    occasionCategoryStatusCreateSaga
  );
  yield takeLatest(
    OCCASION_CATEGORY_STATUS_VIEW,
    progressSaga,
    occasionCategoryStatusViewSaga
  );
  yield takeLatest(
    UPDATE_CATEGORY_STATUS,
    progressSaga,
    occasionCategoryStatusUpdateSaga
  );
  yield takeLatest(
    DELETE_CATEGORY_STATUS,
    progressSaga,
    occasionCategoryStatusDeleteSaga
  );
}
