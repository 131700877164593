export const OCCASION_RESOURCES_STATUS = [
  { label: 'Active', value: true },
  { label: 'In Active', value: false }
];

export const OCCASION_RESOURCES_TYPES = [
  { label: 'Image', value: 'image' },
  { label: 'Video', value: 'video' },
  { label: 'File', value: 'file' },
  { label: 'Link', value: 'link' },
  { label: 'Multiple Image', value: 'multiple_images' },
  { label: 'Multiple Video', value: 'multiple_videos' },
  { label: 'Multiple File', value: 'multiple_files' },
  { label: 'Multiple Link', value: 'multiple_links' },
  { label: 'Multiline Body', value: 'multiline_body' },
  { label: 'General', value: 'general' }
];
