import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import moment from 'moment';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import {
  occasionItineraryCreate,
  occasionItineraryUpdate
} from '../../../../../../actions/itinerary';
import { getCurrentItinerary } from '../../../../../../selectors/itinerary';

const validateSosData = (values) => {
  const errors = {};
  let STime = new Date(values?.startTime).getTime();
  let ETime = new Date(values?.endTime).getTime();
  if (!values.startTime) {
    errors.startTime = 'Start Time is required';
  }
  if (!values.endTime) {
    errors.endTime = 'End Time is required';
  }
  if (STime > ETime) {
    errors.endTime = 'End Time should be greater';
  }
  if (!values.activity) {
    errors.activity = 'Activity is required';
  }

  return errors;
};

const ItineraryCreate = (props) => {
  const [itineraryData, setItineraryData] = useState({
    startTime:
      props.currentItineraryData?.startTime &&
      moment(props.currentItineraryData?.startTime, 'hh:mm A'),
    endTime:
      props.currentItineraryData?.endTime &&
      moment(props.currentItineraryData?.endTime, 'hh:mm A'),
    activity: props.currentItineraryData?.activity || '',
    description: props.currentItineraryData?.description || '',
    venue: props.currentItineraryData?.venue || ''
  });
  const [errorMessages, setErrorMessages] = useState({});
  const handleSubmit = () => {
    try {
      const validateObj = validateSosData(itineraryData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append(
          'itinerary[start_time]',
          itineraryData?.startTime?.format('HH:mm')
        );
        formData.append(
          'itinerary[end_time]',
          itineraryData?.endTime?.format('HH:mm')
        );
        formData.append('itinerary[activity]', itineraryData.activity);
        formData.append('itinerary[description]', itineraryData.description);
        formData.append('itinerary[venue]', itineraryData.venue);
        props.itineraryId
          ? props.occasionItineraryUpdate(
              props.occasionId,
              props.scheduleId,
              props.itineraryId,
              'Occasion',
              props.occasionId,
              formData
            )
          : props.occasionItineraryCreate(
              props.occasionId,
              props.scheduleId,
              'Occasion',
              props.occasionId,
              formData,
              () => {
                setItineraryData({});
              }
            );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let formValue;
      let inputName;

      if (type === 'startTime') {
        value = moment(e, 'HH:mm');
        name = type;
      }
      if (type === 'endTime') {
        value = moment(e, 'HH:mm');
        name = type;
      }

      formValue = value || e?.target?.value;
      inputName = name || e?.target?.name;
      setItineraryData({
        ...itineraryData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="occasion-itinerary-create-section">
        <div className="occasion-ininerary-create-form">
          <Form
            className="occassion-itinerary-form-container"
            onFinish={handleSubmit}>
            <div className="timebox">
              <InputGroup
                label="Start Time"
                placeholder="Enter Time"
                name="startTime"
                fieldType="timePicker"
                format="HH:mm"
                onChange={handleInputChange}
                value={itineraryData?.startTime}
                errorMessage={errorMessages?.startTime}
              />
              <InputGroup
                label="End Time"
                placeholder="Enter Time"
                name="endTime"
                fieldType="timePicker"
                format="HH:mm"
                onChange={handleInputChange}
                value={itineraryData?.endTime}
                errorMessage={errorMessages?.endTime}
              />
            </div>
            <InputGroup
              label="Activity"
              placeholder="Enter Activity"
              name="activity"
              onChange={handleInputChange}
              value={itineraryData?.activity}
              errorMessage={errorMessages?.activity}
            />
            <InputGroup
              label="Description"
              name="description"
              fieldType="textArea"
              placeholder="Enter Description"
              onChange={handleInputChange}
              value={itineraryData?.description}
            />
            <InputGroup
              label="Venue"
              placeholder="Enter Venue"
              name="venue"
              onChange={handleInputChange}
              value={itineraryData?.venue}
              errorMessage={errorMessages?.venue}
            />
            <SubmitButton
              buttonText={props.itineraryId ? 'Save Changes' : 'Create'}
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentItineraryData: getCurrentItinerary(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { occasionItineraryCreate, occasionItineraryUpdate },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ItineraryCreate);
