import {
  CREATE_IMAGES_SUCCESS,
  CREATE_VIDEOS_SUCCESS,
  CREATE_MEDIA_FAILED,
  GET_GALLERY_IMAGES_SUCCESS,
  GET_GALLERY_IMAGES_FAILED,
  GET_GALLERY_VIDEOS_SUCCESS,
  GET_GALLERY_VIDEOS_FAILED,
  UPLOAD_MEDIA_LIST,
  GET_GALLERY_INFO_SUCCESS,
  GET_GALLERY_INFO_FAILED,
  CLEAR_UPLOAD_IMAGE_LIST,
  DELETE_MEDIA_SUCCESS,
  DELETE_MEDIA_FAILED
} from '../constants/actionTypes';
import { union } from '../helpers/extraHelpers';

const initialState = {
  data: null,
  photos: null,
  videos: null,
  photosNextPresent: null,
  videosNextPresent: null,
  error: null,
  uploadImageList: {}
};

const gallery = (state = initialState, action) => {
  switch (action.type) {
    case GET_GALLERY_INFO_SUCCESS: {
      const { occasionId, data } = action.payload;
      const galleryPrevIds = state.data || {};
      return {
        ...state,
        data: {
          ...galleryPrevIds,
          [occasionId]: data.id
        }
      };
    }

    case GET_GALLERY_IMAGES_SUCCESS: {
      const { occasionId, data, links, page } = action.payload;
      const prevState = state.photos || {};
      const oldIds =
        state.photos && state?.photos[occasionId]
          ? state.photos[occasionId]
          : [];
      const prevNextLinks = state.photosNextPresent || {};
      const newIds = data.map((el) => el.id);
      const Ids = page === 1 ? newIds : union(oldIds, newIds);
      return {
        ...state,
        photos: {
          ...prevState,
          [occasionId]: Ids
        },
        photosNextPresent: {
          ...prevNextLinks,
          [occasionId]: links?.next
        }
      };
    }

    case GET_GALLERY_VIDEOS_SUCCESS: {
      const { occasionId, data, links, page } = action.payload;
      const prevState = state.videos || {};
      const oldIds =
        state.videos && state?.videos[occasionId]
          ? state.videos[occasionId]
          : [];
      const prevNextLinks = state.videosNextPresent || {};
      const newIds = data.map((el) => el.id);
      const Ids = page === 1 ? newIds : union(oldIds, newIds);
      return {
        ...state,
        videos: {
          ...prevState,
          [occasionId]: Ids
        },
        videosNextPresent: {
          ...prevNextLinks,
          [occasionId]: links?.next
        }
      };
    }

    case CREATE_IMAGES_SUCCESS: {
      const { occasionId, data } = action.payload;
      const prevState = state.photos || {};
      const oldIds =
        state.photos && state?.photos[occasionId]
          ? state.photos[occasionId]
          : [];
      const newId = [data.id];
      const Ids = union(newId, oldIds);
      return {
        ...state,
        photos: {
          ...prevState,
          [occasionId]: Ids
        }
      };
    }

    case CREATE_VIDEOS_SUCCESS: {
      const { occasionId, data } = action.payload;
      const prevState = state.videos || {};
      const oldIds =
        state.videos && state?.videos[occasionId]
          ? state.videos[occasionId]
          : [];
      const newId = [data.id];
      const Ids = union(newId, oldIds);
      return {
        ...state,
        videos: {
          ...prevState,
          [occasionId]: Ids
        }
      };
    }

    case UPLOAD_MEDIA_LIST: {
      const { occasionId, data, progress } = action.payload;
      const prevImageList = state.uploadImageList || {};
      const prevImageListPerOccasion = state.uploadImageList[occasionId]
        ? state.uploadImageList[occasionId]
        : {};
      return {
        ...state,
        uploadImageList: {
          ...prevImageList,
          [occasionId]: {
            ...prevImageListPerOccasion,
            [data.uid]: {
              ...data,
              progress
            }
          }
        }
      };
    }

    case CLEAR_UPLOAD_IMAGE_LIST: {
      const { occasionId } = action.payload;
      const prevImageList = state.uploadImageList || {};
      return {
        ...state,
        uploadImageList: {
          ...prevImageList,
          [occasionId]: null
        }
      };
    }

    case DELETE_MEDIA_SUCCESS: {
      const { occasionId, mediaId, mediaType } = action.payload;
      const type = mediaType === 'photo' ? 'photos' : 'videos';
      const prevState = state[type] || {};
      const oldIds =
        state[type] && state[type][occasionId] ? state[type][occasionId] : [];

      const newIds = oldIds.filter((el) => el !== mediaId);

      return {
        ...state,
        [type]: {
          ...prevState,
          [occasionId]: newIds
        }
      };
    }

    case DELETE_MEDIA_FAILED:
    case GET_GALLERY_INFO_FAILED:
    case CREATE_MEDIA_FAILED:
    case GET_GALLERY_IMAGES_FAILED:
    case GET_GALLERY_VIDEOS_FAILED: {
      return {
        ...state,
        error: action.error
      };
    }

    default:
      return state;
  }
};

export default gallery;
