import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Button } from 'antd';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import './styles.scss';
import { CloseOutlined } from '@ant-design/icons';
import { validateEmailOrMobile } from '../../../../../helpers/extraHelpers';
import {
  createOccasionWhiteListWithDealerCode,
  updateOccasionWhiteListWithDealerCode
} from '../../../../../actions/occasionWhiteList';
import { currentOccasionWhiteListData } from '../../../../../selectors/occasionWhiteList';

const validateSendData = (values) => {
  const errors = {};
  if (!values.text) {
    errors.text = 'Email Or Phone Number is required';
  }
  if (values.text && !validateEmailOrMobile(values.text)) {
    errors.text = 'Email Or Phone Number is not correct';
  }
  if (!values.dealerCode) {
    errors.dealerCode = 'Dealer Code is required';
  }
  return errors;
};

const WhitListCreateWithDealerCode = (props) => {
  const [sendData, setSendData] = useState({
    text: props.currentWhiteListData?.emailOrMobile || '',
    dealerCode: props.currentWhiteListData?.dealerCode || ''
  });
  const [errorMessages, setErrorMessages] = useState({});
  const handleSubmit = () => {
    try {
      const validateObj = validateSendData(sendData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();

        formData.append('occasion_white_list[email_or_mobile]', sendData.text);
        formData.append(
          'occasion_white_list[dealer_code]',
          sendData.dealerCode
        );

        props.whiteListId
          ? props.updateOccasionWhiteListWithDealerCode(
              props.occasionId,
              props.whiteListId,
              formData,
              () => {
                props.handlePageRefresh();
                props.handleCloseClick();
              }
            )
          : props.createOccasionWhiteListWithDealerCode(
              props.occasionId,
              formData,
              () => {
                setSendData({
                  text: '',
                  dealerCode: ''
                });
              }
            );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;

      const formValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;

      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }

      setSendData({
        ...sendData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="occasion-white-list-create-section">
        <div className="occasion-white-list-create-form">
          <Form
            className="occasion-white-list-form-container"
            onFinish={handleSubmit}>
            <InputGroup
              name="text"
              placeholder="Enter Email or Phone number"
              label="Email/Phone number"
              value={sendData?.text}
              onChange={handleInputChange}
              errorMessage={errorMessages.text}
            />
            <InputGroup
              name="dealerCode"
              placeholder=" Enter Dealer Code"
              label="Dealer Code"
              value={sendData?.dealerCode}
              onChange={handleInputChange}
              errorMessage={errorMessages.dealerCode}
            />

            <SubmitButton
              buttonText={props.whiteListId ? 'Save Changes' : 'Create'}
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentWhiteListData: currentOccasionWhiteListData(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createOccasionWhiteListWithDealerCode,
      updateOccasionWhiteListWithDealerCode
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WhitListCreateWithDealerCode);
