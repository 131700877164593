import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  eventAffairFoodCreateApi,
  eventAffairFoodDeleteApi,
  eventAffairFoodUpdateApi,
  eventAffairFoodViewApi
} from '../api/eventAffairFood';
import {
  EVENT_AFFAIR_FOOD_CREATE,
  EVENT_AFFAIR_FOOD_DELETE,
  EVENT_AFFAIR_FOOD_UPDATE,
  EVENT_AFFAIR_FOOD_VIEW
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  eventAffairFoodCreateFailed,
  eventAffairFoodCreateSuccess,
  eventAffairFoodDeleteFailed,
  eventAffairFoodDeleteSuccess,
  eventAffairFoodUpdateFailed,
  eventAffairFoodUpdateSuccess,
  eventAffairFoodViewFailed,
  eventAffairFoodViewSuccess
} from '../actions/eventAffairFood';

export function* eventAffairFoodCreateSaga(action) {
  try {
    const result = yield call(
      eventAffairFoodCreateApi,
      action.payload.ocId,
      action.payload.formData,
      action.payload.eventId,
      action.payload.eventAffairId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventAffairFoodCreateSuccess(
          action.payload.ocId,
          action.payload.eventId,
          action.payload.eventAffairId,
          result.data,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(eventAffairFoodCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(eventAffairFoodCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventAffairFoodViewSaga(action) {
  try {
    const result = yield call(
      eventAffairFoodViewApi,
      action.payload.ocId,
      action.payload.page,
      action.payload.eventId,
      action.payload.eventAffairId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventAffairFoodViewSuccess(
          action.payload.ocId,
          action.payload.eventId,
          action.payload.eventAffairId,
          result.data,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(eventAffairFoodViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventAffairFoodUpdateSaga(action) {
  try {
    const result = yield call(
      eventAffairFoodUpdateApi,
      action.payload.ocId,
      action.payload.eventAffairId,
      action.payload.eventId,
      action.payload.foodId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventAffairFoodUpdateSuccess(
          action.payload.ocId,
          action.payload.eventId,
          action.payload.eventAffairId,
          result.data,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(eventAffairFoodUpdateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(eventAffairFoodUpdateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* eventAffairFoodDeleteSaga(action) {
  try {
    const result = yield call(
      eventAffairFoodDeleteApi,
      action.payload.ocId,
      action.payload.eventAffairId,
      action.payload.eventId,
      action.payload.foodId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        eventAffairFoodDeleteSuccess(
          action.payload.ocId,
          action.payload.eventId,
          action.payload.eventAffairId,
          result.data,
          result.included,
          result.links
        )
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(eventAffairFoodDeleteFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* eventAffairFoodActionWatcher() {
  yield takeLatest(
    EVENT_AFFAIR_FOOD_CREATE,
    progressSaga,
    eventAffairFoodCreateSaga
  );
  yield takeLatest(
    EVENT_AFFAIR_FOOD_VIEW,
    progressSaga,
    eventAffairFoodViewSaga
  );

  yield takeLatest(
    EVENT_AFFAIR_FOOD_UPDATE,
    progressSaga,
    eventAffairFoodUpdateSaga
  );
  yield takeLatest(
    EVENT_AFFAIR_FOOD_DELETE,
    progressSaga,
    eventAffairFoodDeleteSaga
  );
}
