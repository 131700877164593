import { union } from 'lodash';
import {
  OCCASION_QUESTION_VIEW_SUCCESS,
  OCCASION_QUESTION_VIEW_FAILED,
  OCCASION_QUESTION_CREATE_SUCCESS,
  OCCASION_QUESTION_CREATE_FAILED
} from '../constants/actionTypes';

const initialState = {
  occasionQuestionData: null,
  error: null,
  questionLinks: null
};

const question = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_QUESTION_VIEW_SUCCESS: {
      const { data, included, links, ticketId } = action.payload;
      const occasionGroupedData = state.occasionQuestionData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];
      return {
        ...state,
        occasionQuestionData: {
          ...occasionGroupedData,
          [ticketId]: [...currentOccasionNewIds]
        },
        questionLinks: links
      };
    }

    case OCCASION_QUESTION_CREATE_SUCCESS: {
      const { data, included, ticketId } = action.payload;
      const occasionGroupedData = state.occasionQuestionData || {};
      const currentQuestionIds = occasionGroupedData[ticketId] || [];

      const newIds = [data.id, ...currentQuestionIds];
      return {
        ...state,
        occasionQuestionData: {
          ...occasionGroupedData,
          [ticketId]: newIds
        }
      };
    }

    case OCCASION_QUESTION_VIEW_FAILED:
    case OCCASION_QUESTION_CREATE_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default question;
