import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Image, Form, Button } from 'antd';
import { ZoomInOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import InputGroup from '../../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { getImageSource } from '../../../../../../helpers/imageHelper';
import {
  regenerateFaceInfo,
  createSelfieUserMedia,
  updateSelfieImage
} from '../../../../../../actions/faceInfo';

const validateFaceInfoData = (values) => {
  const error = {};
  if (!values.avatar) {
    error.avatar = 'Image is required';
  }
  return error;
};

const UserDetailsTab = ({
  userDetails,
  occasionId,
  regenerateFaceInfo,
  createSelfieUserMedia,
  updateSelfieImage,
  fetchProgress
}) => {
  const [isEdit, setIsEdit] = React.useState(false);
  const [faceInfoUserData, setFaceInfoUserData] = React.useState({});
  const [errorMessages, setErrorMessages] = React.useState({});
  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleRegenerateFaceInfo = () => {
    regenerateFaceInfo(occasionId, 'User', userDetails?.id);
  };

  const handleCreateSelfieUserMedia = () => {
    createSelfieUserMedia(occasionId, userDetails?.id);
  };

  const onSubmit = () => {
    try {
      const validateObj = validateFaceInfoData(faceInfoUserData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        const formData = new FormData();
        formData.append(
          'user[selfie_image]',
          faceInfoUserData.avatar[0].originFileObj
        );
        updateSelfieImage(occasionId, userDetails?.id, formData, () => {
          setFaceInfoUserData({});
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'avatar') {
        value = e.fileList;
        name = type;
      }
      const Value = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setFaceInfoUserData({
        ...faceInfoUserData,
        [inputName]: Value
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="face-info-user-details-container">
      <p className="face-info-user-details card-details">
        <p className="fields">Image:</p>
        <p className="field-data card-image-container">
          <Image
            src={getImageSource(userDetails?.selfieImageUrl)}
            preview={{
              maskClassName: 'preview-style',
              mask: <ZoomInOutlined />
            }}
            className="image-style"
          />
        </p>

        {isEdit ? (
          <div className="face-info-user-form-container">
            <Form className="face-info-user-form" onFinish={onSubmit}>
              <InputGroup
                name="avatar"
                fieldType="file"
                accept="image/*"
                fileList={faceInfoUserData?.avatar}
                onChange={handleInputChange}
                errorMessage={errorMessages.avatar}
              />
              <SubmitButton
                disabled={fetchProgress}
                fetchProgress={fetchProgress}
                buttonText={'Save'}
                className="submit-button"
              />
            </Form>
            <Button onClick={handleEdit} className="edit-close-button">
              <CloseOutlined />
            </Button>
          </div>
        ) : (
          <button className="edit-button" onClick={handleEdit}>
            <EditOutlined />
            <span>Edit </span>
          </button>
        )}
      </p>

      <p className="face-info-user-details">
        <p className="fields">Name:</p>
        <p className="field-data">{userDetails?.name}</p>
      </p>

      <p className="face-info-user-details">
        <p className="fields">Email:</p>
        <p className="field-data">{userDetails?.email}</p>
      </p>

      <p className="face-info-user-details">
        <p className="fields">Mobile No:</p>
        <p className="field-data">{userDetails?.mobile}</p>
      </p>

      <p className="face-info-user-details">
        <p className="fields">Active:</p>
        <p className="field-data">{userDetails?.active.toString()}</p>
      </p>

      <p className="face-info-user-details">
        <p className="fields">From Internal Team:</p>
        <p className="field-data">{userDetails?.fromInternalTeam.toString()}</p>
      </p>

      {userDetails?.faceInfo === true || userDetails?.faceInfo === false ? (
        <p className="face-info-user-details">
          <p className="fields">Face Info:</p>
          <p className="field-data">{userDetails?.faceInfo?.toString()}</p>
        </p>
      ) : (
        ''
      )}

      <p className="face-info-user-details">
        <button
          className="face-info-user-regenerate"
          onClick={handleRegenerateFaceInfo}>
          Regenerate Face Info
        </button>
      </p>

      <p className="face-info-user-details">
        <button
          className="face-info-user-regenerate"
          onClick={handleCreateSelfieUserMedia}>
          Create Selfie User Media
        </button>
      </p>
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { regenerateFaceInfo, createSelfieUserMedia, updateSelfieImage },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsTab);
