import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../../components/Section';
import ErrorBoundary from '../../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../../../components/InputGroup/FormComponents';
import {
  occasionAlbumCreate,
  updateAlbum
} from '../../../../../../../actions/album';
import { getCurrentAlbum } from '../../../../../../../selectors/album';
import { getImageSource } from '../../../../../../../helpers/imageHelper';
import { ALBUM_ISPUBLIC_STATUS } from './constants';

const validateAlbum = (values) => {
  const error = {};
  if (!values.title) {
    error.title = 'Title is required';
  }
  return error;
};

function CreateAlbum(props) {
  const [albumData, setAlbumData] = React.useState({
    title: props.currentAlbum?.title || '',
    isPublic:
      props.currentAlbum?.isPublic === true
        ? 'true'
        : props.currentAlbum?.isPublic === false
        ? 'false'
        : 'false',
    cardImage: props.currentAlbum?.albumCardImageUrl
      ? [{ url: getImageSource(props.currentAlbum?.albumCardImageUrl) }]
      : null
  });
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleSubmit = () => {
    try {
      const validateObj = validateAlbum(albumData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        if (albumData.cardImage && albumData.cardImage[0]?.originFileObj) {
          formData.append(
            'album[album_card_image]',
            albumData.cardImage[0]?.originFileObj
          );
        }
        formData.append('album[title]', albumData.title);
        formData.append('album[is_public]', albumData.isPublic);

        props.albumId
          ? props.updateAlbum(props.occasionId, props.albumId, formData)
          : props.occasionAlbumCreate(props.occasionId, formData, () => {
              setAlbumData({});
            });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'cardImage') {
        value = e.fileList;
        name = type;
      }
      const albumValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setAlbumData({
        ...albumData,
        [inputName]: albumValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ErrorBoundary>
      <SectionContent className="album-create-section">
        <div className="album-create-form">
          <Form className="album-form" onFinish={handleSubmit}>
            <InputGroup
              name="cardImage"
              fieldType="file"
              label="Album Image"
              accept="image/*"
              fileList={albumData?.cardImage}
              onChange={handleInputChange}
            />
            <InputGroup
              label="Title"
              name="title"
              placeholder="Enter Album Title"
              onChange={handleInputChange}
              value={albumData?.title}
              errorMessage={errorMessages.title}
            />
            <InputGroup
              fieldType="radioGroup"
              label="Is Public"
              name="isPublic"
              options={ALBUM_ISPUBLIC_STATUS}
              onChange={handleInputChange}
              value={albumData?.isPublic}
            />

            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.albumId ? 'Save Changes' : 'Create Album'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentAlbum: getCurrentAlbum(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ occasionAlbumCreate, updateAlbum }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateAlbum);
