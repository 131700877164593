import request from './request';

export function userOccasionViewApi(userId, page, token) {
  return request(
    `api/v2/sociana_admin/get_user_occasions?user_id=${userId}&page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}
