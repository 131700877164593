import { union } from 'lodash';
import {
  OCCASION_IMPORTANT_CONTACT_VIEW_SUCCESS,
  OCCASION_IMPORTANT_CONTACT_VIEW_FAILED,
  OCCASION_IMPORTANT_CONTACT_CREATE_SUCCESS,
  OCCASION_IMPORTANT_CONTACT_CREATE_FAILED,
  OCCASION_IMPORTANT_CONTACT_DELETE_SUCCESS,
  OCCASION_IMPORTANT_CONTACT_DELETE_FAILED
} from '../constants/actionTypes';

const initialState = {
  occasionImportantContactData: null,
  error: null,
  importantContactLinks: null
};

const importantContact = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_IMPORTANT_CONTACT_VIEW_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.occasionImportantContactData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionImportantContactData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        importantContactLinks: links
      };
    }

    case OCCASION_IMPORTANT_CONTACT_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionImportantContactData || {};
      const currentOccasionImportantContactIds =
        occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionImportantContactIds];
      return {
        ...state,
        occasionImportantContactData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }

    case OCCASION_IMPORTANT_CONTACT_DELETE_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.occasionImportantContactData || {};
      const currentOccasionImportantContactIds =
        occasionGroupedData[ocId] || [];
      const filteredIds = currentOccasionImportantContactIds.filter(
        (id) => id !== data.id
      );

      return {
        ...state,
        occasionImportantContactData: {
          ...occasionGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case OCCASION_IMPORTANT_CONTACT_VIEW_FAILED:
    case OCCASION_IMPORTANT_CONTACT_CREATE_FAILED:
    case OCCASION_IMPORTANT_CONTACT_DELETE_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default importantContact;
