import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  occasionLiveVideoCreateApi,
  occasionLiveVideoDeleteApi,
  occasionLiveVideoViewApi
} from '../api/liveVideo';
import {
  OCCASION_LIVE_VIDEO_CREATE,
  OCCASION_LIVE_VIDEO_DELETE,
  OCCASION_LIVE_VIDEO_VIEW
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  occasionLiveVideoCreateFailed,
  occasionLiveVideoCreateSuccess,
  occasionLiveVideoDeleteFailed,
  occasionLiveVideoDeleteSuccess,
  occasionLiveVideoViewFailed,
  occasionLiveVideoViewSuccess
} from '../actions/liveVideo';

export function* occasionLiveVideoCreateSaga(action) {
  try {
    const result = yield call(
      occasionLiveVideoCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionLiveVideoCreateSuccess(
          result.data,
          result.included,
          action.payload.ocId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionLiveVideoCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionLiveVideoCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionLiveVideoViewSaga(action) {
  try {
    const result = yield call(
      occasionLiveVideoViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionLiveVideoViewSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionLiveVideoViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionLiveVideoDeleteSaga(action) {
  try {
    const result = yield call(
      occasionLiveVideoDeleteApi,
      action.payload.ocId,
      action.payload.videoId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionLiveVideoDeleteSuccess(
          action.payload.ocId,
          action.payload.videoId
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionLiveVideoDeleteFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* liveVideoActionWatcher() {
  yield takeLatest(
    OCCASION_LIVE_VIDEO_CREATE,
    progressSaga,
    occasionLiveVideoCreateSaga
  );
  yield takeLatest(
    OCCASION_LIVE_VIDEO_VIEW,
    progressSaga,
    occasionLiveVideoViewSaga
  );
  yield takeLatest(
    OCCASION_LIVE_VIDEO_DELETE,
    progressSaga,
    occasionLiveVideoDeleteSaga
  );
}
