import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import './styles.scss';

const VideoPlayer = ({ videoUrl, cdnVideoUrl, ...otherProps }) => {
  const [video, setVideo] = useState(cdnVideoUrl || videoUrl);

  const handleVideoLoadError = () => {
    if (videoUrl) {
      setVideo(cdnVideoUrl);
    } else {
      console.error('Error in playing video');
    }
  };

  return (
    <ReactPlayer
      url={`${video}`}
      width="100%"
      onError={handleVideoLoadError}
      controls={true}
      {...otherProps}
    />
  );
};

const CarouselVideoPlayer = ({
  videoUrls,
  cdnVideoUrls,
  className,
  ...otherVideoPlayerProps
}) => {
  return (
    <div>
      {videoUrls?.length > 0 && (
        <Carousel className={`carousel-style ${className}`}>
          {videoUrls?.map((url, index) => (
            <VideoPlayer
              videoUrl={url}
              cdnVideoUrl={cdnVideoUrls[index]}
              {...otherVideoPlayerProps}
            />
          ))}
        </Carousel>
      )}
    </div>
  );
};

export { VideoPlayer, CarouselVideoPlayer };
