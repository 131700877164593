import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';

import {
  OCCASION_TAB_SETTING_CREATE,
  OCCASION_TAB_SETTING_UPDATE,
  OCCASION_TAB_SETTING_VIEW
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  occasionTabSettingCreateApi,
  occasionTabSettingViewApi,
  updateOccasionTabSettingApi
} from '../api/occasionTabSetting';
import {
  occasionTabSettingCreateFailed,
  occasionTabSettingCreateSuccess,
  occasionTabSettingUpdateFailed,
  occasionTabSettingUpdateSuccess,
  occasionTabSettingViewFailed,
  occasionTabSettingViewSuccess
} from '../actions/occasionTabSetting';

export function* occasionTabSettingCreateSaga(action) {
  try {
    const result = yield call(
      occasionTabSettingCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionTabSettingCreateSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionTabSettingCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionTabSettingCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionTabSettingViewSaga(action) {
  try {
    const result = yield call(
      occasionTabSettingViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionTabSettingViewSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionTabSettingViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updateOccasionTabSettingSaga(action) {
  try {
    const result = yield call(
      updateOccasionTabSettingApi,
      action.payload.ocId,
      action.payload.occasionTabSettingId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionTabSettingUpdateSuccess(
          result.data,
          action.payload.ocId,
          result.included,
          result.links
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionTabSettingUpdateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionTabSettingUpdateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* occasionTabSettingActionWatcher() {
  yield takeLatest(
    OCCASION_TAB_SETTING_CREATE,
    progressSaga,
    occasionTabSettingCreateSaga
  );
  yield takeLatest(
    OCCASION_TAB_SETTING_VIEW,
    progressSaga,
    occasionTabSettingViewSaga
  );

  yield takeLatest(
    OCCASION_TAB_SETTING_UPDATE,
    progressSaga,
    updateOccasionTabSettingSaga
  );
}
