import {
  GET_ALL_MEMBERS_SUCCESS,
  GET_ALL_MEMBERS_FAILED,
  SEARCH_MEMBERS_SUCCESS,
  SEARCH_MEMBERS_FAILED,
  RESET_MEMBERS,
  DELETE_MEMBERS_SUCCESS,
  DELETE_MEMBERS_FAILED
} from '../constants/actionTypes';

const initialState = {
  data: null,
  error: null,
  links: null,
  searchedIds: null,
  count: 0,
  searchedLinks: null,
  isSearch: false
};

const members = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_MEMBERS_FAILED:
    case SEARCH_MEMBERS_FAILED:
    case GET_ALL_MEMBERS_FAILED: {
      return { ...state, error: action.error };
    }

    case RESET_MEMBERS: {
      return {
        ...state,
        isSearch: false
      };
    }

    case GET_ALL_MEMBERS_SUCCESS: {
      const { ocId, data, links, meta } = action.payload;
      const prevData = state.data || {};
      const memberIds = data.map((el) => el.id);

      const prevLinks = state.links || {};
      const memberLinks = links;
      return {
        ...state,
        data: {
          ...prevData,
          [ocId]: memberIds
        },
        links: {
          ...prevLinks,
          [ocId]: memberLinks
        },
        isSearch: false,
        count: meta?.memberCount || 0
      };
    }

    case SEARCH_MEMBERS_SUCCESS: {
      const { ocId, data, links, meta } = action.payload;
      const prevData = state.searchedIds || {};
      const memberIds = data.map((el) => el.id);

      const prevLinks = state.searchedLinks || {};
      const memberLinks = links;
      return {
        ...state,
        searchedIds: {
          ...prevData,
          [ocId]: memberIds
        },
        searchedLinks: {
          ...prevLinks,
          [ocId]: memberLinks
        },
        isSearch: true,
        count: meta?.totalCount || 0
      };
    }

    case DELETE_MEMBERS_SUCCESS: {
      const { ocId, data } = action.payload;
      const prevSearchedData = state.searchedIds || {};
      const prevData = state.data || {};

      const searchedIds = prevSearchedData[ocId] || [];
      const ids = prevData[ocId] || [];

      const newSearchedData = searchedIds.filter((el) => el !== data?.id);
      const newData = ids.filter((el) => el !== data?.id);

      return {
        ...state,
        searchedIds: {
          ...prevSearchedData,
          [ocId]: newSearchedData
        },
        data: {
          ...prevData,
          [ocId]: newData
        },
        count: state.count - 1
      };
    }

    default:
      return state;
  }
};

export default members;
