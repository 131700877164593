import { call, all, fork, take, cancel } from 'redux-saga/effects';
import authSagaActionWatcher from './authSaga';
import alertWatcher from './alertSaga';
import occasionActionWatcher from './occasionSaga';
import adminActionWatcher from './adminSaga';
import socianaInternalUserActionWatcher from './socianaInternalUserSaga';
import accessControlsActionWatcher from './accessControlSaga';
import featureControlsActionWatcher from './featureControlSaga';
import reportsActionWatcher from './reportSaga';
import forgotPasswordActionWatcher from './forgotPasswordSaga';
import postActionWatcher from './postSaga';
import userActionWatcher from './userSaga';
import galleryActionWatcher from './gallery';
import eventActionWatcher from './eventSaga';
import { NETWORK_OFFLINE, NETWORK_ONLINE } from '../constants/actionTypes';
import invitationActionWatcher from './InvitationSaga';
import hospitalityActionWatcher from './hospitalitySaga';
import hospitalityTaskActionWatcher from './hospitalityTaskSaga';
import groupActionWatcher from './groupSaga';
import triggerActionWatcher from './triggerSaga';
import liveVideoActionWatcher from './liveVideo';
import sosActionWatcher from './sos';
import liveActionWatcher from './liveSaga';
import membersActionWatcher from './membersSaga';
import sponsorshipActionWatcher from './sponsorshipSaga';
import importantContactActionWatcher from './importantContactSaga';
import awardActionWatcher from './awardSaga';
import ticketActionWatcher from './ticketSaga';
import ticketVariantActionWatcher from './ticketVariantSaga';
import ticketVariantFormActionWatcher from './ticketvariantFormSaga';
import questionActionWatcher from './questionSaga';
import ticketPurchasesActionWatcher from './purchaseSaga';
import scheduleActionWatcher from './scheduleSaga';
import itineraryActionWatcher from './itinerarySaga';
import notificationsActionWatcher from './notificationSaga';
import carouselActionWatcher from './carouselSaga';
import divisionActionWatcher from './divisionSaga';
import subdivisionActionWatcher from './subdivisionSaga';
import smsActionWatcher from './notificationSmsSaga';
import guestActionWatcher from './guestSaga';
import albumActionWatcher from './albumSaga';
import occasionAppActionWatcher from './occasionAppSaga';
import roleNameActionWatcher from './roleNameSaga';
import userOccasionActionWatcher from './userOccasionSaga';
import pollPostActionWatcher from './pollPostSaga';
import occasionCodeActionWatcher from './occasionCodeSaga';
import occasionWhiteListActionWatcher from './occasionWhiteListSaga';
import publicAnswerActionWatcher from './publicAnswerSaga';
import eventAffairEssenceActionWatcher from './eventAffairEssenceSaga';
import eventAffairEntertainmentActionWatcher from './eventAffairEntertainmentSaga';
import eventAffairFoodActionWatcher from './eventAffairFoodSaga';
import discussionActionWatcher from './discussionSaga';
import enquiryActionWatcher from './enquirySaga';
import occasionTabSettingActionWatcher from './occasionTabSettingSaga';
import speakerActionWatcher from './speakerSaga';
import speakerReferenceActionWatcher from './speakerReferenceSaga';
import userDeviceActionWatcher from './userDeviceSaga';
import activityTrackerActionWatcher from './activityTrackerSaga';
import activityTrackerPresentUserActionWatcher from './activityTrackerPresentUserSaga';
import alarmActionWatcher from './alarmSaga';
import hardLogoutActionWatcher from './hardLogoutSaga';
import faceInfoActionWatcher from './faceInfosaga';
import activityTrackerAbsentUserActionWatcher from './activityTrackerAbsentUserSaga';
import fixedGroupPostSettingActionWatcher from './fixedGroupPostSettingSaga';
import hospitalityGoogelSheetActionWatcher from './hospitalityGoogleSheetSaga';
import competitionActionWatcher from './competitionSaga';
import participantWatcher from './participant';
import ticketGoogelSheetActionWatcher from './ticketGoogleSheetSaga';
import whatsappForProvidedTaskActionWatcher from './whatsappForProvidedTaskSaga';
import whatsappTemplatesActionWatcher from './whatsAppTemplatesSaga';
import occasionResourcesActionWatcher from './occasionResourcesSaga';

export function* rootSaga() {
  const SAGAS = [
    authSagaActionWatcher,
    alertWatcher,
    occasionActionWatcher,
    adminActionWatcher,
    socianaInternalUserActionWatcher,
    accessControlsActionWatcher,
    featureControlsActionWatcher,
    reportsActionWatcher,
    forgotPasswordActionWatcher,
    postActionWatcher,
    userActionWatcher,
    galleryActionWatcher,
    invitationActionWatcher,
    eventActionWatcher,
    hospitalityActionWatcher,
    groupActionWatcher,
    liveActionWatcher,
    hospitalityTaskActionWatcher,
    sponsorshipActionWatcher,
    importantContactActionWatcher,
    liveVideoActionWatcher,
    sosActionWatcher,
    awardActionWatcher,
    membersActionWatcher,
    ticketActionWatcher,
    ticketVariantActionWatcher,
    triggerActionWatcher,
    ticketVariantFormActionWatcher,
    questionActionWatcher,
    ticketPurchasesActionWatcher,
    carouselActionWatcher,
    scheduleActionWatcher,
    itineraryActionWatcher,
    notificationsActionWatcher,
    carouselActionWatcher,
    divisionActionWatcher,
    subdivisionActionWatcher,
    smsActionWatcher,
    albumActionWatcher,
    occasionAppActionWatcher,
    roleNameActionWatcher,
    guestActionWatcher,
    userOccasionActionWatcher,
    pollPostActionWatcher,
    occasionCodeActionWatcher,
    occasionWhiteListActionWatcher,
    publicAnswerActionWatcher,
    eventAffairEssenceActionWatcher,
    eventAffairEntertainmentActionWatcher,
    eventAffairFoodActionWatcher,
    discussionActionWatcher,
    enquiryActionWatcher,
    occasionTabSettingActionWatcher,
    speakerActionWatcher,
    speakerReferenceActionWatcher,
    userDeviceActionWatcher,
    activityTrackerActionWatcher,
    activityTrackerPresentUserActionWatcher,
    alarmActionWatcher,
    hardLogoutActionWatcher,
    faceInfoActionWatcher,
    activityTrackerAbsentUserActionWatcher,
    fixedGroupPostSettingActionWatcher,
    hospitalityGoogelSheetActionWatcher,
    competitionActionWatcher,
    participantWatcher,
    ticketGoogelSheetActionWatcher,
    whatsappForProvidedTaskActionWatcher,
    whatsappTemplatesActionWatcher,
    occasionResourcesActionWatcher
  ];

  yield all(
    SAGAS.map((saga) => {
      try {
        return call(saga);
      } catch (error) {
        return false;
      }
    })
  );
}

export default function* networkSaga() {
  // listening for network online
  while (yield take(NETWORK_ONLINE)) {
    // creating a thread process to run parallel
    const task = yield fork(rootSaga);

    // listening for network offline
    yield take(NETWORK_OFFLINE);

    // cancel task if network is offline
    yield cancel(task);
  }
}
