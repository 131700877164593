import { createSelector } from 'reselect';
import { commentEntities, userEntities } from './entity';
import { commentIds } from './static';

export const currentComments = createSelector(
  commentIds,
  commentEntities,
  (Ids, entities) =>
    Ids && entities && Ids?.length > 0 && Ids.map((id) => entities[id])
);

export const currentCommentsWithUserData = createSelector(
  currentComments,
  userEntities,
  (data, entities) =>
    (data &&
      entities &&
      data?.length > 0 &&
      data.map((el) => ({ ...el, user: { ...entities[el?.user?.id] } }))) ||
    (Array.isArray(data) ? [] : null)
);
