import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  createAdminOccasionApi,
  createNewAdmin,
  deleteCurrentAdminOccasionApi,
  getActiveAdminPagination,
  getAdminBySearchApi,
  getCurrentAdminOccasionApi,
  getInactiveAdminPagination,
  updateAdmin,
  updateAdminOccasionApi
} from '../api/admin';

import {
  CREATE_ADMIN,
  GET_ADMIN_PAGINATION,
  CREATE_ADMIN_OCCASION,
  GET_ADMIN_OCCASION_MEMBER,
  EDIT_ADMIN,
  GET_ADMIN_BY_SEARCH,
  DELETE_ADMIN_OCCASION_MEMBER,
  UPDATE_ADMIN_OCCASION
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  createAdminFailed,
  createAdminSuccess,
  getAdminWithPaginationSuccess,
  getAdminWithPaginationFailed,
  createAdminOccasionSuccess,
  createAdminOccasionFailed,
  getAdminOccasionMemberFailed,
  getAdminOccasionMemberSuccess,
  updateAdminSuccess,
  updateAdminFailed,
  getAdminBySearchSuccess,
  getAdminBySearchFailed,
  deleteAdminOccasionMemberSuccess,
  deleteAdminOccasionMemberFailed,
  updateAdminOccasionSuccess,
  updateAdminOccasionFailed
} from '../actions/admin';

export function* createAdminSaga(action) {
  try {
    const result = yield call(
      createNewAdmin,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(createAdminSuccess(result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(createAdminFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(createAdminFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getAdminsSagaWithPagination(action) {
  try {
    const { isActive } = action.payload;
    const result = yield call(
      isActive ? getActiveAdminPagination : getInactiveAdminPagination,
      action.payload.page,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getAdminWithPaginationSuccess(
          result.data,
          result.links,
          isActive,
          result?.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getAdminWithPaginationFailed(error, action.payload.isActive));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getAdminOccasionMemberWithPaginationSaga(action) {
  try {
    const result = yield call(
      getCurrentAdminOccasionApi,
      action.payload.page,
      action.payload.adminId,
      action.payload.adminStatus,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        getAdminOccasionMemberSuccess(
          result.data,
          result.included,
          result.links,
          action.payload.adminId,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getAdminOccasionMemberFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* createAdminOccasionSaga(action) {
  try {
    const result = yield call(
      createAdminOccasionApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        createAdminOccasionSuccess(
          result.data,
          result.included,
          action.payload.adminId
        )
      );
      if (action.callback) {
        action.callback();
      }

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
    } else {
      yield put(createAdminOccasionFailed(result));

      if (result && result.meta && result.meta.message) {
        yield put(requestAutoHideAlert(result.meta.message, 'Error'));
      } else {
        yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
      }
    }
  } catch (error) {
    yield put(createAdminOccasionFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updateAdminSaga(action) {
  try {
    const result = yield call(
      updateAdmin,
      action.payload.adminId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(updateAdminSuccess(result.data));
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateAdminFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Some error occured', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateAdminFailed(error));
    yield put(requestAutoHideAlert(error, 'Error', 4000));
  }
}

export function* getAdminBySearchSaga(action) {
  try {
    const result = yield call(
      getAdminBySearchApi,
      action.payload.searchData,
      action.payload.status,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getAdminBySearchSuccess(
          result.data,
          result.included,
          action.payload.status,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getAdminBySearchFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* deleteAdminOccasionMemberWithPaginationSaga(action) {
  try {
    const result = yield call(
      deleteCurrentAdminOccasionApi,
      action.payload.ocId,
      action.payload.adminOccasionId,
      getUserToken()
    );

    if (isValidResult(result)) {
      yield put(
        deleteAdminOccasionMemberSuccess(
          result.data,
          result.included,
          result.links,
          action.payload.adminId,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
    }
  } catch (error) {
    yield put(deleteAdminOccasionMemberFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updateAdminOccasionSaga(action) {
  try {
    const result = yield call(
      updateAdminOccasionApi,
      action.payload.ocId,
      action.payload.adminOccasionId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateAdminOccasionSuccess(
          result.data,
          result.included,
          action.payload.adminId
        )
      );
      if (action.callback) {
        action.callback();
      }

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
    } else {
      yield put(updateAdminOccasionFailed(result));

      if (result && result.meta && result.meta.message) {
        yield put(requestAutoHideAlert(result.meta.message, 'Error'));
      } else {
        yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
      }
    }
  } catch (error) {
    yield put(updateAdminOccasionFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* adminActionWatcher() {
  yield takeLatest(CREATE_ADMIN, progressSaga, createAdminSaga);
  yield takeLatest(
    GET_ADMIN_PAGINATION,
    progressSaga,
    getAdminsSagaWithPagination
  );
  yield takeLatest(
    GET_ADMIN_OCCASION_MEMBER,
    progressSaga,
    getAdminOccasionMemberWithPaginationSaga
  );
  yield takeLatest(
    CREATE_ADMIN_OCCASION,
    progressSaga,
    createAdminOccasionSaga
  );
  yield takeLatest(EDIT_ADMIN, progressSaga, updateAdminSaga);
  yield takeLatest(GET_ADMIN_BY_SEARCH, progressSaga, getAdminBySearchSaga);

  yield takeLatest(
    DELETE_ADMIN_OCCASION_MEMBER,
    progressSaga,
    deleteAdminOccasionMemberWithPaginationSaga
  );
  yield takeLatest(
    UPDATE_ADMIN_OCCASION,
    progressSaga,
    updateAdminOccasionSaga
  );
}
