import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Comment, Tooltip, List, Empty, Button, Typography } from 'antd';
import moment from 'moment';
import {
  currentOccasionId,
  currentPostId
} from '../../../../../selectors/dynamicData';
import { getReportedPostCommentsWithPagination } from '../../../../../actions/reports';
import { currentCommentsWithUserData } from '../../../../../selectors/comments';
import { commentLinks } from '../../../../../selectors/static';
import './styles.scss';
import { Avatar } from '../../../../../components/Avatar';

const Comments = (props) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    props.getReportedPostCommentsWithPagination(
      props.occasionId,
      page,
      'Post',
      props.postId
    );
  }, [page]);

  const getAvatar = (item) => (
    <Avatar
      className="profile-image"
      avatarUrl={item?.user?.avatarUrl}
      name={item?.user?.name}
    />
  );

  const commentRenderItem = (item) => (
    <div className="comments">
      <Comment
        author={item?.user?.name}
        avatar={getAvatar(item)}
        content={
          <Typography.Paragraph
            ellipsis={{
              rows: 3,
              expandable: true
            }}>
            {item?.message}
          </Typography.Paragraph>
        }
        datetime={
          <Tooltip
            title={moment(item?.createdAt, 'DD/MM/YYYY HH:mm')
              .subtract(2, 'days')
              .format('DD-MM-YYYY HH:mm')}>
            <span>
              {moment(item?.createdAt, 'DD/MM/YYYY HH:mm')
                .subtract(2, 'days')
                .fromNow()}
            </span>
          </Tooltip>
        }
      />
    </div>
  );

  const getNextPage = (str) => {
    const str1 = str.split('=');
    const res = str1[1].split('&');
    return parseInt(res[0], 10);
  };

  const onLoadMore = () => {
    if (props?.commentLinks?.next) {
      const pageNumber = getNextPage(props?.commentLinks?.next);
      setPage(pageNumber);
    }
  };

  const loadMore = useMemo(() => {
    return props.commentLinks?.next ? (
      <div className="load-more">
        <Button onClick={onLoadMore}>loading more</Button>
      </div>
    ) : null;
  }, [props?.commentLinks?.next]);

  return (
    <div>
      <p className="like-header-text">People Who have commented</p>
      {props.comments?.length > 0 ? (
        <List
          dataSource={props?.comments}
          renderItem={commentRenderItem}
          loading={props.fetchProgress}
          loadMore={loadMore}
        />
      ) : (
        <Empty />
      )}
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  occasionId: currentOccasionId(store, props),
  postId: currentPostId(store, props),
  fetchProgress: store.browserState.fetchProgress,
  comments: currentCommentsWithUserData(store, props),
  commentLinks: commentLinks(store, props)
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getReportedPostCommentsWithPagination },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
