import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { editSpeakerReference } from '../../../../../../actions/speakerReference';
import { currentSpeakerReferenceData } from '../../../../../../selectors/speakerReference';

function CreateSpeakerReference(props) {
  const [speakerReferenceData, setSpeakerReferenceData] = React.useState({
    priority: props.currentSpeakerReferenceData?.priority || '1'
  });

  const handleSubmit = () => {
    try {
      const formData = new FormData();

      formData.append(
        'speaker_reference[priority]',
        speakerReferenceData.priority
      );

      props.speakerReferenceId &&
        props.editSpeakerReference(
          props.occasionId,
          props.speakerId,
          props.speakerReferenceId,
          formData
        );
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      const formValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      setSpeakerReferenceData({
        ...speakerReferenceData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="speaker-reference-create-section">
        <div className="speaker-reference-create-form">
          <Form className="speaker-reference-form" onFinish={handleSubmit}>
            <InputGroup
              label="Priority"
              type="Number"
              name="priority"
              placeholder="Enter Priority"
              onChange={handleInputChange}
              value={speakerReferenceData?.priority}
            />
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.speakerReferenceId ? 'Save Changes' : 'Create'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentSpeakerReferenceData: currentSpeakerReferenceData(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ editSpeakerReference }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSpeakerReference);
