import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import { getTicketGoogleSheetData } from '../../../../../../selectors/ticketGoogleSheet';
import {
  getTicketGoogleSheet,
  ticketGoogleSheetCreate,
  deleteTicketGoogleSheet
} from '../../../../../../actions/ticketGoogleSheet';

const TicketGoogleSheetView = (props) => {
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.getTicketGoogleSheet(props.occasionId, page);
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const handleCreate = () => {
    props.ticketGoogleSheetCreate(props.occasionId);
  };

  const deleteData = (id) => {
    props.deleteTicketGoogleSheet(props.occasionId, id);
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="ticket-google-sheet-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>

        {props.ticketGoogleSheetData?.length == 0 && (
          <Button className="create-button" onClick={handleCreate}>
            Create Google Sheet
          </Button>
        )}
      </div>
    )
  };

  return (
    <ErrorBoundary>
      <SectionContent className="ticket-google-sheet-view-section">
        <div className="ticket-google-sheet-view-container">
          <TableView
            isPaginated={true}
            paginationOptions={paginationOptions}
            isRouted={false}
            onPageChange={onPageChange}
            onDelete={deleteData}
            data={props.ticketGoogleSheetData}
            fetchProgress={props.fetchProgress}
            paginationLinks={props.ticketGoogleSheetLinks}
            blacklistKeys={['modelId', 'modelType']}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  ticketGoogleSheetData: getTicketGoogleSheetData(store, props),
  ticketGoogleSheetLinks: store.ticketGoogleSheet.ticketGoogleSheetLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    {
      getTicketGoogleSheet,
      ticketGoogleSheetCreate,
      deleteTicketGoogleSheet
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(TicketGoogleSheetView);
