import React, { useState } from 'react';
import { Modal, Image } from 'antd';
import './styles.scss';

const ImageModal = ({ visible, floorPlanImageUrls, onOk, onCancel, title }) => {
  const [error, setError] = useState(false);
  return (
    <>
      <Modal
        centered
        title={title ? title : 'Floor Plan Images'}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        style={{ top: 80 }}>
        <div className="floorPlanImages-container">
          {floorPlanImageUrls.map((urls) => {
            return (
              <div className="floorPlanImages-content">
                {!error ? (
                  <Image
                    src={`${process.env.REACT_APP_CLIENT_URL}${Object.values(
                      urls
                    )}`}
                    className="floorPlanImages"
                    onError={() => setError(true)}
                  />
                ) : (
                  <Image
                    src={`${process.env.REACT_APP_CLIENT_URL}${urls}
                `}
                    className="floorPlanImages"
                    onError={() => setError(true)}
                  />
                )}
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
};
export default ImageModal;
