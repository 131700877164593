import {
  CREATE_MEDIA,
  CREATE_MEDIA_FAILED,
  CREATE_IMAGES_SUCCESS,
  CREATE_VIDEOS_SUCCESS,
  GET_GALLERY_IMAGES,
  GET_GALLERY_IMAGES_SUCCESS,
  GET_GALLERY_IMAGES_FAILED,
  GET_GALLERY_VIDEOS,
  GET_GALLERY_VIDEOS_SUCCESS,
  GET_GALLERY_VIDEOS_FAILED,
  UPLOAD_MEDIA_LIST,
  CLEAR_UPLOAD_IMAGE_LIST,
  GET_GALLERY_INFO,
  GET_GALLERY_INFO_SUCCESS,
  GET_GALLERY_INFO_FAILED,
  DELETE_MEDIA,
  DELETE_MEDIA_FAILED,
  DELETE_MEDIA_SUCCESS
} from '../constants/actionTypes';

export const getGalleryInfo = (occasionId) => ({
  type: GET_GALLERY_INFO,
  payload: { occasionId }
});

export const getGalleryInfoSuccess = (occasionId, data, included) => ({
  type: GET_GALLERY_INFO_SUCCESS,
  payload: { occasionId, data, included }
});

export const getGalleryInfoFailed = (error) => ({
  type: GET_GALLERY_INFO_FAILED,
  error
});

export const createMedia = (
  occasionId,
  formData,
  updateProgress,
  mediaType = 'photo',
  callback
) => ({
  type: CREATE_MEDIA,
  payload: { occasionId, formData, updateProgress, mediaType },
  callback
});

export const createImagesSuccess = (occasionId, data, included) => ({
  type: CREATE_IMAGES_SUCCESS,
  payload: { occasionId, data, included }
});

export const createVideosSuccess = (occasionId, data, included) => ({
  type: CREATE_VIDEOS_SUCCESS,
  payload: { occasionId, data, included }
});

export const createMediaFailed = (error) => ({
  type: CREATE_MEDIA_FAILED,
  error
});

export const getGalleryImages = (occasionId, uuid, mType, page, callback) => ({
  type: GET_GALLERY_IMAGES,
  payload: { occasionId, uuid, mType, page },
  callback
});

export const getGalleryImagesSuccess = (
  occasionId,
  data,
  included,
  links,
  page
) => ({
  type: GET_GALLERY_IMAGES_SUCCESS,
  payload: { occasionId, data, included, links, page }
});

export const getGalleryImagesFailed = (error) => ({
  type: GET_GALLERY_IMAGES_FAILED,
  error
});

export const getGalleryVideos = (occasionId, uuid, mType, page, callback) => ({
  type: GET_GALLERY_VIDEOS,
  payload: { occasionId, uuid, mType, page },
  callback
});

export const getGalleryVideosSuccess = (
  occasionId,
  data,
  included,
  links,
  page
) => ({
  type: GET_GALLERY_VIDEOS_SUCCESS,
  payload: { occasionId, data, included, links, page }
});

export const getGalleryVideosFailed = (error) => ({
  type: GET_GALLERY_VIDEOS_FAILED,
  error
});

export const updateImageList = (occasionId, data, progress) => ({
  type: UPLOAD_MEDIA_LIST,
  payload: { occasionId, data, progress }
});

export const clearUploadHistory = (occasionId) => ({
  type: CLEAR_UPLOAD_IMAGE_LIST,
  payload: { occasionId }
});

export const deleteMedia = (
  occasionId,
  uuid,
  mediaId,
  mType,
  mediaType = 'photo',
  callback
) => ({
  type: DELETE_MEDIA,
  payload: { occasionId, uuid, mediaId, mType, mediaType },
  callback
});

export const deleteMediaSuccess = (occasionId, uuid, mediaId, mediaType) => ({
  type: DELETE_MEDIA_SUCCESS,
  payload: { occasionId, uuid, mediaId, mediaType }
});

export const deleteMediaFailed = (error) => ({
  type: DELETE_MEDIA_FAILED,
  error
});
