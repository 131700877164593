import request from './request';

export function createPost(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/posts`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function viewPost(ocId, postableType, pageNumber, token) {
  return request(
    `/api/v2/sociana_admin/oc/${ocId}/fetch_posts_by_internal_team?page=${pageNumber}&postable_type=${postableType}`,
    {
      method: 'GET',
      token
    }
  );
}

export function editPostApi(ocId, postableType, postId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/posts/${postId}?postable_type=${postableType}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function deletePostApi(ocId, postableType, postId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/posts/${postId}?postable_type=${postableType}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function downloadExslExportUserPostApi(ocId, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/xlsx_export_user_post_details`,
    {
      method: 'GET',
      token,
      isBlob: true
    }
  );
}
