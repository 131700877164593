import { union } from 'lodash';
import {
  OCCASION_RESOURCES_VIEW_SUCCESS,
  OCCASION_RESOURCES_VIEW_FAILED,
  DELETE_OCCASION_RESOURCES_FAILED,
  DELETE_OCCASION_RESOURCES_SUCCESS,
  OCCASION_RESOURCES_CREATE_SUCCESS,
  OCCASION_RESOURCES_CREATE_FAILED
} from '../constants/actionTypes';

const initialState = {
  occasionResourcesIds: null,
  occasionResourcesLinks: null,
  error: null
};

const occasionResources = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_RESOURCES_VIEW_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.occasionResourcesIds || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionResourcesIds: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        occasionResourcesLinks: links
      };
    }

    case OCCASION_RESOURCES_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionResourcesIds || {};
      const currentOccasionResourcesIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionResourcesIds];
      return {
        ...state,
        occasionResourcesIds: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }

    case DELETE_OCCASION_RESOURCES_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionResourcesIds || {};
      const currentOccasionResourcesIds = occasionGroupedData[ocId] || [];
      const filteredIds = currentOccasionResourcesIds.filter(
        (id) => id !== data.id
      );

      return {
        ...state,
        occasionResourcesIds: {
          ...occasionGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case OCCASION_RESOURCES_CREATE_FAILED:
    case OCCASION_RESOURCES_VIEW_FAILED:
    case DELETE_OCCASION_RESOURCES_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default occasionResources;
