import request from './request';

export function occasionTicketVariantCreateApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/ticket/ticket_variants`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function occasionTicketVariantViewApi(ocId, ticketId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/ticket/ticket_variants?page=${page}&ticket_id=${ticketId}`,
    {
      method: 'GET',
      token
    }
  );
}

export function updateTicketVariantApi(ocId, ticketVariantId, formData, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/ticket/ticket_variants/${ticketVariantId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}
