import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { Form, Empty } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../components/Section';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import InputGroup from '../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../components/InputGroup/FormComponents';
import {
  getGroupOccasionUsers,
  searchGroupOccasionUsers,
  createGroupMembers,
  parseCSVtoAddGroupMembers
} from '../../../../actions/group';
import {
  getAllOccasionGroupUsers,
  getAllOccasionSearchedGroupUsers,
  getGroupOccasionSearchedUserLinks,
  getGroupOccasionUserLinks
} from '../../../../selectors/group';
import { ListItem } from '../../../../components/ListItem';

const validateUsers = (values, users) => {
  const errors = {};
  if (values.toggle) {
    if (!values.csv) {
      errors.csv = 'A CSV file is required';
    }
  } else {
    if (users.length === 0) {
      errors.name = 'Member is required';
    }
  }
  return errors;
};

const GroupMembersCreate = (props) => {
  const [users, setGetGroupDetails] = React.useState({
    name: '',
    toggle: false,
    csv: null
  });

  const [selectedUsers, setSelectedUsers] = useState([]);

  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);

  const [errorMessages, setErrorMessages] = React.useState({});

  useEffect(() => {
    fetchUserDetails(page);
  }, [page, props.tabKey]);

  useEffect(() => {
    fetchSearchUserDetails(searchPage);
  }, [searchPage]);

  const getPage = (str) => {
    const decodedURI = decodeURI(str);
    const number = new URLSearchParams(decodedURI).get('page[number]');
    return (number && parseInt(number)) || 1;
  };

  const fetchUserDetails = (page) => {
    setTimeout(() => {
      props.getGroupOccasionUsers(props.occasionId, props.groupId, page);
    }, 0);
  };

  const fetchSearchUserDetails = (page) => {
    if (users.name.length > 0) {
      props.searchGroupOccasionUsers(
        props.occasionId,
        props.groupId,
        page,
        users.name
      );
    }
  };

  const addGroupMembers = () => {
    try {
      const validateObj = validateUsers(users, selectedUsers);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        const formData = new FormData();

        if (users.toggle) {
          if (users.csv[0]) {
            formData.append('csv_file', users.csv[0]?.originFileObj);
          }
          props.parseCSVtoAddGroupMembers(
            props.occasionId,
            formData,
            props.groupId,
            callback
          );
        } else {
          formData.append('occasion_group_member[group_id]', props.groupId);

          selectedUsers.forEach((el) =>
            formData.append('occasion_group_member[member_ids][]', el.id)
          );

          props.createGroupMembers(
            props.occasionId,
            formData,
            props.groupId,
            callback
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const callback = () => {
    setGetGroupDetails({
      ...users,
      name: '',
      csv: null
    });
    setSelectedUsers([]);
  };

  const handleChange = (e, type) => {
    try {
      if (e !== null || e !== undefined) {
        let value = e?.target?.value;
        let inputName = e?.target?.name;
        if (type === 'csv') {
          value = e.fileList;
          inputName = type;
        }
        if (type === 'toggle') {
          value = e;
          inputName = type;
        }

        if (value && type !== 'csv' && type !== 'toggle') {
          props.searchGroupOccasionUsers(
            props.occasionId,
            props.groupId,
            1,
            value
          );
        }

        if (searchPage !== 1) {
          setSearchPage(1);
        }

        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        setGetGroupDetails({
          ...users,
          [inputName]: value
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onEndReached = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height >= scrollHeight - 20) {
      if (props.links?.next && !users.name) {
        const page = getPage(props.links?.next);
        setPage(page);
      } else if (props.searchedUsersLinks && users.name) {
        const page = getPage(props.searchedUsersLinks?.next);
        setSearchPage(page);
      }
    }
  };

  const onSelectUser = (data) => {
    const found = selectedUsers.find((el) => el.id === data.id);
    if (!found) {
      setSelectedUsers([...selectedUsers, data]);
    } else {
      const filteredList = selectedUsers.filter((el) => el.id !== data.id);
      setSelectedUsers([...filteredList]);
    }
  };

  const getFilteredList = () => {
    const selectedUserIds = selectedUsers.map((data) => data?.id);

    const userList = users.name ? props.searchedUsers : props.users;

    const filteredList = userList?.filter(
      (el) => !selectedUserIds.includes(el.id) && el
    );

    return filteredList || [];
  };

  const renderSelectedUsers = () => {
    return (
      <div className="selected-users-container" onScroll={onEndReached}>
        {selectedUsers &&
          selectedUsers.map((item) => {
            return (
              <ListItem
                key={item.id}
                data={item}
                onSelect={onSelectUser}
                selected={true}
                disabled={!!item.currentOccasionGroupMember}
              />
            );
          })}
      </div>
    );
  };

  const renderListBox = () => {
    return (
      <div className="users-list-box" onScroll={onEndReached}>
        {getFilteredList().length === 0 && !props.fetchProgress && (
          <div className="users-empty-list">
            <Empty />
          </div>
        )}
        {getFilteredList().map((item) => {
          return (
            <ListItem
              key={item.id}
              data={item}
              onSelect={onSelectUser}
              disabled={!!item.currentOccasionGroupMember}
            />
          );
        })}
        {!props.links?.next && <p className="no-more-data">--------</p>}
        {props.fetchProgress && (
          <div className="users-list-loader">
            <LoadingOutlined className="loader-icon" />
          </div>
        )}
      </div>
    );
  };

  return (
    <ErrorBoundary>
      <SectionContent className="group-members-section">
        <div className="group-members-create">
          <Form className="group-form-container" onFinish={addGroupMembers}>
            <InputGroup
              fieldType="switch"
              name="toggle"
              label="Toggle to upload CSV"
              onChange={handleChange}
            />
            {users.toggle ? (
              <InputGroup
                fieldType="file"
                accept=".csv"
                name="csv"
                label="CSV file"
                fileList={users?.csv}
                onChange={handleChange}
                errorMessage={errorMessages?.csv}
              />
            ) : (
              <>
                <InputGroup
                  label="Member Name"
                  placeholder="Enter Name"
                  name="name"
                  onChange={handleChange}
                  value={users?.name}
                  errorMessage={errorMessages?.name}
                />
                {renderSelectedUsers()}
                {renderListBox()}
              </>
            )}

            <SubmitButton
              buttonText="Add Members"
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  users: getAllOccasionGroupUsers(store, props),
  links: getGroupOccasionUserLinks(store, props),
  searchedUsers: getAllOccasionSearchedGroupUsers(store, props),
  searchedUsersLinks: getGroupOccasionSearchedUserLinks(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGroupOccasionUsers,
      searchGroupOccasionUsers,
      createGroupMembers,
      parseCSVtoAddGroupMembers
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GroupMembersCreate);
