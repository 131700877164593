import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Login from '../containers/Login';
import { RoutingEndPoints } from './RoutingEndPoints';
import ForgotPasswordPage from '../containers/ForgotPassword/ForgotPassword';
import ResetPasswordPage from '../containers/ResetPassword/index';

const UnAuthorizedRoutes = () => {
  const {
    unAuthorized: { signIn, forgotPassword, resetPassword }
  } = RoutingEndPoints;
  return (
    <>
      <Switch>
        <Route exact path={signIn.path} component={Login} />
        <Route
          exact
          path={forgotPassword.path}
          component={ForgotPasswordPage}
        />
        <Route exact path={resetPassword.path} component={ResetPasswordPage} />
      </Switch>
    </>
  );
};

export default withRouter(UnAuthorizedRoutes);
