import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button, AutoComplete } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup';
import { getImageSource } from '../../../../../helpers/imageHelper';
import { Image } from '../../../../../components/Image';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import { occasionGroupView } from '../../../../../actions/group';
import { getOccasionGroupsWithAllData } from '../../../../../selectors/group';
import { currentAlarmData } from '../../../../../selectors/alarm';
import { updateAlarm, occasionAlarmCreate } from '../../../../../actions/alarm';

const validateAlarmData = (values, id) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Name is required';
  }
  if (!values.date) {
    errors.date = 'Date is required (should be greater then present date)';
  }
  if (!values.time) {
    errors.time = 'Time is required';
  }
  if (values.group?.length === 0 && values.sendToAll !== true) {
    errors.group = 'Group is required';
  }
  return errors;
};

const { Option } = AutoComplete;

function CreateAlarm(props) {
  const [alarmData, setAlarmData] = React.useState({
    name: props.currentAlarmData?.name || '',
    description: props.currentAlarmData?.description || '',
    sendToAll: true,
    isActive: props.currentAlarmData?.isActive === 'True' ? true : false,
    group: [],
    date: props.currentAlarmData?.alarmTime
      ? moment.utc(props.currentAlarmData.alarmTime)
      : null,
    time: props.currentAlarmData?.alarmTime
      ? moment.utc(props.currentAlarmData.alarmTime)
      : null
  });
  const [errorMessages, setErrorMessages] = React.useState({});
  const [page, setPage] = React.useState(1);

  const onPageChange = (page) => {
    props.occasionGroupView(props.occasionId, page);
  };

  React.useEffect(() => {
    onPageChange(page);
  }, [page]);

  const getPage = (str) => {
    const decodedURI = decodeURI(str);
    const number = decodedURI
      .split('page[number]')[1]
      .replace('=', '')
      .split('&');
    return (number && parseInt(number)) || 1;
  };

  const onEndReached = (e) => {
    const { scrollTop, scrollHeight } = e.target;
    const { height } = e.target.getBoundingClientRect();
    if (scrollTop + height >= scrollHeight - 20) {
      if (props.allGroupsLinks?.next) {
        const page = getPage(props.allGroupsLinks?.next);
        setPage(page);
      }
    }
  };

  const handleSubmit = () => {
    try {
      const validateObj = validateAlarmData(alarmData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();

        const dateTime =
          alarmData?.date?.format('YYYY-MM-DD') +
          ' ' +
          alarmData?.time?.format('HH:mm');
        formData.append('alarm[alarm_time]', dateTime);
        formData.append('alarm[name]', alarmData.name);
        formData.append('alarm[description]', alarmData.description);
        if (!props.alarmId) {
          formData.append(
            'alarm[send_to_all]',
            alarmData.sendToAll ? true : false
          );
        }
        if (props.alarmId) {
          formData.append(
            'alarm[is_active]',
            alarmData.isActive ? true : false
          );
        }
        if (!alarmData?.sendToAll) {
          alarmData?.group.forEach((id) => {
            formData.append('alarm[group_ids][]', id);
          });
        }

        props.alarmId
          ? props.updateAlarm(props.occasionId, props.alarmId, formData)
          : props.occasionAlarmCreate(props.occasionId, formData, () => {
              setAlarmData({
                name: '',
                description: '',
                sendToAll: true,
                isActive: false,
                group: [],
                date: '',
                time: ''
              });
            });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;

      if (type === 'sendToAll') {
        value = e;
        name = type;
      }
      if (type === 'isActive') {
        value = e;
        name = type;
      }
      if (type === 'date') {
        value = moment(e, 'DD/MM/YYYY');
        name = type;
      }
      if (type === 'time') {
        value = moment(e, 'HH:mm');
        name = type;
      }

      const alarmValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setAlarmData({
        ...alarmData,
        [inputName]: alarmValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="alarm-create-section">
        <div className="alarm-create-form">
          <Form className="alarm-form" onFinish={handleSubmit}>
            <div style={{ color: 'red' }}>**Date And Time Should Be In UTC</div>
            <div style={{ display: 'flex' }}>
              <InputGroup
                label="Date"
                placeholder="Enter Date"
                name="date"
                fieldType="datePicker"
                onChange={handleInputChange}
                value={alarmData?.date}
                errorMessage={errorMessages?.date}
              />
              <InputGroup
                label="Time"
                placeholder="Enter Time"
                name="time"
                fieldType="timePicker"
                format="HH:mm"
                onChange={handleInputChange}
                value={alarmData?.time}
                errorMessage={errorMessages?.time}
              />
            </div>
            <InputGroup
              label="Name"
              name="name"
              placeholder="Enter Name"
              onChange={handleInputChange}
              value={alarmData?.name}
              errorMessage={errorMessages.name}
            />
            <InputGroup
              label="Description"
              name="description"
              fieldType="textArea"
              placeholder="Enter Description"
              onChange={handleInputChange}
              value={alarmData?.description}
            />

            {!props.alarmId && (
              <InputGroup
                label="For All Members"
                name="sendToAll"
                fieldType="switch"
                onChange={handleInputChange}
                value={alarmData?.sendToAll}
              />
            )}

            {props.alarmId && (
              <InputGroup
                label="Is Active"
                name="isActive"
                fieldType="switch"
                onChange={handleInputChange}
                value={alarmData?.isActive}
              />
            )}

            {!props.alarmId && !alarmData?.sendToAll && (
              <InputGroup
                fieldType="dropdown"
                className="group-search"
                mode="multiple"
                name="group"
                label="Select Group"
                placeholder="Select Group"
                onPopupScroll={onEndReached}
                onChange={handleInputChange}
                errorMessage={errorMessages?.group}
                value={alarmData?.group}>
                {props.groupData?.length > 0 &&
                  props.groupData.map((item) => {
                    return (
                      <Option value={item?.id} key={item?.id}>
                        <div className="options-container">
                          <p className="avatar-image-container">
                            <Image
                              src={getImageSource(item?.groupPictureUrl)}
                              preview={false}
                            />
                          </p>
                          <div className="occasion-group-details-container">
                            <p className="occasion-group-name">{item?.name}</p>
                          </div>
                        </div>
                      </Option>
                    );
                  })}
              </InputGroup>
            )}
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.alarmId ? 'Save Changes' : 'Create'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  groupData: getOccasionGroupsWithAllData(store, props),
  currentAlarmData: currentAlarmData(store, props),
  allGroupsLinks: store.group.groupLinks
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { occasionGroupView, updateAlarm, occasionAlarmCreate },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateAlarm);
