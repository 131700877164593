import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import './styles.scss';
import { Button } from 'antd';
import {
  capitalize,
  changeUnderscoreToSpace,
  decamelize
} from '../../../../helpers/stringHelper';
import { Switch } from '../../../../components/InputGroup/FormComponents';
import { usePrevious } from '../../../../hooks/usePrevious';

const ControlComponent = ({
  data,
  filterString = '',
  entityName = '',
  currentControls,
  updateControl,
  createControl,
  fetchProgress,
  occasionId
}) => {
  const prevProps = usePrevious({
    data,
    filterString,
    entityName,
    currentControls,
    updateControl,
    createControl,
    fetchProgress
  });

  let currentControl;
  currentControls?.forEach((el) => {
    if (el[filterString] === data) {
      currentControl = el;
    }
  });

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (prevProps?.currentControls !== currentControls) {
      setIsActive(currentControl?.status === 'on');
    }
  }, [prevProps?.currentControls, currentControls, currentControl?.status]);

  const [loadingId, setLoadingId] = useState('');
  const convertedDataString = capitalize(changeUnderscoreToSpace(data));
  const found =
    ['on', 'off'].includes(currentControl?.status) && !!currentControl;

  const handleSwitchChange = (e) => {
    const formData = new FormData();
    formData.append(`${entityName}[status]`, e ? 'on' : 'off');
    setLoadingId(currentControl?.id);
    updateControl(occasionId, currentControl?.id, formData, () => {
      setIsActive((prevIsActive) => !prevIsActive);
      setLoadingId('');
    });
  };

  const handleButtonClick = () => {
    const formData = new FormData();
    setLoadingId(currentControl?.id);
    formData.append(`${entityName}[${decamelize(filterString, '_')}]`, data);
    createControl(occasionId, formData, () => {
      setLoadingId('');
    });
  };

  return (
    <div className="control-container">
      <span style={{ marginRight: 10 }}>{convertedDataString}</span>
      {fetchProgress && currentControl?.id === loadingId ? (
        <LoadingOutlined spin />
      ) : found ? (
        <Switch
          name={data}
          disabled={fetchProgress}
          onChange={handleSwitchChange}
          value={isActive}
          loading={loadingId === currentControl?.id}
        />
      ) : (
        <Button
          type="primary"
          size="small"
          disabled={fetchProgress}
          className="create-button"
          loading={loadingId === currentControl?.id}
          onClick={handleButtonClick}>
          Create
        </Button>
      )}
    </div>
  );
};

export { ControlComponent };
