import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import {
  hospitalityTaskRespondedView,
  hospitalityRequestedTaskDownload,
  hospitalityRequestedTaskDelete,
  searchRespondedTasks
} from '../../../../../../actions/hospitalityTask';
import {
  getAllResondedTaskWithUserData,
  currentHospitalityTaskWithTaskOptionsData,
  getAllResondedTask,
  getAllResondedTaskSearchWithGuestData
} from '../../../../../../selectors/hospitalityTask';
import useLinkDownloader from '../../../../../../hooks/useLinkDownloader';
import { Button, Modal } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { RESET_RESPONDEDTASKS } from '../../../../../../constants/actionTypes';
import ProvidedTaskSearch from './ProvidedTaskSearch';
import AddProvidedAnswerForAll from './AddProvidedAnswerForAll';

const RespondedView = (props) => {
  const [page, setPage] = useState(1);
  const [openSingleAnswer, setOpenSingleAnswer] = useState(false);
  const [isMultipalFileView, setIsMultipalFileView] = useState(false);

  const dispatch = useDispatch();
  const [taskData, setTaskData] = React.useState({
    info: ''
  });

  const handleCreateSingleAnswer = (userId, guestId) => {
    let data = {
      userId,
      guestId
    };
    setOpenSingleAnswer(data);
  };

  const onSearch = () => {
    try {
      if (taskData.info) {
        props.searchRespondedTasks(
          props.occasionId,
          props.hospitalityTaskId,
          taskData,
          1
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const resetSearch = () => {
    dispatch({ type: RESET_RESPONDEDTASKS });
    setTaskData({
      info: ''
    });
  };

  const handleMultipalFilesView = () => {
    setIsMultipalFileView(true);
  };
  const handleOk = (type) => {
    if (type === 'multipalFileView') {
      setIsMultipalFileView(false);
    }
  };

  const handleCancel = (type) => {
    if (type === 'multipalFileView') {
      setIsMultipalFileView(false);
    }
  };

  const onPageChange = (page) => {
    setPage(page);
    props.hospitalityTaskRespondedView(
      props.occasionId,
      props.hospitalityTaskId,
      page
    );
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const { downloadLinkFile } = useLinkDownloader();

  const handleDownloadRequestedData = () => {
    props.hospitalityRequestedTaskDownload(
      props.occasionId,
      props.hospitalityTaskId,
      (blobUrl) => {
        downloadLinkFile(
          blobUrl,
          `${props?.currentTask?.name}_${props.currentTask?.id}_requested`,
          'csv'
        );
      }
    );
  };

  const paginationOptions = {
    showTotal: () => {
      return (
        <div className="button-box">
          <Button className="refresh-button" onClick={() => onPageChange(page)}>
            <SyncOutlined />
          </Button>
          {props.currentTask?.dataInputType != 'document' && (
            <Button
              className="download-button"
              onClick={handleDownloadRequestedData}>
              Download
            </Button>
          )}
        </div>
      );
    }
  };

  const handleDelete = (id) => {
    props.hospitalityRequestedTaskDelete(
      props.occasionId,
      props.hospitalityTaskId,
      id
    );
  };

  const expandableComponent = (record) => {
    const { currentOccasionHospitalityGuests: guests } = record;
    if (guests?.length > 0) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ textAlign: 'left' }}>Guests:</p>
          <ul>
            {guests?.length > 0 &&
              guests?.map((item) => {
                return (
                  <li
                    style={{
                      textAlign: 'left',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}>
                    <span style={{ margin: 0 }}>Name: {item?.name}</span>
                    {item?.currentTaskAnswer?.length > 0 ? (
                      item?.currentTaskAnswer[0]
                        ?.multipleDocumentAttachmentUrls ? (
                        <>
                          <div onClick={handleMultipalFilesView}>
                            Answer:
                            <span
                              style={{
                                cursor: 'pointer',
                                color: 'blue',
                                marginLeft: '5px'
                              }}>
                              View All Files
                            </span>
                          </div>
                          <Modal
                            centered
                            title="All Documents"
                            visible={isMultipalFileView}
                            onOk={() => handleOk('multipalFileView')}
                            onCancel={() => handleCancel('multipalFileView')}
                            footer={null}>
                            <div
                              style={{
                                width: '100%',
                                height: '150px',
                                overflowY: 'scroll',
                                display: 'flex',
                                flexFlow: 'column nowrap'
                              }}>
                              {Array.isArray(item.currentTaskAnswer) &&
                                item.currentTaskAnswer.length > 0 &&
                                item.currentTaskAnswer[0].multipleDocumentAttachmentUrls.map(
                                  (item, index) => {
                                    return (
                                      <div style={{ marginBottom: '20px' }}>
                                        <a
                                          href={`${
                                            process.env.REACT_APP_CLIENT_URL
                                          }/${Object.values(item)[0]}`}
                                          target="_blank">
                                          {`File ${index + 1}`}
                                        </a>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </Modal>
                        </>
                      ) : item?.currentTaskAnswer[0]?.documentAttachmentUrl ? (
                        <span style={{ marginBottom: 10 }}>
                          Answer:
                          <a
                            href={`${process.env.REACT_APP_CLIENT_URL}/${item?.currentTaskAnswer[0]?.documentAttachmentUrl}`}
                            target="_blank">
                            File
                          </a>
                        </span>
                      ) : (
                        <span style={{ marginBottom: 10 }}>
                          Answer: {item?.currentTaskAnswer[0]?.answer}
                        </span>
                      )
                    ) : (
                      <span>
                        Answer:
                        <span style={{ color: 'red' }}>
                          NA
                          <Button
                            onClick={() =>
                              handleCreateSingleAnswer(record?.userId, item?.id)
                            }
                            style={{
                              marginLeft: 10,
                              display: 'flex',
                              width: 150,
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                            Add Answer
                          </Button>
                        </span>
                      </span>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
      );
    } else {
      return <p style={{ textAlign: 'left' }}>No Guests present.</p>;
    }
  };

  const getData = () => {
    if (
      props.isSearch
        ? props.respondedSearchData?.length > 0
        : props.respondedTask?.length > 0
    ) {
      const data = props.isSearch
        ? props.respondedSearchData
        : props.respondedTask;

      return data.map((item) => {
        return {
          id: item?.currentRequestedTaskAnswer?.id,
          userId: item?.id,
          name: item?.name,
          email: item?.email,
          mobile: item?.mobile,
          currentRequestedTaskAnswer: item?.currentRequestedTaskAnswer,
          currentOccasionHospitalityGuests:
            item?.currentOccasionHospitalityGuests,
          avatarUrl: item?.avatarUrl
        };
      });
    } else {
      return [];
    }
  };

  if (openSingleAnswer) {
    return (
      <div className="edit-container">
        <AddProvidedAnswerForAll
          occasionId={props.occasionId}
          hospitalityTaskId={props.hospitalityTaskId}
          sendToAll={false}
          userData={openSingleAnswer}
          type="request"
          hospitalityTaskDetails={props.currentTask}
          dataInputType={props.dataInputType}
          handleCloseClick={() => setOpenSingleAnswer(false)}
        />
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <SectionContent className="task-request-view-section">
        <div className="task-request-view-container">
          <ProvidedTaskSearch
            occasionId={props.occasionId}
            taskData={taskData}
            setTaskData={setTaskData}
            onSearch={onSearch}
            resetSearch={resetSearch}
          />
          <TableView
            isPaginated={true}
            isRouted={false}
            paginationOptions={paginationOptions}
            expandable={expandableComponent}
            onPageChange={onPageChange}
            onDelete={handleDelete}
            data={getData()}
            fetchProgress={props.fetchProgress}
            blacklistKeys={[
              'hospitalityTaskId',
              'hospitalityGuestId',
              'answerToId',
              'answerById',
              'answerTo',
              'dob',
              'gender',
              'userId'
            ]}
            paginationLinks={
              props.isSearch ? props.respondedSearchLinks : props.respondedLinks
            }
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  respondedTask: getAllResondedTaskWithUserData(store, props),
  currentTask: currentHospitalityTaskWithTaskOptionsData(store, props),
  allData: getAllResondedTask(store, props),
  respondedLinks: store.hospitalityTask.respondedtaskLinks,

  respondedSearchData: getAllResondedTaskSearchWithGuestData(store, props),
  respondedSearchLinks: store.hospitalityTask.respondedSearchLinks,
  isSearch: store.hospitalityTask.isRespondedSearch
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    {
      hospitalityTaskRespondedView,
      hospitalityRequestedTaskDownload,
      hospitalityRequestedTaskDelete,
      searchRespondedTasks
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(RespondedView);
