import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  deleteHospitalityCategoryApi,
  downloadExslExportApi,
  getContactUsApi,
  getExslExportApi,
  hospitalityCreateApi,
  hospitalityViewApi,
  updateHospitalityAllAnswerSForXLSXApi,
  updateHospitalityCategoryApi,
  updateHospitalityProvideAnswerForCSVApi
} from '../api/hospitality';
import {
  DELETE_HOSPITALITY_CATEGORY,
  DOWNLOAD_EXSL_EXPORT,
  GET_CONTACTUS,
  GET_EXSL_EXPORT,
  HOSPITALITY_CATEGORY_CREATE,
  HOSPITALITY_CATEGORY_UPDATE,
  HOSPITALITY_CATEGORY_VIEW,
  UPDATE_HOSPITALITY_ALL_ANSWERS,
  UPDATE_HOSPITALITY_PROVIDE_ANSWER
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  hospitalityCategoryCreateSuccess,
  hospitalityCategoryCreateFailed,
  hospitalityCategoryViewSuccess,
  hospitalityCategoryViewFailed,
  hospitalityCategoryUpdateSuccess,
  hospitalityCategoryUpdateFailed,
  deleteHospitalityCategorySuccess,
  deleteHospitalityCategoryFailed,
  getContactUsSuccess,
  getContactUsFailed,
  updateHospitalityProvideAnswerSuccess,
  updateHospitalityProvideAnswerFailed,
  getExslExportSuccess,
  getExslExportFailed,
  downloadExslExportSuccess,
  downloadExslExportFailed,
  updateHospitalityAllAnswersSuccess,
  updateHospitalityAllAnswersFailed
} from '../actions/hospitality';

export function* hospitalityCreateSaga(action) {
  try {
    const result = yield call(
      hospitalityCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hospitalityCategoryCreateSuccess(result.data, action.payload.ocId)
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(hospitalityCategoryCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(hospitalityCategoryCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hospitalityViewSaga(action) {
  try {
    const result = yield call(
      hospitalityViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        hospitalityCategoryViewSuccess(
          result.data,
          result.included,
          result.links,
          action.payload.ocId,
          action.payload.page
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(hospitalityCategoryViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getExslExportSaga(action) {
  try {
    const result = yield call(
      getExslExportApi,
      action.payload.ocId,
      action.payload.taskIds,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(getExslExportSuccess(result.data));
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getExslExportFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* downloadExslExportSaga(action) {
  try {
    const result = yield call(
      downloadExslExportApi,
      action.payload.ocId,
      action.payload.remember,
      action.payload.taskIds,
      action.payload.orderBy,
      action.payload.orderWith,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(downloadExslExportSuccess(result.data, result.included));
      if (typeof action.callback === 'function') {
        action.callback(result?.blobUrl);
      }
    }
  } catch (error) {
    yield put(downloadExslExportFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* hospitalityCategoryUpdateSaga(action) {
  try {
    const result = yield call(
      updateHospitalityCategoryApi,
      action.payload.ocId,
      action.payload.hospitalityCategoryId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(hospitalityCategoryUpdateSuccess(result.data));
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(hospitalityCategoryUpdateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Some error occured', 'Error', 4000));
    }
  } catch (error) {
    yield put(hospitalityCategoryUpdateFailed(error));
    yield put(requestAutoHideAlert(error, 'Error', 4000));
  }
}

export function* hospitalityCategoryDeleteSaga(action) {
  try {
    const result = yield call(
      deleteHospitalityCategoryApi,
      action.payload.ocId,
      action.payload.hospitalityCategoryId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        deleteHospitalityCategorySuccess(action.payload.ocId, result.data)
      );
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(deleteHospitalityCategoryFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* contactUsViewSaga(action) {
  try {
    const result = yield call(
      getContactUsApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getContactUsSuccess(
          result.data,
          result.included,
          result.links,
          action.payload.ocId
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getContactUsFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updateHospitalityProvideAnswerByCsvSaga(action) {
  try {
    const result = yield call(
      updateHospitalityProvideAnswerForCSVApi,
      action.payload.ocId,

      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateHospitalityProvideAnswerSuccess(
          action.payload.ocId,

          result.data,
          result.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(updateHospitalityProvideAnswerFailed(error));
    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updateHospitalityAllAnswersByXlsxSaga(action) {
  try {
    const result = yield call(
      updateHospitalityAllAnswerSForXLSXApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateHospitalityAllAnswersSuccess(
          action.payload.ocId,
          result.data,
          result.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(updateHospitalityAllAnswersFailed(error));
    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* hospitalityActionWatcher() {
  yield takeLatest(
    HOSPITALITY_CATEGORY_CREATE,
    progressSaga,
    hospitalityCreateSaga
  );
  yield takeLatest(
    HOSPITALITY_CATEGORY_VIEW,
    progressSaga,
    hospitalityViewSaga
  );
  yield takeLatest(GET_EXSL_EXPORT, progressSaga, getExslExportSaga);
  yield takeLatest(DOWNLOAD_EXSL_EXPORT, progressSaga, downloadExslExportSaga);
  yield takeLatest(
    HOSPITALITY_CATEGORY_UPDATE,
    progressSaga,
    hospitalityCategoryUpdateSaga
  );
  yield takeLatest(
    DELETE_HOSPITALITY_CATEGORY,
    progressSaga,
    hospitalityCategoryDeleteSaga
  );
  yield takeLatest(GET_CONTACTUS, progressSaga, contactUsViewSaga);
  yield takeLatest(
    UPDATE_HOSPITALITY_PROVIDE_ANSWER,
    progressSaga,
    updateHospitalityProvideAnswerByCsvSaga
  );
  yield takeLatest(
    UPDATE_HOSPITALITY_ALL_ANSWERS,
    progressSaga,
    updateHospitalityAllAnswersByXlsxSaga
  );
}
