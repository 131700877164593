import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import {
  occasionLiveVideoView,
  occasionLiveVideoDelete
} from '../../../../../actions/liveVideo';
import { getAllOccasionLivevideos } from '../../../../../selectors/liveVideo';
import { LiveVideoComponent } from './LiveVideoComponent';

const LiveVideoView = (props) => {
  const onPageChange = (page) => {
    props.occasionLiveVideoView(props.occasionId, page);
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);
  const deleteLiveVideo = (videoId) => {
    props.occasionLiveVideoDelete(props.occasionId, videoId);
  };
  return (
    <ErrorBoundary>
      <SectionContent className="live-video-view-section">
        <div className="live-video-view-container">
          {props.liveVideos?.length > 0
            ? props.liveVideos?.map((item) => (
                <LiveVideoComponent
                  url={item.url}
                  videoId={item.id}
                  deleteLiveVideo={deleteLiveVideo}
                />
              ))
            : 'No Videos To Display !'}
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  liveVideos: getAllOccasionLivevideos(store, props)
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { occasionLiveVideoView, occasionLiveVideoDelete },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(LiveVideoView);
