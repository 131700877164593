import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'antd';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import TableView from '../../../../../components/Table';
import { getOccasionInternalTeamMember } from '../../../../../actions/socianaInternalUser';
import { currentOccasionId } from '../../../../../selectors/dynamicData';
import {
  getCurrentOccasionInternalTeam,
  getCurrentOccasionInternalTeamLinks
} from '../../../../../selectors/socianaInternalUser';
import RowExpandable from './RowExpandable';
import AssignInternalTeam from './AssignInternalTeam';
import { SectionContent } from '../../../../../components/Section';

const ViewInternalTeamMembers = (props) => {
  const [assignOpen, setAssignOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.getOccasionInternalTeamMember(page, props.occasionId);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const expandableComponent = (record) => {
    return (
      <RowExpandable
        currentCollaboratorId={record?.id}
        occasionId={props.occasionId}
      />
    );
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="internal-team-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="assign-button" onClick={() => setAssignOpen(true)}>
          Assign
        </Button>
      </div>
    )
  };
  if (!assignOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="occassion-internal-team-view-section">
          <div className="occasion-internal-team-view-container">
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              expandable={expandableComponent}
              fetchProgress={props.fetchProgress}
              onPageChange={onPageChange}
              data={props.internalTeam}
              isRouted={false}
              paginationLinks={props.internalTeamLinks}
              blacklistKeys={['userId', 'occasionId', 'user']}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-assign-container">
      <AssignInternalTeam
        occasionId={props.occasionId}
        handleCloseClick={() => setAssignOpen(false)}
      />
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  occasionId: currentOccasionId(store, props),
  internalTeam: getCurrentOccasionInternalTeam(store, props),
  internalTeamLinks: getCurrentOccasionInternalTeamLinks(store, props)
});

const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ getOccasionInternalTeamMember }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchProps
)(ViewInternalTeamMembers);
