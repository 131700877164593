import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import { Switch } from '../../../../../../components/InputGroup/FormComponents';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import { Button, Tooltip } from 'antd';
import PollPostCreate from '../Create';
import {
  occasionPollPostViewByType,
  deletePollPostByType,
  downloadPollPostByCsv,
  updatePollPost
} from '../../../../../../actions/pollPost';
import { getAllPollPostPollWithOptionsData } from '../../../../../../selectors/pollPost';
import useLinkDownloader from '../../../../../../hooks/useLinkDownloader';

const PollPostPollView = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [pollPostId, setPollPostId] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const { downloadLinkFile } = useLinkDownloader();

  const onPageChange = (page) => {
    setPage(page);
    props.occasionPollPostViewByType(props.occasionId, 'poll', page);
  };

  const pageRefresh = (page) => {
    props.occasionPollPostViewByType(props.occasionId, 'poll', page);
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  // const handleCreate = () => {
  //   setPollPostId('');
  //   setCreateOpen(true);
  // };

  const downloadCsv = (id, question) => {
    props.downloadPollPostByCsv(props.occasionId, id, (blobUrl) => {
      downloadLinkFile(blobUrl, `${question.replace(/\?$/, '')}`, 'csv');
    });
  };

  const onHandelVisible = (e, id, createdBy) => {
    const formData = new FormData();
    formData.append('poll_post[is_visible]', e ? true : false);
    formData.append('poll_post[created_by_id]', createdBy.id);
    props.updatePollPost(props.occasionId, id, formData);
  };

  const customAction = (id) => {
    if (id) {
      let question = props.pollPostData?.find((item) => item.id == id)
        ?.question;
      let isVisible = props.pollPostData?.find((item) => item.id == id)
        ?.isVisible;
      let createdBy = props.pollPostData?.find((item) => item.id == id)
        ?.createdBy;
      return (
        <>
          <Tooltip title="Download Csv">
            <Button
              className="action-button"
              onClick={() => downloadCsv(id, question)}>
              <DownloadOutlined />
            </Button>
          </Tooltip>
          <Tooltip
            title={isVisible == true ? 'Make It InVisible' : 'Make It Visible'}>
            <Switch
              loading={props.fetchProgress}
              value={isVisible}
              style={{ marginLeft: 10 }}
              onChange={(e) => onHandelVisible(e, id, createdBy)}
            />
          </Tooltip>
        </>
      );
    }
    return;
  };

  const paginationOptions = {
    showTotal: () => (
      <div className="poll-post-button-box">
        <Button className="refresh-button" onClick={() => pageRefresh(page)}>
          <SyncOutlined />
        </Button>
      </div>
    )
  };

  const editData = (id) => {
    setCreateOpen(true);
    setPollPostId(id);
  };

  const deleteData = (id) => {
    props.deletePollPostByType(props.occasionId, id, 'poll');
  };

  const getData = () => {
    if (props.pollPostData?.length > 0) {
      return props.pollPostData?.map((item) => {
        return {
          id: item?.id,
          isVisible: item?.isVisible,
          question: item?.question,
          questionType: item?.questionType,
          description: item?.description,
          createdBy: item?.createdBy,
          pollPostOptions: item?.pollPostOptions,
          explanationDescription: item?.pollPostExplanation?.description,
          picture:
            item?.pollPostExplanation?.pictureUrls &&
            item?.pollPostExplanation?.pictureUrls.length > 0
              ? item?.pollPostExplanation?.pictureUrls.map((item) =>
                  Object.values(item)
                )
              : [],
          video: item?.pollPostExplanation?.videoUrl,
          videoThumbnail: item?.pollPostExplanation?.videoThumbnailUrls
        };
      });
    } else {
      return [];
    }
  };

  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="poll-post-view-section">
          <div className="poll-post-view-container">
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              isRouted={false}
              onPageChange={onPageChange}
              onDelete={deleteData}
              isEditable={editData}
              data={getData()}
              fetchProgress={props.fetchProgress}
              paginationLinks={props.pollPostLinks}
              customAction={customAction}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <PollPostCreate
        occasionId={props.occasionId}
        pollPostId={pollPostId}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  pollPostData: getAllPollPostPollWithOptionsData(store, props),
  pollPostLinks: store.pollPost.pollPostPollLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    {
      occasionPollPostViewByType,
      deletePollPostByType,
      downloadPollPostByCsv,
      updatePollPost
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(PollPostPollView);
