import { createSelector } from 'reselect';
import { currentOccasionId } from './dynamicData';
import { userEntities, discussionEntities } from './entity';
import { discussionIds } from './static';

export const currentOccasionDiscussionIds = createSelector(
  currentOccasionId,
  discussionIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getAllOccasionDiscussions = createSelector(
  discussionEntities,
  currentOccasionDiscussionIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
export const occasionDiscussionWithUserData = createSelector(
  getAllOccasionDiscussions,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      user: { ...entities[item?.user?.id] }
    }))
);
