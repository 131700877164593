import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import {
  getOccasionMembers,
  searchMembers,
  deleteMember,
  downloadMemberDetailsByExls,
  downloadQrCode,
  mamberDownloadExslExport
} from '../../../../../../actions/members';
import {
  getMemberLinks,
  getMembersData,
  getSearchedMemberLinks,
  getSearchedMembersData
} from '../../../../../../selectors/members';
import MemberSearch from './Search';
import './styles.scss';
import { RESET_MEMBERS } from '../../../../../../constants/actionTypes';
import MemberEdit from '../MemberEdit';
import useLinkDownloader from '../../../../../../hooks/useLinkDownloader';

const MembersView = (props) => {
  const [memberData, setMemberData] = React.useState({
    info: '',
    type: ''
  });
  const [memberId, setMemberId] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [page, setPage] = React.useState(1);
  const dispatch = useDispatch();
  const { downloadLinkFile } = useLinkDownloader();

  const downloadExls = () => {
    try {
      props.mamberDownloadExslExport(props.occasionId, (blobUrl) => {
        downloadLinkFile(
          blobUrl,
          `Logged In Users ${props.occasionDetails?.name}`,
          'xlsx'
        );
      });
    } catch (error) {
      console.error(error);
    }
  };

  const searchMemberData = (page = 1) => {
    try {
      if (memberData.info || memberData.type) {
        props.searchMembers(props.occasionId, memberData, page);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onPageChange = (page) => {
    setPage(page);
    if (props.isSearchOn) {
      searchMemberData(page);
    } else {
      props.getOccasionMembers(props.occasionId, page);
    }
  };

  const resetSearch = () => {
    dispatch({ type: RESET_MEMBERS });
    setMemberData({
      info: '',
      type: ''
    });
  };

  const downloadMemberDetailsByExls = () => {
    props.downloadMemberDetailsByExls(props.occasionId, (blobUrl) => {
      downloadLinkFile(
        blobUrl,
        `${props.occasionDetails?.name} Members`,
        'xlsx'
      );
    });
  };

  const downloadQr = () => {
    props.downloadQrCode(props.occasionId, (blobUrl) => {
      downloadLinkFile(
        blobUrl,
        `${props.occasionDetails.name}_QR_Codes`,
        'zip'
      );
    });
  };

  React.useEffect(() => {
    if (!memberData.info && !memberData.type) {
      onPageChange(1);
    }
  }, [memberData]);

  React.useEffect(() => {
    resetSearch();
  }, []);

  const paginationOptions = {
    showTotal: () => (
      <div className="member-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <div className="member-count-container">
          <span>Members</span>
          <span className="member-count">{props.count}</span>
        </div>
        <Button
          className="refresh-button"
          onClick={downloadMemberDetailsByExls}>
          Export Member Details
        </Button>
        <Button className="refresh-button" onClick={downloadQr}>
          Download QR Codes
        </Button>
        <Button className="refresh-button" onClick={downloadExls}>
          Export Logged In User
        </Button>
      </div>
    )
  };

  const deleteData = (id) => {
    props.deleteMember(props.occasionId, id);
  };

  const showDelete = (id) => {
    let showDelete = true;
    let data = null;

    if (props.isSearchOn) {
      data = props.searchedMembers?.find((el) => el.id === id);
    } else {
      data = props.members?.find((el) => el.id === id);
    }

    if (data.type === 'Owner') {
      showDelete = false;
    }

    return showDelete;
  };
  const location = useLocation();

  const editData = (id) => {
    setIsEditable(true);
    setMemberId(id);
  };
  if (!isEditable) {
    return (
      <ErrorBoundary>
        <SectionContent className="members-view-section">
          <div className="members-view-container">
            <MemberSearch
              occasionId={props.occasionId}
              memberData={memberData}
              setMemberData={setMemberData}
              searchMemberData={searchMemberData}
              resetSearch={resetSearch}
            />
            <TableView
              paginationOptions={paginationOptions}
              isPaginated={true}
              pathname={`${location.pathname}/member/view`}
              isRouted={true}
              onPageChange={onPageChange}
              onDelete={deleteData}
              showDelete={showDelete}
              isEditable={editData}
              data={props.isSearchOn ? props.searchedMembers : props.members}
              fetchProgress={props.fetchProgress}
              paginationLinks={
                props.isSearchOn ? props.searchedMemberLinks : props.memberLinks
              }
              blacklistKeys={['userId', 'occasionId', 'user']}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <MemberEdit
      currentCollaboratorId={memberId}
      occasionId={props.occasionId}
      handleCloseClick={() => setIsEditable(false)}
    />
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  members: getMembersData(store, props),
  memberLinks: getMemberLinks(store, props),
  searchedMembers: getSearchedMembersData(store, props),
  searchedMemberLinks: getSearchedMemberLinks(store, props),
  isSearchOn: store.members.isSearch,
  count: store.members.count
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    {
      getOccasionMembers,
      searchMembers,
      deleteMember,
      downloadMemberDetailsByExls,
      downloadQrCode,
      mamberDownloadExslExport
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(MembersView);
