import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'antd';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import { occasionLiveVideoCreate } from '../../../../../actions/liveVideo';

const validateVideoLinkData = (values) => {
  const errors = {};
  if (!values.youTubeLink) {
    errors.youTubeLink = 'YouTube Link is Required';
  }
  if (
    !!values.youTubeLink &&
    !/http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/.test(
      values.youTubeLink
    )
  ) {
    errors.youTubeLink = 'Invalid Url';
  }
  return errors;
};

const LiveVideoLinkCreate = (props) => {
  const [videoLinkData, setVideoLinkData] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const handleSubmit = () => {
    try {
      const validateObj = validateVideoLinkData(videoLinkData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('live_video_url[url]', videoLinkData?.youTubeLink);
        props.occasionLiveVideoCreate(props.occasionId, formData);
        setVideoLinkData({});
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let formValue;
      let inputName;

      if (type === 'youTubeLink') {
        value = e.target.value;
        name = type;
      }

      formValue = value || e?.target?.value;
      inputName = name || e?.target?.name;

      setVideoLinkData({
        ...videoLinkData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ErrorBoundary>
      <SectionContent className="occasion-live-video-create-section">
        <div className="occasion-live-video-create-form">
          <Form className="form-control-container" onFinish={handleSubmit}>
            <InputGroup
              name="youTubeLink"
              placeholder="Enter Youtube Link"
              label="Youtube Link"
              value={videoLinkData?.youTubeLink}
              onChange={handleInputChange}
              errorMessage={errorMessages.youTubeLink}
            />
            <SubmitButton
              buttonText="Create Link"
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ occasionLiveVideoCreate }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveVideoLinkCreate);
