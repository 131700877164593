import {
  GET_ALL_MEMBERS,
  GET_ALL_MEMBERS_SUCCESS,
  GET_ALL_MEMBERS_FAILED,
  SEARCH_MEMBERS,
  SEARCH_MEMBERS_SUCCESS,
  SEARCH_MEMBERS_FAILED,
  DELETE_MEMBERS,
  DELETE_MEMBERS_SUCCESS,
  DELETE_MEMBERS_FAILED,
  UPDATE_MEMBERS,
  UPDATE_MEMBERS_FAILED,
  UPDATE_MEMBERS_SUCCESS,
  DOWNLOAD_MEMBER_EXLS,
  DOWNLOAD_MEMBER_EXLS_FAILED,
  DOWNLOAD_MEMBER_EXLS_SUCCESS,
  DOWNLOAD_QR_CODE,
  DOWNLOAD_QR_CODE_FAILED,
  DOWNLOAD_QR_CODE_SUCCESS,
  MEMBER_DOWNLOAD_EXSL_EXPORT,
  MEMBER_DOWNLOAD_EXSL_EXPORT_FAILED,
  MEMBER_DOWNLOAD_EXSL_EXPORT_SUCCESS,
  MEMBER_CREATE_BY_CSV,
  MEMBER_CREATE_BY_CSV_FAILED,
  MEMBER_CREATE_BY_CSV_SUCCESS
} from '../constants/actionTypes';

export const memberCreateByCsv = (ocId, formData, callback) => ({
  type: MEMBER_CREATE_BY_CSV,
  payload: { ocId, formData },
  callback
});

export const memberCreateByCsvSuccess = (data, included, ocId) => ({
  type: MEMBER_CREATE_BY_CSV_SUCCESS,
  payload: { data, included, ocId }
});

export const memberCreateByCsvFailed = (error) => ({
  type: MEMBER_CREATE_BY_CSV_FAILED,
  error
});

export const mamberDownloadExslExport = (ocId, callback) => {
  return {
    type: MEMBER_DOWNLOAD_EXSL_EXPORT,
    payload: { ocId },
    callback
  };
};

export const memberDownloadExslExportSuccess = (data, included) => {
  return {
    type: MEMBER_DOWNLOAD_EXSL_EXPORT_SUCCESS,
    payload: { data, included }
  };
};

export const memberDownloadExslExportFailed = (error) => {
  return {
    type: MEMBER_DOWNLOAD_EXSL_EXPORT_FAILED,
    error
  };
};

export const getOccasionMembers = (ocId, page, callback) => ({
  type: GET_ALL_MEMBERS,
  payload: { ocId, page },
  callback
});

export const getOccasionMembersSuccess = (
  ocId,
  data,
  included,
  links,
  meta
) => ({
  type: GET_ALL_MEMBERS_SUCCESS,
  payload: { ocId, data, included, links, meta }
});

export const getOccasionMembersFailed = (error) => ({
  type: GET_ALL_MEMBERS_FAILED,
  error
});

export const searchMembers = (ocId, memberData, page, callback) => ({
  type: SEARCH_MEMBERS,
  payload: { ocId, memberData, page },
  callback
});

export const searchMembersSuccess = (ocId, data, included, links, meta) => ({
  type: SEARCH_MEMBERS_SUCCESS,
  payload: { ocId, data, included, links, meta }
});

export const searchMembersFailed = (error) => ({
  type: SEARCH_MEMBERS_FAILED,
  error
});

export const deleteMember = (ocId, memberId, callback) => ({
  type: DELETE_MEMBERS,
  payload: { ocId, memberId },
  callback
});

export const deleteMemberSuccess = (ocId, data) => ({
  type: DELETE_MEMBERS_SUCCESS,
  payload: { ocId, data }
});

export const deleteMemberFailed = (error) => ({
  type: DELETE_MEMBERS_FAILED,
  error
});

export const updateMember = (ocId, memberId, formData, callback) => ({
  type: UPDATE_MEMBERS,
  payload: { ocId, memberId, formData },
  callback
});

export const updateMemberSuccess = (ocId, data) => ({
  type: UPDATE_MEMBERS_SUCCESS,
  payload: { ocId, data }
});

export const updateMemberFailed = (error) => ({
  type: UPDATE_MEMBERS_FAILED,
  error
});

export const downloadMemberDetailsByExls = (ocId, callback) => {
  return {
    type: DOWNLOAD_MEMBER_EXLS,
    payload: { ocId },
    callback
  };
};

export const downloadMemberDetailsByExlsSuccess = (data, included) => {
  return {
    type: DOWNLOAD_MEMBER_EXLS_SUCCESS,
    payload: { data, included }
  };
};

export const downloadMemberDetailsByExlsFailed = (error) => {
  return {
    type: DOWNLOAD_MEMBER_EXLS_FAILED,
    error
  };
};

export const downloadQrCode = (ocId, callback) => {
  return {
    type: DOWNLOAD_QR_CODE,
    payload: { ocId },
    callback
  };
};

export const downloadQrCodeSuccess = (data, included) => {
  return {
    type: DOWNLOAD_QR_CODE_SUCCESS,
    payload: { data, included }
  };
};

export const downloadQrCodeFailed = (error) => {
  return {
    type: DOWNLOAD_QR_CODE_FAILED,
    error
  };
};
