import isEqual from 'lodash/isEqual';
import { wrap } from 'object-path-immutable';
import { ENTITY_DELETE_REQUEST_SUCCESS } from '../constants/actionTypes';

const defaultEntity = {};

const setRecord = (state, imm, row) => {
  if (!row.id) return;

  const record = { ...row.attributes, id: row.id };
  const { relationships } = row;

  if (relationships) {
    Object.keys(relationships).forEach((relation) => {
      record[relation] = relationships[relation].data;
    });
  }

  if (!state[row.type] || !isEqual(state[row.type][row.id], record)) {
    imm.set([row.type, row.id], record);
  }
};

const entity = (state = defaultEntity, action) => {
  if (!action.error && action.payload) {
    const imm = wrap(state);

    if (action.payload.data) {
      let rows = action.payload.data;
      if (action.type === ENTITY_DELETE_REQUEST_SUCCESS) {
        imm.del([rows.type, rows.id]);
        return imm.value();
      }
      if (!Array.isArray(rows)) rows = [rows];

      rows.forEach((row) => setRecord(state, imm, row));
    }

    if (action.payload.included) {
      let rows = action.payload.included;

      if (!Array.isArray(rows)) rows = [rows];

      rows.forEach((row) => setRecord(state, imm, row));
    }

    return imm.value();
  }
  return state;
};

export default entity;
