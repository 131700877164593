import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import { occasionAwardCreate, updateAward } from '../../../../../actions/award';
import { currentAwardData } from '../../../../../selectors/award';
import { getImageSource } from '../../../../../helpers/imageHelper';

const validateAward = (values) => {
  const error = {};
  if (!values.name) {
    error.name = 'Name is required';
  }
  if (!values.awardee) {
    error.awardee = 'Awardee  is required';
  }
  return error;
};

function CreateAward(props) {
  const [awardData, setAwardData] = React.useState({
    name: props.currentAwardData?.awardName || '',
    awardee: props.currentAwardData?.awardee || '',
    description: props.currentAwardData?.description || '',
    category: props.currentAwardData?.category || '',
    avatar: props.currentAwardData?.awardImageUrl
      ? [{ url: getImageSource(props.currentAwardData?.awardImageUrl) }]
      : null
  });
  const [errorMessages, setErrorMessages] = React.useState({});

  const handleSubmit = () => {
    try {
      const validateObj = validateAward(awardData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        if (awardData.avatar && awardData.avatar[0]?.originFileObj) {
          formData.append(
            'award[award_image]',
            awardData.avatar[0].originFileObj
          );
        }
        formData.append('award[award_name]', awardData.name);
        formData.append('award[awardee]', awardData.awardee);
        formData.append('award[category]', awardData.category);
        formData.append('award[description]', awardData.description);

        props.awardId
          ? props.updateAward(props.occasionId, props.awardId, formData)
          : props.occasionAwardCreate(props.occasionId, formData, () => {
              setAwardData({});
            });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      if (type === 'avatar') {
        value = e.fileList;
        name = type;
      }
      const awardValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setAwardData({
        ...awardData,
        [inputName]: awardValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="award-create-section">
        <div className="award-create-form">
          <Form className="award-form" onFinish={handleSubmit}>
            <InputGroup
              name="avatar"
              fieldType="file"
              label="Award Image"
              accept="image/*"
              fileList={awardData?.avatar}
              onChange={handleInputChange}
            />
            <InputGroup
              label="Award Name"
              name="name"
              placeholder="Enter Award Name"
              onChange={handleInputChange}
              value={awardData?.name}
              errorMessage={errorMessages.name}
            />
            <InputGroup
              label="Awardee"
              name="awardee"
              placeholder="Enter Awardee Name"
              onChange={handleInputChange}
              value={awardData?.awardee}
              errorMessage={errorMessages.awardee}
            />
            <InputGroup
              label="Award Category"
              name="category"
              placeholder="Enter Award Category"
              onChange={handleInputChange}
              value={awardData?.category}
            />
            <InputGroup
              label="Description"
              name="description"
              placeholder="Enter Description"
              onChange={handleInputChange}
              value={awardData?.description}
            />
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.awardId ? 'Save Changes' : 'Create Award'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentAwardData: currentAwardData(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ occasionAwardCreate, updateAward }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateAward);
