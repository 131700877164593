import { createSelector } from 'reselect';
import { currentOccasionId } from './dynamicData';
import { occasionLiveVideoEntities } from './entity';
import { occasionLiveVideoIds } from './static';

export const currentOccasionLiveVideoIds = createSelector(
  currentOccasionId,
  occasionLiveVideoIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);
export const getAllOccasionLivevideos = createSelector(
  occasionLiveVideoEntities,
  currentOccasionLiveVideoIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);
