import {
  OCCASION_DISCUSSION_VIEW,
  OCCASION_DISCUSSION_VIEW_SUCCESS,
  OCCASION_DISCUSSION_VIEW_FAILED,
  OCCASION_DISCUSSION_DELETE,
  OCCASION_DISCUSSION_DELETE_FAILED,
  OCCASION_DISCUSSION_DELETE_SUCCESS
} from '../constants/actionTypes';

export const occasionDiscussionView = (ocId, page) => ({
  type: OCCASION_DISCUSSION_VIEW,
  payload: { ocId, page }
});

export const occasionDiscussionViewSuccess = (
  ocId,
  data,
  included,
  links,
  meta
) => ({
  type: OCCASION_DISCUSSION_VIEW_SUCCESS,
  payload: { ocId, data, included, links, meta }
});

export const occasionDiscussionViewFailed = (error) => ({
  type: OCCASION_DISCUSSION_VIEW_FAILED,
  error
});

export const occasionDiscussionDelete = (ocId, discussionId) => ({
  type: OCCASION_DISCUSSION_DELETE,
  payload: { ocId, discussionId }
});

export const occasionDiscussionDeleteSuccess = (
  ocId,
  data,
  included,
  links
) => ({
  type: OCCASION_DISCUSSION_DELETE_SUCCESS,
  payload: { ocId, data, included, links }
});

export const occasionDiscussionDeleteFailed = (error) => ({
  type: OCCASION_DISCUSSION_DELETE_FAILED,
  error
});
