import {
  GET_OCCASION_APP_DETAILS,
  GET_OCCASION_APP_DETAILS_FAILED,
  GET_OCCASION_APP_DETAILS_SUCCESS,
  OCCASION_APP_CREATE,
  OCCASION_APP_CREATE_FAILED,
  OCCASION_APP_CREATE_SUCCESS,
  UPDATE_OCCASION_APP,
  UPDATE_OCCASION_APP_FAILED,
  UPDATE_OCCASION_APP_SUCCESS
} from '../constants/actionTypes';

export const getOccasionAppDetails = (ocId) => ({
  type: GET_OCCASION_APP_DETAILS,
  payload: { ocId }
});

export const getOccasionAppDetailsSuccess = (data, ocId, included, links) => ({
  type: GET_OCCASION_APP_DETAILS_SUCCESS,
  payload: { data, ocId, included, links }
});

export const getOccasionAppDetailsFailed = (error) => ({
  type: GET_OCCASION_APP_DETAILS_FAILED,
  error
});

export const occasionAppCreate = (ocId, formData, callback) => ({
  type: OCCASION_APP_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionAppCreateSuccess = (data, ocId, included, links) => ({
  type: OCCASION_APP_CREATE_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionAppCreateFailed = (error) => ({
  type: OCCASION_APP_CREATE_FAILED,
  error
});

export const updateOccasionApp = (ocId, formData, callback) => ({
  type: UPDATE_OCCASION_APP,
  payload: { ocId, formData },
  callback
});

export const updateOccasionAppSuccess = (ocId, data, included) => ({
  type: UPDATE_OCCASION_APP_SUCCESS,
  payload: { ocId, data, included }
});

export const updateOccasionAppFailed = (error) => ({
  type: UPDATE_OCCASION_APP_FAILED,
  error
});
