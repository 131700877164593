import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import {
  getHospitalityTaskPublicAnswer,
  deleteHospitalityTaskPublicAnswer
} from '../../../../../../actions/publicAnswer';
import { currentHospitalityTaskWithTaskOptionsData } from '../../../../../../selectors/hospitalityTask';
import {
  getAllHospitalityPublicAnswerDataWithUsers,
  getAllHospitalityPublicAnswerLinks
} from '../../../../../../selectors/publicAnswer';
import { Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import AddPublicAnswer from './AddPublicAnswer';

const PublicAnswer = (props) => {
  const [page, setPage] = useState(1);
  const [viewPublicAnswer, setPublicAnswer] = useState(false);
  const [answerId, setAnswerId] = useState(null);
  const onPageChange = (page) => {
    setPage(page);
    props.getHospitalityTaskPublicAnswer(
      props.occasionId,
      props.hospitalityTaskId,
      page
    );
  };

  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const togglePublicAnswer = () => {
    setPublicAnswer((val) => !val);
    if (viewPublicAnswer) {
      setAnswerId(null);
    }
  };

  const paginationOptions = {
    showTotal: () => {
      return (
        <div className="button-box">
          <Button className="refresh-button" onClick={() => onPageChange(page)}>
            <SyncOutlined />
          </Button>
          <Button className="download-button" onClick={togglePublicAnswer}>
            Create
          </Button>
        </div>
      );
    }
  };

  if (viewPublicAnswer) {
    return (
      <ErrorBoundary>
        <div className="edit-container">
          <AddPublicAnswer
            occasionId={props.occasionId}
            hospitalityTaskId={props.hospitalityTaskId}
            hospitalityTaskDetails={props.currentTask}
            publicAnswerId={answerId}
            handleCloseClick={togglePublicAnswer}
          />
        </div>
      </ErrorBoundary>
    );
  }

  const deleteData = (id) => {
    props.deleteHospitalityTaskPublicAnswer(
      props.occasionId,
      props.hospitalityTaskId,
      id
    );
  };

  const editData = (id) => {
    togglePublicAnswer();
    setAnswerId(id);
  };

  return (
    <ErrorBoundary>
      <SectionContent className="task-request-view-section">
        <div className="task-request-view-container">
          <TableView
            isPaginated={true}
            isRouted={false}
            paginationOptions={paginationOptions}
            onPageChange={onPageChange}
            fetchProgress={props.fetchProgress}
            data={props.publicAnswer}
            paginationLinks={props.publicLinks}
            onDelete={deleteData}
            isEditable={editData}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentTask: currentHospitalityTaskWithTaskOptionsData(store, props),
  publicAnswer: getAllHospitalityPublicAnswerDataWithUsers(store, props),
  publicLinks: getAllHospitalityPublicAnswerLinks(store, props)
});

const mapDispatchProps = (dispatch) => {
  return bindActionCreators(
    { getHospitalityTaskPublicAnswer, deleteHospitalityTaskPublicAnswer },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchProps)(PublicAnswer);
