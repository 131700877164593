import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../components/Section';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import InputGroup from '../../../../components/InputGroup';
import { SubmitButton } from '../../../../components/InputGroup/FormComponents';
import { qrCodeCreate } from '../../../../actions/user';

function QrCodeCreateModal(props) {
  const [qrCodeData, setQrCodeData] = React.useState({
    color: '',
    bgColor: ''
  });

  const handleSubmit = () => {
    try {
      props.qrCodeCreate(
        props.userId,
        qrCodeData.color,
        qrCodeData.bgColor,
        () => {
          setQrCodeData({});
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;

      const qrCodeValue = value || e?.target?.value;
      const inputName = name || e?.target?.name;

      setQrCodeData({
        ...qrCodeData,
        [inputName]: qrCodeValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="qr-code-create-section">
        <div className="qr-code-create-form">
          <Form className="qr-code-form" onFinish={handleSubmit}>
            <InputGroup
              label="Color"
              name="color"
              placeholder="Enter Color"
              onChange={handleInputChange}
              value={qrCodeData?.color}
            />

            <InputGroup
              label="Bg Color"
              name="bgColor"
              placeholder="Enter Bg Color"
              onChange={handleInputChange}
              value={qrCodeData?.bgColor}
            />
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={'Create Qr Code'}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ qrCodeCreate }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QrCodeCreateModal);
