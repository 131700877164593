import { createSelector } from 'reselect';
import { divisionEntities } from './entity';
import { divisionIds } from './static';
import { currentOccasionId, currentDivisionId } from './dynamicData';

export const currentDivisionIds = createSelector(
  currentOccasionId,
  divisionIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getAllDivisionData = createSelector(
  divisionEntities,
  currentDivisionIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getCurrentDivision = createSelector(
  currentDivisionId,
  divisionEntities,
  (ocId, entities) => ocId && entities && entities[ocId]
);
