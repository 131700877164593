import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import TableView from '../../../../../components/Table';
import { occasionEventView, deleteEvent } from '../../../../../actions/event';
import { getAllOccasionEvents } from '../../../../../selectors/event';
import EventSearch from './EventSearch';
import CreateOccasionEvent from './EventCreate';
import { Button } from 'antd';

const ViewOccasionEvent = (props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.occasionEventView(props.occasionId, page);
  };

  const location = useLocation();
  React.useEffect(() => {
    onPageChange(1);
  }, []);
  const paginationOptions = {
    showTotal: () => (
      <div className="event-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <Button className="create-button" onClick={() => setCreateOpen(true)}>
          Create
        </Button>
      </div>
    )
  };

  const deleteData = (id) => {
    props.deleteEvent(props.occasionId, id);
  };

  const showDelete = (id) => {
    let showDelete = true;
    let data = props.events?.find((el) => el.id === id);
    if (data.tag === 'Special') {
      showDelete = false;
    }

    return showDelete;
  };

  if (!createOpen) {
    return (
      <ErrorBoundary>
        <SectionContent className="event-view-section">
          <div className="event-view-container">
            <EventSearch
              startDate={props.currentOccasion.startAt}
              endDate={props.currentOccasion.endAt}
              ocId={props.occasionId}
            />
            <TableView
              isPaginated={true}
              paginationOptions={paginationOptions}
              pathname={`${location.pathname}/event/view`}
              onPageChange={onPageChange}
              onDelete={deleteData}
              data={props.events}
              fetchProgress={props.fetchProgress}
              paginationLinks={props.eventLinks}
              normalTime={true}
            />
          </div>
        </SectionContent>
      </ErrorBoundary>
    );
  }
  return (
    <div className="edit-container">
      <CreateOccasionEvent
        occasionId={props.occasionId}
        modelGroupEventKey={props.modelGroupEventKey}
        handleCloseClick={() => setCreateOpen(false)}
      />
    </div>
  );
};
const mapStateToProps = (store, props) => ({
  events: getAllOccasionEvents(store, props),
  fetchProgress: store.browserState.fetchProgress,
  eventLinks: store.event.occasionEventLinks
  // currentEventData: getCurrentEvent(store, props)
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ occasionEventView, deleteEvent }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(ViewOccasionEvent);
