export const union = (oldIds = [], newIds = []) => {
  const set = new Set([...oldIds, ...newIds]);
  return Array.from(set);
};

export const validateEmail = (emailId) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(emailId);
};

export const validateMobile = (phoneNo) => {
  const re = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
  return re.test(phoneNo);
};

export const validateEmailOrMobile = (emailOrMobile) =>
  validateEmail(emailOrMobile) || validateMobile(emailOrMobile);

export const concatArrayData = (array, separator) => {
  let data = '';
  array?.forEach((el, index) => {
    if (index !== array.length - 1) {
      data += el + separator;
    } else {
      data += el;
    }
  });
  return data;
};

export const isObject = (item) => typeof item === 'object' && item !== null;

export const logError = ({ message, error, errorInfo }) => {
  if (message) {
    console.log(message);
  }
  if (error) {
    console.log(
      'Error details: ',
      isObject(error) ? JSON.stringify(error, null, 2) : error
    );
  }
  if (errorInfo) {
    console.log(
      'Error info: ',
      isObject(errorInfo) ? JSON.stringify(errorInfo, null, 2) : errorInfo
    );
  }

  if (process.env.NODE_ENV === 'production') {
    Sentry.captureException(error, { extra: errorInfo });
  }
};
