import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import { occasionSmsCreate } from '../../../../../../actions/notificationSms';

const validateSmsData = (values) => {
  const errors = {};
  if (!values.sms) {
    errors.sms = 'Message is required';
  }
  return errors;
};

const SmsCreate = (props) => {
  const [smsData, setSmsData] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const handleSubmit = () => {
    try {
      const validateObj = validateSmsData(smsData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('sms_saver[message]', smsData.sms);
        props.occasionSmsCreate(props.occasionId, formData);
        setSmsData({});
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let formValue;
      let inputName;
      formValue = value || e?.target?.value;
      inputName = name || e?.target?.name;

      setSmsData({
        ...smsData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ErrorBoundary>
      <SectionContent className="occasion-sms-create-section">
        <div className="occasion-sms-create-form">
          <Form
            className="occassion-sms-form-container"
            onFinish={handleSubmit}>
            <InputGroup
              label="Message"
              name="sms"
              fieldType="textArea"
              placeholder="Enter Message"
              onChange={handleInputChange}
              value={smsData?.sms}
              errorMessage={errorMessages?.sms}
            />
            <SubmitButton
              buttonText="Create"
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ occasionSmsCreate }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SmsCreate);
