import { getMomentTime } from '../../../../helpers/dateTimeHelper';

const validate = (values, ignorePassword) => {
  const errors = {};
  if (!values.first_name) errors.first_name = 'First Name is required';
  if (!values.email) {
    errors.email = 'Email is required';
  } else if (values.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
      errors.email = 'Invalid Email';
  }

  if (!ignorePassword && !values.password)
    errors.password = 'Password is required';
  else if (!ignorePassword && values.password.length < 6)
    errors.password = 'Minimum 6 characters long';

  if (!values.dob) {
    errors.dob = 'Please enter date of birth';
  } else if (getMomentTime(values.dob) > new Date().getTime()) {
    errors.dob = 'Invalid Date Of Birth';
  }

  if (!values.gender) errors.gender = 'Gender is required';

  return errors;
};

export default validate;
