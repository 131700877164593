import { createSelector } from 'reselect';
import { currentOccasionId, currentHospitalityCategoryId } from './dynamicData';
import {
  hospitalityCategoryEntities,
  contactUsEntities,
  userEntities
} from './entity';
import { hospitalityCategoryIds, contactUsIds } from './static';

export const currentHospitalityCategoryIds = createSelector(
  currentOccasionId,
  hospitalityCategoryIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getAllHospitalityCategoryData = createSelector(
  hospitalityCategoryEntities,
  currentHospitalityCategoryIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getCurrentHospitality = createSelector(
  currentHospitalityCategoryId,
  hospitalityCategoryEntities,
  (id, entities) => id && entities && entities[id]
);

export const currentContactUsIds = createSelector(
  currentOccasionId,
  contactUsIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getAllContactUsData = createSelector(
  contactUsEntities,
  currentContactUsIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getAllContactUsWithUserData = createSelector(
  getAllContactUsData,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      user: { ...entities[item?.user?.id] }
    }))
);
