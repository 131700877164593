import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';

import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import { ACTIVITY_TRACKER_ABSENT_USER_VIEW } from '../constants/actionTypes';
import { activityTrackerAbsentUserViewApi } from '../api/activityTrackerAbsentUser';
import {
  activityTrackerAbsentUserViewFailed,
  activityTrackerAbsentUserViewSuccess
} from '../actions/activityTrackerAbsentUser';

export function* activityTrackerAbsentUserViewSaga(action) {
  try {
    const result = yield call(
      activityTrackerAbsentUserViewApi,
      action.payload.ocId,
      action.payload.trackerId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        activityTrackerAbsentUserViewSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          action.payload.trackerId,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(activityTrackerAbsentUserViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* activityTrackerAbsentUserActionWatcher() {
  yield takeLatest(
    ACTIVITY_TRACKER_ABSENT_USER_VIEW,
    progressSaga,
    activityTrackerAbsentUserViewSaga
  );
}
