import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import { getContactUs } from '../../../../../../actions/hospitality';
import { getAllContactUsWithUserData } from '../../../../../../selectors/hospitality';

const ContactUsView = (props) => {
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.getContactUs(props.occasionId, page);
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const paginationOptions = {
    showTotal: () => (
      <div className="contact-us-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
      </div>
    )
  };

  return (
    <ErrorBoundary>
      <SectionContent className="hospitality-contactus-view-section">
        <div className="hospitality-contactus-view-container">
          <TableView
            isPaginated={true}
            paginationOptions={paginationOptions}
            isRouted={false}
            onPageChange={onPageChange}
            data={props.contactUsData}
            fetchProgress={props.fetchProgress}
            paginationLinks={props.contactUsLinks}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  contactUsData: getAllContactUsWithUserData(store, props),
  contactUsLinks: store.hospitality.contactUsLinks
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ getContactUs }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(ContactUsView);
