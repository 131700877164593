import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Button } from 'antd';
import './styles.scss';
import { CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import { ENTER_AS } from './CodeConstant';
import { codeCreate, updateCode } from '../../../../../actions/occasionCode';
import { currentOccasionCodeData } from '../../../../../selectors/occasionCode';

const validateRoleNameData = (values) => {
  const errors = {};
  if (!values.code) {
    errors.code = 'Code is required';
  }
  if (!values.type) {
    errors.type = 'Type is required';
  }
  return errors;
};

const OccasionCodeCreate = (props) => {
  const [codeData, setCodeData] = useState({
    code: props.currentOccasionCodeData?.code || '',
    type: props.currentOccasionCodeData?.enterAs || '',
    status: props.currentOccasionCodeData?.status === 'active' ? true : false
  });
  const [errorMessages, setErrorMessages] = useState({});
  const handleSubmit = () => {
    try {
      const validateObj = validateRoleNameData(codeData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('occasion_code[code]', codeData?.code);
        formData.append('occasion_code[enter_as]', codeData?.type);
        formData.append(
          'occasion_code[status]',
          codeData?.status === true ? 'active' : 'in_active'
        );

        props.occasionCodeId
          ? props.updateCode(props.occasionId, props.occasionCodeId, formData)
          : props.codeCreate(props.occasionId, formData, () => {
              setCodeData({});
            });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let formValue;
      let inputName;

      if (type === 'status') {
        value = e;
        name = type;
      }

      formValue = value || e?.target?.value;
      inputName = name || e?.target?.name;

      setCodeData({
        ...codeData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="occasion-code-create-section">
        <div className="occasion-code-create-form">
          <Form
            className="occassion-code-form-container"
            onFinish={handleSubmit}>
            <InputGroup
              name="code"
              placeholder=" Enter Code"
              label="Occasion Code"
              value={codeData?.code}
              onChange={handleInputChange}
              errorMessage={errorMessages.code}
            />
            <InputGroup
              label="Guest Type"
              className="code-inputbox"
              fieldType="dropdown"
              placeholder="Select"
              options={ENTER_AS}
              name="type"
              onChange={handleInputChange}
              value={codeData?.type}
              errorMessage={errorMessages.type}
            />
            <InputGroup
              label="Status"
              defaultChecked={codeData?.status}
              name="status"
              onChange={handleInputChange}
              fieldType="switch"
            />

            <SubmitButton
              buttonText={props.occasionCodeId ? 'Save Changes' : 'Create'}
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  currentOccasionCodeData: currentOccasionCodeData(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ codeCreate, updateCode }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OccasionCodeCreate);
