import { put, call, takeLatest, debounce } from 'redux-saga/effects';
import progressSaga from './progressSaga';
import {
  getUserWithPagination,
  editUserApi,
  getUserBySearchApi,
  searchUsersApi,
  getUserByOccasionApi,
  qrCodeCreateApi,
  qrCodeDeleteApi
} from '../api/user';
import {
  GET_USER_PAGINATION,
  EDIT_USER,
  GET_USER_BY_SEARCH,
  SEARCH_USERS,
  GET_USER_BY_OCCASION,
  QR_CODE_CREATE,
  QR_CODE_DELETE
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { entityDeleteRequestSuccess } from '../actions/entity';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import { getOccasionTypeName } from '../helpers/occasionHelper';
import {
  getUserWithPaginationSuccess,
  getUserWithPaginationFailed,
  editUserSuccess,
  editUserFailed,
  getUserBySearchSuccess,
  getUserBySearchFailed,
  searchUsersSuccess,
  searchUsersFailed,
  getUserByOccasionSuccess,
  getUserByOccasionFailed,
  qrCodeCreateSuccess,
  qrCodeCreateFailed,
  qrCodeDeleteSuccess,
  qrCodeDeleteFailed
} from '../actions/user';

export function* getUsersSagaWithPagination(action) {
  try {
    const result = yield call(
      getUserWithPagination,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getUserWithPaginationSuccess(result.data, result.links, result?.meta)
      );

      if (action.callback) {
        action.callback();
      }
    } else if (result?.meta && result?.meta?.message) {
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(getUserWithPaginationFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* editUserSaga(action) {
  try {
    const result = yield call(
      editUserApi,
      action.payload.formData,
      action.payload.userId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(editUserSuccess(result.data));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(editUserFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Some error occured', 'Error', 4000));
    }
  } catch (error) {
    yield put(editUserFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getUserBySearchSaga(action) {
  try {
    const result = yield call(
      getUserBySearchApi,
      action.payload.searchData,
      action.payload.fromInternalTeam,
      action.payload.status,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getUserBySearchSuccess(
          result.data,
          result.included,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getUserBySearchFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* searchUsersSaga(action) {
  try {
    const result = yield call(
      searchUsersApi,
      action.payload.ocId,
      action.payload.query,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(searchUsersSuccess(result.data, result.included, result.links));
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(searchUsersFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* getUserByOccasionSaga(action) {
  try {
    const result = yield call(
      getUserByOccasionApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getUserByOccasionSuccess(
          result.data,
          result.included,
          result.links,
          action.payload.page
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getUserByOccasionFailed(error));

    if (error && error.meta) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error'));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error'));
    }
  }
}

export function* qrCodeCreateSaga(action) {
  try {
    const result = yield call(
      qrCodeCreateApi,
      action.payload.userId,
      action.payload.color,
      action.payload.bgColor,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(qrCodeCreateSuccess(result.data, result.included));

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(qrCodeCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(qrCodeCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* qrCodeDeleteSaga(action) {
  try {
    const result = yield call(
      qrCodeDeleteApi,
      action.payload.qrCodeId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(qrCodeDeleteSuccess(result.data));
      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }

      if (typeof action.callback === 'function') {
        action.callback();
      }
    }
  } catch (error) {
    yield put(qrCodeDeleteFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* userActionWatcher() {
  yield takeLatest(
    GET_USER_PAGINATION,
    progressSaga,
    getUsersSagaWithPagination
  );
  yield takeLatest(EDIT_USER, progressSaga, editUserSaga);
  yield takeLatest(GET_USER_BY_SEARCH, progressSaga, getUserBySearchSaga);
  yield takeLatest(GET_USER_BY_OCCASION, progressSaga, getUserByOccasionSaga);
  yield debounce(1000, SEARCH_USERS, progressSaga, searchUsersSaga);
  yield takeLatest(QR_CODE_CREATE, progressSaga, qrCodeCreateSaga);
  yield takeLatest(QR_CODE_DELETE, progressSaga, qrCodeDeleteSaga);
}
