import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Form } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import moment from 'moment';
import { SectionContent } from '../../../../components/Section';
import {
  GENDERS,
  ADMIN_ACCESS_TYPES
} from '../../../../constants/OtherConstants';
import validateAdmin from './validate';
import { createAdmin, updateAdmin } from '../../../../actions/admin';
import { currentAdminId } from '../../../../selectors/dynamicData';
import { getCurrentAdmin } from '../../../../selectors/admin';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import InputGroup from '../../../../components/InputGroup';
import { SubmitButton } from '../../../../components/InputGroup/FormComponents';
import { renderRoute } from '../../../../components/RouterHOC';
import { adminCreateRouteAccess } from '../../../../selectors/auth';
import { getImageSource } from '../../../../helpers/imageHelper';

function AdminCreate(props) {
  const [adminFormState, setAdminFormState] = React.useState({
    ...props.initialValues
  });
  const [errorMessages, setErrorMessages] = React.useState({});
  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);
  const handleAdminCreate = () => {
    try {
      const validateObj = validateAdmin(adminFormState, props.adminId);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const data = new FormData();
        data.append(
          !!props.adminId ? 'sociana_admin[name]' : 'sign_up[name]',
          adminFormState.name
        );
        data.append(
          !!props.adminId ? 'sociana_admin[phone]' : 'sign_up[phone]',
          adminFormState.phone
        );
        data.append(
          !!props.adminId ? 'sociana_admin[email]' : 'sign_up[email]',
          adminFormState.email
        );
        if (adminFormState.dob) {
          data.append(
            !!props.adminId ? 'sociana_admin[dob]' : 'sign_up[dob]',
            adminFormState.dob.format('DD/MM/YYYY')
          );
        }

        if (adminFormState.validTill) {
          data.append(
            !!props.adminId
              ? 'sociana_admin[valid_till]'
              : 'sign_up[valid_till]',
            adminFormState.validTill.format('DD/MM/YYYY')
          );
        }

        data.append(
          !!props.adminId ? 'sociana_admin[gender]' : 'sign_up[gender]',
          adminFormState.gender
        );
        data.append(
          !!props.adminId
            ? 'sociana_admin[access_type]'
            : 'sign_up[access_type]',
          adminFormState.access_type
        );
        data.append(
          !!props.adminId
            ? 'sociana_admin[current_status]'
            : 'sign_up[current_status]',
          adminFormState?.current_status ? 'active' : 'inactive'
        );
        if (
          adminFormState?.card_image &&
          adminFormState?.card_image[0]?.originFileObj
        ) {
          data.append(
            !!props.adminId ? 'sociana_admin[avatar]' : 'sign_up[avatar]',
            adminFormState?.card_image[0].originFileObj
          );
        }
        data.append('sign_up[password]', adminFormState.password);

        !!props.adminId
          ? props.updateAdmin(props.adminId, data, () => {
              setInitiallyDisabled(true);
            })
          : props.createAdmin(data, () => {
              setAdminFormState({});
              setInitiallyDisabled(true);
            });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let adminFormValue;
      let inputName;
      if (isInitiallyDisabled) {
        setInitiallyDisabled(false);
      }
      if (type === 'card_image') {
        value = e.fileList;
        name = type;
      }
      if (type === 'current_status') {
        value = e;
        name = type;
      }
      if (type === 'dob') {
        value = moment(e, 'DD/MM/YYYY');
        name = type;
      }
      if (type === 'validTill') {
        value = moment(e, 'DD/MM/YYYY');
        name = type;
      }
      adminFormValue = value || e?.target?.value;
      inputName = name || e?.target?.name;

      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }

      setAdminFormState({
        ...adminFormState,
        [inputName]: adminFormValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="admin-create-section">
        <div className="admin-create-form">
          <Form className="admin-form" onFinish={handleAdminCreate}>
            <InputGroup
              name="name"
              label="Name"
              placeholder="Enter Name"
              onChange={handleInputChange}
              errorMessage={errorMessages.name}
              value={adminFormState?.name}
            />
            <InputGroup
              name="phone"
              label="Phone Number"
              placeholder="Enter Phone Number"
              onChange={handleInputChange}
              type="tel"
              errorMessage={errorMessages.phone}
              value={adminFormState?.phone}
            />
            <InputGroup
              name="email"
              label="Email ID"
              placeholder="Enter Email ID"
              onChange={handleInputChange}
              type="email"
              errorMessage={errorMessages.email}
              value={adminFormState?.email}
            />
            <InputGroup
              fieldType="datePicker"
              onChange={handleInputChange}
              label="Date of Birth"
              name="dob"
              value={adminFormState?.dob}
              errorMessage={errorMessages.dob}
            />
            <InputGroup
              fieldType="datePicker"
              onChange={handleInputChange}
              label="Valid Till"
              name="validTill"
              value={adminFormState?.validTill}
            />
            <InputGroup
              fieldType="radioGroup"
              options={GENDERS}
              name="gender"
              label="Gender"
              onChange={handleInputChange}
              value={adminFormState?.gender}
              errorMessage={errorMessages.gender}
            />
            <InputGroup
              fieldType="radioGroup"
              options={ADMIN_ACCESS_TYPES}
              name="access_type"
              label="User Access Type"
              onChange={handleInputChange}
              value={adminFormState?.access_type}
              errorMessage={errorMessages.access_type}
            />
            <InputGroup
              label="Current status"
              defaultChecked={adminFormState?.current_status}
              name="current_status"
              onChange={handleInputChange}
              fieldType="switch"
              errorMessage={errorMessages.current_status}
            />
            {!props.adminId ? (
              <InputGroup
                label="Password"
                name="password"
                type="password"
                placeholder="Enter password"
                onChange={handleInputChange}
                value={adminFormState?.password}
                errorMessage={errorMessages.password}
              />
            ) : (
              ''
            )}
            <InputGroup
              fieldType="file"
              label="Profile Picture"
              accept="image/*"
              fileList={adminFormState?.card_image}
              onChange={handleInputChange}
              name="card_image"
              errorMessage={errorMessages.card_image}
            />
            <SubmitButton
              disabled={props.fetchProgress}
              fetchProgress={props.fetchProgress}
              buttonText={props.adminId ? 'Save Changes' : 'Create Admin'}
            />
          </Form>
          {!!props.adminId && (
            <Button
              onClick={props.handleCloseClick}
              className="edit-close-button">
              <CloseOutlined />
            </Button>
          )}
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => {
  const adminId = currentAdminId(store, props);
  const currentAdmin = getCurrentAdmin(store, props);
  const initialValues = {
    name: currentAdmin?.name || '',
    phone: currentAdmin?.phone || '',
    email: currentAdmin?.email || '',
    validTill:
      currentAdmin?.validTill && moment(currentAdmin?.validTill, 'DD/MM/YYYY'),
    dob: currentAdmin?.dob && moment(currentAdmin?.dob, 'DD/MM/YYYY'),
    current_status: currentAdmin?.currentStatus === 'active' ? true : false,
    gender: currentAdmin?.gender || '',
    access_type: currentAdmin?.accessType || '',
    password: '',
    card_image: !!currentAdmin?.avatarUrl
      ? [{ url: getImageSource(currentAdmin?.avatarUrl) }]
      : null
  };
  return {
    adminId,
    currentAdmin,
    initialValues,
    fetchProgress: store.browserState.fetchProgress
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createAdmin,
      updateAdmin
    },
    dispatch
  );

export default renderRoute(adminCreateRouteAccess)(
  connect(mapStateToProps, mapDispatchToProps)(AdminCreate)
);
