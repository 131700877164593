import { union } from 'lodash';
import {
  COMPETITION_CREATE_FAILED,
  COMPETITION_CREATE_SUCCESS,
  COMPETITION_VIEW_FAILED,
  COMPETITION_VIEW_SUCCESS,
  DELETE_COMPETITION_FAILED,
  DELETE_COMPETITION_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  competitionData: null,
  competitionLinks: null,
  error: null
};

const Competition = (state = initialState, action) => {
  switch (action.type) {
    case COMPETITION_VIEW_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.competitionData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        competitionData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        competitionLinks: links
      };
    }

    case COMPETITION_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.competitionData || {};
      const currentCompetitionIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentCompetitionIds];
      return {
        ...state,
        competitionData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }

    case DELETE_COMPETITION_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.competitionData || {};
      const currentCompetitionIds = occasionGroupedData[ocId] || [];
      const filteredIds = currentCompetitionIds.filter((id) => id !== data.id);

      return {
        ...state,
        competitionData: {
          ...occasionGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case COMPETITION_VIEW_FAILED:
    case COMPETITION_CREATE_FAILED:
    case DELETE_COMPETITION_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default Competition;
