import request from './request';

export function multipleInvitationsApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/send_multiple_invitation`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function parse_csvApi(ocId, formData, token) {
  return request(`api/v2/sociana_admin/oc/${ocId}/parse_csv`, {
    method: 'POST',
    body: formData,
    formData: true,
    headers: { 'Content-Type': 'multipart/form-data' },
    token
  });
}

export function getOccasionPendingInvitationApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/pending_invitation_lists?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function getOccasionPendingRegistrationApi(ocId, page, token) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/pending_registration_lists?page=${page}`,
    {
      method: 'GET',
      token
    }
  );
}

export function pendingInvitationSearchApi(
  ocId,
  joinas,
  status,
  invitedby,
  user,
  page,
  token
) {
  let query = `page=${page}`;
  if (!!joinas) {
    query += `&join_as=${joinas}`;
  }
  if (!!status) {
    query += `&status=${status}`;
  }
  if (!!invitedby) {
    query += `&invited_by_id=${invitedby}`;
  }
  if (!!user) {
    query += `&user_id=${user}`;
  }
  return request(
    `api/v2/sociana_admin/oc/${ocId}/search_pending_invitation?${query}`,
    {
      method: 'GET',
      token
    }
  );
}

export function pendingRegistrationSearchApi(
  ocId,
  text,
  joinas,
  invitedby,
  page,
  token
) {
  let query = `page=${page}`;
  if (!!text) {
    query += `&email_or_mobile=${text}`;
  }
  if (!!joinas) {
    query += `&join_as=${joinas}`;
  }
  if (!!invitedby) {
    query += `&invited_by_id=${invitedby}`;
  }
  return request(
    `api/v2/sociana_admin/oc/${ocId}/search_pending_registration?${query}`,
    {
      method: 'GET',
      token
    }
  );
}

export function searchPendingInvitationApi(ocId, query, page, token) {
  let queryText = `page=${page}`;
  if (!!query) {
    queryText += `&q=${query}`;
  }
  return request(
    `api/v2/sociana_admin/oc/${ocId}/pending_invitations/search?${queryText}`,
    {
      method: 'GET',
      token
    }
  );
}

export function searchPendingRegistrationApi(ocId, query, page, token) {
  let queryText = `page=${page}`;
  if (!!query) {
    queryText += `&q=${query}`;
  }
  return request(
    `api/v2/sociana_admin/oc/${ocId}/pending_registrations/search?${queryText}`,
    {
      method: 'GET',
      token
    }
  );
}

export function resendPendingRegistrationApi(ocId, allOrId, token) {
  let queryText =
    typeof allOrId === 'boolean'
      ? `recipients=all`
      : `pending_registration_id=${allOrId}`;

  return request(
    `api/v2/sociana_admin/oc/${ocId}/pending_registrations/resend_invitation?${queryText}`,
    {
      method: 'POST',
      token
    }
  );
}

export function resendPendingInvitationApi(ocId, allOrId, token) {
  let queryText =
    typeof allOrId === 'boolean'
      ? `recipients=all`
      : `pending_invitation_id=${allOrId}`;

  return request(
    `api/v2/sociana_admin/oc/${ocId}/pending_invitations/resend_invitation?${queryText}`,
    {
      method: 'POST',
      token
    }
  );
}

export function deleteOccasionPendingInvitationApi(
  ocId,
  pendingInvitationId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/pending_invitations/${pendingInvitationId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function updatePendingInvitationApi(
  ocId,
  pendingInvitationId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/pending_invitations/${pendingInvitationId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}

export function deleteOccasionPendingRegistrationApi(
  ocId,
  pendingRegistartionId,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/pending_registrations/${pendingRegistartionId}`,
    {
      method: 'DELETE',
      token
    }
  );
}

export function updatePendingRegistrationApi(
  ocId,
  pendingRegistrationId,
  formData,
  token
) {
  return request(
    `api/v2/sociana_admin/oc/${ocId}/pending_registrations/${pendingRegistrationId}`,
    {
      method: 'PUT',
      body: formData,
      formData: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      token
    }
  );
}
