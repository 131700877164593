import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import TableView from '../../../../../../components/Table';
import { Button } from 'antd';
import { getAllActivityTrackerAbsentUsers } from '../../../../../../selectors/activityTrackerAbsentUser';
import { activityTrackerAbsentUserView } from '../../../../../../actions/activityTrackerAbsentUser';

const AbsentUserView = (props) => {
  const [page, setPage] = React.useState(1);
  const onPageChange = (page) => {
    setPage(page);
    props.activityTrackerAbsentUserView(
      props.occasionId,
      props.activityTrackerId,
      page
    );
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const paginationOptions = {
    showTotal: () => (
      <div className="absent-user-button-box">
        <Button className="refresh-button" onClick={() => onPageChange(page)}>
          <SyncOutlined />
        </Button>
        <div className="count-box">
          <span>Total User</span>
          <span className="count">{props.count}</span>
        </div>
      </div>
    )
  };

  return (
    <ErrorBoundary>
      <SectionContent className="absent-user-view-section">
        <div className="absent-user-view-container">
          <TableView
            isPaginated={true}
            paginationOptions={paginationOptions}
            isRouted={false}
            onPageChange={onPageChange}
            data={props.activityTrackerAbsentUserData}
            fetchProgress={props.fetchProgress}
            paginationLinks={props.activityTrackerAbsentUserLinks}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  activityTrackerAbsentUserData: getAllActivityTrackerAbsentUsers(store, props),
  activityTrackerAbsentUserLinks:
    store.activityTrackerAbsentUser.activityTrackerAbsentUserLinks,
  count: store.activityTrackerAbsentUser.absentUserCount
});
const mapDispatchProps = (dispatch) => {
  return bindActionCreators({ activityTrackerAbsentUserView }, dispatch);
};

export default connect(mapStateToProps, mapDispatchProps)(AbsentUserView);
