import {
  CREATE_SPEAKER,
  CREATE_SPEAKER_SUCCESS,
  CREATE_SPEAKER_FAILED,
  GET_SPEAKER_PAGINATION,
  GET_SPEAKER_PAGINATION_SUCCESS,
  GET_SPEAKER_PAGINATION_FAILED,
  EDIT_SPEAKER,
  EDIT_SPEAKER_SUCCESS,
  EDIT_SPEAKER_FAILED,
  DELETE_SPEAKER,
  DELETE_SPEAKER_SUCCESS,
  DELETE_SPEAKER_FAILED
} from '../constants/actionTypes';

export const createSpeaker = (
  ocId,
  referencable_type,
  referencable_id,
  formData,
  callback
) => ({
  type: CREATE_SPEAKER,
  payload: { ocId, referencable_type, referencable_id, formData },
  callback
});

export const createSpeakerSuccess = (
  ocId,
  referencable_type,
  data,
  included
) => ({
  type: CREATE_SPEAKER_SUCCESS,
  payload: { ocId, referencable_type, data, included }
});

export const createSpeakerFailed = (error) => ({
  type: CREATE_SPEAKER_FAILED,
  error
});

export const getSpeakerWithPagination = (ocId, pageNumber, callback) => ({
  type: GET_SPEAKER_PAGINATION,
  payload: { ocId, pageNumber },
  callback
});

export const getSpeakerWithPaginationSuccess = (
  ocId,
  data,
  links,
  included
) => ({
  type: GET_SPEAKER_PAGINATION_SUCCESS,
  payload: { ocId, data, links, included }
});

export const getSpeakerWithPaginationFailed = (error) => ({
  type: GET_SPEAKER_PAGINATION_FAILED,
  error
});

export const editSpeaker = (ocId, speakerId, formData, callback) => ({
  type: EDIT_SPEAKER,
  payload: { ocId, speakerId, formData },
  callback
});

export const editSpeakerSuccess = (ocId, data, included) => ({
  type: EDIT_SPEAKER_SUCCESS,
  payload: { ocId, data, included }
});

export const editSpeakerFailed = (error) => ({
  type: EDIT_SPEAKER_FAILED,
  error
});

export const deleteSpeaker = (ocId, speakerId, callback) => ({
  type: DELETE_SPEAKER,
  payload: { ocId, speakerId },
  callback
});

export const deleteSpeakerSuccess = (ocId, data, included) => ({
  type: DELETE_SPEAKER_SUCCESS,
  payload: {
    ocId,
    data,
    included
  }
});

export const deleteSpeakerFailed = (error) => ({
  type: DELETE_SPEAKER_FAILED,
  error
});
