import React from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Button, Progress } from 'antd';
import {
  createActionTrigger,
  updateActionTrigger
} from '../../../../../../actions/trigger';
import { CloseOutlined } from '@ant-design/icons';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup/index';
import {
  SubmitButton,
  FormMap
} from '../../../../../../components/InputGroup/FormComponents';
import { currentActionTrigger } from '../../../../../../selectors/trigger';
import { getProcessedVideo } from '../../../../../../helpers/videoHelper';

const validateLocationData = (values) => {
  const errors = {};
  if (!values?.type) errors.type = 'Type is required';
  if (!values?.message) errors.message = 'Message is required';
  if (!values?.actionType) errors.actionType = 'Action is required';
  return errors;
};
const ActionTriggerCreate = (props) => {
  const [actionTriggerData, setActionTriggerData] = React.useState({
    message: props.initialData?.message || '',
    type: props.initialData?.type || '',
    actionType: props.initialData?.actionType || '',
    action_trigger_media: props.initialData?.action_trigger_media || null
  });
  const [isInitiallyDisabled, setInitiallyDisabled] = React.useState(true);
  const [errorMessages, setErrorMessages] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [percent, setPercent] = React.useState(0);

  const updateProgress = (e) => {
    const percentVal = Math.round(e.ratio * 100);
    setPercent(percentVal);
  };
  const handleActionTriggerCreate = async () => {
    try {
      const validateObj = validateLocationData(actionTriggerData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        const formData = new FormData();
        if (
          actionTriggerData?.action_trigger_media &&
          actionTriggerData?.action_trigger_media[0]?.originFileObj
        ) {
          let file = actionTriggerData?.action_trigger_media[0];
          if (!!file?.originFileObj && file?.type?.indexOf('image') >= 0) {
            formData.append(
              'action_trigger[media_file]',
              actionTriggerData?.action_trigger_media[0]?.originFileObj
            );
          }
          if (!!file?.originFileObj && file?.type?.indexOf('video') >= 0) {
            setLoading(true);
            const videoFile = await getProcessedVideo(
              file?.originFileObj,
              updateProgress
            );

            formData.append('action_trigger[media_file]', videoFile);
          }
        }
        formData.append('action_trigger[message]', actionTriggerData?.message);
        formData.append('action_trigger[object_name]', 'Occasion');
        formData.append('action_trigger[verb]', actionTriggerData?.actionType);
        if (props.triggerId) {
          props.updateActionTrigger(
            props.occasionId,
            props.triggerId,
            formData,
            () => {
              setActionTriggerData({
                message: '',
                type: '',
                actionType: '',
                action_trigger_media: null
              });
              props.handleCloseClick();
            }
          );
        } else {
          props.createActionTrigger(props.occasionId, formData, () => {
            setActionTriggerData({
              message: '',
              type: '',
              actionType: '',
              action_trigger_media: null
            });
            props.handleCloseClick();
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e, type) => {
    try {
      let value;
      let name;
      if (e) {
        if (isInitiallyDisabled) {
          setInitiallyDisabled(false);
        }
        if (type === 'action_trigger_media') {
          value = e.fileList;
          name = type;
        }
        if (type === 'triggerRadius') {
          value = e;
          name = type;
        }
        if (type === 'venue') {
          value = e;
          name = 'action';
        }
        const actionTriggerValue = value || e?.target?.value;
        const inputName = name || e?.target?.name;
        if (Object.keys(errorMessages).length > 0) {
          setErrorMessages({});
        }
        setActionTriggerData({
          ...actionTriggerData,
          [inputName]: actionTriggerValue
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ErrorBoundary>
      <SectionContent className="action-trigger-section">
        <div className="action-trigger-create">
          <Form
            className="action-trigger-form-container"
            onFinish={handleActionTriggerCreate}>
            <InputGroup
              fieldType="file"
              label="Upload photo, gif or video"
              accept="image/*,video/*"
              fileList={actionTriggerData?.action_trigger_media}
              onChange={handleChange}
              name="action_trigger_media"
              errorMessage={errorMessages.action_trigger_media}
              multiple={false}
            />
            {loading && (
              <p className="compressing-video-message-container">
                <span className="compressing-video-message">
                  Compressing video
                </span>
                <Progress type="circle" percent={percent} width={30} />
              </p>
            )}
            <InputGroup
              label="Choose Type"
              options={[{ value: 'invitation', label: 'Invitation' }]}
              fieldType="radioGroup"
              name="type"
              onChange={handleChange}
              value={actionTriggerData?.type}
              errorMessage={errorMessages?.type}
            />
            {actionTriggerData?.type === 'invitation' && (
              <InputGroup
                label="Choose Action"
                options={[{ value: 'accept', label: 'Accept' }]}
                fieldType="radioGroup"
                name="actionType"
                onChange={handleChange}
                value={actionTriggerData?.actionType}
                errorMessage={errorMessages?.actionType}
              />
            )}
            <InputGroup
              label="Message"
              placeholder="Enter Message"
              name="message"
              fieldType="textArea"
              onChange={handleChange}
              value={actionTriggerData?.message}
              errorMessage={errorMessages?.message}
            />
            <SubmitButton
              buttonText={props.triggerId ? 'Save Changes' : 'Create'}
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};

const mapStateToProps = (store, props) => {
  const currentTrigger = currentActionTrigger(store, props);
  let initialData = {};
  if (currentTrigger) {
    initialData.message = currentTrigger.message;
    initialData.type = 'invitation';
    initialData.actionType = currentTrigger.verb;
    initialData.action_trigger_media = currentTrigger.action_trigger_media;
  }
  return {
    fetchProgress: store.browserState.fetchProgress,
    initialData
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { createActionTrigger, updateActionTrigger },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionTriggerCreate);
