import { createSelector } from 'reselect';
import { userEntities, occasionSmsEntities } from './entity';
import { occasionSmsIds } from './static';
import { currentOccasionId } from './dynamicData';

export const currentSmsIds = createSelector(
  currentOccasionId,
  occasionSmsIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getAllSmsData = createSelector(
  occasionSmsEntities,
  currentSmsIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const occasionSmsWithUserData = createSelector(
  getAllSmsData,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      createdBy: { ...entities[item?.createdBy?.id] }
    }))
);
