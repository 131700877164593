import React, { useState } from 'react';
import { Image } from 'antd';
import { ZoomInOutlined } from '@ant-design/icons';

import './styles.scss';
import { getImageSource } from '../../helpers/imageHelper';

function CardImage(props) {
  const [error, setError] = useState(false);

  return (
    <div className="card-image-container">
      {!error ? (
        <Image
          src={getImageSource(props.data)}
          preview={{ maskClassName: 'preview-style', mask: <ZoomInOutlined /> }}
          className={`image-style ${props.className}`}
          onError={() => setError(true)}
        />
      ) : props.data ? (
        <a
          href={
            props.keyName === 'fileUrl' && props.data.includes('https://')
              ? `${props.data}`
              : `${process.env.REACT_APP_CLIENT_URL}/${props.data}`
          }
          target="_blank">
          View File
        </a>
      ) : (
        '---'
      )}
    </div>
  );
}
export default CardImage;
