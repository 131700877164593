import {
  OCCASION_SMS_CREATE,
  OCCASION_SMS_CREATE_SUCCESS,
  OCCASION_SMS_CREATE_FAILED,
  OCCASION_SMS_VIEW,
  OCCASION_SMS_VIEW_SUCCESS,
  OCCASION_SMS_VIEW_FAILED
} from '../constants/actionTypes';

export const occasionSmsCreate = (ocId, formData, callback) => ({
  type: OCCASION_SMS_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionSmsCreateSuccess = (data, ocId, included, links) => ({
  type: OCCASION_SMS_CREATE_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionSmsCreateFailed = (error) => ({
  type: OCCASION_SMS_CREATE_FAILED,
  error
});

export const occasionSmsView = (ocId, page) => ({
  type: OCCASION_SMS_VIEW,
  payload: { ocId, page }
});

export const occasionSmsViewSuccess = (data, ocId, included, links) => ({
  type: OCCASION_SMS_VIEW_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionSmsViewFailed = (error) => ({
  type: OCCASION_SMS_VIEW_FAILED,
  error
});
