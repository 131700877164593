import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  occasionTicketVariantFormCreateApi,
  occasionTicketVariantFormViewApi
} from '../api/ticketVariantForm';
import {
  OCCASION_TICKET_VARIANT_FORM_CREATE,
  OCCASION_TICKET_VARIANT_FORM_VIEW
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  occasionTicketVariantFormCreateFailed,
  occasionTicketVariantFormCreateSuccess,
  occasionTicketVariantFormViewFailed,
  occasionTicketVariantFormViewSuccess
} from '../actions/ticketVariantForm';

export function* occasionTicketVariantFormCreateSaga(action) {
  try {
    const result = yield call(
      occasionTicketVariantFormCreateApi,
      action.payload.ocId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionTicketVariantFormCreateSuccess(
          result.data,
          result.included,
          action.payload.ticketId
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(occasionTicketVariantFormCreateFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(occasionTicketVariantFormCreateFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* occasionTicketVariantFormViewSaga(action) {
  try {
    const result = yield call(
      occasionTicketVariantFormViewApi,
      action.payload.ocId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        occasionTicketVariantFormViewSuccess(
          result.data,
          result.included,
          result.links,
          action.payload.ticketId
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(occasionTicketVariantFormViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* ticketVariantFormActionWatcher() {
  yield takeLatest(
    OCCASION_TICKET_VARIANT_FORM_CREATE,
    progressSaga,
    occasionTicketVariantFormCreateSaga
  );
  yield takeLatest(
    OCCASION_TICKET_VARIANT_FORM_VIEW,
    progressSaga,
    occasionTicketVariantFormViewSaga
  );
}
