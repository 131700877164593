import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'antd';
import { SectionContent } from '../../../../../components/Section';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../components/InputGroup/FormComponents';
import {
  multipleInvitations,
  parse_csv
} from '../../../../../actions/Invitation';
import { CloseOutlined } from '@ant-design/icons';
import { validateEmailOrMobile } from '../../../../../helpers/extraHelpers';

const validateSendData = (values, Toggle, emailOrMobileData) => {
  const errors = {};
  if (!Toggle) {
    if (emailOrMobileData.length === 0) {
      errors.message = 'Invalid Input';
    }
    emailOrMobileData.forEach((item) => {
      if (!validateEmailOrMobile(item)) {
        errors.message = 'Invalid Input';
      }
    });
  } else {
    if (!values.csv_file) {
      errors.csv_file = 'A CSV file is required';
    }
  }
  return errors;
};

const SendCreate = (props) => {
  const [sendData, setSendData] = useState({
    toggle: false,
    text: '',
    csv_file: null
  });
  const [emailOrMobile, setEmailOrMobile] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});
  const handleSubmit = () => {
    try {
      const validateObj = validateSendData(
        sendData,
        sendData.toggle,
        emailOrMobile
      );
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        !sendData.toggle
          ? emailOrMobile.forEach((el) => {
              return formData.append('email_or_mobiles[]', el);
            })
          : formData.append('csv_file', sendData.csv_file[0].originFileObj);

        !sendData.toggle
          ? props.multipleInvitations(props.occasionId, formData, callback)
          : props.parse_csv(props.occasionId, formData, callback);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const callback = () => {
    setErrorMessages({});
    setEmailOrMobile([]);
    setSendData({
      ...sendData,
      csv_file: null,
      text: ''
    });
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let formValue;
      let inputName;

      if (type === 'csv_file') {
        value = e.fileList;
        name = type;
      }
      if (type === 'toggle') {
        value = e;
        name = type;
      }

      if (e?.target?.name === 'text') {
        value = e?.target?.value;
        name = e?.target?.name;

        const data = value.split(',');
        if (data.length > 0) {
          const arr = data
            .map((el) => {
              return el.trim() ? el : null;
            })
            .filter((el) => el);
          setEmailOrMobile(arr);
        }
      }

      formValue = value;
      inputName = name;

      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }

      setSendData({
        ...sendData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  const removeElement = (index) => {
    const copyData = emailOrMobile;
    copyData.splice(index, 1);
    setEmailOrMobile([...copyData]);
  };

  return (
    <ErrorBoundary>
      <SectionContent>
        <Form
          className="form-control-container send-invitation-form"
          onFinish={handleSubmit}>
          <InputGroup
            fieldType="switch"
            name="toggle"
            label="Toggle to upload CSV"
            onChange={handleInputChange}
          />
          {sendData.toggle ? (
            <InputGroup
              fieldType="file"
              accept=".csv"
              name="csv_file"
              label="CSV file"
              fileList={sendData?.csv_file}
              errorMessage={errorMessages.csv_file}
              onChange={handleInputChange}
            />
          ) : (
            ''
          )}
          {!sendData.toggle ? (
            <>
              <InputGroup
                name="text"
                placeholder="Enter Email or Phone number (comma or space separated)"
                label="Email/Phone number"
                fieldType="textArea"
                value={sendData?.text}
                onChange={handleInputChange}
                errorMessage={errorMessages.message}
              />
              <div className="form-email-or-mobile">
                {emailOrMobile?.map((el, index) => {
                  const isValidMail = validateEmailOrMobile(el);
                  if (el.trim())
                    return (
                      <div
                        key={`${el}_${index}`}
                        className={`email-or-mobile ${
                          isValidMail ? 'valid-mail' : 'invalid-mail'
                        }`}>
                        <div className="data-content">{el}</div>
                        <div
                          className="close-icon"
                          onClick={() => removeElement(index)}>
                          <CloseOutlined />
                        </div>
                      </div>
                    );
                  return null;
                })}
              </div>
            </>
          ) : (
            ''
          )}
          <SubmitButton
            buttonText="Create"
            fetchProgress={props.fetchProgress}
            disabled={props.fetchProgress}
          />
        </Form>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      multipleInvitations,
      parse_csv
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SendCreate);
