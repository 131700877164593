import { getMomentTime } from '../../../../helpers/dateTimeHelper';
const validate = (values, ID) => {
  const errors = {};
  if (!values.phone) {
    errors.phone = 'Phone Number is required';
  } else if (values.phone) {
    if (values.phone.length > 10 || values.phone.length < 10)
      errors.phone = 'Invalid Phone';
  }

  if (!values.email) {
    errors.email = 'Email is required';
  } else if (values.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
      errors.email = 'Invalid Email';
  }
  if (!ID) {
    if (!values.password) errors.password = 'Password is required';
    else if (values.password.length < 6)
      errors.password = 'Minimum 6 characters long';
  }
  if (!values.name) {
    errors.name = 'User name is required';
  } else if (values.name.length < 4 || values.name.length > 100) {
    errors.name = 'Must be between 4 to 100 characters';
  }

  if (!values.dob) {
    errors.dob = 'Please enter date of birth';
  } else if (getMomentTime(values.dob) > new Date().getTime()) {
    errors.dob = 'Invalid Date Of Birth';
  }

  if (!values.gender) errors.gender = 'Gender is required';

  if (!values.access_type) errors.access_type = 'Access type is required';

  return errors;
};

export default validate;
