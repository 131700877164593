import {
  CREATE_SPEAKER_SUCCESS,
  CREATE_SPEAKER_FAILED,
  GET_SPEAKER_PAGINATION_FAILED,
  GET_SPEAKER_PAGINATION_SUCCESS,
  DELETE_SPEAKER_SUCCESS,
  DELETE_SPEAKER_FAILED,
  EDIT_SPEAKER_FAILED
} from '../constants/actionTypes';
import { union } from 'lodash';

const initialState = {
  occasionSpeakerData: null,
  error: null,
  occasionSpeakerLinks: null
};

const speaker = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_SPEAKER_FAILED:
    case EDIT_SPEAKER_FAILED:
    case GET_SPEAKER_PAGINATION_FAILED:
    case CREATE_SPEAKER_FAILED: {
      return { ...state, error: action.error };
    }

    case CREATE_SPEAKER_SUCCESS: {
      const { ocId, data } = action.payload;
      const prevData = state.occasionSpeakerData || {};
      const prevSpeakerIds = prevData[ocId] || [];
      const newIds = [data.id, ...prevSpeakerIds];
      return {
        ...state,
        occasionSpeakerData: {
          ...prevData,
          [ocId]: newIds
        }
      };
    }

    case GET_SPEAKER_PAGINATION_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.occasionSpeakerData || {};
      const currentOccasionSpeakerNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionSpeakerData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionSpeakerNewIds]
        },
        occasionSpeakerLinks: links
      };
    }

    case DELETE_SPEAKER_SUCCESS: {
      const { data, ocId } = action.payload;

      const occasionGroupedData = state.occasionSpeakerData || {};
      const currentOccasionSpeakerIds = occasionGroupedData[ocId] || [];
      const currentOccasionSpeakerNewIds = currentOccasionSpeakerIds?.filter(
        (id) => id != data.id
      );

      return {
        ...state,
        occasionSpeakerData: {
          ...occasionGroupedData,
          [ocId]: currentOccasionSpeakerNewIds
        }
      };
    }

    default:
      return state;
  }
};

export default speaker;
