import { createSelector } from 'reselect';
import {
  currentOccasionId,
  currentUserId,
  currentMediaId
} from './dynamicData';
import { mediaEntities, userEntities } from './entity';
import {
  faceInfoMediaIds,
  faceInfoUserIds,
  selFieUserMediaIds
} from './static';

export const currentSelfieUserMediaIds = createSelector(
  currentUserId,
  selFieUserMediaIds,
  (uId, ids) => uId && ids && ids[uId]
);

export const getAllSelfieUserMedia = createSelector(
  mediaEntities,
  currentSelfieUserMediaIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const getSelfieUserMediaWithUserData = createSelector(
  getAllSelfieUserMedia,
  userEntities,
  (data, entities) =>
    data &&
    entities &&
    data?.length > 0 &&
    data.map((item) => ({
      ...item,
      createdBy: { ...entities[item?.createdBy?.id] }
    }))
);

export const currentFaceInfoUserIds = createSelector(
  currentOccasionId,
  faceInfoUserIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getAllFaceInfoUsers = createSelector(
  userEntities,
  currentFaceInfoUserIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const currentFaceInfoUserData = createSelector(
  currentUserId,
  userEntities,
  (id, entity) => id && entity && entity[id]
);

export const currentFaceInfoMediaIds = createSelector(
  currentOccasionId,
  faceInfoMediaIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getAllFaceInfoMedais = createSelector(
  mediaEntities,
  currentFaceInfoMediaIds,
  (entities, ids) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => entities[id])) ||
    (Array.isArray(ids) ? [] : null)
);

export const currentFaceInfoMediaData = createSelector(
  currentMediaId,
  mediaEntities,
  (id, entity) => id && entity && entity[id]
);
