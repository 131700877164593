import { createSelector } from 'reselect';
import {
  carouselSegmentDetailEntities,
  carouselSegmentEntities
} from './entity';
import { carouselIds } from './static';
import { currentOccasionId } from './dynamicData';

export const currentCarouselIds = createSelector(
  currentOccasionId,
  carouselIds,
  (ocId, ids) => ocId && ids && ids[ocId]
);

export const getAllCarouselData = createSelector(
  carouselSegmentDetailEntities,
  currentCarouselIds,
  carouselSegmentEntities,
  (entities, ids, cEntites) =>
    (ids &&
      entities &&
      Object.keys(entities).length > 0 &&
      ids.map((id) => ({
        ...entities[id],
        carouselSegmentStatus:
          cEntites[entities[id]?.carouselSegment?.id]?.active,
        carouselSegmentId: cEntites[entities[id]?.carouselSegment?.id]?.id
      }))) ||
    (Array.isArray(ids) ? [] : null)
);
