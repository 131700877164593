import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { capitalize } from '../../../../../../helpers/stringHelper';
import { SectionContent } from '../../../../../../components/Section';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import InputGroup from '../../../../../../components/InputGroup/index';
import { SubmitButton } from '../../../../../../components/InputGroup/FormComponents';
import {
  eventAffairCreate,
  eventAffairUpdate
} from '../../../../../../actions/event';
import { getCurrentEventAffair } from '../../../../../../selectors/eventAffair';
import { getImageSource } from '../../../../../../helpers/imageHelper';

const validateData = (values) => {
  const errors = {};
  if (!values.eventAffairType) {
    errors.eventAffairType = 'Event Affair Type is Required';
  }
  if (!values.colorCode) {
    errors.colorCode = 'Color Code is Required';
  }
  return errors;
};

const EventAffairCreate = (props) => {
  const [eventAffairData, setEventAffairDate] = useState({
    eventAffairType: props.currentEventAffairData?.affairType || '',
    colorCode: props.currentEventAffairData?.colorCode || '',
    extraInfo: props.currentEventAffairData?.extraInfo || '',
    description: props.currentEventAffairData?.description || '',
    image: props.currentEventAffairData?.iconImageUrl && [
      { url: getImageSource(props.currentEventAffairData?.iconImageUrl) }
    ]
  });
  const [errorMessages, setErrorMessages] = useState({});

  const eventAffairTypeOptions = Object.keys(props.eventAffairTypes);

  const handleSubmit = () => {
    try {
      const validateObj = validateData(eventAffairData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append(
          'event_affair[affair_type]',
          eventAffairData.eventAffairType
        );
        formData.append('event_affair[color_code]', eventAffairData.colorCode);
        formData.append('event_affair[extra_info]', eventAffairData.extraInfo);

        if (eventAffairData?.description) {
          if (Array.isArray(eventAffairData.description)) {
            eventAffairData.description?.map((item) =>
              formData.append('event_affair[description][]', item)
            );
          } else {
            const Arr = eventAffairData?.description.split(',');
            Arr?.map((item) =>
              formData.append('event_affair[description][]', item)
            );
          }
        }
        if (eventAffairData.image && eventAffairData.image[0]?.originFileObj) {
          formData.append(
            'event_affair[icon_image]',
            eventAffairData.image[0]?.originFileObj
          );
        }
        props.eventAffairId
          ? props.eventAffairUpdate(
              props.occasionId,
              props.eventAffairId,
              props.eventId,
              formData
            )
          : props.eventAffairCreate(
              props.occasionId,
              formData,
              props.eventId,
              () => {
                setEventAffairDate({});
              }
            );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, type) => {
    try {
      let value;
      let name;
      let formValue;
      let inputName;
      if (type === 'image') {
        value = e.fileList;
        name = type;
      }
      formValue = value || e?.target?.value;
      inputName = name || e?.target?.name;
      setEventAffairDate({
        ...eventAffairData,
        [inputName]: formValue
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ErrorBoundary>
      <SectionContent className="event-affair-create-section">
        <div className="event-affair-create-form">
          <Form className="event-affair-form-container" onFinish={handleSubmit}>
            <InputGroup
              fieldType="dropdown"
              className="event-affair-type"
              options={eventAffairTypeOptions?.map((item) => ({
                label: capitalize(item),
                value: item
              }))}
              name="eventAffairType"
              label="Event Affair Type"
              onChange={handleInputChange}
              value={eventAffairData?.eventAffairType}
              errorMessage={errorMessages.eventAffairType}
            />

            <InputGroup
              label="Description"
              name="description"
              placeholder="Enter Description"
              onChange={handleInputChange}
              fieldType="textArea"
              value={eventAffairData?.description}
            />

            <InputGroup
              label="Extra Info"
              name="extraInfo"
              placeholder="Enter Extra Info"
              fieldType="textArea"
              onChange={handleInputChange}
              value={eventAffairData?.extraInfo}
            />

            <InputGroup
              label="Color Code"
              name="colorCode"
              className="color-code-input"
              onChange={handleInputChange}
              fieldType="colorPicker"
              value={eventAffairData?.colorCode}
              errorMessage={errorMessages.colorCode}
            />

            <InputGroup
              fieldType="file"
              label="Image"
              accept="image/*"
              fileList={eventAffairData?.image}
              onChange={handleInputChange}
              name="image"
            />

            <SubmitButton
              buttonText={props.eventAffairId ? 'Save Changes' : 'Create'}
              fetchProgress={props.fetchProgress}
              disabled={props.fetchProgress}
            />
          </Form>
          <Button
            onClick={props.handleCloseClick}
            className="edit-close-button">
            <CloseOutlined />
          </Button>
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
};
const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  eventAffairTypes: store.occasion.events.event_affair_types,
  currentEventAffairData: getCurrentEventAffair(store, props)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ eventAffairCreate, eventAffairUpdate }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EventAffairCreate);
