import {
  OCCASION_TAB_SETTING_VIEW,
  OCCASION_TAB_SETTING_VIEW_SUCCESS,
  OCCASION_TAB_SETTING_VIEW_FAILED,
  OCCASION_TAB_SETTING_UPDATE,
  OCCASION_TAB_SETTING_UPDATE_FAILED,
  OCCASION_TAB_SETTING_UPDATE_SUCCESS,
  OCCASION_TAB_SETTING_CREATE,
  OCCASION_TAB_SETTING_CREATE_FAILED,
  OCCASION_TAB_SETTING_CREATE_SUCCESS
} from '../constants/actionTypes';

export const occasionTabSettingCreate = (ocId, formData, callback) => ({
  type: OCCASION_TAB_SETTING_CREATE,
  payload: { ocId, formData },
  callback
});

export const occasionTabSettingCreateSuccess = (
  data,
  ocId,
  included,
  links
) => ({
  type: OCCASION_TAB_SETTING_CREATE_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionTabSettingCreateFailed = (error) => ({
  type: OCCASION_TAB_SETTING_CREATE_FAILED,
  error
});

export const occasionTabSettingView = (ocId, page) => ({
  type: OCCASION_TAB_SETTING_VIEW,
  payload: { ocId, page }
});

export const occasionTabSettingViewSuccess = (data, ocId, included, links) => ({
  type: OCCASION_TAB_SETTING_VIEW_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionTabSettingViewFailed = (error) => ({
  type: OCCASION_TAB_SETTING_VIEW_FAILED,
  error
});

export const occasionTabSettingUpdate = (
  ocId,
  occasionTabSettingId,
  formData,
  callback
) => ({
  type: OCCASION_TAB_SETTING_UPDATE,
  payload: { ocId, occasionTabSettingId, formData },
  callback
});

export const occasionTabSettingUpdateSuccess = (
  data,
  ocId,
  included,
  links
) => ({
  type: OCCASION_TAB_SETTING_UPDATE_SUCCESS,
  payload: { data, ocId, included, links }
});

export const occasionTabSettingUpdateFailed = (error) => ({
  type: OCCASION_TAB_SETTING_UPDATE_FAILED,
  error
});
