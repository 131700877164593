import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Image } from 'antd';
import moment from 'moment';
import { ZoomInOutlined, EditOutlined } from '@ant-design/icons';
import { getImageSource } from '../../../../../../helpers/imageHelper';
import { regenerateFaceInfo } from '../../../../../../actions/faceInfo';

const MediaDetailsTab = ({ mediaDetails, occasionId, regenerateFaceInfo }) => {
  const handleRegenerateFaceInfo = () => {
    regenerateFaceInfo(occasionId, 'Media', mediaDetails?.id);
  };

  return (
    <div className="face-info-media-details-container">
      <p className="face-info-media-details card-details">
        <p className="fields">File:</p>
        <p className="field-data card-image-container">
          <Image
            src={getImageSource(mediaDetails?.fileUrl)}
            preview={{
              maskClassName: 'preview-style',
              mask: <ZoomInOutlined />
            }}
            className="image-style"
          />
        </p>
      </p>
      <p className="face-info-media-details">
        <p className="fields">Name:</p>
        <p className="field-data">{mediaDetails?.name}</p>
      </p>

      <p className="face-info-media-details">
        <p className="fields">Created At:</p>
        <p className="field-data">
          {moment(mediaDetails?.createdAt).format('DD/MM/YYYY hh:mm A')}
        </p>
      </p>

      <p className="face-info-media-details">
        <p className="fields">Kind:</p>
        <p className="field-data">{mediaDetails?.kind}</p>
      </p>

      <p className="face-info-media-details">
        <p className="fields">Face Info:</p>
        <p className="field-data">{mediaDetails?.faceInfo.toString()}</p>
      </p>
      <p className="face-info-media-details">
        <button
          className="face-info-media-regenerate"
          onClick={handleRegenerateFaceInfo}>
          Regenerate Face Info
        </button>
      </p>
    </div>
  );
};

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ regenerateFaceInfo }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MediaDetailsTab);
