import {
  GET_REPORTS_WITH_PAGINATION_SUCCESS,
  GET_REPORTS_WITH_PAGINATION_FAILED,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAILED
} from '../constants/actionTypes';

const initialState = {
  posts: {
    data: null,
    links: null
  },
  error: null
};

const report = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_POST_FAILED:
    case GET_REPORTS_WITH_PAGINATION_FAILED: {
      return { ...state, error: action.error };
    }

    case GET_REPORTS_WITH_PAGINATION_SUCCESS: {
      const { data, links, ocId } = action.payload;
      const newIds = data && data?.length > 0 ? data.map((el) => el?.id) : [];
      return {
        ...state,
        posts: {
          data: { [ocId]: newIds },
          links
        }
      };
    }

    case DELETE_POST_SUCCESS: {
      const { data, occasionId } = action.payload;
      const occasionGroupedData = state.posts.data || {};
      const currentOccasionPostIds = occasionGroupedData[occasionId] || [];
      const currentOccasionPostNewIds = currentOccasionPostIds?.filter(
        (id) => id != data.id
      );

      return {
        ...state,
        posts: {
          data: {
            ...occasionGroupedData,
            [occasionId]: currentOccasionPostNewIds
          }
        }
      };
    }

    default:
      return state;
  }
};

export default report;
