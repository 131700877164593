import React, { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SectionContent } from '../../../../../../components/Section';
import './styles.scss';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import Tabs from '../../../../../../components/Tabs';

import {
  currentOccasionId,
  currentUserId
} from '../../../../../../selectors/dynamicData';
import { getCurrentOccasion } from '../../../../../../selectors/occasion';
import { TabsHeader } from '../../../../../../components/TabsHeader';
import { currentFaceInfoUserData } from '../../../../../../selectors/faceInfo';
import UserDetailsTab from './details';
import ViewSelfieUserMedia from './SelfieUserMedia/view';

function FaceInfoUserDetails(props) {
  const detailsComponent = () => {
    return (
      <UserDetailsTab
        userDetails={props.currentUserData}
        occasionId={props.occasionId}
      />
    );
  };

  const selfieUserMediaComponent = () => {
    return (
      <ViewSelfieUserMedia
        userId={props.currentUserData?.id}
        occasionId={props.occasionId}
      />
    );
  };

  const tabPanes = useMemo(() => {
    return [
      {
        tabName: 'Details',
        tabKey: '1',
        children: detailsComponent()
      },
      {
        tabName: 'Selfie User Media',
        tabKey: '2',
        children: selfieUserMediaComponent()
      }
    ];
  }, [props?.occasionId, props?.currentUserData]);

  const goBack = () => {
    props.history.goBack();
  };

  return (
    <ErrorBoundary>
      <SectionContent className="face-info-user-section">
        <TabsHeader
          name={props.currentOccasion.name}
          extraData={[
            props.currentOccasion.type,
            props.currentOccasion.guestType,
            'User',
            props.currentUserData.name
          ]}
          wrapperClass="section-details"
        />
        <div className="face-info-user-section-container">
          <Tabs
            defaultActiveKey="1"
            tabPanes={tabPanes}
            onExtraButtonPress={goBack}
          />
        </div>
      </SectionContent>
    </ErrorBoundary>
  );
}

const mapStateToProps = (store, props) => ({
  fetchProgress: store.browserState.fetchProgress,
  occasionId: currentOccasionId(store, props),
  currentOccasion: getCurrentOccasion(store, props),
  currentUserId: currentUserId(store, props),
  currentUserData: currentFaceInfoUserData(store, props)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FaceInfoUserDetails);
