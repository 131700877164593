import { union } from 'lodash';
import {
  OCCASION_ALBUM_VIEW_SUCCESS,
  OCCASION_ALBUM_VIEW_FAILED,
  OCCASION_ALBUM_CREATE_SUCCESS,
  OCCASION_ALBUM_CREATE_FAILED,
  GET_ALBUM_IMAGES_SUCCESS,
  GET_ALBUM_VIDEOS_SUCCESS,
  CREATE_ALBUM_IMAGES_SUCCESS,
  CREATE_ALBUM_VIDEOS_SUCCESS,
  DELETE_ALBUM_MEDIA_FAILED,
  CREATE_ALBUM_MEDIA_FAILED,
  GET_ALBUM_IMAGES_FAILED,
  GET_ALBUM_VIDEOS_FAILED,
  CLEAR_ALBUM_UPLOAD_IMAGE_LIST,
  DELETE_ALBUM_MEDIA_SUCCESS,
  UPLOAD_ALBUM_MEDIA_LIST,
  DELETE_ALBUM_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  occasionAlbumData: null,
  albumLinks: null,
  photos: null,
  videos: null,
  photosNextPresent: null,
  videosNextPresent: null,
  uploadImageList: {},
  error: null
};

const album = (state = initialState, action) => {
  switch (action.type) {
    case OCCASION_ALBUM_VIEW_SUCCESS: {
      const { data, ocId, links } = action.payload;
      const occasionGroupedData = state.occasionAlbumData || {};
      const currentOccasionNewIds =
        data && data?.length > 0 ? data.map((el) => el.id) : [];

      return {
        ...state,
        occasionAlbumData: {
          ...occasionGroupedData,
          [ocId]: [...currentOccasionNewIds]
        },
        albumLinks: links
      };
    }

    case OCCASION_ALBUM_CREATE_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionAlbumData || {};
      const currentOccasionAlbumIds = occasionGroupedData[ocId] || [];

      const newIds = [data.id, ...currentOccasionAlbumIds];
      return {
        ...state,
        occasionAlbumData: {
          ...occasionGroupedData,
          [ocId]: newIds
        }
      };
    }
    case DELETE_ALBUM_SUCCESS: {
      const { data, ocId } = action.payload;
      const occasionGroupedData = state.occasionAlbumData || {};
      const currentOccasionAlbumIds = occasionGroupedData[ocId] || [];
      const filteredIds = currentOccasionAlbumIds.filter(
        (id) => id !== data.id
      );

      return {
        ...state,
        occasionAlbumData: {
          ...occasionGroupedData,
          [ocId]: filteredIds
        }
      };
    }

    case GET_ALBUM_IMAGES_SUCCESS: {
      const { occasionId, albumId, data, links, page } = action.payload;
      const prevState = state.photos || {};
      const oldIds =
        state.photos && state?.photos[albumId] ? state.photos[albumId] : [];
      const prevNextLinks = state.photosNextPresent || {};
      const newIds = data.map((el) => el.id);
      const Ids = page === 1 ? newIds : union(oldIds, newIds);
      return {
        ...state,
        photos: {
          ...prevState,
          [albumId]: Ids
        },
        photosNextPresent: {
          ...prevNextLinks,
          [albumId]: links?.next
        }
      };
    }

    case GET_ALBUM_VIDEOS_SUCCESS: {
      const { occasionId, albumId, data, links, page } = action.payload;
      const prevState = state.videos || {};
      const oldIds =
        state.videos && state?.videos[albumId] ? state.videos[albumId] : [];
      const prevNextLinks = state.videosNextPresent || {};
      const newIds = data.map((el) => el.id);
      const Ids = page === 1 ? newIds : union(oldIds, newIds);
      return {
        ...state,
        videos: {
          ...prevState,
          [albumId]: Ids
        },
        videosNextPresent: {
          ...prevNextLinks,
          [albumId]: links?.next
        }
      };
    }

    case CREATE_ALBUM_IMAGES_SUCCESS: {
      const { occasionId, albumId, data } = action.payload;
      const prevState = state.photos || {};
      const oldIds =
        state.photos && state?.photos[albumId] ? state.photos[albumId] : [];
      const newId = [data.id];
      const Ids = union(newId, oldIds);
      return {
        ...state,
        photos: {
          ...prevState,
          [albumId]: Ids
        }
      };
    }

    case CREATE_ALBUM_VIDEOS_SUCCESS: {
      const { occasionId, albumId, data } = action.payload;
      const prevState = state.videos || {};
      const oldIds =
        state.videos && state?.videos[albumId] ? state.videos[albumId] : [];
      const newId = [data.id];
      const Ids = union(newId, oldIds);
      return {
        ...state,
        videos: {
          ...prevState,
          [albumId]: Ids
        }
      };
    }

    case UPLOAD_ALBUM_MEDIA_LIST: {
      const { occasionId, albumId, data, progress } = action.payload;
      const prevImageList = state.uploadImageList || {};
      const prevImageListPerOccasion = state.uploadImageList[albumId]
        ? state.uploadImageList[albumId]
        : {};
      return {
        ...state,
        uploadImageList: {
          ...prevImageList,
          [albumId]: {
            ...prevImageListPerOccasion,
            [data.uid]: {
              ...data,
              progress
            }
          }
        }
      };
    }

    case CLEAR_ALBUM_UPLOAD_IMAGE_LIST: {
      const { occasionId, albumId } = action.payload;
      const prevImageList = state.uploadImageList || {};
      return {
        ...state,
        uploadImageList: {
          ...prevImageList,
          [albumId]: null
        }
      };
    }

    case DELETE_ALBUM_MEDIA_SUCCESS: {
      const { occasionId, mediaId, mediaType, albumId } = action.payload;
      const type = mediaType === 'photo' ? 'photos' : 'videos';
      const prevState = state[type] || {};
      const oldIds =
        state[type] && state[type][albumId] ? state[type][albumId] : [];

      const newIds = oldIds.filter((el) => el !== mediaId);

      return {
        ...state,
        [type]: {
          ...prevState,
          [albumId]: newIds
        }
      };
    }

    case OCCASION_ALBUM_VIEW_FAILED:
    case OCCASION_ALBUM_CREATE_FAILED:
    case DELETE_ALBUM_MEDIA_FAILED:
    case CREATE_ALBUM_MEDIA_FAILED:
    case GET_ALBUM_IMAGES_FAILED:
    case GET_ALBUM_VIDEOS_FAILED: {
      return { ...state, error: action.error };
    }
    default:
      return state;
  }
};

export default album;
