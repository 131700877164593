import Logo from '../../../../../assets/sociana-emblem2.png';
import Airplane from '../../../../../assets/hospitality-airplane.png';
import Car from '../../../../../assets/hospitality-car.png';
import Hotel from '../../../../../assets/hospitality-hotel.png';
import Luggage from '../../../../../assets/hospitality-luggage.png';
import Luggage2 from '../../../../../assets/hospitality-luggage2.png';
import Reception from '../../../../../assets/hospitality-reception.png';
import RoomService from '../../../../../assets/hospitality-room-service.png';
import Talking from '../../../../../assets/hospitality-talking.png';
import Travel from '../../../../../assets/hospitality-travel.png';

import AirplaneLanding from '../../../../../assets/hospitality-airplane-landing.png';
import AirplaneTakeOff from '../../../../../assets/hospitality-airplane-take-off.png';
import Crowd from '../../../../../assets/hospitality-crowd.png';
import Clock2 from '../../../../../assets/hospitality-imac-clock.png';
import UserGroup from '../../../../../assets/hospitality-male-user-group.png';
import PassportTicket from '../../../../../assets/hospitality-passport-and-tickets.png';
import Schedule from '../../../../../assets/hospitality-schedule.png';
import Ticket from '../../../../../assets/hospitality-ticket.png';
import TravelDocument from '../../../../../assets/hospitality-travel-document.png';

export const IMAGE_RADIO = {
  airplane: Airplane,
  car: Car,
  luggage: Luggage,
  luggage2: Luggage2,
  reception: Reception,
  roomService: RoomService,
  talking: Talking,
  travel: Travel,
  hotel: Hotel,
  logo: Logo,
  'airplane-landing': AirplaneLanding,
  'airplane-take-off': AirplaneTakeOff,
  crowd: Crowd,
  'imac-clock': Clock2,
  'user-group': UserGroup,
  'passport-ticket': PassportTicket,
  schedule: Schedule,
  ticket: Ticket,
  'travel-document': TravelDocument
};
