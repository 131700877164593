import { put, call, takeLatest } from 'redux-saga/effects';

import progressSaga from './progressSaga';
import {
  createSelfieUserMediaApi,
  faceInfoViewApi,
  getSelfieUserMediaApi,
  regenerateAllGallaryMediaFaceInfoApi,
  regenerateFaceInfoApi,
  updateSelfieImageApi
} from '../api/faceInfo';
import {
  CREATE_SELFIE_USER_MEDIA,
  FACE_INFO_MEDIA_VIEW,
  FACE_INFO_USER_VIEW,
  GET_SELFIE_USER_MEDIA,
  REGENERATE_ALL_GALLARY_MEDIA,
  REGENERATE_FACE_INFO,
  UPDATE_SELFIE_IMAGE
} from '../constants/actionTypes';
import { requestAutoHideAlert } from '../actions/alert';
import { isValidResult } from '../helpers/responseHelper';
import { getUserToken } from '../helpers/authHelper';
import {
  createSelfieUserMediaFailed,
  createSelfieUserMediaSuccess,
  faceInfoMediaViewFailed,
  faceInfoMediaViewSuccess,
  faceInfoUserViewFailed,
  faceInfoUserViewSuccess,
  getSelfieUserMediaFailed,
  getSelfieUserMediaSuccess,
  regenerateAllGallaryMediaFaceInfoFailed,
  regenerateAllGallaryMediaFaceInfoSuccess,
  regenerateFaceInfoFailed,
  regenerateFaceInfoSuccess,
  updateSelfieImageFailed,
  updateSelfieImageSuccess
} from '../actions/faceInfo';

export function* faceInfoMediaViewSaga(action) {
  try {
    const result = yield call(
      faceInfoViewApi,
      action.payload.ocId,
      action.payload.type,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        faceInfoMediaViewSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(faceInfoMediaViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* faceInfoUserViewSaga(action) {
  try {
    const result = yield call(
      faceInfoViewApi,
      action.payload.ocId,
      action.payload.type,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        faceInfoUserViewSuccess(
          result.data,
          result.included,
          action.payload.ocId,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(faceInfoUserViewFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* regenerateFaceInfoSaga(action) {
  try {
    const result = yield call(
      regenerateFaceInfoApi,
      action.payload.ocId,
      action.payload.type,
      action.payload.typeId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        regenerateFaceInfoSuccess(
          action.payload.ocId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(regenerateFaceInfoFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(regenerateFaceInfoFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* createSelfieUserMediaSaga(action) {
  try {
    const result = yield call(
      createSelfieUserMediaApi,
      action.payload.ocId,
      action.payload.userId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        createSelfieUserMediaSuccess(
          action.payload.ocId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(createSelfieUserMediaFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(createSelfieUserMediaFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* updateSelfieImageSaga(action) {
  try {
    const result = yield call(
      updateSelfieImageApi,
      action.payload.ocId,
      action.payload.userId,
      action.payload.formData,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        updateSelfieImageSuccess(
          action.payload.ocId,
          result.data,
          result?.included
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(updateSelfieImageFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(updateSelfieImageFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* regenerateAllGallaryMediaFaceInfoSaga(action) {
  try {
    const result = yield call(
      regenerateAllGallaryMediaFaceInfoApi,
      action.payload.ocId,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        regenerateAllGallaryMediaFaceInfoSuccess(
          action.payload.ocId,
          result.data
        )
      );

      if (result.meta) {
        yield put(requestAutoHideAlert(result.meta.message, 'Success', 4000));
      }
      if (typeof action.callback === 'function') {
        action.callback();
      }
    } else if (result && result.meta) {
      yield put(regenerateAllGallaryMediaFaceInfoFailed(result.meta.message));
      yield put(requestAutoHideAlert(result.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  } catch (error) {
    yield put(regenerateAllGallaryMediaFaceInfoFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export function* getSelfieUserMediaSaga(action) {
  try {
    const result = yield call(
      getSelfieUserMediaApi,
      action.payload.ocId,
      action.payload.userId,
      action.payload.page,
      getUserToken()
    );
    if (isValidResult(result)) {
      yield put(
        getSelfieUserMediaSuccess(
          result.data,
          result.included,
          action.payload.userId,
          result.links,
          result.meta
        )
      );
      if (action.callback) {
        action.callback();
      }
    }
  } catch (error) {
    yield put(getSelfieUserMediaFailed(error));

    if (error?.meta && error?.meta?.message) {
      yield put(requestAutoHideAlert(error.meta.message, 'Error', 4000));
    } else {
      yield put(requestAutoHideAlert('Something went wrong!', 'Error', 4000));
    }
  }
}

export default function* faceInfoActionWatcher() {
  yield takeLatest(FACE_INFO_MEDIA_VIEW, progressSaga, faceInfoMediaViewSaga);
  yield takeLatest(FACE_INFO_USER_VIEW, progressSaga, faceInfoUserViewSaga);
  yield takeLatest(REGENERATE_FACE_INFO, progressSaga, regenerateFaceInfoSaga);
  yield takeLatest(
    CREATE_SELFIE_USER_MEDIA,
    progressSaga,
    createSelfieUserMediaSaga
  );
  yield takeLatest(UPDATE_SELFIE_IMAGE, progressSaga, updateSelfieImageSaga);
  yield takeLatest(
    REGENERATE_ALL_GALLARY_MEDIA,
    progressSaga,
    regenerateAllGallaryMediaFaceInfoSaga
  );
  yield takeLatest(GET_SELFIE_USER_MEDIA, progressSaga, getSelfieUserMediaSaga);
}
