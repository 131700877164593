import React from 'react';
import './styles.scss';

export const CustomParamsContent = ({ data, dataKey }) => {
  if (dataKey === 'customParams') {
    let Arr = [];
    data.map((item) => {
      const kstr1 = item.split('",');
      const kstr2 = kstr1[0].split('=>"');
      const kstr3 = kstr2[1].split('"');

      const vstr2 = kstr1[1].split('=>"');
      const vstr3 = vstr2[1].split('"');
      const obj = { key: kstr3[0], value: vstr3[0] };
      Arr.push(obj);
    });

    return (
      <div className="custom-params-container">
        {Arr.length > 0 &&
          Arr.map((el) => {
            return (
              <div className="custom-params-key-content">
                <b>{el.key}: </b>
                <div className="custom-params-key-box"> {el.value}</div>
              </div>
            );
          })}
      </div>
    );
  } else if (dataKey === 'selfModelQueryParams') {
    return (
      <div className="custom-params-container">
        <div className="custom-params-key-content">
          <b>Key</b>
          <b>Value</b>
        </div>
        {data.length > 0 &&
          data.map((el) => {
            return (
              <div className="custom-params-key-content">
                <div className="query-key">{el.key} </div>
                <div className="custom-params-key-box"> {el.value}</div>
              </div>
            );
          })}
      </div>
    );
  }
};
